import React, { useEffect, useState } from 'react';
import {
  Box, Button, CircularProgress, Grid,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { confirmVerificationCodeRequest, resendVerificationCodeRequest } from 'redux/auth/actions';
import usePrevious from 'CustomHooks/usePrevious';
import VerificationCodeInput from 'Components/VerificationCodeInput/verificationCodeInput';
import { useTranslation } from 'react-i18next';
import Layout from '../Layout';

const VerificationCode = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    isConfirmVerificationCodeSuccess,
    isConfirmVerificationCodeError,
    confirmVerificationCodeErrorMessage,
    resendVerificationCodeErrorMessage,
    isResendVerificationCodeSuccess,
    isResendVerificationCodeError,
  } = useSelector((state) => state.auth);

  const prevIsConfirmVerificationCodeSuccess = usePrevious(isConfirmVerificationCodeSuccess);
  const prevIsConfirmVerificationCodeError = usePrevious(isConfirmVerificationCodeError);
  const prevIsResendVerificationCodeSuccess = usePrevious(isResendVerificationCodeSuccess);
  const prevIsResendVerificationCodeError = usePrevious(isResendVerificationCodeError);

  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [seconds, setSeconds] = useState(90);
  const [errors, setErrors] = useState({});
  const [email, setEmail] = useState('');

  useEffect(() => {
    const forgotEmail = JSON.parse(localStorage.getItem('forgotPasswordEmail'));
    setEmail(forgotEmail.forgotPasswordEmail);
  }, []);

  useEffect(() => {
    if (prevIsConfirmVerificationCodeSuccess === false && isConfirmVerificationCodeSuccess) {
      history.push('/reset-password');
      const prevData = JSON.parse(localStorage.getItem('forgotPasswordEmail'));
      localStorage.setItem('forgotPasswordEmail', JSON.stringify({ ...prevData, verificationCode }));
      setLoading(false);
    } else if (prevIsConfirmVerificationCodeError === false && isConfirmVerificationCodeError) {
      setLoading(false);
      setErrors({ confirmCodeError: confirmVerificationCodeErrorMessage });
    }
  }, [isConfirmVerificationCodeSuccess, isConfirmVerificationCodeError]);

  useEffect(() => {
    if (prevIsResendVerificationCodeSuccess === false && isResendVerificationCodeSuccess) {
      setSeconds(90);
    } else if (prevIsResendVerificationCodeError === false && isResendVerificationCodeError) {
      setErrors({ resendCodeError: resendVerificationCodeErrorMessage });
    }
  }, [isResendVerificationCodeSuccess, isResendVerificationCodeError]);

  useEffect(() => {
    setInterval(() => {
      setSeconds((sec) => {
        if (sec > 0) {
          return sec - 1;
        } else {
          return 0;
        }
      });
    }, 1000);
  }, []);

  const formatTime = (time) => `${String(Math.floor(time / 60)).padStart(2, '0')}:${String(
    time % 60,
  ).padStart(2, '0')}`;

  const handleSubmit = () => {
    setLoading(true);
    dispatch(confirmVerificationCodeRequest({ email, code: verificationCode }));
  };

  const handleResendCode = () => {
    dispatch(resendVerificationCodeRequest({ email }));
  };

  return (
    <Layout>
      <Box className="form-container">
        <Box className="form-box">
          <Box p="50px">
            <p className="title">
              {t('VerificationCode')}
            </p>
            <p className="sub-title">
              {t('EnterCodeText')} {email} <span className="verification-code-time">{formatTime(seconds)}</span>
            </p>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box style={{ display: 'flex', gap: '5px' }}>
                    <VerificationCodeInput setVerificationCode={setVerificationCode} />
                  </Box>
                  {errors.resendCodeError && <p className="error-message">{errors.resendCodeError}</p>}
                  {errors.confirmCodeError && <p className="error-message">{errors.confirmCodeError}</p>}
                </Grid>
              </Grid>
              <Box className="resend-code-row">
                <p>
                  {t('DontGetCode')} <span><Button type="text" color="primary" onClick={handleResendCode} disabled={seconds !== 0}>{t('Resend')}</Button></span>
                </p>
              </Box>
              <Box className="sign-in-btn">
                <Button
                  disabled={!verificationCode}
                  variant="contained"
                  color="primary"
                  data-cy="sign-in"
                  onClick={handleSubmit}
                  loading={loading}
                  fullWidth
                >
                  {loading && <CircularProgress color="white" size={20} />}
                  {!loading && t('Next')}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default VerificationCode;
