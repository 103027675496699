import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  loginRequest,
} from 'redux/auth/actions';
import {
  InputLabel,
  OutlinedInput,
  TextField,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ErrorIcon from '@material-ui/icons/Error';
import usePrevious from 'CustomHooks/usePrevious';
import Layout from '../Layout';
import OAuth from '../../../Components/Account/oAuth';
import '../styles.scss';

const Login = ({
  handleEmailVerified, handleLogin, handleCompanyOnBoarded, match,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    isLoginSuccess,
    isLoginError,
    errorMessage,
    accessToken,
    userAccount,
  } = useSelector((state) => state.auth);

  const prevIsLoginSuccess = usePrevious(isLoginSuccess);
  const prevIsLoginError = usePrevious(isLoginError);

  const [loading, setLoading] = useState(false);
  const [submitDone, setSubmitDone] = useState(false);
  const [data, setData] = useState({
    email: '',
    password: '',
  });
  const [formErrors, setFormErrors] = useState({
    email: false,
    password: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [incorrectLogin, setIncorrectLogin] = useState(false);

  useEffect(() => {
    const emailJustVerified = localStorage.getItem('emailJustVerified');
    const existingEmail = localStorage.getItem('existingEmail');
    if (emailJustVerified === '1') {
      localStorage.removeItem('emailJustVerified');
      handleEmailVerified(true);
    } else if (emailJustVerified === '0') {
      localStorage.removeItem('emailJustVerified');
    }
    if (existingEmail) {
      localStorage.removeItem('existingEmail');
      setData({ ...data, email: existingEmail });
    }
  }, []);

  useEffect(() => {
    if (prevIsLoginSuccess === false && isLoginSuccess) {
      const lang = localStorage.getItem('i18nextLng');
      localStorage.clear();
      localStorage.setItem('i18nextLng', lang);
      localStorage.setItem('menuOpen', true);
      const { redirect } = match.params;
      handleLogin(accessToken);
      handleCompanyOnBoarded(userAccount.company_is_on_boarded_at);
      handleEmailVerified(userAccount.email_verified_at);

      setLoading(false);
      setIncorrectLogin(false);
      if (redirect && redirect === 'settings') {
        history.push('/my-account/settings');
      } else {
        history.push('/calendar');
      }
    } else if (prevIsLoginError === false && isLoginError) {
      localStorage.removeItem('token');
      setLoading(false);
      setIncorrectLogin(isLoginError);
    }
  }, [isLoginSuccess, isLoginError]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitDone(true);
    if (validateForm(null, true)) {
      validateForm('email');
      validateForm('password');
      return false;
    } else {
      const loginData = {};
      loginData.email = data.email;
      loginData.password = data.password;
      setLoading(true);
      dispatch(loginRequest(loginData));
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData({ ...data, [name]: value });

    setIncorrectLogin(false);

    if (submitDone) {
      validateForm(name);
    }
  };

  const validateForm = (name, all = false) => {
    if (all) {
      return (!(data.email.length > 0) || !(data.password.length >= 6));
    } else {
      switch (name) {
        case 'email':
          formErrors.email = !(data.email.length > 0);
          formErrors.validEmail = !(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(data.email));
          break;
        case 'password':
          formErrors.password = !(data.password.length > 0);
          formErrors.passwordLength = !(data.password.length >= 6);
          break;
        default:
          break;
      }
      setFormErrors(formErrors);
      return true;
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Layout>
      <Box className="form-container">
        <Box className="form-box">
          <Box p="50px">
            <p className="title">
              {t('SignIn')}
            </p>
            <p className="sub-title">
              {t('EnterSignInDetailsText')}
            </p>
            <form action="" method="" onSubmit={handleSubmit} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={!!formErrors.email || !!formErrors.validEmail || incorrectLogin}
                  >
                    <TextField
                      error={!!formErrors.email || !!formErrors.validEmail || incorrectLogin}
                      fullWidth
                      label={t('Email')}
                      variant="outlined"
                      onChange={handleChange}
                      data-cy="email"
                      type="email"
                      value={data.email}
                      name="email"
                      size="small"
                    />

                    {formErrors.validEmail && !formErrors.email && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {t('EmailValid')}
                        </FormHelperText>
                      </div>
                    )}

                    {formErrors.email && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {t('EmailRequired')}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={!!formErrors.password || !!formErrors.passwordLength || incorrectLogin}
                  >
                    <InputLabel htmlFor="outlined-adornment-password" error={!!formErrors.password || !!formErrors.passwordLength || incorrectLogin}>{t('Password')}</InputLabel>
                    <OutlinedInput
                      error={!!formErrors.password || !!formErrors.passwordLength || incorrectLogin}
                      type={showPassword ? 'text' : 'password'}
                      id="icon-password"
                      name="password"
                      label={t('Password')}
                      data-cy="password"
                      onChange={handleChange}
                      endAdornment={(
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="toggle password visibility"
                            onClick={() => handleClickShowPassword()}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility style={{ color: '#393939' }} fontSize="small" /> : <VisibilityOff style={{ color: '#393939' }} fontSize="small" />}
                          </IconButton>
                        </InputAdornment>
                            )}
                    />
                    {incorrectLogin && !formErrors.password && !formErrors.passwordLength ? (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {errorMessage}
                        </FormHelperText>
                      </div>
                    ) : formErrors.password ? (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {t('PasswordRequired')}
                        </FormHelperText>
                      </div>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
              <Box className="forgot-password">
                <Link to="/forgot-password">
                  <span>{t('ForgotPassword1')}</span>
                </Link>
              </Box>
              <Box className="sign-in-btn">
                <Button
                  disabled={loading}
                  variant="contained"
                  color="primary"
                  data-cy="sign-in"
                  type="submit"
                  loading={loading}
                  fullWidth
                >
                  {loading && <CircularProgress color="white" size={20} />}
                  {!loading && t('SignIn')}
                </Button>
                <OAuth handleLogin={handleLogin} />
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

Login.propTypes = {
  match: PropTypes.object.isRequired,
  handleLogin: PropTypes.func.isRequired,
  handleCompanyOnBoarded: PropTypes.func.isRequired,
  handleEmailVerified: PropTypes.func.isRequired,
};

export default Login;
