import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  Menu,
  Dialog,
  Tooltip,
  DialogTitle,
  ListItemText,
  DialogContent,
  DialogActions,
  Box,
  Badge,
  Avatar,
  Button,
  MenuItem,
  CardMedia,
  IconButton,
  Typography,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import moment from 'moment';
import useTheme from '@material-ui/core/styles/useTheme';
import withStyles from '@material-ui/core/styles/withStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getIndustryServicesRequest } from '../../../../../../../redux/service/actions';
import {
  updateStaffRequest,
  addStaffToCompanyRequest,
  deleteStaffInvitationRequest,
  deleteStaffRequest,
  getStaffPositionsRequest,
  inviteExistingStaffRequest,
  inviteStaffToCompanyRequest,
  revertAddStaffToCompanyToDefault,
  revertDeleteInvitationToDefault,
  revertDeleteStaffToDefault,
  revertInviteExistingStaffToDefault,
  revertInviteStaffToCompanyToDefault,
  revertStaffPermissionsToDefault,
  revertUpdateStaffToDefault,
  updateStaffPermissionsRequest,
  resendStaffInvitationRequest,
} from '../../../../../../../redux/staff/actions';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import StaffIsolatedDropdown from './staffIsolatedDropdown';
import noStaff from '../../../../../../../assets/img/no-staff.svg';
import DeleteStaffOrStaffInvitation from '../../../../../../../Modals/Staff/Companies/deleteStaffOrStaffInvitation';
import LightTooltip from '../../../../../../../MaterialComponents/lightTooltip';
import firstLetter from '../../../../../../../Modules/getFirstLetter';
import countryCodeOptions from '../../../../../../../Modules/countryCodeOptions';
import { getProfessionsRequest } from '../../../../../../../redux/country/actions';
import {
  getCompanyByIdRequest,
  getPreSignUrlRequest,
  uploadImageToAmazonRequest,
} from '../../../../../../../redux/company/actions';
import StaffInvitationIsolatedDropdown from './staffInvitationIsolatedDropdown';
import PreviewStaffModal from '../../../../../../../Modals/Staff/Preview';
import StaffLoading from '../../../../../../../Components/Loading/staffLoading';
import ExistingModal from '../../../../../../../Modals/Owner/Staff/existingModal';
import AddStaff from '../../../../../../../Modals/Owner/Staff/addStaff';
import ResendInvitation from '../../../../../../../Modals/Owner/Staff/resendInvitation';
import InviteAddedStaff from '../../../../../../../Modals/Owner/Staff/inviteAddedStaff';
import PermissionsModal from '../../../../../../../Modals/Owner/Staff/permissionsModal';
import toUpperCaseEachWord from '../../../../../../../Modules/toUpperCaseEachWord';

function Staff(props) {
  const { t } = useTranslation();
  const {
    addStaff,
    userAccount,
    staffFilterItem,
    getStaffPositions,
    isGetStaffPositionsSuccess,
    revertAddStaff,
    updateStaff,
    revertUpdateStaff,
    isUpdateStaffSuccess,
    updatedStaff,
    revertStaffPermissions,
    isAddStaffPerformed,
    isAddStaffToCompanySuccess,
    addedStaff,
    isAddStaffToCompanyError,
    addCompanyStaffErrors,
    addStaffErrorMessage,
    closeAddStaffModal,
    isInviteStaffPerformed,
    isInviteStaffToCompanySuccess,
    isInviteStaffToCompanyError,
    revertInviteStaff,
    storedInvitation,
    inviteStaff,
    inviteStaffErrorMessage,
    closeInviteNewStaffModal,
    updateStaffPermissions,
    isUpdateStaffPermissionsSuccess,
    isInvitedExistingStaffSuccess,
    isInvitedExistingStaffErrorMessage,
    isGetStaffPositionsError,
    isDeleteStaffInvitationError,
    staffInvitation,
    inviteExistingStaff,
    revertInviteExistingStaff,
    revertDeleteStaff,
    isDeleteStaffSuccess,
    getIndustryServices,
    isInvitedExistingStaffError,
    positions,
    deleteStaffInvitationErrorMessage,
    isUpdateStaffPermissionsError,
    updateStaffErrorMessage,
    updateStaffPermissionsErrorMessage,
    isDeleteStaffInvitationSuccess,
    isUpdateStaffError,
    updatedPagesAccess,
    revertDeleteInvitation,
    deleteStaffErrorMessage,
    isDeleteStaffError,
    sendCompanyUpdated,
    deleteStaff,
    deleteStaffInvitation,
    handleOpenAddStaffModal,
    professions,
    getProfessions,
    isGetProfessionsSuccess,
    companyGot,
    isGetCompanyByIdSuccess,
    resendStaffInvitation,
    isResendStaffInvitationSuccess,
    filteredStaffOption,
    isFilterStaffSuccess,
    allCountries,
    isGetPreSignedUrlSuccess,
    // isGetPreSignedUrlError,
    preSignUrl,
    isUploadImageToAmazonSuccess,
    // isUploadImageToAmazonError,
    uploadImageToAmazon,
    getPreSignUrl,
  } = props;

  const [company, setCompany] = useState({});
  const [files, setFiles] = useState(null);
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(true);
  const [selectedStaffFilter, setSelectedStaffFilter] = useState(staffFilterItem);
  const [isAddStaffModalShown, setIsAddStaffModalShown] = useState(isAddStaffPerformed);
  const [addStaffErrors, setAddStaffErrors] = useState({});
  const [, setAddStaffErrorMessageText] = useState({});
  const [isInviteNewStaffModalShown, setIsInviteNewStaffModalShown] = useState(isInviteStaffPerformed);
  const [inviteStaffErrors, setInviteStaffErrors] = useState({});
  const [isDeleteStaffOrStaffInvitationModalShown, setIsDeleteStaffOrStaffInvitationModalShown] = useState(false);
  const [isInvitedStaff, setIsInvitedStaff] = useState(false);
  const [invitationEmailStaffText, setInvitationEmailStaffText] = useState('');
  const [selectedStaffOrStaffInvitationId, setSelectedStaffOrStaffInvitationId] = useState(null);
  const [staffItem, setStaffItem] = useState({
    position_id: '',
    first_name: '',
    last_name: '',
    email: null,
    phone_code: '',
    phone_number: '',
    is_active: true,
    staff_invitations: [],
  });
  const [positionOptions, setPositionOptions] = useState([]);
  const [selectedPositionOption, setSelectedPositionOption] = useState(0);
  const [selectedProfessionOption, setSelectedProfessionOption] = useState('');
  const [professionOptions, setProfessionOptions] = useState([]);
  const [staffErrors, setStaffErrors] = useState({
    first_name: false,
    last_name: false,
    email: false,
    phone_number: false,
    service: false,
  });
  const [selectedStaffPermissions, setSelectedStaffPermissions] = useState([]);
  const [isEditPermissionsModalShown, setIsEditPermissionsModalShown] = useState(false);
  const [isPreviewStaffModalInfo, setIsPreviewStaffModalInfo] = useState(false);
  const [previewStaffItem, setPreviewStaffItem] = useState({});
  const [alert, setAlert] = useState(null);
  const [lastDeleteStaffId, setLastDeleteStaffId] = useState(false);
  const [selectedInvitationId, setSelectedInvitationId] = useState(false);
  const [isOpenResendDialog, setIsOpenResendDialog] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEm, setAnchorEm] = React.useState(null);
  const [inviteYourselfError, setInviteYourselfError] = useState(false);
  const [isOpenInviteStaffDialog, setIsOpenInviteStaffDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [allCompanyStaffSorted, setAllCompanyStaffSorted] = useState([]);
  const [selectedCodeValue, setSelectedCodeValue] = useState('');
  const [codeOptions, setCodeOptions] = useState([]);
  const [checkedLogin, setCheckedLogin] = useState(false);
  const [anchorOpen, setAnchorOpen] = useState(null);
  const [lineProgress, setLineProgress] = useState(false);
  const [existingUser, setExistingUser] = useState('');
  const [isOpenExistingModal, setIsOpenExistingModal] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [avatarName, setAvatarName] = useState('');
  const [, setOpenSnackbar] = useState(false);
  const [, setSnackbarType] = useState('');
  const [, setSnackbarMessage] = useState('');

  // Get some props previous values
  const prevIsAddStaffPerformed = usePrevious(isAddStaffPerformed);
  const previousIsAddStaffModalShown = usePrevious(isAddStaffModalShown);
  const prevIsUpdateStaffSuccess = usePrevious(isUpdateStaffSuccess);
  const previousIsInviteNewStaffModalShown = usePrevious(isInviteNewStaffModalShown);
  const previousIsGetProfessionsSuccess = usePrevious(isGetProfessionsSuccess);
  const previousIsResendStaffInvitationSuccess = usePrevious(isResendStaffInvitationSuccess);
  const previousIsAddStaffToCompanySuccess = usePrevious(isAddStaffToCompanySuccess);
  const previousIsInviteStaffToCompanySuccess = usePrevious(isInviteStaffToCompanySuccess);
  const previousIsInviteStaffToCompanyError = usePrevious(isInviteStaffToCompanyError);
  const prevIsGetPreSignedUrlSuccess = usePrevious(isGetPreSignedUrlSuccess);
  // const prevIsGetPreSignedUrlError = usePrevious(isGetPreSignedUrlError);
  const prevIsUploadImageToAmazonSuccess = usePrevious(isUploadImageToAmazonSuccess);
  // const prevIsUploadImageToAmazonError = usePrevious(isUploadImageToAmazonError);

  const tableAppHeader = ['Date', 'Name', 'Price', 'Status'];
  const tableServiceHeader = ['Name', 'Duration', 'Price'];

  const theme = useTheme();

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  // Perform, when component gets mounted
  useEffect(() => {
    getStaffPositions();
    getIndustryServices({ industryId: companyGot.industry_id });
    getProfessions(companyGot.industry_id);
    setCompany(companyGot);
    const codeOptions = countryCodeOptions.getCodeOptions(allCountries);
    setCodeOptions(codeOptions);
    return () => {
      revertStaffPermissions();
      revertUpdateStaff();
      revertDeleteStaff();
      revertDeleteInvitation();
      revertInviteStaff();
      revertAddStaff();
      revertInviteExistingStaff();
    };
  }, []);

  // Handel staff filter is success
  useEffect(() => {
    if (isFilterStaffSuccess) {
      const filteredStaffCopy = filteredStaffOption.staffs;
      const sortedStaff = filteredStaffCopy.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllCompanyStaffSorted(sortedStaff);
    }
  }, [isFilterStaffSuccess]);
  // Company by ID success
  useEffect(() => {
    if (isGetCompanyByIdSuccess) {
      const companyGotCopy = companyGot;
      const staffInvitation = companyGotCopy.invitations.filter((item) => item.created_at);
      const registeredStaff = companyGotCopy.staff.filter((item) => item.email_verified_at);
      const addedStaff = companyGotCopy.staff.filter((item) => !item.email_verified_at && !item.staff_invitations.length);
      const staffWithInvitations = companyGotCopy.staff.filter((item) => item.staff_invitations.length);
      const filteredStaffWithInvitations = staffWithInvitations.map((item) => item.staff_invitations[0]);
      const allCompanyStaff = [...staffInvitation, ...registeredStaff, ...addedStaff, ...filteredStaffWithInvitations];
      // Sorted staffs by created date & time
      const allCompanyStaffSortedCopy = allCompanyStaff.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllCompanyStaffSorted(allCompanyStaffSortedCopy);
      setLoading(false);
      setButtonLoading(true);
    }
  }, [isGetCompanyByIdSuccess]);
  // When filtered items was changed
  useEffect(() => {
    setSelectedStaffFilter(staffFilterItem);
  }, [staffFilterItem]);

  useEffect(() => {
    if ((previousIsAddStaffModalShown && !isAddStaffModalShown) || (previousIsInviteNewStaffModalShown && !isInviteNewStaffModalShown)) {
      setTimeout(() => {
        setStaffItem({
          position_id: '',
          first_name: '',
          last_name: '',
          email: null,
          is_active: true,
          staff_invitations: [],
        });
      }, 500);
    }
  }, [isAddStaffModalShown, isInviteNewStaffModalShown]);
  // Handle Add Staff Success
  useEffect(() => {
    if (previousIsAddStaffToCompanySuccess === false && isAddStaffToCompanySuccess) {
      setCompany({
        ...company,
        staff: [
          ...company.staff,
          addedStaff,
        ],
      });
      const preSignUrlOption = {
        id: company.id,
        logo: avatarName,
        folder: 'companyStaff',
      };
      getPreSignUrl(preSignUrlOption);
      allCompanyStaffSorted.unshift(addedStaff);
    }
  }, [isAddStaffToCompanySuccess]);
  // Get Pre-Signed Url Success
  useEffect(() => {
    if (prevIsGetPreSignedUrlSuccess === false && isGetPreSignedUrlSuccess) {
      const uploadImageOption = {
        preSignUrl,
        logo: avatar,
      };
      uploadImageToAmazon(uploadImageOption);
    }
  }, [isGetPreSignedUrlSuccess]);
  useEffect(() => {
    if (prevIsUploadImageToAmazonSuccess === false && isUploadImageToAmazonSuccess) {
      setIsAddStaffModalShown(false);
      setOpenSnackbar(true);
      setButtonLoading(true);
      setSnackbarMessage('Staff Added Successfully');
      setSnackbarType('success');
    }
  }, [isUploadImageToAmazonSuccess]);

  // Handle Add Staff Error
  useEffect(() => {
    if (isAddStaffToCompanyError) {
      setAddStaffErrorMessageText(addStaffErrorMessage);
      setAddStaffErrors(addCompanyStaffErrors);
      setButtonLoading(true);
      setOpenSnackbar(true);
      setSnackbarType('error');
    }
  }, [isAddStaffToCompanyError]);
  // Handle staff invitation is success
  useEffect(() => {
    if (previousIsInviteStaffToCompanySuccess === false && isInviteStaffToCompanySuccess) {
      if (storedInvitation.already_sent) {
        setOpenSnackbar(true);
        setSnackbarMessage('Invitation to this staff already sent');
        setSnackbarType('error');
      } else if (storedInvitation.staffExists) {
        const { existingStaff } = storedInvitation;
        const alert = getExistingUserAlertContent(existingStaff, () => handleInviteNewStaff(true), handleCancelConfirmation);
        setAlert(alert);
      } else {
        const companyCopy = { ...company };
        if (storedInvitation.staff_id) {
          const staffIndex = companyCopy.staff.findIndex((item) => item.id === storedInvitation.staff_id);
          companyCopy.staff[staffIndex].staff_invitations.push(storedInvitation);
          setCompany(companyCopy);
        } else {
          const updatedCompany = {
            ...company,
            invitations: [
              ...company.invitations,
              storedInvitation,
            ],
          };
          setCompany(updatedCompany);
          sendCompanyUpdated(updatedCompany, true);
        }
        handleCloseAddStaffModal();
        setIsInviteNewStaffModalShown(false);
        setIsOpenInviteStaffDialog(false);
        closeInviteNewStaffModal();
        setAlert(null);
        setOpenSnackbar(true);
        setSnackbarMessage(i18n.t('InvitationSent'));
        setSnackbarType('success');
      }
    }
  }, [isInviteStaffToCompanySuccess]);
  // Handle staff invitation is error
  useEffect(() => {
    if (previousIsInviteStaffToCompanyError === false && isInviteStaffToCompanyError) {
      setInvitationEmailStaffText(inviteStaffErrorMessage);
      setInviteYourselfError(false);
      setButtonLoading(true);
    }
  }, [isInviteStaffToCompanyError]);
  // Handle staff update is success
  useEffect(() => {
    if (prevIsUpdateStaffSuccess === false && isUpdateStaffSuccess) {
      const companyStaffCopy = [...company.staff];
      const allCompanyStaffSortedCopy = [...allCompanyStaffSorted];
      // const staffIndex = companyStaffCopy.findIndex((item) => item.id === updatedStaff.id);
      const staffIndex = allCompanyStaffSortedCopy.findIndex((item) => item.id === updatedStaff.id);
      // companyStaffCopy[staffIndex] = updatedStaff;
      allCompanyStaffSortedCopy[staffIndex] = updatedStaff;
      const updatedCompany = {
        ...company,
        staff: companyStaffCopy,
      };
      const preSignUrlOption = {
        id: company.id,
        logo: avatarName,
        folder: 'companyStaff',
      };
      getPreSignUrl(preSignUrlOption);
      // setAvatar(null);
      // setFiles(null);
      setCompany(updatedCompany);
      setAllCompanyStaffSorted(allCompanyStaffSortedCopy);
      sendCompanyUpdated(updatedCompany, true);
      if (updatedStaff.notDeletedServices) {
        const alert = getServicesNotDeletedAlertContent(updatedStaff.notDeletedServices);
        setAlert(alert);
      }
      setOpenSnackbar(true);
      setSnackbarMessage('Staff Updated Successfully');
      setSnackbarType('success');
    }
  }, [isUpdateStaffSuccess]);
  // Handle staff update is success
  useEffect(() => {
    if (isUpdateStaffError) {
      setOpenSnackbar(true);
      setSnackbarMessage(updateStaffErrorMessage);
      setSnackbarType('error');
    }
  }, [isUpdateStaffError]);
  // Show add staff modal
  useEffect(() => {
    if (prevIsAddStaffPerformed === false && isAddStaffPerformed) {
      setIsAddStaffModalShown(true);
      setStaffItem({});
      setAvatar(null);
      setSelectedProfessionOption('');
    }
  }, [isAddStaffPerformed]);
  // Show invite staff modal
  useEffect(() => {
    if (isInviteStaffPerformed) {
      setInvitationEmailStaffText('');
      setIsInviteNewStaffModalShown(true);
    }
  }, [isInviteStaffPerformed]);

  useEffect(() => {
    if (isDeleteStaffInvitationSuccess) {
      const companyCopy = { ...company };
      const selectedStaffIndex = companyCopy.staff.findIndex((item) => item.id === selectedInvitationId);
      const selectedInvitationIndex = companyCopy.invitations.findIndex((item) => item.id === selectedInvitationId);
      selectedStaffIndex !== -1 ? companyCopy.staff[selectedStaffIndex].staff_invitations.length = 0 : null;
      selectedInvitationIndex !== -1 ? companyCopy.invitations.splice([selectedInvitationIndex], 1) : null;
      setCompany(companyCopy);
      setAnchorEl(null);
      setAnchorOpen(null);
      setSelectedInvitationId(false);
      sendCompanyUpdated(companyCopy, true);
      setIsDeleteStaffOrStaffInvitationModalShown(false);
      setOpenSnackbar(true);
      setSnackbarMessage(i18n.t('InvitationDeleted'));
      setSnackbarType('success');
    }
  }, [isDeleteStaffInvitationSuccess]);

  useEffect(() => {
    if (isDeleteStaffInvitationError) {
      setOpenSnackbar(true);
      setSnackbarMessage(deleteStaffInvitationErrorMessage);
      setSnackbarType('error');
    }
  }, [isDeleteStaffInvitationError]);

  useEffect(() => {
    if (isGetStaffPositionsSuccess) {
      const positionOptions = [];
      positions.map((position) => {
        positionOptions.push({
          value: position.id,
          label: position.name,
        });
        return true;
      });
      const selectedPositionOption = positionOptions[positionOptions.length - 1];
      setPositionOptions(positionOptions);
      setSelectedPositionOption(selectedPositionOption.value);
    }
  }, [isGetStaffPositionsSuccess]);

  useEffect(() => {
    if (isGetStaffPositionsError) {
      const positionOptions = [];
      positions.map((position) => {
        positionOptions.push({
          value: position.id,
          label: position.name,
        });
        return true;
      });
      const selectedPositionOption = positionOptions[0];
      setPositionOptions(positionOptions);
      setSelectedPositionOption(selectedPositionOption.value);
    }
  }, [isGetStaffPositionsError]);

  useEffect(() => {
    if (previousIsGetProfessionsSuccess === false && isGetProfessionsSuccess) {
      const professionOptions = [];
      professions.map((prof) => {
        professionOptions.push({
          value: prof.id,
          label: prof.name,
        });
        return true;
      });
      setProfessionOptions(professionOptions);
    }
  }, [isGetProfessionsSuccess]);

  useEffect(() => {
    if (isUpdateStaffPermissionsSuccess) {
      const { staff } = company;
      const staffCopy = [...staff];
      staffCopy.find((item) => (item.id === parseInt(updatedPagesAccess.staff_id))).pagesAccess = updatedPagesAccess.pagesAccess;
      setCompany({
        ...company,
        staff: staffCopy,
      });
      setSelectedStaffPermissions([]);
      setIsEditPermissionsModalShown(false);
      setOpenSnackbar(true);
      setSnackbarMessage('Staff Permissions Updated Successfully');
      setSnackbarType('error');
    }
  }, [isUpdateStaffPermissionsSuccess]);

  useEffect(() => {
    if (isUpdateStaffPermissionsError) {
      setIsEditPermissionsModalShown(false);
      setSelectedStaffPermissions([]);
      setOpenSnackbar(true);
      setSnackbarMessage(updateStaffPermissionsErrorMessage);
      setSnackbarType('error');
    }
  }, [isUpdateStaffPermissionsError]);

  useEffect(() => {
    if (isInvitedExistingStaffSuccess) {
      if (staffInvitation.staffExists) {
        const { existingStaff } = staffInvitation;
        const alert = getExistingUserAlertContent(existingStaff, () => handleInviteExistingStaff(true, existingStaff), handleCancelConfirmation);
        setAlert(alert);
      } else {
        const { staff } = company;
        const staffCopy = [...staff];
        staffCopy.map((item) => {
          if (item.id === staffInvitation.staff_id) {
            item.staff_invitations.push(staffInvitation);
          }
        });
        setCompany({
          ...company,
          staff: staffCopy,
        });
        company.invitations.push(staffInvitation);
        setStaffItem({
          position_id: '',
          first_name: '',
          last_name: '',
          email: null,
          is_active: true,
          staff_invitations: [],
        });
        setOpenSnackbar(true);
        setSnackbarMessage(i18n.t('InvitationSent'));
        setSnackbarType('success');
      }
    }
  }, [isInvitedExistingStaffSuccess]);

  useEffect(() => {
    if (isInvitedExistingStaffError) {
      setOpenSnackbar(true);
      setSnackbarMessage(isInvitedExistingStaffErrorMessage);
      setSnackbarType('error');
    }
  }, [isInvitedExistingStaffError]);

  useEffect(() => {
    if (isDeleteStaffSuccess) {
      const id = lastDeleteStaffId;
      // const staffFiltered = company.staff.filter((item) => item.id !== id);
      const staffFiltered = allCompanyStaffSorted.filter((item) => item.id !== id);
      const invitationsFiltered = company.invitations.filter((item) => item.staff_id !== id);
      const companyStaff = allCompanyStaffSorted.filter((element) => element.id !== id);
      setAllCompanyStaffSorted(companyStaff);
      const companyUpdated = {
        ...company,
        staff: staffFiltered,
        invitations: invitationsFiltered,
      };
      setCompany(companyUpdated);
      sendCompanyUpdated(companyUpdated, true);
      setOpenSnackbar(true);
      setSnackbarMessage('Staff Deleted Successfully');
      setSnackbarType('success');
      setLastDeleteStaffId(false);
      setIsDeleteStaffOrStaffInvitationModalShown(false);
    }
  }, [isDeleteStaffSuccess]);

  useEffect(() => {
    if (isDeleteStaffError) {
      if (deleteStaffErrorMessage === 'confirm_appointments_delete') {
        const alert = pendingAppointmentsInfoModal();
        setAlert(alert);
      } else {
        setOpenSnackbar(true);
        setSnackbarMessage(deleteStaffErrorMessage);
        setSnackbarType('error');
      }
    }
  }, [isDeleteStaffError]);

  // Handle Resend Invitation success
  useEffect(() => {
    if (previousIsResendStaffInvitationSuccess === false && isResendStaffInvitationSuccess) {
      setIsOpenResendDialog(false);
      setLineProgress(false);
      setOpenSnackbar(true);
      setSnackbarMessage(i18n.t('EmailResent'));
      setSnackbarType('success');
    }
  }, [isResendStaffInvitationSuccess]);

  const getServicesNotDeletedAlertContent = (notDeletedServices) => (
    <Dialog
      open={() => ({})}
      onClose={handleCancelConfirmation}
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>Services not deleted</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={() => setAlert(null)}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          Services are not deleted, as staff has pending appointments with these
          services.
        </Typography>
        <Typography gutterBottom>Please finish the appointments, then delete the services.</Typography>
        {notDeletedServices.map((item) => (
          <span key={item.id}>{item.name}</span>
        ))}
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => setAlert(null)}
        >
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );

  const pendingAppointmentsInfoModal = () => (
    <Dialog
      open={() => ({})}
      onClose={() => setAlert(null)}
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>Delete Staff</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={() => setAlert(null)}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>You can not delete this staff.</Typography>
        <Typography gutterBottom>There are upcoming appointments assigned to this staff.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setAlert(null)}
        >
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );

  const getExistingUserAlertContent = (existingUser) => {
    setExistingUser(existingUser);
    setIsOpenExistingModal(true);
  };

  const handleEditStaff = (id) => {
    let staffItem;
    let selectedStaffPermissions = {};
    if (selectedStaffFilter.value === 'staff') {
      if (selectedStaff.pagesAccess) {
        const permissions = JSON.parse(staffItem.pagesAccess);
        selectedStaffPermissions = Object.entries(permissions)
          .filter(([name, value]) => (name !== 'company_id' ? ({ name, ...value }) : false));
      }
      if (selectedStaff.avatar) {
        setAvatar(selectedStaff.avatar);
        setFiles(selectedStaff.avatar);
      }
      if (selectedStaff.staff_company_positions) {
        const selectedPosition = selectedStaff.staff_company_positions[0].id;
        setSelectedPositionOption(selectedPosition);
      }
      if (selectedStaff.staff_company_professions.length) {
        const selectedProfession = selectedStaff.staff_company_professions[0].id;
        setSelectedProfessionOption(selectedProfession);
      }
      setStaffItem(selectedStaff);
      setIsAddStaffModalShown(true);
    } else {
      staffItem = company.invitations.find((item) => item.id === id);
      staffItem.is_active = true;
      setStaffItem(staffItem);
      setIsInviteNewStaffModalShown(true);
    }
    setAnchorEl(false);
    setSelectedStaffPermissions(selectedStaffPermissions);
  };

  const handleCloseAddStaffModal = () => {
    setIsAddStaffModalShown(false);
    closeAddStaffModal();
    setStaffErrors({
      first_name: false,
      last_name: false,
      email: false,
      phone_number: false,
      service: false,
    });
    setFiles(null);
    setAvatar(null);
    setAddStaffErrors({});
    setCheckedLogin(false);
    setStaffItem({});
    setSelectedProfessionOption('');
  };

  const handleCloseInviteStaffDialog = () => {
    setIsOpenInviteStaffDialog(false);
    setAddStaffErrors({});
    setCheckedLogin(false);
    setStaffItem({});
  };

  const handleStaffInputChange = (event) => {
    const {
      name,
      value,
    } = event.target;
    setStaffItem({
      ...staffItem,
      [name]: value.trim() !== '' ? value : '',
    });
  };

  const handleAddStaff = () => {
    if (validateAddStaffForm()) {
      const staffItemCopy = {
        ...staffItem,
        position_id: selectedPositionOption,
        profession_id: selectedPositionOption === 1 ? null : selectedProfessionOption,
        staff_id: staffItem.id,
        email: staffItem.email || null,
        full_phone_number: (`${staffItem.phone_code} ${staffItem.phone_number}`).replace(/\D/g, ''),
      };
      setButtonLoading(false);
      setStaffItem(staffItemCopy);
      closeAddStaffModal();
      addStaff(staffItemCopy);
    }
  };

  const validateAddStaffForm = () => {
    const {
      first_name,
      last_name,
      phone_code,
      profession_id,
      phone_number,
    } = staffItem;
    const errors = {
      firstName: !first_name ? t('FirstName') + t('IsRequired') : null,
      lastName: !last_name ? t('LastName') + t('IsRequired') : null,
      profession_id: selectedPositionOption === 2 && !profession_id ? t('Profession') + t('IsRequired') : null,
      phone_number: !phone_number ? t('PhoneNumber') + t('IsRequired') : phone_number.length < 12 ? t('PhoneNumber') + t('IsNotValid') : null,
      phone_code: !phone_code ? t('Code') + t('IsRequired') : null,
    };
    setAddStaffErrors(errors);
    return Object.values(errors)
      .filter((error) => (error !== null)).length === 0;
  };

  const handleInviteNewStaff = (identified = false) => {
    if (validateInviteNewStaffForm()) {
      const staffItemCopy = {
        ...staffItem,
        position_id: selectedPositionOption,
        profession_id: selectedPositionOption === 1 ? null : selectedProfessionOption,
        staffIdentified: identified && 'allowed',
      };
      if (userAccount.email === staffItemCopy.email) {
        setInviteYourselfError(true);
        setInvitationEmailStaffText('');
        return false;
      }
      setInviteYourselfError(false);
      setStaffItem(staffItemCopy);
      const invitationExists = company.invitations.find(
        (item) => item.email === staffItemCopy.email && item.id !== staffItemCopy.id,
      );
      if (!invitationExists) {
        setIsInviteNewStaffModalShown(true);
        inviteStaff(staffItemCopy);
      } else {
        setInvitationEmailStaffText('Invitation with this email already exists');
      }
    }
  };

  const handleInviteCurrentStaff = (e, id, identified = false) => {
    const selectedStaff = allCompanyStaffSorted.filter((item) => item.id === id);
    if (validateInviteNewStaffForm()) {
      const staffItemCopy = {
        ...staffItem,
        position_id: selectedPositionOption,
        profession_id: staffItem.profession_id,
        phone_code: staffItem.phone_code,
        phone_number: staffItem.phone_number,
        staffIdentified: identified && 'allowed',
        staff_id: id,
      };
      if (userAccount.email === selectedStaff.email) {
        setInviteYourselfError(true);
        setInvitationEmailStaffText('');
        return false;
      }
      setButtonLoading(false);
      setInviteYourselfError(false);
      setStaffItem(staffItemCopy);
      const invitationExists = company.invitations.find(
        (item) => item.email === selectedStaff.email && item.id !== selectedStaff.id,
      );
      if (!invitationExists) {
        inviteStaff(staffItemCopy);
      } else {
        setInvitationEmailStaffText('Invitation with this email already exists');
      }
    }
  };

  const validateInviteNewStaffForm = (id) => {
    const {
      first_name,
      last_name,
      email,
      phone_code,
      phone_number,
      profession_id,
    } = staffItem;
    const errors = {
      firstName: id ? null : !first_name ? t('Firstname') + t('IsRequired') : null,
      lastName: id ? null : !last_name ? t('LastName') + t('IsRequired') : null,
      profession_id: id ? null : selectedPositionOption === 2 && !profession_id ? t('Profession') + t('IsRequired') : null,
      email: id ? null : !email ? t('Email') + t('IsRequired') : null,
      phone_number: id ? null : !phone_number ? t('PhoneNumber') + t('IsRequired') : phone_number.length < 12 ? t('PhoneNumber') + t('IsNotValid') : null,
      phone_code: id ? null : !phone_code ? t('Code') + t('IsRequired') : null,
    };
    setInviteYourselfError(false);
    setInvitationEmailStaffText('');
    setInviteStaffErrors(errors);
    return Object.values(errors)
      .filter((error) => (error !== null)).length === 0;
  };

  const handleUpdateStaff = () => {
    if (validateStaffForm('', true)) {
      const logo = moment().format('DDMMYYYYHHmmss') + avatar.name;
      setAvatarName(logo);
      const staffItemCopy = {
        ...selectedStaff,
        aws_avatar: logo,
        position_id: selectedPositionOption,
        profession_id: selectedProfessionOption,
      };
      setStaffItem(staffItemCopy);
      updateStaff(staffItemCopy);
    }
  };

  const validateStaffForm = (name, all = false) => {
    const errorsCopy = { ...staffErrors };
    const {
      first_name,
      last_name,
      email,
      is_active,
      phone_number,
    } = staffItem;
    if (all) {
      errorsCopy.first_name = first_name.length === 0;
      errorsCopy.last_name = last_name.length === 0;
      errorsCopy.phone_number = phone_number.length === 0 ? t('PhoneNumber') + t('IsRequired') : phone_number.length < 12 ? t('PhoneNumber') + t('IsNotValid') : false;
      errorsCopy.email = staffItem.email ? email.length === 0 : false;
      setStaffErrors(errorsCopy);
      return (
        first_name.length !== 0
        && last_name.length !== 0
        && (phone_number.length !== 0 && phone_number.length >= 12)
        && (is_active ? email.length === 0 : true)
      );
    }
    const value = staffItem[name];
    errorsCopy[name] = value.length === 0;
    setStaffErrors(errorsCopy);
  };

  const handleInviteExistingStaff = (identified = null, existingStaff = null) => {
    if (validateInviteStaffForm()) {
      const {
        id: staff_id,
        email,
      } = staffItem;
      // const { id } = company;
      inviteExistingStaff({
        staff_id,
        staffIdentified: identified && 'allowed',
        position_id: staffItem.position_id,
        phone_code: staffItem.phone_code,
        phone_number: staffItem.phone_number,
        first_name: staffItem.first_name,
        last_name: staffItem.last_name,
        email,
      });
      if (identified) {
        const companyStaff = company.staff.find((item) => (item.id === staff_id));
        companyStaff.first_name = existingStaff.first_name;
        companyStaff.last_name = existingStaff.last_name;
        setAlert(null);
      }
    }
  };

  const validateInviteStaffForm = () => {
    const {
      first_name,
      last_name,
      email,
      profession_id,
    } = staffItem;
    const errors = {
      firstName: first_name.length === 0 ? t('FirstName') + t('IsRequired') : null,
      lastName: last_name.length === 0 ? t('LastName') + t('IsRequired') : null,
      profession_id: profession_id.length === 0 ? t('Profession') + t('IsRequired') : null,
      email: email === null ? t('Email') + t('IsRequired') : null,
    };
    setInviteStaffErrors(errors);
    return Object.values(errors)
      .filter((error) => (error !== null)).length === 0;
  };

  const handlePermissionsChange = (e) => {
    const selectedStaffPermissionsCopy = [...selectedStaffPermissions];
    const {
      name,
      checked,
    } = e.target;
    const index = selectedStaffPermissionsCopy.findIndex((item) => item[0] === name);
    selectedStaffPermissionsCopy[index][1].status = checked ? 1 : 0;
    setSelectedStaffPermissions(selectedStaffPermissionsCopy);
  };

  const handleUpdateStaffPermissions = () => {
    const permissions = Object.fromEntries(selectedStaffPermissions);
    const data = {
      id: staffItem.id,
      permission_id: staffItem.staff_company_permissions.id,
      permissions,
    };
    updateStaffPermissions(data);
  };

  const handleEditPermissions = (id) => {
    let selectedStaffPermissions = {};
    const staffItem = company.staff.find((item) => item.id === id);
    if (staffItem.staff_company_permissions) {
      if (!staffItem.pagesAccess) {
        setOpenSnackbar(true);
        setSnackbarMessage('Unable to get staff permissions!');
        setSnackbarType('error');
        return false;
      }
      const permissions = (JSON.parse(staffItem.pagesAccess));
      selectedStaffPermissions = Object.entries(permissions)
        .filter(([name, value]) => (name !== 'company_id' ? ({ name, ...value }) : false));
    }
    setStaffItem(staffItem);
    setIsEditPermissionsModalShown(true);
    setSelectedStaffPermissions(selectedStaffPermissions);
  };

  const handleInviteStaff = (id) => {
    const staffItem = company.staff.find((item) => item.id === id);
    setStaffItem(staffItem);
  };

  const handleDeleteStaff = (id) => {
    setLastDeleteStaffId(id);
    const data = {
      staffId: id,
      companyId: company.id,
      isConfirmed: true,
    };
    deleteStaff(data);
  };

  const handleDeleteStaffInvitation = (id, email) => {
    let invitedId = '';
    if (email) {
      const [invitation] = allCompanyStaffSorted.filter((item) => item.id === id);
      invitedId = invitation.id;
    } else {
      const [selectedStaffInvitation] = company.staff.filter((item) => item.id === id);
      const [staffInvited] = selectedStaffInvitation.staff_invitations;
      invitedId = staffInvited.id;
    }
    deleteStaffInvitation({ id: invitedId });
  };

  const handleEditStaffInvitation = (staffId, email) => {
    let staffInvitation;
    if (email) {
      const [staffItem] = company.invitations.filter((item) => item.id === staffId);
      staffInvitation = staffItem;
    } else {
      const [staffItem] = company.staff.filter((item) => item.id === staffId);
      [staffInvitation] = staffItem.staff_invitations;
    }
    setStaffItem(staffInvitation);
    setIsInviteNewStaffModalShown(true);
  };

  const handlePreviewStaffInfo = (id, selectedItem) => {
    const [selectedStaff] = company.staff.filter((item) => item.id === id);
    const staffAppointments = selectedItem.staff_id ? selectedStaff.staff_appointments && selectedStaff.staff_appointments.length
      ? selectedStaff.staff_appointments : [] : selectedItem.staff_appointments || [];
    const appointments = staffAppointments.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
    const services = selectedStaff.company_staff_service;
    const customerIds = staffAppointments.length ? staffAppointments.map((item) => item.customer_id) : [];
    const selectedCustomer = company.customers.filter((item) => customerIds.includes(item.id));
    const customerName = selectedCustomer.length ? selectedCustomer[0].full_name : null;
    const positionName = selectedStaff.staff_company_positions ? selectedStaff.staff_company_positions[0].name : selectedItem.staff_company_positions[0].name;
    const professionName = selectedStaff.staff_company_professions ? selectedStaff.staff_company_professions.length ? selectedStaff.staff_company_professions[0].name : '' : '';
    setPreviewStaffItem(
      {
        ...selectedItem,
        appointments,
        positionName,
        professionName,
        customerName,
        isInvited: false,
        services,
      },
    );
    setIsPreviewStaffModalInfo(true);
  };

  const handlePreviewInvitationInfo = (id) => {
    const [selectedStaff] = company.invitations.filter((item) => item.id === id);
    const positionName = selectedStaff.position.name;
    const professionName = selectedStaff.profession_id ? professions.filter((item) => item.id === selectedStaff.profession_id)[0].name : '';
    setPreviewStaffItem(
      {
        ...selectedStaff,
        positionName,
        professionName,
        isInvited: true,
      },
    );
    setIsPreviewStaffModalInfo(true);
  };
  const handleUpdateAvatar = (file) => {
    setFiles(URL.createObjectURL(file[0]));
    setAvatar(file[0]);
    const logo = moment().format('DDMMYYYYHHmmss') + file[0].name;
    setAvatarName(logo);
    setStaffItem({
      ...staffItem,
      aws_avatar: logo,
    });
  };
  const removeUserAvatar = () => {
    setFiles(null);
    setAvatar(null);
    setStaffItem({
      ...staffItem,
      aws_avatar: null,
    });
  };
  const handleCancelConfirmation = () => {
    setAlert(null);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  const changePosition = (event) => {
    const { value } = event.target;
    setSelectedPositionOption(value);
    setStaffItem({
      ...staffItem,
      position_id: value,
    });
  };

  const changeProfession = (event) => {
    const { value } = event.target;
    setSelectedProfessionOption(value);
    setStaffItem({
      ...staffItem,
      profession_id: value,
    });
  };

  const handleClickOpenMenu = (e, id) => {
    setSelectedInvitationId(id);
    setAnchorOpen(e.currentTarget);
  };

  const handleCloseOpenMenu = () => {
    setAnchorOpen(null);
  };

  const closeStaffDeleteOrStaffInvitationModal = () => {
    setIsDeleteStaffOrStaffInvitationModalShown(false);
  };

  const openDeleteAlert = (id, email, invited = false) => {
    if (email) {
      setStaffItem(selectedStaff);
      setSelectedInvitationId(selectedStaff.staff_id || selectedStaff.id);
    } else {
      setStaffItem(selectedStaff);
    }
    setAnchorOpen(false);
    setAnchorEl(false);
    setIsDeleteStaffOrStaffInvitationModalShown(true);
    setIsInvitedStaff(invited);
    setSelectedStaffOrStaffInvitationId(selectedStaff.id);
  };

  const openAddServicesModal = (id) => {
    let staffItem;
    let selectedStaffPermissions = {};
    if (selectedStaffFilter.value === 'staff') {
      const staffItem = company.staff.find((item) => item.id === id);
      if (staffItem.pagesAccess) {
        const permissions = JSON.parse(staffItem.pagesAccess);
        selectedStaffPermissions = Object.entries(permissions)
          .filter(([name, value]) => (name !== 'company_id' ? ({ name, ...value }) : false));
      }
      if (staffItem.staff_company_positions) {
        const selectedPosition = positionOptions.find((item) => (item.value === staffItem.staff_company_positions[0].id));
        setSelectedPositionOption(selectedPosition.value);
      }
      setStaffItem(staffItem);
    } else {
      staffItem = company.invitations.find((item) => item.id === id);
      staffItem.is_active = true;
      setStaffItem(staffItem);
    }
    setSelectedStaffPermissions(selectedStaffPermissions);
  };

  const handleCountryCodeSelectChange = (event) => {
    const codeId = parseInt(event.target.value);
    const phoneOption = codeOptions.find((item) => parseInt(item.value) === codeId);
    setSelectedCodeValue(phoneOption.value);
    setStaffItem({
      ...staffItem,
      phone_code: phoneOption.value,
    });
  };

  const handlePhoneNumberChange = (event) => {
    const phone = event.target.value;
    setStaffItem({
      ...staffItem,
      phone_number: phone,
    });
  };

  const handleChangeCustomerLogin = (event) => {
    setCheckedLogin(event.target.checked);
  };

  const handleInviteStaffDialog = (e, item) => {
    if (validateInviteNewStaffForm(item.id)) {
      const [selectedCompanyStaff] = company.staff.filter((i) => i.id === item.id);
      const staffItemCopy = {
        ...selectedCompanyStaff,
        phone_code: selectedCompanyStaff.phone_code,
        phone_number: selectedCompanyStaff.phone_number,
        position_id: selectedCompanyStaff.staff_company_positions.length ? selectedCompanyStaff.staff_company_positions[0].id : null,
        profession_id: selectedCompanyStaff.staff_company_professions.length ? selectedCompanyStaff.staff_company_professions[0].id : null,
        email: '',
      };
      if (userAccount.email === selectedCompanyStaff.email) {
        setInviteYourselfError(true);
        setInvitationEmailStaffText('');
        return false;
      }
      setInviteYourselfError(false);
      setStaffItem(staffItemCopy);
      setCheckedLogin(true);
      setIsOpenInviteStaffDialog(true);
    }
  };

  const handleOpenResendInvitationDialog = (selectedInvitationId) => {
    const selectedStaff = allCompanyStaffSorted.find((item) => item.id === selectedInvitationId);
    setAnchorOpen(false);
    setStaffItem(selectedStaff);
    setIsOpenResendDialog(true);
  };

  const handleCloseResendInvitationDialog = () => {
    setIsOpenResendDialog(false);
  };

  const handleResendInviteStaffFormSubmit = (e, staffItem) => {
    const resendInvitationItemCopy = {
      id: staffItem.id,
      email: staffItem.email,
    };
    setAnchorOpen(null);
    setLineProgress(true);
    resendStaffInvitation(resendInvitationItemCopy);
  };

  const handleClosePreviewModal = () => {
    setIsPreviewStaffModalInfo(false);
    setTabValue(0);
  };

  return (
    <>
      {!loading
        ? (
          <div id="tab-main-parent" className="tab-customers">
            <div className="main-content">
              <Box display="flex" flexWrap="wrap" justifyContent="flex-start" width="100%">
                <Tooltip title={i18n.t('AddStaff')}>
                  <Card className="card-plus-box" onClick={(e) => handleOpenAddStaffModal(e)}>
                    <CardMedia className="img-header" />
                  </Card>
                </Tooltip>
                {allCompanyStaffSorted.length ? allCompanyStaffSorted.map((item, index) => (
                  [
                    item.staff_id === null
                      ? (
                        <span id={item.id} key={item.id}>
                          <Card className="card-plus-box" key={`${item.id}-${index}`}>
                            <Card className="card-box-root">
                              <CardHeader
                                className="card-header-service"
                                onClick={() => setSelectedStaff(item)}
                                action={(
                                  <StaffInvitationIsolatedDropdown
                                    handleEditStaff={handleEditStaff}
                                    staffItem={item}
                                    isUpdateStaffSuccess={isUpdateStaffSuccess}
                                    handleEditPermissions={handleEditPermissions}
                                    handleEditStaffInvitation={handleEditStaffInvitation}
                                    handleInviteStaff={handleInviteStaff}
                                    handleCancelStaff={openDeleteAlert}
                                    handleAddServices={openAddServicesModal}
                                    anchorEl={anchorEl}
                                  />
                                )}
                                title={(
                                  <LightTooltip
                                    title={`${item.first_name} ${item.last_name}`}
                                    placement="top-start"
                                  >
                                    <span>
                                      {`${item.first_name} ${item.last_name}`}
                                    </span>
                                  </LightTooltip>
                                )}
                              />
                              <CardMedia className="img-header" onClick={() => handlePreviewInvitationInfo(item.id, item)}>
                                {item && item.avatar ? (
                                  <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                ) : (
                                  <Badge
                                    color="secondary"
                                    overlap="circle"
                                    className="avatar-badge"
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <Avatar variant="circle" className="card-box-img card-box-letters">
                                      {firstLetter(item.first_name, item.last_name)}
                                    </Avatar>
                                  </Badge>
                                )}
                              </CardMedia>
                              <CardContent className="content-card-box">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  className="invited-menu-btn"
                                  onClick={(e) => handleClickOpenMenu(e, item.id)}
                                  endIcon={<ExpandMoreIcon />}
                                >
                                  Invited
                                </Button>
                                <StyledMenu
                                  id="customized-menu"
                                  anchorEl={anchorOpen}
                                  keepMounted
                                  open={anchorOpen}
                                  onClose={handleCloseOpenMenu}
                                >
                                  <StyledMenuItem onClick={() => openDeleteAlert(selectedInvitationId, item.email)}>
                                    <ListItemText primary={t('Cancel')} />
                                  </StyledMenuItem>
                                  <StyledMenuItem
                                    onClick={() => handleOpenResendInvitationDialog(selectedInvitationId, item)}
                                  >
                                    <ListItemText primary="Resend" />
                                  </StyledMenuItem>
                                </StyledMenu>
                              </CardContent>
                            </Card>
                          </Card>
                        </span>
                      )
                    // Registered Staff
                      : item.email_verified_at
                        ? (
                          <span id={item.id} key={item.id}>
                            <Box item="true" m="15px" maxWidth="220px" key={`${item.id}-${index}`}>
                              <Card className="card-box-root">
                                <CardHeader
                                  className="card-header-service"
                                  onClick={() => setSelectedStaff(item)}
                                  action={(
                                    <>
                                      <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={(event) => setAnchorEm(event.currentTarget)}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id="long-menu"
                                        elevation={1}
                                        anchorEl={anchorEm}
                                        keepMounted
                                        open={anchorEm}
                                        onClose={() => setAnchorEm(null)}
                                        PaperProps={{
                                          style: {
                                            width: '20ch',
                                          },
                                        }}
                                      >
                                        <Link
                                          to="#"
                                          onClick={() => openDeleteAlert(item.id)}
                                          className="card-box-item"
                                        >
                                          <MenuItem>
                                            {t('Delete')}
                                          </MenuItem>
                                        </Link>
                                      </Menu>
                                    </>
                              )}
                                  title={(
                                    <LightTooltip
                                      title={`${item.first_name} ${item.last_name}`}
                                      placement="top-start"
                                    >
                                      <span>
                                        {`${item.first_name} ${item.last_name}`}
                                      </span>
                                    </LightTooltip>
                              )}
                                />
                                <CardMedia className="img-header" onClick={() => handlePreviewStaffInfo(item.id, item)}>
                                  {item && item.avatar ? (
                                    <Badge
                                      color="secondary"
                                      overlap="circle"
                                      className="avatar-badge"
                                      badgeContent={item.email_verified_at ? <CheckCircleOutlineIcon /> : ''}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                    >
                                      <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                    </Badge>
                                  ) : (
                                    <Badge
                                      color="secondary"
                                      overlap="circle"
                                      className="avatar-badge"
                                      badgeContent={item.email_verified_at ? <CheckCircleOutlineIcon /> : ''}
                                      anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                      }}
                                    >
                                      <Avatar variant="circle" className="card-box-img card-box-letters">
                                        {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                      </Avatar>
                                    </Badge>
                                  )}
                                </CardMedia>
                              </Card>
                            </Box>
                          </span>
                        )
                      // Added Staff
                        : item.email === null
                          ? (
                            <span id={item.id} key={item.id}>
                              <Box item="true" m="15px" maxWidth="220px" key={`${item.id}-${index}`}>
                                <Card className="card-box-root">
                                  <CardHeader
                                    className="card-header-service"
                                    onClick={() => setSelectedStaff(item)}
                                    action={(
                                      <StaffIsolatedDropdown
                                        handleEditStaff={handleEditStaff}
                                        staffItem={item}
                                        isUpdateStaffSuccess={isUpdateStaffSuccess}
                                        handleEditPermissions={handleEditPermissions}
                                        handleEditStaffInvitation={handleEditStaffInvitation}
                                        handleInviteStaff={handleInviteStaff}
                                        handleDeleteStaff={openDeleteAlert}
                                        handleAddServices={openAddServicesModal}
                                        anchorEl={anchorEl}
                                        setAnchorEl={setAnchorEl}
                                      />
                                )}
                                    title={(
                                      <LightTooltip
                                        title={`${item.first_name} ${item.last_name}`}
                                        placement="top-start"
                                      >
                                        <span>
                                          {`${item.first_name} ${item.last_name}`}
                                        </span>
                                      </LightTooltip>
                                )}
                                  />
                                  <CardMedia className="img-header" onClick={() => handlePreviewStaffInfo(item.id, item)}>
                                    {item && item.avatar ? (
                                      <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                    ) : (
                                      <Badge
                                        color="secondary"
                                        overlap="circle"
                                        className="avatar-badge"
                                        badgeContent={item.email_verified_at ? <CheckCircleOutlineIcon /> : ''}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        }}
                                      >
                                        <Avatar variant="circle" className="card-box-img card-box-letters">
                                          {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                        </Avatar>
                                      </Badge>
                                    )}
                                  </CardMedia>
                                  <CardContent className="content-card-box">
                                    <Button
                                      color="primary"
                                      size="small"
                                      className="invite-btn"
                                      onClick={(e) => handleInviteStaffDialog(e, item)}
                                    >
                                      Invite
                                    </Button>
                                  </CardContent>
                                </Card>
                              </Box>
                            </span>
                          )
                        // Staff with invitations
                          : item.staff_id
                            ? (
                              <span id={item.id} key={item.id}>
                                <Card className="card-plus-box" key={`${item.id}-${index}`}>
                                  <Card className="card-box-root">
                                    <CardHeader
                                      className="card-header-service"
                                      onClick={() => setSelectedStaff(item)}
                                      action={(
                                        <StaffInvitationIsolatedDropdown
                                          handleEditStaff={handleEditStaff}
                                          staffItem={item}
                                          isUpdateStaffSuccess={isUpdateStaffSuccess}
                                          handleEditPermissions={handleEditPermissions}
                                          handleEditStaffInvitation={handleEditStaffInvitation}
                                          handleInviteStaff={handleInviteStaff}
                                          handleCancelStaff={openDeleteAlert}
                                          handleAddServices={openAddServicesModal}
                                          anchorEl={anchorEl}
                                        />
                                  )}
                                      title={(
                                        <LightTooltip
                                          title={`${item.first_name} ${item.last_name}`}
                                          placement="top-start"
                                        >
                                          <span>
                                            {`${item.first_name} ${item.last_name}`}
                                          </span>
                                        </LightTooltip>
                                  )}
                                    />
                                    <CardMedia className="img-header" onClick={() => handlePreviewStaffInfo(item.staff_id, item)}>
                                      {item && item.avatar ? (
                                        <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                      ) : (
                                        <Badge
                                          color="secondary"
                                          overlap="circle"
                                          className="avatar-badge"
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                          }}
                                        >
                                          <Avatar variant="circle" className="card-box-img card-box-letters">
                                            {firstLetter(item.first_name, item.last_name)}
                                          </Avatar>
                                        </Badge>
                                      )}
                                    </CardMedia>
                                    <CardContent className="content-card-box">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className="invited-menu-btn"
                                        onClick={(e) => handleClickOpenMenu(e, item.id)}
                                        endIcon={<ExpandMoreIcon />}
                                      >
                                     Invited
                                      </Button>
                                      <StyledMenu
                                        id="customized-menu"
                                        anchorEl={anchorOpen}
                                        keepMounted
                                        open={anchorOpen}
                                        onClose={handleCloseOpenMenu}
                                      >
                                        <StyledMenuItem onClick={() => openDeleteAlert(selectedInvitationId, item.email)}>
                                          <ListItemText primary={t('Cancel')} />
                                        </StyledMenuItem>
                                        <StyledMenuItem
                                          onClick={() => handleOpenResendInvitationDialog(selectedInvitationId, item)}
                                        >
                                          <ListItemText primary="Resend" />
                                        </StyledMenuItem>
                                      </StyledMenu>
                                    </CardContent>
                                  </Card>
                                </Card>
                              </span>
                            )
                            : null,
                  ]
                ))
                  // When Company don't have any Staff
                  : (
                    <Box className="staff-page" mx="auto">
                      <Box>
                        <Box>
                          <img src={noStaff} alt="noStaff" />
                        </Box>
                        <Typography component="div">
                          <Box fontWeight="500" align="center">
                            <Typography variant="h6" color="secondary">
                              No staff yet
                            </Typography>
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                  )}
              </Box>
            </div>
          </div>
        )
        : <StaffLoading />}

      {/* Add/Edit inactive staff modal */}
      <AddStaff
        removeUserAvatar={removeUserAvatar}
        handleUpdateAvatar={handleUpdateAvatar}
        setStaffItem={setStaffItem}
        files={files}
        avatar={avatar}
        open={isAddStaffModalShown}
        staffItem={staffItem}
        codeOptions={codeOptions}
        staffErrors={staffErrors}
        checkedLogin={checkedLogin}
        buttonLoading={buttonLoading}
        addStaffErrors={addStaffErrors}
        handleAddStaff={handleAddStaff}
        positionOptions={positionOptions}
        onClose={handleCloseAddStaffModal}
        handleUpdateStaff={handleUpdateStaff}
        selectedCodeValue={selectedCodeValue}
        inviteStaffErrors={inviteStaffErrors}
        professionOptions={professionOptions}
        handleChangePosition={changePosition}
        inviteYourselfError={inviteYourselfError}
        handleChangeProfession={changeProfession}
        selectedPositionOption={selectedPositionOption}
        handleStaffInputChange={handleStaffInputChange}
        handlePhoneNumberChange={handlePhoneNumberChange}
        selectedProfessionOption={selectedProfessionOption}
        invitationEmailStaffText={invitationEmailStaffText}
        handleInviteCurrentStaff={handleInviteCurrentStaff}
        handleChangeCustomerLogin={handleChangeCustomerLogin}
        handleCountryCodeSelectChange={handleCountryCodeSelectChange}
      />
      {/* Edit staff permissions modal */}
      <PermissionsModal
        open={isEditPermissionsModalShown}
        onClose={() => setIsEditPermissionsModalShown(false)}
        toUpperCaseEachWord={toUpperCaseEachWord}
        handlePermissionsChange={handlePermissionsChange}
        selectedStaffPermissions={selectedStaffPermissions}
        handleUpdateStaffPermissions={handleUpdateStaffPermissions}
      />
      {/* Invite added Staff */}
      <InviteAddedStaff
        loading={loading}
        staffItem={staffItem}
        open={isOpenInviteStaffDialog}
        inviteStaffErrors={inviteStaffErrors}
        inviteYourselfError={inviteYourselfError}
        invitationEmailStaffText={invitationEmailStaffText}
        onClose={handleCloseInviteStaffDialog}
        handleStaffInputChange={handleStaffInputChange}
        handleInviteCurrentStaff={handleInviteCurrentStaff}
      />
      {/* Resend Invitation modal */}
      <ResendInvitation
        open={isOpenResendDialog}
        staffItem={staffItem}
        lineProgress={lineProgress}
        onClose={handleCloseResendInvitationDialog}
        handleResendInvitation={handleResendInviteStaffFormSubmit}
      />
      {/* Preview staff modal */}
      <PreviewStaffModal
        theme={theme}
        tabValue={tabValue}
        a11yProps={a11yProps}
        tableAppHeader={tableAppHeader}
        handleChangeTab={handleChangeTab}
        previewStaffItem={previewStaffItem}
        handleChangeIndex={handleChangeIndex}
        tableServiceHeader={tableServiceHeader}
        handleClosePreviewModal={handleClosePreviewModal}
        isPreviewStaffModalInfo={isPreviewStaffModalInfo}
      />
      {/* Existing User Modal */}
      <ExistingModal
        existingUser={existingUser}
        open={isOpenExistingModal}
        onClose={() => setIsOpenExistingModal(false)}
        handleCancelConfirmation={handleCancelConfirmation}
        handleConfirmExistingUser={handleInviteExistingStaff}
      />
      {isDeleteStaffOrStaffInvitationModalShown && (
        <DeleteStaffOrStaffInvitation
          closeStaffDeleteOrStaffInvitationModal={closeStaffDeleteOrStaffInvitationModal}
          isInvited={isInvitedStaff}
          staffItem={staffItem}
          handleDeleteStaffInvitation={handleDeleteStaffInvitation}
          handleDeleteStaff={handleDeleteStaff}
          selectedStaffOrStaffInvitation={selectedStaffOrStaffInvitationId}
        />
      )}
      {alert}
    </>
  );
}

Staff.propTypes = {
  userAccount: PropTypes.object.isRequired,
  // match: PropTypes.object.isRequired,
  staffFilterItem: PropTypes.object.isRequired,
  sendCompanyUpdated: PropTypes.func.isRequired,
  // Delete Staff Props
  deleteStaff: PropTypes.func.isRequired,
  revertDeleteStaff: PropTypes.func.isRequired,
  isDeleteStaffSuccess: PropTypes.bool.isRequired,
  isDeleteStaffError: PropTypes.bool.isRequired,
  deleteStaffErrorMessage: PropTypes.string.isRequired,
  // Get Staff Positions
  getStaffPositions: PropTypes.func.isRequired,
  isGetStaffPositionsSuccess: PropTypes.bool.isRequired,
  isGetStaffPositionsError: PropTypes.bool.isRequired,
  positions: PropTypes.array.isRequired,
  // Add new staff
  isAddStaffPerformed: PropTypes.bool.isRequired,
  addStaff: PropTypes.func.isRequired,
  isAddStaffToCompanySuccess: PropTypes.bool.isRequired,
  isAddStaffToCompanyError: PropTypes.bool.isRequired,
  addedStaff: PropTypes.object.isRequired,
  addCompanyStaffErrors: PropTypes.object.isRequired,
  addStaffErrorMessage: PropTypes.string.isRequired,
  closeAddStaffModal: PropTypes.func.isRequired,
  handleOpenAddStaffModal: PropTypes.func.isRequired,
  // Revert Add Staff
  revertAddStaff: PropTypes.func.isRequired,
  // Invite Staff Props
  isInviteStaffPerformed: PropTypes.bool.isRequired,
  closeInviteNewStaffModal: PropTypes.func.isRequired,
  revertInviteStaff: PropTypes.func.isRequired,
  isInviteStaffToCompanySuccess: PropTypes.bool.isRequired,
  isInviteStaffToCompanyError: PropTypes.bool.isRequired,
  inviteStaff: PropTypes.func.isRequired,
  storedInvitation: PropTypes.object.isRequired,
  inviteStaffErrorMessage: PropTypes.string.isRequired,
  isInvitedExistingStaffSuccess: PropTypes.bool.isRequired,
  isInvitedExistingStaffError: PropTypes.bool.isRequired,
  isInvitedExistingStaffErrorMessage: PropTypes.string.isRequired,
  inviteExistingStaff: PropTypes.func.isRequired,
  revertInviteExistingStaff: PropTypes.func.isRequired,
  staffInvitation: PropTypes.object.isRequired,
  // Update Staff
  updateStaff: PropTypes.func.isRequired,
  revertUpdateStaff: PropTypes.func.isRequired,
  isUpdateStaffSuccess: PropTypes.bool.isRequired,
  isUpdateStaffError: PropTypes.bool.isRequired,
  updatedStaff: PropTypes.object.isRequired,
  updateStaffErrorMessage: PropTypes.string.isRequired,
  // Update Staff Permissions Props
  updateStaffPermissions: PropTypes.func.isRequired,
  revertStaffPermissions: PropTypes.func.isRequired,
  isUpdateStaffPermissionsSuccess: PropTypes.bool.isRequired,
  isUpdateStaffPermissionsError: PropTypes.bool.isRequired,
  updateStaffPermissionsErrorMessage: PropTypes.string.isRequired,
  updatedPagesAccess: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  getIndustryServices: PropTypes.func.isRequired,
  // Delete Invitation Props
  deleteStaffInvitation: PropTypes.func.isRequired,
  revertDeleteInvitation: PropTypes.func.isRequired,
  isDeleteStaffInvitationSuccess: PropTypes.bool.isRequired,
  isDeleteStaffInvitationError: PropTypes.bool.isRequired,
  deleteStaffInvitationErrorMessage: PropTypes.string.isRequired,
  // Get Industry Professions
  professions: PropTypes.array.isRequired,
  getProfessions: PropTypes.func.isRequired,
  isGetProfessionsSuccess: PropTypes.bool.isRequired,
  // Get Company By Id Props
  // getCompanyById: PropTypes.func.isRequired,
  isGetCompanyByIdSuccess: PropTypes.bool.isRequired,
  companyGot: PropTypes.object.isRequired,
  // Filtered staff
  filteredStaffOption: PropTypes.object.isRequired,
  isFilterStaffSuccess: PropTypes.bool.isRequired,
  // Resend Invitation
  resendStaffInvitation: PropTypes.func.isRequired,
  isResendStaffInvitationSuccess: PropTypes.bool.isRequired,
  // Get countries
  allCountries: PropTypes.array.isRequired,
  // Amazon Aws S3
  getPreSignUrl: PropTypes.func.isRequired,
  // isGetPreSignedUrlError: PropTypes.bool.isRequired,
  isGetPreSignedUrlSuccess: PropTypes.bool.isRequired,
  preSignUrl: PropTypes.string.isRequired,
  // Upload Image to Amazon
  uploadImageToAmazon: PropTypes.func.isRequired,
  isUploadImageToAmazonSuccess: PropTypes.bool.isRequired,
  // isUploadImageToAmazonError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  userAccount: state.account.userAccount,
  updatedCompany: state.company.updatedCompany,
  // Get Company By Id
  isGetCompanyByIdSuccess: state.company.isGetCompanyByIdSuccess,
  isGetCompanyByIdError: state.company.isGetCompanyByIdError,
  companyGot: state.company.companyGot,
  getCompanyByIdErrorMessage: state.company.getCompanyByIdErrorMessage,
  // Delete Staff
  isDeleteStaffSuccess: state.staff.isDeleteStaffSuccess,
  isDeleteStaffError: state.staff.isDeleteStaffError,
  deleteStaffErrorMessage: state.staff.deleteStaffErrorMessage,
  // Get Staff Positions
  isGetStaffPositionsSuccess: state.staff.isGetStaffPositionsSuccess,
  isGetStaffPositionsError: state.staff.isGetStaffPositionsError,
  positions: state.staff.positions,
  // Add Staff
  isAddStaffToCompanySuccess: state.staff.isAddStaffToCompanySuccess,
  isAddStaffToCompanyError: state.staff.isAddStaffToCompanyError,
  addedStaff: state.staff.addedStaff,
  addCompanyStaffErrors: state.staff.addCompanyStaffErrors,
  addStaffErrorMessage: state.staff.addStaffErrorMessage,
  // Invite Staff
  isInviteStaffToCompanySuccess: state.staff.isInviteStaffToCompanySuccess,
  isInviteStaffToCompanyError: state.staff.isInviteStaffToCompanyError,
  storedInvitation: state.staff.storedInvitation,
  inviteStaffErrorMessage: state.staff.inviteStaffErrorMessage,
  isInvitedExistingStaffErrorMessage: state.staff.isInvitedExistingStaffErrorMessage,
  isInvitedExistingStaffError: state.staff.isInvitedExistingStaffError,
  isInvitedExistingStaffSuccess: state.staff.isInvitedExistingStaffSuccess,
  staffInvitation: state.staff.staffInvitation,
  // Update Staff
  isUpdateStaffSuccess: state.staff.isUpdateStaffSuccess,
  isUpdateStaffError: state.staff.isUpdateStaffError,
  updatedStaff: state.staff.updatedStaff,
  updateStaffErrorMessage: state.staff.updateStaffErrorMessage,
  // Delete Invitation
  isDeleteStaffInvitationSuccess: state.staff.isDeleteStaffInvitationSuccess,
  isDeleteStaffInvitationError: state.staff.isDeleteStaffInvitationError,
  deleteStaffInvitationErrorMessage: state.staff.deleteStaffInvitationErrorMessage,
  // Update Staff Permissions
  isUpdateStaffPermissionsSuccess: state.staff.isUpdateStaffPermissionsSuccess,
  isUpdateStaffPermissionsError: state.staff.isUpdateStaffPermissionsError,
  updateStaffPermissionsErrorMessage: state.staff.updateStaffPermissionsErrorMessage,
  updatedPagesAccess: state.staff.updatedPagesAccess,
  // Get Industry Services
  isGetIndustryServicesSuccess: state.service.isGetIndustryServicesSuccess,
  isGetIndustryServicesError: state.service.isGetIndustryServicesError,
  services: state.service.services,
  // Get countries
  allCountries: state.country.allCountries,
  // Get professions
  professions: state.country.professions,
  isGetProfessionsSuccess: state.country.isGetProfessionsSuccess,
  // Resend Staff Invitation
  isResendStaffInvitationSuccess: state.staff.isResendStaffInvitationSuccess,
  isResendStaffInvitationError: state.staff.isResendStaffInvitationError,
  // Filtered Staff
  isFilterStaffSuccess: state.staff.isFilterStaffSuccess,
  filteredStaffOption: state.staff.filteredStaffOption,
  // Amazon Aws S3
  isGetPreSignedUrlSuccess: state.company.isGetPreSignedUrlSuccess,
  // isGetPreSignedUrlError: state.company.isGetPreSignedUrlError,
  preSignUrl: state.company.preSignUrl,
  uploadImageToAmazon: state.company.uploadImageToAmazon,
  isUploadImageToAmazonSuccess: state.company.isUploadImageToAmazonSuccess,
  // isUploadImageToAmazonError: state.company.isUploadImageToAmazonError,
});

function mapDispatchToProps(dispatch) {
  return {
    updateStaff: (data) => dispatch(updateStaffRequest(data)),
    deleteStaff: (data) => dispatch(deleteStaffRequest(data)),
    addStaff: (data) => dispatch(addStaffToCompanyRequest(data)),
    revertUpdateStaff: () => dispatch(revertUpdateStaffToDefault()),
    inviteStaff: (data) => dispatch(inviteStaffToCompanyRequest(data)),
    getStaffPositions: (data) => dispatch(getStaffPositionsRequest(data)),
    revertDeleteStaff: (data) => dispatch(revertDeleteStaffToDefault(data)),
    revertInviteStaff: () => dispatch(revertInviteStaffToCompanyToDefault()),
    revertStaffPermissions: () => dispatch(revertStaffPermissionsToDefault()),
    getIndustryServices: (data) => dispatch(getIndustryServicesRequest(data)),
    inviteExistingStaff: (data) => dispatch(inviteExistingStaffRequest(data)),
    revertDeleteInvitation: () => dispatch(revertDeleteInvitationToDefault()),
    revertAddStaff: (data) => dispatch(revertAddStaffToCompanyToDefault(data)),
    deleteStaffInvitation: (data) => dispatch(deleteStaffInvitationRequest(data)),
    updateStaffPermissions: (data) => dispatch(updateStaffPermissionsRequest(data)),
    revertInviteExistingStaff: (data) => dispatch(revertInviteExistingStaffToDefault(data)),
    // getCompanyById: (data) => dispatch(getCompanyByIdRequest(data)),
    getProfessions: (data) => dispatch(getProfessionsRequest(data)),
    resendStaffInvitation: (data) => dispatch(resendStaffInvitationRequest(data)),
    getPreSignUrl: (data) => dispatch(getPreSignUrlRequest(data)),
    uploadImageToAmazon: (data) => dispatch(uploadImageToAmazonRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
