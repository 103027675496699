import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import EditServiceItem from './editServiceItem';
import { checkAppointmentsByServiceIdRequest } from '../../../../../../../../redux/appointment/actions';
import usePrevious from '../../../../../../../../CustomHooks/usePrevious';

function ServiceItem(props) {
  const dispatch = useDispatch();
  const {
    isCheckAppointmentByServiceIdSuccess,
    appointments,
  } = useSelector((state) => state.appointment);

  const {
    services,
    serviceId,
    individualUserId,
    subService,
    industryId,
    setServiceIds,
    setSelectedServiceParentId,
    setSelectedIndividualUserServiceId,
    setOpenDeleteServiceModal,
    individualUserServices,
    setIndividualUserServices,
    setFilteredAppointments,
  } = props;

  // Get some props previous values
  const prevIsCheckAppointmentByServiceIdSuccess = usePrevious(isCheckAppointmentByServiceIdSuccess);

  const [serviceOptions, setServiceOptions] = useState([]);
  const [editAble, setEditAble] = useState(false);

  // Check Appointments Success
  useEffect(() => {
    if (prevIsCheckAppointmentByServiceIdSuccess === false && isCheckAppointmentByServiceIdSuccess) {
      const filteredAppointments = appointments.filter((appointment) => appointment.status.id === 1 || appointment.status.id === 2);
      setFilteredAppointments(filteredAppointments);
    }
  }, [isCheckAppointmentByServiceIdSuccess]);

  useEffect(() => {
    if (!subService.individual_user_industry_service_id) {
      setEditAble(true);
    }
  }, [subService]);

  useEffect(() => {
    const service = services.find((service) => service.id === serviceId);
    const serviceOptionsData = [];

    if (service) {
      service.subServices.map((service) => {
        serviceOptionsData.push({
          id: service.id,
          logo: service.logo,
          value: service.id,
          label: service.name,
        });
      });
    }

    setServiceOptions(serviceOptionsData);
  }, [services]);

  const showDuration = (duration) => {
    const hours = Math.floor(duration / 60);
    const min = duration % 60;

    if (hours === 0) {
      return `${min} min`;
    } else if (min === 0) {
      return `${hours} hour`;
    } else {
      return `${hours} hr ${min} min`;
    }
  };

  const removeService = (individualUserIndustryId, serviceParentId) => {
    dispatch(checkAppointmentsByServiceIdRequest({
      individual_user_industry_id: individualUserId,
      service_id: individualUserIndustryId,
    }));
    setSelectedIndividualUserServiceId(individualUserIndustryId);
    setSelectedServiceParentId(serviceParentId);
    setOpenDeleteServiceModal(true);
    setServiceIds([]);
  };

  return (
    <>
      {editAble ? (
        <EditServiceItem
          individualUserId={individualUserId}
          individualUserServices={individualUserServices}
          subService={subService}
          serviceOptions={serviceOptions}
          serviceId={serviceId}
          setEditAble={setEditAble}
          setIndividualUserServices={setIndividualUserServices}
          industryId={industryId}
        />
      ) : (
        <Grid container direction="row" alignItems="center" spacing={3}>
          <Grid item xs={3}>
            { subService.name }
          </Grid>
          <Grid item xs={2} align="center">
            { showDuration(subService.duration) }
          </Grid>
          <Grid item xs align="center">
            { `${subService.price} AMD` }
          </Grid>
          <Grid item xs align="center">
            <IconButton
              aria-label="edit"
              onClick={() => setEditAble(true)}
              size="small"
            >
              <EditIcon fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => removeService(subService.individual_user_industry_service_id, serviceId)}
              size="small"
            >
              <DeleteOutlineIcon fontSize="small" color="secondary" />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}

ServiceItem.propTypes = {
  individualUserId: PropTypes.number.isRequired,
  subService: PropTypes.object.isRequired,
  serviceId: PropTypes.number.isRequired,
  setServiceIds: PropTypes.func.isRequired,
  setSelectedServiceParentId: PropTypes.func.isRequired,
  setSelectedIndividualUserServiceId: PropTypes.func.isRequired,
  setOpenDeleteServiceModal: PropTypes.func.isRequired,
  individualUserServices: PropTypes.array.isRequired,
  setIndividualUserServices: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
  industryId: PropTypes.number.isRequired,
  setFilteredAppointments: PropTypes.func.isRequired,
};

export default ServiceItem;
