import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import {
  Select,
  TextField,
  InputLabel,
  FormControl,
  Grid,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import {
  updateAppointmentRequest,
} from '../../../../../../../redux/appointment/actions';
import {
  getIndividualUserIndustryByIdRequest,
} from '../../../../../../../redux/individualUser/actions';
import capitalize from '../../../../../../../Modules/capitalize';
import InfoLoading from '../../../../../../../Components/Loading/infoLoading';
import calendar from '../../../../../../../assets/icons/calendar.svg';
import clock from '../../../../../../../assets/icons/clock.svg';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';

function Info(props) {
  const {
    appointment,
    isAppointmentCancelled,
    allStatuses,
    getIndustryById,
    isGetIndividualUserIndustryByIdSuccess,
    isGetIndividualUserIndustryByIdError,
    IndividualUserIndustryGot,
    getIndividualUserIndustryErrorMessage,
    isUpdateAppointmentError,
    isUpdateAppointmentSuccess,
    updateAppointmentErrors,
    setIndustry,
    setAppointmentItem,
    appointmentItem,
    setIsButtonDisabled,
  } = props;

  // Get some props previous values
  const prevIsGetIndividualUserIndustryByIdSuccess = usePrevious(isGetIndividualUserIndustryByIdSuccess);
  const prevIsGetIndividualUserIndustryByIdError = usePrevious(isGetIndividualUserIndustryByIdError);
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);
  const prevIsUpdateAppointmentError = usePrevious(isUpdateAppointmentError);

  const [loading, setLoading] = useState(true);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatusOption, setSelectedStatusOption] = useState({});
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Perform once, when component gets mounted
  useEffect(() => {
    const time = moment(appointment.start_time, 'HH:mm:ss');
    setAppointmentItem({
      ...appointment,
      day: new Date(appointment.date),
      time,
      day_time: `${appointment.date} ${appointment.start_time}:00`,
    });
    getIndustryById(appointment.individual_user_industry_id);
  }, []);
  useEffect(() => {
    if (allStatuses.length) {
      const options = allStatuses.map((item) => ({
        label: capitalize(item.name),
        value: item.id,
      }));
      setStatusOptions(options);
      const selectedOption = options.find((item) => item.value === parseInt(appointment.status_id));
      setSelectedStatusOption(selectedOption);
      setLoading(false);
    }
  }, [allStatuses]);

  useEffect(() => {
    if (prevIsGetIndividualUserIndustryByIdSuccess === false && isGetIndividualUserIndustryByIdSuccess) {
      const industryCopy = { ...IndividualUserIndustryGot };
      industryCopy.workingHours = IndividualUserIndustryGot.workingHours.map((hour) => ({
        daysOfWeek: [hour.week_day_id === '7' ? 0 : hour.week_day_id],
        startTime: hour.start_time,
        endTime: hour.end_time,
        isWorking: hour.is_working,
      }));
      setIndustry(industryCopy);
    }
  }, [isGetIndividualUserIndustryByIdSuccess]);

  useEffect(() => {
    if (prevIsGetIndividualUserIndustryByIdError === false && isGetIndividualUserIndustryByIdError) {
      setOpenSnackbar(true);
      setSnackbarMessage(getIndividualUserIndustryErrorMessage);
      setSnackbarType('error');
    }
  }, [isGetIndividualUserIndustryByIdError]);

  // Perform, when update appointment success
  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      setErrors({});
    }
  }, [isUpdateAppointmentSuccess]);

  // Perform, when update appointment error
  useEffect(() => {
    if (prevIsUpdateAppointmentError === false && isUpdateAppointmentError) {
      setLoading(false);
      setIsButtonDisabled(false);
      setErrors(updateAppointmentErrors);
    }
  }, [isUpdateAppointmentError]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAppointmentItem({
      ...appointmentItem,
      [name]: value,
    });
  };

  const renderDatetime = (label, value, onChange, img, name) => (
    <div className="datetime-content">
      <TextField
        fullWidth
        error={!!errors[name]}
        size="small"
        type={label.toLowerCase()}
        onChange={(e) => onChange(name, e.target.value)}
        disabled={isAppointmentCancelled}
        variant="outlined"
        label={label}
        style={{ position: 'relative' }}
        placeholder={label}
        value={value || ''}
        inputProps={{
          autoComplete: 'new-password',
        }}
      />

      {errors[name] && (
        <div className="error-message-content">
          <ErrorIcon fontSize="small" color="error" />
          <FormHelperText error>
            {errors[name]}
          </FormHelperText>
        </div>
      )}
    </div>
  );

  const updateAppointmentItem = (name, value) => {
    let updateValue = value;
    if (name === 'start_time') {
      updateValue = `${updateValue}:00`;
    }
    setAppointmentItem({
      ...appointmentItem,
      [name]: updateValue,
    });
  };

  return !loading ? (
    <>
      <Grid id="info-tab" className="tab-item">
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              label="Title"
              name="title"
              value={appointmentItem.title || ''}
              onChange={(e) => handleInputChange(e)}
              disabled={isAppointmentCancelled}
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" size="small" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                id="demo-simple-select-helper"
                value={selectedStatusOption.value}
                disabled
              >
                {statusOptions && statusOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              style={{ position: 'relative' }}
              label="Customer"
              name="customer"
              placeholder="Customer"
              value={
                appointmentItem.customer
                  ? `${appointmentItem.customer.first_name} ${appointmentItem.customer.last_name}`
                  : ''
              }
              disabled
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              style={{ position: 'relative' }}
              label="Industry"
              name="industry"
              placeholder="Industry"
              value={appointmentItem.industry.name ? appointmentItem.industry.name : ''}
              disabled
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              style={{ position: 'relative' }}
              label="Service"
              name="service"
              placeholder="Service"
              value={appointmentItem.service ? appointment.service.name : ''}
              disabled
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              label="Permalink"
              name="permalink"
              placeholder="Permalink"
              value={appointmentItem.permalink || ''}
              disabled
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              label="Permalink"
              name="permalink"
              placeholder="Permalink"
              value={appointmentItem.permalink || ''}
              disabled
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderDatetime('Date', appointmentItem.date, updateAppointmentItem, calendar, 'date')}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderDatetime('Time', appointmentItem.start_time, updateAppointmentItem, clock, 'start_time')}
          </Grid>
        </Grid>
      </Grid>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  ) : <InfoLoading />;
}

Info.propTypes = {
  appointment: PropTypes.object.isRequired,
  setIsButtonDisabled: PropTypes.func.isRequired,
  setIndustry: PropTypes.func.isRequired,
  setAppointmentItem: PropTypes.func.isRequired,
  appointmentItem: PropTypes.object.isRequired,
  isAppointmentCancelled: PropTypes.bool.isRequired,
  allStatuses: PropTypes.array.isRequired,
  getIndustryById: PropTypes.func.isRequired,
  isGetIndividualUserIndustryByIdSuccess: PropTypes.bool.isRequired,
  isGetIndividualUserIndustryByIdError: PropTypes.bool.isRequired,
  IndividualUserIndustryGot: PropTypes.object.isRequired,
  getIndividualUserIndustryErrorMessage: PropTypes.string.isRequired,
  isUpdateAppointmentError: PropTypes.bool.isRequired,
  isUpdateAppointmentSuccess: PropTypes.bool.isRequired,
  updateAppointmentErrors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isUpdateAppointmentError: state.appointment.isUpdateAppointmentError,
  isUpdateAppointmentSuccess: state.appointment.isUpdateAppointmentSuccess,
  updateAppointmentErrors: state.appointment.updateAppointmentErrors,
  updatedAppointment: state.appointment.updatedAppointment,
  updateAppointmentErrorMessage: state.appointment.updateAppointmentErrorMessage,
  isGetIndividualUserIndustryByIdSuccess: state.individualUser.isGetIndividualUserIndustryByIdSuccess,
  isGetIndividualUserIndustryByIdError: state.individualUser.isGetIndividualUserIndustryByIdError,
  IndividualUserIndustryGot: state.individualUser.IndividualUserIndustryGot,
  getIndividualUserIndustryErrorMessage: state.individualUser.getIndividualUserIndustryErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    updateAppointment: (data) => dispatch(updateAppointmentRequest(data)),
    getIndustryById: (data) => dispatch(getIndividualUserIndustryByIdRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);
