import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Drawer } from '@material-ui/core';
import moment from 'moment';
import {
  cancelRequest,
  deleteAppointmentRequest,
  storeAppointmentRequest,
  updateAppointmentRequest,
} from 'redux/appointment/actions';

import usePrevious from 'CustomHooks/usePrevious';
import './style.scss';

// Icons
import Customer from './Items/customer';
import Service from './Items/service';
import Staff from './Items/staff';
import DateAndTime from './Items/dateAndTime';
import Note from './Items/note';
import BottomCreatePart from './Bottom/create';
import BottomEditPart from './Bottom/edit';
import Header from './Header';
import AppointmentActionModal from './ActionModal';

const AppointmentsDrawer = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userSettings } = useSelector((state) => state.account);

  const {
    isOpen,
    onClose,
    date,
    startTime,
    staffId,
    appointment,
    snackBarAlert,
    appointments,
    setAppointments,
    fromCalendar,
    setSelectedAppointmentId,
  } = props;
  const {
    // isStoreAppointmentError,
    // storeAppointmentErrors,
    isStoreAppointmentSuccess,
    isDeleteAppointmentSuccess,
    isUpdateAppointmentSuccess,
    isCancelSuccess,
  } = useSelector((state) => state.appointment);
  const { companyGot } = useSelector((state) => state.company);

  const [errors, setErrors] = useState({});
  const [appointmentItem, setAppointmentItem] = useState({});
  const [isOpenActionModal, setIsOpenActionModal] = useState(false);
  const [actionType, setActionType] = useState('');
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [appointmentDeleteLoading, setAppointmentDeleteLoading] = useState(false);
  const [appointmentCancelLoading, setAppointmentCancelLoading] = useState(false);
  const checkedStatuses = JSON.parse(localStorage.getItem('calendarCheckedStatuses'));

  // Get some props previous values
  // const prevIsStoreAppointmentError = usePrevious(isStoreAppointmentError);
  const prevIsStoreAppointmentSuccess = usePrevious(isStoreAppointmentSuccess);
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);
  const prevIsDeleteAppointmentSuccess = usePrevious(isDeleteAppointmentSuccess);
  const prevIsCancelSuccess = usePrevious(isCancelSuccess);

  useEffect(() => {
    if (Object.keys(appointment).length > 0) {
      setAppointmentItem(appointment);
    } else if (date.length > 0 && startTime.length > 0) {
      setAppointmentItem({
        date,
        staff_id: staffId,
        start_time: startTime,
      });
    } else {
      setAppointmentItem({
        date: date || moment().format('Y-MM-DD'),
      });
    }
  }, []);

  useEffect(() => {
    if (prevIsDeleteAppointmentSuccess === false && isDeleteAppointmentSuccess) {
      setIsOpenActionModal(false);
      setAppointmentDeleteLoading(false);
      const appointmentsCopy = [...appointments];
      const index = appointmentsCopy.findIndex((i) => i.id === appointment.id);
      appointmentsCopy.splice(index, 1);
      setAppointments(appointmentsCopy);
      onClose();
      snackBarAlert(true, t('AppointmentDeleted'), 'success');
    }
  }, [isDeleteAppointmentSuccess]);

  // Handle Store Appointment Success
  useEffect(() => {
    if (prevIsStoreAppointmentSuccess === false && isStoreAppointmentSuccess) {
      setAppointmentLoading(false);
      snackBarAlert(true, t('AppointmentCreated'), 'success');
      onClose();
    }
  }, [isStoreAppointmentSuccess]);

  // Handle Update Appointment Success
  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      setAppointmentLoading(false);
      snackBarAlert(true, t('UpdatedSuccess'), 'success');
      onClose();
    }
  }, [isUpdateAppointmentSuccess]);

  useEffect(() => {
    if (prevIsCancelSuccess === false && isCancelSuccess) {
      setIsOpenActionModal(false);
      setAppointmentCancelLoading(false);
      const appointmentsCopy = [...appointments];
      const index = appointmentsCopy.findIndex((i) => i.id === appointment.id);
      if (checkedStatuses.includes(3)) {
        appointmentsCopy[index].status_name = 'cancelled';
        appointmentsCopy[index].status_id = 3;
        appointmentsCopy[index].headerBgColor = '#CED3D6';
        appointmentsCopy[index].borderColor = '#CED3D6';
        appointmentsCopy[index].backgroundColor = '#CED3D6';
      } else {
        appointmentsCopy.splice(index, 1);
      }

      setAppointments(appointmentsCopy);
      onClose();
      snackBarAlert(true, t('AppointmentCancel'), 'success');
    }
  }, [isCancelSuccess]);

  useEffect(() => {
    if (!companyGot.is_staff_based) {
      setAppointmentItem((prevValue) => ({
        ...prevValue,
        staff_id: companyGot.user_id,
      }));
    }
  }, [companyGot]);

  // Handle customer invited Success
  // useEffect(() => {
  //   if (prevIsInviteCustomerToCompanySuccess === false && isInviteCustomerToCompanySuccess) {
  //     // setIsAppointmentModalShown(false);
  //   }
  // }, [isInviteCustomerToCompanySuccess]);

  // const handleAddCustomer = (customer) => {
  //   customersOptions.push({
  //     value: customer.id,
  //     label: `${customer.first_name} ${customer.last_name}`,
  //     phone: customer.phone_code + customer.phone_number,
  //   });
  // };

  const handleChange = (name, value) => {
    setAppointmentItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleStoreAppointment = () => {
    if (validateForm('', true)) {
      const timeAndDate = moment().format(`${appointmentItem.date} ${appointmentItem.start_time}`);
      setAppointmentLoading(true);
      dispatch(storeAppointmentRequest({
        ...appointmentItem,
        duration: appointmentItem.duration,
        start_time: `${appointmentItem.start_time}:00`,
        date: appointmentItem.date,
        day_time: `${timeAndDate}:00`,
        service_id: appointmentItem.service_id,
        staff_id: appointmentItem.staff_id,
        customer_id: appointmentItem.customer_id,
        note_from_creator: appointmentItem.note_from_creator,
        for_calendar: fromCalendar,
      }));
    }
  };

  const validateForm = (name, all = false) => {
    const errorsCopy = { ...errors };
    if (all) {
      errorsCopy.price = appointmentItem.price && Object.keys(appointmentItem.price).length === 0;
      errorsCopy.duration = !appointmentItem.duration;
      errorsCopy.staff = !appointmentItem.staff_id;
      errorsCopy.service = !appointmentItem.service_id;
      errorsCopy.customer = appointmentItem.customer_id === undefined;
      errorsCopy.date = !appointmentItem.date;
      errorsCopy.start_time = !appointmentItem.start_time;
      setErrors(errorsCopy);
      return (
        !(errorsCopy.price)
                && !(errorsCopy.duration)
                && !(errorsCopy.staff)
                && !(errorsCopy.service)
                && !(errorsCopy.customer)
                && !(errorsCopy.start_time)
                && !(errorsCopy.date)
      );
    } else {
      const value = appointmentItem[name];
      errorsCopy[name] = value.length === 0;
      setErrors(errorsCopy);
    }
  };

  // const updateAppointmentItem = (name, value) => {
  //   let updateValue = value;
  //   if (name === 'start_time') {
  //     updateValue = `${updateValue}`;
  //   }
  //   setAppointmentItem({
  //     ...appointmentItem,
  //     [name]: updateValue,
  //   });
  //   dispatch(staffSpecialGraphicRequest({
  //     ...graphicData,
  //     date: value,
  //   }));
  // };

  const handleUpdateAppointment = () => {
    if (validateForm('', true)) {
      setAppointmentLoading(true);
      const timeAndDate = moment().format(`${appointmentItem.date} ${appointmentItem.start_time}`);
      dispatch(updateAppointmentRequest({
        ...appointmentItem,
        id: appointmentItem.id,
        duration: appointmentItem.duration,
        start_time: `${appointmentItem.start_time}:00`,
        date: appointmentItem.date,
        day_time: `${timeAndDate}:00`,
        service_id: appointmentItem.service_id,
        staff_id: appointmentItem.staff_id,
        customer_id: appointmentItem.customer_id,
        note_from_creator: appointmentItem.note_from_creator,
        for_calendar: fromCalendar,
      }));
    }
  };

  const deleteApp = () => {
    setAppointmentDeleteLoading(true);
    dispatch(deleteAppointmentRequest({ id: appointment.id }));
  };

  const cancelApp = () => {
    setAppointmentCancelLoading(true);
    dispatch(cancelRequest({ id: appointment.id }));
  };

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={onClose}
      className="appointment-drawer"
      // disableBackdropClick
      PaperProps={{
        style: {
          height: 'calc(100% - 64px)',
          top: 64,
          maxWidth: '563px',
          width: '100%',
        },
      }}
      id="appointment-drawer"
    >
      <div className="drawer-content">
        <div>
          <Header appointment={appointment} onClose={onClose} />
          <Customer errors={errors} handleChange={handleChange} appointmentItem={appointmentItem} />
          {userSettings && userSettings.appointment && userSettings.appointment.creating_order === 'Service-Staff' ? (
            <>
              <Service errors={errors} handleChange={handleChange} appointmentItem={appointmentItem} isFirst />
              {companyGot.is_staff_based && <Staff errors={errors} handleChange={handleChange} appointmentItem={appointmentItem} />}
            </>
          ) : (
            <>
              {companyGot.is_staff_based && <Staff errors={errors} handleChange={handleChange} appointmentItem={appointmentItem} isFirst />}
              <Service errors={errors} handleChange={handleChange} appointmentItem={appointmentItem} />
            </>
          )}
          <DateAndTime errors={errors} handleChange={handleChange} appointmentItem={appointmentItem} selectedAppointment={appointment} />
          <Note handleChange={handleChange} appointmentItem={appointmentItem} />
        </div>
        <div>
          {appointment.id ? (
            <BottomEditPart
              appointmentLoading={appointmentLoading}
              handleUpdateAppointment={handleUpdateAppointment}
              handleDelete={() => {
                setIsOpenActionModal(true);
                setActionType('delete');
              }}
              handleCancel={() => {
                setIsOpenActionModal(true);
                setActionType('cancel');
                setSelectedAppointmentId(appointment.id);
              }}
              appointmentDeleteLoading={appointmentDeleteLoading}
              appointmentCancelLoading={appointmentCancelLoading}
              statusName={appointment.status_name}
            />
          ) : (
            <BottomCreatePart onClose={onClose} appointmentLoading={appointmentLoading} handleStoreAppointment={handleStoreAppointment} />
          )}
        </div>
      </div>

      <AppointmentActionModal
        type={actionType}
        open={isOpenActionModal}
        onClose={() => setIsOpenActionModal(false)}
        handleDelete={deleteApp}
        handleCancel={cancelApp}
        appointmentDeleteLoading={appointmentDeleteLoading}
        appointmentCancelLoading={appointmentCancelLoading}
      />
    </Drawer>
  );
};

AppointmentsDrawer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  snackBarAlert: PropTypes.func.isRequired,
  appointment: PropTypes.object.isRequired,
  appointments: PropTypes.array.isRequired,
  setAppointments: PropTypes.func.isRequired,
  staffId: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
  date: PropTypes.string,
  startTime: PropTypes.string,
  fromCalendar: PropTypes.bool,
  setSelectedAppointmentId: PropTypes.func.isRequired,
};

AppointmentsDrawer.defaultProps = {
  staffId: null,
  date: '',
  startTime: '',
  fromCalendar: false,
};

export default AppointmentsDrawer;
