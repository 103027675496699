import React from 'react';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Button,
  IconButton,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import moment from 'moment';
import i18n from 'i18next';
import servicesLogo from '../../../assets/servicesLogo';
import calendar from '../../../assets/icons/calendar.svg';

const EditAppointmentModal = (props) => {
  const {
    open,
    errors,
    onClose,
    options,
    selectedHour,
    pendingValue,
    availableTime,
    renderDatetime,
    setPendingValue,
    handleDateChange,
    handleInputChange,
    handleChangeHours,
    selectedAppointment,
    handleEditAppointment,
    handleServiceOptionChange,
  } = props;

  return (
    <Dialog
      size="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <span className="alert-title">
          <span> Edit Appointment</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" size="small">
              <Autocomplete
                size="small"
                fullWidth
                autoHighlight
                disabled
                value={selectedAppointment.industry}
                options={selectedAppointment.industry}
                getOptionLabel={(option) => option.name}
                renderOption={(option) => (
                  <div
                    className="input-menu"
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    label="Industry *"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined" size="small">
              <Autocomplete
                size="small"
                fullWidth
                autoHighlight
                disabled
                value={selectedAppointment.customer}
                options={selectedAppointment.customer}
                getOptionLabel={(option) => option.full_name}
                renderOption={(option) => (
                  <div
                    className="input-menu"
                    key={option.id}
                    value={option.id}
                  >
                    {option.fullName} <span className="customer-option-phone"> + {option.full_phone_number} </span>
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    label="Customer *"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth size="small" variant="outlined">
              <Autocomplete
                size="small"
                fullWidth
                autoHighlight
                options={options}
                groupBy={(option) => (
                  <span className="group-title">{option.type
                    ? <img src={servicesLogo[option.type]} className="service-img" alt="&#xf013;" width="20px" /> : null}
                    {option.type}
                  </span>
                )}
                getOptionLabel={(option) => option.name}
                value={pendingValue}
                onChange={(event, newValue) => {
                  setPendingValue(newValue);
                  handleServiceOptionChange(newValue);
                }}
                renderOption={(option) => (
                  <div
                    className="input-menu"
                    key={option.id}
                    value={option.id}
                  >
                    <Grid container direction="row">
                      <Grid item xs={8}>{option.name}</Grid>
                      <Grid item xs={2}><span className="service-duration">{option.duration} min</span></Grid>
                      <Grid item xs={2}><span className="service-price">{option.price} ֏</span></Grid>
                    </Grid>
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    label="Service"
                    variant="outlined"
                    error={errors.service}
                  />
                )}
              />
              { errors.service && <p className="error-message mt-1">Service is required.</p> }
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderDatetime('Date', selectedAppointment.date, handleDateChange, calendar, 'date')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
            >
              <Autocomplete
                size="small"
                fullWidth
                autoHighlight
                options={availableTime}
                disabled={!Object.keys(pendingValue).length}
                value={selectedHour}
                onChange={handleChangeHours}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                    }}
                    label="Available hours"
                    variant="outlined"
                  />
                )}
              />
              { errors.start_time && (
              <div className="error-message-content">
                <ErrorIcon fontSize="small" color="error" />
                <FormHelperText error>
                    Time is required.
                </FormHelperText>
              </div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box className="w-100">
              <TextField
                fullWidth
                size="small"
                label="Notes"
                variant="outlined"
                aria-label="Notes"
                name="note_from_creator"
                className="notes-from-appointment"
                value={selectedAppointment.note_from_creator}
                placeholder={`Appointment ${i18n.t('Notes')}`}
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={moment(selectedAppointment.date).isBefore(moment(), 'days')}
          onClick={(e) => handleEditAppointment(e)}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditAppointmentModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  availableTime: PropTypes.array.isRequired,
  selectedHour: PropTypes.string.isRequired,
  pendingValue: PropTypes.object.isRequired,
  renderDatetime: PropTypes.func.isRequired,
  setPendingValue: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleChangeHours: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  selectedAppointment: PropTypes.object.isRequired,
  handleEditAppointment: PropTypes.func.isRequired,
  handleServiceOptionChange: PropTypes.func.isRequired,
};

export default EditAppointmentModal;
