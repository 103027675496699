import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  InputLabel,
  TextField,
  Select,
  DialogContent,
  DialogActions,
  MenuItem,
  IconButton,
  FormControl,
  Button,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import {
  addServiceRequest,
} from '../../../redux/service/actions';
import usePrevious from '../../../CustomHooks/usePrevious';

function AddNewServiceModal(props) {
  const {
    open,
    onClose,
    individualUserIndustryItem,
    isGetIndustryServicesSuccess,
    services,
    addService,
    isAddServiceSuccess,
    isAddServiceError,
    addServiceErrors,
  } = props;

  // Get some props for previous service* values
  const prevIsAddServiceSuccess = usePrevious(isAddServiceSuccess);
  const prevIsAddServiceError = usePrevious(isAddServiceError);

  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedServiceOption, setSelectedServiceOption] = useState({});
  const [validationErrors, setValidationErrors] = useState({});
  const [serviceItem, setServiceItem] = useState({
    industry_id: individualUserIndustryItem.industry_id,
    service_id: '',
    price: '',
    duration: '',
  });
  const [errors, setErrors] = useState({
    service_id: false,
    price: false,
    duration: false,
  });
  useEffect(() => {
    if (isGetIndustryServicesSuccess && services.length) {
      const serviceOptionsCopy = [];
      services.map((service) => (
        serviceOptionsCopy.push({
          value: service.id,
          label: service.name,
        })
      ));
      setServiceOptions(serviceOptionsCopy);
    }
  }, [isGetIndustryServicesSuccess]);

  useEffect(() => {
    if (prevIsAddServiceSuccess === false && isAddServiceSuccess) {
      setServiceItem({
        industry_id: individualUserIndustryItem.industry_id,
        service_id: false,
        price: false,
        duration: false,
      });
      setValidationErrors({});
      setSelectedServiceOption({});
      onClose();
    }
  }, [isAddServiceSuccess]);

  useEffect(() => {
    if (prevIsAddServiceError === false && isAddServiceError) {
      setValidationErrors(addServiceErrors);
    }
  }, [isAddServiceError]);

  const validateForm = () => {
    const errorsCopy = { ...errors };
    errorsCopy.service_id = serviceItem.service_id === '';
    errorsCopy.price = serviceItem.price === '';
    errorsCopy.duration = serviceItem.duration === '';
    setErrors(() => ({ ...errorsCopy }));
    return (errorsCopy.service_id || errorsCopy.price || errorsCopy.duration);
  };

  const handleServiceChange = (event) => {
    const { value } = event.target;
    const findSelectedService = serviceOptions.find((item) => item.value === value);
    setSelectedServiceOption(findSelectedService);
    setServiceItem({
      ...serviceItem,
      service_id: value,
    });
  };

  const handleServiceInputChange = (event) => {
    const { name, value } = event.target;
    setServiceItem({
      ...serviceItem,
      [name]: value,
    });
  };

  const handleStoreIndividualService = (event) => {
    event.preventDefault();
    const errorExists = validateForm();
    if (!errorExists) {
      addService({
        ...serviceItem,
        individual_user_industry_id: individualUserIndustryItem.id,
      });
    }
  };

  return (
    <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <span className="alert-title">
          <span>New Service</span>
          <IconButton
            aria-label="close"
            className="close-btn"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={(!!errors.service_id || !!validationErrors.service_id)}
            >
              <InputLabel>Service</InputLabel>
              <Select
                onChange={(e) => handleServiceChange(e)}
                label="Service"
                name="service_id"
                value={selectedServiceOption.value ? selectedServiceOption.value : ''}
              >
                <MenuItem value="" disabled><em>Select service</em></MenuItem>
                {serviceOptions && serviceOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>

              { errors.service_id && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    Service is required.
                  </FormHelperText>
                </div>
              )}

              { validationErrors.service_id && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {validationErrors.service_id}
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={(!!errors.duration)}
            >
              <TextField
                fullWidth
                error={(!!errors.duration)}
                size="small"
                label="Duration(min)"
                variant="outlined"
                name="duration"
                value={serviceItem.duration}
                onChange={handleServiceInputChange}
                type="number"
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />

              { errors.duration && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    Duration is required.
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={(!!errors.price)}
            >
              <TextField
                fullWidth
                error={(!!errors.price)}
                size="small"
                label="Price($) *"
                variant="outlined"
                name="price"
                value={serviceItem.price}
                onChange={handleServiceInputChange}
                type="number"
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />

              { errors.price && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    Price is required.
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={(e) => handleStoreIndividualService(e)}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddNewServiceModal.propTypes = {
  // Parent props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  individualUserIndustryItem: PropTypes.object.isRequired,
  // Industry Services
  isGetIndustryServicesSuccess: PropTypes.bool.isRequired,
  services: PropTypes.array.isRequired,

  // Add service
  addService: PropTypes.func.isRequired,
  isAddServiceSuccess: PropTypes.bool.isRequired,
  isAddServiceError: PropTypes.bool.isRequired,
  addServiceErrors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // Get Industry Service
  isGetIndustryServicesSuccess: state.service.isGetIndustryServicesSuccess,
  isGetIndustryServicesError: state.service.isGetIndustryServicesError,
  services: state.service.services,

  // Add service
  isAddServiceSuccess: state.service.isAddServiceSuccess,
  isAddServiceError: state.service.isAddServiceError,
  addServiceErrorMessage: state.service.addServiceErrorMessage,
  addedService: state.service.addedService,
  addServiceErrors: state.service.addServiceErrors,
});

function mapDispatchToProps(dispatch) {
  return {
    addService: (data) => dispatch(addServiceRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddNewServiceModal);
