import { put, takeLatest } from 'redux-saga/effects';
import {
  getMessagesRequest, getMessagesSuccess,
  getMessagesError, storeMessageRequest,
  storeMessageSuccess, storeMessageError,
  makeMessagesReadRequest, makeMessagesReadSuccess,
  makeMessagesReadError, updateMessageRequest,
  updateMessageSuccess, updateMessageError,
  deleteMessageRequest, deleteMessageSuccess,
  deleteMessageError,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

function* getMessages(action) {
  try {
    const { limit, page } = action.payload;
    const url = `messages?limit=${limit}&page=${page}`;
    const response = yield axiosInstance.get(url);
    if (response && response.status === 200) {
      yield put(getMessagesSuccess(response.data));
    } else {
      yield put(getMessagesError(response.response.data));
    }
  } catch (e) {
    yield put(getMessagesError(e.response && e.response.data ? e.response.data : ''));
  }
}

function* storeMessage(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('messages', payload);
    if (response && response.status === 200) {
      yield put(storeMessageSuccess(response.data));
    } else {
      yield put(storeMessageError(response.response.data));
    }
  } catch (e) {
    yield put(storeMessageError(e.response.data));
  }
}

function* makeMessagesRead(action) {
  try {
    const { id } = action.payload;
    const response = yield axiosInstance.get(`messages/read/${id}`);
    if (response && response.status === 200) {
      yield put(makeMessagesReadSuccess(response.data));
    }
  } catch (e) {
    yield put(makeMessagesReadError());
  }
}

function* updateMessage(action) {
  try {
    const { id, appointment_id, text } = action.payload;
    const response = yield axiosInstance.put(`messages/${id}`, {
      appointment_id,
      text,
    });
    if (response && response.status === 200) {
      yield put(updateMessageSuccess(response.data));
    } else {
      yield put(updateMessageError(response.response.data));
    }
  } catch (e) {
    yield put(updateMessageError(e.response.data));
  }
}

function* deleteMessage(action) {
  try {
    const { id } = action.payload;
    const response = yield axiosInstance.delete(`messages/${id}`);
    if (response && response.status === 202) {
      yield put(deleteMessageSuccess());
    } else {
      yield put(deleteMessageError(response.response.data));
    }
  } catch (e) {
    yield put(deleteMessageError(e.response.data));
  }
}

export default function* () {
  yield takeLatest(getMessagesRequest, getMessages);
  yield takeLatest(storeMessageRequest, storeMessage);
  yield takeLatest(makeMessagesReadRequest, makeMessagesRead);
  yield takeLatest(updateMessageRequest, updateMessage);
  yield takeLatest(deleteMessageRequest, deleteMessage);
}
