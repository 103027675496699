import { handleActions } from 'redux-actions';
import {
  getCompanyStaffRequest,
  getCompanyStaffSuccess,
  getCompanyStaffError,
  getSelectedStaffInfoRequest,
  getSelectedStaffInfoSuccess,
  getSelectedStaffInfoError,
  getStaffPositionsRequest,
  getStaffPositionsSuccess,
  getStaffPositionsError,
  inviteStaffToCompanyRequest,
  inviteStaffToCompanyError,
  inviteStaffToCompanySuccess,
  revertInviteStaffToCompanyToDefault,
  addStaffToCompanyRequest,
  addStaffToCompanySuccess,
  addStaffToCompanyError,
  revertAddStaffToCompanyToDefault,
  deleteStaffRequest,
  deleteStaffSuccess,
  deleteStaffError,
  revertDeleteStaffToDefault,
  updateStaffRequest,
  updateStaffSuccess,
  updateStaffError,
  revertUpdateStaffToDefault,
  updateInvitationRequest,
  updateInvitationSuccess,
  updateInvitationError,
  revertUpdateInvitationToDefault,
  deleteStaffInvitationRequest,
  deleteStaffInvitationSuccess,
  deleteStaffInvitationError,
  revertDeleteInvitationToDefault,
  updateStaffPermissionsRequest,
  updateStaffPermissionsSuccess,
  updateStaffPermissionsError,
  revertStaffPermissionsToDefault,
  getStaffAvailableTimesRequest,
  getStaffAvailableTimesSuccess,
  getStaffAvailableTimesError,
  acceptStaffInvitationRequest,
  acceptStaffInvitationSuccess,
  acceptStaffInvitationError,
  inviteExistingStaffRequest,
  inviteExistingStaffSuccess,
  inviteExistingStaffError,
  revertInviteExistingStaffToDefault,
  resendStaffInvitationRequest,
  resendStaffInvitationSuccess,
  resendStaffInvitationError,
  filterStaffOptionsRequest,
  filterStaffOptionsSuccess,
  filterStaffOptionsError,
  getStaffCurrentServicesRequest,
  getStaffCurrentServicesSuccess,
  getStaffCurrentServicesError,
  getCalendarCompanyStaffRequest,
  getCalendarCompanyStaffSuccess,
  getCalendarCompanyStaffError,
  getStaffUpcomingAppointmentsRequest,
  getStaffUpcomingAppointmentsSuccess,
  getStaffUpcomingAppointmentsError,
  getStaffAppointmentsHistoryRequest,
  getStaffAppointmentsHistorySuccess,
  getStaffAppointmentsHistoryError,
  getCompanyStaffHaveServiceRequest,
  getCompanyStaffHaveServiceSuccess,
  getCompanyStaffHaveServiceError,
  getCompanyStaffForSelectRequest,
  getCompanyStaffForSelectError,
  getCompanyStaffForSelectSuccess,
  addProfessionRequest,
  addProfessionSuccess,
  addProfessionError,
} from './actions';

const initialState = {
  isGetCompanyStaffSuccess: false,
  isGetCompanyStaffError: false,
  companyStaff: [],
  isGetCompanyStaffSelectSuccess: false,
  isGetCompanyStaffSelectError: false,
  companyStaffForSelect: [],
  isGetCompanyStaffHaveServiceSuccess: false,
  isGetCompanyStaffHaveServiceError: false,
  companyStaffHaveService: [],
  isGetStaffInfoSuccess: false,
  isGetStaffInfoError: false,
  staffInfo: [],
  isGetStaffPositionsSuccess: false,
  isGetStaffPositionsError: false,
  positions: [],
  isInviteStaffToCompanySuccess: false,
  isInviteStaffToCompanyError: false,
  storedInvitation: {},
  inviteStaffErrorMessage: '',
  isAddStaffToCompanySuccess: false,
  isAddStaffToCompanyError: false,
  addStaffErrorMessage: '',
  addedStaff: {},
  addCompanyStaffErrors: {},
  isDeleteStaffSuccess: false,
  isDeleteStaffError: false,
  deleteStaffErrorMessage: '',
  isUpdateStaffSuccess: false,
  isUpdateStaffError: false,
  updatedStaff: {},
  updateStaffErrorMessage: '',
  isUpdateInvitationSuccess: false,
  isUpdateInvitationError: false,
  updatedInvitation: {},
  updateStaffInvitationSucceed: {},
  updateInvitationErrorMessage: '',
  updateInvitationErrors: {},
  isDeleteStaffInvitationSuccess: false,
  isDeleteStaffInvitationError: false,
  deleteStaffInvitationErrorMessage: '',
  isUpdateStaffPermissionsSuccess: false,
  isUpdateStaffPermissionsError: false,
  updateStaffPermissionsErrorMessage: '',
  updatedPagesAccess: [],
  isGetStaffAvailableTimesSuccess: false,
  isGetStaffAvailableTimesError: false,
  availableTimes: [],
  isAcceptStaffInvitationSuccess: false,
  isAcceptStaffInvitationError: false,
  acceptStaffInvitationErrorMessage: '',
  invitedStaff: {},
  isInvitedExistingStaffSuccess: false,
  isInvitedExistingStaffError: false,
  isInvitedExistingStaffErrorMessage: '',
  staffInvitation: {},
  isResendStaffInvitationSuccess: false,
  isResendStaffInvitationError: false,
  isFilterStaffSuccess: false,
  isFilterStaffError: false,
  filteredStaffOption: {},
  isGetStaffCurrentServicesSuccess: false,
  isGetStaffCurrentServicesError: false,
  staffWithCurrentServices: [],
  isGetCalendarCompanyStaffSuccess: false,
  isGetCalendarCompanyStaffError: false,
  getCalendarCompanyStaffErrorMessage: '',
  calendarCompanyStaff: [],
  staffUpcomingAppointments: [],
  staffUpcomingAppointmentsCount: 0,
  isGetStaffUpcomingAppointmentsSuccess: false,
  isGetStaffUpcomingAppointmentsError: false,
  getStaffUpcomingAppointmentsErrorMessage: '',
  staffAppointmentsHistory: [],
  staffAppointmentsHistoryCount: 0,
  isGetStaffAppointmentsHistorySuccess: false,
  isGetStaffAppointmentsHistoryError: false,
  getStaffAppointmentsHistoryErrorMessage: '',
  isAddProfessionSuccess: true,
  isAddProfessionError: false,
};

const reducer = handleActions({
  [getCompanyStaffRequest]: (state) => ({
    ...state,
    isGetCompanyStaffSuccess: false,
    isGetCompanyStaffError: false,
  }),
  [getCompanyStaffSuccess]: (state, { payload }) => ({
    ...state,
    isGetCompanyStaffSuccess: true,
    isGetCompanyStaffError: false,
    companyStaff: payload.data,
    companyStaffCount: payload.total,
  }),
  [getCompanyStaffError]: (state) => ({
    ...state,
    isGetCompanyStaffSuccess: false,
    isGetCompanyStaffError: true,
    companyStaff: [],
  }),
  [getCompanyStaffForSelectRequest]: (state) => ({
    ...state,
    isGetCompanyStaffSelectSuccess: false,
    isGetCompanyStaffSelectError: false,
  }),
  [getCompanyStaffForSelectSuccess]: (state, { payload }) => ({
    ...state,
    isGetCompanyStaffSelectSuccess: true,
    isGetCompanyStaffSelectError: false,
    companyStaffForSelect: payload.data,
  }),
  [getCompanyStaffForSelectError]: (state) => ({
    ...state,
    isGetCompanyStaffSelectSuccess: false,
    isGetCompanyStaffSelectError: true,
    companyStaffForSelect: [],
  }),
  [getCompanyStaffHaveServiceRequest]: (state) => ({
    ...state,
    isGetCompanyStaffHaveServiceSuccess: false,
    isGetCompanyStaffHaveServiceError: false,
  }),
  [getCompanyStaffHaveServiceSuccess]: (state, { payload }) => ({
    ...state,
    isGetCompanyStaffHaveServiceSuccess: true,
    isGetCompanyStaffHaveServiceError: false,
    companyStaffHaveService: payload.data,
    companyStaffHaveServiceCount: payload.total,
  }),
  [getCompanyStaffHaveServiceError]: (state) => ({
    ...state,
    isGetCompanyStaffHaveServiceSuccess: false,
    isGetCompanyStaffHaveServiceError: true,
    companyStaffHaveService: [],
  }),
  [getSelectedStaffInfoRequest]: (state) => ({
    ...state,
    isGetStaffInfoSuccess: false,
    isGetStaffInfoError: false,
    staffInfo: [],
  }),
  [getSelectedStaffInfoSuccess]: (state, { payload }) => ({
    ...state,
    isGetStaffInfoSuccess: true,
    isGetStaffInfoError: false,
    staffInfo: payload.data,
  }),
  [getSelectedStaffInfoError]: (state) => ({
    ...state,
    isGetStaffInfoSuccess: false,
    isGetStaffInfoError: true,
    staffInfo: [],
  }),
  [getStaffPositionsRequest]: (state) => ({
    ...state,
    isGetStaffPositionsSuccess: false,
    isGetStaffPositionsError: false,
    positions: [],
  }),
  [getStaffPositionsSuccess]: (state, { payload }) => ({
    ...state,
    isGetStaffPositionsSuccess: true,
    isGetStaffPositionsError: false,
    positions: payload.data,
  }),
  [getStaffPositionsError]: (state) => ({
    ...state,
    isGetStaffPositionsSuccess: false,
    isGetStaffPositionsError: true,
    positions: [],
  }),
  [inviteStaffToCompanyRequest]: (state) => ({
    ...state,
    isInviteStaffToCompanySuccess: false,
    isInviteStaffToCompanyError: false,
    storedInvitation: {},
  }),
  [revertInviteStaffToCompanyToDefault]: (state) => ({
    ...state,
    isInviteStaffToCompanySuccess: false,
    isInviteStaffToCompanyError: false,
    storedInvitation: {},
  }),
  [inviteStaffToCompanySuccess]: (state, { payload }) => ({
    ...state,
    isInviteStaffToCompanySuccess: true,
    isInviteStaffToCompanyError: false,
    storedInvitation: payload.data,
  }),
  [inviteStaffToCompanyError]: (state, { payload }) => ({
    ...state,
    isInviteStaffToCompanySuccess: false,
    isInviteStaffToCompanyError: true,
    storedInvitation: {},
    inviteStaffErrorMessage: payload.errors,
  }),
  [revertAddStaffToCompanyToDefault]: (state) => ({
    ...state,
    isAddStaffToCompanySuccess: false,
    isAddStaffToCompanyError: false,
    addedStaff: {},
    addStaffErrorMessage: '',
  }),
  [addStaffToCompanyRequest]: (state) => ({
    ...state,
    isAddStaffToCompanySuccess: false,
    isAddStaffToCompanyError: false,
    addedStaff: {},
    addCompanyStaffErrors: {},
    addStaffErrorMessage: '',
  }),
  [addStaffToCompanySuccess]: (state, { payload }) => ({
    ...state,
    isAddStaffToCompanySuccess: true,
    isAddStaffToCompanyError: false,
    addStaffErrorMessage: '',
    addCompanyStaffErrors: {},
    addedStaff: payload.data,
  }),
  [addStaffToCompanyError]: (state, { payload }) => ({
    ...state,
    isAddStaffToCompanySuccess: false,
    isAddStaffToCompanyError: true,
    addCompanyStaffErrors: payload.errors || {},
    addStaffErrorMessage: payload.data || '',
    addedStaff: {},
  }),
  [deleteStaffRequest]: (state) => ({
    ...state,
    isDeleteStaffSuccess: false,
    isDeleteStaffError: false,
    deleteStaffErrorMessage: '',
  }),
  [revertDeleteStaffToDefault]: (state) => ({
    ...state,
    isDeleteStaffSuccess: false,
    isDeleteStaffError: false,
    deleteStaffErrorMessage: '',
  }),
  [deleteStaffSuccess]: (state, { payload }) => ({
    ...state,
    isDeleteStaffSuccess: true,
    isDeleteStaffError: false,
    deleteStaffErrorMessage: '',
    companyStaff: payload,
  }),
  [deleteStaffError]: (state, { payload }) => ({
    ...state,
    isDeleteStaffSuccess: false,
    isDeleteStaffError: true,
    deleteStaffErrorMessage: payload.message,
  }),
  [revertUpdateStaffToDefault]: (state) => ({
    ...state,
    isUpdateStaffSuccess: false,
    isUpdateStaffError: false,
    updatedStaff: {},
    updateCompanyStaffErrors: {},
    updateStaffErrorMessage: '',
  }),
  [updateStaffRequest]: (state) => ({
    ...state,
    isUpdateStaffSuccess: false,
    isUpdateStaffError: false,
    updatedStaff: {},
    updateCompanyStaffErrors: {},
    updateStaffErrorMessage: '',
  }),
  [updateStaffSuccess]: (state, { payload }) => {
    const updatedStaffIndex = state.companyStaff.findIndex((item) => item.id === payload.data.id);
    if (updatedStaffIndex !== -1) {
      state.companyStaff[updatedStaffIndex] = payload.data;
    }

    return ({
      ...state,
      isUpdateStaffSuccess: true,
      isUpdateStaffError: false,
      updatedStaff: payload.data,
      updateCompanyStaffErrors: {} || {},
      updateStaffErrorMessage: '',
    });
  },
  [updateStaffError]: (state, { payload }) => ({
    ...state,
    isUpdateStaffSuccess: false,
    isUpdateStaffError: true,
    updatedStaff: {},
    updateCompanyStaffErrors: payload.errors,
    updateStaffErrorMessage: payload.message,
  }),
  [revertUpdateInvitationToDefault]: (state) => ({
    ...state,
    isUpdateInvitationSuccess: false,
    isUpdateInvitationError: false,
    updatedInvitation: {},
    updateInvitationErrorMessage: '',
    updateInvitationErrors: {},
  }),
  [updateInvitationRequest]: (state) => ({
    ...state,
    isUpdateInvitationSuccess: false,
    isUpdateInvitationError: false,
    updatedInvitation: {},
    updateStaffInvitationSucceed: {},
    updateInvitationErrorMessage: '',
    updateInvitationErrors: {},
  }),
  [updateInvitationSuccess]: (state, { payload }) => ({
    ...state,
    isUpdateInvitationSuccess: true,
    isUpdateInvitationError: false,
    updatedInvitation: payload.data || {},
    updateStaffInvitationSucceed: payload.data || {},
    updateInvitationErrorMessage: '',
    updateInvitationErrors: {},
  }),
  [updateInvitationError]: (state, { payload }) => ({
    ...state,
    isUpdateInvitationSuccess: false,
    isUpdateInvitationError: true,
    updatedInvitation: {},
    updateStaffInvitationSucceed: {},
    updateInvitationErrorMessage: payload.message || '',
    updateInvitationErrors: payload.errors || {},
  }),
  [revertDeleteInvitationToDefault]: (state) => ({
    ...state,
    isDeleteStaffInvitationSuccess: false,
    isDeleteStaffInvitationError: false,
    deleteStaffInvitationErrorMessage: '',
  }),
  [deleteStaffInvitationRequest]: (state) => ({
    ...state,
    isDeleteStaffInvitationSuccess: false,
    isDeleteStaffInvitationError: false,
    deleteStaffInvitationErrorMessage: '',
  }),
  [deleteStaffInvitationSuccess]: (state) => ({
    ...state,
    isDeleteStaffInvitationSuccess: true,
    isDeleteStaffInvitationError: false,
    deleteStaffInvitationErrorMessage: '',
  }),
  [deleteStaffInvitationError]: (state, { payload }) => ({
    ...state,
    isDeleteStaffInvitationSuccess: false,
    isDeleteStaffInvitationError: true,
    deleteStaffInvitationErrorMessage: payload.message,
  }),
  [updateStaffPermissionsRequest]: (state) => ({
    ...state,
    isUpdateStaffPermissionsSuccess: false,
    isUpdateStaffPermissionsError: false,
    updateStaffPermissionsErrorMessage: '',
    updatedPagesAccess: [],
  }),
  [revertStaffPermissionsToDefault]: (state) => ({
    ...state,
    isUpdateStaffPermissionsSuccess: false,
    isUpdateStaffPermissionsError: false,
    updateStaffPermissionsErrorMessage: '',
    updatedPermissions: {},
  }),
  [updateStaffPermissionsSuccess]: (state, { payload }) => ({
    ...state,
    isUpdateStaffPermissionsSuccess: true,
    isUpdateStaffPermissionsError: false,
    updateStaffErrorMessage: '',
    updatedPagesAccess: payload,
  }),
  [updateStaffPermissionsError]: (state, { payload }) => ({
    ...state,
    isUpdateStaffPermissionsSuccess: false,
    isUpdateStaffPermissionsError: true,
    updateStaffPermissionsErrorMessage: payload.message,
    updatedPagesAccess: [],
  }),
  [getStaffAvailableTimesRequest]: (state) => ({
    ...state,
    isGetStaffAvailableTimesSuccess: false,
    isGetStaffAvailableTimesError: false,
    availableTimes: [],
  }),
  [getStaffAvailableTimesSuccess]: (state, { payload }) => ({
    ...state,
    isGetStaffAvailableTimesSuccess: true,
    isGetStaffAvailableTimesError: false,
    availableTimes: payload,
  }),
  [getStaffAvailableTimesError]: (state) => ({
    ...state,
    isGetStaffAvailableTimesSuccess: false,
    isGetStaffAvailableTimesError: true,
    availableTimes: [],
  }),
  [acceptStaffInvitationRequest]: (state) => ({
    ...state,
    isAcceptStaffInvitationSuccess: false,
    isAcceptStaffInvitationError: false,
    acceptStaffInvitationErrorMessage: '',
    invitedStaff: {},
  }),
  [acceptStaffInvitationSuccess]: (state, { payload }) => ({
    ...state,
    isAcceptStaffInvitationSuccess: true,
    isAcceptStaffInvitationError: false,
    acceptStaffInvitationErrorMessage: '',
    invitedStaff: payload.data,
  }),
  [acceptStaffInvitationError]: (state, { payload }) => ({
    ...state,
    isAcceptStaffInvitationSuccess: false,
    isAcceptStaffInvitationError: true,
    acceptStaffInvitationErrorMessage: payload && payload.errors && payload.errors.token ? `${payload.errors.token[0]} !!!` : payload.message,
    invitedStaff: {},
  }),
  [revertInviteExistingStaffToDefault]: (state) => ({
    ...state,
    isInvitedExistingStaffSuccess: false,
    isInvitedExistingStaffError: false,
    isInvitedExistingStaffErrorMessage: '',
  }),
  [inviteExistingStaffRequest]: (state) => ({
    ...state,
    isInvitedExistingStaffSuccess: false,
    isInvitedExistingStaffError: false,
    isInvitedExistingStaffErrorMessage: '',
  }),
  [inviteExistingStaffSuccess]: (state, { payload }) => ({
    ...state,
    isInvitedExistingStaffSuccess: true,
    isInvitedExistingStaffError: false,
    isInvitedExistingStaffErrorMessage: '',
    staffInvitation: payload.data,
  }),
  [inviteExistingStaffError]: (state, { payload }) => ({
    ...state,
    isInvitedExistingStaffSuccess: false,
    isInvitedExistingStaffError: true,
    isInvitedExistingStaffErrorMessage: payload.message,
  }),
  [resendStaffInvitationRequest]: (state) => ({
    ...state,
    isResendStaffInvitationSuccess: false,
    isResendStaffInvitationError: false,
  }),
  [resendStaffInvitationSuccess]: (state) => ({
    ...state,
    isResendStaffInvitationSuccess: true,
    isResendStaffInvitationError: false,
  }),
  [resendStaffInvitationError]: (state) => ({
    ...state,
    isResendStaffInvitationSuccess: false,
    isResendStaffInvitationError: true,
  }),
  [filterStaffOptionsRequest]: (state) => ({
    ...state,
    isFilterStaffSuccess: false,
    isFilterStaffError: false,
  }),
  [filterStaffOptionsSuccess]: (state, { payload }) => ({
    ...state,
    isFilterStaffSuccess: true,
    isFilterStaffError: false,
    filteredStaffOption: payload.data,
  }),
  [filterStaffOptionsError]: (state) => ({
    ...state,
    isFilterStaffSuccess: false,
    isFilterStaffError: true,
    filteredStaffOption: {},
  }),
  [getStaffCurrentServicesRequest]: (state) => ({
    ...state,
    isGetStaffCurrentServicesSuccess: false,
    isGetStaffCurrentServicesError: false,
    staffWithCurrentServices: [],
  }),
  [getStaffCurrentServicesSuccess]: (state, { payload }) => ({
    ...state,
    isGetStaffCurrentServicesSuccess: true,
    isGetStaffCurrentServicesError: false,
    staffWithCurrentServices: payload.data,
  }),
  [getStaffCurrentServicesError]: (state) => ({
    ...state,
    isGetStaffCurrentServicesSuccess: false,
    isGetStaffCurrentServicesError: true,
    staffWithCurrentServices: [],
  }),
  [getCalendarCompanyStaffRequest]: (state) => ({
    ...state,
    isGetCalendarCompanyStaffSuccess: false,
    isGetCalendarCompanyStaffError: false,
    getCalendarCompanyStaffErrorMessage: '',
    calendarCompanyStaff: [],
  }),
  [getCalendarCompanyStaffSuccess]: (state, { payload }) => ({
    ...state,
    isGetCalendarCompanyStaffSuccess: true,
    isGetCalendarCompanyStaffError: false,
    getCalendarCompanyStaffErrorMessage: '',
    calendarCompanyStaff: payload.data,
  }),
  [getCalendarCompanyStaffError]: (state, { payload }) => ({
    ...state,
    isGetCalendarCompanyStaffSuccess: false,
    isGetCalendarCompanyStaffError: true,
    getCalendarCompanyStaffErrorMessage: payload.message,
    calendarCompanyStaff: [],
  }),
  [getStaffUpcomingAppointmentsRequest]: (state) => ({
    ...state,
    staffUpcomingAppointments: [],
    staffUpcomingAppointmentsCount: 0,
    isGetStaffUpcomingAppointmentsSuccess: false,
    isGetStaffUpcomingAppointmentsError: false,
    getStaffUpcomingAppointmentsErrorMessage: '',
  }),
  [getStaffUpcomingAppointmentsSuccess]: (state, { payload }) => ({
    ...state,
    staffUpcomingAppointments: payload.data,
    staffUpcomingAppointmentsCount: payload.total,
    isGetStaffUpcomingAppointmentsSuccess: true,
  }),
  [getStaffUpcomingAppointmentsError]: (state, { payload }) => ({
    ...state,
    isGetStaffUpcomingAppointmentsError: true,
    getStaffUpcomingAppointmentsErrorMessage: payload.message,
  }),
  [getStaffAppointmentsHistoryRequest]: (state) => ({
    ...state,
    staffAppointmentsHistory: [],
    staffAppointmentsHistoryCount: 0,
    isGetStaffAppointmentsHistorySuccess: false,
    isGetStaffAppointmentsHistoryError: false,
    getStaffAppointmentsHistoryErrorMessage: '',
  }),
  [getStaffAppointmentsHistorySuccess]: (state, { payload }) => ({
    ...state,
    staffAppointmentsHistory: payload.data,
    staffAppointmentsHistoryCount: payload.total,
    isGetStaffAppointmentsHistorySuccess: true,
  }),
  [getStaffAppointmentsHistoryError]: (state, { payload }) => ({
    ...state,
    isGetStaffAppointmentsHistoryError: true,
    getStaffAppointmentsHistoryErrorMessage: payload.message,
  }),
  [addProfessionRequest]: (state) => ({
    ...state,
    isAddProfessionSuccess: false,
    isAddProfessionError: false,
  }),
  [addProfessionSuccess]: (state) => ({
    ...state,
    isAddProfessionSuccess: true,
    isAddProfessionError: false,
  }),
  [addProfessionError]: (state) => ({
    ...state,
    isAddProfessionSuccess: false,
    isAddProfessionError: true,
  }),
}, initialState);

export default reducer;
