import React from 'react';
import {
  Box,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SendIcon from '@material-ui/icons/Send';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import MoodIcon from '@material-ui/icons/Mood';

function SubmitMessage(data) {
  const {
    newMessage,
    handleSendMessage,
    handleMessageTextChange,
    handleUpdateMessage,
    emptySendMessage,
  } = data;

  const sendMessage = (e) => {
    // While clicking enter+shift, it moves to a new line
    const keyCode = e.which || e.keyCode;
    if (e.key === 'Enter' && keyCode === 13 && !e.shiftKey) {
      handleSendMessage();
      e.preventDefault();
      e.target.value = '';
    }
  };

  return (
    <div>
      <div className="message-send">
        <Box display="flex" alignItems="flex-end">
          <div className="attach-item">
            <IconButton>
              <AttachFileIcon />
            </IconButton>
          </div>
          <div className="photo-item">
            <IconButton>
              <CropOriginalIcon />
            </IconButton>
          </div>
          <div>
            <IconButton>
              <MoodIcon />
            </IconButton>
          </div>
        </Box>
        <div className="send-message">
          <div className="type-message">
            <textarea
              onKeyUp={sendMessage}
              value={newMessage.text}
              onChange={(event) => handleMessageTextChange(event)}
            />
          </div>
          <Box display="flex" alignItems="flex-end">
            <IconButton
              disabled={emptySendMessage}
              variant="contained"
              color="primary"
              type="submit"
              onClick={newMessage.id ? () => handleUpdateMessage() : () => handleSendMessage()}
            >
              {newMessage.id ? <EditIcon /> : <SendIcon />}
            </IconButton>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default SubmitMessage;
