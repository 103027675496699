import React, { useContext } from 'react';

import {
  ListItemText,
  Menu,
  ListItem,
  MenuItem,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import deleteIcon from 'assets/icons/delete.svg';
import editIcon from 'assets/icons/edit.svg';
import ServiceContext from './serviceContext';

function CategoryItem({
  index, category, classes,
}) {
  const {
    handleSelectCategory,
    selectedCategoryIndex,
    handleDeleteCategory,
    handleEditCategory,
    setSelectedCategory,
  } = useContext(ServiceContext);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <ListItem
        button
        key={category.id}
        onClick={() => handleSelectCategory(category.id, category.name, index)}
        selected={selectedCategoryIndex === index}
        classes={{ root: classes.root, selected: classes.selected }}
        className="list-item-text"
      >
        <ListItemText primary={category.name} classes={{ primary: classes.text }} />

        {category.user_id && (
          <IconButton
            aria-label="more"
            aria-controls={`${category.name}-${category.id}`}
            aria-haspopup="true"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MoreVertIcon />
          </IconButton>
        )}
      </ListItem>
      <Menu
        id={`${category.name}-${category.id}`}
        elevation={1}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => {
          setAnchorEl(null);
          setSelectedCategory(null);
        }}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={() => {
          setAnchorEl(null);
          handleDeleteCategory(category);
        }}
        >
          <img src={deleteIcon} alt="delete" />
          <span className="menu-delete-btn">
            {t('Delete')}
          </span>
        </MenuItem>
        <MenuItem onClick={() => {
          setAnchorEl(null);
          handleEditCategory(category);
        }}
        >
          <img src={editIcon} alt="edit" />
          <span className="menu-delete-btn" style={{ color: '#393939' }}>
            {t('Edit')}
          </span>
        </MenuItem>
      </Menu>
    </>
  );
}

CategoryItem.propTypes = {
  index: PropTypes.number.isRequired,
  category: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default CategoryItem;
