import React from 'react';
import {
  Grid,
  Button,
  MenuItem,
  IconButton,
  FormControl,
  FormHelperText,
  Select,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  InputLabel,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import TextMaskCustom from '../../../Modules/TextMaskCustom';

const AddCustomerModal = (props) => {
  const {
    fromModal,
    customerItem,
    checkedLogin,
    currentIndustryId,
    phoneCodeOptions,
    handleUpdateCustomer,
    selectedPhoneCode,
    handleInputChange,
    industryOptions,
    customerIndustry,
    validationErrors,
    isAddCustomerModalOpen,
    handleChangeCustomerLogin,
    handleSelectChangeForCode,
    handleCloseAddCustomerModal,
    handleInviteCustomerFormSubmit,
    handleCreateCustomerFormSubmit,
    handleSelectIndustryForCustomer,
  } = props;

  return (
    <Dialog
      size="sm"
      open={isAddCustomerModalOpen}
      onClose={handleCloseAddCustomerModal}
    >
      <DialogTitle>
        <span className="alert-title">
          <span>{customerItem.id && !checkedLogin ? i18n.t('EditCustomer') : checkedLogin ? 'Invite to Register' : 'Add Customer'}</span>
          <span>
            <IconButton aria-label="close" className="close-btn" onClick={handleCloseAddCustomerModal}>
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {customerItem.id
            ? (
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <span className="invite-customer-text">
                    Invite
                    <span className="invite-customer-text-name"> {customerItem.first_name} </span>
                    <span className="invite-customer-text-name"> {customerItem.last_name} </span>
                    to register on SetTime.
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(validationErrors.email)}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      data-cy="email"
                      label={checkedLogin ? 'Email address *' : 'Email address *'}
                      error={checkedLogin ? validationErrors.email : ''}
                      id="outlined-size-normal"
                      variant="outlined"
                      type="text"
                      name="email"
                      value={customerItem.email || ''}
                      onChange={handleInputChange}
                    />
                    {checkedLogin ? validationErrors.email && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.email}
                        </FormHelperText>
                      </div>
                    ) : ''}
                  </FormControl>
                </Grid>
              </Grid>
            )
            : (
              <>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(validationErrors.first_name)}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      label="First name"
                      data-cy="firstName"
                      data-customer="first_name"
                      variant="outlined"
                      type="text"
                      name="first_name"
                      error={validationErrors.first_name || ''}
                      value={customerItem.first_name}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: 20 }}
                    />
                    {validationErrors.first_name && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.first_name}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(validationErrors.last_name)}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      label="Last name"
                      data-cy="lastName"
                      data-customer="last_name"
                      id="outlined-size-normal"
                      variant="outlined"
                      type="text"
                      name="last_name"
                      error={validationErrors.last_name || ''}
                      value={customerItem.last_name}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: 20 }}
                    />
                    {validationErrors.last_name && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.last_name}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                {!currentIndustryId && (
                  <Grid item xs={12}>
                    <FormControl
                      size="small"
                      variant="outlined"
                      fullWidth
                      error={(validationErrors.individual_user_industry_id)}
                    >
                      <InputLabel>Industry *</InputLabel>
                      <Select
                        name="customerToIndustry"
                        id="customerToIndustry"
                        error={validationErrors.individual_user_industry_id || ''}
                        onChange={(e) => handleSelectIndustryForCustomer(e, 'industry')}
                        value={customerIndustry.industry.value}
                        label="Industry *"
                      >
                        <MenuItem disabled value=""><em>Select Industry</em></MenuItem>
                        {industryOptions && industryOptions.map((option, index) => (
                          <MenuItem
                            key={`${option.id}-${index}`}
                            value={option.value}
                            selected={customerIndustry.industry.value === option.value}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {validationErrors.individual_user_industry_id && (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText>
                            {validationErrors.individual_user_industry_id}
                          </FormHelperText>
                        </div>
                      )}
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(validationErrors.phone_code)}
                  >
                    <InputLabel>Code *</InputLabel>
                    <Select
                      name="phone_code"
                      label="Code *"
                      onChange={(event) => {
                        handleSelectChangeForCode(event);
                      }}
                    >
                      <MenuItem disabled value=""><em>{i18n.t('PhoneCode')}</em></MenuItem>
                      {phoneCodeOptions && phoneCodeOptions.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={option.value}
                          selected={selectedPhoneCode.value === option.value}
                        >
                          <img
                            src={option.label.flagImageSrc}
                            alt="flag"
                            className="country-flag"
                          />
                          {option.key}
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>
                    {validationErrors.phone_code && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.phone_code}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(validationErrors.phone_number)}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      data-cy="phone"
                      id="phone-detail"
                      data-customer="phone_number"
                      type="text"
                      name="phone_number"
                      error={validationErrors.phone_number || ''}
                      value={customerItem.phone_number || ''}
                      onChange={handleInputChange}
                      label="Phone number *"
                      variant="outlined"
                      InputProps={{
                        inputComponent: TextMaskCustom,
                      }}
                    />
                    {validationErrors.phone_number && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.phone_number}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                {!fromModal
                  ? (
                    <>
                      <Grid item xs={12}>
                        <FormControl component="fieldset">
                          <FormControlLabel
                            value={checkedLogin}
                            control={<Checkbox color="primary" checked={checkedLogin} />}
                            label="Invite to register"
                            labelPlacement="register"
                            onChange={handleChangeCustomerLogin}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        {checkedLogin
                          ? (
                            <FormControl
                              fullWidth
                              size="small"
                              variant="outlined"
                              error={(validationErrors.email)}
                            >
                              <TextField
                                fullWidth
                                size="small"
                                data-cy="email"
                                error={checkedLogin ? validationErrors.email : ''}
                                label={checkedLogin ? 'Email address *' : 'Email address *'}
                                id="outlined-size-normal"
                                variant="outlined"
                                type="text"
                                name="email"
                                value={customerItem.email || ''}
                                onChange={handleInputChange}
                              />
                              {checkedLogin ? validationErrors.email && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  {validationErrors.email}
                                </FormHelperText>
                              </div>
                              ) : ''}
                            </FormControl>
                          ) : ''}
                      </Grid>
                    </>
                  )
                  : null}
              </>
            )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          data-cy="cancel"
          onClick={handleCloseAddCustomerModal}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          data-cy="send"
          onClick={customerItem.id && !checkedLogin ? handleUpdateCustomer : checkedLogin ? () => handleInviteCustomerFormSubmit(checkedLogin) : () => handleCreateCustomerFormSubmit(checkedLogin)}
        >
          {customerItem.id && !checkedLogin ? 'Update' : checkedLogin ? 'Send' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddCustomerModal.propTypes = {
  // Parent props
  fromModal: PropTypes.bool.isRequired,
  customerItem: PropTypes.object.isRequired,
  checkedLogin: PropTypes.bool.isRequired,
  industryOptions: PropTypes.array.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  phoneCodeOptions: PropTypes.array.isRequired,
  validationErrors: PropTypes.object.isRequired,
  customerIndustry: PropTypes.object.isRequired,
  selectedPhoneCode: PropTypes.object.isRequired,
  handleUpdateCustomer: PropTypes.func.isRequired,
  isAddCustomerModalOpen: PropTypes.bool.isRequired,
  handleChangeCustomerLogin: PropTypes.func.isRequired,
  handleSelectChangeForCode: PropTypes.func.isRequired,
  handleCloseAddCustomerModal: PropTypes.func.isRequired,
  handleInviteCustomerFormSubmit: PropTypes.func.isRequired,
  handleCreateCustomerFormSubmit: PropTypes.func.isRequired,
  handleSelectIndustryForCustomer: PropTypes.func.isRequired,
  currentIndustryId: PropTypes.oneOfType([PropTypes.number, PropTypes.object]).isRequired,
};

export default AddCustomerModal;
