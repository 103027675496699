import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordRequest } from 'redux/auth/actions';
import {
  InputLabel,
  OutlinedInput,
  FormControl,
  Box,
  InputAdornment,
  IconButton,
  Button,
  Grid,
  CircularProgress, makeStyles,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import usePrevious from 'CustomHooks/usePrevious';
import i18n from 'i18next';
import Layout from '../Layout';

const useStyles = makeStyles(() => ({
  root: {
    '&.Mui-error': {
      borderColor: 'green',
    },
    '&.MuiFormHelperText-root': {
      color: 'yellow',
    },
  },
}));

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const classes = useStyles();

  const {
    isResetPasswordSuccess,
    isResetPasswordError,
    resetPasswordErrors,
    verificationCodeData,
  } = useSelector((state) => state.auth);

  const prevIsResetPasswordSuccess = usePrevious(isResetPasswordSuccess);
  const prevIsResetPasswordError = usePrevious(isResetPasswordError);

  const [password, setPassword] = useState({ newPassword: '', confirmPassword: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isErrorExist, setIsErrorExist] = useState(false);
  const [passwordError, setPasswordError] = useState({
    newPassword: { value: '', similar: false },
    confirmPassword: {
      characters: false,
      similar: false,
      uppercase: false,
      lowercase: false,
      digit: false,
      isRequired: false,
    },
  });
  const [resetPasswordData, setResetPasswordData] = useState({});

  useEffect(() => {
    if (prevIsResetPasswordSuccess === false && isResetPasswordSuccess) {
      history.push('/login');
      setLoading(false);
      localStorage.removeItem('forgotPasswordEmail');
    } else if (prevIsResetPasswordError === false && isResetPasswordError) {
      setLoading(false);
      console.log('resetPasswordErrors: ', resetPasswordErrors);
    }
  }, [isResetPasswordSuccess, isResetPasswordError]);

  useEffect(() => {
    const prevData = JSON.parse(localStorage.getItem('forgotPasswordEmail'));

    if (verificationCodeData.token) {
      localStorage.setItem('forgotPasswordEmail', JSON.stringify({ ...prevData, token: verificationCodeData.token }));
    }
    setResetPasswordData(JSON.parse(localStorage.getItem('forgotPasswordEmail')));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const validate = validateField();
    if (!validate) {
      setPasswordError({
        newPassword: { value: '', similar: false },
        confirmPassword: {
          characters: false,
          similar: false,
          uppercase: false,
          lowercase: false,
          digit: false,
          isRequired: false,
        },
      });
      setIsErrorExist(false);

      const data = {
        token: resetPasswordData.token,
        verification_code: resetPasswordData.verificationCode,
        password: password.newPassword,
        password_confirmation: password.confirmPassword,
      };

      dispatch(resetPasswordRequest(data));
    } else {
      console.log(passwordError);
      setIsErrorExist(true);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setPassword(
      { ...password, [name]: value },
    );
  };

  const handleClickShowPassword = (type) => {
    if (type === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validateField = () => {
    const errors = { ...passwordError };

    // Confirm Password
    errors.confirmPassword.isRequired = password.confirmPassword.length === 0;
    errors.confirmPassword.similar = password.confirmPassword !== password.newPassword;
    errors.confirmPassword.characters = !/(?=.{8,})/.test(
      password.confirmPassword,
    );
    errors.confirmPassword.uppercase = !/(?=.*[A-Z])/.test(
      password.confirmPassword,
    );
    errors.confirmPassword.lowercase = !/(?=.*[a-z])/.test(
      password.confirmPassword,
    );
    errors.confirmPassword.digit = !/(?=.*[0-9])/.test(
      password.confirmPassword,
    );

    setPasswordError(errors);
    return (
      Object.values(errors.newPassword).some((error) => error === true)
        || Object.values(errors.confirmPassword).some((error) => error === true)
    );
  };

  return (
    <>
      <Layout>
        <Box className="form-container">
          <Box className="form-box">
            <Box p="50px">
              <p className="title">
                {i18n.t('ResetPassword')}
              </p>
              <p className="sub-title">
                {i18n.t('NewPassMustBeDifferentText')}
              </p>
              <form action="" method="" onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2} className="reset-pass-email-input">
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="outlined-adornment-password">{i18n.t('NewPassword')}</InputLabel>
                      <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        id="icon-eye"
                        name="newPassword"
                        label={i18n.t('NewPassword')}
                        value={password.newPassword}
                        onChange={handleChange}
                        className={classes.root}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('password')}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <Visibility style={{ color: isErrorExist ? '#F12B2C' : '#393939' }} fontSize="small" /> : <VisibilityOff style={{ color: isErrorExist ? '#F12B2C' : '#393939' }} fontSize="small" />}
                            </IconButton>
                          </InputAdornment>
                            )}
                        error={isErrorExist}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="outlined-adornment-password">{i18n.t('ConfirmPassword')}</InputLabel>
                      <OutlinedInput
                        error={isErrorExist}
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="eye-icon"
                        name="confirmPassword"
                        label={i18n.t('ConfirmPassword')}
                        value={password.confirmPassword}
                        onChange={handleChange}
                        className={classes.root}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword('confirm')}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? <Visibility style={{ color: isErrorExist ? '#F12B2C' : '#393939' }} fontSize="small" /> : <VisibilityOff style={{ color: isErrorExist ? '#F12B2C' : '#393939' }} fontSize="small" />}
                            </IconButton>
                          </InputAdornment>
                            )}
                      />
                    </FormControl>
                    {passwordError.confirmPassword.similar && <p className="error-message">New and confirm passwords should be the same.</p>}
                  </Grid>
                </Grid>
                <Box className="password-detail">
                  <Box>
                    <p className={passwordError.confirmPassword.characters ? 'error-text' : 'text'}>
                        8
                    </p>
                    <p className={passwordError.confirmPassword.characters ? 'error-text' : 'text'}>
                      {i18n.t('Characters')}
                    </p>
                  </Box>
                  <Box>
                    <p className={passwordError.confirmPassword.uppercase ? 'error-text' : 'text'}>
                        A
                    </p>
                    <p className={passwordError.confirmPassword.uppercase ? 'error-text' : 'text'}>
                      {i18n.t('Uppercase')}
                    </p>
                  </Box>
                  <Box>
                    <p className={passwordError.confirmPassword.lowercase ? 'error-text' : 'text'}>
                        a
                    </p>
                    <p className={passwordError.confirmPassword.lowercase ? 'error-text' : 'text'}>
                      {i18n.t('Lowercase')}
                    </p>
                  </Box>
                  <Box>
                    <p className={passwordError.confirmPassword.digit ? 'error-text' : 'text'}>
                        123
                    </p>
                    <p className={passwordError.confirmPassword.digit ? 'error-text' : 'text'}>
                      {i18n.t('Digit')}
                    </p>
                  </Box>
                </Box>
                <Box className="sign-in-btn">
                  <Button
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    data-cy="sign-in"
                    type="submit"
                    loading={loading}
                    fullWidth
                  >
                    {loading && <CircularProgress color="white" size={20} />}
                    {!loading && i18n.t('Confirm')}
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default ResetPassword;
