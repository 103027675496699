import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './customers.scss';
import '../../../../../style/general.scss';
import {
  Card,
  Menu,
  AppBar,
  Tooltip,
  TextField,
  ListItemText,
  Box,
  Badge,
  Button,
  Toolbar,
  MenuItem,
  CardMedia,
  CardHeader,
  Typography,
  IconButton,
  CardContent,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import withStyles from '@material-ui/core/styles/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import TuneRoundedIcon from '@material-ui/icons/Tune';
import i18n from 'i18next';
import usePrevious from '../../../../../CustomHooks/usePrevious';
import {
  getIndividualCustomersRequest,
  deleteIndividualUserInvitationRequest,
  updateIndividualUserInvitationRequest,
  getAllIndividualCustomerInvitationsRequest,
} from '../../../../../redux/customer/actions';
import {
  resendCustomerEmailRequest,
  storeCustomerToIndustryRequest,
  updateIndividualCustomerRequest,
  inviteCustomerToIndustryRequest,
  deleteCustomerFromIndustryRequest,
  getIndividualUserIndustriesRequest,
  getIndividualUserIndustryByIdRequest,
  filterIndividualCustomerByStatusRequest,
} from '../../../../../redux/individualUser/actions';
import { getAppointmentsRequest } from '../../../../../redux/appointment/actions';
import countryCodeOptions from '../../../../../Modules/countryCodeOptions';
import noCustomers from '../../../../../assets/img/no-customer.svg';
import BoxLoading from '../../../../../Components/Loading/BoxLoading';
import LightTooltip from '../../../../../MaterialComponents/lightTooltip';
import firstLetter from '../../../../../Modules/getFirstLetter';
import SnackbarToast from '../../../../../Modules/SnackbarToast';
import AddCustomerModal from '../../../../../Modals/IndividualUser/Customer/addCustomerModal';
import DeleteCustomerModal from '../../../../../Modals/IndividualUser/DeleteCustomerModal/index';
import CustomerDropdownMenu from '../../../../../Modals/IndividualUser/Customer/customerDropdownMenu';
import CustomerIsolatedDropdown from '../../../../../Modals/IndividualUser/Customer/customerIsolatedDropdown';
import PreviewCustomerModal from '../../../../../Modals/Customers/Preview';
import FilterCustomerOptions from '../../../../../Modals/Customers/FilterCustomerOptions';
import EditCustomerModal from '../../../../../Modals/IndividualUser/Customer/editCustomerModal';
import ResendCustomer from '../../../../../Modals/IndividualUser/Customer/resendCustomer';
import ExistingCustomer from '../../../../../Modals/IndividualUser/Customer/existingCustomer';

function customers(props) {
  const dispatch = useDispatch();

  const {
    history,
  } = props;

  const {
    allCountries,
  } = useSelector((state) => state.country);

  const {
    userAccount,
  } = useSelector((state) => state.account);

  const {
    allAppointments,
    isGetAppointmentsError,
    isGetAppointmentsSuccess,
  } = useSelector((state) => state.appointment);

  const {
    isGetAllCustomersError,
    allIndividualCustomers,
    isDeleteIndividualCustomerError,
    updatedIndividualUserInvitation,
    allIndustriesCustomerInvitations,
    isGetAllCustomerInvitationsError,
    isDeleteIndividualCustomerSuccess,
    isGetAllIndividualInvitationsError,
    isGetAllIndividualCustomersSuccess,
    isGetAllIndividualInvitationsSuccess,
    deleteIndividualCustomerErrorMessage,
    updateIndividualUserInvitationSucceed,
    isDeleteIndividualUserInvitationError,
    isUpdateIndividualUserInvitationError,
    isUpdateIndividualUserInvitationSuccess,
    isDeleteIndividualUserInvitationSuccess,
    updateIndividualUserInvitationErrorMessage,
    deleteIndividualUserInvitationErrorMessage,
  } = useSelector((state) => state.customers);

  const {
    newInvitation,
    newCustomerForIndustry,
    updatedIndividualCustomer,
    allIndividualUserIndustries,
    isResendCustomerEmailSuccess,
    inviteCustomerToIndustryErrors,
    isInviteCustomerToIndustryError,
    isUpdateIndividualCustomerError,
    inviteCustomerToIndustrySucceed,
    isStoreCustomerToIndustrySuccess,
    isStoreCustomerToIndustryError,
    newCustomerForIndustryError,
    isInviteCustomerToIndustrySuccess,
    isUpdateIndividualCustomerSuccess,
    isGetIndividualUserIndustriesError,
    filteredIndividualCustomerByStatus,
    isDeleteCustomerFromIndustrySuccess,
    updateIndividualCustomerErrorMessage,
    isGetIndividualUserIndustriesSuccess,
    isFilterIndividualCustomerByStatusError,
    isFilterIndividualCustomerByStatusSuccess,
    isGetIndividualUserIndustryByIdSuccess,
    isGetIndividualUserIndustryByIdError,
    IndividualUserIndustryGot,
    getIndividualUserIndustryErrorMessage,
  } = useSelector((state) => state.individualUser);

  // Get some props previous values
  const prevIsGetAllCustomersError = usePrevious(isGetAllCustomersError);
  const prevIsGetAppointmentsError = usePrevious(isGetAppointmentsError);
  const prevIsGetAppointmentsSuccess = usePrevious(isGetAppointmentsSuccess);
  const prevIsResendCustomerEmailSuccess = usePrevious(isResendCustomerEmailSuccess);
  const prevIsDeleteIndividualCustomerError = usePrevious(isDeleteIndividualCustomerError);
  const prevIsInviteCustomerToIndustryError = usePrevious(isInviteCustomerToIndustryError);
  const prevIsUpdateIndividualCustomerError = usePrevious(isUpdateIndividualCustomerError);
  const prevIsStoreCustomerToIndustrySuccess = usePrevious(isStoreCustomerToIndustrySuccess);
  const prevIsStoreCustomerToIndustryError = usePrevious(isStoreCustomerToIndustryError);
  const prevIsDeleteIndividualCustomerSuccess = usePrevious(isDeleteIndividualCustomerSuccess);
  const prevIsInviteCustomerToIndustrySuccess = usePrevious(isInviteCustomerToIndustrySuccess);
  const prevIsUpdateIndividualCustomerSuccess = usePrevious(isUpdateIndividualCustomerSuccess);
  const prevIsGetAllIndividualInvitationsError = usePrevious(isGetAllIndividualInvitationsError);
  const prevIsGetIndividualUserIndustriesError = usePrevious(isGetIndividualUserIndustriesError);
  const prevIsDeleteCustomerFromIndustrySuccess = usePrevious(isDeleteCustomerFromIndustrySuccess);
  const prevIsGetAllIndividualCustomersSuccess = usePrevious(isGetAllIndividualCustomersSuccess);
  const prevIsGetIndividualUserIndustriesSuccess = usePrevious(isGetIndividualUserIndustriesSuccess);
  const prevIsGetAllIndividualInvitationsSuccess = usePrevious(isGetAllIndividualInvitationsSuccess);
  const prevIsDeleteIndividualUserInvitationError = usePrevious(isDeleteIndividualUserInvitationError);
  const prevIsUpdateIndividualUserInvitationError = usePrevious(isUpdateIndividualUserInvitationError);
  const prevIsUpdateIndividualUserInvitationSuccess = usePrevious(isUpdateIndividualUserInvitationSuccess);
  const prevIsDeleteIndividualUserInvitationSuccess = usePrevious(isDeleteIndividualUserInvitationSuccess);
  const prevIsFilterIndividualCustomerByStatusError = usePrevious(isFilterIndividualCustomerByStatusError);
  const prevIsFilterIndividualCustomerByStatusSuccess = usePrevious(isFilterIndividualCustomerByStatusSuccess);
  const prevIsGetIndividualUserIndustryByIdSuccess = usePrevious(isGetIndividualUserIndustryByIdSuccess);
  const prevIsGetIndividualUserIndustryByIdError = usePrevious(isGetIndividualUserIndustryByIdError);

  const theme = useTheme();
  const [checkedStatus, setCheckedStatus] = useState({
    active: true,
    inActive: true,
    individual_user_industry_ids: [],
    searchValue: '',
  });
  const customerFilter = {
    active: true,
    inActive: true,
  };
  const [alert, setAlert] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const tableAppHeader = ['Date', 'Name', 'Price', 'Status'];
  const [customerItem, setCustomerItem] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone_code: '',
    phone_number: '',
    individual_user_industry_id: '',
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEm, setAnchorEm] = React.useState(null);
  const [snackbarType, setSnackbarType] = useState('');
  const [customer, setCustomer] = useState({});
  const [isCustomer, setIsCustomer] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [lineProgress, setLineProgress] = useState(false);
  const [checkedLogin, setCheckedLogin] = useState(false);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState({
    value: null,
  });
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [industryOptions, setIndustryOptions] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [validationErrors, setValidationError] = useState({});
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [invitationFormErrors, setInvitationFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });
  const [customerIdToDelete, setCustomerIdToDelete] = useState([]);
  const [invitationItem] = useState({
    first_name: '',
    last_name: '',
    company_id: '',
    phone_code: '',
    phone_number: '',
    email: '',
  });
  const [isOpenResendModal, setIsOpenResendModal] = useState(false);
  const [individualCustomers, setIndividualCustomers] = useState([]);
  const [invitationToDelete, setInvitationToDelete] = useState({
    id: null,
    industryId: null,
  });
  const [previewCustomerItem, setPreviewCustomerItem] = useState({});
  const [customerToDeleteId, setCustomerToDeleteId] = useState(null);
  const [customerIndustry, setCustomerIndustry] = useState({
    first_name: '',
    last_name: '',
    email: '',
    industry: { value: null },
    phone_code: { value: null },
    phone_number: null,
  });
  const [allIndustryCustomers, setAllIndustryCustomers] = useState([]);
  const [resendInvitationItem, setResendInvitationItem] = useState({});
  const [isOpenCustomerFilter, setIsOpenCustomerFilter] = useState(null);
  const [currentIndustry, setCurrentIndustry] = useState(IndividualUserIndustryGot);
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
  const [invitationOldIndustryId] = useState(null);
  const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = useState(false);
  const [industryCustomerInvitations, setIndustryCustomerInvitations] = useState([]);
  const [isPreviewCustomerModalInfo, setIsPreviewCustomerModalInfo] = useState(false);
  const [isDeleteCustomerModal, setIsDeleteCustomerModal] = useState(false);
  const [selectedIndustriesToDeleteCustomer, setSelectedIndustriesToDeleteCustomer] = useState([]);
  const [isExistingData, setIsExistingData] = useState({});
  const [isOpenExistingModal, setIsOpenExistingModal] = useState(false);
  const open = Boolean(anchorEl);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  // Perform when function gets mounted
  useEffect(() => {
    const phoneCodeOptionsCopy = countryCodeOptions.getCodeOptions(allCountries);
    setPhoneCodeOptions(phoneCodeOptionsCopy);
    dispatch(getAppointmentsRequest());
    dispatch(getIndividualCustomersRequest());
    dispatch(getIndividualUserIndustriesRequest());
  }, []);

  // Get appointments Success
  useEffect(() => {
    if (prevIsGetAppointmentsSuccess === false && isGetAppointmentsSuccess) {
      setAppointments(allAppointments);
    }
  }, [isGetAppointmentsSuccess]);

  // Get appointments Error
  useEffect(() => {
    if (prevIsGetAppointmentsError === false && isGetAppointmentsError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
      history.push('/calendar');
    }
  }, [isGetAppointmentsError]);

  // Handle get All Industry success
  useEffect(() => {
    if (prevIsGetIndividualUserIndustriesSuccess === false && isGetIndividualUserIndustriesSuccess) {
      const industryOptions = [];
      allIndividualUserIndustries.map((industry) => industryOptions.push({
        value: industry.id,
        label: industry.industry_name,
      }));
      const industryIds = allIndividualUserIndustries.map((item) => item.id);
      const getIndustryID = localStorage.getItem('selectedIndustryId');
      setCurrentIndustry(parseInt(getIndustryID) || industryOptions[0].value);
      dispatch(getIndividualUserIndustryByIdRequest(parseInt(getIndustryID) || allIndividualUserIndustries[0].id));
      const allCustomersInIndustries = allIndividualUserIndustries.map((item) => item.customers);
      const customers = [...new Set([].concat(...allCustomersInIndustries))];
      const allInvitationCustomersInIndustries = allIndividualUserIndustries.map((item) => item.customerInvitations);
      const invitedCustomers = [...new Set([].concat(...allInvitationCustomersInIndustries))];
      const mergedCustomer = [...customers, ...invitedCustomers];
      const sortedCustomer = mergedCustomer.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllIndustryCustomers(sortedCustomer);
      setIndustryOptions(industryOptions);
      setCurrentIndustry(allIndividualUserIndustries[0].id);
      setCheckedStatus({
        ...checkedStatus,
        individual_user_industry_ids: industryIds,
      });
      setLoading(false);
    }
  }, [isGetIndividualUserIndustriesSuccess]);

  // Handle get All Industry error
  useEffect(() => {
    if (prevIsGetIndividualUserIndustriesError === false && isGetIndividualUserIndustriesError) {
      snackBarAlert(true, i18n.t('InvalidData'), 'error');
      setLoading(false);
    }
  }, [isGetIndividualUserIndustriesError]);

  // Handle Get individual industry by ID success
  useEffect(() => {
    if (prevIsGetIndividualUserIndustryByIdSuccess === false && isGetIndividualUserIndustryByIdSuccess) {
      const IndividualUserIndustryGotCopy = IndividualUserIndustryGot;
      setCurrentIndustry(IndividualUserIndustryGotCopy);
      history.push(`/customers/${IndividualUserIndustryGotCopy.id}`);
    }
  }, [isGetIndividualUserIndustryByIdSuccess]);

  // Handle Get All Industry by ID error
  useEffect(() => {
    if (prevIsGetIndividualUserIndustryByIdError && isGetIndividualUserIndustryByIdError) {
      snackBarAlert(true, getIndividualUserIndustryErrorMessage.message, 'error');
    }
  }, [isGetIndividualUserIndustryByIdError]);

  // Handle create customer to Industry success
  useEffect(() => {
    if (prevIsStoreCustomerToIndustrySuccess === false && isStoreCustomerToIndustrySuccess) {
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      setAllIndustryCustomers(allIndustryCustomersCopy);
      const [chosenIndustry] = allIndividualUserIndustries.filter((item) => item.id === customerItem.individual_user_industry_id);
      allIndustryCustomersCopy.push({
        ...newCustomerForIndustry,
        individual_user_customer_invitation: [],
        pivot: {
          individual_user_industry_id: chosenIndustry.id,
        },
      });
      allIndustryCustomersCopy.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllIndustryCustomers(allIndustryCustomersCopy);
      chosenIndustry.customers.push({
        ...newCustomerForIndustry,
        individual_user_customer_invitation: [],
        pivot: {
          individual_user_industry_id: chosenIndustry.id,
        },
      });
      setLineProgress(false);
      setCustomerItem({
        email: '',
        first_name: '',
        last_name: '',
        company_id: '',
        phone_code: '',
        phone_number: '',
        individual_user_industry_id: '',
      });
      setCustomerIndustry({
        ...customerIndustry,
        industry: { value: null },
      });
      handleCloseAddCustomerModal();
      snackBarAlert(true, i18n.t('CustomerAdded'), 'success');
    }
  }, [isStoreCustomerToIndustrySuccess]);

  // Handle create customer to Industry error
  useEffect(() => {
    if (prevIsStoreCustomerToIndustryError === false && isStoreCustomerToIndustryError) {
      setValidationError(newCustomerForIndustryError);
    }
  }, [isStoreCustomerToIndustryError]);

  // Handle customer deleted
  useEffect(() => {
    if (prevIsDeleteCustomerFromIndustrySuccess === false && isDeleteCustomerFromIndustrySuccess) {
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      const customerIndex = allIndustryCustomersCopy.findIndex((item) => item.id === customerToDeleteId);
      allIndustryCustomersCopy.splice(customerIndex, 1);
      setAllIndustryCustomers(allIndustryCustomersCopy);
      setCustomerToDeleteId(null);
      setAlert(null);
      setAnchorEl(null);
      setLoading(false);
      setLineProgress(false);
      setIsDeleteCustomerModal(false);
      snackBarAlert(true, i18n.t('CustomerDeleted'), 'success');
    }
  }, [isDeleteCustomerFromIndustrySuccess]);

  useEffect(() => {
    if (prevIsGetAllIndividualCustomersSuccess === false && isGetAllIndividualCustomersSuccess) {
      setIndividualCustomers(allIndividualCustomers);
      dispatch(getAllIndividualCustomerInvitationsRequest());
      setLoading(false);
    }
  }, [isGetAllIndividualCustomersSuccess]);

  useEffect(() => {
    if (prevIsGetAllCustomersError === false && isGetAllCustomersError) {
      snackBarAlert(true, i18n.t('InvalidData'), 'error');
      setLoading(false);
    }
  }, [isGetAllCustomersError]);

  // Handle resend invitation success
  useEffect(() => {
    if (prevIsResendCustomerEmailSuccess === false && isResendCustomerEmailSuccess) {
      setIsOpenResendModal(false);
      setLineProgress(false);
      snackBarAlert(true, i18n.t('EmailResent'), 'success');
    }
  }, [isResendCustomerEmailSuccess]);

  useEffect(() => {
    if (prevIsGetAllIndividualInvitationsSuccess === false && isGetAllIndividualInvitationsSuccess) {
      setIndustryCustomerInvitations(allIndustriesCustomerInvitations);
      setLoading(false);
    }
  }, [isGetAllIndividualInvitationsSuccess]);

  useEffect(() => {
    if (prevIsGetAllIndividualInvitationsError === false && isGetAllIndividualInvitationsError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetAllIndividualInvitationsError]);

  // Handle get companies customer invitations error
  useEffect(() => {
    if (isGetAllCustomerInvitationsError) {
      setLoading(false);
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetAllCustomerInvitationsError]);

  useEffect(() => {
    if (prevIsInviteCustomerToIndustrySuccess === false && isInviteCustomerToIndustrySuccess) {
      if (inviteCustomerToIndustrySucceed.message && inviteCustomerToIndustrySucceed.message === 'existing_customer') {
        // User with invitation email already exists, ask user to confirm
        const { existingCustomer } = inviteCustomerToIndustrySucceed;
        const alert = getExistingCustomerAlert(existingCustomer);
        setAlert(alert);
      } else {
        const allIndustryCustomersCopy = [...allIndustryCustomers];
        const customerIndex = allIndustryCustomersCopy.findIndex((item) => item.id === newInvitation.customer_id);
        if (customerIndex !== -1) {
          allIndustryCustomersCopy[customerIndex].individual_user_customer_invitation.push(newInvitation);
        } else {
          allIndustryCustomersCopy.push({
            ...newInvitation,
            pivot: {
              individual_user_industry_id: currentIndustry.id,
            },
            customer_id: null,
          });
        }
        allIndustryCustomersCopy.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
        setAllIndustryCustomers(allIndustryCustomersCopy);
        setLineProgress(false);
        setCustomerIndustry({
          ...customerIndustry,
          industry: { value: null },
        });
        handleCloseAddCustomerModal();
        setCustomerItem({
          email: '',
          first_name: '',
          last_name: '',
          company_id: '',
          phone_code: '',
          phone_number: '',
          individual_user_industry_id: '',
        });
        setCheckedLogin(false);
        setLineProgress(false);
        setIsOpenExistingModal(false);
        snackBarAlert(true, inviteCustomerToIndustrySucceed.message, 'success');
      }
    }
  }, [isInviteCustomerToIndustrySuccess]);

  useEffect(() => {
    if (prevIsInviteCustomerToIndustryError === false && isInviteCustomerToIndustryError) {
      snackBarAlert(true, inviteCustomerToIndustryErrors.message, 'error');
      setLineProgress(false);
    }
  }, [isInviteCustomerToIndustryError]);

  useEffect(() => {
    if (prevIsDeleteIndividualUserInvitationSuccess === false && isDeleteIndividualUserInvitationSuccess) {
      const customerInvitationsCopy = [...industryCustomerInvitations];
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      const invitationIndustryIndex = allIndustryCustomersCopy.findIndex((item) => item.id === invitationToDelete.id);
      const customerIndex = allIndustryCustomers.findIndex((item) => item.id === invitationToDelete.customerId);
      if (customerIndex !== -1) {
        allIndustryCustomers[customerIndex].individual_user_customer_invitation.pop();
      } else {
        allIndustryCustomersCopy.splice(invitationIndustryIndex, 1);
      }
      setIndustryCustomerInvitations(customerInvitationsCopy);
      setAllIndustryCustomers(allIndustryCustomersCopy);
      setAlert(null);
      setInvitationToDelete({
        id: null,
        industryId: null,
      });
      snackBarAlert(true, i18n.t('InvitationDeleted', 'success'));
      setLoading(false);
      setAnchorEm(false);
      setIsOpenResendModal(false);
      setIsDeleteCustomerModal(false);
    }
  }, [isDeleteIndividualUserInvitationSuccess]);

  useEffect(() => {
    if (prevIsDeleteIndividualUserInvitationError === false && isDeleteIndividualUserInvitationError) {
      setInvitationToDelete({
        id: null,
        industryId: null,
      });
      setLoading(false);
      snackBarAlert(true, deleteIndividualUserInvitationErrorMessage, 'error');
    }
  }, [isDeleteIndividualUserInvitationError]);

  useEffect(() => {
    if (prevIsUpdateIndividualUserInvitationSuccess === false && isUpdateIndividualUserInvitationSuccess) {
      if (
        updateIndividualUserInvitationSucceed
        && updateIndividualUserInvitationSucceed.message
        && updateIndividualUserInvitationSucceed.message === 'existing_customer'
      ) {
        const { existingCustomer } = updateIndividualUserInvitationSucceed;
        const alert = getExistingCustomerAlert(existingCustomer, true);
        setAlert(alert);
      } else {
        const industryInvitationsCopy = [...industryCustomerInvitations];
        const industryIndex = industryInvitationsCopy.findIndex(
          (item) => item.id === updatedIndividualUserInvitation.industry_id,
        );
        const invitationIndex = industryInvitationsCopy[industryIndex].customerInvitations.findIndex(
          (item) => item.id === updatedIndividualUserInvitation.id,
        );
        if (invitationIndex !== -1) {
          industryInvitationsCopy[industryIndex].customerInvitations[invitationIndex] = updatedIndividualUserInvitation;
        } else {
          const oldIndustryIndex = industryInvitationsCopy.findIndex(
            (item) => item.id === invitationOldIndustryId,
          );
          const oldInvitationIndex = industryInvitationsCopy[oldIndustryIndex].customerInvitations.findIndex(
            (item) => item.id === updatedIndividualUserInvitation.id,
          );
          industryInvitationsCopy[oldIndustryIndex].customerInvitations.splice(oldInvitationIndex, 1);
          industryInvitationsCopy[industryIndex].customerInvitations.push(updatedIndividualUserInvitation);
        }
        setIndustryCustomerInvitations(industryInvitationsCopy);
        setCustomerIndustry({
          first_name: '',
          last_name: '',
          email: '',
          industry: { value: null },
          phone_code: { value: null },
          phone_number: null,
        });
        snackBarAlert(true, i18n.t('InvitationUpdated'), 'success');
      }
    }
  }, [isUpdateIndividualUserInvitationSuccess]);

  useEffect(() => {
    if (prevIsUpdateIndividualUserInvitationError === false && isUpdateIndividualUserInvitationError) {
      snackBarAlert(true, updateIndividualUserInvitationErrorMessage, 'error');
    }
  }, [isUpdateIndividualUserInvitationError]);

  useEffect(() => {
    if (prevIsDeleteIndividualCustomerSuccess === false && isDeleteIndividualCustomerSuccess) {
      const individualCustomersCopy = [...individualCustomers];
      const customerIndex = individualCustomersCopy.findIndex((item) => item.id === customerIdToDelete);
      let industries = JSON.parse(individualCustomers[customerIndex].industries);
      const industryIds = industries.map((item) => item.id);
      if (JSON.stringify(industryIds) === JSON.stringify(selectedIndustriesToDeleteCustomer)) {
        individualCustomersCopy.splice(customerIndex, 1);
        setIndividualCustomers(individualCustomersCopy);
      } else {
        industries = industries.filter((item) => !selectedIndustriesToDeleteCustomer.includes(item.id));
        individualCustomersCopy[customerIndex].industries = JSON.stringify(industries);
        setIndividualCustomers(individualCustomersCopy);
      }
      setSelectedIndustriesToDeleteCustomer([]);
      setCustomerIdToDelete(null);
      setLoading(false);
      setAnchorEl(null);
      snackBarAlert(true, i18n.t('CustomerDeleted'), 'success');
    }
  }, [isDeleteIndividualCustomerSuccess]);

  useEffect(() => {
    if (prevIsDeleteIndividualCustomerError === false && isDeleteIndividualCustomerError) {
      setLoading(false);
      snackBarAlert(true, deleteIndividualCustomerErrorMessage, 'error');
    }
  }, [isDeleteIndividualCustomerError]);

  // Handle update customer success
  useEffect(() => {
    if (prevIsUpdateIndividualCustomerSuccess === false && isUpdateIndividualCustomerSuccess) {
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      const updateCustomerSucceedCopy = { ...updatedIndividualCustomer };
      const customerIndex = allIndustryCustomersCopy.findIndex((item) => item.id === updateCustomerSucceedCopy.id);
      const updatedCustomerInfo = {
        ...updateCustomerSucceedCopy,
        individual_user_customer_invitation: [],
        pivot: {
          individual_user_industry_id: allIndustryCustomersCopy[customerIndex].pivot.individual_user_industry_id,
        },
        email: null,
      };
      allIndustryCustomersCopy.splice(customerIndex, 1, updatedCustomerInfo);
      allIndustryCustomersCopy.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllIndustryCustomers(allIndustryCustomersCopy);
      setSelectedPhoneCode({
        value: '',
      });
      setCustomerItem({});
      setIsEditCustomerModalOpen(false);
      setAnchorEl(null);
      setAnchorEm(null);
      setIsAddCustomerModalOpen(false);
      snackBarAlert(true, i18n.t('CustomerUpdated'), 'success');
    }
  }, [isUpdateIndividualCustomerSuccess]);

  // Handle update customer error
  useEffect(() => {
    if (prevIsUpdateIndividualCustomerError === false && isUpdateIndividualCustomerError) {
      setIsEditCustomerModalOpen(false);
      snackBarAlert(true, updateIndividualCustomerErrorMessage.message, 'error');
    }
  }, [isUpdateIndividualCustomerError]);

  // Handle customer filter success
  useEffect(() => {
    if (prevIsFilterIndividualCustomerByStatusSuccess === false && isFilterIndividualCustomerByStatusSuccess) {
      const allIndividualUserIndustriesCopy = [...allIndividualUserIndustries];
      const customerInvitationsCopy = allIndividualUserIndustriesCopy[0].customerInvitations;
      const customersCopy = allIndividualUserIndustriesCopy[0].customers;
      const allCustomers = [...customerInvitationsCopy, ...customersCopy];
      const filteredIndividualCustomerByStatusCopy = [...filteredIndividualCustomerByStatus.customers];
      const activeIds = filteredIndividualCustomerByStatusCopy.map((item) => item.customer_id || item.id);
      const allCustomersAfterFilter = allCustomers.filter((item) => activeIds.indexOf(item.id) !== -1);
      allCustomersAfterFilter.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllIndustryCustomers(allCustomersAfterFilter);
      handleCloseFilter();
    }
  }, [isFilterIndividualCustomerByStatusSuccess]);

  // Handle customer filter error
  useEffect(() => {
    if (prevIsFilterIndividualCustomerByStatusError === false && isFilterIndividualCustomerByStatusError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isFilterIndividualCustomerByStatusError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const getExistingCustomerAlert = (existingCustomer, isGettingUpdated = false) => {
    const existingData = {
      existingCustomer,
      isGettingUpdated,
    };
    setIsExistingData(existingData);
    setIsOpenExistingModal(true);
  };

  const handleSelectIndustryForCustomer = (event) => {
    const industryId = parseInt(event.target.value);
    if (industryId) {
      const industry = allIndividualUserIndustries.find((item) => item.id === industryId);
      setCustomerIndustry({
        ...customerIndustry,
        industry: {
          label: industry.industry_name,
          value: industry.id,
        },
      });
      setCustomerItem({
        ...customerItem,
        individual_user_industry_id: industry.id,
      });
    } else {
      setCustomerIndustry({
        ...customerIndustry,
        industry: {
          label: '',
          value: '',
        },
      });
    }
  };

  const handleCustomerInvite = (existingCustomer = false) => {
    const data = { ...customerItem };
    if (existingCustomer === true) {
      setAlert(null);
      data.is_confirmed = true;
    }
    data.industry_id = customerItem.individual_user_industry_id === null ? '' : customerItem.individual_user_industry_id;
    data.phone_code = customerItem.phone_code;
    data.phone_number = customerItem.phone_number === null ? '' : customerItem.phone_number;
    data.email = customerItem.email === '' ? '' : customerItem.email;
    delete data.industry;
    setValidationError({});
    if (validateInvitation(data)) {
      dispatch(inviteCustomerToIndustryRequest(data));
    }
  };

  const handleUpdateCustomerInvitation = (existingCustomer = false) => {
    const data = { ...customerIndustry };
    if (userAccount.email === data.email) {
      snackBarAlert(true, i18n.t('CantInviteYourSelf'), 'error');
      return false;
    }
    if (existingCustomer === true) {
      setAlert(null);
      data.is_confirmed = true;
    }
    data.industry_id = customerIndustry.industry.value;
    data.phone_code = customerIndustry.phone_code.value;
    data.phone_number = customerIndustry.phone_number === null ? '' : customerIndustry.phone_number;
    data.email = customerIndustry.email === '' ? '' : customerIndustry.email;
    delete data.industry;
    setValidationError({});
    if (validateInvitation(data)) {
      dispatch(updateIndividualUserInvitationRequest(data));
    } else if (existingCustomer === true) {
      setAlert(null);
    }
  };

  const validateInvitation = (data) => {
    const {
      first_name,
      last_name,
      email,
      industry_id,
      phone_code,
      phone_number,
    } = data;
    const errors = {
      firstName: first_name.length === 0 ? 'First name is required' : null,
      lastName: last_name.length === 0 ? 'Last name is required' : null,
      email: email === '' ? 'Email is required' : null,
      industry: industry_id === '' ? 'Industry is required' : null,
      phone_code: phone_code === null ? 'Phone Code is required' : null,
      phone_number: phone_number === '' ? 'Phone number is required' : null,
    };
    setValidationError(errors);
    return Object.values(errors).filter((error) => (error !== null)).length === 0;
  };

  const handleConfirmDeleteInvitation = (customer) => {
    if (customer.individual_user_customer_invitation && customer.individual_user_customer_invitation.length) {
      dispatch(deleteIndividualUserInvitationRequest({ id: customer.individual_user_customer_invitation[0].id }));
      setInvitationToDelete({
        id: customer.individual_user_customer_invitation[0].id,
        industryId: customer.individual_user_customer_invitation[0].industry_id,
        customerId: customer.individual_user_customer_invitation[0].customer_id,
      });
    } else {
      dispatch(deleteIndividualUserInvitationRequest({ id: customer.id }));
      setInvitationToDelete({
        id: customer.id,
        industryId: customer.industry_id,
      });
    }
    setLoading(true);
  };

  const handleSelectChangeForCode = (event) => {
    const codeId = parseInt(event.target.value);
    const phone = phoneCodeOptions.find((item) => parseInt(item.value) === codeId);
    setCustomerItem({
      ...customerItem,
      phone_code: phone.value,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateCustomer = () => {
    if (validateCreateForm()) {
      setInvitationFormErrors({});
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      const currentCustomer = allIndustryCustomersCopy.find((item) => item.id === customerItem.id);
      const customerUpdateInfo = {
        individual_user_industry_id: currentCustomer.pivot.individual_user_industry_id,
        id: customerItem.id,
        first_name: customerItem.first_name,
        last_name: customerItem.last_name,
        phone_number: customerItem.phone_number,
        phone_code: customerItem.phone_code,
        full_phone_number: (`${customerItem.phone_code} ${customerItem.phone_number}`).replace(/\D/g, ''),
      };
      dispatch(updateIndividualCustomerRequest(customerUpdateInfo));
    }
  };

  const handleCloseAddCustomerModal = useCallback(() => {
    setCustomerItem({
      email: '',
      first_name: '',
      last_name: '',
      company_id: '',
      phone_code: '',
      phone_number: '',
      individual_user_industry_id: '',
    });
    setValidationError({});
    setCheckedLogin(false);
    setIsAddCustomerModalOpen(false);
    setSelectedPhoneCode({ value: null });
    setCustomerIndustry({
      ...customerIndustry,
      industry: { value: null },
    });
  }, []);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setCustomerItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }, [invitationItem]);

  const validateCreateForm = (e) => {
    const errors = {
      first_name: e ? null : !customerItem.first_name.length ? 'First name is required' : null,
      last_name: e ? null : !customerItem.last_name.length ? 'Last name is required' : null,
      individual_user_industry_id: e ? null : currentIndustry.id ? null : !customerItem.individual_user_industry_id ? 'Industry is required' : null,
      email: e ? null : checkedLogin ? !customerItem.email.length ? 'Email is required' : null : null,
      phone_number: e ? null : (customerItem.phone_number === '' && !customerItem.phone_number.length)
        ? 'Phone number is required' : customerItem.phone_number.length < 12 ? 'Phone number is not valid' : null,
      phone_code: e ? null : (selectedPhoneCode && selectedPhoneCode.value === null && customerItem.phone_code === ''
        && !customerItem.phone_code.length) ? 'Code is required' : null,
    };
    setValidationError(errors);
    setInvitationFormErrors(errors);
    return Object.values(errors)
      .filter((error) => (error !== null)).length === 0;
  };

  const handleInviteCustomerFormSubmit = (existingCustomer = false) => {
    if (validateCreateForm()) {
      const data = { ...customerItem };
      if (userAccount.email === data.email) {
        return false;
      }
      if (existingCustomer === true) {
        setAlert(null);
        data.is_confirmed = true;
      }
      data.id = null;
      data.individual_user_industry_id = customerItem.individual_user_industry_id;
      data.phone_code = customerItem.phone_code;
      data.phone_number = customerItem.phone_number === '' ? null : customerItem.phone_number;
      data.email = customerItem.email === '' ? null : customerItem.email;
      data.customer_id = customerItem.id || null;
      setInvitationFormErrors({
        firstName: false,
        lastName: false,
        email: false,
      });
      setCheckedLogin(false);
      setLineProgress(true);
      dispatch(inviteCustomerToIndustryRequest(data));
    } else if (existingCustomer === true) {
      setAlert(null);
    }
  };

  const handleCreateCustomerFormSubmit = (checkedLogin) => {
    if (validateCreateForm(checkedLogin)) {
      const data = { ...customerItem };
      data.individual_user_industry_id = customerItem.individual_user_industry_id;
      data.phone_code = customerItem.phone_code;
      data.phone_number = customerItem.phone_number === '' ? null : customerItem.phone_number;
      data.email = null;
      data.full_phone_number = (`${data.phone_code} ${data.phone_number}`).replace(/\D/g, '');
      dispatch(storeCustomerToIndustryRequest(data));
    }
  };

  const handleChangeCustomerLogin = (event) => {
    setCheckedLogin(event.target.checked);
  };

  const handleConfirmDeleteCustomer = (customer) => {
    setLoading(true);
    setCustomerToDeleteId(customer.id);
    dispatch(deleteCustomerFromIndustryRequest({
      customer_id: customer.id,
      individual_user_industry_id: customer.pivot.individual_user_industry_id,
    }));
  };

  const showConfirmDeleteAlertContent = (e, customer, isCustomer) => {
    e.preventDefault();
    setIsDeleteCustomerModal(true);
    setCustomer(customer);
    setIsCustomer(isCustomer);
  };

  const handleClickMenu = (event, customer, created) => {
    setAnchorEm(event.currentTarget);
    const resendInvitationItemCopy = {
      id: created ? customer.individual_user_customer_invitation[0].id : customer.id,
      email: created ? customer.individual_user_customer_invitation[0].email : customer.email,
    };
    setResendInvitationItem(resendInvitationItemCopy);
  };

  const handleCloseMenu = () => {
    setAnchorEm(null);
  };

  const handleOpenResendModal = () => {
    setAnchorEm(false);
    setIsOpenResendModal(true);
  };

  const handleCloseResendInvitationModal = () => {
    setIsOpenResendModal(false);
  };

  const handleResendCustomerInvitation = (resendInvitationItem) => {
    dispatch(resendCustomerEmailRequest(resendInvitationItem));
    setAnchorEm(null);
    setLineProgress(true);
  };

  const handleInviteCustomerModal = (event, id) => {
    event.preventDefault();
    setAnchorEl(null);
    const selectedCustomer = allIndustryCustomers.find((item) => item.id === id);
    setCustomerItem({
      id: selectedCustomer.id,
      first_name: selectedCustomer.first_name,
      last_name: selectedCustomer.last_name,
      phone_code: selectedCustomer.phone_code,
      phone_number: selectedCustomer.phone_number,
      email: selectedCustomer.email || '',
      individual_user_industry_id: selectedCustomer.pivot.individual_user_industry_id,
    });
    setIsAddCustomerModalOpen(true);
    setCheckedLogin(true);
  };

  // Customer Preview
  const handlePreviewCustomerInfo = (id) => {
    const allIndustryCustomersCopy = [...allIndustryCustomers];
    const customerItem = allIndustryCustomersCopy.find((item) => item.id === id);
    const filteredAppointments = appointments.filter((item) => item.customer_id === id);
    setPreviewCustomerItem({
      ...customerItem,
      email: customerItem.email || (customerItem.individual_user_customer_invitation.length ? customerItem.individual_user_customer_invitation[0].email : null),
      appointments: filteredAppointments,
    });
    setIsPreviewCustomerModalInfo(true);
    setTabValue(0);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  const handleOpenEditCustomerModal = (event, customerID) => {
    event.preventDefault();
    setAnchorEl(false);
    const allIndustryCustomersCopy = [...allIndustryCustomers];
    const customerItem = allIndustryCustomersCopy.find((item) => item.id === customerID);
    setCustomerItem({
      id: customerItem.id,
      first_name: customerItem.first_name,
      last_name: customerItem.last_name,
      phone_code: customerItem.phone_code,
      phone_number: customerItem.phone_number,
    });
    setSelectedPhoneCode({
      value: customerItem.phone_code,
    });
    setInvitationFormErrors({});
    setIsEditCustomerModalOpen(true);
    setCheckedLogin(false);
  };

  const handleCloseEditModal = () => {
    setIsEditCustomerModalOpen(false);
    setCustomerItem({});
  };

  const handleDeleteCustomerModalClose = () => {
    setIsDeleteCustomerModal(false);
  };

  const handleEditCustomerItemChange = useCallback((event) => {
    const { name, value } = event.target;
    setCustomerItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }, [customerItem]);

  const handleSearchCustomer = (event) => {
    const data = {
      active: checkedStatus.active,
      inActive: checkedStatus.inActive,
      individual_user_industry_ids: checkedStatus.individual_user_industry_ids,
      searchValue: event ? event.target.value : '',
    };
    dispatch(filterIndividualCustomerByStatusRequest(data));
  };

  const handleCloseFilter = () => {
    setIsOpenCustomerFilter(false);
  };

  // Handle change industry checkbox
  const handleChangeIndustry = (event) => {
    const { name, value } = event.target;
    const checkedCheckBoxItems = checkedStatus[name].includes(parseInt(value))
      ? { ...checkedStatus, [name]: checkedStatus[name].filter((item) => item !== parseInt(value)) }
      : { ...checkedStatus, [name]: [...checkedStatus[name], parseInt(value)] };

    setCheckedStatus({ ...checkedCheckBoxItems });
  };

  // Handle change status checkbox
  const handleChangeStatus = (event) => {
    const { name, value } = event.target;
    const checkedFilterItems = checkedStatus[name] === !!value
      ? { ...checkedStatus, [name]: !value }
      : { ...checkedStatus, [name]: !!value };
    setCheckedStatus({ ...checkedFilterItems });
  };

  return (
    <div className="menu-item" id="customers">
      <div className="general-content">
        <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
              <Box display="flex" className="tabs-navbar">
                <Box alignSelf="center" className="divider-title">
                  <Typography variant="h6" noWrap>
                    <Box display="flex" alignItems="center" height="100%">
                      Customers
                    </Box>
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <TextField
                  type="search"
                  size="small"
                  color="secondary"
                  className="search-staff"
                  placeholder="Search for customers"
                  InputProps={{
                    startAdornment: <SearchIcon position="start" />,
                  }}
                  variant="outlined"
                  onChange={handleSearchCustomer}
                />
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => setIsAddCustomerModalOpen(true)}
                >
                  {i18n.t('AddAppointment')}
                </Button>
                <Tooltip title="Filter">
                  <IconButton
                    aria-label="filter"
                    onClick={() => setIsOpenCustomerFilter(true)}
                  >
                    <TuneRoundedIcon color="secondary" />
                  </IconButton>
                </Tooltip>
                <FilterCustomerOptions
                  company={currentIndustry}
                  checkedItems={checkedStatus}
                  customerFilter={customerFilter}
                  role={userAccount.activeRole.name}
                  handleCloseFilter={handleCloseFilter}
                  isOpenCustomerFilter={isOpenCustomerFilter}
                  allIIndustries={allIndividualUserIndustries}
                  handleChangeIndustry={handleChangeIndustry}
                  handleChangeStatus={handleChangeStatus}
                  handleSubmitCustomerOption={handleSearchCustomer}
                />
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {!loading
          ? (
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start" width="100%">
              <Tooltip title="New Customer">
                <Card className="card-plus-box" onClick={() => setIsAddCustomerModalOpen(true)}>
                  <CardMedia className="img-header" />
                </Card>
              </Tooltip>
              {allIndustryCustomers.length ? allIndustryCustomers.map((item, index) => (
                // Invited Customer
                item.customer_id === null
                  ? (
                    <span key={item.id}>
                      <Box item m="15px" maxWidth="220px">
                        <Card className="card-box-root" key={index}>
                          <CardHeader
                            className="card-header-service"
                            action={(
                              <CustomerIsolatedDropdown
                                customerInvitation={item}
                                showConfirmDeleteAlertContent={showConfirmDeleteAlertContent}
                              />
                          )}
                            title={(
                              <LightTooltip
                                title={`${item.first_name} ${item.last_name}`}
                                placement="top-start"
                              >
                                <span>
                                  {`${item.first_name} ${item.last_name}`}
                                </span>
                              </LightTooltip>
                          )}
                          />
                          <CardMedia className="img-header" onClick={() => handlePreviewCustomerInfo(item.id)}>
                            {item && item.avatar ? (
                              <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                            ) : (
                              <Avatar variant="circle" className="card-box-img card-box-letters">
                                {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                              </Avatar>
                            )}
                          </CardMedia>
                          <CardContent className="content-card-box">
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              className="invited-menu-btn"
                              onClick={(e) => handleClickMenu(e, item)}
                              endIcon={<ExpandMoreIcon />}
                            >
                    Invited
                            </Button>
                            <StyledMenu
                              id="customized-menu"
                              anchorEl={anchorEm}
                              keepMounted
                              open={Boolean(anchorEm)}
                              onClose={handleCloseMenu}
                            >
                              <StyledMenuItem onClick={(e) => showConfirmDeleteAlertContent(e, item, false)}>
                                <ListItemText primary="Cancel" />
                              </StyledMenuItem>
                              <StyledMenuItem onClick={(e) => handleOpenResendModal(e, item)}>
                                <ListItemText primary="Resend" />
                              </StyledMenuItem>
                            </StyledMenu>
                          </CardContent>
                        </Card>
                      </Box>
                    </span>
                  )
                // Registered Customer
                  : item.email_verified_at
                    ? (
                      <span key={item.id}>
                        <Box
                          item
                          m="15px"
                          maxWidth="220px"
                          key={`${item.id}-${index}`}
                          className="customer-box"
                        >
                          <Card className="card-box-root">
                            <CardHeader
                              className="card-header-service"
                              action={(
                                <div>
                                  <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    id="long-menu"
                                    elevation={1}
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    onClose={handleClose}
                                    PaperProps={{
                                      style: {
                                        width: '20ch',
                                      },
                                    }}
                                  >
                                    <Link
                                      data-cy="delete-customer"
                                      to="/"
                                      className="card-box-item"
                                      onClick={(e) => showConfirmDeleteAlertContent(e, item, true)}
                                    >
                                      <MenuItem>
                                      Delete
                                      </MenuItem>
                                    </Link>
                                  </Menu>
                                </div>
                            )}
                              title={(
                                <LightTooltip title={`${item.first_name} ${item.last_name}`} placement="top-start">
                                  <span>
                                    {`${item.first_name} ${item.last_name}`}
                                  </span>
                                </LightTooltip>
                            )}
                            />
                            <Box>
                              <CardMedia className="img-header" onClick={() => handlePreviewCustomerInfo(item.id)}>
                                {item && item.avatar ? (
                                  <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                ) : (
                                  <Badge
                                    color="secondary"
                                    overlap="circle"
                                    className="avatar-badge"
                                    badgeContent={item.email_verified_at ? <CheckCircleOutlineIcon /> : ''}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                  >
                                    <Avatar variant="circle" className="card-box-img card-box-letters">
                                      {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                    </Avatar>
                                  </Badge>
                                )}
                              </CardMedia>
                              <CardContent className="content-card-box" />
                            </Box>
                          </Card>
                        </Box>
                      </span>
                    )
                  // Added Customer
                    : item.individual_user_customer_invitation && !item.individual_user_customer_invitation.length
                      ? (
                        <span>
                          <Box
                            item
                            m="15px"
                            maxWidth="220px"
                            key={`${item.id}-${index}`}
                            className="customer-box"
                          >
                            <Card className="card-box-root">
                              <CardHeader
                                className="card-header-service"
                                action={(
                                  <CustomerDropdownMenu
                                    customer={item}
                                    openEditInviteCustomerModal={handleOpenEditCustomerModal}
                                    showConfirmDeleteAlertContent={showConfirmDeleteAlertContent}
                                    isUpdateIndividualCustomerSuccess={isUpdateIndividualCustomerSuccess}
                                  />
                              )}
                                title={(
                                  <LightTooltip title={`${item.first_name} ${item.last_name}`} placement="top-start">
                                    <span>
                                      {`${item.first_name} ${item.last_name}`}
                                    </span>
                                  </LightTooltip>
                              )}
                              />
                              <Box>
                                <CardMedia className="img-header" onClick={() => handlePreviewCustomerInfo(item.id)}>
                                  {item && item.avatar ? (
                                    <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                  ) : (
                                    <Avatar variant="circle" className="card-box-img card-box-letters">
                                      {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                    </Avatar>
                                  )}
                                </CardMedia>
                                <CardContent className="content-card-box">
                                  {!item.email
                                    ? (
                                      <Button
                                        color="primary"
                                        size="small"
                                        className="invite-btn"
                                        onClick={(e) => handleInviteCustomerModal(e, item.id)}
                                      >
                                      Invite
                                      </Button>
                                    )
                                    : (
                                      <Typography className="registered-text">
                                      Registered
                                      </Typography>
                                    )}
                                </CardContent>
                              </Box>
                            </Card>
                          </Box>
                        </span>
                      )
                    // Customer with invitations
                      : item.individual_user_customer_invitation && item.individual_user_customer_invitation.length
                        ? (
                          <span>
                            <Box item m="15px" maxWidth="220px">
                              <Card className="card-box-root" key={index}>
                                <CardHeader
                                  className="card-header-service"
                                  action={(
                                    <CustomerIsolatedDropdown
                                      customerInvitation={item}
                                      showConfirmDeleteAlertContent={showConfirmDeleteAlertContent}
                                    />
                                )}
                                  title={(
                                    <LightTooltip
                                      title={`${item.first_name} ${item.last_name}`}
                                      placement="top-start"
                                    >
                                      <span>
                                        {`${item.first_name} ${item.last_name}`}
                                      </span>
                                    </LightTooltip>
                                )}
                                />
                                <CardMedia className="img-header" onClick={() => handlePreviewCustomerInfo(item.id)}>
                                  {item && item.avatar ? (
                                    <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                  ) : (
                                    <Avatar variant="circle" className="card-box-img card-box-letters">
                                      {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                    </Avatar>
                                  )}
                                </CardMedia>
                                <CardContent className="content-card-box">
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className="invited-menu-btn"
                                    onClick={(e) => handleClickMenu(e, item, 'created')}
                                    endIcon={<ExpandMoreIcon />}
                                  >
                        Invited
                                  </Button>
                                  <StyledMenu
                                    id="customized-menu"
                                    anchorEl={anchorEm}
                                    keepMounted
                                    open={Boolean(anchorEm)}
                                    onClose={handleCloseMenu}
                                  >
                                    <StyledMenuItem onClick={(e) => showConfirmDeleteAlertContent(e, item, false)}>
                                      <ListItemText primary="Cancel" />
                                    </StyledMenuItem>
                                    <StyledMenuItem onClick={(e) => handleOpenResendModal(e, item)}>
                                      <ListItemText primary="Resend" />
                                    </StyledMenuItem>
                                  </StyledMenu>
                                </CardContent>
                              </Card>
                            </Box>
                          </span>
                        )
                        : null
              ))
                : (
                  <Box className="customers-page" mx="auto">
                    <Box>
                      <Box>
                        <img src={noCustomers} alt="noCustomer" />
                      </Box>
                      <Typography component="div">
                        <Box fontWeight="500" align="center">
                          <Typography variant="h6" color="secondary">
                          You don&apos;t have any customers yet
                          </Typography>
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                )}
            </Box>
          )
          : <BoxLoading />}
      </div>
      <AddCustomerModal
        customerItem={customerItem}
        checkedLogin={checkedLogin}
        lineProgress={lineProgress}
        invitationItem={invitationItem}
        industryOptions={industryOptions}
        phoneCodeOptions={phoneCodeOptions}
        validationErrors={validationErrors}
        customerIndustry={customerIndustry}
        selectedPhoneCode={selectedPhoneCode}
        handleInputChange={handleInputChange}
        handleUpdateCustomer={handleUpdateCustomer}
        isAddCustomerModalOpen={isAddCustomerModalOpen}
        handleChangeCustomerLogin={handleChangeCustomerLogin}
        handleSelectChangeForCode={handleSelectChangeForCode}
        handleCloseAddCustomerModal={handleCloseAddCustomerModal}
        handleInviteCustomerFormSubmit={handleInviteCustomerFormSubmit}
        handleCreateCustomerFormSubmit={handleCreateCustomerFormSubmit}
        handleSelectIndustryForCustomer={handleSelectIndustryForCustomer}
      />
      <ResendCustomer
        open={isOpenResendModal}
        lineProgress={lineProgress}
        resendInvitationItem={resendInvitationItem}
        onClose={handleCloseResendInvitationModal}
        handleResendCustomerInvitation={handleResendCustomerInvitation}
      />
      <EditCustomerModal
        customerItem={customerItem}
        open={isEditCustomerModalOpen}
        onClose={handleCloseEditModal}
        phoneCodeOptions={phoneCodeOptions}
        selectedPhoneCode={selectedPhoneCode}
        handleUpdateCustomer={handleUpdateCustomer}
        invitationFormErrors={invitationFormErrors}
        handleSelectChangeForCode={handleSelectChangeForCode}
        handleEditCustomerItemChange={handleEditCustomerItemChange}
      />
      <ExistingCustomer
        open={isOpenExistingModal}
        isExistingData={isExistingData}
        onClose={() => setIsOpenExistingModal(false)}
        handleCustomerInvite={handleCustomerInvite}
        handleUpdateCustomerInvitation={handleUpdateCustomerInvitation}
      />
      <DeleteCustomerModal
        customer={customer}
        isCustomer={isCustomer}
        open={isDeleteCustomerModal}
        onClose={handleDeleteCustomerModalClose}
        handleConfirmDeleteCustomer={handleConfirmDeleteCustomer}
        handleConfirmDeleteInvitation={handleConfirmDeleteInvitation}
      />
      <PreviewCustomerModal
        theme={theme}
        tabValue={tabValue}
        a11yProps={a11yProps}
        tableAppHeader={tableAppHeader}
        handleChangeTab={handleChangeTab}
        handleChangeIndex={handleChangeIndex}
        previewCustomerItem={previewCustomerItem}
        isPreviewCustomerModalInfo={isPreviewCustomerModalInfo}
        setIsPreviewCustomerModalInfo={setIsPreviewCustomerModalInfo}
      />
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
      {alert}
    </div>
  );
}

customers.propTypes = {
  history: PropTypes.object.isRequired,
};

export default customers;
