import {
  Box, Button, Dialog, DialogActions, DialogContent, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import warningSharp from 'assets/img/warningSharp.svg';

function DeleteModal({
  onClose, deleteStaff, selected, staff, deletedStaffId, setDeletedStaffId, setSelected, staffCount,
}) {
  const { t } = useTranslation();

  const [staffFullName, setStaffFullName] = useState('');

  useEffect(() => {
    if (selected.length === 1) {
      const foundedStaff = staff.find((item) => item.id === selected[0]);
      setStaffFullName(foundedStaff.full_name);
    }
  }, []);

  const handleClose = () => {
    onClose();
    setSelected([]);
    setDeletedStaffId([]);
  };

  return (
    <Dialog
      open
      onClose={onClose}
      className="delete-modal"
    >
      <DialogContent className="modal-content">
        <Box className="close">
          <IconButton
            aria-label="close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="warning-icon">
          <img src={warningSharp} alt="warning" style={{ width: 24, height: 20 }} />
        </Box>

        <Box className="title">
          {staffCount === selected.length || staffCount === deletedStaffId.length ? (
            t('haveOneStaff')
          ) : selected.length > 1 ? (
            t('deleteMultipleStaffText')
          ) : (
            <>
              {t('upcomingAppointmentWith')} {staffFullName}
              {t('willBeCancelled')} {t('continueDeleting')}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions className="buttons">
        {staffCount === selected.length || staffCount === deletedStaffId.length ? (
          <Button
            color="primary"
            variant="contained"
            size="small"
            style={{ width: '100%' }}
            onClick={handleClose}
          >
              Ok
          </Button>
        ) : (
          <>
            <Button
              size="small"
              variant="outlined"
              onClick={handleClose}
              style={{ color: '#A4A4A4', width: '100%', height: 33 }}
            >
              {t('Cancel')}
            </Button>
            <Button
              size="small"
              variant="contained"
              onClick={deleteStaff}
              style={{
                color: 'white', backgroundColor: '#F12B2C', width: '100%', height: 33,
              }}
            >
              {t('Delete')}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export default DeleteModal;

DeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  deleteStaff: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  staff: PropTypes.array.isRequired,
  deletedStaffId: PropTypes.array.isRequired,
  setDeletedStaffId: PropTypes.func.isRequired,
  setSelected: PropTypes.func.isRequired,
  staffCount: PropTypes.number.isRequired,
};
