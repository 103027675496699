import React from 'react';
import 'react-placeholder/lib/reactPlaceholder.css';
import { Box } from '@material-ui/core';
import { RectShape } from 'react-placeholder/lib/placeholders';

function accordionLoading() {
  return (
    <div id="tab-main-parent" className="tab-customers">
      <Box className="box-general">
        <div className="main-content">
          <Box mt={4}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ height: 78 }} />
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ height: 78, marginBottom: 15, marginTop: 15 }} />
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ height: 78 }} />
          </Box>
        </div>
      </Box>
    </div>
  );
}

export default accordionLoading;
