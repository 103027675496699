import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function StaffInvitationIsolatedDropdown(props) {
  const { t } = useTranslation();
  const {
    staffItem,
    handleCancelStaff,
    isUpdateStaffSuccess,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (isUpdateStaffSuccess) {
      setAnchorEl(null);
    }
  }, [isUpdateStaffSuccess]);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        elevation={1}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        <Link
          to="#"
          onClick={() => handleCancelStaff(staffItem.id, staffItem.email)}
          className="card-box-item"
        >
          <MenuItem>
            {staffItem.staff_id ? t('CancelInvitation') : t('WithDrawInvitation') }
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

StaffInvitationIsolatedDropdown.propTypes = {
  staffItem: PropTypes.object.isRequired,
  handleCancelStaff: PropTypes.func.isRequired,
  isUpdateStaffSuccess: PropTypes.bool.isRequired,
};

export default StaffInvitationIsolatedDropdown;
