import { handleActions } from 'redux-actions';
import {
  storeIndividualUserIndustryRequest,
  storeIndividualUserIndustrySuccess,
  storeIndividualUserIndustryError,
  getIndividualUserIndustryByIdRequest,
  getIndividualUserIndustryByIdSuccess,
  getIndividualUserIndustryByIdError,
  updateIndividualUserIndustryRequest,
  updateIndividualUserIndustrySuccess,
  updateIndividualUserIndustryError,
  deleteIndividualUserIndustryRequest,
  deleteIndividualUserIndustrySuccess,
  deleteIndividualUserIndustryError,
  getIndividualUserIndustriesRequest,
  getIndividualUserIndustriesSuccess,
  getIndividualUserIndustriesError,
  getFilteredIndividualUserIndustriesRequest,
  getFilteredIndividualUserIndustriesSuccess,
  getFilteredIndividualUserIndustriesError,
  getIndividualUserAvailableTimesRequest,
  getIndividualUserAvailableTimesSuccess,
  getIndividualUserAvailableTimesError,
  inviteCustomerToIndustryRequest,
  inviteCustomerToIndustrySuccess,
  inviteCustomerToIndustryError,
  getPersonalIndividualUserIndustriesRequest,
  getPersonalIndividualUserIndustriesSuccess,
  getPersonalIndividualUserIndustriesError,
  getUserIndividualIndustriesRequest,
  getUserIndividualIndustriesSuccess,
  getUserIndividualIndustriesError,
  storeIndividualGalleryCompanyRequest,
  storeIndividualGalleryCompanySuccess,
  storeIndividualGalleryCompanyError,
  deleteIndividualGalleryCompanyRequest,
  deleteIndividualGalleryCompanySuccess,
  deleteIndividualGalleryCompanyError,
  individualCloseDayRequest,
  individualCloseDaySuccess,
  individualCloseDayError,
  updateIndividualCloseDayRequest,
  updateIndividualCloseDaySuccess,
  updateIndividualCloseDayError,
  deleteIndividualCloseDayRequest,
  deleteIndividualCloseDaySuccess,
  deleteIndividualCloseDayError,
  specialIndividualDayRequest,
  specialIndividualDaySuccess,
  specialIndividualDayError,
  updateIndividualSpecialDayRequest,
  updateIndividualSpecialDaySuccess,
  updateIndividualSpecialDayError,
  deleteIndividualSpecialDayRequest,
  deleteIndividualSpecialDaySuccess,
  deleteIndividualSpecialDayError,
  storeCustomerToIndustryRequest,
  storeCustomerToIndustrySuccess,
  storeCustomerToIndustryError,
  deleteCustomerFromIndustryRequest,
  deleteCustomerFromIndustrySuccess,
  deleteCustomerFromIndustryError,
  resendCustomerEmailRequest,
  resendCustomerEmailSuccess,
  resendCustomerEmailError,
  updateIndividualCustomerRequest,
  updateIndividualCustomerSuccess,
  updateIndividualCustomerError,
  filterIndividualCustomerByStatusRequest,
  filterIndividualCustomerByStatusSuccess,
  filterIndividualCustomerByStatusError,
  getIndividualUserSettingsRequest,
  getIndividualUserSettingsSuccess,
  getIndividualUserSettingsError,
  updateIndividualUserSettingsRequest,
  updateIndividualUserSettingsSuccess,
  updateIndividualUserSettingsError,
  getIndividualAvailableHoursRequest,
  getIndividualAvailableHoursSuccess,
  getIndividualAvailableHoursError,
  getIndustryAvailableDatesRequest,
  getIndustryAvailableDatesSuccess,
  getIndustryAvailableDatesError,
} from './actions';

const initialState = {
  isStoreIndividualUserIndustrySuccess: false,
  isStoreIndividualUserIndustryError: false,
  storedIndividualUserIndustry: {},
  storeIndividualUserIndustryErrorMessage: '',
  storeIndividualUserIndustryErrors: {},
  isGetIndividualUserIndustryByIdSuccess: false,
  isGetIndividualUserIndustryByIdError: false,
  IndividualUserIndustryGot: {},
  getIndividualUserIndustryErrorMessage: '',
  isUpdateIndividualUserIndustrySuccess: false,
  isUpdateIndividualUserIndustryError: false,
  updatedIndividualUserIndustry: {},
  updateIndividualUserIndustryErrorMessage: '',
  isGetIndividualUserIndustriesSuccess: false,
  isGetIndividualUserIndustriesError: false,
  allIndividualUserIndustries: [],
  filteredIndividualUserIndustries: [],
  isGetFilteredIndividualUserIndustriesSuccess: false,
  isGetFilteredIndividualUserIndustriesError: false,
  getFilteredIndividualUserIndustriesErrorMessage: '',
  isGetIndividualUserAvailableTimesSuccess: false,
  isGetIndividualUserAvailableTimesError: false,
  individualUserAvailableTimes: [],
  getIndividualUserAvailableTimesErrorMessage: '',
  isDeleteIndividualUserIndustrySuccess: false,
  isDeleteIndividualUserIndustryError: false,
  deleteIndividualUserIndustryErrorMessage: '',
  isInviteCustomerToIndustrySuccess: false,
  isInviteCustomerToIndustryError: false,
  inviteCustomerToIndustrySucceed: {},
  inviteCustomerToIndustryErrors: [],
  newInvitation: {},
  isGetPersonalIndividualUserIndustriesSuccess: false,
  isGetPersonalIndividualUserIndustriesError: false,
  personalIndividualUserIndustries: [],
  getPersonalIndividualUserIndustriesErrorMessage: '',
  isGetUserIndividualIndustriesSuccess: false,
  isGetUserIndividualIndustriesError: false,
  userIndividualIndustries: [],
  getUserIndividualIndustriesErrorMessage: '',
  storedIndividualGalleryCompany: {},
  isStoreIndividualGalleryCompanySuccess: false,
  isStoreIndividualGalleryCompanyError: false,
  storeIndividualGalleryCompanyErrors: [],
  deletedIndividualGalleryCompany: {},
  isDeleteIndividualGalleryCompanySuccess: false,
  isDeleteIndividualGalleryCompanyError: false,
  deleteIndividualGalleryCompanyErrors: [],
  storedIndividualCloseDay: {},
  storeIndividualCloseDayErrors: {},
  isIndividualCloseDaySuccess: false,
  isIndividualCloseDayError: false,
  isUpdateIndividualCloseDaySuccess: false,
  isUpdateIndividualCloseDayError: false,
  updatedIndividualCloseDay: {},
  isDeleteIndividualCloseDaySuccess: false,
  isDeleteIndividualCloseDayError: false,
  deleteIndividualCloseDayErrorMessage: '',
  isSpecialIndividualDaySuccess: false,
  isSpecialIndividualDayError: false,
  storedIndividualSpecialDay: {},
  storeIndividualSpecialDayErrors: {},
  isUpdateIndividualSpecialDaySuccess: false,
  isUpdateIndividualSpecialDayError: false,
  updatedIndividualSpecialDay: {},
  updateIndividualSpecialDayErrors: '',
  isDeleteIndividualSpecialDaySuccess: false,
  isDeleteIndividualSpecialDayError: false,
  deleteIndividualSpecialDayErrorMessage: '',
  isStoreCustomerToIndustrySuccess: false,
  isStoreCustomerToIndustryError: false,
  newCustomerForIndustry: {},
  newCustomerForIndustryError: {},
  isDeleteCustomerFromIndustrySuccess: false,
  isDeleteCustomerFromIndustryError: false,
  deleteCustomerFromIndustryErrorMessage: '',
  isResendCustomerEmailSuccess: false,
  isResendCustomerEmailError: false,
  isUpdateIndividualCustomerSuccess: false,
  isUpdateIndividualCustomerError: false,
  updatedIndividualCustomer: {},
  updateIndividualCustomerErrorMessage: '',
  updateIndividualCustomerErrors: {},
  isFilterIndividualCustomerByStatusSuccess: false,
  isFilterIndividualCustomerByStatusError: false,
  filteredIndividualCustomerByStatus: {},
  isGetIndividualUserSettingsSuccess: false,
  isGetIndividualUserSettingsError: false,
  getIndividualUserSettingsErrorMessage: '',
  individualUserSettings: {},
  isUpdateIndividualUserSettingsSuccess: false,
  isUpdateIndividualUserSettingsError: false,
  updateIndividualUserSettingsErrorMessage: '',
  isIndividualAvailableHoursSuccess: false,
  isIndividualAvailableHoursError: false,
  individualAvailableHours: {},
  isGetAvailableDatesSuccess: false,
  isGetAvailableDatesError: false,
  industryAvailableDates: {},
};

const reducer = handleActions({
  [storeIndividualUserIndustryRequest]: (state) => ({
    ...state,
    isStoreIndividualUserIndustrySuccess: false,
    isStoreIndividualUserIndustryError: false,
    storedIndividualUserIndustry: {},
    storeIndividualUserIndustryErrors: {},
    storeIndividualUserIndustryErrorMessage: '',
  }),
  [storeIndividualUserIndustrySuccess]: (state, { payload: { data } }) => ({
    ...state,
    isStoreIndividualUserIndustrySuccess: true,
    isStoreIndividualUserIndustryError: false,
    storedIndividualUserIndustry: data,
    storeIndividualUserIndustryErrors: {},
    storeIndividualUserErrors: [],
  }),
  [storeIndividualUserIndustryError]: (state, { payload }) => ({
    ...state,
    isStoreIndividualUserIndustrySuccess: false,
    isStoreIndividualUserIndustryError: true,
    storedIndividualUserIndustry: {},
    storeIndividualUserIndustryErrors: payload.errors || {},
    storeIndividualUserIndustryErrorMessage: payload.message || '',
  }),
  [getIndividualUserIndustryByIdRequest]: (state) => ({
    ...state,
    isGetIndividualUserIndustryByIdSuccess: false,
    isGetIndividualUserIndustryByIdError: false,
    IndividualUserIndustryGot: {},
    getIndividualUserIndustryErrorMessage: '',
  }),
  [getIndividualUserIndustryByIdSuccess]: (state, { payload }) => ({
    ...state,
    isGetIndividualUserIndustryByIdSuccess: true,
    isGetIndividualUserIndustryByIdError: false,
    IndividualUserIndustryGot: payload.data,
    getIndividualUserIndustryErrorMessage: '',
  }),
  [getIndividualUserIndustryByIdError]: (state, { payload }) => ({
    ...state,
    isGetIndividualUserIndustryByIdSuccess: false,
    isGetIndividualUserIndustryByIdError: true,
    IndividualUserIndustryGot: {},
    getIndividualUserIndustryErrorMessage: payload.message,
  }),
  [updateIndividualUserIndustryRequest]: (state) => ({
    ...state,
    isUpdateIndividualUserIndustrySuccess: false,
    isUpdateIndividualUserIndustryError: false,
    updatedIndividualUserIndustry: {},
    updateIndividualUserIndustryErrors: {},
    updateIndividualUserIndustryErrorMessage: '',
  }),
  [updateIndividualUserIndustrySuccess]: (state, { payload: { data } }) => ({
    ...state,
    isUpdateIndividualUserIndustrySuccess: true,
    isUpdateIndividualUserIndustryError: false,
    updatedIndividualUserIndustry: data,
    updateIndividualUserIndustryErrors: {},
    updateIndividualUserIndustryErrorMessage: '',
  }),
  [updateIndividualUserIndustryError]: (state, { payload: { message, errors } }) => ({
    ...state,
    isUpdateIndividualUserIndustrySuccess: false,
    isUpdateIndividualUserIndustryError: true,
    updatedIndividualUserIndustry: {},
    updateIndividualUserIndustryErrors: errors || {},
    updateIndividualUserIndustryErrorMessage: message || '',
  }),
  [deleteIndividualUserIndustryRequest]: (state) => ({
    ...state,
    isDeleteIndividualUserIndustrySuccess: false,
    isDeleteIndividualUserIndustryError: false,
    deleteIndividualUserIndustryErrorMessage: '',
  }),
  [deleteIndividualUserIndustrySuccess]: (state) => ({
    ...state,
    isDeleteIndividualUserIndustrySuccess: true,
    isDeleteIndividualUserIndustryError: false,
    deleteIndividualUserIndustryErrorMessage: '',
  }),
  [deleteIndividualUserIndustryError]: (state, { payload }) => ({
    ...state,
    isDeleteIndividualUserIndustrySuccess: false,
    isDeleteIndividualUserIndustryError: true,
    deleteIndividualUserIndustryErrorMessage: payload.message,
  }),
  [getIndividualUserIndustriesRequest]: (state) => ({
    ...state,
    isGetIndividualUserIndustriesSuccess: false,
    isGetIndividualUserIndustriesError: false,
    allIndividualUserIndustries: [],
  }),
  [getIndividualUserIndustriesSuccess]: (state, { payload }) => ({
    ...state,
    isGetIndividualUserIndustriesSuccess: true,
    isGetIndividualUserIndustriesError: false,
    allIndividualUserIndustries: payload.data,
  }),
  [getIndividualUserIndustriesError]: (state) => ({
    ...state,
    isGetIndividualUserIndustriesSuccess: false,
    isGetIndividualUserIndustriesError: true,
    allIndividualUserIndustries: [],
  }),
  [getFilteredIndividualUserIndustriesRequest]: (state) => ({
    ...state,
    isGetFilteredIndividualUserIndustriesSuccess: false,
    isGetFilteredIndividualUserIndustriesError: false,
    filteredIndividualUserIndustries: [],
    getFilteredIndividualUserIndustriesErrorMessage: '',
  }),
  [getFilteredIndividualUserIndustriesSuccess]: (state, { payload }) => ({
    ...state,
    isGetFilteredIndividualUserIndustriesSuccess: true,
    isGetFilteredIndividualUserIndustriesError: false,
    filteredIndividualUserIndustries: payload.data,
    getFilteredIndividualUserIndustriesErrorMessage: '',
  }),
  [getFilteredIndividualUserIndustriesError]: (state, { payload }) => ({
    ...state,
    isGetFilteredIndividualUserIndustriesSuccess: false,
    isGetFilteredIndividualUserIndustriesError: true,
    filteredIndividualUserIndustries: [],
    getFilteredIndividualUserIndustriesErrorMessage: payload.message,
  }),
  [getIndividualUserAvailableTimesRequest]: (state) => ({
    ...state,
    isGetIndividualUserAvailableTimesSuccess: false,
    isGetIndividualUserAvailableTimesError: false,
    individualUserAvailableTimes: [],
    getIndividualUserAvailableTimesErrorMessage: '',
  }),
  [getIndividualUserAvailableTimesSuccess]: (state, { payload }) => ({
    ...state,
    isGetIndividualUserAvailableTimesSuccess: true,
    isGetIndividualUserAvailableTimesError: false,
    individualUserAvailableTimes: payload,
    getIndividualUserAvailableTimesErrorMessage: '',
  }),
  [getIndividualUserAvailableTimesError]: (state, { payload }) => ({
    ...state,
    isGetIndividualUserAvailableTimesSuccess: false,
    isGetIndividualUserAvailableTimesError: true,
    individualUserAvailableTimes: [],
    getIndividualUserAvailableTimesErrorMessage: payload.message,
  }),
  [inviteCustomerToIndustryRequest]: (state) => ({
    ...state,
    isInviteCustomerToIndustrySuccess: false,
    isInviteCustomerToIndustryError: false,
    inviteCustomerToIndustryErrors: [],
    inviteCustomerToIndustrySucceed: {},
    newInvitation: {},
  }),
  [inviteCustomerToIndustrySuccess]: (state, { payload }) => ({
    ...state,
    isInviteCustomerToIndustrySuccess: true,
    isInviteCustomerToIndustryError: false,
    inviteCustomerToIndustryErrors: [],
    inviteCustomerToIndustrySucceed: payload,
    newInvitation: payload.invitation || {},
  }),
  [inviteCustomerToIndustryError]: (state, { payload }) => ({
    ...state,
    isInviteCustomerToIndustrySuccess: false,
    isInviteCustomerToIndustryError: true,
    inviteCustomerToIndustryErrors: payload,
    inviteCustomerToIndustrySucceed: {},
    newInvitation: {},
  }),
  [getPersonalIndividualUserIndustriesRequest]: (state) => ({
    ...state,
    isGetPersonalIndividualUserIndustriesSuccess: false,
    isGetPersonalIndividualUserIndustriesError: false,
    personalIndividualUserIndustries: [],
    getPersonalIndividualUserIndustriesErrorMessage: '',
  }),
  [getPersonalIndividualUserIndustriesSuccess]: (state, { payload }) => ({
    ...state,
    isGetPersonalIndividualUserIndustriesSuccess: true,
    isGetPersonalIndividualUserIndustriesError: false,
    personalIndividualUserIndustries: payload.data,
    getPersonalIndividualUserIndustriesErrorMessage: '',
  }),
  [getPersonalIndividualUserIndustriesError]: (state, { payload }) => ({
    ...state,
    isGetPersonalIndividualUserIndustriesSuccess: false,
    isGetPersonalIndividualUserIndustriesError: true,
    personalIndividualUserIndustries: [],
    getPersonalIndividualUserIndustriesErrorMessage: payload.message,
  }),
  [getUserIndividualIndustriesRequest]: (state) => ({
    ...state,
    isGetUserIndividualIndustriesSuccess: false,
    isGetUserIndividualIndustriesError: false,
    userIndividualIndustries: [],
    getUserIndividualIndustriesErrorMessage: '',
  }),
  [getUserIndividualIndustriesSuccess]: (state, { payload }) => ({
    ...state,
    isGetUserIndividualIndustriesSuccess: true,
    isGetUserIndividualIndustriesError: false,
    userIndividualIndustries: payload.data,
    getUserIndividualIndustriesErrorMessage: '',
  }),
  [getUserIndividualIndustriesError]: (state, { payload }) => ({
    ...state,
    isGetUserIndividualIndustriesSuccess: false,
    isGetUserIndividualIndustriesError: true,
    userIndividualIndustries: [],
    getUserIndividualIndustriesErrorMessage: payload.message,
  }),
  [storeIndividualGalleryCompanyRequest]: (state) => ({
    ...state,
    isStoreIndividualGalleryCompanySuccess: false,
    isStoreIndividualGalleryCompanyError: false,
    storeIndividualGalleryCompanyErrors: [],
  }),
  [storeIndividualGalleryCompanySuccess]: (state, { payload }) => ({
    ...state,
    storedIndividualGalleryCompany: payload.data,
    isStoreIndividualGalleryCompanySuccess: true,
    isStoreIndividualGalleryCompanyError: false,
    storeIndividualGalleryCompanyErrors: [],
  }),
  [storeIndividualGalleryCompanyError]: (state, { payload }) => ({
    ...state,
    storedIndividualGalleryCompany: {},
    isStoreIndividualGalleryCompanySuccess: false,
    isStoreIndividualGalleryCompanyError: true,
    storeIndividualGalleryCompanyErrors: payload.data,
  }),
  [deleteIndividualGalleryCompanyRequest]: (state) => ({
    ...state,
    deletedIndividualGalleryCompany: {},
    isDeleteIndividualGalleryCompanySuccess: false,
    isDeleteIndividualGalleryCompanyError: false,
    deleteIndividualGalleryCompanyErrors: [],
  }),
  [deleteIndividualGalleryCompanySuccess]: (state, { payload }) => ({
    ...state,
    deletedIndividualGalleryCompany: payload.data,
    isDeleteIndividualGalleryCompanySuccess: true,
    isDeleteIndividualGalleryCompanyError: false,
    deleteIndividualGalleryCompanyErrors: [],
  }),
  [deleteIndividualGalleryCompanyError]: (state) => ({
    ...state,
    deletedIndividualGalleryCompany: {},
    isDeleteIndividualGalleryCompanySuccess: false,
    isDeleteIndividualGalleryCompanyError: false,
    deleteIndividualGalleryCompanyErrors: [],
  }),
  [individualCloseDayRequest]: (state) => ({
    ...state,
    storedIndividualCloseDay: {},
    isIndividualCloseDaySuccess: false,
    isIndividualCloseDayError: false,
  }),
  [individualCloseDaySuccess]: (state, { payload }) => ({
    ...state,
    storedIndividualCloseDay: payload.data,
    isIndividualCloseDaySuccess: true,
    isIndividualCloseDayError: false,
  }),
  [individualCloseDayError]: (state, { payload }) => ({
    ...state,
    storedIndividualCloseDay: {},
    isIndividualCloseDaySuccess: false,
    isIndividualCloseDayError: true,
    storeIndividualCloseDayErrors: payload.error,
  }),
  [updateIndividualCloseDayRequest]: (state) => ({
    ...state,
    isUpdateIndividualCloseDaySuccess: false,
    isUpdateIndividualCloseDayError: false,
    updatedIndividualCloseDay: {},
  }),
  [updateIndividualCloseDaySuccess]: (state, { payload }) => ({
    ...state,
    isUpdateIndividualCloseDaySuccess: true,
    isUpdateIndividualCloseDayError: false,
    updatedIndividualCloseDay: payload.data,
  }),
  [updateIndividualCloseDayError]: (state) => ({
    ...state,
    isUpdateIndividualCloseDaySuccess: false,
    isUpdateIndividualCloseDayError: true,
    updatedIndividualCloseDay: {},
  }),
  [deleteIndividualCloseDayRequest]: (state) => ({
    ...state,
    isDeleteIndividualCloseDaySuccess: false,
    isDeleteIndividualCloseDayError: false,
    deleteIndividualCloseDayErrorMessage: '',
  }),
  [deleteIndividualCloseDaySuccess]: (state) => ({
    ...state,
    isDeleteIndividualCloseDaySuccess: true,
    isDeleteIndividualCloseDayError: false,
  }),
  [deleteIndividualCloseDayError]: (state, { payload }) => ({
    ...state,
    isDeleteIndividualCloseDaySuccess: false,
    isDeleteIndividualCloseDayError: true,
    deleteIndividualCloseDayErrorMessage: payload.message,
  }),
  [specialIndividualDayRequest]: (state) => ({
    ...state,
    isSpecialIndividualDaySuccess: false,
    isSpecialIndividualDayError: false,
    storedIndividualSpecialDay: {},
    storeIndividualSpecialDayErrors: {},
  }),
  [specialIndividualDaySuccess]: (state, { payload }) => ({
    ...state,
    isSpecialIndividualDaySuccess: true,
    isSpecialIndividualDayError: false,
    storedIndividualSpecialDay: payload.data,
    storeIndividualSpecialDayErrors: {},
  }),
  [specialIndividualDayError]: (state, { payload }) => ({
    ...state,
    isSpecialIndividualDaySuccess: false,
    isSpecialIndividualDayError: true,
    storedIndividualSpecialDay: {},
    storeIndividualSpecialDayErrors: payload.errors,
  }),
  [updateIndividualSpecialDayRequest]: (state) => ({
    ...state,
    isUpdateIndividualSpecialDaySuccess: false,
    isUpdateIndividualSpecialDayError: false,
    updatedIndividualSpecialDay: {},
  }),
  [updateIndividualSpecialDaySuccess]: (state, { payload }) => ({
    ...state,
    isUpdateIndividualSpecialDaySuccess: true,
    isUpdateIndividualSpecialDayError: false,
    updatedIndividualSpecialDay: payload.data,
  }),
  [updateIndividualSpecialDayError]: (state, { payload }) => ({
    ...state,
    isUpdateIndividualSpecialDaySuccess: false,
    isUpdateIndividualSpecialDayError: true,
    updatedIndividualSpecialDay: {},
    updateIndividualSpecialDayErrors: payload.error,
  }),
  [deleteIndividualSpecialDayRequest]: (state) => ({
    ...state,
    isDeleteIndividualSpecialDaySuccess: false,
    isDeleteIndividualSpecialDayError: false,
    deleteIndividualSpecialDayErrorMessage: '',
  }),
  [deleteIndividualSpecialDaySuccess]: (state) => ({
    ...state,
    isDeleteIndividualSpecialDaySuccess: true,
    isDeleteIndividualSpecialDayError: false,
    deleteIndividualSpecialDayErrorMessage: '',
  }),
  [deleteIndividualSpecialDayError]: (state, { payload }) => ({
    ...state,
    isDeleteIndividualSpecialDaySuccess: false,
    isDeleteIndividualSpecialDayError: true,
    deleteIndividualSpecialDayErrorMessage: payload.message,
  }),
  [storeCustomerToIndustryRequest]: (state) => ({
    ...state,
    isStoreCustomerToIndustrySuccess: false,
    isStoreCustomerToIndustryError: false,
    newCustomerForIndustry: {},
    newCustomerForIndustryError: {},
  }),
  [storeCustomerToIndustrySuccess]: (state, { payload }) => ({
    ...state,
    isStoreCustomerToIndustrySuccess: true,
    isStoreCustomerToIndustryError: false,
    newCustomerForIndustry: payload.data,
  }),
  [storeCustomerToIndustryError]: (state, { payload }) => ({
    ...state,
    isStoreCustomerToIndustrySuccess: false,
    isStoreCustomerToIndustryError: true,
    newCustomerForIndustryError: payload.errors,
  }),
  [deleteCustomerFromIndustryRequest]: (state) => ({
    ...state,
    isDeleteCustomerFromIndustrySuccess: false,
    isDeleteCustomerFromIndustryError: false,
    deleteCustomerFromIndustryErrorMessage: '',
  }),
  [deleteCustomerFromIndustrySuccess]: (state) => ({
    ...state,
    isDeleteCustomerFromIndustrySuccess: true,
    isDeleteCustomerFromIndustryError: false,
    deleteCustomerFromIndustryErrorMessage: '',
  }),
  [deleteCustomerFromIndustryError]: (state, { payload }) => ({
    ...state,
    isDeleteCustomerFromIndustrySuccess: false,
    isDeleteCustomerFromIndustryError: true,
    deleteCustomerFromIndustryErrorMessage: payload.message,
  }),
  [resendCustomerEmailRequest]: (state) => ({
    ...state,
    isResendCustomerEmailSuccess: false,
    isResendCustomerEmailError: false,
  }),
  [resendCustomerEmailSuccess]: (state) => ({
    ...state,
    isResendCustomerEmailSuccess: true,
    isResendCustomerEmailError: false,
  }),
  [resendCustomerEmailError]: (state) => ({
    ...state,
    isResendCustomerEmailSuccess: false,
    isResendCustomerEmailError: true,
  }),
  [updateIndividualCustomerRequest]: (state) => ({
    ...state,
    isUpdateIndividualCustomerSuccess: false,
    isUpdateIndividualCustomerError: false,
    updatedIndividualIndividualCustomer: {},
    updateIndividualCustomerSucceed: {},
    updateIndividualCustomerErrorMessage: '',
    updateIndividualCustomerErrors: {},
  }),
  [updateIndividualCustomerSuccess]: (state, { payload }) => ({
    ...state,
    isUpdateIndividualCustomerSuccess: true,
    isUpdateIndividualCustomerError: false,
    updatedIndividualCustomer: payload.data || {},
    updateIndividualCustomerErrorMessage: '',
    updateIndividualCustomerErrors: {},
  }),
  [updateIndividualCustomerError]: (state, { payload }) => ({
    ...state,
    isUpdateIndividualCustomerSuccess: false,
    isUpdateIndividualCustomerError: true,
    updatedIndividualCustomer: {},
    updateIndividualCustomerErrorMessage: payload.message || 'Something went wrong',
    updateIndividualCustomerErrors: payload.errors || {},
  }),
  [filterIndividualCustomerByStatusRequest]: (state) => ({
    ...state,
    isFilterIndividualCustomerByStatusSuccess: false,
    isFilterIndividualCustomerByStatusError: false,
  }),
  [filterIndividualCustomerByStatusSuccess]: (state, { payload }) => ({
    ...state,
    isFilterIndividualCustomerByStatusSuccess: true,
    isFilterIndividualCustomerByStatusError: false,
    filteredIndividualCustomerByStatus: payload.data,
  }),
  [filterIndividualCustomerByStatusError]: (state) => ({
    ...state,
    isFilterIndividualCustomerByStatusSuccess: false,
    isFilterIndividualCustomerByStatusError: true,
    filteredCustomerByStatus: {},
  }),
  [getIndividualUserSettingsRequest]: (state) => ({
    ...state,
    isGetIndividualUserSettingsSuccess: false,
    isGetIndividualUserSettingsError: false,
    getIndividualUserSettingsErrorMessage: '',
    individualUserSettings: {},
  }),
  [getIndividualUserSettingsSuccess]: (state, { payload }) => ({
    ...state,
    isGetIndividualUserSettingsSuccess: true,
    isGetIndividualUserSettingsError: false,
    getIndividualUserSettingsErrorMessage: '',
    individualUserSettings: payload.data,
  }),
  [getIndividualUserSettingsError]: (state, { payload }) => ({
    ...state,
    isGetIndividualUserSettingsSuccess: false,
    isGetIndividualUserSettingsError: true,
    getIndividualUserSettingsErrorMessage: payload.message,
    individualUserSettings: {},
  }),
  [updateIndividualUserSettingsRequest]: (state) => ({
    ...state,
    isUpdateIndividualUserSettingsSuccess: false,
    isUpdateIndividualUserSettingsError: false,
    updateIndividualUserSettingsErrorMessage: '',
  }),
  [updateIndividualUserSettingsSuccess]: (state) => ({
    ...state,
    isUpdateIndividualUserSettingsSuccess: true,
    isUpdateIndividualUserSettingsError: false,
    updateIndividualUserSettingsErrorMessage: '',
  }),
  [updateIndividualUserSettingsError]: (state, { payload }) => ({
    ...state,
    isUpdateIndividualUserSettingsSuccess: false,
    isUpdateIndividualUserSettingsError: true,
    updateIndividualUserSettingsErrorMessage: payload.message,
  }),
  [getIndividualAvailableHoursRequest]: (state) => ({
    ...state,
    isIndividualAvailableHoursSuccess: false,
    isIndividualAvailableHoursError: false,
    individualAvailableHours: {},
  }),
  [getIndividualAvailableHoursSuccess]: (state, { payload }) => ({
    ...state,
    isIndividualAvailableHoursSuccess: true,
    isIndividualAvailableHoursError: false,
    individualAvailableHours: payload,
  }),
  [getIndividualAvailableHoursError]: (state) => ({
    ...state,
    isIndividualAvailableHoursSuccess: false,
    isIndividualAvailableHoursError: true,
    individualAvailableHours: {},
  }),
  [getIndustryAvailableDatesRequest]: (state) => ({
    ...state,
    isGetAvailableDatesSuccess: false,
    isGetAvailableDatesError: false,
    industryAvailableDates: {},
  }),
  [getIndustryAvailableDatesSuccess]: (state, { payload }) => ({
    ...state,
    isGetAvailableDatesSuccess: true,
    isGetAvailableDatesError: false,
    industryAvailableDates: payload,
  }),
  [getIndustryAvailableDatesError]: (state) => ({
    ...state,
    isGetAvailableDatesSuccess: false,
    isGetAvailableDatesError: true,
    industryAvailableDates: {},
  }),
}, initialState);

export default reducer;
