import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import ServiceContext from './serviceContext';

function Header({ handleSearchService }) {
  const { t } = useTranslation();

  const {
    setOpenServiceDrawer, setSelectedServiceId, setPrice, setErrors,
  } = useContext(ServiceContext);

  const handleOpenServiceDrawer = () => {
    setOpenServiceDrawer(true);
    setSelectedServiceId([]);
    setPrice({ type: 'fixed', price: { from: '0', to: '0' } });
    setErrors({});
  };

  return (
    <Box id={styles.headerContainer}>
      <Box className={styles.header}>
        <Box className={styles.searchBox}>
          <TextField
            type="search"
            size="small"
            color="secondary"
            className="search-staff"
            placeholder={t('SearchService')}
            InputProps={{
              startAdornment: <SearchIcon position="start" style={{ color: '#393939', fontSize: 16, marginRight: 10 }} />,
            }}
            variant="outlined"
            fullWidth
            onChange={handleSearchService}
          />
        </Box>
        <Box className={styles.buttonBox}>
          <Button
            size="small"
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleOpenServiceDrawer}
          >
            {t('NewService')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

Header.propTypes = {
  handleSearchService: PropTypes.func.isRequired,
};

export default Header;
