import React from 'react';
import {
  TableCell, TableContainer, Table, TableHead, TableRow, TableBody, Paper,
} from '@material-ui/core';
import { RoundShape } from 'react-placeholder/lib/placeholders';
import 'react-placeholder/lib/reactPlaceholder.css';
import Box from '@material-ui/core/Box';
import ReactPlaceholder from 'react-placeholder';
import SecondNavbarLoading from './secondNavbarLoading';

function appointment(customer, company, staff, service, Price, Date, Status, Actions) {
  return {
    customer, company, staff, service, Price, Date, Status, Actions,
  };
}

const appointments = [
  appointment(),
  appointment(),
  appointment(),
  appointment(),
  appointment(),
  appointment(),
  appointment(),
  appointment(),
];

function appointmentsLoading() {
  return (
    <>
      <SecondNavbarLoading />
      <Box p="20px">
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Box width="100%" alignSelf="center">
                    <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box width="100%" alignSelf="center">
                    <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box width="100%" alignSelf="center">
                    <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box width="100%" alignSelf="center">
                    <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box width="100%" alignSelf="center">
                    <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box width="100%" alignSelf="center">
                    <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box width="100%" alignSelf="center">
                    <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                  </Box>
                </TableCell>
                <TableCell>
                  <Box width="100%" alignSelf="center">
                    <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {appointments.map((appointment, index) => (
                <TableRow key={`${appointment.id}-${index}`}>
                  <TableCell component="th" scope="row">
                    <Box display="flex">
                      <RoundShape color="rgb(224, 224, 224)" style={{ width: 30, height: 25 }} />
                      <Box width="100%" alignSelf="center" ml="10px">
                        <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box display="flex">
                      <RoundShape color="rgb(224, 224, 224)" style={{ width: 30, height: 25 }} />
                      <Box width="100%" alignSelf="center" ml="10px">
                        <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box display="flex">
                      <RoundShape color="rgb(224, 224, 224)" style={{ width: 30, height: 25 }} />
                      <Box width="100%" alignSelf="center" ml="10px">
                        <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box display="flex">
                      <RoundShape color="rgb(224, 224, 224)" style={{ width: 30, height: 25 }} />
                      <Box width="100%" alignSelf="center" ml="10px">
                        <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box width="100%" alignSelf="center">
                      <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box width="100%" alignSelf="center">
                      <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box width="100%" alignSelf="center">
                      <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                    </Box>
                  </TableCell>
                  <TableCell align="right">
                    <Box width="100%" alignSelf="center">
                      <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

export default appointmentsLoading;
