import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import { InputLabel } from '@material-ui/core';

function GetSelectedRoleMessages(props) {
  const {
    roles,
    selectedRole,
    selectRole,
  } = props;

  return (
    <FormControl fullWidth variant="outlined" size="small" className="selectSize">
      <InputLabel>Role</InputLabel>
      <Select
        label="Role"
        value={selectedRole}
        onChange={(e) => selectRole(e.target.value)}
      >
        { roles.map((role) => (
          <MenuItem
            value={role}
            key={role}
          >
            {role}
          </MenuItem>
        )) }
      </Select>
    </FormControl>
  );
}

GetSelectedRoleMessages.propTypes = {
  roles: PropTypes.array.isRequired,
  selectedRole: PropTypes.string.isRequired,
  selectRole: PropTypes.func.isRequired,
};

export default GetSelectedRoleMessages;
