import React, {
  useState,
  useEffect,
} from 'react';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import i18n from 'i18next';
import {
  getAppointmentByIdRequest,
  getAllStatusesRequest,
} from '../../../../../../redux/appointment/actions';
import InfoLaoding from '../../../../../../Components/Loading/infoLoading';
import {
  Info,
} from './tabs';
import { History } from '../../../Owner/Appointments/details/tabs';
import SnackbarToast from '../../../../../../Modules/SnackbarToast';

function AppointmentDetails(props) {
  const {
    history,
    match,
    getAppointmentById,
    isGetAppointmentByIdSuccess,
    isGetAppointmentByIdError,
    returnedAppointment,
    getAllStatuses,
    isGetAllStatusesSuccess,
    isGetAllStatusesError,
    allStatuses,
  } = props;
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [appointment, setAppointment] = useState({});
  const [appointmentStatuses, setAppointmentStatuses] = useState([]);
  const [isButtonClick, setIsButtonClick] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Perform, when function gets mounted
  useEffect(() => {
    const { appointmentId } = match.params;
    getAppointmentById(appointmentId);
  }, []);
  // Perform, when route param appointmentId gets changed
  useEffect(() => {
    const { id } = appointment;
    const { appointmentId } = params;
    if (id && id !== appointmentId) {
      setLoading(true);
      getAppointmentById(appointmentId);
    }
  }, [params]);
  // Perform, when get appointment by id success
  useEffect(() => {
    if (isGetAppointmentByIdSuccess) {
      setAppointment(returnedAppointment);
      getAllStatuses();
    }
  }, [isGetAppointmentByIdSuccess]);
  // Perform, when get appointment by id error
  useEffect(() => {
    if (isGetAppointmentByIdError) {
      snackBarAlert(true, i18n.t('AppointmentNotFound'), 'error');
      history.push('/calendar');
    }
  }, [isGetAppointmentByIdError]);
  // Perform, when get appointment all statuses success
  useEffect(() => {
    if (isGetAllStatusesSuccess) {
      setAppointmentStatuses(allStatuses);
      setLoading(false);
    }
  }, [isGetAllStatusesSuccess]);
  // Perform, when get appointment all statuses error
  useEffect(() => {
    if (isGetAllStatusesError) {
      setLoading(false);
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetAllStatusesError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleChangeTab = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleSaveAppointment = () => {
    setIsButtonClick(true);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  return (
    <>
      <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
            <Box display="flex" className="tabs-navbar">
              <Box alignSelf="center" className="divider-title">
                <Typography variant="h6">
                  { appointment && appointment.title && appointment.title.length ? appointment.title : 'Appointment' }
                </Typography>
              </Box>
              <Box alignSelf="center" ml="-18px" mr="6px">
                <Link to="/my-appointments">
                  <IconButton size="small">
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Link>
              </Box>
              <Box alignSelf="center" className="tab-companies">
                <AppBar position="static" color="white" elevation={0}>
                  <Tabs
                    value={activeTabIndex}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="secondary"
                  >
                    <Tab
                      label="Info"
                      onClick={() => handleChangeTab(0)}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="History"
                      onClick={() => handleChangeTab(1)}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </AppBar>
              </Box>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveAppointment}
              >
                  Update
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {!loading ? (
        <div className="companies menu-item">
          <Box className="tab-list companies-tab-list">
            {activeTabIndex === 0 && (
            <Info
              appointment={appointment}
              allStatuses={appointmentStatuses}
              isAppointmentUpdate={isButtonClick}
              setIsAppointmentUpdate={setIsButtonClick}
            />
            )}
            {activeTabIndex === 1 && (
            <History
              history={appointment.history}
            />
            )}
            <SnackbarToast
              message={snackbarMessage}
              type={snackbarType}
              open={openSnackbar}
              onClose={() => setOpenSnackbar(false)}
            />
          </Box>
        </div>
      ) : <InfoLaoding />}
    </>
  );
}

AppointmentDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  getAppointmentById: PropTypes.func.isRequired,
  isGetAppointmentByIdSuccess: PropTypes.bool.isRequired,
  isGetAppointmentByIdError: PropTypes.bool.isRequired,
  returnedAppointment: PropTypes.object.isRequired,
  getAllStatuses: PropTypes.func.isRequired,
  isGetAllStatusesSuccess: PropTypes.bool.isRequired,
  isGetAllStatusesError: PropTypes.bool.isRequired,
  allStatuses: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  // Get appointment by id
  isGetAppointmentByIdSuccess: state.appointment.isGetAppointmentByIdSuccess,
  isGetAppointmentByIdError: state.appointment.isGetAppointmentByIdError,
  returnedAppointment: state.appointment.returnedAppointment,
  // Get all appointment statuses
  isGetAllStatusesSuccess: state.appointment.isGetAllStatusesSuccess,
  isGetAllStatusesError: state.appointment.isGetAllStatusesError,
  allStatuses: state.appointment.allStatuses,
});

function mapDispatchToProps(dispatch) {
  return {
    getAppointmentById: (data) => dispatch(getAppointmentByIdRequest(data)),
    getAllStatuses: () => dispatch(getAllStatusesRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails);
