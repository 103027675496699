import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  MenuItem,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';

import AppDatePicker from 'Modules/AppDatePicker';
import Checkboxes from 'Modules/checkboxes';
import './style.scss';

const AppointmentFilterDrawer = (props) => {
  const {
    anchorEl,
    filterData,
    checkedItems,
    submitFilter,
    selectedDateTo,
    selectedDateFrom,
    handleCloseFilter,
    handleChangeEndDate,
    handleChangeStartDate,
    handleChangeCheckboxes,
    fromCalendar,
  } = props;

  const { t } = useTranslation();

  return (
    <Drawer
      anchor="right"
      open={Boolean(anchorEl)}
      onClose={handleCloseFilter}
      className="appointment-filter-drawer"
      // disableBackdropClick
      PaperProps={{
        style: {
          height: 'calc(100% - 64px)',
          top: 64,
        },
      }}
      id="appointment-drawer"
    >
      <div className="drawer-content">
        <div className="title-content">
          <h3 className="title">
            { t('Filter') }
          </h3>
          <CloseIcon className="close-icon" onClick={handleCloseFilter} />
        </div>
        <div>
          <p className="title">{t('ByStatus')}</p>
          {filterData.status && filterData.status.map((status) => (
            <div
              key={status.name}
              style={{
                display: 'flex', justifyContent: 'space-between', marginBottom: '16px', alignItems: 'center',
              }}
            >
              <Box alignSelf="center" mr="5px" className="filter-text">
                <p className="status-name">
                  {t(`${status.title}`)}
                </p>
              </Box>
              { parseInt(status.status_id) === 1
                ? (
                  <Checkboxes.Accept
                    value={status.status_id}
                    checked={Object.keys(checkedItems).length ? checkedItems.status.includes(parseInt(status.status_id)) : ''}
                    onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                    name="status"
                    className="status-checkbox"
                  />
                )
                : parseInt(status.status_id) === 2
                  ? (
                    <Checkboxes.Pending
                      value={status.status_id}
                      checked={Object.keys(checkedItems).length ? checkedItems.status.includes(parseInt(status.status_id)) : ''}
                      onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                      name="status"
                      className="status-checkbox"
                    />
                  )
                  : parseInt(status.status_id) === 3
                    ? (
                      <Checkboxes.Cancel
                        value={status.status_id}
                        checked={Object.keys(checkedItems).length ? checkedItems.status.includes(parseInt(status.status_id)) : ''}
                        onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                        name="status"
                        className="status-checkbox"
                      />
                    ) : (
                      <Checkboxes.Finish
                        value={status.status_id}
                        checked={Object.keys(checkedItems).length ? checkedItems.status.includes(parseInt(status.status_id)) : ''}
                        onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                        name="status"
                        className="status-checkbox"
                      />
                    )}
            </div>
          ))}
          {filterData.services.length
            ? (
              <>
                <Typography variant="h5">Service</Typography>
                <Divider />
              </>
            )
            : null}
          {filterData.services && filterData.services.map((service) => (
            <div key={service.id}>
              <Box alignSelf="center" mr="5px" className="filter-text">
                <Typography component="div">
                  {service.name}
                </Typography>
              </Box>
              <Checkbox
                color="primary"
                value={service.id}
                name="services"
                checked={Object.keys(checkedItems).length ? checkedItems.services.includes(service.id) : ''}
                onChange={(e) => handleChangeCheckboxes(e, service.id)}
              />
            </div>
          ))}
          {!fromCalendar && (
            <>
              <Divider style={{ marginTop: '30px', marginBottom: '30px' }} />
              <p className="title">{t('ByDate')}</p>
              <Box display="flex">
                <div className="date-from">
                  <MenuItem className="date-picker">
                    <AppDatePicker
                      format="dd.MM.yyyy"
                      maxDate={selectedDateTo}
                      selectedDate={selectedDateFrom === '' ? (moment().add(-6, 'months')) : selectedDateFrom}
                      onChange={handleChangeStartDate}
                      keyboardIcon={<DateRangeIcon fontSize="small" />}
                    />
                  </MenuItem>
                </div>
                <div className="date-to">
                  <MenuItem className="date-picker">
                    <AppDatePicker
                      format="dd.MM.yyyy"
                      minDate={selectedDateFrom}
                      selectedDate={selectedDateTo === '' ? (moment().add(6, 'months')) : selectedDateTo}
                      onChange={handleChangeEndDate}
                      keyboardIcon={<DateRangeIcon fontSize="small" />}
                    />
                  </MenuItem>
                </div>
              </Box>
            </>
          )}
        </div>
      </div>
      <div className="save-btn">
        <Button variant="contained" color="primary" onClick={submitFilter}>{t('Save')}</Button>
      </div>
    </Drawer>
  );
};

AppointmentFilterDrawer.propTypes = {
  // account: PropTypes.object.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
  handleChangeStartDate: PropTypes.func.isRequired,
  handleChangeEndDate: PropTypes.func.isRequired,
  handleChangeCheckboxes: PropTypes.func.isRequired,
  checkedItems: PropTypes.object.isRequired,
  filterData: PropTypes.object.isRequired,
  submitFilter: PropTypes.func.isRequired,
  selectedDateTo: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  selectedDateFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
  fromCalendar: PropTypes.bool,
};

AppointmentFilterDrawer.defaultProps = {
  fromCalendar: false,
};

export default AppointmentFilterDrawer;
