import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import { TextField } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Note({ handleChange, appointmentItem }) {
  const { t } = useTranslation();

  return (
    <div className="input-content">
      <div className="input-title-box">
        <TextsmsOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
        <span className="input-title">
          {t('Note')}
        </span>
      </div>
      <TextField
        value={appointmentItem.note_from_creator}
        fullWidth
        size="small"
        label={t('Notes')}
        variant="outlined"
        aria-label="Notes"
        name="note_from_creator"
        className="notes-from-appointment"
        placeholder={t('AppointmentNotes')}
        onChange={(e) => handleChange('note_from_creator', e.target.value)}
      />
    </div>
  );
}

Note.propTypes = {
  handleChange: PropTypes.func.isRequired,
  appointmentItem: PropTypes.object.isRequired,
};

export default Note;
