import moment from 'moment';

const handleSortingAppointments = (appointment) => {
  const allAppointments = appointment.map((item) => ({
    ...item,
    dateTime: `${item.date} ${item.start_time}`,
  }));
  return allAppointments.sort((left, right) => moment.utc(left.dateTime).diff(moment.utc(right.dateTime)));
};

export default handleSortingAppointments;
