const menuItems = [
  {
    id: 1,
    visibleName: 'Calendar',
    name: 'calendar',
    path: '/calendar',
    icon: 'calendar_month_outlined_icon',
    roles: 'Owner Customer Staff Individual',
    title: 'Calendar',
  },
  {
    id: 2,
    visibleName: 'Appointments',
    name: 'appointments',
    path: '/my-appointments',
    icon: 'fact_check_outlined',
    roles: 'Owner Customer Staff Individual',
    title: 'Appointments',
  },
  // {
  //   id: 3,
  //   visibleName: 'Companies',
  //   name: 'companies',
  //   path: '/companies',
  //   icon: 'business',
  //   roles: 'Owner Staff',
  //   title: 'Companies',
  // },
  {
    id: 4,
    visibleName: 'Customers',
    name: 'customers',
    path: '/customers',
    icon: 'group',
    roles: 'Owner Staff Individual',
    title: 'Customers',
  },
  // {
  //   id: 5,
  //   visibleName: 'Messages',
  //   name: 'messages',
  //   path: '/messages',
  //   icon: 'drafts',
  //   roles: 'Owner Staff Customer Individual',
  //   title: 'Messages',
  // },
  // {
  //   id: 6,
  //   visibleName: 'Reminders',
  //   name: 'reminders',
  //   path: '/reminders',
  //   icon: 'add_alert',
  //   roles: 'Owner Customer Staff Individual',
  //   title: 'Reminders',
  // },
  {
    id: 5,
    visibleName: 'Industries',
    name: 'industries',
    path: '/industries',
    icon: 'public',
    roles: 'Owner Staff Individual',
    title: 'Industries',
  },
  {
    id: 6,
    visibleName: 'Staff',
    name: 'staff',
    path: '/staff',
    icon: 'badge',
    roles: 'Owner Customer Staff Individual',
    title: 'Staff',
  },
  {
    id: 7,
    visibleName: 'Services',
    name: 'service',
    path: '/service',
    icon: 'list_alt',
    roles: 'Owner Customer Staff Individual',
    title: 'Services',
  },
  // {
  //   id: 8,
  //   visibleName: 'Work schedule',
  //   name: 'workingSchedule',
  //   path: '/working-schedule',
  //   icon: 'date_range ',
  //   roles: 'Owner Customer Staff Individual',
  //   title: 'Working Schedule',
  // },
  {
    id: 9,
    visibleName: 'Gallery',
    name: 'gallery',
    path: '/gallery',
    icon: 'collections_outlined',
    roles: 'Owner Customer Staff Individual',
    title: 'Gallery',
  },
  // {
  //   id: 10,
  //   visibleName: 'Statistics',
  //   name: 'statistics',
  //   path: '/statistics',
  //   icon: 'insert_chart',
  //   roles: 'Owner Customer Staff Individual',
  //   title: 'Statistics',
  // },
  // {
  //   id: 11,
  //   visibleName: 'Settings',
  //   name: 'settings',
  //   path: '/settings',
  //   icon: 'settings',
  //   roles: 'Owner Customer Staff Individual',
  //   title: 'Settings',
  // },
];

export default menuItems;
