import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import i18n from 'i18next';
import DeleteButton from '../../../MaterialComponents/deleteButton';

function DeleteStaffOrStaffInvitation(props) {
  const {
    closeStaffDeleteOrStaffInvitationModal,
    staffItem,
    handleDeleteStaffInvitation,
    handleDeleteStaff,
    selectedStaffOrStaffInvitation,
  } = props;

  return (
    <>
      {staffItem.email && !staffItem.email_verified_at
        ? (
          <Dialog
            open
            onClose={closeStaffDeleteOrStaffInvitationModal}
          >
            <DialogTitle id="customized-dialog-title" onClose={closeStaffDeleteOrStaffInvitationModal}>
              <span className="alert-title">
                <span>{i18n.t('ConfirmCancel')}</span>
                <span>
                  <IconButton
                    aria-label="close"
                    className="close-btn"
                    onClick={closeStaffDeleteOrStaffInvitationModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </span>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>{i18n.t('AreYouSureCancel')} Staff Invitation</Typography>
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                onClick={closeStaffDeleteOrStaffInvitationModal}
              >
                {i18n.t('No')}
              </Button>
              <DeleteButton
                size="small"
                variant="contained"
                color="primary"
                onClick={() => handleDeleteStaffInvitation(selectedStaffOrStaffInvitation, staffItem.email)}
              >
                {i18n.t('Cancel')}
              </DeleteButton>
            </DialogActions>
          </Dialog>
        )
        : (
          <Dialog
            open
            onClose={closeStaffDeleteOrStaffInvitationModal}
          >
            <DialogTitle id="customized-dialog-title" onClose={closeStaffDeleteOrStaffInvitationModal}>
              <span className="alert-title">
                <span>{i18n.t(`Confirm${staffItem.staff_invitations.length ? 'Cancel' : 'Delete'}`)}</span>
                <span>
                  <IconButton
                    aria-label="close"
                    className="close-btn"
                    onClick={closeStaffDeleteOrStaffInvitationModal}
                  >
                    <CloseIcon />
                  </IconButton>
                </span>
              </span>
            </DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                {staffItem.staff_invitations.length
                  ? `${i18n.t('AreYouSureCancel')} ${i18n.t('Staff')} ${i18n.t('Invitation')}`
                  : `${i18n.t('AreYouSureDelete')} ${i18n.t('Staff')}`} ?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                onClick={closeStaffDeleteOrStaffInvitationModal}
              >
                {staffItem.staff_invitations.length ? i18n.t('No') : i18n.t('Cancel')}
              </Button>
              <DeleteButton
                size="small"
                variant="contained"
                color="primary"
                onClick={() => staffItem.staff_invitations.length ? handleDeleteStaffInvitation(selectedStaffOrStaffInvitation, staffItem.email) : handleDeleteStaff(selectedStaffOrStaffInvitation)}
              >
                {staffItem.staff_invitations.length ? i18n.t('Cancel') : i18n.t('Delete')}
              </DeleteButton>
            </DialogActions>
          </Dialog>
        )}
    </>
  );
}

DeleteStaffOrStaffInvitation.propTypes = {
  // Parent props
  staffItem: PropTypes.object.isRequired,
  closeStaffDeleteOrStaffInvitationModal: PropTypes.func.isRequired,
  handleDeleteStaffInvitation: PropTypes.func.isRequired,
  handleDeleteStaff: PropTypes.func.isRequired,
  selectedStaffOrStaffInvitation: PropTypes.number.isRequired,
};

export default DeleteStaffOrStaffInvitation;
