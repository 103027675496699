import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Card,
  Menu,
  Select,
  Dialog,
  Tooltip,
  TextField,
  InputLabel,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent,
  Badge,
  Typography,
  Box,
  Button,
  Grid,
  CardContent,
  CardHeader,
  CardMedia,
  FormControl,
  FormHelperText,
  IconButton,
  Avatar,
  MenuItem,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import useTheme from '@material-ui/core/styles/useTheme';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import {
  getCompanyByIdRequest,
  storeCustomerToCompanyRequest,
  inviteCustomerToCompanyRequest,
} from '../../../../../../../redux/company/actions';
import {
  deleteCustomerRequest,
  updateCustomerRequest,
  deleteInvitationRequest,
  updateInvitationRequest,
  resendCustomerInvitationRequest,
} from '../../../../../../../redux/customer/actions';
import noCustomers from '../../../../../../../assets/img/no-customer.svg';
import TextMaskCustom from '../../../../../../../Modules/TextMaskCustom';
import BoxLoading from '../../../../../../../Components/Loading/BoxLoading';
import DeleteButton from '../../../../../../../MaterialComponents/deleteButton';
import LightTooltip from '../../../../../../../MaterialComponents/lightTooltip';
import firstLetter from '../../../../../../../Modules/getFirstLetter';
import CustomerIsolatedDropdown from './customerInvitationIsolatedDropdown';
import CustomerDropdownMenu from '../../../../../../../Modals/Owner/CustomerModals/customerDropdownMenu';
import PreviewCustomerModal from '../../../../../../../Modals/Customers/Preview';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';
import countryCodeOptions from '../../../../../../../Modules/countryCodeOptions';

function Customers(props) {
  const { t } = useTranslation();
  const {
    match,
    userAccount,
    openInviteCustomerModalPerformed,
    company,
    closeInviteCustomerModal,
    sendCompanyUpdated,
    inviteCustomerToCompany,
    isInviteCustomerToCompanySuccess,
    isInviteCustomerToCompanyError,
    inviteCustomerToCompanySucceed,
    inviteCustomerToCompanyErrors,
    newInvitation,
    deleteCustomer,
    isDeleteCustomerSuccess,
    isDeleteCustomerError,
    deleteCustomerErrorMessage,
    deleteInvitation,
    isDeleteInvitationSuccess,
    isDeleteInvitationError,
    deleteInvitationErrorMessage,
    updateInvitation,
    isUpdateInvitationSuccess,
    isUpdateInvitationError,
    updatedInvitation,
    updateInvitationErrorMessage,
    updateInvitationSucceed,
    openInviteCustomerModal,
    storeCustomerToCompany,
    isStoreCustomerToCompanySuccess,
    isStoreCustomerToCompanyError,
    storeCustomerToCompanyErrors,
    newCustomerSucceed,
    updateCustomer,
    updateCustomerSucceed,
    isUpdateCustomerSuccess,
    resendCustomerInvitation,
    isResendCustomerInvitationSuccess,
    isResendCustomerInvitationError,
    isFilterCustomerByStatusSuccess,
    filteredCustomerByStatus,
    allCountries,
    getCompanyById,
    companyGot,
    isGetCompanyByIdSuccess,
  } = props;
  // Get some props previous values
  const prevIsStoreCustomerToCompanySuccess = usePrevious(isStoreCustomerToCompanySuccess);
  const prevIsStoreCustomerToCompanyError = usePrevious(isStoreCustomerToCompanyError);
  const prevIsInviteCustomerToCompanySuccess = usePrevious(isInviteCustomerToCompanySuccess);
  const prevIsInviteCustomerToCompanyError = usePrevious(isInviteCustomerToCompanyError);
  const prevIsDeleteCustomerSuccess = usePrevious(isDeleteCustomerSuccess);
  const prevIsDeleteCustomerError = usePrevious(isDeleteCustomerError);
  const prevIsDeleteInvitationSuccess = usePrevious(isDeleteInvitationSuccess);
  const prevIsDeleteInvitationError = usePrevious(isDeleteInvitationError);
  const prevIsUpdateInvitationSuccess = usePrevious(isUpdateInvitationSuccess);
  const prevIsUpdateInvitationError = usePrevious(isUpdateInvitationError);
  const prevOpenInviteCustomerModalPerformed = usePrevious(openInviteCustomerModalPerformed);
  const prevIsUpdateCustomerSuccess = usePrevious(isUpdateCustomerSuccess);
  const prevIsResendCustomerInvitationSuccess = usePrevious(isResendCustomerInvitationSuccess);
  const prevIsFilterCustomerByStatusSuccess = usePrevious(isFilterCustomerByStatusSuccess);
  const prevIsGetCompanyByIdSuccess = usePrevious(isGetCompanyByIdSuccess);

  const [currentCompany, setCurrentCompany] = useState(company);
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isInviteCustomerModalOpen, setIsInviteCustomerModalOpen] = useState(false);
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
  const [, setIsInvitationModalLoading] = useState(false);
  const [invitationItem, setInvitationItem] = useState({
    first_name: '',
    last_name: '',
    phone_code: '',
    phone_number: '',
    email: '',
  });
  const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = useState(false);
  const [invitationFormErrors, setInvitationFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone_number: false,
  });
  const [selectedPhoneCode, setSelectedPhoneCode] = useState({
    value: null,
  });
  const [alert, setAlert] = useState(null);
  const [customerToDeleteId, setCustomerToDeleteId] = useState(null);
  const [invitationToDeleteId, setInvitationToDeleteId] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEm, setAnchorEm] = React.useState(null);
  const [errors, setErrors] = useState({});
  const [inviteYourselfError, setInviteYourselfError] = useState(false);
  const [isPreviewCustomerModalInfo, setIsPreviewCustomerModalInfo] = useState(false);
  const [previewCustomerItem, setPreviewCustomerItem] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [checkedLogin, setCheckedLogin] = useState(false);
  const [customerItem, setCustomerItem] = useState({});
  const [isOpenResendModal, setIsOpenResendModal] = useState(false);
  const [resendInvitationItem, setResendInvitationItem] = useState({});
  const [lineProgress, setLineProgress] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [allCompanyCustomerSorted, setAllCompanyCustomerSorted] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const tableAppHeader = ['Date', 'Name', 'Price', 'Status'];
  const open = Boolean(anchorEl);
  const theme = useTheme();

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  // When a company is not empty
  useEffect(() => {
    const { id } = match.params;
    getCompanyById(id);
    const codeOptions = countryCodeOptions.getCodeOptions(allCountries);
    setPhoneCodeOptions(codeOptions);
  }, []);

  useEffect(() => {
    if (prevIsGetCompanyByIdSuccess === false && isGetCompanyByIdSuccess) {
      const companyCopy = { ...companyGot };
      const customerInvitation = companyCopy.customerInvitations.filter((item) => item.created_at);
      const registeredCustomer = companyCopy.customers.filter((item) => item.email_verified_at);
      const addedCustomer = companyCopy.customers.filter((item) => !item.email_verified_at && !item.customer_invitations.length);
      const customerWithInvitations = companyCopy.customers.filter((item) => item.customer_invitations.length);
      const filteredCustomerWithInvitations = customerWithInvitations.map((item) => item.customer_invitations[0]);
      const allCompanyCustomers = [...customerInvitation, ...registeredCustomer, ...addedCustomer, ...filteredCustomerWithInvitations];
      // Sorted customers by created date & time
      const sortedCustomers = allCompanyCustomers.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllCompanyCustomerSorted(sortedCustomers);
      setCurrentCompany(companyCopy);
      setLoading(true);
    }
  }, [isGetCompanyByIdSuccess]);
  // Handle toggle invite customer modal
  useEffect(() => {
    if (!prevOpenInviteCustomerModalPerformed && openInviteCustomerModalPerformed) {
      setIsAddCustomerModalOpen(true);
    }
  }, [openInviteCustomerModalPerformed]);
  // Handle create customer to company success
  useEffect(() => {
    if (prevIsStoreCustomerToCompanySuccess === false && isStoreCustomerToCompanySuccess) {
      const newCustomerSuccessCopy = { ...newCustomerSucceed };
      newCustomerSuccessCopy.customer_invitations = [];
      newCustomerSuccessCopy.email = null;
      setCurrentCompany({
        ...currentCompany,
        customers: [
          ...currentCompany.customers,
          newCustomerSuccessCopy,
        ],
      });
      currentCompany.customers.unshift(newCustomerSuccessCopy);
      allCompanyCustomerSorted.unshift(newCustomerSuccessCopy);
      setIsInvitationModalLoading(false);
      setLineProgress(false);
      setButtonLoading(false);
      handleCloseInvitationModal();
      setLoading(true);
      snackBarAlert(true, i18n.t('CustomerAdded'), 'success');
    }
  }, [isStoreCustomerToCompanySuccess]);
  // Handle create customer to company error
  useEffect(() => {
    if (prevIsStoreCustomerToCompanyError === false && isStoreCustomerToCompanyError) {
      setInvitationFormErrors(storeCustomerToCompanyErrors);
    }
  }, [isStoreCustomerToCompanyError]);
  // Handle invite customer to company success
  useEffect(() => {
    if (prevIsInviteCustomerToCompanySuccess === false && isInviteCustomerToCompanySuccess) {
      const companyCopy = { ...currentCompany };
      const newCustomerInvitationSuccessCopy = { ...newInvitation };
      if (inviteCustomerToCompanySucceed.message && inviteCustomerToCompanySucceed.message === 'existing_customer') {
        // User with invitation email already exists, ask user to confirm
        setIsInvitationModalLoading(false);
        const { existingCustomer } = inviteCustomerToCompanySucceed;
        const alert = getExistingCustomerAlert(existingCustomer);
        setAlert(alert);
      } else {
        // Customer invitation sent successfully
        setInvitationItem({
          first_name: '',
          last_name: '',
          phone_code: '',
          phone_number: '',
          email: '',
        });
        setSelectedPhoneCode({
          value: null,
        });
        if (newInvitation.customer_id !== null) {
          const customerIndex = companyCopy.customers.findIndex((item) => item.id === newInvitation.customer_id);
          companyCopy.customers[customerIndex].customer_invitations.push(newInvitation);
          setCurrentCompany({
            ...currentCompany,
            companyCopy,
          });
          const customerIndexSort = allCompanyCustomerSorted.findIndex((item) => item.id === newInvitation.customer_id);
          const allCompanyCustomerSortedCopy = [...allCompanyCustomerSorted];
          allCompanyCustomerSortedCopy.splice(customerIndexSort, 1, newInvitation);
          setAllCompanyCustomerSorted(allCompanyCustomerSortedCopy);
        } else {
          newCustomerInvitationSuccessCopy.customer_id = null;
          setCurrentCompany({
            ...currentCompany,
            customerInvitations: [
              ...currentCompany.customerInvitations,
              newCustomerInvitationSuccessCopy,
            ],
          });
          companyCopy.customerInvitations.push(newCustomerInvitationSuccessCopy);
          allCompanyCustomerSorted.unshift(newCustomerInvitationSuccessCopy);
        }
        setIsInvitationModalLoading(false);
        handleCloseInvitationModal();
        setLineProgress(false);
        setButtonLoading(false);
        snackBarAlert(true, inviteCustomerToCompanySucceed.message, 'success');
      }
    }
  }, [isInviteCustomerToCompanySuccess]);
  // Handle invite customer to company error
  useEffect(() => {
    if (prevIsInviteCustomerToCompanyError === false && isInviteCustomerToCompanyError) {
      setLineProgress(false);
      setIsInvitationModalLoading(false);
      setInviteYourselfError(false);
      setErrors(inviteCustomerToCompanyErrors.errors);
    }
  }, [isInviteCustomerToCompanyError]);
  // Handle delete customer success
  useEffect(() => {
    if (prevIsDeleteCustomerSuccess === false && isDeleteCustomerSuccess) {
      const customerIndex = currentCompany.customers.findIndex((item) => item.id === customerToDeleteId);
      const sortedCustomerIndex = allCompanyCustomerSorted.findIndex((item) => item.id === customerToDeleteId);
      currentCompany.customers.splice(customerIndex, 1);
      allCompanyCustomerSorted.splice(sortedCustomerIndex, 1);
      setCurrentCompany(currentCompany);
      sendCompanyUpdated(currentCompany);
      setCustomerToDeleteId(null);
      setAlert(null);
      setLoading(true);
      setAnchorEl(null);
      snackBarAlert(true, i18n.t('CustomerDeleted'), 'success');
    }
  }, [isDeleteCustomerSuccess]);
  // Handle delete customer error
  useEffect(() => {
    if (prevIsDeleteCustomerError === false && isDeleteCustomerError) {
      if (deleteCustomerErrorMessage === 'pending_appointments') {
        const alert = getDeleteCustomerAlertContent();
        setAlert(alert);
      } else {
        snackBarAlert(true, deleteCustomerErrorMessage, 'error');
      }
      setCustomerToDeleteId(null);
    }
  }, [isDeleteCustomerError]);
  // Handle delete customer invitation success
  useEffect(() => {
    if (prevIsDeleteInvitationSuccess === false && isDeleteInvitationSuccess) {
      const companyCopy = { ...currentCompany };
      const allCompanyCustomerSortedCopy = [...allCompanyCustomerSorted];
      const removedCustomer = allCompanyCustomerSortedCopy.filter(
        (item) => item.id === invitationToDeleteId,
      );
      const removedCustomerId = removedCustomer[0].customer_id || removedCustomer[0].id;
      const removedCustomerIndex = companyCopy.customers.findIndex((item) => item.id === removedCustomerId);

      companyCopy.customerInvitations.length
        ? companyCopy.customerInvitations.splice(companyCopy.customerInvitations.findIndex((e) => e.id === removedCustomerId), 1)
        : companyCopy.customers.filter((item) => item.id === removedCustomerId)[0].customer_invitations.pop();

      setCurrentCompany({
        ...currentCompany,
        companyCopy,
      });
      const customerIndexSort = allCompanyCustomerSorted.findIndex((item) => item.id === removedCustomer[0].id);
      if (removedCustomer[0].customer_id) {
        allCompanyCustomerSortedCopy.splice(customerIndexSort, 1, companyCopy.customers[removedCustomerIndex]);
      } else {
        allCompanyCustomerSortedCopy.splice(customerIndexSort, 1);
      }
      // allCompanyCustomerSortedCopy.splice(customerIndexSort, 1, companyCopy.customers[removedCustomerIndex]);
      setAllCompanyCustomerSorted(allCompanyCustomerSortedCopy);
      sendCompanyUpdated(companyCopy);
      setInvitationToDeleteId(null);
      setAlert(null);
      setLoading(false);
      setAnchorEl(null);
      setLoading(true);
      snackBarAlert(true, i18n.t('InvitationDeleted', 'success'));
    }
  }, [isDeleteInvitationSuccess]);
  // Handle delete customer invitation success
  useEffect(() => {
    if (prevIsDeleteInvitationError === false && isDeleteInvitationError) {
      setInvitationToDeleteId(null);
      setLoading(false);
      snackBarAlert(true, deleteInvitationErrorMessage, 'error');
    }
  }, [isDeleteInvitationError]);
  // Handle update customer success
  useEffect(() => {
    if (prevIsUpdateCustomerSuccess === false && isUpdateCustomerSuccess) {
      const companyCopy = { ...currentCompany };
      const updateCustomerSucceedCopy = { ...updateCustomerSucceed };
      const allCompanyCustomerSortedCopy = [...allCompanyCustomerSorted];
      const customerIndex = companyCopy.customers.findIndex((item) => item.id === updateCustomerSucceedCopy.id);
      const sortedCustomerIndex = allCompanyCustomerSortedCopy.findIndex((item) => item.id === updateCustomerSucceedCopy.id);
      const updatedCustomerInfo = {
        ...updateCustomerSucceedCopy,
        customer_invitations: [],
        email: null,
      };
      companyCopy.customers.splice(customerIndex, 1, updatedCustomerInfo);
      allCompanyCustomerSortedCopy.splice(sortedCustomerIndex, 1, updatedCustomerInfo);
      setCurrentCompany({
        ...currentCompany,
        companyCopy,
      });
      setSelectedPhoneCode({
        value: '',
      });
      setAllCompanyCustomerSorted(allCompanyCustomerSortedCopy);
      setInvitationItem(updateCustomerSucceed);
      setIsEditCustomerModalOpen(false);
      setAnchorEl(null);
      snackBarAlert(true, i18n.t('CustomerUpdated'), 'success');
    }
  }, [isUpdateCustomerSuccess]);
  // Handle update customer invitation success
  useEffect(() => {
    if (prevIsUpdateInvitationSuccess === false && isUpdateInvitationSuccess) {
      if (
        updateInvitationSucceed
        && updateInvitationSucceed.message
        && updateInvitationSucceed.message === 'existing_customer'
      ) {
        setIsInvitationModalLoading(false);
        const { existingCustomer } = updateInvitationSucceed;
        const alert = getExistingCustomerAlert(existingCustomer, true);
        setAlert(alert);
      } else {
        const companyCopy = { ...company };
        const invitationIndex = companyCopy.customerInvitations.findIndex(
          (item) => item.id === updatedInvitation.id,
        );
        companyCopy.customerInvitations[invitationIndex] = updatedInvitation;
        sendCompanyUpdated(companyCopy);
        handleCloseInvitationModal();
        setIsInvitationModalLoading(false);
        snackBarAlert(true, i18n.t('InvitationUpdated'), 'success');
      }
    }
  }, [isUpdateInvitationSuccess]);
  // Handle update customer invitation error
  useEffect(() => {
    if (prevIsUpdateInvitationError === false && isUpdateInvitationError) {
      setIsInvitationModalLoading(false);
      snackBarAlert(true, updateInvitationErrorMessage, 'error');
    }
  }, [isUpdateInvitationError]);
  // Handle resend invitation success
  useEffect(() => {
    if (prevIsResendCustomerInvitationSuccess === false && isResendCustomerInvitationSuccess) {
      setIsOpenResendModal(false);
      setLineProgress(false);
      setButtonLoading(false);
      snackBarAlert(true, i18n.t('EmailResent'), 'success');
    }
  }, [isResendCustomerInvitationSuccess]);
  // Handle resend invitation error
  useEffect(() => {
    if (prevIsResendCustomerInvitationSuccess === false && isResendCustomerInvitationError) {
      setIsOpenResendModal(false);
      setLineProgress(false);
      setButtonLoading(false);
      snackBarAlert(true, 'Email is not resend!', 'error');
    }
  }, [isResendCustomerInvitationError]);
  // Handle Filtered Customer success
  useEffect(() => {
    if (prevIsFilterCustomerByStatusSuccess === false && isFilterCustomerByStatusSuccess) {
      const filteredCustomerByStatusCopy = filteredCustomerByStatus.customers;
      filteredCustomerByStatusCopy.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllCompanyCustomerSorted(filteredCustomerByStatusCopy);
    }
  }, [isFilterCustomerByStatusSuccess]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleInvitationItemChange = useCallback((event) => {
    const { name, value } = event.target;
    setInvitationItem((prevValue) => ({
      ...prevValue,
      [name]: value.trim() !== '' ? value : '',
    }));
    setCustomerItem((prevValue) => ({
      ...prevValue,
      [name]: value.trim() !== '' ? value : '',
    }));
  }, [invitationItem]);

  const handleEditCustomerItemChange = useCallback((event) => {
    const { name, value } = event.target;
    setInvitationItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }, [invitationItem]);

  const handleSelectChangeForCode = (event) => {
    const codeId = parseInt(event.target.value);
    const phone = phoneCodeOptions.find((item) => parseInt(item.value) === codeId);
    setSelectedPhoneCode({
      value: phone.value,
    });
    setInvitationItem({
      ...invitationItem,
      phone_code: phone.value,
    });
  };

  const handleInviteCustomerModal = (event, id) => {
    event.preventDefault();
    setAnchorEl(null);
    const companyCopy = { ...currentCompany };
    const customer = companyCopy.customers.find((item) => item.id === id);
    setInvitationItem({
      id: customer.id,
      first_name: customer.first_name,
      last_name: customer.last_name,
      phone_code: customer.phone_code,
      phone_number: customer.phone_number,
      email: customer.email,
    });
    openInviteCustomerModal(event);
    setCheckedLogin(true);
    setIsInviteCustomerModalOpen(true);
  };

  const handleCloseInvitationModal = useCallback(() => {
    setErrors({});
    setLineProgress(false);
    setInvitationItem({
      id: '',
      first_name: '',
      last_name: '',
      phone_code: '',
      phone_number: '',
      email: '',
    });
    setIsInviteCustomerModalOpen(false);
    setIsAddCustomerModalOpen(false);
    setCustomerItem({});
    setSelectedPhoneCode({ value: null });
    setCheckedLogin(false);
    closeInviteCustomerModal();
    setInvitationFormErrors({});
  }, []);

  const validateInvitationForm = (e) => {
    const errors = {
      firstName: e ? null : !invitationItem.first_name.length ? t('FirstName') + t('IsRequired') : null,
      lastName: e ? null : !invitationItem.last_name.length ? t('LastName') + t('IsRequired') : null,
      email: e ? null : checkedLogin ? !invitationItem.email ? t('Email') + t('IsRequired') : null : null,
      phone_number: e ? null : (invitationItem.phone_number === '' || !invitationItem.phone_number.length)
        ? t('PhoneNumber') + t('IsRequired') : (invitationItem.phone_number === '' || invitationItem.phone_number.length < 12) ? t('PhoneNumber') + t('IsNotValid') : null,
      phone_code: e ? null : (selectedPhoneCode && selectedPhoneCode.value === null && invitationItem.phone_code === ''
        && !invitationItem.phone_code.length) ? t('Code') + t('IsRequired') : null,
    };
    setInvitationFormErrors(errors);
    return Object.values(errors)
      .filter((error) => (error !== null)).length === 0;
  };

  const handleCreateCustomerFormSubmit = (checkedLogin) => {
    if (validateInvitationForm(checkedLogin)) {
      setErrors({});
      const data = { ...invitationItem };
      setIsInvitationModalLoading(true);
      data.phone_code = selectedPhoneCode.value;
      data.phone_number = invitationItem.phone_number === '' ? null : invitationItem.phone_number;
      data.email = null;
      data.full_phone_number = (`${data.phone_code} ${data.phone_number}`).replace(/\D/g, '');
      setInvitationFormErrors({
        firstName: false,
        lastName: false,
      });
      storeCustomerToCompany(data);
      setButtonLoading(true);
    }
  };

  const handleInviteCustomerFormSubmit = (existingCustomer = false) => {
    if (validateInvitationForm()) {
      setErrors({});
      const data = { ...invitationItem };
      if (userAccount.email === data.email) {
        setInviteYourselfError(true);
        return false;
      }
      setInviteYourselfError(false);
      if (existingCustomer === true) {
        setAlert(null);
        data.is_confirmed = true;
      }
      setIsInvitationModalLoading(true);
      data.id = null;
      data.phone_code = selectedPhoneCode.value || invitationItem.phone_code;
      data.phone_number = invitationItem.phone_number === '' ? null : invitationItem.phone_number;
      data.email = invitationItem.email === '' ? null : invitationItem.email;
      data.customer_id = invitationItem.id || null;
      setInvitationFormErrors({
        firstName: false,
        lastName: false,
        email: false,
      });
      setLineProgress(true);
      inviteCustomerToCompany(data);
      setButtonLoading(true);
    } else if (existingCustomer === true) {
      setAlert(null);
    }
  };

  const handleConfirmDeleteCustomer = (id) => {
    setLoading(true);
    setCustomerToDeleteId(id);
    deleteCustomer({
      id,
      companyId: company.id,
    });
  };

  const handleConfirmDeleteInvitation = (invitationId) => {
    setLoading(true);
    setInvitationToDeleteId(invitationId);
    deleteInvitation({
      id: invitationId,
    });
  };

  const handleUpdateInvitation = (existingCustomer = false) => {
    if (validateInvitationForm()) {
      setErrors({});
      const data = {
        ...invitationItem,
        phone_code: selectedPhoneCode.value || invitationItem.phone_code,
        phone_number: invitationItem.phone_number || '',
      };
      if (userAccount.email === data.email) {
        setInviteYourselfError(true);
        return false;
      }
      setInviteYourselfError(false);
      if (existingCustomer === true) {
        setAlert(null);
        data.is_confirmed = true;
      }
      setIsInvitationModalLoading(true);
      updateInvitation(data);
    } else if (existingCustomer === true) {
      setAlert(null);
    }
  };

  const getExistingCustomerAlert = (existingCustomer, isGettingUpdated = false) => (
    <Dialog
      onClose={() => setAlert(null)}
      aria-labelledby="customized-dialog-title"
      open={() => ({})}
    >
      <DialogTitle onClick={() => setAlert(null)}>
        <span className="alert-title">
          <span>Existing User</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={() => setAlert(null)}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>Please make sure this is the customer you want to invite:</Typography>
        <Typography gutterBottom>Name:
          <Link
            to="#"
            onClick={(e) => e.preventDefault()}
          >
            {existingCustomer.first_name} {existingCustomer.last_name}
          </Link>
        </Typography>
        <Typography gutterBottom>Email: <Link to="#" onClick={(e) => e.preventDefault()}>{existingCustomer.email}</Link></Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => setAlert(null)}
        >
          {t('Cancel')}
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => isGettingUpdated ? handleUpdateInvitation(true) : handleInviteCustomerFormSubmit(true)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  const getDeleteCustomerAlertContent = () => (
    <Dialog
      open={() => ({})}
      onClose={() => setAlert(null)}
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>Delete customer</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={() => setAlert(null)}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>You are not permitted to make the action.</Typography>
        <Typography gutterBottom>Company customer have some pending appointments.</Typography>
        <Typography gutterBottom>Either finish the appointments or reassign them.</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          color="primary"
          variant="contained"
          onClick={() => setAlert(null)}
        >
          Got it
        </Button>
      </DialogActions>
    </Dialog>
  );

  const showConfirmDeleteAlertContent = (e, itemId, isCustomer) => {
    handleCloseMenu();
    e.preventDefault();
    setAnchorEl(false);
    const alert = (
      <Dialog
        open={() => ({})}
        onClose={() => setAlert(null)}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => setAlert(null)}>
          <span className="alert-title">
            <span> {t('ConfirmDelete')}</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => setAlert(null)}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>{i18n.t('AreYouSureDelete')} {isCustomer ? 'customer' : 'invitation'} ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => setAlert(null)}
          >
            {t('Cancel')}
          </Button>
          <DeleteButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => isCustomer ? handleConfirmDeleteCustomer(itemId) : handleConfirmDeleteInvitation(itemId)}
          >
            {t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
    );
    setAlert(alert);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePreviewCustomerInfo = (id) => {
    const customerItem = company.customers.find((item) => item.id === id);
    const staffAppointments = company.staff.map((item) => item.staff_appointments);

    const allStaffAppointments = [];
    staffAppointments.map((item) => allStaffAppointments.push(...item));
    const appointments = allStaffAppointments.filter((item) => item.customer_id === id);
    setPreviewCustomerItem({
      ...customerItem,
      appointments,
    });
    setIsPreviewCustomerModalInfo(true);
  };

  const handlePreviewInvitedCustomerInfo = (id, invited) => {
    if (invited) {
      const [customerItem] = company.customerInvitations.filter((item) => item.id === id);
      const appointments = [];
      setPreviewCustomerItem({
        ...customerItem,
        appointments,
      });
    } else {
      const customerItem = company.customers.filter((item) => item.customer_invitations.length);
      const [selectedCustomer] = customerItem.filter((item) => item.customer_invitations[0].id === id);
      const staffAppointments = company.staff.map((item) => item.staff_appointments);
      const allStaffAppointments = [];
      staffAppointments.map((item) => allStaffAppointments.push(...item));
      const appointments = allStaffAppointments.filter((item) => item.customer_id === id);
      setPreviewCustomerItem({
        ...selectedCustomer.customer_invitations[0],
        appointments,
      });
    }
    setIsPreviewCustomerModalInfo(true);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  // const handleChangeCustomerLogin = (event) => {
  //   setCheckedLogin(event.target.checked);
  // };

  const handleClickMenu = (event) => {
    setAnchorEm(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEm(null);
  };

  const handleUpdateCustomer = () => {
    if (validateInvitationForm('', true)) {
      setErrors({});
      const customerUpdateInfo = {
        ...invitationItem,
        id: invitationItem.id,
        first_name: invitationItem.first_name,
        last_name: invitationItem.last_name,
        phone_number: invitationItem.phone_number,
        phone_code: invitationItem.phone_code,
      };
      updateCustomer(customerUpdateInfo);
    }
  };

  const handleOpenResendModal = (e, invitation) => {
    const resendInvitationItemCopy = {
      id: invitation.id,
      email: invitation.email,
    };
    setAnchorEm(false);
    setResendInvitationItem(resendInvitationItemCopy);
    setIsOpenResendModal(true);
  };

  const handleCloseResendInvitationModal = () => {
    setIsOpenResendModal(false);
  };

  const handleResendCustomerInvitation = (resendInvitationItem) => {
    resendCustomerInvitation(resendInvitationItem);
    setAnchorEm(null);
    setLineProgress(true);
    setButtonLoading(true);
  };

  const handleOpenEditCustomerModal = (event, customerID) => {
    event.preventDefault();
    setAnchorEl(false);
    const customerItem = currentCompany.customers.find((item) => item.id === customerID);
    setInvitationItem({
      id: customerItem.id,
      first_name: customerItem.first_name,
      last_name: customerItem.last_name,
      phone_code: customerItem.phone_code,
      phone_number: customerItem.phone_number,
    });
    setSelectedPhoneCode({
      value: customerItem.phone_code,
    });
    setIsEditCustomerModalOpen(true);
    setCheckedLogin(false);
  };

  const handleCloseEditModal = () => {
    setSelectedPhoneCode({});
    setInvitationItem({});
    setInvitationFormErrors(false);
    setIsEditCustomerModalOpen(false);
  };

  const handleSelectedCustomer = (customer) => {
    setSelectedCustomer(customer);
  };

  return loading ? (
    <>
      <div id="tab-main-parent">
        <div className="tab-customers">
          <Box>
            <div className="main-content">
              <Box display="flex" flexWrap="wrap" justifyContent="flex-start" width="100%">
                <Tooltip title="New Customer">
                  <Card className="card-plus-box" onClick={(e) => openInviteCustomerModal(e)}>
                    <CardMedia className="img-header" />
                  </Card>
                </Tooltip>
                {allCompanyCustomerSorted.length ? allCompanyCustomerSorted.map((item, index) => (
                  // Invited Customer
                  [
                    item.customer_id === null
                      ? (
                        <span key={item.id}>
                          <Box item m="15px" maxWidth="220px">
                            <Card className="card-box-root" key={index}>
                              <CardHeader
                                className="card-header-service"
                                action={(
                                  <CustomerIsolatedDropdown
                                    customerInvitation={item}
                                    showConfirmDeleteAlertContent={showConfirmDeleteAlertContent}
                                  />
                            )}
                                title={(
                                  <LightTooltip
                                    title={`${item.first_name} ${item.last_name}`}
                                    placement="top-start"
                                  >
                                    <span>
                                      {`${item.first_name} ${item.last_name}`}
                                    </span>
                                  </LightTooltip>
                            )}
                              />
                              <CardMedia className="img-header" onClick={() => handlePreviewInvitedCustomerInfo(item.id, true)}>
                                {item && item.avatar ? (
                                  <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                ) : (
                                  <Avatar variant="circle" className="card-box-img card-box-letters">
                                    {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                  </Avatar>
                                )}
                              </CardMedia>
                              <CardContent className="content-card-box">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  className="invited-menu-btn"
                                  onClick={(e) => handleClickMenu(e, item.id)}
                                  endIcon={<ExpandMoreIcon />}
                                >
                            Invited
                                </Button>
                                <StyledMenu
                                  id="customized-menu"
                                  anchorEl={anchorEm}
                                  keepMounted
                                  open={Boolean(anchorEm)}
                                  onClose={handleCloseMenu}
                                >
                                  <StyledMenuItem onClick={(e) => showConfirmDeleteAlertContent(e, item.id, false)}>
                                    <ListItemText primary={t('Cancel')} />
                                  </StyledMenuItem>
                                  <StyledMenuItem onClick={(e) => handleOpenResendModal(e, item)}>
                                    <ListItemText primary="Resend" />
                                  </StyledMenuItem>
                                </StyledMenu>
                              </CardContent>
                            </Card>
                          </Box>
                        </span>
                      )
                    // Registered Customer
                      : item.email_verified_at
                        ? (
                          <span key={item.id}>
                            <Box
                              item
                              m="15px"
                              maxWidth="220px"
                              key={`${item.id}-${index}`}
                              className="customer-box"
                            >
                              <Card className="card-box-root">
                                <CardHeader
                                  className="card-header-service"
                                  action={(
                                    <div>
                                      <IconButton
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id="long-menu"
                                        elevation={1}
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                          style: {
                                            width: '20ch',
                                          },
                                        }}
                                      >
                                        <Link
                                          data-cy="delete-customer"
                                          to="/"
                                          className="card-box-item"
                                          onClick={(e) => showConfirmDeleteAlertContent(e, item.id, true)}
                                        >
                                          <MenuItem>
                                            {t('Delete')}
                                          </MenuItem>
                                        </Link>
                                      </Menu>
                                    </div>
                              )}
                                  title={(
                                    <LightTooltip title={`${item.first_name} ${item.last_name}`} placement="top-start">
                                      <span>
                                        {`${item.first_name} ${item.last_name}`}
                                      </span>
                                    </LightTooltip>
                              )}
                                />
                                <Box>
                                  <CardMedia className="img-header" onClick={() => handlePreviewCustomerInfo(item.id)}>
                                    {item && item.avatar ? (
                                      <Badge
                                        color="secondary"
                                        overlap="circle"
                                        className="avatar-badge"
                                        badgeContent={item.email_verified_at ? <CheckCircleOutlineIcon /> : ''}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        }}
                                      >
                                        <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                      </Badge>
                                    ) : (
                                      <Badge
                                        color="secondary"
                                        overlap="circle"
                                        className="avatar-badge"
                                        badgeContent={item.email_verified_at ? <CheckCircleOutlineIcon /> : ''}
                                        anchorOrigin={{
                                          vertical: 'bottom',
                                          horizontal: 'right',
                                        }}
                                      >
                                        <Avatar variant="circle" className="card-box-img card-box-letters">
                                          {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                        </Avatar>
                                      </Badge>
                                    )}
                                  </CardMedia>
                                  <CardContent className="content-card-box" />
                                </Box>
                              </Card>
                            </Box>
                          </span>
                        )
                      // Added Customer
                        : item.email === null
                          ? (
                            <span>
                              <Box
                                item
                                m="15px"
                                maxWidth="220px"
                                key={`${item.id}-${index}`}
                                className="customer-box"
                              >
                                <Card className="card-box-root">
                                  <CardHeader
                                    className="card-header-service"
                                    action={(
                                      <CustomerDropdownMenu
                                        customer={item}
                                        selectedCustomer={selectedCustomer}
                                        setSelectedCustomer={handleSelectedCustomer}
                                        isUpdateCustomerSuccess={isUpdateCustomerSuccess}
                                        showConfirmDeleteAlertContent={showConfirmDeleteAlertContent}
                                        openEditInviteCustomerModal={handleOpenEditCustomerModal}
                                      />
                                )}
                                    title={(
                                      <LightTooltip title={`${item.first_name} ${item.last_name}`} placement="top-start">
                                        <span>
                                          {`${item.first_name} ${item.last_name}`}
                                        </span>
                                      </LightTooltip>
                                )}
                                  />
                                  <Box>
                                    <CardMedia className="img-header" onClick={() => handlePreviewCustomerInfo(item.id)}>
                                      {item && item.avatar ? (
                                        <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                      ) : (
                                        <Avatar variant="circle" className="card-box-img card-box-letters">
                                          {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                        </Avatar>
                                      )}
                                    </CardMedia>
                                    <CardContent className="content-card-box">
                                      {!item.email
                                        ? (
                                          <Button
                                            color="primary"
                                            size="small"
                                            className="invite-btn"
                                            onClick={(e) => handleInviteCustomerModal(e, item.id)}
                                          >
                                        Invite
                                          </Button>
                                        )
                                        : (
                                          <Typography className="registered-text">
                                        Registered
                                          </Typography>
                                        )}
                                    </CardContent>
                                  </Box>
                                </Card>
                              </Box>
                            </span>
                          )
                        // Customer with invitations
                          : item.customer_id
                            ? (
                              <span>
                                <Box item m="15px" maxWidth="220px">
                                  <Card className="card-box-root" key={index}>
                                    <CardHeader
                                      className="card-header-service"
                                      action={(
                                        <CustomerIsolatedDropdown
                                          anchorEl={anchorEl}
                                          customerInvitation={item}
                                          showConfirmDeleteAlertContent={showConfirmDeleteAlertContent}
                                        />
                                  )}
                                      title={(
                                        <LightTooltip
                                          title={`${item.first_name} ${item.last_name}`}
                                          placement="top-start"
                                        >
                                          <span>
                                            {`${item.first_name} ${item.last_name}`}
                                          </span>
                                        </LightTooltip>
                                  )}
                                    />
                                    <CardMedia className="img-header" onClick={() => handlePreviewInvitedCustomerInfo(item.id)}>
                                      {item && item.avatar ? (
                                        <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                                      ) : (
                                        <Avatar variant="circle" className="card-box-img card-box-letters">
                                          {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                        </Avatar>
                                      )}
                                    </CardMedia>
                                    <CardContent className="content-card-box">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className="invited-menu-btn"
                                        onClick={(e) => handleClickMenu(e, item.id)}
                                        endIcon={<ExpandMoreIcon />}
                                      >
                                    Invited
                                      </Button>
                                      <StyledMenu
                                        id="customized-menu"
                                        anchorEl={anchorEm}
                                        keepMounted
                                        open={Boolean(anchorEm)}
                                        onClose={handleCloseMenu}
                                      >
                                        <StyledMenuItem onClick={(e) => showConfirmDeleteAlertContent(e, item.id, false)}>
                                          <ListItemText primary={t('Cancel')} />
                                        </StyledMenuItem>
                                        <StyledMenuItem onClick={(e) => handleOpenResendModal(e, item)}>
                                          <ListItemText primary="Resend" />
                                        </StyledMenuItem>
                                      </StyledMenu>
                                    </CardContent>
                                  </Card>
                                </Box>
                              </span>
                            )
                            : null,
                  ]
                ))
                  : (
                    <Box className="customers-page" mx="auto">
                      <Box>
                        <Box>
                          <img src={noCustomers} alt="noCustomer" />
                        </Box>
                        <Typography component="div">
                          <Box fontWeight="500" align="center">
                            <Typography variant="h6" color="secondary">
                              You don&apos;t have any customers yet
                            </Typography>
                          </Box>
                        </Typography>
                      </Box>
                    </Box>
                  )}
              </Box>
            </div>
          </Box>
        </div>
        {/* Add customer modal */}
        {!invitationItem.id && (
          <Dialog
            size="sm"
            open={isAddCustomerModalOpen}
            onClose={handleCloseInvitationModal}
          >
            <DialogTitle>
              <span className="alert-title">
                <span>Add Customer</span>
                <span>
                  <IconButton aria-label="close" className="close-btn" onClick={handleCloseInvitationModal}>
                    <CloseIcon />
                  </IconButton>
                </span>
              </span>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                {!invitationItem.id
                    && (
                      <Grid
                        container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                            error={(!!invitationFormErrors.firstName)}
                          >
                            <TextField
                              fullWidth
                              error={(!!invitationFormErrors.firstName)}
                              size="small"
                              label={`${t('FirstName')} *`}
                              data-cy="firstName"
                              data-customer="first_name"
                              variant="outlined"
                              type="text"
                              name="first_name"
                              value={invitationItem.first_name || customerItem.first_name}
                              onChange={handleInvitationItemChange}
                              inputProps={{ maxLength: 20 }}
                            />

                            {invitationFormErrors.firstName && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  {invitationFormErrors.firstName}
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                            error={(!!invitationFormErrors.lastName)}
                          >
                            <TextField
                              fullWidth
                              error={(!!invitationFormErrors.lastName)}
                              size="small"
                              label={`${t('LastName')} *`}
                              data-cy="lastName"
                              data-customer="last_name"
                              id="outlined-size-normal"
                              variant="outlined"
                              type="text"
                              name="last_name"
                              value={invitationItem.last_name || customerItem.last_name}
                              onChange={handleInvitationItemChange}
                              inputProps={{ maxLength: 20 }}
                            />

                            {invitationFormErrors.lastName && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  {invitationFormErrors.lastName}
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                          <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                            error={(!!invitationFormErrors.phone_code)}
                          >
                            <InputLabel>Code *</InputLabel>
                            <Select
                              name="phone_code"
                              label={`${t('Code')} *`}
                              onChange={(event) => {
                                handleSelectChangeForCode(event);
                              }}
                              value={selectedPhoneCode.value || invitationItem.phone_code || customerItem.phone_code}
                            >
                              <MenuItem disabled value=""><em>{i18n.t('PhoneCode')}</em></MenuItem>
                              {phoneCodeOptions && phoneCodeOptions.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.value}
                                  selected={selectedPhoneCode.value === option.value}
                                >
                                  <img
                                    src={option.label.flagImageSrc}
                                    alt="flag"
                                    className="country-flag"
                                  />
                                  {option.key}
                                  {option.value}
                                </MenuItem>
                              ))}
                            </Select>

                            {invitationFormErrors.phone_code && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  {invitationFormErrors.phone_code}
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl
                            fullWidth
                            size="small"
                            variant="outlined"
                            error={(!!invitationFormErrors.phone_number)}
                          >
                            <TextField
                              fullWidth
                              error={(!!invitationFormErrors.phone_number)}
                              size="small"
                              data-cy="phone"
                              id="phone-detail"
                              data-customer="phone_number"
                              type="text"
                              name="phone_number"
                              value={invitationItem.phone_number || customerItem.phone_number || ''}
                              onChange={handleInvitationItemChange}
                              label={`${t('PhoneNumber')} *`}
                              variant="outlined"
                              InputProps={{
                                inputComponent: TextMaskCustom,
                              }}
                            />

                            {invitationFormErrors.phone_number && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  {invitationFormErrors.phone_number}
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          {checkedLogin
                            ? (
                              <FormControl
                                fullWidth
                                size="small"
                                variant="outlined"
                                error={(!!invitationFormErrors.email || !!errors.email || !!inviteYourselfError)}
                              >
                                <TextField
                                  fullWidth
                                  error={checkedLogin ? (!!invitationFormErrors.email || !!errors.email || !!inviteYourselfError) : ''}
                                  size="small"
                                  data-cy="email"
                                  label={checkedLogin ? 'Email *' : 'Email'}
                                  id="outlined-size-normal"
                                  variant="outlined"
                                  type="text"
                                  name="email"
                                  value={invitationItem.email || ''}
                                  onChange={handleInvitationItemChange}
                                />

                                {checkedLogin ? invitationFormErrors.email && (
                                  <div className="error-message-content">
                                    <ErrorIcon fontSize="small" color="error" />
                                    <FormHelperText>
                                      {invitationFormErrors.email}
                                    </FormHelperText>
                                  </div>
                                ) : ''}

                                {checkedLogin ? errors.email && (
                                  <div className="error-message-content">
                                    <ErrorIcon fontSize="small" color="error" />
                                    <FormHelperText>
                                      {errors.email}
                                    </FormHelperText>
                                  </div>
                                ) : ''}

                                {checkedLogin ? inviteYourselfError && (
                                  <div className="error-message-content">
                                    <ErrorIcon fontSize="small" color="error" />
                                    <FormHelperText>
                                      You can&apos;t invite yourself as customer
                                    </FormHelperText>
                                  </div>
                                ) : ''}
                              </FormControl>
                            ) : ''}
                        </Grid>
                      </Grid>
                    )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                data-cy="cancel"
                onClick={handleCloseInvitationModal}
              >
                {t('Cancel')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                data-cy="send"
                onClick={customerItem.id && !checkedLogin ? handleUpdateCustomer : checkedLogin
                  ? () => handleInviteCustomerFormSubmit(checkedLogin)
                  : () => handleCreateCustomerFormSubmit(checkedLogin)}
                loading={buttonLoading}
              >
                {buttonLoading && <CircularProgress color="white" size={20} />}
                {!buttonLoading && customerItem.id && !checkedLogin ? t('Update') : checkedLogin ? 'Send' : 'Create'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {/* Customer Modal */}
        {invitationItem.id && (
          <Dialog
            size="sm"
            open={isInviteCustomerModalOpen}
            onClose={handleCloseInvitationModal}
          >
            <DialogTitle>
              <span className="alert-title">
                <span>{customerItem.id && !checkedLogin ? i18n.t('EditCustomer') : checkedLogin && 'Invite to Register'}</span>
                <span>
                  <IconButton aria-label="close" className="close-btn" onClick={handleCloseInvitationModal}>
                    <CloseIcon />
                  </IconButton>
                </span>
              </span>
            </DialogTitle>
            <DialogContent dividers>
              <Grid container spacing={2}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <span className="invite-customer-text">
                          Invite
                      <span className="invite-customer-text-name"> {invitationItem.first_name} </span>
                      <span className="invite-customer-text-name"> {invitationItem.last_name} </span>
                          to register on SetTime.
                    </span>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      size="small"
                      variant="outlined"
                      error={(!!invitationFormErrors.email || !!errors.email || !!inviteYourselfError)}
                    >
                      <TextField
                        fullWidth
                        error={checkedLogin ? (!!invitationFormErrors.email || !!errors.email || !!inviteYourselfError) : ''}
                        size="small"
                        data-cy="email"
                        label={checkedLogin ? 'Email address *' : 'Email address *'}
                        id="outlined-size-normal"
                        variant="outlined"
                        type="text"
                        name="email"
                        value={invitationItem.email || ''}
                        onChange={handleInvitationItemChange}
                      />

                      {checkedLogin ? invitationFormErrors.email && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {invitationFormErrors.email}
                        </FormHelperText>
                      </div>
                      ) : ''}

                      {checkedLogin ? errors.email && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {errors.email}
                        </FormHelperText>
                      </div>
                      ) : ''}

                      {checkedLogin ? inviteYourselfError && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                                  You can&apos;t invite yourself as customer
                        </FormHelperText>
                      </div>
                      ) : ''}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                size="small"
                data-cy="cancel"
                onClick={handleCloseInvitationModal}
              >
                {t('Cancel')}
              </Button>
              <Button
                size="small"
                color="primary"
                variant="contained"
                data-cy={invitationItem.id ? 'invite' : 'invite'}
                loading={lineProgress}
                onClick={customerItem.id && !checkedLogin ? handleUpdateCustomer : checkedLogin ? () => handleInviteCustomerFormSubmit(checkedLogin) : () => handleCreateCustomerFormSubmit(checkedLogin)}
              >
                {lineProgress && <CircularProgress color="white" size={20} />}
                {!lineProgress && customerItem.id && !checkedLogin ? t('Update') : checkedLogin ? 'Invite' : 'Create'}
              </Button>
            </DialogActions>
          </Dialog>
        )}
        {/* Edit Customer */}
        <Dialog
          size="sm"
          open={isEditCustomerModalOpen}
          onClose={handleCloseEditModal}
        >
          <DialogTitle>
            <span className="alert-title">
              <span>{i18n.t('EditCustomer')}</span>
              <span>
                <IconButton aria-label="close" className="close-btn" onClick={handleCloseEditModal}>
                  <CloseIcon />
                </IconButton>
              </span>
            </span>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={(!!invitationFormErrors.firstName)}
                >
                  <TextField
                    fullWidth
                    error={(!!invitationFormErrors.firstName)}
                    size="small"
                    label={t('Inputs.FirstName')}
                    data-cy="firstName"
                    data-customer="first_name"
                    variant="outlined"
                    type="text"
                    name="first_name"
                    value={invitationItem.first_name}
                    onChange={handleEditCustomerItemChange}
                    inputProps={{ maxLength: 20 }}
                  />

                  {invitationFormErrors.firstName && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>
                        {invitationFormErrors.firstName}
                      </FormHelperText>
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={(!!invitationFormErrors.lastName)}
                >
                  <TextField
                    fullWidth
                    error={(!!invitationFormErrors.lastName)}
                    size="small"
                    label={t('Inputs.LastName')}
                    data-cy="lastName"
                    data-customer="last_name"
                    id="outlined-size-normal"
                    variant="outlined"
                    type="text"
                    name="last_name"
                    value={invitationItem.last_name}
                    onChange={handleEditCustomerItemChange}
                    inputProps={{ maxLength: 20 }}
                  />

                  {invitationFormErrors.lastName && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {invitationFormErrors.lastName}
                    </FormHelperText>
                  </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={(!!invitationFormErrors.phone_code)}
                >
                  <InputLabel>Code *</InputLabel>
                  <Select
                    name="phone_code"
                    label={`${t('Inputs.Code')} *`}
                    onChange={handleSelectChangeForCode}
                    value={selectedPhoneCode.value || invitationItem.phone_code}
                  >
                    <MenuItem disabled value=""><em>{i18n.t('PhoneCode')}</em></MenuItem>
                    {phoneCodeOptions && phoneCodeOptions.map((option) => (
                      <MenuItem
                        key={option.id}
                        value={option.value}
                        selected={selectedPhoneCode.value === option.value}
                      >
                        <img
                          src={option.label.flagImageSrc}
                          alt="flag"
                          className="country-flag"
                        />
                        {option.key}
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>

                  {invitationFormErrors.phone_code && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {invitationFormErrors.phone_code}
                    </FormHelperText>
                  </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={8}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                  error={(!!invitationFormErrors.phone_number)}
                >
                  <TextField
                    fullWidth
                    error={(!!invitationFormErrors.phone_number)}
                    size="small"
                    data-cy="phone"
                    id="phone-detail"
                    data-customer="phone_number"
                    type="text"
                    name="phone_number"
                    value={invitationItem.phone_number}
                    onChange={handleEditCustomerItemChange}
                    label={`${t('Inputs.PhoneNumber')} *`}
                    variant="outlined"
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  />

                  {invitationFormErrors.phone_number && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {invitationFormErrors.phone_number}
                    </FormHelperText>
                  </div>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              data-cy="cancel"
              onClick={handleCloseEditModal}
            >
              {t('Cancel')}
            </Button>
            <Button
              size="small"
              color="primary"
              variant="contained"
              data-cy="update"
              onClick={handleUpdateCustomer}
            >
              {t('Update')}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Resend Invitation */}
        <Dialog
          size="sm"
          open={isOpenResendModal}
          onClose={handleCloseResendInvitationModal}
        >
          <DialogTitle>
            <span className="alert-title">
              <span>Resend Invite</span>
              <span>
                <IconButton aria-label="close" className="close-btn" onClick={handleCloseResendInvitationModal}>
                  <CloseIcon />
                </IconButton>
              </span>
            </span>
          </DialogTitle>
          <DialogContent dividers>
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
            >
              <Grid item xs={12}>
                <span className="invite-staff-text">
            Resend Invite to <span className="invite-staff-text-name">{resendInvitationItem.email}</span> ?
                </span>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={handleCloseResendInvitationModal}
            >
              {t('Cancel')}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={(() => handleResendCustomerInvitation(resendInvitationItem))}
              loading={lineProgress}
            >
              {lineProgress && <CircularProgress color="white" size={20} />}
              {!lineProgress && 'Resend'}
            </Button>
          </DialogActions>
        </Dialog>
        {/* Customer Preview Modal */}
        <PreviewCustomerModal
          theme={theme}
          tabValue={tabValue}
          a11yProps={a11yProps}
          tableAppHeader={tableAppHeader}
          handleChangeTab={handleChangeTab}
          handleChangeIndex={handleChangeIndex}
          previewCustomerItem={previewCustomerItem}
          isPreviewCustomerModalInfo={isPreviewCustomerModalInfo}
          setIsPreviewCustomerModalInfo={setIsPreviewCustomerModalInfo}
        />
        {alert}
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  ) : <BoxLoading />;
}

Customers.propTypes = {
  match: PropTypes.object.isRequired,
  userAccount: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  openInviteCustomerModalPerformed: PropTypes.bool.isRequired,
  closeInviteCustomerModal: PropTypes.func.isRequired,
  sendCompanyUpdated: PropTypes.func.isRequired,
  // Add new Customer
  storeCustomerToCompany: PropTypes.func.isRequired,
  isStoreCustomerToCompanySuccess: PropTypes.bool.isRequired,
  isStoreCustomerToCompanyError: PropTypes.bool.isRequired,
  storeCustomerToCompanyErrors: PropTypes.array.isRequired,
  newCustomerSucceed: PropTypes.array.isRequired,
  // Invite customer to company
  inviteCustomerToCompany: PropTypes.func.isRequired,
  isInviteCustomerToCompanySuccess: PropTypes.bool.isRequired,
  isInviteCustomerToCompanyError: PropTypes.bool.isRequired,
  inviteCustomerToCompanySucceed: PropTypes.object.isRequired,
  inviteCustomerToCompanyErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  newInvitation: PropTypes.object.isRequired,
  openInviteCustomerModal: PropTypes.func.isRequired,
  // Delete customer
  deleteCustomer: PropTypes.func.isRequired,
  isDeleteCustomerSuccess: PropTypes.bool.isRequired,
  isDeleteCustomerError: PropTypes.bool.isRequired,
  deleteCustomerErrorMessage: PropTypes.string.isRequired,
  // Delete invitation
  deleteInvitation: PropTypes.func.isRequired,
  isDeleteInvitationSuccess: PropTypes.bool.isRequired,
  isDeleteInvitationError: PropTypes.bool.isRequired,
  deleteInvitationErrorMessage: PropTypes.string.isRequired,
  // Update Invitation
  updateInvitation: PropTypes.func.isRequired,
  isUpdateInvitationSuccess: PropTypes.bool.isRequired,
  isUpdateInvitationError: PropTypes.bool.isRequired,
  updatedInvitation: PropTypes.object.isRequired,
  updateInvitationErrorMessage: PropTypes.string.isRequired,
  updateInvitationSucceed: PropTypes.object.isRequired,
  // Update Customer
  updateCustomer: PropTypes.func.isRequired,
  isUpdateCustomerSuccess: PropTypes.bool.isRequired,
  updateCustomerSucceed: PropTypes.object.isRequired,
  // Resend Invitation
  resendCustomerInvitation: PropTypes.func.isRequired,
  isResendCustomerInvitationSuccess: PropTypes.bool.isRequired,
  isResendCustomerInvitationError: PropTypes.bool.isRequired,
  // Customer Filter
  filteredCustomerByStatus: PropTypes.object.isRequired,
  isFilterCustomerByStatusSuccess: PropTypes.bool.isRequired,
  // Get countries
  allCountries: PropTypes.array.isRequired,
  // Get Company By Id Props
  getCompanyById: PropTypes.func.isRequired,
  isGetCompanyByIdSuccess: PropTypes.bool.isRequired,
  companyGot: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userAccount: state.account.userAccount,
  // Create customer to company
  isStoreCustomerToCompanySuccess: state.company.isStoreCustomerToCompanySuccess,
  isStoreCustomerToCompanyError: state.company.isStoreCustomerToCompanyError,
  storeCustomerToCompanyErrors: state.company.storeCustomerToCompanyErrors,
  newCustomerSucceed: state.company.newCustomerSucceed,
  // Invite customer to company
  isInviteCustomerToCompanySuccess: state.company.isInviteCustomerToCompanySuccess,
  isInviteCustomerToCompanyError: state.company.isInviteCustomerToCompanyError,
  inviteCustomerToCompanySucceed: state.company.inviteCustomerToCompanySucceed,
  inviteCustomerToCompanyErrors: state.company.inviteCustomerToCompanyErrors,
  newInvitation: state.company.newInvitation,
  // Delete customer
  isDeleteCustomerSuccess: state.customers.isDeleteCustomerSuccess,
  isDeleteCustomerError: state.customers.isDeleteCustomerError,
  deleteCustomerErrorMessage: state.customers.deleteCustomerErrorMessage,
  // Delete invitation
  isDeleteInvitationSuccess: state.customers.isDeleteInvitationSuccess,
  isDeleteInvitationError: state.customers.isDeleteInvitationError,
  deleteInvitationErrorMessage: state.customers.deleteInvitationErrorMessage,
  // Update Invitation
  isUpdateInvitationSuccess: state.customers.isUpdateInvitationSuccess,
  isUpdateInvitationError: state.customers.isUpdateInvitationError,
  updatedInvitation: state.customers.updatedInvitation,
  updateInvitationErrorMessage: state.customers.updateInvitationErrorMessage,
  updateInvitationSucceed: state.customers.updateInvitationSucceed,
  // Update Customer
  isUpdateCustomerSuccess: state.customers.isUpdateCustomerSuccess,
  isUpdateCustomerError: state.customers.isUpdateCustomerError,
  updatedCustomer: state.customers.updatedCustomer,
  updateCustomerErrorMessage: state.customers.updateCustomerErrorMessage,
  updateCustomerSucceed: state.customers.updateCustomerSucceed,
  // Resend Invitation
  isResendCustomerInvitationSuccess: state.customers.isResendCustomerInvitationSuccess,
  isResendCustomerInvitationError: state.customers.isResendCustomerInvitationError,
  // Get Company By Id
  isGetCompanyByIdSuccess: state.company.isGetCompanyByIdSuccess,
  isGetCompanyByIdError: state.company.isGetCompanyByIdError,
  companyGot: state.company.companyGot,
  getCompanyByIdErrorMessage: state.company.getCompanyByIdErrorMessage,
  // Filter Customer
  isFilterCustomerByStatusSuccess: state.customers.isFilterCustomerByStatusSuccess,
  isFilterCustomerByStatusError: state.customers.isFilterCustomerByStatusError,
  filteredCustomerByStatus: state.customers.filteredCustomerByStatus,
  // Get countries
  allCountries: state.country.allCountries,
});

function mapDispatchToProps(dispatch) {
  return {
    storeCustomerToCompany: (data) => dispatch(storeCustomerToCompanyRequest(data)),
    inviteCustomerToCompany: (data) => dispatch(inviteCustomerToCompanyRequest(data)),
    deleteCustomer: (data) => dispatch(deleteCustomerRequest(data)),
    deleteInvitation: (data) => dispatch(deleteInvitationRequest(data)),
    updateCustomer: (data) => dispatch(updateCustomerRequest(data)),
    updateInvitation: (data) => dispatch(updateInvitationRequest(data)),
    getCompanyById: (data) => dispatch(getCompanyByIdRequest(data)),
    resendCustomerInvitation: (data) => dispatch(resendCustomerInvitationRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
