import { put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  getCompanyStaffRequest,
  getCompanyStaffSuccess,
  getCompanyStaffError,
  getSelectedStaffInfoRequest,
  getSelectedStaffInfoSuccess,
  getSelectedStaffInfoError,
  getStaffPositionsRequest,
  getStaffPositionsSuccess,
  getStaffPositionsError,
  inviteStaffToCompanyRequest,
  inviteStaffToCompanySuccess,
  inviteStaffToCompanyError,
  addStaffToCompanyRequest,
  addStaffToCompanySuccess,
  addStaffToCompanyError,
  deleteStaffRequest,
  deleteStaffSuccess,
  deleteStaffError,
  updateStaffRequest,
  updateStaffSuccess,
  updateStaffError,
  updateInvitationRequest,
  updateInvitationSuccess,
  updateInvitationError,
  deleteStaffInvitationRequest,
  deleteStaffInvitationSuccess,
  deleteStaffInvitationError,
  updateStaffPermissionsRequest,
  updateStaffPermissionsSuccess,
  updateStaffPermissionsError,
  getStaffAvailableTimesRequest,
  getStaffAvailableTimesSuccess,
  getStaffAvailableTimesError,
  acceptStaffInvitationRequest,
  acceptStaffInvitationSuccess,
  acceptStaffInvitationError,
  inviteExistingStaffRequest,
  inviteExistingStaffSuccess,
  inviteExistingStaffError,
  resendStaffInvitationRequest,
  resendStaffInvitationSuccess,
  resendStaffInvitationError,
  filterStaffOptionsRequest,
  filterStaffOptionsSuccess,
  filterStaffOptionsError,
  getStaffCurrentServicesRequest,
  getStaffCurrentServicesSuccess,
  getStaffCurrentServicesError,
  getCalendarCompanyStaffSuccess,
  getCalendarCompanyStaffError,
  getCalendarCompanyStaffRequest,
  getStaffUpcomingAppointmentsSuccess,
  getStaffUpcomingAppointmentsError,
  getStaffAppointmentsHistorySuccess,
  getStaffAppointmentsHistoryError,
  getStaffUpcomingAppointmentsRequest,
  getStaffAppointmentsHistoryRequest,
  getCompanyStaffHaveServiceRequest,
  getCompanyStaffHaveServiceSuccess,
  getCompanyStaffHaveServiceError,
  getCompanyStaffForSelectRequest,
  getCompanyStaffForSelectSuccess,
  getCompanyStaffForSelectError,
  addProfessionRequest,
  addProfessionSuccess,
  addProfessionError,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

const companyStaff = (state) => state.staff.companyStaff;
const professions = (state) => state.country.professions;
const industryId = (state) => state.company.companyGot.industry_id;

function* getCompanyStaff(action) {
  const { forService, page, limit } = action.payload;
  const url = forService ? `company/p/staff?forService=${forService}&page=${page}&limit=${limit}` : 'company/staffs';
  try {
    const response = yield axiosInstance.get(url);
    if (response && response.status === 200) {
      yield put(
        getCompanyStaffSuccess({
          data: response.data.data,
          total: response.data.total,
        })
      );
    }
  } catch (e) {
    yield put(getCompanyStaffError);
  }
}

function* getCompanyStaffSelect() {
  try {
    const response = yield axiosInstance.get('company/staff/select');
    if (response && response.status === 200) {
      yield put(
        getCompanyStaffForSelectSuccess({
          data: response.data,
        })
      );
    }
  } catch (e) {
    yield put(getCompanyStaffForSelectError);
  }
}

function* getCompanyStaffHaveService() {
  try {
    const response = yield axiosInstance.get('company/staffs');
    if (response && response.status === 200) {
      yield put(
        getCompanyStaffHaveServiceSuccess({
          data: response.data.data,
          total: response.data.total,
        })
      );
    }
  } catch (e) {
    yield put(getCompanyStaffHaveServiceError);
  }
}

function* getCompanyStaffInfo(action) {
  const { id } = action.payload;
  try {
    const response = yield axiosInstance.get(`company/staffs/${id}`);
    if (response && response.status === 200) {
      yield put(getSelectedStaffInfoSuccess(response.data));
    }
  } catch (e) {
    yield put(getSelectedStaffInfoError);
  }
}

function* getStaffPositions() {
  try {
    const response = yield axiosInstance.get('positions');
    if (response && response.status === 200) {
      yield put(getStaffPositionsSuccess(response.data));
    }
  } catch (e) {
    yield put(getStaffPositionsError);
  }
}

function* addProfession({ payload }) {
  try {
    const industry_id = yield select(industryId);
    const response = yield axiosInstance.post('company/add-profession', { industry_id, name: payload.name });
    if (response && response.status && response.status === 200) {
      yield put(addProfessionSuccess(response.data.data));
    }
  } catch (e) {
    if (e && e.response && e.response.data) {
      yield put(addProfessionError(e.response.data));
    }
  }
}

function* addStaff(action) {
  const { payload } = action;
  try {
    const { avatar } = payload;
    let data = payload;
    let config = axiosInstance.defaults.headers;
    if (avatar) {
      config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const {
        company_id,
        first_name,
        full_phone_number,
        last_name,
        position_id,
        profession_id,
        phone_number,
        phone_code,
        avatar,
      } = data;
      const formData = new FormData();
      formData.append('company_id', company_id);
      // formData.append('email', email);
      formData.append('first_name', first_name);
      formData.append('full_phone_number', full_phone_number);
      formData.append('last_name', last_name);
      formData.append('phone_code', phone_code);
      formData.append('phone_number', phone_number);
      formData.append('position_id', position_id);
      formData.append('avatar', avatar);
      formData.append('profession_id', profession_id);
      data = formData;
    } else {
      data.logo = undefined;
    }
    const response = yield axiosInstance.post('company/staff', data, config);
    if (response && response.status && response.status === 200) {
      const allProfessions = yield select(professions);
      const professionId = response.data.data.staff_company_professions[0]?.id;
      if (professionId) {
        const foundedProfessionIndex = allProfessions.findIndex((i) => i.id === professionId);
        if (foundedProfessionIndex === -1) {
          allProfessions.push({
            id: response.data.data.staff_company_professions[0].id,
            name: response.data.data.staff_company_professions[0].name,
          });
        }
      }
      yield put(addStaffToCompanySuccess(response.data));
    } else {
      yield put(addStaffToCompanyError(response.response.data));
    }
  } catch (e) {
    if (e && e.response && e.response.data) {
      yield put(addStaffToCompanyError(e.response.data));
    }
  }
}

function* updateStaff(action) {
  const { payload } = action;
  const { aws_avatar } = payload;
  let data = payload;
  let config = axiosInstance.defaults.headers;
  if (aws_avatar) {
    config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const {
      company_id,
      first_name,
      id,
      full_phone_number,
      last_name,
      position_id,
      profession_id,
      phone_number,
      phone_code,
      aws_avatar,
    } = data;
    const formData = new FormData();
    formData.append('id', id);
    formData.append('company_id', company_id);
    // formData.append('email', email);
    formData.append('first_name', first_name);
    formData.append('full_phone_number', full_phone_number);
    formData.append('last_name', last_name);
    formData.append('phone_code', phone_code);
    formData.append('phone_number', phone_number);
    formData.append('position_id', position_id);
    formData.append('profession_id', profession_id);
    formData.append('aws_avatar', aws_avatar);
    formData.append('_method', 'PUT');
    data = formData;
  } else {
    data['_method'] = 'PUT';
  }
  try {
    const response = yield axiosInstance.post(`company/staff/${payload.id}`, data, config);
    if (response && response.status === 200) {
      yield put(updateStaffSuccess(response.data));
    } else {
      yield put(updateStaffError(response.response.data));
    }
  } catch (e) {
    yield put(updateStaffError(e.response.data));
  }
}

function* deleteStaff(action) {
  const { staffId, isConfirmed, multiple } = action.payload;
  try {
    const response = multiple
      ? yield axiosInstance.post('company/delete-staff', { ids: staffId })
      : yield axiosInstance.delete(`company/staff/${staffId}?${isConfirmed ? `&is_confirmed=${true}` : ''}`);
    if (response && response.status === 202) {
      const staff = yield select(companyStaff);
      const staffCopy = [...staff];
      const filteredData = staffCopy.filter((item) => !staffId.includes(item.id));

      yield put(deleteStaffSuccess(filteredData));
    } else {
      yield put(deleteStaffError(response.response.data));
    }
  } catch (e) {
    yield put(deleteStaffError(e.response.data));
  }
}

function* inviteStaff(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('company/staff/invite', payload);
    if (response && response.status === 200) {
      yield put(inviteStaffToCompanySuccess(response.data));
    } else {
      yield put(inviteStaffToCompanyError(response.response.data));
    }
  } catch (e) {
    yield put(inviteStaffToCompanyError(e.response.data));
  }
}

function* updateInvitation(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.put(`company/invite/${payload.id}`, payload);
    if (response && response.status === 200) {
      yield put(updateInvitationSuccess(response.data));
    } else {
      yield put(updateInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(updateInvitationError(e.response.data));
  }
}

function* deleteStaffInvitation(action) {
  const { id } = action.payload;
  try {
    const response = yield axiosInstance.delete(`company/invite/${id}`);
    if (response && response.status === 202) {
      yield put(deleteStaffInvitationSuccess());
    } else {
      yield put(deleteStaffInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(deleteStaffInvitationError(e.response.data));
  }
}

function* updateStaffPermissions(action) {
  const { payload } = action;
  const { id } = payload;
  try {
    const response = yield axiosInstance.put(`company/staff/permissions/${id}`, payload);
    if (response && response.status === 200) {
      yield put(updateStaffPermissionsSuccess(response.data));
    } else {
      yield put(updateStaffPermissionsError(response.response.data));
    }
  } catch (e) {
    yield put(updateStaffPermissionsError(e.response.data));
  }
}

function* getStaffAvailableTimes(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('staff/available-times', payload);
    if (response && response.status === 200) {
      yield put(getStaffAvailableTimesSuccess(response.data));
    } else {
      yield put(getStaffAvailableTimesError());
    }
  } catch (e) {
    yield put(getStaffAvailableTimesError());
  }
}

function* acceptInvitation(action) {
  const { token } = action.payload;
  try {
    const response = yield axiosInstance.post('company/staff/invite/accept', { token });
    if (response && response.status === 200) {
      yield put(acceptStaffInvitationSuccess(response.data));
    } else {
      yield put(acceptStaffInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(acceptStaffInvitationError(e.response.data));
  }
}

function* inviteExistingStaff(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('company/staff/invite', payload);
    if (response && response.status === 200) {
      yield put(inviteExistingStaffSuccess(response.data));
    } else {
      yield put(inviteExistingStaffError(response.response.data));
    }
  } catch (e) {
    yield put(inviteExistingStaffError(e.response.data));
  }
}

function* resendStaffInvitation(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('company/staff/resend', payload);
    if (response && response.status === 202) {
      yield put(resendStaffInvitationSuccess(response.data));
    } else {
      yield put(resendStaffInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(resendStaffInvitationError(e.response.data));
  }
}

function* filterStaffOptions(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post(`company/filtered-staffs?q=${payload.searchValue}`, payload);
    if (response && response.status === 200) {
      yield put(filterStaffOptionsSuccess(response.data));
    } else {
      yield put(filterStaffOptionsError(response.response.data));
    }
  } catch (e) {
    yield put(filterStaffOptionsError(e.response.data));
  }
}

function* getStaffWithCurrentServiceById(action) {
  const { company_service_id } = action.payload;
  try {
    const response = yield axiosInstance.get(`company/service/staffs?company_service_id=${company_service_id}`);
    if (response && response.status === 200) {
      yield put(getStaffCurrentServicesSuccess(response.data));
    } else {
      yield put(getStaffCurrentServicesError(response.response.data));
    }
  } catch (e) {
    yield put(getStaffCurrentServicesError(e.response.data));
  }
}

function* getCalendarCompanyStaff() {
  try {
    const response = yield axiosInstance.get('company/web/get-company-staff');

    if (response && response.status === 200) {
      yield put(getCalendarCompanyStaffSuccess(response.data));
    } else {
      yield put(getCalendarCompanyStaffError(response.response.data));
    }
  } catch (e) {
    yield put(getCalendarCompanyStaffError(e.response.data));
  }
}

function* getStaffUpcomingAppointments(action) {
  const { id, page } = action.payload;
  const response = yield axiosInstance.get(`p/appointments/staff/upcoming/${id}?page=${page}`);
  try {
    if (response && response.status === 200) {
      yield put(getStaffUpcomingAppointmentsSuccess(response.data));
    } else {
      yield put(getStaffUpcomingAppointmentsError(response.response.data.errors));
    }
  } catch (e) {
    yield put(getStaffUpcomingAppointmentsError(e.response.data));
  }
}

function* getStaffAppointmentsHistory(action) {
  const { id, page } = action.payload;
  const response = yield axiosInstance.get(`p/appointments/staff/history/${id}?page=${page}`);
  try {
    if (response && response.status === 200) {
      yield put(getStaffAppointmentsHistorySuccess(response.data));
    } else {
      yield put(getStaffAppointmentsHistoryError(response.response.data.errors));
    }
  } catch (e) {
    yield put(getStaffAppointmentsHistoryError(e.response.data));
  }
}

export default function*() {
  yield takeLatest(getCompanyStaffRequest, getCompanyStaff);
  yield takeLeading(getCompanyStaffForSelectRequest, getCompanyStaffSelect);
  yield takeLatest(getCompanyStaffHaveServiceRequest, getCompanyStaffHaveService);
  yield takeLatest(getSelectedStaffInfoRequest, getCompanyStaffInfo);
  yield takeLatest(getStaffPositionsRequest, getStaffPositions);
  yield takeLatest(addStaffToCompanyRequest, addStaff);
  yield takeLatest(addProfessionRequest, addProfession);
  yield takeLatest(updateStaffRequest, updateStaff);
  yield takeLatest(deleteStaffRequest, deleteStaff);
  yield takeLatest(inviteStaffToCompanyRequest, inviteStaff);
  yield takeLatest(updateInvitationRequest, updateInvitation);
  yield takeLatest(deleteStaffInvitationRequest, deleteStaffInvitation);
  yield takeLatest(updateStaffPermissionsRequest, updateStaffPermissions);
  yield takeLatest(getStaffAvailableTimesRequest, getStaffAvailableTimes);
  yield takeLatest(acceptStaffInvitationRequest, acceptInvitation);
  yield takeLatest(inviteExistingStaffRequest, inviteExistingStaff);
  yield takeLatest(resendStaffInvitationRequest, resendStaffInvitation);
  yield takeLatest(filterStaffOptionsRequest, filterStaffOptions);
  yield takeLatest(getStaffCurrentServicesRequest, getStaffWithCurrentServiceById);
  yield takeLatest(getCalendarCompanyStaffRequest, getCalendarCompanyStaff);
  yield takeLatest(getStaffUpcomingAppointmentsRequest, getStaffUpcomingAppointments);
  yield takeLatest(getStaffAppointmentsHistoryRequest, getStaffAppointmentsHistory);
}
