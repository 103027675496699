import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  const { name } = other;

  const [selectedMask, setSelectedMask] = useState([]);

  useEffect(() => {
    if (name === 'phoneNumber' || name === 'phone_number') {
      const mask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
      setSelectedMask(mask);
    }
  }, []);

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={selectedMask}
      placeholder="(93) 326-464"
      guide={false}
      id="my-input-id"
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

export default TextMaskCustom;
