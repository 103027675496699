import React, { useState, useEffect, useRef } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  List,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Box, Button,
} from '@material-ui/core';
import io from 'socket.io-client';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  makeSeenRequest,
  makeReadRequest,
  acceptAppointmentRequest,
  cancelAppointmentRequest,
  acceptCustomerInvitationRequest,
  declineCustomerInvitationRequest,
  acceptIndustryCustomerInvitationRequest,
  declineIndustryCustomerInvitationRequest,
  acceptStaffInvitationFromNotificationRequest,
  declineStaffInvitationFromNotificationRequest,
  getNotificationByIdRequest,
  readAllNotificationsRequest,
  deleteNotificationRequest,
} from 'redux/notification/actions';
import { changeLanguageRequest } from 'redux/translate/action';
import usePrevious from 'CustomHooks/usePrevious';
import SnackbarToast from 'Modules/SnackbarToast';
import sortingNotifications from 'Modules/sortingNotifications';
import { useTranslation } from 'react-i18next';
import { getUserAccountNotificationsRequest, resetNotificationsRequest } from '../../redux/account/actions';
import GetUnseenNotificationCountContent from './GetUnseeNotificationCountContent/getUnseenNotificationCountContent';
import TopBarNotificationItemContent from './TopBarNotificationItemContent/TopBarNotificationItemContent';

function Notifications(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    account,
    history,
    userNotifications,
    isMakeSeenSuccess,
    isMakeSeenError,
    makeSeenErrorMessage,
    makeRead,
    isMakeReadSuccess,
    isMakeReadError,
    makeReadErrorMessage,
    acceptAppointment,
    isAcceptAppointmentSuccess,
    isAcceptAppointmentError,
    acceptAppointmentErrorMessage,
    cancelAppointment,
    isCancelAppointmentSuccess,
    isCancelAppointmentError,
    cancelAppointmentErrorMessage,
    resetNotifications,
    acceptCustomerInvitation,
    isAcceptCustomerInvitationSuccess,
    isAcceptCustomerInvitationError,
    acceptCustomerInvitationErrorMessage,
    declineCustomerInvitation,
    isDeclineCustomerInvitationSuccess,
    isDeclineCustomerInvitationError,
    declineCustomerInvitationErrorMessage,
    acceptIndustryCustomerInvitation,
    isAcceptIndustryCustomerInvitationSuccess,
    isAcceptIndustryCustomerInvitationError,
    acceptIndustryCustomerInvitationErrorMessage,
    declineIndustryCustomerInvitation,
    isDeclineIndustryCustomerInvitationSuccess,
    isDeclineIndustryCustomerInvitationError,
    declineIndustryCustomerInvitationErrorMessage,
    acceptStaffInvitation,
    isAcceptStaffInvitationSuccess,
    isAcceptStaffInvitationError,
    acceptStaffInvitationErrorMessage,
    declineStaffInvitation,
    isDeclineStaffInvitationSuccess,
    isDeclineStaffInvitationError,
    declineStaffInvitationErrorMessage,
    makeSeen,
    getNotificationById,
    isGetNotificationByIdSuccess,
    isGetNotificationByIdError,
    getNotificationByIdErrorMessage,
    notificationById,
    getAccountNotifications,
    isGetUserAccountNotificationsSuccess,
    isGetUserAccountNotificationsError,
    accountNotifications,
    notificationsCount,
    isDeleteNotificationSuccess,
    isDeleteNotificationError,
    deleteNotificationErrorMessage,
    changeLanguage,
    currentLanguage,
    isGetTranslateLanguageSuccess,
    isGetTranslateLanguageError,
  } = props;

  const { isReadAllNotificationsSuccess } = useSelector((state) => state.notification);

  const prevIsGetNotificationByIdSuccess = usePrevious(isGetNotificationByIdSuccess);
  const prevIsGetNotificationByIdError = usePrevious(isGetNotificationByIdError);
  const prevIsDeleteNotificationSuccess = usePrevious(isDeleteNotificationSuccess);
  const prevIsDeleteNotificationError = usePrevious(isDeleteNotificationError);

  const [socket, setSocket] = useState(null);
  const [isAccountJoined, setIsAccountJoined] = useState(false);
  const [lastClickedNotificationId, setLastClickedNotificationId] = useState(null);
  const [notificationToDeleteId, setNotificationToDeleteId] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const anchorRef = useRef(null);
  const [page, setPage] = useState(2);
  const [count, setCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);

  const prevOpen = useRef(isOpenNotifications);

  // Get some props previous values
  const prevIsMakeSeenSuccess = usePrevious(isMakeSeenSuccess);
  const prevIsMakeSeenError = usePrevious(isMakeSeenError);
  const prevIsMakeReadSuccess = usePrevious(isMakeReadSuccess);
  const prevIsMakeReadError = usePrevious(isMakeReadError);
  const prevIsReadAllNotificationsSuccess = usePrevious(isReadAllNotificationsSuccess);
  const prevIsAcceptAppointmentSuccess = usePrevious(isAcceptAppointmentSuccess);
  const prevIsAcceptAppointmentError = usePrevious(isAcceptAppointmentError);
  const prevIsCancelAppointmentSuccess = usePrevious(isCancelAppointmentSuccess);
  const prevIsCancelAppointmentError = usePrevious(isCancelAppointmentError);
  const prevIsAcceptCustomerInvitationSuccess = usePrevious(isAcceptCustomerInvitationSuccess);
  const prevIsAcceptCustomerInvitationError = usePrevious(isAcceptCustomerInvitationError);
  const prevIsDeclineCustomerInvitationSuccess = usePrevious(isDeclineCustomerInvitationSuccess);
  const prevIsDeclineCustomerInvitationError = usePrevious(isDeclineCustomerInvitationError);
  const prevIsAcceptIndividualCustomerInvitationSuccess = usePrevious(isAcceptIndustryCustomerInvitationSuccess);
  const prevIsAcceptIndividualCustomerInvitationError = usePrevious(isAcceptIndustryCustomerInvitationError);
  const prevIsDeclineIndustryCustomerInvitationSuccess = usePrevious(isDeclineIndustryCustomerInvitationSuccess);
  const prevIsDeclineIndustryCustomerInvitationError = usePrevious(isDeclineIndustryCustomerInvitationError);
  const prevIsAcceptStaffInvitationSuccess = usePrevious(isAcceptStaffInvitationSuccess);
  const prevIsAcceptStaffInvitationError = usePrevious(isAcceptStaffInvitationError);
  const prevIsDeclineStaffInvitationSuccess = usePrevious(isDeclineStaffInvitationSuccess);
  const prevIsDeclineStaffInvitationError = usePrevious(isDeclineStaffInvitationError);
  const prevIsGetUserAccountNotificationsSuccess = usePrevious(isGetUserAccountNotificationsSuccess);
  const prevIsGetUserAccountNotificationError = usePrevious(isGetUserAccountNotificationsError);

  // Component mounted
  useEffect(() => {
    const socket = io(process.env.REACT_APP_NODEJS_API_URL);
    setSocket(socket);

    socket.on('receiveNotification', (id) => {
      getNotificationById({ id });
    });

    if (!isGetTranslateLanguageError && !isGetTranslateLanguageSuccess && !currentLanguage) {
      changeLanguage(i18n.language);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (currentLanguage) {
      setPage(2);
      dispatch(getAccountNotifications({ page: 1 }));
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (userNotifications.length > 0) {
      // setNotifications([...userNotifications]);
      const filteredNotifications = sortingNotifications(userNotifications);
      setNotifications(filteredNotifications);
      setCount(notificationsCount);
    }
  }, [userNotifications]);

  useEffect(() => {
    if (socket && account && !isAccountJoined) {
      const permalink = `account${account.permalink}`;
      socket.emit('joinNotifications', { permalink });
      setIsAccountJoined(true);
    }
  }, [socket, account]);

  useEffect(() => {
    if (prevIsGetNotificationByIdSuccess === false && isGetNotificationByIdSuccess) {
      setNotifications((prevValue) => ([notificationById, ...prevValue]));
    } else if (prevIsGetNotificationByIdError === false && isGetNotificationByIdError) {
      console.log(getNotificationByIdErrorMessage);
    }
  }, [isGetNotificationByIdSuccess, isGetNotificationByIdError]);

  // Perform, when make notifications seen success
  useEffect(() => {
    if (prevIsReadAllNotificationsSuccess === false && isReadAllNotificationsSuccess) {
      const notificationCopy = [...notifications];
      notificationCopy.map((notificationItem) => {
        notificationItem.read_at = moment();
      });

      setNotifications(notificationCopy);
    }
  }, [isReadAllNotificationsSuccess]);

  // Perform, when make notifications seen success
  useEffect(() => {
    if (prevIsMakeSeenSuccess === false && isMakeSeenSuccess) {
      const now = moment();
      const seenNotifications = notifications.map((item) => ({
        ...item,
        seen_at: now,
      }));
      setNotifications(seenNotifications);
      resetNotifications(seenNotifications);
    }
  }, [isMakeSeenSuccess]);
  // Perform, when make notifications seen error
  useEffect(() => {
    if (prevIsMakeSeenError === false && isMakeSeenError) {
      snackBarAlert(true, makeSeenErrorMessage, 'error');
    }
  }, [isMakeSeenError]);
  // Perform, when make notification read success
  useEffect(() => {
    if (prevIsMakeReadSuccess === false && isMakeReadSuccess && lastClickedNotificationId) {
      const notificationsCopy = [...notifications];
      const notificationIndex = notificationsCopy.findIndex((item) => item.id === lastClickedNotificationId);
      notificationsCopy[notificationIndex].read_at = moment();
      setNotifications(notificationsCopy);
      resetNotifications(notificationsCopy);
      // const { link } = notificationsCopy[notificationIndex];
      // if (link) {
      //   history.push(link);
      // }
    }
  }, [isMakeReadSuccess]);
  // Perform, when make notification read error
  useEffect(() => {
    if (prevIsMakeReadError === false && isMakeReadError) {
      const notificationsCopy = [...notifications];
      const notificationIndex = notificationsCopy.findIndex((item) => item.id === lastClickedNotificationId);
      const notification = notificationsCopy[notificationIndex];
      if (notification && notification.link) {
        history.push(notification.link);
      }
      snackBarAlert(true, makeReadErrorMessage, 'error');
    }
  }, [isMakeReadError]);
  // Perform, when accept appointment success
  useEffect(() => {
    if (prevIsAcceptAppointmentSuccess === false && isAcceptAppointmentSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, t('AppointmentAccepted'), 'success');
    }
  }, [isAcceptAppointmentSuccess]);
  // Perform, when accept appointment error
  useEffect(() => {
    if (prevIsAcceptAppointmentError === false && isAcceptAppointmentError) {
      snackBarAlert(true, acceptAppointmentErrorMessage, 'error');
    }
  }, [isAcceptAppointmentError]);
  // Perform, when cancel appointment success
  useEffect(() => {
    if (prevIsCancelAppointmentSuccess === false && isCancelAppointmentSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, t('AppointmentCanceled'), 'success');
    }
  }, [isCancelAppointmentSuccess]);
  // Perform, when accept appointment error
  useEffect(() => {
    if (prevIsCancelAppointmentError === false && isCancelAppointmentError) {
      snackBarAlert(true, cancelAppointmentErrorMessage, 'error');
    }
  }, [isCancelAppointmentError]);
  // Perform, when accept customer invitation success
  useEffect(() => {
    if (prevIsAcceptCustomerInvitationSuccess === false && isAcceptCustomerInvitationSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, t('InvitationAccepted'), 'success');
    }
  }, [isAcceptCustomerInvitationSuccess]);
  // Perform, when accept customer invitation error
  useEffect(() => {
    if (prevIsAcceptCustomerInvitationError === false && isAcceptCustomerInvitationError) {
      snackBarAlert(true, acceptCustomerInvitationErrorMessage, 'error');
    }
  }, [isAcceptCustomerInvitationError]);
  // Perform, when decline customer invitation success
  useEffect(() => {
    if (prevIsDeclineCustomerInvitationSuccess === false && isDeclineCustomerInvitationSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, t('InvitationDeclined'), 'success');
    }
  }, [isDeclineCustomerInvitationSuccess]);
  // Perform, when decline customer invitation error
  useEffect(() => {
    if (prevIsDeclineCustomerInvitationError === false && isDeclineCustomerInvitationError) {
      snackBarAlert(true, declineCustomerInvitationErrorMessage, 'error');
    }
  }, [isDeclineCustomerInvitationError]);
  // Perform, when accept industry customer invitation success
  useEffect(() => {
    if (prevIsAcceptIndividualCustomerInvitationSuccess === false && isAcceptIndustryCustomerInvitationSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, t('InvitationAccepted'), 'success');
    }
  }, [isAcceptIndustryCustomerInvitationSuccess]);
  // Perform, when accept industry customer invitation error
  useEffect(() => {
    if (prevIsAcceptIndividualCustomerInvitationError === false && isAcceptIndustryCustomerInvitationError) {
      snackBarAlert(true, acceptIndustryCustomerInvitationErrorMessage, 'error');
    }
  }, [isAcceptIndustryCustomerInvitationError]);
  // Perform, when decline industry customer invitation success
  useEffect(() => {
    if (prevIsDeclineIndustryCustomerInvitationSuccess === false && isDeclineIndustryCustomerInvitationSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, t('InvitationDeclined'), 'success');
    }
  }, [isDeclineIndustryCustomerInvitationSuccess]);
  // Perform, when decline industry customer invitation error
  useEffect(() => {
    if (prevIsDeclineIndustryCustomerInvitationError === false && isDeclineIndustryCustomerInvitationError) {
      snackBarAlert(true, declineIndustryCustomerInvitationErrorMessage, 'error');
    }
  }, [isDeclineIndustryCustomerInvitationError]);
  // Perform, when accept staff invitation success
  useEffect(() => {
    if (prevIsAcceptStaffInvitationSuccess === false && isAcceptStaffInvitationSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, t('InvitationAccepted'), 'success');
    }
  }, [isAcceptStaffInvitationSuccess]);
  // Perform, when accept staff invitation error
  useEffect(() => {
    if (prevIsAcceptStaffInvitationError === false && isAcceptStaffInvitationError) {
      snackBarAlert(true, acceptStaffInvitationErrorMessage, 'error');
    }
  }, [isAcceptStaffInvitationError]);
  // Perform, when accept staff invitation success
  useEffect(() => {
    if (prevIsDeclineStaffInvitationSuccess === false && isDeclineStaffInvitationSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, t('InvitationDeclined'), 'success');
    }
  }, [isDeclineStaffInvitationSuccess]);
  // Perform, when accept staff invitation error
  useEffect(() => {
    if (prevIsDeclineStaffInvitationError === false && isDeclineStaffInvitationError) {
      snackBarAlert(true, declineStaffInvitationErrorMessage, 'error');
    }
  }, [isDeclineStaffInvitationError]);
  // Perform, when notify modal is open
  useEffect(() => {
    if (prevOpen.current === true && isOpenNotifications === false) {
      anchorRef.current.focus();
    }

    if (isOpenNotifications) {
      makeSeen();
    }

    prevOpen.current = isOpenNotifications;
  }, [isOpenNotifications]);

  // Perform, when get notifications success
  useEffect(() => {
    if (prevIsGetUserAccountNotificationsSuccess === false && isGetUserAccountNotificationsSuccess) {
      if (showMore) {
        if (accountNotifications.length > 0) {
          const notificationsCopy = [...notifications];
          const moreNotifications = [...notificationsCopy, ...accountNotifications];
          setNotifications(moreNotifications);
          setPage(page + 1);
          setShowMore(false);
          setButtonLoading(false);
        }
        setButtonLoading(false);
      }

      setLoading(false);
    }
  }, [isGetUserAccountNotificationsSuccess]);

  useEffect(() => {
    if (prevIsGetUserAccountNotificationError === false && isGetUserAccountNotificationsError) {
      snackBarAlert(true, 'Failed get notifications', 'error');
    }
  }, [isGetUserAccountNotificationsError]);

  useEffect (() => {
    if (!isGetUserAccountNotificationsSuccess && !isGetUserAccountNotificationsError && !notifications.length) {
      setLoading(true);
    }
  }, [notifications, isGetUserAccountNotificationsSuccess, isGetUserAccountNotificationsError]);

  useEffect(() => {
    if (prevIsDeleteNotificationSuccess === false && isDeleteNotificationSuccess) {
      const notificationsCopy = [...notifications];
      const index = notificationsCopy.findIndex((item) => item.id === notificationToDeleteId);

      if (index !== -1) {
        notificationsCopy.splice(index, 1);
        setAnchorEl(null);
        setNotifications(notificationsCopy);
      }
    } else if (prevIsDeleteNotificationError === false && isDeleteNotificationError) {
      snackBarAlert(true, deleteNotificationErrorMessage, 'error');
    }
  }, [isDeleteNotificationSuccess, isDeleteNotificationError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const removeDeletedNotification = (notifications) => {
    const notificationsCopy = [...notifications];
    const notificationIndex = notificationsCopy.findIndex((item) => item.id === notificationToDeleteId);
    notificationsCopy.splice(notificationIndex, 1);
    setLastClickedNotificationId(null);
    setNotifications(notificationsCopy);
    resetNotifications(notificationsCopy);
  };

  const getUnseenNotificationCount = () => {
    const unseenNotifications = notifications.filter((item) => !item.seen_at);
    return unseenNotifications.length;
  };

  const redirectNotification = (event, notification) => {
    const { classList } = event.target;
    const { id } = notification;
    if (classList.contains('btn') || classList.contains('activity')) {
      return false;
    }
    if (!notification.read_at) {
      setLastClickedNotificationId(id);
      makeRead({ id });
    }
  };

  const handleAcceptAppointment = (id) => {
    setNotificationToDeleteId(id);
    acceptAppointment({ id });
    setNotificationActionType(id, 'Accepted');
  };

  const handleCancelAppointment = (id) => {
    setNotificationToDeleteId(id);
    cancelAppointment({ id });
    setNotificationActionType(id, 'Canceled');
  };

  const handleAcceptCustomerInvitation = (id) => {
    setNotificationToDeleteId(id);
    acceptCustomerInvitation({ id });
    setNotificationActionType(id, 'Accepted');
  };

  const handleDeclineCustomerInvitation = (id) => {
    setNotificationToDeleteId(id);
    declineCustomerInvitation({ id });
    setNotificationActionType(id, 'Declined');
  };

  const handleAcceptIndustryCustomerInvitation = (id) => {
    setNotificationToDeleteId(id);
    acceptIndustryCustomerInvitation({ id });
    setNotificationActionType(id, 'Accepted');
  };

  const handleDeclineIndustryCustomerInvitation = (id) => {
    setNotificationToDeleteId(id);
    declineIndustryCustomerInvitation({ id });
    setNotificationActionType(id, 'Declined');
  };

  const handleAcceptStaffInvitation = (id) => {
    setNotificationToDeleteId(id);
    acceptStaffInvitation({ id });
    setNotificationActionType(id, 'Accepted');
  };

  const handleDeclineStaffInvitation = (id) => {
    setNotificationToDeleteId(id);
    declineStaffInvitation({ id });
    setNotificationActionType(id, 'Declined');
  };

  const setNotificationActionType = (id, typeMessage) => {
    const notificationCopy = [...notifications];
    const changedNotifyKey = notificationCopy.findIndex((item) => item.id === id);
    if (changedNotifyKey !== -1) {
      const changedNotify = notificationCopy[changedNotifyKey];
      changedNotify.action.type.name = 'info';
      changedNotify.action.type_message = typeMessage;
      changedNotify.message = `${typeMessage} notification`;
      changedNotify.action.message = `${typeMessage} notification`;
      changedNotify.action.description = `${typeMessage} notification`;
      setNotifications([...notificationCopy]);
    }
  };

  const toggleNotificationsMenu = () => {
    setIsOpenNotifications((prevOpen) => !prevOpen);
  };

  const closeNotificationsMenu = (event) => {
    let classList = [];
    if (event.target && event.target.classList) {
      classList = [...event.target.classList];
    }

    if ((anchorRef.current && anchorRef.current.contains(event.target)) || classList.includes('activity')) {
      return false;
    }

    setAnchorEl(null);
    setIsOpenNotifications(false);
  };

  const handleShowNotifications = () => {
    if (count !== notifications.length) {
      getAccountNotifications({
        page,
      });
      setShowMore(true);
      setButtonLoading(true);
    } else {
      setPage(2);
      const notificationCopy = [...notifications];
      const sliceNotifications = notificationCopy.slice(0, 10);
      setNotifications(sliceNotifications);
    }
  };

  const handleReadAll = (e) => {
    e.stopPropagation();
    dispatch(readAllNotificationsRequest());
  };

  const handleDelete = () => {
    if (notificationToDeleteId) {
      dispatch(deleteNotificationRequest({ id: notificationToDeleteId }));
    }
  };

  const handleOpenMenu = (event, id) => {
    event.stopPropagation();
    setNotificationToDeleteId(id);
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Box display="flex">
        <GetUnseenNotificationCountContent
          anchorRef={anchorRef}
          toggleNotificationsMenu={toggleNotificationsMenu}
          getUnseenNotificationCount={getUnseenNotificationCount}
        />
        <Popper
          open={isOpenNotifications}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          className="notification"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper>
                <ClickAwayListener onClickAway={closeNotificationsMenu}>
                  <div className='notification-content'>
                    <div className="notification-header">
                      <div className="title-box">
                        <p>
                          {t('Notification')}
                        </p>
                      </div>
                      <div className="read-all-box">
                        <a onClick={(e) => handleReadAll(e)}>
                          {t('ReadAll')}
                        </a>
                      </div>
                    </div>
                    {/* <MenuList autoFocusItem={isOpenNotifications} id="menu-list-grow" disablePadding> */}
                      <List disablePadding className="buttons-notification">
                        <TopBarNotificationItemContent
                          notifications={notifications}
                          redirectNotification={redirectNotification}
                          handleAcceptAppointment={handleAcceptAppointment}
                          handleCancelAppointment={handleCancelAppointment}
                          handleAcceptCustomerInvitation={handleAcceptCustomerInvitation}
                          handleDeclineCustomerInvitation={handleDeclineCustomerInvitation}
                          handleAcceptIndustryCustomerInvitation={handleAcceptIndustryCustomerInvitation}
                          handleDeclineIndustryCustomerInvitation={handleDeclineIndustryCustomerInvitation}
                          handleAcceptStaffInvitation={handleAcceptStaffInvitation}
                          handleDeclineStaffInvitation={handleDeclineStaffInvitation}
                          handleDelete={handleDelete}
                          handleOpenMenu={handleOpenMenu}
                          setAnchorEl={setAnchorEl}
                          anchorEl={anchorEl}
                          loading={loading}
                        />
                      </List>
                      <Box className="show-less-box">
                        {count > 5 && (
                          <Button
                            size="small"
                            data-cy="send"
                            type="text"
                            onClick={handleShowNotifications}
                            className="see-all"
                            variant="contained"
                            loading={buttonLoading || undefined}
                          >
                            {buttonLoading && <CircularProgress color="white" size={20} />}
                            {notifications.length !== count ? t('ShowMore') : t('ShowLess')}
                          </Button>
                        )}
                      </Box>
                    {/* </MenuList> */}
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

Notifications.propTypes = {
  account: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userNotifications: PropTypes.array.isRequired,
  // Reset Notifications
  resetNotifications: PropTypes.func.isRequired,
  // Make notifications seen
  makeSeen: PropTypes.func.isRequired,
  isMakeSeenSuccess: PropTypes.bool.isRequired,
  isMakeSeenError: PropTypes.bool.isRequired,
  makeSeenErrorMessage: PropTypes.string.isRequired,
  // Make notification read
  makeRead: PropTypes.func.isRequired,
  isMakeReadSuccess: PropTypes.bool.isRequired,
  isMakeReadError: PropTypes.bool.isRequired,
  makeReadErrorMessage: PropTypes.string.isRequired,
  // Accept appointment
  acceptAppointment: PropTypes.func.isRequired,
  isAcceptAppointmentSuccess: PropTypes.bool.isRequired,
  isAcceptAppointmentError: PropTypes.bool.isRequired,
  acceptAppointmentErrorMessage: PropTypes.string.isRequired,
  // Cancel appointment
  cancelAppointment: PropTypes.func.isRequired,
  isCancelAppointmentSuccess: PropTypes.bool.isRequired,
  isCancelAppointmentError: PropTypes.bool.isRequired,
  cancelAppointmentErrorMessage: PropTypes.string.isRequired,
  // Accept customer invitation
  acceptCustomerInvitation: PropTypes.func.isRequired,
  isAcceptCustomerInvitationSuccess: PropTypes.bool.isRequired,
  isAcceptCustomerInvitationError: PropTypes.bool.isRequired,
  acceptCustomerInvitationErrorMessage: PropTypes.string.isRequired,
  // Accept individual customer invitation
  acceptIndustryCustomerInvitation: PropTypes.func.isRequired,
  isAcceptIndustryCustomerInvitationSuccess: PropTypes.bool.isRequired,
  isAcceptIndustryCustomerInvitationError: PropTypes.bool.isRequired,
  acceptIndustryCustomerInvitationErrorMessage: PropTypes.string.isRequired,
  // Decline customer invitation
  declineCustomerInvitation: PropTypes.func.isRequired,
  isDeclineCustomerInvitationSuccess: PropTypes.bool.isRequired,
  isDeclineCustomerInvitationError: PropTypes.bool.isRequired,
  declineCustomerInvitationErrorMessage: PropTypes.string.isRequired,
  // Decline individual customer invitation
  declineIndustryCustomerInvitation: PropTypes.func.isRequired,
  isDeclineIndustryCustomerInvitationSuccess: PropTypes.bool.isRequired,
  isDeclineIndustryCustomerInvitationError: PropTypes.bool.isRequired,
  declineIndustryCustomerInvitationErrorMessage: PropTypes.string.isRequired,
  // Accept staff invitation
  acceptStaffInvitation: PropTypes.func.isRequired,
  isAcceptStaffInvitationSuccess: PropTypes.bool.isRequired,
  isAcceptStaffInvitationError: PropTypes.bool.isRequired,
  acceptStaffInvitationErrorMessage: PropTypes.string.isRequired,
  // Decline staff invitation
  isDeclineStaffInvitationSuccess: PropTypes.bool.isRequired,
  isDeclineStaffInvitationError: PropTypes.bool.isRequired,
  declineStaffInvitationErrorMessage: PropTypes.string.isRequired,
  declineStaffInvitation: PropTypes.func.isRequired,
  // Get notification by id
  getNotificationById: PropTypes.func.isRequired,
  isGetNotificationByIdSuccess: PropTypes.bool.isRequired,
  isGetNotificationByIdError: PropTypes.bool.isRequired,
  getNotificationByIdErrorMessage: PropTypes.string.isRequired,
  notificationById: PropTypes.object.isRequired,
  // Get notifications
  getAccountNotifications: PropTypes.func.isRequired,
  isGetUserAccountNotificationsSuccess: PropTypes.bool.isRequired,
  isGetUserAccountNotificationsError: PropTypes.bool.isRequired,
  accountNotifications: PropTypes.array.isRequired,
  notificationsCount: PropTypes.number.isRequired,
  isDeleteNotificationSuccess: PropTypes.bool.isRequired,
  isDeleteNotificationError: PropTypes.bool.isRequired,
  deleteNotificationErrorMessage: PropTypes.string.isRequired,
  // Language
  changeLanguage: PropTypes.func.isRequired,
  currentLanguage: PropTypes.string.isRequired,
  isGetTranslateLanguageSuccess: PropTypes.bool.isRequired,
  isGetTranslateLanguageError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isMakeSeenSuccess: state.notification.isMakeSeenSuccess,
  isMakeSeenError: state.notification.isMakeSeenError,
  makeSeenErrorMessage: state.notification.makeSeenErrorMessage,
  isMakeReadSuccess: state.notification.isMakeReadSuccess,
  isMakeReadError: state.notification.isMakeReadError,
  makeReadErrorMessage: state.notification.makeReadErrorMessage,
  isAcceptAppointmentSuccess: state.notification.isAcceptAppointmentSuccess,
  isAcceptAppointmentError: state.notification.isAcceptAppointmentError,
  acceptAppointmentErrorMessage: state.notification.acceptAppointmentErrorMessage,
  isCancelAppointmentSuccess: state.notification.isCancelAppointmentSuccess,
  isCancelAppointmentError: state.notification.isCancelAppointmentError,
  cancelAppointmentErrorMessage: state.notification.cancelAppointmentErrorMessage,
  isAcceptCustomerInvitationSuccess: state.notification.isAcceptCustomerInvitationSuccess,
  isAcceptCustomerInvitationError: state.notification.isAcceptCustomerInvitationError,
  acceptCustomerInvitationErrorMessage: state.notification.acceptCustomerInvitationErrorMessage,
  isDeclineCustomerInvitationSuccess: state.notification.isDeclineCustomerInvitationSuccess,
  isDeclineCustomerInvitationError: state.notification.isDeclineCustomerInvitationError,
  declineCustomerInvitationErrorMessage: state.notification.declineCustomerInvitationErrorMessage,
  isAcceptIndustryCustomerInvitationSuccess: state.notification.isAcceptIndustryCustomerInvitationSuccess,
  isAcceptIndustryCustomerInvitationError: state.notification.isAcceptIndustryCustomerInvitationError,
  acceptIndustryCustomerInvitationErrorMessage: state.notification.acceptIndustryCustomerInvitationErrorMessage,
  isDeclineIndustryCustomerInvitationSuccess: state.notification.isDeclineIndustryCustomerInvitationSuccess,
  isDeclineIndustryCustomerInvitationError: state.notification.isDeclineIndustryCustomerInvitationError,
  declineIndustryCustomerInvitationErrorMessage: state.notification.declineIndustryCustomerInvitationErrorMessage,
  isAcceptStaffInvitationSuccess: state.notification.isAcceptStaffInvitationSuccess,
  isAcceptStaffInvitationError: state.notification.isAcceptStaffInvitationError,
  acceptStaffInvitationErrorMessage: state.notification.acceptStaffInvitationErrorMessage,
  isDeclineStaffInvitationSuccess: state.notification.isDeclineStaffInvitationSuccess,
  isDeclineStaffInvitationError: state.notification.isDeclineStaffInvitationError,
  declineStaffInvitationErrorMessage: state.notification.declineStaffInvitationErrorMessage,
  isGetNotificationByIdSuccess: state.notification.isGetNotificationByIdSuccess,
  isGetNotificationByIdError: state.notification.isGetNotificationByIdError,
  getNotificationByIdErrorMessage: state.notification.getNotificationByIdErrorMessage,
  notificationById: state.notification.notificationById,
  isGetUserAccountNotificationsSuccess: state.account.isGetUserAccountNotificationsSuccess,
  isGetUserAccountNotificationsError: state.account.isGetUserAccountNotificationsError,
  accountNotifications: state.account.accountNotifications,
  isDeleteNotificationSuccess: state.notification.isDeleteNotificationSuccess,
  isDeleteNotificationError: state.notification.isDeleteNotificationError,
  deleteNotificationErrorMessage: state.notification.deleteNotificationErrorMessage,
  currentLanguage: state.translate.currentLanguage,
  isGetTranslateLanguageSuccess: state.translate.isGetTranslateLanguageSuccess,
  isGetTranslateLanguageError: state.translate.isGetTranslateLanguageError,
});

function mapDispatchToProps(dispatch) {
  return {
    makeSeen: () => dispatch(makeSeenRequest()),
    makeRead: (data) => dispatch(makeReadRequest(data)),
    acceptAppointment: (data) => dispatch(acceptAppointmentRequest(data)),
    cancelAppointment: (data) => dispatch(cancelAppointmentRequest(data)),
    resetNotifications: (data) => dispatch(resetNotificationsRequest(data)),
    acceptCustomerInvitation: (data) => dispatch(acceptCustomerInvitationRequest(data)),
    acceptIndustryCustomerInvitation: (data) => dispatch(acceptIndustryCustomerInvitationRequest(data)),
    declineCustomerInvitation: (data) => dispatch(declineCustomerInvitationRequest(data)),
    declineIndustryCustomerInvitation: (data) => dispatch(declineIndustryCustomerInvitationRequest(data)),
    acceptStaffInvitation: (data) => dispatch(acceptStaffInvitationFromNotificationRequest(data)),
    declineStaffInvitation: (data) => dispatch(declineStaffInvitationFromNotificationRequest(data)),
    getNotificationById: (data) => dispatch(getNotificationByIdRequest(data)),
    getAccountNotifications: (data) => dispatch(getUserAccountNotificationsRequest(data)),
    changeLanguage: (lang) => dispatch(changeLanguageRequest({ lang })),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
