import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCut, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './createOrEditAppointment.scss';
import moment from 'moment';
import Calendar from 'react-calendar';
import { Carousel } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import { getUserLocationRequest } from '../../../redux/account/actions';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import i18n from 'i18next';
import { getFilteredCompaniesRequest } from '../../../../../redux/company/actions';
import { getIndustriesRequest } from '../../../../../redux/industry/actions';
import { getStaffAvailableTimesRequest } from '../../../../../redux/staff/actions';
import {
  storeAppointmentRequest, getAppointmentByIdRequest,
  updateAppointmentRequest,
} from '../../../../../redux/appointment/actions';
import Loading from '../../../../../Components/Loading/loading';
import companyLogo from '../../../../../assets/img/company.svg';
import avatar from '../../../../../assets/img/avatar.svg';
import day from '../../../../../assets/icons/day.svg';
import night from '../../../../../assets/icons/night.svg';
import {
  getFilteredIndividualUserIndustriesRequest,
  getIndividualUserAvailableTimesRequest,
} from '../../../../../redux/individualUser/actions';
import SnackbarToast from '../../../../../Modules/SnackbarToast';

class CreateOrEditAppointment extends PureComponent {
  today = moment().format('dddd');

  isFromStep = false;

  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    // Get Industries Props
    getIndustries: PropTypes.func.isRequired,
    isGetIndustriesSuccess: PropTypes.bool.isRequired,
    isGetIndustriesError: PropTypes.bool.isRequired,
    industries: PropTypes.array.isRequired,
    // Get User Location Props
    // getUserLocation: PropTypes.func.isRequired,
    // isGetUserLocationSuccess: PropTypes.bool.isRequired,
    // locationInfo: PropTypes.object.isRequired,
    // Get Filtered Companies Props
    getFilteredCompanies: PropTypes.func.isRequired,
    isGetFilteredCompaniesSuccess: PropTypes.bool.isRequired,
    isGetFilteredCompaniesError: PropTypes.bool.isRequired,
    getFilteredCompaniesErrorMessage: PropTypes.string.isRequired,
    filteredCompanies: PropTypes.array.isRequired,
    // Get Staff Available Times Props
    getStaffAvailableTimes: PropTypes.func.isRequired,
    availableTimes: PropTypes.array.isRequired,
    isGetStaffAvailableTimesSuccess: PropTypes.bool.isRequired,
    isGetStaffAvailableTimesError: PropTypes.bool.isRequired,
    // Store Appointment Props
    storeAppointment: PropTypes.func.isRequired,
    isStoreAppointmentSuccess: PropTypes.bool.isRequired,
    isStoreAppointmentError: PropTypes.bool.isRequired,
    newAppointment: PropTypes.object.isRequired,
    storeAppointmentErrorMessage: PropTypes.string.isRequired,
    // Get Appointment By Id Props
    getAppointmentById: PropTypes.func.isRequired,
    isGetAppointmentByIdSuccess: PropTypes.bool.isRequired,
    isGetAppointmentByIdError: PropTypes.bool.isRequired,
    returnedAppointment: PropTypes.object.isRequired,
    getAppointmentByIdErrorMessage: PropTypes.string.isRequired,
    // Update Appointment Props
    updateAppointment: PropTypes.func.isRequired,
    isUpdateAppointmentSuccess: PropTypes.bool.isRequired,
    isUpdateAppointmentError: PropTypes.bool.isRequired,
    updatedAppointment: PropTypes.object.isRequired,
    updateAppointmentErrorMessage: PropTypes.string.isRequired,
    // Get filtered Individual Users
    getFilteredIndividualUserIndustries: PropTypes.func.isRequired,
    isGetFilteredIndividualUserIndustriesSuccess: PropTypes.bool.isRequired,
    isGetFilteredIndividualUserIndustriesError: PropTypes.bool.isRequired,
    filteredIndividualUserIndustries: PropTypes.array.isRequired,
    getFilteredIndividualUserIndustriesErrorMessage: PropTypes.string.isRequired,
    // Get Individual User Available Times
    getIndividualUserAvailableTimes: PropTypes.func.isRequired,
    isGetIndividualUserAvailableTimesSuccess: PropTypes.bool.isRequired,
    isGetIndividualUserAvailableTimesError: PropTypes.bool.isRequired,
    individualUserAvailableTimes: PropTypes.array.isRequired,
    getIndividualUserAvailableTimesErrorMessage: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      activeStep: 0,
      filteredIndustries: [],
      selectedIndustry: {},
      filteredServices: [],
      selectedService: {
        name: false,
        id: null,
      },
      stepLoading: false,
      companySearchValue: '',
      selectedCompany: {},
      companies: [],
      filteredCompanies: [],
      selectedStaff: {},
      staffSearchValue: '',
      filteredStaff: [],
      selectedDay: new Date(),
      selectedTime: '',
      availableTimes: {
        dayTimes: [],
        nightTimes: [],
      },
      customerNote: '',
      appointmentItem: {
        id: null,
      },
      isEditAppointment: false,
      isAvailableTimesLoading: false,
      getFilteredCompaniesError: '',
      dayTimesActiveIndex: 0,
      nightTimesActiveIndex: 0,
      chooseIndividualUsers: false,
      individualUsersSearchValue: '',
      individualUsers: [],
      filteredIndividualUserIndustries: [],
      getFilteredIndividualUserError: '',
      selectedIndividualUser: {},
      snackbarMessage: '',
      snackbarType: '',
      openSnackbar: false,
    };
  }

  componentDidMount() {
    const { getIndustries } = this.props;
    this.setState({ loading: true });
    getIndustries();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      history,
      match,
      // isGetUserLocationSuccess,
      // locationInfo,
      getFilteredCompanies,
      isGetFilteredCompaniesSuccess,
      isGetFilteredCompaniesError,
      getFilteredCompaniesErrorMessage,
      filteredCompanies,
      getIndustries,
      isGetIndustriesSuccess,
      isGetIndustriesError,
      industries,
      isGetStaffAvailableTimesSuccess,
      availableTimes,
      isStoreAppointmentSuccess,
      isStoreAppointmentError,
      newAppointment,
      storeAppointmentErrorMessage,
      isGetAppointmentByIdSuccess,
      isGetAppointmentByIdError,
      returnedAppointment,
      getAppointmentByIdErrorMessage,
      // getUserLocation,
      getAppointmentById,
      isGetStaffAvailableTimesError,
      isUpdateAppointmentSuccess,
      isUpdateAppointmentError,
      updatedAppointment,
      updateAppointmentErrorMessage,
      getFilteredIndividualUserIndustries,
      isGetFilteredIndividualUserIndustriesSuccess,
      getFilteredIndividualUserIndustriesErrorMessage,
      isGetFilteredIndividualUserIndustriesError,
      filteredIndividualUserIndustries,
      isGetIndividualUserAvailableTimesSuccess,
      isGetIndividualUserAvailableTimesError,
      individualUserAvailableTimes,
      getIndividualUserAvailableTimesErrorMessage,
    } = this.props;
    const {
      selectedStaff,
      isEditAppointment,
      appointmentItem,
    } = this.state;
    const { params: prevMatchParams } = prevProps.match;
    const { params: currentMatchParams } = match;

    // Handle Get Industries Success
    if (!prevProps.isGetIndustriesSuccess && isGetIndustriesSuccess && !isGetIndustriesError) {
      this.setState({
        filteredIndustries: industries,
      });
      const { id, appointmentId } = match.params;
      if (id || appointmentId) {
        const idFromParams = id || appointmentId;
        getAppointmentById(idFromParams);
        this.setState({ isEditAppointment: true });
      } else {
        this.setState({ loading: false });
      }
    }
    // Handle Get User Location Success
    // if (!prevProps.isGetUserLocationSuccess && isGetUserLocationSuccess && locationInfo.status) {
    //   const { selectedService, appointmentItem, chooseIndividualUsers } = this.state;
    //   const { id } = selectedService;
    //   const { country } = locationInfo;
    //   let date = null;
    //   let appointmentId = null;
    //   if (appointmentItem && appointmentItem.id) {
    //     date = appointmentItem.date;
    //     appointmentId = appointmentItem.id;
    //   }
    //   const data = {
    //     service_id: id,
    //     country,
    //     date,
    //     appointment_id: appointmentId,
    //   };
    //   if (!chooseIndividualUsers) {
    //     getFilteredCompanies(data);
    //   } else {
    //     getFilteredIndividualUserIndustries(data);
    //   }
    // }
    // Handle Get filtered companies error
    if (!prevProps.isGetFilteredCompaniesError && isGetFilteredCompaniesError && getFilteredCompaniesErrorMessage) {
      this.setState({
        getFilteredCompaniesError: getFilteredCompaniesErrorMessage,
        stepLoading: false,
      });
    }
    // Handle Get Filtered Companies Success
    if (!prevProps.isGetFilteredCompaniesSuccess && isGetFilteredCompaniesSuccess && !isGetFilteredCompaniesError) {
      const { getStaffAvailableTimes } = this.props;
      const { selectedService } = this.state;
      this.setState({
        filteredCompanies,
        companies: filteredCompanies,
        stepLoading: false,
      });
      if (isEditAppointment && appointmentItem.id && !this.isFromStep) {
        if (!filteredCompanies.length) {
          this.snackBarAlert(true, i18n.t('AppointmentNotFound'), 'error');
          history.push('/calendar');
        } else {
          const selectedCompany = filteredCompanies.find((item) => item.id === parseInt(appointmentItem.company_id));
          const selectedStaff = selectedCompany.staff.find((item) => item.id === parseInt(appointmentItem.staff_id));
          const selectedTime = moment(appointmentItem.start_time, 'HH:mm:s').format('HH:mm');
          const selectedDay = new Date(appointmentItem.date);
          this.setState({
            selectedCompany,
            selectedStaff,
            activeStep: 3,
            loading: false,
            filteredStaff: selectedCompany.staff,
            selectedTime,
            selectedDay,
            customerNote: appointmentItem.note_from_customer,
          });
          getStaffAvailableTimes({
            staff_id: selectedStaff.id,
            service_id: selectedService.id,
            date: moment(selectedDay).format('Y-MM-DD'),
            appointment_id: appointmentItem && appointmentItem.id ? appointmentItem.id : null,
          });
        }
      }
    }
    // Handle Get Filtered Individual Users
    if (!prevProps.isGetFilteredIndividualUserIndustriesSuccess && isGetFilteredIndividualUserIndustriesSuccess && !isGetFilteredIndividualUserIndustriesError) {
      const { getIndividualUserAvailableTimes } = this.props;
      const { selectedService } = this.state;
      this.setState({
        filteredIndividualUserIndustries,
        individualUsers: filteredIndividualUserIndustries,
        stepLoading: false,
      });
      if (isEditAppointment && appointmentItem.id && !this.isFromStep) {
        if (!filteredIndividualUserIndustries.length) {
          this.snackBarAlert(true, i18n.t('AppointmentNotFound'), 'error');
          history.push('/calendar');
        } else {
          const selectedIndividualUser = filteredIndividualUserIndustries.find((item) => item.id === parseInt(appointmentItem.individual_user_industry_id));
          const selectedTime = moment(appointmentItem.start_time, 'HH:mm:s').format('HH:mm');
          const selectedDay = new Date(appointmentItem.date);
          this.setState({
            selectedIndividualUser,
            activeStep: 2,
            loading: false,
            selectedTime,
            selectedDay,
            customerNote: appointmentItem.note_from_customer,
          });
          getIndividualUserAvailableTimes({
            individual_user_industry_id: selectedIndividualUser.id,
            service_id: selectedService.id,
            date: moment(selectedDay).format('Y-MM-DD'),
            appointment_id: appointmentItem.id,
          });
        }
      }
    }
    // Handle get Filtered Individual Users Error
    if (!prevProps.isGetFilteredIndividualUserIndustriesError && isGetFilteredIndividualUserIndustriesError && getFilteredIndividualUserIndustriesErrorMessage) {
      this.setState({
        getFilteredIndividualUserError: getFilteredIndividualUserIndustriesErrorMessage,
        stepLoading: false,
      });
    }
    // Prevent Calendar Label Click
    if (JSON.stringify(prevState.selectedStaff) !== JSON.stringify(selectedStaff)) {
      const calendarLabelContainer = document.getElementsByClassName('react-calendar__navigation__label');
      Array.from(calendarLabelContainer).forEach((element) => {
        element.addEventListener('click', (event) => {
          event.stopPropagation();
        });
      });
    }
    // Handle Get Available Times Success
    if (!prevProps.isGetStaffAvailableTimesSuccess && isGetStaffAvailableTimesSuccess) {
      const {
        selectedTime,
      } = this.state;
      const timesConverted = this.convertTimesToDateAndNight(availableTimes);
      const dayTimesActiveIndex = timesConverted.dayTimes.findIndex((item) => item.includes(selectedTime));
      const nightTimesActiveIndex = timesConverted.nightTimes.findIndex((item) => item.includes(selectedTime));

      this.setState({
        availableTimes: timesConverted,
        isAvailableTimesLoading: false,
        dayTimesActiveIndex: dayTimesActiveIndex || 0,
        nightTimesActiveIndex: nightTimesActiveIndex || 0,
      });
    }
    // Handle Get Staff Available Times Error
    if (!prevProps.isGetStaffAvailableTimesError && isGetStaffAvailableTimesError) {
      this.setState((prevState) => ({
        availableTimes: {
          ...prevState.availableTimes,
          dayTimes: [],
          nightTimes: [],
        },
        isAvailableTimesLoading: false,
      }));
    }
    // Handle Get Individual User Available Times Success
    if (!prevProps.isGetIndividualUserAvailableTimesSuccess && isGetIndividualUserAvailableTimesSuccess) {
      const {
        selectedTime,
      } = this.state;
      const timesConverted = this.convertTimesToDateAndNight(individualUserAvailableTimes);
      const dayTimesActiveIndex = timesConverted.dayTimes.findIndex((item) => item.includes(selectedTime));
      const nightTimesActiveIndex = timesConverted.nightTimes.findIndex((item) => item.includes(selectedTime));

      this.setState({
        availableTimes: timesConverted,
        isAvailableTimesLoading: false,
        dayTimesActiveIndex: dayTimesActiveIndex || 0,
        nightTimesActiveIndex: nightTimesActiveIndex || 0,
      });
    }
    // Handle Get Individual User Available Times Error
    if (!prevProps.isGetIndividualUserAvailableTimesError && isGetIndividualUserAvailableTimesError && getIndividualUserAvailableTimesErrorMessage) {
      this.setState((prevState) => ({
        availableTimes: {
          ...prevState.availableTimes,
          dayTimes: [],
          nightTimes: [],
        },
        isAvailableTimesLoading: false,
      }));
    }
    // Handle Store Appointment Success
    if (!prevProps.isStoreAppointmentSuccess && isStoreAppointmentSuccess && newAppointment.id) {
      this.setState({ loading: false });
      this.snackBarAlert(true, i18n.t('AppointmentCreated'), 'success');
      history.push(`/calendar/${newAppointment.id}`);
    }
    // Handle Store Appointment Error
    if (!prevProps.isStoreAppointmentError && isStoreAppointmentError) {
      this.setState({ loading: false });
      this.snackBarAlert(true, storeAppointmentErrorMessage, 'error');
    }
    // Handle Get Appointments Success
    if (isGetAppointmentByIdSuccess && !prevProps.isGetAppointmentByIdSuccess) {
      const selectedIndustry = industries.find((item) => item.services.find((service) => service.id === parseInt(returnedAppointment.service_id)));
      const selectedService = selectedIndustry.services.find((service) => service.id === parseInt(returnedAppointment.service_id));
      this.setState({
        appointmentItem: returnedAppointment,
        selectedService,
        chooseIndividualUsers: !!returnedAppointment.individual_user_industry_id,
      });
      const { appointmentItem, chooseIndividualUsers } = this.state;
      const { id } = selectedService;
      let date = null;
      let appointmentId = null;
      if (appointmentItem && appointmentItem.id) {
        date = appointmentItem.date;
        appointmentId = appointmentItem.id;
      }
      const data = {
        service_id: id,
        country: 'Armenia',
        date,
        appointment_id: appointmentId,
      };
      if (!chooseIndividualUsers && returnedAppointment.individual_user_industry_id === null) {
        getFilteredCompanies(data);
      } else {
        getFilteredIndividualUserIndustries(data);
      }
      // getUserLocation();
    }
    // Handle Get Appointment Error
    if (!prevProps.isGetAppointmentByIdError && isGetAppointmentByIdError) {
      this.snackBarAlert(true, getAppointmentByIdErrorMessage, 'error');
      history.push('/calendar');
    }
    // Handle Update Appointment Success
    if (!prevProps.isUpdateAppointmentSuccess && isUpdateAppointmentSuccess && updatedAppointment.id) {
      this.setState({ loading: false });
      this.snackBarAlert(true, i18n.t('AppointmentUpdated'), 'success');
      history.push(`/calendar/${updatedAppointment.id}`);
    }
    // Handle update Appointment Error
    if (!prevProps.isUpdateAppointmentError && isUpdateAppointmentError) {
      this.setState({ loading: false });
      this.snackBarAlert(true, updateAppointmentErrorMessage, 'error');
    }
    // Handle appointmentId parameter change in route
    if (prevMatchParams.appointmentId !== currentMatchParams.appointmentId) {
      this.setState({ loading: true });
      getIndustries();
    }
    // Handle id parameter change in route
    if (prevMatchParams.id !== currentMatchParams.id) {
      this.setState({ loading: true });
      getIndustries();
    }
  }

  snackBarAlert = (snackOpen, snackMessage, snackType) => {
    this.setState({
      snackbarType: snackType,
      openSnackbar: snackOpen,
      snackbarMessage: snackMessage,
    });
  };

  handleStepClick = (step) => {
    const {
      activeStep,
      isEditAppointment,
    } = this.state;
    const isAppointmentCancelledOrFinished = this.getIsAppointmentCancelledOrFinished();
    if (isEditAppointment && (isAppointmentCancelledOrFinished || step < 2)) {
      return false;
    }
    if (activeStep > step) {
      switch (step) {
        case 0: {
          this.setState({
            selectedIndustry: {},
            selectedService: {},
            selectedCompany: {},
            selectedStaff: {},
            selectedTime: '',
            selectedIndividualUser: {},
          });
          break;
        }
        case 1: {
          this.setState({
            selectedCompany: {},
            selectedStaff: {},
            selectedTime: '',
            selectedIndividualUser: {},
          });
          break;
        }
        case 2: {
          this.setState({
            selectedStaff: {},
            selectedTime: '',
            selectedIndividualUser: {},
          });
          break;
        }
        case 3: {
          this.setState({
            selectedTime: '',
          });
          break;
        }
        default: {
          return false;
        }
      }
      this.setState({ activeStep: step, stepLoading: false });
    }
  };

  handleChooseIndustry = (industry) => {
    const { selectedIndustry } = this.state;
    if (selectedIndustry.id && industry.id === selectedIndustry.id) {
      this.setState({
        selectedIndustry: {},
        filteredServices: [],
      });
    } else {
      this.setState({
        selectedIndustry: industry,
        filteredServices: industry.services,
      });
    }
  };

  handleChooseService = (id, name) => {
    // const { getUserLocation } = this.props;
    this.setState({
      activeStep: 1,
      selectedService: {
        name,
        id,
      },
      stepLoading: true,
    });
    const {
      isEditAppointment,
      appointmentItem,
      chooseIndividualUsers,
    } = this.state;
    const {
      getFilteredCompanies,
      getFilteredIndividualUserIndustries,
    } = this.props;
    if (isEditAppointment) {
      this.isFromStep = true;
    }
    let date = null;
    let appointmentId = null;
    if (appointmentItem && appointmentItem.id) {
      date = appointmentItem.date;
      appointmentId = appointmentItem.id;
    }
    const data = {
      service_id: id,
      country: 'Armenia',
      date,
      appointment_id: appointmentId,
    };
    if (!chooseIndividualUsers) {
      getFilteredCompanies(data);
    } else {
      getFilteredIndividualUserIndustries(data);
    }
    // getUserLocation();
  };

  handleSearchIndustryChange = (event) => {
    const { industries } = this.props;
    const { name, value } = event.target;
    const filteredIndustries = industries.filter((item) => item.name.toUpperCase().includes(value.toUpperCase()));
    this.setState({
      filteredIndustries,
      [name]: value,
    });
  };

  handleSearchCompanyChange = (event) => {
    const { companies } = this.state;
    const { name, value } = event.target;
    const filteredCompanies = companies.filter((item) => item.name.toUpperCase().includes(value.toUpperCase()));
    this.setState({
      [name]: value,
      filteredCompanies,
    });
  };

  handleSearchStaffChange = (event) => {
    const { selectedCompany } = this.state;
    const { staff } = selectedCompany;
    const { name, value } = event.target;
    const filteredStaff = staff.filter((item) => `${item.first_name.toUpperCase()} ${item.last_name.toUpperCase()}`.includes(value.toUpperCase()));
    this.setState({
      [name]: value,
      filteredStaff,
    });
  };

  handleSearchIndividualUsers = (event) => {
    const { filteredIndividualUserIndustries } = this.state;
    const { name, value } = event.target;
    const individualUsers = filteredIndividualUserIndustries.filter((item) => `${item.first_name.toUpperCase()} ${item.last_name.toUpperCase()}`.includes(value.toUpperCase()));
    this.setState({
      [name]: value,
      individualUsers,
    });
  };

  selectCompany = (company) => {
    const { selectedCompany } = this.state;
    if (selectedCompany.id === company.id) {
      this.setState({ selectedCompany: {} });
    } else {
      this.setState({ selectedCompany: company });
    }
  };

  handleChooseCompany = () => {
    const { selectedCompany } = this.state;
    this.setState({
      activeStep: 2,
      filteredStaff: selectedCompany.staff,
    });
  };

  handleChooseStaff = (staff) => {
    const {
      selectedStaff,
      selectedDay,
      availableTimes,
      appointmentItem,
      isEditAppointment,
    } = this.state;
    const isAppointmentCancelledOrFinished = this.getIsAppointmentCancelledOrFinished();
    if (isEditAppointment && isAppointmentCancelledOrFinished) {
      return false;
    }
    if (selectedStaff.id && selectedStaff.id === staff.id) {
      this.setState({
        selectedStaff: {},
        activeStep: 2,
        selectedTime: '',
      });
    } else {
      let availableTimesCopy = { ...availableTimes };
      const date = appointmentItem && appointmentItem.id ? moment(appointmentItem.date).format('Y-MM-DD') : moment().format('Y-MM-DD');
      if (moment(selectedDay).format('Y-MM-DD') === date) {
        availableTimesCopy = staff.availableHours ? this.convertTimesToDateAndNight(staff.availableHours) : {};
      } else {
        const { getStaffAvailableTimes } = this.props;
        const {
          selectedService,
          appointmentItem,
        } = this.state;
        this.setState({ isAvailableTimesLoading: true });
        getStaffAvailableTimes({
          staff_id: staff.id,
          service_id: selectedService.id,
          date: moment(selectedDay).format('Y-MM-DD'),
          appointment_id: appointmentItem && appointmentItem.id ? appointmentItem.id : null,
        });
      }
      this.setState({
        selectedStaff: staff,
        activeStep: 3,
        selectedTime: '',
        availableTimes: availableTimesCopy,
      });
    }
  };

  handleChooseIndividualUser = (individualUser) => {
    const {
      selectedIndividualUser, selectedDay,
      availableTimes, appointmentItem,
    } = this.state;
    if (selectedIndividualUser.id && selectedIndividualUser.id === individualUser.id) {
      this.setState({
        selectedIndividualUser: {},
        activeStep: 2,
        selectedTime: '',
      });
    } else {
      let availableTimesCopy = { ...availableTimes };
      const date = appointmentItem && appointmentItem.id ? moment(appointmentItem.date).format('Y-MM-DD') : moment().format('Y-MM-DD');
      if (moment(selectedDay).format('Y-MM-DD') === date) {
        availableTimesCopy = individualUser.individualUserIndustry.availableHours ? this.convertTimesToDateAndNight(individualUser.individualUserIndustry.availableHours) : {};
      } else {
        const { getIndividualUserAvailableTimes } = this.props;
        const { selectedService, appointmentItem } = this.state;
        this.setState({ isAvailableTimesLoading: true });
        getIndividualUserAvailableTimes({
          individual_user_industry_id: selectedIndividualUser.id,
          service_id: selectedService.id,
          date: moment(selectedDay).format('Y-MM-DD'),
          appointment_id: appointmentItem && appointmentItem.id ? appointmentItem.id : null,
        });
      }
      this.setState({
        activeStep: 2,
        selectedIndividualUser: individualUser,
        selectedTime: '',
        availableTimes: availableTimesCopy,
      });
    }
  };

  handleMakeAppointment = () => {
    this.setState({ loading: true });
    const { storeAppointment } = this.props;
    const {
      selectedTime, selectedDay, selectedStaff,
      selectedService, customerNote,
      chooseIndividualUsers, selectedIndividualUser,
    } = this.state;
    const data = {
      date: moment(selectedDay).format('Y-MM-DD'),
      start_time: `${selectedTime}:00`,
      service_id: selectedService.id,
      note_from_customer: customerNote,
    };
    if (!chooseIndividualUsers) {
      storeAppointment({
        ...data,
        staff_id: selectedStaff.id,
      });
    } else {
      storeAppointment({
        ...data,
        individual_user_industry_id: selectedIndividualUser.id,
      });
    }
  };

  selectAppointmentDate = (selectedDay) => {
    const {
      selectedStaff,
      selectedService,
      isEditAppointment,
      appointmentItem,
      chooseIndividualUsers,
      selectedIndividualUser,
    } = this.state;

    const isAppointmentCancelledOrFinished = this.getIsAppointmentCancelledOrFinished();
    if (isEditAppointment && isAppointmentCancelledOrFinished) {
      return false;
    }
    const { getStaffAvailableTimes, getIndividualUserAvailableTimes } = this.props;
    this.setState({
      selectedDay,
      isAvailableTimesLoading: true,
    });
    const data = {
      date: moment(selectedDay).format('Y-MM-DD'),
      appointment_id: appointmentItem && appointmentItem.id ? appointmentItem.id : null,
      service_id: selectedService.id,
    };
    if (!chooseIndividualUsers) {
      getStaffAvailableTimes({
        ...data,
        staff_id: selectedStaff.id,
      });
    } else {
      getIndividualUserAvailableTimes({
        ...data,
        individual_user_industry_id: selectedIndividualUser.id,
      });
    }
  };

  convertTimesToDateAndNight = (availableTimes) => {
    const timesConverted = {
      dayTimes: [],
      nightTimes: [],
    };
    if (availableTimes) {
      const dayTimes = availableTimes.filter((item) => item < '16:00');
      const nightTimes = availableTimes.filter((item) => item >= '16:00');
      let daysArray = [];
      dayTimes.map((item, key) => {
        if ((key + 1) % 6 === 0) {
          timesConverted.dayTimes.push([...daysArray, item]);
          daysArray = [];
        } else {
          daysArray.push(item);
        }
        if (daysArray.length && key === (dayTimes.length - 1)) {
          timesConverted.dayTimes.push(daysArray);
        }
      });
      let nightsArray = [];
      nightTimes.map((item, key) => {
        if ((key + 1) % 6 === 0) {
          timesConverted.nightTimes.push([...nightsArray, item]);
          nightsArray = [];
        } else {
          nightsArray.push(item);
        }
        if (nightsArray.length && key === (nightTimes.length - 1)) {
          timesConverted.nightTimes.push(nightsArray);
        }
      });
    }
    return timesConverted;
  };

  handleUpdateAppointment = () => {
    const {
      selectedTime, selectedDay, selectedStaff,
      selectedService, customerNote,
      appointmentItem, chooseIndividualUsers, selectedIndividualUser,
    } = this.state;
    const { updateAppointment } = this.props;
    this.setState({ loading: true });
    const data = {
      id: appointmentItem.id,
      service_id: selectedService.id,
      date: moment(selectedDay).format('Y-MM-DD'),
      start_time: `${selectedTime}:00`,
      note_from_customer: customerNote,
    };
    if (!chooseIndividualUsers) {
      updateAppointment({
        ...data,
        staff_id: selectedStaff.id,
      });
    } else {
      updateAppointment({
        ...data,
        individual_user_industry_id: selectedIndividualUser.id,
      });
    }
  };

  isDateDisabled = (date) => {
    const { isEditAppointment, selectedCompany } = this.state;
    const isAppointmentCancelledOrFinished = this.getIsAppointmentCancelledOrFinished();
    if (isEditAppointment && isAppointmentCancelledOrFinished) {
      return true;
    }
    const dateObject = moment(date.date, 'YYYY-MM-DD HH:mm:ss');
    const weekDay = dateObject.format('dddd');
    const day = selectedCompany.workingHours.find((item) => item.week_day === weekDay);
    return !day.is_working;
  };

  isIndividualUserDateDisabled = (date) => {
    const { selectedIndividualUser, isEditAppointment } = this.state;
    const isAppointmentCancelledOrFinished = this.getIsAppointmentCancelledOrFinished();
    if (isEditAppointment && isAppointmentCancelledOrFinished) {
      return true;
    }
    const dateObject = moment(date.date, 'YYYY-MM-DD HH:mm:ss');
    const weekDay = dateObject.format('dddd');
    const day = selectedIndividualUser.workingHours.find((item) => item.week_day === weekDay);
    return !day.is_working;
  };

  handleChangeAppointmentSteps = (e) => {
    const { value } = e.target;
    const selected = value !== 'company';
    this.setState({ chooseIndividualUsers: selected });
  };

  getAppointmentItemStatus = () => {
    const { appointmentItem } = this.state;
    return appointmentItem && appointmentItem.status && appointmentItem.status.name ? appointmentItem.status.name : null;
  };

  handleTimeChange = (selectedTime) => {
    const { isEditAppointment } = this.state;
    const isAppointmentCancelledOrFinished = this.getIsAppointmentCancelledOrFinished();
    if (isEditAppointment && isAppointmentCancelledOrFinished) {
      return false;
    }
    this.setState({ selectedTime });
  };

  getHoursItemClassName = (item) => {
    const {
      selectedTime,
      isEditAppointment,
    } = this.state;
    let className = 'item';
    className = (selectedTime === item) ? `${className} chosen` : className;
    const isAppointmentCancelledOrFinished = this.getIsAppointmentCancelledOrFinished();
    className = (isEditAppointment && isAppointmentCancelledOrFinished) ? `${className} disabled` : className;
    return className;
  };

  getIsAppointmentCancelledOrFinished = () => {
    const { appointmentItem } = this.state;
    return (appointmentItem.id && (appointmentItem.status.name === 'cancelled' || appointmentItem.status.name === 'finished'));
  };

  render() {
    const {
      loading, activeStep, filteredServices,
      selectedService, stepLoading,
      filteredCompanies, companySearchValue,
      selectedCompany, staffSearchValue,
      filteredStaff, selectedStaff, selectedDay,
      selectedTime, filteredIndustries,
      selectedIndustry, availableTimes,
      customerNote, isEditAppointment, appointmentItem,
      isAvailableTimesLoading, dayTimesActiveIndex,
      nightTimesActiveIndex, getFilteredCompaniesError, chooseIndividualUsers,
      individualUsersSearchValue, selectedIndividualUser, individualUsers,
      getFilteredIndividualUserError, openSnackbar,
      snackbarType,
      snackbarMessage,
    } = this.state;
    const isAppointmentCancelledOrFinished = this.getIsAppointmentCancelledOrFinished();

    return !loading ? (
      <>
        <div className="menu-item create-appointment">
          <div className="general-content">
            <div className="page-top-bar">
              <h3>{ isEditAppointment && appointmentItem.id ? 'Update Appointment' : 'New Appointment' }</h3>
            </div>
            <div className="panel">
              <div className="main-content">
                <div className="nav-content">
                  <div
                    className={`navigation-item ${chooseIndividualUsers && 'navigation-item-individual'} ${activeStep === 0 ? 'active' : ''} ${activeStep > 0 ? 'passed' : ''}`}
                    role="button"
                    onClick={() => this.handleStepClick(0)}
                  >
                    <div className="item-count first">1</div>
                    <div className="item-name">
                      { selectedService.name || 'Choose a service' }
                    </div>
                  </div>
                  <div className={`circle-container ${chooseIndividualUsers && 'circle-container-individual'} ${activeStep > 0 ? 'active' : ''}`}>
                    <div className="circle-separator" />
                  </div>
                  <div
                    className={`navigation-item ${chooseIndividualUsers && 'navigation-item-individual'} ${activeStep === 1 ? 'active' : ''} ${activeStep > 1 ? 'passed' : ''}`}
                    role="button"
                    onClick={() => this.handleStepClick(1)}
                  >
                    <div className="item-count">2</div>
                    <div className="item-name">
                      { chooseIndividualUsers ? selectedIndividualUser.name || 'Choose a Individual User' : selectedCompany.name || 'Choose a company' }
                    </div>
                  </div>
                  <div className={`circle-container ${chooseIndividualUsers && 'circle-container-individual'} ${activeStep > 1 ? 'active' : ''}`}>
                    <div className="circle-separator" />
                  </div>
                  <div
                    className={`navigation-item ${chooseIndividualUsers && 'navigation-item-individual'} ${activeStep === 2 ? 'active' : ''} ${activeStep > 2 ? 'passed' : ''}`}
                    role="button"
                    onClick={() => this.handleStepClick(2)}
                  >
                    <div className="item-count">3</div>
                    <div className="item-name">{chooseIndividualUsers ? 'Choose a time' : selectedStaff.id ? `${selectedStaff.first_name} ${selectedStaff.last_name}` : 'Choose a staff'}</div>
                  </div>
                  {
                    !chooseIndividualUsers
                      ? (
                        <>
                          <div className={`circle-container ${activeStep > 2 ? 'active' : ''}`}>
                            <div className="circle-separator" />
                          </div>
                          <div
                            className={`navigation-item ${activeStep === 3 ? 'active' : ''} ${activeStep > 3 ? 'passed' : ''}`}
                            role="button"
                            onClick={() => this.handleStepClick(3)}
                          >
                            <div className="item-count">4</div>
                            <div className="item-name">{`${selectedDay && selectedTime ? `${moment(selectedDay).format('DD MMM')} ${selectedTime}` : 'Choose a time'}`}</div>
                          </div>
                        </>
                      ) : null
                  }
                </div>
                { activeStep === 0 ? (
                  <div className="industries-tab">
                    <TextField
                      fullWidth
                      label="Search..."
                      type="search"
                      size="small"
                      color="secondary"
                    />
                    <div className="appointment-types">
                      <Grid container spacing={2}>
                        <Grid item xs={4} className="form-check">
                          <label className="form-check-label label-container">
                            <TextField
                              type="radio"
                              className="form-check-input"
                              id="company"
                              value="company"
                              checked={!chooseIndividualUsers}
                              name="change_appointment_steps"
                              onChange={(e) => this.handleChangeAppointmentSteps(e)}
                            />Company
                            <span data-cy="companies" className="radio-button"><div /></span>
                          </label>
                        </Grid>
                        <Grid item xs={4} className="form-check">
                          <label className="form-check-label label-container">
                            <TextField
                              type="radio"
                              className="form-check-input"
                              id="individual"
                              value="individual"
                              checked={chooseIndividualUsers}
                              name="change_appointment_steps"
                              onChange={(e) => this.handleChangeAppointmentSteps(e)}
                            />Individual Users
                            <span data-cy="individual-users" className="radio-button"><div /></span>
                          </label>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="industries-content">
                      { filteredIndustries.length ? filteredIndustries.map((industry) => (
                        <div className="industry-item-container" key={industry.id}>
                          <div
                            data-cy="industry-item"
                            className="industry-item"
                            role="button"
                            onClick={() => this.handleChooseIndustry(industry)}
                            key={industry.id}
                          >
                            <div className="left-content">
                              <span className="icon-content ml-2">
                                <FontAwesomeIcon icon={faCut} />
                              </span>
                              <span className="industry-name">
                                {industry.name}
                              </span>
                            </div>
                            <div className="right-content">
                              <span>
                                <FontAwesomeIcon icon={selectedIndustry.id === industry.id ? faChevronDown : faChevronRight} size="lg" />
                              </span>
                            </div>
                          </div>
                          { selectedIndustry.id === industry.id ? (
                            <div className="industry-services-content">
                              { filteredServices.length ? filteredServices.map((service) => (
                                <div
                                  data-cy="service-item"
                                  className="service-item"
                                  role="button"
                                  key={service.id}
                                  onClick={() => this.handleChooseService(service.id, service.name)}
                                >
                                  <div className="left-content">
                                    <span className="image-content">
                                      { service.logo ? (
                                        <img src={service.logo} alt={service.name} />
                                      ) : <FontAwesomeIcon icon={faCut} /> }
                                    </span>
                                    <span className="service-name">
                                      {service.name}
                                    </span>
                                  </div>
                                  <div className="right-content">
                                    <span>
                                      <FontAwesomeIcon icon={faChevronRight} size="lg" />
                                    </span>
                                  </div>
                                </div>
                              )) : (
                                <Box display="flex" justifyContent="center" mb="24px">
                                  <span>No Service found for this Industry</span>
                                </Box>
                              ) }
                            </div>
                          ) : null }
                        </div>
                      )) : (
                        <Box display="flex" justifyContent="center" mb="24px">
                          <span>No Industry found</span>
                        </Box>
                      )}
                    </div>
                  </div>
                ) : null }
                { activeStep === 1
                  ? stepLoading ? <div className="loading-container"><Loading /></div>
                    : !chooseIndividualUsers ? (
                      <div className="companies-tab">
                        <TextField
                          fullWidth
                          label="Search..."
                          type="search"
                          size="small"
                          color="secondary"
                          value={companySearchValue}
                          onChange={(event) => this.handleSearchCompanyChange(event)}
                        />
                        <div className="companies-content">
                          { filteredCompanies.length && !getFilteredCompaniesError ? filteredCompanies.map((company) => (
                            <div className="company-item-container" key={company.id}>
                              <div
                                data-cy="company-item"
                                className="company-item"
                                role="button"
                                onClick={() => this.selectCompany(company)}
                                key={company.id}
                              >
                                <Box display="flex" className="left-content">
                                  <Box ml="8px" className="logo-content">
                                    <img src={company.logo || companyLogo} alt="Company" />
                                  </Box>
                                  <div className="item-name">
                                    { company.name }
                                  </div>
                                </Box>
                                <div className="right-content">
                                  <span>
                                    <FontAwesomeIcon icon={selectedCompany.id === company.id ? faChevronDown : faChevronRight} size="lg" />
                                  </span>
                                </div>
                              </div>
                              { selectedCompany.id === company.id
                                ? (
                                  <div className="company-details">
                                    <div className="header row">
                                      <div className="image-content">
                                        <img src={selectedCompany.logo || companyLogo} alt="Company" />
                                      </div>
                                      <div className="name-content">
                                        <h3>{selectedCompany.name}</h3>
                                        <p>{selectedCompany.industry.name}</p>
                                      </div>
                                    </div>
                                    <div className="content">
                                      <Grid container spacing={2}>
                                        <Grid item xs={4} className="content-item">
                                          <div className="title-container">
                                            <h5>Working Hours</h5>
                                          </div>
                                          <div className="details-content">
                                            { selectedCompany.workingHours.map((hour) => (
                                              <div className="working-hour-item" key={Math.random()}>
                                                <div className={`week-day ${hour.week_day === this.today ? 'today' : ''}`}>
                                                  { hour.week_day }
                                                </div>
                                                <div className="hours">
                                                  { hour.is_working ? `${hour.start_time} - ${hour.end_time}` : 'Not Working' }
                                                </div>
                                              </div>
                                            )) }
                                          </div>
                                        </Grid>
                                        <Grid item xs={4} className="content-item">
                                          <div className="title-container">
                                            <h5>Location</h5>
                                          </div>
                                          <div className="details-content">
                                            <div className="mapouter">
                                              <div className="gmap_canvas">
                                                <iframe
                                                  title={`map ${selectedCompany.id}`}
                                                  width="230"
                                                  height="330"
                                                  id="gmap_canvas"
                                                  src="https://maps.google.com/maps?q=we%20consturct&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                                  frameBorder="0"
                                                  scrolling="no"
                                                  marginHeight="0"
                                                  marginWidth="0"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </Grid>
                                        <Grid item xs={4} className="content-item">
                                          <div className="title-container">
                                            <h5>Contact Info</h5>
                                          </div>
                                          <div className="contact-info-container">
                                            <div className="contact-details-content">
                                              <div className="nav-headers">
                                                <span>Country:</span>
                                                <span>State:</span>
                                                <span>City:</span>
                                                <span>Address:</span>
                                                <span>Website:</span>
                                                <span>Phone:</span>
                                                <span>Email:</span>
                                              </div>
                                              <div className="nav-values">
                                                <span>{ selectedCompany.country.name }</span>
                                                <span>{ selectedCompany.state.name }</span>
                                                <span>{ selectedCompany.city.name }</span>
                                                <span>{ selectedCompany.address }</span>
                                                <span>to add website</span>
                                                <span>{`+${selectedCompany.phone_code}${selectedCompany.phone_number}`}</span>
                                                <span>Email</span>
                                              </div>
                                            </div>
                                            <div>
                                              <Button
                                                size="small"
                                                color="primary"
                                                data-cy="choose-company"
                                                type="button"
                                                onClick={() => this.handleChooseCompany()}
                                              >
                                                  Choose company
                                              </Button>
                                            </div>
                                          </div>
                                        </Grid>
                                      </Grid>
                                    </div>
                                  </div>
                                ) : null }
                            </div>
                          )) : (
                            <Box display="flex" justifyContent="center" mb="24px">
                              <span>No Company found</span>
                            </Box>
                          )}
                        </div>
                      </div>
                    )
                      : chooseIndividualUsers ? (
                        <div className="individual-users-tab">
                          <TextField
                            fullWidth
                            label="Search..."
                            type="search"
                            size="small"
                            color="secondary"
                            value={individualUsersSearchValue}
                            onChange={(event) => this.handleSearchIndividualUsers(event)}
                          />
                          { individualUsers.length && !getFilteredIndividualUserError ? individualUsers.map((user) => (
                            <div className="individual-user-content" key={user.id}>
                              <div className="individual-user-container">
                                <div
                                  className="staff-item"
                                  role="button"
                                  onClick={() => this.handleChooseIndividualUser(user)}
                                >
                                  <Box display="flex" className="left-content">
                                    <div className="image-content">
                                      {
                                            user.individualUserIndustry.user.avatar
                                              ? (<img src={user.individualUserIndustry.user.avatar} alt="Avatar" />) : null
                                          }
                                    </div>
                                    <div className="info-content">
                                      <h3>{`${user.first_name} ${user.last_name}`}</h3>
                                      <p>{user.first_name}</p>
                                    </div>
                                  </Box>
                                  <div className="right-content">
                                    <div className="first-available-time">
                                      { user.individualUserIndustry.availableHours.length ? `Today at ${user.individualUserIndustry.availableHours[0]}` : 'Not available today' }
                                      <span><FontAwesomeIcon icon={selectedStaff.id === user.id ? faChevronDown : faChevronRight} /></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                            : (
                              <Box display="flex" justifyContent="center" mb="24px">
                                <span>Individual user not found</span>
                              </Box>
                            ) }
                        </div>
                      )
                        : null : null}
                { activeStep === 2 || activeStep === 3
                  ? stepLoading ? <div className="loading-container"><Loading /></div>
                    : !chooseIndividualUsers ? (
                      <div className="staff-tab">
                        <TextField
                          fullWidth
                          label="Search..."
                          type="search"
                          size="small"
                          color="secondary"
                          value={staffSearchValue}
                          onChange={(event) => this.handleSearchStaffChange(event)}
                        />
                        { filteredStaff.length ? filteredStaff.map((staff) => (
                          <div className="staff-content" key={staff.id}>
                            <div className="staff-item-container">
                              <div
                                data-cy="staff-item"
                                className="staff-item"
                                role="button"
                                onClick={() => this.handleChooseStaff(staff)}
                              >
                                <Box display="flex" className="left-content">
                                  <div className="image-content">
                                    <img src={avatar} alt="Avatar" />
                                  </div>
                                  <div className="info-content">
                                    <h3>{`${staff.first_name} ${staff.last_name}`}</h3>
                                    <p>{selectedService.name}</p>
                                  </div>
                                </Box>
                                <div className="right-content">
                                  <div className="first-available-time">
                                    { staff.availableHours.length ? `Today at ${staff.availableHours[0]}` : 'Not available today' }
                                    <span><FontAwesomeIcon icon={selectedStaff.id === staff.id ? faChevronDown : faChevronRight} /></span>
                                  </div>
                                </div>
                              </div>
                              { selectedStaff.id === staff.id
                                ? (
                                  <Grid className="staff-details">
                                    <Grid container spacing={2}>
                                      <Grid item xs={4}>
                                        <div className="month-content">
                                          <Calendar
                                            minDate={new Date()}
                                            value={selectedDay}
                                            onChange={(selectedDay) => this.selectAppointmentDate(selectedDay)}
                                            tileDisabled={(date) => this.isDateDisabled(date)}
                                          />
                                        </div>
                                      </Grid>
                                      <Grid container xs={4}>
                                        { Object.keys(availableTimes).length ? (
                                          <div className="set-time">
                                            <img src={day} alt="" />
                                            <div className="day-hours">
                                              { availableTimes.dayTimes.length ? (
                                                <Carousel
                                                  interval={null}
                                                  activeIndex={dayTimesActiveIndex}
                                                  onSelect={(dayTimesActiveIndex) => this.setState({ dayTimesActiveIndex })}
                                                >
                                                  { isAvailableTimesLoading ? (
                                                    <Carousel.Item className="active" key="loading">
                                                      <Loading />
                                                    </Carousel.Item>
                                                  ) : availableTimes.dayTimes.map((items) => (
                                                    <Carousel.Item key={Math.random()}>
                                                      <div className="hours-content">
                                                        { items.map((item) => (
                                                          <div
                                                            data-cy="hour-item"
                                                            key={Math.random()}
                                                            role="button"
                                                            className={this.getHoursItemClassName(item)}
                                                            onClick={() => this.handleTimeChange(item)}
                                                          >{ item }
                                                          </div>
                                                        )) }
                                                      </div>
                                                    </Carousel.Item>
                                                  ))}
                                                </Carousel>
                                              ) : <Box display="flex" justifyContent="center">No Available Hours</Box> }
                                            </div>
                                            <img src={night} alt="" />
                                            <div className="night-hours">
                                              { availableTimes.nightTimes.length ? (
                                                <Carousel
                                                  interval={null}
                                                  activeIndex={nightTimesActiveIndex}
                                                  onSelect={(nightTimesActiveIndex) => this.setState({ nightTimesActiveIndex })}
                                                >
                                                  { isAvailableTimesLoading ? (
                                                    <Carousel.Item key={Math.random()} className="active">
                                                      <Loading />
                                                    </Carousel.Item>
                                                  ) : availableTimes.nightTimes.map((items) => (
                                                    <Carousel.Item key={Math.random()}>
                                                      <div className="hours-content">
                                                        { items.map((item) => (
                                                          <div
                                                            key={Math.random()}
                                                            role="button"
                                                            className={this.getHoursItemClassName(item)}
                                                            onClick={() => this.handleTimeChange(item)}
                                                          >
                                                            { item }
                                                          </div>
                                                        )) }
                                                      </div>
                                                    </Carousel.Item>
                                                  )) }
                                                </Carousel>
                                              ) : <Box display="flex" justifyContent="center">No Available Hours</Box> }
                                            </div>
                                          </div>
                                        ) : <Box display="flex" justifyContent="center">No Available Hours</Box> }
                                      </Grid>
                                      <Grid item xs={4}>
                                        <div className={`notes-content ${selectedStaff && selectedDay && selectedTime ? 'button-added' : ''}`}>
                                          <div className="title-content">
                                            <div className="name">
                                                  Notes
                                            </div>
                                          </div>
                                          <div className="area">
                                            <textarea
                                              data-cy="notes"
                                              rows="5"
                                              placeholder="Add your notes to the appointment..."
                                              value={customerNote}
                                              onChange={(event) => this.setState({ customerNote: event.target.value })}
                                            />
                                          </div>
                                          { selectedStaff && selectedDay && selectedTime && (
                                            isEditAppointment && !isAppointmentCancelledOrFinished ? (
                                              <div>
                                                <Button
                                                  size="small"
                                                  color="primary"
                                                  data-cy="edit-appointment"
                                                  type="button"
                                                  onClick={this.handleUpdateAppointment}
                                                >
                                                      Edit Appointment
                                                </Button>
                                              </div>
                                            ) : (
                                              !isEditAppointment && (
                                                <div>
                                                  <Button
                                                    size="small"
                                                    color="primary"
                                                    data-cy="make-appointment"
                                                    type="button"
                                                    onClick={this.handleMakeAppointment}
                                                  >
                                                        Make Appointment
                                                  </Button>
                                                </div>
                                              )
                                            )
                                          ) }
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                ) : null }
                            </div>
                          </div>
                        ))
                          : (
                            <Box display="flex" justifyContent="center" mb="24px">
                              <span>No Staff found</span>
                            </Box>
                          ) }
                      </div>
                    )
                      : chooseIndividualUsers
                        ? (
                          <div className="individual-users-tab">
                            <div className="individual-user-content">
                              <div className="individual-user-details">
                                <Grid container spacing={2}>
                                  <Grid item xs={4}>
                                    <div className="month-content">
                                      <Calendar
                                        minDate={new Date()}
                                        value={selectedDay}
                                        onChange={(selectedDay) => this.selectAppointmentDate(selectedDay)}
                                        tileDisabled={(date) => this.isIndividualUserDateDisabled(date)}
                                      />
                                    </div>
                                  </Grid>
                                  <Grid container xs={4}>
                                    { Object.keys(availableTimes).length ? (
                                      <div className="set-time">
                                        <img src={day} alt="" />
                                        <div className="day-hours">
                                          { availableTimes.dayTimes.length ? (
                                            <Carousel
                                              interval={null}
                                              activeIndex={dayTimesActiveIndex}
                                              onSelect={(dayTimesActiveIndex) => this.setState({ dayTimesActiveIndex })}
                                            >
                                              { isAvailableTimesLoading ? (
                                                <Carousel.Item className="active" key="loading">
                                                  <Loading />
                                                </Carousel.Item>
                                              ) : availableTimes.dayTimes.map((items) => (
                                                <Carousel.Item key={Math.random()}>
                                                  <div className="hours-content">
                                                    { items.map((item) => (
                                                      <div
                                                        key={Math.random()}
                                                        role="button"
                                                        className={this.getHoursItemClassName(item)}
                                                        onClick={() => this.handleTimeChange(item)}
                                                      >{ item }
                                                      </div>
                                                    )) }
                                                  </div>
                                                </Carousel.Item>
                                              ))}
                                            </Carousel>
                                          ) : <Box display="flex" justifyContent="center">No Available Hours</Box> }
                                        </div>
                                        <img src={night} alt="" />
                                        <div className="night-hours">
                                          { availableTimes.nightTimes.length ? (
                                            <Carousel
                                              interval={null}
                                              activeIndex={nightTimesActiveIndex}
                                              onSelect={(nightTimesActiveIndex) => this.setState({ nightTimesActiveIndex })}
                                            >
                                              { isAvailableTimesLoading ? (
                                                <Carousel.Item key={Math.random()} className="active">
                                                  <Loading />
                                                </Carousel.Item>
                                              ) : availableTimes.nightTimes.map((items) => (
                                                <Carousel.Item key={Math.random()}>
                                                  <div className="hours-content">
                                                    { items.map((item) => (
                                                      <div
                                                        key={Math.random()}
                                                        role="button"
                                                        className={this.getHoursItemClassName(item)}
                                                        onClick={() => this.handleTimeChange(item)}
                                                      >
                                                        { item }
                                                      </div>
                                                    )) }
                                                  </div>
                                                </Carousel.Item>
                                              )) }
                                            </Carousel>
                                          ) : <Box display="flex" justifyContent="center">No Available Hours</Box> }
                                        </div>
                                      </div>
                                    ) : <Box display="flex" justifyContent="center">No Available Hours</Box> }
                                  </Grid>
                                  <Grid item xs={4}>
                                    <div className={`notes-content ${selectedIndividualUser && selectedDay && selectedTime ? 'button-added' : ''}`}>
                                      <div className="title-content">
                                        <div className="name">
                                          Notes
                                        </div>
                                      </div>
                                      <div className="area">
                                        <textarea
                                          data-cy="notes"
                                          rows="5"
                                          placeholder="Add your notes to the appointment..."
                                          value={customerNote}
                                          onChange={(event) => this.setState({ customerNote: event.target.value })}
                                        />
                                      </div>
                                      { selectedIndividualUser && selectedDay && selectedTime && (
                                        isEditAppointment && !isAppointmentCancelledOrFinished ? (
                                          <div>
                                            <Button
                                              size="small"
                                              color="primary"
                                              data-cy="edit-appointment"
                                              type="button"
                                              onClick={this.handleUpdateAppointment}
                                            >
                                              Edit Appointment
                                            </Button>
                                          </div>
                                        ) : (
                                          !isEditAppointment && (
                                            <div>
                                              <Button
                                                size="small"
                                                color="primary"
                                                data-cy="make-appointment"
                                                type="button"
                                                onClick={this.handleMakeAppointment}
                                              >
                                                Make Appointment
                                              </Button>
                                            </div>
                                          )
                                        )
                                      ) }
                                    </div>
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                        )
                        : null : null }
              </div>
            </div>
          </div>
        </div>
        <SnackbarToast
          type={snackbarType}
          open={openSnackbar}
          message={snackbarMessage}
          onClose={() => this.setState({ openSnackbar: false })}
        />
      </>
    ) : <Loading />;
  }
}

const mapStateToProps = (state) => ({
  isGetIndustriesSuccess: state.industry.isGetIndustriesSuccess,
  isGetIndustriesError: state.industry.isGetIndustriesError,
  industries: state.industry.industries,
  // Get User Location Info
  // isGetUserLocationSuccess: state.account.isGetUserLocationSuccess,
  // isGetUserLocationError: state.account.isGetUserLocationError,
  // locationInfo: state.account.locationInfo,
  // Get Filtered Companies
  isGetFilteredCompaniesSuccess: state.company.isGetFilteredCompaniesSuccess,
  isGetFilteredCompaniesError: state.company.isGetFilteredCompaniesError,
  getFilteredCompaniesErrorMessage: state.company.getFilteredCompaniesErrorMessage,
  filteredCompanies: state.company.filteredCompanies,
  // Get Staff Available Times
  isGetStaffAvailableTimesSuccess: state.staff.isGetStaffAvailableTimesSuccess,
  availableTimes: state.staff.availableTimes,
  isGetStaffAvailableTimesError: state.staff.isGetStaffAvailableTimesError,
  // Store Appointment
  isStoreAppointmentSuccess: state.appointment.isStoreAppointmentSuccess,
  isStoreAppointmentError: state.appointment.isStoreAppointmentError,
  newAppointment: state.appointment.newAppointment,
  storeAppointmentErrorMessage: state.appointment.storeAppointmentErrorMessage,
  // Get Appointment By Id
  isGetAppointmentByIdSuccess: state.appointment.isGetAppointmentByIdSuccess,
  isGetAppointmentByIdError: state.appointment.isGetAppointmentByIdError,
  returnedAppointment: state.appointment.returnedAppointment,
  getAppointmentByIdErrorMessage: state.appointment.getAppointmentByIdErrorMessage,
  // Update Appointment
  isUpdateAppointmentSuccess: state.appointment.isUpdateAppointmentSuccess,
  isUpdateAppointmentError: state.appointment.isUpdateAppointmentError,
  updatedAppointment: state.appointment.updatedAppointment,
  updateAppointmentErrorMessage: state.appointment.updateAppointmentErrorMessage,
  // Get Filtered Individual Users
  isGetFilteredIndividualUserIndustriesSuccess: state.individualUser.isGetFilteredIndividualUserIndustriesSuccess,
  isGetFilteredIndividualUserIndustriesError: state.individualUser.isGetFilteredIndividualUserIndustriesError,
  filteredIndividualUserIndustries: state.individualUser.filteredIndividualUserIndustries,
  getFilteredIndividualUserIndustriesErrorMessage: state.individualUser.getFilteredIndividualUserIndustriesErrorMessage,
  // Get Individual User Available Times
  isGetIndividualUserAvailableTimesSuccess: state.individualUser.isGetIndividualUserAvailableTimesSuccess,
  isGetIndividualUserAvailableTimesError: state.individualUser.isGetIndividualUserAvailableTimesError,
  individualUserAvailableTimes: state.individualUser.individualUserAvailableTimes,
  getIndividualUserAvailableTimesErrorMessage: state.individualUser.getIndividualUserAvailableTimesErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndustries: (data) => dispatch(getIndustriesRequest(data)),
    // getUserLocation: (data) => dispatch(getUserLocationRequest(data)),
    getFilteredCompanies: (data) => dispatch(getFilteredCompaniesRequest(data)),
    getStaffAvailableTimes: (data) => dispatch(getStaffAvailableTimesRequest(data)),
    storeAppointment: (data) => dispatch(storeAppointmentRequest(data)),
    getAppointmentById: (id) => dispatch(getAppointmentByIdRequest(id)),
    updateAppointment: (data) => dispatch(updateAppointmentRequest(data)),
    getFilteredIndividualUserIndustries: (data) => dispatch(getFilteredIndividualUserIndustriesRequest(data)),
    getIndividualUserAvailableTimes: (data) => dispatch(getIndividualUserAvailableTimesRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrEditAppointment);
