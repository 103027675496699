import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { TableSortLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiTableHead from '@material-ui/core/TableHead';

const TableHead = withStyles(() => ({
  root: {
    backgroundColor: '#FAFAFA',
    fontSize: '14px !important',
  },
}))(MuiTableHead);

const TableHeaderCell = withStyles(() => ({
  root: {
    color: '#393939',
    font: 'normal 700 14px/19px Nunito Sans, sans-serif',
  },
}))(TableCell);

function Header({ headCells, classes, order, orderBy, onRequestSort }) {
  const { t } = useTranslation();

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((cell, index) => (
          <TableHeaderCell
            key={cell.id}
            align={cell.numeric || index === 3 ? 'right' : 'left'}
            padding={cell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === cell.id ? order : false}
            style={{ paddingRight: index === 3 ? '92px' : 0 }}
          >
            {cell.sortable ? (
              <TableSortLabel
                active={orderBy === cell.id}
                direction={orderBy === cell.id ? order : 'asc'}
                onClick={createSortHandler(cell.id)}
              >
                <div style={{ paddingLeft: index === 0 ? '64px' : 0 }}>
                  {t(cell.label)}
                  {orderBy === cell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                    </span>
                  ) : null}
                </div>
              </TableSortLabel>
            ) : (
              <>{t(cell.label)}</>
            )}
          </TableHeaderCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

Header.propTypes = {
  headCells: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

export default Header;
