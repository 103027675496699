function serviceDuration(duration, h, m) {
  const hour = Math.floor(duration / 60);
  const min = duration % 60;

  const hourText = hour !== 0 ? `${hour} ${h}` : '';
  const minText = min !== 0 ? `${min} ${m}` : '';

  return hourText && minText
    ? `${hourText} ${minText}`
    : hourText || minText;
}

export default serviceDuration;
