import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  TextField,
  Select,
  AppBar,
  InputLabel,
  FormControl,
  MenuItem,
  Button,
  Divider,
  Grid,
  Box,
  Typography,
  Container,
  Toolbar,
  FormHelperText,
} from '@material-ui/core';
import { format } from 'date-fns';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import 'moment-timezone';
import Logo from 'assets/img/logo.svg';
import i18n from 'i18next';
import countryCodeOptions from '../../../../Modules/countryCodeOptions';
import { savePersonalDetailsRequest } from '../../../../redux/account/actions';
import Loading from '../../../../Components/Loading/loading';
import usePrevious from '../../../../CustomHooks/usePrevious';
import TextMaskCustom from '../../../../Modules/TextMaskCustom';
import SnackbarToast from '../../../../Modules/SnackbarToast';

const Index = (props) => {
  const {
    backToAccountType,
    countries,
    userAccount,
    savePersonalDetails,
    isSavePersonalDetailsSuccess,
    isSavePersonalDetailsError,
    personalDetailsErrors,
  } = props;

  const prevIsSavePersonalDetailsSuccess = usePrevious(isSavePersonalDetailsSuccess);
  const prevIsSavePersonalDetailsError = usePrevious(isSavePersonalDetailsError);

  const [personalDetails, setPersonalDetails] = useState({
    firstName: '',
    lastName: '',
    phoneCode: '',
    phoneNumber: '',
    gender: '',
    birthDay: '',
    countryId: '',
    timezone: '',
  });
  const [formErrors, setFormErrors] = useState({
    firstName: '',
    lastName: '',
    phoneCode: '',
    phoneNumber: '',
    gender: '',
    birthDay: '',
    countryId: '',
    timezone: '',
  });
  const [selectedCountryOption, setSelectedCountryOption] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);
  const [gender, setGender] = useState('');
  const [birthDay, setBirthday] = useState('');
  const [selectedPhoneCode, setSelectedPhoneCode] = useState('');
  const [codeOptions, setCodeOptions] = useState([]);
  const [, setUserEmail] = useState('');
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [selectedTimeZoneOption, setSelectedTimeZoneOption] = useState('');
  const date = new Date();

  // Perform, when component gets mounted
  useEffect(() => {
    setUserEmail(userAccount.email);
    setPersonalDetails((prevState) => ({
      ...prevState,
      firstName: userAccount.first_name,
      lastName: userAccount.last_name,
      phoneNumber: userAccount.phone_number,
      phoneCode: userAccount.phone_code,
      gender: userAccount.gender,
    }));
    const timeZones = moment.tz.names();
    const timeZoneNamesList = [];
    timeZones.forEach((item, i) => timeZoneNamesList.push(` (GMT${moment.tz(timeZones[i]).format('Z')})${timeZones[i]}`));
    setTimeZoneOptions(timeZoneNamesList);
    setGender(userAccount.gender);
    setLoading(false);
  }, []);

  useEffect(() => {
    const codeOptions = countryCodeOptions.getCodeOptions(countries);
    setCodeOptions(codeOptions);

    const countryOptions = countryCodeOptions.getCountryOptions(countries);
    setCountryOptions(countryOptions);
    const selectedCode = codeOptions.find((item) => item.value === userAccount.phone_code);
    if (selectedCode) {
      setSelectedPhoneCode(selectedCode.value);
    }
  }, [countries]);

  useEffect(() => {
    if (prevIsSavePersonalDetailsSuccess === false && isSavePersonalDetailsSuccess) {
      setOpenSnackbar(true);
      setSnackbarMessage('Personal details saved');
      setSnackbarType('success');
      window.location.reload(false);
    } else if (prevIsSavePersonalDetailsError === false && isSavePersonalDetailsError) {
      setLoading(false);
    }
  }, [isSavePersonalDetailsSuccess, isSavePersonalDetailsError]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (value.trim() !== '') {
      setPersonalDetails({
        ...personalDetails,
        [name]: value,
      });
    }
  };

  const handleCountrySelectChange = (event, countryName) => {
    const selectedCountryOption = countryOptions.find((item) => item.value === countryName);
    if (selectedCountryOption && selectedCountryOption.id) {
      const phoneOption = codeOptions.find((item) => item.key === selectedCountryOption.key);
      setSelectedCountryOption(selectedCountryOption);
      const selectedTimeZone = timeZoneOptions.find((item) => item.includes(selectedCountryOption.capital));
      setSelectedTimeZoneOption(selectedTimeZone);
      setPersonalDetails({
        ...personalDetails,
        country: selectedCountryOption.value,
        countryId: selectedCountryOption.id,
        phoneCode: phoneOption.value,
        timezone: selectedTimeZone,
        stateId: '',
        cityId: '',
      });
      setSelectedPhoneCode(phoneOption.value);
    } else {
      setSelectedCountryOption({});
      setPersonalDetails({
        ...personalDetails,
        countryId: '',
        country: '',
        countryName: '',
        phoneCode: '',
        stateId: '',
        cityId: '',
      });
    }
  };

  const handleCountryCodeSelectChange = (event) => {
    const codeId = parseInt(event.target.value);
    const phoneOption = codeOptions.find((item) => parseInt(item.value) === codeId);
    setSelectedPhoneCode(phoneOption.value);

    setPersonalDetails({
      ...personalDetails,
      phoneCode: phoneOption.value,
    });
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
    const { name, value } = event.target;
    setPersonalDetails({
      ...personalDetails,
      [name]: value,
    });
  };

  const handleBirthDayChange = ({ target: { value } }) => {
    setBirthday(value);
  };

  const validateForm = () => {
    const formItemNames = Object.keys(personalDetails);
    const formErrorsCopy = { ...formErrors };
    const validateName = (name) => (!personalDetails[name] || personalDetails[name].length === 0);
    const validatePhone = () => (
      (!personalDetails.phoneNumber || personalDetails.phoneNumber.length === 0));

    const validatePhoneCode = () => (
      (!selectedPhoneCode || selectedPhoneCode.length === 0)
    );

    const validateCountry = () => (
      (!personalDetails.country || personalDetails.country.length === 0)
    );

    const validateTimezone = () => (
      (!selectedTimeZoneOption || selectedTimeZoneOption.length === 0 || !personalDetails.timezone)
    );

    formItemNames.forEach((name) => {
      switch (name) {
        case 'firstName': {
          formErrorsCopy[name] = validateName(name);
          break;
        }
        case 'lastName': {
          formErrorsCopy[name] = validateName(name);
          break;
        }
        case 'gender': {
          formErrorsCopy[name] = validateName(name);
          break;
        }
        case 'phoneNumber': {
          formErrorsCopy[name] = validatePhone();
          break;
        }
        case 'phoneCode': {
          formErrorsCopy[name] = validatePhoneCode();
          break;
        }
        case 'birthDay': {
          formErrorsCopy[name] = !(birthDay && birthDay.length !== 0);
          break;
        }
        case 'countryId': {
          formErrorsCopy[name] = validateCountry();
          break;
        }
        case 'timezone': {
          formErrorsCopy[name] = validateTimezone();
          break;
        }
      }
    });
    setFormErrors(formErrorsCopy);
    const errorValues = Object.keys(formErrorsCopy);
    return errorValues.find((item) => formErrorsCopy[item]);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const errorExists = validateForm();
    if (!errorExists) {
      setLoading(true);
      savePersonalDetails({
        ...personalDetails,
        birthDay,
        gender,
      });
    }
  };

  const handleTimeZoneSelectChange = (e, selectedTimeZone) => {
    if (selectedTimeZone) {
      setSelectedTimeZoneOption(selectedTimeZone || '');
      setPersonalDetails({
        ...personalDetails,
        timezone: selectedTimeZone,
      });
    } else {
      setSelectedTimeZoneOption({
        selectedTimeZone: '',
      });
      setPersonalDetails({
        ...personalDetails,
        timezone: '',
      });
    }
  };

  return !loading ? (
    <>
      <div className="auth auth-bg">
        <Container>
          <AppBar color="transparent" position="static" elevation={0} className="login-header">
            <Toolbar>
              <Box mx="auto" className="auth-logo">
                <Link to="/login">
                  <img src={Logo} alt="Logo" />
                </Link>
              </Box>
            </Toolbar>
          </AppBar>
          <Grid container justifyContent="center">
            <Grid item direction="row" justifyContent="center" md={6} sm={9}>
              <div className="auth-box">
                <Box display="flex" height="100%">
                  <Box alignSelf="center" mx="auto" width="100%">
                    <Box px="40px" py="30px">
                      <Box mb="30px">
                        <Typography align="center" variant="h4">Personal details</Typography>
                      </Box>
                      <Grid container spacing={2} xs={12} className="personal-container">
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!formErrors.firstName}
                          >
                            <TextField
                              fullWidth
                              name="firstName"
                              value={personalDetails.firstName}
                              onChange={handleInputChange}
                              label="First name *"
                              variant="outlined"
                              color="primary"
                              size="small"
                              error={!!formErrors.firstName}
                              inputProps={{
                                autoComplete: 'new-password',
                              }}
                            />

                            { formErrors.firstName && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  First name is required
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!formErrors.lastName}
                          >
                            <TextField
                              error={!!formErrors.lastName}
                              fullWidth
                              name="lastName"
                              value={personalDetails.lastName}
                              onChange={handleInputChange}
                              label="Last name *"
                              variant="outlined"
                              size="small"
                              inputProps={{
                                autoComplete: 'new-password',
                              }}
                            />

                            { formErrors.lastName && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  Last name is required
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} xs={12} className="personal-container">
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!formErrors.gender}
                          >
                            <InputLabel>Gender *</InputLabel>
                            <Select
                              value={gender}
                              name="gender"
                              label="Gender *"
                              onChange={(event) => handleGenderChange(event)}
                            >
                              <MenuItem value="female">Female</MenuItem>
                              <MenuItem value="male">Male</MenuItem>
                            </Select>

                            { formErrors.gender && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  Gender is required
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!formErrors.birthDay}
                          >
                            <TextField
                              label="Day of birth *"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              error={!!formErrors.birthDay}
                              fullWidth
                              variant="outlined"
                              name="birthDay"
                              value={birthDay}
                              onChange={handleBirthDayChange}
                              inputProps={{ max: format(date.setFullYear(date.getFullYear() - 12), 'yyyy-MM-dd'), autoComplete: 'new-password' }}
                              size="small"
                            />

                            { formErrors.birthDay && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  Birthday is required
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} xs={12} className="personal-container">
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!formErrors.phoneCode}
                          >
                            <InputLabel>Code *</InputLabel>
                            <Select
                              name="phoneCode"
                              label="Code *"
                              onChange={(event) => handleCountryCodeSelectChange(event)}
                              value={selectedPhoneCode}
                            >
                              <MenuItem disabled value=""><em>{i18n.t('PhoneCode')}</em></MenuItem>
                              <Divider />
                              {codeOptions && codeOptions.map((option, index) => (
                                <MenuItem
                                  className="flag-select"
                                  key={`${option.id}-${index}`}
                                  value={option.value}
                                >
                                  <img
                                    src={option.label.flagImageSrc}
                                    alt="flag"
                                    className="country-flag"
                                  />
                                  {option.key}
                                  {option.value}
                                </MenuItem>
                              ))}
                            </Select>

                            { formErrors.phoneCode && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  {i18n.t('PhoneCode')} is required
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!formErrors.phoneNumber || !!personalDetailsErrors.phone_number}
                          >
                            <TextField
                              error={!!formErrors.phoneNumber || !!personalDetailsErrors.phone_number}
                              fullWidth
                              type="text"
                              value={personalDetails.phoneNumber}
                              onChange={handleInputChange}
                              name="phoneNumber"
                              label="Phone number *"
                              variant="outlined"
                              size="small"
                              InputProps={{
                                inputComponent: TextMaskCustom,
                                autoComplete: 'new-password',
                              }}
                            />

                            { (formErrors.phoneNumber || personalDetailsErrors.phone_number) && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  {formErrors.phoneNumber ? 'Phone number is required' : personalDetailsErrors ? personalDetailsErrors.phone_number : ''}
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!formErrors.countryId}
                          >
                            <Autocomplete
                              size="small"
                              fullWidth
                              autoHighlight
                              value={selectedCountryOption}
                              onInputChange={handleCountrySelectChange}
                              options={countryOptions}
                              getOptionLabel={(option) => option.label}
                              renderOption={(option) => (
                                <div
                                  className="input-menu"
                                  key={option.id}
                                  value={option.id}
                                >
                                  {option.value}
                                </div>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                  }}
                                  label="Country *"
                                  variant="outlined"
                                  error={!!formErrors.countryId}
                                />
                              )}
                            />

                            { formErrors.countryId && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  Country is required
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!formErrors.timezone}
                          >
                            <Autocomplete
                              size="small"
                              fullWidth
                              options={timeZoneOptions}
                              getOptionLabel={(option) => option}
                              onChange={handleTimeZoneSelectChange}
                              value={selectedTimeZoneOption || personalDetails.timezone !== 'null' ? selectedTimeZoneOption || personalDetails.timezone : ''}
                              renderOption={(option) => (option)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password',
                                  }}
                                  error={!!formErrors.timezone}
                                  label="Time zone *"
                                  variant="outlined"
                                />
                              )}
                            />

                            { formErrors.timezone && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  Timezone is required
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>

                      </Grid>
                      <Box display="flex" justifyContent="space-between" width="100%" px="8px" mt="22px">
                        {!userAccount.invited
                          ? (
                            <Box alignSelf="center">
                              <Link onClick={backToAccountType} to="/">
                                <Button
                                  color="secondary"
                                  startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} color="secondary" />}
                                >
                                  Back
                                </Button>
                              </Link>
                            </Box>
                          )
                          : <Box alignSelf="center" /> }
                        <div>
                          <Button size="small" className="primary-btn" color="primary" onClick={onSubmit} variant="contained" data-cy="submit" type="submit">
                            Save
                          </Button>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  ) : <Loading />;
};

Index.propTypes = {
  backToAccountType: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  userAccount: PropTypes.object.isRequired,
  savePersonalDetails: PropTypes.func.isRequired,
  isSavePersonalDetailsSuccess: PropTypes.bool.isRequired,
  isSavePersonalDetailsError: PropTypes.bool.isRequired,
  personalDetailsErrors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userAccount: state.account.userAccount,
  isSavePersonalDetailsSuccess: state.account.isSavePersonalDetailsSuccess,
  isSavePersonalDetailsError: state.account.isSavePersonalDetailsError,
  personalDetailsErrors: state.account.personalDetailsErrors,
});

function mapDispatchToProps(dispatch) {
  return {
    savePersonalDetails: (data) => dispatch(savePersonalDetailsRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
