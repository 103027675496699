import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListLoading from 'Components/Loading/listLoading';
import CategoryItem from './categoryItem';
import ServiceContext from './serviceContext';

const useStyles = makeStyles({
  root: {
    '&$selected': {
      backgroundColor: '#F2F9FD !important',
      borderRadius: '4px',
    },
  },
  selected: {},
  text: {
    color: '#393939',
    font: 'normal 400 14px/19px Nunito Sans, sans-serif',
    whiteSpace: 'nowrap',
    width: '176px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

function Categories() {
  const { loading, categories } = useContext(ServiceContext);

  const classes = useStyles();

  return (
    !loading ? (
      <>
        {categories.map((category, index) => (
          <CategoryItem
            index={index}
            category={category}
            classes={classes}
          />
        ))}
      </>
    ) : (
      <ListLoading count={7} />
    )
  );
}

export default Categories;
