import { handleActions } from 'redux-actions';
import {
  getAllCountriesRequest,
  getAllCountriesSuccess,
  getAllCountriesError,
  getStatesRequest,
  getStatesSuccess,
  getStatesError,
  getCitiesRequest,
  getCitiesSuccess,
  getCitiesError,
  getProfessionsRequest,
  getProfessionsSuccess,
  getProfessionsError,
} from './actions';
import { addProfessionSuccess } from '../staff/actions';

const initialState = {
  isGetAllCountriesSuccess: false,
  isGetAllCountriesError: false,
  allCountries: [],
  isGetStatesSuccess: false,
  isGetStatesError: false,
  states: [],
  isGetCitiesSuccess: false,
  isGetCitiesError: false,
  cities: [],
  isGetProfessionsSuccess: false,
  isGetProfessionsError: false,
  professions: [],
};

const reducer = handleActions(
  {
    [getAllCountriesRequest]: (state) => ({
      ...state,
      allCountries: [],
      isGetAllCountriesSuccess: false,
      isGetAllCountriesError: false,
    }),
    [getAllCountriesSuccess]: (state, { payload }) => ({
      ...state,
      allCountries: payload.data,
      isGetAllCountriesSuccess: true,
      isGetAllCountriesError: false,
    }),
    [getAllCountriesError]: (state) => ({
      ...state,
      allCountries: [],
      isGetAllCountriesSuccess: false,
      isGetAllCountriesError: true,
    }),
    [getStatesRequest]: (state) => ({
      ...state,
      states: [],
      isGetStatesSuccess: false,
      isGetStatesError: false,
    }),
    [getStatesSuccess]: (state, { payload }) => ({
      ...state,
      states: payload.data,
      isGetStatesSuccess: true,
      isGetStatesError: false,
    }),
    [getStatesError]: (state) => ({
      ...state,
      states: [],
      isGetStatesSuccess: false,
      isGetStatesError: true,
    }),
    [getCitiesRequest]: (state) => ({
      ...state,
      cities: [],
      isGetCitiesSuccess: false,
      isGetCitiesError: false,
    }),
    [getCitiesSuccess]: (state, { payload }) => ({
      ...state,
      cities: payload.data,
      isGetCitiesSuccess: true,
      isGetCitiesError: false,
    }),
    [getCitiesError]: (state) => ({
      ...state,
      cities: [],
      isGetCitiesSuccess: false,
      isGetCitiesError: true,
    }),
    [getProfessionsRequest]: (state) => ({
      ...state,
      professions: [],
      isGetProfessionsSuccess: false,
      isGetProfessionsError: false,
    }),
    [getProfessionsSuccess]: (state, { payload }) => ({
      ...state,
      professions: payload.data,
      isGetProfessionsSuccess: true,
      isGetProfessionsError: false,
    }),
    [getProfessionsError]: (state) => ({
      ...state,
      professions: [],
      isGetProfessionsSuccess: false,
      isGetProfessionsError: true,
    }),
    [addProfessionSuccess]: (state, { payload }) => ({
      ...state,
      professions: [payload, ...state.professions],
    }),
  },
  initialState
);

export default reducer;
