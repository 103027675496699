import React, { useEffect, useState } from 'react';
import {
  Radio,
  AppBar,
  Switch,
  Select,
  TableCell,
  RadioGroup,
  FormControlLabel,
  Box,
  Grid,
  Table,
  Toolbar,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  makeStyles,
  FormControl,
  TableContainer,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import SnackbarToast from '../../../../../Modules/SnackbarToast';
import SettingsLoading from '../../../../../Components/Loading/settingsLoading';
import {
  thanks,
  reminder,
  slotDurations,
  notificationsType,
  cancelUpToAppointments,
  limitFutureAppointments,
  limitSoonerAppointments,
} from '../../../../../Modules/constants';
import {
  getIndividualUserSettingsRequest,
  updateIndividualUserSettingsRequest,
} from '../../../../../redux/individualUser/actions';
import usePrevious from '../../../../../CustomHooks/usePrevious';
import {
  updateAppointmentsReminderRequest,
  updateAppointmentsThankYouMessageRequest,
} from '../../../../../redux/appointment/actions';

const useStyles = makeStyles({
  table: {
    minWidth: 320,
  },
  titleCalendar: {
    fontWeight: 500,
    marginBottom: 12,
  },
  titleAppointment: {
    fontWeight: 500,
  },
  settingsTable: {
    boxShadow: '0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12)',
    marginBottom: 20,
    padding: '0 16px',
  },
  notificationTableTh: {
    fontSize: 14,
  },
});

function Settings() {
  const dispatch = useDispatch();

  const {
    individualUserSettings,
    isGetIndividualUserSettingsError,
    isGetIndividualUserSettingsSuccess,
    getIndividualUserSettingsErrorMessage,
    isUpdateIndividualUserSettingsSuccess,
    isUpdateIndividualUserSettingsError,
    updateIndividualUserSettingsErrorMessage,
  } = useSelector((state) => state.individualUser);

  const {
    // Update Appointments Reminder
    isUpdateAppointmentsReminderSuccess,
    isUpdateAppointmentsReminderError,
    updateAppointmentsReminderErrorMessage,
    // Update Appointments Thank You Message
    isUpdateAppointmentThankYouMessageSuccess,
    isUpdateAppointmentThankYouMessageError,
    updateAppointmentThankYouMessageErrorMessage,
  } = useSelector((state) => state.appointment);

  const tableHead = [
    'Notification',
    '',
  ];
  const classes = useStyles();

  const [settings, setSettings] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [changedReminder, setChangeReminder] = useState(false);
  const [changedThankYou, setChangedThankYou] = useState(false);
  const [loading, setLoading] = useState(true);

  // Get some props previous values
  const prevIsGetIndividualUserSettingsSuccess = usePrevious(isGetIndividualUserSettingsSuccess);
  const prevIsGetIndividualUserSettingsError = usePrevious(isGetIndividualUserSettingsError);
  const prevIsUpdateIndividualUserSettingsSuccess = usePrevious(isUpdateIndividualUserSettingsSuccess);
  const prevIsUpdateIndividualUserSettingsError = usePrevious(isUpdateIndividualUserSettingsError);
  const prevIsUpdateAppointmentsReminderSuccess = usePrevious(isUpdateAppointmentsReminderSuccess);
  const prevIsUpdateAppointmentsReminderError = usePrevious(isUpdateAppointmentsReminderError);
  const prevIsUpdateAppointmentThankYouMessageSuccess = usePrevious(isUpdateAppointmentThankYouMessageSuccess);
  const prevIsUpdateAppointmentThankYouMessageError = usePrevious(isUpdateAppointmentThankYouMessageError);

  useEffect(() => {
    dispatch(getIndividualUserSettingsRequest());
  }, []);

  // Handle get Individual User settings success
  useEffect(() => {
    if (prevIsGetIndividualUserSettingsSuccess === false && isGetIndividualUserSettingsSuccess) {
      setSettings(individualUserSettings);
      setLoading(false);
    }
  }, [isGetIndividualUserSettingsSuccess]);

  // Handle get Individual User settings error
  useEffect(() => {
    if (prevIsGetIndividualUserSettingsError === false && isGetIndividualUserSettingsError) {
      snackBarAlert(true, getIndividualUserSettingsErrorMessage, 'error');
      setLoading(false);
    }
  }, [isGetIndividualUserSettingsError]);

  // Handle Individual User settings updated success
  useEffect(() => {
    if (prevIsUpdateIndividualUserSettingsSuccess === false && isUpdateIndividualUserSettingsSuccess) {
      if (changedReminder) {
        dispatch(updateAppointmentsReminderRequest());
      } else if (changedThankYou) {
        dispatch(updateAppointmentsThankYouMessageRequest());
      } else {
        snackBarAlert(true, i18n.t('UpdatedSuccess'), 'success');
      }
    }
  }, [isUpdateIndividualUserSettingsSuccess]);

  // Handle Update User Settings Error
  useEffect(() => {
    if (prevIsUpdateIndividualUserSettingsError === false && isUpdateIndividualUserSettingsError) {
      snackBarAlert(true, updateIndividualUserSettingsErrorMessage, 'error');
    }
  }, [isUpdateIndividualUserSettingsError]);

  // Handle Update Appointments Reminder Success
  useEffect(() => {
    if (prevIsUpdateAppointmentsReminderSuccess === false && isUpdateAppointmentsReminderSuccess) {
      setChangeReminder(false);
      snackBarAlert(true, 'Reminders Updated for all appointments', 'success');
    }
  }, [isUpdateAppointmentsReminderSuccess]);

  // Handle Update Appointments Reminder Error
  useEffect(() => {
    if (prevIsUpdateAppointmentsReminderError === false && isUpdateAppointmentsReminderError) {
      snackBarAlert(true, updateAppointmentsReminderErrorMessage, 'error');
    }
  }, [isUpdateAppointmentsReminderError]);

  // Handle Update Appointments Thank You Message Success
  useEffect(() => {
    if (prevIsUpdateAppointmentThankYouMessageSuccess === false && isUpdateAppointmentThankYouMessageSuccess) {
      snackBarAlert(true, 'Thank You Messages updated for all appointments', 'success');
    }
  }, [isUpdateAppointmentThankYouMessageSuccess]);

  // Handle Update Appointments Thank You Message Error
  useEffect(() => {
    if (prevIsUpdateAppointmentThankYouMessageError === false && isUpdateAppointmentThankYouMessageError) {
      snackBarAlert(true, updateAppointmentThankYouMessageErrorMessage, 'error');
    }
  }, [isUpdateAppointmentThankYouMessageError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleChangeSettings = (event, notificationType) => {
    const { name, checked } = event.target;
    const settingsCopy = { ...settings };
    if (checked) {
      settingsCopy.notifications[notificationType].push(name);
    } else {
      const notificationIndex = settingsCopy.notifications[notificationType].findIndex((item) => item === name);
      settingsCopy.notifications[notificationType].splice(notificationIndex, 1);
    }

    setSettings(settingsCopy);
    dispatch(updateIndividualUserSettingsRequest({ settings: settingsCopy }));
  };

  const handleChange = (e, value, type) => {
    const { name } = e.target;
    if (type === 'reminder') {
      setChangeReminder(true);
      setChangedThankYou(false);
    } else if (type === 'follow_up') {
      setChangedThankYou(true);
      setChangeReminder(false);
    }
    const settingsCopy = { ...settings };
    settingsCopy[type][name] = value;
    setSettings(settingsCopy);
    dispatch(updateIndividualUserSettingsRequest({ settings: settingsCopy }));
  };

  const splitString = (string) => {
    const split = string.split(/([0-9]+)/).filter(Boolean);

    if (split.length > 1) {
      return `${split[0].trim()} ${split[1].trim()}`;
    } else {
      return split[0];
    }
  };

  return (
    <>
      <div className="main-content">
        <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
              <Box display="flex" className="tabs-navbar">
                <Box alignSelf="center" className="divider-title">
                  <Typography variant="h6" noWrap>
                    <Box display="flex" alignItems="center" height="100%">
                      My Settings
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box p="32px">
          {!loading ? (
            <Grid container spacing={4} className="settings-box">
              {Object.keys(settings).length && (
                <>
                  <Grid item xs={12} md={6}>
                    <Box className="settings-calendarBox">
                      <Box mb="16px">
                        <Typography variant="subtitle1" className={classes.titleCalendar}>
                          Calendar
                        </Typography>
                        <Divider />
                      </Box>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>
                            Default View
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <RadioGroup row defaultValue="timeGridDay" name="default_view" value={settings.calendar.default_view} onChange={(e) => handleChange(e, e.target.value, 'calendar')}>
                            <FormControlLabel value="timeGridDay" control={<Radio size="small" color="primary" />} label="Daily" />
                            <FormControlLabel value="timeGridWeek" control={<Radio size="small" color="primary" />} label="Weekly" />
                            <FormControlLabel value="dayGridMonth" control={<Radio size="small" color="primary" />} label="Monthly" />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>
                            Calendar Slot duration
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl>
                            <Select
                              value={settings.calendar.slot}
                              onChange={(e) => handleChange(e, e.target.value, 'calendar')}
                              name="slot"
                            >
                              {slotDurations.map((item, index) => (
                                <MenuItem key={`${index}-${item}`} value={item}>{item} minutes</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="settings-appointmentBox">
                      <Box pb="12px">
                        <Typography variant="subtitle1" className={classes.titleAppointment}>
                          Appointment
                        </Typography>
                      </Box>
                      <Divider />
                      <Grid container justify="space-between" className="settings-appointments">
                        <Grid item>
                          <Typography>
                            Automatically accept appointments
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Switch
                            size="small"
                            checked={settings.appointment.auto_accept}
                            onChange={(e) => handleChange(e, e.target.checked, 'appointment')}
                            color="primary"
                            name="auto_accept"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justify="space-between" className="settings-appointments">
                        <Grid item>
                          <Typography>
                            Automatically finish confirmed appointments when appointment ending time is passed
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Switch
                            size="small"
                            checked={settings.appointment.auto_finish}
                            onChange={(e) => handleChange(e, e.target.checked, 'appointment')}
                            color="primary"
                            name="auto_finish"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justify="space-between" className="settings-appointments">
                        <Grid item>
                          <Typography>
                            Allow to create past date appointments
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Switch
                            size="small"
                            checked={settings.appointment.past_appointments}
                            onChange={(e) => handleChange(e, e.target.checked, 'appointment')}
                            color="primary"
                            name="past_appointments"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justify="flex-start" alignItems="center" className="settings-appointments">
                        <Grid item>
                          <Typography>
                            Allow Customer to create an appointment no later than
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Box mx="16px">
                            <Select
                              value={splitString(settings.appointment.create_no_lather_than)}
                              onChange={(e) => handleChange(e, e.target.value, 'appointment')}
                              name="create_no_lather_than"
                            >
                              {limitFutureAppointments.map((limit, index) => (
                                <MenuItem key={`${index}-${limit}`} value={limit}> {limit} </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography>
                            in advance
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justify="flex-start" alignItems="center" className="settings-appointments">
                        <Grid item>
                          <Typography>
                            Allow Customer to create an appointment no sooner than
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Box mx="16px">
                            <Select
                              value={splitString(settings.appointment.create_no_sooner_than)}
                              onChange={(e) => handleChange(e, e.target.value, 'appointment')}
                              name="create_no_sooner_than"
                            >
                              {limitSoonerAppointments.map((soonerAppointment, index) => (
                                <MenuItem key={`${index}-${soonerAppointment}`} value={soonerAppointment}> {soonerAppointment} </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography>
                            in advance
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justify="flex-start" alignItems="center" className="settings-appointments">
                        <Grid item>
                          <Typography>
                            Allow Customer to cancel appointments up to
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Box mx="10px">
                            <Select
                              value={splitString(settings.appointment.cancel_up_to)}
                              onChange={(e) => handleChange(e, e.target.value, 'appointment')}
                              name="cancel_up_to"
                            >
                              {cancelUpToAppointments.map((cancelUpTo, index) => (
                                <MenuItem key={`${index}-${cancelUpTo}`} value={cancelUpTo}> {cancelUpTo} </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography>
                            in advance
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justify="flex-start" alignItems="center" className="settings-appointments">
                        <Grid item>
                          <Typography>
                            Allow Customer to reschedule appointments up to
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Box mx="10px">
                            <Select
                              value={splitString(settings.appointment.reschedule_up_to)}
                              onChange={(e) => handleChange(e, e.target.value, 'appointment')}
                              name="reschedule_up_to"
                            >
                              {cancelUpToAppointments.map((cancelUpTo, index) => (
                                <MenuItem key={`${index}-${index}`} value={cancelUpTo}> {cancelUpTo} </MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Grid>
                        <Grid item>
                          <Typography>
                            in advance
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider />
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TableContainer className={classes.settingsTable}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            {tableHead.map((item, index) => (
                              <TableCell key={`${index}-${item}`}>{item}</TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.keys(settings.notifications).map((keyName, index) => (
                            <TableRow key={`${index}-${keyName}`}>
                              <TableCell component="th" scope="row" className={classes.notificationTableTh}>
                                {notificationsType[keyName]}
                              </TableCell>
                              <TableCell align="center">
                                <Switch
                                  size="small"
                                  checked={settings.notifications[keyName].includes('notification')}
                                  onChange={(event) => handleChangeSettings(event, keyName)}
                                  color="primary"
                                  name="notification"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box className="settings-calendarBox">
                      <Box mb="12px">
                        <Typography variant="subtitle1" className={classes.titleCalendar}>
                          Reminder
                        </Typography>
                        <Divider />
                      </Box>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>
                            Remind Customer
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl>
                            <Select
                              value={settings.reminder.customer}
                              onChange={(e) => handleChange(e, e.target.value, 'reminder')}
                              name="customer"
                            >
                              {reminder.map((item, index) => (
                                <MenuItem key={`${index}-${item.value}`} value={item.value}>{item.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="settings-calendarBox">
                      <Box mb="12px">
                        <Typography variant="subtitle1" className={classes.titleCalendar}>
                          Follow up
                        </Typography>
                        <Divider />
                      </Box>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>
                            Send &quot;Thank you&quot; message to Customer
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl>
                            <Select
                              value={settings.follow_up.thank_you_customer}
                              onChange={(e) => handleChange(e, e.target.value, 'follow_up')}
                              name="thank_you_customer"
                            >
                              {thanks.map((item, index) => (
                                <MenuItem key={`${index}-${item.value}`} value={item.value}>{item.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <SettingsLoading />
          )}
        </Box>
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

export default Settings;
