const servicesLogo = {
  // Advisory
  Accounting: require('../assets/img/services/Advisory/Accounting Advisory.svg'),
  Advisory: require('../assets/img/services/Advisory/Advisory.svg'),
  Financial: require('../assets/img/services/Advisory/Financial Advisory.svg'),
  IT: require('../assets/img/services/Advisory/IT advisory.svg'),
  Management: require('../assets/img/services/Advisory/Management Advisory.svg'),
  Marketing: require('../assets/img/services/Advisory/Marketing Advisory.svg'),
  'Real Estate': require('../assets/img/services/Advisory/Real Estate.svg'),
  Travel: require('../assets/img/services/Advisory/Travel Advisory.svg'),
  Legal: require('../assets/img/services/Advisory/Legal Advisory.svg'),
  Brokerage: require('../assets/img/services/Advisory/Brokerage.svg'),

  // Beauty
  Barber: require('../assets/img/services/Beauty/Barber.svg'),
  Beauty: require('../assets/img/services/Beauty/beauty.svg'),
  ELOS: require('../assets/img/services/Beauty/ELOS epilation.svg'),
  Eyebrows: require('../assets/img/services/Beauty/Eyebrows.svg'),
  Eyelashes: require('../assets/img/services/Beauty/Eyelashes.svg'),
  'For kids': require('../assets/img/services/Beauty/For kids.svg'),
  'Hair Care': require('../assets/img/services/Beauty/Hair Care.svg'),
  'Hair Coloring': require('../assets/img/services/Beauty/Hair Coloring.svg'),
  'Hair Extensions': require('../assets/img/services/Beauty/Hair Extensions.svg'),
  'Hair Styling': require('../assets/img/services/Beauty/Hair Styling.svg'),
  Haircut: require('../assets/img/services/Beauty/Haircut.svg'),
  'Lazer Epilation': require('../assets/img/services/Beauty/Lazer Epilation.svg'),
  Makeup: require('../assets/img/services/Beauty/Makeup.svg'),
  Manicure: require('../assets/img/services/Beauty/Manicure.svg'),
  'Needle Epilation': require('../assets/img/services/Beauty/Needle Epilation.svg'),
  Pedicure: require('../assets/img/services/Beauty/Pedicure.svg'),
  Photoepilation: require('../assets/img/services/Beauty/Photoepilation.svg'),
  Tattoo: require('../assets/img/services/Beauty/Tattoo.svg'),

  // Healthcare
  Allergist: require('../assets/img/services/Healthcare/Allergist.svg'),
  Cardiologist: require('./img/services/Healthcare/Cardiologist.svg'),
  Dentistry: require('./img/services/Healthcare/Dentistry.svg'),
  Dermatology: require('./img/services/Healthcare/Dermatology.svg'),
  'Ear, Nose & Throat': require('./img/services/Healthcare/Ear, Nose & Throat.svg'),
  Gastroenterology: require('./img/services/Healthcare/Gastroenterology.svg'),
  Healthcare: require('./img/services/Healthcare/Healthcare.svg'),
  Nutritionist: require('./img/services/Healthcare/Nutritionist.svg'),
  Psychiatry: require('./img/services/Healthcare/Psychiatry.svg'),
  Urology: require('./img/services/Healthcare/Urologist.svg'),
  'Dietitian / Nutritionist': require('./img/services/Healthcare/Dietitian  Nutritionist.svg'),

  // Photography
  Photography: require('./img/services/Photography/Photography.svg'),
  Videography: require('./img/services/Photography/Videography.svg'),

  // SPA
  Massage: require('../assets/img/services/SPA/Massage therapy.svg'),
  'Body Care': require('../assets/img/services/SPA/Body care.svg'),
  Facials: require('../assets/img/services/SPA/Facials.svg'),
  SPA: require('../assets/img/services/SPA/Spa.svg'),

  // Other
  'Car Wash': require('./img/services/Other/Car wash.svg'),
  'Football pitch rent': require('./img/services/Other/Football pitch rent.svg'),
  Other: require('./img/services/Other/Other.svg'),
  Toastmaster: require('./img/services/Other/Toastmaster.svg'),
  Cleaning: require('./img/services/Other/CleaningService.svg'),
  'Apartment rent': require('./img/services/Other/Renting.svg'),
};
export default servicesLogo;
