import React, { useEffect, useState } from 'react';
import {
  AppBar,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TableCell,
  Select,
  Box,
  Divider,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import { notificationsType, slotDurations, tableHeadCustomer } from '../../../../../Modules/constants';
import { getUserSettingsRequest, updateUserSettingsRequest } from '../../../../../redux/account/actions';
import SettingsLoading from '../../../../../Components/Loading/settingsLoading';
import usePrevious from '../../../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../../../Modules/SnackbarToast';

const useStyles = makeStyles({
  table: {
    minWidth: 320,
  },
  titleCalendar: {
    fontWeight: 500,
    marginBottom: 12,
  },
  titleAppointment: {
    fontWeight: 500,
  },
  settingsTable: {
    boxShadow: '0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12)',
    marginBottom: 20,
    padding: '0 16px',
  },
});

const Settings = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const {
    // Get User Settings
    isGetUserSettingsSuccess,
    isGetUserSettingsError,
    getUserSettingsErrorMessage,
    userSettings,
    // Update User Settings
    isUpdateUserSettingsSuccess,
    isUpdateUserSettingsError,
    updateUserSettingsErrorMessage,
  } = useSelector((state) => state.account);

  // Get some props previous values
  const prevIsGetUserSettingsError = usePrevious(isGetUserSettingsError);
  const prevIsGetUserSettingsSuccess = usePrevious(isGetUserSettingsSuccess);
  const prevIsUpdateUserSettingsError = usePrevious(isUpdateUserSettingsError);
  const prevIsUpdateUserSettingsSuccess = usePrevious(isUpdateUserSettingsSuccess);

  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [settings, setSettings] = useState({});

  useEffect(() => {
    dispatch(getUserSettingsRequest());
    setLoading(true);
  }, []);

  // Handle Get User Settings Success
  useEffect(() => {
    if (prevIsGetUserSettingsSuccess === false && isGetUserSettingsSuccess) {
      const userSettingsCopy = { ...userSettings };
      setSettings(userSettingsCopy);
      setLoading(false);
    }
  }, [isGetUserSettingsSuccess]);

  // Handle Get User Settings Error
  useEffect(() => {
    if (prevIsGetUserSettingsError === false && isGetUserSettingsError) {
      snackBarAlert(true, getUserSettingsErrorMessage, 'error');
    }
  }, [isGetUserSettingsError]);

  // Handle Update User Settings Success
  useEffect(() => {
    if (prevIsUpdateUserSettingsSuccess === false && isUpdateUserSettingsSuccess) {
      snackBarAlert(true, i18n.t('UpdatedSuccess'), 'success');
    }
  }, [isUpdateUserSettingsSuccess]);

  // Handle Update User Settings Error
  useEffect(() => {
    if (prevIsUpdateUserSettingsError === false && isUpdateUserSettingsError) {
      snackBarAlert(true, updateUserSettingsErrorMessage, 'error');
    }
  }, [isUpdateUserSettingsError]);

  const handleChangeSettings = (event, notificationType) => {
    const { name, checked } = event.target;
    const settingsCopy = { ...settings };
    if (checked) {
      settingsCopy.notifications[notificationType].push(name);
    } else {
      const notificationIndex = settingsCopy.notifications[notificationType].findIndex((item) => item === name);
      settingsCopy.notifications[notificationType].splice(notificationIndex, 1);
    }

    setSettings(settingsCopy);
    dispatch(updateUserSettingsRequest({ settings: settingsCopy }));
  };

  const handleChange = (e, value, type) => {
    const { name } = e.target;
    const settingsCopy = { ...settings };
    settingsCopy[type][name] = value;
    setSettings(settingsCopy);
    dispatch(updateUserSettingsRequest({ settings: settingsCopy }));
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  return (
    <>
      <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
            <Box display="flex" className="tabs-navbar">
              <Box alignSelf="center" className="divider-title">
                <Typography variant="h6" noWrap>
                  <Box display="flex" alignItems="center" height="100%">
                    My Settings
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box p="32px">
        {!loading ? (
          <Grid container spacing={4} className="settings-box">
            <Grid item xs={12} md={6}>
              <Box className="settings-calendarBox">
                <Box mb="16px">
                  <Typography variant="subtitle1" className={classes.titleCalendar}>
                  Calendar
                  </Typography>
                  <Divider />
                </Box>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={4}>
                    <Typography>
                    Default View
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <RadioGroup
                      row
                      defaultValue="timeGridDay"
                      name="default_view"
                      value={settings.calendar.default_view}
                      onChange={(e) => handleChange(e, e.target.value, 'calendar')}
                    >
                      <FormControlLabel
                        value="timeGridDay"
                        control={<Radio size="small" color="primary" />}
                        label="Daily"
                      />
                      <FormControlLabel
                        value="timeGridWeek"
                        control={<Radio size="small" color="primary" />}
                        label="Weekly"
                      />
                      <FormControlLabel
                        value="dayGridMonth"
                        control={<Radio size="small" color="primary" />}
                        label="Monthly"
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={4}>
                    <Typography>
                    Calendar Slot duration
                    </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl>
                      <Select
                        value={settings.calendar.slot}
                        onChange={(e) => handleChange(e, e.target.value, 'calendar')}
                        name="slot"
                      >
                        {slotDurations.map((item) => (
                          <MenuItem value={item}>{item} minutes</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <TableContainer className={classes.settingsTable}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {/* {tableHeadReminder.map((item) => ( */}
                      {/*  <TableCell>{item}</TableCell> */}
                      {/* ))} */}
                    </TableRow>
                  </TableHead>
                  {/* <TableBody> */}
                  {/*  <TableRow> */}
                  {/*    <TableCell component="th" scope="row"> */}
                  {/*      Remind Customer */}
                  {/*    </TableCell> */}
                  {/*    <TableCell> */}
                  {/*      <Switch */}
                  {/*        size="small" */}
                  {/*        // checked={settings.notifications[keyName].includes('notification')} */}
                  {/*        // onChange={(event) => handleChangeSettings(event, keyName)} */}
                  {/*        color="primary" */}
                  {/*        name="notification" */}
                  {/*        inputProps={{ 'aria-label': 'primary checkbox' }} */}
                  {/*      /> */}
                  {/*    </TableCell> */}
                  {/*    <TableCell> */}
                  {/*      <Switch */}
                  {/*        size="small" */}
                  {/*        // checked={settings.notifications[keyName].includes('notification')} */}
                  {/*        // onChange={(event) => handleChangeSettings(event, keyName)} */}
                  {/*        color="primary" */}
                  {/*        name="notification" */}
                  {/*        inputProps={{ 'aria-label': 'primary checkbox' }} */}
                  {/*      /> */}
                  {/*    </TableCell> */}
                  {/*  </TableRow> */}
                  {/* </TableBody> */}
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <TableContainer className={classes.settingsTable}>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {tableHeadCustomer.map((item) => (
                        <TableCell>{item}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(settings.notifications).map((keyName) => (
                      <TableRow>
                        <TableCell component="th" scope="row" className={classes.notificationTableTh}>
                          {notificationsType[keyName]}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            size="small"
                            checked={settings.notifications[keyName].includes('notification')}
                            onChange={(event) => handleChangeSettings(event, keyName)}
                            color="primary"
                            name="notification"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            size="small"
                            checked={settings.notifications[keyName].includes('email')}
                            onChange={(event) => handleChangeSettings(event, keyName)}
                            color="primary"
                            name="email"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {/* {notificationsType.map((item) => ( */}
                    {/*  <TableRow> */}
                    {/*    <TableCell component="th" scope="row"> */}
                    {/*      {item} */}
                    {/*    </TableCell> */}
                    {/*    <TableCell align="center"> */}
                    {/*      <Switch */}
                    {/*        size="small" */}
                    {/*      // checked={settings.notifications[keyName].includes('notification')} */}
                    {/*      // onChange={(event) => handleChangeSettings(event, keyName)} */}
                    {/*        color="primary" */}
                    {/*        name="notification" */}
                    {/*        inputProps={{ 'aria-label': 'primary checkbox' }} */}
                    {/*      /> */}
                    {/*    </TableCell> */}
                    {/*    <TableCell align="center"> */}
                    {/*      <Switch */}
                    {/*        size="small" */}
                    {/*      // checked={settings.notifications[keyName].includes('notification')} */}
                    {/*      // onChange={(event) => handleChangeSettings(event, keyName)} */}
                    {/*        color="primary" */}
                    {/*        name="notification" */}
                    {/*        inputProps={{ 'aria-label': 'primary checkbox' }} */}
                    {/*      /> */}
                    {/*    </TableCell> */}
                    {/*  </TableRow> */}
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        ) : (
          <SettingsLoading />
        )}
      </Box>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

export default Settings;
