import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import i18n from 'i18next';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import {
  getAppointmentByIdRequest,
  getAllStatusesRequest,
  updateAppointmentRequest,
} from '../../../../../../redux/appointment/actions';
import InfoLoading from '../../../../../../Components/Loading/infoLoading';
import {
  Info,
  History,
} from './tabs';
import {
  getIndividualUserIndustryByIdRequest,
} from '../../../../../../redux/individualUser/actions';
import validateAppointmentTimeForIndividual from '../../../../../../Modules/validateAppoointmentTimes';
import SnackbarToast from '../../../../../../Modules/SnackbarToast';

function AppointmentDetails(props) {
  const {
    getAppointmentById,
    isGetAppointmentByIdSuccess,
    isGetAppointmentByIdError,
    returnedAppointment,
    getAllStatuses,
    isGetAllStatusesSuccess,
    isGetAllStatusesError,
    allStatuses,
    updateAppointment,
    isUpdateAppointmentSuccess,
    updatedAppointment,
  } = props;
  const history = useHistory();
  const params = useParams();

  const prevIsGetAppointmentByIdSuccess = usePrevious(isGetAppointmentByIdSuccess);
  const prevIsGetAppointmentByIdError = usePrevious(isGetAppointmentByIdError);
  const prevIsGetAllStatusesSuccess = usePrevious(isGetAllStatusesSuccess);
  const prevIsGetAllStatusesError = usePrevious(isGetAllStatusesError);
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);

  const [loading, setLoading] = useState(true);
  const [appointment, setAppointment] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [appointmentItem, setAppointmentItem] = useState({});
  const [statusOptions] = useState([]);
  const [, setSelectedStatusOption] = useState({});
  const [industry, setIndustry] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Perform, when update appointment success
  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      const time = moment(updatedAppointment.start_time, 'HH:mm:ss');
      setAppointmentItem({
        ...updatedAppointment,
        day: new Date(updatedAppointment.date),
        time,
        day_time: `${updatedAppointment.date} ${updatedAppointment.start_time}:00`,
      });
      // TODO check functionality appointment update
      setAppointment({
        ...updatedAppointment,
        day: new Date(updatedAppointment.date),
        time,
        day_time: `${updatedAppointment.date} ${updatedAppointment.start_time}:00`,
      });
      const option = statusOptions.find((item) => item.value === parseInt(updatedAppointment.status_id));
      setSelectedStatusOption(option);
      setIsButtonDisabled(false);
      setLoading(false);
      snackBarAlert(true, i18n.t('AppointmentUpdated'), 'success');
    }
  }, [isUpdateAppointmentSuccess]);

  // Perform, when function gets mounted
  useEffect(() => {
    const { appointmentId } = params;
    getAppointmentById(appointmentId);
  }, []);

  // Perform, when route param appointmentId gets changed
  useEffect(() => {
    const { id } = appointment;
    const { appointmentId } = params;
    if (id && id !== parseInt(appointmentId)) {
      setLoading(true);
      getAppointmentById(appointmentId);
    }
  }, [params]);

  // Perform, when get appointment by id success
  useEffect(() => {
    if (prevIsGetAppointmentByIdSuccess === false && isGetAppointmentByIdSuccess) {
      setAppointment(returnedAppointment);
      getAllStatuses();
    }
  }, [isGetAppointmentByIdSuccess]);
  // Perform, when get appointment by id error
  useEffect(() => {
    if (prevIsGetAppointmentByIdError === false && isGetAppointmentByIdError) {
      snackBarAlert(true, i18n.t('AppointmentNotFound'), 'error');
      history.push('/calendar');
    }
  }, [isGetAppointmentByIdError]);

  // Perform, when get appointment all statuses success
  useEffect(() => {
    if (prevIsGetAllStatusesSuccess === false && isGetAllStatusesSuccess) {
      setLoading(false);
    }
  }, [isGetAllStatusesSuccess]);

  // Perform, when get appointment all statuses error
  useEffect(() => {
    if (prevIsGetAllStatusesError === false && isGetAllStatusesError) {
      setLoading(false);
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetAllStatusesError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleChangeTab = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const handleSaveAppointment = () => {
    if (validateAppointmentTimeForIndividual({ ...industry }, { ...appointmentItem })) {
      setLoading(true);
      setIsButtonDisabled(true);
      updateAppointment({
        ...appointmentItem,
      });
    }
  };

  const isAppointmentCancelled = () => (appointment && appointment.status && appointment.status.name === 'cancelled');

  return (
    <>
      <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
            <Box display="flex" className="tabs-navbar">
              <Box alignSelf="center" className="divider-title">
                <Typography variant="h6">
                  { appointment.title && appointment.title.length ? appointment.title : 'Appointment' }
                </Typography>
              </Box>
              <Box alignSelf="center" ml="-18px" mr="6px">
                <Link to="/my-appointments">
                  <IconButton size="small">
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Link>
              </Box>
              <Box alignSelf="center" className="tab-companies">
                <AppBar position="static" color="white" elevation={0}>
                  <Tabs
                    value={activeTabIndex}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="secondary"
                  >
                    <Tab
                      label="Info"
                      onClick={() => handleChangeTab(0)}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="History"
                      onClick={() => handleChangeTab(1)}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </AppBar>
              </Box>
            </Box>
            { activeTabIndex === 0 && (
            <Box align="right">
              <Button
                size="small"
                type="button"
                variant="contained"
                color="primary"
                onClick={handleSaveAppointment}
                disabled={isButtonDisabled || isAppointmentCancelled()}
              >
                Save
              </Button>
            </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {!loading ? (
        <>
          <div className="companies menu-item">
            <Box className="tab-list companies-tab-list">
              {activeTabIndex === 0 && (
                <Info
                  appointment={appointment}
                  allStatuses={allStatuses}
                  setIndustry={setIndustry}
                  setAppointmentItem={setAppointmentItem}
                  appointmentItem={appointmentItem}
                  isAppointmentCancelled={isAppointmentCancelled()}
                  setIsButtonDisabled={setIsButtonDisabled}
                />
              )}
              {activeTabIndex === 1 && (
                <History
                  history={appointment.history}
                />
              )}
            </Box>
          </div>
          <SnackbarToast
            message={snackbarMessage}
            type={snackbarType}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
          />
        </>
      ) : <InfoLoading />}
    </>
  );
}

AppointmentDetails.propTypes = {
  getAppointmentById: PropTypes.func.isRequired,
  isGetAppointmentByIdSuccess: PropTypes.bool.isRequired,
  isGetAppointmentByIdError: PropTypes.bool.isRequired,
  returnedAppointment: PropTypes.object.isRequired,
  getAllStatuses: PropTypes.func.isRequired,
  isGetAllStatusesSuccess: PropTypes.bool.isRequired,
  isGetAllStatusesError: PropTypes.bool.isRequired,
  allStatuses: PropTypes.array.isRequired,
  appointment: PropTypes.object.isRequired,
  isAppointmentCancelled: PropTypes.bool.isRequired,
  updateAppointment: PropTypes.func.isRequired,
  isUpdateAppointmentSuccess: PropTypes.bool.isRequired,
  isUpdateAppointmentError: PropTypes.bool.isRequired,
  updatedAppointment: PropTypes.object.isRequired,
  updateAppointmentErrorMessage: PropTypes.string.isRequired,
  getIndustryById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  // Get appointment by id
  isGetAppointmentByIdSuccess: state.appointment.isGetAppointmentByIdSuccess,
  isGetAppointmentByIdError: state.appointment.isGetAppointmentByIdError,
  returnedAppointment: state.appointment.returnedAppointment,
  // Get all appointment statuses
  isGetAllStatusesSuccess: state.appointment.isGetAllStatusesSuccess,
  isGetAllStatusesError: state.appointment.isGetAllStatusesError,
  allStatuses: state.appointment.allStatuses,
  isUpdateAppointmentSuccess: state.appointment.isUpdateAppointmentSuccess,
  isUpdateAppointmentError: state.appointment.isUpdateAppointmentError,
  updatedAppointment: state.appointment.updatedAppointment,
});

function mapDispatchToProps(dispatch) {
  return {
    updateAppointment: (data) => dispatch(updateAppointmentRequest(data)),
    getIndustryById: (data) => dispatch(getIndividualUserIndustryByIdRequest(data)),
    getAppointmentById: (data) => dispatch(getAppointmentByIdRequest(data)),
    getAllStatuses: () => dispatch(getAllStatusesRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails);
