import { all } from 'redux-saga/effects';
import authSaga from './auth/sagas';
import industrySaga from './industry/sagas';
import companySaga from './company/sagas';
import accountSaga from './account/sagas';
import businessHoursSaga from './businnessHours/sagas';
import staffSaga from './staff/sagas';
import serviceSaga from './service/sagas';
import appointmentSaga from './appointment/sagas';
import messageSaga from './message/sagas';
import historySaga from './history/sagas';
import countrySaga from './country/sagas';
import individualUserSaga from './individualUser/sagas';
import customerSaga from './customer/sagas';
import notificationSaga from './notification/sagas';
import changeLanguage from './translate/saga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    industrySaga(),
    companySaga(),
    accountSaga(),
    businessHoursSaga(),
    staffSaga(),
    serviceSaga(),
    appointmentSaga(),
    messageSaga(),
    historySaga(),
    countrySaga(),
    individualUserSaga(),
    customerSaga(),
    notificationSaga(),
    changeLanguage(),
  ]);
}
