import React from 'react';
import { Grid, Box } from '@material-ui/core';
import { TextRow } from 'react-placeholder/lib/placeholders';

function SettingsLoading() {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={6}>
        <Box height="188px">
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
        </Box>
        <Box height="285px" mt="32px">
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box height="495px">
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
          <TextRow color="#E0E0E0" />
        </Box>
      </Grid>
    </Grid>
  );
}
export default SettingsLoading;
