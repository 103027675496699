import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import '../details.scss';
import {
  Accordion,
  AccordionDetails,
  Box,
  IconButton,
  Typography,
  AccordionSummary,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
import {
  getIndustryServicesRequest,
  getServicesByIndividualUserIdRequest,
} from '../../../../../../../redux/service/actions';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import noService from '../../../../../../../assets/img/no-service.svg';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';
import NewCategoryModal from '../../../../../../../Modals/IndividualUserIndustry/NewCategoryModal';
import servicesLogo from '../../../../../../../assets/servicesLogo';
import ServicesIsolatedDropdown from '../../../../Owner/Companies/details/tabs/Services/servicesIsolatedDropdown';
import ServiceItem from './Services/serviceItem';
import DeleteServiceModal from '../../../../../../../Modals/IndividualUserIndustry/DeleteServiceModal';
import { checkAppointmentsByServiceIdRequest } from '../../../../../../../redux/appointment/actions';

function Services(props) {
  const dispatch = useDispatch();

  const {
    // Get Industry Services
    isGetIndustryServicesSuccess,
    services,
    // Get Individual User Services
    isGetServicesByIndividualUserIdSuccess,
    isGetServicesByIndividualUserIdError,
    servicesByIndividualUserId,
    getServicesByIndividualUserIdErrorMessage,
  } = useSelector((state) => state.service);

  const {
    isCheckAppointmentByServiceIdSuccess,
    appointments,
  } = useSelector((state) => state.appointment);

  const {
    individualUserIndustryItem,
    openNewCategoryModal,
    handleCloseNewCategoryModal,
  } = props;

  // Get some props values
  const prevIsGetIndustryServicesSuccess = usePrevious(isGetIndustryServicesSuccess);
  const prevIsGetServicesByIndividualUserIdSuccess = usePrevious(isGetServicesByIndividualUserIdSuccess);
  const prevIsGetServicesByIndividualUserIdError = usePrevious(isGetServicesByIndividualUserIdError);
  const prevIsCheckAppointmentByServiceIdSuccess = usePrevious(isCheckAppointmentByServiceIdSuccess);

  const [individualUserServices, setIndividualUserServices] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedIndividualUserServiceId, setSelectedIndividualUserServiceId] = useState(0);
  const [selectedServiceParentId, setSelectedServiceParentId] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [openDeleteServiceModal, setOpenDeleteServiceModal] = useState(false);
  const [serviceIds, setServiceIds] = useState([]);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [serviceData, setServiceData] = useState({
    service_id: '',
    duration: 0,
    price: '',
    individual_user_industry_id: '',
    name: '',
  });
  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState('');

  useEffect(() => {
    if (typeof individualUserIndustryItem !== 'undefined') {
      dispatch(getIndustryServicesRequest({
        industryId: individualUserIndustryItem.industry_id,
      }));
      dispatch(getServicesByIndividualUserIdRequest({
        industryId: individualUserIndustryItem.industry_id,
        individualUserIndustryId: individualUserIndustryItem.id,
      }));

      individualUserIndustryItem.appointments.map((item) => {
        services.push(item.service_id);
      });

      setServiceData((prevValue) => ({
        ...prevValue,
        individual_user_industry_id: individualUserIndustryItem.id,
      }));
    }
  }, [individualUserIndustryItem]);

  useEffect(() => {
    if (prevIsGetIndustryServicesSuccess === false && isGetIndustryServicesSuccess) {
      const serviceOptions = [];
      services.map((service) => (
        serviceOptions.push({
          value: service.id,
          label: service.name,
        })
      ));
      setServiceOptions(serviceOptions);
    }
  }, [isGetIndustryServicesSuccess]);

  // Handle Get Services By Individual User Id Success
  useEffect(() => {
    if (prevIsGetServicesByIndividualUserIdSuccess === false && isGetServicesByIndividualUserIdSuccess) {
      setIndividualUserServices(servicesByIndividualUserId);
    }
  }, [isGetServicesByIndividualUserIdSuccess]);

  // Handle Get Services By Individual User Id Error
  useEffect(() => {
    if (prevIsGetServicesByIndividualUserIdError === false && isGetServicesByIndividualUserIdError) {
      snackBarAlert(true, getServicesByIndividualUserIdErrorMessage, 'error');
    }
  }, [isGetServicesByIndividualUserIdError]);

  // Check Appointments Success
  useEffect(() => {
    if (prevIsCheckAppointmentByServiceIdSuccess === false && isCheckAppointmentByServiceIdSuccess) {
      const filteredAppointments = appointments.filter((appointment) => appointment.status.id === 1 || appointment.status.id === 2);
      setFilteredAppointments(filteredAppointments);
    }
  }, [isCheckAppointmentByServiceIdSuccess]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleDeleteCategory = (e) => {
    e.stopPropagation();
    setExpanded(false);
    const individualUserService = individualUserServices.find((service) => service.id === selectedServiceId);
    const serviceIds = [];
    JSON.parse(individualUserService.subServices).map((subService) => {
      if (subService.individual_user_industry_service_id) {
        serviceIds.push(subService.individual_user_industry_service_id);
      }
    });

    setServiceIds(serviceIds);
    dispatch(checkAppointmentsByServiceIdRequest({
      individual_user_industry_id: individualUserIndustryItem.id,
      service_ids: serviceIds,
    }));
    setSelectedIndividualUserServiceId(0);
    setSelectedServiceParentId(selectedServiceId);
    setOpenDeleteServiceModal(true);
    setAnchorEl(false);
  };

  const handleAddServiceToStaff = (e, parentServiceId) => {
    e.stopPropagation();
    setExpanded(!expanded ? parentServiceId : expanded);
    const individualUserServicesCopy = [...individualUserServices];
    const individualUserService = individualUserServices.find((service) => service.id === parentServiceId);
    const individualUserServiceIndex = individualUserServices.findIndex((service) => service.id === parentServiceId);
    const subServices = JSON.parse(individualUserService.subServices);
    let createdSubServiceForm = true;
    subServices.map((item) => {
      if (!item.individual_user_industry_service_id) {
        createdSubServiceForm = false;
      }
    });
    if (createdSubServiceForm) {
      subServices.unshift(serviceData);
      individualUserService.subServices = JSON.stringify(subServices);
      if (!individualUserService.accordion) {
        individualUserService.accordion = !(individualUserService.accordion);
      }
      individualUserServicesCopy[individualUserServiceIndex] = individualUserService;
      setIndividualUserServices(individualUserServicesCopy);
    }
  };

  const toggleAccordion = (parentServiceId) => {
    const individualUserServicesCopy = [...individualUserServices];
    const individualUserService = individualUserServices.find((service) => service.id === parentServiceId);
    individualUserService.accordion = !(individualUserService.accordion);
    setIndividualUserServices(individualUserServicesCopy);
  };

  const useStyles = makeStyles(() => ({
    expanded: {
      margin: '0 0 !important',
    },
  }));

  const classes = useStyles();

  const handleChangeAccordion = (parentServiceId) => {
    setExpanded(!expanded ? parentServiceId : false);
  };

  return (
    <div id="service-tab">
      <>
        <Box className="box-general">
          <div className="main-content">
            {individualUserServices.length ? individualUserServices.map((service, index) => (
              <Box key={`${service.name}-${index}`} mt={4} className="accordion-box">
                <Accordion elevation={0} expanded={expanded === service.id} onChange={() => handleChangeAccordion(service.id)}>
                  <AccordionSummary
                    classes={{
                      expanded: classes.expanded,
                    }}
                    expandIcon={<ExpandMoreIcon onClick={() => toggleAccordion(service.id)} />}
                    className="card-header-service accordion-category"
                  >
                    <Box display="flex" alignItems="center" width="100%">
                      <Box mr="10px">
                        <img src={servicesLogo[service.name]} alt="serviceLogo" width="40px" />
                      </Box>
                      <Box width="inherit">
                        <Typography variant="h5">
                          {service.name}
                        </Typography>
                      </Box>

                      <Box display="flex" width="100%" justifyContent="flex-end">
                        <Box align="left">
                          <IconButton color="primary" className="addIcon-Service" onClick={(e) => handleAddServiceToStaff(e, service.id)}>
                            <AddIcon />
                          </IconButton>
                        </Box>
                        <Box>
                          <ServicesIsolatedDropdown
                            anchorEl={anchorEl}
                            setAnchorEl={setAnchorEl}
                            parentServiceId={service.id}
                            selectedServiceId={selectedServiceId}
                            setSelectedServiceId={setSelectedServiceId}
                            handleDeleteCategory={(e) => handleDeleteCategory(e)}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box className="card-box-root-service" width="100%">
                      <Box height="auto">
                        {JSON.parse(service.subServices).map((subService, index) => (
                          <Box className="service" key={`${subService.name}-${index}`}>
                            <ServiceItem
                              individualUserId={individualUserIndustryItem.id}
                              subService={subService}
                              serviceId={service.id}
                              setServiceIds={setServiceIds}
                              setSelectedIndividualUserServiceId={setSelectedIndividualUserServiceId}
                              setSelectedServiceParentId={setSelectedServiceParentId}
                              setOpenDeleteServiceModal={setOpenDeleteServiceModal}
                              individualUserServices={individualUserServices}
                              setIndividualUserServices={setIndividualUserServices}
                              services={services}
                              setFilteredAppointments={setFilteredAppointments}
                              industryId={individualUserIndustryItem.industry_id}
                            />
                          </Box>
                        ))}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )) : (
              <Box className="service-page" mx="auto">
                <Box>
                  <Box>
                    <img src={noService} alt="noCustomer" />
                  </Box>
                  <Typography component="div">
                    <Box fontWeight="500" align="center">
                      <Typography variant="h6" color="secondary">
                        No services yet
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            )}
          </div>
        </Box>

        {openNewCategoryModal && (
          <NewCategoryModal
            individualUserIndustryItem={individualUserIndustryItem}
            serviceOptions={serviceOptions}
            individualUserServices={individualUserServices}
            onClose={handleCloseNewCategoryModal}
          />
        )}

        <DeleteServiceModal
          open={openDeleteServiceModal}
          onClose={() => setOpenDeleteServiceModal(false)}
          individualUserServices={individualUserServices}
          setIndividualUserServices={setIndividualUserServices}
          selectedIndividualUserServiceId={selectedIndividualUserServiceId}
          setSelectedIndividualUserServiceId={setSelectedIndividualUserServiceId}
          serviceIds={serviceIds}
          setServiceIds={setServiceIds}
          selectedIndividualParentId={selectedServiceParentId}
          setSelectedIndividualParentId={setSelectedServiceParentId}
          // servicesByCompanyId={servicesByCompanyId}
          individualUserId={individualUserIndustryItem.id}
          filteredAppointments={filteredAppointments}
        />

        <SnackbarToast
          message={snackbarMessage}
          type={snackbarType}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
        />
      </>
    </div>
  );
}

Services.propTypes = {
  // Get Props From Parent
  individualUserIndustryItem: PropTypes.object.isRequired,
  openNewCategoryModal: PropTypes.bool.isRequired,
  handleCloseNewCategoryModal: PropTypes.func.isRequired,
};

export default Services;
