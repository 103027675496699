import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  Button,
  Typography,
} from '@material-ui/core';
import i18n from 'i18next';
import DeleteButton from '../../MaterialComponents/deleteButton';

function DeleteAppointment(props) {
  const {
    open,
    cancel,
    confirm,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={cancel}
    >
      <DialogTitle id="customized-dialog-title" onClose={cancel}>
        <span className="alert-title">
          <span>{i18n.t('ConfirmDeletion')}</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={cancel}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {i18n.t('AppointmentDeleteQuestion')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={cancel}
        >
          {i18n.t('Cancel')}
        </Button>
        <DeleteButton
          size="small"
          variant="contained"
          color="primary"
          onClick={confirm}
        >
          {i18n.t('Delete')}
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
}

DeleteAppointment.propTypes = {
  open: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
};

export default DeleteAppointment;
