import { createAction } from 'redux-actions';

export const getIndustryServicesRequest = createAction('GET_INDUSTRY_SERVICES_REQUEST');
export const getIndustryServicesSuccess = createAction('GET_INDUSTRY_SERVICES_SUCCESS');
export const getIndustryServicesError = createAction('GET_INDUSTRY_SERVICES_ERROR');

export const addServiceRequest = createAction('ADD_SERVICE_REQUEST');
export const addServiceSuccess = createAction('ADD_SERVICE_SUCCESS');
export const addServiceError = createAction('ADD_SERVICE_ERROR');

export const deleteServiceRequest = createAction('DELETE_SERVICE_REQUEST');
export const deleteServiceSuccess = createAction('DELETE_SERVICE_SUCCESS');
export const deleteServiceError = createAction('DELETE_SERVICE_ERROR');

export const deleteServiceByIdRequest = createAction('DELETE_SERVICE_BY_ID_REQUEST');
export const deleteServiceByIdSuccess = createAction('DELETE_SERVICE_BY_ID_SUCCESS');
export const deleteServiceByIdError = createAction('DELETE_SERVICE_BY_ID_ERROR');

export const updateServiceRequest = createAction('UPDATE_SERVICE_REQUEST');
export const updateServiceSuccess = createAction('UPDATE_SERVICE_SUCCESS');
export const updateServiceError = createAction('UPDATE_SERVICE_ERROR');

export const detachStaffServiceRequest = createAction('DETACH_STAFF_SERVICE_REQUEST');
export const detachStaffServiceSuccess = createAction('DETACH_STAFF_SERVICE_SUCCESS');
export const detachStaffServiceError = createAction('DETACH_STAFF_SERVICE_ERROR');

export const getByIndustryIdRequest = createAction('GET_BY_INDUSTRY_ID_REQUEST');
export const getByIndustryIdSuccess = createAction('GET_BY_INDUSTRY_ID_SUCCESS');
export const getByIndustryIdError = createAction('GET_BY_INDUSTRY_ID_ERROR');

export const getServiceByIdRequest = createAction('GET_SERVICE_BY_ID_REQUEST');
export const getServiceByIdSuccess = createAction('GET_SERVICE_BY_ID_SUCCESS');
export const getServiceByIdError = createAction('GET_SERVICE_BY_ID_ERROR');

export const getServicesByCompanyRequest = createAction('GET_SERVICES_BY_COMPANY_REQUEST');
export const getServicesByCompanySuccess = createAction('GET_SERVICES_BY_COMPANY_SUCCESS');
export const getServicesByCompanyError = createAction('GET_SERVICES_BY_COMPANY_ERROR');

export const deleteCompanyServiceRequest = createAction('DELETE_COMPANY_SERVICE_REQUEST');
export const deleteCompanyServiceSuccess = createAction('DELETE_COMPANY_SERVICE_SUCCESS');
export const deleteCompanyServiceError = createAction('DELETE_COMPANY_SERVICE_ERROR');

export const updateCompanyServiceRequest = createAction('UPDATE_COMPANY_SERVICE_REQUEST');
export const updateCompanyServiceSuccess = createAction('UPDATE_COMPANY_SERVICE_SUCCESS');
export const updateCompanyServiceError = createAction('UPDATE_COMPANY_SERVICE_ERROR');

export const getCompanyServicesWithSubServicesRequest = createAction('GET_COMPANY_SERVICES_WITH_SUB_SERVICES_REQUEST');
export const getCompanyServicesWithSubServicesSuccess = createAction('GET_COMPANY_SERVICES_WITH_SUB_SERVICES_SUCCESS');
export const getCompanyServicesWithSubServicesError = createAction('GET_COMPANY_SERVICES_WITH_SUB_SERVICES_ERROR');

export const getServicesByIndividualUserIdRequest = createAction('GET_SERVICES_BY_INDIVIDUAL_USER_ID_REQUEST');
export const getServicesByIndividualUserIdSuccess = createAction('GET_SERVICES_BY_INDIVIDUAL_USER_ID_SUCCESS');
export const getServicesByIndividualUserIdError = createAction('GET_SERVICES_BY_INDIVIDUAL_USER_ID_ERROR');

export const updateIndividualUserServiceRequest = createAction('UPDATE_INDIVIDUAL_USER_SERVICE_REQUEST');
export const updateIndividualUserServiceSuccess = createAction('UPDATE_INDIVIDUAL_USER_SERVICE_SUCCESS');
export const updateIndividualUserServiceError = createAction('UPDATE_INDIVIDUAL_USER_SERVICE_ERROR');

export const deleteIndividualUserServiceRequest = createAction('DELETE_INDIVIDUAL_USER_SERVICE_REQUEST');
export const deleteIndividualUserServiceSuccess = createAction('DELETE_INDIVIDUAL_USER_SERVICE_SUCCESS');
export const deleteIndividualUserServiceError = createAction('DELETE_INDIVIDUAL_USER_SERVICE_ERROR');

export const getServiceWithStaffRequest = createAction('GET_SERVICE_WITH_STAFF_REQUEST');
export const getServiceWithStaffSuccess = createAction('GET_SERVICE_WITH_STAFF_SUCCESS');
export const getServiceWithStaffError = createAction('GET_SERVICE_WITH_STAFF_ERROR');

export const getIndividualServicesWithSubServicesRequest = createAction('GET_INDIVIDUAL_SERVICES_WITH_SUB_SERVICES_REQUEST');
export const getIndividualServicesWithSubServicesSuccess = createAction('GET_INDIVIDUAL_SERVICES_WITH_SUB_SERVICES_SUCCESS');
export const getIndividualServicesWithSubServicesError = createAction('GET_INDIVIDUAL_SERVICES_WITH_SUB_SERVICES_ERROR');

export const addCustomServiceRequest = createAction('ADD_CUSTOM_SERVICE_REQUEST');
export const addCustomServiceSuccess = createAction('ADD_CUSTOM_SERVICE_SUCCESS');
export const addCustomServiceError = createAction('ADD_CUSTOM_SERVICE_ERROR');
