import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import {
  Grow,
  Paper,
  Popper,
  MenuList,
  Box,
  MenuItem,
  IconButton,
  FormControl,
  Select,
  ClickAwayListener,
  InputLabel, Avatar,
} from '@material-ui/core';
import {
  ExitToApp as ExitToAppIcon,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@material-ui/icons/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { languages } from 'Modules/constants';
import '../../i18n/i18n';
import flag from 'assets/flags/flag';
import { changeLanguageRequest } from 'redux/translate/action';
import defaultCompanyLogo from 'assets/img/company.svg';
import SwitchToAccount from '../Account/switchToAccount';
import Notifications from '../Notifications/index';
import { resetRequest } from '../../redux/auth/actions';
import usePrevious from '../../CustomHooks/usePrevious';

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: 300,
  },
}));

const TopBar = (props) => {
  const {
    account,
    notifications,
    notificationsCount,
    handleSwitchToAccountProps,
    handleDrawerToggle,
    userLocationInfo,
  } = props;

  const { companyGot } = useSelector((state) => state.company);
  const { 
    isGetUserAccountNotifications,
    isGetUserAccountNotificationsSuccess,
    isGetUserAccountNotificationsError,
  } = useSelector((state) => state.account);

  const [isOpenUserMenu, setIsOpenUserMenu] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [loading, setLoading] = useState(true);

  const prevIsGetUserAccountNotificationsSuccess = usePrevious(isGetUserAccountNotificationsSuccess);
  const prevIsGetUserAccountNotificationsError = usePrevious(isGetUserAccountNotificationsError);

  const anchorRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  // Translation
  const { t, i18n } = useTranslation();

  const classes = useStyles();

  let switchCustomerAccount = 0;
  let switchOwnerAccount = 0;
  let switchStaffAccount = 0;
  let switchIndividualAccount = 0;
  let isBusinessAccount = false;
  const prevOpen = useRef(isOpenUserMenu);

  useEffect(() => {
    const language = localStorage.getItem('i18nextLng');
    setSelectedLanguage(language);
  }, []);

  useEffect(() => {
    if (prevOpen.current === true && isOpenUserMenu === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = isOpenUserMenu;
  }, [isOpenUserMenu]);

  useEffect(() => {
    if (prevIsGetUserAccountNotificationsSuccess === false && isGetUserAccountNotificationsSuccess) {
      setLoading(false);
    }
  }, [isGetUserAccountNotificationsSuccess]);

  useEffect(() => {
    if (prevIsGetUserAccountNotificationsError === false && isGetUserAccountNotificationsError) {
      setLoading(false);
    }
  }, [isGetUserAccountNotificationsError]);

  useEffect(() => {

  }, [isGetUserAccountNotifications]);

  const handleLogOut = async () => {
    const { handleLogout } = props;
    dispatch(resetRequest());
    await handleLogout();
  };

  if (account.permissions.length) {
    const permissions = JSON.parse(account.permissions[0].permissions).common_access;
    // Permission to switch to customer account
    if (permissions.switch_customer_account && permissions.switch_customer_account.status === 1) {
      switchCustomerAccount = permissions.switch_customer_account.status;
    }
    // Permission to switch to staff account
    if (permissions.switch_staff_account && permissions.switch_staff_account.status === 1) {
      switchStaffAccount = permissions.switch_staff_account.status;
    }
    // Permission to switch to owner account
    if (permissions.switch_owner_account && permissions.switch_owner_account.status === 1) {
      switchOwnerAccount = permissions.switch_owner_account.status;
    }
    // Permission to switch to individual account
    if (permissions.switch_individual_account && permissions.switch_individual_account.status === 1) {
      switchIndividualAccount = permissions.switch_individual_account.status;
    }
  }

  if (account && account.activeRole && account.activeRole.name === 'Owner') {
    isBusinessAccount = true;
  }

  const toggleUserMenu = () => {
    setIsOpenUserMenu((prevOpen) => !prevOpen);
  };

  const closeUserMenu = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setIsOpenUserMenu(false);
  };

  const handleChangeLanguage = (lng) => {
    setLoading(true);
    const { value } = lng.target;
    i18n.changeLanguage(value).then((r) => r);
    setSelectedLanguage(value);
    dispatch(changeLanguageRequest({ lang: value }));
  };

  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box>
        <IconButton
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
        >
          <MenuIcon fill="#393939" />
        </IconButton>
      </Box>
      <Box className="topBar-items">
        {userLocationInfo && userLocationInfo.real_country === 'Armenia' && (
          <div>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
            >
              <InputLabel htmlFor="open-select" />
              <Select
                onChange={handleChangeLanguage}
                value={selectedLanguage}
                disableUnderline
                inputProps={{
                  id: 'open-select',
                }}
                disabled={loading}
                variant="standard"
                style={{ boxShadow: 'none', border: 0 }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  style: { zIndex: 99999, marginTop: 10 },
                  getContentAnchorEl: null,
                  classes: { paper: classes.menuPaper },
                }}
                name="country"
              >
                {languages && languages.map((option, index) => (
                  <MenuItem
                    key={`${option.id}-${index}`}
                    value={option.value}
                    selected={selectedLanguage === option.label}
                  >
                    <img
                      src={flag[option.value]}
                      alt="flag"
                      className="country-flag"
                    />
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <div>
          <Notifications
            account={account}
            userNotifications={notifications}
            notificationsCount={notificationsCount}
            history={history}
          />
        </div>
        <div>
          <IconButton
            ref={anchorRef}
            aria-controls={isOpenUserMenu ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={toggleUserMenu}
            className="company-avatar-btn"
          >
            <Avatar className="topBar-company-avatar" src={companyGot.logo ? companyGot.logo : defaultCompanyLogo} />
          </IconButton>
          <Popper open={isOpenUserMenu} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'left top' : 'left bottom',
                  maxWidth: '250px',
                  width: '100px',
                  minWidth: '250px',
                  position: 'absolute',
                  right: '-28px',
                }}
              >
                <Paper className="topBar-account-paper">
                  <ClickAwayListener onClickAway={closeUserMenu}>
                    <MenuList className="topBar-account-box">
                      {
                        <div onClick={closeUserMenu}>
                          {
                            (!!switchCustomerAccount
                                || !!switchStaffAccount
                                || !!switchIndividualAccount)
                            && isBusinessAccount
                            && (
                            <SwitchToAccount
                              handleSwitchToAccountProps={(data) => handleSwitchToAccountProps(data)}
                              switchRoleName="Personal"
                            />
                            )
                          }
                          { !!switchOwnerAccount && !isBusinessAccount && (
                          <SwitchToAccount
                            handleSwitchToAccountProps={(data) => handleSwitchToAccountProps(data)}
                            switchRoleName="Business"
                          />
                          )}
                        </div>
                    }
                      {/* <MenuItem onClick={() => { history.push('/profile'); }}> */}
                      {/*  <Box display="flex"> */}
                      {/*    <Box mr="10px"> */}
                      {/*      <AccountBoxIcon className="settings-icon" fontSize="small" /> */}
                      {/*    </Box> */}
                      {/*    <Box alignSelf="center"> */}
                      {/*      {t('MyProfile')} */}
                      {/*    </Box> */}
                      {/*  </Box> */}
                      {/* </MenuItem> */}
                      {/* <MenuItem onClick={() => handleMyCompany()}> */}
                      {/*  <Box display="flex"> */}
                      {/*    <Box mr="10px"> */}
                      {/*      <BusinessIcon className="settings-icon" fontSize="small" /> */}
                      {/*    </Box> */}
                      {/*    <Box alignSelf="center"> */}
                      {/*      {t('MyCompany')} */}
                      {/*    </Box> */}
                      {/*  </Box> */}
                      {/* </MenuItem> */}
                      <MenuItem className="detail-menu-item">
                        <Box>
                          <p className="company-name">
                            {companyGot.name}
                          </p>
                          <p className="company-industry">
                            {companyGot.industry_name}
                          </p>
                        </Box>
                      </MenuItem>
                      <MenuItem onClick={() => handleLogOut()} className="logOut-menu-item">
                        <Box display="flex">
                          <Box mr="10px">
                            <ExitToAppIcon className="settings-icon" fontSize="small" />
                          </Box>
                          <Box alignSelf="center">
                            {t('Logout')}
                          </Box>
                        </Box>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </Box>
    </Box>
  );
};

TopBar.propTypes = {
  handleLogout: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
  notifications: PropTypes.array.isRequired,
  notificationsCount: PropTypes.number.isRequired,
  handleDrawerToggle: PropTypes.func.isRequired,
  userLocationInfo: PropTypes.object.isRequired,
  // Create Account Props Handler
  handleCreateAccountProps: PropTypes.func.isRequired,
  // Switch To Account Props Handler
  handleSwitchToAccountProps: PropTypes.func.isRequired,
};

export default TopBar;
