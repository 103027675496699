import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Paper,
  Dialog,
  AppBar,
  TableCell,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Table,
  Button,
  Toolbar,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  Typography,
  TableContainer,
  TablePagination,
} from '@material-ui/core';
import {
  TuneRounded as TuneRoundedIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Avatar from '@material-ui/core/Avatar';

import usePrevious from '../../../../../CustomHooks/usePrevious';
import capitalize from '../../../../../Modules/capitalize';
import FilterContent from './FilterContent';
import filterItems from '../../../../../Modules/AppointmentsFilterItems/personalAppointments';
import AppointmentPreview from '../../../../../Modals/AppointmentPreview';
import noAppointment from '../../../../../assets/img/no-appointment.svg';
import SnackbarToast from '../../../../../Modules/SnackbarToast';
import AppointmentsLoading from '../../../../../Components/Loading/appointmentsLoading';
import firstLetter from '../../../../../Modules/getFirstLetter';
import {
  acceptRequest,
  deleteAppointmentRequest,
  getAppointmentsRequest,
} from '../../../../../redux/appointment/actions';
import PermissionsModule from '../../../../../Modules/Permission';
import CreateNewAppointmentModal from '../../../../../Modals/NewAppointment';
import AppointmentActionsModal from '../../../../../Modals/AppointmentActions';
import { filterCustomerAppointmentsByDateRequest } from '../../../../../redux/customer/actions';
import '../../../../../style/appointments.scss';

const useStyles = makeStyles({
  table: {
    minWidth: 320,
  },
  paginationTable: {
    marginTop: 15,
  },
  itemsImg: {
    width: 30,
    height: 30,
    verticalAlign: 'middle',
    marginRight: 10,
    borderRadius: '50%',
    objectFit: 'cover',
  },
  avatarAppointments: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  avatarLettersAppointments: {
    width: 30,
    height: 30,
    marginRight: 10,
    fontSize: 13,
  },
  tableCell: {
    cursor: 'pointer',
  },
});

function MyAppointments(props) {
  const {
    account,
    history,
    // isAcceptSuccess,
    allAppointments,
    getAppointments,
    deleteAppointment,
    isGetAppointmentsError,
    isDeleteAppointmentError,
    isGetAppointmentsSuccess,
    isDeleteAppointmentSuccess,
    deleteAppointmentErrorMessage,
    filterCustomerAppointmentsByDate,
    filteredCustomerAppointmentsByDate,
    isFilterCustomerAppointmentsByDateSuccess,
  } = props;

  const TODAY = moment().format('YYYY-MM-DD');
  const TOMORROW = moment().add(1, 'days').format('YYYY-MM-DD');

  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [tableHeader, setTableHeader] = useState();
  const [page, setPage] = React.useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterData] = useState(filterItems);
  const [appointments, setAppointments] = useState([]);
  const [snackbarType, setSnackbarType] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isPreviewShown, setIsPreviewShown] = useState(false);
  const [saveAppointment, setSaveAppointment] = useState(() => {});
  const [isCreateOrUpdateAppointmentPerformed, setIsCreateOrUpdateAppointmentPerformed] = useState(false);
  const [appointmentItem, setAppointmentItem] = useState({});
  const [checkedItems, setCheckedItems] = useState({
    status: [1, 2, 3, 4],
    payment: ['paid', 'unpaid'],
  });
  const [, setEditModal] = useState(false);
  const [, setIsOpenUpdateModal] = useState(false);
  const [appointmentToDeleteId, setAppointmentToDeleteId] = useState(null);
  const [isDeleteAppointmentModalShown, setIsDeleteAppointmentModalShown] = useState(false);
  const [isAppointmentActionsModalOpen, setIsAppointmentActionsModalOpen] = useState(false);
  const [currentDay, setCurrentDay] = useState({
    start_time: '00:00',
    end_time: '24:00',
  });
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const classes = useStyles();

  // Get some props previous values
  const prevIsGetAppointmentsSuccess = usePrevious(isGetAppointmentsSuccess);
  const prevIsGetAppointmentsError = usePrevious(isGetAppointmentsError);
  const prevIsDeleteAppointmentError = usePrevious(isDeleteAppointmentError);
  const prevIsDeleteAppointmentSuccess = usePrevious(isDeleteAppointmentSuccess);
  const prevIsFilterCustomerAppointmentsByDateSuccess = usePrevious(isFilterCustomerAppointmentsByDateSuccess);

  // When component gets mounted
  useEffect(() => {
    const tableHeaderItems = [
      'Date/Time',
      'Duration',
      'Company',
      'Service',
      'Price($)',
      'Staff',
      'Status',
      'Actions',
    ];
    const selectedStatus = (filterData.status.filter((item, index) => (index < 2))).map((item) => parseInt(item.status_id));
    setCheckedItems({
      ...checkedItems,
      status: selectedStatus,
    });
    setTableHeader(tableHeaderItems);
    getAppointments();
  }, []);

  // Get appointments Success
  useEffect(() => {
    if (prevIsGetAppointmentsSuccess === false && isGetAppointmentsSuccess) {
      const filteredAllAppointmentsCopy = [...allAppointments];
      const sortedAppointment = handleSortingAppointments(filteredAllAppointmentsCopy);
      setAppointments(sortedAppointment);
      setTableLoading(true);
      setAnchorEl(null);
      setPage(0);
    }
  }, [isGetAppointmentsSuccess]);

  // Get appointments Error
  useEffect(() => {
    if (prevIsGetAppointmentsError === false && isGetAppointmentsError) {
      setOpenSnackbar(true);
      setSnackbarMessage('Something went wrong');
      setSnackbarType('error');
      history.push('/calendar');
    }
  }, [isGetAppointmentsError]);

  // Appointment deleted success
  useEffect(() => {
    if (prevIsDeleteAppointmentSuccess === false && isDeleteAppointmentSuccess) {
      const appointmentIndex = appointments.findIndex((item) => item.id === appointmentToDeleteId);
      const newAppointments = [...appointments];
      newAppointments.splice(appointmentIndex, 1);
      setIsDeleteAppointmentModalShown(false);
      setAppointmentToDeleteId(null);
      setIsOpenUpdateModal(false);
      setEditModal(false);
      setIsAppointmentActionsModalOpen(false);
      setOpenSnackbar(true);
      setSnackbarType('success');
      setSnackbarMessage('Appointment deleted');
      setAppointments(newAppointments);
    }
  }, [isDeleteAppointmentSuccess]);

  // Appointments delete error
  useEffect(() => {
    if (prevIsDeleteAppointmentError === false && isDeleteAppointmentError) {
      setIsDeleteAppointmentModalShown(false);
      setAppointmentToDeleteId(null);
      setOpenSnackbar(true);
      setSnackbarMessage(deleteAppointmentErrorMessage);
      setSnackbarType('error');
    }
  }, [isDeleteAppointmentError]);

  // Appointment filtered
  useEffect(() => {
    if (prevIsFilterCustomerAppointmentsByDateSuccess === false && isFilterCustomerAppointmentsByDateSuccess) {
      const filteredAllAppointmentsCopy = [...filteredCustomerAppointmentsByDate];
      const sortedAppointment = handleSortingAppointments(filteredAllAppointmentsCopy);
      setAppointments(sortedAppointment);
      setTableLoading(true);
      setOpen(false);
      setAnchorEl(null);
      setPage(0);
    }
  });

  const handleOpenFilter = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const togglePreview = (show) => {
    setIsPreviewShown(show);
  };

  const getSaveAppointment = (func) => {
    setSaveAppointment(() => func);
  };

  const handleSaveAppointment = () => {
    setIsPreviewShown(false);
    setIsCreateOrUpdateAppointmentPerformed(false);
    saveAppointment();
  };

  const handleOpenCreateAppointmentModal = () => {
    setIsCreateOrUpdateAppointmentPerformed(true);
  };

  const handleCloseCreateAppointmentModal = () => {
    setIsCreateOrUpdateAppointmentPerformed(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenUpdateModal = (event, itemId) => {
    const appointment = appointments.find((item) => item.id === parseInt(itemId));
    setIsAppointmentActionsModalOpen(true);
    setSelectedAppointment(appointment);
  };

  const showConfirmDelete = (e, id) => {
    e.preventDefault();
    setAppointmentToDeleteId(id);
    setIsDeleteAppointmentModalShown(true);
    const [first] = PermissionsModule.getRoles(account.testPermissions);
    localStorage.setItem('prefix', first.toLowerCase());
  };

  // Change the time in the current day
  const handleCurrentDayTimeChange = (event) => {
    const { name, value } = event.target;
    setCurrentDay({
      ...currentDay,
      [name]: value,
    });
  };

  const handleDeleteAppointment = () => {
    deleteAppointment({ id: appointmentToDeleteId });
  };

  const getAppointmentItem = (appointmentItem, showPreview = false) => {
    setAppointmentItem(appointmentItem);
    togglePreview(showPreview);
  };

  const handleCloseActionsModal = () => {
    setIsAppointmentActionsModalOpen(false);
  };

  // Apply filter
  const submitFilterItems = (data) => {
    filterCustomerAppointmentsByDate(data);
  };

  const onAppointmentCreated = (appointment) => {
    const appointmentsCopy = [...appointments];
    appointmentsCopy.push(appointment);
    const sortedAppointment = handleSortingAppointments(appointmentsCopy);
    setAppointments(sortedAppointment);
  };

  const handleSortingAppointments = (appointment) => (
    appointment.sort((left, right) => moment.utc(left.date).diff(moment.utc(right.date)))
  );

  return (
    <>
      <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            className="second-navbar"
          >
            <Typography variant="h6">
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <span className="appointment-title">Appointments</span>
                </Grid>
              </Grid>
            </Typography>
            <Box display="flex" alignItems="center">
              <Button
                variant="contained"
                onClick={handleOpenCreateAppointmentModal}
                color="primary"
                size="small"
              >
                Add Appointment
              </Button>
              <Box ml="10px">
                <IconButton
                  aria-label="filter"
                  onClick={handleOpenFilter}
                >
                  <TuneRoundedIcon color="secondary" />
                </IconButton>
                {/* {appointments.length ? */}
                <FilterContent
                  open={open}
                  anchorEl={anchorEl}
                  filterData={filterData}
                  appointments={appointments}
                  handleCloseFilter={handleCloseFilter}
                  submitFilterItems={submitFilterItems}
                  handleCurrentDayTimeChange={handleCurrentDayTimeChange}
                />
                {/*: null} */}
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div>
        <div className="menu-item appointments">
          <div className="general-content">
            {tableLoading ? (
              appointments && appointments.length ? (
                <div className="main-content appointments">
                  <TableContainer component={Paper} size="small">
                    <Table aria-label="caption table" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {tableHeader.map((item, index) => (
                            <TableCell key={`${index}-${Math.random()}`}>
                              {item}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {appointments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                          <TableRow
                            key={`${index}-${Math.random()}`}
                          >
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography>
                                <Typography className="appointment-date">
                                  {item.date === TODAY ? 'Today' : item.date === TOMORROW ? 'Tomorrow' : item.date}
                                </Typography>
                                <Typography className="appointment-time">
                                  <Typography noWrap variant="caption">
                                    {moment(item.start_time, 'HH:mm').format('HH:mm')}
                                  </Typography>
                                </Typography>
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography noWrap variant="caption">
                                <Box display="flex" alignItems="center">
                                  <Typography noWrap variant="caption">{item.duration} min</Typography>
                                </Box>
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography noWrap variant="caption">
                                <Box display="flex" alignItems="center">
                                  { item.company && item.company.logo ? (
                                    <Avatar
                                      className={classes.avatarAppointments}
                                      alt="avatar"
                                      src={item.company.logo}
                                    />
                                  )
                                    : (
                                      <Avatar variant="circle" className={classes.avatarLettersAppointments}>
                                        { (item.company.name ? item.company.name : '').charAt(0).toUpperCase()}
                                      </Avatar>
                                    )}
                                  <Typography>
                                    <Typography className="appointment-company-name">
                                      {item.company ? `${item.company.name}` : '----'}
                                    </Typography>
                                    <Typography className="appointment-time">
                                      <Typography noWrap variant="caption">
                                        {item.company.industry.name}
                                      </Typography>
                                    </Typography>
                                  </Typography>
                                </Box>
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography noWrap variant="caption">
                                {item.service.name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography noWrap variant="caption">{item.price}</Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography noWrap variant="caption">
                                <Box display="flex" alignItems="center">
                                  { item.staff ? (
                                    item.staff && item.staff.avatar ? (
                                      <Avatar
                                        className={classes.avatarAppointments}
                                        alt="avatar"
                                        src={item.staff.avatar}
                                      />
                                    ) : (
                                      <Avatar variant="circle" className={classes.avatarLettersAppointments}>
                                        { firstLetter(item.staff.first_name ? item.staff.first_name
                                          : '', item.staff.last_name ? item.staff.last_name : '')}
                                      </Avatar>
                                    )
                                  ) : item.individualUser ? (
                                    item.individualUser && item.individualUser.avatar ? (
                                      <Avatar
                                        className={classes.avatarAppointments}
                                        alt="avatar"
                                        src={item.individualUser.avatar}
                                      />
                                    ) : (
                                      <Avatar variant="circle" className={classes.avatarLettersAppointments}>
                                        { firstLetter(item.individualUser.first_name ? item.individualUser.first_name
                                          : '', item.individualUser.last_name ? item.individualUser.last_name : '')}
                                      </Avatar>
                                    )
                                  ) : ''}
                                  {item.staff ? `${item.staff.first_name} ${item.staff.last_name}`
                                    : (item.individualUser ? item.individualUser.full_name : '----')}
                                </Box>
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <div className={`appointment-status ${item.status.name}`}>
                                { capitalize(item.status.name) }
                              </div>
                            </TableCell>
                            <TableCell>
                              {item.status.id === 3 || item.status.id === 4
                                ? <Button />
                                : (
                                  <Button aria-label="edit" onClick={(e) => handleOpenUpdateModal(e, item.id)}>
                                    <EditIcon fontSize="small" color="secondary" />
                                  </Button>
                                )}
                              <IconButton
                                aria-label="delete"
                                onClick={(e) => showConfirmDelete(e, item.id)}
                              >
                                <DeleteOutlineIcon fontSize="small" color="secondary" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {appointments.length > 8
                    ? (
                      <TablePagination
                        rowsPerPageOptions={[8, 25, 50]}
                        component="div"
                        count={appointments.length}
                        labelRowsPerPage={t('RowsPerPage')}
                        labelDisplayedRows={({ count, from, to }) => `${from}-${to}/${count}`}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={classes.paginationTable}
                      />
                    )
                    : null}
                </div>
              ) : (
                <Box className="appointments-page box-general" py="30px" align="center">
                  <Box>
                    <Box>
                      <img src={noAppointment} alt="noAppointment" />
                    </Box>
                    <Typography component="div">
                      <Box fontWeight="500">
                        <Typography variant="h6" color="secondary">
                          {t('NoAppointments')}
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              )
            ) : <AppointmentsLoading />}
          </div>
        </div>
        {isDeleteAppointmentModalShown && (
        <Dialog
          open={() => ({})}
          onClose={() => setIsDeleteAppointmentModalShown(false)}
        >
          <DialogTitle id="customized-dialog-title" onClose={() => setIsDeleteAppointmentModalShown(false)}>
            <span className="alert-title">
              <span>{t('ConfirmDeletion')}</span>
              <span>
                <IconButton
                  aria-label="close"
                  className="close-btn"
                  onClick={() => setIsDeleteAppointmentModalShown(false)}
                >
                  <CloseIcon />
                </IconButton>
              </span>
            </span>
          </DialogTitle>
          <DialogContent dividers>
            <Typography gutterBottom>{t('AppointmentDeleteQuestion')}</Typography>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => setIsDeleteAppointmentModalShown(false)}
            >
              {t('Cancel')}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleDeleteAppointment}
            >
              {t('Delete')}
            </Button>
          </DialogActions>
        </Dialog>
        )}
        <CreateNewAppointmentModal
          open={isCreateOrUpdateAppointmentPerformed && !isPreviewShown}
          close={handleCloseCreateAppointmentModal}
          onAppointmentCreated={onAppointmentCreated}
          sendAppointmentItem={getAppointmentItem}
          sendSaveAppointment={getSaveAppointment}
          calendarAppointmentItem={appointmentItem}
          openInviteCustomerModal={() => {}}
          customerOptions={[]}
        />
        <AppointmentPreview
          open={isPreviewShown}
          onModalClose={() => togglePreview(false)}
          appointmentItem={appointmentItem}
          saveAppointment={handleSaveAppointment}
        />
        <AppointmentActionsModal
          open={isAppointmentActionsModalOpen}
          onClose={handleCloseActionsModal}
          appointment={selectedAppointment}
          handleDelete={showConfirmDelete}
        />
        <SnackbarToast
          message={snackbarMessage}
          type={snackbarType}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
        />
        { alert }
      </div>
    </>
  );
}

MyAppointments.propTypes = {
  account: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getAppointments: PropTypes.func.isRequired,
  allAppointments: PropTypes.array.isRequired,
  isGetAppointmentsError: PropTypes.bool.isRequired,
  isGetAppointmentsSuccess: PropTypes.bool.isRequired,
  // Accept appointment
  // isAcceptSuccess: PropTypes.bool.isRequired,
  // Delete Appointment
  deleteAppointment: PropTypes.func.isRequired,
  isDeleteAppointmentError: PropTypes.bool.isRequired,
  isDeleteAppointmentSuccess: PropTypes.bool.isRequired,
  deleteAppointmentErrorMessage: PropTypes.string.isRequired,
  // Filter Appointments
  filterCustomerAppointmentsByDate: PropTypes.func.isRequired,
  filteredCustomerAppointmentsByDate: PropTypes.array.isRequired,
  isFilterCustomerAppointmentsByDateSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account.userAccount,
  // Get appointment
  allAppointments: state.appointment.allAppointments,
  isGetAppointmentsError: state.appointment.isGetAppointmentsError,
  isGetAppointmentsSuccess: state.appointment.isGetAppointmentsSuccess,
  // Accept Appointment
  // isAcceptSuccess: state.appointment.isAcceptSuccess,
  isAcceptError: state.appointment.isAcceptError,
  acceptErrorMessage: state.appointment.acceptErrorMessage,
  // Delete appointment
  isDeleteAppointmentError: state.appointment.isDeleteAppointmentError,
  isDeleteAppointmentSuccess: state.appointment.isDeleteAppointmentSuccess,
  deleteAppointmentErrorMessage: state.appointment.deleteAppointmentErrorMessage,
  // Filter Appointments
  filteredCustomerAppointmentsByDate: state.customers.filteredCustomerAppointmentsByDate,
  isFilterCustomerAppointmentsByDateError: state.customers.isFilterCustomerAppointmentsByDateError,
  isFilterCustomerAppointmentsByDateSuccess: state.customers.isFilterCustomerAppointmentsByDateSuccess,
});

function mapDispatchToProps(dispatch) {
  return {
    acceptAppointment: (data) => dispatch(acceptRequest(data)),
    getAppointments: (data) => dispatch(getAppointmentsRequest(data)),
    deleteAppointment: (data) => dispatch(deleteAppointmentRequest(data)),
    filterCustomerAppointmentsByDate: (data) => dispatch(filterCustomerAppointmentsByDateRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAppointments);
