import React, { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  Button,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import ServiceContext from '../serviceContext';
import { addCustomServiceRequest, updateServiceRequest } from '../../../../../../redux/service/actions';

function CreateOrUpdateCategoryModal() {
  const {
    setOpenCategoryModal, selectedCategory, setSelectedCategory,
  } = useContext(ServiceContext);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [categoryName, setCategoryName] = useState(selectedCategory ? selectedCategory.name : '');
  const [error, setError] = useState(false);

  const handleChange = () => {
    if (categoryName.trim().length > 0) {
      setError(false);
      if (selectedCategory) {
        dispatch(updateServiceRequest({ id: selectedCategory.id, name: categoryName }));
      } else {
        dispatch(addCustomServiceRequest({ name_en: categoryName }));
      }
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      size="sm"
      open
      onClose={() => {
        setSelectedCategory(null);
        setOpenCategoryModal(false);
      }}
    >
      <DialogTitle>
        <span className="alert-title">
          <span>{selectedCategory ? t('EditCategory') : t('NewCategory')}</span>
          <span>
            <IconButton aria-label="close" className="close-btn" onClick={() => setOpenCategoryModal(false)}>
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <FormControl
          fullWidth
          size="small"
          variant="outlined"
          error={error}
        >
          <TextField
            fullWidth
            size="small"
            error={error}
            label={`${t('Category')} *`}
            variant="outlined"
            type="text"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
          />

          {error && (
            <div className="error-message-content">
              <ErrorIcon fontSize="small" color="error" />
              <FormHelperText>
                {t('CategoryIsRequired')}
              </FormHelperText>
            </div>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          data-cy="cancel"
          onClick={() => {
            setSelectedCategory(null);
            setOpenCategoryModal(false);
          }}
        >
          {t('Cancel')}
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          data-cy="update"
          onClick={handleChange}
        >
          {t('Save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateOrUpdateCategoryModal;
