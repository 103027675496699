import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import routes from '../Modules/routes';

class NotPrivateRoutes extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    handleLogin: PropTypes.func.isRequired,
    handleCompanyOnBoarded: PropTypes.func.isRequired,
    handleEmailVerified: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const notPrivateRoutes = routes.filter((route) => (!route.isPrivate));
    const {
      handleLogin,
      handleCompanyOnBoarded,
      handleEmailVerified,
    } = this.props;
    const renderedRoutes = notPrivateRoutes.map((route) => {
      const RouteVal = route.component;

      return (
        <Route
          key={route.id}
          path={route.path}
          exact
          render={(props) => (
            <RouteVal
              {...props}
              handleLogin={(token) => handleLogin(token)}
              handleCompanyOnBoarded={(onBoarded) => handleCompanyOnBoarded(onBoarded)}
              handleEmailVerified={(emailVerified) => handleEmailVerified(emailVerified)}
            />
          )}
        />
      );
    });
    return (
      <Switch>
        {renderedRoutes}
        <Route path="*">
          <Redirect to="/login/" />
        </Route>
      </Switch>
    );
  }
}

export default NotPrivateRoutes;
