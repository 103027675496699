import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../../../../../style/general.scss';
// import FullCalendar from '@fullcalendar/react';
// import interactionPlugin from '@fullcalendar/interaction';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import dayGridPlugin from '@fullcalendar/daygrid';
import {
  AppBar,
  Select,
  Tooltip,
  InputBase,
  Box,
  Grid,
  Button,
  Toolbar,
  Divider,
  MenuItem,
  IconButton,
  Typography,
  FormControl,
} from '@material-ui/core';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import i18n from 'i18next';
import {
  acceptRequest,
  cancelRequest,
  deleteAppointmentRequest,
} from '../../../../../redux/appointment/actions';
import CreateNewAppointmentModal from '../../../../../Modals/NewAppointment';
import AppointmentActionsModal from '../../../../../Modals/AppointmentActions';
import DeleteAppointmentAlert from '../../../../../Alerts/DeleteAppointment';
import AppointmentPreview from '../../../../../Modals/AppointmentPreview';
import CalendarLoading from '../../../../../Components/Loading/calendarLoading';
import FilterCalendarCustomer from './FilterCalendarCustomer';
import SnackbarToast from '../../../../../Modules/SnackbarToast';
import filterItems from '../../../../../Modules/AppointmentsFilterItems/personalAppointments';

class Calendar extends PureComponent {
  appointmentToDeleteId = null;

  appointmentToAcceptOrCancelId = null;

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    allCompanies: PropTypes.array.isRequired,
    isGetAppointmentsSuccess: PropTypes.bool.isRequired,
    isGetAppointmentsError: PropTypes.bool.isRequired,
    allAppointments: PropTypes.array.isRequired,
    deleteAppointment: PropTypes.func.isRequired,
    isDeleteAppointmentSuccess: PropTypes.bool.isRequired,
    isDeleteAppointmentError: PropTypes.bool.isRequired,
    deleteAppointmentErrorMessage: PropTypes.string.isRequired,
    acceptAppointment: PropTypes.func.isRequired,
    isAcceptSuccess: PropTypes.bool.isRequired,
    isAcceptError: PropTypes.bool.isRequired,
    acceptErrorMessage: PropTypes.string.isRequired,
    cancelAppointment: PropTypes.func.isRequired,
    isCancelSuccess: PropTypes.bool.isRequired,
    isCancelError: PropTypes.bool.isRequired,
    cancelErrorMessage: PropTypes.string.isRequired,
    // Appointment filter
    filterCustomerAppointments: PropTypes.func.isRequired,
    filteredCustomerAppointments: PropTypes.array.isRequired,
    isFilterCustomerAppointmentsError: PropTypes.array.isRequired,
    isFilterCustomerAppointmentsSuccess: PropTypes.array.isRequired,
    isGetAllCompaniesSuccess: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      events: [],
      appointmentId: '',
      appointmentItem: {},
      saveAppointment: () => {},
      companyWorkingHours: [],
      selectedAppointment: {},
      currentDayIndex: 0,
      selectedTimeGrid: '',
      loading: false,
      alert: null,
      anchorEl: null,
      open: false,
      filterData: filterItems,
      isAppointmentPreviewShown: false,
      isAppointmentActionsModalOpen: false,
      isDeleteAppointmentAlertShown: false,
      isCreateNewAppointmentModalOpen: false,
      selectedTimeGridOption: [
        { value: 'timeGridDay', label: 'Daily' },
        { value: 'timeGridWeek', label: 'Weekly' },
        { value: 'dayGridMonth', label: 'Monthly' },
      ],
      currentDay: {
        start_time: '00:00',
        end_time: '24:00',
      },
      snackbarMessage: '',
      snackbarType: '',
      openSnackbar: false,
      companyStartTime: '9:00',
      companyEndTime: '18:00',
      companyBreakStartTime: '',
      companyBreakEndTime: '',
    };
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleBodyClick);
  }

  componentDidUpdate(prevProps) {
    const {
      isAcceptError,
      isCancelError,
      allAppointments,
      isAcceptSuccess,
      isCancelSuccess,
      acceptErrorMessage,
      cancelErrorMessage,
      isGetAppointmentsError,
      isGetAppointmentsSuccess,
      isDeleteAppointmentError,
      isDeleteAppointmentSuccess,
      filteredCustomerAppointments,
      deleteAppointmentErrorMessage,
      isFilterCustomerAppointmentsError,
      isFilterCustomerAppointmentsSuccess,
    } = this.props;
    // Get appointments success
    if (!prevProps.isGetAppointmentsSuccess && isGetAppointmentsSuccess) {
      const events = allAppointments.map((item) => this.generateCalendarEvent(item));
      this.setState({
        loading: false,
        appointments: allAppointments,
        events,
      });
      const { location } = this.props;
      const { urlDate } = this.state;
      if (location.search !== '') {
        this.setState({
          changedDate: urlDate,
        });
      }
    }
    // Get appointments error
    if (!prevProps.isGetAppointmentsError && isGetAppointmentsError) {
      this.setState({ loading: false });
    }
    // Delete appointment success
    if (!prevProps.isDeleteAppointmentSuccess && isDeleteAppointmentSuccess) {
      const { events } = this.state;
      const eventsCopy = [...events];
      const appointmentIndex = events.findIndex((event) => event.id === this.appointmentToDeleteId);
      if (appointmentIndex !== -1) {
        eventsCopy.splice(appointmentIndex, 1);
      }
      this.setState({
        events: eventsCopy,
        isAppointmentActionsModalOpen: false,
      });
      this.snackBarAlert(true, i18n.t('AppointmentDeleted'), 'success');
    }
    // Delete appointment error
    if (!prevProps.isDeleteAppointmentError && isDeleteAppointmentError) {
      this.snackBarAlert(true, deleteAppointmentErrorMessage, 'error');
    }
    // Accept appointment success
    if (!prevProps.isAcceptSuccess && isAcceptSuccess) {
      const { appointments } = this.state;
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex(
        (item) => item.id === parseInt(this.appointmentToAcceptOrCancelId),
      );
      // Accepted status id
      appointmentsCopy[appointmentIndex].status_id = 1;
      appointmentsCopy[appointmentIndex].is_accept_allowed = false;
      this.snackBarAlert(true, i18n.t('AppointmentAccepted'), 'success');
      this.appointmentToAcceptOrCancelId = null;
      this.setState({
        appointments: appointmentsCopy,
        isAppointmentActionsModalOpen: false,
      });
    }
    // Accept appointment error
    if (!prevProps.isAcceptError && isAcceptError) {
      this.appointmentToAcceptOrCancelId = null;
      this.snackBarAlert(true, acceptErrorMessage, 'error');
    }
    // Cancel appointment success
    if (!prevProps.isCancelSuccess && isCancelSuccess) {
      const { appointments } = this.state;
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex(
        (item) => item.id === parseInt(this.appointmentToAcceptOrCancelId),
      );
      // Cancelled status id
      appointmentsCopy[appointmentIndex].status_id = 3;
      this.snackBarAlert(true, i18n.t('AppointmentUpdated'), 'success');
      this.appointmentToAcceptOrCancelId = null;
      this.setState({
        appointments: appointmentsCopy,
        isAppointmentActionsModalOpen: false,
      });
    }
    // Cancel appointment error
    if (!prevProps.isCancelError && isCancelError) {
      this.appointmentToAcceptOrCancelId = null;
      this.snackBarAlert(true, cancelErrorMessage, 'error');
    }
    // Filtered Appointment is success
    if (!prevProps.isFilterCustomerAppointmentsSuccess && isFilterCustomerAppointmentsSuccess) {
      const events = filteredCustomerAppointments.map((item) => this.generateCalendarEvent(item));
      this.setState({
        loading: false,
        appointments: filteredCustomerAppointments,
        events,
      });
      const { location } = this.props;
      const { urlDate } = this.state;
      if (location.search !== '') {
        this.setState({
          changedDate: urlDate,
          anchorEl: null,
          open: false,
        });
      }
    }
    // Filtered Appointment is error
    if (!prevProps.isFilterCustomerAppointmentsError && isFilterCustomerAppointmentsError) {
      this.snackBarAlert(true, 'Appointment is not filtered', 'error');
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleBodyClick);
  }

  snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    this.openSnackbar = snackOpen;
    this.snackbarMessage = SnackMessage;
    this.snackbarType = SnackType;
  };

  handleEventClick = (e) => {
    const { event } = e;
    const { appointments } = this.state;
    const appointment = appointments.find((item) => item.id === parseInt(event.id));
    this.setState({
      selectedAppointment: appointment,
      isAppointmentActionsModalOpen: true,
    });
  };

  handleBodyClick = (event) => {
    const { path } = event;
    if (!path) {
      return false;
    }
    const element = document.getElementById('appointment-details');
    const isEventClicked = path.findIndex((item) => item.className && typeof (item.className) === 'string'
      && item.className.includes('fc-time-grid-event fc-event fc-start'));
    if (isEventClicked === -1 && element) {
      element.style.display = 'none';
    }
  };

  handleEditAppointment = (id) => {
    const { history } = this.props;
    history.push(`/my-appointments/${id}`);
  };

  showDeleteAppointmentModal = (e, id) => {
    this.setState({
      isDeleteAppointmentAlertShown: true,
      appointmentId: id,
    });
  };

  handleDeleteAppointment = () => {
    this.setState({ isDeleteAppointmentAlertShown: false });
    const { appointmentId } = this.state;
    const { deleteAppointment } = this.props;
    this.appointmentToDeleteId = appointmentId;
    deleteAppointment({ id: appointmentId });
  };

  handleAcceptAppointment = (id) => {
    this.appointmentToAcceptOrCancelId = id;
    const { acceptAppointment } = this.props;
    acceptAppointment(id);
  };

  handleCancelAppointment = (id) => {
    this.appointmentToAcceptOrCancelId = id;
    const { cancelAppointment } = this.props;
    cancelAppointment(id);
  };

  generateCalendarEvent = (appointment) => {
    const startTime = moment(appointment.start_time, 'LT').format('LT');
    const endTime = moment(appointment.end_time, 'LT').format('LT');
    const day = moment(appointment.date).format('Y-MM-DD');
    const startDate = new Date(`${day} ${startTime}`);
    const endDate = new Date(`${day} ${endTime}`);

    return {
      id: appointment.id,
      title: appointment.title || appointment.note_from_customer || '',
      start: startDate,
      end: endDate,
      color: '#0282DA',
      textColor: '#000000',
      border: '0.5px solid #0282DA',
      backgroundColor: appointment.status.color,
      status: appointment.status.name,
    };
  };

  renderEventTitle = (
    end,
    start,
    company,
    staffName,
    statusName,
    serviceName,
    servicePrice,
    selectedTimeGrid,
  ) => (`
    <div class=${selectedTimeGrid === 'dayGridMonth' ? 'appointment-content-month' : 'appointment-content'}>
      <span class="role-color" />
      <span class="duration">${selectedTimeGrid === 'dayGridMonth' ? start : start}
        ${selectedTimeGrid === 'dayGridMonth' ? '' : `-${end}`}</span>
      <Tooltip title=${statusName}>
        <span class="material-icons status">
          ${selectedTimeGrid === 'dayGridMonth' ? '' : statusName === 'pending' ? 'query_builder'
    : statusName === 'cancelled' ? 'not_interested'
      : statusName === 'accepted' ? 'check_circle_outline' : 'outlined_flag'}
        </span>
      </Tooltip>
      <br/>
      <span class="staff-name">${staffName}</span>
      <span class="service-title">${serviceName} at ${company}</span>
      <span class="service-price">${servicePrice} AMD</span>
      <Tooltip title="Edit" class="event-tool">
        <span class="material-icons editBtn">${statusName !== 'cancelled' ? 'edit' : ''}</span>
      </Tooltip>
    </div>
  `);

  eventRender = (event) => {
    const {
      el: element,
      event: eventApi,
    } = event;
    const { selectedTimeGrid, appointments } = this.state;
    const start = moment(eventApi.start).format('HH:mm');
    const end = moment(eventApi.end).format('HH:mm');
    const appId = eventApi.id;
    const [selectedApp] = appointments.filter((item) => item.id === parseInt(appId));
    const company = selectedApp.company.name;
    const staffName = selectedApp.staff.full_name;
    const serviceName = selectedApp.service.name;
    const statusName = selectedApp.status.name;
    const servicePrice = selectedApp.price;

    element.innerHTML = this.renderEventTitle(
      end,
      start,
      company,
      staffName,
      statusName,
      serviceName,
      servicePrice,
      selectedTimeGrid,
    );
  };

  closeCreateNewAppointmentModal = () => {
    this.setState({ isCreateNewAppointmentModalOpen: false });
  };

  onAppointmentCreated = (appointment) => {
    const {
      appointments: prevAppointments,
      events: prevEvents,
    } = this.state;
    const event = this.generateCalendarEvent(appointment);
    const appointments = [...prevAppointments, appointment];
    const events = [...prevEvents, event];

    this.setState({ appointments, events });
  };

  getAppointmentItem = (appointmentItem, isAppointmentPreviewShown = false) => {
    this.setState({
      appointmentItem,
      isAppointmentPreviewShown,
    });
  };

  getSaveAppointment = (saveAppointment) => {
    this.setState({ saveAppointment });
  };

  handleSaveAppointment = () => {
    const { saveAppointment } = this.state;
    this.setState({ isAppointmentPreviewShown: false });
    saveAppointment();
  };

  onCalendarSelect = () => {
    this.setState({ isCreateNewAppointmentModalOpen: true });
  };

  onActionsModalClose = () => {
    this.setState({ isAppointmentActionsModalOpen: false });
  };

  selectAllow = (args) => {
    if (Date.parse(args.start) < Date.parse(new Date())) {
      return false;
    } else {
      const startTime = moment(args.startStr).format('HH:mm');
      const startDay = moment(args.startStr).format('Y-MM-DD');
      const endTime = moment(args.endStr).format('HH:mm');
      const hoursDiff = moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'hours');
      const minutesDiff = moment.utc(moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'))).format('mm');
      if ((hoursDiff > 0) || minutesDiff > 0) {
        const appointmentItem = {
          day_time: args.startStr,
          date: startDay,
          start_time: startTime,

        };
        this.setState({
          appointmentItem,
        });
        return true;
      } else {
        return false;
      }
    }
  };

  checkRescheduleAvailable = ((event, startDay) => {
    const { companyWorkingHours } = this.state;
    const { start, end } = event.event;
    const weekDay = moment(startDay).day();

    const eventStart = moment(start, 'h:mma');
    const eventEnd = moment(end, 'h:mma');
    const startWorkingAt = `${startDay} ${companyWorkingHours[weekDay - 1].startTime}`;
    const endWorkingAt = `${startDay} ${companyWorkingHours[weekDay - 1].endTime}`;
    return eventStart.isSameOrAfter(moment(startWorkingAt)) && eventEnd.isSameOrBefore(moment(endWorkingAt));
  });

  scrollCalendarToCurrentTime = () => {
    const now = moment();
    const isPm = now.format('a') === 'pm';
    const hours = parseInt(now.format('hh')) + (isPm ? 12 : 0);
    const minutes = parseInt(now.format('mm'));
    // each hour block height - 72
    const height = (hours * 72) + (minutes / 3);
    setTimeout(() => {
      window.scrollTo(0, height);
    }, 500);
  };

  handleOpenAppointmentsInfo = () => {
    const { changedDate } = this.state;
    const { allAppointments, filteredCustomerAppointments } = this.props;
    const allAppointmentsCopy = filteredCustomerAppointments.length ? [...filteredCustomerAppointments] : [...allAppointments];
    const [yourName] = allAppointmentsCopy.map((item) => item.customer).map((i) => i.full_name);
    const filteredAppointments = allAppointmentsCopy.filter((item) => item.date === changedDate);
    const filteredStaffPrice = filteredAppointments.map((item) => parseInt(item.price));
    const filteredAppointmentsPrice = filteredStaffPrice.length ? filteredStaffPrice.reduce((a, b) => a + b) : 0;
    const getAlert = () => (
      <Box className="dropdown-content">
        <MenuItem disabled>
          Statistics of {yourName}
        </MenuItem>
        <Divider />
        <MenuItem>
          <Grid container spacing={1}>
            <Grid item xs={9}>Appointments</Grid>
            <Grid item xs={3}>{filteredAppointments.length}</Grid>
          </Grid>
        </MenuItem>
        <MenuItem>
          <Grid container spacing={1}>
            <Grid item xs={9}>Total payments</Grid>
            <Grid item xs={3}>&#36;{filteredAppointmentsPrice}</Grid>
          </Grid>
        </MenuItem>
      </Box>
    );
    this.setState({ alert: getAlert(), changedDate });
  };

  handleChangeDay = (event) => {
    const { currentDayIndex } = this.state;
    const { location, history } = this.props;
    const { date, view } = event;
    const checkedDate = date;
    const day = moment(checkedDate).format('YYYY-MM-DD');
    const getUrlDate = new URLSearchParams(location.search).get('date');
    const setDate = (currentDayIndex === '' && getUrlDate !== null) ? getUrlDate : day;
    const IsoWeekDay = 1;
    const currentDate = moment(checkedDate).format('YYYY-MM-DD');
    const startOfPeriod = moment(checkedDate);
    const daysToSubtract = moment(startOfPeriod).isoWeekday() >= IsoWeekDay
      ? moment(startOfPeriod).isoWeekday() - IsoWeekDay
      : 7 + moment(startOfPeriod).isoWeekday() - IsoWeekDay;
    if ((view.type !== 'timeGridWeek' && view.type !== 'dayGridMonth') && currentDayIndex !== daysToSubtract) {
      this.setState({
        currentDayIndex: daysToSubtract,
        changedDate: currentDate,
        urlDate: getUrlDate,
      });
      history.push(`/calendar/?date=${setDate}`);
    }
    this.scrollCalendarToCurrentTime();
    this.getCompanyWorkingSchedule();
  }

  getCompanyWorkingSchedule = () => {
    const { allCompanies, isGetAllCompaniesSuccess } = this.props;
    if (isGetAllCompaniesSuccess) {
      const { currentDayIndex } = this.state;
      const allCompaniesCopy = { ...allCompanies };
      const staffCompanies = allCompaniesCopy.companies;
      const working_hours = staffCompanies.map((item) => JSON.parse(item.working_hours));
      const breaking_hours = staffCompanies.map((item) => JSON.parse(item.breaking_hours));
      const start_time = working_hours.map((item) => item[currentDayIndex].start_time);
      const end_time = working_hours.map((item) => item[currentDayIndex].end_time);
      const [break_start_time] = breaking_hours.map((item) => item[currentDayIndex].is_breaking ? item[currentDayIndex].start_time : '');
      const [break_end_time] = breaking_hours.map((item) => item[currentDayIndex].is_breaking ? item[currentDayIndex].end_time : '');
      const [startTime] = staffCompanies.length > 1 ? start_time.sort((a, b) => (a < b) ? -1 : ((a > b) ? 1 : 0)) : start_time;
      const [endTime] = staffCompanies.length > 1 ? end_time.sort((a, b) => (a > b) ? -1 : ((a < b) ? 1 : 0)) : end_time;
      this.setState({
        companyStartTime: startTime,
        companyEndTime: endTime,
        companyBreakStartTime: break_start_time,
        companyBreakEndTime: break_end_time,
      });
    }
  }

  handleChangeDate = ({ target: { value } }, ref) => {
    const calendarApi = ref.current.getApi();
    calendarApi.gotoDate(value);
    this.setState({
      changedDate: moment(value, moment.defaultFormat).toDate(),
    });
  };

  handleChangeGrid = ({ target: { value } }, ref) => {
    ref.current.getApi().changeView(value);
    this.setState({
      selectedTimeGrid: value,
    });
  };

  // Open filter menu
  handleOpenFilter = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: true });
  };

  // Close filter menu
  handleCloseFilter = () => {
    this.setState({ anchorEl: null, open: false });
  };

  // Change the time in the current day
  handleCurrentDayTimeChange = (event) => {
    const { name, value } = event.target;
    this.setState((prevState) => ({
      currentDay: {
        ...prevState.currentDay,
        [name]: value,
      },
    }));
  };

  // Apply filter
  submitFilterItems = (data) => {
    const { filterCustomerAppointments } = this.props;
    filterCustomerAppointments(data);
  }

  render() {
    const {
      open,
      alert,
      // events,
      loading,
      anchorEl,
      currentDay,
      filterData,
      changedDate,
      appointments,
      openSnackbar,
      snackbarType,
      // companyEndTime,
      snackbarMessage,
      appointmentItem,
      selectedTimeGrid,
      // companyStartTime,
      // companyBreakEndTime,
      // companyWorkingHours,
      selectedAppointment,
      // companyBreakStartTime,
      selectedTimeGridOption,
      isAppointmentPreviewShown,
      isDeleteAppointmentAlertShown,
      isAppointmentActionsModalOpen,
      isCreateNewAppointmentModalOpen,
    } = this.state;

    // const currentDate = moment().format('YYYY-MM-DD');
    const calendarRef = React.createRef();

    // const staffBusinessHours = companyStartTime ? [
    //   {
    //     daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
    //     startTime: companyStartTime,
    //     endTime: companyBreakStartTime || companyEndTime,
    //   },
    //   {
    //     daysOfWeek: [1, 2, 3, 4, 5, 6, 7],
    //     startTime: companyBreakEndTime,
    //     endTime: companyEndTime,

    //   },
    // ] : [];

    return !loading ? (
      <div>
        <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
              <Typography variant="h6">
                <Box display="flex" alignItems="center" height="100%">
                  Calendar
                </Box>
              </Typography>
              <Box display="flex" alignItems="center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onCalendarSelect}
                  size="small"
                >
                  Add Appointment
                </Button>
                <Box ml="10px">
                  <IconButton
                    aria-label="equalizerIcon"
                    className="appointments-info"
                    onFocus={() => ({})}
                    onMouseOver={this.handleOpenAppointmentsInfo}
                    onMouseLeave={() => (this.setState({ alert: null }))}
                  >
                    <EqualizerIcon color="secondary" />
                  </IconButton>
                  <Tooltip title="Filter">
                    <IconButton
                      aria-label="filter"
                      onClick={this.handleOpenFilter}
                    >
                      <TuneRoundedIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                  <FilterCalendarCustomer
                    open={open}
                    anchorEl={anchorEl}
                    currentDay={currentDay}
                    filterData={filterData}
                    appointments={appointments}
                    handleCloseFilter={this.handleCloseFilter}
                    submitFilterItems={this.submitFilterItems}
                    handleCurrentDayTimeChange={this.handleCurrentDayTimeChange}
                  />
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <div className="menu-item appointments calendar">
          <div className="menu-item general">
            <div className="panel">
              <div className="main-content">
                <div className="main-content">
                  <div className="box-general">
                    <FormControl
                      fullWidth
                      size="small"
                      className="date-picker"
                    >
                      <Tooltip title="Date Picker" placement="top">
                        <InputBase
                          size="small"
                          type="date"
                          id="datePicker"
                          onChange={(e) => this.handleChangeDate(e, calendarRef)}
                          value={moment(changedDate).format('YYYY-MM-DD')}
                          inputProps={{ 'aria-label': 'naked', style: { fontSize: 20 } }}
                          inputlabelprops={{ shrink: true }}
                          autoComplete="off"
                        />
                      </Tooltip>
                    </FormControl>
                    <div className="full-calendar">
                      <Box display="flex" alignItems="center" ml="auto" className="calendar-header">
                        <FormControl fullWidth variant="outlined" size="small" className="select-time-grid">
                          <Select
                            value={selectedTimeGrid || 'timeGridDay'}
                            onChange={(e) => this.handleChangeGrid(e, calendarRef)}
                          >
                            {selectedTimeGridOption.map((option, index) => (
                              <MenuItem
                                key={index}
                                value={option.value}
                                selected={selectedTimeGrid}
                              >
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      {/*<FullCalendar*/}
                      {/*  ref={calendarRef}*/}
                      {/*  className="full-calendar"*/}
                      {/*  events={events}*/}
                      {/*  defaultView="timeGridDay"*/}
                      {/*  slotDuration="00:20:00"*/}
                      {/*  slotLabelInterval={{ hour: 0.5 }}*/}
                      {/*  titleFormat={{*/}
                      {/*    year: 'numeric',*/}
                      {/*    month: 'short',*/}
                      {/*    day: 'numeric',*/}
                      {/*  }}*/}
                      {/*  slotLabelFormat={{*/}
                      {/*    hour: 'numeric',*/}
                      {/*    minute: '2-digit',*/}
                      {/*    hour12: false,*/}
                      {/*  }}*/}
                      {/*  eventTimeFormat={{*/}
                      {/*    hour: 'numeric',*/}
                      {/*    minute: '2-digit',*/}
                      {/*    hour12: false,*/}
                      {/*  }}*/}
                      {/*  locale="en-GB"*/}
                      {/*  buttonText={{*/}
                      {/*    today: 'Today',*/}
                      {/*  }}*/}
                      {/*  header={{*/}
                      {/*    left: '',*/}
                      {/*    center: 'prev title next',*/}
                      {/*    right: 'today, dayGridMonth,timeGridWeek,timeGridDay',*/}
                      {/*  }}*/}
                      {/*  allDaySlot={false}*/}
                      {/*  contentHeight="auto"*/}
                      {/*  plugins={[*/}
                      {/*    interactionPlugin,*/}
                      {/*    timeGridPlugin,*/}
                      {/*    dayGridPlugin,*/}
                      {/*  ]}*/}
                      {/*  selectable*/}
                      {/*  editable*/}
                      {/*  droppable*/}
                      {/*  draggable*/}
                      {/*  eventlimit*/}
                      {/*  views={{*/}
                      {/*    month: {*/}
                      {/*      eventLimit: 2,*/}
                      {/*    },*/}
                      {/*  }}*/}
                      {/*  minTime={companyStartTime}*/}
                      {/*  maxTime={companyEndTime}*/}
                      {/*  businessHours={*/}
                      {/*    selectedTimeGrid !== 'dayGridMonth'*/}
                      {/*      ? staffBusinessHours : companyWorkingHours*/}
                      {/*  }*/}
                      {/*  nowIndicator*/}
                      {/*  eventOverlap={false}*/}
                      {/*  autoeventwireup={false}*/}
                      {/*  selectOverlap={() => false}*/}
                      {/*  eventDurationEditable={false}*/}
                      {/*  select={this.onCalendarSelect}*/}
                      {/*  eventDrop={(event) => this.eventDrop(event)}*/}
                      {/*  selectAllow={(args) => this.selectAllow(args)}*/}
                      {/*  eventRender={(event) => this.eventRender(event)}*/}
                      {/*  eventClick={(event) => this.handleEventClick(event)}*/}
                      {/*  dayRender={(event) => this.handleChangeDay(event, changedDate)}*/}
                      {/*  defaultDate={changedDate !== '' ? changedDate : currentDate}*/}
                      {/*/>*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CreateNewAppointmentModal
          open={isCreateNewAppointmentModalOpen && !isAppointmentPreviewShown}
          close={this.closeCreateNewAppointmentModal}
          onAppointmentCreated={this.onAppointmentCreated}
          sendAppointmentItem={this.getAppointmentItem}
          sendSaveAppointment={this.getSaveAppointment}
          calendarAppointmentItem={appointmentItem}
          openInviteCustomerModal={() => {}}
          customerOptions={[]}
        />
        <AppointmentPreview
          open={isAppointmentPreviewShown}
          onModalClose={() => this.setState({ isAppointmentPreviewShown: false })}
          appointmentItem={appointmentItem}
          saveAppointment={this.handleSaveAppointment}
        />
        <AppointmentActionsModal
          open={isAppointmentActionsModalOpen}
          onClose={this.onActionsModalClose}
          appointment={selectedAppointment}
          cancel={this.handleCancelAppointment}
          edit={this.handleEditAppointment}
          accept={this.handleAcceptAppointment}
          handleDelete={this.showDeleteAppointmentModal}
        />
        <DeleteAppointmentAlert
          open={isDeleteAppointmentAlertShown}
          cancel={() => this.setState({ isDeleteAppointmentAlertShown: false })}
          confirm={this.handleDeleteAppointment}
        />
        <SnackbarToast
          type={snackbarType}
          open={openSnackbar}
          message={snackbarMessage}
          onClose={() => this.setState({ openSnackbar: false })}
        />
        { alert }
        <SnackbarToast
          message={snackbarMessage}
          type={snackbarType}
          open={openSnackbar}
          onClose={() => this.setState({ openSnackbar: false })}
        />
      </div>
    ) : <CalendarLoading />;
  }
}

const mapStateToProps = (state) => ({
  // Get All Companies
  isGetAllCompaniesSuccess: state.company.isGetAllCompaniesSuccess,
  isGetAllCompaniesError: state.company.isGetAllCompaniesError,
  getAllCompaniesErrorMessage: state.company.getAllCompaniesErrorMessage,
  allCompanies: state.company.allCompanies,
  // Update Appointment
  isUpdateAppointmentSuccess: state.appointment.isUpdateAppointmentSuccess,
  isUpdateAppointmentError: state.appointment.isUpdateAppointmentError,
  updatedAppointment: state.appointment.updatedAppointment,
  updateAppointmentErrorMessage: state.appointment.updateAppointmentErrorMessage,
  // Delete Appointment
  isDeleteAppointmentSuccess: state.appointment.isDeleteAppointmentSuccess,
  isDeleteAppointmentError: state.appointment.isDeleteAppointmentError,
  deleteAppointmentErrorMessage: state.appointment.deleteAppointmentErrorMessage,
  // Accept Appointment
  isAcceptSuccess: state.appointment.isAcceptSuccess,
  isAcceptError: state.appointment.isAcceptError,
  acceptErrorMessage: state.appointment.acceptErrorMessage,
  // Cancel Appointment
  isCancelSuccess: state.appointment.isCancelSuccess,
  isCancelError: state.appointment.isCancelError,
  cancelErrorMessage: state.appointment.cancelErrorMessage,
  // Account
  userAccount: state.account.userAccount,
  // Get staff appointment
  isGetStaffCompanyAppointmentsSuccess: state.company.isGetStaffCompanyAppointmentsSuccess,
  isGetStaffCompanyAppointmentsError: state.company.isGetStaffCompanyAppointmentsError,
  staffCompanyAppointments: state.company.staffCompanyAppointments,
});

function mapDispatchToProps(dispatch) {
  return {
    deleteAppointment: (data) => dispatch(deleteAppointmentRequest(data)),
    accept: (data) => dispatch(acceptRequest(data)),
    cancel: (data) => dispatch(cancelRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar);
