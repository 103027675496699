import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteButton from '../../../../../../../MaterialComponents/deleteButton';

function BottomEditPart({
  appointmentLoading,
  handleUpdateAppointment,
  handleDelete,
  handleCancel,
  appointmentDeleteLoading,
  appointmentCancelLoading,
  statusName,
}) {
  const { t } = useTranslation();
  return (
    <div className="buttons-box content-between">
      <div className="btn">
        <DeleteButton
          variant="outlined"
          loading={appointmentDeleteLoading}
          startIcon={<DeleteIcon color="error" />}
          onClick={handleDelete}
        >
          {appointmentDeleteLoading && <CircularProgress color="white" size={20} />}
          {!appointmentDeleteLoading && t('Delete') }
        </DeleteButton>
      </div>
      {(statusName === 'accepted' || statusName === 'pending') && (
        <div className="right-button-content">
          <div className="create-btn">
            <Button
              onClick={handleCancel}
              loading={appointmentCancelLoading.toString()}
              data-cy="cancel"
              type="cancel"
              size="small"
              style={{
                borderRadius: 4,
                border: '1px solid #A4A4A4',
                backgroundColor: '#FFFFFF',
                padding: '6px 28px',
                font: 'normal 400 14px/19px Nunito Sans, sans-serif',
                color: '#A4A4A4',
                width: '100%',
                height: '100%',
              }}
            >
              {appointmentCancelLoading && <CircularProgress color="white" size={20} />}
              {t('CancelAppointment')}
            </Button>
          </div>
          <div className="create-btn">
            <Button
              size="small"
              onClick={handleUpdateAppointment}
              data-cy="save"
              type="submit"
              variant="contained"
              color="primary"
              loading={appointmentLoading.toString()}
              style={{
                borderRadius: 4,
                backgroundColor: '#0282DA',
                padding: '8px 16px',
                font: 'normal 400 14px/19px Nunito Sans, sans-serif',
                color: '#FFFFFF',
                width: '100%',
                height: '100%',
              }}
            >
              {appointmentLoading && <CircularProgress color="white" size={20} />}
              {t('Update')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

BottomEditPart.propTypes = {
  statusName: PropTypes.object.isRequired,
  appointmentLoading: PropTypes.bool.isRequired,
  appointmentDeleteLoading: PropTypes.bool.isRequired,
  appointmentCancelLoading: PropTypes.bool.isRequired,
  handleUpdateAppointment: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

export default BottomEditPart;
