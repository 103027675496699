import axios from 'axios';

const token = localStorage.getItem('token');
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';
axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;

axiosInstance.interceptors.request.use((config) => {
  // Set role prefix
  const supportedPrefixes = ['customer', 'staff', 'individual', 'owner'];
  if (localStorage.getItem('prefix')) {
    const prefix = localStorage.getItem('prefix').toLowerCase();
    if (supportedPrefixes.includes(prefix) && !axiosInstance.defaults.baseURL.includes(prefix)) {
      const baseUrl = process.env.REACT_APP_API_URL;
      config.baseURL = `${baseUrl}/${prefix}`;
    }
  }

  const token = localStorage.getItem('token');
  const currentAuthorization = config.headers.common.Authorization;
  config.headers['X-Platform'] = 'Web';
  if (currentAuthorization === 'Bearer null') {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    const oldToken = currentAuthorization.split(' ')[1];
    if (oldToken !== token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
});

axiosInstance.interceptors.response.use((response) => response, (error) => {
  if (
    error.response
    && error.response.status === 401
    && (
      error.response.data.message === 'Token has expired'
      || error.response.data.message === 'Unable to authenticate with invalid token.'
    || error.response.data.message === 'Token Signature could not be verified.'
    )
  ) {
    localStorage.removeItem('token');
    window.location.reload(false);
  }
  return error;
});

export default axiosInstance;
