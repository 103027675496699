import React from 'react';
import PropTypes from 'prop-types';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { IconButton, Badge } from '@material-ui/core';

function GetUnseenNotificationCountContent(props) {
  const {
    toggleNotificationsMenu,
    getUnseenNotificationCount,
    anchorRef,
  } = props;

  return (
    <>
      <IconButton onClick={toggleNotificationsMenu} ref={anchorRef} className="notification-icon-btn">
        <Badge badgeContent={getUnseenNotificationCount() && getUnseenNotificationCount()} overlap="rectangle" color="error">
          <NotificationsNoneIcon color="secondary" />
        </Badge>
      </IconButton>
    </>
  );
}
GetUnseenNotificationCountContent.propTypes = {
  toggleNotificationsMenu: PropTypes.func.isRequired,
  anchorRef: PropTypes.any.isRequired,
  getUnseenNotificationCount: PropTypes.func.isRequired,
};

export default GetUnseenNotificationCountContent;
