import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import i18n from 'i18next';
import DeleteButton from '../../../MaterialComponents/deleteButton';

function ConfirmDeleteInvitationModal(props) {
  const {
    open,
    onClose,
    handleConfirmDeleteInvitation,
    invitationId,
    companyId,
  } = props;
  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span className="alert-title">
            <span>{i18n.t('ConfirmDeletion')}</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {i18n.t('AreYouSureDelete')} invitation ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onClose}
          >
            {i18n.t('Cancel')}
          </Button>
          <DeleteButton
            size="small"
            variant="contained"
            color="primary"
            onClick={(e) => handleConfirmDeleteInvitation(e, invitationId, companyId)}
          >
            {i18n.t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDeleteInvitationModal.propTypes = {
  // Parent Props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleConfirmDeleteInvitation: PropTypes.func.isRequired,
  invitationId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
};

export default ConfirmDeleteInvitationModal;
