import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function CustomerIsolatedDropdown(props) {
  const {
    customerInvitation,
    showConfirmDeleteAlertContent,
  } = props;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        elevation={1}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        <Link
          className="card-box-item"
          to="/"
          onClick={(e) => {
            showConfirmDeleteAlertContent(e, customerInvitation.id, false);
            setAnchorEl(null);
          }}
        >
          <MenuItem>
            {t('CancelInvitation')}
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

CustomerIsolatedDropdown.propTypes = {
  customerInvitation: PropTypes.object.isRequired,
  showConfirmDeleteAlertContent: PropTypes.func.isRequired,
};

export default CustomerIsolatedDropdown;
