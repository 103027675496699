import { createAction } from 'redux-actions';

export const getCompanyStaffRequest = createAction('GET_COMPANY_STAFF_REQUEST');
export const getCompanyStaffSuccess = createAction('GET_COMPANY_STAFF_SUCCESS');
export const getCompanyStaffError = createAction('GET_COMPANY_STAFF_ERROR');

export const getCompanyStaffForSelectRequest = createAction('GET_COMPANY_STAFF_FOR_SELECT_REQUEST');
export const getCompanyStaffForSelectSuccess = createAction('GET_COMPANY_STAFF_FOR_SELECT_SUCCESS');
export const getCompanyStaffForSelectError = createAction('GET_COMPANY_STAFF_FOR_SELECT_ERROR');

export const getCompanyStaffHaveServiceRequest = createAction('GET_COMPANY_STAFF_HAVE_SERVICE_REQUEST');
export const getCompanyStaffHaveServiceSuccess = createAction('GET_COMPANY_STAFF_HAVE_SERVICE_SUCCESS');
export const getCompanyStaffHaveServiceError = createAction('GET_COMPANY_STAFF_HAVE_SERVICE_ERROR');

export const getSelectedStaffInfoRequest = createAction('GET_SELECTED_STAFF_INFO_REQUEST');
export const getSelectedStaffInfoSuccess = createAction('GET_SELECTED_STAFF_INFO_SUCCESS');
export const getSelectedStaffInfoError = createAction('GET_SELECTED_STAFF_INFO_ERROR');

export const getStaffPositionsRequest = createAction('GET_STAFF_POSITIONS_REQUEST');
export const getStaffPositionsSuccess = createAction('GET_STAFF_POSITIONS_SUCCESS');
export const getStaffPositionsError = createAction('GET_STAFF_POSITIONS_ERROR');

export const inviteStaffToCompanyRequest = createAction('INVITE_STAFF_TO_COMPANY_REQUEST');
export const inviteStaffToCompanySuccess = createAction('INVITE_STAFF_TO_COMPANY_SUCCESS');
export const inviteStaffToCompanyError = createAction('INVITE_STAFF_TO_COMPANY_ERROR');
export const revertInviteStaffToCompanyToDefault = createAction('REVERT_INVITE_STAFF_TO_COMPANY_TO_DEFAULT');

export const addProfessionRequest = createAction('ADD_PROFESSION_REQUEST');
export const addProfessionSuccess = createAction('ADD_PROFESSION_SUCCESS');
export const addProfessionError = createAction('ADD_PROFESSION_ERROR');

export const addStaffToCompanyRequest = createAction('ADD_STAFF_TO_COMPANY_REQUEST');
export const addStaffToCompanySuccess = createAction('ADD_STAFF_TO_COMPANY_SUCCESS');
export const addStaffToCompanyError = createAction('ADD_STAFF_TO_COMPANY_ERROR');
export const revertAddStaffToCompanyToDefault = createAction('REVERT_ADD_STAFF_TO_COMPANY_TO_DEFAULT');

export const deleteStaffRequest = createAction('DELETE_STAFF_REQUEST');
export const deleteStaffSuccess = createAction('DELETE_STAFF_SUCCESS');
export const deleteStaffError = createAction('DELETE_STAFF_ERROR');
export const revertDeleteStaffToDefault = createAction('REVERT_DELETE_STAFF_TO_DEFAULT');

export const updateStaffRequest = createAction('UPDATE_STAFF_REQUEST');
export const updateStaffSuccess = createAction('UPDATE_STAFF_SUCCESS');
export const updateStaffError = createAction('UPDATE_STAFF_ERROR');
export const revertUpdateStaffToDefault = createAction('REVERT_UPDATE_STAFF_TO_DEFAULT');

export const updateInvitationRequest = createAction('UPDATE_INVITATION_REQUEST');
export const updateInvitationSuccess = createAction('UPDATE_INVITATION_SUCCESS');
export const updateInvitationError = createAction('UPDATE_INVITATION_ERROR');
export const revertUpdateInvitationToDefault = createAction('REVERT_UPDATE_INVITATION_TO_DEFAULT');

export const deleteStaffInvitationRequest = createAction('DELETE_STAFF_INVITATION_REQUEST');
export const deleteStaffInvitationSuccess = createAction('DELETE_STAFF_INVITATION_SUCCESS');
export const deleteStaffInvitationError = createAction('DELETE_STAFF_INVITATION_ERROR');
export const revertDeleteInvitationToDefault = createAction('REVERT_DELETE_INVITATION_TO_DEFAULT');

export const updateStaffPermissionsRequest = createAction('UPDATE_STAFF_PERMISSIONS_REQUEST');
export const updateStaffPermissionsSuccess = createAction('UPDATE_STAFF_PERMISSIONS_SUCCESS');
export const updateStaffPermissionsError = createAction('UPDATE_STAFF_PERMISSIONS_ERROR');
export const revertStaffPermissionsToDefault = createAction('REVERT_STAFF_PERMISSIONS_TO_DEFAULT');

export const getStaffAvailableTimesRequest = createAction('GET_STAFF_AVAILABLE_TIMES_REQUEST');
export const getStaffAvailableTimesSuccess = createAction('GET_STAFF_AVAILABLE_TIMES_SUCCESS');
export const getStaffAvailableTimesError = createAction('GET_STAFF_AVAILABLE_TIMES_ERROR');

export const acceptStaffInvitationRequest = createAction('ACCEPT_STAFF_INVITATION_REQUEST');
export const acceptStaffInvitationSuccess = createAction('ACCEPT_STAFF_INVITATION_SUCCESS');
export const acceptStaffInvitationError = createAction('ACCEPT_STAFF_INVITATION_ERROR');

export const inviteExistingStaffRequest = createAction('INVITE_EXISTING_STAFF_REQUEST');
export const inviteExistingStaffSuccess = createAction('INVITE_EXISTING_STAFF_SUCCESS');
export const inviteExistingStaffError = createAction('INVITE_EXISTING_STAFF_ERROR');
export const revertInviteExistingStaffToDefault = createAction('REVERT_INVITE_EXISTING_STAFF_TO_DEFAULT');

export const resendStaffInvitationRequest = createAction('RESEND_STAFF_INVITATIONS_REQUEST');
export const resendStaffInvitationSuccess = createAction('RESEND_STAFF_INVITATIONS_SUCCESS');
export const resendStaffInvitationError = createAction('RESEND_STAFF_INVITATIONS_ERROR');

export const filterStaffOptionsRequest = createAction('FILTER_STAFF_OPTIONS_REQUEST');
export const filterStaffOptionsSuccess = createAction('FILTER_STAFF_OPTIONS_SUCCESS');
export const filterStaffOptionsError = createAction('FILTER_STAFF_OPTIONS_ERROR');

export const getStaffCurrentServicesRequest = createAction('GET_STAFF_CURRENT_SERVICES_REQUEST');
export const getStaffCurrentServicesSuccess = createAction('GET_STAFF_CURRENT_SERVICES_SUCCESS');
export const getStaffCurrentServicesError = createAction('GET_STAFF_CURRENT_SERVICES_ERROR');

export const getCalendarCompanyStaffRequest = createAction('GET_CALENDAR_COMPANY_STAFF_REQUEST');
export const getCalendarCompanyStaffSuccess = createAction('GET_CALENDAR_COMPANY_STAFF_SUCCESS');
export const getCalendarCompanyStaffError = createAction('GET_CALENDAR_COMPANY_STAFF_ERROR');

export const getStaffUpcomingAppointmentsRequest = createAction('GET_STAFF_UPCOMING_APPOINTMENTS_REQUEST');
export const getStaffUpcomingAppointmentsSuccess = createAction('GET_STAFF_UPCOMING_APPOINTMENTS_SUCCESS');
export const getStaffUpcomingAppointmentsError = createAction('GET_STAFF_UPCOMING_APPOINTMENTS_ERROR');

export const getStaffAppointmentsHistoryRequest = createAction('GET_STAFF_APPOINTMENTS_HISTORY_REQUEST');
export const getStaffAppointmentsHistorySuccess = createAction('GET_STAFF_APPOINTMENTS_HISTORY_SUCCESS');
export const getStaffAppointmentsHistoryError = createAction('GET_STAFF_APPOINTMENTS_HISTORY_ERROR');
