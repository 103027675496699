import React from 'react';

function Statistics() {
  return (
    <div>
      Statistics
    </div>
  );
}

export default Statistics;
