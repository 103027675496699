import { takeLatest, put, takeLeading } from 'redux-saga/effects';
import axios from 'axios';
import {
  getUserAccountRequest,
  getUserAccountSuccess,
  getUserAccountError,
  verifyEmailRequest,
  verifyEmailSuccess,
  verifyEmailError,
  verifyPhoneRequest,
  verifyPhoneSuccess,
  verifyPhoneError,
  savePersonalDetailsRequest,
  savePersonalDetailsError,
  savePersonalDetailsSuccess,
  saveBusinessDetailsRequest,
  saveBusinessDetailsError,
  saveBusinessDetailsSuccess,
  finishCompanyOnBoardingRequest,
  finishCompanyOnBoardingSuccess,
  finishCompanyOnBoardingError,
  createNewRoleAccountError,
  createNewRoleAccountSuccess,
  createNewRoleAccountRequest,
  switchToAccountRequest,
  switchToAccountSuccess,
  switchToAccountError,
  updateUserAccountRequest,
  updateUserAccountSuccess,
  updateUserAccountError,
  updateAccountAvatarRequest,
  updateAccountAvatarSuccess,
  updateAccountAvatarError,
  finishIndividualOnBoardingRequest,
  finishIndividualOnBoardingSuccess,
  finishIndividualOnBoardingError,
  sendVerificationCodeError,
  sendVerificationCodeSuccess,
  sendVerificationCodeRequest,
  sendVerificationLinkError,
  sendVerificationLinkSuccess,
  sendVerificationLinkRequest,
  addAccountTypeRequest,
  addAccountTypeSuccess,
  addAccountTypeError,
  changePasswordRequest,
  changePasswordSuccess,
  changePasswordError,
  getUserSettingsRequest,
  getUserSettingsSuccess,
  getUserSettingsError,
  updateUserSettingsRequest,
  updateUserSettingsSuccess,
  updateUserSettingsError,
  getUserAccountNotificationsRequest,
  getUserAccountNotificationsSuccess,
  getUserAccountNotificationsError,
  deactivateRoleRequest,
  deactivateRoleSuccess,
  deactivateRoleError,
  syncGoogleCalendarRequest,
  syncGoogleCalendarSuccess,
  syncGoogleCalendarError,
  authenticateGoogleAccountRequest,
  authenticateGoogleAccountSuccess,
  authenticateGoogleAccountError,
  getAvatarPreSignUrlRequest,
  getAvatarPreSignUrlSuccess,
  getAvatarPreSignUrlError,
  uploadAvatarToAmazonRequest,
  uploadAvatarToAmazonSuccess,
  uploadAvatarToAmazonError,
  checkUnsubscribeTokenExistRequest,
  checkUnsubscribeTokenExistSuccess,
  checkUnsubscribeTokenExistError,
  unsubscribeSuccess,
  unsubscribeError,
  unsubscribeRequest,
  getUserLocationSuccess,
  getUserLocationRequest,
  getUserLocationError,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

function* getAccount() {
  try {
    const response = yield axiosInstance.get('account');
    if (response && response.status === 200) {
      yield put(getUserAccountSuccess(response.data));
    }
  } catch (e) {
    yield put(getUserAccountError());
  }
}

function* updateUserAccount(action) {
  const {
    first_name,
    last_name,
    country,
    state,
    city,
    phone_code,
    phone_number,
    gender,
    birthday,
    timezone,
    aws_avatar,
  } = action.payload;
  try {
    const response = yield axiosInstance.put('account/update', {
      aws_avatar,
      first_name,
      last_name,
      country_id: country,
      state_id: state,
      city_id: city,
      gender,
      birthday,
      phone_code,
      phone_number,
      timezone,
      full_phone_number: (`${phone_code} ${phone_number}`).replace(/\D/g, ''),
    });
    if (response && response.status === 200) {
      yield put(updateUserAccountSuccess(response.data));
    } else {
      yield put(updateUserAccountError(response.response.data));
    }
  } catch (e) {
    yield put(updateUserAccountError());
  }
}

function* updateAccountAvatar(action) {
  const { payload } = action;
  try {
    const formData = new FormData();
    formData.append('avatar', payload);
    const response = yield axiosInstance.post('account/update-avatar', payload ? formData : null);
    if (response && response.status === 200) {
      yield put(updateAccountAvatarSuccess(response.data));
    } else {
      yield put(updateAccountAvatarError(response.response.data));
    }
  } catch (e) {
    yield put(updateAccountAvatarError(e.response.data));
  }
}

function* verifyEmail(action) {
  const { email, token } = action.payload;
  try {
    const response = yield axiosInstance.put('account/verify-email', {
      email,
      token,
    });
    if (response && response.status === 202) {
      yield put(verifyEmailSuccess());
    } else {
      yield put(verifyEmailError(response.response.data));
    }
  } catch (e) {
    yield put(verifyEmailError());
  }
}

// function* getUserLocation() {
//   try {
//     const response = yield axios.get('http://ip-api.com/json/');
//     if (response && response.status === 200) {
//       yield put(getUserLocationSuccess(response.data));
//     }
//   } catch (e) {
//     yield put(getUserLocationError());
//   }
// }

function* verifyPhone(action) {
  const { confirmationCode } = action.payload;
  try {
    const response = yield axiosInstance.put('account/verify-phone', {
      token: confirmationCode,
    });
    if (response && response.status === 202) {
      yield put(verifyPhoneSuccess());
    } else {
      yield put(verifyPhoneError());
    }
  } catch (e) {
    yield put(verifyPhoneError());
  }
}

function* savePersonalDetails(action) {
  try {
    const {
      firstName,
      lastName,
      phoneCode,
      phoneNumber,
      birthDay,
      gender,
      countryId,
      timezone,
    } = action.payload;
    const response = yield axiosInstance.put('account/personal-details', {
      first_name: firstName,
      last_name: lastName,
      phone_code: phoneCode,
      phone_number: phoneNumber,
      birthday: birthDay && birthDay.length ? birthDay : null,
      gender,
      full_phone_number: (`${phoneCode} ${phoneNumber}`).replace(/\D/g, ''),
      country_id: countryId,
      timezone,
    });

    if (response && response.status === 200) {
      yield put(savePersonalDetailsSuccess(response.data));
    } else {
      yield put(savePersonalDetailsError(response.response.data));
    }
  } catch (e) {
    yield put(savePersonalDetailsError());
  }
}

function* saveBusinessDetails(action) {
  try {
    const {
      name,
      industryId,
      countryId,
      stateId,
      cityId,
      address,
      phoneCode,
      phoneNumber,
      tin,
      zip,
      website,
      facebook,
      instagram,
    } = action.payload;
    const response = yield axiosInstance.post('owner/company', {
      name,
      industry_id: industryId,
      country_id: countryId,
      state_id: stateId,
      city_id: cityId,
      address,
      phone_code: phoneCode,
      phone_number: phoneNumber,
      tin,
      zip_code: zip || null,
      website_url: website,
      facebook_url: facebook,
      instagram_url: instagram,
    });

    if (response && response.status === 200) {
      yield put(saveBusinessDetailsSuccess(response.data));
    } else {
      yield put(saveBusinessDetailsError(response.response.data));
    }
  } catch (e) {
    yield put(saveBusinessDetailsError());
  }
}

function* finishCompanyOnBoarding() {
  try {
    const response = yield axiosInstance.get('company/onboard/finish');
    if (response && response.status === 202) {
      yield put(finishCompanyOnBoardingSuccess());
    }
  } catch (e) {
    yield put(finishCompanyOnBoardingError(e.response.data));
  }
}

function* createNewRoleAccount(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('create-new-account', payload);
    if (response && response.status === 200) {
      yield put(createNewRoleAccountSuccess(response.data));
    } else {
      yield put(createNewRoleAccountError(response.response.data));
    }
  } catch (e) {
    yield put(createNewRoleAccountError(e.response.data));
  }
}

function* switchToAccount(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('switch-role', payload);
    if (response && response.status === 202) {
      yield put(switchToAccountSuccess());
    } else {
      yield put(switchToAccountError(response.response.data));
    }
  } catch (e) {
    yield put(switchToAccountError(e.response.data));
  }
}

function* finishIndividualOnBoarding() {
  try {
    const response = yield axiosInstance.put('account/individual-onboard');
    if (response && response.status === 202) {
      yield put(finishIndividualOnBoardingSuccess());
    } else {
      yield put(finishIndividualOnBoardingError(response.response.data));
    }
  } catch (e) {
    yield put(finishIndividualOnBoardingError(e.response.data));
  }
}

function* sendVerificationCode() {
  try {
    const response = yield axiosInstance.get('account/resend-code');
    if (response && response.status === 200) {
      yield put(sendVerificationCodeSuccess(response.data));
    } else {
      yield put(sendVerificationCodeError(response.response.data));
    }
  } catch (e) {
    yield put(sendVerificationCodeError(e.response.data));
  }
}

function* sendVerificationLink(action) {
  const { email } = action.payload;
  try {
    const response = yield axiosInstance.get(`resend-link/${email}`);
    if (response && response.status === 200) {
      yield put(sendVerificationLinkSuccess(response.data));
    } else {
      yield put(sendVerificationLinkError(response.response.data));
    }
  } catch (e) {
    yield put(sendVerificationLinkError(e.response.data));
  }
}

function* addAccountType(action) {
  try {
    const { type, isUpdate } = action.payload;
    const url = `account-type/${isUpdate ? 'update' : 'add'}/${type}`;

    const response = yield axiosInstance.put(url);
    if (response && response.status === 200) {
      yield put(addAccountTypeSuccess(response.data));
    } else {
      yield put(addAccountTypeError(response.data));
    }
  } catch (e) {
    yield put(addAccountTypeError(e.response.data));
  }
}

function* changePassword(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.put('account/change-password', payload);
    if (response && response.status === 204) {
      yield put(changePasswordSuccess());
    } else {
      yield put(changePasswordError(response.data));
    }
  } catch (e) {
    yield put(changePasswordError(e.response.data));
  }
}

function* getSettings() {
  try {
    const response = yield axiosInstance.get('user/settings');
    if (response && response.status === 200) {
      yield put(getUserSettingsSuccess(response.data));
    }
  } catch (e) {
    yield put(getUserSettingsError());
  }
}

function* updateUserSettings(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.put('user/settings', payload);
    if (response && response.status === 200) {
      yield put(updateUserSettingsSuccess(response.data));
    } else {
      yield put(updateUserSettingsError(response.response.data));
    }
  } catch (e) {
    yield put(updateUserSettingsError(e.response.data));
  }
}

function* getAccountNotifications(action) {
  try {
    const { page } = action.payload;
    const response = yield axiosInstance.get(`account/notifications?page=${page}`);
    if (response && response.status === 200) {
      yield put(getUserAccountNotificationsSuccess(response.data));
    }
  } catch (e) {
    yield put(getUserAccountNotificationsError());
  }
}

function* deactivateUserRole(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('account/user-account', payload);
    if (response && response.status === 200) {
      yield put(deactivateRoleSuccess());
    } else {
      yield put(deactivateRoleError());
    }
  } catch (e) {
    yield put(deactivateRoleError());
  }
}

function* syncWithGoogle() {
  try {
    const response = yield axiosInstance.get('google-calendar/sync');
    if (response && response.status === 200) {
      yield put(syncGoogleCalendarSuccess(response.data));
    } else {
      yield put(syncGoogleCalendarError());
    }
  } catch (e) {
    yield put(syncGoogleCalendarError());
  }
}

function* authenticateGoogleAccount({ payload }) {
  try {
    const response = yield axiosInstance.get(`google-calendar/authenticate?code=${payload.code}`);
    if (response && response.status === 202) {
      yield put(authenticateGoogleAccountSuccess());
    } else {
      yield put(authenticateGoogleAccountError());
    }
  } catch (e) {
    yield put(authenticateGoogleAccountError());
  }
}
function* getAvatarPreSignUrl(action) {
  const url = `account/pre-sign-url?&logo=${action.payload.logo}`;
  try {
    const response = yield axiosInstance.get(url);
    if (response && response.status && response.status === 200) {
      yield put(getAvatarPreSignUrlSuccess(response.data));
    } else {
      yield put(getAvatarPreSignUrlError());
    }
  } catch (e) {
    yield put(getAvatarPreSignUrlError());
  }
}
function* uploadAvatarToAmazon(action) {
  const url = action.payload.preSignUrl;
  const { logo } = action.payload;
  const config = {
    method: 'PUT',
    url,
    data: logo,
    headers: { 'Content-Type': logo.type },
  };
  try {
    const response = yield axios(config);
    if (response && response.status === 200) {
      yield put(uploadAvatarToAmazonSuccess());
    } else {
      yield put(uploadAvatarToAmazonError());
    }
  } catch (e) {
    yield put(uploadAvatarToAmazonError());
  }
}

function* checkUnsubscribeToken(action) {
  const { token } = action.payload;
  try {
    const response = yield axiosInstance.post('check/unsubscribe', { token });
    if (response && response.status === 202) {
      yield put(checkUnsubscribeTokenExistSuccess());
    } else {
      yield put(checkUnsubscribeTokenExistError(response.response.data));
    }
  } catch (e) {
    yield put(checkUnsubscribeTokenExistError());
  }
}

function* unsubscribe(action) {
  const { token } = action.payload;
  try {
    const response = yield axiosInstance.post('unsubscribe', { token });
    if (response && response.status === 202) {
      yield put(unsubscribeSuccess());
    } else {
      yield put(unsubscribeError(response.response.data));
    }
  } catch (e) {
    yield put(unsubscribeError());
  }
}

function* getUserLocation() {
  try {
    const response = yield axiosInstance.post('real-country');
    if (response && response.status === 200) {
      yield put(getUserLocationSuccess(response.data));
    }
  } catch (e) {
    yield put(getUserLocationError);
  }
}

export default function* () {
  yield takeLatest(getUserAccountRequest, getAccount);
  yield takeLatest(updateUserAccountRequest, updateUserAccount);
  yield takeLatest(updateAccountAvatarRequest, updateAccountAvatar);
  yield takeLatest(verifyEmailRequest, verifyEmail);
  // yield takeLatest(getUserLocationRequest, getUserLocation);
  yield takeLatest(verifyPhoneRequest, verifyPhone);
  yield takeLatest(savePersonalDetailsRequest, savePersonalDetails);
  yield takeLatest(saveBusinessDetailsRequest, saveBusinessDetails);
  yield takeLatest(finishCompanyOnBoardingRequest, finishCompanyOnBoarding);
  yield takeLatest(createNewRoleAccountRequest, createNewRoleAccount);
  yield takeLatest(switchToAccountRequest, switchToAccount);
  yield takeLatest(finishIndividualOnBoardingRequest, finishIndividualOnBoarding);
  yield takeLatest(sendVerificationCodeRequest, sendVerificationCode);
  yield takeLatest(sendVerificationLinkRequest, sendVerificationLink);
  yield takeLatest(addAccountTypeRequest, addAccountType);
  yield takeLatest(changePasswordRequest, changePassword);
  yield takeLatest(getUserSettingsRequest, getSettings);
  yield takeLatest(updateUserSettingsRequest, updateUserSettings);
  yield takeLeading(getUserAccountNotificationsRequest, getAccountNotifications);
  yield takeLatest(deactivateRoleRequest, deactivateUserRole);
  yield takeLatest(syncGoogleCalendarRequest, syncWithGoogle);
  yield takeLatest(authenticateGoogleAccountRequest, authenticateGoogleAccount);
  yield takeLatest(getAvatarPreSignUrlRequest, getAvatarPreSignUrl);
  yield takeLatest(uploadAvatarToAmazonRequest, uploadAvatarToAmazon);
  yield takeLatest(checkUnsubscribeTokenExistRequest, checkUnsubscribeToken);
  yield takeLatest(unsubscribeRequest, unsubscribe);
  yield takeLatest(getUserLocationRequest, getUserLocation);
}
