import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import i18n from 'i18next';
import {
  staffSpecialDayRequest,
  updateStaffSpecialDayRequest,
} from '../../../redux/company/actions';
import usePrevious from '../../../CustomHooks/usePrevious';
import splitSpaces from '../../../Modules/splitSpaces';
import AutocompleteSelectLimitTags from '../../../Modules/AutocompleteSelectLimitTags';
import SnackbarToast from '../../../Modules/SnackbarToast';

function WorkDayModal(props) {
  const {
    open,
    onClose,
    handleUpdateCompanyState,
    specificDay,
    buttonLoading,
    setButtonLoading,
    staffOptions,
    allSpecialDays,
    setAllSpecialDays,
    addStaffSpecialDay,
    isStaffSpecialDaySuccess,
    isStaffSpecialDayError,
    storeStaffSpecialDayErrors,
    storedStaffSpecialDay,
    updateStaffSpecialDay,
    isUpdateStaffSpecialDaySuccess,
    isUpdateStaffSpecialDayError,
    updateStaffSpecialDayErrors,
    updatedStaffSpecialDay,
  } = props;

  const date = new Date();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isBreaking, setIsBreaking] = useState(false);
  const [updateErrors, setUpdateErrors] = useState({});
  const [errorBreakingTime, setErrorBreakingTime] = useState({});
  const [errorWorkingTime, setErrorWorkingTime] = useState({});
  const [errorDate, setErrorDate] = useState('');
  const [errors, setErrors] = useState({
    start_date: false,
    end_date: false,
    start_time: false,
    end_time: false,
    staff_list: false,
    break_start_time: false,
    break_end_time: false,
  });
  const [storeErrors, setStoreErrors] = useState({});
  const [workDayData, setWorkDayData] = useState({
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    staff_list: [],
    description: '',
    break_start_time: null,
    break_end_time: null,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous value
  const prevIsStaffSpecialDaySuccess = usePrevious(isStaffSpecialDaySuccess);
  const prevIsStaffSpecialDayError = usePrevious(isStaffSpecialDayError);
  const prevIsUpdateStaffSpecialDaySuccess = usePrevious(isUpdateStaffSpecialDaySuccess);
  const prevIsUpdateStaffSpecialDayError = usePrevious(isUpdateStaffSpecialDayError);

  // Get Specific Off data
  useEffect(() => {
    const keys = Object.keys(specificDay);
    const valueExists = keys.find((key) => specificDay[key]);
    if (valueExists) {
      setWorkDayData(specificDay);

      if (specificDay.break_start_time) {
        setIsBreaking(true);
      }
    } else {
      setWorkDayData({
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
        staff_list: [],
        description: '',
        break_start_time: null,
        break_end_time: null,
      });
      setIsBreaking(false);
      setErrors({});
      setUpdateErrors({});
      setStoreErrors({});
      setSelectedOptions([]);
      setErrorBreakingTime({});
      setErrorWorkingTime({});
      setErrorDate('');
    }
  }, [specificDay]);

  // Handle Store Special Day success
  useEffect(() => {
    if (prevIsStaffSpecialDaySuccess === false && isStaffSpecialDaySuccess) {
      snackBarAlert(true, i18n.t('SpecificDayAdded'), 'success');
      const specialDaysCopy = [...storedStaffSpecialDay];
      setAllSpecialDays(specialDaysCopy);
      handleUpdateCompanyState('staffSpecialDays', specialDaysCopy);
      setWorkDayData({
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
        break_start_time: null,
        break_end_time: null,
        staff_list: [],
        description: '',
      });
      setSelectedOptions([]);
      setStoreErrors({});
      onClose();
    }
  }, [isStaffSpecialDaySuccess]);

  // Handle Store Special Day error
  useEffect(() => {
    if (prevIsStaffSpecialDayError === false && isStaffSpecialDayError) {
      setStoreErrors(storeStaffSpecialDayErrors);
    }
  }, [isStaffSpecialDayError]);

  // Handle Update Special Day success
  useEffect(() => {
    if (prevIsUpdateStaffSpecialDaySuccess === false && isUpdateStaffSpecialDaySuccess) {
      const specialDaysCopy = [...allSpecialDays];
      const specialDayIndex = specialDaysCopy.findIndex((item) => item.id === updatedStaffSpecialDay.id);
      specialDaysCopy.splice(specialDayIndex, 1);
      specialDaysCopy.push(updatedStaffSpecialDay);
      setAllSpecialDays(specialDaysCopy);
      handleUpdateCompanyState('staffSpecialDays', specialDaysCopy);
      setUpdateErrors({});
      setButtonLoading(true);
      onClose();
      snackBarAlert(true, i18n.t('SpecificDayUpdated'), 'success');
    }
  }, [isUpdateStaffSpecialDaySuccess]);

  useEffect(() => {
    if (prevIsUpdateStaffSpecialDayError === false && isUpdateStaffSpecialDayError) {
      setUpdateErrors(updateStaffSpecialDayErrors);
    }
  }, [isUpdateStaffSpecialDayError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const useStyles = makeStyles(() => ({
    error: {
      borderColor: '#f44336',
    },
  }));

  const updateWorkDayData = (name, value) => {
    setWorkDayData({
      ...workDayData,
      [name]: value,
    });
  };

  const validateForm = (add) => {
    const errorsCopy = { ...errors };
    errorsCopy.start_date = workDayData.start_date === '';
    errorsCopy.end_date = workDayData.end_date === '';
    errorsCopy.start_time = workDayData.start_time === '';
    errorsCopy.end_time = workDayData.end_time === '';
    if (add) {
      errorsCopy.staff_list = selectedOptions.length === 0;
    } else {
      errorsCopy.staff_list = false;
    }
    if (isBreaking) {
      errorsCopy.break_start_time = workDayData.break_start_time === null;
      errorsCopy.break_end_time = workDayData.break_end_time === null;
    } else {
      errorsCopy.break_start_time = false;
      errorsCopy.break_end_time = false;
    }
    setErrors(() => ({ ...errorsCopy }));
    return (errorsCopy.start_date || errorsCopy.end_date || errorsCopy.start_time || errorsCopy.end_time || errorsCopy.staff_list || errorsCopy.break_start_time || errorsCopy.break_end_time);
  };

  const handleAddWorkDay = () => {
    if (!validateForm(true)) {
      const workDayDataCopy = { ...workDayData };
      const staffIds = [];
      selectedOptions.map((staff) => {
        staffIds.push(staff.id);
      });
      workDayDataCopy.description = splitSpaces(workDayDataCopy.description);
      workDayDataCopy.staff_list = staffIds;
      setButtonLoading(false);
      addStaffSpecialDay(workDayDataCopy);
    }
  };

  const handleUpdateWorkDay = () => {
    if (!validateForm()) {
      const workDayDataCopy = { ...workDayData };

      if (!isBreaking) {
        workDayDataCopy.break_start_time = null;
        workDayDataCopy.break_end_time = null;
      }
      workDayDataCopy.description = splitSpaces(workDayDataCopy.description);
      setButtonLoading(false);
      updateStaffSpecialDay(workDayDataCopy);
    }
  };

  const handleChangeDays = (e) => {
    const { name, value } = e.target;
    setErrorDate('');
    if (name === 'start_date') {
      setWorkDayData({
        ...workDayData,
        start_date: value,
        end_date: value,
      });
    } else if (name === 'end_date') {
      const startDate = moment(workDayData.start_date, 'Y-MM-DD').format('Y-MM-DD HH:mm a');
      const endDate = moment(value, 'Y-MM-DD').format('Y-MM-DD HH:mm a');
      if (startDate < endDate) {
        updateWorkDayData('end_date', value);
      } else {
        setErrorDate('End date should be after start date');
      }
    }
  };

  const handleChangeTimes = (e) => {
    const { name, value } = e.target;
    setErrorWorkingTime({});
    if (name === 'start_time') {
      const startTime = moment(value, 'HH:mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(workDayData.end_time, 'HH:mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        updateWorkDayData('start_time', value);
      } else {
        setErrorWorkingTime({
          start_time: i18n.t('StartTimeEarlier'),
        });
      }
    } else if (name === 'end_time') {
      const startTime = moment(workDayData.start_time, 'HH:mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(value, 'HH:mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        updateWorkDayData('end_time', value);
      } else {
        setErrorWorkingTime({
          end_time: i18n.t('EndTimeAfter'),
        });
      }
    }
  };

  const handleChangeBreaking = (e) => {
    setIsBreaking(e.target.checked);
  };

  const handleChangeBreakingTimes = (e) => {
    const { name, value } = e.target;
    const breakingTime = moment(value, 'HH:mm').format('Y-MM-DD HH:mm a');
    const startTime = moment(workDayData.start_time, 'HH:mm').format('Y-MM-DD HH:mm a');
    const endTime = moment(workDayData.end_time, 'HH:mm').format('Y-MM-DD HH:mm a');
    setErrorBreakingTime({});
    if (breakingTime >= startTime && breakingTime <= endTime) {
      if (name === 'break_start_time') {
        const breakingStartTime = moment(value, 'HH:mm').format('Y-MM-DD HH:mm a');
        const breakingEndTime = moment(workDayData.break_end_time, 'HH:mm').format('Y-MM-DD HH:mm a');
        if (breakingStartTime < breakingEndTime) {
          updateWorkDayData('break_start_time', value);
        } else {
          setErrorBreakingTime({
            break_start_time: 'Breaking start time should be before breaking end time',
          });
        }
      } else if (name === 'break_end_time') {
        const breakingStartTime = moment(workDayData.break_start_time, 'HH:mm').format('Y-MM-DD HH:mm a');
        const breakingEndTime = moment(value, 'HH:mm').format('Y-MM-DD HH:mm a');
        if (breakingStartTime < breakingEndTime) {
          updateWorkDayData('break_end_time', value);
        } else {
          setErrorBreakingTime({
            break_end_time: i18n.t('BreakingTimeAfter'),
          });
        }
      }
    } else {
      setErrorBreakingTime({
        break_start_time: 'Choose an hour between the start time and end time',
      });
    }
  };

  const classes = useStyles();

  const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions);
  const handleClearOptions = () => setSelectedOptions([]);
  const handleSelectAll = (isSelected) => {
    if (isSelected) {
      setSelectedOptions(staffOptions);
    } else {
      handleClearOptions();
    }
  };

  const getOptionLabel = (option) => `${option.label}`;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              <span>{workDayData.id ? i18n.t('EditSpecificWorkDays') : i18n.t('AddSpecificWorkDays')}</span>
            </Typography>
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.start_date || !!storeErrors.start_date || !!updateErrors.start_date}
                size="small"
                variant="outlined"
              >
                <TextField
                  label="Start date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.start_date || !!storeErrors.start_date || !!updateErrors.start_date}
                  size="small"
                  name="start_date"
                  onChange={(e) => handleChangeDays(e)}
                  variant="outlined"
                  value={workDayData.start_date || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd'), max: '9999-12-31', autoComplete: 'new-password' }}
                />

                { errors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      Start date is required.
                    </FormHelperText>
                  </div>
                )}

                { storeErrors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.start_date}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.start_date}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.end_date || !!storeErrors.end_date || !!updateErrors.end_date || !!errorDate}
                size="small"
                variant="outlined"
              >
                <TextField
                  label="End date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.end_date || !!storeErrors.end_date || !!updateErrors.end_date || !!errorDate}
                  size="small"
                  name="end_date"
                  onChange={(e) => handleChangeDays(e)}
                  variant="outlined"
                  value={workDayData.end_date || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd'), max: '9999-12-31', autoComplete: 'new-password' }}
                />

                { errors.end_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      End date is required.
                    </FormHelperText>
                  </div>
                )}

                { storeErrors.end_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.end_date}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.end_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.end_date}
                    </FormHelperText>
                  </div>
                )}

                { errorDate && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {errorDate}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.start_time || !!storeErrors.start_time || !!updateErrors.start_time || !!errorWorkingTime.start_time}
                size="small"
                variant="outlined"
              >
                <TextField
                  label="Start time"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.start_time || !!storeErrors.start_time || !!updateErrors.start_time || !!errorWorkingTime.start_time}
                  size="small"
                  name="start_time"
                  onChange={(e) => handleChangeTimes(e)}
                  variant="outlined"
                  value={workDayData.start_time || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd'), autoComplete: 'new-password' }}
                />
                { errors.start_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      Start time is required.
                    </FormHelperText>
                  </div>
                )}

                { storeErrors.start_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.start_time}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.start_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.start_time}
                    </FormHelperText>
                  </div>
                )}

                { errorWorkingTime.start_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {errorWorkingTime.start_time}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.end_time || !!storeErrors.end_time || !!updateErrors.end_time || !!errorWorkingTime.end_time}
                size="small"
                variant="outlined"
              >
                <TextField
                  label="End time"
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.end_time || !!storeErrors.end_time || !!updateErrors.end_time || !!errorWorkingTime.end_time}
                  size="small"
                  name="end_time"
                  onChange={(e) => handleChangeTimes(e)}
                  variant="outlined"
                  value={workDayData.end_time || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd'), autoComplete: 'new-password' }}
                />

                { errors.end_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      End date is required.
                    </FormHelperText>
                  </div>
                )}

                { storeErrors.end_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.end_time}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.end_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.end_time}
                    </FormHelperText>
                  </div>
                )}

                { errorWorkingTime.end_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {errorWorkingTime.end_time}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid item component="label" xs="auto">
                <FormControlLabel
                  value="breaking"
                  control={(
                    <Switch
                      checked={isBreaking}
                      onChange={handleChangeBreaking}
                      name="is_breaking"
                      color="primary"
                      size="small"
                    />
                  )}
                  label="Break"
                  labelPlacement="top"
                />
              </Grid>
            </Grid>
            {isBreaking && (
              <>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.break_start_time || !!errorBreakingTime.break_start_time}
                    size="small"
                    variant="outlined"
                  >
                    <TextField
                      fullWidth
                      error={!!errors.break_start_time || !!errorBreakingTime.break_start_time}
                      label="Start Time"
                      size="small"
                      type="time"
                      name="break_start_time"
                      onChange={(e) => handleChangeBreakingTimes(e)}
                      variant="outlined"
                      value={workDayData.break_start_time || ''}
                      inputProps={{ min: format(date, 'yyyy-MM-dd'), autoComplete: 'new-password' }}
                      inputlabelprops={{
                        shrink: true,
                      }}
                    />

                    { errors.break_start_time && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          Breaking start time is required.
                        </FormHelperText>
                      </div>
                    )}

                    {errorBreakingTime.break_start_time && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          { errorBreakingTime.break_start_time }
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.break_end_time || !!errorBreakingTime.break_end_time}
                    size="small"
                    variant="outlined"
                  >
                    <TextField
                      fullWidth
                      error={!!errors.break_end_time || !!errorBreakingTime.break_end_time}
                      label="End Time"
                      size="small"
                      type="time"
                      name="break_end_time"
                      onChange={(e) => handleChangeBreakingTimes(e)}
                      variant="outlined"
                      value={workDayData.break_end_time || ''}
                      inputProps={{ min: format(date, 'yyyy-MM-dd'), autoComplete: 'new-password' }}
                      inputlabelprops={{
                        shrink: true,
                      }}
                    />

                    { errors.break_end_time && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          End time is required.
                        </FormHelperText>
                      </div>
                    )}

                    {errorBreakingTime.break_end_time && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          { errorBreakingTime.break_end_time }
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
            {!workDayData.id ? (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={!!errors.staff_list}
                  size="small"
                  variant="outlined"
                >
                  <AutocompleteSelectLimitTags
                    items={staffOptions}
                    getOptionLabel={getOptionLabel}
                    selectedValues={selectedOptions}
                    label="Staff *"
                    placeholder="Staff"
                    limitTags={2}
                    error={!!errors.staff_list}
                    onToggleOption={handleToggleOption}
                    onClearOptions={handleClearOptions}
                    onSelectAll={handleSelectAll}
                  />

                  { errors.staff_list && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>
                        Staff is required.
                      </FormHelperText>
                    </div>
                  )}
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  <TextField
                    fullWidth
                    disabled
                    label="Staff"
                    size="small"
                    type="text"
                    name="start_date"
                    variant="outlined"
                    value={workDayData.company_staff.staff.full_name || ''}
                    inputProps={{ min: format(date, 'yyyy-MM-dd'), autoComplete: 'new-password' }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={!!storeErrors.description || !!updateErrors.description}
                size="small"
                variant="outlined"
              >
                <TextField
                  value={workDayData.description || ''}
                  onChange={(e) => updateWorkDayData('description', e.target.value)}
                  className={(!!storeErrors.description || !!updateErrors.description) && classes.error}
                  multiline
                  fullWidth
                  rowsMin={2}
                  inputProps={{ maxLength: 500, autoComplete: 'new-password' }}
                  variant="outlined"
                  placeholder={i18n.t('Description')}
                  label={i18n.t('Description')}
                  name="note_from_creator"
                />

                { storeErrors.description && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.description}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.description && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.description}
                    </FormHelperText>
                  </div>
                )}

              </FormControl>
            </Grid>
            {workDayData.start_date && workDayData.start_time && workDayData.end_time && (
              <Grid item xs={12}>
                <Box className="info-working-schedule-section">
                  From { moment(workDayData.start_date).format('ll') } { moment(workDayData.start_time, 'HH:mm').format('LT') } to { moment(workDayData.end_date).format('ll') } { moment(workDayData.end_time, 'HH:mm').format('LT') }
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            data-cy="cancel"
            type="button"
            onClick={onClose}
          >
            {i18n.t('Cancel')}
          </Button>
          <Button
            size="small"
            data-cy="send"
            type="button"
            onClick={workDayData.id ? handleUpdateWorkDay : handleAddWorkDay}
            variant="contained"
            color="primary"
            loading={buttonLoading}
          >
            {!buttonLoading && <CircularProgress color="white" size={20} />}
            {buttonLoading && workDayData.id ? i18n.t('Save') : buttonLoading && !workDayData.id ? i18n.t('Create') : ''}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

WorkDayModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  staffOptions: PropTypes.array.isRequired,
  specificDay: PropTypes.object.isRequired,
  allSpecialDays: PropTypes.object.isRequired,
  setAllSpecialDays: PropTypes.func.isRequired,
  handleUpdateCompanyState: PropTypes.func.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  setButtonLoading: PropTypes.func.isRequired,

  // Add Special Day
  addStaffSpecialDay: PropTypes.func.isRequired,
  isStaffSpecialDaySuccess: PropTypes.bool.isRequired,
  isStaffSpecialDayError: PropTypes.bool.isRequired,
  storeStaffSpecialDayErrors: PropTypes.object.isRequired,
  storedStaffSpecialDay: PropTypes.object.isRequired,

  // Update Special Day
  updateStaffSpecialDay: PropTypes.func.isRequired,
  isUpdateStaffSpecialDaySuccess: PropTypes.bool.isRequired,
  isUpdateStaffSpecialDayError: PropTypes.bool.isRequired,
  updateStaffSpecialDayErrors: PropTypes.object.isRequired,
  updatedStaffSpecialDay: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // Add Special Off
  isStaffSpecialDaySuccess: state.company.isStaffSpecialDaySuccess,
  isStaffSpecialDayError: state.company.isStaffSpecialDayError,
  storeStaffSpecialDayErrors: state.company.storeStaffSpecialDayErrors,
  storedStaffSpecialDay: state.company.storedStaffSpecialDay,

  // Update Special Day
  isUpdateStaffSpecialDaySuccess: state.company.isUpdateStaffSpecialDaySuccess,
  isUpdateStaffSpecialDayError: state.company.isUpdateStaffSpecialDayError,
  updateStaffSpecialDayErrors: state.company.updateStaffSpecialDayErrors,
  updatedStaffSpecialDay: state.company.updatedStaffSpecialDay,
});

function mapDispatchToProps(dispatch) {
  return {
    addStaffSpecialDay: (data) => dispatch(staffSpecialDayRequest(data)),
    updateStaffSpecialDay: (data) => dispatch(updateStaffSpecialDayRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkDayModal);
