import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Tab,
  Tabs,
  Box,
  Typography,
  Toolbar,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'; import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { getCompanyWorkingScheduleRequest } from '../../../../../redux/company/actions';
import WorkingScheduleLoading from '../../../../../Components/Loading/workingScheduleLoading';
import usePrevious from '../../../../../CustomHooks/usePrevious';
import General from './tabs/general';
import Company from './tabs/company';
import Staff from './tabs/staff';

function WorkingSchedule(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history, match } = props;
  const {
    isGetCompanyWorkingScheduleSuccess,
    workingSchedule,
  } = useSelector((state) => state.company);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  // Get some props previous values

  const prevIsGetCompanyWorkingScheduleSuccess = usePrevious(isGetCompanyWorkingScheduleSuccess);

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState({});
  const [schedule, setSchedule] = useState({});

  // Get Company By Id
  useEffect(() => {
    const { tab } = match.params;
    const { selectedTab, selectedTabName } = getSelectedTab(tab);
    setActiveTabIndex(selectedTab);
    history.push(`/working-schedule/${selectedTabName}`);
    setLoading(true);
    dispatch(getCompanyWorkingScheduleRequest());
  }, []);

  useEffect(() => {
    if (prevIsGetCompanyWorkingScheduleSuccess === false && isGetCompanyWorkingScheduleSuccess) {
      setSchedule(workingSchedule);
    }
  }, [isGetCompanyWorkingScheduleSuccess]);

  const handleChangeTab = (tabIndex) => {
    const { selectedTab, selectedTabName } = getSelectedTab(tabIndex);
    setActiveTabIndex(selectedTab);
    const url = `/working-schedule/${selectedTabName}`;
    history.push(url);
  };

  const getSelectedTab = (tab) => {
    let selectedTab;
    let selectedTabName = tab;
    switch (tab) {
      case 'general': {
        selectedTab = 0;
        break;
      }
      case 'company': {
        selectedTab = 1;
        break;
      }
      case 'staff': {
        selectedTab = 2;
        break;
      }
      default: {
        selectedTab = 0;
        selectedTabName = 'general';
      }
    }
    return {
      selectedTab,
      selectedTabName,
    };
  };

  const handleUpdateCompanyState = (name, value) => {
    setCompany({
      ...company,
      [name]: value,
    });
  };

  const handleDeleteCompanyState = (name, value) => {
    setCompany({
      ...company,
      [name]: value,
    });
  };

  return (
    <>
      <AppBar color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
            <Box display="flex" className="tabs-navbar">
              <Box alignSelf="center" className="divider-title">
                <Typography variant="h6" noWrap>
                  <Box
                    alignItems="center"
                    height="100%"
                    minWidth="auto"
                    maxWidth="220px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="noWrap"
                  >
                    {i18n.t('WorkSchedule')}
                  </Box>
                </Typography>
              </Box>
              <Box alignSelf="center" className="tab-companies">
                <AppBar position="static" color="inherit" elevation={0}>
                  <Tabs
                    value={activeTabIndex}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="secondary"
                  >
                    <Tab
                      label={t('WorkingScheduleTab.General')}
                      onClick={() => handleChangeTab('general')}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={t('WorkingScheduleTab.Company')}
                      onClick={() => handleChangeTab('company')}
                      {...a11yProps(1)}
                    />
                    <Tab
                      label={t('WorkingScheduleTab.Staff')}
                      onClick={() => handleChangeTab('staff')}
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </AppBar>
              </Box>
            </Box>
            <Box display="flex">
              <Box alignSelf="center" data-cy="filter" />

            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {loading ? (
        <div>
          <Box>
            <Box className="tab-list companies-tab-list">
              {activeTabIndex === 0 ? (
                <General
                  schedule={schedule}
                />
              ) : null }
              {activeTabIndex === 1 ? (
                <Company
                  schedule={schedule}
                  handleUpdateCompanyState={handleUpdateCompanyState}
                  handleDeleteCompanyState={handleDeleteCompanyState}
                />
              ) : null}
              {activeTabIndex === 2 ? (
                <Staff
                  schedule={schedule}
                  handleUpdateCompanyState={handleUpdateCompanyState}
                  handleDeleteCompanyState={handleDeleteCompanyState}
                />
              ) : null}
            </Box>
          </Box>
        </div>
      ) : <WorkingScheduleLoading />}
    </>
  );
}

WorkingSchedule.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  companyGot: PropTypes.object.isRequired,
  handleUpdateCompanyState: PropTypes.func.isRequired,
  handleDeleteCompanyState: PropTypes.func.isRequired,
  isGetCompanyByIdSuccess: PropTypes.bool.isRequired,
  isGetCompanyByIdError: PropTypes.bool.isRequired,
  getCompanyById: PropTypes.func.isRequired,
};

export default WorkingSchedule;
