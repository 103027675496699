import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  Paper,
  Button,
  Grid,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import StaffDayOffModal from '../../../../../../Modals/Owner/WorkingSchedule/staffDayOff';
import StaffSpecialDay from '../../../../../../Modals/Owner/WorkingSchedule/staffSpecialDay';
import DeleteStaffDayOffModal from '../../../../../../Modals/Owner/WorkingSchedule/deleteStaffDayOffModal';
import DeleteStaffSpecialDay from '../../../../../../Modals/Owner/WorkingSchedule/deleteStaffSpecialDay';
import splitSpaces from '../../../../../../Modules/splitSpaces';
import WorkingScheduleLoading from '../../../../../../Components/Loading/workingScheduleLoading';
import { getCompanyStaffRequest } from '../../../../../../redux/staff/actions';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import customerAndStaffDefaultLogo from '../../../../../../assets/img/avatar.svg';

function WorkingSchedule(props) {
  const dispatch = useDispatch();
  const {
    schedule,
    handleUpdateCompanyState,
    handleDeleteCompanyState,
  } = props;

  const { isGetCompanyStaffSuccess, companyStaff } = useSelector((state) => state.staff);

  const prevIsGetCompanyStaffSuccess = usePrevious(isGetCompanyStaffSuccess);

  const [staffDayOffs, setStaffDayOffs] = useState([]);
  const [staffSpecialDays, setStaffSpecialDays] = useState([]);
  const [isDayOffModalShown, setIsDayOffModalShown] = useState(false);
  const [isWorkDayModalShown, setIsWorkDayModalShown] = useState(false);
  const [isDayOffDeleteModalShown, setIsDayOffDeleteModalShown] = useState(false);
  const [isStaffSpecialDayDeleteModalShown, setIsStaffSpecialDayDeleteModalShown] = useState(false);
  const [staffOptions, setStaffOptions] = useState([]);
  const [staffDayOff, setStaffDayOff] = useState([]);
  const [staffSpecialDay, setStaffSpecialDay] = useState([]);
  const [selectedStaffDayOffId, setSelectedStaffDayOffId] = useState(0);
  const [selectedStaffSpecialDayId, setSelectedStaffSpecialDayId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(true);

  // Get day offs, specific days and close days
  useEffect(() => {
    if (Object.keys(schedule).length > 0) {
      dispatch(getCompanyStaffRequest({ forService: null }));
      setLoading(true);
      setStaffDayOffs(schedule.staffDayOffs);
      setStaffSpecialDays(schedule.staffSpecialDays);
    }
  }, [schedule]);

  useEffect(() => {
    if (prevIsGetCompanyStaffSuccess === false && isGetCompanyStaffSuccess) {
      const options = [];
      companyStaff.map((staff) => {
        options.push({
          id: staff.id,
          value: staff.full_name,
          label: staff.full_name,
          logo: staff.logo || customerAndStaffDefaultLogo,
        });
      });

      setStaffOptions(options);
    }
  }, [isGetCompanyStaffSuccess]);

  const handleOpenStaffDayOffModal = () => {
    setStaffDayOff({});
    setIsDayOffModalShown(true);
  };

  const handleEditStaffDayOff = (dayOff) => {
    setStaffDayOff(dayOff);
    setIsDayOffModalShown(true);
  };

  const handleDeleteStaffDayOff = (dayOffId) => {
    setSelectedStaffDayOffId(dayOffId);
    setIsDayOffDeleteModalShown(true);
  };

  const handleOpenStaffSpecialModal = () => {
    setStaffSpecialDay({});
    setIsWorkDayModalShown(true);
  };

  const handleEditStaffSpecialDay = (specificDay) => {
    setStaffSpecialDay(specificDay);
    setIsWorkDayModalShown(true);
  };

  const handleDeleteStaffSpecialDay = (specialDayId) => {
    setSelectedStaffSpecialDayId(specialDayId);
    setIsStaffSpecialDayDeleteModalShown(true);
  };

  const description = (description) => {
    if (splitSpaces(description) !== ' ' && description !== '') {
      if (description.length > 30) {
        return `${description.substring(0, 30)}...`;
      } else {
        return description;
      }
    } else {
      return '-';
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Grid container xs={12} spacing={2} id="working-schedule-content">
            <Grid className="company-staff-date" spacing={2} container xs={12}>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Grid className="staff-box">
                    <Grid container justify="space-between">
                      <Typography variant="h6" id="tableTitle" component="div">
                        {i18n.t('DaysOff')}
                      </Typography>
                      <Grid className="closed-days-button">
                        <Paper square style={{ boxShadow: 'none' }}>
                          <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
                            <Box>
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={handleOpenStaffDayOffModal}
                              >
                                {i18n.t('AddDayOff')}
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="companies menu-item">
                        <Box>
                          <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">{i18n.t('TableHeader.Date')}</TableCell>
                                  <TableCell align="center">{i18n.t('TableHeader.Description')}</TableCell>
                                  <TableCell align="center">{i18n.t('TableHeader.Staff')}</TableCell>
                                  <TableCell align="center">{i18n.t('TableHeader.Actions')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {staffDayOffs.map((dayOff) => (
                                  <TableRow>
                                    <TableCell align="center">
                                      { dayOff.start_date === dayOff.end_date ? `${moment(dayOff.start_date).format('DD.MM.YYYY')}` : `${moment(dayOff.start_date).format('DD')} - ${moment(dayOff.end_date).format('DD.MM.YYYY')}` }
                                    </TableCell>
                                    <TableCell align="center">
                                      { description(dayOff.description) }
                                    </TableCell>
                                    <TableCell align="center">
                                      { dayOff.company_staff.staff.full_name }
                                    </TableCell>
                                    <TableCell align="center">
                                      <IconButton size="small">
                                        <EditIcon color="secondary" fontSize="small" onClick={() => handleEditStaffDayOff(dayOff)} />
                                      </IconButton>
                                      <IconButton size="small">
                                        <DeleteOutlineIcon color="secondary" fontSize="small" onClick={() => handleDeleteStaffDayOff(dayOff.id)} />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid item xs={12}>
                  <Grid className="staff-box">
                    <Grid container justify="space-between">
                      <Typography variant="h6" id="tableTitle" component="div">
                        {i18n.t('SpecificDaysTitle')}
                      </Typography>
                      <Grid className="closed-days-button">
                        <Paper square style={{ boxShadow: 'none' }}>
                          <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
                            <Box>
                              <Button
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={handleOpenStaffSpecialModal}
                              >
                                {i18n.t('AddSpecificWorkDays')}
                              </Button>
                            </Box>
                          </Box>
                        </Paper>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="companies menu-item">
                        <Box>
                          <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">{i18n.t('TableHeader.Date')}</TableCell>
                                  <TableCell align="center">{i18n.t('TableHeader.Time')}</TableCell>
                                  <TableCell align="center">{i18n.t('TableHeader.BreakTime')}</TableCell>
                                  <TableCell align="center">{i18n.t('TableHeader.Description')}</TableCell>
                                  <TableCell align="center">{i18n.t('TableHeader.Staff')}</TableCell>
                                  <TableCell align="center">{i18n.t('TableHeader.Actions')}</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {staffSpecialDays.map((specificDay) => (
                                  <TableRow>
                                    <TableCell align="center">
                                      { specificDay.start_date === specificDay.end_date ? `${moment(specificDay.start_date).format('DD.MM.YYYY')}` : `${moment(specificDay.start_date).format('DD')} - ${moment(specificDay.end_date).format('DD.MM.YYYY')}` }
                                    </TableCell>
                                    <TableCell align="center">
                                      { moment(`${specificDay.start_date} ${specificDay.start_time}`).format('H:mm') } - { moment(`${specificDay.end_date} ${specificDay.end_time}`).format('H:mm') }
                                    </TableCell>
                                    <TableCell align="center">
                                      {specificDay.break_start_time && specificDay.break_start_time
                                        ? (
                                          <>
                                            { moment(`${specificDay.start_date} ${specificDay.break_start_time}`).format('H:mm') } - { moment(`${specificDay.end_date} ${specificDay.break_end_time}`).format('H:mm') }
                                          </>
                                        ) : '-'}
                                    </TableCell>
                                    <TableCell align="center">
                                      { description(specificDay.description) }
                                    </TableCell>
                                    <TableCell align="center">
                                      { specificDay.company_staff.staff.full_name }
                                    </TableCell>
                                    <TableCell align="center">
                                      <IconButton size="small">
                                        <EditIcon color="secondary" fontSize="small" onClick={() => handleEditStaffSpecialDay(specificDay)} />
                                      </IconButton>
                                      <IconButton size="small">
                                        <DeleteOutlineIcon color="secondary" fontSize="small" onClick={() => handleDeleteStaffSpecialDay(specificDay.id)} />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <StaffDayOffModal
            open={isDayOffModalShown}
            onClose={() => setIsDayOffModalShown(false)}
            dayOff={staffDayOff}
            allDayOffs={staffDayOffs}
            setAllDayOffs={setStaffDayOffs}
            staffOptions={staffOptions}
            handleUpdateCompanyState={handleUpdateCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <StaffSpecialDay
            open={isWorkDayModalShown}
            onClose={() => setIsWorkDayModalShown(false)}
            specificDay={staffSpecialDay}
            allSpecialDays={staffSpecialDays}
            setAllSpecialDays={setStaffSpecialDays}
            staffOptions={staffOptions}
            handleUpdateCompanyState={handleUpdateCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <DeleteStaffDayOffModal
            open={isDayOffDeleteModalShown}
            onClose={() => setIsDayOffDeleteModalShown(false)}
            dayOffId={selectedStaffDayOffId}
            allDayOffs={staffDayOffs}
            setAllDayOffs={setStaffDayOffs}
            handleDeleteCompanyState={handleDeleteCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <DeleteStaffSpecialDay
            open={isStaffSpecialDayDeleteModalShown}
            onClose={() => setIsStaffSpecialDayDeleteModalShown(false)}
            specialDayId={selectedStaffSpecialDayId}
            allSpecialDays={staffSpecialDays}
            setAllSpecialDays={setStaffSpecialDays}
            handleDeleteCompanyState={handleDeleteCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />
        </>
      ) : <WorkingScheduleLoading />}
    </>
  );
}

WorkingSchedule.propTypes = {
  schedule: PropTypes.object.isRequired,
  handleUpdateCompanyState: PropTypes.func.isRequired,
  handleDeleteCompanyState: PropTypes.func.isRequired,
};

export default WorkingSchedule;
