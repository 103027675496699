import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Switch,
  TableCell,
  Paper,
  Button,
  Tab,
  Tabs,
  Grid,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import BreakingHours from '../../../WorkingSchedule/breakingHours';
import WorkingHours from '../../../WorkingSchedule/workingHours';
import StaffDayOffModal from '../../../../../../../Modals/Owner/WorkingSchedule/staffDayOff';
import StaffSpecialDay from '../../../../../../../Modals/Owner/WorkingSchedule/staffSpecialDay';
import DeleteStaffDayOffModal from '../../../../../../../Modals/Owner/WorkingSchedule/deleteStaffDayOffModal';
import DeleteStaffSpecialDay from '../../../../../../../Modals/Owner/WorkingSchedule/deleteStaffSpecialDay';
import CloseDayModal from '../../../../../../../Modals/Owner/WorkingSchedule/closeDay';
import DeleteCloseDayModal from '../../../../../../../Modals/Owner/WorkingSchedule/deleteCloseDay';
import SpecialDay from '../../../../../../../Modals/Owner/WorkingSchedule/specialDay';
import DeleteSpecialDayModal from '../../../../../../../Modals/Owner/WorkingSchedule/deleteSpecialDay';
import splitSpaces from '../../../../../../../Modules/splitSpaces';
import WorkingScheduleLoading from '../../../../../../../Components/Loading/workingScheduleLoading';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';
import { checkCompanyDayAppointmentsRequest } from '../../../../../../../redux/appointment/actions';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import HaveAppointmentsModal from '../../../../../../../Modals/IndividualUserIndustry/HaveAppointments';
import { updateWorkingHoursRequest } from '../../../../../../../redux/businnessHours/actions';
import weekDayNumber from '../../../../../../../Modules/weekDayNumber';

function WorkingSchedule(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    // Check Day Appointments
    isCheckCompanyDayAppointmentsSuccess,
    isCheckCompanyDayAppointmentsError,
    companyDayAppointments,
    // Cancel Day Appointments
    isCancelCompanyDayAppointmentsSuccess,
    isCancelCompanyDayAppointmentsError,
    cancelCompanyDayAppointmentsErrorMessage,
  } = useSelector((state) => state.appointment);

  const {
    // Update Working Hours
    isUpdateWorkingHoursSuccess,
    isUpdateWorkingHoursError,
    updatedWorkingHours,
    // Update Breaking Hours
    isUpdateBreakingHoursSuccess,
    isUpdateBreakingHoursError,
    updatedBreakingHours,
  } = useSelector((state) => state.businessHours);

  const {
    companyGot,
    handleUpdateCompanyState,
    handleDeleteCompanyState,
  } = props;

  // Get some props previous values
  const prevIsCheckCompanyDayAppointmentsSuccess = usePrevious(isCheckCompanyDayAppointmentsSuccess);
  const prevIsCheckCompanyDayAppointmentsError = usePrevious(isCheckCompanyDayAppointmentsError);
  const prevIsCancelCompanyDayAppointmentsSuccess = usePrevious(isCancelCompanyDayAppointmentsSuccess);
  const prevIsCancelCompanyDayAppointmentsError = usePrevious(isCancelCompanyDayAppointmentsError);
  const prevIsUpdateWorkingHoursSuccess = usePrevious(isUpdateWorkingHoursSuccess);
  const prevIsUpdateWorkingHoursError = usePrevious(isUpdateWorkingHoursError);
  const prevIsUpdateBreakingHoursSuccess = usePrevious(isUpdateBreakingHoursSuccess);
  const prevIsUpdateBreakingHoursError = usePrevious(isUpdateBreakingHoursError);

  const [workingClosedHours, setWorkingClosedHours] = useState(null);
  const [staffDayOffs, setStaffDayOffs] = useState([]);
  const [closeDays, setCloseDays] = useState([]);
  const [staffSpecialDays, setStaffSpecialDays] = useState([]);
  const [specialDays, setSpecialDays] = useState([]);
  const [workingHours, setWorkingHours] = useState();
  const [haveAppointmentsModalShown, setHaveAppointmentsModalShown] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeTabIndexForCompany, setActiveTabIndexForCompany] = useState(0);
  const [isDayOffModalShown, setIsDayOffModalShown] = useState(false);
  const [isWorkDayModalShown, setIsWorkDayModalShown] = useState(false);
  const [isDayOffDeleteModalShown, setIsDayOffDeleteModalShown] = useState(false);
  const [isStaffSpecialDayDeleteModalShown, setIsStaffSpecialDayDeleteModalShown] = useState(false);
  const [isCloseDayModalShown, setIsCloseDayModalShown] = useState(false);
  const [isSpecialDayModalShown, setIsSpecialDayModalShown] = useState(false);
  const [isSpecialDayDeleteModalShown, setIsSpecialDayDeleteModalShown] = useState(false);
  const [isDeleteCloseDayModalShown, setIsDeleteCLoseDayModalShown] = useState(0);
  const [staffDayOff, setStaffDayOff] = useState({});
  const [closeDay, setCloseDay] = useState({});
  const [staffSpecialDay, setStaffSpecialDay] = useState({});
  const [specialDay, setSpecialDay] = useState({});
  const [selectedStaffDayOffId, setSelectedStaffDayOffId] = useState(0);
  const [selectedStaffSpecialDayId, setSelectedStaffSpecialDayId] = useState(0);
  const [selectedCloseDayId, setSelectedCloseDayId] = useState(0);
  const [selectedSpecialDayId, setSelectedSpecialDayId] = useState(0);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState('');
  const [dayId, setDayId] = useState('');
  const [weekDay, setWeekDay] = useState('');
  const [checkDay, setCheckDay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [cancelData, setCancelData] = useState({});
  const [company, setCompany] = useState({});

  // Get day offs, specific days and close days
  useEffect(() => {
    setLoading(true);
    setButtonLoading(true);
    setStaffDayOffs(companyGot.staffDayOffs);
    setStaffSpecialDays(companyGot.staffSpecialDays);
    setCloseDays(companyGot.closeDays);
    setSpecialDays(companyGot.specialDays);
    setWorkingHours(companyGot.workingHours);
    setCompany(companyGot);
  }, [companyGot]);

  // Handle Check Company Day Appointments Success
  useEffect(() => {
    if (prevIsCheckCompanyDayAppointmentsSuccess === false && isCheckCompanyDayAppointmentsSuccess && checkDay) {
      if (companyDayAppointments.length > 0) {
        const weekDay = workingHours.find((hour) => hour.week_day_id === dayId).week_day;
        setWeekDay(weekDay);
        const appointmentsIds = [];
        companyDayAppointments.map((item) => {
          appointmentsIds.push(item.id);
        });
        setCancelData({
          appointment_ids: appointmentsIds,
          name: 'toggle',
        });
        setHaveAppointmentsModalShown(true);
      } else {
        handleWorkingHoursToggleDetailsChange(name, value, checked, dayId);
      }

      setCheckDay(false);
    }
  }, [isCheckCompanyDayAppointmentsSuccess]);

  // Handle Check Company Day Appointments Error
  useEffect(() => {
    if (prevIsCheckCompanyDayAppointmentsError === false && isCheckCompanyDayAppointmentsError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isCheckCompanyDayAppointmentsError]);

  // Handle Update Working Hours Success
  useEffect(() => {
    if (prevIsUpdateWorkingHoursSuccess === false && isUpdateWorkingHoursSuccess) {
      setLoading(true);
      setButtonLoading(true);
      setCompany((prevState) => ({
        ...prevState,
        workingHours: updatedWorkingHours,
      }));
      setWorkingHours(updatedWorkingHours);
      snackBarAlert(true, 'Working days updated successfully', 'success');
    }
  }, [isUpdateWorkingHoursSuccess]);

  // Handle Update Working Hours Error
  useEffect(() => {
    if (prevIsUpdateWorkingHoursError === false && isUpdateWorkingHoursError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isUpdateWorkingHoursError]);

  // Handle Update Breaking Hours Success
  useEffect(() => {
    if (prevIsUpdateBreakingHoursSuccess === false && isUpdateBreakingHoursSuccess) {
      setLoading(true);
      setButtonLoading(true);
      setCompany((prevState) => ({
        ...prevState,
        breakingHours: updatedBreakingHours,
      }));
      snackBarAlert(true, 'Breaking hours updated successfully', 'success');
    }
  }, [isUpdateBreakingHoursSuccess]);

  // Handle Update Breaking Hours Error
  useEffect(() => {
    if (prevIsUpdateBreakingHoursError === false && isUpdateBreakingHoursError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isUpdateBreakingHoursError]);

  // Handle cancel appointments success
  useEffect(() => {
    if (prevIsCancelCompanyDayAppointmentsSuccess === false && isCancelCompanyDayAppointmentsSuccess && cancelData.name === 'toggle') {
      setHaveAppointmentsModalShown(false);
      handleWorkingHoursToggleDetailsChange(name, value, checked, dayId);
    }
  }, [isCancelCompanyDayAppointmentsSuccess]);

  // Handle cancel appointments success
  useEffect(() => {
    if (prevIsCancelCompanyDayAppointmentsError === false && isCancelCompanyDayAppointmentsError) {
      setOpenSnackbar(true);
      setSnackbarType('error');
      setSnackbarMessage(cancelCompanyDayAppointmentsErrorMessage);
    }
  }, [isCancelCompanyDayAppointmentsError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const changeWorkingHours = (value) => {
    setWorkingClosedHours(value);
  };

  const handleCheckAppointments = (event, id) => {
    const { name, value, checked } = event.target;
    if (checked) {
      handleWorkingHoursToggleDetailsChange(name, value, checked, id);
    } else {
      setCheckDay(true);
      dispatch(checkCompanyDayAppointmentsRequest({
        day: weekDayNumber(id),
      }));
      setName(name);
      setValue(value);
      setChecked(checked);
      setDayId(id);
    }
  };

  const handleWorkingHoursToggleDetailsChange = (name, value, checked, id) => {
    const val = checked !== undefined ? checked : value;
    const workingHoursCopy = [...company.workingHours];
    const breakingHoursCopy = [...company.breakingHours];
    const workingHourIndex = workingHoursCopy.findIndex((hour) => hour.week_day_id === id);
    const breakingHourIndex = breakingHoursCopy.findIndex((hour) => hour.week_day_id === id);
    changeWorkingHours(workingHoursCopy);
    if (name === 'is_working') {
      workingHoursCopy[workingHourIndex][name] = val;
      breakingHoursCopy[breakingHourIndex]['is_breaking'] = val;
      setWorkingHours(workingHoursCopy);
    } else if (name === 'start_time') {
      const startTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(workingHoursCopy[workingHourIndex].end_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        workingHoursCopy[workingHourIndex][name] = val;
        breakingHoursCopy[breakingHourIndex]['is_breaking'] = val;
        setWorkingHours(workingHoursCopy);
      } else {
        snackBarAlert(true, i18n.t('StartTimeEarlier'), 'error');

        return false;
      }
    } else if (name === 'end_time') {
      const startTime = moment(workingHoursCopy[workingHourIndex].start_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        workingHoursCopy[workingHourIndex][name] = val;
        setWorkingHours(workingHoursCopy);
      } else {
        snackBarAlert(true, i18n.t('EndTimeAfter'), 'error');

        return false;
      }
    }

    dispatch(updateWorkingHoursRequest({
      businessDays: workingHoursCopy,
    }));
    // setLoading(false);
  };

  const handleChangeTab = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleChangeTabForCompany = (tabIndex) => {
    setActiveTabIndexForCompany(tabIndex);
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  const handleOpenStaffDayOffModal = () => {
    setStaffDayOff({});
    setIsDayOffModalShown(true);
  };

  const handleEditStaffDayOff = (dayOff) => {
    setStaffDayOff(dayOff);
    setIsDayOffModalShown(true);
  };

  const handleDeleteStaffDayOff = (dayOffId) => {
    setSelectedStaffDayOffId(dayOffId);
    setIsDayOffDeleteModalShown(true);
  };

  const handleOpenStaffSpecialModal = () => {
    setStaffSpecialDay({});
    setIsWorkDayModalShown(true);
  };

  const handleEditStaffSpecialDay = (specificDay) => {
    setStaffSpecialDay(specificDay);
    setIsWorkDayModalShown(true);
  };

  const handleDeleteStaffSpecialDay = (specialDayId) => {
    setSelectedStaffSpecialDayId(specialDayId);
    setIsStaffSpecialDayDeleteModalShown(true);
  };

  const handleOpenCloseDayModal = () => {
    setCloseDay({});
    setIsCloseDayModalShown(true);
  };

  const handleEditCloseDay = (closeDay) => {
    setCloseDay(closeDay);
    setIsCloseDayModalShown(true);
  };

  const handleDeleteCloseDay = (closeDayId) => {
    setSelectedCloseDayId(closeDayId);
    setIsDeleteCLoseDayModalShown(true);
  };

  const handleOpenSpecialDayModal = () => {
    setSpecialDay({});
    setIsSpecialDayModalShown(true);
  };

  const handleEditSpecialDay = (specialDay) => {
    setSpecialDay(specialDay);
    setIsSpecialDayModalShown(true);
  };

  const handleDeleteSpecialDay = (specialDayId) => {
    setSelectedSpecialDayId(specialDayId);
    setIsSpecialDayDeleteModalShown(true);
  };

  const description = (description) => {
    if (splitSpaces(description) !== ' ' && description !== '') {
      if (description.length > 30) {
        return `${description.substring(0, 30)}...`;
      } else {
        return description;
      }
    } else {
      return '-';
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Grid container xs={12} spacing={2} id="working-schedule-content">
            <Grid container className="working-schedule-box">
              <Grid xs={12} className="working-schedule-title">
                <Typography variant="h6" id="tableTitle" component="div">
                  {i18n.t('InfoGeneral')}
                </Typography>
              </Grid>
              <Grid container className="general-schedule">
                <Grid item xs={3} className="tab-item week-day-switch working-schedule" id="tab-main-parent">
                  <Box className="time-zone">
                    <span>{i18n.t('GeneralSchedule.WeekDays')}</span>
                  </Box>
                  <Box py="25px" px="17px">
                    { workingHours.map((workHour) => (
                      <div className="hours-content" key={`${workHour.week_day}`}>
                        <Box display="flex" minWidth="173px" className="day-content" key={Math.random()}>
                          <Box minWidth="91px">
                            <div className="day-name">
                              <span data-cy="week-day-name">{ workHour.week_day }</span>
                            </div>
                          </Box>
                          <Box minWidth="91px">
                            <Typography component="div">
                              <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>
                                  <Switch
                                    checked={workHour.is_working}
                                    onChange={(event) => handleCheckAppointments(event, workHour.week_day_id)}
                                    name="is_working"
                                    color="primary"
                                    size="small"
                                  />
                                </Grid>
                                {workHour.is_working ? (
                                  <Grid item> <Typography color="initial" variant="body1"> Open </Typography> </Grid>
                                ) : <Typography color="initial" variant="body1"> Close </Typography>}
                              </Grid>
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </Box>
                </Grid>
                <Grid item className="working-schedule">
                  <div className="time-zone working">
                    <span>WorkingHours</span>
                  </div>
                  <WorkingHours
                    company={company}
                    setWeekDay={setWeekDay}
                    cancelData={cancelData}
                    setLoading={setLoading}
                    setCancelData={setCancelData}
                    changeCompanyHours={changeWorkingHours}
                    setHaveAppointmentsModalShown={setHaveAppointmentsModalShown}
                  />
                </Grid>
                <Grid className="breaking-schedule">
                  <div className="time-zone">
                    <span>{i18n.t('GeneralSchedule.BreakHours')}</span>
                  </div>
                  <BreakingHours
                    company={company}
                    setCancelData={setCancelData}
                    cancelData={cancelData}
                    setLoading={setLoading}
                    setWeekDay={setWeekDay}
                    workingClosedHours={workingClosedHours}
                    changeCompanyHours={changeWorkingHours}
                    setHaveAppointmentsModalShown={setHaveAppointmentsModalShown}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className="company-staff-date" container xs={12}>
              <Grid border="none" id="company" item xs={6}>
                <Grid container className="company-box">
                  <Typography variant="h6" id="tableTitle" component="div">
                    Company
                  </Typography>
                  <Grid item xs={12}>
                    <Paper style={{ boxShadow: 'none' }} square>
                      <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
                        <Box alignSelf="center" boxShadow="none" className="tab-companies">
                          <AppBar position="static" color="inherit" elevation={0}>
                            <Tabs
                              value={activeTabIndexForCompany}
                              aria-label="simple tabs example"
                              variant="scrollable"
                              indicatorColor="primary"
                              textColor="secondary"
                            >
                              <Tab
                                label={t('ClosedDays')}
                                onClick={() => handleChangeTabForCompany(0)}
                                {...a11yProps(0)}
                              />
                              <Tab
                                label={t('SpecificDaysTitle')}
                                onClick={() => handleChangeTabForCompany(1)}
                                {...a11yProps(1)}
                              />
                            </Tabs>
                          </AppBar>
                        </Box>
                        {activeTabIndexForCompany === 0 && (
                        <Box>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleOpenCloseDayModal}
                          >
                            {t('AddClosedDays')}
                          </Button>
                        </Box>
                        )}

                        {activeTabIndexForCompany === 1 && (
                        <Box>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleOpenSpecialDayModal}
                          >
                            {i18n.t('AddSpecificWorkDays')}
                          </Button>
                        </Box>
                        )}
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="companies menu-item">
                      <Box boxShadow={0}>
                        {activeTabIndexForCompany === 0 && (
                        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {closeDays.map((closeDay) => (
                                <TableRow>
                                  <TableCell align="center">
                                    { closeDay.start_date === closeDay.end_date ? `${moment(closeDay.start_date).format('DD.MM.YYYY')}` : `${moment(closeDay.start_date).format('DD')} - ${moment(closeDay.end_date).format('DD.MM.YYYY')}` }
                                  </TableCell>
                                  <TableCell align="center">
                                    { description(closeDay.description) }
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton size="small">
                                      <EditIcon color="secondary" fontSize="small" onClick={() => handleEditCloseDay(closeDay)} />
                                    </IconButton>
                                    <IconButton size="small">
                                      <DeleteOutlineIcon color="secondary" fontSize="small" onClick={() => handleDeleteCloseDay(closeDay.id)} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        )}
                        {activeTabIndexForCompany === 1 && (
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Time</TableCell>
                                <TableCell align="center">Break Time</TableCell>
                                <TableCell align="center">Description</TableCell>
                                <TableCell align="center">Actions</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {specialDays.map((specialDay) => (
                                <TableRow>
                                  <TableCell align="center">
                                    { specialDay.start_date === specialDay.end_date ? `${moment(specialDay.start_date).format('DD.MM.YYYY')}` : `${moment(specialDay.start_date).format('DD')} - ${moment(specialDay.end_date).format('DD.MM.YYYY')}` }
                                  </TableCell>
                                  <TableCell align="center">
                                    { moment(`${specialDay.start_date} ${specialDay.start_time}`).format('H:mm') } - { moment(`${specialDay.end_date} ${specialDay.end_time}`).format('H:mm') }
                                  </TableCell>
                                  <TableCell align="center">
                                    {specialDay.break_start_time && specialDay.break_end_time
                                      ? (
                                        <>
                                          { moment(`${specialDay.start_date} ${specialDay.break_start_time}`).format('H:mm') } - { moment(`${specialDay.end_date} ${specialDay.break_end_time}`).format('H:mm') }
                                        </>
                                      ) : '-'}
                                  </TableCell>
                                  <TableCell align="center">
                                    { description(specialDay.description) }
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton size="small">
                                      <EditIcon color="secondary" fontSize="small" onClick={() => handleEditSpecialDay(specialDay)} />
                                    </IconButton>
                                    <IconButton size="small">
                                      <DeleteOutlineIcon color="secondary" fontSize="small" onClick={() => handleDeleteSpecialDay(specialDay.id)} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        )}
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                {company.staff && company.staff.length > 0 && (
                <Grid item xs={12}>
                  <Grid className="staff-box">
                    <Typography variant="h6" id="tableTitle" component="div">
                          Staff
                    </Typography>
                    <Grid item xs={12}>
                      <Paper square style={{ boxShadow: 'none' }}>
                        <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
                          <Box alignSelf="center" className="tab-companies">
                            <AppBar position="static" color="inherit" elevation={0}>
                              <Tabs
                                value={activeTabIndex}
                                aria-label="simple tabs example"
                                variant="scrollable"
                                indicatorColor="primary"
                                textColor="secondary"
                              >
                                <Tab
                                  label={t('DaysOff')}
                                  onClick={() => handleChangeTab(0)}
                                  {...a11yProps(0)}
                                />
                                <Tab
                                  label={t('SpecificDaysTitle')}
                                  onClick={() => handleChangeTab(1)}
                                  {...a11yProps(1)}
                                />
                              </Tabs>
                            </AppBar>
                          </Box>
                          {activeTabIndex === 0 && (
                          <Box>
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={handleOpenStaffDayOffModal}
                            >
                              AddDayOff
                            </Button>
                          </Box>
                          )}

                          {activeTabIndex === 1 && (
                          <Box>
                            <Button
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={handleOpenStaffSpecialModal}
                            >
                              {i18n.t('AddSpecificWorkDays')}
                            </Button>
                          </Box>
                          )}
                        </Box>
                      </Paper>
                    </Grid>
                    <Grid item xs={12}>
                      <div className="companies menu-item">
                        <Box>
                          {activeTabIndex === 0 && (
                          <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Date</TableCell>
                                  <TableCell align="center">Description</TableCell>
                                  <TableCell align="center">Staff</TableCell>
                                  <TableCell align="center">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {staffDayOffs.map((dayOff) => (
                                  <TableRow>
                                    <TableCell align="center">
                                      { dayOff.start_date === dayOff.end_date ? `${moment(dayOff.start_date).format('DD.MM.YYYY')}` : `${moment(dayOff.start_date).format('DD')} - ${moment(dayOff.end_date).format('DD.MM.YYYY')}` }
                                    </TableCell>
                                    <TableCell align="center">
                                      { description(dayOff.description) }
                                    </TableCell>
                                    <TableCell align="center">
                                      { dayOff.company_staff.staff.full_name }
                                    </TableCell>
                                    <TableCell align="center">
                                      <IconButton size="small">
                                        <EditIcon color="secondary" fontSize="small" onClick={() => handleEditStaffDayOff(dayOff)} />
                                      </IconButton>
                                      <IconButton size="small">
                                        <DeleteOutlineIcon color="secondary" fontSize="small" onClick={() => handleDeleteStaffDayOff(dayOff.id)} />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          )}
                          {activeTabIndex === 1 && (
                          <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="center">Date</TableCell>
                                  <TableCell align="center">Time</TableCell>
                                  <TableCell align="center">Break Time</TableCell>
                                  <TableCell align="center">Description</TableCell>
                                  <TableCell align="center">Staff</TableCell>
                                  <TableCell align="center">Actions</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {staffSpecialDays.map((specificDay) => (
                                  <TableRow>
                                    <TableCell align="center">
                                      { specificDay.start_date === specificDay.end_date ? `${moment(specificDay.start_date).format('DD.MM.YYYY')}` : `${moment(specificDay.start_date).format('DD')} - ${moment(specificDay.end_date).format('DD.MM.YYYY')}` }
                                    </TableCell>
                                    <TableCell align="center">
                                      { moment(`${specificDay.start_date} ${specificDay.start_time}`).format('H:mm') } - { moment(`${specificDay.end_date} ${specificDay.end_time}`).format('H:mm') }
                                    </TableCell>
                                    <TableCell align="center">
                                      {specificDay.break_start_time && specificDay.break_start_time
                                        ? (
                                          <>
                                            { moment(`${specificDay.start_date} ${specificDay.break_start_time}`).format('H:mm') } - { moment(`${specificDay.end_date} ${specificDay.break_end_time}`).format('H:mm') }
                                          </>
                                        ) : '-'}
                                    </TableCell>
                                    <TableCell align="center">
                                      { description(specificDay.description) }
                                    </TableCell>
                                    <TableCell align="center">
                                      { specificDay.company_staff.staff.full_name }
                                    </TableCell>
                                    <TableCell align="center">
                                      <IconButton size="small">
                                        <EditIcon color="secondary" fontSize="small" onClick={() => handleEditStaffSpecialDay(specificDay)} />
                                      </IconButton>
                                      <IconButton size="small">
                                        <DeleteOutlineIcon color="secondary" fontSize="small" onClick={() => handleDeleteStaffSpecialDay(specificDay.id)} />
                                      </IconButton>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          )}
                        </Box>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <SnackbarToast
            message={snackbarMessage}
            type={snackbarType}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
          />

          <StaffDayOffModal
            open={isDayOffModalShown}
            onClose={() => setIsDayOffModalShown(false)}
            dayOff={staffDayOff}
            allDayOffs={staffDayOffs}
            setAllDayOffs={setStaffDayOffs}
            handleUpdateCompanyState={handleUpdateCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <StaffSpecialDay
            open={isWorkDayModalShown}
            onClose={() => setIsWorkDayModalShown(false)}
            specificDay={staffSpecialDay}
            allSpecialDays={staffSpecialDays}
            setAllSpecialDays={setStaffSpecialDays}
            handleUpdateCompanyState={handleUpdateCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <DeleteStaffDayOffModal
            open={isDayOffDeleteModalShown}
            onClose={() => setIsDayOffDeleteModalShown(false)}
            dayOffId={selectedStaffDayOffId}
            allDayOffs={staffDayOffs}
            setAllDayOffs={setStaffDayOffs}
            handleDeleteCompanyState={handleDeleteCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <DeleteStaffSpecialDay
            open={isStaffSpecialDayDeleteModalShown}
            onClose={() => setIsStaffSpecialDayDeleteModalShown(false)}
            specialDayId={selectedStaffSpecialDayId}
            allSpecialDays={staffSpecialDays}
            setAllSpecialDays={setStaffSpecialDays}
            handleDeleteCompanyState={handleDeleteCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <CloseDayModal
            open={isCloseDayModalShown}
            onClose={() => setIsCloseDayModalShown(false)}
            closeDay={closeDay}
            allCloseDays={closeDays}
            setAllCloseDays={setCloseDays}
            handleUpdateCompanyState={handleUpdateCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <DeleteCloseDayModal
            open={isDeleteCloseDayModalShown}
            onClose={() => setIsDeleteCLoseDayModalShown(false)}
            closeDayId={selectedCloseDayId}
            allCloseDays={closeDays}
            setAllCloseDays={setCloseDays}
            handleDeleteCompanyState={handleDeleteCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <SpecialDay
            open={isSpecialDayModalShown}
            onClose={() => setIsSpecialDayModalShown(false)}
            specialDay={specialDay}
            allSpecialDays={specialDays}
            setAllSpecialDays={setSpecialDays}
            handleUpdateCompanyState={handleUpdateCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <DeleteSpecialDayModal
            open={isSpecialDayDeleteModalShown}
            onClose={() => setIsSpecialDayDeleteModalShown(false)}
            specialDayId={selectedSpecialDayId}
            allSpecialDays={specialDays}
            setAllSpecialDays={setSpecialDays}
            handleDeleteCompanyState={handleDeleteCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <HaveAppointmentsModal
            open={haveAppointmentsModalShown}
            onClose={() => setHaveAppointmentsModalShown(false)}
            weekDay={weekDay}
            from="company"
            cancelData={cancelData}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />
        </>
      ) : <WorkingScheduleLoading />}
    </>
  );
}

WorkingSchedule.propTypes = {
  companyGot: PropTypes.object.isRequired,
  handleUpdateCompanyState: PropTypes.func.isRequired,
  handleDeleteCompanyState: PropTypes.func.isRequired,
};

export default WorkingSchedule;
