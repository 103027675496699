import React, { Component } from 'react';
import { connect } from 'react-redux';
import io from 'socket.io-client';
import PropTypes from 'prop-types';
import '../../../../../style/messages.scss';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  DialogTitle,
  DialogContent,
  Dialog,
  AppBar,
  Accordion,
  AccordionDetails,
  DialogActions,
  Box,
  Toolbar,
  AccordionSummary,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import i18n from 'i18next';
import {
  LeftContent,
  ChatContent,
  SubmitMessage,
  TopContent,
  RightContent,
} from '../../../../../Components/Messages';
import {
  getMessagesRequest,
  storeMessageRequest,
  makeMessagesReadRequest,
  updateMessageRequest,
  deleteMessageRequest,
} from '../../../../../redux/message/actions';
import MessagesLoading from '../../../../../Components/Loading/messagesLoading';
import messagesSocketModule from '../../../../../Modules/messagesSocket';
import Index from '../../../../../Components/Messages/MissingMessages';
import DeleteButton from '../../../../../MaterialComponents/deleteButton';
import SnackbarToast from '../../../../../Modules/SnackbarToast';

class Messages extends Component {
  // @TODO Change with translations
  eventMessages = {
    appointments: {
      created: 'Created Appointment',
      updated: 'Updated Appointment',
      declined: 'Declined Appointment',
      cancelled: 'Cancelled Appointment',
      accepted: 'Accepted Appointment',
    },
  };

  socket = {};

  lastDeleteMessageId = null;

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    // Account
    userAccount: PropTypes.object.isRequired,
    children: PropTypes.object.isRequired,
    // Get Index Props
    getMessages: PropTypes.func.isRequired,
    isGetMessagesSuccess: PropTypes.bool.isRequired,
    isGetMessagesError: PropTypes.bool.isRequired,
    appointments: PropTypes.array.isRequired,
    getMessagesErrorMessage: PropTypes.string.isRequired,
    // Store Message
    storeMessage: PropTypes.func.isRequired,
    isStoreMessageSuccess: PropTypes.bool.isRequired,
    isStoreMessageError: PropTypes.bool.isRequired,
    storedMessage: PropTypes.object.isRequired,
    storeMessageErrorMessage: PropTypes.string.isRequired,
    // Make Index Read
    makeMessagesRead: PropTypes.func.isRequired,
    isMakeMessagesReadSuccess: PropTypes.bool.isRequired,
    isMakeMessagesReadError: PropTypes.bool.isRequired,
    messagesReadAppointmentId: PropTypes.number.isRequired,
    // Update Message Props
    updateMessage: PropTypes.func.isRequired,
    isUpdateMessageSuccess: PropTypes.bool.isRequired,
    isUpdateMessageError: PropTypes.bool.isRequired,
    updatedMessage: PropTypes.object.isRequired,
    updateMessageErrorMessage: PropTypes.string.isRequired,
    // Delete Message Props
    deleteMessage: PropTypes.func.isRequired,
    isDeleteMessageSuccess: PropTypes.bool.isRequired,
    isDeleteMessageError: PropTypes.bool.isRequired,
    deleteMessageErrorMessage: PropTypes.string.isRequired,
  };

  options = [
    { value: 'customer', label: 'Customer' },
    { value: 'individual', label: 'Individual' },
    { value: 'staff', label: 'Staff' },
  ];

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      appointments: [],
      limit: 10,
      page: 0,
      selectedAppointment: {
        service: {},
        company: {
          industry: {},
        },
        staff: {},
        messages: [],
      },
      newMessage: {
        from_id: '',
        to_id: '',
        text: '',
        appointment_id: '',
      },
      expanded: false,
      alert: null,
      emptySendMessage: true,
      snackbarMessage: '',
      snackbarType: '',
      openSnackbar: false,
    };
  }

  componentDidMount() {
    const { getMessages } = this.props;
    const { limit, page } = this.state;
    this.setState({ loading: true });
    getMessages({
      limit,
      page,
    });
    this.socket = io(process.env.REACT_APP_NODEJS_API_URL);
    this.socket.on('receiveMessage', (message) => {
      const { appointments, selectedAppointment } = this.state;
      const data = messagesSocketModule.receiveMessage({
        appointments,
        selectedAppointment,
        message,
      });
      this.setState({
        selectedAppointment: data.selectedAppointment,
        appointments: data.appointments,
      });
      this.scrollChatContent();
    });
    // Listen For Message Update
    this.socket.on('messageUpdated', (message) => {
      const { selectedAppointment, appointments } = this.state;
      const data = messagesSocketModule.updateMessage({
        appointments,
        selectedAppointment,
        message,
      });
      this.setState({
        appointments: data.appointments,
        selectedAppointment: data.selectedAppointment,
      });
    });
    // Listen For Message Delete
    this.socket.on('messageDeleted', ({ messageId, appointmentId }) => {
      const { appointments, selectedAppointment } = this.state;
      const data = messagesSocketModule.deleteMessage({
        appointments,
        selectedAppointment,
        messageId,
        appointmentId,
      });
      this.setState({
        appointments: data.appointments,
        selectedAppointment: data.selectedAppointment,
      });
    });
    const { children } = this.props;
    if (children) {
      const { selectedRole } = children.props;
      this.setState({ selectedRole });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      location,
      isGetMessagesSuccess,
      getMessagesErrorMessage,
      appointments,
      isGetMessagesError,
      isStoreMessageSuccess,
      isStoreMessageError,
      storedMessage,
      storeMessageErrorMessage,
      isMakeMessagesReadSuccess,
      isMakeMessagesReadError,
      messagesReadAppointmentId,
      isUpdateMessageSuccess,
      isUpdateMessageError,
      updatedMessage,
      updateMessageErrorMessage,
      isDeleteMessageSuccess,
      isDeleteMessageError,
      deleteMessageErrorMessage,
    } = this.props;
    // Handle Get Message Appointments Success
    if (!prevProps.isGetMessagesSuccess && isGetMessagesSuccess) {
      const { selectedAppointment } = this.state;
      const { history } = this.props;
      let selectedAppointmentCopy = { ...selectedAppointment };
      if (appointments.length > 0) {
        selectedAppointmentCopy = { ...appointments[0] };
      }
      this.setState({
        loading: false,
        appointments,
        selectedAppointment: selectedAppointmentCopy,
      }, () => {
        appointments.map((item) => {
          this.socket.emit('join', {
            permalink: item.permalink,
          });
        });
        if (history.location && selectedAppointmentCopy) {
          const getAppointmentUrlId = new URLSearchParams(location.search).get('appointment-room');
          const filteredAppointment = appointments.filter((item) => item.id === parseInt(getAppointmentUrlId));
          this.setState({
            selectedAppointment: filteredAppointment[0] || appointments[0],
          });
          history.push(`/messages/?appointment-room=${getAppointmentUrlId !== null ? getAppointmentUrlId : selectedAppointmentCopy.id}`);
        }
        this.scrollChatContent();
      });
    }
    // Handle Get Message Appointments Error
    if (!prevProps.isGetMessagesError && isGetMessagesError) {
      this.setState({ loading: false });
      this.snackBarAlert(true, getMessagesErrorMessage, 'error');
    }
    // Handle Store Message Success
    if (!prevProps.isStoreMessageSuccess && isStoreMessageSuccess && storedMessage.id) {
      const { appointments } = this.state;
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === storedMessage.appointment_id);
      appointmentsCopy[appointmentIndex].messages = [
        ...appointmentsCopy[appointmentIndex].messages,
        storedMessage,
      ];
      this.setState({
        appointments: appointmentsCopy,
        selectedAppointment: appointmentsCopy[appointmentIndex],
        newMessage: {
          from_id: '',
          to_id: '',
          text: '',
          appointment_id: '',
        },
        emptySendMessage: true,
      });
      this.scrollChatContent();
      this.socket.emit('sendMessage', {
        message: storedMessage,
        permalink: appointmentsCopy[appointmentIndex].permalink,
      });
    }
    // Handle Store Message Error
    if (!prevProps.isStoreMessageError && isStoreMessageError) {
      this.snackBarAlert(true, storeMessageErrorMessage, 'error');
    }
    // Handle Make Index Read Success
    if (!prevProps.isMakeMessagesReadSuccess && isMakeMessagesReadSuccess && !isMakeMessagesReadError) {
      const { appointments } = this.state;
      const { userAccount } = this.props;
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === parseInt(messagesReadAppointmentId));
      if (appointmentIndex !== -1) {
        appointmentsCopy[appointmentIndex].messages = appointmentsCopy[appointmentIndex].messages.map((item) => {
          if (item.to_id === userAccount.id) {
            return {
              ...item,
              seen_at: {
                ...item.seen_at,
                customer: moment().format('Y-MM-DD HH:mm'),
              },
            };
          } else {
            return item;
          }
        });
        this.setState({
          appointments: appointmentsCopy,
        });
      }
    }
    // Handle Update Message Success
    if (!prevProps.isUpdateMessageSuccess && isUpdateMessageSuccess && updatedMessage.id) {
      const { appointments } = this.state;
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === updatedMessage.appointment_id);
      const messageIndex = appointmentsCopy[appointmentIndex].messages.findIndex((item) => item.id === updatedMessage.id);
      if (messageIndex !== -1) {
        appointmentsCopy[appointmentIndex].messages[messageIndex] = updatedMessage;
      }
      this.setState({
        appointments: appointmentsCopy,
        newMessage: {
          from_id: '',
          to_id: '',
          text: '',
          appointment_id: '',
        },
        selectedAppointment: appointmentsCopy[appointmentIndex],
      });
      this.socket.emit('updateMessage', {
        message: updatedMessage,
        permalink: appointmentsCopy[appointmentIndex].permalink,
      });
    }
    // Handle Update Message Error
    if (!prevProps.isUpdateMessageError && isUpdateMessageError) {
      this.snackBarAlert(true, updateMessageErrorMessage, 'error');
    }
    // Handle Delete Message Success
    if (!prevProps.isDeleteMessageSuccess && isDeleteMessageSuccess) {
      const {
        appointments,
        selectedAppointment,
        newMessage,
      } = this.state;
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === selectedAppointment.id);
      const messageIndex = appointmentsCopy[appointmentIndex].messages.findIndex((item) => item.id === this.lastDeleteMessageId);
      if (messageIndex !== -1) {
        appointmentsCopy[appointmentIndex].messages.splice(messageIndex, 1);
      }
      const defaultMessageObject = {
        from_id: '',
        to_id: '',
        text: '',
        appointment_id: '',
      };
      const messageItem = newMessage.id && newMessage.id === parseInt(this.lastDeleteMessageId) ? { ...defaultMessageObject } : { ...newMessage };
      this.setState({
        appointments: appointmentsCopy,
        selectedAppointment: appointmentsCopy[appointmentIndex],
        newMessage: messageItem,
      });
      this.socket.emit('deleteMessage', {
        permalink: appointmentsCopy[appointmentIndex].permalink,
        messageId: this.lastDeleteMessageId,
        appointmentId: appointmentsCopy[appointmentIndex].id,
      });
      this.lastDeleteMessageId = null;
    }
    // Handle Delete Message Error
    if (!prevProps.isDeleteMessageError && isDeleteMessageError) {
      this.snackBarAlert(true, deleteMessageErrorMessage, 'error');
      this.lastDeleteMessageId = null;
    }
  }

  componentWillUnmount() {
    this.socket.emit('leaveConnection');
    this.socket.off();
  }

  snackBarAlert = (snackOpen, snackMessage, snackType) => {
    this.setState({
      snackbarType: snackType,
      openSnackbar: snackOpen,
      snackbarMessage: snackMessage,
    });
  };

  handleChooseAppointmentMessage = (id) => {
    const {
      appointments,
      selectedAppointment: { id: previousAppointmentId },
      newMessage,
    } = this.state;
    const { history } = this.props;
    const appointmentItem = appointments.find((item) => item.id === id);
    const appointmentsCopy = [...appointments];
    const prevAppointmentIndex = appointmentsCopy.findIndex((item) => item.id === parseInt(previousAppointmentId));
    appointmentsCopy[prevAppointmentIndex].newMessage = newMessage;
    const currentMessage = appointmentItem.newMessage || {
      from_id: '',
      to_id: '',
      text: '',
      appointment_id: '',
    };
    this.setState({
      appointments: appointmentsCopy,
      selectedAppointment: appointmentItem,
      newMessage: currentMessage,
    }, () => {
      this.scrollChatContent();
      this.makeAppointmentMessagesRead();
    });
    history.push(`/messages/?appointment-room=${appointmentItem.id}`);
  };

  handleChange = (panel) => (event, isExpanded) => {
    isExpanded ? this.setState({ expanded: panel }) : this.setState({ expanded: false });
  };

  handleMessageTextChange = (event) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      newMessage: {
        ...prevState.newMessage,
        text: value,
      },
    }));

    if (value === '') {
      this.setState({
        emptySendMessage: true,
      });
    } else {
      this.setState({
        emptySendMessage: false,
      });
    }
  };

  handleSendMessage = () => {
    const {
      newMessage, selectedAppointment,
    } = this.state;
    const { userAccount, storeMessage } = this.props;
    if (newMessage.text && (newMessage.text.trim() !== '')) {
      storeMessage({
        ...newMessage,
        from_id: userAccount.id,
        to_id: selectedAppointment.staff_id ? selectedAppointment.staff_id : selectedAppointment.individual_user_industry.user_id,
        appointment_id: selectedAppointment.id,
      });
    } else {
      this.setState({
        emptySendMessage: true,
      });
    }
  };

  scrollChatContent = () => {
    setTimeout(() => {
      const chantContentObj = document.getElementById('messages-content');
      chantContentObj ? chantContentObj.scrollTop = chantContentObj.scrollHeight : null;
    }, 10);
  };

  handlePageChanged = (page) => {
    this.setState({
      page,
      loading: true,
    });
    const { getMessages } = this.props;
    const { limit } = this.state;
    getMessages({
      limit,
      page,
    });
  };

  makeAppointmentMessagesRead = () => {
    const { selectedAppointment: { messages, id } } = this.state;
    const {
      userAccount: {
        activeRole: { name },
        id: accountId,
      },
    } = this.props;
    const roleName = name.toLowerCase();
    const atLeastOneUnread = messages.find((item) => item.seen_at[roleName] === 'null' && item.to_id === accountId);
    if (atLeastOneUnread && atLeastOneUnread.id) {
      const { makeMessagesRead } = this.props;
      makeMessagesRead({ id });
    }
  };

  handleEditMessage = (event, id) => {
    event.preventDefault();
    const { selectedAppointment } = this.state;
    const messageItem = selectedAppointment.messages.find((item) => item.id === id);
    this.setState({
      newMessage: messageItem,
    });
  };

  handleUpdateMessage = () => {
    const { newMessage } = this.state;
    const { updateMessage } = this.props;
    updateMessage(newMessage);
  };

  showDeleteMessagePopup = (event, id) => {
    event.preventDefault();
    const alert = (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={() => ({})}
        onCancel={() => this.setState({ alert: null })}
      >
        <DialogTitle
          id="customized-dialog-title"
          onCancel={() => this.setState({ alert: null })}
        >
          <span className="alert-title">
            <span>{i18n.t('ConfirmDeletion')}</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => this.setState({ alert: null })}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {i18n.t('AreYouSureDelete')} message ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({ alert: null })}
            size="small"
          >
            {i18n.t('Cancel')}
          </Button>
          <DeleteButton
            variant="contained"
            size="small"
            color="primary"
            onClick={() => this.handleDeleteMessage(id)}
          >
            {i18n.t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
      // <SweetAlert
      //   custom
      //   showCancel
      //   showCloseButton
      //   confirmBtnText="Delete"
      //   cancelBtnText="Cancel"
      //   confirmBtnBsStyle="primary"
      //   cancelBtnBsStyle="default"
      //   title="Confirm message delete"
      //   onConfirm={() => this.handleDeleteMessage(id)}
      //   onCancel={() => this.setState({ alert: null })}
      // />
    );
    this.setState({ alert });
  };

  handleDeleteMessage = (id) => {
    this.lastDeleteMessageId = id;
    const { deleteMessage } = this.props;
    deleteMessage({ id });
    this.setState({ alert: null });
  };

  render() {
    const {
      loading,
      appointments,
      selectedAppointment,
      newMessage,
      alert,
      expanded,
      selectedRole,
      emptySendMessage,
      openSnackbar,
      snackbarType,
      snackbarMessage,
    } = this.state;
    const { userAccount, children } = this.props;

    return !loading ? (
      <>
        <div className="menu-item messages">
          <div className="general-content">
            <div className="panel">
              <div className="main-content">
                <div>
                  <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
                    <Toolbar>
                      <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                        <Typography variant="h6">
                          <Box display="flex" alignItems="center" height="100%">
                            Messages
                          </Box>
                        </Typography>
                        <div>
                          {children}
                        </div>
                      </Box>
                    </Toolbar>
                  </AppBar>
                  { appointments.length > 0 ? (
                    <Box className="main-chat-content accordion">
                      <Accordion expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                        >
                          <Typography>Users</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="left-content">
                            <LeftContent
                              appointments={appointments}
                              selectedAppointment={selectedAppointment}
                              handleChooseAppointmentMessage={this.handleChooseAppointmentMessage}
                              userAccount={userAccount}
                              selectedRole={selectedRole}
                            />
                            {/* <Pagination page={page} pages={pages} handlePageChanged={this.handlePageChanged} /> */}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2bh-content"
                        >
                          <Typography>Chats</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="content-chat">
                            <TopContent selectedAppointment={selectedAppointment} userRole="customer" />
                            <div className="chat" onClick={() => this.makeAppointmentMessagesRead()}>
                              <ChatContent
                                selectedAppointment={selectedAppointment}
                                userAccount={userAccount}
                                handleEditMessage={(event, id) => this.handleEditMessage(event, id)}
                                showDeleteMessagePopup={(event, id) => this.showDeleteMessagePopup(event, id)}
                              />
                              <SubmitMessage
                                newMessage={newMessage}
                                handleSendMessage={this.handleSendMessage}
                                handleUpdateMessage={this.handleUpdateMessage}
                                handleMessageTextChange={this.handleMessageTextChange}
                                onClick={() => this.makeAppointmentMessagesRead()}
                                emptySendMessage={emptySendMessage}
                              />
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion expanded={expanded === 'panel3'} className="accordion-last-item" onChange={this.handleChange('panel3')}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel3bh-content"
                        >
                          <Typography>{i18n.t('Details')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <RightContent selectedAppointment={selectedAppointment} />
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  ) : (
                    <Index />
                  )}
                </div>
              </div>
            </div>
          </div>
          { alert }
        </div>
        <SnackbarToast
          type={snackbarType}
          open={openSnackbar}
          message={snackbarMessage}
          onClose={() => this.setState({ openSnackbar: false })}
        />
      </>
    ) : <MessagesLoading />;
  }
}

const mapStateToProps = (state) => ({
  // Get Index
  isGetMessagesSuccess: state.message.isGetMessagesSuccess,
  isGetMessagesError: state.message.isGetMessagesError,
  appointments: state.message.appointments,
  allCount: state.message.allCount,
  getMessagesErrorMessage: state.message.getMessagesErrorMessage,
  userAccount: state.account.userAccount,
  // Store Message
  isStoreMessageSuccess: state.message.isStoreMessageSuccess,
  isStoreMessageError: state.message.isStoreMessageError,
  storedMessage: state.message.storedMessage,
  storeMessageErrorMessage: state.message.storeMessageErrorMessage,
  // Make Index Read
  isMakeMessagesReadSuccess: state.message.isMakeMessagesReadSuccess,
  isMakeMessagesReadError: state.message.isMakeMessagesReadError,
  messagesReadAppointmentId: state.message.messagesReadAppointmentId,
  // Update Message
  isUpdateMessageSuccess: state.message.isUpdateMessageSuccess,
  isUpdateMessageError: state.message.isUpdateMessageError,
  updatedMessage: state.message.updatedMessage,
  updateMessageErrorMessage: state.message.updateMessageErrorMessage,
  // Delete Message
  isDeleteMessageSuccess: state.message.isDeleteMessageSuccess,
  isDeleteMessageError: state.message.isDeleteMessageError,
  deleteMessageErrorMessage: state.message.deleteMessageErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    getMessages: (data) => dispatch(getMessagesRequest(data)),
    storeMessage: (data) => dispatch(storeMessageRequest(data)),
    makeMessagesRead: (data) => dispatch(makeMessagesReadRequest(data)),
    updateMessage: (data) => dispatch(updateMessageRequest(data)),
    deleteMessage: (data) => dispatch(deleteMessageRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
