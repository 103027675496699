import {
  TableBody,
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import Row from './row';

function Body(props) {
  const {
    rows,
    selected,
    order,
    orderBy,
    handleClick,
    onRowClick,
    deletedCustomerId,
  } = props;

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const isSelected = (name) => {
    if (selected.length) {
      return selected.indexOf(name) !== -1;
    } else {
      return deletedCustomerId.indexOf(name) !== -1;
    }
  };

  return (
    <TableBody>
      {stableSort(rows, getComparator(order, orderBy))
        .map((row, index) => {
          const isItemSelected = isSelected(row.id);
          const labelId = `enhanced-table-checkbox-${index}`;

          return (
            <Row
              row={row}
              key={`${row.id}-${index}`}
              isItemSelected={isItemSelected}
              labelId={labelId}
              onRowClick={onRowClick}
              handleClick={handleClick}
            />
          );
        })}
    </TableBody>
  );
}

Body.propTypes = {
  rows: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  deletedCustomerId: PropTypes.array.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

export default Body;
