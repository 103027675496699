import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';

function renderActivityButtons(props) {
  const {
    notification,
    handleAcceptAppointment,
    handleCancelAppointment,
    handleAcceptCustomerInvitation,
    handleDeclineCustomerInvitation,
    handleAcceptIndustryCustomerInvitation,
    handleDeclineIndustryCustomerInvitation,
    handleAcceptStaffInvitation,
    handleDeclineStaffInvitation,
  } = props;

  const { action: { name } } = notification;
  const confirmButtonText = 'Accept';
  // let cancelButtonText = 'Decline';
  let onAcceptClickFunction;
  let onCancelClickFunction;

  switch (name) {
    case 'customer.invitation.received': {
      onAcceptClickFunction = handleAcceptCustomerInvitation;
      onCancelClickFunction = handleDeclineCustomerInvitation;
      break;
    }
    case 'individual.customer.invitation.received': {
      onAcceptClickFunction = handleAcceptIndustryCustomerInvitation;
      onCancelClickFunction = handleDeclineIndustryCustomerInvitation;
      break;
    }
    case 'staff.invitation.received': {
      onAcceptClickFunction = handleAcceptStaffInvitation;
      onCancelClickFunction = handleDeclineStaffInvitation;
      break;
    }
    default: {
      onAcceptClickFunction = handleAcceptAppointment;
      onCancelClickFunction = handleCancelAppointment;
      // cancelButtonText = 'Decline';
    }
  }

  return (
    <Box className="notification-buttons">
      <Button
        className="cancel-btn activity"
        size="small"
        onClick={() => onCancelClickFunction(notification.id)}
      >
        <span className="activity">Cancel</span>
      </Button>
      <Button
        size="small"
        className="accept-btn activity"
        variant="contained"
        color="primary"
        onClick={() => onAcceptClickFunction(notification.id)}
      >
        <span className="activity">{confirmButtonText}</span>
      </Button>
    </Box>
  );
}

renderActivityButtons.propTypes = {
  notification: PropTypes.object.isRequired,
  handleAcceptAppointment: PropTypes.func.isRequired,
  handleCancelAppointment: PropTypes.func.isRequired,
  handleAcceptCustomerInvitation: PropTypes.func.isRequired,
  handleDeclineCustomerInvitation: PropTypes.func.isRequired,
  handleAcceptIndustryCustomerInvitation: PropTypes.func.isRequired,
  handleDeclineIndustryCustomerInvitation: PropTypes.func.isRequired,
  handleAcceptStaffInvitation: PropTypes.func.isRequired,
  handleDeclineStaffInvitation: PropTypes.func.isRequired,
};

export default renderActivityButtons;
