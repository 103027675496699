import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import i18n from 'i18next';
import DeleteButton from '../../../MaterialComponents/deleteButton';

const DeleteGalleryImage = (props) => {
  const {
    handleClose,
    removeCompanyImage,
    openDeleteImageModal,
    deleteGalleryLoading,
  } = props;

  return (
    <Dialog onClose={handleClose} open={openDeleteImageModal}>
      <DialogTitle onClose={() => handleClose()}>
        <span className="alert-title">
          <span>{i18n.t('ConfirmDeletion')}</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{i18n.t('AreYouSureDelete')} image? </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => handleClose()}
        >
          {i18n.t('Cancel')}
        </Button>
        <DeleteButton
          size="small"
          variant="contained"
          color="primary"
          onClick={removeCompanyImage}
          loading={deleteGalleryLoading}
        >
          {deleteGalleryLoading && <CircularProgress color="white" size={20} />}
          {!deleteGalleryLoading && i18n.t('Delete')}
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteGalleryImage.propTypes = {
  handleClose: PropTypes.func.isRequired,
  removeCompanyImage: PropTypes.func.isRequired,
  openDeleteImageModal: PropTypes.bool.isRequired,
  deleteGalleryLoading: PropTypes.bool.isRequired,
};

export default DeleteGalleryImage;
