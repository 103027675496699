import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import usePrevious from 'CustomHooks/usePrevious';
import {
  acceptRequest,
  cancelRequest,
  deleteAppointmentRequest,
  filterAllAppointmentsRequest,
  filterAllMyAppointmentsRequest,
  // finishRequest,
} from 'redux/appointment/actions';
import filterItems from 'Modules/AppointmentsFilterItems/personalAppointments';
import PermissionsModule from 'Modules/Permission';
import 'style/appointments.scss';
import CreateCustomer from 'Modals/CreateCustomer';
import SnackbarToast from 'Modules/SnackbarToast';
import { getUserSettingsRequest } from 'redux/account/actions';
import { getCompanyStaffHaveServiceRequest } from 'redux/staff/actions';
import AddIcon from '@material-ui/icons/Add';
import FilterIcon from 'assets/icons/filter.svg';
import AppointmentsDrawer from './Drawer';
import styles from './styles.module.scss';
import AppointmentTable from './Table';
import AppointmentFilterDrawer from './FilterDrawer';
import AppointmentDetailsDrawer from './Drawer/appointmentDetails';
import AppointmentActionModal from './Drawer/ActionModal';

function MyAppointments(props) {
  const dispatch = useDispatch();
  // Translation
  const { t } = useTranslation();

  const {
    userAccount,
    userSettings,
    isGetUserSettingsError,
    isGetUserSettingsSuccess,
    getUserSettingsErrorMessage,
  } = useSelector((state) => state.account);

  const {
    isGetCompanyStaffHaveServiceSuccess,
    companyStaffHaveService,
  } = useSelector((state) => state.staff);

  const {
    inviteCustomerToCompanySucceed,
    isInviteCustomerToCompanySuccess,
  } = useSelector((state) => state.company);

  const {
    isAcceptSuccess,
    isCancelSuccess,
    isFinishSuccess,
    isDeleteAppointmentSuccess,
    isDeleteAppointmentError,
    deleteAppointmentErrorMessage,
    isGetGeneralAppointmentsError,
    isFilterAllAppointmentsSuccess,
    isFilterAllAppointmentsError,
    filteredAllMyAppointments,
    filteredAllMyAppointmentsCount,
    isUpdateAppointmentSuccess,
    isUpdateAppointmentError,
    updatedAppointment,
    isGetUserRoleInAppointmentSuccess,
    userRoleInAppointment,
    newAppointment,
    isStoreAppointmentSuccess,
    isStoreAppointmentError,
  } = useSelector((state) => state.appointment);

  const { isGetAllCountriesSuccess, allCountries } = useSelector((state) => state.country);

  const { history } = props;

  // Get some props previous values
  const prevIsAcceptSuccess = usePrevious(isAcceptSuccess);
  const prevIsCancelSuccess = usePrevious(isCancelSuccess);
  const prevIsFinishSuccess = usePrevious(isFinishSuccess);
  // const prevIsListOwnerCompaniesSuccess = usePrevious(isListOwnerCompaniesSuccess);
  const prevIsGetCompanyStaffHaveServiceSuccess = usePrevious(isGetCompanyStaffHaveServiceSuccess);
  const prevIsGetUserSettingsError = usePrevious(isGetUserSettingsError);
  const prevIsGetUserSettingsSuccess = usePrevious(isGetUserSettingsSuccess);
  const prevIsDeleteAppointmentError = usePrevious(isDeleteAppointmentError);
  const prevIsUpdateAppointmentError = usePrevious(isUpdateAppointmentError);
  const prevIsDeleteAppointmentSuccess = usePrevious(isDeleteAppointmentSuccess);
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);
  const prevIsFilterAllAppointmentsSuccess = usePrevious(isFilterAllAppointmentsSuccess);
  const prevIsInviteCustomerToCompanySuccess = usePrevious(isInviteCustomerToCompanySuccess);
  const prevIsGetUserRoleInAppointmentSuccess = usePrevious(isGetUserRoleInAppointmentSuccess);
  const prevIsGetAllCountriesSuccess = usePrevious(isGetAllCountriesSuccess);
  const prevIsStoreAppointmentSuccess = usePrevious(isStoreAppointmentSuccess);
  const prevIsStoreAppointmentError = usePrevious(isStoreAppointmentError);
  const [tableLoading, setTableLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment().add(1, 'months').format('YYYY-MM-DD'));
  const [appointmentsCount, setAppointmentsCount] = useState(0);
  // const [tableHeader, setTableHeader] = useState();
  const [checkedItems, setCheckedItems] = useState({
    status: [1, 2, 3, 4],
    payment: ['paid', 'unpaid'],
    date: {
      from: selectedStartDate,
      to: selectedEndDate,
    },
  });
  const [filterData] = useState(filterItems);
  const [userRoles, setUserRoles] = useState([]);
  const [, setAppointmentItem] = useState({});
  const [selectedAppointmentId, setSelectedAppointmentId] = useState(null);
  const [isCreateCustomerModalShow, setIsCreateCustomerModalShow] = useState(false);
  const [fromCalendar] = useState(false);
  const [, setStaffOptions] = useState([]);
  const [page, setPage] = useState(0);
  const [, setSelectedRole] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [acceptedAppointmentId, setAcceptedAppointmentId] = useState('');
  const [countries, setCountries] = useState({});

  const [isShowAppointmentDrawer, setIsShowAppointmentDrawer] = useState(false);
  const [isShowAppointmentDetailsDrawer, setIsShowAppointmentDetailsDrawer] = useState(false);
  const [isOpenActionModal, setIsOpenActionModal] = useState(false);
  const [appointment, setAppointment] = useState({});
  const [actionType, setActionType] = useState('');
  const [appointmentDeleteLoading, setAppointmentDeleteLoading] = useState(false);
  const [appointmentCancelLoading, setAppointmentCancelLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Get all appointments by checked items when component mounted
  useEffect(() => {
    const checkedStatuses = localStorage.getItem('appointmentsCheckedStatuses');
    checkedStatuses ? setCheckedItems({ ...checkedItems, status: JSON.parse(checkedStatuses) }) : localStorage.setItem('appointmentsCheckedStatuses', JSON.stringify(checkedItems.status));
    const userRoles = PermissionsModule.getRolesWithId(userAccount.testPermissions);
    const userRolesCopy = [...userRoles];
    const activeRoleId = userAccount.activeRole.id;
    if (activeRoleId !== 2) {
      const roleIds = [];
      userRoles.map((item) => {
        if (!roleIds.includes(item.role_id.toString())) {
          roleIds.push(item.role_id.toString());
        }
      });
      // setCheckedItems({ ...checkedItems, roles: roleIds, status: checkedStatuses && JSON.parse(checkedStatuses) });
    }
    setUserRoles(userRolesCopy);
    // const tableHeaderItems = [
    //   t('DateTime'),
    //   t('Duration'),
    //   t('Customer'),
    //   t('Service'),
    //   t('Price'),
    //   t('Staff'),
    //   t('Status'),
    //   t('Actions'),
    // ];
    // const selectedStatus = (filterData.status.filter((item, index) => (index < 2))).map((item) => parseInt(item.status_id));
    // setCheckedItems({
    //   ...checkedItems,
    //   status: selectedStatus,
    // });
    // setTableHeader(tableHeaderItems);

    // TODO change some calls
    if (!Object.keys(userSettings).length) {
      dispatch(getUserSettingsRequest());
    } else {
      getUserSettings();
    }

    if (!companyStaffHaveService.length) {
      dispatch(getCompanyStaffHaveServiceRequest());
    } else {
      getCompanyStaff();
    }
  }, []);
  // Handle get company staff success
  useEffect(() => {
    if (prevIsGetCompanyStaffHaveServiceSuccess === false && isGetCompanyStaffHaveServiceSuccess) {
      getCompanyStaff();
    }
  }, [isGetCompanyStaffHaveServiceSuccess]);
  // Handle get countries
  useEffect(() => {
    if (prevIsGetAllCountriesSuccess === false && isGetAllCountriesSuccess) {
      setCountries(allCountries);
    }
  }, [isGetAllCountriesSuccess]);
  // Get appointments Error
  useEffect(() => {
    if (isGetGeneralAppointmentsError) {
      setOpenSnackbar(true);
      setSnackbarMessage('Something went wrong');
      setSnackbarType('error');
      history.push('/calendar');
    }
  }, [isGetGeneralAppointmentsError]);
  // delete appointment
  useEffect(() => {
    if (prevIsDeleteAppointmentSuccess === false && isDeleteAppointmentSuccess) {
      const appointmentIndex = appointments.findIndex((item) => item.id === selectedAppointmentId);
      const newAppointments = [...appointments];
      newAppointments.splice(appointmentIndex, 1);
      setAppointmentDeleteLoading(false);
      setIsOpenActionModal(false);
      setAppointments(newAppointments);
      setOpenSnackbar(true);
      setSnackbarMessage('Appointment deleted');
      setSnackbarType('success');
    } else if (prevIsDeleteAppointmentError === false && isDeleteAppointmentError) {
      setIsOpenActionModal(false);
      setAppointmentDeleteLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage(deleteAppointmentErrorMessage);
      setSnackbarType('error');
    }
  }, [isDeleteAppointmentSuccess, isDeleteAppointmentError]);
  // Handle invite customer to company success
  useEffect(() => {
    if (prevIsInviteCustomerToCompanySuccess === false && isInviteCustomerToCompanySuccess) {
      setTableLoading(true);
      setOpenSnackbar(true);
      setSnackbarMessage(inviteCustomerToCompanySucceed.message);
      setSnackbarType('success');
    }
  }, [isInviteCustomerToCompanySuccess]);
  // Perform, when get user role appointments success
  useEffect(() => {
    if (prevIsGetUserRoleInAppointmentSuccess === false && isGetUserRoleInAppointmentSuccess) {
      localStorage.setItem('prefix', userRoleInAppointment);
      setSelectedRole(userRoleInAppointment.toLowerCase());
    }
  }, [isGetUserRoleInAppointmentSuccess]);
  // Get filtered appointments
  useEffect(() => {
    if (prevIsFilterAllAppointmentsSuccess === false && isFilterAllAppointmentsSuccess) {
      getFilteredAppointments();
      setAppointmentsCount(filteredAllMyAppointmentsCount);
    }
  }, [isFilterAllAppointmentsSuccess]);
  // When appointment is not filtered
  useEffect(() => {
    if (isFilterAllAppointmentsError) {
      setOpenSnackbar(true);
      setSnackbarMessage('Appointment is not filtered');
      setSnackbarType('error');
    }
  }, [isFilterAllAppointmentsError]);
  // Handle updated appointment success
  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === updatedAppointment.id);
      appointmentsCopy.splice(appointmentIndex, 1, updatedAppointment);
      setAppointments(appointmentsCopy);
      setOpenSnackbar(true);
      setSnackbarMessage('Appointment rescheduled successfully');
      setSnackbarType('success');
    }
  }, [isUpdateAppointmentSuccess]);
  // Handle updated appointment error
  useEffect(() => {
    if (prevIsUpdateAppointmentError === false && isUpdateAppointmentError) {
      // Do not need anything :) !
    }
  }, [isUpdateAppointmentError]);
  // Handle settings is success
  useEffect(() => {
    if (prevIsGetUserSettingsSuccess === false && isGetUserSettingsSuccess) {
      getUserSettings();
    }
  }, [isGetUserSettingsSuccess]);
  // Handle settings is error
  useEffect(() => {
    if (prevIsGetUserSettingsError === false && isGetUserSettingsError) {
      setOpenSnackbar(true);
      setSnackbarMessage(getUserSettingsErrorMessage);
      setSnackbarType('error');
    }
  }, [isGetUserSettingsError]);
  // Appointment was accepted
  useEffect(() => {
    if (!prevIsAcceptSuccess && isAcceptSuccess && acceptedAppointmentId) {
      const appointmentCopy = [...appointments];
      const appointmentIndex = appointmentCopy.findIndex((item) => item.id === acceptedAppointmentId);
      if (checkedItems.status.includes(1) && appointmentCopy.length) {
        appointmentCopy[appointmentIndex].status_id = 1;
        appointmentCopy[appointmentIndex].is_accept_allowed = false;
        appointmentCopy[appointmentIndex].status_name = 'accepted';
      } else {
        setAppointmentsCount(appointmentsCount - 1);
        appointmentCopy.splice(appointmentIndex, 1);
      }
      setAppointments(appointmentCopy);
    }
  }, [isAcceptSuccess]);
  // Appointment was canceled
  useEffect(() => {
    if (prevIsCancelSuccess === false && isCancelSuccess) {
      const appointmentCopy = [...appointments];
      const appointmentIndex = appointmentCopy.findIndex((item) => item.id === selectedAppointmentId);
      if (checkedItems.status.includes(3) && appointmentIndex !== -1) {
        appointmentCopy[appointmentIndex].status_id = 3;
        appointmentCopy[appointmentIndex].is_accept_allowed = false;
        appointmentCopy[appointmentIndex].status_name = 'cancelled';
      } else {
        setAppointmentsCount(appointmentsCount - 1);
        appointmentCopy.splice(appointmentIndex, 1);
      }
      setAppointments(appointmentCopy);
      setIsOpenActionModal(false);
      setAppointmentCancelLoading(false);
      setIsShowAppointmentDetailsDrawer(false);
      setOpenSnackbar(true);
      setSnackbarMessage('Appointment canceled');
      setSnackbarType('info');
    }
  }, [isCancelSuccess]);

  // Appointment was finished
  useEffect(() => {
    if (!prevIsFinishSuccess && isFinishSuccess) {
      const appointmentCopy = [...appointments];
      const appointmentIndex = appointmentCopy.findIndex((item) => item.id === selectedAppointmentId);
      if (checkedItems.status.includes(4)) {
        if (appointmentIndex !== -1) {
          appointmentCopy[appointmentIndex].status_id = 4;
          appointmentCopy[appointmentIndex].is_accept_allowed = false;
          appointmentCopy[appointmentIndex].status_name = 'finished';
        }
      } else {
        setAppointmentsCount(appointmentsCount - 1);
        appointmentCopy.splice(appointmentIndex, 1);
      }
      setAppointments(appointmentCopy);
    }
  }, [isFinishSuccess]);

  // Handle Store Appointment Success
  useEffect(() => {
    if (prevIsStoreAppointmentSuccess === false && isStoreAppointmentSuccess) {
      setAppointmentsCount(appointmentsCount + 1);
      if (appointments.length < rowsPerPage) {
        addNewAppointment();
      }
    }
  }, [isStoreAppointmentSuccess]);

  // Handle Store Appointment Error
  useEffect(() => {
    if (prevIsStoreAppointmentError === false && isStoreAppointmentError) {
      setOpenSnackbar(true);
      setSnackbarMessage('Something went wrong');
      setSnackbarType('error');
    }
  }, [isStoreAppointmentError]);

  const addNewAppointment = () => {
    const appointmentsCopy = [...appointments];
    appointmentsCopy.push(newAppointment);
    setAppointments(appointmentsCopy);
  };

  // Get filtered appointments success
  const getFilteredAppointments = () => {
    const filteredAllAppointmentsCopy = [...filteredAllMyAppointments];
    handleSortingAppointment(filteredAllAppointmentsCopy);
    setTableLoading(true);
    setAnchorEl(null);
  };

  // Get company staff success
  const getCompanyStaff = () => {
    const staffOptions = companyStaffHaveService.map(({ id, full_name, avatar }) => ({
      value: id,
      label: full_name,
      logo: avatar,
    }));
    setStaffOptions(staffOptions);
  };

  // Get User Settings success
  const getUserSettings = () => {
    const pastAppointments = userSettings.appointment && userSettings.appointment.past_appointments;
    const checkedStatuses = localStorage.getItem('appointmentsCheckedStatuses');
    const dateRange = localStorage.getItem('dateRange');
    if (dateRange) {
      const range = JSON.parse(dateRange);
      setSelectedStartDate(range.start);
      setSelectedEndDate(range.end);
    } else {
      localStorage.setItem('dateRange', JSON.stringify({ start: selectedStartDate, end: selectedEndDate }));
    }

    // setCheckedItems({
    //   ...checkedItems,
    //   status: pastAppointments ? [1, 2, 4] : checkedItems.status,
    // });
    const data = {
      status_ids: checkedStatuses ? JSON.parse(checkedStatuses) : pastAppointments ? [1, 2, 4] : checkedItems.status,
      start_date: dateRange ? JSON.parse(dateRange).start : selectedStartDate,
      end_date: dateRange ? JSON.parse(dateRange).end : selectedEndDate,
      searchValue: '',
      page: 1,
      limit: 10,
    };
    setPage(0);
    dispatch(filterAllAppointmentsRequest(data));
    // setCompanySettings(userSettings);
  };

  // open filter menu
  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // close filter menu
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };
  // handle change and filter checkboxes
  const handleChangeCheckboxes = (event) => {
    const { name, value } = event.target;
    const checkedFilterItems = checkedItems[name].includes(parseInt(value))
      ? { ...checkedItems, [name]: checkedItems[name].filter((item) => item !== parseInt(value)) }
      : { ...checkedItems, [name]: [...checkedItems[name], parseInt(value)] };

    localStorage.setItem('appointmentsCheckedStatuses', JSON.stringify(checkedFilterItems.status));

    setCheckedItems({ ...checkedFilterItems });
  };
  // Change start date
  const handleChangeStartDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const start = moment(selectedEndDate).subtract(1, 'months').format('YYYY-MM-DD');

    if (!moment(date).isBetween(start, selectedEndDate, null, '[]') && formattedDate !== selectedEndDate) {
      setSelectedEndDate(moment(date).add(1, 'M').format('YYYY-MM-DD'));
    }

    setSelectedStartDate(formattedDate);
  };
  // Change end date
  const handleChangeEndDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const start = moment(selectedStartDate).add(1, 'months').format('YYYY-MM-DD');

    if (!moment(date).isBetween(selectedStartDate, start, null, '[]') && formattedDate !== selectedStartDate) {
      setSelectedStartDate(moment(date).subtract(1, 'M').format('YYYY-MM-DD'));
    }

    setSelectedEndDate(formattedDate);
  };
  // submit data for filtered appointments
  const submitFilter = () => {
    const data = {
      status_ids: checkedItems.status,
      service_ids: checkedItems.services,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      searchValue: '',
      page: 1,
    };

    localStorage.setItem('dateRange', JSON.stringify({
      start: selectedStartDate, end: selectedEndDate,
    }));

    setPage(0);
    dispatch(filterAllMyAppointmentsRequest(data));
    setTableLoading(true);
  };
  // set table Header related appointment data
  // const getSelectedRoleTableHeader = ({ roles }) => {
  //   const hasCustomer = roles.includes('3');
  //   const hasIndividual = roles.includes('5');
  //   const hasStaff = roles.includes('4');
  //   if (hasCustomer) {
  //     const newHeaders = ['Date', 'Company', 'Individual', 'Industry', 'Service', 'Status', 'Actions'];
  //     setTableHeader([...newHeaders]);
  //   } else if (hasIndividual) {
  //     const newHeaders = ['Date', 'Individual', 'Industry', 'Customer', 'Service', 'Status', 'Actions'];
  //     setTableHeader([...newHeaders]);
  //   } else if (hasStaff) {
  //     const newHeaders = ['Date', 'Company', 'Customer', 'Service', 'Status', 'Actions'];
  //     setTableHeader([...newHeaders]);
  //   }
  // };

  const handleDeleteAppointment = () => {
    setAppointmentDeleteLoading(true);
    dispatch(deleteAppointmentRequest({ id: selectedAppointmentId }));
  };

  const openCancelAppModal = (e, id) => {
    e.stopPropagation();
    setSelectedAppointmentId(id);
    setActionType('cancel');
    setIsOpenActionModal(true);
  };

  const showConfirmDelete = (e, id) => {
    e.stopPropagation();
    setSelectedAppointmentId(id);
    setActionType('delete');
    setIsOpenActionModal(true);
    // setIsDeleteAppointmentModalShown(true);
    // const [first] = PermissionsModule.getRoles(userAccount.testPermissions);
    // localStorage.setItem('prefix', first.toLowerCase());
    // dispatch(getUserRoleInAppointmentRequest({ id }));
  };

  const handleOpenCreateAppointmentModal = () => {
    setAppointmentItem({});
    setAppointment({});
    setIsShowAppointmentDrawer(true);
    // if (!allCompanyCustomers.length) {
    //   dispatch(getAllCompanyCustomersRequest());
    // }
  };

  const closeCreateOrUpdateAppointmentModal = () => {
    // setIsCreateOrUpdateAppointmentPerformed(false);
  };

  // const updateCompanyStaff = (staff, appointment) => {
  //   const addedAppointments = [...appointments, appointment];
  //   handleSortingAppointment(addedAppointments);
  // };

  const handleSortingAppointment = (appointments) => {
    const allAppointments = appointments.map((item) => ({
      ...item,
      dateTime: `${item.date} ${item.start_time}`,
    }));
    const sortedAppointment = allAppointments.sort((x, y) => moment.utc(x.dateTime).diff(moment.utc(y.dateTime)));
    setAppointments(sortedAppointment);
  };

  const handleCloseCreateCustomerModal = () => {
    setIsCreateCustomerModalShow(false);
    // setIsCreateOrUpdateAppointmentPerformed(false);
  };

  const handleOpenUpdateModal = (e, itemID) => {
    const selectedAppointment = appointments.find((item) => item.id === itemID);
    const now = moment();
    const appointmentTime = moment(`${selectedAppointment.date} ${selectedAppointment.start_time}`);
    if (now.isAfter(appointmentTime) || selectedAppointment.status_name === 'cancelled' || selectedAppointment.status_name === 'pending') {
      setAppointment(selectedAppointment);
      setIsShowAppointmentDetailsDrawer(true);
    } else {
      setAppointment({
        id: parseInt(selectedAppointment.id),
        start_time: moment(`${selectedAppointment.date} ${selectedAppointment.start_time}`).format('HH:mm'),
        date: selectedAppointment.date,
        service_id: selectedAppointment.service_id,
        customer_id: selectedAppointment.customer_id,
        price: selectedAppointment.price,
        staff_id: selectedAppointment.staff_id,
        status_name: selectedAppointment.status_name,
        company_service_id: selectedAppointment.company_service_id,
        duration: parseInt(selectedAppointment.duration),
        note_from_creator: selectedAppointment.description,
        from_widget: selectedAppointment.from_widget,
      });
      setIsShowAppointmentDrawer(true);
    }
  };

  // Search Appointments
  // const handleSearchAppointment = (event) => {
  //   const data = {
  //     status_ids: checkedItems.status,
  //     service_ids: checkedItems.services,
  //     start_date: selectedStartDate,
  //     end_date: selectedEndDate,
  //     searchValue: event.target.value,
  //     page: 1,
  //     limit: 10,
  //   };
  //   dispatch(filterAllAppointmentsRequest(data));
  //   setTableLoading(true);
  // };

  const handleChangeAppointmentStatus = (event, value) => {
    const { name } = event.target;
    const appointmentCopy = [...appointments];
    appointmentCopy[name] = value;
    setAppointments(appointmentCopy);
  };

  const handleAcceptAppointmentSelect = (e, id) => {
    e.stopPropagation();
    setAcceptedAppointmentId(id);
    dispatch(acceptRequest(id));
  };

  const handleCancelAppointmentSelect = () => {
    setAppointmentCancelLoading(true);
    dispatch(cancelRequest({ id: selectedAppointmentId }));
  };

  const handleFinishAppointmentSelect = () => {
    setIsShowAppointmentDetailsDrawer(true);
    // e.stopPropagation();
    // setSelectedAppointmentId(id);
    // const selectedApp = appointments.find((item) => item.id === id);
    // dispatch(finishRequest({ id, price: selectedApp.price.price.from }));
  };

  const checkStartTimeAppointment = (date, startTime) => {
    const appointmentDate = moment(`${date} ${startTime}`);
    const currentDate = moment();

    return appointmentDate.isBefore(currentDate);
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  return (
    <>
      <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={12} md={6} className="title-companyName">
              {/*  <Grid container justify="flex-start"> */}
              {/*    <Grid item> */}
              {/*      <Box alignSelf="center"> */}
              {/*        <TextField */}
              {/*          type="search" */}
              {/*          size="small" */}
              {/*          color="secondary" */}
              {/*          className="search-appointment" */}
              {/*          placeholder={t('SearchByCustomerOrStaff')} */}
              {/*          InputProps={{ */}
              {/*            startAdornment: <SearchIcon position="start" style={{ color: '#393939', fontSize: 16, marginRight: 10 }} />, */}
              {/*          }} */}
              {/*          variant="outlined" */}
              {/*          onChange={handleSearchAppointment} */}
              {/*        /> */}
              {/*      </Box> */}
              {/*    </Grid> */}
              {/*  </Grid> */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container justify="flex-end">
                <Grid item md={12} className="appointments-fields">
                  <Box display="flex" flexWrap="wrap" justifyContent="flex-end">
                    <Box alignSelf="center" className="button-box">
                      <Button
                        aria-label="filter"
                        onClick={handleOpenFilter}
                        className="filterIcon"
                      >
                        <img src={FilterIcon} alt="filter" />
                        <span
                          style={{
                            marginLeft: '6px',
                            color: '#393939',
                            font: 'normal 600 14px/19px Nunito Sans, sans-serif',
                          }}
                        >
                          {t('Filter')}
                        </span>
                      </Button>
                      {/* <FilterContent */}
                      {/*  handleCloseFilter={handleCloseFilter} */}
                      {/*  anchorEl={anchorEl} */}
                      {/*  selectedDateFrom={selectedStartDate} */}
                      {/*  selectedDateTo={selectedEndDate} */}
                      {/*  handleChangeStartDate={handleChangeStartDate} */}
                      {/*  handleChangeEndDate={handleChangeEndDate} */}
                      {/*  handleChangeCheckboxes={handleChangeCheckboxes} */}
                      {/*  checkedItems={checkedItems} */}
                      {/*  filterData={filterData} */}
                      {/*  submitFilter={submitFilter} */}
                      {/*  userRoles={userRoles} */}
                      {/*  getSelectedRoleTableHeader={getSelectedRoleTableHeader} */}
                      {/* /> */}
                      <Button
                        variant="contained"
                        onClick={handleOpenCreateAppointmentModal}
                        color="primary"
                        size="small"
                        startIcon={<AddIcon />}
                      >
                        {t('NewAppointment')}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <div id={styles.appointmentContainer}>
        <div className="menu-item appointments">
          <div className="general-content">
            <Box className={styles.tableBox}>
              <AppointmentTable
                appointments={appointments}
                loading={tableLoading}
                setLoading={setTableLoading}
                checkStartTimeAppointment={checkStartTimeAppointment}
                handleCancelAppointmentSelect={openCancelAppModal}
                handleFinishAppointmentSelect={handleFinishAppointmentSelect}
                handleChangeAppointmentStatus={handleChangeAppointmentStatus}
                handleAcceptAppointmentSelect={handleAcceptAppointmentSelect}
                showConfirmDelete={showConfirmDelete}
                handleOpenUpdateModal={handleOpenUpdateModal}
                checkedItems={checkedItems}
                selectedStartDate={selectedStartDate}
                selectedEndDate={selectedEndDate}
                count={appointmentsCount}
                setPage={setPage}
                page={page}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Box>
          </div>
        </div>
        {isShowAppointmentDrawer && (
          <AppointmentsDrawer
            setSelectedAppointmentId={setSelectedAppointmentId}
            isOpen={isShowAppointmentDrawer}
            onClose={() => setIsShowAppointmentDrawer(false)}
            staffId={null}
            snackBarAlert={snackBarAlert}
            setAppointments={setAppointments}
            appointments={appointments}
            startTime=""
            date=""
            appointment={appointment}
          />
        )}

        {isShowAppointmentDetailsDrawer && (
          <AppointmentDetailsDrawer
            isOpen={isShowAppointmentDetailsDrawer}
            onClose={() => setIsShowAppointmentDetailsDrawer(false)}
            appointment={appointment}
            snackBarAlert={snackBarAlert}
            setAppointments={setAppointments}
            appointments={appointments}
            checkedItems={checkedItems}
          />
        )}

        <AppointmentFilterDrawer
          handleCloseFilter={handleCloseFilter}
          anchorEl={anchorEl}
          selectedDateFrom={selectedStartDate}
          selectedDateTo={selectedEndDate}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
          handleChangeCheckboxes={handleChangeCheckboxes}
          checkedItems={checkedItems}
          filterData={filterData}
          submitFilter={submitFilter}
          userRoles={userRoles}
          // getSelectedRoleTableHeader={getSelectedRoleTableHeader}
        />
        <CreateCustomer
          fromCalendar={fromCalendar}
          allCountries={countries}
          isCreateCustomerModalShow={isCreateCustomerModalShow}
          handleCloseCreateCustomerModal={handleCloseCreateCustomerModal}
          closeCreateOrUpdateAppointmentModal={closeCreateOrUpdateAppointmentModal}
        />

        <AppointmentActionModal
          type={actionType}
          open={isOpenActionModal}
          onClose={() => setIsOpenActionModal(false)}
          handleDelete={handleDeleteAppointment}
          handleCancel={handleCancelAppointmentSelect}
          appointmentCancelLoading={appointmentCancelLoading}
          appointmentDeleteLoading={appointmentDeleteLoading}
        />

        <SnackbarToast
          type={snackbarType}
          open={openSnackbar}
          message={snackbarMessage}
          onClose={() => setOpenSnackbar(false)}
        />
      </div>
    </>
  );
}

MyAppointments.propTypes = {
  history: PropTypes.object.isRequired,
};

export default MyAppointments;
