import { handleActions } from 'redux-actions';
import {
  updateWorkingHoursRequest,
  updateWorkingHoursSuccess,
  updateWorkingHoursError,
  updateBreakingHoursRequest,
  updateBreakingHoursSuccess,
  updateBreakingHoursError,
  getDefaultWorkingHoursRequest,
  getDefaultWorkingHoursSuccess,
  getDefaultWorkingHoursError,
  getDefaultBreakingHoursRequest,
  getDefaultBreakingHoursSuccess,
  getDefaultBreakingHoursError,
} from './actions';

const initialState = {
  weekDays: [],
  isGetWeekDaysSuccess: false,
  isGetWeekDaysError: false,
  isUpdateWorkingHoursSuccess: false,
  isUpdateWorkingHoursError: false,
  updateWorkingHoursErrorMessage: '',
  updatedWorkingHours: [],
  isUpdateBreakingHoursSuccess: false,
  isUpdateBreakingHoursError: false,
  updatedBreakingHours: [],
  isGetDefaultWorkingHoursSuccess: false,
  isGetDefaultWorkingHoursError: false,
  defaultWorkingHours: [],
  isGetDefaultBreakingHoursSuccess: false,
  isGetDefaultBreakingHoursError: false,
  defaultBreakingHours: [],
};

const reducer = handleActions(
  {
    [updateWorkingHoursRequest]: (state) => ({
      ...state,
      isUpdateWorkingHoursSuccess: false,
      isUpdateWorkingHoursError: false,
      updatedWorkingHours: [],
    }),
    [updateWorkingHoursSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateWorkingHoursSuccess: true,
      isUpdateWorkingHoursError: false,
      updatedWorkingHours: payload.data.working_hours,
      updatedBreakingHours: payload.data.breaking_hours,
    }),
    [updateWorkingHoursError]: (state, { payload }) => ({
      ...state,
      isUpdateWorkingHoursSuccess: false,
      isUpdateWorkingHoursError: true,
      updateWorkingHoursErrorMessage: payload.message,
      updatedWorkingHours: [],
    }),
    [updateBreakingHoursRequest]: (state) => ({
      ...state,
      isUpdateBreakingHoursSuccess: false,
      isUpdateBreakingHoursError: false,
      updatedBreakingHours: [],
    }),
    [updateBreakingHoursSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateBreakingHoursSuccess: true,
      isUpdateBreakingHoursError: false,
      updatedBreakingHours: payload.data,
    }),
    [updateBreakingHoursError]: (state) => ({
      ...state,
      isUpdateBreakingHoursSuccess: false,
      isUpdateBreakingHoursError: false,
      updatedBreakingHours: [],
    }),
    [getDefaultWorkingHoursRequest]: (state) => ({
      ...state,
      isGetDefaultWorkingHoursSuccess: false,
      isGetDefaultWorkingHoursError: false,
      defaultWorkingHours: [],
    }),
    [getDefaultWorkingHoursSuccess]: (state, { payload }) => ({
      ...state,
      isGetDefaultWorkingHoursSuccess: true,
      isGetDefaultWorkingHoursError: false,
      defaultWorkingHours: (payload),
    }),
    [getDefaultWorkingHoursError]: (state) => ({
      ...state,
      isGetDefaultWorkingHoursSuccess: true,
      isGetDefaultWorkingHoursError: false,
      defaultWorkingHours: [],
    }),
    [getDefaultBreakingHoursRequest]: (state) => ({
      ...state,
      isGetDefaultBreakingHoursSuccess: false,
      isGetDefaultBreakingHoursError: false,
      defaultBreakingHours: [],
    }),
    [getDefaultBreakingHoursSuccess]: (state, { payload }) => ({
      ...state,
      isGetDefaultBreakingHoursSuccess: true,
      isGetDefaultBreakingHoursError: false,
      defaultBreakingHours: (payload),
    }),
    [getDefaultBreakingHoursError]: (state) => ({
      ...state,
      isGetDefaultBreakingHoursSuccess: true,
      isGetDefaultBreakingHoursError: false,
      defaultBreakingHours: [],
    }),
  },
  initialState,
);

export default reducer;
