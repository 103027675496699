import React from 'react';
import 'react-placeholder/lib/reactPlaceholder.css';
import { Box, Grid } from '@material-ui/core';
import { RectShape } from 'react-placeholder/lib/placeholders';

function galleryLoading() {
  return (
    <Grid className="company-gallery">
      <Grid item sm={12}>
        <Box className="add-gallery-content" id="dropzone">
          <section className="gallery">
            <div className="aside">
              <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 160, height: 160, margin: 10 }} />
              <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 160, height: 160, margin: 10 }} />
              <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 160, height: 160, margin: 10 }} />
            </div>
          </section>
        </Box>
      </Grid>
    </Grid>
  );
}

export default galleryLoading;
