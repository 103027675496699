import { createAction } from 'redux-actions';

export const getIndividualUserIndustriesRequest = createAction('GET_INDIVIDUAL_USER_INDUSTRIES_REQUEST');
export const getIndividualUserIndustriesSuccess = createAction('GET_INDIVIDUAL_USER_INDUSTRIES_SUCCESS');
export const getIndividualUserIndustriesError = createAction('GET_INDIVIDUAL_USER_INDUSTRIES_ERROR');

export const getIndividualUserIndustryByIdRequest = createAction('GET_INDIVIDUAL_USER_INDUSTRY_BY_ID_REQUEST');
export const getIndividualUserIndustryByIdSuccess = createAction('GET_INDIVIDUAL_USER_INDUSTRY_BY_ID_SUCCESS');
export const getIndividualUserIndustryByIdError = createAction('GET_INDIVIDUAL_USER_INDUSTRY_BY_ID_ERROR');

export const storeIndividualUserIndustryRequest = createAction('STORE_INDIVIDUAL_USER_INDUSTRY_REQUEST');
export const storeIndividualUserIndustrySuccess = createAction('STORE_INDIVIDUAL_USER_INDUSTRY_SUCCESS');
export const storeIndividualUserIndustryError = createAction('STORE_INDIVIDUAL_USER_INDUSTRY_ERROR');

export const updateIndividualUserIndustryRequest = createAction('UPDATE_INDIVIDUAL_USER_INDUSTRY_REQUEST');
export const updateIndividualUserIndustrySuccess = createAction('UPDATE_INDIVIDUAL_USER_INDUSTRY_SUCCESS');
export const updateIndividualUserIndustryError = createAction('UPDATE_INDIVIDUAL_USER_INDUSTRY_ERROR');

export const deleteIndividualUserIndustryRequest = createAction('DELETE_INDIVIDUAL_USER_INDUSTRY_REQUEST');
export const deleteIndividualUserIndustrySuccess = createAction('DELETE_INDIVIDUAL_USER_INDUSTRY_SUCCESS');
export const deleteIndividualUserIndustryError = createAction('DELETE_INDIVIDUAL_USER_INDUSTRY_ERROR');

export const getFilteredIndividualUserIndustriesRequest = createAction('GET_FILTERED_INDIVIDUAL_USER_INDUSTRIES_REQUEST');
export const getFilteredIndividualUserIndustriesSuccess = createAction('GET_FILTERED_INDIVIDUAL_USER_INDUSTRIES_SUCCESS');
export const getFilteredIndividualUserIndustriesError = createAction('GET_FILTERED_INDIVIDUAL_USER_INDUSTRIES_ERROR');

export const getIndividualUserAvailableTimesRequest = createAction('GET_INDIVIDUAL_USER_AVAILABLE_TIMES_REQUEST');
export const getIndividualUserAvailableTimesSuccess = createAction('GET_INDIVIDUAL_USER_AVAILABLE_TIMES_SUCCESS');
export const getIndividualUserAvailableTimesError = createAction('GET_INDIVIDUAL_USER_AVAILABLE_TIMES_ERROR');

export const inviteCustomerToIndustryRequest = createAction('INVITE_CUSTOMER_TO_INDUSTRY_REQUEST');
export const inviteCustomerToIndustrySuccess = createAction('INVITE_CUSTOMER_TO_INDUSTRY_SUCCESS');
export const inviteCustomerToIndustryError = createAction('INVITE_CUSTOMER_TO_INDUSTRY_ERROR');

export const getPersonalIndividualUserIndustriesRequest = createAction('GET_PERSONAL_INDIVIDUAL_USER_INDUSTRIES_REQUEST');
export const getPersonalIndividualUserIndustriesSuccess = createAction('GET_PERSONAL_INDIVIDUAL_USER_INDUSTRIES_SUCCESS');
export const getPersonalIndividualUserIndustriesError = createAction('GET_PERSONAL_INDIVIDUAL_USER_INDUSTRIES_ERROR');

export const getUserIndividualIndustriesRequest = createAction('GET_USER_INDIVIDUAL_INDUSTRIES_REQUEST');
export const getUserIndividualIndustriesSuccess = createAction('GET_USER_INDIVIDUAL_INDUSTRIES_SUCCESS');
export const getUserIndividualIndustriesError = createAction('GET_USER_INDIVIDUAL_INDUSTRIES_ERROR');

export const storeIndividualGalleryCompanyRequest = createAction('STORE_INDIVIDUAL_GALLERY_COMPANY_REQUEST');
export const storeIndividualGalleryCompanySuccess = createAction('STORE_INDIVIDUAL_GALLERY_COMPANY_SUCCESS');
export const storeIndividualGalleryCompanyError = createAction('STORE_INDIVIDUAL_GALLERY_COMPANY_ERROR');

export const deleteIndividualGalleryCompanyRequest = createAction('DELETE_INDIVIDUAL_GALLERY_COMPANY_REQUEST');
export const deleteIndividualGalleryCompanySuccess = createAction('DELETE_INDIVIDUAL_GALLERY_COMPANY_SUCCESS');
export const deleteIndividualGalleryCompanyError = createAction('DELETE_INDIVIDUAL_GALLERY_COMPANY_ERROR');

export const individualCloseDayRequest = createAction('INDIVIDUAL_CLOSE_DAY_REQUEST');
export const individualCloseDaySuccess = createAction('INDIVIDUAL_CLOSE_DAY_SUCCESS');
export const individualCloseDayError = createAction('INDIVIDUAL_CLOSE_DAY_ERROR');

export const updateIndividualCloseDayRequest = createAction('UPDATE_INDIVIDUAL_CLOSE_DAY_REQUEST');
export const updateIndividualCloseDaySuccess = createAction('UPDATE_INDIVIDUAL_CLOSE_DAY_SUCCESS');
export const updateIndividualCloseDayError = createAction('UPDATE_INDIVIDUAL_CLOSE_DAY_ERROR');

export const deleteIndividualCloseDayRequest = createAction('DELETE_INDIVIDUAL_CLOSE_DAY_REQUEST');
export const deleteIndividualCloseDaySuccess = createAction('DELETE_INDIVIDUAL_CLOSE_DAY_SUCCESS');
export const deleteIndividualCloseDayError = createAction('DELETE_INDIVIDUAL_CLOSE_DAY_ERROR');

export const specialIndividualDayRequest = createAction('SPECIAL_INDIVIDUAL_DAY_REQUEST');
export const specialIndividualDaySuccess = createAction('SPECIAL_INDIVIDUAL_DAY_SUCCESS');
export const specialIndividualDayError = createAction('SPECIAL_INDIVIDUAL_DAY_ERROR');

export const updateIndividualSpecialDayRequest = createAction('UPDATE_INDIVIDUAL_SPECIAL_DAY_REQUEST');
export const updateIndividualSpecialDaySuccess = createAction('UPDATE_INDIVIDUAL_SPECIAL_DAY_SUCCESS');
export const updateIndividualSpecialDayError = createAction('UPDATE_INDIVIDUAL_SPECIAL_DAY_ERROR');

export const deleteIndividualSpecialDayRequest = createAction('DELETE_INDIVIDUAL_SPECIAL_DAY_REQUEST');
export const deleteIndividualSpecialDaySuccess = createAction('DELETE_INDIVIDUAL_SPECIAL_DAY_SUCCESS');
export const deleteIndividualSpecialDayError = createAction('DELETE_INDIVIDUAL_SPECIAL_DAY_ERROR');

export const storeCustomerToIndustryRequest = createAction('STORE_CUSTOMER_TO_INDUSTRY_REQUEST');
export const storeCustomerToIndustrySuccess = createAction('STORE_CUSTOMER_TO_INDUSTRY_SUCCESS');
export const storeCustomerToIndustryError = createAction('STORE_CUSTOMER_TO_INDUSTRY_ERROR');

export const deleteCustomerFromIndustryRequest = createAction('DELETE_CUSTOMER_FROM_INDUSTRY_REQUEST');
export const deleteCustomerFromIndustrySuccess = createAction('DELETE_CUSTOMER_FROM_INDUSTRY_SUCCESS');
export const deleteCustomerFromIndustryError = createAction('DELETE_CUSTOMER_FROM_INDUSTRY_ERROR');

export const resendCustomerEmailRequest = createAction('RESEND_CUSTOMER_EMAIL_REQUEST');
export const resendCustomerEmailSuccess = createAction('RESEND_CUSTOMER_EMAIL_SUCCESS');
export const resendCustomerEmailError = createAction('RESEND_CUSTOMER_EMAIL_ERROR');

export const updateIndividualCustomerRequest = createAction('UPDATE_INDIVIDUAL_CUSTOMER_REQUEST');
export const updateIndividualCustomerSuccess = createAction('UPDATE_INDIVIDUAL_CUSTOMER_SUCCESS');
export const updateIndividualCustomerError = createAction('UPDATE_INDIVIDUAL_CUSTOMER_ERROR');

export const filterIndividualCustomerByStatusRequest = createAction('FILTER_INDIVIDUAL_CUSTOMER_BY_STATUS_REQUEST');
export const filterIndividualCustomerByStatusSuccess = createAction('FILTER_INDIVIDUAL_CUSTOMER_BY_STATUS_SUCCESS');
export const filterIndividualCustomerByStatusError = createAction('FILTER_INDIVIDUAL_CUSTOMER_BY_STATUS_ERROR');

export const getIndividualUserSettingsRequest = createAction('GET_INDIVIDUAL_USER_SETTINGS_REQUEST');
export const getIndividualUserSettingsSuccess = createAction('GET_INDIVIDUAL_USER_SETTINGS_SUCCESS');
export const getIndividualUserSettingsError = createAction('GET_INDIVIDUAL_USER_SETTINGS_ERROR');

export const updateIndividualUserSettingsRequest = createAction('UPDATE_INDIVIDUAL_USER_SETTINGS_REQUEST');
export const updateIndividualUserSettingsSuccess = createAction('UPDATE_INDIVIDUAL_USER_SETTINGS_SUCCESS');
export const updateIndividualUserSettingsError = createAction('UPDATE_INDIVIDUAL_USER_SETTINGS_ERROR');

export const getIndividualAvailableHoursRequest = createAction('GET_INDIVIDUAL_AVAILABLE_HOURS_REQUEST');
export const getIndividualAvailableHoursSuccess = createAction('GET_INDIVIDUAL_AVAILABLE_HOURS_SUCCESS');
export const getIndividualAvailableHoursError = createAction('GET_INDIVIDUAL_AVAILABLE_HOURS_ERROR');

export const getIndustryAvailableDatesRequest = createAction('GET_INDUSTRY_AVAILABLE_DATES_REQUEST');
export const getIndustryAvailableDatesSuccess = createAction('GET_INDUSTRY_AVAILABLE_DATES_SUCCESS');
export const getIndustryAvailableDatesError = createAction('GET_INDUSTRY_AVAILABLE_DATES_ERROR');
