import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { getUserLocationRequest } from '../../redux/account/actions';
import {
  InputLabel,
  OutlinedInput,
  CircularProgress,
  AppBar,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  FormControl,
  IconButton,
  Button,
  Typography,
  Box,
  Grid,
  Toolbar,
  FormHelperText,
  Container,
} from '@material-ui/core';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import ErrorIcon from '@material-ui/icons/Error';
import Logo from 'assets/img/logo.svg';
import {
  checkTokenRequest,
  registerRequest,
} from '../../../redux/auth/actions';
import './style.scss';

class Registration extends Component {
  static propTypes = {
    // getUserLocation: PropTypes.func.isRequired,
    // isGetUserLocationSuccess: PropTypes.bool.isRequired,
    // isGetUserLocationError: PropTypes.bool.isRequired,
    // locationInfo: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    checkToken: PropTypes.func.isRequired,
    isCheckTokenError: PropTypes.bool.isRequired,
    registerUser: PropTypes.func.isRequired,
    isRegisterSuccess: PropTypes.bool.isRequired,
    isRegisterError: PropTypes.bool.isRequired,
    registerErrorMessage: PropTypes.string.isRequired,
    userAccount: PropTypes.object.isRequired,
    registerErrors: PropTypes.object.isRequired,
    handleLogin: PropTypes.func.isRequired,
    handleCompanyOnBoarded: PropTypes.func.isRequired,
    handleEmailVerified: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      email: '',
      password: '',
      confirmPassword: '',
      submitDone: false,
      formErrors: {
        email: false,
        password: false,
      },
      registrationErrors: {},
      buttonDisabled: false,
      showPassword: false,
      showConfirmPassword: false,
      isAcceptedTerms: false,
      validPassword: false,
      showValidationText: false,
    };
  }

  componentDidMount() {
    const { checkToken, match } = this.props;
    const { token } = match.params;
    if (token) {
      checkToken({ token });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      history,
      isRegisterSuccess,
      isRegisterError,
      isCheckTokenError,
      userAccount,
      registerErrors,
      handleLogin,
      handleCompanyOnBoarded,
      handleEmailVerified,
      // isGetUserLocationSuccess,
      // isGetUserLocationError,
      // locationInfo,
      registerErrorMessage,
    } = this.props;
    // if (!prevProps.isGetUserLocationSuccess && isGetUserLocationSuccess && !isGetUserLocationError && locationInfo) {
    //   const { codeOptions } = this.state;
    //   const { countryCode } = locationInfo;
    //   const selectedCodeOption = codeOptions.find((item) => item.key === countryCode);
    //   this.setState({ selectedCodeOption });
    // }
    if (!prevProps.isRegisterSuccess && prevProps.isRegisterSuccess !== isRegisterSuccess && userAccount) {
      handleLogin(userAccount.token);
      handleEmailVerified(userAccount.email_verified_at);
      handleCompanyOnBoarded(userAccount.company_is_on_boarded_at);
    }
    if (!prevProps.isRegisterError && isRegisterError) {
      if (registerErrors) {
        const errors = {};
        const keys = Object.keys(registerErrors);
        keys.map((key) => {
          // eslint-disable-next-line prefer-destructuring
          errors[key] = registerErrors[key][0];
          return true;
        });
        this.setState({
          registrationErrors: errors,
          buttonDisabled: true,
          loading: false,
        });
      }
      if (registerErrorMessage === 'existing_user') {
        const { email } = this.state;
        localStorage.setItem('existingEmail', email);
      }
    }

    if (!prevProps.isCheckTokenError && isCheckTokenError) {
      history.push('/login');
    }
  }

  validateForm = (key, all = false) => {
    const {
      password,
      confirmPassword,
    } = this.state;
    if (all) {
      return (
        !(password.length >= 8)
        || !(password.match(/(?=.*[A-Z])/))
        || !(confirmPassword.length > 0)
        || !(confirmPassword === password));
    } else {
      const { formErrors } = this.state;
      switch (key) {
        case 'password':
          formErrors.password = !(password.length > 0);
          formErrors.passwordLength = !(password.length >= 8);
          formErrors.passwordFormat = !(password.match(/(?=.*[A-Z])/));
          break;
        case 'confirmPassword':
          formErrors.confirmPasswordLength = !(confirmPassword.length > 0);
          formErrors.confirmPassword = !(confirmPassword === password);
          break;
        default:
          break;
      }
      this.setState({
        formErrors,
      });
      return true;
    }
  };

  handleClickShowPassword = (type) => {
    const { showPassword, showConfirmPassword } = this.state;
    if (type === 'password') {
      this.setState({
        showPassword: !showPassword,
      });
    } else {
      this.setState({
        showConfirmPassword: !showConfirmPassword,
      });
    }
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  handleCheckValue = (event) => {
    const { checked } = event.target;
    this.setState({ isAcceptedTerms: checked });
  };

  handleChange = async (event) => {
    const { name, value } = event.target;
    const { state } = this;
    await this.setState(
      { [name]: value },
    );
    this.setState({
      buttonDisabled: false,
    });

    if (state.submitDone) {
      this.validateForm(name);
    }
    this.validationPassword(value);
  };

  validationPassword = (value) => {
    const password = document.querySelector('.password');
    const helperText = {
      charLength: document.querySelector('.helper-text .length'),
      lowercase: document.querySelector('.helper-text .lowercase'),
      uppercase: document.querySelector('.helper-text .uppercase'),
      special: document.querySelector('.helper-text .special'),
    };
    const pattern = {
      charLength() {
        if (value.length >= 8) {
          return true;
        }
      },
      lowercase() {
        const regex = /^(?=.*[a-z]).+$/; // Lowercase character pattern

        if (regex.test(value)) {
          return true;
        }
      },
      uppercase() {
        const regex = /^(?=.*[A-Z]).+$/; // Uppercase character pattern

        if (regex.test(value)) {
          return true;
        }
      },
      special() {
        const regex = /^(?=.*[0-9_\W]).+$/; // Special character or number pattern

        if (regex.test(value)) {
          return true;
        }
      },
    };
    // Listen for keyup action on password field
    password.addEventListener('keyup', () => {
      // Check that password is a minimum of 8 characters
      this.patternTest(pattern.charLength(), helperText.charLength);

      // Check that password contains a lowercase letter
      this.patternTest(pattern.lowercase(), helperText.lowercase);

      // Check that password contains an uppercase letter
      this.patternTest(pattern.uppercase(), helperText.uppercase);

      // Check that password contains a number or special character
      this.patternTest(pattern.special(), helperText.special);

      // Check that all requirements are fulfilled
      if (this.hasClass(helperText.charLength, 'valid')
        && this.hasClass(helperText.lowercase, 'valid')
        && this.hasClass(helperText.uppercase, 'valid')
        && this.hasClass(helperText.special, 'valid')
      ) {
        this.addClass(password.parentElement, 'valid');
        this.setState({
          validPassword: true,
        });
      } else {
        this.removeClass(password.parentElement, 'valid');
        this.setState({
          validPassword: false,
        });
      }
    });
  }

  addClass = (el, className) => {
    if (el.classList) {
      el.classList.add(className);
    } else {
      el.className += ` ${className}`;
    }
  }

  removeClass = (el, className) => {
    if (el.classList) el.classList.remove(className);
    else el.className = el.className.replace(new RegExp(`(^|\\b)${className.split(' ').join('|')}(\\b|$)`, 'gi'), ' ');
  }

  patternTest = (pattern, response) => {
    if (pattern) {
      this.addClass(response, 'valid');
    } else {
      this.removeClass(response, 'valid');
    }
  }

  hasClass = (el, className) => {
    if (el.classList) {
      return el.classList.contains(className);
    } else {
      new RegExp(`(^| )${className}( |$)`, 'gi').test(el.className);
    }
  }

  async handleSubmit(e) {
    e.preventDefault();
    const {
      password,
      confirmPassword,
      validPassword,
    } = this.state;
    this.setState(
      { submitDone: true },
    );
    await this.setState({
      registrationErrors: {},
    });
    if (this.validateForm(null, true)) {
      this.validateForm('confirmPassword');
      return false;
    } else if (validPassword && password === confirmPassword) {
      const { match, registerUser } = this.props;
      const { token } = match.params;
      const {
        password,
        confirmPassword,
      } = this.state;
      this.setState({
        formErrors: {},
      });
      const data = {};
      data.token = token;
      data.password = password;
      data.confirmPassword = confirmPassword;
      if (token) {
        this.setState(
          { loading: true },
        );
        registerUser(data);
      }
    }
  }

  render() {
    const {
      loading,
      password,
      formErrors,
      showPassword,
      buttonDisabled,
      isAcceptedTerms,
      confirmPassword,
      registrationErrors,
      showValidationText,
      showConfirmPassword,
    } = this.state;
    return (
      <div className="auth auth-bg">
        <Container>
          <AppBar color="transparent" position="static" elevation={0} className="login-header">
            <Toolbar>
              <Box mx="auto" className="auth-logo">
                <Link to="/login">
                  <img src={Logo} alt="Logo" />
                </Link>
              </Box>
            </Toolbar>
          </AppBar>
          <Grid container justify="center">
            <Grid direction="row" justify="center" xs={12} sm={7} md={4}>
              <div className="auth-box">
                <Box px="40px" py="30px">
                  {/* <Box mb="30px"> */}
                  {/*  <Typography align="center" variant="h4">Sign Up</Typography> */}
                  {/* </Box> */}
                  <form onSubmit={(e) => this.handleSubmit(e, password)}>
                    <div>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!formErrors.password || !!formErrors.passwordLength || !!formErrors.passwordFormat || !!registrationErrors.password}
                          >
                            <InputLabel
                              htmlFor="outlined-adornment-password"
                              error={!!formErrors.password || !!formErrors.passwordLength || !!formErrors.passwordFormat || !!registrationErrors.password}
                            >
                              Password
                            </InputLabel>
                            <OutlinedInput
                              error={!!formErrors.password || !!formErrors.passwordLength || !!formErrors.passwordFormat || !!registrationErrors.password}
                              type={showPassword ? 'text' : 'password'}
                              id="password-icon"
                              name="password"
                              label="Password"
                              className="password"
                              value={password}
                              onChange={this.handleChange}
                              onClick={() => this.setState({ showValidationText: true })}
                              endAdornment={(
                                <InputAdornment position="end">
                                  <IconButton
                                    size="small"
                                    aria-label="toggle password visibility"
                                    onClick={() => this.handleClickShowPassword('password')}
                                    onMouseDown={this.handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <Visibility color="primary" fontSize="small" /> : <VisibilityOff color="secondary" fontSize="small" />}
                                  </IconButton>
                                </InputAdornment>
                              )}
                            />

                            { registrationErrors.password && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  { registrationErrors.password }
                                </FormHelperText>
                              </div>
                            )}

                            { formErrors.password && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  Password is required.
                                </FormHelperText>
                              </div>
                            )}

                            { formErrors.passwordLength && !formErrors.password && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  Password must contain at least 8 characters
                                </FormHelperText>
                              </div>
                            )}

                            { formErrors.passwordFormat && !formErrors.password && !formErrors.passwordLength && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  Password must be contain least 1 capital letter
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>

                          <ul className={`helper-text ${showValidationText && 'show'}`}>
                            <li className="length">At least 8 characters</li>
                            <li className="lowercase">Contains a lowercase letter.</li>
                            <li className="uppercase">Contains an uppercase letter.</li>
                            <li className="special">Contains a number or special character.</li>
                          </ul>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl
                            fullWidth
                            variant="outlined"
                            margin="dense"
                            error={!!formErrors.confirmPassword || !!formErrors.confirmPasswordLength}
                          >
                            <InputLabel htmlFor="outlined-adornment-password" error={!!formErrors.confirmPassword || !!formErrors.confirmPasswordLength}>Confirm password</InputLabel>
                            <OutlinedInput
                              error={!!formErrors.confirmPassword || !!formErrors.confirmPasswordLength}
                              type={showConfirmPassword ? 'text' : 'password'}
                              id="icon-password"
                              name="confirmPassword"
                              label="Confirm password"
                              value={confirmPassword}
                              onChange={this.handleChange}
                              endAdornment={(
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => this.handleClickShowPassword('confirm')}
                                    onMouseDown={this.handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showConfirmPassword ? <Visibility color="primary" fontSize="small" /> : <VisibilityOff color="secondary" fontSize="small" />}
                                  </IconButton>
                                </InputAdornment>
                              )}
                            />

                            { formErrors.confirmPasswordLength && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  Confirm password is required.
                                </FormHelperText>
                              </div>
                            )}

                            { !formErrors.confirmPasswordLength && formErrors.confirmPassword && (
                              <div className="error-message-content">
                                <ErrorIcon fontSize="small" color="error" />
                                <FormHelperText>
                                  Password does not match
                                </FormHelperText>
                              </div>
                            )}
                          </FormControl>
                        </Grid>
                      </Grid>
                    </div>
                    <div />
                    <Box display="flex" mx="0">
                      <Box px="0" display="flex">
                        <Box display="flex">
                          <FormControlLabel
                            className="checkbox-accept"
                            control={(
                              <Checkbox
                                size="small"
                                checked={isAcceptedTerms}
                                onChange={this.handleCheckValue}
                                name="isAcceptedTerms"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                color="primary"
                              />
                            )}
                            label={<Typography>I accept <Link to="/privacy" className="privacy-txt">the services agreement and privacy & cookies statement.</Link></Typography>}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box my="30px" align="center">
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        data-cy="submit"
                        disabled={this.loading || buttonDisabled || !isAcceptedTerms}
                        type="submit"
                        onClick={this.onClick}
                        loading={loading}
                      >
                        {loading && <CircularProgress color="white" size={20} />}
                        {!loading && 'Sign Up'}
                      </Button>
                    </Box>
                  </form>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <Box mr="5px">
                      <Typography align="center" color="secondary">
                        Already have an account ?
                      </Typography>
                    </Box>
                    <Box>
                      <Link to="/login"> <Typography color="primary"> Sign in </Typography> </Link>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // isGetUserLocationSuccess: state.account.isGetUserLocationSuccess,
  // isGetUserLocationError: state.account.isGetUserLocationError,
  // locationInfo: state.account.locationInfo,
  isCheckTokenError: state.auth.isCheckTokenError,
  isRegisterSuccess: state.auth.isRegisterSuccess,
  isRegisterError: state.auth.isRegisterError,
  registerErrorMessage: state.auth.registerErrorMessage,
  userAccount: state.auth.userAccount,
  registerErrors: state.auth.registerErrors,
});

function mapDispatchToProps(dispatch) {
  return {
    registerUser: (data) => dispatch(registerRequest(data)),
    checkToken: (data) => dispatch(checkTokenRequest(data)),
    // getUserLocation: (data) => dispatch(getUserLocationRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
