import React from 'react';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  IconButton,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const InviteAddedStaff = (props) => {
  const {
    open,
    onClose,
    loading,
    staffItem,
    inviteStaffErrors,
    inviteYourselfError,
    handleStaffInputChange,
    invitationEmailStaffText,
    handleInviteCurrentStaff,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>
            Invite to Register
          </span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={12}>
            <span className="invite-staff-text">
              Invite
              <span className="invite-staff-text-name"> {staffItem.first_name} </span>
              <span className="invite-staff-text-name"> {staffItem.last_name} </span>
              to register on SetTime.
            </span>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={(!!inviteStaffErrors.email || invitationEmailStaffText !== '' || !!inviteYourselfError)}
            >
              <TextField
                fullWidth
                label="Email address *"
                error={(!!inviteStaffErrors.email || invitationEmailStaffText !== '' || !!inviteYourselfError)}
                size="small"
                variant="outlined"
                name="email"
                value={staffItem.email}
                inputProps={{
                  autoComplete: 'new-password',
                }}
                onChange={handleStaffInputChange}
              />

              {inviteStaffErrors.email && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {inviteStaffErrors.email}
                  </FormHelperText>
                </div>
              )}

              {invitationEmailStaffText !== '' && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {invitationEmailStaffText}
                  </FormHelperText>
                </div>
              )}

              {inviteYourselfError && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    You can&apos;t invite yourself as staff
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={((e) => handleInviteCurrentStaff(e, staffItem.id))}
          loading={loading}
        >
          {loading && <CircularProgress color="white" size={20} />}
          {!loading && 'Invite'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

InviteAddedStaff.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  staffItem: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  inviteStaffErrors: PropTypes.object.isRequired,
  inviteYourselfError: PropTypes.bool.isRequired,
  invitationEmailStaffText: PropTypes.string.isRequired,
  handleStaffInputChange: PropTypes.func.isRequired,
  handleInviteCurrentStaff: PropTypes.func.isRequired,
};

export default InviteAddedStaff;
