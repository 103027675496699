import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Radio,
  AppBar,
  Switch,
  Select,
  TableCell,
  RadioGroup,
  FormControlLabel,
  Box,
  Grid,
  Table,
  Toolbar,
  Divider,
  MenuItem,
  TableRow,
  TableBody,
  TableHead,
  Typography,
  makeStyles,
  FormControl,
  TableContainer,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  getUserSettingsRequest,
  updateUserSettingsRequest,
} from '../../../../../redux/account/actions';
import {
  thanks,
  reminder,
  slotDurations,
  schedulingOrder,
  notificationsType,
} from '../../../../../Modules/constants';
import usePrevious from '../../../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../../../Modules/SnackbarToast';
import SettingsLoading from '../../../../../Components/Loading/settingsLoading';
import {
  updateAppointmentsReminderRequest,
  updateAppointmentsThankYouMessageRequest,
} from '../../../../../redux/appointment/actions';

const useStyles = makeStyles({
  table: {
    minWidth: 320,
  },
  titleCalendar: {
    fontWeight: 500,
    marginBottom: 12,
  },
  titleAppointment: {
    fontWeight: 500,
  },
  settingsTable: {
    boxShadow: '0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0px rgba(0, 0, 0, 0.14), 0 1px 8px 0px rgba(0, 0, 0, 0.12)',
    marginBottom: 20,
    padding: '0 16px',
  },
  notificationTableTh: {
    fontSize: 14,
  },
  rowRevers: {
    marginLeft: 'auto',
  },
});

function Settings() {
  const dispatch = useDispatch();
  // Translation
  const { t } = useTranslation();
  const {
    // Get User Settings
    isGetUserSettingsSuccess,
    isGetUserSettingsError,
    getUserSettingsErrorMessage,
    userSettings,
    // Update User Settings
    isUpdateUserSettingsSuccess,
    isUpdateUserSettingsError,
    updateUserSettingsErrorMessage,
  } = useSelector((state) => state.account);

  const {
    // Update Appointments Reminder
    isUpdateAppointmentsReminderSuccess,
    isUpdateAppointmentsReminderError,
    updateAppointmentsReminderErrorMessage,
    // Update Appointments Thank You Message
    isUpdateAppointmentThankYouMessageSuccess,
    isUpdateAppointmentThankYouMessageError,
    updateAppointmentThankYouMessageErrorMessage,
  } = useSelector((state) => state.appointment);

  // Get some props previous values
  const prevIsGetUserSettingsError = usePrevious(isGetUserSettingsError);
  const prevIsGetUserSettingsSuccess = usePrevious(isGetUserSettingsSuccess);
  const prevIsUpdateUserSettingsError = usePrevious(isUpdateUserSettingsError);
  const prevIsUpdateUserSettingsSuccess = usePrevious(isUpdateUserSettingsSuccess);
  const prevIsUpdateAppointmentsReminderSuccess = usePrevious(isUpdateAppointmentsReminderSuccess);
  const prevIsUpdateAppointmentsReminderError = usePrevious(isUpdateAppointmentsReminderError);
  const prevIsUpdateAppointmentThankYouMessageSuccess = usePrevious(isUpdateAppointmentThankYouMessageSuccess);
  const prevIsUpdateAppointmentThankYouMessageError = usePrevious(isUpdateAppointmentThankYouMessageError);

  const [settings, setSettings] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [changedReminder, setChangeReminder] = useState(false);
  const [changedThankYou, setChangedThankYou] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (Object.keys(userSettings).length !== 0 && userSettings) {
      const userSettingsCopy = { ...userSettings };
      setSettings(userSettingsCopy);
      setLoading(false);
    } else {
      dispatch(getUserSettingsRequest());
      setLoading(true);
    }
  }, []);

  // Handle Get User Settings Success
  useEffect(() => {
    if (prevIsGetUserSettingsSuccess === false && isGetUserSettingsSuccess) {
      const userSettingsCopy = { ...userSettings };
      setSettings(userSettingsCopy);
      setLoading(false);
    }
  }, [isGetUserSettingsSuccess]);

  // Handle Get User Settings Error
  useEffect(() => {
    if (prevIsGetUserSettingsError === false && isGetUserSettingsError) {
      snackBarAlert(true, getUserSettingsErrorMessage, 'error');
    }
  }, [isGetUserSettingsError]);

  // Handle Update User Settings Success
  useEffect(() => {
    if (prevIsUpdateUserSettingsSuccess === false && isUpdateUserSettingsSuccess) {
      if (changedReminder) {
        dispatch(updateAppointmentsReminderRequest());
      } else if (changedThankYou) {
        dispatch(updateAppointmentsThankYouMessageRequest());
      } else {
        snackBarAlert(true, i18n.t('UpdatedSuccess'), 'success');
      }
    }
  }, [isUpdateUserSettingsSuccess]);

  // Handle Update User Settings Error
  useEffect(() => {
    if (prevIsUpdateUserSettingsError === false && isUpdateUserSettingsError) {
      snackBarAlert(true, updateUserSettingsErrorMessage, 'error');
    }
  }, [isUpdateUserSettingsError]);

  // Handle Update Appointments Reminder Success
  useEffect(() => {
    if (prevIsUpdateAppointmentsReminderSuccess === false && isUpdateAppointmentsReminderSuccess) {
      setChangeReminder(false);
      snackBarAlert(true, 'Reminders Updated for all appointments', 'success');
    }
  }, [isUpdateAppointmentsReminderSuccess]);

  // Handle Update Appointments Reminder Error
  useEffect(() => {
    if (prevIsUpdateAppointmentsReminderError === false && isUpdateAppointmentsReminderError) {
      snackBarAlert(true, updateAppointmentsReminderErrorMessage, 'error');
    }
  }, [isUpdateAppointmentsReminderError]);

  // Handle Update Appointments Thank You Message Success
  useEffect(() => {
    if (prevIsUpdateAppointmentThankYouMessageSuccess === false && isUpdateAppointmentThankYouMessageSuccess) {
      snackBarAlert(true, 'Thank You Messages updated for all appointments', 'success');
    }
  }, [isUpdateAppointmentThankYouMessageSuccess]);

  // Handle Update Appointments Thank You Message Error
  useEffect(() => {
    if (prevIsUpdateAppointmentThankYouMessageError === false && isUpdateAppointmentThankYouMessageError) {
      snackBarAlert(true, updateAppointmentThankYouMessageErrorMessage, 'error');
    }
  }, [isUpdateAppointmentThankYouMessageError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleChangeSettings = (event, notificationType) => {
    const { name, checked } = event.target;
    const settingsCopy = { ...settings };
    if (checked) {
      settingsCopy.notifications[notificationType].push(name);
    } else {
      const notificationIndex = settingsCopy.notifications[notificationType].findIndex((item) => item === name);
      settingsCopy.notifications[notificationType].splice(notificationIndex, 1);
    }

    setSettings(settingsCopy);
    update(settingsCopy);
  };

  const handleChange = (e, value, type) => {
    const { name } = e.target;
    if (type === 'reminder') {
      setChangeReminder(true);
      setChangedThankYou(false);
    } else if (type === 'follow_up') {
      setChangedThankYou(true);
      setChangeReminder(false);
    }
    const settingsCopy = { ...settings };
    settingsCopy[type][name] = value;
    setSettings(settingsCopy);
    update(settingsCopy);
  };

  const update = (settings) => {
    dispatch(updateUserSettingsRequest({ settings }));
  };

  return (
    <>
      <div className="main-content">
        <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
              <Box display="flex" className="tabs-navbar">
                <Box alignSelf="center" className="divider-title">
                  <Typography variant="h6" noWrap>
                    <Box display="flex" alignItems="center" height="100%">
                      {t('Settings')}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Box p="32px">
          {!loading ? (
            <Grid container spacing={4} className="settings-box">
              {Object.keys(settings).length && (
                <>
                  <Grid item xs={12} md={6}>
                    <Box className="settings-calendarBox">
                      <Box mb="16px">
                        <Typography variant="subtitle1" className={classes.titleCalendar}>
                          {t('Calendar')}
                        </Typography>
                        <Divider />
                      </Box>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>
                            {t('DefaultView')}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <RadioGroup row defaultValue="timeGridDay" name="default_view" value={settings.calendar.default_view} onChange={(e) => handleChange(e, e.target.value, 'calendar')}>
                            <FormControlLabel value="timeGridDay" control={<Radio size="small" color="primary" />} label={t('Daily')} />
                            <FormControlLabel value="timeGridWeek" control={<Radio size="small" color="primary" />} label={t('Weekly')} />
                            <FormControlLabel value="dayGridMonth" control={<Radio size="small" color="primary" />} label={t('Monthly')} />
                          </RadioGroup>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>
                            {t('SlotDuration')}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl>
                            <Select
                              value={settings.calendar.slot}
                              onChange={(e) => handleChange(e, e.target.value, 'calendar')}
                              name="slot"
                            >
                              {slotDurations.map((item) => (
                                <MenuItem value={item}>{item} minutes</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="settings-appointmentBox">
                      <Box pb="12px">
                        <Typography variant="subtitle1" className={classes.titleAppointment}>
                          {t('Appointments')}
                        </Typography>
                      </Box>
                      <Divider />
                      <Grid container justify="space-between" className="settings-appointments">
                        <Grid item>
                          <Typography>
                            {t('AutoAccept')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Switch
                            size="small"
                            checked={settings.appointment.auto_accept}
                            onChange={(e) => handleChange(e, e.target.checked, 'appointment')}
                            color="primary"
                            name="auto_accept"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justify="space-between" wrap="wrap-reverse" className="settings-appointments">
                        <Grid item>
                          <Typography>
                            {t('AutoFinish')}
                          </Typography>
                        </Grid>
                        <Grid item className={classes.rowRevers}>
                          <Switch
                            size="small"
                            checked={settings.appointment.auto_finish}
                            onChange={(e) => handleChange(e, e.target.checked, 'appointment')}
                            color="primary"
                            name="auto_finish"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justify="space-between" className="settings-appointments">
                        <Grid item>
                          <Typography>
                            {t('PastDate')}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Switch
                            size="small"
                            checked={settings.appointment.past_appointments}
                            onChange={(e) => handleChange(e, e.target.checked, 'appointment')}
                            color="primary"
                            name="past_appointments"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </Grid>
                      </Grid>
                      <Divider />
                      <Grid container justify="space-between" className="settings-appointments">
                        <Grid item>
                          <Box display="flex" alignItems="center">
                            <Box mr="16px">
                              <span>
                                {t('Order')}
                              </span>
                            </Box>
                            <Box>
                              <Select
                                value={settings.appointment.creating_order || 'Service-provider'}
                                onChange={(e) => handleChange(e, e.target.value, 'appointment')}
                                name="creating_order"
                              >
                                {schedulingOrder.map((item) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TableContainer className={classes.settingsTable}>
                      <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>{i18n.t('Notification')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {Object.keys(settings.notifications).map((keyName) => (
                            <TableRow>
                              <TableCell component="th" scope="row" className={classes.notificationTableTh}>
                                {i18n.t(`Notification${notificationsType[keyName]}`)}
                              </TableCell>
                              <TableCell align="center">
                                <Switch
                                  size="small"
                                  checked={settings.notifications[keyName].includes('notification')}
                                  onChange={(event) => handleChangeSettings(event, keyName)}
                                  color="primary"
                                  name="notification"
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box className="settings-calendarBox">
                      <Box mb="12px">
                        <Typography variant="subtitle1" className={classes.titleCalendar}>
                          {t('Reminder')}
                        </Typography>
                        <Divider />
                      </Box>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>
                            {t('RemindCustomer')}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl>
                            <Select
                              value={settings.reminder.customer}
                              onChange={(e) => handleChange(e, e.target.value, 'reminder')}
                              name="customer"
                            >
                              {reminder.map((item) => (
                                <MenuItem value={item.value}>{item.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box className="settings-calendarBox">
                      <Box mb="12px">
                        <Typography variant="subtitle1" className={classes.titleCalendar}>
                          {t('FollowUp')}
                        </Typography>
                        <Divider />
                      </Box>
                      <Grid container spacing={3} alignItems="center">
                        <Grid item xs={4}>
                          <Typography>
                            {t('ThankYou')}
                          </Typography>
                        </Grid>
                        <Grid item xs={8}>
                          <FormControl>
                            <Select
                              value={settings.follow_up.thank_you_customer}
                              onChange={(e) => handleChange(e, e.target.value, 'follow_up')}
                              name="thank_you_customer"
                            >
                              {thanks.map((item) => (
                                <MenuItem value={item.value}>{item.name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          ) : (
            <SettingsLoading />
          )}
        </Box>
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

export default Settings;
