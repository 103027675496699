import React from 'react';
import Calendar from 'react-calendar';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import company from '../../../assets/img/company.svg';
import avatar from '../../../assets/img/avatar.svg';

function RightContent(props) {
  const {
    selectedAppointment,
  } = props;

  return (
    <div className="right-content">
      <div className="details">
        <div className="company-image">
          {
            selectedAppointment.company ? (
              selectedAppointment.company.logo ? (
                <img src={selectedAppointment.company.logo} alt="company" width="100%" />
              ) : (
                <Box width="100%" display="flex" justifyContent="center" pt="14px">
                  <img src={company} alt="" width="80%" />
                </Box>
              )
            ) : selectedAppointment.individual_user_industry && (
              selectedAppointment.individual_user_industry.avatar ? (
                <img src={selectedAppointment.individual_user_industry.avatar} alt="company" width="100%" />
              ) : (
                <Box width="100%" display="flex" justifyContent="center" pt="14px">
                  <img src={avatar} alt="" width="80%" />
                </Box>
              )
            )
          }
        </div>
        <div className="about-company">
          <ul>
            <li>
              <Typography className="details-item">Service</Typography>
              <Typography className="information">{ selectedAppointment.service.name }</Typography>
            </li>
            <li>
              <Typography className="details-item">Duration</Typography>
              <Typography className="information">{ selectedAppointment.duration }</Typography>
            </li>
            <li>
              <Typography className="details-item">Price</Typography>
              <Typography className="information">{ selectedAppointment.price }</Typography>
            </li>
          </ul>
          {selectedAppointment.company && (
            <ul>
              <li>
                <Typography className="details-item">Company</Typography>
                <Typography className="information">{ selectedAppointment.company && selectedAppointment.company.name }</Typography>
              </li>
              <li>
                <Typography className="details-item">Staff</Typography>
                <Typography className="information">{ selectedAppointment.staff && selectedAppointment.staff.first_name } { selectedAppointment.staff && selectedAppointment.staff.last_name }</Typography>
              </li>
            </ul>
          )}

          {selectedAppointment.individual_user_industry && (
            <ul>
              <li>
                <Typography className="details-item">Industry</Typography>
                <Typography className="information">{ selectedAppointment.individual_user_industry && selectedAppointment.individual_user_industry.industry.name }</Typography>
              </li>
              <li>
                <Typography className="details-item">Individual User</Typography>
                <Typography className="information">{ selectedAppointment.individual_user_industry && selectedAppointment.individual_user_industry.first_name } { selectedAppointment.individual_user_industry && selectedAppointment.individual_user_industry.last_name }</Typography>
              </li>
            </ul>
          )}
        </div>
        <div className="calendar">
          <Calendar
            value={new Date()}
          />
        </div>
      </div>
    </div>
  );
}

RightContent.propTypes = {
  selectedAppointment: PropTypes.object.isRequired,
};

export default RightContent;
