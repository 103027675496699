import React from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

function ActionTab({ company }) {
  return (
    <Typography variant="h6" noWrap>
      { company.company.name }
    </Typography>
  );
}

ActionTab.propTypes = {
  company: PropTypes.object.isRequired,
};

export default ActionTab;
