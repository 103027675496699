import React from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import rootStore from './redux/store';
import Routing from './Router/Routing';
import DateFnsUtils from '@date-io/date-fns';
import * as locales from 'date-fns/locale';
import 'moment/locale/hy-am';
import 'moment/locale/es';
import 'moment/locale/fr';
import './style/general.scss';

function App() {
  const { i18n } = useTranslation();
  document.title = 'Appointment Scheduler For Service Providers | SetTime';
  // document.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';

  return (
    <Provider store={rootStore.store}>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <MuiPickersUtilsProvider
          locale={locales[i18n.language || 'en']}
          utils={DateFnsUtils}
        >
          <Router>
            <Routing />
          </Router>
        </MuiPickersUtilsProvider>
      </GoogleOAuthProvider>
    </Provider>
  );
}

export default App;
