import { takeLatest, put } from 'redux-saga/effects';
import {
  getAllCountriesRequest,
  getAllCountriesSuccess,
  getAllCountriesError,
  getStatesRequest,
  getStatesSuccess,
  getStatesError,
  getCitiesRequest,
  getCitiesSuccess,
  getCitiesError,
  getProfessionsRequest,
  getProfessionsSuccess,
  getProfessionsError,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

function* getAllCountries() {
  try {
    const response = yield axiosInstance.get('countries');
    if (response && response.status === 200) {
      yield put(getAllCountriesSuccess(response.data));
    } else {
      yield put(getAllCountriesError());
    }
  } catch (e) {
    yield put(getAllCountriesError());
  }
}

function* getStates(action) {
  const countryId = action.payload;
  try {
    const response = yield axiosInstance.get(`states?countryId=${countryId}`);
    if (response && response.status === 200) {
      yield put(getStatesSuccess(response.data));
    } else {
      yield put(getStatesError());
    }
  } catch (e) {
    yield put(getStatesError);
  }
}

function* getCities(action) {
  const stateId = action.payload;
  try {
    const response = yield axiosInstance.get(`cities?stateId=${stateId}`);
    if (response && response.status === 200) {
      yield put(getCitiesSuccess(response.data));
    } else {
      yield put(getCitiesError());
    }
  } catch (e) {
    yield put(getCitiesError);
  }
}

function* getProfessions() {
  try {
    const response = yield axiosInstance.get('company/all-professions');
    if (response && response.status === 200) {
      yield put(getProfessionsSuccess(response.data));
    } else {
      yield put(getProfessionsError());
    }
  } catch (e) {
    yield put(getProfessionsError);
  }
}

export default function* () {
  yield takeLatest(getAllCountriesRequest, getAllCountries);
  yield takeLatest(getStatesRequest, getStates);
  yield takeLatest(getCitiesRequest, getCities);
  yield takeLatest(getProfessionsRequest, getProfessions);
}
