import React, { useContext } from 'react';
import {
  Checkbox,
  TableCell,
  IconButton,
  TableRow, Avatar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import '../../Customers/customers.scss';
import getInitials from 'Modules/getInitials';
import DefaultProfileImage from 'assets/img/default-profile.png';
import editIcon from 'assets/icons/edit.svg';
import deleteIcon from 'assets/icons/delete.svg';
import { useSelector } from 'react-redux';
import StaffContext from '../staffContext';

function Row(props) {
  const {
    row,
    isItemSelected,
    labelId,
    onRowClick,
    handleClick,
  } = props;

  const { isUploadImageToAmazonSuccess } = useSelector((state) => state.company);
  const { deleteStaff } = useContext(StaffContext);

  const edit = () => {
    onRowClick();
  };

  const renderAvatar = () => {
    const { avatar, first_name, last_name } = row;
    if (avatar) {
      return (
        <Avatar alt={first_name} src={`${avatar}?=${isUploadImageToAmazonSuccess.toString()}`} style={{ width: 32, height: 32, marginRight: 12 }} />
      );
    } else {
      return (
        <div className="default-circle">
          {first_name ? (
            /^[a-zA-Z]*$/.test(first_name[0]) ? (
              getInitials(first_name)
            ) : last_name ? (
              /^[a-zA-Z]*$/.test(last_name[0]) ? (
                getInitials(last_name)
              ) : (
                <img src={DefaultProfileImage} alt="default" width={32} height={32} />
              )
            ) : (
              <img src={DefaultProfileImage} alt="default" width={32} height={32} />
            )
          ) : (
            <img src={DefaultProfileImage} alt="default" width={32} height={32} />
          )}
        </div>
      );
    }
  };

  return (
    <TableRow
      hover
      aria-checked={isItemSelected}
      role="checkbox"
      tabIndex={-1}
      selected={isItemSelected}
      onClick={() => onRowClick(row)}
      className="row"
    >
      <TableCell padding="checkbox" className="row-item selectCheckbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          onClick={(event) => handleClick(event, row.id, row)}
        />
      </TableCell>
      <TableCell component="th" id={labelId} scope="row" padding="none" className="row-item">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {renderAvatar()}
          {row.full_name || '-'}
        </div>
      </TableCell>
      <TableCell align="left" className="row-item">{row.profession_name || '-'}</TableCell>
      <TableCell align="left" className="row-item">{row.phone_number || '-'}</TableCell>
      <TableCell align="center" className="row-item" style={{ paddingRight: '34px' }}>{row.assigned_services_count || '-'}</TableCell>
      <TableCell align="center" className="row-item" style={{ paddingRight: '34px' }}>{row.total || '-'}</TableCell>
      <TableCell align="center" className="row-item action">
        <IconButton style={{ marginLeft: '-12px' }} onClick={() => edit(row)}>
          <img src={editIcon} alt="edit" />
        </IconButton>
        <IconButton onClick={(e) => deleteStaff(e, row.id)}>
          <img src={deleteIcon} alt="delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default Row;

Row.propTypes = {
  row: PropTypes.object.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  labelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onRowClick: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};
