import { createAction } from 'redux-actions';

// Login
export const loginRequest = createAction('LOGIN_REQUEST');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginError = createAction('LOGIN_ERROR');
// Send email for register
export const sendEmailRequest = createAction('SEND_EMAIL_REQUEST');
export const sendEmailSuccess = createAction('SEND_EMAIL_SUCCESS');
export const sendEmailError = createAction('SEND_EMAIL_ERROR');
// Check token
export const checkTokenRequest = createAction('CHECK_TOKEN_REQUEST');
export const checkTokenSuccess = createAction('CHECK_TOKEN_SUCCESS');
export const checkTokenError = createAction('CHECK_TOKEN_ERROR');
// Registration
export const registerRequest = createAction('REGISTER_REQUEST');
export const registerSuccess = createAction('REGISTER_SUCCESS');
export const registerError = createAction('REGISTER_ERROR');
// Forgot password
export const forgotPasswordRequest = createAction('FORGOT_PASSWORD_REQUEST');
export const forgotPasswordSuccess = createAction('FORGOT_PASSWORD_SUCCESS');
export const forgotPasswordError = createAction('FORGOT_PASSWORD_ERROR');

export const confirmVerificationCodeRequest = createAction('CONFIRM_VERIFICATION_CODE_REQUEST');
export const confirmVerificationCodeSuccess = createAction('CONFIRM_VERIFICATION_CODE_SUCCESS');
export const confirmVerificationCodeError = createAction('CONFIRM_VERIFICATION_CODE_ERROR');

export const resendVerificationCodeRequest = createAction('RESEND_VERIFICATION_CODE_REQUEST');
export const resendVerificationCodeSuccess = createAction('RESEND_VERIFICATION_CODE_SUCCESS');
export const resendVerificationCodeError = createAction('RESEND_VERIFICATION_CODE_ERROR');

// Reset password
export const resetPasswordRequest = createAction('RESET_PASSWORD_REQUEST');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordError = createAction('RESET_PASSWORD_ERROR');
// Login With Google
export const loginWithGoogleRequest = createAction('LOGIN_WITH_GOOGLE_REQUEST');
export const loginWithGoogleSuccess = createAction('LOGIN_WITH_GOOGLE_SUCCESS');
export const loginWithGoogleError = createAction('LOGIN_WITH_GOOGLE_ERROR');
// Login With Facebook
export const loginWithFacebookRequest = createAction('LOGIN_WITH_FACEBOOK_REQUEST');
export const loginWithFacebookSuccess = createAction('LOGIN_WITH_FACEBOOK_SUCCESS');
export const loginWithFacebookError = createAction('LOGIN_WITH_FACEBOOK_ERROR');

export const resetRequest = createAction('RESET');
