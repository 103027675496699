import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faCalendar } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import getNotificationTimeDifference from '../GetNotificationTimeDifference/getNotificationTimeDifference';
import RenderActivityButtons from '../ActivityButtonsContent/activityButtonsContent';

function NotificationItemContent(props) {
  const {
    notifications,
    redirectNotification,
    getNotificationStatusClassName,
    handleAcceptAppointment,
    handleCancelAppointment,
    handleAcceptCustomerInvitation,
    handleDeclineCustomerInvitation,
    handleAcceptStaffInvitation,
    handleDeclineStaffInvitation,
    handleAcceptIndustryCustomerInvitation,
    handleDeclineIndustryCustomerInvitation,
  } = props;

  return (
    <>
      { notifications.map((notification) => (
        <div
          key={`notificationItem${notification.id}`}
          className={`notifications-item  ${!notification.read_at && 'unread'}`}
        >
          <div className="notification-content">
            <div className="activity-content">
              <div className="calendar">
                <FontAwesomeIcon icon={faCalendar} />
              </div>
              <span
                className="notification-message"
                onClick={(event) => redirectNotification(event, notification)}
              >{notification.message}
              </span>
              <span className="notification-status">Status</span>
              <FontAwesomeIcon icon={faArrowRight} />
              <b className={`notification-type ${getNotificationStatusClassName(notification.action.type_message)}`}>
                {notification.action.type_message}
              </b>
              {notification.action.type.name === 'activity' && (
              <RenderActivityButtons
                notification={notification}
                handleAcceptAppointment={handleAcceptAppointment}
                handleCancelAppointment={handleCancelAppointment}
                handleAcceptCustomerInvitation={handleAcceptCustomerInvitation}
                handleDeclineCustomerInvitation={handleDeclineCustomerInvitation}
                handleAcceptStaffInvitation={handleAcceptStaffInvitation}
                handleDeclineStaffInvitation={handleDeclineStaffInvitation}
                handleAcceptIndustryCustomerInvitation={handleAcceptIndustryCustomerInvitation}
                handleDeclineIndustryCustomerInvitation={handleDeclineIndustryCustomerInvitation}
              />
              )}
              <span className="span-time">
                {getNotificationTimeDifference(notification.created_at, notification.company.owner.timezone)}
              </span>
            </div>
          </div>
        </div>
      )) }
    </>
  );
}

NotificationItemContent.propTypes = {
  notifications: PropTypes.array.isRequired,
  handleAcceptAppointment: PropTypes.func.isRequired,
  handleCancelAppointment: PropTypes.func.isRequired,
  handleAcceptCustomerInvitation: PropTypes.func.isRequired,
  handleDeclineCustomerInvitation: PropTypes.func.isRequired,
  handleAcceptIndustryCustomerInvitation: PropTypes.func.isRequired,
  handleDeclineIndustryCustomerInvitation: PropTypes.func.isRequired,
  getNotificationStatusClassName: PropTypes.func.isRequired,
  handleAcceptStaffInvitation: PropTypes.func.isRequired,
  handleDeclineStaffInvitation: PropTypes.func.isRequired,
  redirectNotification: PropTypes.func.isRequired,
};

export default NotificationItemContent;
