import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Button,
} from '@material-ui/core';
import QontoStepIcon from './stepSubComponents/QontoStepIcon';
import usePrevious from '../../../CustomHooks/usePrevious';
import { Industry, Services, WorkingHours } from './steps';
import { storeIndividualUserIndustryRequest } from '../../../redux/individualUser/actions';
import { addServiceRequest } from '../../../redux/service/actions';
import { finishIndividualOnBoardingRequest } from '../../../redux/account/actions';
import { QontoConnector, useStyles } from './stepStyles';
import SnackbarToast from '../../../Modules/SnackbarToast';

function IndividualUserOnBoarding(props) {
  const {
    individualIndustries,
    handleCloseModal,
    storeIndividualUserIndustry,
    isStoreIndividualUserIndustrySuccess,
    isStoreIndividualUserIndustryError,
    storedIndividualUserIndustry,
    storeIndividualUserIndustryErrorMessage,
    addService,
    isAddServiceSuccess,
    isAddServiceError,
    finishIndividualUserOnBoarding,
    isFinishIndividualOnBoardingSuccess,
    isFinishIndividualOnBoardingError,
    finishIndividualOnBoardingErrorMessage,
  } = props;

  const prevIsStoreIndividualUserIndustrySuccess = usePrevious(isStoreIndividualUserIndustrySuccess);
  const prevIsStoreIndividualUserIndustryError = usePrevious(isStoreIndividualUserIndustryError);
  const prevIsAddServiceSuccess = usePrevious(isAddServiceSuccess);
  const prevIsAddServiceError = usePrevious(isAddServiceError);

  const [dialogOpen, setDialogOpen] = useState(true);
  const [selectedServices, setSelectedServices] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [individualUserData, setIndividualUserData] = useState({
    phone_code: '',
    phone_number: '',
    industry_id: '',
    working_hours: [],
    breaking_hours: [],
  });
  const [industryFormErrors, setIndustryFormErrors] = useState({
    phone_code: false,
    phone_number: false,
    industry_id: false,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const classes = useStyles();
  const steps = ['Industry', 'Working hours', 'Services'];

  useEffect(() => {
    if (prevIsStoreIndividualUserIndustrySuccess === false && isStoreIndividualUserIndustrySuccess) {
      if (selectedServices.length > 0 && storedIndividualUserIndustry.industry_id) {
        addService({
          services: selectedServices,
          individual_user_industry_id: storedIndividualUserIndustry.id,
        });
      }
    }
  }, [isStoreIndividualUserIndustrySuccess]);

  useEffect(() => {
    if (prevIsStoreIndividualUserIndustryError === false && isStoreIndividualUserIndustryError) {
      snackBarAlert(true, storeIndividualUserIndustryErrorMessage, 'error');
      handleCloseModal(false);
    }
  }, [isStoreIndividualUserIndustryError]);

  useEffect(() => {
    if (prevIsAddServiceSuccess === false && isAddServiceSuccess) {
      finishIndividualUserOnBoarding();
      setDialogOpen(false);
    }
  }, [isAddServiceSuccess]);

  useEffect(() => {
    if (prevIsAddServiceError === false && isAddServiceError) {
      snackBarAlert(true, 'Please try to edit later', 'error');
      finishIndividualUserOnBoarding();
      setDialogOpen(false);
    }
  }, [isAddServiceError]);

  useEffect(() => {
    if (isFinishIndividualOnBoardingSuccess) {
      snackBarAlert(true, 'Individual user creating finished', 'success');
      window.location.reload();
    }
  }, [isFinishIndividualOnBoardingSuccess]);

  useEffect(() => {
    if (isFinishIndividualOnBoardingError) {
      snackBarAlert(true, finishIndividualOnBoardingErrorMessage, 'error');
    }
  }, [isFinishIndividualOnBoardingError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const setIndividualUserFunction = (dataOptions) => {
    setIndividualUserData((prevState) => ({
      ...prevState,
      ...dataOptions,
    }));
  };

  const handleNext = () => {
    if (validateForm()) {
      if (activeStep === 2) {
        storeIndividualUserIndustry(individualUserData);
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };

  const validateForm = () => {
    switch (activeStep) {
      case 0: {
        return validateIndustryForm();
      }
      case 1: {
        return validateBusinessHoursForm();
      }
      case 2: {
        return validateServiceForm();
      }
    }
  };

  const validateServiceForm = () => selectedServices.length > 0;

  const validateBusinessHoursForm = () => individualUserData.working_hours !== {};

  const validateIndustryForm = () => {
    const individualKeys = Object.keys(individualUserData);
    const industryFormErrorsCopy = { ...industryFormErrors };
    individualKeys.map((item) => {
      switch (item) {
        case 'industry_id': {
          industryFormErrorsCopy.industry_id = typeof individualUserData.industry_id !== 'number';
          break;
        }
        case 'phone_number': {
          industryFormErrorsCopy.phone_number = !(individualUserData.phone_number
              && individualUserData.phone_number !== '');
          break;
        }
        case 'phone_code': {
          industryFormErrorsCopy.phone_code = !(individualUserData.phone_code
              && individualUserData.phone_code !== '');
          break;
        }
      }
    });

    setIndustryFormErrors(industryFormErrorsCopy);
    return (
      !industryFormErrorsCopy.phone_code
        && !industryFormErrorsCopy.phone_number
        && !industryFormErrorsCopy.industry_id
    );
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <>
      <Dialog
        // disableBackdropClick
        disableEscapeKeyDown
        aria-labelledby="confirmation-dialog-title"
        open={dialogOpen}
      >
        <DialogContent dividers>
          <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          { activeStep === 0 ? (
            <Industry
              individualIndustries={individualIndustries}
              setIndividualUserFunction={setIndividualUserFunction}
              formErrors={industryFormErrors}
              individualUserData={individualUserData}
            />
          ) : activeStep === 1 ? (
            <WorkingHours
              workingHoursProps={individualUserData.working_hours}
              breakingHoursProps={individualUserData.breaking_hours}
              setIndividualUserFunction={setIndividualUserFunction}
            />
          ) : (
            <Services
              setSelectedServices={setSelectedServices}
              individualUserIndustry={individualUserData}
            />
          )}
        </DialogContent>
        {/* <Typography className={classes.instructions} weight={600} variant="h5">{getStepContent(activeStep)}</Typography> */}
        { activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed - you&apos;re finished
            </Typography>
            <Button size="small" onClick={handleReset}>
              Reset
            </Button>
          </div>
        ) : (
          <DialogActions>
            <Button
              size="small"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

IndividualUserOnBoarding.propTypes = {
  // Get Parent Props
  individualIndustries: PropTypes.array.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  // Store Individual User Industry
  storeIndividualUserIndustry: PropTypes.func.isRequired,
  isStoreIndividualUserIndustrySuccess: PropTypes.bool.isRequired,
  isStoreIndividualUserIndustryError: PropTypes.bool.isRequired,
  storedIndividualUserIndustry: PropTypes.object.isRequired,
  storeIndividualUserIndustryErrorMessage: PropTypes.string.isRequired,
  // Add service
  addService: PropTypes.func.isRequired,
  isAddServiceSuccess: PropTypes.bool.isRequired,
  isAddServiceError: PropTypes.bool.isRequired,
  // Finish on boarding
  finishIndividualUserOnBoarding: PropTypes.func.isRequired,
  isFinishIndividualOnBoardingSuccess: PropTypes.bool.isRequired,
  isFinishIndividualOnBoardingError: PropTypes.bool.isRequired,
  finishIndividualOnBoardingErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // Store Individual User Industry
  isStoreIndividualUserIndustrySuccess: state.individualUser.isStoreIndividualUserIndustrySuccess,
  isStoreIndividualUserIndustryError: state.individualUser.isStoreIndividualUserIndustryError,
  storedIndividualUserIndustry: state.individualUser.storedIndividualUserIndustry,
  storeIndividualUserIndustryErrorMessage: state.individualUser.storeIndividualUserIndustryErrorMessage,
  // Add service
  isAddServiceSuccess: state.service.isAddServiceSuccess,
  isAddServiceError: state.service.isAddServiceError,
  addServiceErrorMessage: state.service.addServiceErrorMessage,
  // Finish individual user on board
  isFinishIndividualOnBoardingSuccess: state.account.isFinishIndividualOnBoardingSuccess,
  isFinishIndividualOnBoardingError: state.account.isFinishIndividualOnBoardingError,
  finishIndividualOnBoardingErrorMessage: state.account.finishIndividualOnBoardingErrorMessage,
});

const mapDispatchToProps = (dispatch) => ({
  storeIndividualUserIndustry: (data) => dispatch(storeIndividualUserIndustryRequest(data)),
  addService: (data) => dispatch(addServiceRequest(data)),
  finishIndividualUserOnBoarding: () => dispatch(finishIndividualOnBoardingRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualUserOnBoarding);
