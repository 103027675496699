import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import i18n from 'i18next';
import DeleteButton from '../../../MaterialComponents/deleteButton';
import {
  deleteStaffDayOffRequest,
} from '../../../redux/company/actions';
import usePrevious from '../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../Modules/SnackbarToast';

function DeleteStaffDayOffModal(props) {
  const {
    open,
    onClose,
    dayOffId,
    allDayOffs,
    setAllDayOffs,
    buttonLoading,
    setButtonLoading,
    deleteStaffDayOff,
    isDeleteStaffDayOffSuccess,
    isDeleteStaffDayOffError,
    deleteStaffDayOffErrorMessage,
    handleDeleteCompanyState,
  } = props;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous values
  const prevIsDeleteStaffDayOffSuccess = usePrevious(isDeleteStaffDayOffSuccess);
  const prevIsDeleteStaffDayOffError = usePrevious(isDeleteStaffDayOffError);

  // Handle delete day off success
  useEffect(() => {
    if (prevIsDeleteStaffDayOffSuccess === false && isDeleteStaffDayOffSuccess) {
      const allDayOffsCopy = [...allDayOffs];
      const dayOffIndex = allDayOffsCopy.findIndex((item) => item.id === dayOffId);
      allDayOffsCopy.splice(dayOffIndex, 1);
      setAllDayOffs(allDayOffsCopy);
      handleDeleteCompanyState('staffDayOffs', allDayOffsCopy);
      setButtonLoading(true);
      onClose();
      snackBarAlert(true, i18n.t('DayOffDeleted'), 'success');
    }
  }, [isDeleteStaffDayOffSuccess]);

  // Handle delete day off error
  useEffect(() => {
    if (prevIsDeleteStaffDayOffError === false && isDeleteStaffDayOffError) {
      snackBarAlert(true, deleteStaffDayOffErrorMessage, 'error');
    }
  }, [isDeleteStaffDayOffError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleConfirmDeleteDayOff = () => {
    deleteStaffDayOff({ id: dayOffId });
    setButtonLoading(false);
  };

  return (
    <div>
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span className="alert-title">
            <span>{i18n.t('ConfirmDeletion')}</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure you want to delete this entry? The day(s) will become available for booking.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onClose}
          >
            {i18n.t('Cancel')}
          </Button>
          <DeleteButton
            size="small"
            variant="contained"
            color="primary"
            onClick={handleConfirmDeleteDayOff}
            loading={buttonLoading}
          >
            {!buttonLoading && <CircularProgress color="white" size={20} />}
            {buttonLoading && i18n.t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  );
}

DeleteStaffDayOffModal.propTypes = {
  // Parent Props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  dayOffId: PropTypes.number.isRequired,
  allDayOffs: PropTypes.object.isRequired,
  setAllDayOffs: PropTypes.func.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  setButtonLoading: PropTypes.func.isRequired,

  // Delete Day Off
  deleteStaffDayOff: PropTypes.func.isRequired,
  isDeleteStaffDayOffSuccess: PropTypes.bool.isRequired,
  isDeleteStaffDayOffError: PropTypes.bool.isRequired,
  deleteStaffDayOffErrorMessage: PropTypes.string.isRequired,
  handleDeleteCompanyState: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // Delete day off
  isDeleteStaffDayOffSuccess: state.company.isDeleteStaffDayOffSuccess,
  isDeleteStaffDayOffError: state.company.isDeleteStaffDayOffError,
  deleteStaffDayOffErrorMessage: state.company.deleteStaffDayOffErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    deleteStaffDayOff: (data) => dispatch(deleteStaffDayOffRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteStaffDayOffModal);
