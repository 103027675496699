import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  Drawer,
  FormHelperText,
  InputAdornment,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import { useTranslation } from 'react-i18next';
// import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import { useDispatch } from 'react-redux';
import { storeCustomerToCompanyRequest } from '../../../../../../redux/company/actions';
import { emailRegex, phoneNumberRegex } from '../../../../../../Modules/regexValidations';
import CustomerContext from '../customerContext';
import { updateCustomerRequest } from '../../../../../../redux/customer/actions';
// import usePrevious from '../../../../../../CustomHooks/usePrevious';
import trimObject from '../../../../../../Modules/objectTrim';

function CustomerDrawer(props) {
  const { selectedCustomer } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { open, setOpen } = useContext(CustomerContext);

  // const {
  //   updateCustomerErrors,
  //   isUpdateCustomerError,
  // } = useSelector((state) => state.customers);

  // const {
  //   storeCustomerToCompanyErrors,
  //   isStoreCustomerToCompanyError,
  // } = useSelector((state) => state.company);

  // const prevIsStoreCustomerToCompanyError = usePrevious(isStoreCustomerToCompanyError);
  // const prevIsUpdateCustomerError = usePrevious(isUpdateCustomerError);

  const customer = {
    is_web: true,
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
  };
  const defaultCustomerErrors = {
    name: { first_name: false, last_name: false },
    email: { isValid: false },
    phone_number: { isRequired: false, isValid: false },
  };

  const [data, setData] = useState(customer);
  const [errors, setErrors] = useState(defaultCustomerErrors);
  // const [actionError, setActionErrors] = useState({});
  const [isSelectedPhoneNumber, setIsSelectedPhoneNumber] = useState(false);
  // const [isSelectedEmail, setIsSelectedEmail] = useState(false);

  useEffect(() => {
    if (open) {
      if (Object.keys(selectedCustomer).length === 0) {
        setData(customer);
      }

      setErrors(defaultCustomerErrors);
      // setActionErrors({});

      if (Object.keys(selectedCustomer).length > 0) {
        setData({
          is_web: true,
          id: selectedCustomer.id,
          first_name: selectedCustomer.first_name,
          last_name: selectedCustomer.last_name,
          email: selectedCustomer.email || '',
          phone_number: selectedCustomer.phone_number,
        });
      }
    }
  }, [open]);

  // useEffect(() => {
  //   if (prevIsStoreCustomerToCompanyError === false && isStoreCustomerToCompanyError) {
  //     setActionErrors(storeCustomerToCompanyErrors);
  //   }
  // }, [isStoreCustomerToCompanyError]);

  // useEffect(() => {
  //   if (prevIsUpdateCustomerError === false && isUpdateCustomerError) {
  //     setActionErrors(updateCustomerErrors);
  //   }
  // }, [isUpdateCustomerError]);

  const handleChange = (name, value) => {
    setData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const handleChangePhoneNumber = (value) => {
    if (value === '' || phoneNumberRegex.test(value)) {
      handleChange('phone_number', value);
    }
  };

  const validateForm = () => {
    const errorsCopy = { ...errors };
    const {
      first_name, last_name, email, phone_number,
    } = data;
    errorsCopy.name.first_name = first_name.length === 0;
    errorsCopy.name.last_name = last_name.length === 0;
    errorsCopy.email.isValid = email.length
      ? !emailRegex.test(email.trim())
      : false;
    // errorsCopy.phone_number.isRequired = phone_number.length === 0;
    errorsCopy.phone_number.isValid = phone_number.length > 21;
    setErrors(errorsCopy);

    return (
      Object.values(errors.phone_number).some((error) => error === true)
      || Object.values(errors.name).every((error) => error === true)
      || Object.values(errors.email).some((error) => error === true)
    );
  };

  const save = () => {
    if (!validateForm()) {
      const customerData = {
        ...data,
        email: !data.email.length ? null : data.email,
      };
      trimObject(customerData);
      if (Object.keys(selectedCustomer).length > 0) {
        dispatch(updateCustomerRequest(customerData));
      } else {
        dispatch(storeCustomerToCompanyRequest({ customerData }));
      }
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      className="drawer"
      // disableBackdropClick
      PaperProps={{
        style: {
          height: 'calc(100% - 64px)',
          top: 64,
        },
      }}
    >
      <div className="drawer-content">
        <div className="title-content">
          <h3 className="title">
            {Object.keys(selectedCustomer).length > 0 ? t('EditCustomer') : t('NewCustomer')}
          </h3>
          <CloseIcon className="close-icon" onClick={() => setOpen(false)} />
        </div>
        <div className="input-content">
          <TextField
            type="text"
            size="small"
            color="secondary"
            className="input"
            placeholder={`${t('FirstName')} *`}
            label={`${t('FirstName')} *`}
            variant="outlined"
            error={errors.name.first_name && errors.name.last_name}
            value={data.first_name}
            onChange={(e) => handleChange('first_name', e.target.value)}
          />
          {errors.name.first_name
            && errors.name.last_name && (
              <FormHelperText error>
                {t('FirstNameOrLastNameRequired')}
              </FormHelperText>
          )}
        </div>
        <div className="input-content">
          <TextField
            type="text"
            size="small"
            color="secondary"
            className="input"
            placeholder={t('LastNameOptional')}
            label={t('LastName')}
            variant="outlined"
            error={errors.name.first_name && errors.name.last_name}
            value={data.last_name}
            onChange={(e) => handleChange('last_name', e.target.value)}
          />
        </div>
        <div className="input-content">
          <TextField
            size="small"
            color="secondary"
            className="input"
            placeholder={t('PhoneNumberOptional')}
            label={t('PhoneNumber')}
            variant="outlined"
            disabled={selectedCustomer.is_guest}
            error={errors.phone_number.isValid}
            value={data.phone_number}
            onChange={(e) => handleChangePhoneNumber(e.target.value)}
            InputProps={
              isSelectedPhoneNumber
                ? {
                  inputMode: 'numeric',
                  pattern: phoneNumberRegex,
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneOutlinedIcon
                        fontSize="small"
                        color={errors.phone_number.isValid ? 'error' : 'secondary'}
                      />
                    </InputAdornment>
                  ),
                }
                : {}
            }
            onFocus={() => setIsSelectedPhoneNumber(true)}
            onBlur={() => setIsSelectedPhoneNumber(false)}
          />
          {errors.phone_number.isValid && (
            <FormHelperText error>
              {t('lowercasePhoneNumber') + t('IsNotValid')}
            </FormHelperText>
          )}
        </div>
      </div>
      <div className="save-btn">
        <Button variant="contained" color="primary" onClick={save}>{t('Save')}</Button>
      </div>
    </Drawer>
  );
}

export default CustomerDrawer;

CustomerDrawer.propTypes = {
  selectedCustomer: PropTypes.object,
};

CustomerDrawer.defaultProps = {
  selectedCustomer: {},
};
