import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import i18n from 'i18next';
import DeleteButton from '../../../MaterialComponents/deleteButton';

function ConfirmDeleteIndustryModal(props) {
  const {
    onClose,
    handleConfirmDeleteIndustry,
    selectedIndividual,
  } = props;

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open
      onClose={onClose}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={onClose}
      >
        <span className="alert-title">
          <span>{i18n.t('ConfirmDeletion')}</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          {i18n.t('AreYouSureDelete')} industry ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          size="small"
        >
          {i18n.t('Cancel')}
        </Button>
        <DeleteButton
          size="small"
          variant="contained"
          color="primary"
          onClick={() => handleConfirmDeleteIndustry(selectedIndividual, selectedIndividual.industry_id)}
        >
          {i18n.t('Delete')}
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
}

ConfirmDeleteIndustryModal.propTypes = {
  // Parent props
  onClose: PropTypes.func.isRequired,
  handleConfirmDeleteIndustry: PropTypes.func.isRequired,
  selectedIndividual: PropTypes.object.isRequired,
};

export default ConfirmDeleteIndustryModal;
