import React from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import i18n from 'i18next';

function SaveInDraft(props) {
  const {
    open,
    handleCloseModal,
    handleSaveToDraft,
    handleCancelSaveDraft,
    appointmentLoading,
  } = props;

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
        <span className="alert-title">
          <span>{i18n.t('SaveDraft')}</span>
          <IconButton
            aria-label="close"
            className="close-btn"
            onClick={handleCloseModal}
          >
            <CloseIcon />
          </IconButton>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        {i18n.t('SaveDraftText')}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancelSaveDraft}
          data-cy="cancel"
          type="cancel"
          size="small"
        >
          {i18n.t('No')}
        </Button>
        <Button
          size="small"
          onClick={handleSaveToDraft}
          data-cy="save"
          type="submit"
          variant="contained"
          color="primary"
          loading={appointmentLoading}
        >
          {appointmentLoading && <CircularProgress color="white" size={20} />}
          {!appointmentLoading && i18n.t('Yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SaveInDraft.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSaveToDraft: PropTypes.bool.isRequired,
  appointmentLoading: PropTypes.bool.isRequired,
  handleCancelSaveDraft: PropTypes.bool.isRequired,
};

export default SaveInDraft;
