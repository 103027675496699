import { combineReducers } from 'redux';
import auth from './auth/reducer';
import industry from './industry/reducer';
import company from './company/reducer';
import account from './account/reducer';
import businessHours from './businnessHours/reducer';
import staff from './staff/reducer';
import service from './service/reducer';
import appointment from './appointment/reducer';
import message from './message/reducer';
import history from './history/reducer';
import country from './country/reducer';
import individualUser from './individualUser/reducer';
import customers from './customer/reducer';
import notification from './notification/reducer';
import translate from './translate/reducer';

const AppReducer = combineReducers({
  auth,
  account,
  industry,
  businessHours,
  company,
  staff,
  service,
  appointment,
  message,
  history,
  country,
  individualUser,
  customers,
  notification,
  translate,
});

const rootReducer = (state, action) => {
  let newState;
  if (action.type === 'RESET') {
    if (state && state.app) {
      const { app } = state;
      newState = { app };
    } else {
      newState = undefined;
    }
  } else {
    newState = state;
  }

  return AppReducer(newState, action);
};

export default rootReducer;
