import React from 'react';
import {
  Card,
  Paper,
  AppBar,
  Dialog,
  TableCell,
  DialogTitle,
  DialogContent,
  Box,
  Tab,
  Tabs,
  Grid,
  Table,
  Avatar,
  TableRow,
  CardMedia,
  TableBody,
  TableHead,
  IconButton,
  Typography,
  CardContent,
  TableContainer,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import TabPanel from '../../NewAppointment/tabPanel';
import firstLetter from '../../../Modules/getFirstLetter';

function PreviewStaffModal(props) {
  const {
    theme,
    tabValue,
    a11yProps,
    tableAppHeader,
    handleChangeTab,
    previewStaffItem,
    handleChangeIndex,
    tableServiceHeader,
    handleClosePreviewModal,
    isPreviewStaffModalInfo,
  } = props;

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={isPreviewStaffModalInfo}
      onClose={() => handleClosePreviewModal()}
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>
            {i18n.t('StaffPreview')}
          </span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={() => handleClosePreviewModal()}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers className="customized-dialog-content">
        <AppBar position="static" color="inherit" elevation={0}>
          {previewStaffItem && previewStaffItem.invited === false
            ? (
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label={i18n.t('Info')} {...a11yProps(0)} />
                <Tab label={i18n.t('AppointmentsHistory')} {...a11yProps(1)} />
                <Tab label={i18n.t('Services')} {...a11yProps(2)} />
              </Tabs>
            )
            : null}
        </AppBar>
        <SwipeableViews
          id="staff-tab-panel"
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabValue}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={tabValue} index={0} dir={theme.direction}>
            <Card className="staff-tab-card">
              <CardContent className="card-action-area">
                <CardMedia>
                  <Grid container>
                    <Grid item xs={6}>
                      {previewStaffItem && previewStaffItem.avatar ? (
                        <Avatar className="card-box-avatar" alt="avatar" src={previewStaffItem.avatar} />
                      ) : (
                        <Avatar variant="circle" className="card-box-avatar">
                          {firstLetter(previewStaffItem.first_name ? previewStaffItem.first_name : '', previewStaffItem.last_name ? previewStaffItem.last_name : '')}
                        </Avatar>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6">
                        {previewStaffItem.first_name}
                        {' '}
                        {previewStaffItem.last_name}
                      </Typography>
                      {previewStaffItem.email
                        ? (
                          <p>
                            <h5>{i18n.t('Email')}:</h5>
                            <span title={previewStaffItem.email} className="preview-email">{previewStaffItem.email}</span>
                          </p>
                        ) : null}
                      {previewStaffItem.phone_code
                        ? (
                          <p>
                            <h5>{i18n.t('PhoneNumber')}:</h5>
                            {previewStaffItem.phone_code}
                            {' '}
                            {previewStaffItem.phone_number}
                          </p>
                        )
                        : null}
                      {previewStaffItem.position
                        ? (
                          <p>
                            <h5>{i18n.t('Position')}:</h5>
                            {previewStaffItem.position.name}
                          </p>
                        )
                        : null}
                      {previewStaffItem.profession
                        ? (
                          <p>
                            <h5>{i18n.t('Profession')}:</h5>
                            <span title={previewStaffItem.profession.name} className="preview-email">{previewStaffItem.profession.name}</span>
                          </p>
                        )
                        : null}
                    </Grid>
                  </Grid>
                </CardMedia>
              </CardContent>
            </Card>
          </TabPanel>
          {previewStaffItem && previewStaffItem.invited === false
            ? (
              <TabPanel value={tabValue} index={1} dir={theme.direction}>
                <Card className="staff-tab-card">
                  <TableContainer component={Paper} size="small" className="history-container">
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          {previewStaffItem.appointments.length > 0 ? tableAppHeader.map((item, index) => (
                            <TableCell key={`${index}-${Math.random()}`}>
                              {item}
                            </TableCell>
                          )) : '' }
                        </TableRow>
                      </TableHead>
                      <TableBody className="history-body">
                        {Object.keys(previewStaffItem).length && previewStaffItem.appointments.length > 0 && previewStaffItem.appointments ? previewStaffItem.appointments.map((item) => (
                          <TableRow tabIndex={-1} key={item.code}>
                            <TableCell>
                              <Box display="flex" alignItems="center">
                                {moment(item.date)
                                  .format('MMM Do')}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box display="flex" alignItems="center">
                                {previewStaffItem.customerName}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box display="flex" alignItems="center">
                              $
                                {item.price}
                              </Box>
                            </TableCell>
                            <TableCell>
                              <div
                                display="flex"
                                alignItems="center"
                                className={`appointment-status s_${item.status_id}`}
                              >
                                {item.status_id === 2 ? 'Pending' : item.status_id === 1 ? 'Accepted' : item.status_id === 3 ? 'Cancelled' : 'Finished'}
                              </div>
                            </TableCell>
                          </TableRow>
                        )) : (
                          <Box className="no-staff-information">
                            <Box>
                              {i18n.t('NoAppointments')}
                            </Box>
                          </Box>
                        )}

                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </TabPanel>
            )
            : null}
          {previewStaffItem && previewStaffItem.invited === false
            ? (
              <TabPanel value={tabValue} index={2} dir={theme.direction}>
                <Card className="staff-tab-card">
                  <TableContainer component={Paper} size="small" className="services-container">
                    <Table stickyHeader>
                      <TableHead>
                        <TableRow>
                          {previewStaffItem.services && previewStaffItem.services.length ? tableServiceHeader.map((item, index) => (
                            <TableCell colSpan={4} key={`${index}-${Math.random()}`}>
                              {item}
                            </TableCell>
                          )) : '' }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {previewStaffItem.services && previewStaffItem.services.length ? previewStaffItem.services.map((item) => (
                          <TableRow tabIndex={-1} key={item.code}>
                            <TableCell colSpan={4}>
                              <Grid item className="staff-preview-name">
                                <Typography noWrap title={item.service.name}>
                                  {item.service.name}
                                </Typography>
                              </Grid>
                            </TableCell>
                            <TableCell colSpan={4}>
                              <Box display="flex" alignItems="center">
                                {item.duration} min
                              </Box>
                            </TableCell>
                            <TableCell colSpan={4}>
                              <Box display="flex" alignItems="center">
                              $
                                {item.price}
                              </Box>
                            </TableCell>
                          </TableRow>
                        )) : (
                          <Box className="no-staff-information">
                            <Box>
                            No services yet
                            </Box>
                          </Box>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Card>
              </TabPanel>
            )
            : null}
        </SwipeableViews>
      </DialogContent>
    </Dialog>
  );
}

PreviewStaffModal.propTypes = {
  theme: PropTypes.object.isRequired,
  a11yProps: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired,
  tableAppHeader: PropTypes.array.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
  handleChangeIndex: PropTypes.func.isRequired,
  previewStaffItem: PropTypes.object.isRequired,
  tableServiceHeader: PropTypes.array.isRequired,
  handleClosePreviewModal: PropTypes.func.isRequired,
  isPreviewStaffModalInfo: PropTypes.bool.isRequired,
};

export default PreviewStaffModal;
