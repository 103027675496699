import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Switch,
  TableCell,
  Paper,
  AppBar,
  Grid,
  Box,
  Tabs,
  Tab,
  Table,
  Button,
  Typography,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  TableContainer,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import BreakingHours from './breakingHours';
import WorkingHours from './workingHours';
import splitSpaces from '../../../../../../../Modules/splitSpaces';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';
import CloseDayModal from '../../../../../../../Modals/IndividualUserIndustry/WorkingSchedule/closeDay';
import DeleteCloseDayModal from '../../../../../../../Modals/IndividualUserIndustry/WorkingSchedule/deleteCloseDay';
import SpecialDay from '../../../../../../../Modals/IndividualUserIndustry/WorkingSchedule/specialDay';
import DeleteSpecialDayModal from '../../../../../../../Modals/IndividualUserIndustry/WorkingSchedule/deleteSpecialDay';
import { updateWorkingHoursRequest } from '../../../../../../../redux/businnessHours/actions';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import {
  checkIndividualUserIndustryDayAppointmentsRequest,
} from '../../../../../../../redux/appointment/actions';
import weekDayNumber from '../../../../../../../Modules/weekDayNumber';
import HaveAppointmentsModal from '../../../../../../../Modals/IndividualUserIndustry/HaveAppointments';
import WorkingScheduleLoading from '../../../../../../../Components/Loading/workingScheduleLoading';

function WorkingSchedule(props) {
  const dispatch = useDispatch();

  const {
    // Check Day Appointments
    isCheckIndividualUserIndustryDayAppointmentsSuccess,
    isCheckIndividualUserIndustryDayAppointmentsError,
    individualUserIndustryDayAppointments,
    // Cancel Day Appointments
    isCancelIndividualUserIndustryDayAppointmentsSuccess,
    isCancelIndividualUserIndustryDayAppointmentsError,
    cancelIndividualUserIndustryDayAppointmentsErrorMessage,
  } = useSelector((state) => state.appointment);

  const {
    // Update Working Hours
    isUpdateWorkingHoursSuccess,
    isUpdateWorkingHoursError,
    updatedWorkingHours,
    // Update Breaking Hours
    isUpdateBreakingHoursSuccess,
    isUpdateBreakingHoursError,
    updatedBreakingHours,
  } = useSelector((state) => state.businessHours);

  const {
    individualUserIndustryItem,
    setIndividualUserIndustryItem,
  } = props;

  // Get some props previous values
  const prevIsCheckIndividualUserIndustryDayAppointmentsSuccess = usePrevious(isCheckIndividualUserIndustryDayAppointmentsSuccess);
  const prevIsCheckIndividualUserIndustryDayAppointmentsError = usePrevious(isCheckIndividualUserIndustryDayAppointmentsError);
  const prevIsCancelIndividualUserDayAppointmentsSuccess = usePrevious(isCancelIndividualUserIndustryDayAppointmentsSuccess);
  const prevIsCancelIndividualUserDayAppointmentsError = usePrevious(isCancelIndividualUserIndustryDayAppointmentsError);
  const prevIsUpdateWorkingHoursSuccess = usePrevious(isUpdateWorkingHoursSuccess);
  const prevIsUpdateWorkingHoursError = usePrevious(isUpdateWorkingHoursError);
  const prevIsUpdateBreakingHoursSuccess = usePrevious(isUpdateBreakingHoursSuccess);
  const prevIsUpdateBreakingHoursError = usePrevious(isUpdateBreakingHoursError);

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });
  const [workingClosedHours, setWorkingClosedHours] = useState(null);
  const [workingHours, setWorkingHours] = useState(individualUserIndustryItem.workingHours);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [activeTabIndexForCompany, setActiveTabIndexForCompany] = useState(0);
  const [closeDay, setCloseDay] = useState({});
  const [closeDays, setCloseDays] = useState([]);
  const [specialDay, setSpecialDay] = useState({});
  const [specialDays, setSpecialDays] = useState([]);
  const [isSpecialDayModalShown, setIsSpecialDayModalShown] = useState(false);
  const [isCloseDayModalShown, setIsCloseDayModalShown] = useState(false);
  const [selectedCloseDayId, setSelectedCloseDayId] = useState(0);
  const [selectedSpecialDayId, setSelectedSpecialDayId] = useState(0);
  const [isSpecialDayDeleteModalShown, setIsSpecialDayDeleteModalShown] = useState(false);
  const [isDeleteCloseDayModalShown, setIsDeleteCLoseDayModalShown] = useState(false);
  const [haveAppointmentsModalShown, setHaveAppointmentsModalShown] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState('');
  const [dayId, setDayId] = useState('');
  const [weekDay, setWeekDay] = useState('');
  const [checkDay, setCheckDay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [cancelData, setCancelData] = useState({});
  const [individualUser, setIndividualUser] = useState({});

  useEffect(() => {
    setLoading(true);
    setButtonLoading(true);
    setCloseDays(individualUserIndustryItem.dayOffs);
    setSpecialDays(individualUserIndustryItem.specialDays);
    setIndividualUser(individualUserIndustryItem);
  }, [individualUserIndustryItem]);

  // Handle Check Company Day Appointments Success
  useEffect(() => {
    if (prevIsCheckIndividualUserIndustryDayAppointmentsSuccess === false && isCheckIndividualUserIndustryDayAppointmentsSuccess && checkDay) {
      if (individualUserIndustryDayAppointments.length > 0) {
        const weekDay = workingHours.find((hour) => hour.week_day_id === dayId).week_day;
        setWeekDay(weekDay);
        const appointmentsIds = [];
        individualUserIndustryDayAppointments.map((item) => {
          appointmentsIds.push(item.id);
        });
        setCancelData({
          appointment_ids: appointmentsIds,
          name: 'toggle',
        });
        setHaveAppointmentsModalShown(true);
      } else {
        handleWorkingHoursDetailsChange(name, value, checked, dayId);
      }

      setCheckDay(false);
    }
  }, [isCheckIndividualUserIndustryDayAppointmentsSuccess]);

  // Handle Check Company Day Appointments Error
  useEffect(() => {
    if (prevIsCheckIndividualUserIndustryDayAppointmentsError === false && isCheckIndividualUserIndustryDayAppointmentsError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isCheckIndividualUserIndustryDayAppointmentsError]);

  // Handle cancel appointments success
  useEffect(() => {
    if (prevIsCancelIndividualUserDayAppointmentsSuccess === false && isCancelIndividualUserIndustryDayAppointmentsSuccess && cancelData.name === 'toggle') {
      setHaveAppointmentsModalShown(false);
      handleWorkingHoursDetailsChange(name, value, checked, dayId);
    }
  }, [isCancelIndividualUserIndustryDayAppointmentsSuccess]);

  // Handle cancel appointments success
  useEffect(() => {
    if (prevIsCancelIndividualUserDayAppointmentsError === false && isCancelIndividualUserIndustryDayAppointmentsError) {
      setOpenSnackbar(true);
      setSnackbarType('error');
      setSnackbarMessage(cancelIndividualUserIndustryDayAppointmentsErrorMessage);
    }
  }, [isCancelIndividualUserIndustryDayAppointmentsError]);

  // Handle Update Working Hours Success
  useEffect(() => {
    if (prevIsUpdateWorkingHoursSuccess === false && isUpdateWorkingHoursSuccess) {
      setLoading(true);
      setButtonLoading(true);
      setIndividualUser((prevState) => ({
        ...prevState,
        workingHours: updatedWorkingHours,
      }));
      snackBarAlert(true, 'Working days updated successfully', 'success');
    }
  }, [isUpdateWorkingHoursSuccess]);

  // Handle Update Working Hours Error
  useEffect(() => {
    if (prevIsUpdateWorkingHoursError === false && isUpdateWorkingHoursError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isUpdateWorkingHoursError]);

  // Handle Update Breaking Hours Success
  useEffect(() => {
    if (prevIsUpdateBreakingHoursSuccess === false && isUpdateBreakingHoursSuccess) {
      setLoading(true);
      setButtonLoading(true);
      setIndividualUser((prevState) => ({
        ...prevState,
        breakingHours: updatedBreakingHours,
      }));
      snackBarAlert(true, 'Breaking hours updated successfully', 'success');
    }
  }, [isUpdateBreakingHoursSuccess]);

  // Handle Update Breaking Hours Error
  useEffect(() => {
    if (prevIsUpdateBreakingHoursError === false && isUpdateBreakingHoursError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isUpdateBreakingHoursError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const changeWorkingHours = (value) => {
    setWorkingClosedHours(value);
  };

  const handleWorkingHoursDetailsChange = (name, value, checked, id) => {
    const val = checked !== undefined ? checked : value;
    const workingHoursCopy = [...individualUserIndustryItem.workingHours];
    const breakingHoursCopy = [...individualUserIndustryItem.breakingHours];
    const workingHourIndex = workingHoursCopy.findIndex((hour) => hour.week_day_id === id);
    const breakingHourIndex = breakingHoursCopy.findIndex((hour) => hour.week_day_id === id);
    changeWorkingHours(workingHoursCopy);
    if (name === 'is_working') {
      workingHoursCopy[workingHourIndex][name] = val;
      breakingHoursCopy[breakingHourIndex]['is_breaking'] = val;
      setWorkingHours(workingHoursCopy);
    } else if (name === 'start_time') {
      const startTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(workingHoursCopy[workingHourIndex].end_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        workingHoursCopy[workingHourIndex][name] = val;
        breakingHoursCopy[breakingHourIndex]['is_breaking'] = val;
        setWorkingHours(workingHoursCopy);
      } else {
        snackBarAlert(true, i18n.t('StartTimeEarlier'), 'error');

        return false;
      }
    } else if (name === 'end_time') {
      const startTime = moment(workingHoursCopy[workingHourIndex].start_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        workingHoursCopy[workingHourIndex][name] = val;
        setWorkingHours(workingHoursCopy);
      } else {
        snackBarAlert(true, i18n.t('EndTimeAfter'), 'error');

        return false;
      }
    }

    dispatch(updateWorkingHoursRequest({
      individualUserIndustryId: individualUser.id,
      businessDays: workingHoursCopy,
    }));
  };

  const handleChangeTabForCompany = (tabIndex) => {
    setActiveTabIndexForCompany(tabIndex);
  };

  const handleOpenCloseDayModal = () => {
    setCloseDay({});
    setIsCloseDayModalShown(true);
  };

  const handleOpenSpecialDayModal = () => {
    setSpecialDay({});
    setIsSpecialDayModalShown(true);
  };

  const description = (description) => {
    if (description) {
      if (splitSpaces(description) !== '' && description !== '') {
        if (description.length > 30) {
          return `${description.substring(0, 30)}...`;
        } else {
          return description;
        }
      } else {
        return '-';
      }
    }
  };

  const handleCheckAppointments = (event, id) => {
    const { name, value, checked } = event.target;
    if (checked) {
      handleWorkingHoursDetailsChange(name, value, checked, id);
    } else {
      setCheckDay(true);
      dispatch(checkIndividualUserIndustryDayAppointmentsRequest({
        individual_user_industry_id: individualUser.id,
        day: weekDayNumber(id),
      }));
      setName(name);
      setValue(value);
      setChecked(checked);
      setDayId(id);
    }
  };

  const handleEditCloseDay = (closeDay) => {
    setCloseDay(closeDay);
    setIsCloseDayModalShown(true);
  };

  const handleDeleteCloseDay = (closeDayId) => {
    setSelectedCloseDayId(closeDayId);
    setIsDeleteCLoseDayModalShown(true);
  };

  const handleEditSpecialDay = (specialDay) => {
    setSpecialDay(specialDay);
    setIsSpecialDayModalShown(true);
  };

  const handleDeleteSpecialDay = (specialDayId) => {
    setSelectedSpecialDayId(specialDayId);
    setIsSpecialDayDeleteModalShown(true);
  };

  return (
    <>
      {loading ? (
        <>
          <Grid container xs={12} spacing={2} id="working-schedule-content">
            <Grid item xs={3} className="tab-item week-day-switch working-schedule" id="tab-main-parent">
              <Box className="time-zone">
                <span>{i18n.t('GeneralSchedule.WeekDays')}</span>
              </Box>
              <Box py="25px" px="17px">
                { workingHours.map((workHour, index) => (
                  <div className="hours-content" key={`${workHour.week_day}-${index}`}>
                    <Box display="flex" minWidth="173px" className="day-content" key={Math.random()}>
                      <Box minWidth="91px">
                        <div className="day-name">
                          <span data-cy="week-day-name">{ workHour.week_day }</span>
                        </div>
                      </Box>
                      <Box minWidth="91px">
                        <Typography component="div">
                          <Grid component="label" container alignItems="center" spacing={1}>
                            <Grid item>
                              <Switch
                                checked={workHour.is_working}
                                onChange={(event) => handleCheckAppointments(event, workHour.week_day_id)}
                                name="is_working"
                                color="primary"
                                size="small"
                              />
                            </Grid>
                            {workHour.is_working ? (
                              <Grid item> <Typography color="inherit" variant="body1"> Open </Typography> </Grid>
                            ) : <Typography color="inherit" variant="body1"> Close </Typography>}
                          </Grid>
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                ))}
              </Box>
            </Grid>
            <Grid item xs={3} className="working-schedule">
              <div className="time-zone">
                <span>{i18n.t('GeneralSchedule.WorkingHours')}</span>
              </div>
              <WorkingHours
                setWeekDay={setWeekDay}
                cancelData={cancelData}
                setLoading={setLoading}
                setCancelData={setCancelData}
                individualUserIndustryItem={individualUserIndustryItem}
                changeIndividualIndustryHours={changeWorkingHours}
                setHaveAppointmentsModalShown={setHaveAppointmentsModalShown}
              />
            </Grid>
            <Grid item xs={4} className="breaking-schedule">
              <div className="time-zone">
                <span>Breaking Hours</span>
              </div>
              <BreakingHours
                setCancelData={setCancelData}
                cancelData={cancelData}
                setLoading={setLoading}
                setWeekDay={setWeekDay}
                workingClosedHours={workingClosedHours}
                individualUserIndustryItem={individualUserIndustryItem}
                changeIndividualIndustryHours={changeWorkingHours}
                setHaveAppointmentsModalShown={setHaveAppointmentsModalShown}
              />
            </Grid>
            <Grid item xs={10}>
              <Grid item xs={12}>
                <Paper square>
                  <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
                    <Box alignSelf="center" className="tab-companies">
                      <AppBar position="static" color="inherit" elevation={0}>
                        <Tabs
                          value={activeTabIndexForCompany}
                          aria-label="simple tabs example"
                          variant="scrollable"
                          indicatorColor="primary"
                          textColor="secondary"
                        >
                          <Tab
                            label="Days off"
                            onClick={() => handleChangeTabForCompany(0)}
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={i18n.t('SpecificDaysTitle')}
                            onClick={() => handleChangeTabForCompany(1)}
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </AppBar>
                    </Box>
                    {activeTabIndexForCompany === 0 && (
                      <Box>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleOpenCloseDayModal}
                        >
                          {i18n.t('AddDayOff')}
                        </Button>
                      </Box>
                    )}
                    {activeTabIndexForCompany === 1 && (
                      <Box>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleOpenSpecialDayModal}
                        >
                          {i18n.t('AddSpecificWorkDays')}
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <div className="companies menu-item">
                  <Box>
                    {activeTabIndexForCompany === 0 && (
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Date</TableCell>
                              <TableCell align="center">Description</TableCell>
                              <TableCell align="center">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {closeDays.map((closeDay) => (
                              <TableRow>
                                <TableCell align="center">
                                  { closeDay.start_date === closeDay.end_date ? `${moment(closeDay.start_date).format('DD.MM.YYYY')}` : `${moment(closeDay.start_date).format('DD')} - ${moment(closeDay.end_date).format('DD.MM.YYYY')}` }
                                </TableCell>
                                <TableCell align="center">
                                  { description(closeDay.description) }
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton size="small">
                                    <EditIcon color="secondary" fontSize="small" onClick={() => handleEditCloseDay(closeDay)} />
                                  </IconButton>
                                  <IconButton size="small">
                                    <DeleteOutlineIcon color="secondary" fontSize="small" onClick={() => handleDeleteCloseDay(closeDay.id)} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                    {activeTabIndexForCompany === 1 && (
                      <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Date</TableCell>
                              <TableCell align="center">Time</TableCell>
                              <TableCell align="center">Break Time</TableCell>
                              <TableCell align="center">Description</TableCell>
                              <TableCell align="center">Actions</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {specialDays.map((specialDay) => (
                              <TableRow>
                                <TableCell align="center">
                                  { specialDay.start_date === specialDay.end_date ? `${moment(specialDay.start_date).format('DD.MM.YYYY')}` : `${moment(specialDay.start_date).format('DD')} - ${moment(specialDay.end_date).format('DD.MM.YYYY')}` }
                                </TableCell>
                                <TableCell align="center">
                                  { moment(`${specialDay.start_date} ${specialDay.start_time}`).format('H:mm') } - { moment(`${specialDay.end_date} ${specialDay.end_time}`).format('H:mm') }
                                </TableCell>
                                <TableCell align="center">
                                  {specialDay.break_start_time && specialDay.break_end_time
                                    ? (
                                      <>
                                        { moment(`${specialDay.start_date} ${specialDay.break_start_time}`).format('H:mm') } - { moment(`${specialDay.end_date} ${specialDay.break_end_time}`).format('H:mm') }
                                      </>
                                    ) : '-'}
                                </TableCell>
                                <TableCell align="center">
                                  { description(specialDay.description) }
                                </TableCell>
                                <TableCell align="center">
                                  <IconButton size="small">
                                    <EditIcon color="secondary" fontSize="small" onClick={() => handleEditSpecialDay(specialDay)} />
                                  </IconButton>
                                  <IconButton size="small">
                                    <DeleteOutlineIcon color="secondary" fontSize="small" onClick={() => handleDeleteSpecialDay(specialDay.id)} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Box>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <CloseDayModal
            open={isCloseDayModalShown}
            onClose={() => setIsCloseDayModalShown(false)}
            closeDay={closeDay}
            allCloseDays={closeDays}
            setAllCloseDays={setCloseDays}
            individualUserIndustryItem={individualUserIndustryItem}
            setIndividualUserIndustryItem={setIndividualUserIndustryItem}
          />

          <SpecialDay
            open={isSpecialDayModalShown}
            onClose={() => setIsSpecialDayModalShown(false)}
            specialDay={specialDay}
            allSpecialDays={specialDays}
            setAllSpecialDays={setSpecialDays}
            individualUserIndustryItem={individualUserIndustryItem}
            setIndividualUserIndustryItem={setIndividualUserIndustryItem}
          />

          <DeleteCloseDayModal
            open={isDeleteCloseDayModalShown}
            onClose={() => setIsDeleteCLoseDayModalShown(false)}
            closeDayId={selectedCloseDayId}
            allCloseDays={closeDays}
            setAllCloseDays={setCloseDays}
            setIndividualUserIndustryItem={setIndividualUserIndustryItem}
          />

          <DeleteSpecialDayModal
            open={isSpecialDayDeleteModalShown}
            onClose={() => setIsSpecialDayDeleteModalShown(false)}
            specialDayId={selectedSpecialDayId}
            allSpecialDays={specialDays}
            setAllSpecialDays={setSpecialDays}
            setIndividualUserIndustryItem={setIndividualUserIndustryItem}
          />

          <HaveAppointmentsModal
            open={haveAppointmentsModalShown}
            onClose={() => setHaveAppointmentsModalShown(false)}
            weekDay={weekDay}
            from="individual"
            cancelData={cancelData}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <SnackbarToast
            message={snackbarMessage}
            type={snackbarType}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
          />
        </>
      ) : <WorkingScheduleLoading />}
    </>
  );
}

WorkingSchedule.propTypes = {
  individualUserIndustryItem: PropTypes.object.isRequired,
  setIndividualUserIndustryItem: PropTypes.func.isRequired,
};

export default WorkingSchedule;
