import React from 'react';
import { TextField, Checkbox } from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PropTypes from 'prop-types';

function AutocompleteSelectLimitTags(props) {
  const {
    items,
    error,
    label,
    limitTags,
    onSelectAll,
    onToggleOption,
    onClearOptions,
    getOptionLabel,
    selectedValues,
  } = props;

  const allSelected = items.length === selectedValues.length;
  const handleToggleSelectAll = () => {
    onSelectAll && onSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      if (selectedOptions.find((option) => option.value === 'select-all')) {
        handleToggleSelectAll();
      } else {
        onToggleOption && onToggleOption(selectedOptions);
      }
    } else if (reason === 'clear') {
      onClearOptions && onClearOptions();
    }
  };

  const optionRenderer = (option, { selected }) => {
    const selectAllProps = option.value === 'select-all' // To control the state of 'select-all' checkbox
      ? { checked: allSelected }
      : {};
    return (
      <>
        <Checkbox
          color="primary"
          icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
          checkedIcon={<CheckBoxIcon fontSize="small" />}
          style={{ marginRight: 8 }}
          checked={selected}
          {...selectAllProps}
        />
        {getOptionLabel(option)}
      </>
    );
  };

  const inputRenderer = (params) => (
    <TextField {...params} error={error} variant="outlined" label={label} placeholder={!items.length ? 'No staff' : ''} />
  );

  const getOptionSelected = (option, anotherOption) => option.value === anotherOption.value;
  const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={limitTags}
      options={items}
      value={selectedValues}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered.length > 0 && [{ label: 'All', value: 'select-all' }, ...filtered];
      }}
      onChange={handleChange}
      renderOption={optionRenderer}
      renderInput={inputRenderer}
    />
  );
}

AutocompleteSelectLimitTags.propTypes = {
  items: PropTypes.array.isRequired,
  selectedValues: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  limitTags: PropTypes.number.isRequired,
  error: PropTypes.bool.isRequired,
  onToggleOption: PropTypes.func.isRequired,
  onClearOptions: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
};

export default AutocompleteSelectLimitTags;
