import { put, takeLatest } from 'redux-saga/effects';
import {
  getIndustriesRequest,
  getIndustriesSuccess,
  getIndustriesError,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

function* getIndustries(action) {
  try {
    const { payload } = action;
    let url = 'industries';
    if (payload && payload.filterBy) {
      url = `${url}?filterBy=${payload.filterBy}`;
    }
    const res = yield axiosInstance.get(url);
    if (res && res.status === 200) {
      yield put(getIndustriesSuccess(res.data));
    } else if (!localStorage.getItem('prefix')) {
      const response = yield axiosInstance.get('owner/industries');
      if (response && response.status === 200) {
        yield put(getIndustriesSuccess(response.data));
      }
    }
  } catch (e) {
    yield put(getIndustriesError());
  }
}

export default function* () {
  yield takeLatest(getIndustriesRequest, getIndustries);
}
