import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button } from '@material-ui/core';
import jwt_decode from 'jwt-decode';

import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import fbSocial from 'assets/img/facebook-rounded.svg';

import i18n from 'i18next';
import {
  loginWithFacebookRequest,
  loginWithGoogleRequest,
} from '../../redux/auth/actions';

export default function OAuth({ handleLogin }) {
  const dispatch = useDispatch();
  const {
    isLoginWithGoogleSuccess,
    isLoginWithFacebookSuccess,
    accessToken,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isLoginWithGoogleSuccess || isLoginWithFacebookSuccess) {
      handleLogin(accessToken);
    }
  }, [isLoginWithGoogleSuccess, isLoginWithFacebookSuccess]);

  const onGoogleSuccess = async (response) => {
    const user = jwt_decode(response.credential);
    dispatch(
      loginWithGoogleRequest({
        email: user.email,
        token: response.credential,
        device: 'web',
      }),
    );
  };

  const responseFacebook = (response) => {
    const { email, accessToken } = response;
    dispatch(
      loginWithFacebookRequest({
        email,
        token: accessToken,
      }),
    );
  };

  return (
    <>
      <Box
        mt="15px"
        // gridGap="15px"
        // display="flex"
        // alignItems="center"
        // justifyContent="center"
      >
        <Box>
          <GoogleLogin
            // type="icon"
            width={300}
            onSuccess={onGoogleSuccess}
            // containerProps={{
            //   style: {
            //     overflow: 'hidden',
            //   },
            // }}
            size="large"
          />
        </Box>
        <Box mt="15px">
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_APP_ID}
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => (
              <Button
                style={{
                  cursor: 'pointer',
                  width: '100%',
                  backgroundColor: 'transparent',
                  border: '1px solid #dddde0',
                  borderRadius: '4px',
                  padding: '0 10px',
                }}
                onMouseEnter={(e) => {
                  e.target.style.backgroundColor = 'rgba(210, 227, 252, 0.15)';
                  e.target.style.borderColor = '#d2e3fc';
                }}
                onMouseLeave={(e) => {
                  e.target.style.backgroundColor = 'transparent';
                  e.target.style.borderColor = '#dddde0';
                }}
                onClick={renderProps.onClick}
              >
                <img
                  src={fbSocial}
                  alt="Facebook"
                  style={{
                    width: '20px',
                    marginRight: '20px',
                  }}
                />
                <div style={{
                  color: '#3c4043',
                  fontWeight: '500',
                }}
                >{i18n.t('continueWithFacebook')}
                </div>
              </Button>
            )}
          />
        </Box>
      </Box>
    </>
  );
}
