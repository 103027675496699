import { createAction } from 'redux-actions';

export const getAllCountriesRequest = createAction('GET_ALL_COUNTRIES_REQUEST');
export const getAllCountriesSuccess = createAction('GET_ALL_COUNTRIES_SUCCESS');
export const getAllCountriesError = createAction('GET_ALL_COUNTRIES_ERROR');

export const getStatesRequest = createAction('GET_STATES_REQUEST');
export const getStatesSuccess = createAction('GET_STATES_SUCCESS');
export const getStatesError = createAction('GET_STATES_ERROR');

export const getCitiesRequest = createAction('GET_CITIES_REQUEST');
export const getCitiesSuccess = createAction('GET_CITIES_SUCCESS');
export const getCitiesError = createAction('GET_CITIES_ERROR');

export const getProfessionsRequest = createAction('GET_PROFESSIONS_REQUEST');
export const getProfessionsSuccess = createAction('GET_PROFESSIONS_SUCCESS');
export const getProfessionsError = createAction('GET_PROFESSIONS_ERROR');
