import React from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import firstLetter from '../../../../Modules/getFirstLetter';

function UserDetails(props) {
  const {
    firstName,
    lastName,
    moreDetail,
  } = props;

  return (
    <Box display="flex" alignItems="center">
      <Box className="user-image">
        <Box className="top-avatar">
          <Avatar variant="circle" className="message-avatar">
            { firstLetter(firstName || '', lastName || '')}
          </Avatar>
        </Box>
      </Box>
      <div className="user-name">
        <Typography component="div">
          <Box fontWeight={600}>
            { firstName } { lastName }
          </Box>
          <Box>{ moreDetail }</Box>
        </Typography>
      </div>
    </Box>
  );
}

UserDetails.propTypes = {
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  moreDetail: PropTypes.string.isRequired,
};

export default UserDetails;
