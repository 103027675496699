import { handleActions } from 'redux-actions';
import {
  getMessagesRequest, getMessagesSuccess,
  getMessagesError, storeMessageRequest,
  storeMessageSuccess, storeMessageError,
  makeMessagesReadRequest, makeMessagesReadSuccess,
  makeMessagesReadError, updateMessageRequest,
  updateMessageSuccess, updateMessageError,
  deleteMessageRequest, deleteMessageSuccess,
  deleteMessageError,
} from './actions';

const initialState = {
  isGetMessagesSuccess: false,
  isGetMessagesError: false,
  appointments: [],
  allCount: 0,
  getMessagesErrorMessage: '',
  isStoreMessageSuccess: false,
  isStoreMessageError: false,
  storedMessage: {},
  storeMessageErrorMessage: '',
  isMakeMessagesReadSuccess: false,
  isMakeMessagesReadError: false,
  messagesReadAppointmentId: 0,
  isUpdateMessageSuccess: false,
  isUpdateMessageError: false,
  updatedMessage: {},
  updateMessageErrorMessage: '',
  isDeleteMessageSuccess: false,
  isDeleteMessageError: false,
  deleteMessageErrorMessage: '',
};

const reducer = handleActions({
  [getMessagesRequest]: (state) => ({
    ...state,
    isGetMessagesSuccess: false,
    isGetMessagesError: false,
    appointments: [],
    allCount: 0,
    getMessagesErrorMessage: '',
  }),
  [getMessagesSuccess]: (state, { payload }) => ({
    ...state,
    isGetMessagesSuccess: true,
    isGetMessagesError: false,
    appointments: payload.data.appointments,
    allCount: payload.data.count,
    getMessagesErrorMessage: '',
  }),
  [getMessagesError]: (state, { payload }) => ({
    ...state,
    isGetMessagesSuccess: false,
    isGetMessagesError: false,
    appointments: [],
    allCount: 0,
    getMessagesErrorMessage: payload.data,
  }),
  [storeMessageRequest]: (state) => ({
    ...state,
    isStoreMessageSuccess: false,
    isStoreMessageError: false,
    storedMessage: {},
    storeMessageErrorMessage: '',
  }),
  [storeMessageSuccess]: (state, { payload }) => ({
    ...state,
    isStoreMessageSuccess: true,
    isStoreMessageError: false,
    storedMessage: payload.data,
    storeMessageErrorMessage: '',
  }),
  [storeMessageError]: (state, { payload }) => ({
    ...state,
    isStoreMessageSuccess: false,
    isStoreMessageError: true,
    storedMessage: {},
    storeMessageErrorMessage: payload.data,
  }),
  [makeMessagesReadRequest]: (state) => ({
    ...state,
    isMakeMessagesReadSuccess: false,
    isMakeMessagesReadError: false,
  }),
  [makeMessagesReadSuccess]: (state, { payload }) => ({
    ...state,
    isMakeMessagesReadSuccess: true,
    isMakeMessagesReadError: false,
    messagesReadAppointmentId: parseInt(payload.data.id),
  }),
  [makeMessagesReadError]: (state) => ({
    ...state,
    isMakeMessagesReadSuccess: false,
    isMakeMessagesReadError: true,
  }),
  [updateMessageRequest]: (state) => ({
    ...state,
    isUpdateMessageSuccess: false,
    isUpdateMessageError: false,
    updatedMessage: {},
    updateMessageErrorMessage: '',
  }),
  [updateMessageSuccess]: (state, { payload }) => ({
    ...state,
    isUpdateMessageSuccess: true,
    isUpdateMessageError: false,
    updatedMessage: payload.data,
    updateMessageErrorMessage: '',
  }),
  [updateMessageError]: (state, { payload }) => ({
    ...state,
    isUpdateMessageSuccess: false,
    isUpdateMessageError: true,
    updatedMessage: {},
    updateMessageErrorMessage: payload.message,
  }),
  [deleteMessageRequest]: (state) => ({
    ...state,
    isDeleteMessageSuccess: false,
    isDeleteMessageError: false,
    deleteMessageErrorMessage: '',
  }),
  [deleteMessageSuccess]: (state) => ({
    ...state,
    isDeleteMessageSuccess: true,
    isDeleteMessageError: false,
    deleteMessageErrorMessage: '',
  }),
  [deleteMessageError]: (state, { payload }) => ({
    ...state,
    isDeleteMessageSuccess: false,
    isDeleteMessageError: true,
    deleteMessageErrorMessage: payload.message,
  }),
}, initialState);

export default reducer;
