import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function Header(props) {
  const {
    roles,
    selectedRole,
    selectRole,
  } = props;
  return (
    <div id="header">
      <div className="roles">
        { roles.map((role) => (
          <div
            className={`${selectedRole === role && 'selected'} role`}
            onClick={() => selectRole(role)}
            key={role}
          >
            {role}
          </div>
        )) }
      </div>
    </div>
  );
}

Header.propTypes = {
  roles: PropTypes.array.isRequired,
  selectedRole: PropTypes.string.isRequired,
  selectRole: PropTypes.func.isRequired,
};

export default Header;
