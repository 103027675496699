import React from 'react';

const getCountryOptions = (countries) => countries.map((country) => ({
  id: country.id,
  key: country.iso,
  value: country.name,
  label: country.name,
  code: country.phoneCode,
  capital: country.capital,
}));

const getCodeOptions = (countries) => {
  const codeOptions = [];
  countries.map((country) => {
    const flagImageSrc = `https://flagcdn.com/w20/${country.iso.toLowerCase()}.png`;
    const flagImageSrcSet = `https://flagcdn.com/w40/${country.iso.toLowerCase()}.png 2x`;
    if ((country.phoneCode).indexOf(',') > -1) {
      const multiCodes = (country.phoneCode).split(',');
      multiCodes.map((code) => {
        codeOptions.push({
          key: country.iso,
          value: code,
          label:
  <>
    <div className="d-flex">
      <img
        src={flagImageSrc}
        srcSet={flagImageSrcSet}
        alt="flag"
      />
      <span className="country-code">+{code}</span>
    </div>
  </ >,
        });
        return true;
      });
    } else {
      codeOptions.push({
        key: country.iso,
        value: `+${country.phoneCode}`,
        label: { flagImageSrc },
      });
    }
  });
  return codeOptions;
};

const getStateOptions = (states) => states.map((state) => ({
  id: state.id,
  value: state.name,
  label: state.name,
}));

const getCitiesOptions = (cities) => cities.map((city) => ({
  id: city.id,
  value: city.name,
  label: city.name,
}));

const getIndustryOptions = (industries) => industries.map((industry) => ({
  id: industry.id,
  value: industry.name,
  label: industry.name,
  logo: industry.logo,
}));

export default {
  getCountryOptions,
  getCodeOptions,
  getStateOptions,
  getCitiesOptions,
  getIndustryOptions,
};
