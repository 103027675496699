export const cancelUpToAppointments = [
  'Never',
  'Anytime',
  '5 minutes',
  '10 minutes',
  '15 minutes',
  '30 minutes',
  '45 minutes',
  '1 hour',
  '2 hour',
  '3 hour',
  '4 hour',
  '5 hour',
  '6 hour',
  '7 hour',
  '8 hour',
  '9 hour',
  '10 hour',
  '11 hour',
  '12 hour',
  '13 hour',
  '14 hour',
  '15 hour',
  '1 day',
  '2 days',
  '3 days',
  '4 days',
  '5 days',
  '6 days',
  '7 days',
  '8 days',
  '9 days',
  '10 days',
  '11 days',
  '12 days',
  '13 days',
  '14 days',
  '15 days',
  '16 days',
  '17 days',
  '18 days',
  '19 days',
  '20 days',
  '21 days',
  '22 days',
  '23 days',
  '24 days',
  '25 days',
  '26 days',
  '27 days',
  '28 days',
  '29 days',
  '30 days',
];

export const limitFutureAppointments = [
  '5 minutes',
  '10 minutes',
  '15 minutes',
  '20 minutes',
  '30 minutes',
  '45 minutes',
  '1 hour',
  '2 hour',
  '3 hour',
  '4 hour',
  '5 hour',
  '10 hour',
  '15 hour',
  '1 day',
  '2 days',
  '3 days',
  '4 days',
  '5 days',
  '7 days',
  '10 days',
  '14 days',
  '21 days',
  '30 days',
  '60 days',
];

export const limitSoonerAppointments = [
  '1 day',
  '2 days',
  '3 days',
  '4 days',
  '5 days',
  '7 days',
  '10 days',
  '14 days',
  '21 days',
  '30 days',
  '60 days',
  '90 days',
  '180 days',
  '360 days',
];

export const reminder = [
  {
    name: 'Never',
    value: false,
  },
  {
    name: '2 hours before',
    value: 2,
  },
  {
    name: '3 hours before',
    value: 3,
  },
  {
    name: '4 hours before',
    value: 4,
  },
  {
    name: '5 hours before',
    value: 5,
  },
  {
    name: '6 hours before',
    value: 6,
  },
  {
    name: '7 hours before',
    value: 7,
  },
  {
    name: '8 hours before',
    value: 8,
  },
  {
    name: '9 hours before',
    value: 9,
  },
  {
    name: '10 hours before',
    value: 10,
  },
  {
    name: '11 hours before',
    value: 11,
  },
  {
    name: '12 hours before',
    value: 12,
  },
];

export const slotDurations = [
  '5',
  '10',
  '15',
  '20',
  '30',
  '60',
];

export const thanks = [
  {
    name: 'Never',
    value: false,
  },
  {
    name: 'Immediately after',
    value: 0,
  },
  {
    name: '5 minutes after',
    value: 5,
  },
  {
    name: '10 minutes after',
    value: 10,
  },
  {
    name: '15 minutes after',
    value: 15,
  },
  {
    name: '20 minutes after',
    value: 20,
  },
  {
    name: '30 minutes after',
    value: 30,
  },
  {
    name: '1 hour after',
    value: 60,
  },
  {
    name: '2 hours after',
    value: 120,
  },
];

export const tableHead = [
  'Notification',
];

export const tableHeadCustomer = [
  'Notification',
  'Email',
];

export const schedulingOrder = [
  'Service provider',
  'Staff-Service',
];

export const notificationsType = {
  appointment_confirmed: 'Confirmed',
  appointment_canceled: 'Canceled',
  appointment_rescheduled: 'Rescheduled',
};

export const customersHeadCells = [
  {
    id: 'full_name', numeric: false, disablePadding: true, label: 'Customer Name', sortable: true,
  },
  {
    id: 'email', numeric: false, disablePadding: false, label: 'Email', sortable: true,
  },
  {
    id: 'phone', numeric: false, disablePadding: false, label: 'Phone', sortable: false,
  },
  {
    id: 'appointments_total', numeric: true, disablePadding: false, label: 'Visits', sortable: true,
  },
  {
    id: 'last_visit', numeric: false, disablePadding: false, label: 'Last Visit', sortable: true,
  },
  {
    id: 'first_visit', numeric: false, disablePadding: false, label: 'First Visit', sortable: true,
  },
  {
    id: 'total', numeric: false, disablePadding: false, label: 'Total', sortable: true,
  },
];

export const staffHeadCells = [
  {
    id: 'full_name', numeric: false, disablePadding: false, label: 'Staff Name', sortable: true,
  },
  {
    id: 'profession_name', numeric: false, disablePadding: false, label: 'Profession', sortable: true,
  },
  {
    id: 'phone', numeric: false, disablePadding: false, label: 'Phone', sortable: false,
  },
  {
    id: 'assigned_services_count', numeric: true, disablePadding: false, label: 'Assigned Services', sortable: true,
  },
  {
    id: 'total', numeric: true, disablePadding: false, label: 'Appointment Count', sortable: true,
  },
];

export const serviceHeadCells = [
  {
    id: 'name', numeric: false, disablePadding: false, label: 'Service Name', sortable: true,
  },
  {
    id: 'price', numeric: false, disablePadding: false, label: 'Price', sortable: true,
  },
  {
    id: 'duration', numeric: false, disablePadding: false, label: 'Duration', sortable: true,
  },
  {
    id: 'actions', numeric: false, disablePadding: false, label: 'Actions', sortable: false,
  },
];

export const AppointmentHeadCells = [
  {
    id: 'date', numeric: false, disablePadding: false, label: 'Date', sortable: true,
  },
  {
    id: 'start_time', numeric: false, disablePadding: false, label: 'Time', sortable: true,
  },
  {
    id: 'customer_name', numeric: false, disablePadding: false, label: 'Customer', sortable: true,
  },
  {
    id: 'staff_first_name', numeric: false, disablePadding: false, label: 'Staff', sortable: true,
  },
  {
    id: 'service_name', numeric: false, disablePadding: false, label: 'Service', sortable: true,
  },
  {
    id: 'price', numeric: false, disablePadding: false, label: 'Price', sortable: true,
  },
  {
    id: 'status_name', numeric: false, disablePadding: false, label: 'Status', sortable: true,
  },
  {
    id: 'actions', numeric: false, disablePadding: false, label: 'Actions', sortable: false,
  },
];

export const currencyList = [
  {
    id: 1, label: 'Armenian Dram (AMD)', icon: '\u058F', currency: 'AMD',
  },
  {
    id: 2, label: 'US dollar (USD)', icon: '\u0024', currency: 'USD',
  },
  {
    id: 3, label: 'European Euro (EUR)', icon: '\u20AC', currency: 'EUR',
  },
  {
    id: 4,
    label: 'British Pound Sterling (GBP)',
    icon: '\u00A3',
    currency: 'GBP',
  },
  {
    id: 5, label: 'Chinese Yuan (CNY)', icon: '\u00A5', currency: 'CNY',
  },
  {
    id: 6, label: 'Russian Ruble (RUB)', icon: '\u20BD', currency: 'RUB',
  },
  {
    id: 7, label: 'Georgian Lari (GEL)', icon: '\u20BE', currency: 'GEL',
  },
  {
    id: 8, label: 'Japanese Yen (JPY)', icon: '\uFFE5', currency: 'JPY',
  },
  {
    id: 9, label: 'Indian Rupee (INR)', icon: '\u20B9', currency: 'INR',
  },
  {
    id: 10, label: 'Swiss Franc (CHF)', icon: '\u20A3', currency: 'CHF',
  },
  {
    id: 11, label: 'South African Rand (ZAR)', icon: '\u0052', currency: 'ZAR',
  },
  {
    id: 12, label: 'Canadian Dollar (CAD)', icon: '\u00A2', currency: 'CAD',
  },
  {
    id: 13,
    label: 'New Zealand Dollar (NZD)',
    icon: 'NZ\u0024',
    currency: 'NZD',
  },
  {
    id: 15, label: 'Australian Dollar (AUD)', icon: 'A\u0024', currency: 'AUD',
  },
  {
    id: 16, label: 'Swedish Krona (SEK)', icon: '\u006B\u0072', currency: 'SEK',
  },
  {
    id: 17, label: 'Mexican Peso (MXN)', icon: 'MX\u0024', currency: 'MXN',
  },
  {
    id: 18, label: 'Brazilian Real (BRL)', icon: 'R\u0024', currency: 'BRL',
  },
  {
    id: 20, label: 'South Korean Won (KRW)', icon: 'HK\u20A9', currency: 'KRW',
  },
  {
    id: 21, label: 'Singapore Dollar (SGD)', icon: 'S\u0024', currency: 'SGD',
  },
  {
    id: 22, label: 'Philippine Peso (PHP)', icon: '\u20B1', currency: 'PHP',
  },
  {
    id: 23, label: 'UAE Dirham (AED)', icon: '\tد.إ', currency: 'AED',
  },
  {
    id: 24, label: 'Turkish Lira (TRY)', icon: '\u20A4', currency: 'TRY',
  },
  {
    id: 25, label: 'Israeli New Shekel (ILS)', icon: '\u20AA', currency: 'ILS',
  },
  {
    id: 26,
    label: 'Indonesian Rupiah (IDR)',
    icon: '\u0052\u0070',
    currency: 'IDR',
  },
  {
    id: 27, label: 'Pakistani Rupee (PKR)', icon: '\u20A8', currency: 'PKR',
  },
  {
    id: 28, label: 'Iranian Rial (IRR)', icon: '\uFDFC', currency: 'IRR',
  },
  {
    id: 29, label: 'Tanzanian Shilling (TZS)', icon: 'TZS', currency: 'TZS',
  },
  {
    id: 30,
    label: 'Eastern Caribbean Dollar (XCD)',
    icon: 'EC\u0024',
    currency: 'XCD',
  },
  {
    id: 33, label: 'Botswana Pula (BWP)', icon: 'P', currency: 'BWP',
  },
  {
    id: 34, label: 'Egyptian Pound (EGP)', icon: ' ج.م', currency: 'EGP',
  },
  {
    id: 35, label: 'Jordanian Dinar (JOD)', icon: 'JD', currency: 'JOD',
  },
  {
    id: 36, label: 'Kenyan Shilling (KES)', icon: 'KSh', currency: 'KES',
  },
  {
    id: 37, label: 'Romanian Leu (RON)', icon: 'lei', currency: 'RON',
  },
  {
    id: 38,
    label: 'Malaysian Ringgit (MYR)',
    icon: '\u0052\u004D',
    currency: 'MYR',
  },
  {
    id: 39, label: 'Moldovan Leu (MDL)', icon: 'L', currency: 'MDL',
  },
  {
    id: 40, label: 'Mozambican Metical (MZN)', icon: 'MT', currency: 'MZN',
  },
  {
    id: 41, label: 'Nigerian Naira (NGN)', icon: '\u20A6', currency: 'NGN',
  },
  {
    id: 42, label: 'Qatari Riyal (QAR)', icon: '\uFDFC', currency: 'QAR',
  },
  {
    id: 43,
    label: 'Afghani (AFN)',
    icon: '\u060b',
    currency: 'AFN',
  },
  {
    id: 44,
    label: 'Lek (ALL)',
    icon: 'L',
    currency: 'ALL',
  },
  {
    id: 45,
    label: 'Algerian Dinar (DZD)',
    icon: '\u062f.\u062c',
    currency: 'DZD',
  },
  {
    id: 46,
    label: 'Kwanza (AOA)',
    icon: 'Kz',
    currency: 'AOA',
  },
  {
    id: 47,
    label: 'Argentine Peso (ARS)',
    icon: '$',
    currency: 'ARS',
  },
  {
    id: 48,
    label: 'Guyana Dollar (GYD)',
    icon: '$',
    currency: 'GYD',
  },
  {
    id: 49,
    label: 'Azerbaijan Manat (AZN)',
    icon: '\u20bc',
    currency: 'AZN',
  },
  {
    id: 50,
    label: 'Bahraini Dinar (BHD)',
    icon: '.\u062f.\u0628',
    currency: 'BHD',
  },
  {
    id: 50,
    label: 'Taka (BDT)',
    icon: '\u09f3',
    currency: 'BDT',
  },
  {
    id: 50,
    label: 'Belarusian Ruble (BYR)',
    icon: 'Br',
    currency: 'BYR',
  },
  {
    id: 51,
    label: 'Belize Dollar (BZD)',
    icon: '$',
    currency: 'BZD',
  },
  {
    id: 52,
    label: 'CFA Franc BCEAO (XOF)',
    icon: 'Fr',
    currency: 'XOF',
  },
  {
    id: 53,
    label: 'Ngultrum (BTN)',
    icon: 'Nu.',
    currency: 'BTN',
  },
  {
    id: 54,
    label: 'Boliviano (BOB)',
    icon: 'Bs.',
    currency: 'BOB',
  },
  {
    id: 55,
    label: 'Convertible Mark (BAM)',
    icon: 'KM',
    currency: 'BAM',
  },
  {
    id: 56,
    label: 'Brunei Dollar (BND)',
    icon: '$',
    currency: 'BND',
  },
  {
    id: 57,
    label: 'Bulgarian Lev (BGN)',
    icon: '\u043b\u0432.',
    currency: 'BGN',
  },
  {
    id: 58,
    label: 'Burundi Franc (BIF)',
    icon: 'Fr',
    currency: 'BIF',
  },
  {
    id: 59,
    label: 'Riel (KHR)',
    icon: '\u17db',
    currency: 'KHR',
  },
  {
    id: 60,
    label: 'CFA Franc BEAC (XAF)',
    icon: 'Fr',
    currency: 'XAF',
  },
  {
    id: 61,
    label: 'Cabo Verde Escudo (CVE)',
    icon: '$',
    currency: 'CVE',
  },
  {
    id: 62,
    label: 'Chilean Peso (CLP)',
    icon: '$',
    currency: 'CLP',
  },
  {
    id: 63,
    label: 'Colombian Peso (COP)',
    icon: '$',
    currency: 'COP',
  },
  {
    id: 64,
    label: 'Comorian Franc (KMF)',
    icon: 'Fr',
    currency: 'KMF',
  },
  {
    id: 65,
    label: 'Congolese Franc (CDF)',
    icon: 'Fr',
    currency: 'CDF',
  },
  {
    id: 66,
    label: 'Costa Rican Colon (CRC)',
    icon: '\u20a1',
    currency: 'CRC',
  },
  {
    id: 67,
    label: 'Kuna (HRK)',
    icon: 'kn',
    currency: 'HRK',
  },
  {
    id: 68,
    label: 'Cuban Peso (CUP)',
    icon: '$',
    currency: 'CUP',
  },
  {
    id: 69,
    label: 'Czech Koruna (CZK)',
    icon: 'K\u010d',
    currency: 'CZK',
  },
  {
    id: 70,
    label: 'Danish Krone (DKK)',
    icon: 'kr',
    currency: 'DKK',
  },
  {
    id: 71,
    label: 'Djibouti Franc (DJF)',
    icon: 'Fr',
    currency: 'DJF',
  },
  {
    id: 72,
    label: 'East Caribbean Dollar (XCD)',
    currency: 'XCD',
    icon: '$',
  },
  {
    id: 73,
    label: 'Dominican Peso (DOP)',
    icon: 'RD$',
    currency: 'DOP',
  },
  {
    id: 74,
    label: 'Nakfa (ERN)',
    icon: 'Nfk',
    currency: 'ERN',
  },
  {
    id: 75,
    label: 'Ethiopian Birr (ETB)',
    icon: 'Br',
    currency: 'ETB',
  },
  {
    id: 76,
    label: 'Fiji Dollar (FJD)',
    icon: '$',
    currency: 'FJD',
  },
  {
    id: 77,
    label: 'Dalasi (GMD)',
    icon: 'D',
    currency: 'GMD',
  },
  {
    id: 78,
    label: 'Ghana Cedi (GHS)',
    icon: '\u20b5',
    currency: 'GHS',
  },
  {
    id: 79,
    label: 'Quetzal (GTQ)',
    icon: 'Q',
    currency: 'GTQ',
  },
  {
    id: 80,
    label: 'Guinean Franc (GNF)',
    icon: 'Fr',
    currency: 'GNF',
  },
  {
    id: 81,
    label: 'Gourde (HTG)',
    icon: 'G',
    currency: 'HTG',
  },
  {
    id: 82,
    label: 'Lempira (HNL)',
    icon: 'L',
    currency: 'HNL',
  },
  {
    id: 83,
    label: 'Forint (HUF)',
    icon: 'Ft',
    currency: 'HUF',
  },
  {
    id: 84,
    label: 'Iceland Krona (ISK)',
    icon: 'kr',
    currency: 'ISK',
  },
  {
    id: 85,
    label: 'Iraqi Dinar (IQD)',
    icon: '\u0639.\u062f',
    currency: 'IQD',
  },
  {
    id: 86,
    label: 'Jamaican Dollar (JMD)',
    icon: '$',
    currency: 'JMD',
  },
  {
    id: 87,
    label: 'Tenge (KZT)',
    icon: '\u20b8',
    currency: 'KZT',
  },
  {
    id: 88,
    label: 'North Korean Won (KPW)',
    icon: '\u20a9',
    currency: 'KPW',
  },
  {
    id: 89,
    label: 'Kuwaiti Dinar (KWD)',
    icon: '\u062f.\u0643',
    currency: 'KWD',
  },
  {
    id: 90,
    label: 'Som (KGS)',
    icon: '\u0441',
    currency: 'KGS',
  },
  {
    id: 91,
    label: 'Lao Kip (LAK)',
    icon: '\u20ad',
    currency: 'LAK',
  },
  {
    id: 92,
    label: 'Lebanese Pound (LBP)',
    icon: '\u0644.\u0644',
    currency: 'LBP',
  },
  {
    id: 93,
    label: 'Loti (LSL)',
    icon: 'L',
    currency: 'LSL',
  },
  {
    id: 94,
    label: 'Liberian Dollar (LRD)',
    icon: '$',
    currency: 'LRD',
  },
  {
    id: 95,
    label: 'Libyan Dinar (LYD)',
    icon: '\u0644.\u062f',
    currency: 'LYD',
  },
  {
    id: 96,
    label: 'Lithuanian litas (LTL)',
    icon: 'Lt',
    currency: 'LTL',
  },
  {
    id: 97,
    label: 'Denar (MKD)',
    icon: '\u0434\u0435\u043d',
    currency: 'MKD',
  },
  {
    id: 98,
    label: 'Malagasy Ariary (MGA)',
    icon: 'Ar',
    currency: 'MGA',
  },
  {
    id: 99,
    label: 'Malawi Kwacha (MWK)',
    icon: 'MK',
    currency: 'MWK',
  },
  {
    id: 100,
    label: 'Rufiyaa (MVR)',
    icon: '.\u0783',
    currency: 'MVR',
  },
  {
    id: 101,
    label: 'Mauritanian ouguiya (MRU)',
    icon: 'UM',
    currency: 'MRU',
  },
  {
    id: 102,
    label: 'Mauritius Rupee (MUR)',
    icon: '\u20a8',
    currency: 'MUR',
  },
  {
    id: 103,
    label: 'Tugrik (MNT)',
    currency: 'MNT',
    icon: '\u20ae',
  },
  {
    id: 104,
    label: 'Moroccan Dirham (MAD)',
    icon: '\u062f.\u0645.',
    currency: 'MAD',
  },
  {
    id: 105,
    label: 'Kyat (MMK)',
    icon: 'Ks',
    currency: 'MMK',
  },
  {
    id: 106,
    label: 'Namibia Dollar (NAD)',
    icon: '$',
    currency: 'NAD',
  },
  {
    id: 107,
    label: 'Nepalese Rupee (NPR)',
    icon: '\u0930\u0942',
    currency: 'NPR',
  },
  {
    id: 108,
    label: 'Cordoba Oro (NIO)',
    icon: 'C$',
    currency: 'NIO',
  },
  {
    id: 109,
    label: 'Naira (NGN)',
    icon: '\u20a6',
    currency: 'NGN',
  },
  {
    id: 110,
    label: 'Norwegian Krone (NOK)',
    icon: 'kr',
    currency: 'NOK',
  },
  {
    id: 111,
    label: 'Rial Omani (OMR)',
    icon: '\u0631.\u0639.',
    currency: 'OMR',
  },
  {
    id: 112,
    label: 'Balboa (PAB)',
    icon: 'B/.',
    currency: 'PAB',
  },
  {
    id: 113,
    label: 'Kina (PGK)',
    icon: 'K',
    currency: 'PGK',
  },
  {
    id: 114,
    label: 'Guarani (PYG)',
    icon: '\u20b2',
    currency: 'PYG',
  },
  {
    id: 115,
    label: 'Sol (PEN)',
    icon: 'S/.',
    currency: 'PEN',
  },
  {
    id: 116,
    label: 'Zloty (PLN)',
    icon: 'z\u0142',
    currency: 'PLN',
  },
  {
    id: 117,
    label: 'Rwanda Franc (RWF)',
    icon: 'Fr',
    currency: 'RWF',
  },
  {
    id: 118,
    label: 'Tala (WST)',
    icon: 'T',
    currency: 'WST',
  },
  {
    id: 119,
    label: 'São Tomé and Príncipe dobra (STD)',
    icon: 'Db',
    currency: 'STD',
  },
  {
    id: 120,
    label: 'Saudi Riyal (SAR)',
    icon: '\ufdfc',
    currency: 'SAR',
  },
  {
    id: 121,
    label: 'Serbian Dinar (RSD)',
    icon: '\u0434\u0438\u043d.',
    currency: 'RSD',
  },
  {
    id: 122,
    label: 'Seychelles Rupee (SCR)',
    icon: '\u20a8',
    currency: 'SCR',
  },
  {
    id: 123,
    label: 'Leone (SLL)',
    icon: 'Le',
    currency: 'SLL',
  },
  {
    id: 124,
    label: 'Solomon Islands Dollar (SBD)',
    icon: '$',
    currency: 'SBD',
  },
  {
    id: 125,
    label: 'Somali Shilling (SOS)',
    currency: 'SOS',
    icon: 'Sh',
  },
  {
    id: 126,
    label: 'South Sudanese Pound (SSP)',
    icon: '\u00a3',
    currency: 'SSP',
  },
  {
    id: 127,
    label: 'Sri Lanka Rupee (LKR)',
    icon: 'Rs',
    currency: 'LKR',
  },
  {
    id: 128,
    label: 'Sudanese Pound (SDG)',
    icon: '\u062c.\u0633.',
    currency: 'SDG',
  },
  {
    id: 129,
    label: 'Surinam Dollar (SRD)',
    icon: '$',
    currency: 'SRD',
  },
  {
    id: 130,
    label: 'Lilangeni (SZL)',
    icon: 'L',
    currency: 'SZL',
  },
  {
    id: 131,
    label: 'Syrian Pound (SYP)',
    icon: '\u00a3',
    currency: 'SYP',
  },
  {
    id: 132,
    label: 'New Taiwan Dollar (TWD)',
    icon: '$',
    currency: 'TWD',
  },
  {
    id: 133,
    label: 'Somoni (TJS)',
    icon: '\u0441.',
    currency: 'TJS',
  },
  {
    id: 134,
    label: 'Baht (THB)',
    icon: '\u0e3f',
    currency: 'THB',
  },
  {
    id: 135,
    label: 'Pa\u2019anga (TOP)',
    icon: 'T$',
    currency: 'TOP',
  },
  {
    id: 136,
    label: 'Trinidad and Tobago Dollar (TTD)',
    icon: '$',
    currency: 'TTD',
  },
  {
    id: 137,
    label: 'Tunisian Dinar (TND)',
    icon: '\u062f.\u062a',
    currency: 'TND',
  },
  {
    id: 138,
    label: 'Turkmenistan New Manat (TMT)',
    icon: 'm.',
    currency: 'TMT',
  },
  {
    id: 139,
    label: 'Uganda Shilling (UGX)',
    icon: 'Sh',
    currency: 'UGX',
  },
  {
    id: 140,
    label: 'Hryvnia (UAH)',
    icon: '\u20b4',
    currency: 'UAH',
  },
  {
    id: 141,
    label: 'Peso Uruguayo (UYU)',
    icon: '$',
    currency: 'UYU',
  },
  {
    id: 142,
    label: 'Uzbekistan Sum (UZS)',
    icon: 'S\u02bb',
    currency: 'UZS',
  },
  {
    id: 143,
    label: 'Vatu (VUV)',
    icon: 'Vt',
    currency: 'VUV',
  },
  {
    id: 144,
    label: 'Venezuelan bolívar (VEF)',
    icon: 'Bs.',
    currency: 'VEF',
  },
  {
    id: 145,
    label: 'Dong (VND)',
    icon: '\u20ab',
    currency: 'VND',
  },
  {
    id: 146,
    label: 'Yemeni Rial (YER)',
    icon: '\ufdfc',
    currency: 'YER',
  },
  {
    id: 147,
    label: 'Zambian kwacha (ZMK)',
    icon: 'ZK',
    currency: 'ZMK',
  },
  {
    id: 148,
    label: 'Zimbabwe Dollar (ZWL)',
    icon: '$',
    currency: 'ZWL',
  },
  {
    id: 149,
    label: 'Barbados (BBD)',
    icon: 'Bds$',
    currency: 'BBD',
  },
];

export const resourceTimeGridOptions = [
  { value: 'resourceTimeGridDay', label: 'Daily' },
  { value: 'resourceTimeGridWeek', label: 'Weekly' },
  { value: 'resourceDayGridMonth', label: 'Monthly' },
];

export const calendarDateFormat = 'YYYY-MM-DD';

export const languages = [
  {
    id: 1,
    label: 'Armenian',
    link: ' ',
    value: 'hy',
  },
  {
    id: 2,
    label: 'English',
    link: ' ',
    value: 'en',
  },
  {
    id: 3,
    label: 'French',
    link: ' ',
    value: 'fr',
  },
  {
    id: 4,
    label: 'Spanish',
    link: ' ',
    value: 'es',
  },
  {
    id: 5,
    label: 'Deutsch',
    link: ' ',
    value: 'de',
  },
  {
    id: 6,
    label: 'Português',
    link: ' ',
    value: 'pt',
  },
  // {
  //   id: 7,
  //   label: 'عربي',
  //   link: ' ',
  //   value: 'ar',
  // },
];
