import React, { useState } from 'react';
import { connect } from 'react-redux';
import '../../../../../style/messages.scss';
import './reminder.scss';
import {
  AppBar,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  TextField,
  InputLabel,
  TableCell,
  Paper,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
  MenuItem,
  FormControl,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditIcon from '@material-ui/icons/Edit';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import i18n from 'i18next';
import DeleteButton from '../../../../../MaterialComponents/deleteButton';

const useStyles = makeStyles({
  table: {
    minWidth: 1550,
  },
});

const Reminder = () => {
  const [dialog, setDialog] = useState(false);
  const [check, setCheck] = useState({ checked: true });
  const [open, setOpen] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const classes = useStyles();

  const appointmentOptions = [];

  const handleReminderDetailsChange = (event) => {
    setCheck({ ...check, [event.target.name]: event.target.checked });
  };

  const handleReminderEditModalOpen = () => {
    setDialog(true);
  };

  const handleDeleteReminderModal = () => {
    setDeleteModal(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const reminderAppointments = [
    {
      title: 'Default message',
      eventOccur: 'After appointment created',
      performAction: 'Send by Email',
      specificTime: 'Immediate',
    },
  ];

  return (
    <>
      <div className="menu-item reminders" id="reminders">
        <div className="general-content">
          <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                <Typography variant="h6">
                  <Box display="flex" alignItems="center" height="100%">
                    Reminder
                  </Box>
                </Typography>
                <div>
                  <Button variant="contained" color="primary" size="small" onClick={handleClickOpen}>
                  Create
                  </Button>
                  <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle>
                      <span className="alert-title">
                        <span>Create New Reminder</span>
                        <span>
                          <IconButton aria-label="close" className="close-btn" onClick={handleClose}>
                            <CloseIcon />
                          </IconButton>
                        </span>
                      </span>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField size="small" fullWidth id="outlined-basic" label="Title *" variant="outlined" />
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-outlined-label">For this appointment *</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              options={appointmentOptions}
                              label="For this appointment *"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-outlined-label">When this event occur *</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              options={appointmentOptions}
                              label="For this appointment *"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-outlined-label">Perform this action *</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              options={appointmentOptions}
                              label="For this appointment *"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-outlined-label">On this specific time *</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              options={appointmentOptions}
                              label="For this appointment *"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <FormControl fullWidth variant="outlined" size="small">
                            <InputLabel id="demo-simple-select-outlined-label">Status *</InputLabel>
                            <Select
                              labelId="demo-simple-select-outlined-label"
                              id="demo-simple-select-outlined"
                              options={appointmentOptions}
                              label="For this appointment *"
                            >
                              <MenuItem value="">
                                <em>None</em>
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            id="outlined-textarea"
                            label="Your message..."
                            multiline
                            fullWidth
                            variant="outlined"
                            rows={3}
                            rowsMax={4}
                          />
                        </Grid>
                      </Grid>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        size="small"
                        onClick={handleClose}
                      >
                      Cancel
                      </Button>
                      <Button
                        size="small"
                        color="primary"
                        variant="contained"
                      >
                      Create
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </Box>
            </Toolbar>
          </AppBar>
          <div className="general-content">
            <div className="main-content reminders">
              <TableContainer component={Paper}>
                <Table aria-label="caption table" className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                            Title
                      </TableCell>
                      <TableCell align="left">
                            When this event occur
                      </TableCell>
                      <TableCell align="left">
                            Perform action
                      </TableCell>
                      <TableCell align="left">
                            On this specific time
                      </TableCell>
                      <TableCell align="left">
                            Status
                      </TableCell>
                      <TableCell align="left">
                            Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reminderAppointments.map((item, index) => (
                      <TableRow key={`${item.title}-${index}`}>
                        <TableCell component="th" scope="row">
                          {item.title}
                        </TableCell>
                        <TableCell>{item.eventOccur}</TableCell>
                        <TableCell>{item.performAction}</TableCell>
                        <TableCell>{item.specificTime}</TableCell>
                        <TableCell>
                          <Switch
                            checked={check.checked}
                            onChange={handleReminderDetailsChange}
                            color="primary"
                            name="checked"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton aria-label="edit" onClick={handleReminderEditModalOpen}>
                            <EditIcon fontSize="small" color="secondary" />
                          </IconButton>
                          <IconButton
                            aria-label="delete"
                            onClick={handleDeleteReminderModal}
                          >
                            <DeleteOutlineIcon fontSize="small" color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        size="sm"
        open={deleteModal}
        onClose={() => setDeleteModal(false)}
      >
        <DialogTitle id="customized-dialog-title">
          <span className="alert-title">
            <span>{i18n.t('ConfirmDeletion')}</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => setDeleteModal(false)}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {i18n.t('AreYouSureDelete')} reminder ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            data-cy="cancel"
            type="button"
            onClick={() => setDeleteModal(false)}
          >
            {i18n.t('Cancel')}
          </Button>
          <DeleteButton
            size="small"
            data-cy="delete"
            type="button"
            variant="contained"
            color="primary"
          >
            {i18n.t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
      <Dialog open={dialog} onClose={() => setDialog(false)}>
        <DialogTitle>
          <span className="dialog-title">
            <span>Edit reminder</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => setDialog(false)}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField size="small" fullWidth id="outlined-basic" label="Title *" variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">For this appointment *</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  options={appointmentOptions}
                  label="For this appointment *"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">When this event occur *</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  options={appointmentOptions}
                  label="For this appointment *"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">Perform this action *</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  options={appointmentOptions}
                  label="For this appointment *"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">On this specific time *</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  options={appointmentOptions}
                  label="For this appointment *"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel id="demo-simple-select-outlined-label">Status *</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  options={appointmentOptions}
                  label="For this appointment *"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="outlined-textarea"
                label="Your message..."
                multiline
                fullWidth
                variant="outlined"
                rows={3}
                rowsMax={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => setDialog(false)}
          >
            {i18n.t('Cancel')}
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
          >
            {i18n.t('Save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = () => ({

});

function mapDispatchToProps() {
  return {

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Reminder);
