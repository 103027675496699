import React, { useEffect, useState } from 'react';
import {
  Menu,
  Checkbox,
  Box,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { filterStaffOptionsRequest } from '../../../redux/staff/actions';
import getStaffProfessions from '../../../Modules/getStaffProfessions';

function FilterStaffOptions(props) {
  const dispatch = useDispatch();
  const {
    open,
    anchorEl,
    checkedStatus,
    allCompanyStaffSorted,
    handleCloseFilter,
    handleChangeStatus,
    handleUpdateCheckedStatus,
  } = props;

  const [checkedItems, setCheckedItems] = useState({});
  const [staffProfessions, setStaffProfessions] = useState([]);
  const [staffFilter, setStaffFilter] = useState({
    active: true,
    inActive: true,
    positions: [],
    professions: [],
  });

  useEffect(() => {
    if (open && !staffProfessions.length) {
      const uniqueProfessions = getStaffProfessions(allCompanyStaffSorted);
      setStaffProfessions(uniqueProfessions);
      const allStaffProfessions = [...uniqueProfessions];
      const allStaffProfessionsIds = allStaffProfessions.map((item) => item.id);
      handleUpdateCheckedStatus({
        active: checkedStatus.active,
        inActive: checkedStatus.inActive,
        positions: checkedStatus.positions,
        professions: allStaffProfessionsIds,
      });

      setStaffFilter({
        ...staffFilter,
        professions: allStaffProfessions,
      });
      setCheckedItems({
        active: checkedStatus.active,
        inActive: checkedStatus.inActive,
        positions: checkedStatus.positions,
        professions: allStaffProfessionsIds,
      });
    }
  }, [open]);

  // Handle change and filter checkboxes
  const handleChangeCheckboxes = (event) => {
    const {
      name,
      value,
    } = event.target;
    const checkedFilterItems = checkedItems[name].includes(parseInt(value))
      ? {
        ...checkedItems,
        [name]: checkedItems[name].filter((item) => item !== parseInt(value)),
      }
      : {
        ...checkedItems,
        [name]: [...checkedItems[name], parseInt(value)],
      };
    setCheckedItems({ ...checkedFilterItems });

    handleUpdateCheckedStatus({ ...checkedFilterItems });
    handleChangeStatus({ ...checkedFilterItems });
  };

  const handleFilterStaffOption = () => {
    const {
      active, inActive, positions, professions,
    } = checkedItems;
    const filteredData = {
      active,
      inActive,
      position_ids: positions || [],
      profession_ids: professions || [],
      searchValue: '',
    };
    dispatch(filterStaffOptionsRequest(filteredData));
    handleCloseFilter();
  };

  return (
    <Menu
      className="filter-staff-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={handleCloseFilter}
    >
      <Typography variant="h5">{i18n.t('Position')}</Typography>
      <li key={staffFilter.positions}>
        <Box alignSelf="center" mr="5px">
          <Typography variant="h5">Manager</Typography>
        </Box>
        <Checkbox
          size="small"
          color="primary"
          value={1}
          name="positions"
          checked={Object.keys(checkedItems).length ? checkedItems.positions.includes(1) : ''}
          onChange={(e) => handleChangeCheckboxes(e, staffFilter.positions)}
        />
      </li>
      <Divider />
      {staffFilter.professions.length
        ? (
          <>
            <Typography variant="h5">{i18n.t('Profession')}</Typography>
            {staffFilter.professions.map((item) => (
              <li key={item.id}>
                <Box alignSelf="center" mr="5px">
                  <Typography variant="h5">{item.label}</Typography>
                </Box>
                <Checkbox
                  size="small"
                  color="primary"
                  value={item.id}
                  name="professions"
                  checked={Object.keys(checkedItems).length ? checkedItems.professions.includes(item.id) : ''}
                  onChange={(e) => handleChangeCheckboxes(e, item.id)}
                />
              </li>
            ))}
          </>
        )
        : null}
      <Divider />
      <Box display="flex" justifyContent="flex-end" mt="16px">
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleFilterStaffOption}
        >
          {i18n.t('Apply')}
        </Button>
      </Box>
    </Menu>
  );
}

FilterStaffOptions.propTypes = {
  open: PropTypes.bool.isRequired,
  anchorEl: PropTypes.bool.isRequired,
  checkedStatus: PropTypes.object.isRequired,
  allCompanyStaffSorted: PropTypes.array.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handleUpdateCheckedStatus: PropTypes.func.isRequired,
};

export default FilterStaffOptions;
