import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import { Link } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import {
  Info,
} from './tabs';
import ActionTab from './actionTab/actionTab';
import NavTabs from './navTabs';
import InfoLoading from '../../../../../../Components/Loading/infoLoading';
import {
  getCompanyByIdRequest,
} from '../../../../../../redux/company/actions';
import WorkingSchedule from './tabs/workingSchedule';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import Services from './tabs/services';
import SnackbarToast from '../../../../../../Modules/SnackbarToast';

function CompanyDetails(props) {
  const {
    match,
    history,
    getCompanyById,
    isGetCompanyByIdSuccess,
    isGetCompanyByIdError,
    companyGot,
    getCompanyByIdErrorMessage,
  } = props;

  const [loading, setLoading] = useState(true);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [company, setCompany] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const prevIsGetCompanyByIdSuccess = usePrevious(isGetCompanyByIdSuccess);
  const prevIsGetCompanyByIdError = usePrevious(isGetCompanyByIdError);

  // Perform, when function gets mounted
  useEffect(() => {
    const { id } = match.params;
    setLoading(true);
    getCompanyById(id);
    setCompany({});
  }, []);
  // Handle Get Company By Id Success
  useEffect(() => {
    if (isGetCompanyByIdSuccess && prevIsGetCompanyByIdSuccess === false) {
      const { id, tab } = match.params;
      const selectedTab = getSelectedTab(tab);
      setActiveTabIndex(selectedTab);
      setCompany(companyGot);
      setLoading(false);
      history.push(`/companies/${id}/${tab || 'info'}`);
    }
  }, [isGetCompanyByIdSuccess]);
  // Handle Get Company By Id Error
  useEffect(() => {
    if (isGetCompanyByIdError && prevIsGetCompanyByIdError === false) {
      setOpenSnackbar(true);
      setSnackbarMessage(getCompanyByIdErrorMessage);
      setSnackbarType('error');
      setLoading(false);
      history.push('/companies');
    }
  }, [isGetCompanyByIdError]);

  const getSelectedTab = (tab) => {
    let selectedTab;
    switch (tab) {
      case 'info': {
        selectedTab = 0;
        break;
      }
      case 'working-schedule': {
        selectedTab = 1;
        break;
      }
      case 'services': {
        selectedTab = 2;
        break;
      }
      default: {
        selectedTab = 0;
      }
    }
    return selectedTab;
  };

  const handleChangeTab = (tab) => {
    const { id } = match.params;
    const selectedTab = getSelectedTab(tab || 'info');
    setActiveTabIndex(selectedTab);
    history.push(`/companies/${id}/${tab || 'info'}`);
  };

  return !loading ? (
    <div>
      <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
            <Box display="flex" className="tabs-navbar">
              <Box alignSelf="center" className="divider-title">
                <ActionTab company={company} />
              </Box>
              <Box alignSelf="center" ml="-18px" mr="6px">
                <Link to="/companies">
                  <IconButton size="small">
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Link>
              </Box>
              <Box alignSelf="center" className="tab-companies">
                <NavTabs
                  activeTabIndex={activeTabIndex}
                  handleChangeTab={handleChangeTab}
                />
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Box className="tab-list companies-tab-list">
        {
          activeTabIndex === 0 ? (
            <Info
              company={company.company}
            />
          ) : activeTabIndex === 1 ? (
            <WorkingSchedule
              company={company}
            />
          ) : activeTabIndex === 2 ? (
            <Services
              company={company.company}
            />
          ) : null
        }
      </Box>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  ) : <InfoLoading />;
}

CompanyDetails.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  // Get Company By Id
  getCompanyById: PropTypes.func.isRequired,
  isGetCompanyByIdSuccess: PropTypes.bool.isRequired,
  isGetCompanyByIdError: PropTypes.bool.isRequired,
  companyGot: PropTypes.object.isRequired,
  getCompanyByIdErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // Get Company By Id
  isGetCompanyByIdSuccess: state.company.isGetCompanyByIdSuccess,
  isGetCompanyByIdError: state.company.isGetCompanyByIdError,
  companyGot: state.company.companyGot,
  getCompanyByIdErrorMessage: state.company.getCompanyByIdErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    getCompanyById: (data) => dispatch(getCompanyByIdRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);
