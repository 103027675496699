import { createAction } from 'redux-actions';

export const storeCompanyRequest = createAction('STORE_COMPANY_REQUEST');
export const storeCompanySuccess = createAction('STORE_COMPANY_SUCCESS');
export const storeCompanyError = createAction('STORE_COMPANY_ERROR');

export const storeGalleryCompanyRequest = createAction('STORE_GALLERY_COMPANY_REQUEST');
export const storeGalleryCompanySuccess = createAction('STORE_GALLERY_COMPANY_SUCCESS');
export const storeGalleryCompanyError = createAction('STORE_GALLERY_COMPANY_ERROR');

export const deleteGalleryCompanyRequest = createAction('DELETE_GALLERY_COMPANY_REQUEST');
export const deleteGalleryCompanySuccess = createAction('DELETE_GALLERY_COMPANY_SUCCESS');
export const deleteGalleryCompanyError = createAction('DELETE_GALLERY_COMPANY_ERROR');

export const getOwnerCompaniesRequest = createAction('GET_OWNER_COMPANIES_REQUEST');
export const getOwnerCompaniesSuccess = createAction('GET_OWNER_COMPANIES_SUCCESS');
export const getOwnerCompaniesError = createAction('GET_OWNER_COMPANIES_ERROR');

export const updateCompanyRequest = createAction('UPDATE_COMPANY_REQUEST');
export const updateCompanySuccess = createAction('UPDATE_COMPANY_SUCCESS');
export const updateCompanyError = createAction('UPDATE_COMPANY_ERROR');

export const uploadImageToAmazonRequest = createAction('PUT_IMAGE_TO_AWS_REQUEST');
export const uploadImageToAmazonSuccess = createAction('PUT_IMAGE_TO_AWS_SUCCESS');
export const uploadImageToAmazonError = createAction('PUT_IMAGE_TO_AWS_ERROR');

export const getPreSignUrlRequest = createAction('GET_PRESIGN_URL_REQUEST');
export const getPreSignUrlSuccess = createAction('GET_PRESIGN_URL_SUCCESS');
export const getPreSignUrlError = createAction('GET_PRESIGN_URL_ERROR');

export const getAllCompaniesRequest = createAction('GET_ALL_COMPANIES_REQUEST');
export const getAllCompaniesSuccess = createAction('GET_ALL_COMPANIES_SUCCESS');
export const getAllCompaniesError = createAction('GET_ALL_OR_SELECTED_COMPANIES_ERROR');

export const getCompanyByIdRequest = createAction('GET_COMPANY_BY_ID_REQUEST');
export const getCompanyByIdSuccess = createAction('GET_COMPANY_BY_ID_SUCCESS');
export const getCompanyByIdError = createAction('GET_COMPANY_BY_ID_ERROR');

export const getCompanyByIdForCalendarRequest = createAction('GET_CALENDAR_COMPANY_BY_ID_REQUEST');
export const getCompanyByIdForCalendarSuccess = createAction('GET_CALENDAR_COMPANY_BY_ID_SUCCESS');
export const getCompanyByIdForCalendarError = createAction('GET_CALENDAR_COMPANY_BY_ID_ERROR');

export const getCleanCompanyByIdRequest = createAction('GET_CLEAN_COMPANY_BY_ID_REQUEST');
export const getCleanCompanyByIdSuccess = createAction('GET_CLEAN_COMPANY_BY_ID_SUCCESS');
export const getCleanCompanyByIdError = createAction('GET_CLEAN_COMPANY_BY_ID_ERROR');

export const getStaffCompanyAppointmentsRequest = createAction('GET_STAFF_COMPANY_APPOINTMENTS');
export const getStaffCompanyAppointmentsSuccess = createAction('GET_STAFF_COMPANY_APPOINTMENTS_SUCCESS');
export const getStaffCompanyAppointmentsError = createAction('GET_STAFF_COMPANY_APPOINTMENTS_ERROR');

export const deleteCompanyRequest = createAction('DELETE_COMPANY_REQUEST');
export const deleteCompanySuccess = createAction('DELETE_COMPANY_SUCCESS');
export const deleteCompanyError = createAction('DELETE_COMPANY_ERROR');

export const getFilteredCompaniesRequest = createAction('GET_FILTERED_COMPANIES_REQUEST');
export const getFilteredCompaniesSuccess = createAction('GET_FILTERED_COMPANIES_SUCCESS');
export const getFilteredCompaniesError = createAction('GET_FILTERED_COMPANIES_ERROR');

export const storeCustomerToCompanyRequest = createAction('STORE_CUSTOMER_TO_COMPANY_REQUEST');
export const storeCustomerToCompanySuccess = createAction('STORE_CUSTOMER_TO_COMPANY_SUCCESS');
export const storeCustomerToCompanyError = createAction('STORE_CUSTOMER_TO_COMPANY_ERROR');

export const inviteCustomerToCompanyRequest = createAction('INVITE_CUSTOMER_TO_COMPANY_REQUEST');
export const inviteCustomerToCompanySuccess = createAction('INVITE_CUSTOMER_TO_COMPANY_SUCCESS');
export const inviteCustomerToCompanyError = createAction('INVITE_CUSTOMER_TO_COMPANY_ERROR');

export const leaveCompanyRequest = createAction('LEAVE_COMPANY_REQUEST');
export const leaveCompanySuccess = createAction('LEAVE_COMPANY_SUCCESS');
export const leaveCompanyError = createAction('LEAVE_COMPANY_ERROR');

export const getPersonalCompaniesRequest = createAction('GET_PERSONAL_COMPANIES_REQUEST');
export const getPersonalCompaniesSuccess = createAction('GET_PERSONAL_COMPANIES_SUCCESS');
export const getPersonalCompaniesError = createAction('GET_PERSONAL_COMPANIES_ERROR');

export const getStaffWithServicesRequest = createAction('GET_STAFF_WITH_SERVICES_REQUEST');
export const getStaffWithServicesSuccess = createAction('GET_STAFF_WITH_SERVICES_SUCCESS');
export const getStaffWithServicesError = createAction('GET_STAFF_WITH_SERVICES_ERROR');

export const staffDayOffRequest = createAction('STAFF_DAY_OFF_REQUEST');
export const staffDayOffSuccess = createAction('STAFF_DAY_OFF_SUCCESS');
export const staffDayOffError = createAction('STAFF_DAY_OFF_ERROR');

export const updateStaffDayOffRequest = createAction('UPDATE_STAFF_DAY_OFF_REQUEST');
export const updateStaffDayOffSuccess = createAction('UPDATE_STAFF_DAY_OFF_SUCCESS');
export const updateStaffDayOffError = createAction('UPDATE_STAFF_DAY_OFF_ERROR');

export const deleteStaffDayOffRequest = createAction('DELETE_STAFF_DAY_OFF_REQUEST');
export const deleteStaffDayOffSuccess = createAction('DELETE_STAFF_DAY_OFF_SUCCESS');
export const deleteStaffDayOffError = createAction('DELETE_STAFF_DAY_OFF_ERROR');

export const staffSpecialDayRequest = createAction('STAFF_SPECIAL_DAY_REQUEST');
export const staffSpecialDaySuccess = createAction('STAFF_SPECIAL_DAY_SUCCESS');
export const staffSpecialDayError = createAction('STAFF_SPECIAL_DAY_ERROR');

export const updateStaffSpecialDayRequest = createAction('UPDATE_STAFF_SPECIAL_DAY_REQUEST');
export const updateStaffSpecialDaySuccess = createAction('UPDATE_STAFF_SPECIAL_DAY_SUCCESS');
export const updateStaffSpecialDayError = createAction('UPDATE_STAFF_SPECIAL_DAY_ERROR');

export const deleteStaffSpecialDayRequest = createAction('DELETE_STAFF_SPECIAL_DAY_REQUEST');
export const deleteStaffSpecialDaySuccess = createAction('DELETE_STAFF_SPECIAL_DAY_SUCCESS');
export const deleteStaffSpecialDayError = createAction('DELETE_STAFF_SPECIAL_DAY_ERROR');

export const closeDayRequest = createAction('CLOSE_DAY_REQUEST');
export const closeDaySuccess = createAction('CLOSE_DAY_SUCCESS');
export const closeDayError = createAction('CLOSE_DAY_ERROR');

export const updateCloseDayRequest = createAction('UPDATE_CLOSE_DAY_REQUEST');
export const updateCloseDaySuccess = createAction('UPDATE_CLOSE_DAY_SUCCESS');
export const updateCloseDayError = createAction('UPDATE_CLOSE_DAY_ERROR');

export const deleteCloseDayRequest = createAction('DELETE_CLOSE_DAY_REQUEST');
export const deleteCloseDaySuccess = createAction('DELETE_CLOSE_DAY_SUCCESS');
export const deleteCloseDayError = createAction('DELETE_CLOSE_DAY_ERROR');

export const specialDayRequest = createAction('SPECIAL_DAY_REQUEST');
export const specialDaySuccess = createAction('SPECIAL_DAY_SUCCESS');
export const specialDayError = createAction('SPECIAL_DAY_ERROR');

export const updateSpecialDayRequest = createAction('UPDATE_SPECIAL_DAY_REQUEST');
export const updateSpecialDaySuccess = createAction('UPDATE_SPECIAL_DAY_SUCCESS');
export const updateSpecialDayError = createAction('UPDATE_SPECIAL_DAY_ERROR');

export const deleteSpecialDayRequest = createAction('DELETE_SPECIAL_DAY_REQUEST');
export const deleteSpecialDaySuccess = createAction('DELETE_SPECIAL_DAY_SUCCESS');
export const deleteSpecialDayError = createAction('DELETE_SPECIAL_DAY_ERROR');

export const staffSpecialGraphicRequest = createAction('STAFF_SPECIAL_GRAPHIC_REQUEST');
export const staffSpecialGraphicSuccess = createAction('STAFF_SPECIAL_GRAPHIC_SUCCESS');
export const staffSpecialGraphicError = createAction('STAFF_SPECIAL_GRAPHIC_ERROR');

export const getStaffAvailableDatesRequest = createAction('GET_STAFF_AVAILABLE_DATES_REQUEST');
export const getStaffAvailableDatesSuccess = createAction('GET_STAFF_AVAILABLE_DATES_SUCCESS');
export const getStaffAvailableDatesError = createAction('GET_STAFF_AVAILABLE_DATES_ERROR');

export const listOwnerCompaniesRequest = createAction('LIST_OWNER_COMPANIES_REQUEST');
export const listOwnerCompaniesSuccess = createAction('LIST_OWNER_COMPANIES_SUCCESS');
export const listOwnerCompaniesError = createAction('LIST_OWNER_COMPANIES_ERROR');

export const getOwnerCompanyRequest = createAction('GET_OWNER_COMPANY_REQUEST');
export const getOwnerCompanySuccess = createAction('GET_OWNER_COMPANY_SUCCESS');
export const getOwnerCompanyError = createAction('GET_OWNER_COMPANY_ERROR');

export const getCompanyImagesRequest = createAction('GET_COMPANY_IMAGES_REQUEST_REQUEST');
export const getCompanyImagesSuccess = createAction('GET_COMPANY_IMAGES_REQUEST_SUCCESS');
export const getCompanyImagesError = createAction('GET_COMPANY_IMAGES_REQUEST_ERROR');

export const getCompanyCalendarRequest = createAction('GET_COMPANY_CALENDAR_REQUEST');
export const getCompanyCalendarSuccess = createAction('GET_COMPANY_CALENDAR_SUCCESS');
export const getCompanyCalendarError = createAction('GET_COMPANY_CALENDAR_ERROR');

export const getCompanyWorkingScheduleRequest = createAction('GET_COMPANY_WORKING_SCHEDULE_REQUEST');
export const getCompanyWorkingScheduleSuccess = createAction('GET_COMPANY_WORKING_SCHEDULE_SUCCESS');
export const getCompanyWorkingScheduleError = createAction('GET_COMPANY_WORKING_SCHEDULE_ERROR');

export const getCompanyRequest = createAction('GET_COMPANY_REQUEST');
export const getCompanySuccess = createAction('GET_COMPANY_SUCCESS');
export const getCompanyError = createAction('GET_COMPANY_ERROR');
