import { handleActions } from 'redux-actions';
import {
  getCompanyCustomersRequest,
  getCompanyCustomersSuccess,
  getCompanyCustomersError,
  getAllCompanyWithIndustriesRequest,
  getAllCompanyWithIndustriesSuccess,
  getAllCompanyWithIndustriesError,
  getAllCompanyCustomersRequest,
  getAllCompanyCustomersSuccess,
  getAllCompanyCustomersError,
  getAllCustomerInvitationsRequest,
  getAllCustomerInvitationsSuccess,
  getAllCustomerInvitationsError,
  getIndividualCustomersRequest,
  getIndividualCustomersSuccess,
  getIndividualCustomersError,
  acceptInvitationRequest,
  acceptInvitationSuccess,
  acceptInvitationError,
  deleteCustomerRequest,
  deleteCustomerSuccess,
  deleteCustomerError,
  deleteIndividualCustomerRequest,
  deleteIndividualCustomerSuccess,
  deleteIndividualCustomerError,
  deleteInvitationRequest,
  deleteInvitationSuccess,
  deleteInvitationError,
  deleteIndividualUserInvitationRequest,
  deleteIndividualUserInvitationSuccess,
  deleteIndividualUserInvitationError,
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerError,
  updateInvitationRequest,
  updateInvitationSuccess,
  updateInvitationError,
  updateIndividualUserInvitationRequest,
  updateIndividualUserInvitationSuccess,
  updateIndividualUserInvitationError,
  getAllIndividualCustomerInvitationsRequest,
  getAllIndividualCustomerInvitationsSuccess,
  getAllIndividualCustomerInvitationsError,
  acceptIndividualCustomerInvitationRequest,
  acceptIndividualCustomerInvitationSuccess,
  acceptIndividualCustomerInvitationError,
  resendCustomerInvitationRequest,
  resendCustomerInvitationSuccess,
  resendCustomerInvitationError,
  filterCustomerByStatusRequest,
  filterCustomerByStatusSuccess,
  filterCustomerByStatusError,
  getStaffAvailableHoursRequest,
  getStaffAvailableHoursSuccess,
  getStaffAvailableHoursError,
  filterCustomerAppointmentsRequest,
  filterCustomerAppointmentsSuccess,
  filterCustomerAppointmentsError,
  filterCustomerAppointmentsByDateRequest,
  filterCustomerAppointmentsByDateSuccess,
  filterCustomerAppointmentsByDateError,
  getCustomerUpcomingAppointmentsRequest,
  getCustomerUpcomingAppointmentsSuccess,
  getCustomerUpcomingAppointmentsError,
  getCustomerAppointmentsHistoryRequest,
  getCustomerAppointmentsHistorySuccess,
  getCustomerAppointmentsHistoryError,
  getAllCompanyCustomersSelectRequest,
  getAllCompanyCustomersSelectSuccess,
  getAllCompanyCustomersSelectError,
} from './actions';

const initialState = {
  isGetAllCustomersSuccess: false,
  isGetAllCustomersError: false,
  allCompanyCustomers: [],
  isGetAllCustomersSelectSuccess: false,
  isGetAllCustomersSelectError: false,
  allCompanyCustomersForSelect: [],
  isGetCustomersSuccess: false,
  isGetCustomersError: false,
  companyCustomers: [],
  isGetAllCustomerInvitationsSuccess: false,
  isGetAllCustomerInvitationsError: false,
  allCompaniesCustomerInvitations: [],
  isGetAllIndividualCustomersSuccess: false,
  isGetAllIndividualCustomersError: false,
  allIndividualCustomers: [],
  isAcceptInvitationSuccess: false,
  isAcceptInvitationError: false,
  invitedUser: {},
  acceptInvitationErrorMessage: '',
  isAcceptIndividualCustomerInvitationSuccess: false,
  isAcceptIndividualCustomerInvitationError: false,
  acceptIndividualCustomerInvitationErrorMessage: '',
  invitedIndividualUser: {},
  isDeleteCustomerSuccess: false,
  isDeleteCustomerError: false,
  deleteCustomerErrorMessage: '',
  isDeleteIndividualCustomerSuccess: false,
  isDeleteIndividualCustomerError: false,
  deleteIndividualCustomerErrorMessage: '',
  isDeleteInvitationSuccess: false,
  isDeleteInvitationError: false,
  deleteInvitationErrorMessage: '',
  isDeleteIndividualUserInvitationSuccess: false,
  isDeleteIndividualUserInvitationError: false,
  deleteIndividualUserInvitationErrorMessage: '',
  isUpdateCustomerSuccess: false,
  isUpdateCustomerError: false,
  updatedCustomer: {},
  updateCustomerErrorMessage: '',
  updateCustomerErrors: {},
  updateCustomerSucceed: {},
  isUpdateInvitationSuccess: false,
  isUpdateInvitationError: false,
  updatedInvitation: {},
  updateInvitationErrorMessage: '',
  updateInvitationErrors: {},
  updateInvitationSucceed: {},
  isUpdateIndividualUserInvitationSuccess: false,
  isUpdateIndividualUserInvitationError: false,
  updatedIndividualUserInvitation: {},
  updateIndividualUserInvitationErrorMessage: '',
  updateIndividualUserInvitationSucceed: {},
  isGetAllIndividualInvitationsSuccess: false,
  isGetAllIndividualInvitationsError: false,
  allIndustriesCustomerInvitations: [],
  isResendCustomerInvitationSuccess: false,
  isResendCustomerInvitationError: false,
  isFilterCustomerByStatusSuccess: false,
  isFilterCustomerByStatusError: false,
  filteredCustomerByStatus: {},
  isGetStaffAvailableHoursSuccess: false,
  isGetStaffAvailableHoursError: false,
  isGetIndividualAvailableHoursError: false,
  isGetIndividualAvailableHoursSuccess: false,
  availableHours: [],
  isFilterCustomerAppointmentsSuccess: false,
  isFilterCustomerAppointmentsError: false,
  filteredCustomerAppointments: [],
  isFilterCustomerAppointmentsByDateSuccess: false,
  isFilterCustomerAppointmentsByDateError: false,
  filteredCustomerAppointmentsByDate: [],
  customerUpcomingAppointments: [],
  customerUpcomingAppointmentsCount: 0,
  isGetCustomerUpcomingAppointmentsSuccess: false,
  isGetCustomerUpcomingAppointmentsError: false,
  getCustomerUpcomingAppointmentsErrorMessage: '',
  customerAppointmentsHistory: [],
  customerAppointmentsHistoryCount: 0,
  isGetCustomerAppointmentsHistorySuccess: false,
  isGetCustomerAppointmentsHistoryError: false,
  getCustomerAppointmentsHistoryErrorMessage: '',
};

const reducer = handleActions(
  {
    [getAllCompanyCustomersRequest]: (state) => ({
      ...state,
      isGetAllCustomersSuccess: false,
      isGetAllCustomersError: false,
    }),
    [getAllCompanyCustomersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllCustomersSuccess: true,
      isGetAllCustomersError: false,
      allCompanyCustomers: payload.data,
      allCompanyCustomersCount: payload.total,
    }
    ),
    [getAllCompanyCustomersError]: (state) => ({
      ...state,
      isGetAllCustomersSuccess: false,
      isGetAllCustomersError: true,
      allCompanyCustomers: [],
    }),
    [getAllCompanyCustomersSelectRequest]: (state) => ({
      ...state,
      isGetAllCustomersSelectSuccess: false,
      isGetAllCustomersSelectError: false,
    }),
    [getAllCompanyCustomersSelectSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllCustomersSelectSuccess: true,
      isGetAllCustomersSelectError: false,
      allCompanyCustomersForSelect: payload.data,
    }
    ),
    [getAllCompanyCustomersSelectError]: (state) => ({
      ...state,
      isGetAllCustomersSelectSuccess: false,
      isGetAllCustomersSelectError: true,
      allCompanyCustomersForSelect: [],
    }),
    [getAllCompanyWithIndustriesRequest]: (state) => ({
      ...state,
      isGetAllCompanyIndustriesSuccess: false,
      isGetAllCompanyIndustriesError: false,
      allCompanyIndustries: [],
    }),
    [getAllCompanyWithIndustriesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllCompanyIndustriesSuccess: true,
      isGetAllCompanyIndustriesError: false,
      allCompanyIndustries: payload,
    }),
    [getAllCompanyWithIndustriesError]: (state) => ({
      ...state,
      isGetAllCompanyIndustriesSuccess: false,
      isGetAllCompanyIndustriesError: true,
      allCompanyIndustries: [],
    }),
    [getCompanyCustomersRequest]: (state) => ({
      ...state,
      isGetCustomersSuccess: false,
      isGetCustomersError: false,
      companyCustomers: [],
    }),
    [getCompanyCustomersSuccess]: (state, { payload }) => ({
      ...state,
      isGetCustomersSuccess: true,
      isGetCustomersError: false,
      companyCustomers: payload,
    }),
    [getCompanyCustomersError]: (state) => ({
      ...state,
      isGetCustomersSuccess: false,
      isGetCustomersError: true,
      companyCustomers: [],
    }),
    [getAllCustomerInvitationsRequest]: (state) => ({
      ...state,
      isGetAllCustomerInvitationsSuccess: false,
      isGetAllCustomerInvitationsError: false,
      allCompaniesCustomerInvitations: [],
    }),
    [getAllCustomerInvitationsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllCustomerInvitationsSuccess: true,
      isGetAllCustomerInvitationsError: false,
      allCompaniesCustomerInvitations: payload.data,
    }),
    [getAllCustomerInvitationsError]: (state) => ({
      ...state,
      isGetAllCustomerInvitationsSuccess: false,
      isGetAllCustomerInvitationsError: true,
      allCompaniesCustomerInvitations: [],
    }),
    [getIndividualCustomersRequest]: (state) => ({
      ...state,
      isGetAllIndividualCustomersSuccess: false,
      isGetAllIndividualCustomersError: false,
      allIndividualCustomers: [],
    }),
    [getIndividualCustomersSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllIndividualCustomersSuccess: true,
      isGetAllIndividualCustomersError: false,
      allIndividualCustomers: payload,
    }),
    [getIndividualCustomersError]: (state) => ({
      ...state,
      isGetAllIndividualCustomersSuccess: false,
      isGetAllIndividualCustomersError: true,
      allIndividualCustomers: [],
    }),
    [acceptInvitationRequest]: (state) => ({
      ...state,
      isAcceptInvitationSuccess: false,
      isAcceptInvitationError: false,
      acceptInvitationErrorMessage: '',
    }),
    [acceptInvitationSuccess]: (state, { payload }) => ({
      ...state,
      isAcceptInvitationSuccess: true,
      isAcceptInvitationError: false,
      invitedUser: payload.data,
      acceptInvitationErrorMessage: '',
    }),
    [acceptInvitationError]: (state, { payload }) => ({
      ...state,
      isAcceptInvitationSuccess: false,
      isAcceptInvitationError: true,
      acceptInvitationErrorMessage: (
        payload.errors
        && payload.errors.token
        && payload.errors.token[0] ? payload.errors.token[0]
          : (payload.message || 'Something went wrong')
      ),
    }),
    [acceptIndividualCustomerInvitationRequest]: (state) => ({
      ...state,
      isAcceptIndividualCustomerInvitationSuccess: false,
      isAcceptIndividualCustomerInvitationError: false,
      acceptIndividualCustomerInvitationErrorMessage: '',
      invitedIndividualUser: {},
    }),
    [acceptIndividualCustomerInvitationSuccess]: (state, { payload }) => ({
      ...state,
      isAcceptIndividualCustomerInvitationSuccess: true,
      isAcceptIndividualCustomerInvitationError: false,
      acceptIndividualCustomerInvitationErrorMessage: '',
      invitedIndividualUser: payload.data,
    }),
    [acceptIndividualCustomerInvitationError]: (state, { payload }) => ({
      ...state,
      isAcceptIndividualCustomerInvitationSuccess: false,
      isAcceptIndividualCustomerInvitationError: true,
      acceptIndividualCustomerInvitationErrorMessage: payload.message || 'Something went wrong',
      invitedIndividualUser: {},
    }),
    [deleteCustomerRequest]: (state) => ({
      ...state,
      isDeleteCustomerSuccess: false,
      isDeleteCustomerError: false,
      deleteCustomerErrorMessage: '',
    }),
    [deleteCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isDeleteCustomerSuccess: true,
      isDeleteCustomerError: false,
      deleteCustomerErrorMessage: '',
      allCompanyCustomers: payload,
    }),
    [deleteCustomerError]: (state, { payload }) => ({
      ...state,
      isDeleteCustomerSuccess: false,
      isDeleteCustomerError: true,
      deleteCustomerErrorMessage: payload.message,
    }),
    [deleteIndividualCustomerRequest]: (state) => ({
      ...state,
      isDeleteIndividualCustomerSuccess: false,
      isDeleteIndividualCustomerError: false,
      deleteIndividualCustomerErrorMessage: '',
    }),
    [deleteIndividualCustomerSuccess]: (state) => ({
      ...state,
      isDeleteIndividualCustomerSuccess: true,
      isDeleteIndividualCustomerError: false,
      deleteIndividualCustomerErrorMessage: '',
    }),
    [deleteIndividualCustomerError]: (state, { payload }) => ({
      ...state,
      isDeleteIndividualCustomerSuccess: false,
      isDeleteIndividualCustomerError: true,
      deleteIndividualCustomerErrorMessage: payload.message,
    }),
    [deleteInvitationRequest]: (state) => ({
      ...state,
      isDeleteInvitationSuccess: false,
      isDeleteInvitationError: false,
      deleteInvitationErrorMessage: '',
    }),
    [deleteInvitationSuccess]: (state) => ({
      ...state,
      isDeleteInvitationSuccess: true,
      isDeleteInvitationError: false,
      deleteInvitationErrorMessage: '',
    }),
    [deleteInvitationError]: (state, { payload }) => ({
      ...state,
      isDeleteInvitationSuccess: false,
      isDeleteInvitationError: false,
      deleteInvitationErrorMessage: payload.message,
    }),
    [deleteIndividualUserInvitationRequest]: (state) => ({
      ...state,
      isDeleteIndividualUserInvitationSuccess: false,
      isDeleteIndividualUserInvitationError: false,
      deleteIndividualUserInvitationErrorMessage: '',
    }),
    [deleteIndividualUserInvitationSuccess]: (state) => ({
      ...state,
      isDeleteIndividualUserInvitationSuccess: true,
      isDeleteIndividualUserInvitationError: false,
      deleteIndividualUserInvitationErrorMessage: '',
    }),
    [deleteIndividualUserInvitationError]: (state, { payload }) => ({
      ...state,
      isDeleteIndividualUserInvitationSuccess: false,
      isDeleteIndividualUserInvitationError: false,
      deleteIndividualUserInvitationErrorMessage: payload.message,
    }),
    [updateCustomerRequest]: (state) => ({
      ...state,
      isUpdateCustomerSuccess: false,
      isUpdateCustomerError: false,
      updatedCustomer: {},
      updateCustomerSucceed: {},
      updateCustomerErrorMessage: '',
      updateCustomerErrors: {},
    }),
    [updateCustomerSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateCustomerSuccess: true,
      isUpdateCustomerError: false,
      updatedCustomer: payload.data || {},
      updateCustomerErrorMessage: '',
      updateCustomerErrors: {},
      updateCustomerSucceed: payload.data || {},
    }),
    [updateCustomerError]: (state, { payload }) => ({
      ...state,
      isUpdateCustomerSuccess: false,
      isUpdateCustomerError: true,
      updatedCustomer: {},
      updateCustomerErrorMessage: payload.message || 'Something went wrong',
      updateCustomerErrors: payload.errors || {},
      updateCustomerSucceed: {},
    }),
    [updateInvitationRequest]: (state) => ({
      ...state,
      isUpdateInvitationSuccess: false,
      isUpdateInvitationError: false,
      updatedInvitation: {},
      updateInvitationSucceed: {},
      updateInvitationErrorMessage: '',
      updateInvitationErrors: {},
    }),
    [updateInvitationSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateInvitationSuccess: true,
      isUpdateInvitationError: false,
      updatedInvitation: payload.data || {},
      updateInvitationErrorMessage: '',
      updateInvitationErrors: {},
      updateInvitationSucceed: payload.data || {},
    }),
    [updateInvitationError]: (state, { payload }) => ({
      ...state,
      isUpdateInvitationSuccess: false,
      isUpdateInvitationError: true,
      updatedInvitation: {},
      updateInvitationErrorMessage: payload.message || 'Something went wrong',
      updateInvitationErrors: payload.errors || {},
      updateInvitationSucceed: {},
    }),
    [updateIndividualUserInvitationRequest]: (state) => ({
      ...state,
      isUpdateIndividualUserInvitationSuccess: false,
      isUpdateIndividualUserInvitationError: false,
      updatedIndividualUserInvitation: {},
      updateIndividualUserInvitationErrorMessage: '',
    }),
    [updateIndividualUserInvitationSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateIndividualUserInvitationSuccess: true,
      isUpdateIndividualUserInvitationError: false,
      updatedIndividualUserInvitation: payload.data || {},
      updateIndividualUserInvitationErrorMessage: '',
      updateIndividualUserInvitationSucceed: payload.data || {},
    }),
    [updateIndividualUserInvitationError]: (state, { payload }) => ({
      ...state,
      isUpdateIndividualUserInvitationSuccess: false,
      isUpdateIndividualUserInvitationError: true,
      updatedIndividualUserInvitation: {},
      updateIndividualUserInvitationErrorMessage: payload.message || 'Something went wrong',
      updateIndividualUserInvitationSucceed: {},
    }),
    [getAllIndividualCustomerInvitationsRequest]: (state) => ({
      ...state,
      isGetAllIndividualInvitationsSuccess: false,
      isGetAllIndividualInvitationsError: false,
      allIndustriesCustomerInvitations: [],
    }),
    [getAllIndividualCustomerInvitationsSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllIndividualInvitationsSuccess: true,
      isGetAllIndividualInvitationsError: false,
      allIndustriesCustomerInvitations: payload.data,
    }),
    [getAllIndividualCustomerInvitationsError]: (state) => ({
      ...state,
      isGetAllIndividualInvitationsSuccess: false,
      isGetAllIndividualInvitationsError: true,
      allIndustriesCustomerInvitations: [],
    }),
    [resendCustomerInvitationRequest]: (state) => ({
      ...state,
      isResendCustomerInvitationSuccess: false,
      isResendCustomerInvitationError: false,
    }),
    [resendCustomerInvitationSuccess]: (state) => ({
      ...state,
      isResendCustomerInvitationSuccess: true,
      isResendCustomerInvitationError: false,
    }),
    [resendCustomerInvitationError]: (state) => ({
      ...state,
      isResendCustomerInvitationSuccess: false,
      isResendCustomerInvitationError: true,
    }),
    [filterCustomerByStatusRequest]: (state) => ({
      ...state,
      isFilterCustomerByStatusSuccess: false,
      isFilterCustomerByStatusError: false,
    }),
    [filterCustomerByStatusSuccess]: (state, { payload }) => ({
      ...state,
      isFilterCustomerByStatusSuccess: true,
      isFilterCustomerByStatusError: false,
      filteredCustomerByStatus: payload.data,
    }),
    [filterCustomerByStatusError]: (state) => ({
      ...state,
      isFilterCustomerByStatusSuccess: false,
      isFilterCustomerByStatusError: true,
      filteredCustomerByStatus: {},
    }),
    [getStaffAvailableHoursRequest]: (state) => ({
      ...state,
      isGetStaffAvailableHoursSuccess: false,
      isGetStaffAvailableHoursError: false,
      availableHours: [],
    }),
    [getStaffAvailableHoursSuccess]: (state, { payload }) => ({
      ...state,
      isGetStaffAvailableHoursSuccess: true,
      isGetStaffAvailableHoursError: false,
      availableHours: payload,
    }),
    [getStaffAvailableHoursError]: (state) => ({
      ...state,
      isGetStaffAvailableHoursSuccess: false,
      isGetStaffAvailableHoursError: true,
      availableHours: [],
    }),
    [filterCustomerAppointmentsRequest]: (state) => ({
      ...state,
      isFilterCustomerAppointmentsSuccess: false,
      isFilterCustomerAppointmentsError: false,
    }),
    [filterCustomerAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isFilterCustomerAppointmentsSuccess: true,
      isFilterCustomerAppointmentsError: false,
      filteredCustomerAppointments: payload.data,
    }),
    [filterCustomerAppointmentsError]: (state) => ({
      ...state,
      isFilterCustomerAppointmentsSuccess: false,
      isFilterCustomerAppointmentsError: true,
      filteredCustomerAppointments: [],
    }),
    [filterCustomerAppointmentsByDateRequest]: (state) => ({
      ...state,
      isFilterCustomerAppointmentsByDateSuccess: false,
      isFilterCustomerAppointmentsByDateError: false,
    }),
    [filterCustomerAppointmentsByDateSuccess]: (state, { payload }) => ({
      ...state,
      isFilterCustomerAppointmentsByDateSuccess: true,
      isFilterCustomerAppointmentsByDateError: false,
      filteredCustomerAppointmentsByDate: payload.data,
    }),
    [filterCustomerAppointmentsByDateError]: (state) => ({
      ...state,
      isFilterCustomerAppointmentsByDateSuccess: false,
      isFilterCustomerAppointmentsByDateError: true,
      filteredCustomerAppointmentsByDate: [],
    }),
    [getCustomerUpcomingAppointmentsRequest]: (state) => ({
      ...state,
      customerUpcomingAppointments: [],
      customerUpcomingAppointmentsCount: 0,
      isGetCustomerUpcomingAppointmentsSuccess: false,
      isGetCustomerUpcomingAppointmentsError: false,
      getCustomerUpcomingAppointmentsErrorMessage: '',
    }),
    [getCustomerUpcomingAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      customerUpcomingAppointments: payload.data,
      customerUpcomingAppointmentsCount: payload.total,
      isGetCustomerUpcomingAppointmentsSuccess: true,
    }),
    [getCustomerUpcomingAppointmentsError]: (state, { payload }) => ({
      ...state,
      isGetCustomerUpcomingAppointmentsError: true,
      getCustomerUpcomingAppointmentsErrorMessage: payload.message,
    }),
    [getCustomerAppointmentsHistoryRequest]: (state) => ({
      ...state,
      customerAppointmentsHistory: [],
      customerAppointmentsHistoryCount: 0,
      isGetCustomerAppointmentsHistorySuccess: false,
      isGetCustomerAppointmentsHistoryError: false,
      getCustomerAppointmentsHistoryErrorMessage: '',
    }),
    [getCustomerAppointmentsHistorySuccess]: (state, { payload }) => ({
      ...state,
      customerAppointmentsHistory: payload.data,
      customerAppointmentsHistoryCount: payload.total,
      isGetCustomerAppointmentsHistorySuccess: true,
    }),
    [getCustomerAppointmentsHistoryError]: (state, { payload }) => ({
      ...state,
      isGetCustomerAppointmentsHistoryError: true,
      getCustomerAppointmentsHistoryErrorMessage: payload.message,
    }),
  },
  initialState,
);

export default reducer;
