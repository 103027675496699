import React from 'react';
import {
  Switch,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

const PermissionsModal = (props) => {
  const {
    open,
    onClose,
    toUpperCaseEachWord,
    handlePermissionsChange,
    selectedStaffPermissions,
    handleUpdateStaffPermissions,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>
              Edit Permissions
          </span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container>
          {selectedStaffPermissions.length ? selectedStaffPermissions.map((permission) => (
            <Grid container xs={6}>
              <Grid item xs={5}>
                <Typography variant="body1" noWrap>
                  {toUpperCaseEachWord(permission[0])}
                </Typography>
              </Grid>
              <Grid xs={2}>
                <Switch
                  size="small"
                  onChange={(e) => handlePermissionsChange(e)}
                  color="primary"
                  name={permission[0]}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                  checked={permission[1].status}
                />
              </Grid>
            </Grid>
          )) : null}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleUpdateStaffPermissions}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PermissionsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  toUpperCaseEachWord: PropTypes.func.isRequired,
  handlePermissionsChange: PropTypes.func.isRequired,
  selectedStaffPermissions: PropTypes.array.isRequired,
  handleUpdateStaffPermissions: PropTypes.func.isRequired,
};

export default PermissionsModal;
