import React from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Box,
  Typography,
  Grid,
} from '@material-ui/core';
import i18n from 'i18next';
import BreakingHours from './breakingHours';
import WorkingHours from './workingHours';

function WorkingSchedule({ company }) {
  const { workingHours, breakingHours } = company;

  return (
    <Grid container item xs={9} spacing={2} id="working-schedule-content">
      <Grid item xs={3} className="tab-item week-day-switch working-schedule" id="tab-main-parent">
        <Box className="time-zone">
          <span>{i18n.t('CompaniesTab.Tabs.Week days')}</span>
        </Box>
        <Box py="25px" px="17px">
          { workingHours.map((workHour, index) => (
            <div className="hours-content" key={`${workHour.week_day}-${index}`}>
              <Box display="flex" minWidth="173px" className="day-content" key={workHour.week_day_id}>
                <Box minWidth="91px">
                  <div className="day-name">
                    <span data-cy="week-day-name">{ workHour.week_day }</span>
                  </div>
                </Box>
                <Box minWidth="91px">
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>
                        <Switch
                          disabled
                          color="primary"
                          size="small"
                          checked={workHour.is_working}
                          name="is_working"
                        />
                      </Grid>
                      {workHour.is_working ? (
                        <Grid item> <Typography color="inherit" variant="body1"> Open </Typography> </Grid>
                      ) : <Typography color="inherit" variant="body1"> Close </Typography>}
                    </Grid>
                  </Typography>
                </Box>
              </Box>
            </div>
          ))}
        </Box>
      </Grid>
      <Grid item xs={3} className="working-schedule">
        <div className="time-zone">
          <span>{i18n.t('GeneralSchedule.WorkingHours')}</span>
        </div>
        <WorkingHours workingHours={workingHours} />
      </Grid>
      <Grid item xs={3} className="breaking-schedule">
        <div className="time-zone">
          <span>Breaking Hours</span>
        </div>
        <BreakingHours workingHours={workingHours} breakingHours={breakingHours} />
      </Grid>
    </Grid>
  );
}

WorkingSchedule.propTypes = {
  company: PropTypes.object.isRequired,
};

export default WorkingSchedule;
