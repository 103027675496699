import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { connect, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import i18n from 'i18next';
import TextMaskCustom from '../../Modules/TextMaskCustom';
import {
  storeCustomerToCompanyRequest,
  inviteCustomerToCompanyRequest,
} from '../../redux/company/actions';
import {
  deleteCustomerRequest,
  deleteInvitationRequest,
  updateInvitationRequest,
} from '../../redux/customer/actions';
import usePrevious from '../../CustomHooks/usePrevious';
import countryCodeOptions from '../../Modules/countryCodeOptions';
import SnackbarToast from '../../Modules/SnackbarToast';

function CreateCustomer(props) {
  const dispatch = useDispatch();
  const {
    isCreateCustomerModalShow,
    handleCloseCreateCustomerModal,
    isStoreCustomerToCompanySuccess,
    isStoreCustomerToCompanyError,
    storeCustomerToCompanyErrors,
    allCountries,
  } = props;

  // Get some props previous values
  const prevIsStoreCustomerToCompanySuccess = usePrevious(isStoreCustomerToCompanySuccess);
  const prevIsStoreCustomerToCompanyError = usePrevious(isStoreCustomerToCompanyError);

  const [selectedPhoneCode, setSelectedPhoneCode] = useState({ value: null });
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [invitationItem, setInvitationItem] = useState({
    first_name: '',
    last_name: '',
    phone_code: '',
    phone_number: '',
    email: '',
  });
  const [errors, setErrors] = useState({});
  const [invitationFormErrors, setInvitationFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });
  const [isInvitationModalLoading, setIsInvitationModalLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const handleInvitationItemChange = useCallback((event) => {
    const { name, value } = event.target;
    setInvitationItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }, [
    invitationItem,
  ]);

  // Handle get countries
  useEffect(() => {
    if (isCreateCustomerModalShow) {
      const codeOptions = countryCodeOptions.getCodeOptions(allCountries);
      setPhoneCodeOptions(codeOptions);
    }
  }, [isCreateCustomerModalShow]);

  // Handle create customer to company success
  useEffect(() => {
    if (prevIsStoreCustomerToCompanySuccess === false && isStoreCustomerToCompanySuccess) {
      handleCloseCreateCustomerModal();
      setIsInvitationModalLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage(i18n.t('CustomerAdded'));
      setSnackbarType('success');
    }
  }, [isStoreCustomerToCompanySuccess]);

  // Handle create customer to company error
  useEffect(() => {
    if (prevIsStoreCustomerToCompanyError === false && isStoreCustomerToCompanyError) {
      setErrors(storeCustomerToCompanyErrors);
      setIsInvitationModalLoading(false);
    }
  }, [isStoreCustomerToCompanyError]);

  const handleSelectChangeForCode = (event) => {
    const codeId = parseInt(event.target.value);
    const phone = phoneCodeOptions.find((item) => parseInt(item.value) === codeId);
    setSelectedPhoneCode({
      value: phone.value,
    });
  };

  const validateInvitationForm = (e) => {
    const errors = {
      firstName: e ? null : !invitationItem.first_name.length ? 'First name is required.' : null,
      lastName: e ? null : !invitationItem.last_name.length ? 'Last name is required.' : null,
      email: null,
      phone_number: e ? null : (invitationItem.phone_number === '' && !invitationItem.phone_number.length)
        ? 'Phone number is required.' : (invitationItem.phone_number.length < 12) ? 'Phone number is not valid' : null,
      phone_code: e ? null : (selectedPhoneCode && selectedPhoneCode.value === null && invitationItem.phone_code === ''
        && !invitationItem.phone_code.length) ? 'Code is required.' : null,
    };
    setInvitationFormErrors(errors);
    return Object.values(errors)
      .filter((error) => (error !== null)).length === 0;
  };

  const handleCreateCustomerFormSubmit = (checkedLogin) => {
    if (validateInvitationForm(checkedLogin)) {
      setErrors({});
      const data = { ...invitationItem };
      setIsInvitationModalLoading(true);
      data.phone_code = selectedPhoneCode.value;
      data.phone_number = invitationItem.phone_number === '' ? null : invitationItem.phone_number;
      data.email = null;
      data.full_phone_number = (`${data.phone_code} ${data.phone_number}`).replace(/\D/g, '');
      setInvitationFormErrors({
        firstName: false,
        lastName: false,
      });
      dispatch(storeCustomerToCompanyRequest(data));
    }
  };

  const onClose = () => {
    setInvitationItem({
      first_name: '',
      last_name: '',
      phone_code: '',
      phone_number: '',
      email: '',
    });
    setInvitationFormErrors({
      firstName: false,
      lastName: false,
      phone_number: false,
      phone_code: false,
    });

    handleCloseCreateCustomerModal();
  };

  return (
    <>
      <Dialog
        size="sm"
        open={isCreateCustomerModalShow}
        onClose={onClose}
      >
        <DialogTitle>
          <span className="alert-title">
            <span>New Customer</span>
            <span>
              <IconButton aria-label="close" className="close-btn" onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!invitationFormErrors.firstName || !!errors.first_name)}
              >
                <TextField
                  fullWidth
                  error={(!!invitationFormErrors.firstName || !!errors.first_name)}
                  size="small"
                  label="First name *"
                  data-cy="firstName"
                  data-customer="first_name"
                  variant="outlined"
                  type="text"
                  name="first_name"
                  value={invitationItem.first_name}
                  onChange={handleInvitationItemChange}
                />

                {(invitationFormErrors.firstName || errors.first_name) && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {invitationFormErrors.firstName || errors.first_name}
                  </FormHelperText>
                </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!invitationFormErrors.lastName || !!errors.last_name)}
              >
                <TextField
                  fullWidth
                  error={(!!invitationFormErrors.lastName || !!errors.last_name)}
                  size="small"
                  label="Last name *"
                  data-cy="lastName"
                  data-customer="last_name"
                  id="outlined-size-normal"
                  variant="outlined"
                  type="text"
                  name="last_name"
                  value={invitationItem.last_name}
                  onChange={handleInvitationItemChange}
                />
                { (invitationFormErrors.lastName || errors.last_name) && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {invitationFormErrors.lastName || errors.last_name}
                  </FormHelperText>
                </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!invitationFormErrors.phone_code || !!errors.phone_code)}
              >
                <InputLabel>Country code *</InputLabel>
                <Select
                  name="phone_code"
                  label="Country code *"
                  onChange={(event) => {
                    handleSelectChangeForCode(event);
                  }}
                  value={selectedPhoneCode.value || invitationItem.phone_code}
                >
                  <MenuItem disabled value=""><em>{i18n.t('PhoneCode')}</em></MenuItem>
                  {phoneCodeOptions && phoneCodeOptions.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.value}
                      selected={selectedPhoneCode.value === option.value}
                    >
                      <img
                        src={option.label.flagImageSrc}
                        alt="flag"
                        className="country-flag"
                      />
                      {option.key}
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>
                {invitationFormErrors.phone_code && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {invitationFormErrors.phone_code}
                  </FormHelperText>
                </div>
                )}
                {errors.phone_code && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {errors.phone_code}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!invitationFormErrors.phone_number || !!errors.phone_number)}
              >
                <TextField
                  fullWidth
                  error={(!!invitationFormErrors.phone_number || !!errors.phone_number)}
                  size="small"
                  data-cy="phone"
                  id="phone-detail"
                  data-customer="phone_number"
                  type="text"
                  name="phone_number"
                  value={invitationItem.phone_number || ''}
                  onChange={handleInvitationItemChange}
                  label="Phone number *"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />
                {invitationFormErrors.phone_number && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {invitationFormErrors.phone_number}
                  </FormHelperText>
                </div>
                )}
                {errors.phone_number && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {errors.phone_number}
                  </FormHelperText>
                </div>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            onClick={() => handleCreateCustomerFormSubmit(false)}
            loading={isInvitationModalLoading}
          >
            {isInvitationModalLoading && <CircularProgress color="white" size={20} />}
            {!isInvitationModalLoading && 'Add'}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

CreateCustomer.propTypes = {
  isCreateCustomerModalShow: PropTypes.bool.isRequired,
  handleCloseCreateCustomerModal: PropTypes.func.isRequired,
  // Get Countries
  allCountries: PropTypes.array.isRequired,
  // Add new Customer
  isStoreCustomerToCompanyError: PropTypes.bool.isRequired,
  storeCustomerToCompanyErrors: PropTypes.object.isRequired,
  isStoreCustomerToCompanySuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  // Create customer to company
  isStoreCustomerToCompanySuccess: state.company.isStoreCustomerToCompanySuccess,
  isStoreCustomerToCompanyError: state.company.isStoreCustomerToCompanyError,
  storeCustomerToCompanyErrors: state.company.storeCustomerToCompanyErrors,
  newCustomerSucceed: state.company.newCustomerSucceed,
  // Delete customer
  isDeleteCustomerSuccess: state.customers.isDeleteCustomerSuccess,
  isDeleteCustomerError: state.customers.isDeleteCustomerError,
  deleteCustomerErrorMessage: state.customers.deleteCustomerErrorMessage,
  // Get Countries
  allCountries: state.country.allCountries,
});

function mapDispatchToProps(dispatch) {
  return {
    // storeCustomerToCompany: (data) => dispatch(storeCustomerToCompanyRequest(data)),
    inviteCustomerToCompany: (data) => dispatch(inviteCustomerToCompanyRequest(data)),
    deleteCustomer: (data) => dispatch(deleteCustomerRequest(data)),
    deleteInvitation: (data) => dispatch(deleteInvitationRequest(data)),
    updateInvitation: (data) => dispatch(updateInvitationRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCustomer);
