import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card,
  ListItemText,
  CardHeader,
  CardMedia,
  CardContent,
  List,
  ListItem,
  Box,
  Typography,
} from '@material-ui/core';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import {
  getIndustryServicesRequest,
} from '../../../../../../../redux/service/actions';
import {
  getIndustriesRequest,
} from '../../../../../../../redux/industry/actions';
import {
  getStaffWithServicesRequest,
  updateCompanyRequest,
} from '../../../../../../../redux/company/actions';
import serviceDefault from '../../../../../../../assets/img/service-default.svg';
import noService from '../../../../../../../assets/img/no-service.svg';
import LightTooltip from '../../../../../../../MaterialComponents/lightTooltip';

function Services(props) {
  const {
    company,
    isGetStaffWithServicesSuccess,
    getStaffWithServices,
    staffWithServices,
  } = props;

  const [serviceOptions, setServiceOptions] = useState([]);

  useEffect(() => {
    getStaffWithServices({ companyId: company.id });
  }, []);

  useEffect(() => {
    if (isGetStaffWithServicesSuccess && staffWithServices.length) {
      let services = [];
      staffWithServices.map((item) => {
        services = [...services, ...item.services];
      });
      setServiceOptions(services);
    }
  }, [isGetStaffWithServicesSuccess]);

  return (
    <Box px="10px" py="10px">
      <Box display="flex" flexWrap="wrap" justifyContent="flex-start" width="100%">
        { serviceOptions.length > 0 ? serviceOptions.map((services) => (
          <Box className="card-boxes">
            <Card className="card-box-root">
              <CardHeader
                className="card-header-service"
                action={(
                  <Box height="48px" />
                )}
                title={(
                  <LightTooltip title={services.service.name} placement="top-start">
                    <span>
                      {services.service.name}
                    </span>
                  </LightTooltip>
                )}
              />
              <CardMedia className="img-header">
                <img className="card-box-img" src={serviceDefault} alt="serviceLogo" />
              </CardMedia>
              <CardContent className="content-card-box">
                <List disablePadding>
                  <ListItem disableGutters dense>
                    <ListItemText className="icon-card-box">
                      <QueryBuilderIcon fontSize="small" />
                    </ListItemText>
                    <ListItemText primary="Duration:" className="details-card-box detail-name" />
                    <ListItemText secondary="10 min" className="details-card-box" />
                  </ListItem>
                  <ListItem disableGutters dense>
                    <ListItemText className="icon-card-box">
                      <AttachMoneyIcon fontSize="small" />
                    </ListItemText>
                    <ListItemText primary="Price:" className="details-card-box detail-name" />
                    <ListItemText secondary="5$" className="details-card-box" />
                  </ListItem>
                </List>
              </CardContent>
            </Card>
          </Box>
        )) : (
          <Box className="service-page" mx="auto">
            <Box>
              <Box>
                <img src={noService} alt="noCustomer" />
              </Box>
              <Typography component="div">
                <Box fontWeight="500" align="center">
                  <Typography variant="h6" color="secondary">
                    No services yet
                  </Typography>
                </Box>
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}

Services.propTypes = {
  // Get Company
  company: PropTypes.object.isRequired,
  isGetStaffWithServicesSuccess: PropTypes.bool.isRequired,
  getStaffWithServices: PropTypes.func.isRequired,
  staffWithServices: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  // Get Industries
  industries: state.industry.industries,
  isGetIndustriesSuccess: state.industry.isGetIndustriesSuccess,
  isGetIndustriesError: state.industry.isGetIndustriesError,
  // Get Industry Services
  isGetIndustryServicesSuccess: state.service.isGetIndustryServicesSuccess,
  isGetIndustryServicesError: state.service.isGetIndustryServicesError,
  services: state.service.services,
  // Update Company
  isUpdateCompanySuccess: state.company.isUpdateCompanySuccess,
  updateCompanyErrors: state.company.updateCompanyErrors,
  isUpdateCompanyError: state.company.isUpdateCompanyError,
  updatedCompany: state.company.updatedCompany,
  // User Account
  userAccount: state.account.userAccount,
  isGetStaffWithServicesSuccess: state.company.isGetStaffWithServicesSuccess,
  isGetStaffWithServicesError: state.company.isGetStaffWithServicesError,
  staffWithServices: state.company.staffWithServices,
  getStaffWithServicesErrorMessage: state.company.getStaffWithServicesErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    updateCompany: (data) => dispatch(updateCompanyRequest(data)),
    getIndustries: (data) => dispatch(getIndustriesRequest(data)),
    getIndustryServices: (data) => dispatch(getIndustryServicesRequest(data)),
    getStaffWithServices: (data) => dispatch(getStaffWithServicesRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
