import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Paper,
  AppBar,
  TableCell,
  TextField,
  Box,
  Grid,
  Table,
  Button,
  Toolbar,
  TableRow,
  TableBody,
  TableHead,
  IconButton,
  FormControl,
  MenuItem,
  Typography,
  TableContainer,
  FormHelperText,
  TablePagination,
} from '@material-ui/core';
import {
  TuneRounded as TuneRoundedIcon,
  Edit as EditIcon,
  DeleteOutline as DeleteOutlineIcon,
  Error as ErrorIcon,
} from '@material-ui/icons';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import usePrevious from '../../../../../CustomHooks/usePrevious';
import capitalize from '../../../../../Modules/capitalize';
import handleSortingAppointments from '../../../../../Modules/sortingAppointments';
import '../../../../../style/appointments.scss';
import FilterContent from './FilterContent';
import filterItems from '../../../../../Modules/AppointmentsFilterItems/personalAppointments';
import AppointmentPreview from '../../../../../Modals/AppointmentPreview';
import noAppointment from '../../../../../assets/img/no-appointment.svg';
import SnackbarToast from '../../../../../Modules/SnackbarToast';
import AppointmentsLoading from '../../../../../Components/Loading/appointmentsLoading';
import {
  acceptRequest,
  cancelRequest,
  finishRequest,
  deleteAppointmentRequest,
  updateAppointmentRequest,
  storeAppointmentRequest,
  filterAllIndividualAppointmentsRequest,
} from '../../../../../redux/appointment/actions';
import PermissionsModule from '../../../../../Modules/Permission';
import {
  getIndividualCustomersRequest,
} from '../../../../../redux/customer/actions';
import {
  getIndividualAvailableHoursRequest,
  getIndividualUserIndustriesRequest,
  getIndividualUserSettingsRequest,
  storeCustomerToIndustryRequest,
} from '../../../../../redux/individualUser/actions';
import CreateOrUpdateAppointmentModal from '../../../../../Modals/IndividualUser/Appointments/createOrUpdateAppointment';
import AddCustomerModal from '../../../../../Modals/IndividualUser/Customer/addCustomerModal';
import countryCodeOptions from '../../../../../Modules/countryCodeOptions';
import DeleteAppointmentModal from '../../../../../Modals/IndividualUser/DeleteAppointmentModal';
import EditAppointmentModal from '../../../../../Modals/IndividualUser/EditAppointmentModal';
import { getServicesByIndividualUserIdRequest } from '../../../../../redux/service/actions';
import SelectStatus from '../../Owner/MyAppointments/SelectStatus';

const useStyles = makeStyles({
  table: {
    minWidth: 1550,
  },
  paginationTable: {
    marginTop: 15,
  },
  itemsImg: {
    width: 30,
    height: 30,
    verticalAlign: 'middle',
    marginRight: 10,
    borderRadius: '50%',
    objectFit: 'cover',
  },
  avatarAppointments: {
    width: 30,
    height: 30,
    marginRight: 10,
  },
  avatarLettersAppointments: {
    width: 30,
    height: 30,
    marginRight: 10,
    fontSize: 13,
  },
  tableCell: {
    cursor: 'pointer',
  },
});

function MyAppointments(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isGetIndividualUserIndustriesSuccess,
    isGetIndividualUserIndustriesError,
    allIndividualUserIndustries,
    newCustomerForIndustry,
    isStoreCustomerToIndustrySuccess,
    isIndividualAvailableHoursSuccess,
    isIndividualAvailableHoursError,
    individualAvailableHours,
    isGetIndividualUserSettingsSuccess,
    isGetIndividualUserSettingsError,
    getIndividualUserSettingsErrorMessage,
    individualUserSettings,
  } = useSelector((state) => state.individualUser);

  const {
    isAcceptSuccess,
    isCancelSuccess,
    isFinishSuccess,
    allAppointments,
    isGetAppointmentsError,
    isGetAppointmentsSuccess,
    isUpdateAppointmentSuccess,
    updatedAppointment,
    newAppointment,
    isStoreAppointmentError,
    isStoreAppointmentSuccess,
    storeAppointmentErrorMessage,
    isFilterIndividualAppointmentsSuccess,
    isFilterIndividualAppointmentsError,
    filteredIndividualAppointments,
    isDeleteAppointmentSuccess,
    isDeleteAppointmentError,
    deleteAppointmentErrorMessage,
  } = useSelector((state) => state.appointment);

  const {
    isGetAllIndividualCustomersSuccess,
    isGetAllIndividualCustomersError,
    allIndividualCustomers,
  } = useSelector((state) => state.customers);

  const {
    allCountries,
  } = useSelector((state) => state.country);

  const {
    servicesByIndividualUserId,
    isGetServicesByIndividualUserIdSuccess,
  } = useSelector((state) => state.service);

  const { userAccount } = useSelector((state) => state.account);

  const { history } = props;

  // Get some props previous values
  const prevIsAcceptSuccess = usePrevious(isAcceptSuccess);
  const prevIsCancelSuccess = usePrevious(isCancelSuccess);
  const prevIsFinishSuccess = usePrevious(isFinishSuccess);
  const prevIsGetAppointmentsError = usePrevious(isGetAppointmentsError);
  const prevIsGetAppointmentsSuccess = usePrevious(isGetAppointmentsSuccess);
  const prevIsDeleteAppointmentError = usePrevious(isDeleteAppointmentError);
  const prevIsDeleteAppointmentSuccess = usePrevious(isDeleteAppointmentSuccess);
  const prevIsStoreAppointmentSuccess = usePrevious(isStoreAppointmentSuccess);
  const prevIsStoreAppointmentError = usePrevious(isStoreAppointmentError);
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);
  const prevIsIndividualAvailableHoursError = usePrevious(isIndividualAvailableHoursError);
  const prevIsIndividualAvailableHoursSuccess = usePrevious(isIndividualAvailableHoursSuccess);
  const prevIsStoreCustomerToIndustrySuccess = usePrevious(isStoreCustomerToIndustrySuccess);
  const prevIsGetIndividualUserIndustriesError = usePrevious(isGetIndividualUserIndustriesError);
  const prevIsGetAllIndividualCustomersSuccess = usePrevious(isGetAllIndividualCustomersSuccess);
  const prevIsGetIndividualUserIndustriesSuccess = usePrevious(isGetIndividualUserIndustriesSuccess);
  const prevIsGetServicesByIndividualUserIdSuccess = usePrevious(isGetServicesByIndividualUserIdSuccess);
  const prevIsFilterIndividualAppointmentsSuccess = usePrevious(isFilterIndividualAppointmentsSuccess);
  const prevIsFilterIndividualAppointmentsError = usePrevious(isFilterIndividualAppointmentsError);
  const prevIsGetIndividualUserSettingsSuccess = usePrevious(isGetIndividualUserSettingsSuccess);
  const prevIsGetIndividualUserSettingsError = usePrevious(isGetIndividualUserSettingsError);

  const TODAY = moment().format('YYYY-MM-DD');
  const TOMORROW = moment().add(1, 'days').format('YYYY-MM-DD');

  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState(false);
  const [tableHeader, setTableHeader] = useState();
  const [page, setPage] = React.useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [snackbarType, setSnackbarType] = useState('');
  const [tableLoading, setTableLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(8);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isPreviewShown, setIsPreviewShown] = useState(false);
  const [isCreateOrUpdateAppointmentPerformed, setIsCreateOrUpdateAppointmentPerformed] = useState(false);
  const [appointmentItem] = useState({});
  const [checkedItems, setCheckedItems] = useState({
    status: [1, 2, 3, 4],
    payment: ['paid', 'unpaid'],
  });
  const [appointmentToDeleteId, setAppointmentToDeleteId] = useState(null);
  const [isDeleteAppointmentModalShown, setIsDeleteAppointmentModalShown] = useState(false);
  const [currentDay, setCurrentDay] = useState({
    start_time: '00:00',
    end_time: '24:00',
  });
  const [selectedAppointment, setSelectedAppointment] = useState({});
  const [individualUsers, setIndividualUsers] = useState([]);
  const [individualUsersOptions, setIndividualUsersOptions] = useState([]);
  const [isCreateCustomerModalShow, setIsCreateCustomerModalShow] = useState(false);
  const [customerItem, setCustomerItem] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone_code: '',
    phone_number: '',
    individual_user_industry_id: '',
  });
  const [customerIndustry, setCustomerIndustry] = useState({
    first_name: '',
    last_name: '',
    email: '',
    industry: { value: null },
    phone_code: { value: null },
    phone_number: null,
  });
  const [checkedLogin, setCheckedLogin] = useState(false);
  const [validationErrors, setValidationError] = useState({});
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [fromModal, setFromModal] = useState(false);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState({
    value: null,
  });
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [allIndustryCustomers, setAllIndustryCustomers] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [pendingValue, setPendingValue] = useState({});
  const [availableTime, setAvailableTime] = useState([]);
  const [selectedHour, setSelectedHour] = useState('');
  const [serviceOption, setServiceOption] = useState({});
  const [errors, setErrors] = useState({});
  const [options, setOptions] = useState({});
  const [settings, setSettings] = useState({});
  const [acceptedAppointmentId, setAcceptedAppointmentId] = useState();
  const [cancelledAppointmentId, setCancelledAppointmentId] = useState();
  const [finishedAppointmentId, setFinishedAppointmentId] = useState();
  const filterData = filterItems;

  const classes = useStyles();

  // When component gets mounted
  useEffect(() => {
    const tableHeaderItems = [
      'Date/Time',
      'Duration',
      'Customer',
      'Service',
      'Price($)',
      'Status',
      'Actions',
    ];
    setTableHeader(tableHeaderItems);
    dispatch(getIndividualCustomersRequest());
    dispatch(getIndividualUserSettingsRequest());
    dispatch(getIndividualUserIndustriesRequest());
    const phoneCodeOptionsCopy = countryCodeOptions.getCodeOptions(allCountries);
    setPhoneCodeOptions(phoneCodeOptionsCopy);
  }, []);

  // Handle get individual users success
  useEffect(() => {
    if (prevIsGetIndividualUserIndustriesSuccess === false && isGetIndividualUserIndustriesSuccess) {
      const individualUsersCopy = [...allIndividualUserIndustries];
      const individualUsersOptions = [];
      individualUsersCopy.map((item) => {
        individualUsersOptions.push({
          value: item.id,
          label: item.industry_name,
          industryId: item.industry_id,
        });
      });
      setIndividualUsers(individualUsersCopy);
      setIndividualUsersOptions(individualUsersOptions);

      const uniqueIndustry = [...new Map(individualUsersCopy.map((item) => [item['id'], item])).values()];
      const industryId = uniqueIndustry.map((item) => item.id);
      const currentDate = moment().format('YYYY-MM-DD');
      const endDate = moment().add(6, 'months').format('YYYY-MM-DD');

      const checkedData = {
        start_date: currentDate,
        end_date: endDate,
        individual_user_industry_ids: industryId,
        searchValue: '',
        status_ids: checkedItems.status,
      };

      dispatch(filterAllIndividualAppointmentsRequest(checkedData));
    }
  }, [isGetIndividualUserIndustriesSuccess]);

  // Handle get individual users error
  useEffect(() => {
    if (prevIsGetIndividualUserIndustriesError === false && isGetIndividualUserIndustriesError) {
      snackBarAlert(true, t('SomethingWrong'), 'error');
    }
  }, [isGetIndividualUserIndustriesError]);

  // Perform, when get individual customers finished
  useEffect(() => {
    if (prevIsGetAllIndividualCustomersSuccess === false && isGetAllIndividualCustomersSuccess) {
      const customerOptions = [];
      allIndividualCustomers.map((customer) => customerOptions.push({
        value: customer.id,
        label: `${customer.first_name} ${customer.last_name}`,
        phone: customer.full_phone_number,
      }));
      setCustomerOptions(customerOptions);
    }
  }, [isGetAllIndividualCustomersSuccess, isGetAllIndividualCustomersError]);

  // Get appointments Success
  useEffect(() => {
    if (prevIsGetAppointmentsSuccess === false && isGetAppointmentsSuccess) {
      const filteredAllAppointmentsCopy = [...allAppointments];
      const filteredApp = filteredAllAppointmentsCopy.filter((item) => item.status_id <= 2);
      const sortedAppointment = handleSortingAppointments(filteredApp);
      setAppointments(sortedAppointment);
      setTableLoading(true);
      setAnchorEl(null);
      setPage(0);
    }
  }, [isGetAppointmentsSuccess]);

  // Get appointments Error
  useEffect(() => {
    if (prevIsGetAppointmentsError === false && isGetAppointmentsError) {
      snackBarAlert(true, t('SomethingWrong'), 'error');
      history.push('/calendar');
    }
  }, [isGetAppointmentsError]);

  // Appointment deleted success
  useEffect(() => {
    if (prevIsDeleteAppointmentSuccess === false && isDeleteAppointmentSuccess) {
      const appointmentIndex = appointments.findIndex((item) => item.id === appointmentToDeleteId);
      const newAppointments = [...appointments];
      newAppointments.splice(appointmentIndex, 1);
      setIsDeleteAppointmentModalShown(false);
      setAppointmentToDeleteId(null);
      snackBarAlert(true, t('AppointmentDeleted'), 'success');
      setAppointments(newAppointments);
    }
  }, [isDeleteAppointmentSuccess]);

  // Appointments delete error
  useEffect(() => {
    if (prevIsDeleteAppointmentError === false && isDeleteAppointmentError) {
      setIsDeleteAppointmentModalShown(false);
      setAppointmentToDeleteId(null);
      snackBarAlert(true, deleteAppointmentErrorMessage, 'error');
    }
  }, [isDeleteAppointmentError]);

  // Handle Store Appointment Success
  useEffect(() => {
    if (prevIsStoreAppointmentSuccess === false && isStoreAppointmentSuccess) {
      const industriesCopy = [...individualUsers];
      const newAppointmentCopy = { ...newAppointment };
      const industryIndex = industriesCopy.findIndex((item) => item.id === newAppointment.individual_user_industry_id);
      industriesCopy[industryIndex].appointments.push(newAppointment);
      appointments.push(newAppointmentCopy);
      setAppointments(appointments);
      updateIndustries(industriesCopy);
      handleSortingAppointments(appointments);
      snackBarAlert(true, t('AppointmentCreated'), 'success');
    }
  }, [isStoreAppointmentSuccess]);

  // Handle Store Appointment Error
  useEffect(() => {
    if (prevIsStoreAppointmentError === false && isStoreAppointmentError) {
      snackBarAlert(true, storeAppointmentErrorMessage, 'error');
    }
  }, [isStoreAppointmentError]);

  // Handle create customer to Industry success
  useEffect(() => {
    if (prevIsStoreCustomerToIndustrySuccess === false && isStoreCustomerToIndustrySuccess) {
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      setAllIndustryCustomers(allIndustryCustomersCopy);
      const [chosenIndustry] = allIndividualUserIndustries.filter((item) => item.id === customerItem.individual_user_industry_id);
      allIndustryCustomersCopy.push({
        ...newCustomerForIndustry,
        individual_user_customer_invitation: [],
        pivot: {
          individual_user_industry_id: chosenIndustry.id,
        },
      });
      allIndustryCustomersCopy.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllIndustryCustomers(allIndustryCustomersCopy);
      chosenIndustry.customers.push({
        ...newCustomerForIndustry,
        individual_user_customer_invitation: [],
        pivot: {
          individual_user_industry_id: chosenIndustry.id,
        },
      });
      setCustomerItem({
        email: '',
        first_name: '',
        last_name: '',
        phone_code: '',
        phone_number: '',
        individual_user_industry_id: '',
      });
      setCustomerIndustry({
        ...customerIndustry,
        industry: { value: null },
      });
      handleCloseCreateCustomerModal();
      snackBarAlert(true, 'Added to customer successfully', 'success');
    }
  }, [isStoreCustomerToIndustrySuccess]);

  // Handle get available hours
  useEffect(() => {
    if (prevIsIndividualAvailableHoursSuccess === false && isIndividualAvailableHoursSuccess) {
      const individualAvailableHoursCopy = [...individualAvailableHours];
      setAvailableTime(individualAvailableHoursCopy);
    }
  }, [isIndividualAvailableHoursSuccess]);

  // Handle servicesWithSubServices {
  useEffect(() => {
    if (prevIsGetServicesByIndividualUserIdSuccess === false && isGetServicesByIndividualUserIdSuccess) {
      const servicesByIndividualUserIdCopy = [...servicesByIndividualUserId];
      servicesByIndividualUserId.forEach((item) => {
        typeof item.subServices === 'string' ? item.subServices = JSON.parse(item.subServices) : item;
      });
      // Option for service
      const option = servicesByIndividualUserIdCopy.reduce((akku, row) => {
        const service = [];
        row.subServices.map((item) => {
          service.push({
            type: !service.length ? row.name : null,
            name: item.name,
            duration: item.duration,
            individual_user_industry_id: item.individual_user_industry_id,
            price: item.price,
            id: item.id,
          });
        });
        akku = akku.concat(service);
        return akku;
      }, []);
      setOptions(option);
    }
  }, [servicesByIndividualUserId]);

  // Handle get available hours error
  useEffect(() => {
    if (prevIsIndividualAvailableHoursError === false && isIndividualAvailableHoursError) {
      snackBarAlert('true', 'Available hours not defined', 'error');
    }
  }, [isIndividualAvailableHoursError]);

  // Handle Update Appointment Success
  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === updatedAppointment.id);
      appointmentsCopy.splice(appointmentIndex, 1);
      appointmentsCopy.push(updatedAppointment);
      setAppointments(appointmentsCopy);
      setIsOpenEditModal(false);
      snackBarAlert(true, t('AppointmentRescheduled'), 'success');
      setAlert(null);
    }
  }, [isUpdateAppointmentSuccess]);

  useEffect(() => {
    if (!prevIsAcceptSuccess && isAcceptSuccess) {
      const appointmentCopy = [...appointments];
      const appointmentIndex = appointmentCopy.findIndex((item) => item.id === acceptedAppointmentId);
      if (checkedItems.status.includes(1)) {
        appointmentCopy[appointmentIndex].status_id = 1;
        appointmentCopy[appointmentIndex].is_accept_allowed = false;
        appointmentCopy[appointmentIndex].status.name = 'accepted';
      } else {
        appointmentCopy.slice(appointmentIndex, 1);
      }
      setAppointments(appointmentCopy);
    }
  }, [isAcceptSuccess]);

  useEffect(() => {
    if (!prevIsCancelSuccess && isCancelSuccess) {
      const appointmentCopy = [...appointments];
      const appointmentIndex = appointmentCopy.findIndex((item) => item.id === cancelledAppointmentId);
      if (checkedItems.status.includes(3) && appointmentCopy.length) {
        appointmentCopy[appointmentIndex].status_id = 3;
        appointmentCopy[appointmentIndex].is_accept_allowed = false;
        appointmentCopy[appointmentIndex].status.name = 'cancelled';
      } else {
        appointmentCopy.splice(appointmentIndex, 1);
      }
      setAppointments(appointmentCopy);
    }
  }, [isCancelSuccess]);

  useEffect(() => {
    if (!prevIsFinishSuccess && isFinishSuccess) {
      const appointmentCopy = [...appointments];
      const appointmentIndex = appointmentCopy.findIndex((item) => item.id === finishedAppointmentId);
      if (checkedItems.status.includes(4)) {
        appointmentCopy[appointmentIndex].status_id = 4;
        appointmentCopy[appointmentIndex].is_accept_allowed = false;
        appointmentCopy[appointmentIndex].status.name = 'finished';
      } else {
        appointmentCopy.splice(appointmentIndex, 1);
      }
      setAppointments(appointmentCopy);
    }
  }, [isFinishSuccess]);

  // Handle get settings success
  useEffect(() => {
    if (prevIsGetIndividualUserSettingsSuccess === false && isGetIndividualUserSettingsSuccess) {
      setSettings(individualUserSettings);
      const selectedStatus = (filterData.status.filter((item, index) => (index < 2))).map((item) => parseInt(item.status_id));
      setCheckedItems({
        ...checkedItems,
        status: individualUserSettings.appointment.past_appointments ? [1, 2, 4] : selectedStatus,
      });
    }
  }, [isGetIndividualUserSettingsSuccess]);
  // Handle get settings error
  useEffect(() => {
    if (prevIsGetIndividualUserSettingsError === false && isGetIndividualUserSettingsError) {
      snackBarAlert(true, getIndividualUserSettingsErrorMessage, 'error');
    }
  }, [isGetIndividualUserSettingsError]);

  // Handle filter Appointments success
  useEffect(() => {
    if (prevIsFilterIndividualAppointmentsSuccess === false && isFilterIndividualAppointmentsSuccess) {
      const filteredAppointments = [...filteredIndividualAppointments];
      const sortedApp = handleSortingAppointments(filteredAppointments);
      setAppointments(sortedApp);
      setAnchorEl(null);
      setPage(0);
    }
  }, [isFilterIndividualAppointmentsSuccess]);

  // Handle filter Appointments error
  useEffect(() => {
    if (prevIsFilterIndividualAppointmentsError === false && isFilterIndividualAppointmentsError) {
      snackBarAlert('true', 'Something went wrong', 'error');
    }
  }, [isFilterIndividualAppointmentsError]);

  const handleOpenFilter = (event) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const togglePreview = (show) => {
    setIsPreviewShown(show);
  };

  const handleSaveAppointment = () => {
    setIsPreviewShown(false);
    setIsCreateOrUpdateAppointmentPerformed(false);
  };

  const handleOpenCreateAppointmentModal = () => {
    setIsCreateOrUpdateAppointmentPerformed(true);
  };

  const handleCloseCreateAppointmentModal = () => {
    setIsCreateOrUpdateAppointmentPerformed(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleOpenUpdateModal = (event, itemId) => {
    const appointment = appointments.find((item) => item.id === parseInt(itemId));
    const [allServices] = individualUsers.filter((item) => item.id === appointment.individual_user_industry_id);
    setSelectedAppointment({
      ...appointment,
      services: allServices.services,
    });
    setServiceOption(allServices.services);
    const time = (appointment.start_time).substring(0, 5);
    setSelectedHour(time);
    setIsOpenEditModal(true);
    const data = {
      individual_user_industry_id: appointment.individual_user_industry_id,
      individual_user_industry_service_id: allServices.services[0].id,
      date: appointment.date,
    };
    setPendingValue({
      duration: appointment.duration,
      id: appointment.service.id,
      individual_user_industry_id: appointment.individual_user_industry_id,
      name: appointment.service.name,
      price: appointment.price,
    });
    dispatch(getServicesByIndividualUserIdRequest({
      industryId: appointment.industry.id,
      individualUserIndustryId: appointment.individual_user_industry_id,
    }));
    dispatch(getIndividualAvailableHoursRequest(data));
  };

  const showConfirmDelete = (e, id) => {
    e.preventDefault();
    setAppointmentToDeleteId(id);
    setIsDeleteAppointmentModalShown(true);
    const [first] = PermissionsModule.getRoles(userAccount.testPermissions);
    localStorage.setItem('prefix', first.toLowerCase());
  };

  // Change the time in the current day
  const handleCurrentDayTimeChange = (event) => {
    const { name, value } = event.target;
    setCurrentDay({
      ...currentDay,
      [name]: value,
    });
  };

  const handleDeleteAppointment = () => {
    dispatch(deleteAppointmentRequest({ id: appointmentToDeleteId }));
  };

  const submitFilterItems = (checkedItems) => {
    dispatch(filterAllIndividualAppointmentsRequest(checkedItems));
    handleCloseFilter();
  };

  const handleCreateAppointment = (industry, pendingValue, appointmentItem, selectedCustomerOption) => {
    dispatch(storeAppointmentRequest({
      ...appointmentItem,
      individual_user_industry_id: industry.id,
      start_time: `${appointmentItem.start_time}:00`,
      service_id: appointmentItem.service_id,
      customer_id: selectedCustomerOption.value,
      creator: 'individual',
      note_from_creator: appointmentItem.note,
    }));
  };

  const handleOpenCreateCustomerModal = () => {
    setIsCreateCustomerModalShow(true);
    setFromModal(true);
  };

  const updateIndustries = (industries) => {
    setIndividualUsers(industries);
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setCustomerItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }, [customerItem]);

  const handleChangeCustomerLogin = (event) => {
    setCheckedLogin(event.target.checked);
  };

  const handleSelectChangeForCode = (event) => {
    const codeId = parseInt(event.target.value);
    const phone = phoneCodeOptions.find((item) => parseInt(item.value) === codeId);
    setSelectedCustomer({
      ...selectedCustomer,
      phone_code: phone.value,
    });
    setCustomerItem({
      ...customerItem,
      phone_code: phone.value,
    });
  };

  const validateCreateForm = (e) => {
    const errors = {
      first_name: e ? null : !customerItem.first_name.length ? 'First name is required' : null,
      last_name: e ? null : !customerItem.last_name.length ? 'Last name is required' : null,
      individual_user_industry_id: e ? null : !customerItem.individual_user_industry_id ? 'Industry is required' : null,
      email: e ? null : checkedLogin ? !customerItem.email.length ? 'Email is required' : null : null,
      phone_number: e ? null : (customerItem.phone_number === '' && !customerItem.phone_number.length)
        ? 'Phone number is required' : null,
      phone_code: e ? null : (selectedPhoneCode && selectedPhoneCode.value === null && customerItem.phone_code === ''
        && !customerItem.phone_code.length) ? 'Code is required' : null,
    };
    setValidationError(errors);
    return Object.values(errors)
      .filter((error) => (error !== null)).length === 0;
  };

  const handleCreateCustomerFormSubmit = (checkedLogin) => {
    if (validateCreateForm(checkedLogin)) {
      const data = { ...customerItem };
      data.individual_user_industry_id = customerItem.individual_user_industry_id;
      data.phone_code = customerItem.phone_code;
      data.phone_number = customerItem.phone_number === '' ? null : customerItem.phone_number;
      data.email = null;
      data.full_phone_number = (`${data.phone_code} ${data.phone_number}`).replace(/\D/g, '');
      dispatch(storeCustomerToIndustryRequest(data));
    }
  };

  const handleSelectIndustryForCustomer = (event) => {
    const industryId = parseInt(event.target.value);
    if (industryId) {
      const industry = individualUsers.find((item) => item.id === industryId);
      setCustomerIndustry({
        ...customerIndustry,
        industry: {
          label: industry.industry_name,
          value: industry.id,
        },
      });
      setCustomerItem({
        ...customerItem,
        individual_user_industry_id: industry.id,
      });
    } else {
      setCustomerIndustry({
        ...customerIndustry,
        industry: {
          label: '',
          value: '',
        },
      });
    }
  };

  const handleCloseCreateCustomerModal = useCallback(() => {
    setCustomerItem({
      email: '',
      first_name: '',
      last_name: '',
      phone_code: '',
      phone_number: '',
      individual_user_industry_id: '',
    });
    setValidationError({});
    setCheckedLogin(false);
    setIsCreateCustomerModalShow(false);
    setSelectedPhoneCode({ value: null });
    setCustomerIndustry({
      ...customerIndustry,
      industry: { value: null },
    });
  }, []);

  const handleServiceOptionChange = (service) => {
    if (service) {
      const serviceId = service.id;
      const serviceOption = selectedAppointment.services.find((item) => item.service_id === serviceId);
      const data = {
        individual_user_industry_id: service.individual_user_industry_id,
        individual_user_industry_service_id: serviceOption.id,
        date: selectedAppointment.date,
      };
      setServiceOption([serviceOption]);
      dispatch(getIndividualAvailableHoursRequest(data));
    } else {
      setPendingValue({});
    }
  };

  const handleChangeHours = (event) => {
    setSelectedAppointment({
      ...selectedAppointment,
      start_time: `${event.target.textContent}:00`,
    });
    setSelectedHour(event.target.textContent);
  };

  const renderDatetime = (label, value, onChange, img, name) => (
    <FormControl
      fullWidth
      size="small"
      variant="outlined"
    >
      <TextField
        fullWidth
        size="small"
        type={label.toLowerCase()}
        onChange={(e) => onChange(name, e.target.value)}
        variant="outlined"
        label={label}
        style={{ position: 'relative' }}
        placeholder={label}
        value={value || ''}
      />

      { moment(value).isBefore(moment(), 'days') && (
        <div className="error-message-content">
          <ErrorIcon fontSize="small" color="error" />
          <FormHelperText>
            {`${label} must be today or after`}
          </FormHelperText>
        </div>
      )}
    </FormControl>
  );

  const handleDateChange = (name, value) => {
    setSelectedAppointment({
      ...selectedAppointment,
      [name]: value,
    });
    const data = {
      individual_user_industry_id: selectedAppointment.individual_user_industry_id,
      individual_user_industry_service_id: serviceOption[0].id,
      date: value,
    };
    setSelectedHour('');
    dispatch(getIndividualAvailableHoursRequest(data));
  };

  const validateForm = (name, all = false) => {
    const errorsCopy = { ...errors };
    if (all) {
      errorsCopy.service = !Object.keys(pendingValue).length;
      errorsCopy.date = !selectedAppointment.date;
      errorsCopy.start_time = !selectedHour;
      setErrors(errorsCopy);
      return (
        Object.keys(pendingValue).length
        && Object.keys(selectedAppointment.date).length !== 0
        && Object.keys(pendingValue) !== 0
        && selectedHour.length !== 0
      );
    } else {
      const value = appointmentItem[name];
      errorsCopy[name] = value.length === 0;
      setErrors(errorsCopy);
    }
  };

  const handleEditAppointment = () => {
    if (validateForm('', true)) {
      dispatch(updateAppointmentRequest(selectedAppointment));
    }
  };

  const handleCloseEditModal = () => {
    setIsOpenEditModal(false);
    setErrors({});
  };

  const checkStartTimeAppointment = (date, startTime) => {
    const appointmentDate = moment(`${date} ${startTime}`);
    const currentDate = moment();

    return appointmentDate.isBefore(currentDate);
  };

  const handleAcceptAppointmentSelect = (id) => {
    setAcceptedAppointmentId(id);
    dispatch(acceptRequest(id));
  };

  const handleCancelAppointmentSelect = (id) => {
    setCancelledAppointmentId(id);
    dispatch(cancelRequest(id));
  };

  const handleFinishAppointmentSelect = (id) => {
    setFinishedAppointmentId(id);
    dispatch(finishRequest(id));
  };

  const handleChangeAppointmentStatus = (event, value) => {
    const { name } = event.target;
    const appointmentCopy = [...appointments];
    appointmentCopy[name] = value;
    setAppointments(appointmentCopy);
  };

  const industryWithService = individualUsers.some((item) => item.services.length);

  return (
    <>
      <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            className="second-navbar"
          >
            <Typography variant="h6">
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <span className="appointment-title">Appointments</span>
                </Grid>
              </Grid>
            </Typography>
            <Box display="flex" alignItems="center">
              {industryWithService && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenCreateAppointmentModal}
                  size="small"
                >
                  Add Appointment
                </Button>
              )}
              <Box ml="10px">
                <IconButton
                  aria-label="filter"
                  onClick={handleOpenFilter}
                >
                  <TuneRoundedIcon color="secondary" />
                </IconButton>
                <FilterContent
                  open={open}
                  anchorEl={anchorEl}
                  settings={settings}
                  filterData={filterData}
                  industries={individualUsers}
                  handleCloseFilter={handleCloseFilter}
                  submitFilterItems={submitFilterItems}
                  handleCurrentDayTimeChange={handleCurrentDayTimeChange}
                />
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div>
        <div className="menu-item appointments">
          <div className="general-content">
            {tableLoading ? (
              appointments && appointments.length ? (
                <div className="main-content appointments">
                  <TableContainer component={Paper} size="small">
                    <Table aria-label="caption table" className={classes.table}>
                      <TableHead>
                        <TableRow>
                          {tableHeader.map((item, index) => (
                            <TableCell key={`${index}-${Math.random()}`}>
                              {item}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {appointments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                          <TableRow
                            key={`${index}-${Math.random()}`}
                          >
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography>
                                <Typography className="appointment-date">
                                  {item.date === TODAY ? 'Today' : item.date === TOMORROW ? 'Tomorrow' : item.date}
                                </Typography>
                                <Typography className="appointment-time">
                                  <Typography noWrap variant="caption">
                                    {moment(item.start_time, 'HH:mm').format('HH:mm')}
                                  </Typography>
                                </Typography>
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography noWrap variant="caption">
                                <Box display="flex" alignItems="center">
                                  <Typography noWrap variant="caption">{item.duration} min</Typography>
                                </Box>
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography noWrap variant="caption">
                                {item.customer.full_name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography noWrap variant="caption">
                                {item.service.name}
                              </Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <Typography noWrap variant="caption">{item.price}</Typography>
                            </TableCell>
                            <TableCell
                              className={classes.tableCell}
                            >
                              <FormControl variant="outlined" size="small" margin="dense">
                                {item.status.id === 3 || item.status.id === 4
                                  ? (
                                    <MenuItem
                                      className={`menu-item-status appointment-status ${item.status.name}`}
                                      value={item.status.id}
                                    >
                                      {capitalize(item.status.name)}
                                    </MenuItem>
                                  )
                                  : (
                                    <SelectStatus
                                      classes={classes}
                                      selectedItem={item}
                                      appointments={appointments}
                                      checkStartTimeAppointment={checkStartTimeAppointment}
                                      handleChangeAppointmentStatus={handleChangeAppointmentStatus}
                                      handleAcceptAppointmentSelect={handleAcceptAppointmentSelect}
                                      handleFinishAppointmentSelect={handleFinishAppointmentSelect}
                                      handleCancelAppointmentSelect={handleCancelAppointmentSelect}
                                    />
                                  )}
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              {item.status.id === 3 || item.status.id === 4 || !(moment(`${(item.date)} ${item.end_time}`).isAfter(moment()))
                                ? <Button disabled />
                                : (
                                  <Button aria-label="edit" onClick={(e) => handleOpenUpdateModal(e, item.id)}>
                                    <EditIcon fontSize="small" color="secondary" />
                                  </Button>
                                )}
                              <IconButton
                                aria-label="delete"
                                onClick={(e) => showConfirmDelete(e, item.id)}
                              >
                                <DeleteOutlineIcon fontSize="small" color="secondary" />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {appointments.length > 8
                    ? (
                      <TablePagination
                        rowsPerPageOptions={[8, 25, 50]}
                        component="div"
                        count={appointments.length}
                        labelRowsPerPage={t('RowsPerPage')}
                        labelDisplayedRows={({ count, from, to }) => `${from}-${to}/${count}`}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={classes.paginationTable}
                      />
                    )
                    : null}
                </div>
              ) : (
                <Box className="appointments-page box-general" py="30px" align="center">
                  <Box>
                    <Box>
                      <img src={noAppointment} alt="noAppointment" />
                    </Box>
                    <Typography component="div">
                      <Box fontWeight="500">
                        <Typography variant="h6" color="secondary">
                          {t('NoAppointments')}
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              )
            ) : <AppointmentsLoading />}
          </div>
        </div>
        <DeleteAppointmentModal
          open={isDeleteAppointmentModalShown}
          onClose={() => setIsDeleteAppointmentModalShown(false)}
          handleDeleteAppointment={handleDeleteAppointment}
        />
        <CreateOrUpdateAppointmentModal
          account={userAccount}
          industries={individualUsers}
          customerOption={customerOptions}
          updateIndustries={updateIndustries}
          industryOptions={individualUsersOptions}
          handleCreateAppointment={handleCreateAppointment}
          isCreateOrUpdateAppointmentPerformed={isCreateOrUpdateAppointmentPerformed && !isPreviewShown}
          closeCreateOrUpdateAppointmentModal={handleCloseCreateAppointmentModal}
          handleOpenCreateCustomerModal={handleOpenCreateCustomerModal}
        />
        <AddCustomerModal
          fromModal={fromModal}
          customerItem={customerItem}
          checkedLogin={checkedLogin}
          customerIndustry={customerIndustry}
          validationErrors={validationErrors}
          phoneCodeOptions={phoneCodeOptions}
          handleInputChange={handleInputChange}
          selectedPhoneCode={selectedPhoneCode}
          industryOptions={individualUsersOptions}
          handleChangeCustomerLogin={handleChangeCustomerLogin}
          isAddCustomerModalOpen={isCreateCustomerModalShow}
          handleSelectChangeForCode={handleSelectChangeForCode}
          handleCreateCustomerFormSubmit={handleCreateCustomerFormSubmit}
          handleSelectIndustryForCustomer={handleSelectIndustryForCustomer}
          handleCloseAddCustomerModal={handleCloseCreateCustomerModal}
        />
        <AppointmentPreview
          open={isPreviewShown}
          onModalClose={() => togglePreview(false)}
          appointmentItem={appointmentItem}
          saveAppointment={handleSaveAppointment}
        />
        <EditAppointmentModal
          open={isOpenEditModal}
          onClose={handleCloseEditModal}
          selectedAppointment={selectedAppointment}
          options={options}
          errors={errors}
          pendingValue={pendingValue}
          selectedHour={selectedHour}
          availableTime={availableTime}
          setPendingValue={(value) => setPendingValue(value)}
          renderDatetime={renderDatetime}
          handleDateChange={handleDateChange}
          handleChangeHours={handleChangeHours}
          handleEditAppointment={handleEditAppointment}
          handleServiceOptionChange={handleServiceOptionChange}
        />
        <SnackbarToast
          message={snackbarMessage}
          type={snackbarType}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
        />
        { alert }
      </div>
    </>
  );
}

MyAppointments.propTypes = {
  history: PropTypes.object.isRequired,
};

export default MyAppointments;
