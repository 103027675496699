import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Select,
  Switch,
  Typography,
  MenuItem,
  Box,
  Grid,
} from '@material-ui/core';
import i18n from 'i18next';
import usePrevious from '../../../../CustomHooks/usePrevious';
import hours from '../../../../Modules/hours';
import Loading from '../../../../Components/Loading/loading';
import {
  getDefaultWorkingHoursRequest,
  getDefaultBreakingHoursRequest,
} from '../../../../redux/businnessHours/actions';
import SnackbarToast from '../../../../Modules/SnackbarToast';

function WorkingHours(props) {
  const {
    getWorkingHours,
    getBreakingHours,
    isGetDefaultWorkingHoursSuccess,
    isGetDefaultWorkingHoursError,
    isGetDefaultBreakingHoursSuccess,
    isGetDefaultBreakingHoursError,
    defaultWorkingHours,
    defaultBreakingHours,
    workingHoursProps,
    breakingHoursProps,
    setIndividualUserFunction,
  } = props;

  const prevIsGetDefaultWorkingHoursSuccess = usePrevious(isGetDefaultWorkingHoursSuccess);
  const prevIsGetDefaultWorkingHoursError = usePrevious(isGetDefaultWorkingHoursError);
  const prevIsGetDefaultBreakingHoursSuccess = usePrevious(isGetDefaultBreakingHoursSuccess);
  const prevIsGetDefaultBreakingHoursError = usePrevious(isGetDefaultBreakingHoursError);

  const weekDays = [
    i18n.t('Monday'),
    i18n.t('Tuesday'),
    i18n.t('Wednesday'),
    i18n.t('Thursday'),
    i18n.t('Friday'),
    i18n.t('Saturday'),
    i18n.t('Sunday'),
  ];
  const [workingHours, setWorkingHours] = useState([]);
  const [breakingHours, setBreakingHours] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    setLoading(true);
    localStorage.setItem('prefix', 'Individual');
    if (Object.keys(workingHoursProps).length !== 0) {
      setWorkingHours(workingHoursProps);
      setLoading(false);
    } else {
      getWorkingHours();
    }

    if (Object.keys(breakingHoursProps).length !== 0) {
      setBreakingHours(breakingHoursProps);
      setLoading(false);
    } else {
      getBreakingHours();
    }
  }, []);

  useEffect(() => {
    if (prevIsGetDefaultWorkingHoursSuccess === false && isGetDefaultWorkingHoursSuccess) {
      const defaultWorkingHoursCopy = [];
      defaultWorkingHours.map((item, index) => {
        defaultWorkingHoursCopy.push(
          {
            ...item,
            week_day: weekDays[index],
          },
        );
      });

      setIndividualUserFunction({
        working_hours: defaultWorkingHoursCopy,
      });
      setWorkingHours(defaultWorkingHoursCopy);
      setLoading(false);
    }
  }, [isGetDefaultWorkingHoursSuccess]);

  useEffect(() => {
    if (prevIsGetDefaultWorkingHoursError === false && isGetDefaultWorkingHoursError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetDefaultWorkingHoursError]);

  useEffect(() => {
    if (prevIsGetDefaultBreakingHoursSuccess === false && isGetDefaultBreakingHoursSuccess) {
      const defaultBreakingHoursCopy = [];
      defaultBreakingHours.map((item, index) => {
        defaultBreakingHoursCopy.push(
          {
            ...item,
            week_day: weekDays[index],
          },
        );
      });

      setIndividualUserFunction({
        breaking_hours: defaultBreakingHoursCopy,
      });

      setBreakingHours(defaultBreakingHoursCopy);
      setLoading(false);
    }
  }, [isGetDefaultBreakingHoursSuccess]);

  useEffect(() => {
    if (prevIsGetDefaultBreakingHoursError === false && isGetDefaultBreakingHoursError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetDefaultBreakingHoursError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleWeekDayDetailsChange = (event, id) => {
    const { name, value, checked } = event.target;
    const val = checked !== undefined ? checked : value;
    const workingHoursCopy = [...workingHours];
    const breakingHoursCopy = [...breakingHours];
    const dayIndex = workingHoursCopy.findIndex((item) => item.week_day_id === id);
    const breakingHourIndex = breakingHoursCopy.findIndex((item) => item.week_day_id === id);
    const day = workingHoursCopy[dayIndex];
    const breakingDay = breakingHoursCopy[breakingHourIndex];
    if (name === 'is_working') {
      day[name] = val;
      breakingDay['is_breaking'] = val;
    } else if (name === 'start_time') {
      const startTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(day.end_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        day[name] = val;
      } else {
        snackBarAlert(true, i18n.t('StartTimeEarlier'), 'error');
        return false;
      }
    } else if (name === 'end_time') {
      const startTime = moment(day.start_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        day[name] = val;
      } else {
        snackBarAlert(true, i18n.t('EndTimeAfter'), 'error');
        return false;
      }
    }
    workingHoursCopy[dayIndex] = day;
    breakingHoursCopy[breakingHourIndex] = breakingDay;
    setIndividualUserFunction({
      working_hours: workingHoursCopy,
    });

    setIndividualUserFunction({
      breaking_hours: breakingHoursCopy,
    });

    setWorkingHours(() => ([
      ...workingHoursCopy,
    ]));

    setBreakingHours(() => ([
      ...breakingHoursCopy,
    ]));
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return !loading ? (
    <div>
      <Box textAlign="center" mb="24px">
        <Typography>
          Let your customers know when you&apos;re open
        </Typography>
      </Box>
      <Box>
        { workingHours && workingHours.map((workHour) => (
          <Box display="flex" className="day-content" key={Math.random()}>
            <Box display="flex" minWidth="173px">
              <Box minWidth="91px">
                <div className="day-name">
                  <span>{ workHour.week_day }</span>
                </div>
              </Box>
              <Box maxWidth="91px">
                <Typography component="div">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>
                      <Switch
                        size="small"
                        checked={workHour.is_working}
                        onChange={(event) => handleWeekDayDetailsChange(event, workHour.week_day_id)}
                        color="primary"
                        name="is_working"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </Grid>
                    {workHour.is_working ? (
                      <Grid item> <Typography color="inherit" variant="body1"> Open </Typography> </Grid>
                    ) : <Typography color="inherit" variant="body1"> Close </Typography>}
                  </Grid>
                </Typography>
              </Box>
            </Box>
            <Box minWidth="173px">
              { workHour.is_working ? (
                <div className="hours-select">
                  <Select
                    id="start_time"
                    disableUnderline
                    disabled={workHour ? !workHour['is_working'] : !workHour.is_working && true}
                    data-cy="working-start-time"
                    MenuProps={MenuProps}
                    value={workHour.start_time}
                    name="start_time"
                    variant="outlined"
                    onChange={(event) => handleWeekDayDetailsChange(event, workHour.week_day_id)}
                  >
                    { hours.map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
                  </Select>
                  <span>-</span>
                  <Select
                    id="end_time"
                    disableUnderline
                    disabled={workHour ? !workHour['is_working'] : !workHour.is_working && true}
                    data-cy="working-end-time"
                    MenuProps={MenuProps}
                    value={workHour.end_time}
                    name="end_time"
                    variant="outlined"
                    onChange={(event) => handleWeekDayDetailsChange(event, workHour.week_day_id)}
                  >
                    { hours.map((hour) => <MenuItem key={Math.random()} value={hour} disabled={hour <= workHour.start_time}>{hour}</MenuItem>) }
                  </Select>
                </div>
              ) : (
                <Box className="selects-border" />
              )}
            </Box>
          </Box>
        )) }
      </Box>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  ) : (<Loading />);
}

WorkingHours.propTypes = {
  // Get Parent props
  workingHoursProps: PropTypes.array.isRequired,
  breakingHoursProps: PropTypes.array.isRequired,
  // Get Working Hours Props
  getWorkingHours: PropTypes.func.isRequired,
  isGetDefaultWorkingHoursSuccess: PropTypes.bool.isRequired,
  isGetDefaultWorkingHoursError: PropTypes.bool.isRequired,
  defaultWorkingHours: PropTypes.array.isRequired,
  setIndividualUserFunction: PropTypes.func.isRequired,
  // Get Breaking Hours Props
  getBreakingHours: PropTypes.func.isRequired,
  isGetDefaultBreakingHoursSuccess: PropTypes.bool.isRequired,
  isGetDefaultBreakingHoursError: PropTypes.bool.isRequired,
  defaultBreakingHours: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  // Get Default Working Hours
  isGetDefaultWorkingHoursSuccess: state.businessHours.isGetDefaultWorkingHoursSuccess,
  isGetDefaultWorkingHoursError: state.businessHours.isGetDefaultWorkingHoursError,
  defaultWorkingHours: state.businessHours.defaultWorkingHours,
  // Get Default Breaking Hours
  isGetDefaultBreakingHoursSuccess: state.businessHours.isGetDefaultBreakingHoursSuccess,
  isGetDefaultBreakingHoursError: state.businessHours.isGetDefaultBreakingHoursError,
  defaultBreakingHours: state.businessHours.defaultBreakingHours,
});

function mapDispatchToProps(dispatch) {
  return {
    getWorkingHours: () => dispatch(getDefaultWorkingHoursRequest()),
    getBreakingHours: () => dispatch(getDefaultBreakingHoursRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkingHours);
