import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from '../../../../Components/General/Header';
import PermissionsModule from '../../../../Modules/Permission';
import CustomerSettings from '../../Roles/Customer/Settings/settings';

function Settings(props) {
  const {
    account: { testPermissions },
    match,
    history,
  } = props;

  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');

  useEffect(() => {
    const roles = PermissionsModule.getRoles(testPermissions);
    setRoles(roles);

    const roleToSelect = PermissionsModule.getRoleToSelect(roles);
    setSelectedRole(roleToSelect);
  }, []);
  useEffect(() => {
    localStorage.setItem('prefix', selectedRole);
  }, [selectedRole]);

  const getSelectedRoleComponent = () => {
    const props = { history, match };
    // eslint-disable-next-line default-case
    switch (selectedRole.toLowerCase()) {
      case 'individual': {
        return <CustomerSettings {...props} />;
      }
      case 'customer': {
        return <CustomerSettings {...props} />;
      }
      case 'staff': {
        return <CustomerSettings {...props} />;
      }
    }
  };
  return (
    <>
      <Header
        roles={roles}
        selectedRole={selectedRole}
        selectRole={(role) => setSelectedRole(role)}
      />
      {getSelectedRoleComponent()}
    </ >
  );
}

Settings.propTypes = {
  account: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account.userAccount,
});

export default connect(mapStateToProps)(Settings);
