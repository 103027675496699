import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function Header({ appointment, onClose }) {
  const { t } = useTranslation();

  const renderStatusClass = (status) => {
    switch (status) {
      case 'accepted':
        return 'status-color-upcoming';
      case 'pending':
        return 'status-color-pending';
      case 'cancelled':
        return 'status-color-cancel';
      case 'finished':
        return 'status-color-finished';
    }
  };

  return (
    <>
      <div className="title-content">
        <h3 className="title">
          {appointment.id ? t('AppointmentDetails') : t('NewAppointment')}
        </h3>
        <CloseIcon className="close-icon" onClick={onClose} />
      </div>
      {appointment.id && (
        <div className="details-content">
          <div className="status-content">
            <div>
              <div className="detail-text">
                ID: {appointment.id}
              </div>
            </div>
            <div className={`${renderStatusClass(appointment.status_name)} status`}>
              {appointment.status_name === 'accepted'
                ? t('Upcoming')
                : appointment.status_name === 'cancelled'
                  ? t('Cancelled')
                  : appointment.status_name === 'pending'
                    ? t('Pending')
                    : t('Finished')}
            </div>
          </div>
          <div className="detail-text source">
            <span>
              {`${t('Source')}: `}
            </span>
            <span>
              {appointment.from_widget ? ` ${t('BookingLink')}`
                : ` ${t('Calendar')}`}
            </span>
          </div>
        </div>
      )}
    </>
  );
}

Header.propTypes = {
  appointment: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Header;
