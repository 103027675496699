import { createAction } from 'redux-actions';

export const getUserAccountRequest = createAction('GET_USER_ACCOUNT_REQUEST');
export const getUserAccountSuccess = createAction('GET_USER_ACCOUNT_SUCCESS');
export const getUserAccountError = createAction('GET_USER_ACCOUNT_ERROR');

export const updateUserAccountRequest = createAction('UPDATE_USER_ACCOUNT_REQUEST');
export const updateUserAccountSuccess = createAction('GET_UPDATED_USER_SUCCESS');
export const updateUserAccountError = createAction('GET_UPDATED_USER_ERROR');

export const updateAccountAvatarRequest = createAction('UPDATE_ACCOUNT_AVATAR_REQUEST');
export const updateAccountAvatarSuccess = createAction('UPDATE_ACCOUNT_AVATAR_SUCCESS');
export const updateAccountAvatarError = createAction('UPDATE_ACCOUNT_AVATAR_ERROR');

export const verifyEmailRequest = createAction('VERIFY_EMAIL_REQUEST');
export const verifyEmailSuccess = createAction('VERIFY_EMAIL_SUCCESS');
export const verifyEmailError = createAction('VERIFY_EMAIL_ERROR');

export const getUserLocationRequest = createAction('GET_USER_LOCATION_REQUEST');
export const getUserLocationSuccess = createAction('GET_USER_LOCATION_SUCCESS');
export const getUserLocationError = createAction('GET_USER_LOCATION_ERROR');

export const verifyPhoneRequest = createAction('VERIFY_PHONE_NUMBER_REQUEST');
export const verifyPhoneSuccess = createAction('VERIFY_PHONE_SUCCESS');
export const verifyPhoneError = createAction('VERIFY_PHONE_ERROR');

export const savePersonalDetailsRequest = createAction('SAVE_PERSONAL_DETAILS_REQUEST');
export const savePersonalDetailsSuccess = createAction('SAVE_PERSONAL_DETAILS_SUCCESS');
export const savePersonalDetailsError = createAction('SAVE_PERSONAL_DETAILS_ERROR');

export const saveBusinessDetailsRequest = createAction('SAVE_BUSINESS_DETAILS_REQUEST');
export const saveBusinessDetailsSuccess = createAction('SAVE_BUSINESS_DETAILS_SUCCESS');
export const saveBusinessDetailsError = createAction('SAVE_BUSINESS_DETAILS_ERROR');

export const finishCompanyOnBoardingRequest = createAction('FINISH_COMPANY_ON_BOARDING_REQUEST');
export const finishCompanyOnBoardingSuccess = createAction('FINISH_COMPANY_ON_BOARDING_SUCCESS');
export const finishCompanyOnBoardingError = createAction('FINISH_COMPANY_ON_BOARDING_ERROR');

export const createNewRoleAccountRequest = createAction('CREATE_NEW_ROLE_ACCOUNT_REQUEST');
export const createNewRoleAccountSuccess = createAction('CREATE_NEW_ROLE_ACCOUNT_SUCCESS');
export const createNewRoleAccountError = createAction('CREATE_NEW_ROLE_ACCOUNT_ERROR');

export const switchToAccountRequest = createAction('SWITCH_TO_ACCOUNT_REQUEST');
export const switchToAccountSuccess = createAction('SWITCH_TO_ACCOUNT_SUCCESS');
export const switchToAccountError = createAction('SWITCH_TO_ACCOUNT_ERROR');

export const finishIndividualOnBoardingRequest = createAction('FINISH_INDIVIDUAL_ON_BOARDING_REQUEST');
export const finishIndividualOnBoardingSuccess = createAction('FINISH_INDIVIDUAL_ON_BOARDING_SUCCESS');
export const finishIndividualOnBoardingError = createAction('FINISH_INDIVIDUAL_ON_BOARDING_ERROR');

export const sendVerificationCodeRequest = createAction('SEND_VERIFICATION_CODE_REQUEST');
export const sendVerificationCodeSuccess = createAction('SEND_VERIFICATION_CODE_SUCCESS');
export const sendVerificationCodeError = createAction('SEND_VERIFICATION_CODE_ERROR');

export const sendVerificationLinkRequest = createAction('SEND_VERIFICATION_LINK_REQUEST');
export const sendVerificationLinkSuccess = createAction('SEND_VERIFICATION_LINK_SUCCESS');
export const sendVerificationLinkError = createAction('SEND_VERIFICATION_LINK_ERROR');

export const resetNotificationsRequest = createAction('RESET_NOTIFICATIONS_REQUEST');

export const addAccountTypeRequest = createAction('ADD_ACCOUNT_TYPE_REQUEST');
export const addAccountTypeSuccess = createAction('ADD_ACCOUNT_TYPE_SUCCESS');
export const addAccountTypeError = createAction('ADD_ACCOUNT_TYPE_ERROR');

export const changePasswordRequest = createAction('CHANGE_PASSWORD_REQUEST');
export const changePasswordSuccess = createAction('CHANGE_PASSWORD_SUCCESS');
export const changePasswordError = createAction('CHANGE_PASSWORD_ERROR');

export const getUserSettingsRequest = createAction('GET_USER_SETTINGS_REQUEST');
export const getUserSettingsSuccess = createAction('GET_USER_SETTINGS_SUCCESS');
export const getUserSettingsError = createAction('GET_USER_SETTINGS_ERROR');

export const updateUserSettingsRequest = createAction('UPDATE_USER_SETTINGS_REQUEST');
export const updateUserSettingsSuccess = createAction('UPDATE_USER_SETTINGS_SUCCESS');
export const updateUserSettingsError = createAction('UPDATE_USER_SETTINGS_ERROR');

export const getUserAccountNotificationsRequest = createAction('GET_USER_ACCOUNT_NOTIFICATIONS_REQUEST');
export const getUserAccountNotificationsSuccess = createAction('GET_USER_ACCOUNT_NOTIFICATIONS_SUCCESS');
export const getUserAccountNotificationsError = createAction('GET_USER_ACCOUNT_NOTIFICATIONS_ERROR');

export const deactivateRoleRequest = createAction('DEACTIVATE_ROLE_REQUEST');
export const deactivateRoleSuccess = createAction('DEACTIVATE_ROLE_SUCCESS');
export const deactivateRoleError = createAction('DEACTIVATE_ROLE_ERROR');

export const syncGoogleCalendarRequest = createAction('SYNC_GOOGLE_CALENDAR_REQUEST');
export const syncGoogleCalendarSuccess = createAction('SYNC_GOOGLE_CALENDAR_SUCCESS');
export const syncGoogleCalendarError = createAction('SYNC_GOOGLE_CALENDAR_ERROR');

export const authenticateGoogleAccountRequest = createAction('AUTHENTICATE_GOOGLE_ACCOUNT_REQUEST');
export const authenticateGoogleAccountSuccess = createAction('AUTHENTICATE_GOOGLE_ACCOUNT_SUCCESS');
export const authenticateGoogleAccountError = createAction('AUTHENTICATE_GOOGLE_ACCOUNT_ERROR');

export const getAvatarPreSignUrlRequest = createAction('GET_AVATAR_PRESIGN_URL_REQUEST');
export const getAvatarPreSignUrlSuccess = createAction('GET_AVATAR_PRESIGN_URL_SUCCESS');
export const getAvatarPreSignUrlError = createAction('GET_AVATAR_PRESIGN_URL_ERROR');

export const uploadAvatarToAmazonRequest = createAction('UPLOAD_AVATAR_TO_AMAZON_REQUEST');
export const uploadAvatarToAmazonSuccess = createAction('UPLOAD_AVATAR_TO_AMAZON_SUCCESS');
export const uploadAvatarToAmazonError = createAction('UPLOAD_AVATAR_TO_AMAZON_ERROR');

export const updateUserAccountInGlobalScope = createAction('UPDATE_USER_ACCOUNT_IN_GLOBAL_SCOPE');

export const checkUnsubscribeTokenExistRequest = createAction('CHECK_UNSUBSCRIBE_TOKEN_EXIST_REQUEST');
export const checkUnsubscribeTokenExistSuccess = createAction('CHECK_UNSUBSCRIBE_EXIST_SUCCESS');
export const checkUnsubscribeTokenExistError = createAction('CHECK_UNSUBSCRIBE_EXIST_ERROR');

export const unsubscribeRequest = createAction('UNSUBSCRIBE_REQUEST');
export const unsubscribeSuccess = createAction('UNSUBSCRIBE_SUCCESS');
export const unsubscribeError = createAction('UNSUBSCRIBE_ERROR');
