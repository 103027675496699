import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import {
  TextField,
  FormControl,
  FormHelperText,
  Grid,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import { updateAppointmentRequest } from '../../../../../../../redux/appointment/actions';
import capitalize from '../../../../../../../Modules/capitalize';
import InfoLaoding from '../../../../../../../Components/Loading/infoLoading';
import calendar from '../../../../../../../assets/icons/calendar.svg';
import clock from '../../../../../../../assets/icons/clock.svg';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';

function Info(props) {
  const {
    appointment,
    allStatuses,
    updateAppointment,
    isUpdateAppointmentSuccess,
    isUpdateAppointmentError,
    updatedAppointment,
    updateAppointmentErrors,
    isAppointmentUpdate,
    setIsAppointmentUpdate,
  } = props;

  // Get some props previous values
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);
  const prevIsUpdateAppointmentError = usePrevious(isUpdateAppointmentError);

  const [loading, setLoading] = useState(false);
  const [appointmentItem, setAppointmentItem] = useState({});
  const [selectedStatusOption, setSelectedStatusOption] = useState({});
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (isAppointmentUpdate) {
      handleSaveAppointment();
      setIsAppointmentUpdate(false);
    }
  }, [isAppointmentUpdate]);

  // Perform once, when component gets mounted
  useEffect(() => {
    const time = moment(appointment && appointment.start_time, 'HH:mm:ss');
    setAppointmentItem({
      ...appointment,
      day: new Date(appointmentItem.date),
      time,
    });
    if (allStatuses.length) {
      const options = allStatuses.map((item) => ({
        label: capitalize(item.name),
        value: item.id,
      }));
      const selectedOption = options.find((item) => parseInt(item.value) === parseInt(appointment.status_id));
      setSelectedStatusOption(selectedOption);
    }
  }, []);
  // Perform, when update appointment success
  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      const time = moment(updatedAppointment.start_time, 'HH:mm:ss');
      setAppointmentItem({
        ...updatedAppointment,
        day: new Date(updatedAppointment.date),
        time,
      });
      setLoading(false);
      setErrors({});
      setOpenSnackbar(true);
      setSnackbarMessage('Appointment updated');
      setSnackbarType('success');
    }
  }, [isUpdateAppointmentSuccess]);
  // perform, when update appointment error
  useEffect(() => {
    if (!prevIsUpdateAppointmentError && isUpdateAppointmentError) {
      setLoading(false);
      setErrors(updateAppointmentErrors);
    }
  }, [isUpdateAppointmentError]);

  const renderDatetime = (label, value, onChange, img, name) => (
    <div className="datetime-content">
      <TextField
        fullWidth
        error={!!errors[name]}
        size="small"
        variant="outlined"
        type={label.toLowerCase()}
        label={label}
        style={{ position: 'relative' }}
        onChange={(e) => onChange(name, e.target.value)}
        placeholder={label}
        value={value || ''}
        inputProps={{
          autoComplete: 'new-password',
        }}
      />

      {errors[name] && (
        <div className="error-message-content">
          <ErrorIcon fontSize="small" color="error" />
          <FormHelperText error>
            {errors[name]}
          </FormHelperText>
        </div>
      )}
    </div>
  );

  const updateAppointmentItem = (name, value) => {
    let updateValue = value;
    if (name === 'start_time') {
      updateValue = `${updateValue}:00`;
    }
    setAppointmentItem({
      ...appointmentItem,
      [name]: updateValue,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAppointmentItem({
      ...appointmentItem,
      [name]: value,
    });
  };

  const handleSaveAppointment = () => {
    setLoading(true);
    updateAppointment({
      ...appointmentItem,
    });
  };

  return (
    <>
      <Grid id="info-tab" className="tab-item">
        { !loading ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Title"
                name="title"
                placeholder="Title"
                value={appointmentItem.title || ''}
                onChange={(e) => handleInputChange(e)}
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDatetime('Date', appointmentItem.date, updateAppointmentItem, calendar, 'date')}
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDatetime('Time', appointmentItem.start_time, updateAppointmentItem, clock, 'start_time')}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small" variant="outlined">
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Status"
                  name="status"
                  placeholder="Status"
                  value={selectedStatusOption.label ? selectedStatusOption.label : ''}
                  disabled
                  inputProps={{
                    autoComplete: 'new-password',
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Permalink"
                name="permalink"
                placeholder="Permalink"
                value={appointmentItem.permalink || ''}
                disabled
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Customer"
                name="customer"
                placeholder="Customer"
                value={
                  appointmentItem.customer
                    ? `${appointmentItem.customer.first_name} ${appointmentItem.customer.last_name}`
                    : ''
                }
                disabled
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Company"
                name="company"
                placeholder="Company"
                value={appointmentItem.company ? appointmentItem.company.name : ''}
                disabled
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Staff"
                name="staff"
                placeholder="Staff"
                value={appointmentItem.staff ? `${appointmentItem.staff.first_name} ${appointmentItem.staff.last_name}` : ''}
                disabled
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Service"
                name="service"
                placeholder="Service"
                value={appointment && appointmentItem.service ? appointment.service.name : ''}
                disabled
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
          </Grid>
        ) : <InfoLaoding />}
      </Grid>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

Info.propTypes = {
  appointment: PropTypes.object.isRequired,
  allStatuses: PropTypes.array.isRequired,
  updateAppointment: PropTypes.func.isRequired,
  isUpdateAppointmentSuccess: PropTypes.bool.isRequired,
  isUpdateAppointmentError: PropTypes.bool.isRequired,
  updatedAppointment: PropTypes.object.isRequired,
  isAppointmentUpdate: PropTypes.bool.isRequired,
  setIsAppointmentUpdate: PropTypes.func.isRequired,
  updateAppointmentErrors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  isUpdateAppointmentSuccess: state.appointment.isUpdateAppointmentSuccess,
  isUpdateAppointmentError: state.appointment.isUpdateAppointmentError,
  updatedAppointment: state.appointment.updatedAppointment,
  updateAppointmentErrors: state.appointment.updateAppointmentErrors,
});

function mapDispatchToProps(dispatch) {
  return {
    updateAppointment: (data) => dispatch(updateAppointmentRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);
