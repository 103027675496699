import { put, takeLatest } from 'redux-saga/effects';
import {
  getHistoryRequest, getHistorySuccess,
  getHistoryError,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

function* getHistory(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('appointments/history', { date: payload });
    if (response && response.status === 200) {
      yield put(getHistorySuccess(response.data));
    } else {
      yield put(getHistoryError(response.response.data));
    }
  } catch (e) {
    yield put(getHistoryError(e.response.data));
  }
}

export default function* () {
  yield takeLatest(getHistoryRequest, getHistory);
}
