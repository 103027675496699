import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import i18n from 'i18next';
import usePrevious from '../../../CustomHooks/usePrevious';
import splitSpaces from '../../../Modules/splitSpaces';
import SnackbarToast from '../../../Modules/SnackbarToast';
import { individualCloseDayRequest, updateIndividualCloseDayRequest } from '../../../redux/individualUser/actions';

function CloseDayModal(props) {
  const dispatch = useDispatch();

  const {
    isIndividualCloseDaySuccess,
    isIndividualCloseDayError,
    storedIndividualCloseDay,
    storeIndividualCloseDayErrors,
    isUpdateIndividualCloseDaySuccess,
    isUpdateIndividualCloseDayError,
    updatedIndividualCloseDay,
  } = useSelector((state) => state.individualUser);

  const {
    open,
    onClose,
    closeDay,
    allCloseDays,
    setAllCloseDays,
    individualUserIndustryItem,
    setIndividualUserIndustryItem,
  } = props;

  const date = new Date();
  const [storeErrors, setStoreErrors] = useState({});
  const [updateErrors, setUpdateErrors] = useState({});
  const [errorDate, setErrorDate] = useState('');
  const [errors, setErrors] = useState({
    start_date: false,
    end_date: false,
  });
  const [closeDayData, setCloseDayData] = useState({
    start_date: '',
    end_date: '',
    description: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous value
  const prevIsIndividualCloseDaySuccess = usePrevious(isIndividualCloseDaySuccess);
  const prevIsIndividualCloseDayError = usePrevious(isIndividualCloseDayError);
  const prevIsUpdateIndividualCloseDaySuccess = usePrevious(isUpdateIndividualCloseDaySuccess);
  const prevIsUpdateIndividualCloseDayError = usePrevious(isUpdateIndividualCloseDayError);

  const useStyles = makeStyles(() => ({
    error: {
      borderColor: '#f44336',
    },
  }));

  // Get Close day data
  useEffect(() => {
    const keys = Object.keys(closeDay);
    const valueExists = keys.find((key) => closeDay[key]);
    if (valueExists) {
      setCloseDayData(closeDay);
    } else {
      setCloseDayData({
        start_date: '',
        end_date: '',
        description: '',
        individual_user_industry_id: individualUserIndustryItem.id,
      });
    }
    setErrors({});
    setStoreErrors({});
    setUpdateErrors({});
    setErrorDate('');
  }, [closeDay]);

  // Handle Store Individual Close day success
  useEffect(() => {
    if (prevIsIndividualCloseDaySuccess === false && isIndividualCloseDaySuccess) {
      snackBarAlert(true, i18n.t('ClosedDayAdded'), 'success');
      const closeDayCopy = [...allCloseDays];
      closeDayCopy.push(storedIndividualCloseDay);
      setAllCloseDays(closeDayCopy);
      setIndividualUserIndustryItem((prevState) => ({
        ...prevState,
        dayOffs: closeDayCopy,
      }));
      setCloseDayData({
        start_date: '',
        end_date: '',
        description: '',
        individual_user_industry_id: individualUserIndustryItem.id,
      });
      setStoreErrors({});
      onClose();
    }
  }, [isIndividualCloseDaySuccess]);

  // Handle Store Day off error
  useEffect(() => {
    if (prevIsIndividualCloseDayError === false && isIndividualCloseDayError) {
      setStoreErrors(storeIndividualCloseDayErrors);
    }
  }, [isIndividualCloseDayError]);

  // Handle Update Close day success
  useEffect(() => {
    if (prevIsUpdateIndividualCloseDaySuccess === false && isUpdateIndividualCloseDaySuccess) {
      const closeDaysCopy = [...allCloseDays];
      const closeDayIndex = closeDaysCopy.findIndex((item) => item.id === updatedIndividualCloseDay.id);
      closeDaysCopy.splice(closeDayIndex, 1);
      closeDaysCopy.push(updatedIndividualCloseDay);
      setAllCloseDays(closeDaysCopy);
      setIndividualUserIndustryItem((prevState) => ({
        ...prevState,
        dayOffs: closeDaysCopy,
      }));
      onClose();
      setUpdateErrors({});
      snackBarAlert(true, i18n.t('ClosedDayUpdated'), 'success');
    }
  }, [isUpdateIndividualCloseDaySuccess]);

  // Handle Update Close day error
  useEffect(() => {
    if (prevIsUpdateIndividualCloseDayError === false && isUpdateIndividualCloseDayError) {
      // setUpdateErrors(updateCloseDayErrors);
    }
  }, [isUpdateIndividualCloseDayError]);

  const updateDayOffData = (name, value) => {
    setCloseDayData({
      ...closeDayData,
      [name]: value,
    });
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const validateForm = () => {
    const errorsCopy = { ...errors };
    errorsCopy.start_date = closeDayData.start_date === '';
    errorsCopy.end_date = closeDayData.end_date === '';
    setErrors(() => ({ ...errorsCopy }));

    return (errorsCopy.start_date || errorsCopy.end_date);
  };

  const handleAddCloseDay = () => {
    if (!validateForm()) {
      const closeDayDataCopy = { ...closeDayData };
      closeDayDataCopy.description = splitSpaces(closeDayDataCopy.description);
      dispatch(individualCloseDayRequest(closeDayDataCopy));
    }
  };

  const handleUpdateCloseDay = () => {
    if (!validateForm()) {
      const closeDayDataCopy = { ...closeDayData };
      closeDayDataCopy.description = splitSpaces(closeDayDataCopy.description);
      dispatch(updateIndividualCloseDayRequest(closeDayDataCopy));
    }
  };

  const handleChangeDays = (e) => {
    const { name, value } = e.target;
    setErrorDate('');
    if (name === 'start_date') {
      setCloseDayData({
        ...closeDayData,
        start_date: value,
        end_date: value,
      });
    } else if (name === 'end_date') {
      const startTime = moment(closeDayData.start_date, 'Y-MM-DD').format('Y-MM-DD HH:mm a');
      const endTime = moment(value, 'Y-MM-DD').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        updateDayOffData('end_date', value);
      } else {
        setErrorDate(i18n.t('EndTimeAfter'));
      }
    }
  };

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              <span>{closeDayData.id ? 'Edit day off' : i18n.t('AddDayOff')}</span>
            </Typography>
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.start_date || !!storeErrors.start_date || !!updateErrors.start_date}
                size="small"
                variant="outlined"
              >
                <TextField
                  label="Start date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.start_date || !!storeErrors.start_date || !!updateErrors.start_date}
                  size="small"
                  name="start_date"
                  onChange={(e) => handleChangeDays(e)}
                  variant="outlined"
                  value={closeDayData.start_date || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd'), max: '9999-12-31', autoComplete: 'new-password' }}
                />

                { errors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      Start date is required.
                    </FormHelperText>
                  </div>
                )}

                { storeErrors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.start_date}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.start_date}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.end_date || !!errorDate}
                size="small"
                variant="outlined"
              >
                <TextField
                  label="End date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.end_date || !!errorDate}
                  size="small"
                  name="end_date"
                  onChange={(e) => handleChangeDays(e)}
                  variant="outlined"
                  value={closeDayData.end_date || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd'), max: '9999-12-31', autoComplete: 'new-password' }}
                />

                { errors.end_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      End date is required.
                    </FormHelperText>
                  </div>
                )}

                { errorDate && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {errorDate}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={!!storeErrors.description || !!updateErrors.description}
                size="small"
                variant="outlined"
              >
                <TextField
                  value={closeDayData.description || ''}
                  onChange={(e) => updateDayOffData('description', e.target.value)}
                  className={(!!storeErrors.description || !!updateErrors.description) && classes.error}
                  multiline
                  fullWidth
                  rowsMin={2}
                  inputProps={{ maxLength: 520, autoComplete: 'new-password' }}
                  variant="outlined"
                  placeholder={i18n.t('Description')}
                  label={i18n.t('Description')}
                  name="description"
                />

                { storeErrors.description && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.description}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.description && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.description}
                    </FormHelperText>
                  </div>
                )}

              </FormControl>
            </Grid>
            {closeDayData.start_date && (
              <Grid item xs={12}>
                <Box className="info-working-schedule-section">
                  {closeDayData.start_date === closeDayData.end_date ? (
                    <>
                      All day on { moment(closeDayData.start_date).format('ll') }
                    </>
                  ) : (
                    <>
                      From { moment(closeDayData.start_date).format('ll') } to { moment(closeDayData.end_date).format('ll') }
                    </>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            data-cy="cancel"
            type="button"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="small"
            data-cy="send"
            type="button"
            onClick={closeDayData.id ? handleUpdateCloseDay : handleAddCloseDay}
            variant="contained"
            color="primary"
          >
            { closeDayData.id ? 'Save' : 'Create' }
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

CloseDayModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeDay: PropTypes.object.isRequired,
  allCloseDays: PropTypes.array.isRequired,
  setAllCloseDays: PropTypes.func.isRequired,
  individualUserIndustryItem: PropTypes.object.isRequired,
  setIndividualUserIndustryItem: PropTypes.func.isRequired,
};

export default CloseDayModal;
