import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../../../../../../style/dropzone.scss';
import '../../../../../../style/general.scss';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import {
  AppBar,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemText,
  Tooltip,
  Menu,
  Box,
  CardContent,
  CardHeader,
  CardMedia,
  List,
  ListItem,
  MenuItem,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import DialogActions from '@material-ui/core/DialogActions';
import i18n from 'i18next';
import NewIndustryModal from '../../../../../../Modals/IndividualUserIndustry/NewIndustryModal';
import {
  deleteIndividualUserIndustryRequest,
  getIndividualUserIndustriesRequest,
} from '../../../../../../redux/individualUser/actions';
import IndividualUserOnBoarding from '../../../../../../Modals/IndividualUserIndustry/IndividualUserWizard/IndividualUserOnBoarding';
import ConfirmDeleteIndustryModal from '../../../../../../Modals/IndividualUserIndustry/ConfirmDeleteIndustryModal';
import { getIndustriesRequest } from '../../../../../../redux/industry/actions';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import industryLogo from '../../../../../../assets/img/industry.svg';
import noIndustry from '../../../../../../assets/img/no-industry.svg';
import BoxLoading from '../../../../../../Components/Loading/BoxLoading';
import LightTooltip from '../../../../../../MaterialComponents/lightTooltip';
import SnackbarToast from '../../../../../../Modules/SnackbarToast';

function IndividualUserIndustries(props) {
  const {
    history,
    getIndividualUserIndustries,
    isGetIndividualUserIndustriesSuccess,
    isGetIndividualUserIndustriesError,
    allIndividualUserIndustries,
    isDeleteIndividualUserIndustrySuccess,
    isDeleteIndividualUserIndustryError,
    deleteIndividualUserIndustryErrorMessage,
    deleteIndividualUserIndustry,
  } = props;

  const prevIsDeleteIndividualUserIndustrySuccess = usePrevious(isDeleteIndividualUserIndustrySuccess);
  const prevIsDeleteIndividualUserIndustryError = usePrevious(isDeleteIndividualUserIndustryError);

  const [alert, setAlert] = useState(null);
  const [addIndustryModalOpen, setAddIndustryModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [individualUserIndustries, setIndividualUserIndustries] = useState([]);
  const [isIndividualUserModalShow, setIsIndividualUserModalShow] = useState(false);
  const [individualIndustries, setIndividualIndustries] = useState([]);
  const [selectedIndividual, setSelectedIndividual] = useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showPendingAppointmentsModal, setShowPendingAppointmentsModal] = useState(false);
  const [isConfirmDeleteIndustryModalShow, setIsConfirmDeleteIndustryModalShow] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const open = Boolean(anchorEl);

  // Perform once, when function is mounted
  useEffect(() => {
    getIndividualUserIndustries();
  }, []);

  useEffect(() => {
    if (individualUserIndustries.length) {
      const individualUserIds = individualUserIndustries.map((item) => parseInt(item.industry_id));
      setIndividualIndustries(individualUserIds);
    }
  }, [individualUserIndustries]);

  useEffect(() => {
    if (isGetIndividualUserIndustriesSuccess) {
      setLoading(false);
      setIndividualUserIndustries([...allIndividualUserIndustries.sort((a, b) => a.id - b.id)]);
    }
  }, [isGetIndividualUserIndustriesSuccess]);

  useEffect(() => {
    if (isGetIndividualUserIndustriesError) {
      snackBarAlert(true, 'Can not find individual users', 'error');
    }
  }, [isGetIndividualUserIndustriesError]);

  useEffect(() => {
    if (prevIsDeleteIndividualUserIndustrySuccess === false && isDeleteIndividualUserIndustrySuccess) {
      history.push('/industries');
      setLoading(false);
      setAlert(null);
      snackBarAlert(true, i18n.t('IndustryDeleted'), 'success');
      setIsConfirmDeleteIndustryModalShow(false);
    }
  }, [isDeleteIndividualUserIndustrySuccess]);

  useEffect(() => {
    if (prevIsDeleteIndividualUserIndustryError === false && isDeleteIndividualUserIndustryError) {
      setLoading(false);
      setAlert(null);
      snackBarAlert(true, deleteIndividualUserIndustryErrorMessage, 'error');
    }
  }, [isDeleteIndividualUserIndustryError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleCloseModal = (individualUserModalShow) => {
    setIsIndividualUserModalShow(individualUserModalShow);
  };

  const openAddIndustryModal = (event) => {
    event.preventDefault();
    setAddIndustryModalOpen(true);
  };

  const addNewIndustry = (newIndustry) => {
    const individualUserIndustriesCopy = [...individualUserIndustries];
    individualUserIndustriesCopy.push(newIndustry);
    setIndividualUserIndustries(individualUserIndustriesCopy.sort((a, b) => a.id - b.id));
  };

  const handleConfirmDeleteIndustry = (selectedIndividual) => {
    setAlert(alert);
    const allIndividualUserIndustriesCopy = [...individualUserIndustries];
    if (allIndividualUserIndustriesCopy.length > 0) {
      const selectedIndividualIndex = allIndividualUserIndustriesCopy.findIndex((item) => item.id === selectedIndividual.id);
      allIndividualUserIndustriesCopy.splice(selectedIndividualIndex, 1);
      setIndividualUserIndustries(allIndividualUserIndustriesCopy.sort((a, b) => a.id - b.id));
      deleteIndividualUserIndustry({ id: selectedIndividual.id });
      handleClose();
      setLoading(true);
    }
  };

  const closeConfirmDeleteIndustryModal = () => {
    setIsConfirmDeleteIndustryModalShow(false);
  };

  const showConfirmDeleteIndividual = (e) => {
    e.preventDefault();
    setAnchorEl(false);
    const statuses = [];

    selectedIndividual.appointments.map((appointment) => {
      statuses.push(appointment.status.id);
    });

    if (selectedIndividual.appointments !== {} && (statuses.includes(1) || statuses.includes(2))) {
      setShowPendingAppointmentsModal(true);
    } else {
      setIsConfirmDeleteIndustryModalShow(true);
    }
  };

  const handleClick = (event, individual) => {
    setAnchorEl(event.currentTarget);
    setSelectedIndividual(individual);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToIndustry = () => {
    history.push(`/industries/${selectedIndividual.id}`);
  };

  return !loading ? (
    <div className="industries menu-item">
      <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              <Box display="flex" alignItems="center" height="100%">
                  Industries
              </Box>
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={(event) => openAddIndustryModal(event)}
                size="small"
              >
                Add Industry
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div className="tab-industries">
        <div className="box-general">
          <div className="main-content">
            <Box display="flex" flexWrap="wrap" justifyContent="flex-start" width="100%">
              <Tooltip title="New Industries">
                <Card className="card-plus-box" onClick={(event) => openAddIndustryModal(event)}>
                  <CardMedia className="img-header" />
                </Card>
              </Tooltip>
              { individualUserIndustries.length > 0 ? individualUserIndustries.map((individual) => (
                <div key={individual.industry_id}>
                  <Box m="15px" maxWidth="220px">
                    <Card className="card-box-root box-cursor">
                      <CardHeader
                        className="card-header-service"
                        action={(
                          <div>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(event) => handleClick(event, individual)}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              id="long-menu"
                              elevation={1}
                              anchorEl={anchorEl}
                              keepMounted
                              open={open}
                              onClose={handleClose}
                              PaperProps={{
                                style: {
                                  width: '20ch',
                                },
                              }}
                            >
                              <Link
                                to="#"
                                onClick={() => redirectToIndustry(individual)}
                                className="card-box-item"
                              >
                                <MenuItem>
                                  Edit
                                </MenuItem>
                              </Link>
                              <Link
                                to="#"
                                onClick={(e) => showConfirmDeleteIndividual(e, individual, individual.industry_id)}
                                className="card-box-item"
                              >
                                <MenuItem>
                                  Delete
                                </MenuItem>
                              </Link>
                            </Menu>
                          </div>
                        )}
                        title={(
                          <LightTooltip title={individual.industry_name} placement="top-start">
                            <span>
                              {individual.industry_name}
                            </span>
                          </LightTooltip>
                        )}
                      />
                      <Box
                        key={individual.id}
                        onClick={() => { history.push(`/industries/${individual.id}`); }}
                      >
                        <CardMedia className="img-header">
                          <img className="card-box-img" src={individual.avatar ? individual.avatar : industryLogo} alt="industry" />
                        </CardMedia>
                      </Box>
                      <CardContent className="content-card-box">
                        <List disablePadding>
                          <ListItem disableGutters dense>
                            <ListItemText className="icon-card-box">
                              <SettingsApplicationsIcon fontSize="small" />
                            </ListItemText>
                            <ListItemText primary="Services:" className="details-card-box detail-name" />
                            <ListItemText secondary={individual.services ? individual.services.length : 0} className="details-card-box" />
                          </ListItem>
                          <ListItem disableGutters dense>
                            <ListItemText className="icon-card-box">
                              <PersonOutlineIcon fontSize="small" />
                            </ListItemText>
                            <ListItemText primary="Customers:" className="details-card-box detail-name" />
                            <ListItemText secondary={individual.customers ? individual.customers.length : 0} className="details-card-box" />
                          </ListItem>
                        </List>
                      </CardContent>
                    </Card>
                  </Box>
                </div>
              )) : (
                <Box className="industry-page" mx="auto">
                  <Box>
                    <Box>
                      <img src={noIndustry} alt="noCustomer" />
                    </Box>
                    <Typography component="div">
                      <Box fontWeight="500" align="center">
                        <Typography variant="h6" color="secondary">
                          You don&apos;t have a industries yet
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              )}
              {alert}
            </Box>
          </div>
        </div>
      </div>
      <NewIndustryModal
        open={addIndustryModalOpen}
        onClose={() => setAddIndustryModalOpen(false)}
        addNewIndustry={() => addNewIndustry()}
        individualUserIndustries={individualUserIndustries}
        setIndividualUserIndustries={setIndividualUserIndustries}
      />
      { isIndividualUserModalShow && (
        <IndividualUserOnBoarding
          individualIndustries={individualIndustries}
          handleCloseModal={(individualUserModalShow) => handleCloseModal(individualUserModalShow)}
        />
      )}

      {isConfirmDeleteIndustryModalShow && (
      <ConfirmDeleteIndustryModal
        onClose={closeConfirmDeleteIndustryModal}
        handleConfirmDeleteIndustry={handleConfirmDeleteIndustry}
        selectedIndividual={selectedIndividual}
      />
      )}

      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />

      <Dialog
        onClose={() => setShowPendingAppointmentsModal(false)}
        aria-labelledby="customized-dialog-title"
        open={showPendingAppointmentsModal}
      >
        <DialogTitle onClose={() => setShowPendingAppointmentsModal(false)}>
          <span className="alert-title">
            <span>Error</span>
            <span>
              <IconButton aria-label="close" className="close-btn" onClick={() => setShowPendingAppointmentsModal(false)}>
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>You can not delete this industry because it has upcoming appointments</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            size="small"
            onClick={() => setShowPendingAppointmentsModal(false)}
          >
              Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : <BoxLoading />;
}

IndividualUserIndustries.propTypes = {
  // Get Individual User Industry Index Props
  history: PropTypes.object.isRequired,
  getIndividualUserIndustries: PropTypes.func.isRequired,
  isGetIndividualUserIndustriesSuccess: PropTypes.bool.isRequired,
  isGetIndividualUserIndustriesError: PropTypes.bool.isRequired,
  allIndividualUserIndustries: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  // Delete Individual User Industry
  deleteIndividualUserIndustry: PropTypes.func.isRequired,
  isDeleteIndividualUserIndustrySuccess: PropTypes.bool.isRequired,
  isDeleteIndividualUserIndustryError: PropTypes.bool.isRequired,
  deleteIndividualUserIndustryErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // All Individual User Industry Index
  isGetIndividualUserIndustriesSuccess: state.individualUser.isGetIndividualUserIndustriesSuccess,
  isGetIndividualUserIndustriesError: state.individualUser.isGetIndividualUserIndustriesError,
  allIndividualUserIndustries: state.individualUser.allIndividualUserIndustries,
  userAccount: state.account.userAccount,
  industries: state.industry.industries,
  isGetIndustriesSuccess: state.industry.isGetIndustriesSuccess,
  isGetIndustriesError: state.industry.isGetIndustriesError,
  // Delete Individual User Industry
  isDeleteIndividualUserIndustrySuccess: state.individualUser.isDeleteIndividualUserIndustrySuccess,
  isDeleteIndividualUserIndustryError: state.individualUser.isDeleteIndividualUserIndustryError,
  deleteIndividualUserIndustryErrorMessage: state.individualUser.deleteIndividualUserIndustryErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndividualUserIndustries: (data) => dispatch(getIndividualUserIndustriesRequest(data)),
    getIndustries: (data) => dispatch(getIndustriesRequest(data)),
    deleteIndividualUserIndustry: (data) => dispatch(deleteIndividualUserIndustryRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualUserIndustries);
