import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  FormControl,
  Grid,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import i18n from 'i18next';
import {
  getIndustriesRequest,
} from '../../../../../../../redux/industry/actions';
import {
  getCitiesRequest,
  getStatesRequest,
} from '../../../../../../../redux/country/actions';
import InfoLoading from '../../../../../../../Components/Loading/infoLoading';
import countryCodeOptions from '../../../../../../../Modules/countryCodeOptions';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';

function Info(props) {
  const {
    company,
    getIndustries,
    industries,
    isGetIndustriesSuccess,
    isGetIndustriesError,
    allCountries,
    getStates,
    states,
    isGetStatesSuccess,
    isGetStatesError,
    getCities,
    cities,
    isGetCitiesSuccess,
    isGetCitiesError,
  } = props;

  const [loading, setLoading] = useState(true);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState({
    value: '',
  });
  const [selectedCountryOption, setSelectedCountryOption] = useState({
    value: '',
  });
  const [codeOptions, setCodeOptions] = useState([]);
  const [userCompanyCountry, setUserCompanyCountry] = useState({});
  const [selectedStateOption, setSelectedStateOption] = useState({
    value: '',
  });
  const prevIsGetCitiesSuccess = usePrevious(isGetCitiesSuccess);
  const [selectedCityOption, setSelectedCityOption] = useState({
    value: '',
  });
  const [selectedFlag, setSelectedFlag] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Perform when function gets mounted
  useEffect(() => {
    const countryOptions = countryCodeOptions.getCountryOptions(allCountries);
    const codeOptions = countryCodeOptions.getCodeOptions(allCountries);
    setCodeOptions(codeOptions);
    if (Object.keys(company).length) {
      const userCompanyCountry = countryOptions.find((item) => item.id === parseInt(company.country_id));
      setUserCompanyCountry(userCompanyCountry);
      const selectedCountryOption = countryOptions.find((item) => item.id === parseInt(company.country_id));
      setSelectedCountryOption(selectedCountryOption);
      getIndustries();
    }
  }, []);

  useEffect(() => {
    if (company && company.phone_code) {
      const currentCodeOption = codeOptions.find((item) => item.value === company.phone_code);
      if (currentCodeOption && currentCodeOption.label.flagImageSrc) {
        setSelectedFlag(currentCodeOption.label.flagImageSrc);
      }
    }
  }, [codeOptions, company]);

  // Perform when function gets mounted
  useEffect(() => {
    getIndustries();
    getStates(company.country_id);
    getCities(company.state_id);
  }, []);

  // Check If Get Industries Is Success
  useEffect(() => {
    if (isGetIndustriesSuccess) {
      const industryOptions = industries.map((industry) => ({
        value: industry.id,
        label: industry.name,
      }));
      const selectedIndustryOption = industryOptions.find((item) => item.value === parseInt(company.industry_id));
      setSelectedIndustryOption(selectedIndustryOption);
      if (company.country_id) {
        getStates(company.country_id);
      }
    }
  }, [isGetIndustriesSuccess]);

  // Handle Get Industries Error
  useEffect(() => {
    if (isGetIndustriesError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
      setLoading(true);
    }
  }, [isGetIndustriesError]);

  // Handle Get States Error
  useEffect(() => {
    if (isGetStatesError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
      setLoading(true);
    }
  }, [isGetStatesError]);

  // Handle Get Cities Error
  useEffect(() => {
    if (isGetCitiesError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
      setLoading(false);
    }
  }, [isGetCitiesError]);

  // Check If Get States Is Success
  useEffect(() => {
    if (isGetStatesSuccess) {
      const stateOptions = countryCodeOptions.getStateOptions(states);
      if (selectedCountryOption.id === userCompanyCountry.id) {
        if (company.state_id) {
          const selectedStateOption = stateOptions.find((item) => item.id === company.state_id);
          if (selectedStateOption && company.state_id) {
            setSelectedStateOption(selectedStateOption);
            getCities(company.state_id);
          } else {
            setLoading(false);
          }
        }
      }
    }
  }, [isGetStatesSuccess]);

  // Check If Get Cities Is Success
  useEffect(() => {
    if (prevIsGetCitiesSuccess === false && isGetCitiesSuccess) {
      const cityOptions = countryCodeOptions.getCitiesOptions(cities);
      if (selectedCountryOption.id === userCompanyCountry.id) {
        const selectedCityOption = cityOptions.find((item) => item.id === company.city_id);
        if (selectedCityOption) {
          setSelectedCityOption(selectedCityOption);
        }
        setLoading(false);
      }
    }
  }, [isGetCitiesSuccess]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  return (
    <>
      {!loading ? (
        <>
          <Grid container direction="row" id="fixed-box" spacing={2}>
            <Grid item xs={12} sm={8}>
              <Grid container direction="row" alignItems="center" spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    name="name"
                    value={company.name || ''}
                    fullWidth
                    label="Company name"
                    InputProps={{
                      readOnly: true,
                      autoComplete: 'new-password',
                      maxLength: 60,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      value={selectedIndustryOption.label ? selectedIndustryOption.label : ''}
                      label="Industry"
                      InputProps={{
                        readOnly: true,
                        autoComplete: 'new-password',
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      value={selectedCountryOption.value}
                      label="Country"
                      InputProps={{
                        readOnly: true,
                        autoComplete: 'new-password',
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      value={selectedStateOption.value}
                      label="State"
                      InputProps={{
                        readOnly: true,
                        autoComplete: 'new-password',
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      value={selectedCityOption.value}
                      label="City"
                      InputProps={{
                        readOnly: true,
                        autoComplete: 'new-password',
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    size="small"
                    name="address"
                    label="Address"
                    value={company.address || ''}
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      autoComplete: 'new-password',
                    }}
                  />
                </Grid>
                <Grid item sm={2}>
                  <FormControl fullWidth>
                    <TextField
                      size="small"
                      name="phone_code"
                      label={i18n.t('PhoneCode')}
                      value={company.phone_code}
                      InputProps={{
                        readOnly: true,
                        autoComplete: 'new-password',
                        startAdornment: (
                          <InputAdornment
                            position="start"
                          >
                            <img
                              src={selectedFlag}
                              alt="flag"
                              className="country-flag"
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={4}>
                  <TextField
                    fullWidth
                    size="small"
                    id="phone-detail"
                    type="number"
                    value={company.phone_number || ''}
                    name="phone_number"
                    data-cy="phone"
                    label="Phone number"
                    InputProps={{
                      readOnly: true,
                      autoComplete: 'new-password',
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={4}>
              {company.logo ? (
                <Box display="flex" alignItems="center" id="staff-info">
                  <Box data-cy="image" className="image-content">
                    <img src={company.logo} alt="Company" />
                  </Box>
                </Box>
              ) : null }
            </Grid>
          </Grid>
          <SnackbarToast
            message={snackbarMessage}
            type={snackbarType}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
          />
        </>
      ) : <InfoLoading />}
    </>
  );
}

Info.propTypes = {
  company: PropTypes.object.isRequired,
  // Get states
  states: PropTypes.array.isRequired,
  getStates: PropTypes.func.isRequired,
  isGetStatesSuccess: PropTypes.bool.isRequired,
  isGetStatesError: PropTypes.bool.isRequired,
  // Get Countries
  allCountries: PropTypes.array.isRequired,
  // Get Cities
  cities: PropTypes.array.isRequired,
  getCities: PropTypes.func.isRequired,
  isGetCitiesSuccess: PropTypes.bool.isRequired,
  isGetCitiesError: PropTypes.bool.isRequired,
  // Get Industries
  industries: PropTypes.array.isRequired,
  getIndustries: PropTypes.func.isRequired,
  isGetIndustriesSuccess: PropTypes.bool.isRequired,
  isGetIndustriesError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  // Get Industries
  industries: state.industry.industries,
  isGetIndustriesSuccess: state.industry.isGetIndustriesSuccess,
  isGetIndustriesError: state.industry.isGetIndustriesError,
  // Get Countries
  allCountries: state.country.allCountries,
  // Get States
  states: state.country.states,
  isGetStatesSuccess: state.country.isGetStatesSuccess,
  isGetStatesError: state.country.isGetStatesError,
  // Get Cities
  cities: state.country.cities,
  isGetCitiesSuccess: state.country.isGetCitiesSuccess,
  isGetCitiesError: state.country.isGetCitiesError,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndustries: (data) => dispatch(getIndustriesRequest(data)),
    getStates: (data) => dispatch(getStatesRequest(data)),
    getCities: (data) => dispatch(getCitiesRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);
