import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { MenuItem, Select } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import hours from '../../../../../../../Modules/hours';

function BreakingHours(props) {
  const {
    breakingHours,
    workingHours,
  } = props;

  const getAvailableBreakingHours = (hours, companyWorkingHours, weekDayId, breakingStart, breakingEnd) => {
    const startTime = moment(companyWorkingHours[weekDayId - 1].start_time, 'HH::mm').format('HH:mm');
    let endTime = moment(companyWorkingHours[weekDayId - 1].end_time, 'HH::mm').format('HH:mm');
    endTime = endTime !== '00:00' ? endTime : '24:00';
    if (breakingStart && !hours.includes(breakingStart)) {
      hours.push(breakingStart);
      hours.sort();
    } else if (breakingEnd && !hours.includes(breakingEnd)) {
      hours.push(breakingEnd);
      hours.sort();
    }
    return hours.filter((hour) => (hour >= startTime && hour <= endTime));
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Box py="25px" px="30px" className="tab-item" id="tab-main-parent">
      <Box display="flex" m="0">
        <Box className="business-hours">
          <div className="hours-content">
            { breakingHours.map((breakHour) => (
              <Box display="flex" minWidth="173px" className="day-content" key={Math.random()}>
                {breakHour.is_breaking ? (
                  <Box minWidth="91px">
                    <div className="hours-select">
                      <Select
                        variant="outlined"
                        disabled
                        disableUnderline
                        data-cy="breaking-start-time"
                        value={breakHour.start_time}
                        name="start_time"
                        MenuProps={MenuProps}
                      >
                        { getAvailableBreakingHours(hours, workingHours, breakHour.week_day_id, breakHour.start_time, null)
                          .map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
                      </Select>
                      <span>-</span>
                      <Select
                        variant="outlined"
                        disabled
                        data-cy="breaking-end-time"
                        disableUnderline
                        value={breakHour.end_time}
                        name="end_time"
                        MenuProps={MenuProps}
                      >
                        { getAvailableBreakingHours(hours, workingHours, breakHour.week_day_id, null, breakHour.end_time).map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
                      </Select>
                    </div>
                  </Box>
                ) : (
                  <Box className="selects-border" />
                )}
              </Box>
            )) }
          </div>
        </Box>
      </Box>
    </Box>
  );
}

BreakingHours.propTypes = {
  workingHours: PropTypes.array.isRequired,
  breakingHours: PropTypes.array.isRequired,
};
export default BreakingHours;
