import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Icon,
  List,
  Toolbar,
  ListItem,
  Drawer,
  AppBar,
  Collapse,
  CssBaseline,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import WhiteLogo from 'assets/img/white-logo.svg';
import Bird from 'assets/img/bird.svg';
import menuItems from 'Modules/menuItems';
import CreateAccountModal from 'Modals/Account/createNewAccountModal';
import SwitchToAccountModal from 'Modals/Account/switchToAccountModal';
import IndividualUserOnBoarding from 'Modals/IndividualUserIndustry/IndividualUserWizard/IndividualUserOnBoarding';
import usePrevious from 'CustomHooks/usePrevious';
import LightTooltip from 'MaterialComponents/lightTooltip';
import PermissionsModule from 'Modules/Permission';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import liveChat from 'Modules/crisp';
import TopBar from '../TopBar/topBar';
import useStyles from './navBarAnimation';
import { getOwnerCompanyRequest } from '../../redux/company/actions';

function NewNavBar(props) {
  const {
    account,
    children,
    handleLogout,
    notifications,
    notificationsCount,
    userLocationInfo,
  } = props;

  const location = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const {
    companyGot,
  } = useSelector((state) => state.company);

  const [open, setOpen] = useState(localStorage.getItem('menuOpen') === 'true');
  const [rolesPagesAccess, setRolesPagesAccess] = useState({});
  const [openNavLinks, setOpenNavLinks] = useState(['General']);
  const [pagesKeys, setPagesKeys] = useState([]);
  const [showCreateAccountModal, setShowCreateAccountModal] = useState(false);
  const [createAccountRoleName, setCreateAccountRoleName] = useState('');
  const [showSwitchToAccountModal, setShowSwitchToAccountModal] = useState(false);
  const [switchRoleName, setSwitchRoleName] = useState('');
  const [individualIndustries, setIndividualIndustries] = useState([]);
  const [isIndividualUserModalShow, setIsIndividualUserModalShow] = useState(false);
  const [activePath, setActivePath] = useState('');
  const [activeCollapse, setActiveCollapse] = useState('');

  const classes = useStyles();

  const previousSwitchRoleName = usePrevious(switchRoleName);
  const previousCreateAccountRoleName = usePrevious(createAccountRoleName);
  const previousShowCreateAccountModal = usePrevious(showCreateAccountModal);
  const previousShowSwitchToAccountModal = usePrevious(showSwitchToAccountModal);

  useEffect(() => {
    liveChat();
    Object.keys(companyGot).length === 0 && dispatch(getOwnerCompanyRequest());
  }, []);

  useEffect(() => {
    if (account.activeRole.name === 'Individual' && account.individual_user_is_on_boarded_at === null) {
      localStorage.setItem('prefix', account.activeRole.name);
      const individualUserIds = account.individualUserIndustries.map((item) => parseInt(item.industry_id));
      setIndividualIndustries(individualUserIds);
      setIsIndividualUserModalShow(true);
    }
  }, [account]);

  useEffect(() => {
    const { pathname } = window.location;
    setActivePath(pathname);
    const menuOpen = localStorage.getItem('menuOpen');
    if (menuOpen === 'false') {
      localStorage.setItem('menuOpen', 'false');
      setOpen(false);
    } else {
      setOpen(true);
    }
    const collapseOpen = localStorage.getItem('collapseOpen');
    setActiveCollapse(collapseOpen);
    // generate pages access
    let rolesPagesAccessCopy = PermissionsModule.getPagesAccess(account.testPermissions);
    let rolesData = Object.keys(rolesPagesAccessCopy);

    const rolesCopy = [...rolesData];
    // remove the first item from array
    rolesCopy.shift();

    const [first] = rolesData;
    // get first role pages access
    const firstRolePages = rolesData.length ? [...rolesPagesAccessCopy[first]] : [];
    const general = PermissionsModule.getGeneralRouteLinks(firstRolePages, rolesCopy, rolesPagesAccessCopy);

    // remove links from each role, if the link is in general
    rolesPagesAccessCopy = {
      General: general,
      ...PermissionsModule.removeDuplicateLinks(rolesData, rolesPagesAccessCopy, general),
    };

    rolesData = Object.keys(rolesPagesAccessCopy);
    let newOpenNavLinks = [...openNavLinks];

    if (rolesData.length === 1) {
      newOpenNavLinks = [
        ...newOpenNavLinks,
        first,
      ];
    } else {
      const activeLink = getActiveLink(rolesData, rolesPagesAccessCopy);
      if (activeLink) {
        newOpenNavLinks = [
          ...newOpenNavLinks,
          activeLink,
        ];
      }
    }

    setRolesPagesAccess(rolesPagesAccessCopy);
    setOpenNavLinks(newOpenNavLinks);
  }, []);

  useEffect(() => {
    const pagesKeysData = Object.keys(rolesPagesAccess).filter((roleName) => rolesPagesAccess[roleName].length !== 0);
    setPagesKeys(pagesKeysData);
  }, [rolesPagesAccess]);

  const handleDrawerToggle = () => {
    localStorage.setItem('menuOpen', (!open).toString());
    setOpen(!open);
  };

  const getActiveLink = (rolesData, rolesPagesAccessCopy) => {
    const { pathname } = window.location;
    let activeLink = '';
    rolesData.forEach((role) => {
      rolesPagesAccessCopy[role].forEach((subLink) => {
        if (pathname === subLink || pathname.includes(subLink)) {
          activeLink = role;
          return false;
        }
      });
    });

    return activeLink;
  };

  const checkLinkName = (linkName) => {
    if (linkName !== 'General') {
      localStorage.setItem('prefix', linkName);
    }
  };

  const toggleOpenLink = (linkName) => {
    let newOpenNavLinks = [...openNavLinks];
    const index = openNavLinks.indexOf(linkName);
    localStorage.setItem('prefix', linkName);
    if (index === -1) {
      newOpenNavLinks = [
        ...newOpenNavLinks,
        linkName,
      ];
    }
    const isCollapse = linkName === activeCollapse ? '' : linkName;
    localStorage.setItem('collapseOpen', isCollapse);
    setActiveCollapse(isCollapse);
    setOpenNavLinks(newOpenNavLinks);
  };

  const toUpperCaseEachWord = (string) => string.replace('-', ' ').split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');

  const subLinkExistsForLink = (linkName) => {
    if (rolesPagesAccess && rolesPagesAccess[linkName]) {
      const items = menuItems.filter((item) => rolesPagesAccess[linkName].includes(item.name));

      return items.length > 0;
    }

    return false;
  };

  const renderSubLinks = (linkName) => {
    const { account: { activeRole: { name: roleName } } } = props;
    return ((openNavLinks.length > 0 && openNavLinks.includes(linkName)) || roleName === 'Owner' || roleName === activeCollapse)
          && menuItems.filter((item) => rolesPagesAccess[linkName].includes(item.name) && !(item.name === 'staff' && !companyGot.is_staff_based)).map((item, index) => {
            if (
              (activePath.includes(item.path) || activePath === item.path)
              && linkName !== 'General'
              && (previousSwitchRoleName === switchRoleName && showSwitchToAccountModal === previousShowSwitchToAccountModal)
              && (previousCreateAccountRoleName === createAccountRoleName && previousShowCreateAccountModal === showCreateAccountModal)
            ) {
              const customerPagePath = '/customers/:filter?';
              if (!(customerPagePath.includes(location.pathname) && pagesKeys.includes('Staff') && pagesKeys.includes('Individual'))) {
                localStorage.setItem('prefix', linkName);
              }
            }

            return (
              <NavLink key={`${item.name}-${index}`} to={item.path} activeClassName="active-menu-item" onClick={() => checkLinkName(linkName)}>
                <Box
                  key={item.name}
                  className={`menu-items ${(linkName === 'Individual' || linkName === 'Customer' || linkName === 'Staff') && open ? 'sub-menu-item' : null}`}
                >
                  <ListItem button key={item.id}>
                    <ListItemIcon>
                      <LightTooltip title={item.title} placement="right">
                        <Icon className={classes.menuItemIcon}>{item.icon}</Icon>
                      </LightTooltip>
                    </ListItemIcon>
                    <ListItemText primary={t(`${toUpperCaseEachWord(item.visibleName)}`)} className={classes.menuItem} />
                  </ListItem>
                </Box>
              </NavLink>
            );
          });
  };

  const getCreateAccountProps = ({ showCreateAccountModal, createAccountRoleName }) => {
    setShowCreateAccountModal(showCreateAccountModal);
    setCreateAccountRoleName(createAccountRoleName);
  };

  const getSwitchToAccountProps = ({ showSwitchToAccountModal, switchRoleName }) => {
    setShowSwitchToAccountModal(showSwitchToAccountModal);
    setSwitchRoleName(switchRoleName);
  };

  const handleCloseModal = (individualUserModalShow) => {
    setIsIndividualUserModalShow(individualUserModalShow);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        bgcolor="white"
        className={clsx(classes.appBar, {
          [classes.appBarShiftOpen]: open,
          [classes.appBarShiftClose]: !open,
        })}
      >
        <Toolbar className={`${classes.navbarTool} navbar-tool`}>
          <Box width="100%">
            <TopBar
              userLocationInfo={userLocationInfo}
              handleCreateAccountProps={(data) => getCreateAccountProps(data)}
              handleSwitchToAccountProps={(data) => getSwitchToAccountProps(data)}
              handleLogout={handleLogout}
              account={account}
              notifications={notifications}
              notificationsCount={notificationsCount}
              handleDrawerToggle={handleDrawerToggle}
            />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <Box className={classes.toolbarLogo} bgcolor="primary.main">
          <Box justifyContent="center" pt={2}>
            <img src={open ? WhiteLogo : Bird} alt="Logo" width="100%" onClick={handleDrawerToggle} />
          </Box>
        </Box>
        <List disablePadding>
          { pagesKeys && pagesKeys.map((key, index) => (
            <div key={`${key}-${index}`}>
              {account.activeRole.name !== 'Owner' && key !== 'General' && subLinkExistsForLink(key) && (
              <ListItem button onClick={() => toggleOpenLink(key)} className="menu-role-item">
                <ListItemIcon>
                  { key === 'Customer' ? (
                    <LightTooltip title="Customer" placement="right">
                      <Icon className={classes.menuItemIcon}>perm_identity</Icon>
                    </LightTooltip>
                  ) : key === 'Staff' ? (
                    <LightTooltip title="Staff" placement="right">
                      <Icon className={classes.menuItemIcon}>assignment_ind</Icon>
                    </LightTooltip>
                  ) : (
                    <LightTooltip title="Individual" placement="right">
                      <Icon className={classes.menuItemIcon}>account_circle</Icon>
                    </LightTooltip>
                  ) }
                </ListItemIcon>
                <ListItemText primary={toUpperCaseEachWord(key)} className={classes.menuItem} />
                { key !== activeCollapse ? <ArrowDropDownIcon className={classes.menuItem} /> : <ArrowDropUpIcon className={classes.menuItem} /> }
              </ListItem>
              )}
              <Collapse
                in={!(account.activeRole.name !== 'Owner' && key !== 'General') || key === activeCollapse}
                timeout="auto"
                unmountOnExit
                className="menu-items-list"
              >
                <Box key={`${key}-${index}`}> { renderSubLinks(key) } </Box>
              </Collapse>
            </div>
          )) }
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
      { showCreateAccountModal && (
        <CreateAccountModal
          handleCreateAccountProps={(data) => getCreateAccountProps(data)}
          createAccountRoleName={createAccountRoleName}
        />
      )}
      { showSwitchToAccountModal && (
        <SwitchToAccountModal
          handleSwitchToAccountProps={(data) => getSwitchToAccountProps(data)}
          switchRoleName={switchRoleName}
        />
      )}
      { isIndividualUserModalShow && (
      <IndividualUserOnBoarding
        individualIndustries={individualIndustries}
        handleCloseModal={(individualUserModalShow) => handleCloseModal(individualUserModalShow)}
      />
      )}
    </div>
  );
}

NewNavBar.propTypes = {
  account: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  handleLogout: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
  notificationsCount: PropTypes.number.isRequired,
  userLocationInfo: PropTypes.object.isRequired,
};

export default NewNavBar;
