import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import Box from '@material-ui/core/Box';
import SecondNavbarLoading from './secondNavbarLoading';

function calendarLoading() {
  return (
    <>
      <SecondNavbarLoading />
      <Box p="20px" width="100%">
        <Box>
          <Box width="125px" mt="35px" mb="39px" mx="auto">
            <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
          </Box>
        </Box>
        <Box>
          <ReactPlaceholder showLoadingAnimation type="text" rows={25} color="rgb(224, 224, 224)" />
        </Box>
      </Box>
    </>
  );
}

export default calendarLoading;
