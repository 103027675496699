import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import '../index.scss';
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Tab,
  Tabs,
  Button,
  IconButton,
} from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  Info,
  History,
} from './tabs';
import InfoLoading from '../../../../../../Components/Loading/infoLoading';
import {
  getAppointmentByIdRequest,
  getAllStatusesRequest,
  updateAppointmentRequest,
} from '../../../../../../redux/appointment/actions';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import { getCompanyByIdRequest } from '../../../../../../redux/company/actions';
import SnackbarToast from '../../../../../../Modules/SnackbarToast';

function AppointmentDetails(props) {
  const {
    isUpdateAppointmentSuccess,
    getAppointmentById,
    isGetAppointmentByIdSuccess,
    isGetAppointmentByIdError,
    returnedAppointment,
    getAllStatuses,
    isGetAllStatusesSuccess,
    isGetAllStatusesError,
    allStatuses,
    getCompanyById,
    isGetCompanyByIdSuccess,
    isGetCompanyByIdError,
    getCompanyByIdErrorMessage,
    companyGot,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams();

  const prevIsGetAppointmentByIdSuccess = usePrevious(isGetAppointmentByIdSuccess);
  const prevIsGetAppointmentByIdError = usePrevious(isGetAppointmentByIdError);

  const [loading, setLoading] = useState(true);
  const [appointment, setAppointment] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [staffsCompanyByService, setStaffsCompanyByService] = useState([]);
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);
  const [isButtonClick, setIsButtonClick] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous values
  const prevIsGetCompanyByIdSuccess = usePrevious(isGetCompanyByIdSuccess);
  const prevIsGetCompanyByIdError = usePrevious(isGetCompanyByIdError);

  // Perform, when function gets mounted
  useEffect(() => {
    const { appointmentId } = params;
    getAppointmentById(appointmentId);
  }, []);
  // Perform, when route param appointmentId gets changed
  useEffect(() => {
    const { id } = appointment;
    const { appointmentId } = params;
    if (appointmentId && id !== parseInt(appointmentId)) {
      setLoading(true);
      getAppointmentById(appointmentId);
    }
  }, [params]);
  // Perform, when get appointment by id success
  useEffect(() => {
    if (prevIsGetAppointmentByIdSuccess === false && isGetAppointmentByIdSuccess) {
      setAppointment(returnedAppointment);
      getCompanyById();
      getAllStatuses();
    }
  }, [isGetAppointmentByIdSuccess]);
  // Perform, when get appointment by id error
  useEffect(() => {
    if (prevIsGetAppointmentByIdError === false && isGetAppointmentByIdError) {
      snackBarAlert(true, i18n.t('AppointmentNotFound'), 'error');
      history.push('/calendar');
    }
  }, [isGetAppointmentByIdError]);
  // Perform, when get company by id success
  useEffect(() => {
    if (prevIsGetCompanyByIdSuccess === false && isGetCompanyByIdSuccess) {
      const staffsCompanyByService = [];
      companyGot.staff.map((staff) => {
        staff.staff_services.map((service) => {
          if (service.service_id === appointment.service_id) {
            staffsCompanyByService.push({
              id: staff.id,
              value: staff.full_name,
              label: staff.full_name,
              logo: staff.logo,
            });
          }
        });
      });
      setStaffsCompanyByService(staffsCompanyByService);
    }
  }, [isGetCompanyByIdSuccess]);
  // Perform, when get company by id error
  useEffect(() => {
    if (prevIsGetCompanyByIdError === false && isGetCompanyByIdError) {
      snackBarAlert(true, getCompanyByIdErrorMessage, 'error');
    }
  }, [isGetCompanyByIdError]);
  // Perform, when get appointment all statuses success
  useEffect(() => {
    if (isGetAllStatusesSuccess) {
      setLoading(false);
    }
  }, [isGetAllStatusesSuccess]);
  // Perform, when get appointment all statuses error
  useEffect(() => {
    if (isGetAllStatusesError) {
      setLoading(false);
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetAllStatusesError]);

  // Perform, when update appointment success
  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      setIsButtonDisabled(false);
      setLoading(false);
      snackBarAlert(true, i18n.t('AppointmentUpdated'), 'success');
    }
  }, [isUpdateAppointmentSuccess]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleSaveAppointment = () => {
    setIsButtonClick(true);
  };

  const handleChangeTab = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const isAppointmentCancelled = () => (appointment && appointment.status && appointment.status.name === 'cancelled');

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  return !loading ? (
    <>
      <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
            <Box display="flex" className="tabs-navbar">
              <Box alignSelf="center" className="divider-title">
                <Typography variant="h6">
                  { appointment.title && appointment.title.length ? appointment.title : 'Appointment' }
                </Typography>
              </Box>
              <Box alignSelf="center" ml="-18px" mr="6px">
                <Link to="/my-appointments">
                  <IconButton size="small">
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Link>
              </Box>
              <Box alignSelf="center" className="tab-companies">
                <AppBar position="static" color="white" elevation={0}>
                  <Tabs
                    value={activeTabIndex}
                    aria-label="simple tabs example"
                    variant="scrollable"
                    indicatorColor="primary"
                    textColor="secondary"
                  >
                    <Tab
                      label="Info"
                      onClick={() => handleChangeTab(0)}
                      {...a11yProps(0)}
                    />
                    <Tab
                      label="History"
                      onClick={() => handleChangeTab(1)}
                      {...a11yProps(1)}
                    />
                  </Tabs>
                </AppBar>
              </Box>
            </Box>
            <Box align="right">
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={handleSaveAppointment}
                disabled={isButtonDisabled || isAppointmentCancelled()}
              >
                {t('Update')}
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div className="companies menu-item">
        <Box className="tab-list companies-tab-list">
          {activeTabIndex === 0 && (
            <Info
              appointment={appointment}
              staffsCompanyByService={staffsCompanyByService}
              allStatuses={allStatuses}
              isAppointmentCancelled={isAppointmentCancelled()}
              isAppointmentUpdate={isButtonClick}
              setIsAppointmentUpdate={setIsButtonClick}
              setIsButtonDisabled={setIsButtonDisabled}
            />
          )}
          {activeTabIndex === 1 && (
            <History
              history={appointment.history}
            />
          )}
        </Box>
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  ) : <InfoLoading />;
}

AppointmentDetails.propTypes = {
  updateAppointmentErrorMessage: PropTypes.string.isRequired,
  isUpdateAppointmentError: PropTypes.bool.isRequired,
  updateAppointment: PropTypes.func.isRequired,
  updatedAppointment: PropTypes.object.isRequired,
  isUpdateAppointmentSuccess: PropTypes.bool.isRequired,
  getAppointmentById: PropTypes.func.isRequired,
  isGetAppointmentByIdSuccess: PropTypes.bool.isRequired,
  isGetAppointmentByIdError: PropTypes.bool.isRequired,
  returnedAppointment: PropTypes.object.isRequired,
  getAllStatuses: PropTypes.func.isRequired,
  isGetAllStatusesSuccess: PropTypes.bool.isRequired,
  isGetAllStatusesError: PropTypes.bool.isRequired,
  allStatuses: PropTypes.array.isRequired,
  getCompanyById: PropTypes.func.isRequired,
  isGetCompanyByIdSuccess: PropTypes.bool.isRequired,
  isGetCompanyByIdError: PropTypes.bool.isRequired,
  companyGot: PropTypes.object.isRequired,
  getCompanyByIdErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // Get appointment by id
  isUpdateAppointmentSuccess: state.appointment.isUpdateAppointmentSuccess,
  updatedAppointment: state.appointment.updatedAppointment,
  isUpdateAppointmentError: state.appointment.isUpdateAppointmentError,
  isGetAppointmentByIdSuccess: state.appointment.isGetAppointmentByIdSuccess,
  isGetAppointmentByIdError: state.appointment.isGetAppointmentByIdError,
  returnedAppointment: state.appointment.returnedAppointment,
  // Get company by id
  isGetCompanyByIdSuccess: state.company.isGetCompanyByIdSuccess,
  isGetCompanyByIdError: state.company.isGetCompanyByIdError,
  companyGot: state.company.companyGot,
  getCompanyByIdErrorMessage: state.company.getCompanyByIdErrorMessage,
  // Get all appointment statuses
  updateAppointmentErrorMessage: state.appointment.updateAppointmentErrorMessage,
  isGetAllStatusesSuccess: state.appointment.isGetAllStatusesSuccess,
  isGetAllStatusesError: state.appointment.isGetAllStatusesError,
  allStatuses: state.appointment.allStatuses,
});

function mapDispatchToProps(dispatch) {
  return {
    getCompanyById: (data) => dispatch(getCompanyByIdRequest(data)),
    getAppointmentById: (data) => dispatch(getAppointmentByIdRequest(data)),
    getAllStatuses: () => dispatch(getAllStatusesRequest()),
    updateAppointment: (data) => dispatch(updateAppointmentRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails);
