import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCompanyCustomersRequest } from 'redux/customer/actions';
import { customersHeadCells } from 'Modules/constants';
import usePrevious from 'CustomHooks/usePrevious';
import SnackbarToast from 'Modules/SnackbarToast';
import Header from './Header';
import EnhancedTable from './Table/index';
import CustomerContext from './customerContext';
import CustomerDrawer from './drawer';
import CustomerDrawer2 from './NewDrawer';

function NewCustomers() {
  const dispatch = useDispatch();

  const {
    isGetAllCustomersSuccess,
    allCompanyCustomers,
    isUpdateCustomerSuccess,
    updateCustomerSucceed,
    isDeleteCustomerSuccess,
    allCompanyCustomersCount,
  } = useSelector((state) => state.customers);

  const {
    isStoreCustomerToCompanySuccess,
    newCustomerSucceed,
  } = useSelector((state) => state.company);

  // Prev values
  const prevIsGetAllCustomersSuccess = usePrevious(isGetAllCustomersSuccess);
  const prevIsStoreCustomerToCompanySuccess = usePrevious(isStoreCustomerToCompanySuccess);
  const prevIsUpdateCustomerSuccess = usePrevious(isUpdateCustomerSuccess);
  const prevIsDeleteCustomerSuccess = usePrevious(isDeleteCustomerSuccess);

  const [customers, setCustomers] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [selected, setSelected] = useState([]);
  const [deletedCustomerId, setDeletedCustomerId] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [customersCount, setCustomersCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [snackbarData, setSnackbarData] = useState({
    message: '',
    type: '',
    open: false,
  });

  useEffect(() => {
    // if (!allCompanyCustomers.length) {
    //   dispatch(getAllCompanyCustomersRequest({ limit: 10, page: 1 }));
    // } else {
    //   setCustomersCount(allCompanyCustomersCount);
    //   setCustomers(allCompanyCustomers);
    // }
    dispatch(getAllCompanyCustomersRequest({ limit: 10, page: 1 }));
  }, []);

  useEffect(() => {
    if (prevIsGetAllCustomersSuccess === false && isGetAllCustomersSuccess) {
      setCustomers(allCompanyCustomers);
      setCustomersCount(allCompanyCustomersCount);
      setLoading(false);
    }
  }, [isGetAllCustomersSuccess]);

  useEffect(() => {
    if (prevIsStoreCustomerToCompanySuccess === false && isStoreCustomerToCompanySuccess) {
      setCustomersCount(customersCount + 1);
      setOpen(false);
      if (customers.length < rowsPerPage) {
        const customersCopy = [...customers];
        customersCopy.push(newCustomerSucceed);
        setCustomers(customersCopy);
      }
    }
  }, [isStoreCustomerToCompanySuccess]);

  useEffect(() => {
    if (prevIsUpdateCustomerSuccess === false && isUpdateCustomerSuccess) {
      const customersCopy = [...customers];
      const foundedIndex = customersCopy.findIndex((item) => item.id === updateCustomerSucceed.id);
      customersCopy[foundedIndex] = updateCustomerSucceed;
      setCustomers(customersCopy);
      setOpenEditDrawer(false);
    }
  }, [isUpdateCustomerSuccess]);

  useEffect(() => {
    if (prevIsDeleteCustomerSuccess === false && isDeleteCustomerSuccess) {
      const customersCopy = [...customers];
      let data;
      if (selected.length) {
        data = customersCopy.filter((item) => !selected.includes(item.id));
        setCustomersCount(customersCount - selected.length);
      } else {
        data = customersCopy.filter((item) => !deletedCustomerId.includes(item.id));
        setCustomersCount(customersCount - 1);
      }
      setCustomers(data);
      setOpenEditDrawer(false);
      setIsModalOpen(false);
      setSelected([]);
      setDeletedCustomerId([]);
    }
  }, [isDeleteCustomerSuccess]);

  const deleteCustomer = (e, id) => {
    e.stopPropagation();
    setDeletedCustomerId([id]);
    setSelected([]);
    setIsModalOpen(true);
  };

  return (
    <CustomerContext.Provider value={{
      open,
      setOpen,
      openEditDrawer,
      setOpenEditDrawer,
      isUpdate,
      setIsUpdate,
      selectedIndex,
      setSelectedIndex,
      setSelected,
      selected,
      isModalOpen,
      setIsModalOpen,
      selectedCustomer,
      setSelectedCustomer,
      deleteCustomer,
      deletedCustomerId,
      setDeletedCustomerId,
      snackbarData,
      setSnackbarData,
      customersCount,
    }}
    >
      <Header />
      <div style={{ height: 'calc(100vh - 128px)' }}>
        <EnhancedTable
          rows={customers}
          headCells={customersHeadCells}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          loading={loading}
        />
        <CustomerDrawer selectedCustomer={isUpdate ? selectedCustomer : {}} />
        <CustomerDrawer2 selectedCustomer={selectedCustomer} />
      </div>
      <SnackbarToast
        open={snackbarData.open}
        message={snackbarData.message}
        type={snackbarData.type}
        onClose={() => setSnackbarData((prev) => ({ ...prev, open: false }))}
      />
    </CustomerContext.Provider>
  );
}

export default NewCustomers;
