const imgStyle = {
  thumbsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '100%',
  },

  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    height: 150,
    padding: 4,
    boxSizing: 'border-box',
  },

  thumbInner: {
    position: 'relative',
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  },

  img: {
    display: 'block',
    width: '100%',
    height: '100%',
  },
};

export default imgStyle;
