import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { verifyEmailRequest } from '../../../redux/account/actions';
import Loading from '../../../Components/Loading/loading';
import SnackbarToast from '../../../Modules/SnackbarToast';

class EmailVerify extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    verifyEmail: PropTypes.func.isRequired,
    isVerifyEmailSuccess: PropTypes.bool.isRequired,
    isVerifyEmailError: PropTypes.bool.isRequired,
    handleEmailVerified: PropTypes.func.isRequired,
    verifyEmailErrors: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      snackbarMessage: '',
      snackbarType: '',
      openSnackbar: false,
    };
  }

  componentDidMount() {
    const { match, verifyEmail } = this.props;
    const { token } = match.params;
    if (token) {
      verifyEmail({ token });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      history,
      isVerifyEmailSuccess,
      isVerifyEmailError,
      handleEmailVerified,
      verifyEmailErrors,
    } = this.props;
    // Email verified success
    if (!prevProps.isVerifyEmailSuccess && isVerifyEmailSuccess && !isVerifyEmailError) {
      localStorage.setItem('emailJustVerified', '1');
      handleEmailVerified(true);
      this.snackBarAlert(true, 'Email successfully verified', 'success');
      history.push('/onboarding');
    }
    // Email verified error
    if (!prevProps.isVerifyEmailError && isVerifyEmailError && !isVerifyEmailSuccess) {
      localStorage.setItem('emailJustVerified', '0');
      verifyEmailErrors.token.map((item) => this.snackBarAlert(true, item, 'error'));
      history.push('/login');
    }
  }

  snackBarAlert = (snackOpen, snackMessage, snackType) => {
    this.setState({
      snackbarType: snackType,
      openSnackbar: snackOpen,
      snackbarMessage: snackMessage,
    });
  };

  render() {
    const {
      openSnackbar,
      snackbarType,
      snackbarMessage,
    } = this.state;

    return (
      <>
        <Loading />
        <SnackbarToast
          type={snackbarType}
          open={openSnackbar}
          message={snackbarMessage}
          onClose={() => this.setState({ openSnackbar: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  isGetUserAccountSuccess: state.account.isGetUserAccountSuccess,
  isVerifyEmailSuccess: state.account.isVerifyEmailSuccess,
  isVerifyEmailError: state.account.isVerifyEmailError,
  verifyEmailErrors: state.account.verifyEmailErrors,
});

function mapDispatchToProps(dispatch) {
  return {
    verifyEmail: (data) => dispatch(verifyEmailRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerify);
