import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import deleteIcon from 'assets/icons/delete.svg';
import editIcon from 'assets/icons/edit.svg';
import { useSelector } from 'react-redux';
import serviceDuration from 'Modules/serviceDuration';
import getServicePrice from 'Modules/getServicePrice';
import ServiceContext from '../serviceContext';

const StyledTableRow = withStyles(() => ({
  root: {
    cursor: 'pointer',
  },
}))(TableRow);

const TableBodyCell = withStyles(() => ({
  root: {
    color: '#393939',
    font: 'normal 500 14px/19px Nunito Sans, sans-serif',
  },
}))(TableCell);

function Body({
  selectedCategoryServices,
  rowsPerPage,
  page,
  order,
  orderBy,
}) {
  const {
    handleOpenDeleteModal, setSelectedServiceId, setOpenServiceDrawer, setErrors,
  } = useContext(ServiceContext);

  const {
    companyGot,
  } = useSelector((state) => state.company);

  const { t } = useTranslation();

  function descendingComparator(a, b, orderBy) {
    const x = typeof b[orderBy] === 'object' ? a[orderBy].price.from : a[orderBy];
    const y = typeof b[orderBy] === 'object' ? b[orderBy].price.from : b[orderBy];

    if (y < x) {
      return -1;
    }
    if (y > x) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const handleEditService = (serviceId) => {
    setOpenServiceDrawer(true);
    const data = [];
    data.push(serviceId);
    setSelectedServiceId(data);
    setErrors({});
  };
  return (
    <TableBody>
      {stableSort(selectedCategoryServices, getComparator(order, orderBy))
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row) => (
          <StyledTableRow key={row.company_service_id} onClick={() => handleEditService(row.company_service_id)}>
            <TableBodyCell
              component="th"
              scope="row"
              onClick={() => handleEditService(row.company_service_id)}
              style={{ width: '25%' }}
            >
              <div style={{ paddingLeft: '64px' }}>
                {row.name || row.name_en || '-'}
              </div>
            </TableBodyCell>
            <TableBodyCell align="left" style={{ width: '25%' }}>
              {row.price ? getServicePrice(row.price.type, row.price.price, companyGot) : getServicePrice(row.service_price.type, row.service_price.price, companyGot) }
            </TableBodyCell>
            <TableBodyCell align="left" style={{ width: '25%' }}>
              {row.duration ? serviceDuration(row.duration, t('H'), t('Min')) : '-'}
            </TableBodyCell>
            <TableBodyCell align="right" style={{ width: '25%', paddingRight: '68px' }}>
              <IconButton style={{ marginLeft: '-12px' }} onClick={() => handleEditService(row.company_service_id)}>
                <img src={editIcon} alt="edit" />
              </IconButton>
              <IconButton onClick={(e) => handleOpenDeleteModal(e, row.company_service_id, row.parent_id)}>
                <img src={deleteIcon} alt="delete" />
              </IconButton>
            </TableBodyCell>
          </StyledTableRow>
        ))}
    </TableBody>
  );
}

Body.propTypes = {
  selectedCategoryServices: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
};

export default Body;
