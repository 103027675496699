import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AppBar,
  Select,
  TextField,
  InputLabel,
  Box,
  Grid,
  Button,
  Divider,
  Toolbar,
  MenuItem,
  Container,
  Typography,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import Logo from 'assets/img/logo.svg';
import i18n from 'i18next';
import countryCodeOptions from '../../../../Modules/countryCodeOptions';
import { saveBusinessDetailsRequest } from '../../../../redux/account/actions';
import Loading from '../../../../Components/Loading/loading';
import usePrevious from '../../../../CustomHooks/usePrevious';
import { getIndustriesRequest } from '../../../../redux/industry/actions';
import { getCitiesRequest, getStatesRequest } from '../../../../redux/country/actions';
import industriesLogo from '../../../../assets/industriesLogo';
import TextMaskCustom from '../../../../Modules/TextMaskCustom';
import SnackbarToast from '../../../../Modules/SnackbarToast';

const Index = (props) => {
  const dispatch = useDispatch();

  const {
    invited,
    countries,
    backToAccountType,
  } = props;

  const {
    isSaveBusinessDetailsSuccess,
    isSaveBusinessDetailsError,
    businessDetailsErrors,
  } = useSelector((state) => state.account);

  const {
    isGetIndustriesSuccess,
    isGetIndustriesError,
    industries,
  } = useSelector((state) => state.industry);

  const {
    isGetStatesSuccess,
    isGetStatesError,
    states,
    isGetCitiesSuccess,
    isGetCitiesError,
    cities,
  } = useSelector((state) => state.country);

  const prevIsSaveBusinessDetailsSuccess = usePrevious(isSaveBusinessDetailsSuccess);
  const prevIsSaveBusinessDetailsError = usePrevious(isSaveBusinessDetailsError);

  const prevIsGetIndustriesSuccess = usePrevious(isGetIndustriesSuccess);
  const prevIsGetIndustriesError = usePrevious(isGetIndustriesError);

  const prevIsGetStatesSuccess = usePrevious(isGetStatesSuccess);
  const prevIsGetStatesError = usePrevious(isGetStatesError);

  const prevIsGetCitiesSuccess = usePrevious(isGetCitiesSuccess);
  const prevIsGetCitiesError = usePrevious(isGetCitiesError);

  const [businessDetails, setBusinessDetails] = useState({
    name: '',
    industryId: '',
    countryId: '',
    stateId: '',
    cityId: '',
    address: '',
    timezone: '',
    phoneCode: '',
    phoneNumber: '',
    zip: '',
    tin: '',
  });
  const [formErrors, setFormErrors] = useState({
    name: '',
    industryId: '',
    countryId: '',
    stateId: '',
    cityId: '',
    address: '',
    timezone: '',
    phoneCode: '',
    phoneNumber: '',
    phoneNumberLength: '',
  });
  const [selectedCodeValue, setSelectedCodeValue] = useState('');
  const [codeOptions, setCodeOptions] = useState([]);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState({});
  const [industryOptions, setIndustryOptions] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState({});
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedStateOption, setSelectedStateOption] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedCityOption, setSelectedCityOption] = useState({});
  const [cityOptions, setCityOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [selectedTimeZoneOption, setSelectedTimeZoneOption] = useState('');

  // Perform, when component gets mounted
  useEffect(() => {
    setLoading(false);
    const timeZones = moment.tz.names();
    const timeZoneNamesList = [];
    timeZones.forEach((item, i) => timeZoneNamesList.push(` (GMT${moment.tz(timeZones[i]).format('Z')})${timeZones[i]}`));
    setTimeZoneOptions(timeZoneNamesList);
    const selectedTimeZone = timeZoneNamesList.find((item) => item.includes(selectedCountryOption.capital));
    setBusinessDetails({
      ...businessDetails,
      timezone: selectedTimeZone,
    });
    setSelectedTimeZoneOption(selectedTimeZone);
  }, [selectedCountryOption]);

  useEffect(() => {
    const codeOptions = countryCodeOptions.getCodeOptions(countries);
    setCodeOptions(codeOptions);
    dispatch(getIndustriesRequest());

    const countryOptions = countryCodeOptions.getCountryOptions(countries);
    setCountryOptions(countryOptions);
  }, [countries]);

  useEffect(() => {
    if (prevIsSaveBusinessDetailsSuccess === false && isSaveBusinessDetailsSuccess) {
      snackBarAlert(true, i18n.t('BusinessSaved'), 'success');
      window.location.reload(false);
    } else if (prevIsSaveBusinessDetailsError === false && isSaveBusinessDetailsError) {
      setLoading(false);
    }
  }, [isSaveBusinessDetailsSuccess, isSaveBusinessDetailsError]);

  useEffect(() => {
    if (isGetIndustriesSuccess && prevIsGetIndustriesSuccess === false) {
      const industryOptions = countryCodeOptions.getIndustryOptions(industries);
      setIndustryOptions(industryOptions);
    } else if (prevIsGetIndustriesError === false && isGetIndustriesError) {
      setLoading(false);
      snackBarAlert(true, i18n.t('IndustriesNotSaved'), 'error');
    }
  }, [isGetIndustriesSuccess, isGetIndustriesError]);

  // Check If Get States Is Success
  useEffect(() => {
    if (isGetStatesSuccess && prevIsGetStatesSuccess === false) {
      const stateOptions = countryCodeOptions.getStateOptions(states);
      setStateOptions(stateOptions);
      if (selectedCountryOption.id) {
        const selectedStateOption = stateOptions.find((item) => item.id === businessDetails.state_id);
        setSelectedStateOption(selectedStateOption);
        if (businessDetails.state_id) {
          dispatch(getCitiesRequest(businessDetails.state_id));
        }
      }
    } else if (isGetStatesError && prevIsGetStatesError) {
      snackBarAlert(true, i18n.t('FileNotSupported'), 'error');
    }
  }, [isGetStatesSuccess, isGetStatesError]);

  // Check If Get Cities Is Success
  useEffect(() => {
    if (prevIsGetCitiesSuccess === false && isGetCitiesSuccess) {
      const cityOptions = countryCodeOptions.getCitiesOptions(cities);
      setCityOptions(cityOptions);
      if (selectedCountryOption.id === businessDetails.id) {
        const selectedCityOption = cityOptions.find((item) => item.id === businessDetails.city_id);
        setSelectedCityOption(selectedCityOption);
        setLoading(false);
      }
    }
  }, [isGetCitiesSuccess]);

  // Perform, when Cities Is error
  useEffect(() => {
    if (prevIsGetCitiesError === false && isGetCitiesError) {
      snackBarAlert(true, i18n.t('BusinessNotSaved'), 'error');
      setLoading(false);
    }
  }, [isGetCitiesError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBusinessDetails({
      ...businessDetails,
      [name]: value,
    });
  };

  const handleCountryCodeSelectChange = (event) => {
    const codeId = parseInt(event.target.value);
    const phoneOption = codeOptions.find((item) => parseInt(item.value) === codeId);
    setSelectedCodeValue(phoneOption.value);
    setBusinessDetails({
      ...businessDetails,
      phoneCode: phoneOption.value,
    });
  };

  const handleIndustrySelectChange = (event, industryName) => {
    const selectedIndustry = industryOptions.find((item) => item.value === industryName);
    if (selectedIndustry && selectedIndustry.id) {
      setSelectedIndustryOption({
        id: selectedIndustry.id,
        label: selectedIndustry.label,
        value: selectedIndustry.value,
      });
      setBusinessDetails({
        ...businessDetails,
        industryId: selectedIndustry.id,
      });
    } else {
      setSelectedIndustryOption({
        id: '',
        label: '',
        value: '',
      });
      setBusinessDetails({
        ...businessDetails,
        industryId: '',
      });
    }
  };

  const handleCountrySelectChange = (event, selectedCountry) => {
    if (selectedCountry && selectedCountry.id) {
      const phoneOption = codeOptions.find((item) => item.key === selectedCountry.key);
      setSelectedCountryOption(selectedCountry);
      setSelectedCodeValue(phoneOption.value);
      setSelectedStateOption({});
      setSelectedCityOption({});
      setCityOptions([]);
      dispatch(getStatesRequest(selectedCountry.id));
      setBusinessDetails({
        ...businessDetails,
        countryId: selectedCountry.id,
        phoneCode: phoneOption.value,
        stateId: '',
        cityId: '',
      });
    } else {
      setSelectedCountryOption({});
      setSelectedStateOption({});
      setSelectedCityOption({});
      setSelectedCodeValue('');
      setCityOptions([]);
      setStateOptions([]);
      setBusinessDetails({
        ...businessDetails,
        countryName: '',
        phoneCode: '',
        countryId: '',
        stateId: '',
        cityId: '',
      });
    }
  };

  const handleTimeZoneSelectChange = (e, selectedTimeZone) => {
    if (selectedTimeZone) {
      setSelectedTimeZoneOption(selectedTimeZone || '');
      setBusinessDetails({
        ...businessDetails,
        timezone: selectedTimeZone,
      });
    } else {
      setBusinessDetails({
        ...businessDetails,
        timezone: '',
      });
      setSelectedTimeZoneOption('');
    }
  };

  const handleStateSelectChange = (event, selectedState) => {
    if (selectedState && selectedState.id) {
      setSelectedStateOption(selectedState);
      setCityOptions([]);
      setBusinessDetails({
        ...businessDetails,
        stateId: selectedState.id,
      });
      dispatch(getCitiesRequest(selectedState.id));
    } else {
      setSelectedStateOption({});
      setSelectedCityOption({});
      setCityOptions([]);
      setBusinessDetails({
        ...businessDetails,
        stateId: '',
        cityId: '',
      });
    }
  };

  const handleCitySelectChange = (event, selectedCity) => {
    if (selectedCity && selectedCity.id) {
      setSelectedCityOption(selectedCity);
      setBusinessDetails({
        ...businessDetails,
        cityId: selectedCity.id,
      });
    } else {
      setSelectedCityOption({});
      setBusinessDetails({
        ...businessDetails,
        cityId: '',
      });
    }
  };

  const validateForm = () => {
    const formItemNames = Object.keys(businessDetails);
    const formErrorsCopy = { ...formErrors };
    const validateName = (name) => (businessDetails[name].length === 0);
    const validatePhone = () => (
      businessDetails.phoneNumber.length === 0
    );

    const validatePhoneLength = () => (
      businessDetails.phoneNumber.length < 12
    );

    const validatePhoneCode = () => (
      !setSelectedCodeValue || setSelectedCodeValue.length === 0
    );

    formItemNames.forEach((name) => {
      switch (name) {
        case 'name': {
          formErrorsCopy[name] = validateName(name);
          break;
        }
        case 'industryId': {
          formErrorsCopy[name] = validateName(name);
          break;
        }
        case 'countryId': {
          formErrorsCopy.country = validateName(name);
          break;
        }
        case 'timezone': {
          formErrorsCopy.timezone = validateName(name);
          break;
        }
        case 'stateId': {
          formErrorsCopy[name] = validateName(name);
          break;
        }
        case 'cityId': {
          formErrorsCopy[name] = validateName(name);
          break;
        }
        case 'address': {
          formErrorsCopy[name] = validateName(name);
          break;
        }
        case 'phoneNumber': {
          formErrorsCopy[name] = validatePhone();
          break;
        }
        case 'phoneCode': {
          formErrorsCopy[name] = validatePhoneCode();
          break;
        }
      }
    });

    formErrorsCopy['phoneNumberLength'] = validatePhoneLength();

    setFormErrors(formErrorsCopy);
    const errorValues = Object.keys(formErrorsCopy);
    return errorValues.find((item) => formErrorsCopy[item]);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const errorExists = validateForm();
    if (!errorExists) {
      setLoading(true);
      dispatch(saveBusinessDetailsRequest({
        ...businessDetails,
        industryId: selectedIndustryOption.id,
        countryId: selectedCountryOption.id,
        stateId: selectedStateOption.id,
        cityId: selectedCityOption.id,
        phoneCode: selectedCodeValue,
        address: businessDetails.address,
      }));
    }
  };

  return !loading ? (
    <div className="auth auth-bg">
      <Container>
        <AppBar color="transparent" position="static" elevation={0} className="login-header">
          <Toolbar>
            <Box mx="auto" className="auth-logo">
              <Link to="/login">
                <img src={Logo} alt="Logo" />
              </Link>
            </Box>
          </Toolbar>
        </AppBar>
        <Grid container justify="center">
          <Grid item direction="row" justify="center" md={6} sm={9}>
            <div className="auth-box">
              <Box display="flex" height="100%">
                <Box alignSelf="center" mx="auto">
                  <Box px="40px" py="30px">
                    <Box mb="30px">
                      <Typography align="center" variant="h4">Business Details</Typography>
                    </Box>
                    <Grid container spacing={2} alignItems="center" className="business-container" xs={12}>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          error={!!formErrors.name}
                        >
                          <TextField
                            fullWidth
                            error={!!formErrors.name}
                            id="name"
                            name="name"
                            value={businessDetails.name}
                            onChange={handleInputChange}
                            label="Company name *"
                            variant="outlined"
                            size="small"
                            inputProps={{
                              autoComplete: 'new-password',
                              maxLength: 60,
                            }}
                          />

                          { formErrors.name && (
                            <div className="error-message-content">
                              <ErrorIcon fontSize="small" color="error" />
                              <FormHelperText>
                                Company name is required
                              </FormHelperText>
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="dense"
                        >
                          <TextField
                            fullWidth
                            label="TIN"
                            variant="outlined"
                            size="small"
                            name="tin"
                            value={businessDetails.tin}
                            onChange={handleInputChange}
                            inputProps={{
                              autoComplete: 'new-password',
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          error={!!formErrors.industryId}
                        >
                          <Autocomplete
                            size="small"
                            fullWidth
                            autoHighlight
                            value={selectedIndustryOption.value}
                            options={industryOptions}
                            getOptionLabel={(option) => option.label}
                            onInputChange={handleIndustrySelectChange}
                            renderOption={(option) => (
                              <div
                                className="input-menu"
                                key={option.id}
                                value={option.value}
                              >
                                <span className="option-logo">
                                  <img src={industriesLogo[option.label]} alt="industryLogo" />
                                </span>
                                {option.label}
                              </div>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Industry *"
                                variant="outlined"
                                error={!!formErrors.industryId}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />

                          { formErrors.industryId && (
                            <div className="error-message-content">
                              <ErrorIcon fontSize="small" color="error" />
                              <FormHelperText>
                                Industry is required
                              </FormHelperText>
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          error={!!formErrors.country}
                        >
                          <Autocomplete
                            size="small"
                            fullWidth
                            autoHighlight
                            value={selectedCountryOption}
                            onChange={handleCountrySelectChange}
                            options={countryOptions}
                            getOptionLabel={(option) => option.label || ''}
                            renderOption={(option) => (
                              <div
                                className="input-menu"
                                key={option.id}
                                value={option.id}
                              >
                                {option.value}
                              </div>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                label="Country *"
                                variant="outlined"
                                error={!!formErrors.country}
                              />
                            )}
                          />

                          { formErrors.country && (
                            <div className="error-message-content">
                              <ErrorIcon fontSize="small" color="error" />
                              <FormHelperText>
                                Country is required
                              </FormHelperText>
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          error={!!formErrors.timezone}
                        >
                          <Autocomplete
                            size="small"
                            fullWidth
                            onChange={handleTimeZoneSelectChange}
                            value={selectedTimeZoneOption || ''}
                            options={timeZoneOptions}
                            getOptionLabel={(option) => option}
                            renderOption={(option) => (
                              <>
                                <span>{`(${moment.tz(option).zoneAbbr()})`} </span>
                                {option}
                              </>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                label="Time zone *"
                                variant="outlined"
                                error={!!formErrors.timezone}
                              />
                            )}
                          />
                          { formErrors.timezone && (
                            <div className="error-message-content">
                              <ErrorIcon fontSize="small" color="error" />
                              <FormHelperText>
                                Time zone  is required
                              </FormHelperText>
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          error={!!formErrors.stateId}
                        >
                          <Autocomplete
                            size="small"
                            fullWidth
                            autoHighlight
                            options={stateOptions}
                            value={selectedStateOption}
                            onChange={handleStateSelectChange}
                            disabled={!stateOptions.length}
                            getOptionLabel={(option) => option.label || ''}
                            renderOption={(option) => (
                              <div
                                className="input-menu"
                                key={option.id}
                                value={option.id}
                              >
                                {option.value}
                              </div>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password',
                                }}
                                label="State *"
                                variant="outlined"
                                error={!!formErrors.stateId}
                              />
                            )}
                          />

                          { formErrors.stateId && (
                          <div className="error-message-content">
                            <ErrorIcon fontSize="small" color="error" />
                            <FormHelperText>
                                State is required
                            </FormHelperText>
                          </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          error={!!formErrors.cityId}
                        >
                          <Autocomplete
                            size="small"
                            fullWidth
                            autoHighlight
                            options={cityOptions}
                            value={selectedCityOption}
                            onChange={handleCitySelectChange}
                            disabled={!cityOptions.length}
                            getOptionLabel={(option) => option.label || ''}
                            renderOption={(option) => (
                              <MenuItem
                                className="input-menu"
                                key={option.id}
                                value={option.id}
                              >
                                {option.value}
                              </MenuItem>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="City *"
                                variant="outlined"
                                error={!!formErrors.cityId}
                              />
                            )}
                          />

                          { formErrors.cityId && (
                          <div className="error-message-content">
                            <ErrorIcon fontSize="small" color="error" />
                            <FormHelperText>
                                City is required
                            </FormHelperText>
                          </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={7} md={9}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          error={!!formErrors.address}
                        >
                          <TextField
                            fullWidth
                            error={!!formErrors.address}
                            id="address"
                            label="Address *"
                            variant="outlined"
                            size="small"
                            name="address"
                            value={businessDetails.address}
                            onChange={handleInputChange}
                            inputProps={{
                              autoComplete: 'new-password',
                            }}
                          />

                          { formErrors.address && (
                            <div className="error-message-content">
                              <ErrorIcon fontSize="small" color="error" />
                              <FormHelperText>
                                Address is required
                              </FormHelperText>
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5} md={3}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          margin="dense"
                        >
                          <TextField
                            fullWidth
                            id="zip"
                            label="Zip code"
                            variant="outlined"
                            size="small"
                            name="zip"
                            value={businessDetails.zip}
                            onChange={handleInputChange}
                            inputProps={{
                              maxLength: 10,
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={5} lg={4}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className="phone-code"
                          margin="dense"
                          error={!!formErrors.phoneCode}
                          disabled
                        >
                          <InputLabel>Code * </InputLabel>
                          <Select
                            name="phoneCode"
                            label="Code *"
                            onChange={(event) => handleCountryCodeSelectChange(event)}
                            value={selectedCodeValue}
                          >
                            <MenuItem disabled value=""><em>{i18n.t('PhoneCode')}</em></MenuItem>
                            <Divider />
                            {codeOptions && codeOptions.map((option, index) => (
                              <MenuItem
                                key={`${option.id}-${index}`}
                                value={option.value}
                                selected={selectedCodeValue === option.value}
                              >
                                <img
                                  src={option.label.flagImageSrc}
                                  alt="flag"
                                  className="country-flag"
                                />
                                {option.key}
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>

                          { formErrors.phoneCode && (
                            <div className="error-message-content">
                              <ErrorIcon fontSize="small" color="error" />
                              <FormHelperText>
                                {i18n.t('PhoneCode')} is required
                              </FormHelperText>
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={7} lg={8}>
                        <FormControl
                          fullWidth
                          variant="outlined"
                          className="phone-code"
                          margin="dense"
                          error={!!formErrors.phoneNumber || !!formErrors.phoneNumberLength || !!(businessDetailsErrors.length)}
                        >
                          <TextField
                            fullWidth
                            error={!!formErrors.phoneNumber || !!formErrors.phoneNumberLength || !!(businessDetailsErrors.length)}
                            id="phone-detail"
                            type="text"
                            value={businessDetails.phoneNumber}
                            onChange={handleInputChange}
                            name="phoneNumber"
                            label="Phone number *"
                            variant="outlined"
                            size="small"
                            InputProps={{
                              inputComponent: TextMaskCustom,
                              autoComplete: 'new-password',
                            }}
                          />
                          { (formErrors.phoneNumber || formErrors.phoneNumberLength || businessDetailsErrors.length > 0) && (
                            <div className="error-message-content">
                              <ErrorIcon fontSize="small" color="error" />
                              <FormHelperText>
                                {formErrors.phoneNumber ? 'Phone number is required' : formErrors.phoneNumberLength ? 'Phone number length can not be less 8 characters' : businessDetailsErrors ? businessDetailsErrors.phone_number : ''}
                              </FormHelperText>
                            </div>
                          )}
                        </FormControl>
                      </Grid>
                      <Box display="flex" justifyContent="space-between" width="100%" px="8px" mt="22px">
                        <Box alignSelf="center">
                          <Link disabled={invited} onClick={backToAccountType} to="/">
                            <Button
                              color="secondary"
                              startIcon={<ArrowBackIosIcon style={{ fontSize: 12 }} color="secondary" />}
                            >
                              Back
                            </Button>
                          </Link>
                        </Box>
                        <div>
                          <Button size="small" color="primary" onClick={onSubmit} variant="contained" data-cy="submit" type="submit">
                              Save
                          </Button>
                        </div>
                      </Box>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Container>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  ) : <Loading />;
};

Index.propTypes = {
  invited: PropTypes.bool.isRequired,
  countries: PropTypes.array.isRequired,
  backToAccountType: PropTypes.func.isRequired,
};

export default Index;
