import React from 'react';
import {
  Card,
  Paper,
  AppBar,
  Dialog,
  DialogTitle,
  DialogContent,
  TableCell,
  Box,
  Tab,
  Tabs,
  Grid,
  Table,
  Avatar,
  TableRow,
  CardMedia,
  TableBody,
  TableHead,
  IconButton,
  Typography,
  CardContent,
  TableContainer,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SwipeableViews from 'react-swipeable-views';
import moment from 'moment';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import TabPanel from '../../NewAppointment/tabPanel';
import firstLetter from '../../../Modules/getFirstLetter';

function PreviewCustomerModal(props) {
  const {
    theme,
    tabValue,
    a11yProps,
    tableAppHeader,
    handleChangeTab,
    handleChangeIndex,
    previewCustomerItem,
    isPreviewCustomerModalInfo,
    setIsPreviewCustomerModalInfo,
  } = props;

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={isPreviewCustomerModalInfo}
      onClose={() => setIsPreviewCustomerModalInfo(false)}
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>
            {i18n.t('CustomerPreview')}
          </span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={() => setIsPreviewCustomerModalInfo(false)}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers className="customized-dialog-content">
        <AppBar position="static" color="inherit" elevation={0}>
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={i18n.t('Info')} {...a11yProps(0)} />
            <Tab label={i18n.t('AppointmentsHistory')} {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          id="customer-tab-panel"
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={tabValue}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel value={tabValue} index={0} dir={theme.direction}>
            <Card className="customer-tab-card">
              <CardContent className="card-action-area">
                <CardMedia className="card-info">
                  <Grid container>
                    <Grid item xs={6}>
                      {previewCustomerItem && previewCustomerItem.avatar ? (
                        <Avatar className="card-box-avatar" alt="avatar" src={previewCustomerItem.avatar} />
                      ) : (
                        <Avatar variant="circle" className="card-box-avatar">
                          {firstLetter(previewCustomerItem.first_name ? previewCustomerItem.first_name : '', previewCustomerItem.last_name ? previewCustomerItem.last_name : '')}
                        </Avatar>
                      )}
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6">{previewCustomerItem.first_name} {previewCustomerItem.last_name}</Typography>
                      {previewCustomerItem.email
                        ? (
                          <p>
                            <h5>{i18n.t('Email')}:</h5>
                            <span title={previewCustomerItem.email} className="preview-email"> {previewCustomerItem.email} </span>
                          </p>
                        )
                        : null}
                      <p>
                        <h5>{i18n.t('PhoneNumber')}:</h5> {previewCustomerItem.phone_code || '+374'} {previewCustomerItem.phone_number || '** *** ***'}
                      </p>
                      {previewCustomerItem.gender
                        ? <p><h5>{i18n.t('Gender')}:</h5> {previewCustomerItem.gender}</p>
                        : null}
                    </Grid>
                  </Grid>
                </CardMedia>
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={tabValue} index={1} dir={theme.direction}>
            <Card className="customer-tab-card">
              {Object.keys(previewCustomerItem).length && previewCustomerItem.appointments.length ? (
                <TableContainer component={Paper} size="small" className="history-container">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {tableAppHeader.map((item, index) => (
                          <TableCell key={`${index}-${Math.random()}`}>
                            <Box textAlign="left">
                              {item}
                            </Box>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody className="history-body">
                      {Object.keys(previewCustomerItem).length && previewCustomerItem.appointments.map((item) => (
                        <TableRow tabIndex={-1} key={item.id}>
                          <TableCell>
                            <Box display="flex" alignItems="center" className="date">
                              {moment(item.date)
                                .format('MMM Do')}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center" className="name">
                              {previewCustomerItem.full_name}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center" className="price">
                              ${item.price}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <div
                              className={`appointment-status s_${item.status_id}`}
                            >
                              {item.status_id === 2 ? 'Pending' : item.status_id === 1 ? 'Accepted' : item.status_id === 3 ? 'Cancelled' : 'Finished'}
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Box display="flex" justifyContent="center" alignItems="center" width="100%">
                  <Box>
                    {i18n.t('NoAppointments')}
                  </Box>
                </Box>
              )}
            </Card>
          </TabPanel>
        </SwipeableViews>
      </DialogContent>
    </Dialog>
  );
}

PreviewCustomerModal.propTypes = {
  theme: PropTypes.object.isRequired,
  a11yProps: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired,
  tableAppHeader: PropTypes.array.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
  handleChangeIndex: PropTypes.func.isRequired,
  previewCustomerItem: PropTypes.object.isRequired,
  isPreviewCustomerModalInfo: PropTypes.bool.isRequired,
  setIsPreviewCustomerModalInfo: PropTypes.func.isRequired,
};

export default PreviewCustomerModal;
