import { put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  getCompanyCalendarRequest,
  getCompanyCalendarSuccess,
  getCompanyCalendarError,
  storeCompanyRequest,
  storeCompanySuccess,
  storeCompanyError,
  storeGalleryCompanyRequest,
  storeGalleryCompanySuccess,
  storeGalleryCompanyError,
  deleteGalleryCompanyRequest,
  deleteGalleryCompanySuccess,
  deleteGalleryCompanyError,
  getOwnerCompaniesRequest,
  getOwnerCompaniesSuccess,
  getOwnerCompaniesError,
  updateCompanyRequest,
  updateCompanySuccess,
  updateCompanyError,
  getAllCompaniesError,
  getAllCompaniesSuccess,
  getAllCompaniesRequest,
  getCompanyByIdRequest,
  getCompanyByIdSuccess,
  getCompanyByIdError,
  getCompanyByIdForCalendarRequest,
  getCompanyByIdForCalendarSuccess,
  getCompanyByIdForCalendarError,
  getCleanCompanyByIdRequest,
  getCleanCompanyByIdSuccess,
  getCleanCompanyByIdError,
  deleteCompanyRequest,
  deleteCompanySuccess,
  deleteCompanyError,
  getFilteredCompaniesRequest,
  getFilteredCompaniesSuccess,
  getFilteredCompaniesError,
  storeCustomerToCompanyRequest,
  storeCustomerToCompanySuccess,
  storeCustomerToCompanyError,
  inviteCustomerToCompanyRequest,
  inviteCustomerToCompanySuccess,
  inviteCustomerToCompanyError,
  leaveCompanyRequest,
  leaveCompanySuccess,
  leaveCompanyError,
  getPersonalCompaniesRequest,
  getPersonalCompaniesSuccess,
  getPersonalCompaniesError,
  getStaffWithServicesRequest,
  getStaffWithServicesSuccess,
  getStaffWithServicesError,
  getStaffCompanyAppointmentsRequest,
  getStaffCompanyAppointmentsSuccess,
  getStaffCompanyAppointmentsError,
  staffDayOffSuccess,
  staffDayOffError,
  staffDayOffRequest,
  updateStaffDayOffSuccess,
  updateStaffDayOffError,
  updateStaffDayOffRequest,
  deleteStaffDayOffRequest,
  deleteStaffDayOffSuccess,
  deleteStaffDayOffError,
  staffSpecialDaySuccess,
  staffSpecialDayError,
  staffSpecialDayRequest,
  updateStaffSpecialDaySuccess,
  updateStaffSpecialDayError,
  updateStaffSpecialDayRequest,
  deleteStaffSpecialDaySuccess,
  deleteStaffSpecialDayError,
  deleteStaffSpecialDayRequest,
  closeDayRequest,
  closeDaySuccess,
  closeDayError,
  updateCloseDaySuccess,
  updateCloseDayError,
  updateCloseDayRequest,
  deleteCloseDaySuccess,
  deleteCloseDayError,
  deleteCloseDayRequest,
  specialDaySuccess,
  specialDayError,
  specialDayRequest,
  updateSpecialDaySuccess,
  updateSpecialDayError,
  updateSpecialDayRequest,
  deleteSpecialDaySuccess,
  deleteSpecialDayError,
  deleteSpecialDayRequest,
  staffSpecialGraphicRequest,
  staffSpecialGraphicSuccess,
  staffSpecialGraphicError,
  getStaffAvailableDatesRequest,
  getStaffAvailableDatesSuccess,
  getStaffAvailableDatesError,
  listOwnerCompaniesRequest,
  listOwnerCompaniesSuccess,
  listOwnerCompaniesError,
  getPreSignUrlRequest,
  getPreSignUrlSuccess,
  getPreSignUrlError,
  uploadImageToAmazonRequest,
  uploadImageToAmazonSuccess,
  uploadImageToAmazonError,
  getOwnerCompanyRequest,
  getOwnerCompanySuccess,
  getOwnerCompanyError,
  getCompanyWorkingScheduleSuccess,
  getCompanyWorkingScheduleError,
  getCompanyWorkingScheduleRequest,
  getCompanyImagesRequest,
  getCompanyImagesSuccess,
  getCompanyImagesError,
  getCompanySuccess,
  getCompanyError,
  getCompanyRequest,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

function* storeCompany(action) {
  const { payload } = action;
  try {
    let data = payload;
    let config = axiosInstance.defaults.headers;
    config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const {
      name, country_id, state_id, city_id, phone_code,
      phone_number, industry_id, address, aws_logo, zip,
    } = data;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('country_id', country_id);
    formData.append('state_id', state_id);
    formData.append('city_id', city_id);
    formData.append('address', address);
    formData.append('phone_code', phone_code);
    formData.append('phone_number', phone_number);
    formData.append('industry_id', industry_id);
    if (aws_logo) {
      formData.append('aws_logo', aws_logo);
    }
    if (zip) {
      formData.append('zip_code', zip);
    }
    data = formData;

    const response = yield axiosInstance.post('company', data, config);
    if (response && response.status && response.status === 200) {
      yield put(storeCompanySuccess(response.data));
    } else {
      yield put(storeCompanyError(response.response.data));
    }
  } catch (e) {
    if (e && e.response && e.response.data) {
      yield put(storeCompanyError(e.response.data));
    }
  }
}

function* storeGalleryCompany(action) {
  const { payload } = action;
  try {
    const { images } = payload;
    let data = payload;
    let config = axiosInstance.defaults.headers;
    if (images.length) {
      config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const formData = new FormData();
      formData.append('images', images);
      data = formData;
    }
    const response = yield axiosInstance.post('company/images', data, config);
    if (response && response.status && response.status === 200) {
      yield put(storeGalleryCompanySuccess(response.data));
    } else {
      yield put(storeGalleryCompanyError(response.response.data));
    }
  } catch (e) {
    if (e && e.response && e.response.data) {
      yield put(storeGalleryCompanyError(e.response.data));
    }
  }
}

function* deleteGalleryCompany(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.delete(`company/images/${id}`);
  try {
    if (response && response.status === 200) {
      yield put(deleteGalleryCompanySuccess(response.data));
    } else {
      yield put(deleteGalleryCompanyError(response.response.data));
    }
  } catch (e) {
    yield put(deleteGalleryCompanyError(e.response.data));
  }
}

function* updateCompany(action) {
  const { payload } = action;
  const { id, images } = payload.company;
  let data = payload.company;
  let config = axiosInstance.defaults.headers;
  config = { headers: { 'Content-Type': 'multipart/form-data' } };
  const {
    is_staff_based, is_service_based,
    name, country_id, state_id, city_id, phone_code, email,
    website_url, facebook_url, instagram_url, booking_url,
    phone_number, industry_id, address, zip_code, timezone,
    overview, aws_logo,
  } = data;
  const formData = new FormData();
  formData.append('id', id);
  formData.append('name', name);
  formData.append('email', email);
  formData.append('country_id', country_id);
  formData.append('state_id', state_id);
  formData.append('city_id', city_id);
  formData.append('address', address);
  formData.append('phone_code', phone_code);
  formData.append('phone_number', phone_number);
  formData.append('industry_id', industry_id);
  formData.append('website_url', website_url);
  formData.append('facebook_url', facebook_url);
  formData.append('instagram_url', instagram_url);
  formData.append('booking_url', booking_url);
  formData.append('images', images);
  formData.append('zip_code', zip_code);
  formData.append('timezone', timezone);
  formData.append('overview', overview);
  formData.append('is_staff_based', is_staff_based);
  formData.append('is_service_based', is_service_based);
  if (payload.isChangeLogo) {
    formData.append('aws_logo', aws_logo);
  }
  formData.append('_method', 'PUT');
  data = formData;
  try {
    const response = yield axiosInstance.post('company/update', data, config);
    if (response && response.status === 200) {
      yield put(updateCompanySuccess(response.data));
    } else {
      yield put(updateCompanyError(response.response.data));
    }
  } catch (e) {
    yield put(updateCompanyError());
  }
}

function* getOwnerCompanies() {
  try {
    const response = yield axiosInstance.get('company');
    if (response && response.status === 200) {
      yield put(getOwnerCompaniesSuccess(response.data));
    }
  } catch (e) {
    yield put(getOwnerCompaniesError());
  }
}

function* getAllCompanies(action) {
  try {
    const { payload } = action;
    const isForCalendar = !!(payload && payload.isForCalendar);
    let url = `company/all?isForCalendar=${isForCalendar}`;
    if (payload && payload.industryId) {
      url = `${url}&industryId=${payload.industryId}`;
    }
    const response = yield axiosInstance.get(url);
    if (response && response.status === 200) {
      yield put(getAllCompaniesSuccess(response.data));
    }
  } catch (e) {
    yield put(getAllCompaniesError(e.payload.data));
  }
}

function* getCompanyById(action) {
  const id = action.payload;
  try {
    const response = yield axiosInstance.get(`company/${id}`);
    if (response && response.status === 200) {
      yield put(getCompanyByIdSuccess(response.data));
    } else {
      yield put(getCompanyByIdError(response.response.data));
    }
  } catch (e) {
    yield put(getCompanyByIdError(e.response.data));
  }
}

function* getCompanyByIdForCalendar(action) {
  const id = action.payload;
  try {
    const response = yield axiosInstance.get(`company-for-calendar/${id}`);
    if (response && response.status === 200) {
      yield put(getCompanyByIdForCalendarSuccess(response.data));
    } else {
      yield put(getCompanyByIdForCalendarError(response.response.data));
    }
  } catch (e) {
    yield put(getCompanyByIdForCalendarError(e.response.data));
  }
}

function* getCleanCompanyById(action) {
  try {
    const { payload: { isForListPage } } = action;
    let url = '';
    if (isForListPage) {
      url = 'clean-company?isForListPage=true';
    }

    const response = yield axiosInstance.get(url);
    if (response && response.status === 200) {
      yield put(getCleanCompanyByIdSuccess(response.data));
    } else {
      yield put(getCleanCompanyByIdError(response.response.data));
    }
  } catch (e) {
    yield put(getCleanCompanyByIdError(e.response.data));
  }
}

function* deleteCompany(action) {
  const { id } = action.payload;
  try {
    const response = yield axiosInstance.delete(`company/${id}`);
    if (response && response.status === 202) {
      yield put(deleteCompanySuccess());
    } else {
      yield put(deleteCompanyError(response.response.data));
    }
  } catch (e) {
    yield put(deleteCompanyError(e.response.data));
  }
}

function* getFilteredCompanies(action) {
  const {
    service_id,
    country,
    date,
    appointment_id,
  } = action.payload;
  try {
    const url = `companies?service_id=${service_id}&country=${country}&date=${date}&appointment_id=${appointment_id}`;
    const response = yield axiosInstance.get(url);
    if (response && response.status === 200) {
      yield put(getFilteredCompaniesSuccess(response.data));
    } else {
      yield put(getFilteredCompaniesError(response.response.data));
    }
  } catch (e) {
    yield put(getFilteredCompaniesError(e.response.data));
  }
}

function* storeCustomerToCompany(data) {
  const response = yield axiosInstance.post('company/customers', data.payload);
  try {
    if (response && response.status === 200) {
      yield put(storeCustomerToCompanySuccess(response.data.data[0]));
    } else {
      yield put(storeCustomerToCompanyError(response.response.data));
    }
  } catch (e) {
    yield put(storeCustomerToCompanyError(e.response.data));
  }
}

function* inviteCustomerToCompany(data) {
  const response = yield axiosInstance.post('company/customer/invite', data.payload);
  try {
    if (response && response.status === 200) {
      yield put(inviteCustomerToCompanySuccess(response.data));
    } else {
      yield put(inviteCustomerToCompanyError(response.response.data));
    }
  } catch (e) {
    yield put(inviteCustomerToCompanyError(e.response.data));
  }
}

function* leaveCompany(data) {
  try {
    const response = yield axiosInstance.post('company/leave', data.payload);
    if (response.status === 202) {
      yield put(leaveCompanySuccess());
    } else {
      yield put(leaveCompanyError());
    }
  } catch (e) {
    yield put(leaveCompanyError());
  }
}

function* getPersonalCompanies(action) {
  try {
    const { industryId } = action.payload;
    const response = yield axiosInstance.get(`personal/companies?industry_id=${industryId}`);
    if (response && response.status === 200) {
      yield put(getPersonalCompaniesSuccess(response.data));
    } else {
      yield put(getPersonalCompaniesError(response.data));
    }
  } catch (e) {
    yield put(getPersonalCompaniesError(e.response.data));
  }
}

function* getStaffWithServices(action) {
  try {
    const { companyId } = action.payload;
    const response = yield axiosInstance.get(`personal/company/staff/${companyId}`);
    if (response && response.status === 200) {
      yield put(getStaffWithServicesSuccess(response.data));
    } else {
      yield put(getStaffWithServicesError(response.data));
    }
  } catch (e) {
    yield put(getStaffWithServicesError(e.response.data));
  }
}

function* getStaffCompanyAppointments(action) {
  const ids = action.payload;
  const response = yield axiosInstance.post('staff-company-for-calendar', { ids });
  try {
    if (response && response.status === 200) {
      yield put(getStaffCompanyAppointmentsSuccess(response.data));
    } else {
      yield put(getStaffCompanyAppointmentsError(response.response.data));
    }
  } catch (e) {
    yield put(getStaffCompanyAppointmentsError(e.response.data));
  }
}

function* addStaffDayOff(action) {
  const response = yield axiosInstance.post('company/staff-day-off', action.payload);
  try {
    if (response && response.status === 200) {
      yield put(staffDayOffSuccess(response.data));
    } else {
      yield put(staffDayOffError(response.response.data));
    }
  } catch (e) {
    yield put(staffDayOffError(e.response.data));
  }
}

function* updateStaffDayOff(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.put(`company/staff-day-off/${id}`, action.payload);
  try {
    if (response && response.status === 200) {
      yield put(updateStaffDayOffSuccess(response.data));
    } else {
      yield put(updateStaffDayOffError(response.response.data));
    }
  } catch (e) {
    yield put(updateStaffDayOffError(e.response.data));
  }
}

function* deleteStaffDayOff(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.delete(`company/staff-day-off/${id}`);
  try {
    if (response && response.status === 200) {
      yield put(deleteStaffDayOffSuccess(response.data));
    } else {
      yield put(deleteStaffDayOffError(response.response.data));
    }
  } catch (e) {
    yield put(deleteStaffDayOffError(e.response.data));
  }
}

function* addStaffSpecialDay(action) {
  const response = yield axiosInstance.post('company/staff-special-day', action.payload);
  try {
    if (response && response.status === 200) {
      yield put(staffSpecialDaySuccess(response.data));
    } else {
      yield put(staffSpecialDayError(response.response.data));
    }
  } catch (e) {
    yield put(staffSpecialDayError(e.response.data));
  }
}

function* updateStaffSpecialDay(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.put(`company/staff-special-day/${id}`, action.payload);
  try {
    if (response && response.status === 200) {
      yield put(updateStaffSpecialDaySuccess(response.data));
    } else {
      yield put(updateStaffSpecialDayError(response.response.data));
    }
  } catch (e) {
    yield put(updateStaffSpecialDayError(e.response.data));
  }
}

function* deleteStaffSpecialDay(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.delete(`company/staff-special-day/${id}`);
  try {
    if (response && response.status === 200) {
      yield put(deleteStaffSpecialDaySuccess(response.data));
    } else {
      yield put(deleteStaffSpecialDayError(response.response.data));
    }
  } catch (e) {
    yield put(deleteStaffSpecialDayError(e.response.data));
  }
}

function* addCloseDay(action) {
  const response = yield axiosInstance.post('company/close-day', action.payload);
  try {
    if (response && response.status === 200) {
      yield put(closeDaySuccess(response.data));
    } else {
      yield put(closeDayError(response.response.data));
    }
  } catch (e) {
    yield put(closeDayError(e.response.data));
  }
}

function* updateCloseDay(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.put(`company/close-day/${id}`, action.payload);
  try {
    if (response && response.status === 200) {
      yield put(updateCloseDaySuccess(response.data));
    } else {
      yield put(updateCloseDayError(response.response.data));
    }
  } catch (e) {
    yield put(updateCloseDayError(e.response.data));
  }
}

function* deleteCloseDay(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.delete(`company/close-day/${id}`);
  try {
    if (response && response.status === 200) {
      yield put(deleteCloseDaySuccess(response.data));
    } else {
      yield put(deleteCloseDayError(response.response.data));
    }
  } catch (e) {
    yield put(deleteCloseDayError(e.response.data));
  }
}

function* addSpecialDay(action) {
  const response = yield axiosInstance.post('company/special-day', action.payload);
  try {
    if (response && response.status === 200) {
      yield put(specialDaySuccess(response.data));
    } else {
      yield put(specialDayError(response.response.data));
    }
  } catch (e) {
    yield put(specialDayError(e.response.data));
  }
}

function* updateSpecialDay(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.put(`company/special-day/${id}`, action.payload);
  try {
    if (response && response.status === 200) {
      yield put(updateSpecialDaySuccess(response.data));
    } else {
      yield put(updateSpecialDayError(response.response.data));
    }
  } catch (e) {
    yield put(updateSpecialDayError(e.response.data));
  }
}

function* deleteSpecialDay(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.delete(`company/special-day/${id}`);
  try {
    if (response && response.status === 200) {
      yield put(deleteSpecialDaySuccess(response.data));
    } else {
      yield put(deleteSpecialDayError(response.response.data));
    }
  } catch (e) {
    yield put(deleteSpecialDayError(e.response.data));
  }
}

function* specialStaffGraphic(action) {
  const response = yield axiosInstance.post('company/staff/available-hours', action.payload);
  try {
    if (response && response.status === 200) {
      yield put(staffSpecialGraphicSuccess(response.data));
    } else {
      yield put(staffSpecialGraphicError(response.response.data.errors));
    }
  } catch (e) {
    yield put(staffSpecialGraphicError(e.response.data));
  }
}

function* getAvailableDates(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('company/staff/available-date', payload);
    if (response && response.status === 200) {
      yield put(getStaffAvailableDatesSuccess(response.data));
    } else {
      yield put(getStaffAvailableDatesError(response.data));
    }
  } catch (e) {
    yield put(getStaffAvailableDatesError(e.response.data));
  }
}

function* listOwnerCompanies(action) {
  try {
    const { payload: { isForCompanyList } } = action;
    let url = 'company/list';
    if (isForCompanyList) {
      url = `${url}?isForCompanyList=true`;
    }
    const response = yield axiosInstance.get(url);
    if (response && response.status === 200) {
      yield put(listOwnerCompaniesSuccess(response.data));
    } else {
      yield put(listOwnerCompaniesError());
    }
  } catch (e) {
    yield put(listOwnerCompaniesError());
  }
}

function* uploadImageToAmazon(action) {
  const url = action.payload.preSignUrl;
  const { logo } = action.payload;
  const config = {
    method: 'PUT',
    url,
    data: logo,
    headers: { 'Content-Type': logo.type },
  };
  try {
    const response = yield axios(config);
    if (response && response.status === 200) {
      yield put(uploadImageToAmazonSuccess());
    } else {
      yield put(uploadImageToAmazonError());
    }
  } catch (e) {
    yield put(uploadImageToAmazonError());
  }
}

function* getPreSignUrl(action) {
  let url = `pre-signed-url?logo=${action.payload.logo}`;
  if (action.payload.folder) {
    url += `&folder=${action.payload.folder}`;
  }
  try {
    const response = yield axiosInstance.get(url);
    if (response && response.status && response.status === 200) {
      yield put(getPreSignUrlSuccess(response.data));
    } else {
      yield put(getPreSignUrlError());
    }
  } catch (e) {
    yield put(getPreSignUrlError());
  }
}

function* getOwnerCompany() {
  try {
    const response = yield axiosInstance.get('/w/company');
    if (response && response.status && response.status === 200) {
      yield put(getOwnerCompanySuccess(response.data.data));
    } else {
      yield put(getOwnerCompanyError());
    }
  } catch (e) {
    yield put(getOwnerCompanyError());
  }
}

function* getWorkingSchedule() {
  try {
    const response = yield axiosInstance.get('/company/working-schedule');
    if (response && response.status === 200) {
      yield put(getCompanyWorkingScheduleSuccess(response.data.data));
    } else {
      yield put(getCompanyWorkingScheduleError());
    }
  } catch (e) {
    yield put(getCompanyWorkingScheduleError());
  }
}

function* getCompanyImages() {
  try {
    const response = yield axiosInstance.get('/company/images');
    if (response && response.status && response.status === 200) {
      yield put(getCompanyImagesSuccess(response.data.data));
    } else {
      yield put(getCompanyImagesError());
    }
  } catch (e) {
    yield put(getCompanyImagesError());
  }
}

function* getCompanyCalendar() {
  try {
    const response = yield axiosInstance.get('/company/calendar');
    if (response && response.status && response.status === 200) {
      yield put(getCompanyCalendarSuccess(response.data.data));
    } else {
      yield put(getCompanyCalendarError());
    }
  } catch (e) {
    yield put(getCompanyCalendarError());
  }
}

function* getCompany() {
  try {
    const response = yield axiosInstance.get('/company/get');
    if (response && response.status && response.status === 200) {
      yield put(getCompanySuccess(response.data.data));
    } else {
      yield put(getCompanyError);
    }
  } catch (e) {
    yield put(getCompanyError());
  }
}

export default function* () {
  yield takeLatest(storeCompanyRequest, storeCompany);
  yield takeLatest(storeGalleryCompanyRequest, storeGalleryCompany);
  yield takeLatest(deleteGalleryCompanyRequest, deleteGalleryCompany);
  yield takeLatest(getOwnerCompaniesRequest, getOwnerCompanies);
  yield takeLatest(updateCompanyRequest, updateCompany);
  yield takeLatest(getAllCompaniesRequest, getAllCompanies);
  yield takeLatest(getCompanyByIdRequest, getCompanyById);
  yield takeLatest(getCompanyByIdForCalendarRequest, getCompanyByIdForCalendar);
  yield takeLatest(getCleanCompanyByIdRequest, getCleanCompanyById);
  yield takeLatest(deleteCompanyRequest, deleteCompany);
  yield takeLatest(getFilteredCompaniesRequest, getFilteredCompanies);
  yield takeLatest(storeCustomerToCompanyRequest, storeCustomerToCompany);
  yield takeLatest(inviteCustomerToCompanyRequest, inviteCustomerToCompany);
  yield takeLatest(leaveCompanyRequest, leaveCompany);
  yield takeLatest(getPersonalCompaniesRequest, getPersonalCompanies);
  yield takeLatest(getStaffWithServicesRequest, getStaffWithServices);
  yield takeLatest(getStaffCompanyAppointmentsRequest, getStaffCompanyAppointments);
  yield takeLatest(staffSpecialDayRequest, addStaffSpecialDay);
  yield takeLatest(updateStaffSpecialDayRequest, updateStaffSpecialDay);
  yield takeLatest(deleteStaffSpecialDayRequest, deleteStaffSpecialDay);
  yield takeLatest(staffDayOffRequest, addStaffDayOff);
  yield takeLatest(updateStaffDayOffRequest, updateStaffDayOff);
  yield takeLatest(deleteStaffDayOffRequest, deleteStaffDayOff);
  yield takeLatest(closeDayRequest, addCloseDay);
  yield takeLatest(updateCloseDayRequest, updateCloseDay);
  yield takeLatest(deleteCloseDayRequest, deleteCloseDay);
  yield takeLatest(specialDayRequest, addSpecialDay);
  yield takeLatest(updateSpecialDayRequest, updateSpecialDay);
  yield takeLatest(deleteSpecialDayRequest, deleteSpecialDay);
  yield takeLatest(staffSpecialGraphicRequest, specialStaffGraphic);
  yield takeLatest(getStaffAvailableDatesRequest, getAvailableDates);
  yield takeLatest(listOwnerCompaniesRequest, listOwnerCompanies);
  yield takeLatest(uploadImageToAmazonRequest, uploadImageToAmazon);
  yield takeLatest(getPreSignUrlRequest, getPreSignUrl);
  yield takeLatest(getOwnerCompanyRequest, getOwnerCompany);
  yield takeLatest(getCompanyImagesRequest, getCompanyImages);
  yield takeLatest(getCompanyWorkingScheduleRequest, getWorkingSchedule);
  yield takeLatest(getCompanyCalendarRequest, getCompanyCalendar);
  yield takeLatest(getCompanyRequest, getCompany);
}
