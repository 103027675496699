import React, { useEffect, useState } from 'react';
import {
  Menu,
  Select,
  Checkbox,
  Box,
  Button,
  Divider,
  MenuItem,
  Typography,
} from '@material-ui/core';
import './index.scss';
import PropTypes from 'prop-types';
import hours from '../../../../../../Modules/hours';
import industriesLogo from '../../../../../../assets/industriesLogo';
import filterStyle from '../../../../../../Modules/filterStyles';
import Checkboxes from '../../../../../../Modules/checkboxes';

function FilterCalendarCustomer(props) {
  const {
    open,
    anchorEl,
    filterData,
    currentDay,
    appointments,
    handleCloseFilter,
    submitFilterItems,
    filterCustomerAppointments,
    handleCurrentDayTimeChange,
  } = props;

  const [checkedItems, setCheckedItems] = useState({});
  const [appointmentIndustry, setAppointmentIndustry] = useState([]);
  const [appointmentStatus, setAppointmentStatus] = useState([]);

  useEffect(() => {
    if (appointments.length) {
      const appointmentsCopy = [...appointments];
      const appointmentsIndustry = appointmentsCopy.map((item) => item.industry);
      const uniqueIndustry = [...new Map(appointmentsIndustry.map((item) => [item['id'], item])).values()];
      const industryId = uniqueIndustry.map((item) => item.id);
      const selectedStatus = (filterData.status.filter((item, index) => (index < 2))).map((item) => parseInt(item.status_id));
      setCheckedItems({
        status_ids: selectedStatus,
        industry_ids: industryId,
      });
      setAppointmentIndustry(uniqueIndustry);
      setAppointmentStatus(filterData.status);
      filterCustomerAppointments({
        status_ids: selectedStatus,
        industry_ids: industryId,
      });
    }
  }, []);

  // handle change and filter checkboxes
  const handleChangeCheckboxes = (event) => {
    const { name, value } = event.target;
    const checkedCheckBoxItems = checkedItems[name].includes(parseInt(value))
      ? { ...checkedItems, [name]: checkedItems[name].filter((item) => item !== parseInt(value)) }
      : { ...checkedItems, [name]: [...checkedItems[name], parseInt(value)] };

    setCheckedItems({ ...checkedCheckBoxItems });
  };

  return (
    <Menu
      className="filter-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={handleCloseFilter}
    >
      {appointmentIndustry.length ? (
        <>
          <Typography variant="h5">Industry</Typography>
          <Divider />
        </>
      ) : null}
      {appointmentIndustry.map((industry) => (
        <li key={industry.id} className="industries-list">
          <Box alignSelf="center" className="industry-info">
            <Box display="flex">
              <img src={industriesLogo[industry.name]} className="industry-logo" alt="industryLogo" width="20px" /> {industry.name}
            </Box>
          </Box>
          <Checkbox
            color="primary"
            value={industry.id}
            name="industry_ids"
            checked={Object.keys(checkedItems).length ? checkedItems.industry_ids.includes(industry.id) : ''}
            onChange={(e) => handleChangeCheckboxes(e, industry.id)}
          />
        </li>
      ))}
      <Divider />
      <Typography variant="h5">Status</Typography>
      {appointmentStatus.map((status) => (
        <li key={status.name}>
          <Box alignSelf="center" mr="5px" className="filter-text">
            <Typography component="div" noWrap>
              {status.title}
            </Typography>
          </Box>
          { parseInt(status.status_id) === 1
            ? (
              <Checkboxes.Accept
                value={status.status_id}
                checked={Object.keys(checkedItems).length ? checkedItems.status_ids.includes(parseInt(status.status_id)) : ''}
                onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                name="status_ids"
              />
            )
            : parseInt(status.status_id) === 2
              ? (
                <Checkboxes.Pending
                  value={status.status_id}
                  checked={Object.keys(checkedItems).length ? checkedItems.status_ids.includes(parseInt(status.status_id)) : ''}
                  onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                  name="status_ids"
                />
              )
              : parseInt(status.status_id) === 3
                ? (
                  <Checkboxes.Cancel
                    value={status.status_id}
                    checked={Object.keys(checkedItems).length ? checkedItems.status_ids.includes(parseInt(status.status_id)) : ''}
                    onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                    name="status_ids"
                  />
                ) : (
                  <Checkboxes.Finish
                    value={status.status_id}
                    checked={Object.keys(checkedItems).length ? checkedItems.status_ids.includes(parseInt(status.status_id)) : ''}
                    onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                    name="status_ids"
                  />
                )}
        </li>
      ))}
      <Divider />
      <Typography variant="h5">Calendar TimeSlot</Typography>
      <Box className="filter-hours">
        <Box>
          <div className="hours-select">
            <Select
              variant="outlined"
              id="start_time"
              disableUnderline
              data-cy="working-start-time"
              value={currentDay.start_time}
              name="start_time"
              MenuProps={filterStyle.MenuProps}
              onChange={(event) => handleCurrentDayTimeChange(event)}
            >
              {hours.map((hour) => <MenuItem key={Math.random()} disabled={hour >= currentDay.end_time} value={hour}>{hour}</MenuItem>)}
            </Select>
            <span>-</span>
            <Select
              variant="outlined"
              id="end_time"
              disableUnderline
              data-cy="working-end-time"
              value={currentDay.end_time}
              name="end_time"
              MenuProps={filterStyle.MenuProps}
              onChange={(event) => handleCurrentDayTimeChange(event)}
            >
              {hours.map((hour) => <MenuItem key={Math.random()} disabled={hour <= currentDay.start_time} value={hour}>{hour}</MenuItem>)}
            </Select>
          </div>
        </Box>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="flex-end" mt="16px">
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => submitFilterItems(checkedItems)}
        >
          Apply
        </Button>
      </Box>
    </Menu>
  );
}

FilterCalendarCustomer.propTypes = {
  anchorEl: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  currentDay: PropTypes.object.isRequired,
  filterData: PropTypes.object.isRequired,
  appointments: PropTypes.array.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  submitFilterItems: PropTypes.func.isRequired,
  handleCurrentDayTimeChange: PropTypes.func.isRequired,
  filterCustomerAppointments: PropTypes.func.isRequired,
};

FilterCalendarCustomer.defaultProps = {
  anchorEl: null,
};

export default FilterCalendarCustomer;
