import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import {
  FormControl, FormHelperText, Grid, makeStyles, TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import getServicePrice from '../../../../../../../Modules/getServicePrice';
import serviceDuration from '../../../../../../../Modules/serviceDuration';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import {
  getCompanyServicesWithSubServicesRequest, getServicesByCompanyRequest,
} from '../../../../../../../redux/service/actions';
import { staffSpecialGraphicRequest } from '../../../../../../../redux/company/actions';
import { getStaffCurrentServicesRequest } from '../../../../../../../redux/staff/actions';

function Service({
  errors, handleChange, appointmentItem, isFirst,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    isGetServicesByCompanySuccess,
    servicesByCompany,
    isGetCompanyServicesWithSubServicesSuccess,
    servicesWithSubServicesByCompanyId,
  } = useSelector((state) => state.service);

  const { companyGot } = useSelector((state) => state.company);

  const prevIsGetServicesByCompanySuccess = usePrevious(isGetServicesByCompanySuccess);
  const prevIsGetCompanyServicesWithSubServicesSuccess = usePrevious(isGetCompanyServicesWithSubServicesSuccess);

  const [selectedOption, setSelectedOption] = useState({});
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isFirst) {
      setLoading(true);
      // if (servicesByCompany.length) {
      //   getServices();
      // } else {
      //   dispatch(getServicesByCompanyRequest());
      // }
      dispatch(getServicesByCompanyRequest());
    } else if (appointmentItem.staff_id) {
      setLoading(true);
      dispatch(getCompanyServicesWithSubServicesRequest({ staff_id: appointmentItem.staff_id }));
    }
  }, []);

  useEffect(() => {
    if ((prevIsGetCompanyServicesWithSubServicesSuccess === false && isGetCompanyServicesWithSubServicesSuccess) || (prevIsGetServicesByCompanySuccess === false && isGetServicesByCompanySuccess)) {
      setSelectedOption({});
      getServices();
    }
  }, [isGetCompanyServicesWithSubServicesSuccess, isGetServicesByCompanySuccess]);

  const getServices = () => {
    const services = isFirst ? servicesByCompany : servicesWithSubServicesByCompanyId;
    services.forEach((item) => {
      typeof item.subServices === 'string' ? item.subServices = JSON.parse(item.subServices) : item;
    });
    const serviceOptions = services.reduce((akku, row) => {
      const service = [];
      row.subServices.map((item) => {
        service.push({
          type: !service.length ? row.name_en : null,
          name: item.name || item.name_en || '',
          duration: item.duration,
          company_service_id: item.company_service_id,
          price: item.price,
          id: item.company_service_id,
        });
      });
      akku = akku.concat(service);
      return akku;
    }, []);

    if (appointmentItem.service_id) {
      const founded = serviceOptions.find((i) => i.id === appointmentItem.service_id);
      if (founded !== -1) {
        setSelectedOption(founded);
      }
    }

    setLoading(false);
    setOptions(serviceOptions);
  };

  const handleChangeOption = (e, value) => {
    if (value) {
      setSelectedOption(value);
      handleChange('service_id', value.company_service_id);
      handleChange('price', value.price);
      handleChange('duration', value.duration);

      if (!isFirst) {
        dispatch(staffSpecialGraphicRequest({
          staff_id: appointmentItem.staff_id,
          company_service_id: value.company_service_id,
          date: appointmentItem.date,
        }));
      } else {
        handleChange('staff_id', null);
        dispatch(getStaffCurrentServicesRequest({
          company_service_id: value.company_service_id,
        }));
      }
    } else {
      setSelectedOption({});
      handleChange('service_id', null);
      handleChange('price', null);
      handleChange('duration', null);
    }
  };

  const useStyles = makeStyles({
    option: {
      borderBottom: '1px solid #E9E9E9',
      padding: '10px 16px',
      paddingLeft: '16px !important',
    },
  });

  const styles = useStyles();

  return (
    <div className="input-content">
      <div className="input-title-box">
        <ListAltOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
        <span className="input-title">
          {t('Service')}
        </span>
      </div>
      <FormControl
        fullWidth
        error={!!errors.service}
        size="small"
        variant="outlined"
      >
        <Autocomplete
          disabled={!appointmentItem.staff_id && !isFirst}
          size="small"
          fullWidth
          autoHighlight
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          selectOnFocus
          clearOnBlur
          options={options}
          loading={loading}
          loadingText={options.length ? t('Loading') : t('NoOptions')}
          classes={{
            option: styles.option,
          }}
          groupBy={(option) => (
            <span className="group-title">
              {option.type}
            </span>
          )}
          getOptionLabel={(option) => option.name || ''}
          value={selectedOption}
          onChange={(event, newValue) => handleChangeOption(event, newValue)}
          renderOption={(option) => (
            <div
              id="appointment-menu-item"
              className="input-menu"
              key={option.id}
              value={option.id}
            >
              <Grid container direction="row">
                <Grid
                  item
                  xs={8}
                  style={{ height: '19px' }}
                >
                  <span
                    className="service-name-appointment"
                    style={{
                      display: 'inline-block',
                      width: '200px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      color: '#393939',
                      font: 'normal 500 14px/19px Nunito sans, sans-serif',
                    }}
                  >
                    {option.name}
                  </span>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justify="space-between">
                    <Grid
                      item
                      xs={6}
                      style={{ height: '16px' }}
                    >
                      <span
                        className="duration"
                        style={{
                          color: '#A4A4A4',
                          font: 'normal 500 12px/16px Nunito sans, sans-serif',
                        }}
                      >
                        {option.duration ? serviceDuration(option.duration, t('H'), t('Min')) : '-'}
                      </span>
                    </Grid>
                    <Grid item xs={6} style={{ height: '16px' }}>
                      <span
                        className="price"
                        style={{
                          color: '#393939',
                          font: 'normal 600 12px/16px Nunito sans, sans-serif',
                          display: 'block',
                          textAlign: 'right',
                        }}
                      >
                        {option.price ? getServicePrice(option.price.type, option.price.price, companyGot) : '-'}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label={`${t('Service')} *`}
              placeholder={t('ChooseService')}
              variant="outlined"
              error={errors.service}
            />
          )}
        />
        { errors.service && (
          <div className="error-message-content">
            <ErrorIcon fontSize="small" color="error" />
            <FormHelperText>
              {t('Service') + t('IsRequired')}
            </FormHelperText>
          </div>
        )}
      </FormControl>

      {Object.keys(selectedOption).length ? (
        <div className="service-info">
          <div className="text">
            <span>
              {appointmentItem.price && getServicePrice(appointmentItem.price.type, appointmentItem.price.price, companyGot)}
            </span>
            <span style={{ marginLeft: 10 }}>
              {serviceDuration(appointmentItem.duration, t('H'), t('Min'))}
            </span>
          </div>
          <AccessTimeIcon />
        </div>
      ) : null}
    </div>
  );
}

Service.propTypes = {
  errors: PropTypes.object.isRequired,
  appointmentItem: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isFirst: PropTypes.bool,
};

Service.defaultProps = {
  isFirst: false,
};

export default Service;
