import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { acceptStaffInvitationRequest } from '../../../../redux/staff/actions';
import Loading from '../../../../Components/Loading/loading';
import SetNewPassword from '../../Onboarding/SetNewPassword';
import SnackbarToast from '../../../../Modules/SnackbarToast';

class AcceptInvitation extends PureComponent {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    handleLogin: PropTypes.func.isRequired,
    isJustInvitationAccepted: PropTypes.bool.isRequired,
    // Accept Invitation Props
    acceptInvitation: PropTypes.func.isRequired,
    isAcceptInvitationSuccess: PropTypes.bool.isRequired,
    isAcceptInvitationError: PropTypes.bool.isRequired,
    acceptInvitationErrorMessage: PropTypes.string.isRequired,
    invitedStaff: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      openSnackbar: false,
      snackbarType: '',
      snackbarMessage: '',
    };
  }

  componentDidMount() {
    const {
      history,
      match,
      acceptInvitation,
      isJustInvitationAccepted,
    } = this.props;
    if (!isJustInvitationAccepted) {
      if (localStorage.getItem('token')) {
        history.push('/calendar');
      } else {
        const { token } = match.params;
        if (token) {
          acceptInvitation({ token });
        } else {
          this.setState({
            openSnackbar: true,
            snackbarMessage: 'Token is not identified !!!',
            snackbarType: 'error',
          });
          history.push('/login');
        }
      }
    } else {
      this.setState({ loading: false });
    }
  }

  componentDidUpdate(prevProps) {
    const {
      isAcceptInvitationSuccess, isAcceptInvitationError,
      acceptInvitationErrorMessage, invitedStaff,
      history, handleLogin,
    } = this.props;
    // Handle Accept Invitation Success
    if (!prevProps.isAcceptInvitationSuccess && isAcceptInvitationSuccess && invitedStaff.id) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: i18n.t('InvitationAccepted'),
        snackbarType: 'success',
      });
      if (invitedStaff.token) {
        handleLogin(invitedStaff.token);
      } else {
        history.push('/calendar');
      }
    }
    // Handle Accept Invitation Error
    if (!prevProps.isAcceptInvitationError && isAcceptInvitationError) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: acceptInvitationErrorMessage,
        snackbarType: 'error',
      });
      history.push('/login');
    }
  }

  render() {
    const {
      loading,
      openSnackbar,
      snackbarType,
      snackbarMessage,
    } = this.state;
    const { invitedStaff } = this.props;

    return loading ? (
      <>
        <Loading />
        <SnackbarToast
          message={snackbarMessage}
          type={snackbarType}
          open={openSnackbar}
          onClose={() => openSnackbar(false)}
        />
      </>
    ) : <SetNewPassword user={invitedStaff} />;
  }
}

const mapStateToProps = (state) => ({
  // Accept Invitation Props
  isAcceptInvitationSuccess: state.staff.isAcceptStaffInvitationSuccess,
  isAcceptInvitationError: state.staff.isAcceptStaffInvitationError,
  acceptInvitationErrorMessage: state.staff.acceptStaffInvitationErrorMessage,
  invitedStaff: state.staff.invitedStaff,
});

function mapDispatchToProps(dispatch) {
  return {
    acceptInvitation: (data) => dispatch(acceptStaffInvitationRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInvitation);
