import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import i18n from 'i18next';
import usePrevious from '../../CustomHooks/usePrevious';
import { createNewRoleAccountRequest } from '../../redux/account/actions';
import SnackbarToast from '../../Modules/SnackbarToast';

const CreateNewAccountModal = (props) => {
  const {
    handleCreateAccountProps,
    createAccountRoleName,
    createNewRoleAccount,
    isCreateNewRoleAccountSuccess,
    isCreateNewRoleAccountError,
    createNewRoleAccountErrorMessage,
  } = props;

  const prevIsCreateNewRoleAccountSuccess = usePrevious(isCreateNewRoleAccountSuccess);
  const prevIsCreateNewRoleAccountError = usePrevious(isCreateNewRoleAccountError);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (!prevIsCreateNewRoleAccountSuccess && isCreateNewRoleAccountSuccess) {
      snackBarAlert(true, i18n.t('AccountCreated'), 'success');
      localStorage.setItem('prefix', createAccountRoleName);
      handleCreateAccountProps({
        showCreateAccountModal: false,
        createAccountRoleName: '',
      });
      window.location.reload(false);
    }
  }, [isCreateNewRoleAccountSuccess]);

  useEffect(() => {
    if (!prevIsCreateNewRoleAccountError && isCreateNewRoleAccountError) {
      handleCreateAccountProps({
        showCreateAccountModal: false,
        createAccountRoleName: '',
      });
      snackBarAlert(true, createNewRoleAccountErrorMessage, 'error');
    }
  }, [isCreateNewRoleAccountError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const createAccount = () => {
    createNewRoleAccount({
      name: createAccountRoleName,
    });
  };

  const close = () => {
    handleCreateAccountProps({
      showCreateAccountModal: false,
    });
  };

  return (
    <>
      <Dialog
        open
        onClose={close}
      >
        <DialogTitle id="customized-dialog-title">
          <span className="alert-title">
            <span>Activate New Account</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={close}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>Are you sure you want to activate
            <span className="role-name"> { createAccountRoleName === 'Owner' ? 'Business' : createAccountRoleName } </span> account ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => handleCreateAccountProps({
              showCreateAccountModal: false,
              createAccountRoleName: '',
            })}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => createAccount()}
          >
            Activate
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

CreateNewAccountModal.propTypes = {
  handleCreateAccountProps: PropTypes.func.isRequired,
  createAccountRoleName: PropTypes.string.isRequired,
  createNewRoleAccount: PropTypes.func.isRequired,
  isCreateNewRoleAccountSuccess: PropTypes.bool.isRequired,
  isCreateNewRoleAccountError: PropTypes.bool.isRequired,
  createNewRoleAccountErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // Create New Role Account Props
  isCreateNewRoleAccountSuccess: state.account.isCreateNewRoleAccountSuccess,
  isCreateNewRoleAccountError: state.account.isCreateNewRoleAccountError,
  createNewRoleAccountErrorMessage: state.account.createNewRoleAccountErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    createNewRoleAccount: (data) => dispatch(createNewRoleAccountRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewAccountModal);
