import React, { useContext, useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Drawer,
  FormHelperText,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { getCompanyStaffForSelectRequest } from 'redux/staff/actions';
import {
  addServiceRequest,
  updateCompanyServiceRequest,
  addCustomServiceRequest,
} from 'redux/service/actions';
import usePrevious from 'CustomHooks/usePrevious';

import hours from 'Modules/hour';
import minutes from 'Modules/minutes';
import ErrorIcon from '@material-ui/icons/Error';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import deleteIcon from 'assets/icons/delete.svg';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import getCurrencyIcon from 'Modules/getCurrencyIcon';
import ServiceContext from '../serviceContext';
import styles from './styles.module.scss';
import { checkAppointmentsByServiceIdRequest } from '../../../../../../redux/appointment/actions';
import CheckAppointmentServiceModal from '../Modal/checkAppointmentsModal';
import getInitials from '../../../../../../Modules/getInitials';
import DefaultProfileImage from '../../../../../../assets/img/default-profile.png';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 'calc(100vh - 54px - 64px)',

    '&::-webkit-scrollbar': {
      height: 0,
      width: 0,
    },
  },
  content: {
    '&::-webkit-scrollbar': {
      height: 0,
      width: 0,
    },
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  label: {
    marginLeft: 0,
    font: 'normal 400 14px/19px Nunito Sans, sans-serif',
    color: '#393939',
  },
  menuPaper: {
    maxHeight: 300,
  },
  icon: {
    fontSize: 14,
    fill: '#393939',
  },
  badgeIcon: {
    transform: 'translate(8px, -7px)',
  },
  checkbox: {
    marginLeft: '15px',
  },
  inputHeight: {
    height: 40,
  },
  ellipsisText: {
    width: 'calc(100% - 20px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const CustomizedTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#E8E6E6',
    color: '#000000',
    fontSize: 11,
    fontWeight: 400,
  },
}))(Tooltip);

function ServiceDrawer() {
  const {
    isGetCompanyStaffSelectSuccess,
    companyStaffForSelect,
  } = useSelector((state) => state.staff);

  const {
    isAddServiceSuccess,
    isAddServiceError,
    addServiceErrorMessage,
    isUpdateCompanyServiceError,
    isUpdateCompanyServiceSuccess,
    updateServiceCompanyErrorMessage,
    updatedCompanyService,
    addedService,
    isAddCustomServiceSuccess,
    isAddCustomServiceError,
    customAddedCategory,
    addCustomServiceErrorMessage,
    services: allServices,
  } = useSelector((state) => state.service);

  const {
    isCheckAppointmentByServiceIdSuccess,
    isCheckAppointmentByServiceIdError,
    appointments: checkedApp,
  } = useSelector((state) => state.appointment);

  const { companyGot } = useSelector((state) => state.company);

  const {
    openServiceDrawer,
    setOpenServiceDrawer,
    snackBarAlert,
    price,
    setPrice,
    selectedCategoryServices,
    setSelectedCategoryServices,
    errors,
    setErrors,
    handleOpenDeleteModal,
    categories,
    setCategories,
    setSelectedCategoryIndex,
    selectedCategoryIndex,
    handleSelectCategory,
    // currentCategory,
    selectedServiceId,
    setSelectedServiceId,
    services,
    setServices,
    setOpenCategoryModal,
  } = useContext(ServiceContext);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const classes = useStyles();

  // Get some props previous value
  const prevIsGetCompanyStaffSelectSuccess = usePrevious(isGetCompanyStaffSelectSuccess);
  const prevIsAddServiceSuccess = usePrevious(isAddServiceSuccess);
  const prevIsAddServiceError = usePrevious(isAddServiceError);
  const prevIsAddCustomServiceSuccess = usePrevious(isAddCustomServiceSuccess);
  const prevIsAddCustomServiceError = usePrevious(isAddCustomServiceError);
  const prevIsUpdateCompanyServiceSuccess = usePrevious(isUpdateCompanyServiceSuccess);
  const prevIsUpdateCompanyServiceError = usePrevious(isUpdateCompanyServiceError);
  const prevIsCheckAppointmentByServiceIdSuccess = usePrevious(isCheckAppointmentByServiceIdSuccess);
  const prevIsCheckAppointmentByServiceIdError = usePrevious(isCheckAppointmentByServiceIdError);

  const [subServices, setSubServices] = useState([]);
  const [serviceItem, setServiceItem] = useState({
    show_on_widget: true,
    auto_accept: true,
    isShowBufferTime: false,
    duration: 0,
  });
  const [fromPrice, setFromPrice] = useState('');
  const [toPrice, setToPrice] = useState('');
  const [selectedStaffOptions, setSelectedStaffOptions] = useState([]);
  const [selectedServiceData, setSelectedServiceData] = useState({});
  const [allCategoriesData, setAllCategoriesData] = useState([]);
  const [isOpenCheckAppointmentModal, setIsOpenCheckAppointmentModal] = useState(false);
  const [updatedData, setUpdatedData] = useState({});

  useEffect(() => {
    if (!companyStaffForSelect.length) {
      dispatch(getCompanyStaffForSelectRequest());
    } else {
      getCompanyStaff();
    }
  }, []);

  // Handle Get Company Staff
  useEffect(() => {
    if (prevIsGetCompanyStaffSelectSuccess === false && isGetCompanyStaffSelectSuccess) {
      getCompanyStaff();
    }
  }, [isGetCompanyStaffSelectSuccess]);

  useEffect(() => {
    const filtered = categories.filter((category) => category.id);
    setAllCategoriesData(filtered);
  }, [categories]);

  // useEffect(() => {
  //   if (serviceItem.category) {
  //     const selectedCategory = services.find((service) => service.id === (serviceItem.category.id || serviceItem.parent_id));
  //     const filteredCategoryService = allServices.find((service) => service.id === (serviceItem.category.id || serviceItem.parent_id));
  //     if (filteredCategoryService && filteredCategoryService.subServices) {
  //       const ids = [...new Set(filteredCategoryService.subServices.map((d) => d.id))];
  //       const selectedCategorySubServices = selectedCategory ? typeof selectedCategory.subServices === 'string' ? JSON.parse(selectedCategory.subServices) : selectedCategory.subServices : [];
  //       const filteredData = selectedCategorySubServices.filter((d) => !ids.includes(d.id));
  //       const merged = filteredCategoryService.subServices.concat(filteredData);
  //       setSubServices(merged);
  //     } else {
  //       setSubServices(selectedCategory ? typeof selectedCategory.subServices === 'string' ? JSON.parse(selectedCategory.subServices) : selectedCategory.subServices : []);
  //     }
  //   }
  // }, [serviceItem.category]);

  useEffect(() => {
    if (prevIsAddServiceSuccess === false && isAddServiceSuccess) {
      setOpenServiceDrawer(false);
      setServiceItem({});
      setPrice({ type: 'fixed', price: { from: '0', to: '0' } });
      snackBarAlert(true, 'Service added successfully', 'success');

      const foundedCategory = categories.find((item) => item.id === addedService.id);
      if (!foundedCategory) {
        setCategories([...categories, { id: addedService.id, name: addedService.name }]);
        setSelectedCategoryIndex(categories.length);
        handleSelectCategory(addedService.id, addedService.name, categories.length);
      } else {
        const categoryIndex = categories.findIndex((category) => category.id === addedService.id);
        handleSelectCategory(addedService.id, addedService.name, categoryIndex);
        setSelectedCategoryServices(JSON.parse(addedService.subServices));
        setSelectedCategoryIndex(categoryIndex);
        // const servicesCopy = [...services];
        // servicesCopy[categoryIndex - 1] = JSON.parse(addedService.subServices);
        // setServices(servicesCopy);
      }
    } else if (prevIsAddServiceError === false && isAddServiceError) {
      snackBarAlert(true, addServiceErrorMessage, 'error');
    }
  }, [isAddServiceSuccess, isAddServiceError]);

  useEffect(() => {
    if (selectedServiceId.length) {
      let selectedService;
      if (selectedCategoryServices && selectedCategoryServices.length) {
        selectedService = selectedCategoryServices.find((category) => category.company_service_id === selectedServiceId[0]);
      }
      const selectedCategory = services.find((service) => service.id === selectedService.parent_id);
      const filteredCategoryService = allServices.find((service) => service.id === selectedService.parent_id);
      if (selectedCategory && selectedCategory.subServices.length) {
        const selectedCategorySubServices = typeof selectedCategory.subServices === 'string' ? JSON.parse(selectedCategory.subServices) : selectedCategory.subServices;
        const ids = selectedCategorySubServices.map((d) => d.id);
        let merged;
        if (filteredCategoryService !== undefined) {
          merged = [...filteredCategoryService.subServices || [], ...selectedCategorySubServices.filter((d) => !ids.includes(d.id))];
        } else {
          merged = [...selectedCategorySubServices.filter((d) => !ids.includes(d.id))];
        }
        setSubServices(merged);
      }
      setSelectedServiceData(selectedService);

      const staffOptions = [];
      if (selectedService.staffs && selectedService.staffs.length) {
        selectedService.staffs.map((staff) => {
          companyStaffForSelect.map((compStaff) => {
            if (staff.id === compStaff.id) {
              staffOptions.push({
                id: compStaff.id,
                value: compStaff.id,
                avatar: compStaff.avatar,
                label: `${compStaff.first_name} ${compStaff.last_name ? compStaff.last_name : ''}`,
                first_name: compStaff.first_name,
                last_name: compStaff.last_name,
              });
            }
          });
        });
      }

      const servicePrice = selectedService.price ? selectedService.price : selectedService.service_price;

      setServiceItem({
        id: selectedService.id,
        category: selectedCategory,
        service: selectedService.name || selectedService.name_en,
        name: selectedService.name || selectedService.name_en,
        staff_ids: staffOptions,
        duration: selectedService.duration,
        extra_duration: selectedService.extra_duration,
        show_on_widget: selectedService.show_on_widget,
        auto_accept: selectedService.auto_accept,
        isShowBufferTime: !!selectedService.extra_duration > 0,
        description: selectedService.description,
        company_service_id: selectedService.company_service_id,
        service_id: selectedService.id,
        parent_id: selectedService.parent_id,
        service_price: servicePrice,
        editType: 'update',
        industry_id: companyGot.industry_id,
      });
      setPrice({ ...price, type: servicePrice.type, price: { from: servicePrice.price.from, to: servicePrice.price.to } });
      setFromPrice(servicePrice.price.from);
      setToPrice(servicePrice.price.to);
    } else if (!selectedServiceId.length) {
      const newServiceItem = {
        show_on_widget: true,
        auto_accept: true,
        isShowBufferTime: false,
        category:
          selectedCategoryIndex && allCategoriesData.length ? allCategoriesData[selectedCategoryIndex - 1] : undefined,
      };
      if (!companyGot.is_staff_based && selectedStaffOptions.length) {
        newServiceItem.staff_ids = [...selectedStaffOptions];
      }
      setServiceItem(newServiceItem);
    }
  }, [selectedServiceId, openServiceDrawer]);

  useEffect(() => {
    if (prevIsUpdateCompanyServiceSuccess === false && isUpdateCompanyServiceSuccess) {
      snackBarAlert(true, 'Service updated successfully', 'success');
      setOpenServiceDrawer(false);
      setIsOpenCheckAppointmentModal(false);
      const serviceIndex = selectedCategoryServices.findIndex((service) => service.company_service_id === serviceItem.company_service_id);
      if (serviceIndex !== -1) {
        const staffOptions = [];
        if (serviceItem.staff_ids) {
          serviceItem.staff_ids.map((staff) => {
            staffOptions.push({
              id: staff.id,
              first_name: staff.first_name,
              last_name: staff.last_name || '',
              avatar: staff.avatar,
            });
          });
        }
      }

      const servicesCopy = [...services];
      if (serviceItem.category && serviceItem.category.id !== serviceItem.parent_id) {
        const category = servicesCopy.find((item) => item.id === serviceItem.category.id);
        const categoryIndex = servicesCopy.find((item) => item.id === serviceItem.category.id);
        const prevCategory = servicesCopy.find((item) => item.id === serviceItem.parent_id);
        const prevCategoryIndex = servicesCopy.findIndex((item) => item.id === serviceItem.parent_id);

        if (prevCategory.id !== category.id) {
          const prevSubServices = typeof prevCategory.subServices === 'string' ? JSON.parse(prevCategory.subServices) : prevCategory.subServices;
          const subServiceIndex = prevSubServices.findIndex((item) => item.id === serviceItem.id);

          prevSubServices.splice(subServiceIndex, 1);
          prevCategory.subServices = JSON.stringify(prevSubServices);
          servicesCopy[prevCategoryIndex] = prevCategory;

          const selectedCategoryServicesCopy = [...selectedCategoryServices];
          selectedCategoryServicesCopy.splice(subServiceIndex, 1);
          setSelectedCategoryServices(selectedCategoryServicesCopy);

          category.subServices.push(updatedCompanyService);
          servicesCopy[categoryIndex] = category;
          setServices(servicesCopy);
        }
      } else {
        const selectedCategoryServicesCopy = [...selectedCategoryServices];
        selectedCategoryServicesCopy[serviceIndex] = updatedCompanyService;
        selectedCategoryServicesCopy[serviceIndex]['price'] = updatedCompanyService.service_price;
        setSelectedCategoryServices(selectedCategoryServicesCopy);
      }

      setSelectedServiceId([]);
    } else if (prevIsUpdateCompanyServiceError === false && isUpdateCompanyServiceError) {
      console.log('updateServiceCompanyErrorMessage: ', updateServiceCompanyErrorMessage);
    }
  }, [isUpdateCompanyServiceSuccess, isUpdateCompanyServiceError]);

  useEffect(() => {
    if (prevIsAddCustomServiceSuccess === false && isAddCustomServiceSuccess) {
      if (!customAddedCategory.parent_id) {
        const data = {
          id: customAddedCategory.id,
          name: customAddedCategory.name_en,
          user_id: customAddedCategory.user_id,
        };
        const categoriesCopy = [...categories];
        categoriesCopy.push(data);
        setCategories(categoriesCopy);
        setOpenCategoryModal(false);
        setAllCategoriesData([...allCategoriesData, data]);
        setServiceItem({
          ...serviceItem, service_id: customAddedCategory.id,
        });
        const servicesCopy = [...services];
        servicesCopy.push({
          ...customAddedCategory,
          subServices: [],
        });
        setServices(servicesCopy);
      } else {
        const data = {
          id: customAddedCategory.id,
          industry_id: customAddedCategory.industry_id,
          name_en: customAddedCategory.name_en,
          name: customAddedCategory.name_en,
          parent_id: customAddedCategory.parent_id,
          user_id: customAddedCategory.user_id,
        };
        const subServicesCopy = [...subServices];
        subServicesCopy.push(data);
        const servicesCopy = [...allServices];
        const index = servicesCopy.findIndex((item) => item.id === customAddedCategory.parent_id);
        if (index !== -1) {
          servicesCopy[index].subServices = subServicesCopy;
          setServices(servicesCopy);
        }
        setSubServices(subServicesCopy);
        setServiceItem({
          ...serviceItem, service_id: customAddedCategory.id, parent_id: customAddedCategory.parent_id, service: customAddedCategory.name_en,
        });
      }
    } else if (prevIsAddCustomServiceError === false && isAddCustomServiceError) {
      console.log('addCustomServiceErrorMessage: ', addCustomServiceErrorMessage);
    }
  }, [isAddCustomServiceSuccess, isAddCustomServiceError]);

  useEffect(() => {
    if (prevIsCheckAppointmentByServiceIdSuccess === false && isCheckAppointmentByServiceIdSuccess) {
      if (checkedApp.length > 0) {
        setIsOpenCheckAppointmentModal(true);
      } else {
        dispatch(updateCompanyServiceRequest(updatedData));
      }
    } else if (prevIsCheckAppointmentByServiceIdError === false && isCheckAppointmentByServiceIdError) {
      snackBarAlert(true, 'Something went wrong', 'error');
    }
  }, [isCheckAppointmentByServiceIdSuccess, isCheckAppointmentByServiceIdError]);

  const getCompanyStaff = () => {
    const staffOptions = [];
    companyStaffForSelect.map((staff) => {
      staffOptions.push({
        id: staff.id,
        avatar: staff.avatar,
        label: staff.full_name,
        value: staff.id,
        first_name: staff.first_name,
        last_name: staff.last_name || '',
        professions: staff.staff_company_professions.length && staff.staff_company_professions[0].name,
      });
    });

    setSelectedStaffOptions(staffOptions);
  };

  const validateForm = (subService, price) => {
    const errorsCopy = { ...errors };
    errorsCopy.category = !subService.category || (subService.category.id === '' && subService.category.name === '');
    errorsCopy.service_id = !subService.name || subService.name === '';
    errorsCopy.staff_ids = !subService.staff_ids || !subService.staff_ids.length;
    errorsCopy.priceType = !price || price.type === '';
    errorsCopy.price = !price || price.type !== 'free' ? (price.price.from === '0' || price.price.from === '') : false;
    errorsCopy.duration = !subService.duration || subService.duration === 0;
    errorsCopy.maxToValue = price && price.price.to && Number(price.price.from) > Number(price.price.to);
    setErrors(() => ({ ...errorsCopy }));

    return (errorsCopy.category || errorsCopy.service_id || errorsCopy.staff_ids || errorsCopy.price || errorsCopy.priceType || errorsCopy.duration || errorsCopy.maxToValue);
  };

  const getStaffIDs = (selectedOptions) => {
    const staffIds = [];
    if (selectedOptions && selectedOptions.length) {
      selectedOptions.map((option) => {
        staffIds.push(option.id || option);
      });
    }

    return staffIds;
  };

  // const getCategoryName = (categoryId) => {
  //   let category;
  //   if (servicesByCompany && servicesByCompany.length) {
  //     category = servicesByCompany.find((category) => category.id === categoryId);
  //   }
  //
  //   return category && category.name;
  // };

  const getOptionSelected = (option, anotherOption) => anotherOption && option.value === anotherOption.value;

  const handleChange = (event, selectedOptions) => {
    setServiceItem({
      ...serviceItem,
      staff_ids: selectedOptions,
    });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setServiceItem({
      ...serviceItem,
      [name]: value,
    });

    if (name === 'type' || value === 'price') {
      setServiceItem({ ...serviceItem, price: { ...serviceItem['price'], [name]: value } });
    }
  };

  const handleChangeChecked = (event) => {
    const { name, checked } = event.target;
    if (name === 'show_on_widget') {
      setServiceItem({
        ...serviceItem,
        show_on_widget: checked,
        auto_accept: checked,
      });
    } else {
      setServiceItem({
        ...serviceItem,
        [name]: checked,
      });
    }
  };

  const handlePriceTypeChange = (event) => {
    const { name, value } = event.target;
    setPrice({
      ...price,
      [name]: value,
      price: { from: '0', to: '0' },
    });
    setToPrice('');
    setFromPrice('');
  };

  const handlePriceChange = (event) => {
    const { name, value } = event.target;

    if (price.type === 'range' && name === 'to') {
      setToPrice(value);
      setPrice({ ...price, price: { from: fromPrice, to: value } });
    } else if (price.type === 'range' && name === 'from') {
      setFromPrice(value);
      setPrice({ ...price, price: { from: value, to: toPrice } });
    } else if (price.type === 'fixed') {
      setPrice({ ...price, price: { from: value, to: value } });
    } else if (price.type === 'free') {
      setPrice({ ...price, price: { from: '0', to: '0' } });
    }
  };

  const handleHourOrMinChange = (e, durationType) => {
    const { name, value } = e.target;
    const serviceItemCopy = { ...serviceItem };
    if (name === 'hour') {
      const min = serviceItemCopy[durationType] ? serviceItemCopy[durationType] % 60 : 0;
      serviceItemCopy[durationType] = (parseInt(value) * 60) + min;
    } else {
      const hour = serviceItemCopy[durationType] ? Math.floor(serviceItemCopy[durationType] / 60) : 0;
      serviceItemCopy[durationType] = (hour * 60) + parseInt(value);
    }
    setServiceItem(serviceItemCopy);
  };

  const handleAddService = () => {
    if (!validateForm(serviceItem, price)) {
      const serviceItemCopy = { ...serviceItem };
      serviceItemCopy.extra_duration = serviceItemCopy.isShowBufferTime ? serviceItemCopy.extra_duration : 0;
      serviceItemCopy.auto_accept = serviceItemCopy.show_on_widget ? serviceItemCopy.auto_accept : false;
      serviceItemCopy.is_add_category = true;
      serviceItemCopy.parent_id = serviceItemCopy.category.id ? serviceItemCopy.category.id : serviceItemCopy.parent_id;
      serviceItemCopy.service_price = price;
      serviceItemCopy.specific_types = { man: false, woman: false, kids: false };
      serviceItemCopy.staff_ids = getStaffIDs(serviceItem.staff_ids);

      delete serviceItemCopy.isShowBufferTime;
      delete serviceItemCopy.category;

      dispatch(addServiceRequest(serviceItemCopy));
    }
  };

  const handleCloseDrawer = () => {
    setSelectedServiceId([]);
    setOpenServiceDrawer(false);
  };

  const handleUpdateService = () => {
    if (!validateForm(serviceItem, price)) {
      const serviceItemCopy = { ...serviceItem };
      serviceItemCopy.extra_duration = serviceItemCopy.isShowBufferTime ? serviceItemCopy.extra_duration : 0;
      serviceItemCopy.service_price = price;
      serviceItemCopy.specific_types = { man: false, woman: false, kids: false };
      serviceItemCopy.staff_ids = getStaffIDs(serviceItemCopy.staff_ids);
      serviceItemCopy.editType = 'update';

      if (serviceItem.category) {
        serviceItemCopy.parent_id = serviceItem.category.id;
      }

      delete serviceItemCopy.isShowBufferTime;
      delete serviceItemCopy.category;
      delete serviceItemCopy.service;

      setUpdatedData(serviceItemCopy);

      dispatch(checkAppointmentsByServiceIdRequest({
        service_id: serviceItemCopy.company_service_id,
        new_service_id: serviceItemCopy.service_id,
        staff_ids: getStaffIDs(serviceItemCopy.staff_ids),
        duration: serviceItemCopy.duration,
        price,
        extra_duration: serviceItemCopy.extra_duration,
      }));
    }
  };

  const update = (isCancel) => {
    const serviceItemCopy = { ...updatedData };
    if (isCancel) {
      serviceItemCopy.editType = 'cancel';
    }

    dispatch(updateCompanyServiceRequest(serviceItemCopy));
  };

  const handleChangeCategory = (event, newValue) => {
    if (newValue) {
      if (newValue && newValue.inputValue) {
        setServiceItem({
          ...serviceItem, category: newValue.inputValue, service: '',
        });
        dispatch(addCustomServiceRequest({ name_en: newValue.inputValue }));
      } else {
        setServiceItem({
          ...serviceItem, category: newValue, service: '',
        });
      }
    } else {
      setServiceItem({
        ...serviceItem, category: newValue, service: '',
      });
    }
  };

  console.log('serviceItem----------', serviceItem);

  // const handleChangeCategoryService = (event, newValue) => {
  //   if (newValue) {
  //     if (newValue && newValue.inputValue) {
  //       setServiceItem({ ...serviceItem, service_id: customAddedCategory.id, service: newValue.inputValue });
  //       const data = { name_en: newValue && newValue.inputValue, parent_id: serviceItem.category.id || serviceItem.parent_id || customAddedCategory.id };
  //       dispatch(addCustomServiceRequest(data));
  //     } else {
  //       setServiceItem({ ...serviceItem, service_id: newValue.id ? newValue.id : '', service: newValue.name });
  //     }
  //   } else {
  //     setServiceItem({ ...serviceItem, service_id: null, service: '' });
  //   }
  // };

  const handleChangeCategoryService = (event) => {
    setServiceItem({ ...serviceItem, name: event.target.value });
  };

  return (
    <Drawer
      anchor="right"
      open={openServiceDrawer}
      onClose={handleCloseDrawer}
      className={`${styles.serviceDrawer} service-drawer`}
      PaperProps={{
        style: {
          height: 'calc(100% - 64px)',
          top: 64,
          width: 568,
        },
      }}
    >
      <div className={styles.drawerContent}>
        <div className={styles.titleContent}>
          <h3 className={styles.title}>
            {!selectedServiceId.length ? t('NewService') : t('EditService')}
          </h3>
          <CloseIcon className={styles.closeIcon} onClick={handleCloseDrawer} />
        </div>
        <div className={classes.root}>
          <div className={classes.content}>
            <div className={styles.secTitle}>
              <p>{t('WorkingScheduleTab.General')}</p>
            </div>
            <Grid container spacing={2} style={{ marginBottom: '12px' }}>
              <Grid item xs={6}>
                <Autocomplete
                  size="small"
                  error={errors.category ? JSON.stringify(errors.category) : undefined}
                  value={serviceItem.category || null}
                  onChange={handleChangeCategory}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  id="free-solo-with-text-demo"
                  options={allCategoriesData}
                  getOptionLabel={(option) => option.name}
                  renderOption={(option) => option.name}
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      style={{ height: 40 }}
                      multiline={false}
                      {...params}
                      label={`${t('ChooseServiceCategory')} *`}
                      title={`${t('ChooseServiceCategory')} *`}
                      InputLabelProps={{ className: classes.ellipsisText }}
                      variant="outlined"
                      error={errors.category ? errors.category : undefined}
                    />
                  )}
                />
                { errors.category && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText error>
                    {t('ServiceCategory') + t('IsRequired')}
                  </FormHelperText>
                </div>
                )}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  error={errors.service_id ? errors.service_id : undefined}
                  size="small"
                  label={`${t('CreateService')} *`}
                  data-cy="service"
                  data-customer="service"
                  variant="outlined"
                  type="text"
                  name="service"
                  value={serviceItem.name || null}
                  onChange={handleChangeCategoryService}
                />
                {/* <Autocomplete */}
                {/*  size="small" */}
                {/*  value={serviceItem.service || null} */}
                {/*  disabled={!serviceItem.category} */}
                {/*  onChange={handleChangeCategoryService} */}
                {/*  filterOptions={(options, params) => { */}
                {/*    const filtered = filter(options, params); */}

                {/*    if (params.inputValue !== '') { */}
                {/*      filtered.push({ */}
                {/*        inputValue: params.inputValue, */}
                {/*        name: `Add "${params.inputValue}"`, */}
                {/*      }); */}
                {/*    } */}

                {/*    return filtered; */}
                {/*  }} */}
                {/*  selectOnFocus */}
                {/*  clearOnBlur */}
                {/*  handleHomeEndKeys */}
                {/*  id="free-solo-with-text-demo" */}
                {/*  options={subServices.map(service => service.name ? service : ({ */}
                {/*    ...service, name: service.name_en */}
                {/*  }))} */}
                {/*  getOptionLabel={(option) => { */}
                {/*    if (typeof option === 'string') { */}
                {/*      return option; */}
                {/*    } */}
                {/*    if (option.inputValue) { */}
                {/*      return option.inputValue; */}
                {/*    } */}
                {/*    return option.name; */}
                {/*  }} */}
                {/*  renderOption={(option) => option.name} */}
                {/*  freeSolo */}
                {/*  renderInput={(params) => ( */}
                {/*    <TextField */}
                {/*      {...params} */}
                {/*      label={`${t('ChooseOrCreateService')} *`} */}
                {/*      title={`${t('ChooseOrCreateService')} *`} */}
                {/*      InputLabelProps={{ className: classes.ellipsisText }} */}
                {/*      variant="outlined" */}
                {/*      error={errors.service_id ? errors.service_id : undefined} */}
                {/*    /> */}
                {/*  )} */}
                {/* /> */}
                { errors.service_id && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText error>
                    {t('Service') + t('IsRequired')}
                  </FormHelperText>
                </div>
                )}
              </Grid>
            </Grid>
            {companyGot.is_staff_based && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Autocomplete
                    size="small"
                    multiple
                    id="tags-outlined"
                    options={selectedStaffOptions}
                    getOptionLabel={(option) => option.label}
                    value={serviceItem.staff_ids || []}
                    onChange={handleChange}
                    getOptionSelected={getOptionSelected}
                    filterSelectedOptions
                    disableCloseOnSelect
                    renderOption={(option) => (
                      <div className="input-menu" key={option.value} value={option.value}>
                        <Box display="flex" alignItems="center">
                          {option.avatar ? (
                            <img className="avatar-circle" src={option.avatar} alt="default" width={32} height={32} />
                          ) : (
                            <div className="default-circle">
                              {option.label ? (
                                /^[a-zA-Z]*$/.test(option.label[0]) ? (
                                  getInitials(option.label[0])
                                ) : (
                                  <img src={DefaultProfileImage} alt="default" width={32} height={32} />
                                )
                              ) : (
                                <img src={DefaultProfileImage} alt="default" width={32} height={32} />
                              )}
                            </div>
                          )}
                          {option.label}
                        </Box>
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={`${t('ChooseServiceProvider')} *`}
                        error={errors.staff_ids ? errors.staff_ids : undefined}
                      />
                    )}
                  />
                  {errors.staff_ids && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText error>
                        {`${t('AtLeastOne')} ${t('ServiceProvider') + t('IsRequired')}`}
                      </FormHelperText>
                    </div>
                  )}
                </Grid>
              </Grid>
            )}
            <div className={styles.section}>
              <div className={styles.secTitle}>
                <p>{t('MaintenanceTime')}</p>
              </div>
              <p className={styles.timeTexts}>
                {t('ServiceDuration')}
              </p>
              <Grid container spacing={2}>
                <Grid item xs={6} className={classes.inputHeight}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    className="duration"
                    error={errors.duration ? errors.duration : undefined}
                  >
                    <InputLabel error={errors.duration ? errors.duration : undefined}>{`${t('ChooseHour')} *`}</InputLabel>
                    <Select
                      variant="outlined"
                      disableunderline="true"
                      data-cy="breaking-start-time"
                      onChange={(e) => handleHourOrMinChange(e, 'duration')}
                      value={Math.floor(serviceItem.duration / 60) || ''}
                      name="hour"
                      label={`${t('ChooseHour')} *`}
                      SelectDisplayProps={{ style: { paddingTop: 11, paddingBottom: 11 } }}
                      renderValue={() => (
                        <Box style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <AccessTimeIcon style={{ fontSize: '17px' }} />
                          <span style={{ marginLeft: '5px', lineHeight: '15px' }}>
                            {serviceItem.duration && Math.floor(serviceItem.duration / 60)}
                          </span>
                        </Box>
                      )}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.menuPaper },
                      }}
                    >
                      { hours.map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
                    </Select>
                  </FormControl>
                  { errors.duration && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText error>
                        {t('Duration') + t('IsRequired')}
                      </FormHelperText>
                    </div>
                  )}
                </Grid>
                <Grid item xs={6} className={classes.inputHeight}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={errors.duration ? errors.duration : undefined}
                  >
                    <InputLabel error={errors.duration ? errors.duration : undefined}>{`${t('ChooseMinutes')} *`}</InputLabel>
                    <Select
                      variant="outlined"
                      disableunderline="true"
                      data-cy="breaking-start-time"
                      value={(serviceItem.duration % 60) || ''}
                      onChange={(e) => handleHourOrMinChange(e, 'duration')}
                      name="min"
                      label={`${t('ChooseMinutes')} *`}
                      SelectDisplayProps={{ style: { paddingTop: 11, paddingBottom: 11 } }}
                      renderValue={() => (
                        <Box style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <AccessTimeIcon style={{ fontSize: '17px' }} />
                          <span style={{ marginLeft: '5px', lineHeight: '15px' }}>
                            {serviceItem.duration && (serviceItem.duration % 60)}
                          </span>
                        </Box>
                      )}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.menuPaper },
                      }}
                    >
                      { minutes.map((min) => <MenuItem key={Math.random()} value={min}>{min}</MenuItem>) }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div className={styles.bufferBox} style={errors.duration ? { marginTop: 40 } : {}}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={serviceItem.isShowBufferTime}
                      onChange={(e) => handleChangeChecked(e)}
                      name="isShowBufferTime"
                      color="primary"
                      className={classes.checkbox}
                    />
                  )}
                  labelPlacement="start"
                  label={(
                    <>
                      <span className={classes.label}>
                        {t('BufferTime')}
                      </span>
                      <Badge
                        overlap="rectangle"
                        className={classes.badgeIcon}
                        badgeContent={(
                          <CustomizedTooltip title={t('BufferTimeTooltipText')} placement="bottom-start">
                            <InfoOutlinedIcon className={classes.icon} />
                          </CustomizedTooltip>
                            )}
                      />
                    </>
                    )}
                />
              </div>
              {serviceItem.isShowBufferTime && (
                <Grid container spacing={2}>
                  <Grid item xs={6} className={classes.inputHeight}>
                    <FormControl
                      fullWidth
                      size="small"
                      variant="outlined"
                      className="duration"
                    >
                      <InputLabel>{t('ChooseHour')}</InputLabel>
                      <Select
                        variant="outlined"
                        disableunderline="true"
                        data-cy="breaking-start-time"
                        onChange={(e) => handleHourOrMinChange(e, 'extra_duration')}
                        value={Math.floor(serviceItem.extra_duration / 60) || ''}
                        name="hour"
                        label={t('ChooseHour')}
                        SelectDisplayProps={{ style: { paddingTop: 11, paddingBottom: 11 } }}
                        renderValue={() => (
                          <Box style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <AccessTimeIcon style={{ fontSize: '17px' }} />
                            <span style={{ marginLeft: '5px', lineHeight: '15px' }}>
                              {serviceItem.extra_duration && Math.floor(serviceItem.extra_duration / 60)}
                            </span>
                          </Box>
                        )}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: { paper: classes.menuPaper },
                        }}
                      >
                        { hours.map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} className={classes.inputHeight}>
                    <FormControl
                      fullWidth
                      size="small"
                      variant="outlined"
                    >
                      <InputLabel>{t('ChooseMinutes')}</InputLabel>
                      <Select
                        variant="outlined"
                        disableunderline="true"
                        data-cy="breaking-start-time"
                        value={(serviceItem.extra_duration % 60) || ''}
                        onChange={(e) => handleHourOrMinChange(e, 'extra_duration')}
                        name="min"
                        label={t('ChooseMinutes')}
                        SelectDisplayProps={{ style: { paddingTop: 11, paddingBottom: 11 } }}
                        renderValue={() => (
                          <Box style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <AccessTimeIcon style={{ fontSize: '17px' }} />
                            <span style={{ marginLeft: '5px', lineHeight: '15px' }}>
                              {serviceItem.extra_duration && (serviceItem.extra_duration % 60)}
                            </span>
                          </Box>
                        )}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                          },
                          getContentAnchorEl: null,
                          classes: { paper: classes.menuPaper },
                        }}
                      >
                        { minutes.map((min) => <MenuItem key={Math.random()} value={min}>{min}</MenuItem>) }
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            </div>
            <div className={styles.section}>
              <div className={styles.secTitle}>
                <p>{t('Pricing')}</p>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={4} className={classes.inputHeight}>
                  <FormControl variant="outlined" fullWidth size="small" error={errors.priceType ? errors.priceType : undefined}>
                    <InputLabel id="demo-simple-select-outlined-label" error={errors.priceType ? errors.priceType : undefined}>{`${t('PriceType')} *`}</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={price.type ? price.type : ''}
                      onChange={(e) => handlePriceTypeChange(e)}
                      label={`${t('PriceType')} *`}
                      name="type"
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        classes: { paper: classes.menuPaper },
                      }}
                    >
                      <MenuItem value="fixed">{t('Fixed')}</MenuItem>
                      <MenuItem value="range">{t('Range')}</MenuItem>
                      <MenuItem value="free">{t('Free')}</MenuItem>
                    </Select>
                    { errors.priceType && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>
                        {t('PriceType') + t('IsRequired')}
                      </FormHelperText>
                    </div>
                    )}
                  </FormControl>
                </Grid>
                {price.type && price.type === 'fixed' ? (
                  <Grid item xs={8} className={classes.inputHeight}>
                    <TextField
                      type="number"
                      pattern="[0-9]*"
                      onChange={(e) => handlePriceChange(e)}
                      label={`${t('Price')} *`}
                      variant="outlined"
                      fullWidth
                      placeholder="0.00"
                      name="price"
                      value={price.price.from !== '0' ? price.price.from : ''}
                      size="small"
                      error={errors.price ? errors.price : undefined}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            position="end"
                          >
                            {companyGot.currency && getCurrencyIcon(companyGot.currency)}
                          </InputAdornment>
                        ),
                      }}
                    />
                    { errors.price && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>
                        {t('Price') + t('IsRequired')}
                      </FormHelperText>
                    </div>
                    )}
                  </Grid>
                ) : price.type && price.type === 'range' ? (
                  <>
                    <Grid item xs={4} className={classes.inputHeight}>
                      <TextField
                        onChange={(e) => handlePriceChange(e)}
                        label={`${t('From')} *`}
                        variant="outlined"
                        fullWidth
                        placeholder="0.00"
                        name="from"
                        value={fromPrice !== '0' ? fromPrice : ''}
                        size="small"
                        error={errors.price ? errors.price : undefined}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                            >
                              {companyGot.currency && getCurrencyIcon(companyGot.currency)}
                            </InputAdornment>
                          ),
                        }}
                      />
                      { errors.price && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {t('Price') + t('IsRequired')}
                        </FormHelperText>
                      </div>
                      )}
                      { errors.maxToValue && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {t('Price')} From should be less than To
                        </FormHelperText>
                      </div>
                      )}
                    </Grid>
                    <Grid item xs={4} className={classes.inputHeight}>
                      <TextField
                        onChange={(e) => handlePriceChange(e)}
                        label={t('To')}
                        variant="outlined"
                        fullWidth
                        placeholder="0.00"
                        name="to"
                        value={toPrice !== '0' ? toPrice : ''}
                        size="small"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                            >
                              {companyGot.currency && getCurrencyIcon(companyGot.currency)}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </div>
            <div className={styles.section}>
              <div className={styles.secTitle}>
                <p style={{ marginBottom: '7px' }}>{t('Details')}</p>
              </div>
              <Grid container direction="column">
                <Grid item xs={12}>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={serviceItem.show_on_widget}
                        onChange={(e) => handleChangeChecked(e)}
                        name="show_on_widget"
                        color="primary"
                        className={classes.checkbox}
                      />
                        )}
                    labelPlacement="start"
                    label={(
                      <>
                        <span className={classes.label}>
                          {t('ShowOnWidget')}
                        </span>
                        <Badge
                          overlap="rectangle"
                          className={classes.badgeIcon}
                          badgeContent={(
                            <CustomizedTooltip title={t('ShowOnWidgetTooltipText')} placement="bottom-start">
                              <InfoOutlinedIcon className={classes.icon} />
                            </CustomizedTooltip>
                        )}
                        />
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {serviceItem.show_on_widget && (
                  <FormControlLabel
                    control={(
                      <Checkbox
                        checked={serviceItem.auto_accept}
                        onChange={(e) => handleChangeChecked(e)}
                        name="auto_accept"
                        color="primary"
                        className={classes.checkbox}
                      />
                    )}
                    className={classes.label}
                    labelPlacement="start"
                    label={(
                      <>
                        <span className={classes.label}>
                          {t('AutoAcceptLabel')}
                        </span>
                        <Badge
                          overlap="rectangle"
                          className={classes.badgeIcon}
                          badgeContent={(
                            <CustomizedTooltip title={t('AutoAcceptTooltipText')} placement="bottom-start">
                              <InfoOutlinedIcon fontSize="small" className={classes.icon} />
                            </CustomizedTooltip>
                              )}
                        />
                      </>
                    )}
                  />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} style={{ marginTop: '12px' }}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                  >
                    <TextField
                      multiline
                      label={t('Description')}
                      rowsmin={3}
                      variant="outlined"
                      name="description"
                      placeholder={t('AddShortDescOptional')}
                      value={serviceItem.description || ''}
                      onChange={(e) => handleInputChange(e)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={styles.footer}>
            {selectedServiceId.length ? (
              <div>
                <Button onClick={(e) => handleOpenDeleteModal(e, selectedServiceId[0], selectedServiceData.parent_id)}>
                  <img src={deleteIcon} alt="delete" />
                  <span className={styles.deleteBtnText}>
                    {t('Delete')}
                  </span>
                </Button>
              </div>
            ) : <div />}
            <div className={styles.buttonsBox}>
              <div>
                <Button
                  variant="contained"
                  style={{
                    borderRadius: 4,
                    border: '1px solid #A4A4A4',
                    backgroundColor: '#FFFFFF',
                    padding: '6px 28px',
                    font: 'normal 400 14px/19px Nunito Sans, sans-serif',
                    color: '#A4A4A4',
                    width: 100,
                  }}
                  onClick={handleCloseDrawer}
                >
                  {t('Cancel')}
                </Button>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: 4,
                    backgroundColor: '#0282DA',
                    padding: '7px 34px',
                    font: 'normal 400 14px/19px Nunito Sans, sans-serif',
                    color: '#FFFFFF',
                    width: 100,
                  }}
                  onClick={selectedServiceId.length ? handleUpdateService : handleAddService}
                >
                  {t('Save')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CheckAppointmentServiceModal
        open={isOpenCheckAppointmentModal}
        onClose={() => setIsOpenCheckAppointmentModal(false)}
        handleUpdate={update}
      />
    </Drawer>
  );
}

export default ServiceDrawer;
