import React, { useContext, useEffect } from 'react';
import {
  AppBar,
  Box,
  Button,
  Grid,
  Toolbar,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDispatch, useSelector } from 'react-redux';
import DeleteButton from 'MaterialComponents/deleteButton';
import { deleteStaffRequest } from 'redux/staff/actions';
import usePrevious from 'CustomHooks/usePrevious';
import StaffContext from '../staffContext';
import DeleteModal from './deleteModal.';

function Header() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    setOpen, setIsUpdate, selected, setSelected, isModalOpen, setIsModalOpen, staff, deletedStaffId, setDeletedStaffId, staffCount,
  } = useContext(StaffContext);

  const { isDeleteStaffSuccess } = useSelector((state) => state.staff);

  // Prev values
  const prevIsDeleteStaffSuccess = usePrevious(isDeleteStaffSuccess);

  const deleteStaff = () => {
    dispatch(deleteStaffRequest({ staffId: deletedStaffId.length ? deletedStaffId : selected, multiple: true }));
  };

  useEffect(() => {
    if (prevIsDeleteStaffSuccess === false && isDeleteStaffSuccess) {
      setIsModalOpen(false);
    }
  }, [isDeleteStaffSuccess]);

  return (
    <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
      <Toolbar>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12} md={6} className="title-companyName">
            <Grid container justify="flex-start">
              {/* <Grid item> */}
              {/*  <TextField */}
              {/*    type="search" */}
              {/*    size="small" */}
              {/*    color="secondary" */}
              {/*    className="search-staff" */}
              {/*    placeholder={t('SearchStaff')} */}
              {/*    InputProps={{ */}
              {/*      startAdornment: <SearchIcon position="start" style={{ color: '#393939', fontSize: 16, marginRight: 10 }} />, */}
              {/*    }} */}
              {/*    variant="outlined" */}
              {/*    onChange={() => {}} */}
              {/*  /> */}
              {/* </Grid> */}
              {selected.length > 0 && (
                <DeleteButton
                  // style={{ marginLeft: '24px' }}
                  variant="outlined"
                  startIcon={<DeleteIcon color="error" />}
                  onClick={() => setIsModalOpen(true)}
                >
                  {t('Delete')}
                </DeleteButton>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container justify="flex-end">
              <Grid item className="appointments-fields">
                <Box display="flex" flexWrap="wrap" justifyContent="flex-end">
                  <Box alignSelf="center" className="button-box">
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setIsUpdate(false);
                        setOpen(true);
                      }}
                      startIcon={<AddIcon />}
                    >
                      {t('NewStaff')}
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
      {isModalOpen && (
        <DeleteModal
          onClose={() => setIsModalOpen(false)}
          setDeletedStaffId={setDeletedStaffId}
          deleteStaff={deleteStaff}
          selected={selected}
          setSelected={setSelected}
          staff={staff}
          deletedStaffId={deletedStaffId}
          staffCount={staffCount}
        />
      )}
    </AppBar>
  );
}

export default Header;
