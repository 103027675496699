import React from 'react';
import Box from '@material-ui/core/Box';
import { RoundShape } from 'react-placeholder/lib/placeholders';
import ReactPlaceholder from 'react-placeholder';
import RectShape from 'react-placeholder/lib/placeholders/RectShape';

function messagesLoading() {
  return (
    <>
      <Box>
        <Box display="flex" width="100%" className="messages-loading">
          <Box className="left-content" flex="1" minHeight="calc(100vh - 64px)">
            <Box p="8px" display="flex" className="message-item">
              <Box>
                <RoundShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: '56px', height: '56px' }} />
              </Box>
              <Box width="165px" ml="12px" alignSelf="center">
                <ReactPlaceholder showLoadingAnimation color="rgb(224, 224, 224)" rows={1} />
              </Box>
            </Box>
            <Box p="8px" display="flex" className="message-item">
              <Box>
                <RoundShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: '56px', height: '56px' }} />
              </Box>
              <Box width="150px" ml="12px" alignSelf="center">
                <ReactPlaceholder showLoadingAnimation color="rgb(224, 224, 224)" rows={1} />
              </Box>
            </Box>
          </Box>
          <Box className="content-chat" flex="4" minHeight="calc(100vh - 64px)">
            <Box className="users-detail">
              <Box display="flex" alignItems="center">
                <Box>
                  <RoundShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: '35px', height: '35px' }} />
                </Box>
                <Box width="150px" ml="12px">
                  <ReactPlaceholder showLoadingAnimation color="rgb(224, 224, 224)" rows={1} />
                </Box>
              </Box>
            </Box>
            <Box>
              <Box p="29px">
                <ReactPlaceholder showLoadingAnimation color="rgb(224, 224, 224)" rows={20} />
              </Box>
            </Box>
          </Box>
          <Box className="right-content" flex="1" minHeight="calc(100vh - 64px)">
            <Box className="details">
              <Box className="company-image">
                <Box pt="14px">
                  <RectShape showLoadingAnimation color="#E0E0E0" style={{ width: '100%', height: 150 }} />
                </Box>
              </Box>
              <Box className="about-company">
                <Box width="100%">
                  <ul>
                    <li>
                      <ReactPlaceholder showLoadingAnimation color="rgb(224, 224, 224)" rows={1} />
                    </li>
                    <li>
                      <ReactPlaceholder showLoadingAnimation color="rgb(224, 224, 224)" rows={1} />
                    </li>
                    <li>
                      <ReactPlaceholder showLoadingAnimation color="rgb(224, 224, 224)" rows={1} />
                    </li>
                  </ul>
                </Box>
                <Box width="100%">
                  <ul>
                    <li>
                      <ReactPlaceholder showLoadingAnimation color="rgb(224, 224, 224)" rows={1} />
                    </li>
                    <li>
                      <ReactPlaceholder showLoadingAnimation color="rgb(224, 224, 224)" rows={1} />
                    </li>
                    <li>
                      <ReactPlaceholder showLoadingAnimation color="rgb(224, 224, 224)" rows={1} />
                    </li>
                  </ul>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="messages-load-small">
          <Box>
            <ReactPlaceholder type="textRow" showLoadingAnimation color="rgb(224, 224, 224)" />
          </Box>
          <Box my="15px">
            <ReactPlaceholder type="textRow" showLoadingAnimation color="rgb(224, 224, 224)" />
          </Box>
          <Box>
            <ReactPlaceholder type="textRow" showLoadingAnimation color="rgb(224, 224, 224)" />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default messagesLoading;
