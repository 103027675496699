import React, { useState } from 'react';
import {
  Avatar, Button, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import getInitials from 'Modules/getInitials';

import editIcon from 'assets/icons/edit.svg';
import editDisabledIcon from 'assets/icons/edit-disabled.svg';
import deleteIcon from 'assets/icons/delete.svg';
import DefaultProfileImage from 'assets/img/default-profile.png';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { getStaffAppointmentsHistoryRequest } from 'redux/staff/actions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    maxWidth: 360,
    '&$selected': {
      backgroundColor: '#F2F9FD !important',
      borderRadius: '4px',
    },
  },
  icon: {
    minWidth: '30px',
  },
  selected: {},
}));

const LeftSide = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    selectedStaff, isUpdate, setIsUpdate, selectedIndex, setSelectedIndex, deleteStaff, setLoading,
  } = props;

  const classes = useStyles();

  const [isRequest, setIsRequest] = useState(true);

  const handleListItemClick = (event, index, staffId) => {
    setSelectedIndex(index);
    if (index === 1) {
      if (isRequest) {
        dispatch(getStaffAppointmentsHistoryRequest({ id: staffId, page: 1 }));
        setLoading(true);
        setIsRequest(false);
      }
    }
    setIsUpdate(false);
  };

  const renderAvatar = () => {
    const { avatar, first_name, last_name } = selectedStaff;
    if (avatar) {
      return (
        <Avatar alt="Remy Sharp" src={avatar} style={{ width: 100, height: 100 }} />
      );
    } else {
      return (
        <div className="no-avatar-circle">
          {first_name ? (
            /^[a-zA-Z]*$/.test(first_name[0]) ? (
              getInitials(first_name)
            ) : last_name ? (
              /^[a-zA-Z]*$/.test(last_name[0]) ? (
                getInitials(last_name)
              ) : (
                <img src={DefaultProfileImage} alt="default" width={100} height={100} />
              )
            ) : (
              <img src={DefaultProfileImage} alt="default" width={100} height={100} />
            )
          ) : (
            <img src={DefaultProfileImage} alt="default" width={100} height={100} />
          )}
        </div>
      );
    }
  };

  const onEdit = () => {
    setIsUpdate(!isUpdate);
    setSelectedIndex(null);
  };

  return (
    <div className="left-container">
      <div>
        <div className="edit-btn">
          <Button onClick={onEdit} disabled={isUpdate}>
            {isUpdate ? (
              <img src={editDisabledIcon} alt="edit" />

            ) : (
              <img src={editIcon} alt="edit" />
            )}
            <span style={{ color: isUpdate ? '#9e9e9e' : '#393939' }}>
              {t('Edit')}
            </span>
          </Button>
        </div>
        <div className="detail-section">
          <div className="avatar-section">
            <div className="avatar">
              {renderAvatar()}
            </div>
            <p className="customer-name">
              {selectedStaff.full_name}
            </p>
          </div>
          <div>
            {selectedStaff.email && (
              <div className="mail-detail">
                <MailOutlineIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
                <span className="mail-text">
                  {selectedStaff.email}
                </span>
              </div>
            )}
            {selectedStaff.phone_number && (
              <div className="phone-detail">
                <PhoneOutlinedIcon style={{ fill: '#393939', fontSize: '19px' }} />
                <span className="phone-text">
                  {selectedStaff.phone_number}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="divider-border" />
        <div className="detail-items">
          <div className={classes.root}>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem
                classes={{ root: classes.root, selected: classes.selected }}
                button
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0, selectedStaff.id)}
              >
                <ListItemIcon className={classes.icon}>
                  <DateRangeOutlinedIcon style={{ fontSize: '18px' }} />
                </ListItemIcon>
                <ListItemText primary={t('UpcomingAppointments')} />
              </ListItem>
              <ListItem
                classes={{ root: classes.root, selected: classes.selected }}
                button
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1, selectedStaff.id)}
              >
                <ListItemIcon className={classes.icon}>
                  <HistoryOutlinedIcon style={{ fontSize: '18px' }} />
                </ListItemIcon>
                <ListItemText primary={t('AppointmentsHistory')} />
              </ListItem>
              {/* <ListItem */}
              {/*  classes={{ root: classes.root, selected: classes.selected }} */}
              {/*  button */}
              {/*  selected={selectedIndex === 2} */}
              {/*  onClick={(event) => handleListItemClick(event, 2)} */}
              {/* > */}
              {/*  <ListItemIcon className={classes.icon}> */}
              {/*    <ListAltOutlinedIcon style={{ fontSize: '18px' }} /> */}
              {/*  </ListItemIcon> */}
              {/*  <ListItemText primary="Assigned Services" /> */}
              {/* </ListItem> */}
            </List>
          </div>
        </div>
        {/* <div style={{ borderBottom: '1px solid #E3E5E6', margin: '8px 24px 10px 24px' }} /> */}
        {/* <div className="new-appointment"> */}
        {/*  <Button> */}
        {/*    <AddIcon style={{ fill: '#0282DA', fontSize: '18px' }} /> */}
        {/*    <span className="new-appointment-text"> */}
        {/*      New Appointment */}
        {/*    </span> */}
        {/*  </Button> */}
        {/* </div> */}
      </div>
      <div className="delete-btn-section">
        <Button onClick={(e) => deleteStaff(e, selectedStaff.id)}>
          <img src={deleteIcon} alt="delete" />
          <span className="delete-btn-text">
            {t('Delete')}
          </span>
        </Button>
      </div>
    </div>
  );
};

LeftSide.propTypes = {
  selectedStaff: PropTypes.object.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  setIsUpdate: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  deleteStaff: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
};

export default LeftSide;
