import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ExistingCustomer = (props) => {
  const {
    open,
    onClose,
    isExistingData,
    handleCustomerInvite,
    handleUpdateCustomerInvitation,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <span className="alert-title">
          <span>Existing user</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom> Please make sure this is the customer you want to invite: </Typography>
        <Typography gutterBottom> Name:
          {Object.keys(isExistingData).length
            && (
            <Link to="#" onClick={(e) => e.preventDefault()}>
              {isExistingData.existingCustomer.first_name} {isExistingData.existingCustomer.last_name}
            </Link>
            )}
        </Typography>
        <Typography gutterBottom> Email:
          <Link to="#" onClick={(e) => e.preventDefault()}>
            {Object.keys(isExistingData).length && isExistingData.existingCustomer.email}
          </Link>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          data-cy="cancel"
          type="button"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          data-cy="delete"
          type="button"
          onClick={() => Object.keys(isExistingData).length && isExistingData.isGettingUpdated ? handleUpdateCustomerInvitation(true) : handleCustomerInvite(true)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExistingCustomer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isExistingData: PropTypes.object.isRequired,
  handleCustomerInvite: PropTypes.func.isRequired,
  handleUpdateCustomerInvitation: PropTypes.func.isRequired,
};

export default ExistingCustomer;
