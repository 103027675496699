import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  staffDayOffRequest,
  updateStaffDayOffRequest,
} from '../../../redux/company/actions';
import usePrevious from '../../../CustomHooks/usePrevious';
import splitSpaces from '../../../Modules/splitSpaces';
import AutocompleteSelectLimitTags from '../../../Modules/AutocompleteSelectLimitTags';
import SnackbarToast from '../../../Modules/SnackbarToast';

function DayOffModal(props) {
  const { t } = useTranslation();
  const {
    open,
    onClose,
    dayOff,
    buttonLoading,
    setButtonLoading,
    handleUpdateCompanyState,
    allDayOffs,
    staffOptions,
    setAllDayOffs,
    addStaffDayOff,
    isStaffDayOffSuccess,
    isStaffDayOffError,
    storeStaffDayOffErrors,
    storedStaffDayOff,
    updateStaffDayOff,
    isUpdateStaffDayOffSuccess,
    isUpdateStaffDayOffError,
    updateStaffDayOffErrors,
    updatedStaffDayOff,
  } = props;

  const date = new Date();
  const [storeErrors, setStoreErrors] = useState({});
  const [updateErrors, setUpdateErrors] = useState({});
  const [errorDate, setErrorDate] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [errors, setErrors] = useState({
    start_date: false,
    end_date: false,
    staff_list: false,
  });
  const [dayOffData, setDayOffData] = useState({
    start_date: '',
    end_date: '',
    staff_list: [],
    description: '',
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous value
  const prevIsStaffDayOffSuccess = usePrevious(isStaffDayOffSuccess);
  const prevIsStaffDayOffError = usePrevious(isStaffDayOffError);
  const prevIsUpdateStaffDayOffSuccess = usePrevious(isUpdateStaffDayOffSuccess);
  const prevIsUpdateStaffDayOffError = usePrevious(isUpdateStaffDayOffError);

  // Get Day Off data
  useEffect(() => {
    const keys = Object.keys(dayOff);
    const valueExists = keys.find((key) => dayOff[key]);
    if (valueExists) {
      setDayOffData(dayOff);
    } else {
      setDayOffData({
        start_date: '',
        end_date: '',
        staff_list: [],
        description: '',
      });
      setErrors({});
      setUpdateErrors({});
      setStoreErrors({});
      setSelectedOptions([]);
      setErrorDate('');
    }
  }, [dayOff]);

  // Handle Store Day off success
  useEffect(() => {
    if (prevIsStaffDayOffSuccess === false && isStaffDayOffSuccess) {
      snackBarAlert(true, i18n.t('DayAdded'), 'success');
      const dayOffsCopy = [...storedStaffDayOff];
      setAllDayOffs(dayOffsCopy);
      handleUpdateCompanyState('staffDayOffs', dayOffsCopy);
      setDayOffData({
        start_date: '',
        end_date: '',
        staff_list: [],
        description: '',
      });
      setSelectedOptions([]);
      setStoreErrors({});
      onClose();
    }
  }, [isStaffDayOffSuccess]);

  // Handle Store Day off error
  useEffect(() => {
    if (prevIsStaffDayOffError === false && isStaffDayOffError) {
      setStoreErrors(storeStaffDayOffErrors);
      setButtonLoading(true);
    }
  }, [isStaffDayOffError]);

  // Handle Update Day off success
  useEffect(() => {
    if (prevIsUpdateStaffDayOffSuccess === false && isUpdateStaffDayOffSuccess) {
      const dayOffsCopy = [...allDayOffs];
      const dayOffIndex = dayOffsCopy.findIndex((item) => item.id === updatedStaffDayOff.id);
      dayOffsCopy.splice(dayOffIndex, 1);
      dayOffsCopy.push(updatedStaffDayOff);
      setAllDayOffs(dayOffsCopy);
      handleUpdateCompanyState('staffDayOffs', dayOffsCopy);
      setButtonLoading(true);
      onClose();
      setUpdateErrors({});
      snackBarAlert(true, i18n.t('DayUpdated'), 'success');
    }
  }, [isUpdateStaffDayOffSuccess]);

  // Handle Update Day off error
  useEffect(() => {
    if (prevIsUpdateStaffDayOffError === false && isUpdateStaffDayOffError) {
      setUpdateErrors(updateStaffDayOffErrors);
    }
  }, [isUpdateStaffDayOffError]);

  const useStyles = makeStyles(() => ({
    error: {
      borderColor: '#f44336',
    },
  }));

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const updateDayOffData = (name, value) => {
    setDayOffData({
      ...dayOffData,
      [name]: value,
    });
  };

  const validateForm = (add = false) => {
    const errorsCopy = { ...errors };
    errorsCopy.start_date = dayOffData.start_date === '';
    errorsCopy.end_date = dayOffData.end_date === '';
    if (add) {
      errorsCopy.staff_list = selectedOptions.length === 0;
    } else {
      errorsCopy.staff_list = false;
    }
    setErrors(() => ({ ...errorsCopy }));
    return (errorsCopy.start_date || errorsCopy.end_date || errorsCopy.staff_list);
  };

  const handleAddDayOff = () => {
    if (!validateForm(true)) {
      const dayOffDataCopy = { ...dayOffData };
      const staffIds = [];
      selectedOptions.map((staff) => {
        staffIds.push(staff.id);
      });
      dayOffDataCopy.description = splitSpaces(dayOffDataCopy.description);
      dayOffDataCopy.staff_list = staffIds;
      addStaffDayOff(dayOffDataCopy);
      setButtonLoading(false);
    }
  };

  const handleUpdateDayOff = () => {
    if (!validateForm()) {
      const dayOffDataCopy = { ...dayOffData };
      dayOffDataCopy.description = splitSpaces(dayOffDataCopy.description);
      setButtonLoading(false);
      updateStaffDayOff(dayOffDataCopy);
    }
  };

  const handleChangeDays = (e) => {
    const { name, value } = e.target;
    setErrorDate('');
    if (name === 'start_date') {
      setDayOffData({
        ...dayOffData,
        start_date: value,
        end_date: value,
      });
    } else if (name === 'end_date') {
      const startTime = moment(dayOffData.start_date, 'Y-MM-DD').format('Y-MM-DD HH:mm a');
      const endTime = moment(value, 'Y-MM-DD').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        updateDayOffData('end_date', value);
      } else {
        setErrorDate(i18n.t('EndTimeAfter'));
      }
    }
  };

  const classes = useStyles();

  const handleToggleOption = (selectedOptions) => setSelectedOptions(selectedOptions);

  const handleClearOptions = () => setSelectedOptions([]);

  const handleSelectAll = (isSelected) => isSelected ? setSelectedOptions(staffOptions) : handleClearOptions();

  const getOptionLabel = (option) => `${option.label}`;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              <span>{dayOffData.id ? 'Edit Days Off' : i18n.t('AddDayOff')}</span>
            </Typography>
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.start_date || !!storeErrors.start_date || !!updateErrors.start_date}
                size="small"
                variant="outlined"
              >
                <TextField
                  label={`${t('StartDate')} *`}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.start_date || !!storeErrors.start_date || !!updateErrors.start_date}
                  size="small"
                  name="start_date"
                  onChange={(e) => handleChangeDays(e)}
                  variant="outlined"
                  value={dayOffData.start_date || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd'), max: '9999-12-31' }}
                />

                { errors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {t('StartDate') + t('IsRequired')}
                    </FormHelperText>
                  </div>
                )}

                { storeErrors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.start_date}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.start_date}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.end_date || !!errorDate}
                size="small"
                variant="outlined"
              >
                <TextField
                  label={`${t('EndDate')} *`}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.end_date || !!errorDate}
                  size="small"
                  name="end_date"
                  onChange={(e) => handleChangeDays(e)}
                  variant="outlined"
                  value={dayOffData.end_date || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd'), max: '9999-12-31' }}
                />

                { errors.end_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {t('EndDate') + t('IsRequired')}
                    </FormHelperText>
                  </div>
                )}

                { errorDate && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      { errorDate }
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            {!dayOffData.id ? (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={!!errors.staff_list}
                  size="small"
                  variant="outlined"
                >
                  <AutocompleteSelectLimitTags
                    items={staffOptions}
                    getOptionLabel={getOptionLabel}
                    selectedValues={selectedOptions}
                    label={t('Staff')}
                    placeholder={t('Staff')}
                    limitTags={2}
                    error={!!errors.staff_list}
                    onToggleOption={handleToggleOption}
                    onClearOptions={handleClearOptions}
                    onSelectAll={handleSelectAll}
                  />

                  { errors.staff_list && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText>
                        {t('Staff') + t('IsRequired')}
                      </FormHelperText>
                    </div>
                  )}
                </FormControl>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  <TextField
                    fullWidth
                    disabled
                    label="Staff"
                    size="small"
                    type="text"
                    name="start_date"
                    variant="outlined"
                    value={dayOffData.company_staff.staff.full_name || ''}
                    inputProps={{ min: format(date, 'yyyy-MM-dd') }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={!!storeErrors.description || !!updateErrors.description}
                size="small"
                variant="outlined"
              >
                <TextField
                  value={dayOffData.description || ''}
                  onChange={(e) => updateDayOffData('description', e.target.value)}
                  className={(!!storeErrors.description || !!updateErrors.description) && classes.error}
                  multiline
                  fullWidth
                  rowsMin={2}
                  inputProps={{ maxLength: 500 }}
                  variant="outlined"
                  placeholder={i18n.t('Notes')}
                  label={i18n.t('Description')}
                  name="note_from_creator"
                />

                { storeErrors.description && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.description}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.description && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.description}
                    </FormHelperText>
                  </div>
                )}

              </FormControl>
            </Grid>
            {dayOffData.start_date && (
              <Grid item xs={12}>
                <Box className="info-working-schedule-section">
                  {dayOffData.start_date === dayOffData.end_date ? (
                    <>
                      All day on { moment(dayOffData.start_date).format('ll') }.
                    </>
                  ) : (
                    <>
                      From { moment(dayOffData.start_date).format('ll') } to { moment(dayOffData.end_date).format('ll') }
                    </>
                  )}
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            data-cy="cancel"
            type="button"
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            size="small"
            data-cy="send"
            type="button"
            onClick={dayOffData.id ? handleUpdateDayOff : handleAddDayOff}
            variant="contained"
            color="primary"
            loading={buttonLoading}
          >
            {!buttonLoading && <CircularProgress color="white" size={20} />}
            {buttonLoading && dayOffData.id ? t('Save') : buttonLoading && !dayOffData.id ? t('Add') : ''}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

DayOffModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  staffOptions: PropTypes.array.isRequired,
  dayOff: PropTypes.object.isRequired,
  allDayOffs: PropTypes.array.isRequired,
  setAllDayOffs: PropTypes.func.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  setButtonLoading: PropTypes.func.isRequired,
  // Add Day Off
  addStaffDayOff: PropTypes.func.isRequired,
  isStaffDayOffSuccess: PropTypes.bool.isRequired,
  isStaffDayOffError: PropTypes.bool.isRequired,
  storeStaffDayOffErrors: PropTypes.object.isRequired,
  storedStaffDayOff: PropTypes.object.isRequired,
  handleUpdateCompanyState: PropTypes.object.isRequired,
  // Update Day Off
  updateStaffDayOff: PropTypes.func.isRequired,
  isUpdateStaffDayOffSuccess: PropTypes.bool.isRequired,
  isUpdateStaffDayOffError: PropTypes.bool.isRequired,
  updateStaffDayOffErrors: PropTypes.object.isRequired,
  updatedStaffDayOff: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // Add Day Off
  isStaffDayOffSuccess: state.company.isStaffDayOffSuccess,
  isStaffDayOffError: state.company.isStaffDayOffError,
  storeStaffDayOffErrors: state.company.storeStaffDayOffErrors,
  storedStaffDayOff: state.company.storedStaffDayOff,
  // Update Day Off
  isUpdateStaffDayOffSuccess: state.company.isUpdateStaffDayOffSuccess,
  isUpdateStaffDayOffError: state.company.isUpdateStaffDayOffError,
  updateStaffDayOffErrors: state.company.updateStaffDayOffErrors,
  updatedStaffDayOff: state.company.updatedStaffDayOff,
});

function mapDispatchToProps(dispatch) {
  return {
    addStaffDayOff: (data) => dispatch(staffDayOffRequest(data)),
    updateStaffDayOff: (data) => dispatch(updateStaffDayOffRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DayOffModal);
