import React, { useEffect, useState } from 'react';
import {
  Button, Checkbox, Divider,
  Menu, Box, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIndividualUserIndustriesRequest } from '../../../../redux/individualUser/actions';
import { getAppointmentByIdRequest } from '../../../../redux/appointment/actions';

function FilterGeneralCalendar(props) {
  const {
    anchorEl,
    handleCloseFilter,
    checkedItems,
    handleChangeCheckboxes,
    submitFilter,
    account: { testPermissions },
  } = props;

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const uniqueRoles = [];
    testPermissions.map((item) => {
      const roleIndex = uniqueRoles.findIndex((index) => index.name === item.role.name);
      if (roleIndex === -1) {
        uniqueRoles.push(item.role);
      }
    });
    setRoles(uniqueRoles);
  }, []);

  return (
    <Menu
      className="filter-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleCloseFilter}
    >
      <Typography variant="h5">Roles</Typography>
      <Divider />
      {roles.map((item) => (
        <li key={item.id}>
          <span className="filter-label">{item.name}</span>
          <Checkbox
            size="small"
            color="primary"
            value={`${item.id}`}
            name="roles"
            checked={checkedItems.roles.includes(`${item.id}`)}
            onChange={handleChangeCheckboxes}
          />
        </li>
      ))}
      <Divider />
      <Box display="flex" justifyContent="flex-end" mt="16px">
        <Button size="small" variant="contained" color="primary" onClick={submitFilter}>
          Apply
        </Button>
      </Box>
    </Menu>
  );
}

FilterGeneralCalendar.propTypes = {
  account: PropTypes.object.isRequired,
  anchorEl: PropTypes.bool,
  handleCloseFilter: PropTypes.func.isRequired,
  checkedItems: PropTypes.object.isRequired,
  submitFilter: PropTypes.func.isRequired,
  handleChangeCheckboxes: PropTypes.func.isRequired,
};

FilterGeneralCalendar.defaultProps = {
  anchorEl: null,
};

const mapStateToProps = (state) => ({
  account: state.account.userAccount,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndividualUserIndustries: (data) => dispatch(getIndividualUserIndustriesRequest(data)),
    getAppointmentById: (data) => dispatch(getAppointmentByIdRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterGeneralCalendar);
