import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles({
  table: {
    maxWidth: 950,
  },
});

function History({ history }) {
  const createMessageMarkup = ({ message }) => ({ __html: message });
  const classes = useStyles();

  return (
    <Box p="25px">
      <TableContainer className={classes.table} component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Actions</TableCell>
              <TableCell>Date/Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { history.map((item) => (
              <TableRow key={item.id}>
                <TableCell align="left" dangerouslySetInnerHTML={createMessageMarkup(item)} />
                <TableCell>
                  {item.updated_at}
                </TableCell>
              </TableRow>
            )) }
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

History.propTypes = {
  history: PropTypes.array.isRequired,
};

export default History;
