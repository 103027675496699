import React, { useContext } from 'react';
import {
  Checkbox,
  TableCell,
  IconButton,
  TableRow,
  Link,
} from '@material-ui/core';
import PropTypes from 'prop-types';

import '../customers.scss';
import editIcon from 'assets/icons/edit.svg';
import deleteIcon from 'assets/icons/delete.svg';
import getCurrencyIcon from 'Modules/getCurrencyIcon';
import { useSelector } from 'react-redux';
import moment from 'moment';
import CustomerContext from '../customerContext';

function Row(props) {
  const {
    row,
    isItemSelected,
    labelId,
    onRowClick,
    handleClick,
  } = props;

  const {
    companyGot,
  } = useSelector((state) => state.company);

  const { deleteCustomer } = useContext(CustomerContext);

  const edit = (row) => {
    onRowClick(row);
  };

  return (
    <TableRow
      hover
      aria-checked={isItemSelected}
      role="checkbox"
      tabIndex={-1}
      selected={isItemSelected}
      onClick={() => edit(row)}
      className="row"
    >
      <TableCell padding="checkbox" className="row-item selectCheckbox">
        <Checkbox
          color="primary"
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
          onClick={(event) => handleClick(event, row.id, row)}
        />
      </TableCell>
      <TableCell component="th" id={labelId} padding="none" className="row-item">
        {row.full_name || '-'}
      </TableCell>
      <TableCell align="left" className="row-item email">
        <Link
          to="#"
          onClick={(e) => {
            e.stopPropagation();
            window.location.href = `mailto:no-reply@${row.email}`;
          }}
        >
          {row.email || '-'}
        </Link>
      </TableCell>
      <TableCell align="left" className="row-item">{row.phone_number || '-'}</TableCell>
      <TableCell align="center" className="row-item" style={{ paddingRight: '34px' }}>
        {row.appointments_total || '-'}
      </TableCell>
      <TableCell align="left" className="row-item">{row.last_visit ? moment(row.last_visit, 'YYYY-MM-DD').format('DD.MM.YYYY') : '-'}</TableCell>
      <TableCell align="left" className="row-item">{row.first_visit ? moment(row.first_visit, 'YYYY-MM-DD').format('DD.MM.YYYY') : '-'}</TableCell>
      <TableCell align="left" className="row-item">
        {row.total}{' '}{row.total > 0 && Object.keys(companyGot).length && getCurrencyIcon(companyGot.currency)}
      </TableCell>
      <TableCell align="center" className="row-item action">
        <IconButton style={{ marginLeft: '-12px' }} onClick={() => edit(row)}>
          <img src={editIcon} alt="edit" />
        </IconButton>
        <IconButton onClick={(e) => deleteCustomer(e, row.id)}>
          <img src={deleteIcon} alt="delete" />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}

export default Row;

Row.propTypes = {
  row: PropTypes.object.isRequired,
  isItemSelected: PropTypes.bool.isRequired,
  labelId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onRowClick: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};
