import { createAction } from 'redux-actions';

export const getAllCompanyCustomersRequest = createAction('GET_ALL_COMPANY_CUSTOMER_REQUEST');
export const getAllCompanyCustomersSuccess = createAction('GET_ALL_COMPANY_CUSTOMER_SUCCESS');
export const getAllCompanyCustomersError = createAction('GET_ALL_COMPANY_CUSTOMER_ERROR');

export const getAllCompanyWithIndustriesRequest = createAction('GET_ALL_COMPANY_WITH_THESE_INDUSTRIES_REQUEST');
export const getAllCompanyWithIndustriesSuccess = createAction('GET_ALL_COMPANY_WITH_THESE_INDUSTRIES_SUCCESS');
export const getAllCompanyWithIndustriesError = createAction('GET_ALL_COMPANY_WITH_THESE_INDUSTRIES_ERROR');

export const getCompanyCustomersRequest = createAction('GET_COMPANY_CUSTOMER_REQUEST');
export const getCompanyCustomersSuccess = createAction('GET_COMPANY_CUSTOMER_SUCCESS');
export const getCompanyCustomersError = createAction('GET_COMPANY_CUSTOMER_ERROR');

export const getAllCustomerInvitationsRequest = createAction('GET_ALL_CUSTOMER_INVITATIONS_REQUEST');
export const getAllCustomerInvitationsSuccess = createAction('GET_ALL_CUSTOMER_INVITATIONS_SUCCESS');
export const getAllCustomerInvitationsError = createAction('GET_ALL_CUSTOMER_INVITATIONS_ERROR');

export const getIndividualCustomersRequest = createAction('GET_ALL_INDIVIDUAL_CUSTOMER_REQUEST');
export const getIndividualCustomersSuccess = createAction('GET_ALL_INDIVIDUAL_CUSTOMER_SUCCESS');
export const getIndividualCustomersError = createAction('GET_ALL_INDIVIDUAL_CUSTOMER_ERROR');

export const acceptInvitationRequest = createAction('ACCEPT_INVITATION_REQUEST');
export const acceptInvitationSuccess = createAction('ACCEPT_INVITATION_SUCCESS');
export const acceptInvitationError = createAction('ACCEPT_INVITATION_ERROR');

export const deleteCustomerRequest = createAction('DELETE_CUSTOMER_REQUEST');
export const deleteCustomerSuccess = createAction('DELETE_CUSTOMER_SUCCESS');
export const deleteCustomerError = createAction('DELETE_CUSTOMER_ERROR');

export const deleteIndividualCustomerRequest = createAction('DELETE_INDIVIDUAL_CUSTOMER_REQUEST');
export const deleteIndividualCustomerSuccess = createAction('DELETE_INDIVIDUAL_CUSTOMER_SUCCESS');
export const deleteIndividualCustomerError = createAction('DELETE_INDIVIDUAL_CUSTOMER_ERROR');

export const deleteInvitationRequest = createAction('DELETE_INVITATION_REQUEST');
export const deleteInvitationSuccess = createAction('DELETE_INVITATION_SUCCESS');
export const deleteInvitationError = createAction('DELETE_INVITATION_ERROR');

export const deleteIndividualUserInvitationRequest = createAction('DELETE_INDIVIDUAL_USER_INVITATION_REQUEST');
export const deleteIndividualUserInvitationSuccess = createAction('DELETE_INDIVIDUAL_USER_INVITATION_SUCCESS');
export const deleteIndividualUserInvitationError = createAction('DELETE_INDIVIDUAL_USER_INVITATION_ERROR');

export const updateCustomerRequest = createAction('UPDATE_CUSTOMER_REQUEST');
export const updateCustomerSuccess = createAction('UPDATE_CUSTOMER_SUCCESS');
export const updateCustomerError = createAction('UPDATE_CUSTOMER_ERROR');

export const updateInvitationRequest = createAction('UPDATE_CUSTOMER_INVITATION_REQUEST');
export const updateInvitationSuccess = createAction('UPDATE_CUSTOMER_INVITATION_SUCCESS');
export const updateInvitationError = createAction('UPDATE_CUSTOMER_INVITATION_ERROR');

export const updateIndividualUserInvitationRequest = createAction('UPDATE_INDIVIDUAL_USER_INVITATION_REQUEST');
export const updateIndividualUserInvitationSuccess = createAction('UPDATE_INDIVIDUAL_USER_INVITATION_SUCCESS');
export const updateIndividualUserInvitationError = createAction('UPDATE_INDIVIDUAL_USER_INVITATION_ERROR');

export const getAllIndividualCustomerInvitationsRequest = createAction('GET_ALL_INDIVIDUAL_CUSTOMER_INVITATIONS_REQUEST');
export const getAllIndividualCustomerInvitationsSuccess = createAction('GET_ALL_INDIVIDUAL_CUSTOMER_INVITATIONS_SUCCESS');
export const getAllIndividualCustomerInvitationsError = createAction('GET_ALL_INDIVIDUAL_CUSTOMER_INVITATIONS_ERROR');

export const acceptIndividualCustomerInvitationRequest = createAction('ACCEPT_INDIVIDUAL_CUSTOMER_INVITATION_REQUEST');
export const acceptIndividualCustomerInvitationSuccess = createAction('ACCEPT_INDIVIDUAL_CUSTOMER_INVITATION_SUCCESS');
export const acceptIndividualCustomerInvitationError = createAction('ACCEPT_INDIVIDUAL_CUSTOMER_INVITATION_ERROR');

export const resendCustomerInvitationRequest = createAction('RESEND_CUSTOMER_INVITATIONS_REQUEST');
export const resendCustomerInvitationSuccess = createAction('RESEND_CUSTOMER_INVITATIONS_SUCCESS');
export const resendCustomerInvitationError = createAction('RESEND_CUSTOMER_INVITATIONS_ERROR');

export const filterCustomerByStatusRequest = createAction('FILTER_CUSTOMER_BY_STATUS_REQUEST');
export const filterCustomerByStatusSuccess = createAction('FILTER_CUSTOMER_BY_STATUS_SUCCESS');
export const filterCustomerByStatusError = createAction('FILTER_CUSTOMER_BY_STATUS_ERROR');

export const getStaffAvailableHoursRequest = createAction('GET_STAFF_AVAILABLE_HOURS_REQUEST');
export const getStaffAvailableHoursSuccess = createAction('GET_STAFF_AVAILABLE_HOURS_SUCCESS');
export const getStaffAvailableHoursError = createAction('GET_STAFF_AVAILABLE_HOURS_ERROR');

export const filterCustomerAppointmentsRequest = createAction('FILTER_CUSTOMER_APPOINTMENTS_REQUEST');
export const filterCustomerAppointmentsSuccess = createAction('FILTER_CUSTOMER_APPOINTMENTS_SUCCESS');
export const filterCustomerAppointmentsError = createAction('FILTER_CUSTOMER_APPOINTMENTS_ERROR');

export const filterCustomerAppointmentsByDateRequest = createAction('FILTER_CUSTOMER_APPOINTMENTS_BY_DATE_REQUEST');
export const filterCustomerAppointmentsByDateSuccess = createAction('FILTER_CUSTOMER_APPOINTMENTS_BY_DATE_SUCCESS');
export const filterCustomerAppointmentsByDateError = createAction('FILTER_CUSTOMER_APPOINTMENTS_BY_DATE_ERROR');

export const getCustomerUpcomingAppointmentsRequest = createAction('GET_CUSTOMER_UPCOMING_APPOINTMENTS_REQUEST');
export const getCustomerUpcomingAppointmentsSuccess = createAction('GET_CUSTOMER_UPCOMING_APPOINTMENTS_SUCCESS');
export const getCustomerUpcomingAppointmentsError = createAction('GET_CUSTOMER_UPCOMING_APPOINTMENTS_ERROR');

export const getCustomerAppointmentsHistoryRequest = createAction('GET_CUSTOMER_APPOINTMENTS_HISTORY_REQUEST');
export const getCustomerAppointmentsHistorySuccess = createAction('GET_CUSTOMER_APPOINTMENTS_HISTORY_SUCCESS');
export const getCustomerAppointmentsHistoryError = createAction('GET_CUSTOMER_APPOINTMENTS_HISTORY_ERROR');

export const getAllCompanyCustomersSelectRequest = createAction('GET_ALL_COMPANY_CUSTOMER_SELECT_REQUEST');
export const getAllCompanyCustomersSelectSuccess = createAction('GET_ALL_COMPANY_CUSTOMER_SELECT_SUCCESS');
export const getAllCompanyCustomersSelectError = createAction('GET_ALL_COMPANY_CUSTOMER_SELECT_ERROR');
