import React from 'react';
import {
  Box, Card, CardContent, CardHeader, CardMedia, List, ListItem, ListItemText,
} from '@material-ui/core';
import ReactPlaceholder from 'react-placeholder';
import { RoundShape } from 'react-placeholder/lib/placeholders';

function BoxLoading() {
  return (
    <>
      <Box display="flex">
        <Box item="true" m="25px" maxWidth="220px">
          <Card className="card-box-root box-cursor">
            <CardHeader
              title={(
                <Box>
                  <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                </Box>
              )}
            />
            <Box>
              <CardMedia className="img-header">
                <RoundShape color="rgb(224, 224, 224)" style={{ width: 140, height: 140, margin: '0 auto' }} />
              </CardMedia>
              <CardContent className="content-card-box">
                <List disablePadding>
                  <ListItem disableGutters dense>
                    <ListItemText className="icon-card-box">
                      <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                    </ListItemText>
                    <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                  </ListItem>
                  <ListItem disableGutters dense>
                    <ListItemText className="icon-card-box">
                      <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                    </ListItemText>
                    <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                  </ListItem>
                  <ListItem disableGutters dense>
                    <ListItemText className="icon-card-box">
                      <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                    </ListItemText>
                    <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                  </ListItem>
                </List>
              </CardContent>
            </Box>
          </Card>
        </Box>
        <Box item="true" mt="25px" maxWidth="220px">
          <Card className="card-box-root box-cursor">
            <CardHeader
              title={(
                <Box>
                  <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
                </Box>
              )}
            />
            <Box>
              <CardMedia className="img-header">
                <RoundShape color="rgb(224, 224, 224)" style={{ width: 140, height: 140, margin: '0 auto' }} />
              </CardMedia>
              <CardContent className="content-card-box">
                <List disablePadding>
                  <ListItem disableGutters dense>
                    <ListItemText className="icon-card-box">
                      <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                    </ListItemText>
                    <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                  </ListItem>
                  <ListItem disableGutters dense>
                    <ListItemText className="icon-card-box">
                      <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                    </ListItemText>
                    <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                  </ListItem>
                  <ListItem disableGutters dense>
                    <ListItemText className="icon-card-box">
                      <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                    </ListItemText>
                    <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                  </ListItem>
                </List>
              </CardContent>
            </Box>
          </Card>
        </Box>
      </Box>
    </>
  );
}
export default BoxLoading;
