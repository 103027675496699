import { put, takeLatest } from 'redux-saga/effects';
import {
  updateWorkingHoursRequest,
  updateWorkingHoursSuccess,
  updateWorkingHoursError,
  updateBreakingHoursRequest,
  updateBreakingHoursSuccess,
  updateBreakingHoursError,
  getDefaultWorkingHoursRequest,
  getDefaultWorkingHoursSuccess,
  getDefaultWorkingHoursError,
  getDefaultBreakingHoursError,
  getDefaultBreakingHoursSuccess,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

function* updateWorkingHours(action) {
  const { payload } = action;
  try {
    let url = 'company/working-hours';
    let data = {
      working_days: payload.businessDays,
    };
    if (payload.individualUserIndustryId) {
      url = 'working-hours';
      data = {
        individual_user_industry_id: payload.individualUserIndustryId,
        working_days: payload.businessDays,
      };
    }
    const response = yield axiosInstance.put(url, data);
    if (response && response.status === 200) {
      yield put(updateWorkingHoursSuccess(response.data));
    } else {
      yield put(updateWorkingHoursError(response.response.data));
    }
  } catch (e) {
    yield put(updateWorkingHoursError());
  }
}

function* updateBreakingHours(action) {
  const { payload } = action;
  try {
    let url = 'company/breaking-hours';
    let data = {
      breaking_days: payload.breakingHours,
    };
    if (payload.individualUserIndustryId) {
      url = 'breaking-hours';
      data = {
        individual_user_industry_id: payload.individualUserIndustryId,
        breaking_days: payload.breakingHours,
      };
    }
    const response = yield axiosInstance.put(url, data);
    if (response && response.status === 200) {
      yield put(updateBreakingHoursSuccess(response.data));
    }
  } catch (e) {
    yield put(updateBreakingHoursError());
  }
}

function* getWorkingHours() {
  try {
    const response = yield axiosInstance.get('working-hours');
    if (response && response.status === 200) {
      yield put(getDefaultWorkingHoursSuccess(response.data));
    }
  } catch (e) {
    yield put(getDefaultWorkingHoursError());
  }
}

function* getBreakingHours() {
  try {
    const response = yield axiosInstance.get('breaking-hours');
    if (response && response.status === 200) {
      yield put(getDefaultBreakingHoursSuccess(response.data));
    }
  } catch (e) {
    yield put(getDefaultBreakingHoursError());
  }
}

export default function* () {
  yield takeLatest(updateWorkingHoursRequest, updateWorkingHours);
  yield takeLatest(updateBreakingHoursRequest, updateBreakingHours);
  yield takeLatest(getDefaultWorkingHoursRequest, getWorkingHours);
  yield takeLatest(getDefaultWorkingHoursRequest, getBreakingHours);
}
