const getColorsByAppointmentStatus = (appointmentStatus, isDark) => {
  let backgroundColor;

  switch (appointmentStatus) {
    case 'accepted': {
      backgroundColor = isDark ? '#F1F8F1' : '#9DCC9B';
      break;
    }
    case 'pending': {
      backgroundColor = isDark ? '#FFFAF5' : '#F3B175';
      break;
    }
    case 'finished': {
      backgroundColor = isDark ? '#F2F9FD' : '#0282DA';
      break;
    }
    case 'cancelled': {
      backgroundColor = isDark ? '#EFF0F1' : '#A4A4A4';
      break;
    }
  }

  return backgroundColor;
};

export default getColorsByAppointmentStatus;
