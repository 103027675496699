import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  TextareaAutosize,
  Button,
  Tabs,
  Tab,
  Grid,
  Box,
  IconButton,
  makeStyles,
  Typography,
  FormControl,
  FormHelperText,
  InputAdornment,
} from '@material-ui/core';
import { format } from 'date-fns';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import moment from 'moment';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ErrorIcon from '@material-ui/icons/Error';
import i18n from 'i18next';
import TabPanel from './tabPanel';
import calendar from '../../assets/icons/calendar.svg';
import clock from '../../assets/icons/clock.svg';
import {
  getIndustriesRequest,
} from '../../redux/industry/actions';
import {
  getAllCompaniesRequest,
  getPersonalCompaniesRequest,
  getStaffWithServicesRequest,
} from '../../redux/company/actions';
import usePrevious from '../../CustomHooks/usePrevious';
import PermissionsModule from '../../Modules/Permission/index';
import {
  storeAppointmentRequest,
  validAppointmentRequest,
  getGeneralAppointmentsRequest,
} from '../../redux/appointment/actions';
import {
  getUserIndividualIndustriesRequest,
  getIndividualAvailableHoursRequest,
  getPersonalIndividualUserIndustriesRequest,
} from '../../redux/individualUser/actions';
import {
  getCompanyCustomersRequest,
  getIndividualCustomersRequest,
  getStaffAvailableHoursRequest,
} from '../../redux/customer/actions';
import industriesLogo from '../../assets/industriesLogo';
import companyLogo from '../../assets/img/company.svg';
import customerAndStaffDefaultLogo from '../../assets/img/avatar.svg';
import serviceLogo from '../../assets/img/service-default.svg';
import capitalize from '../../Modules/capitalize';
import {
  getCompanyServicesWithSubServicesRequest,
  getIndividualServicesWithSubServicesRequest,
} from '../../redux/service/actions';
import servicesLogo from '../../assets/servicesLogo';
import SnackbarToast from '../../Modules/SnackbarToast';

function NewAppointment(props) {
  const {
    open,
    close,
    industries,
    userAccount,
    allCompanies,
    getCompanies,
    getIndustries,
    newAppointment,
    customerOptions,
    getAllCompanies,
    validAppointment,
    companyCustomers,
    personalCompanies,
    storeAppointment,
    staffWithServices,
    getUserIndustries,
    getPersonalCompanies,
    onAppointmentCreated,
    getStaffWithServices,
    sendAppointmentItem,
    sendSaveAppointment,
    isValidAppointmentError,
    validAppointmentErrors,
    allIndividualCustomers,
    isGetAllCompaniesSuccess,
    isGetAllCompaniesError,
    isStoreAppointmentSuccess,
    isStoreAppointmentError,
    storeAppointmentErrors,
    getPersonalIndustries,
    userIndividualIndustries,
    getIndividualCustomers,
    isGetPersonalCompaniesError,
    isGetStaffWithServicesSuccess,
    isGetStaffWithServicesError,
    isGetPersonalCompaniesSuccess,
    personalIndividualUserIndustries,
    getStaffWithServicesErrorMessage,
    getPersonalCompaniesErrorMessage,
    isGetAllIndividualCustomersSuccess,
    isGetAllIndividualCustomersError,
    calendarAppointmentItem,
    isValidAppointmentSuccess,
    getAllCompaniesErrorMessage,
    getCompanyCustomers,
    isGetCustomersSuccess,
    isGetCustomersError,
    openInviteCustomerModal,
    getPersonalAppointments,
    getGeneralAppointments,
    isGetUserIndividualIndustriesSuccess,
    isGetUserIndividualIndustriesError,
    isGetPersonalIndividualUserIndustriesSuccess,
    isGetPersonalIndividualUserIndustriesError,
    getUserIndividualIndustriesErrorMessage,
    getPersonalIndividualUserIndustriesErrorMessage,
    getStaffAvailableHours,
    isGetStaffAvailableHoursSuccess,
    availableHours,
    getServicesWithSubServices,
    isGetCompanyServicesWithSubServicesSuccess,
    servicesWithSubServicesByCompanyId,
    getServicesByIndividualUserId,
    isGetIndividualServicesWithSubServicesSuccess,
    isGetIndividualServicesWithSubServicesError,
    servicesWithSubServicesByIndividualId,
    getServicesByIndividualUserIdErrorMessage,
    getIndividualAvailableHours,
    individualAvailableHours,
    isIndividualAvailableHoursSuccess,
  } = props;

  const prevIsGetCustomersSuccess = usePrevious(isGetCustomersSuccess);
  const prevIsGetCustomersError = usePrevious(isGetCustomersError);
  const prevIsGetPersonalCompaniesSuccess = usePrevious(isGetPersonalCompaniesSuccess);
  const prevIsGetPersonalCompaniesError = usePrevious(isGetPersonalCompaniesError);
  const prevIsGetStaffWithServicesSuccess = usePrevious(isGetStaffWithServicesSuccess);
  const prevIsGetStaffWithServicesError = usePrevious(isGetStaffWithServicesError);
  const prevIsStoreAppointmentSuccess = usePrevious(isStoreAppointmentSuccess);
  const prevIsStoreAppointmentError = usePrevious(isStoreAppointmentError);
  const prevIsValidAppointmentSuccess = usePrevious(isValidAppointmentSuccess);
  const prevIsValidAppointmentError = usePrevious(isValidAppointmentError);
  const prevIsGetPersonalIndividualUserIndustriesSuccess = usePrevious(isGetPersonalIndividualUserIndustriesSuccess);
  const prevIsGetPersonalIndividualUserIndustriesError = usePrevious(isGetPersonalIndividualUserIndustriesError);
  const prevIsGetUserIndividualIndustriesSuccess = usePrevious(isGetUserIndividualIndustriesSuccess);
  const prevIsGetUserIndividualIndustriesError = usePrevious(isGetUserIndividualIndustriesError);
  const prevIsGetAllIndividualCustomersSuccess = usePrevious(isGetAllIndividualCustomersSuccess);
  const prevIsGetAllIndividualCustomersError = usePrevious(isGetAllIndividualCustomersError);
  const prevIsGetAllCompaniesSuccess = usePrevious(isGetAllCompaniesSuccess);
  const prevIsGetAllCompaniesError = usePrevious(isGetAllCompaniesSuccess);
  const prevIsGetStaffAvailableHoursSuccess = usePrevious(isGetStaffAvailableHoursSuccess);
  const prevIsIndividualAvailableHoursSuccess = usePrevious(isIndividualAvailableHoursSuccess);
  const prevIsGetCompanyServicesWithSubServicesSuccess = usePrevious(isGetCompanyServicesWithSubServicesSuccess);
  const prevIsGetIndividualServicesWithSubServicesSuccess = usePrevious(isGetIndividualServicesWithSubServicesSuccess);
  const prevIsGetIndividualServicesWithSubServicesError = usePrevious(isGetIndividualServicesWithSubServicesError);
  const date = new Date();

  const a11yProps = (index) => ({
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  });

  const appointmentObject = (error = false) => {
    const value = error ? false : '';
    const object = {
      date: error ? false : format(date, 'yyyy-MM-dd'),
      start_time: error ? false : format(date, 'HH:mm'),
      staff_id: value,
      service_id: value,
      price: value,
      duration: value,
      individual_user_industry_id: value,
    };
    error ? object.industry_id = false : object.title = '';

    return object;
  };

  // State
  const [appointmentItem, setAppointmentItem] = useState(appointmentObject());
  const [formErrors, setFormErrors] = useState(appointmentObject(true));
  const [tabIndex, setTabIndex] = useState(0);
  const [companies, setCompanies] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [individualUserIndustries, setIndividualUserIndustries] = useState([]);
  const [selectedIndividualUserIndustry, setSelectedIndividualUserIndustry] = useState('');
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [selectedStaff, setSelectedStaff] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [services, setServices] = useState([]);
  const [staff, setStaff] = useState([]);
  const [roles, setRoles] = useState([]);
  const [createAppointmentFor, setCreateAppointmentFor] = useState(null);
  const [userIndustries, setUserIndustries] = useState([]);
  const [validErrors, setValidErrors] = useState({});
  const [errors, setErrors] = useState({});
  const [staffAvailableTime, setStaffAvailableTime] = useState([]);
  const [staffServices, setStaffServices] = useState([]);
  const [selectedHour, setSelectedHour] = useState('');
  const [pendingValue, setPendingValue] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const useStyles = makeStyles(() => ({
    leftBtn: {
      color: '#000',
      marginLeft: '-8px',
      marginRight: '5px',
    },
    option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    tabLabel: {
      textTransform: 'initial',
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (Object.keys(calendarAppointmentItem).length > 0) {
      setAppointmentItem(
        {
          ...appointmentItem,
          date: calendarAppointmentItem ? calendarAppointmentItem.date : format(date, 'yyyy-MM-dd'),
          start_time: calendarAppointmentItem ? (calendarAppointmentItem.start_time === undefined ? calendarAppointmentItem.time : calendarAppointmentItem.start_time) : format(date, 'hh:mm'),
          day_time: calendarAppointmentItem ? calendarAppointmentItem.day_time : '',
        },
      );
    }
    const userRoles = PermissionsModule.getRoles(userAccount.testPermissions);
    setRoles(userRoles.sort());

    if (sendSaveAppointment) {
      sendSaveAppointment(createAppointment);
    }
    const [first] = userRoles;
    if (open) {
      if (first === 'Owner') {
        getIndustries({ filterBy: 'Company' });
      }
    }
    localStorage.setItem('prefix', first.toLowerCase());
  }, [open]);
  // Perform, when get subServices
  useEffect(() => {
    if (prevIsGetCompanyServicesWithSubServicesSuccess === false && isGetCompanyServicesWithSubServicesSuccess) {
      const servicesWithSubServicesByCompanyIdCopy = [...servicesWithSubServicesByCompanyId];
      servicesWithSubServicesByCompanyIdCopy.forEach((item) => {
        if (typeof item.subServices === 'string') {
          item.subServices = JSON.parse(item.subServices);
        }
      });
      setStaffServices(servicesWithSubServicesByCompanyIdCopy);
    }
  }, [isGetCompanyServicesWithSubServicesSuccess]);

  // Perform, when get individual services success
  useEffect(() => {
    if (prevIsGetIndividualServicesWithSubServicesSuccess === false && isGetIndividualServicesWithSubServicesSuccess) {
      const individualUserServicesCopy = [...servicesWithSubServicesByIndividualId];
      individualUserServicesCopy.forEach((item) => {
        if (typeof item.subServices === 'string') {
          item.subServices = JSON.parse(item.subServices);
        }
      });
      setStaffServices(individualUserServicesCopy);
    }
  }, [isGetIndividualServicesWithSubServicesSuccess]);

  // Perform, when get individual services error
  useEffect(() => {
    if (prevIsGetIndividualServicesWithSubServicesError === false && isGetIndividualServicesWithSubServicesError) {
      snackBarAlert(true, getServicesByIndividualUserIdErrorMessage, 'error');
    }
  }, []);

  // Perform, when get companies finished
  useEffect(() => {
    if (prevIsGetPersonalCompaniesSuccess === false && isGetPersonalCompaniesSuccess) {
      if (personalCompanies && personalCompanies.length > 0) {
        setCompanies(personalCompanies);
      }
    } else if (prevIsGetPersonalCompaniesError === false && isGetPersonalCompaniesError) {
      snackBarAlert(true, getPersonalCompaniesErrorMessage, 'error');
    }
  }, [isGetPersonalCompaniesSuccess, isGetPersonalCompaniesError]);
  // Perform when get staff with services finished
  useEffect(() => {
    if (prevIsGetStaffWithServicesSuccess === false && isGetStaffWithServicesSuccess) {
      if (staffWithServices && staffWithServices.length > 0) {
        const currentStaff = staffWithServices.find((item) => item.company_id === selectedCompany);
        const services = [];

        if (currentStaff && currentStaff.services && currentStaff.services.length > 0) {
          currentStaff.services.map((item) => services.push({
            id: item.id,
            service_id: item.service_id,
            name: item.service.name,
            duration: item.duration,
            price: item.price,
          }));
        }

        setServices(services);
        setStaff(staffWithServices);
      }
    } else if (prevIsGetStaffWithServicesError === false && isGetStaffWithServicesError) {
      snackBarAlert(true, getStaffWithServicesErrorMessage, 'error');
    }
  }, [isGetStaffWithServicesSuccess, isGetStaffWithServicesError]);
  // Perform, when appointment is valid
  useEffect(() => {
    if (prevIsValidAppointmentSuccess === false && isValidAppointmentSuccess) {
      const infoData = getAppointmentInfo();
      sendAppointmentItem(infoData, true);
      snackBarAlert(true, i18n.t('ValidAppointment'), 'success');
    } else if (prevIsValidAppointmentError === false && isValidAppointmentError) {
      setValidErrors(validAppointmentErrors);
      setErrors({});
    }
  }, [isValidAppointmentSuccess, isValidAppointmentError]);
  // Perform, when store appointment finished
  useEffect(() => {
    if (prevIsStoreAppointmentSuccess === false && isStoreAppointmentSuccess) {
      onAppointmentCreated(newAppointment);
      setSelectedHour('');
      getPersonalAppointments();
      onModalClose();
      getGeneralAppointments();
    } else if (prevIsStoreAppointmentError === false && isStoreAppointmentError) {
      setErrors(storeAppointmentErrors);
      setValidErrors({});
    }
  }, [isStoreAppointmentSuccess, isStoreAppointmentError]);
  // Perform, when get individual user industries finished
  useEffect(() => {
    if (prevIsGetPersonalIndividualUserIndustriesSuccess === false && isGetPersonalIndividualUserIndustriesSuccess) {
      setIndividualUserIndustries(personalIndividualUserIndustries);
    } else if (prevIsGetPersonalIndividualUserIndustriesError === false && isGetPersonalIndividualUserIndustriesError) {
      snackBarAlert(true, getPersonalIndividualUserIndustriesErrorMessage, 'error');
    }
  }, [isGetPersonalIndividualUserIndustriesSuccess, prevIsGetPersonalIndividualUserIndustriesError]);
  // Perform, when get user individual industries finished
  useEffect(() => {
    if (prevIsGetUserIndividualIndustriesSuccess === false && isGetUserIndividualIndustriesSuccess) {
      setUserIndustries(userIndividualIndustries);
    } else if (prevIsGetUserIndividualIndustriesError === false && isGetUserIndividualIndustriesError) {
      snackBarAlert(true, getUserIndividualIndustriesErrorMessage, 'error');
    }
  }, [isGetUserIndividualIndustriesSuccess, isGetUserIndividualIndustriesError]);
  // Perform, when get individual customers finished
  useEffect(() => {
    if (prevIsGetAllIndividualCustomersSuccess === false && isGetAllIndividualCustomersSuccess) {
      const customers = allIndividualCustomers.map((item) => ({
        id: item.id,
        name: `${item.first_name} ${item.last_name}`,
      }));
      setCustomers(customers);
    } else if (prevIsGetAllIndividualCustomersError === false && isGetAllIndividualCustomersError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetAllIndividualCustomersSuccess, isGetAllIndividualCustomersError]);

  useEffect(() => {
    if (isGetCustomersSuccess && prevIsGetCustomersSuccess === false) {
      const companyCustomersCopy = [...companyCustomers.data];
      if (companyCustomersCopy && companyCustomersCopy.length > 0) {
        const customersData = companyCustomersCopy.map((item) => ({
          id: item.id,
          name: `${item.first_name} ${item.last_name}`,
        }));
        setCustomers(customersData);
      }
    } else if (isGetCustomersError && prevIsGetCustomersError === false) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetCustomersSuccess, isGetCustomersError]);
  // Perform, when get all companies for staff finished
  useEffect(() => {
    if (prevIsGetAllCompaniesSuccess === false && isGetAllCompaniesSuccess) {
      setCompanies(allCompanies.companies || allCompanies);
    } else if (prevIsGetAllCompaniesError === false && isGetAllCompaniesError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetAllCompaniesSuccess, isGetAllCompaniesError]);
  // Perform, when function gets mounted
  useEffect(() => {
    if (roles[tabIndex] === 'Staff') {
      getAllCompanies();
    }
  }, [roles]);
  // Handle Get All Companies Error
  useEffect(() => {
    if (isGetAllCompaniesError) {
      snackBarAlert(true, getAllCompaniesErrorMessage, 'error');
    }
  }, [isGetAllCompaniesError]);
  // Handle Get Staff Available Hours
  useEffect(() => {
    if (prevIsGetStaffAvailableHoursSuccess === false && isGetStaffAvailableHoursSuccess) {
      setStaffAvailableTime(availableHours);
    }
  }, [isGetStaffAvailableHoursSuccess]);

  // Handle Get Individual Available Hours
  useEffect(() => {
    if (prevIsIndividualAvailableHoursSuccess === false && isIndividualAvailableHoursSuccess) {
      setStaffAvailableTime(individualAvailableHours);
    }
  }, [isIndividualAvailableHoursSuccess]);

  const revertStateToDefault = (revertTab = true) => {
    setAppointmentItem(appointmentObject());
    setFormErrors(appointmentObject(true));
    if (revertTab) {
      setTabIndex(0);
    }
    setSelectedCompany('');
    setSelectedService('');
    setSelectedStaff('');
    setSelectedCustomer('');
    setSelectedIndustry('');
    setSelectedIndividualUserIndustry('');
    setCreateAppointmentFor(null);
    setErrors({});
    setValidErrors({});
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const onModalClose = () => {
    close();
    revertStateToDefault();
  };

  const updateAppointmentItem = (name, value) => {
    if (createAppointmentFor === 'Individual') {
      const availableHoursData = {
        individual_user_industry_id: appointmentItem.individual_user_industry_id,
        service_id: appointmentItem.service_id,
        date: value,
      };
      getIndividualAvailableHours(availableHoursData);
    } else {
      const availableHoursData = {
        staff_id: appointmentItem.staff_id,
        service_id: appointmentItem.service_id,
        date: value,
      };
      getStaffAvailableHours(availableHoursData);
    }
    setAppointmentItem({
      ...appointmentItem,
      [name]: value,
    });
  };

  const chooseIndustry = (event, value) => {
    if (value && value.id) {
      const { id } = value;
      setSelectedIndustry(id);
      const data = { industryId: id };
      const role = roles[tabIndex];
      if (role === 'Customer') {
        if (createAppointmentFor === 'Company') {
          setSelectedCompany('');
          setSelectedStaff('');
          setSelectedService('');
          getPersonalCompanies(data);
        } else {
          setSelectedIndividualUserIndustry('');
          setSelectedService('');
          getPersonalIndustries(data);
        }
      } else if (role === 'Individual') {
        const industry = userIndustries.find((item) => item.id === id);
        const services = industry.services.map((item) => ({
          id: item.service.id,
          name: item.service.name,
        }));
        setSelectedService('');
        setSelectedCustomer('');
        setServices(services);
      } else if (role === 'Owner') {
        setSelectedCompany('');
        setSelectedStaff('');
        setSelectedService('');
        getCompanies(data);
      }
    } else {
      setSelectedIndustry('');
      setSelectedCompany('');
      setSelectedCustomer('');
      setSelectedStaff('');
      setSelectedService('');
      setSelectedIndividualUserIndustry('');
    }
  };

  const chooseCompany = (event, value) => {
    if (value && value.id) {
      const { id } = value;
      setSelectedCompany(id);
      const role = roles[tabIndex];
      if (role === 'Customer' || role === 'Owner') {
        getStaffWithServices({ companyId: id });
      } else if (role === 'Staff') {
        getStaffWithServices({ companyId: id });
        const company = companies.find((item) => item.id === id);
        const services = company.staff_services.map((item) => ({
          id: item.service.id,
          name: item.service.name,
        }));
        setServices(services);
        getCompanyCustomers({ id });
      }
    } else {
      setSelectedCompany('');
      setSelectedStaff('');
      setSelectedService('');
      setSelectedCustomer('');
    }
  };

  const chooseIndividualUserIndustry = (event, value) => {
    if (value && value.id) {
      const { id } = value;
      setSelectedIndividualUserIndustry(id);
      setSelectedService('');
      updateAppointmentItem('individual_user_industry_id', id);
      getServicesByIndividualUserId({
        individualUserIndustryId: id,
        industryId: selectedIndustry,
      });
    } else {
      setSelectedIndividualUserIndustry('');
      setSelectedService('');
    }
  };

  const chooseStaff = (event, value) => {
    if (value && value.id) {
      const { id } = value;
      setSelectedStaff(id);
      updateAppointmentItem('staff_id', id);
      const staff = staffWithServices.find((item) => item.id === id);
      const services = staff ? staff.services.map((item) => ({
        id: item.id,
        name: item.service.name,
      })) : [];
      setServices(services);
      getServicesWithSubServices({
        staff_id: id,
      });
    } else {
      setSelectedStaff('');
      setSelectedService('');
    }
  };

  const chooseCustomer = (event, value) => {
    if (value && value.id) {
      const { id } = value;
      updateAppointmentItem('customer_id', id);
      if (id) {
        setSelectedCustomer(id);
      }
    } else {
      setSelectedCustomer('');
    }
  };

  const chooseService = (serviceItem) => {
    if (serviceItem) {
      const { service_id, price, duration } = serviceItem;
      const role = roles[tabIndex];
      setSelectedService(service_id);
      setAppointmentItem({
        ...appointmentItem,
        service_id,
        price,
        duration,
      });

      if (createAppointmentFor === 'Individual') {
        const availableHoursData = {
          individual_user_industry_id: appointmentItem.individual_user_industry_id,
          service_id,
          date: appointmentItem.date,
        };

        getIndividualAvailableHours(availableHoursData);
      } else {
        const availableHoursData = {
          staff_id: appointmentItem.staff_id || userAccount.id,
          service_id,
          date: appointmentItem.date,
        };
        getStaffAvailableHours(availableHoursData);
      }

      if (allIndividualCustomers.length === 0 && role === 'Individual') {
        getIndividualCustomers();
      } else if (role === 'Owner') {
        const company = companies.find((item) => item.id === selectedCompany);
        const customers = company.customers.map((item) => ({
          id: item.id,
          name: item.full_name,
        }));
        const filteredCustomers = customers.filter((item) => item.id !== selectedStaff);
        setCustomers(filteredCustomers);
      }
    } else {
      setSelectedService('');
    }
  };

  const validateForm = () => {
    const keys = Object.keys(formErrors);
    const errors = { ...formErrors };
    const isCreatingForCompany = createAppointmentFor === 'Company';
    const isCreatingForStaff = roles[tabIndex] === 'Staff';
    const isCustomerCreating = roles[tabIndex] === 'Customer';
    keys.forEach((key) => {
      switch (key) {
        case 'industry_id': {
          errors[key] = (!selectedIndustry && !isCreatingForStaff && selectedIndustry === '');
          break;
        }
        case 'company_id': {
          errors[key] = (!appointmentItem.company_id && isCreatingForCompany && appointmentItem.company_id === '');
          break;
        }
        case 'individual_user_industry_id': {
          errors[key] = (createAppointmentFor === 'Individual' && !appointmentItem.individual_user_industry_id && !isCreatingForCompany && appointmentItem.individual_user_industry_id === '');
          break;
        }
        case 'staff_id': {
          errors[key] = (!appointmentItem.staff_id && isCreatingForCompany && appointmentItem.staff_id === '');
          break;
        }
        case 'service_id': {
          errors[key] = (!appointmentItem.service_id && appointmentItem.service_id === '');
          break;
        }
        case 'date': {
          errors[key] = (!appointmentItem.date && appointmentItem.date === '');
          break;
        }
        case 'start_time': {
          errors[key] = (!appointmentItem.start_time && appointmentItem.start_time === '');
          break;
        }
        case 'price': {
          errors[key] = (!isCustomerCreating && (!appointmentItem.price && appointmentItem.price === ''));
          break;
        }
        case 'duration': {
          errors[key] = (!isCustomerCreating && (!appointmentItem.duration && appointmentItem.duration === ''));
          break;
        }
      }
    });

    if (appointmentItem.start_time) {
      const startTime = new Date(appointmentItem.start_time);
      const currentDate = new Date();
      errors.start_time = currentDate > startTime;
    }

    setFormErrors(errors);

    return keys.find((key) => errors[key]);
  };

  const getStoreAppointmentData = () => {
    const role = roles[tabIndex];
    const data = {
      date: appointmentItem.date,
      service_id: appointmentItem.service_id,
      duration: appointmentItem.duration,
      start_time: `${appointmentItem.start_time}:00`,
    };
    if (role === 'Customer') {
      if (createAppointmentFor === 'Company') {
        data.company_id = appointmentItem.company_id;
        data.staff_id = appointmentItem.staff_id;
      } else {
        data.individual_user_industry_id = appointmentItem.individual_user_industry_id;
      }
    } else if (role === 'Individual') {
      data.creator = 'individual';
      data.customer_id = selectedCustomer;
      data.individual_user_industry_id = selectedIndustry;
      data.price = appointmentItem.price;
      data.duration = appointmentItem.duration;
      data.day_time = moment(`${appointmentItem.date} ${appointmentItem.start_time}`).format();
    } else if (role === 'Staff' || role === 'Owner') {
      data.company_id = appointmentItem.company_id;
      data.customer_id = appointmentItem.customer_id;
      data.price = appointmentItem.price;
      data.duration = appointmentItem.duration;
      data.day_time = moment(`${appointmentItem.date} ${appointmentItem.start_time}`).format();
      data.staff_id = role === 'Staff' ? userAccount.id : appointmentItem.staff_id;
    }

    return {
      ...data,
      duration: parseInt(data.duration),
    };
  };

  const getAppointmentInfo = () => {
    const company = companies.find((item) => item.id === selectedCompany);
    const staff = staffWithServices.find((item) => item.id === selectedStaff);
    const service = services.find((item) => item.id === selectedService);

    return {
      companyName: company.name,
      staffName: staff.name,
      serviceName: service.name,
      title: appointmentItem.title,
      date: appointmentItem.date,
      time: appointmentItem.start_time,
      price: appointmentItem.price,
      duration: appointmentItem.duration,
    };
  };

  const handleShowPreview = () => {
    if (!validateForm()) {
      const data = getStoreAppointmentData();
      validAppointment(data);
    }
  };

  const createAppointment = () => {
    if (!validateForm()) {
      const data = getStoreAppointmentData();
      storeAppointment(data);
    }
  };

  const getOptionLogo = (label, option, name = null) => {
    let logo;
    switch (label) {
      case 'Industry': {
        logo = industriesLogo[name === 'industry' ? option.industryName : option.name];
        break;
      }
      case 'Company': {
        logo = option.logo || companyLogo;
        break;
      }
      case 'Staff': {
        logo = option.logo || customerAndStaffDefaultLogo;
        break;
      }
      case 'Service': {
        logo = option.logo || serviceLogo;
        break;
      }
      case 'Customer': {
        logo = option.logo || customerAndStaffDefaultLogo;
        break;
      }
      default: {
        logo = null;
      }
    }
    return logo;
  };

  const getSelectInputShownValue = (label, value, options) => {
    const selectedOption = options.find((option) => option.id === value);

    return selectedOption ? (label === 'industry' ? selectedOption.industryName : selectedOption.name) : '';
  };

  const renderSelect = (label, value, onChange, options, name, img = null) => {
    const inputValue = getSelectInputShownValue(name, value, options);
    return (
      <>
        {img ? (
          <Grid item xs={12}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={!!formErrors[name]}
            >
              <TextField
                fullWidth
                error={!!formErrors[name]}
                size="small"
                variant="outlined"
                placeholder={label}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img src={img} alt="" />
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                }}
              />

              { formErrors[name] && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {`${label} is required.`}
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={!!formErrors[name]}
            >
              <Autocomplete
                fullWidth
                id="country-select-demo"
                size="small"
                options={options}
                classes={{
                  option: classes.option,
                }}
                value={value.id}
                autoHighlight
                onChange={onChange}
                getOptionLabel={(option) => name === 'industry' ? option.industryName : option.name}
                renderOption={(option) => (
                  <div
                    className="input-menu"
                    key={option.id}
                    value={option.id}
                  >
                    <span className="option-logo">
                      <img
                        src={getOptionLogo(label, option, name)}
                        alt="logo"
                      />
                    </span>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <span className="input-name">{name === 'industry' ? option.industryName : option.name}</span>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <span className="input-field">{name === 'service_id'
                        ? `Price: ${option.price} /
                       Duration: ${option.duration}`
                        : ''}
                      </span>
                    </Grid>
                  </div>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={`${label} *`}
                    variant="outlined"
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password',
                      value: inputValue,
                    }}
                    autoComplete="false"
                    error={!!formErrors[name]}
                  />
                )}
              />

              { formErrors[name] && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {`${label} is required.`}
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
        )}
      </>
    );
  };

  const renderSelectService = (label, value, onChange, options, name) => (
    <Grid item xs={12}>
      <FormControl
        fullWidth
        size="small"
        variant="outlined"
        error={!!formErrors[name]}
      >
        <Autocomplete
          size="small"
          fullWidth
          autoHighlight
          options={options}
          groupBy={(option) => (
            <span className="group-title">{option.type
              ? <img src={servicesLogo[option.type]} className="service-img" alt="service-logo" width="20px" /> : null}
              {option.type}
            </span>
          )}
          getOptionLabel={(option) => option.name}
          value={pendingValue}
          onChange={(event, newValue) => {
            setPendingValue(newValue);
            onChange(newValue);
          }}
          renderOption={(option) => (
            <div
              className="input-menu"
              key={option.id}
              value={option.id}
            >
              <Grid container direction="row">
                <Grid item xs={8}>{option.name}</Grid>
                <Grid item xs={2}><span className="service-duration">{option.duration} min</span></Grid>
                <Grid item xs={2}><span className="service-price">{option.price} ֏</span></Grid>
              </Grid>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Service"
              variant="outlined"
              error={errors.service}
            />
          )}
        />

        { formErrors[name] && (
          <div className="error-message-content">
            <ErrorIcon fontSize="small" color="error" />
            <FormHelperText>
              {`${label} is required.`}
            </FormHelperText>
          </div>
        )}
      </FormControl>
    </Grid>
  );

  const renderDatetime = (label, value, onChange, img, name) => (
    <Grid item xs={12}>
      <FormControl
        fullWidth
        size="small"
        variant="outlined"
        error={!!formErrors[name] || !!errors[name] || !!validErrors[name]}
      >
        <TextField
          fullWidth
          error={!!formErrors[name] || !!errors[name] || !!validErrors[name]}
          size="small"
          type={label.toLowerCase()}
          onChange={(e) => onChange(name, e.target.value)}
          variant="outlined"
          value={value || ''}
          inputProps={{ min: format(date, 'yyyy-MM-dd'), autoComplete: 'new-password' }}
        />

        { formErrors[name] && (
          <div className="error-message-content">
            <ErrorIcon fontSize="small" color="error" />
            <FormHelperText>
              {`${label} is required.`}
            </FormHelperText>
          </div>
        )}

        {errors[name] && (
          <div className="error-message-content">
            <ErrorIcon fontSize="small" color="error" />
            <FormHelperText>
              {errors[name]}
            </FormHelperText>
          </div>
        )}

        {validErrors[name] && (
          <div className="error-message-content">
            <ErrorIcon fontSize="small" color="error" />
            <FormHelperText>
              {validErrors[name]}
            </FormHelperText>
          </div>
        )}
      </FormControl>
    </Grid>
  );

  const renderTextarea = (label, value, onChange, name) => (
    <Grid item xs={12}>
      <FormControl
        fullWidth
        size="small"
        variant="outlined"
      >
        <TextareaAutosize
          rowsMin={3}
          className="notes-from-creator"
          name={name}
          aria-label={label}
          placeholder={label}
          value={value}
          onChange={onChange}
        />
      </FormControl>
    </Grid>
  );

  const renderInput = (label, name) => (
    <Grid container>
      <Grid item xs={12}>
        <FormControl
          fullWidth
          size="small"
          variant="outlined"
          error={!!formErrors[name]}
        >
          <TextField
            fullWidth
            error={!!formErrors[name]}
            size="small"
            variant="outlined"
            style={{ position: 'relative' }}
            placeholder={label}
            label={label}
            value={appointmentItem[name] || ''}
            InputProps={{
              classes: {
                root: classes.root,
                notchedOutline: classes.notchedOutline,
              },
            }}
            onChange={(event) => updateAppointmentItem(name, event.target.value)}
          />

          { formErrors[name] && (
            <div className="error-message-content">
              <ErrorIcon fontSize="small" color="error" />
              <FormHelperText>
                {`${capitalize(name)} is required.`}
              </FormHelperText>
            </div>
          )}
        </FormControl>
      </Grid>
    </Grid>
  );

  const handleTabChange = (event, tabIndex) => {
    setTabIndex(tabIndex);
    setValidErrors({});
    setErrors({});
    localStorage.setItem('prefix', roles[tabIndex]);
    revertStateToDefault(false);
    if (roles[tabIndex] === 'Individual') {
      if (userIndividualIndustries.length === 0) {
        getUserIndustries();
      } else {
        setUserIndustries(userIndividualIndustries);
      }
    } else if (roles[tabIndex] === 'Staff') {
      getCompanies({ isForCalendar: true });
    }
  };

  const shouldItemRendered = (dependedOptionValue) => !!(dependedOptionValue && dependedOptionValue.toString().length !== 0);

  const renderIndividualIndustrySelect = () => {
    const params = [
      'Individual User',
      selectedIndividualUserIndustry,
      chooseIndividualUserIndustry,
      individualUserIndustries,
      'individual_user_industry_id',
    ];
    return renderSelect(...params);
  };

  const handleChangeWhom = (type) => {
    setValidErrors({});
    setErrors({});
    if (type === 'Company') {
      setCreateAppointmentFor('Company');
      getIndustries({ filterBy: 'Company' });
    } else {
      setCreateAppointmentFor('Individual');
      getIndustries({ filterBy: 'Individual' });
    }
  };

  const handleChangeHours = (event) => {
    if (event.target.textContent) {
      setAppointmentItem({
        ...appointmentItem,
        start_time: event.target.textContent,
      });
      updateAppointmentItem('start_time', event.target.textContent);
      setSelectedHour(event.target.textContent);
    }
  };

  const renderFormFieldsBasedOnRoles = (role, index) => {
    if (role === 'Customer') {
      return (
        <TabPanel key={`${role}${index}`} value={tabIndex} index={index}>
          { createAppointmentFor ? (
            <>
              {renderSelect('Industry', selectedIndustry, chooseIndustry, industries, 'industry_id')}
              {shouldItemRendered(selectedIndustry) && (
                createAppointmentFor === 'Company' ? (
                  renderSelect('Company', selectedCompany, chooseCompany, companies, 'company_id')
                ) : (
                  renderIndividualIndustrySelect()
                )
              )}
              {shouldItemRendered(selectedCompany) && renderSelect('Staff', selectedStaff, chooseStaff, staff, 'staff_id')}
              {(shouldItemRendered(selectedStaff) || shouldItemRendered(selectedIndividualUserIndustry)) && renderSelectService('Service', selectedService, chooseService, serviceOptions, 'service_id')}
              <Grid item xs={6}>
                {selectedService
                  ? renderDatetime('Date', appointmentItem ? appointmentItem.date : appointmentItem, updateAppointmentItem, calendar, 'date')
                  : null}
              </Grid>
              <Grid item xs={6}>
                {selectedService
                  ? (
                    <FormControl
                      fullWidth
                      // error={!staffAvailableTime.length}
                      size="small"
                      variant="outlined"
                    >
                      <Autocomplete
                        size="small"
                        fullWidth
                        autoHighlight
                        options={staffAvailableTime}
                        value={selectedHour}
                        onChange={handleChangeHours}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: 'new-password',
                            }}
                            label="Available hours"
                            variant="outlined"
                            error={errors.start_time}
                          />
                        )}
                      />
                      { errors.start_time && (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText>
                            Time is required.
                          </FormHelperText>
                        </div>
                      )}
                    </FormControl>
                  )
                  : null}
              </Grid>
              <Grid item xs={12}>
                {renderTextarea('Notes', appointmentItem ? appointmentItem.note_from_creator : appointmentItem, updateAppointmentItem, 'note_from_creator')}
              </Grid>
            </>
          ) : (
            <Box m="0 auto">
              <Box mt="16px">
                <Typography variant="body1">Choose with whom you want to create appointment</Typography>
              </Box>
              <Box display="flex" justifyContent="center" mt="16px">
                <Box>
                  <Button variant="outlined" color="primary" onClick={() => handleChangeWhom('Company')}>
                    Company
                  </Button>
                </Box>
                <Box ml="16px">
                  <Button variant="outlined" color="primary" onClick={() => handleChangeWhom('Individual')}>
                    Individual user
                  </Button>
                </Box>
              </Box>
            </Box>
          ) }
        </TabPanel>
      );
    } else if (role === 'Individual') {
      return (
        <TabPanel key={`${role}${index}`} value={tabIndex} index={index}>
          {renderSelect('Industry', selectedIndustry, chooseIndustry, userIndustries, 'industry_id')}
          {shouldItemRendered(selectedIndustry) && renderSelect('Service', selectedService, chooseService, services)}
          {shouldItemRendered(selectedService) && renderSelect('Customer', selectedCustomer, chooseCustomer, customers)}
          {shouldItemRendered(selectedCustomer) && (
            <Grid item xs={6}>
              {renderInput('Price($)', 'price')}
            </Grid>
          )}
          {shouldItemRendered(selectedCustomer) && (
            <Grid item xs={6}>
              {renderInput('Duration(min)', 'duration')}
            </Grid>
          )}
          <Grid item xs={6}>
            {renderDatetime('Date', appointmentItem ? appointmentItem.date : appointmentItem, updateAppointmentItem, calendar, 'date')}
          </Grid>
          <Grid item xs={6}>
            {renderDatetime('Time', appointmentItem ? appointmentItem.start_time : appointmentItem, updateAppointmentItem, clock, 'start_time')}
          </Grid>
          <Grid item xs={12}>
            {renderTextarea('Notes', appointmentItem ? appointmentItem.note_from_creator : appointmentItem, updateAppointmentItem, 'note_from_creator')}
          </Grid>
        </TabPanel>
      );
    } else if (role === 'Staff') {
      return (
        <TabPanel key={`${role}${index}`} value={tabIndex} index={index}>
          {renderSelect('Company', selectedCompany, chooseCompany, companies)}
          {shouldItemRendered(selectedCompany) && renderSelect('Service', selectedService, chooseService, services)}
          {shouldItemRendered(selectedService) && renderSelect('Customer', selectedCustomer, chooseCustomer, customers)}
          {shouldItemRendered(selectedCustomer) && (
            <Grid item xs={6}>
              {renderInput('Duration(min)', 'duration')}
            </Grid>
          ) }
          {shouldItemRendered(selectedCustomer) && (
            <Grid item xs={6}>
              {renderInput('Price($)', 'price')}
            </Grid>
          ) }
          <Grid item xs={6}>
            {renderDatetime('Date', appointmentItem ? appointmentItem.date : appointmentItem, updateAppointmentItem, calendar, 'date')}
          </Grid>
          <Grid item xs={6}>
            {renderDatetime('Time', appointmentItem ? appointmentItem.start_time : appointmentItem, updateAppointmentItem, clock, 'start_time')}
          </Grid>
          <Grid item xs={12}>
            {renderTextarea('Notes', appointmentItem ? appointmentItem.note_from_creator : appointmentItem, updateAppointmentItem, 'note_from_creator')}
          </Grid>
        </TabPanel>
      );
    } else if (role === 'Owner') {
      return (
        <TabPanel key={`${role}${index}`} value={tabIndex} index={index}>
          {customerOptions && !customerOptions.length
            ? (
              <Box mx="auto" my="0">
                <p>Please invite customers and continue to create your appointment!</p>
              </Box>
            )
            : (
              <>
                {renderSelect('Industry', selectedIndustry, chooseIndustry, industries, 'industry_id')}
                {shouldItemRendered(selectedIndustry) && renderSelect('Company', selectedCompany, chooseCompany, companies)}
                {shouldItemRendered(selectedCompany) && renderSelect('Staff', selectedStaff, chooseStaff, staff)}
                {shouldItemRendered(selectedStaff) && renderSelect('Service', selectedService, chooseService, services)}
                {shouldItemRendered(selectedService) && renderSelect('Customer', selectedCustomer, chooseCustomer, customers)}
                {shouldItemRendered(selectedCustomer) && (
                  <Grid item xs={6}>
                    {renderInput('Price($)', 'price')}
                  </Grid>
                ) }
                {shouldItemRendered(selectedCustomer) && (
                  <>
                    <Grid item xs={6}>
                      {renderInput('Duration(min)', 'duration')}
                    </Grid>
                    <Grid item xs={6}>
                      {renderDatetime('Date', appointmentItem ? appointmentItem.date : appointmentItem, updateAppointmentItem, calendar, 'date')}
                    </Grid>
                    <Grid item xs={6}>
                      {renderDatetime('Time', appointmentItem ? appointmentItem.start_time : appointmentItem, updateAppointmentItem, clock, 'start_time')}
                    </Grid>
                    <Grid item xs={12}>
                      {renderTextarea('Notes', appointmentItem ? appointmentItem.note_from_creator : appointmentItem, updateAppointmentItem, 'note_from_creator')}
                    </Grid>
                  </>
                ) }
              </ >
            )}
        </TabPanel>
      );
    }
  };

  const isTopRolesSectionShown = () => {
    const roles = PermissionsModule.getRoles(userAccount.testPermissions);
    const isLoggedInOwner = (userAccount && userAccount.activeRole.name === 'Owner');
    const isSingleRole = (roles.length === 1);

    return !isLoggedInOwner && !isSingleRole;
  };

  const serviceOptions = staffServices.reduce((akku, row) => {
    const service = [];
    row.subServices.map((item) => {
      service.push({
        type: !service.length ? row.name : null,
        name: item.name,
        duration: item.duration,
        service_id: createAppointmentFor === 'Company' ? item.company_service_id : item.individual_user_industry_service_id,
        price: item.price,
        id: item.id,
      });
    });
    akku = akku.concat(service);
    return akku;
  }, []);

  return (
    <>
      <Dialog
        maxWidth="md"
        open={open}
        onClose={onModalClose}
      >
        <DialogTitle>
          <span className="alert-title">
            <span>
              {createAppointmentFor && roles[tabIndex] === 'Customer' ? (
                <IconButton className={classes.leftBtn}>
                  <KeyboardArrowLeftIcon
                    onClick={revertStateToDefault}
                  />
                </IconButton>

              ) : null}
              <span>
            Appointment
              </span>
            </span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onModalClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          {userAccount && isTopRolesSectionShown() && (
            <Box mb="16px">
              <Tabs
                indicatorColor="primary"
                value={tabIndex}
                onChange={handleTabChange}
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                {roles.map((role, index) => (
                  <Tab key={`${role}${index}`} label={role === 'Individual' ? `As an ${role}` : `As a ${role}`} {...a11yProps(index)} className={classes.tabLabel} />
                ))}
              </Tabs>
            </Box>
          )}
          <Box
            index={tabIndex}
            onChangeIndex={handleTabChange}
            onAppointmentCreated
          >
            {roles.map((role, index) => renderFormFieldsBasedOnRoles(role, index))}
          </Box>
        </DialogContent>
        { ((roles[tabIndex] !== 'Customer' && selectedCustomer) || createAppointmentFor) && (
          <DialogActions>
            <Button size="small" onClick={close}>Cancel</Button>
            { roles[tabIndex] === 'Customer' && createAppointmentFor === 'Company' ? (
              <Button size="small" variant="contained" color="primary" onClick={handleShowPreview}>Preview</Button>
            ) : <Button size="small" variant="contained" color="primary" onClick={createAppointment}>Save</Button>}
          </DialogActions>
        ) }
        {customerOptions && customerOptions.length === 0 && roles[tabIndex] === 'Owner'
          ? (
            <DialogActions>
              <Button size="small" onClick={close}>Cancel</Button>
              <Button size="small" variant="contained" color="primary" onClick={openInviteCustomerModal}>Invite</Button>
            </DialogActions>
          )
          : null }
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

NewAppointment.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onAppointmentCreated: PropTypes.func.isRequired,
  getIndustries: PropTypes.func.isRequired,
  userAccount: PropTypes.object.isRequired,
  industries: PropTypes.array.isRequired,
  getPersonalCompanies: PropTypes.func.isRequired,
  isGetPersonalCompaniesSuccess: PropTypes.bool.isRequired,
  isGetPersonalCompaniesError: PropTypes.bool.isRequired,
  personalCompanies: PropTypes.array.isRequired,
  getPersonalCompaniesErrorMessage: PropTypes.string.isRequired,
  getStaffWithServices: PropTypes.func.isRequired,
  isGetStaffWithServicesSuccess: PropTypes.bool.isRequired,
  isGetStaffWithServicesError: PropTypes.bool.isRequired,
  staffWithServices: PropTypes.array.isRequired,
  getStaffWithServicesErrorMessage: PropTypes.string.isRequired,
  storeAppointment: PropTypes.func.isRequired,
  isStoreAppointmentSuccess: PropTypes.bool.isRequired,
  isStoreAppointmentError: PropTypes.bool.isRequired,
  newAppointment: PropTypes.object.isRequired,
  storeAppointmentErrors: PropTypes.object.isRequired,
  getPersonalIndustries: PropTypes.func.isRequired,
  isGetPersonalIndividualUserIndustriesSuccess: PropTypes.bool.isRequired,
  isGetPersonalIndividualUserIndustriesError: PropTypes.bool.isRequired,
  personalIndividualUserIndustries: PropTypes.array.isRequired,
  getPersonalIndividualUserIndustriesErrorMessage: PropTypes.string.isRequired,
  getUserIndustries: PropTypes.func.isRequired,
  isGetUserIndividualIndustriesSuccess: PropTypes.bool.isRequired,
  isGetUserIndividualIndustriesError: PropTypes.bool.isRequired,
  userIndividualIndustries: PropTypes.array.isRequired,
  getUserIndividualIndustriesErrorMessage: PropTypes.string.isRequired,
  getIndividualCustomers: PropTypes.func.isRequired,
  isGetAllIndividualCustomersSuccess: PropTypes.bool.isRequired,
  isGetAllIndividualCustomersError: PropTypes.bool.isRequired,
  allIndividualCustomers: PropTypes.array.isRequired,
  getCompanies: PropTypes.func.isRequired,
  sendAppointmentItem: PropTypes.func.isRequired,
  sendSaveAppointment: PropTypes.func.isRequired,
  calendarAppointmentItem: PropTypes.object.isRequired,
  openInviteCustomerModal: PropTypes.func.isRequired,
  // Get All Companies Props
  getAllCompanies: PropTypes.func.isRequired,
  isGetAllCompaniesSuccess: PropTypes.bool.isRequired,
  isGetAllCompaniesError: PropTypes.bool.isRequired,
  allCompanies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  getAllCompaniesErrorMessage: PropTypes.string.isRequired,
  // Get all customers
  getCompanyCustomers: PropTypes.func.isRequired,
  isGetCustomersSuccess: PropTypes.bool.isRequired,
  isGetCustomersError: PropTypes.bool.isRequired,
  companyCustomers: PropTypes.array.isRequired,
  // Get all customers
  validAppointment: PropTypes.func.isRequired,
  isValidAppointmentError: PropTypes.bool.isRequired,
  isValidAppointmentSuccess: PropTypes.bool.isRequired,
  validAppointmentErrors: PropTypes.object.isRequired,
  customerOptions: PropTypes.array.isRequired,
  getPersonalAppointments: PropTypes.func.isRequired,
  getGeneralAppointments: PropTypes.func.isRequired,
  // Get Staff Available Hours
  getStaffAvailableHours: PropTypes.func.isRequired,
  isGetStaffAvailableHoursSuccess: PropTypes.bool.isRequired,
  isGetStaffAvailableHoursError: PropTypes.bool.isRequired,
  availableHours: PropTypes.array.isRequired,
  // Get services with sub services
  getServicesWithSubServices: PropTypes.func.isRequired,
  isGetCompanyServicesWithSubServicesSuccess: PropTypes.bool.isRequired,
  servicesWithSubServicesByCompanyId: PropTypes.object.isRequired,
  // Individual User Services
  getServicesByIndividualUserId: PropTypes.func.isRequired,
  isGetIndividualServicesWithSubServicesSuccess: PropTypes.bool.isRequired,
  isGetIndividualServicesWithSubServicesError: PropTypes.bool.isRequired,
  servicesWithSubServicesByIndividualId: PropTypes.array.isRequired,
  getServicesByIndividualUserIdErrorMessage: PropTypes.string.isRequired,
  // Get Individual User Services
  getIndividualAvailableHours: PropTypes.func.isRequired,
  isIndividualAvailableHoursSuccess: PropTypes.bool.isRequired,
  individualAvailableHours: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  // Get All Companies
  getAllCompaniesErrorMessage: state.company.getAllCompaniesErrorMessage,
  isGetAllCompaniesSuccess: state.company.isGetAllCompaniesSuccess,
  isGetAllCompaniesError: state.company.isGetAllCompaniesError,
  allCompanies: state.company.allCompanies,
  // Valid Appointment state
  isValidAppointmentSuccess: state.appointment.isValidAppointmentSuccess,
  isValidAppointmentError: state.appointment.isValidAppointmentError,
  validAppointment: state.appointment.validAppointment,
  validAppointmentErrors: state.appointment.validAppointmentErrors,
  // Get customers
  isGetCustomersSuccess: state.customers.isGetCustomersSuccess,
  isGetCustomersError: state.customers.isGetCustomersError,
  companyCustomers: state.customers.companyCustomers,
  // Delete appointment
  isDeleteAppointmentSuccess: state.appointment.isDeleteAppointmentSuccess,
  isDeleteAppointmentError: state.appointment.isDeleteAppointmentError,
  deleteAppointmentErrorMessage: state.appointment.deleteAppointmentErrorMessage,
  // All Appointments
  generalAppointments: PropTypes.array.isRequired,
  userAccount: state.account.userAccount,
  industries: state.industry.industries,
  isGetPersonalCompaniesSuccess: state.company.isGetPersonalCompaniesSuccess,
  isGetPersonalCompaniesError: state.company.isGetPersonalCompaniesError,
  personalCompanies: state.company.personalCompanies,
  getPersonalCompaniesErrorMessage: state.company.getPersonalCompaniesErrorMessage,
  isGetStaffWithServicesSuccess: state.company.isGetStaffWithServicesSuccess,
  isGetStaffWithServicesError: state.company.isGetStaffWithServicesError,
  staffWithServices: state.company.staffWithServices,
  getStaffWithServicesErrorMessage: state.company.getStaffWithServicesErrorMessage,
  isStoreAppointmentSuccess: state.appointment.isStoreAppointmentSuccess,
  isStoreAppointmentError: state.appointment.isStoreAppointmentError,
  newAppointment: state.appointment.newAppointment,
  storeAppointmentErrorMessage: state.appointment.storeAppointmentErrorMessage,
  storeAppointmentErrors: state.appointment.storeAppointmentErrors,
  isGetPersonalIndividualUserIndustriesSuccess: state.individualUser.isGetPersonalIndividualUserIndustriesSuccess,
  isGetPersonalIndividualUserIndustriesError: state.individualUser.isGetPersonalIndividualUserIndustriesError,
  personalIndividualUserIndustries: state.individualUser.personalIndividualUserIndustries,
  getPersonalIndividualUserIndustriesErrorMessage: state.individualUser.getPersonalIndividualUserIndustriesErrorMessage,
  isGetUserIndividualIndustriesSuccess: state.individualUser.isGetUserIndividualIndustriesSuccess,
  isGetUserIndividualIndustriesError: state.individualUser.isGetUserIndividualIndustriesError,
  userIndividualIndustries: state.individualUser.userIndividualIndustries,
  getUserIndividualIndustriesErrorMessage: state.individualUser.getUserIndividualIndustriesErrorMessage,
  isGetAllIndividualCustomersSuccess: state.customers.isGetAllIndividualCustomersSuccess,
  isGetAllIndividualCustomersError: state.customers.isGetAllIndividualCustomersError,
  allIndividualCustomers: state.customers.allIndividualCustomers,
  // Get Staff Available Hours
  isGetStaffAvailableHoursSuccess: state.customers.isGetStaffAvailableHoursSuccess,
  isGetStaffAvailableHoursError: state.customers.isGetStaffAvailableHoursError,
  availableHours: state.customers.availableHours,
  // Sub Services
  servicesWithSubServicesByCompanyId: state.service.servicesWithSubServicesByCompanyId,
  isGetCompanyServicesWithSubServicesSuccess: state.service.isGetCompanyServicesWithSubServicesSuccess,
  // Individual User Services
  isGetIndividualServicesWithSubServicesSuccess: state.service.isGetIndividualServicesWithSubServicesSuccess,
  isGetIndividualServicesWithSubServicesError: state.service.isGetIndividualServicesWithSubServicesError,
  servicesWithSubServicesByIndividualId: state.service.servicesWithSubServicesByIndividualId,
  getServicesByIndividualUserIdErrorMessage: state.service.getServicesByIndividualUserIdErrorMessage,
  // Get Individual User Available Hours
  isIndividualAvailableHoursSuccess: state.individualUser.isIndividualAvailableHoursSuccess,
  individualAvailableHours: state.individualUser.individualAvailableHours,
});

function mapDispatchToProps(dispatch) {
  return {
    getAllCompanies: () => dispatch(getAllCompaniesRequest()),
    getIndustries: (data) => dispatch(getIndustriesRequest(data)),
    getCompanies: (data) => dispatch(getAllCompaniesRequest(data)),
    validAppointment: (data) => dispatch(validAppointmentRequest(data)),
    storeAppointment: (data) => dispatch(storeAppointmentRequest(data)),
    getGeneralAppointments: () => dispatch(getGeneralAppointmentsRequest()),
    getUserIndustries: () => dispatch(getUserIndividualIndustriesRequest()),
    getIndividualCustomers: () => dispatch(getIndividualCustomersRequest()),
    getCompanyCustomers: (data) => dispatch(getCompanyCustomersRequest(data)),
    getPersonalCompanies: (data) => dispatch(getPersonalCompaniesRequest(data)),
    getStaffWithServices: (data) => dispatch(getStaffWithServicesRequest(data)),
    getPersonalAppointments: (data) => dispatch(getGeneralAppointmentsRequest(data)),
    getPersonalIndustries: (data) => dispatch(getPersonalIndividualUserIndustriesRequest(data)),
    getStaffAvailableHours: (data) => dispatch(getStaffAvailableHoursRequest(data)),
    getIndividualAvailableHours: (data) => dispatch(getIndividualAvailableHoursRequest(data)),
    getServicesWithSubServices: (data) => dispatch(getCompanyServicesWithSubServicesRequest(data)),
    getServicesByIndividualUserId: (data) => dispatch(getIndividualServicesWithSubServicesRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewAppointment);
