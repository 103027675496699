import { createAction } from 'redux-actions';

export const getMessagesRequest = createAction('GET_MESSAGES_REQUEST');
export const getMessagesSuccess = createAction('GET_MESSAGES_SUCCESS');
export const getMessagesError = createAction('GET_MESSAGES_ERROR');
export const storeMessageRequest = createAction('STORE_MESSAGE_REQUEST');
export const storeMessageSuccess = createAction('STORE_MESSAGE_SUCCESS');
export const storeMessageError = createAction('STORE_MESSAGE_ERROR');
export const makeMessagesReadRequest = createAction('MAKE_MESSAGES_READ_REQUEST');
export const makeMessagesReadSuccess = createAction('MAKE_MESSAGES_READ_SUCCESS');
export const makeMessagesReadError = createAction('MAKE_MESSAGES_READ_ERROR');
export const updateMessageRequest = createAction('UPDATE_MESSAGE_REQUEST');
export const updateMessageSuccess = createAction('UPDATE_MESSAGE_SUCCESS');
export const updateMessageError = createAction('UPDATE_MESSAGE_ERROR');
export const deleteMessageRequest = createAction('DELETE_MESSAGE_REQUEST');
export const deleteMessageSuccess = createAction('DELETE_MESSAGE_SUCCESS');
export const deleteMessageError = createAction('DELETE_MESSAGE_ERROR');
