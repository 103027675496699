import React from 'react';
import MaterialPagination from '@material-ui/lab/Pagination';

function Pagination(data) {
  const {
    page, pages, handlePageChanged,
  } = data;
  const handlePageChange = (event, newPage) => {
    handlePageChanged(newPage);
  };

  return (
    <div className="pagination">
      <MaterialPagination page={page + 1} count={pages} color="primary" onChange={handlePageChange} />
    </div>
  );
}

export default Pagination;
