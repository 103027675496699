import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import i18n from 'i18next';
import { deleteIndividualUserServiceRequest } from '../../../redux/service/actions';
import usePrevious from '../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../Modules/SnackbarToast';

function DeleteServiceModal(props) {
  const dispatch = useDispatch();
  const {
    isDeleteIndividualUserServiceSuccess,
    isDeleteIndividualUserServiceError,
    deleteIndividualUserServiceErrorMessage,
  } = useSelector((state) => state.service);
  const {
    open,
    onClose,
    individualUserId,
    selectedIndividualUserServiceId,
    setSelectedIndividualUserServiceId,
    serviceIds,
    setServiceIds,
    selectedIndividualParentId,
    setSelectedIndividualParentId,
    individualUserServices,
    setIndividualUserServices,
    filteredAppointments,
  } = props;

  // Get some props previous values
  const prevIsDeleteIndividualUserServiceSuccess = usePrevious(isDeleteIndividualUserServiceSuccess);
  const prevIsDeleteIndividualUserServiceError = usePrevious(isDeleteIndividualUserServiceError);

  const [checked, setChecked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Handle Delete Company Service Success
  useEffect(() => {
    if (prevIsDeleteIndividualUserServiceSuccess === false && isDeleteIndividualUserServiceSuccess) {
      const individualUserServicesCopy = [...individualUserServices];
      const individualUserService = individualUserServices.find((service) => service.id === selectedIndividualParentId);
      const individualUserServiceIndex = individualUserServices.findIndex((service) => service.id === selectedIndividualParentId);
      if (serviceIds.length === 0) {
        const subServices = JSON.parse(individualUserService.subServices);
        const subServiceIndex = subServices.findIndex((service) => service.individual_user_industry_service_id === selectedIndividualUserServiceId);
        subServices.splice(subServiceIndex, 1);
        individualUserService.subServices = JSON.stringify(subServices);
        if (JSON.parse(individualUserService.subServices).length === 0) {
          individualUserServicesCopy.splice(individualUserServiceIndex, 1);
        } else {
          individualUserServicesCopy[individualUserServiceIndex] = individualUserService;
        }
      } else {
        individualUserServicesCopy.splice(individualUserServiceIndex, 1);
      }
      setIndividualUserServices(individualUserServicesCopy);
      setSelectedIndividualUserServiceId('');
      setSelectedIndividualParentId('');
      setServiceIds([]);
      onClose();
      snackBarAlert(true, i18n.t('ServiceDeleted'), 'success');
    }
  }, [isDeleteIndividualUserServiceSuccess]);

  // Handle Delete Company Service Error
  useEffect(() => {
    if (prevIsDeleteIndividualUserServiceError === false && isDeleteIndividualUserServiceError) {
      snackBarAlert(true, deleteIndividualUserServiceErrorMessage, 'error');
    }
  }, [isDeleteIndividualUserServiceError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleDeleteService = () => {
    if (serviceIds.length > 0) {
      dispatch(deleteIndividualUserServiceRequest({
        individual_user_industry_id: individualUserId,
        ids: serviceIds,
        checked,
      }));
    } else {
      dispatch(deleteIndividualUserServiceRequest({
        individual_user_industry_id: individualUserId,
        id: selectedIndividualUserServiceId,
        checked,
      }));
    }
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span className="alert-title">
            <span>Delete Service</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {filteredAppointments.length > 0
              ? (
                <>
                  <Box>
                    <Box mb={3} display="flex" alignItems="center" justifyContent="center">
                      <Box>
                        <WarningIcon />
                      </Box>
                      <Box>
                        <b>This service has { filteredAppointments.length } upcoming {filteredAppointments.length >= 2 ? 'appointments' : 'appointment'}</b>
                      </Box>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={<Checkbox color="primary" onChange={handleCheckboxChange} checked={checked} />}
                        label="Cancel all upcoming appointments with this service"
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {serviceIds.length > 0 ? (
                    <Box>
                      <Typography gutterBottom>
                      Are you sure?
                      </Typography>
                      <Typography gutterBottom>
                      If you delete this category, all services included in it will be deleted too.
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography gutterBottom>
                      Are you sure?
                      </Typography>
                      <Typography gutterBottom>
                      You and your customers will not be able to create appointments with this service anymore
                      </Typography>
                    </Box>
                  )}
                </>
              )}

          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleDeleteService}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  );
}

DeleteServiceModal.propTypes = {
  // Parent Props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  individualUserId: PropTypes.number.isRequired,
  selectedIndividualUserServiceId: PropTypes.number.isRequired,
  setSelectedIndividualUserServiceId: PropTypes.func.isRequired,
  serviceIds: PropTypes.array.isRequired,
  setServiceIds: PropTypes.func.isRequired,
  selectedIndividualParentId: PropTypes.number.isRequired,
  setSelectedIndividualParentId: PropTypes.func.isRequired,
  individualUserServices: PropTypes.array.isRequired,
  setIndividualUserServices: PropTypes.func.isRequired,
  filteredAppointments: PropTypes.array.isRequired,
};

export default DeleteServiceModal;
