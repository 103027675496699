import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  getAllCompanyCustomersRequest,
  getCustomerUpcomingAppointmentsRequest,
} from 'redux/customer/actions';
import EnhancedTableHead from './header';
import Body from './body';
import CustomerContext from '../customerContext';
import TableLoading from '../../../../../../Components/General/TableLoading.';
import noCustomerImage from '../../../../../../assets/noDataImages/no-customers.svg';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import '../customers.scss';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  paper: {
    overflow: 'auto',
    height: 'calc(100vh - 181px)',
    borderRadius: 'unset',
    borderTop: '1px solid #E3E5E6',
    boxShadow: 'unset',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  headerField: {
    color: '#393939',
    fontWeight: 600,
  },
  bodyField: {
    color: '#393939',
    fontFamily: 'Nunito',
    fontWeight: 500,
  },
}));

export default function EnhancedTable({
  rows, headCells, rowsPerPage, setRowsPerPage, loading,
}) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const { t } = useTranslation();

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('full_name');
  const [page, setPage] = useState(0);

  const { isDeleteCustomerSuccess } = useSelector((state) => state.customers);

  const prevIsDeleteCustomerSuccess = usePrevious(isDeleteCustomerSuccess);

  const {
    setIsUpdate, setSelected, selected, setSelectedCustomer, setOpenEditDrawer, setSelectedIndex, deletedCustomerId, setDeletedCustomerId, customersCount,
  } = useContext(CustomerContext);

  useEffect(() => {
    if (prevIsDeleteCustomerSuccess === false && isDeleteCustomerSuccess) {
      if (rows.length === selected.length) {
        setPage(0);
        dispatch(getAllCompanyCustomersRequest({ limit: rowsPerPage, page: 1 }));
      }
    }
  }, [isDeleteCustomerSuccess, rows]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
    setDeletedCustomerId([]);
  };

  const onRowClick = (item) => {
    setSelectedCustomer(item);
    setOpenEditDrawer(true);
    setIsUpdate(false);
    setSelectedIndex(0);
    dispatch(getCustomerUpcomingAppointmentsRequest({ id: item.id, page: 1 }));
  };

  const handleClick = (event, name) => {
    event.stopPropagation();
    let selectedIndex;
    let checkedItems;
    let newSelected = [];

    if (deletedCustomerId.length) {
      selectedIndex = deletedCustomerId.indexOf(name);
      checkedItems = deletedCustomerId;
    } else {
      selectedIndex = selected.indexOf(name);
      checkedItems = selected;
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(checkedItems, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(checkedItems.slice(1));
    } else if (selectedIndex === checkedItems.length - 1) {
      newSelected = newSelected.concat(checkedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        checkedItems.slice(0, selectedIndex),
        checkedItems.slice(selectedIndex + 1),
      );
    }

    if (deletedCustomerId.length) {
      setDeletedCustomerId(newSelected);
    } else {
      setSelected(newSelected);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    dispatch(getAllCompanyCustomersRequest({ limit: rowsPerPage, page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    const limit = parseInt(event.target.value, 10);
    setRowsPerPage(limit);
    dispatch(getAllCompanyCustomersRequest({ limit, page: 1 }));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className="data-table"
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={deletedCustomerId.length || selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
            />
            {!loading ? (
              rows.length ? (
                <Body
                  rows={rows}
                  handleClick={handleClick}
                  page={page}
                  selected={selected}
                  deletedCustomerId={deletedCustomerId}
                  order={order}
                  orderBy={orderBy}
                  rowsPerPage={rowsPerPage}
                  onRowClick={onRowClick}
                />
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={10}>
                      <div className="no-data">
                        <img src={noCustomerImage} alt="no-customer" />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )
            ) : (
              <TableBody>
                <TableLoading count={10} />
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
      {customersCount > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={customersCount}
          labelRowsPerPage={t('RowsPerPage')}
          labelDisplayedRows={({ count, from, to }) => `${from}-${to}/${count}`}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
        />
      )}
    </div>
  );
}

EnhancedTable.propTypes = {
  rows: PropTypes.array.isRequired,
  headCells: PropTypes.array.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
