import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import companyLogo from '../../../assets/img/company.svg';
import avatar from '../../../assets/img/avatar.svg';

function chatContent(data) {
  const {
    selectedAppointment,
    userAccount,
    handleEditMessage,
    showDeleteMessagePopup,
  } = data;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;

  return selectedAppointment ? (
    <div className="messages" id="messages-content">
      { selectedAppointment.messages && selectedAppointment.messages.length ? (
        selectedAppointment.messages.map((message) => {
          if (message.appointment_event_id) {
            const keys = message.appointment_event.translate_name.split('.');
            return (
              <Box
                key={message.id}
                display="flex"
                justifyContent="center"
                alignItems="center"
                mb={3}
              >
                <div className="staff-image">
                  <img src={avatar} alt="avatar" />
                </div>
                <div className="event-text">
                  { `${selectedAppointment.staff.first_name} ${selectedAppointment.staff.last_name} ${this.eventMessages[keys[0]][keys[1]]}`}
                </div>
              </Box>
            );
          } else if (parseInt(message.from_id) === userAccount.id) {
            return (
              <Box
                key={message.id}
                display="flex"
                alignItems="center"
                className="message-item right"
              >
                <Box display="flex" width="100%">
                  <Box display="flex" width="100%" alignItems="center" justifyContent="flex-end" alignSelf="flex-start">
                    <div
                      className={`${parseInt(message.from_id) === userAccount.id ? 'message-content' : 'content-message'}`}
                    >
                      <Box display="flex">
                        <Box alignSelf="flex-start" className="message-detail">
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            elevation={1}
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <Link
                              className="card-box-item"
                              to="/"
                              onClick={(e) => handleEditMessage(e, message.id)}
                            >
                              <MenuItem>
                                Edit
                              </MenuItem>
                            </Link>
                            <Link
                              className="card-box-item"
                              to="/"
                              onClick={(e) => showDeleteMessagePopup(e, message.id)}
                            >
                              <MenuItem>
                                Delete
                              </MenuItem>
                            </Link>
                          </Menu>
                        </Box>
                        <Box className="text">
                          { message.text }
                        </Box>
                        <Box className="staff-image">
                          <img
                            src={avatar}
                            alt="avatar"
                          />
                        </Box>
                      </Box>
                      <Box className="time">
                        { moment(message.created_at).format('HH:mm') }
                      </Box>
                    </div>
                  </Box>
                </Box>
              </Box>
            );
          } else if (selectedAppointment.company_id && (parseInt(message.from_id) === selectedAppointment.company.user_id && userAccount.activeRole.name === 'Staff')) {
            return (
              <Box
                key={message.id}
                display="flex"
                alignItems="center"
                className="message-item right"
              >
                <div
                  className={`${parseInt(message.from_id) === userAccount.id ? 'message-content' : 'content-message'}`}
                >
                  <Box display="flex">
                    <Box className="text">
                      { message.text }
                    </Box>
                    <Box className="staff-image">
                      <img src={selectedAppointment.company.logo || companyLogo} alt="companyLogo" />
                    </Box>
                  </Box>
                  <Box className="time">
                    { moment(message.created_at).format('HH:mm') }
                  </Box>
                </div>
              </Box>
            );
          } else if (selectedAppointment.individual_user_industry && parseInt(message.from_id) === selectedAppointment.individual_user_industry.user_id && userAccount.activeRole.name === 'Individual') {
            return (
              <Box
                key={message.id}
                display="flex"
                alignItems="center"
                className="message-item right"
              >
                <Box display="flex" width="100%" flexDirection="column">
                  <Box display="flex" width="100%" alignItems="center" justifyContent="flex-end">
                    <div
                      className={`${parseInt(message.from_id) === userAccount.id ? 'message-content' : 'content-message'}`}
                    >
                      <Box display="flex">
                        <Box alignSelf="flex-start" className="message-detail">
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            elevation={1}
                            anchorEl={anchorEl}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <Link
                              className="card-box-item"
                              to="/"
                              onClick={(e) => handleEditMessage(e, message.id)}
                            >
                              <MenuItem>
                                Edit
                              </MenuItem>
                            </Link>
                            <Link
                              className="card-box-item"
                              to="/"
                              onClick={(e) => showDeleteMessagePopup(e, message.id)}
                            >
                              <MenuItem>
                                Delete
                              </MenuItem>
                            </Link>
                          </Menu>
                        </Box>
                        <Box className="text">
                          { message.text }
                        </Box>
                        <Box className="staff-image">
                          <img src={(selectedAppointment.individual_user_industry_id) ? (selectedAppointment.individual_user_industry.avatar) : companyLogo} alt="avatar" />
                        </Box>
                      </Box>
                      <Box className="time">
                        { moment(message.created_at).format('HH:mm') }
                      </Box>
                    </div>
                  </Box>
                </Box>
              </Box>
            );
          } else if (selectedAppointment.company_id && parseInt(message.from_id) === selectedAppointment.staff.id && userAccount.activeRole.name === 'Owner') {
            return (
              <Box
                key={message.id}
                display="flex"
                alignItems="center"
                className="message-item right"
              >
                <Box display="flex" width="100%" flexDirection="column">
                  <Box display="flex" width="100%" alignItems="center" justifyContent="flex-end">
                    <div
                      className={`${parseInt(message.from_id) === userAccount.id ? 'message-content' : 'content-message'}`}
                    >
                      <Box display="flex">
                        <Box alignSelf="flex-start" className="message-detail">
                          <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            elevation={1}
                            keepMounted
                            open={open}
                            onClose={handleClose}
                            PaperProps={{
                              style: {
                                maxHeight: ITEM_HEIGHT * 4.5,
                                width: '20ch',
                              },
                            }}
                          >
                            <Link
                              className="card-box-item"
                              to="/"
                              onClick={(e) => handleEditMessage(e, message.id)}
                            >
                              <MenuItem>
                                Edit
                              </MenuItem>
                            </Link>
                            <Link
                              className="card-box-item"
                              to="/"
                              onClick={(e) => showDeleteMessagePopup(e, message.id)}
                            >
                              <MenuItem>
                                Delete
                              </MenuItem>
                            </Link>
                          </Menu>
                        </Box>
                        <Box className="text">
                          { message.text }
                        </Box>
                        <Box className="staff-image">
                          <img src={selectedAppointment.staff.avatar || avatar} alt="avatar" />
                        </Box>
                      </Box>
                      <Box className="time">
                        { moment(message.created_at).format('HH:mm') }
                      </Box>
                    </div>
                  </Box>
                </Box>
              </Box>
            );
          } else {
            return (
              <Box
                key={message.id}
                display="flex"
                alignItems="center"
                className="message-item left"
              >
                <div
                  className={`${parseInt(message.from_id) === userAccount.id ? 'message-content' : 'content-message'}`}
                >
                  <Box display="flex">
                    <Box className="staff-image">
                      <img
                        src={(selectedAppointment.company_id) ? (selectedAppointment.company.logo || companyLogo) : avatar}
                        alt="companyLogo"
                      />
                    </Box>
                    <Box className="text">
                      { message.text }
                    </Box>
                  </Box>
                  <Box className="time">
                    { moment(message.created_at).format('HH:mm') }
                  </Box>
                </div>
              </Box>
            );
          }
        })
      ) : (
        <Box display="flex" justifyContent="center">
          No Message
        </Box>
      ) }
    </div>
  ) : null;
}

export default chatContent;
