import React from 'react';
import { Box, Typography } from '@material-ui/core';
import MessagesObject from '../../../assets/img/no-message.svg';

function Index() {
  return (
    <Box display="flex" width="100%" justifyContent="center" alignItems="center" flexDirection="column" className="main-message-content box-general">
      <Box>
        <img src={MessagesObject} alt="noMessage" />
      </Box>
      <Typography component="div">
        <Box fontWeight="500">
          <Typography variant="h6" color="secondary">
            No messages yet
          </Typography>
        </Box>
      </Typography>
    </Box>
  );
}

export default Index;
