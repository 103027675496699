import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  Paper,
  Button,
  Grid,
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableContainer,
  IconButton,
} from '@material-ui/core';
import moment from 'moment';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import CloseDayModal from '../../../../../../Modals/Owner/WorkingSchedule/closeDay';
import DeleteCloseDayModal from '../../../../../../Modals/Owner/WorkingSchedule/deleteCloseDay';
import SpecialDay from '../../../../../../Modals/Owner/WorkingSchedule/specialDay';
import DeleteSpecialDayModal from '../../../../../../Modals/Owner/WorkingSchedule/deleteSpecialDay';
import splitSpaces from '../../../../../../Modules/splitSpaces';
import WorkingScheduleLoading from '../../../../../../Components/Loading/workingScheduleLoading';

function WorkingSchedule(props) {
  const { t } = useTranslation();

  const {
    schedule,
    handleUpdateCompanyState,
    handleDeleteCompanyState,
  } = props;

  const [closeDays, setCloseDays] = useState([]);
  const [specialDays, setSpecialDays] = useState([]);
  const [isCloseDayModalShown, setIsCloseDayModalShown] = useState(false);
  const [isSpecialDayModalShown, setIsSpecialDayModalShown] = useState(false);
  const [isSpecialDayDeleteModalShown, setIsSpecialDayDeleteModalShown] = useState(false);
  const [isDeleteCloseDayModalShown, setIsDeleteCLoseDayModalShown] = useState(0);
  const [closeDay, setCloseDay] = useState({});
  const [specialDay, setSpecialDay] = useState({});
  const [selectedCloseDayId, setSelectedCloseDayId] = useState(0);
  const [selectedSpecialDayId, setSelectedSpecialDayId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  // Get day offs, specific days and close days
  useEffect(() => {
    if (Object.keys(schedule).length > 0) {
      setLoading(true);
      setButtonLoading(true);
      setCloseDays(schedule.closeDays);
      setSpecialDays(schedule.specialDays);
    }
  }, [schedule]);

  const handleOpenCloseDayModal = () => {
    setCloseDay({});
    setIsCloseDayModalShown(true);
  };

  const handleEditCloseDay = (closeDay) => {
    setCloseDay(closeDay);
    setIsCloseDayModalShown(true);
  };

  const handleDeleteCloseDay = (closeDayId) => {
    setSelectedCloseDayId(closeDayId);
    setIsDeleteCLoseDayModalShown(true);
  };

  const handleOpenSpecialDayModal = () => {
    setSpecialDay({});
    setIsSpecialDayModalShown(true);
  };

  const handleEditSpecialDay = (specialDay) => {
    setSpecialDay(specialDay);
    setIsSpecialDayModalShown(true);
  };

  const handleDeleteSpecialDay = (specialDayId) => {
    setSelectedSpecialDayId(specialDayId);
    setIsSpecialDayDeleteModalShown(true);
  };

  const description = (description) => {
    if (splitSpaces(description) !== ' ' && description !== '') {
      if (description.length > 30) {
        return `${description.substring(0, 30)}...`;
      } else {
        return description;
      }
    } else {
      return '-';
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Grid container xs={12} spacing={2} id="working-schedule-content">
            <Grid className="company-staff-date" spacing={2} container xs={12}>
              <Grid border="none" id="closed-days" item xs={6}>
                <Grid container className="company-box" justify="space-between">
                  <Grid>
                    <Typography variant="h6" id="tableTitle" component="div">
                      {i18n.t('ClosedDays')}
                    </Typography>
                  </Grid>
                  <Grid className="closed-days-button">
                    <Paper style={{ boxShadow: 'none' }} square>
                      <Box display="flex" flexWrap="wrap" width="100%" alignItems="center">

                        <Box>
                          <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleOpenCloseDayModal}
                          >
                            {t('AddClosedDays')}
                          </Button>
                        </Box>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="companies menu-item">
                      <Box boxShadow={0}>
                        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">{i18n.t('TableHeader.Date')}</TableCell>
                                <TableCell align="center">{i18n.t('TableHeader.Description')}</TableCell>
                                <TableCell align="center">{i18n.t('TableHeader.Actions')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {closeDays.map((closeDay) => (
                                <TableRow>
                                  <TableCell align="center">
                                    { closeDay.start_date === closeDay.end_date ? `${moment(closeDay.start_date).format('DD.MM.YYYY')}` : `${moment(closeDay.start_date).format('DD')} - ${moment(closeDay.end_date).format('DD.MM.YYYY')}` }
                                  </TableCell>
                                  <TableCell align="center">
                                    { description(closeDay.description) }
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton size="small">
                                      <EditIcon color="secondary" fontSize="small" onClick={() => handleEditCloseDay(closeDay)} />
                                    </IconButton>
                                    <IconButton size="small">
                                      <DeleteOutlineIcon color="secondary" fontSize="small" onClick={() => handleDeleteCloseDay(closeDay.id)} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid border="none" id="specific-days" item xs={6}>
                <Grid container className="company-box" justify="space-between">
                  <Grid>
                    <Typography variant="h6" id="tableTitle" component="div">
                      {i18n.t('SpecificDaysTitle')}
                    </Typography>
                  </Grid>
                  <Grid className="closed-days-button">
                    <Paper style={{ boxShadow: 'none' }} square>
                      <Box>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleOpenSpecialDayModal}
                        >
                          {i18n.t('AddSpecificWorkDays')}
                        </Button>
                      </Box>
                    </Paper>
                  </Grid>
                  <Grid item xs={12}>
                    <div className="companies menu-item">
                      <Box boxShadow={0}>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell align="center">{i18n.t('TableHeader.Date')}</TableCell>
                                <TableCell align="center">{i18n.t('TableHeader.Time')}</TableCell>
                                <TableCell align="center">{i18n.t('TableHeader.BreakTime')}</TableCell>
                                <TableCell align="center">{i18n.t('TableHeader.Description')}</TableCell>
                                <TableCell align="center">{i18n.t('TableHeader.Actions')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {specialDays.map((specialDay) => (
                                <TableRow>
                                  <TableCell align="center">
                                    { specialDay.start_date === specialDay.end_date ? `${moment(specialDay.start_date).format('DD.MM.YYYY')}` : `${moment(specialDay.start_date).format('DD')} - ${moment(specialDay.end_date).format('DD.MM.YYYY')}` }
                                  </TableCell>
                                  <TableCell align="center">
                                    { moment(`${specialDay.start_date} ${specialDay.start_time}`).format('H:mm') } - { moment(`${specialDay.end_date} ${specialDay.end_time}`).format('H:mm') }
                                  </TableCell>
                                  <TableCell align="center">
                                    {specialDay.break_start_time && specialDay.break_end_time
                                      ? (
                                        <>
                                          { moment(`${specialDay.start_date} ${specialDay.break_start_time}`).format('H:mm') } - { moment(`${specialDay.end_date} ${specialDay.break_end_time}`).format('H:mm') }
                                        </>
                                      ) : '-'}
                                  </TableCell>
                                  <TableCell align="center">
                                    { description(specialDay.description) }
                                  </TableCell>
                                  <TableCell align="center">
                                    <IconButton size="small">
                                      <EditIcon color="secondary" fontSize="small" onClick={() => handleEditSpecialDay(specialDay)} />
                                    </IconButton>
                                    <IconButton size="small">
                                      <DeleteOutlineIcon color="secondary" fontSize="small" onClick={() => handleDeleteSpecialDay(specialDay.id)} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <CloseDayModal
            open={isCloseDayModalShown}
            onClose={() => setIsCloseDayModalShown(false)}
            closeDay={closeDay}
            allCloseDays={closeDays}
            setAllCloseDays={setCloseDays}
            handleUpdateCompanyState={handleUpdateCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <DeleteCloseDayModal
            open={isDeleteCloseDayModalShown}
            onClose={() => setIsDeleteCLoseDayModalShown(false)}
            closeDayId={selectedCloseDayId}
            allCloseDays={closeDays}
            setAllCloseDays={setCloseDays}
            handleDeleteCompanyState={handleDeleteCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <SpecialDay
            open={isSpecialDayModalShown}
            onClose={() => setIsSpecialDayModalShown(false)}
            specialDay={specialDay}
            allSpecialDays={specialDays}
            setAllSpecialDays={setSpecialDays}
            handleUpdateCompanyState={handleUpdateCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />

          <DeleteSpecialDayModal
            open={isSpecialDayDeleteModalShown}
            onClose={() => setIsSpecialDayDeleteModalShown(false)}
            specialDayId={selectedSpecialDayId}
            allSpecialDays={specialDays}
            setAllSpecialDays={setSpecialDays}
            handleDeleteCompanyState={handleDeleteCompanyState}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />
        </>
      ) : <WorkingScheduleLoading />}
    </>
  );
}

WorkingSchedule.propTypes = {
  schedule: PropTypes.object.isRequired,
  handleUpdateCompanyState: PropTypes.func.isRequired,
  handleDeleteCompanyState: PropTypes.func.isRequired,
};

export default WorkingSchedule;
