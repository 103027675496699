import { handleActions } from 'redux-actions';
import {
  storeCompanyRequest,
  storeCompanySuccess,
  storeCompanyError,
  storeGalleryCompanyRequest,
  storeGalleryCompanySuccess,
  storeGalleryCompanyError,
  deleteGalleryCompanyRequest,
  deleteGalleryCompanySuccess,
  deleteGalleryCompanyError,
  getOwnerCompaniesRequest,
  getOwnerCompaniesSuccess,
  getOwnerCompaniesError,
  updateCompanyRequest,
  updateCompanySuccess,
  updateCompanyError,
  getAllCompaniesRequest,
  getAllCompaniesSuccess,
  getAllCompaniesError,
  getCompanyByIdRequest,
  getCompanyByIdSuccess,
  getCompanyByIdError,
  getCompanyByIdForCalendarRequest,
  getCompanyByIdForCalendarSuccess,
  getCompanyByIdForCalendarError,
  getCleanCompanyByIdRequest,
  getCleanCompanyByIdSuccess,
  getCleanCompanyByIdError,
  deleteCompanyRequest,
  deleteCompanySuccess,
  deleteCompanyError,
  getFilteredCompaniesRequest,
  getFilteredCompaniesSuccess,
  getFilteredCompaniesError,
  storeCustomerToCompanyRequest,
  storeCustomerToCompanySuccess,
  storeCustomerToCompanyError,
  inviteCustomerToCompanyRequest,
  inviteCustomerToCompanySuccess,
  inviteCustomerToCompanyError,
  leaveCompanyRequest,
  leaveCompanySuccess,
  leaveCompanyError,
  getPersonalCompaniesRequest,
  getPersonalCompaniesSuccess,
  getPersonalCompaniesError,
  getStaffWithServicesRequest,
  getStaffWithServicesSuccess,
  getStaffWithServicesError,
  getStaffCompanyAppointmentsRequest,
  getStaffCompanyAppointmentsSuccess,
  getStaffCompanyAppointmentsError,
  staffDayOffRequest,
  staffDayOffSuccess,
  staffDayOffError,
  updateStaffDayOffRequest,
  updateStaffDayOffSuccess,
  updateStaffDayOffError,
  deleteStaffDayOffRequest,
  deleteStaffDayOffSuccess,
  deleteStaffDayOffError,
  staffSpecialDayRequest,
  staffSpecialDaySuccess,
  staffSpecialDayError,
  updateStaffSpecialDayRequest,
  updateStaffSpecialDaySuccess,
  updateStaffSpecialDayError,
  deleteStaffSpecialDayRequest,
  deleteStaffSpecialDaySuccess,
  deleteStaffSpecialDayError,
  closeDayRequest,
  closeDaySuccess,
  closeDayError,
  updateCloseDayRequest,
  updateCloseDaySuccess,
  updateCloseDayError,
  deleteCloseDayRequest,
  deleteCloseDaySuccess,
  deleteCloseDayError,
  specialDayRequest,
  specialDaySuccess,
  specialDayError,
  updateSpecialDayRequest,
  updateSpecialDaySuccess,
  updateSpecialDayError,
  deleteSpecialDayRequest,
  deleteSpecialDaySuccess,
  deleteSpecialDayError,
  staffSpecialGraphicRequest,
  staffSpecialGraphicSuccess,
  staffSpecialGraphicError,
  getStaffAvailableDatesRequest,
  getStaffAvailableDatesSuccess,
  getStaffAvailableDatesError,
  listOwnerCompaniesRequest,
  listOwnerCompaniesSuccess,
  listOwnerCompaniesError,
  uploadImageToAmazonRequest,
  uploadImageToAmazonSuccess,
  uploadImageToAmazonError,
  getPreSignUrlRequest,
  getPreSignUrlSuccess,
  getPreSignUrlError,
  getOwnerCompanyRequest,
  getOwnerCompanySuccess,
  getOwnerCompanyError,
  getCompanyWorkingScheduleRequest,
  getCompanyWorkingScheduleSuccess,
  getCompanyWorkingScheduleError,
  getCompanyImagesRequest,
  getCompanyImagesSuccess,
  getCompanyImagesError,
  getCompanyCalendarRequest,
  getCompanyCalendarSuccess,
  getCompanyCalendarError,
  getCompanyRequest,
  getCompanySuccess,
  getCompanyError,
} from './actions';

const initialState = {
  isGetCompanyCalendarSuccess: false,
  isGetCompanyCalendarError: false,
  companyCalendar: {},
  storedCompany: {},
  isStoreCompanySuccess: false,
  isStoreCompanyError: false,
  storeCompanyErrors: [],
  storedGalleryCompany: {},
  storeCompanyCustomerErrors: {},
  isStoreGalleryCompanySuccess: false,
  isStoreGalleryCompanyError: false,
  storeGalleryCompanyErrors: [],
  ownerCompanies: {},
  isGetOwnerCompaniesSuccess: false,
  isGetOwnerCompaniesError: false,
  isUpdateCompanySuccess: false,
  isUpdateCompanyError: false,
  updatedCompany: {},
  updateCompanyErrors: {},
  isGetAllCompaniesSuccess: false,
  isGetAllCompaniesError: false,
  getAllCompaniesErrorMessage: '',
  allCompanies: [],
  isGetCleanCompanyByIdSuccess: false,
  isGetCleanCompanyByIdError: false,
  cleanCompany: {},
  getCleanCompanyByIdErrorMessage: '',
  isGetCompanyByIdSuccess: false,
  isGetCompanyByIdError: false,
  isGetCompanyByIdForCalendarSuccess: false,
  isGetCompanyByIdForCalendarError: false,
  companyGot: {},
  companyForCalendar: {},
  getCompanyByIdErrorMessage: '',
  getCompanyByIdForCalendarErrorMessage: '',
  isDeleteCompanySuccess: false,
  isDeleteCompanyError: false,
  deleteCompanyErrorMessage: '',
  isGetFilteredCompaniesSuccess: false,
  isGetFilteredCompaniesError: false,
  filteredCompanies: [],
  getFilteredCompaniesErrorMessage: '',
  isStoreCustomerToCompanySuccess: false,
  isStoreCustomerToCompanyError: false,
  storeCustomerToCompanySucceed: {},
  storeCustomerToCompanyErrors: {},
  newCustomerSucceed: {},
  isInviteCustomerToCompanySuccess: false,
  isInviteCustomerToCompanyError: false,
  inviteCustomerToCompanySucceed: {},
  inviteCustomerToCompanyErrors: [],
  newInvitation: {},
  leaveCompanySuccess: false,
  leaveCompanyError: false,
  isGetPersonalCompaniesSuccess: false,
  isGetPersonalCompaniesError: false,
  personalCompanies: [],
  getPersonalCompaniesErrorMessage: '',
  isGetStaffWithServicesSuccess: false,
  isGetStaffWithServicesError: false,
  staffWithServices: [],
  getStaffWithServicesErrorMessage: '',
  isGetStaffCompanyAppointmentsSuccess: false,
  isGetStaffCompanyAppointmentsError: false,
  staffCompanyAppointments: [],
  isStaffDayOffSuccess: false,
  isStaffDayOffError: false,
  storeStaffDayOffErrors: {},
  storedStaffDayOff: {},
  isUpdateStaffDayOffSuccess: false,
  isUpdateStaffDayOffError: false,
  updateStaffDayOffErrors: {},
  updatedStaffDayOff: {},
  isDeleteStaffDayOffSuccess: false,
  isDeleteStaffDayOffError: false,
  deleteStaffDayOffErrorMessage: '',
  isStaffSpecialDaySuccess: false,
  isStaffSpecialDayError: false,
  storeStaffSpecialDayErrors: {},
  storedStaffSpecialDay: {},
  isUpdateStaffSpecialDaySuccess: false,
  isUpdateStaffSpecialDayError: false,
  updateStaffSpecialDayErrors: {},
  updatedStaffSpecialDay: {},
  isDeleteStaffSpecialDaySuccess: false,
  isDeleteStaffSpecialDayError: false,
  deleteStaffSpecialDayErrorMessage: '',
  isCloseDaySuccess: false,
  isCloseDayError: false,
  storeCloseDayErrors: {},
  storedCloseDay: {},
  isUpdateCloseDaySuccess: false,
  isUpdateCloseDayError: false,
  updateCloseDayErrors: {},
  updatedCloseDay: {},
  isDeleteCloseDaySuccess: false,
  isDeleteCloseDayError: false,
  deleteCloseDayErrorMessage: '',
  isSpecialDaySuccess: false,
  isSpecialDayError: false,
  storeSpecialDayErrors: {},
  storedSpecialDay: {},
  isUpdateSpecialDaySuccess: false,
  isUpdateSpecialDayError: false,
  updateSpecialDayErrors: {},
  updatedSpecialDay: {},
  isDeleteSpecialDaySuccess: false,
  isDeleteSpecialDayError: false,
  deleteSpecialDayErrorMessage: '',
  deletedGalleryCompany: {},
  isDeleteGalleryCompanySuccess: false,
  isDeleteGalleryCompanyError: false,
  deleteGalleryCompanyErrors: [],
  isSpecialGraphicRequest: false,
  isSpecialGraphicSuccess: false,
  isSpecialGraphicError: false,
  specialDateGraphicErrorMessage: '',
  specialHoursForStaff: [],
  isGetStaffAvailableDatesSuccess: false,
  isGetStaffAvailableDatesError: false,
  staffAvailableDates: {},
  isListOwnerCompaniesSuccess: false,
  isListOwnerCompaniesError: false,
  companiesList: [],
  isGetOwnerCompanySuccess: false,
  isGetOwnerCompanyError: false,
  isGetCompanyWorkingScheduleSuccess: false,
  isGetCompanyWorkingScheduleError: false,
  workingSchedule: {},
  isGetCompanyImagesSuccess: false,
  isGetCompanyImagesError: false,
  companyImages: [],
  isGetPreSignedUrlSuccess: false,
  isGetPreSignedUrlError: false,
  preSignUrl: '',
  isUploadImageToAmazonSuccess: false,
  isUploadImageToAmazonError: false,
  imageUrl: '',
};

const reducer = handleActions(
  {
    [storeCompanyRequest]: (state) => ({
      ...state,
      isStoreCompanySuccess: false,
      isStoreCompanyError: false,
      storeCompanyErrors: [],
      storedCompany: {},
    }),
    [storeCompanySuccess]: (state, { payload }) => ({
      ...state,
      isStoreCompanySuccess: true,
      isStoreCompanyError: false,
      storedCompany: payload.data,
      storeCompanyErrors: [],
    }),
    [storeCompanyError]: (state, { payload }) => ({
      ...state,
      isStoreCompanySuccess: false,
      isStoreCompanyError: true,
      storedCompany: {},
      storeCompanyErrors: payload.errors,
    }),
    [storeGalleryCompanyRequest]: (state) => ({
      ...state,
      isStoreGalleryCompanySuccess: false,
      isStoreGalleryCompanyError: false,
      storeGalleryCompanyErrors: [],
      storedGalleryCompany: {},
    }),
    [storeGalleryCompanySuccess]: (state, { payload }) => ({
      ...state,
      isStoreGalleryCompanySuccess: true,
      isStoreGalleryCompanyError: false,
      storedGalleryCompany: payload.data,
      storeGalleryCompanyErrors: [],
    }),
    [storeGalleryCompanyError]: (state, { payload }) => ({
      ...state,
      isStoreGalleryCompanySuccess: false,
      isStoreGalleryCompanyError: true,
      storedGalleryCompany: {},
      storeGalleryCompanyErrors: payload.errors,
    }),
    [deleteGalleryCompanyRequest]: (state) => ({
      ...state,
      isDeleteGalleryCompanySuccess: false,
      isDeleteGalleryCompanyError: false,
      deleteGalleryCompanyErrors: [],
      deletedGalleryCompany: {},
    }),
    [deleteGalleryCompanySuccess]: (state, { payload }) => ({
      ...state,
      isDeleteGalleryCompanySuccess: true,
      isDeleteGalleryCompanyError: false,
      deletedGalleryCompany: payload.data,
      deleteGalleryCompanyErrors: [],
    }),
    [deleteGalleryCompanyError]: (state, { payload }) => ({
      ...state,
      isDeleteGalleryCompanySuccess: false,
      isDeleteGalleryCompanyError: true,
      deletedGalleryCompany: {},
      deleteGalleryCompanyErrors: payload.errors,
    }),
    [getOwnerCompaniesRequest]: (state) => ({
      ...state,
      isGetOwnerCompaniesSuccess: false,
      isGetOwnerCompaniesError: false,
    }),
    [getOwnerCompaniesSuccess]: (state, { payload }) => ({
      ...state,
      isGetOwnerCompaniesSuccess: true,
      isGetOwnerCompaniesError: false,
      ownerCompanies: payload.data,
    }),
    [getOwnerCompaniesError]: (state) => ({
      ...state,
      isGetOwnerCompaniesSuccess: false,
      isGetOwnerCompaniesError: false,
      ownerCompanies: {},
    }),
    [updateCompanyRequest]: (state) => ({
      ...state,
      isUpdateCompanySuccess: false,
      isUpdateCompanyError: false,
      updatedCompany: {},
    }),
    [updateCompanySuccess]: (state, { payload }) => ({
      ...state,
      isUpdateCompanySuccess: true,
      isUpdateCompanyError: false,
      updatedCompany: payload.data,
    }),
    [updateCompanyError]: (state, { payload }) => ({
      ...state,
      isUpdateCompanySuccess: false,
      isUpdateCompanyError: true,
      updatedCompany: {},
      updateCompanyErrors: payload.errors,
    }),
    [getAllCompaniesRequest]: (state) => ({
      ...state,
      isGetAllCompaniesSuccess: false,
      isGetAllCompaniesError: false,
      getAllCompaniesErrorMessage: '',
      allCompanies: [],
    }),
    [getAllCompaniesSuccess]: (state, { payload }) => ({
      ...state,
      isGetAllCompaniesSuccess: true,
      isGetAllCompaniesError: false,
      getAllCompaniesErrorMessage: '',
      allCompanies: payload.data,
    }),
    [getAllCompaniesError]: (state, { payload }) => ({
      ...state,
      isGetAllCompaniesSuccess: false,
      isGetAllCompaniesError: true,
      getAllCompaniesErrorMessage: payload.data,
      allCompanies: [],
    }),
    [getCompanyByIdRequest]: (state) => ({
      ...state,
      isGetCompanyByIdSuccess: false,
      isGetCompanyByIdError: false,
      companyGot: {},
      getCompanyByIdErrorMessage: '',
    }),
    [getCompanyByIdSuccess]: (state, { payload }) => ({
      ...state,
      isGetCompanyByIdSuccess: true,
      isGetCompanyByIdError: false,
      companyGot: payload.data,
      getCompanyByIdErrorMessage: '',
    }),
    [getCompanyByIdError]: (state, { payload }) => ({
      ...state,
      isGetCompanyByIdSuccess: false,
      isGetCompanyByIdError: true,
      companyGot: {},
      getCompanyByIdErrorMessage: payload.message,
    }),
    [getCompanyByIdForCalendarRequest]: (state) => ({
      ...state,
      isGetCompanyByIdForCalendarSuccess: false,
      isGetCompanyByIdForCalendarError: false,
      companyForCalendar: {},
      getCompanyByIdForCalendarErrorMessage: '',
    }),
    [getCompanyByIdForCalendarSuccess]: (state, { payload }) => ({
      ...state,
      isGetCompanyByIdForCalendarSuccess: true,
      isGetCompanyByIdForCalendarError: false,
      companyForCalendar: payload.data,
      getCompanyByIdForCalendarErrorMessage: '',
    }),
    [getCompanyByIdForCalendarError]: (state, { payload }) => ({
      ...state,
      isGetCompanyByIdForCalendarSuccess: false,
      isGetCompanyByIdForCalendarError: true,
      companyForCalendar: {},
      getCompanyByIdForCalendarErrorMessage: payload.message,
    }),
    [getCleanCompanyByIdRequest]: (state) => ({
      ...state,
      isGetCleanCompanyByIdSuccess: false,
      isGetCleanCompanyByIdError: false,
      cleanCompany: {},
      getCleanCompanyByIdErrorMessage: '',
    }),
    [getCleanCompanyByIdSuccess]: (state, { payload }) => ({
      ...state,
      isGetCleanCompanyByIdSuccess: true,
      isGetCleanCompanyByIdError: false,
      cleanCompany: payload.data,
      getCleanCompanyByIdErrorMessage: '',
    }),
    [getCleanCompanyByIdError]: (state, { payload }) => ({
      ...state,
      isGetCleanCompanyByIdSuccess: false,
      isGetCleanCompanyByIdError: true,
      cleanCompany: {},
      getCleanCompanyByIdErrorMessage: payload.message,
    }),
    [deleteCompanyRequest]: (state) => ({
      ...state,
      isDeleteCompanySuccess: false,
      isDeleteCompanyError: false,
      deleteCompanyErrorMessage: '',
    }),
    [deleteCompanySuccess]: (state) => ({
      ...state,
      isDeleteCompanySuccess: true,
      isDeleteCompanyError: false,
      deleteCompanyErrorMessage: '',
    }),
    [deleteCompanyError]: (state, { payload }) => ({
      ...state,
      isDeleteCompanySuccess: false,
      isDeleteCompanyError: true,
      deleteCompanyErrorMessage: payload.message,
    }),
    [getFilteredCompaniesRequest]: (state) => ({
      ...state,
      isGetFilteredCompaniesSuccess: false,
      isGetFilteredCompaniesError: false,
      filteredCompanies: [],
      getFilteredCompaniesErrorMessage: '',
    }),
    [getFilteredCompaniesSuccess]: (state, { payload }) => ({
      ...state,
      isGetFilteredCompaniesSuccess: true,
      isGetFilteredCompaniesError: false,
      filteredCompanies: payload.data,
      getFilteredCompaniesErrorMessage: '',
    }),
    [getFilteredCompaniesError]: (state, { payload }) => ({
      ...state,
      isGetFilteredCompaniesSuccess: false,
      isGetFilteredCompaniesError: true,
      filteredCompanies: [],
      getFilteredCompaniesErrorMessage: payload.message,
    }),
    [storeCustomerToCompanyRequest]: (state) => ({
      ...state,
      isStoreCustomerToCompanySuccess: false,
      isStoreCustomerToCompanyError: false,
      storeCustomerToCompanyErrors: {},
      newCustomerSucceed: {},
    }),
    [storeCustomerToCompanySuccess]: (state, { payload }) => ({
      ...state,
      isStoreCustomerToCompanySuccess: true,
      isStoreCustomerToCompanyError: false,
      storeCustomerToCompanyErrors: {},
      newCustomerSucceed: payload,
    }),
    [storeCustomerToCompanyError]: (state, { payload }) => ({
      ...state,
      isStoreCustomerToCompanySuccess: false,
      isStoreCustomerToCompanyError: true,
      storeCustomerToCompanyErrors: payload.errors || {},
      newCustomerSucceed: {},
    }),
    [inviteCustomerToCompanyRequest]: (state) => ({
      ...state,
      isInviteCustomerToCompanySuccess: false,
      isInviteCustomerToCompanyError: false,
      inviteCustomerToCompanyErrors: [],
      inviteCustomerToCompanySucceed: {},
      newInvitation: {},
    }),
    [inviteCustomerToCompanySuccess]: (state, { payload }) => ({
      ...state,
      isInviteCustomerToCompanySuccess: true,
      isInviteCustomerToCompanyError: false,
      inviteCustomerToCompanyErrors: [],
      inviteCustomerToCompanySucceed: payload,
      newInvitation: payload.invitation || {},
    }),
    [inviteCustomerToCompanyError]: (state, { payload }) => ({
      ...state,
      isInviteCustomerToCompanySuccess: false,
      isInviteCustomerToCompanyError: true,
      inviteCustomerToCompanyErrors: payload,
      inviteCustomerToCompanySucceed: {},
      newInvitation: {},
    }),
    [leaveCompanyRequest]: (state) => ({
      ...state,
      leaveCompanySuccess: false,
      leaveCompanyError: false,
    }),
    [leaveCompanySuccess]: (state) => ({
      ...state,
      leaveCompanySuccess: true,
      leaveCompanyError: false,
    }),
    [leaveCompanyError]: (state) => ({
      ...state,
      leaveCompanySuccess: false,
      leaveCompanyError: true,
    }),
    [getPersonalCompaniesRequest]: (state) => ({
      ...state,
      isGetPersonalCompaniesSuccess: false,
      isGetPersonalCompaniesError: false,
      personalCompanies: [],
      getPersonalCompaniesErrorMessage: '',
    }),
    [getPersonalCompaniesSuccess]: (state, { payload }) => ({
      ...state,
      isGetPersonalCompaniesSuccess: true,
      isGetPersonalCompaniesError: false,
      personalCompanies: payload.data,
      getPersonalCompaniesErrorMessage: '',
    }),
    [getPersonalCompaniesError]: (state, { payload }) => ({
      ...state,
      isGetPersonalCompaniesSuccess: false,
      isGetPersonalCompaniesError: true,
      personalCompanies: [],
      getPersonalCompaniesErrorMessage: payload.message,
    }),
    [getStaffWithServicesRequest]: (state) => ({
      ...state,
      isGetStaffWithServicesSuccess: false,
      isGetStaffWithServicesError: false,
      staffWithServices: [],
      getStaffWithServicesErrorMessage: '',
    }),
    [getStaffWithServicesSuccess]: (state, { payload }) => ({
      ...state,
      isGetStaffWithServicesSuccess: true,
      isGetStaffWithServicesError: false,
      staffWithServices: payload.data,
      getStaffWithServicesErrorMessage: '',
    }),
    [getStaffWithServicesError]: (state, { payload }) => ({
      ...state,
      isGetStaffWithServicesSuccess: false,
      isGetStaffWithServicesError: true,
      staffWithServices: [],
      getStaffWithServicesErrorMessage: payload.message,
    }),
    [getStaffCompanyAppointmentsRequest]: (state) => ({
      ...state,
      isGetStaffCompanyAppointmentsSuccess: false,
      isGetStaffCompanyAppointmentsError: false,
      staffCompanyAppointments: [],
    }),
    [getStaffCompanyAppointmentsSuccess]: (state, { payload }) => ({
      ...state,
      isGetStaffCompanyAppointmentsSuccess: true,
      isGetStaffCompanyAppointmentsError: false,
      staffCompanyAppointments: payload.data,
    }),
    [getStaffCompanyAppointmentsError]: (state) => ({
      ...state,
      isGetStaffCompanyAppointmentsSuccess: false,
      isGetStaffCompanyAppointmentsError: true,
      staffCompanyAppointments: [],
    }),
    [staffDayOffRequest]: (state) => ({
      ...state,
      isStaffDayOffSuccess: false,
      isStaffDayOffError: false,
      storeStaffDayOffErrors: {},
      storedStaffDayOff: {},
    }),
    [staffDayOffSuccess]: (state, { payload }) => ({
      ...state,
      isStaffDayOffSuccess: true,
      storedStaffDayOff: payload.data,
    }),
    [staffDayOffError]: (state, { payload }) => ({
      ...state,
      isStaffDayOffError: true,
      storeStaffDayOffErrors: payload.errors,
    }),
    [staffSpecialDayRequest]: (state) => ({
      ...state,
      isStaffSpecialDaySuccess: false,
      isStaffSpecialDayError: false,
      storeStaffSpecialDayErrors: {},
      storedStaffSpecialDay: {},
    }),
    [staffSpecialDaySuccess]: (state, { payload }) => ({
      ...state,
      isStaffSpecialDaySuccess: true,
      storedStaffSpecialDay: payload.data,
    }),
    [staffSpecialDayError]: (state, { payload }) => ({
      ...state,
      isStaffSpecialDayError: true,
      storeStaffSpecialDayErrors: payload.errors,
    }),
    [updateStaffSpecialDayRequest]: (state) => ({
      ...state,
      isUpdateStaffSpecialDaySuccess: false,
      isUpdateStaffSpecialDayError: false,
      updateSpecialDayErrors: {},
      updatedSpecialDay: {},
    }),
    [updateStaffSpecialDaySuccess]: (state, { payload }) => ({
      ...state,
      isUpdateStaffSpecialDaySuccess: true,
      updatedStaffSpecialDay: payload.data,
    }),
    [updateStaffSpecialDayError]: (state, { payload }) => ({
      ...state,
      isUpdateStaffSpecialDayError: true,
      updateStaffSpecialDayErrors: payload.errors,
    }),
    [deleteStaffSpecialDayRequest]: (state) => ({
      ...state,
      isDeleteStaffSpecialDaySuccess: false,
      isDeleteStaffSpecialDayError: false,
      deleteStaffSpecialDayErrorMessage: '',
    }),
    [deleteStaffSpecialDaySuccess]: (state) => ({
      ...state,
      isDeleteStaffSpecialDaySuccess: true,
      isDeleteStaffSpecialDayError: false,
      deleteStaffSpecialDayErrorMessage: '',
    }),
    [deleteStaffSpecialDayError]: (state, { payload }) => ({
      ...state,
      isDeleteStaffSpecialDaySuccess: false,
      isDeleteStaffSpecialDayError: true,
      deleteStaffSpecialDayErrorMessage: payload.message,
    }),
    [updateStaffDayOffRequest]: (state) => ({
      ...state,
      isUpdateStaffDayOffSuccess: false,
      isUpdateStaffDayOffError: false,
      updateStaffDayOffErrors: {},
      updatedStaffDayOff: {},
    }),
    [updateStaffDayOffSuccess]: (state, { payload }) => ({
      ...state,
      isUpdateStaffDayOffSuccess: true,
      updatedStaffDayOff: payload.data,
    }),
    [updateStaffDayOffError]: (state, { payload }) => ({
      ...state,
      isUpdateStaffDayOffError: true,
      updateStaffDayOffErrors: payload.errors,
    }),
    [deleteStaffDayOffRequest]: (state) => ({
      ...state,
      isDeleteStaffDayOffSuccess: false,
      isDeleteStaffDayOffError: false,
      deleteStaffDayOffErrorMessage: '',
    }),
    [deleteStaffDayOffSuccess]: (state) => ({
      ...state,
      isDeleteStaffDayOffSuccess: true,
      deleteStaffDayOffErrorMessage: '',
    }),
    [deleteStaffDayOffError]: (state, { payload }) => ({
      ...state,
      isDeleteStaffDayOffError: true,
      deleteStaffDayOffErrorMessage: payload.message,
    }),
    [closeDayRequest]: (state) => ({
      ...state,
      isCloseDaySuccess: false,
      isCloseDayError: false,
      storeCloseDayErrors: {},
      storedCloseDay: {},
    }),
    [closeDaySuccess]: (state, { payload }) => ({
      ...state,
      isCloseDaySuccess: true,
      storedCloseDay: payload.data,
    }),
    [closeDayError]: (state, { payload }) => ({
      ...state,
      isCloseDayError: true,
      storeCloseDayErrors: payload.errors,
    }),
    [updateCloseDayRequest]: (state) => ({
      ...state,
      isUpdateCloseDaySuccess: false,
      isUpdateCloseDayError: false,
      updateCloseDayErrors: {},
      updatedCloseDay: {},
    }),
    [updateCloseDaySuccess]: (state, { payload }) => ({
      ...state,
      isUpdateCloseDaySuccess: true,
      updatedCloseDay: payload.data,
    }),
    [updateCloseDayError]: (state, { payload }) => ({
      ...state,
      isUpdateCloseDayError: true,
      updateCloseDayErrors: payload.errors,
    }),
    [deleteCloseDayRequest]: (state) => ({
      ...state,
      isDeleteCloseDaySuccess: false,
      isDeleteCloseDayError: false,
      deleteCloseDayErrorMessage: '',
    }),
    [deleteCloseDaySuccess]: (state) => ({
      ...state,
      isDeleteCloseDaySuccess: true,
    }),
    [deleteCloseDayError]: (state, { payload }) => ({
      ...state,
      isDeleteCloseDayError: true,
      deleteCloseDayErrorMessage: payload.message,
    }),
    [specialDayRequest]: (state) => ({
      ...state,
      isSpecialDaySuccess: false,
      isSpecialDayError: false,
      storeSpecialDayErrors: {},
      storedSpecialDay: {},
    }),
    [specialDaySuccess]: (state, { payload }) => ({
      ...state,
      isSpecialDaySuccess: true,
      storedSpecialDay: payload.data,
    }),
    [specialDayError]: (state, { payload }) => ({
      ...state,
      isSpecialDayError: true,
      storeSpecialDayErrors: payload.errors,
    }),
    [updateSpecialDayRequest]: (state) => ({
      ...state,
      isUpdateSpecialDaySuccess: false,
      isUpdateSpecialDayError: false,
      updateSpecialDayErrors: {},
      updatedSpecialDay: {},
    }),
    [updateSpecialDaySuccess]: (state, { payload }) => ({
      ...state,
      isUpdateSpecialDaySuccess: true,
      updatedSpecialDay: payload.data,
    }),
    [updateSpecialDayError]: (state, { payload }) => ({
      ...state,
      isUpdateSpecialDayError: true,
      updateSpecialDayErrors: payload.errors,
    }),
    [deleteSpecialDayRequest]: (state) => ({
      ...state,
      isDeleteSpecialDaySuccess: false,
      isDeleteSpecialDayError: false,
      deleteSpecialDayErrorMessage: '',
    }),
    [deleteSpecialDaySuccess]: (state) => ({
      ...state,
      isDeleteSpecialDaySuccess: true,
    }),
    [deleteSpecialDayError]: (state, { payload }) => ({
      ...state,
      isDeleteSpecialDayError: true,
      deleteSpecialDayErrorMessage: payload.message,
    }),
    [staffSpecialGraphicRequest]: (state) => ({
      ...state,
      isSpecialGraphicSuccess: false,
      isSpecialGraphicError: false,
      specialHoursForStaff: [],
      specialDateGraphicErrorMessage: '',
    }),
    [staffSpecialGraphicSuccess]: (state, { payload }) => ({
      ...state,
      isSpecialGraphicSuccess: true,
      isSpecialGraphicError: false,
      specialHoursForStaff: payload,
    }),
    [staffSpecialGraphicError]: (state, { payload }) => ({
      ...state,
      isSpecialGraphicSuccess: false,
      isSpecialGraphicError: true,
      specialHoursForStaff: [],
      specialDateGraphicErrorMessage: payload,
    }),
    [getStaffAvailableDatesRequest]: (state) => ({
      ...state,
      isGetStaffAvailableDatesSuccess: false,
      isGetStaffAvailableDatesError: false,
      staffAvailableDates: {},
    }),
    [getStaffAvailableDatesSuccess]: (state, { payload }) => ({
      ...state,
      isGetStaffAvailableDatesSuccess: true,
      isGetStaffAvailableDatesError: false,
      staffAvailableDates: payload,
    }),
    [getStaffAvailableDatesError]: (state) => ({
      ...state,
      isGetStaffAvailableDatesSuccess: false,
      isGetStaffAvailableDatesError: true,
      staffAvailableDates: {},
    }),
    [listOwnerCompaniesRequest]: (state) => ({
      ...state,
      isListOwnerCompaniesSuccess: false,
      isListOwnerCompaniesError: false,
      companiesList: [],
    }),
    [listOwnerCompaniesSuccess]: (state, { payload }) => ({
      ...state,
      isListOwnerCompaniesSuccess: true,
      isListOwnerCompaniesError: false,
      companiesList: payload.data,
    }),
    [listOwnerCompaniesError]: (state) => ({
      ...state,
      isListOwnerCompaniesSuccess: false,
      isListOwnerCompaniesError: true,
      companiesList: [],
    }),
    [getPreSignUrlRequest]: (state) => ({
      ...state,
      isGetPreSignedUrlSuccess: false,
      isGetPreSignedUrlError: false,
      preSignUrl: '',
    }),
    [getPreSignUrlSuccess]: (state, { payload }) => ({
      ...state,
      isGetPreSignedUrlSuccess: true,
      isGetPreSignedUrlError: false,
      preSignUrl: payload.url,
    }),
    [getPreSignUrlError]: (state) => ({
      ...state,
      isGetPreSignedUrlSuccess: false,
      isGetPreSignedUrlError: true,
      preSignUrl: '',
    }),
    [uploadImageToAmazonRequest]: (state) => ({
      ...state,
      isUploadImageToAmazonSuccess: false,
      isUploadImageToAmazonError: false,
    }),
    [uploadImageToAmazonSuccess]: (state) => ({
      ...state,
      isUploadImageToAmazonSuccess: true,
      isUploadImageToAmazonError: false,
    }),
    [uploadImageToAmazonError]: (state) => ({
      ...state,
      isUploadImageToAmazonSuccess: false,
      isUploadImageToAmazonError: true,
    }),
    [getOwnerCompanyRequest]: (state) => ({
      ...state,
      isGetOwnerCompanySuccess: false,
      isGetOwnerCompanyError: false,
      companyGot: {},
    }),
    [getOwnerCompanySuccess]: (state, { payload }) => ({
      ...state,
      isGetOwnerCompanySuccess: true,
      isGetOwnerCompanyError: false,
      companyGot: payload,
    }),
    [getOwnerCompanyError]: (state) => ({
      ...state,
      isGetOwnerCompanySuccess: false,
      isGetOwnerCompanyError: true,
      companyGot: {},
    }),
    [getCompanyWorkingScheduleRequest]: (state) => ({
      ...state,
      isGetCompanyWorkingScheduleSuccess: false,
      isGetCompanyWorkingScheduleError: false,
      workingSchedule: {},
    }),
    [getCompanyWorkingScheduleSuccess]: (state, { payload }) => ({
      ...state,
      isGetCompanyWorkingScheduleSuccess: true,
      isGetCompanyWorkingScheduleError: false,
      workingSchedule: payload,
    }),
    [getCompanyWorkingScheduleError]: (state) => ({
      ...state,
      isGetCompanyWorkingScheduleSuccess: false,
      isGetCompanyWorkingScheduleError: true,
      workingSchedule: {},
    }),
    [getCompanyImagesRequest]: (state) => ({
      ...state,
      isGetCompanyImagesSuccess: false,
      isGetCompanyImagesError: false,
      companyImages: [],
    }),
    [getCompanyImagesSuccess]: (state, { payload }) => ({
      ...state,
      isGetCompanyImagesSuccess: true,
      isGetCompanyImagesError: false,
      companyImages: payload,
    }),
    [getCompanyImagesError]: (state) => ({
      ...state,
      isGetCompanyImagesSuccess: false,
      isGetCompanyImagesError: true,
      companyImages: [],
    }),

    [getCompanyCalendarRequest]: (state) => ({
      ...state,
      isGetCompanyCalendarSuccess: false,
      isGetCompanyCalendarError: false,
      companyCalendar: [],
    }),
    [getCompanyCalendarSuccess]: (state, { payload }) => ({
      ...state,
      isGetCompanyCalendarSuccess: true,
      isGetCompanyCalendarError: false,
      companyCalendar: payload,
    }),
    [getCompanyCalendarError]: (state) => ({
      ...state,
      isGetCompanyCalendarSuccess: false,
      isGetCompanyCalendarError: true,
      companyCalendar: [],
    }),

    [getCompanyRequest]: (state) => ({
      ...state,
      isGetCompanySuccess: false,
      isGetCompanyError: false,
      company: [],
    }),
    [getCompanySuccess]: (state, { payload }) => ({
      ...state,
      isGetCompanySuccess: true,
      isGetCompanyError: false,
      company: payload,
    }),
    [getCompanyError]: (state) => ({
      ...state,
      isGetCompanySuccess: false,
      isGetCompanyError: true,
      company: [],
    }),
  },
  initialState,
);

export default reducer;
