const resources = {
  de: {
    translation: {
      Accept: 'Akzeptieren',
      Accepted: 'Akzeptiert',
      AccountCreated: 'Konto erstellt.',
      Actions: 'Optionen',
      Add: 'Hinzufügen',
      AddAppointment: 'Termin hinzufügen',
      AddClosedDays: 'Geschlossene Tage hinzufügen',
      AddCompany: 'Unternehmen hinzufügen',
      AddCustomer: 'Kunde hinzufügen',
      AddDayOff: 'Freie Tage hinzufügen',
      AddNewCustomer: 'Neuen Kunden hinzufügen',
      Address: 'Straßenadresse',
      AddService: 'Dienst hinzufügen',
      AddShortDescOptional: 'Kurzbeschreibung hinzufügen (optional)',
      AddSpecificWorkDays: 'Bestimmte Arbeitstage hinzufügen',
      AddStaff: 'Personal hinzufügen',
      All: 'Alle',
      AllAppointmentsCancelText: 'Alle kommenden Termine werden abgesagt.',
      AMD: 'AMD',
      Apply: 'Anwenden',
      ApplyToAll: 'Für alle übernehmen',
      'Appointment Count': 'Anzahl der Termine',
      AppointmentAccepted: 'Termin erfolgreich akzeptiert',
      AppointmentCanceled: 'Termin erfolgreich abgesagt',
      AppointmentCancelModalText: 'Sind Sie sicher, dass Sie Ihren Termin absagen möchten?',
      AppointmentCreated: 'Termin erfolgreich erstellt',
      AppointmentDeleted: 'Termin erfolgreich gelöscht',
      AppointmentDeleteModalText:
        'Der Termin wird aus dem Kalender verschwinden und wird nicht in der Statistik berechnet.',
      AppointmentDeleteQuestion: 'Sind Sie sicher, dass Sie den Termin löschen möchten?',
      AppointmentDetails: 'Termin Details',
      AppointmentHistory: 'Terminhistorie',
      AppointmentNotes: 'Nicht für Kunden sichtbar',
      AppointmentNotFiltered: 'Termin wird nicht gefiltert',
      AppointmentNotFound: 'Termin nicht gefunden.',
      AppointmentRescheduled: 'Termin erfolgreich neu geplant',
      Appointments: 'Termine',
      AppointmentSaveDraft: 'Terminentwurf gespeichert',
      AppointmentsHistory: 'Termingeschichte',
      AreYouSureCancel: 'Sind Sie sicher, dass Sie den',
      AreYouSureDelete: 'Sind Sie sicher, dass Sie den',
      'Assigned Services': 'Zugewiesene Dienste',
      AtLeastOne: 'Mindestens einer ',
      AutoAccept: 'Termine automatisch akzeptieren',
      AutoAcceptLabel: 'Automatisch akzeptieren',
      AutoAcceptTooltipText: 'Termine, die online gebucht werden, werden automatisch akzeptiert.',
      AutoFinish: 'Bestätigte Termine automatisch als abgeschlossen markieren, sobald ihre Endzeit verstrichen ist',
      AvailableHours: 'Verfügbare Zeitfenster',
      BookingLink: 'Buchungslink',
      BookingUrl: 'Buchungs-URL',
      BreakEndTimeAfter: 'Die Endzeit der Pause sollte nach der Startzeit der Pause liegen.',
      BreakEndTimeRequired: 'Die Endzeit der Pause ist erforderlich.',
      Breaking: 'Pause',
      BreakStartTimeRequired: 'Die Startzeit der Pause ist erforderlich.',
      BufferTime: 'Pufferzeit',
      BufferTimeTooltipText: 'Zusätzliche Zeit nach dem Service hinzufügen, die dem Benutzer nicht angezeigt wird.',
      BusinessNotSaved: 'Geschäftsdetails nicht gespeichert.',
      BusinessSaved: 'Geschäftsdetails gespeichert.',
      ByDate: 'Nach Datum',
      ByStatus: 'Nach Status',
      Calendar: 'Kalender',
      CalendarTimeSlot: 'Zeitbereich',
      Cancel: 'Abbrechen',
      CancelAllUpcomingAppointments: 'Alle kommenden Termine absagen',
      CancelAppointment: 'Termin absagen',
      CancelInvitation: 'Einladung stornieren',
      Cancelled: 'Abgesagt',
      CantInviteYourSelf: 'Sie können sich nicht selbst als Kunden einladen',
      Categories: 'Kategorien',
      Category: 'Kategorie',
      CategoryIsRequired: 'Kategorie ist erforderlich',
      Characters: 'Zeichen',
      ChooseCustomer: 'Wählen Sie einen Kunden aus',
      ChooseHour: 'Stunde wählen',
      ChooseMinutes: 'Minuten wählen',
      ChooseOrCreateService: 'Dienst auswählen oder erstellen',
      ChooseOrCreateServiceCategory: 'Dienstkategorie auswählen oder erstellen',
      ChooseService: 'Wählen Sie einen Dienst aus',
      ChooseServiceCategory: 'Dienstkategorie auswählen',
      ChooseServiceProvider: 'Dienstanbieter auswählen',
      ChooseStaff: 'Wählen Sie ein Mitarbeitermitglied aus',
      City: 'Stadt',
      Close: 'Geschlossen',
      ClosedDayAdded: 'Geschlossene Tag(e) erfolgreich hinzugefügt.',
      ClosedDays: 'Geschlossene Tage',
      ClosedDayUpdated: 'Geschlossene Tag(e) erfolgreich aktualisiert.',
      Code: 'Ländercode',
      Companies: 'Unternehmen',
      Company: 'Unternehmen',
      CompanyDeleted: 'Unternehmen erfolgreich gelöscht',
      CompanyEmail: 'Unternehmens-E-Mail-Adresse eingeben',
      CompanyName: 'Unternehmensname',
      CompanyOverview: 'Schreiben Sie eine kurze Übersicht über Ihr Unternehmen',
      Confirm: 'Bestätigen',
      ConfirmActionMassagesDelete: 'Sind Sie sicher, dass Sie dieses Unternehmen entfernen möchten?',
      ConfirmActionMassagesDeleteError:
        'Sie können dieses Unternehmen noch nicht entfernen, da bevorstehende Termine vorhanden sind.',
      ConfirmActionMassagesLeave: 'Sind Sie sicher, dass Sie das Unternehmen verlassen möchten?',
      ConfirmCancel: 'Abbruch bestätigen',
      ConfirmDelete: 'Löschung bestätigen',
      ConfirmDeletion: 'Löschung bestätigen',
      ConfirmPassword: 'Passwort bestätigen',
      continueDeleting: 'Möchten Sie mit dem Löschen fortfahren?',
      continueWithFacebook: 'Weiter mit Facebook',
      Country: 'Land',
      Create: 'Erstellen',
      CreateAppointment: 'Termin erstellen',
      CreateService: 'Dienst erstellen',
      CurrentPassword: 'Aktuelles Passwort',
      Customer: 'Kunde',
      'Customer Name': 'Kundenname',
      CustomerAdded: 'Kunde hinzugefügt',
      CustomerDeleted: 'Kunde erfolgreich gelöscht',
      CustomerDeleteText: 'Wenn es Termine mit diesen Kunden gibt, werden sie abgesagt.',
      CustomerDetails: 'Kundendetails',
      CustomerPreview: 'Kunden-Vorschau',
      Customers: 'Kunden',
      CustomerUpdated: 'Kunde erfolgreich aktualisiert',
      Daily: 'Täglich',
      Date: 'Datum',
      DateAndTime: 'Datum & Uhrzeit',
      DatePicker: 'Datumsauswahl',
      DateTime: 'Datum/Uhrzeit',
      DayAdded: 'Freier Tag(e) erfolgreich hinzugefügt',
      DayOfBirth: 'Geburtsdatum',
      DayOffDeleted: 'Freier Tag(e) erfolgreich gelöscht.',
      DaysOff: 'Freie Tage',
      DayUpdated: 'Freier Tag(e) erfolgreich aktualisiert.',
      Decline: 'Ablehnen',
      DefaultView: 'Standardkalenderansicht',
      Delete: 'Löschen',
      DeleteCategoryText:
        'Wenn Sie diese Dienstkategorie löschen, werden auch alle darunter liegenden Dienste gelöscht.',
      deleteMultipleStaffText: 'Wenn es Termine mit diesen Mitarbeitern gibt, werden sie abgesagt.',
      Description: 'Beschreibung',
      Details: 'Details',
      Digit: 'Ziffer',
      DontGetCode: 'Keinen Code erhalten?',
      DontHaveAnAccount: 'Noch kein Konto?',
      DownloadAndSignUp: 'Laden Sie die App herunter und melden Sie sich jetzt an!',
      Duration: 'Dauer',
      Edit: 'Bearbeiten',
      EditAppointment: 'Termin bearbeiten',
      EditCategory: 'Kategorie bearbeiten',
      EditCustomer: 'Kunde bearbeiten',
      EditService: 'Dienst bearbeiten',
      EditSpecificWorkDays: 'Bestimmte Arbeitstage bearbeiten',
      EditStaff: 'Personal bearbeiten',
      Email: 'E-Mail',
      EmailOptional: 'E-Mail (optional)',
      EmailRequired: 'E-Mail ist erforderlich.',
      EmailResent: 'E-Mail wurde erfolgreich erneut gesendet.',
      EmailValid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      EndDate: 'Enddatum',
      EndDateRequired: 'Enddatum ist erforderlich.',
      EndTime: 'Endzeit',
      EndTimeAfter: 'Die Endzeit sollte nach der Startzeit liegen.',
      EnterCodeText: 'Geben Sie den 6-stelligen Code ein, der an Ihre gesendet wurde',
      EnterCustomerEmail: 'Kunden-E-Mail eingeben',
      EnterCustomerFirstName: 'Vorname des Kunden eingeben',
      EnterCustomerLastName: 'Nachname des Kunden eingeben',
      EnterCustomerPhoneNumber: 'Telefonnummer des Kunden eingeben',
      EnterSignInDetailsText: 'Bitte geben Sie Ihre Daten ein, um sich anzumelden.',
      EnterStaffFirstName: 'Vorname des Mitarbeiters eingeben',
      EnterStaffLastName: 'Nachname des Mitarbeiters eingeben',
      EnterStaffPhoneNumber: 'Telefonnummer des Mitarbeiters eingeben',
      EnterStaffProfession: 'Berufsbezeichnung des Mitarbeiters eingeben',
      Facebook: 'Facebook-Seiten-URL',
      FasterAndMoreOrganizedText: 'Lassen Sie uns den Buchungsprozess schneller und organisierter gestalten.',
      Female: 'Weiblich',
      FileNotSupported: 'Die Datei wird nicht unterstützt',
      Filter: 'Filtern',
      FinalPriceText: 'Bitte definieren Sie den Endpreis für diesen Dienst.',
      Finish: 'Beenden',
      FinishAppointment: 'Termin beenden',
      Finished: 'Beendet',
      'First Visit': 'Erster Besuch',
      FirstName: 'Vorname',
      FirstNameOrLastNameRequired: 'Vorname oder Nachname ist erforderlich',
      Fixed: 'Fixiert',
      FollowUp: 'Nachverfolgung',
      ForgotPassword1: 'Passwort vergessen?',
      ForgotPassword2: 'Passwort vergessen',
      Free: 'Kostenlos',
      Fri: 'Fr',
      Friday: 'Freitag',
      From: 'Von',
      Gallery: 'Galerie',
      Gender: 'Geschlecht',
      General: 'Allgemeine Informationen',
      GeneralSchedule: {
        BreakHours: 'Pausezeit',
        Title: 'Allgemeiner Zeitplan',
        WeekDays: 'Arbeitstage',
        WorkingHours: 'Arbeitszeit',
      },
      H: 'h',
      haveOneStaff: 'Mindestens 1 Mitarbeiter ist erforderlich.',
      Hour: 'Stunde',
      ImageDeleted: 'Bild erfolgreich gelöscht',
      IndustriesNotSaved: 'Branchendetails nicht gespeichert.',
      Industry: 'Branche',
      IndustryDeleted: 'Branche erfolgreich gelöscht.',
      IndustryNotFound: 'Branche nicht gefunden.',
      IndustryUpdated: 'Branche erfolgreich aktualisiert.',
      Info: 'Info',
      InfoGeneral: 'Allgemeine Informationen',
      InfoLinks: 'Links',
      InfoLocation: 'Ort',
      InfoTitle: 'Info',
      Instagram: 'Instagram-Benutzername',
      InvalidData: 'Ungültige Daten.',
      InvalidLoginOrPass: 'Ungültige Anmeldung oder Passwort.',
      Invitation: 'Einladung',
      InvitationAccepted: 'Einladung erfolgreich akzeptiert',
      InvitationDeclined: 'Einladung abgelehnt.',
      InvitationDeleted: 'Einladung erfolgreich gelöscht',
      InvitationSent: 'Einladung gesendet.',
      InvitationUpdated: 'Einladung erfolgreich aktualisiert',
      Invite: 'Einladen',
      InviteCustomer: 'Zum Registrieren einladen',
      InviteCustomerDescription: '<0>{{name}}</0> zum Registrieren bei SetTime einladen.',
      Invited: 'Eingeladen',
      InviteToRegister: 'Zum Registrieren einladen',
      IsNotValid: ' ist ungültig',
      IsRequired: ' ist erforderlich',
      IsTooLong: ' ist zu lang',
      Language: 'Sprache',
      'Last Visit': 'Letzter Besuch',
      LastName: 'Nachname',
      LastNameOptional: 'Nachname (optional)',
      Leave: 'Verlassen',
      LeaveCompany: 'Unternehmen verlassen.',
      LoadDraft: 'Entwurf laden',
      Loading: 'Laden',
      Location: 'Ort',
      Logout: 'Abmelden',
      Lowercase: 'Kleinbuchstaben',
      lowercasePhoneNumber: 'Telefonnummer',
      MaintenanceTime: 'Wartungszeit',
      Male: 'Männlich',
      Manager: 'Manager',
      Min: 'min',
      Mon: 'Mo',
      Monday: 'Montag',
      Monthly: 'Monatlich',
      MyAppointments: 'Termine',
      MyCompany: 'Mein Unternehmen',
      MyProfile: 'Mein Profil',
      Name: 'Name',
      New: 'Neu',
      NewAppointment: 'Neuer Termin',
      NewCategory: 'Neue Kategorie',
      NewCompany: 'Neues Unternehmen',
      NewCustomer: 'Neuer Kunde',
      NewPassMustBeDifferentText: 'Ihr neues Passwort muss sich von früheren Passwörtern unterscheiden',
      NewPassword: 'Neues Passwort',
      NewService: 'Neuer Dienst',
      NewStaff: 'Neues Personal',
      Next: 'Weiter',
      No: 'Nein',
      NoAppointments: 'Keine Termine bisher',
      NoNotifications: 'Noch keine Benachrichtigungen',
      NoOptions: 'Keine Optionen',
      Note: 'Notiz',
      Notes: 'Terminnotizen',
      Notification: 'Benachrichtigung',
      NotificationCanceled: 'Kunden benachrichtigen, wenn ihr Termin abgesagt wird',
      NotificationConfirmed: 'Kunden benachrichtigen, wenn ihr Termin bestätigt wird',
      NotificationRescheduled: 'Kunden benachrichtigen, wenn ihr Termin neu geplant wird',
      NotRegistered: 'Nicht registriert',
      Off: 'Aus',
      On: 'An',
      Open: 'Geöffnet',
      Order: 'Planungsauftrag',
      Password: 'Passwort',
      PasswordRequired: 'Passwort ist erforderlich.',
      PasswordSettings: 'Passwort ändern',
      PastDate: 'Erstellen von Terminen in der Vergangenheit erlauben',
      Pending: 'Ausstehend',
      Permalink: 'Permalink',
      PersonalInformation: 'Persönliche Informationen',
      Phone: 'Telefon',
      PhoneCode: 'Ländervorwahl',
      PhoneNumber: 'Telefonnummer',
      PhoneNumberOptional: 'Telefonnummer (optional)',
      Position: 'Position',
      Price: 'Preis',
      PriceType: 'Preistyp',
      Pricing: 'Preisgestaltung',
      Profession: 'Beruf',
      ProfessionOptional: 'Beruf (optional)',
      Range: 'Bereich',
      ReadAll: 'Alles lesen',
      Registered: 'Registriert',
      RemindCustomer: 'Den Kunden erinnern',
      Reminder: 'Erinnerungen',
      Resend: 'Erneut senden',
      ResetPassword: 'Passwort zurücksetzen',
      RowsPerPage: 'Zeilen pro Seite',
      Sat: 'Sa',
      Saturday: 'Samstag',
      Save: 'Speichern',
      SaveDraft: 'Entwurf speichern',
      SaveDraftText: 'Möchten Sie diesen Termin als Entwurf speichern?',
      SearchByCustomerOrStaff: 'Nach Kunde oder Personal suchen',
      SearchCustomer: 'Nach Kundenname suchen',
      SearchService: 'Nach Dienstname suchen',
      SearchStaff: 'Nach Mitarbeiternamen suchen',
      SelectedDayNotWork: 'Der ausgewählte Tag ist kein Arbeitstag',
      Send: 'Senden',
      Service: 'Dienst',
      'Service Name': 'Dienstname',
      ServiceBased: 'Dienstbasiert',
      ServiceCategory: 'Dienstkategorie',
      ServiceDeleted: 'Dienst erfolgreich gelöscht.',
      ServiceDeletionText:
        'Dieser Dienst steht nicht mehr zur Buchung zur Verfügung, und falls Termine vorhanden sind, werden sie abgesagt.',
      ServiceDuration: 'Dienstdauer',
      ServiceNotFound: 'Dienst nicht gefunden.',
      ServiceProvider: 'Dienstanbieter',
      Services: 'Dienste',
      Settings: 'Einstellungen',
      ShortPassword: 'Passwort ist zu kurz.',
      ShowLess: 'Weniger anzeigen',
      ShowMore: 'Mehr anzeigen',
      ShowOnWidget: 'Im Widget anzeigen',
      ShowOnWidgetTooltipText: 'Der Dienst wird für die Online-Buchung verfügbar sein.',
      SignIn: 'Anmelden',
      SlotDuration: 'Dauer des Kalenderslots',
      SomethingWrong: 'Etwas ist schief gelaufen.',
      Source: 'Quelle',
      SpecificDayAdded: 'Bestimmte Arbeitstag(e) erfolgreich hinzugefügt.',
      SpecificDayDeleted: 'Bestimmte Arbeitstag(e) erfolgreich gelöscht.',
      SpecificDaysTitle: 'Bestimmte Arbeitstage',
      SpecificDayUpdated: 'Bestimmte Arbeitstag(e) erfolgreich aktualisiert.',
      SpecificWorkDayDeleted: 'Bestimmte Arbeitstag(e) erfolgreich gelöscht.',
      Staff: 'Personal',
      'Staff Name': 'Name des Personals',
      StaffBased: 'Personalbasiert',
      StaffDetails: 'Personaldetails',
      StaffNotFind: 'Personal wurde nicht gefunden!',
      StaffPreview: 'Personalvorschau',
      Staffs: 'Mitarbeiter',
      StartDate: 'Startdatum',
      StartTime: 'Startzeit',
      StartTimeEarlier: 'Die Startzeit sollte früher als die Endzeit liegen.',
      StartTimeRequired: 'Startzeit ist erforderlich.',
      State: 'Bundesland',
      Statistics: 'Statistiken',
      Status: 'Status',
      Sun: 'So',
      Sunday: 'Sonntag',
      TableHeader: {
        Actions: 'Optionen',
        BreakTime: 'Pause',
        Date: 'Datum',
        Description: 'Beschreibung',
        Staff: 'Personal',
        Time: 'Arbeitszeiten',
      },
      ThankYou: 'Senden Sie dem Kunden eine Dankesnachricht',
      ThisMonth: 'Diesen Monat',
      ThisServiceHas: 'Dieser Dienst hat ',
      ThisWeek: 'Diese Woche',
      Thu: 'Do',
      Thursday: 'Donnerstag',
      Time: 'Zeit',
      TimeZone: 'Zeitzone',
      Tin: 'TIN',
      Title: 'Titel',
      To: 'Bis',
      Today: 'Heute',
      Tomorrow: 'Morgen',
      Total: 'Gesamt',
      TotalPayments: 'Einnahmen',
      Tue: 'Di',
      Tuesday: 'Dienstag',
      Upcoming: 'Bevorstehend',
      UpcomingAppointments: 'Bevorstehende Termine',
      UpcomingAppointmentServiceModalText: ' bevorstehenden Termin(e).',
      upcomingAppointmentWith: 'Die bevorstehenden Termine mit',
      Update: 'Aktualisieren',
      UpdatedSuccess: 'Aktualisiert erfolgreich.',
      Uppercase: 'Großbuchstaben',
      UserNotBreak: 'Benutzer macht in den ausgewählten Stunden eine Pause',
      UserNotWork: 'Benutzer arbeitet nicht in den ausgewählten Stunden',
      ValidAppointment: 'Gültige Terminfelder',
      VerificationCode: 'Bestätigungscode',
      Visits: 'Besuche',
      WalkIn: 'Walk-In',
      WalkInDesc: 'Wählen Sie Walk-In aus, wenn Sie den Kunden überspringen möchten.',
      WebSite: 'URL der Unternehmenswebsite',
      Wed: 'Mi',
      Wednesday: 'Mittwoch',
      Weekly: 'Wöchentlich',
      WeWillSendVerificationCode: 'Wir werden Ihnen den 6-stelligen Bestätigungscode senden.',
      willBeCancelled: ' wird storniert.',
      WithDrawInvitation: 'Einladung zurückziehen',
      'Work Schedule': 'Arbeitsplan',
      WorkDateEndTimeRequired: 'Arbeitsende ist erforderlich.',
      WorkDateStartTimeRequired: 'Arbeitsbeginn ist erforderlich.',
      WorkdayEnd: 'Arbeitsende',
      WorkdayStart: 'Arbeitsbeginn',
      WorkingScheduleTab: {
        Company: 'Unternehmen',
        General: 'Allgemein',
        Staff: 'Personal',
      },
      WorkSchedule: 'Arbeitsplan',
      Yes: 'Ja',
      ZipCode: 'Postleitzahl',
    },
  },
};
export default resources;
