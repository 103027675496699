import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { serviceHeadCells } from 'Modules/constants';
import Header from './header';
import Body from './body';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableCell-body': {
      padding: '7px 16px',
    },
  },
  paginationBox: {
    borderTop: '1px solid #E3E5E6',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
});

function ServicesTable({
  selectedCategoryServices, loading, page, setPage,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-label="simple table"
          size="medium"
        >
          <Header
            headCells={serviceHeadCells}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={selectedCategoryServices.length}
          />
          <Body
            selectedCategoryServices={selectedCategoryServices}
            rowsPerPage={rowsPerPage}
            page={page}
            order={order}
            orderBy={orderBy}
            loading={loading}
          />
        </Table>
      </TableContainer>
      {selectedCategoryServices.length > 5 && (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={selectedCategoryServices.length}
        labelRowsPerPage={t('RowsPerPage')}
        labelDisplayedRows={({ count, from, to }) => `${from}-${to}/${count}`}
        rowsPerPage={rowsPerPage}
        page={page}
        onRowsPerPageChange={handleChangeRowsPerPage}
        onPageChange={handleChangePage}
        className={classes.paginationBox}
      />
      )}
    </>
  );
}

ServicesTable.propTypes = {
  selectedCategoryServices: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
};

export default ServicesTable;
