import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { connect } from 'react-redux';
import EditServiceItem from './editServiceItem';
import { checkAppointmentsByServiceIdRequest } from '../../../../../../../../redux/appointment/actions';
import usePrevious from '../../../../../../../../CustomHooks/usePrevious';

function ServiceItem(props) {
  const {
    services,
    setServices,
    serviceId,
    companyId,
    subService,
    industryId,
    setServiceIds,
    staffOptions,
    setSelectedServiceParentId,
    setSelectedCompanyServiceId,
    setOpenDeleteServiceModal,
    companyServices,
    setCompanyServices,
    setFilteredAppointments,
    checkAppointmentsByServiceId,
    isCheckAppointmentByServiceIdSuccess,
    appointments,
  } = props;

  // Get some props previous values
  const prevIsCheckAppointmentByServiceIdSuccess = usePrevious(isCheckAppointmentByServiceIdSuccess);

  const [serviceOptions, setServiceOptions] = useState([]);
  const [editAble, setEditAble] = useState(false);

  // Check Appointments Success
  useEffect(() => {
    if (prevIsCheckAppointmentByServiceIdSuccess === false && isCheckAppointmentByServiceIdSuccess) {
      const filteredAppointments = appointments.filter((appointment) => appointment.status.id === 1 || appointment.status.id === 2);
      setFilteredAppointments(filteredAppointments);
    }
  }, [isCheckAppointmentByServiceIdSuccess]);

  useEffect(() => {
    if (!subService.company_service_id) {
      setEditAble(true);
    }
  }, [subService]);

  useEffect(() => {
    const service = services.find((service) => service.id === serviceId);
    const serviceOptionsData = [];

    if (service) {
      service.subServices.map((service) => {
        serviceOptionsData.push({
          id: service.id,
          logo: service.logo,
          value: service.id,
          label: service.name,
          user: service.user_id,
        });
      });
    }

    setServiceOptions(serviceOptionsData);
  }, [services]);

  const showDuration = (duration) => {
    const hours = Math.floor(duration / 60);
    const min = duration % 60;

    if (hours === 0) {
      return `${min} min`;
    } else if (min === 0) {
      return `${hours} hour`;
    } else {
      return `${hours} hr ${min} min`;
    }
  };

  const removeService = (companyServiceId, serviceParentId) => {
    checkAppointmentsByServiceId({
      service_id: companyServiceId,
    });
    setSelectedCompanyServiceId(companyServiceId);
    setSelectedServiceParentId(serviceParentId);
    setOpenDeleteServiceModal(true);
    setServiceIds([]);
  };

  return (
    <>
      {editAble ? (
        <EditServiceItem
          services={services}
          setServices={setServices}
          companyId={companyId}
          companyServices={companyServices}
          subService={subService}
          staffOptions={staffOptions}
          serviceOptions={serviceOptions}
          setServiceOptions={setServiceOptions}
          serviceId={serviceId}
          setEditAble={setEditAble}
          setCompanyServices={setCompanyServices}
          industryId={industryId}
        />
      ) : (
        <Grid container direction="row" alignItems="center" spacing={3}>
          <Grid item xs={3}>
            { subService.name }
          </Grid>
          <Grid item xs={2} align="center">
            { showDuration(subService.duration) }
          </Grid>
          <Grid item xs align="center">
            { `${subService.price} AMD` }
          </Grid>
          <Grid item xs={3}>
            {subService.staffs && (
            <>
              {subService.staffs.slice(0, 2).map((staff, index) => (
                <span key={`${staff.first_name}-${index}`}>
                  { (index ? ', ' : '') } { staff.first_name } { staff.last_name }
                </span>
              ))}
              {subService.staffs.length > subService.staffs.slice(0, 2).length && (
              <span>
                {' '} + { subService.staffs.length - subService.staffs.slice(0, 2).length }
              </span>
              )}
            </>
            )}
          </Grid>
          <Grid item xs align="center">
            <IconButton
              aria-label="edit"
              onClick={() => setEditAble(true)}
              size="small"
            >
              <EditIcon fontSize="small" color="secondary" />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={() => removeService(subService.company_service_id, serviceId)}
              size="small"
            >
              <DeleteOutlineIcon fontSize="small" color="secondary" />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </>
  );
}

ServiceItem.propTypes = {
  companyId: PropTypes.object.isRequired,
  subService: PropTypes.object.isRequired,
  serviceId: PropTypes.number.isRequired,
  setServiceIds: PropTypes.func.isRequired,
  setSelectedServiceParentId: PropTypes.func.isRequired,
  setSelectedCompanyServiceId: PropTypes.func.isRequired,
  setOpenDeleteServiceModal: PropTypes.func.isRequired,
  companyServices: PropTypes.array.isRequired,
  setCompanyServices: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
  setServices: PropTypes.func.isRequired,
  staffOptions: PropTypes.array.isRequired,
  industryId: PropTypes.number.isRequired,
  setFilteredAppointments: PropTypes.func.isRequired,
  // Check Appointments
  appointments: PropTypes.object.isRequired,
  checkAppointmentsByServiceId: PropTypes.func.isRequired,
  isCheckAppointmentByServiceIdSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  // Check Appointments
  appointments: state.appointment.appointments,
  isCheckAppointmentByServiceIdError: state.appointment.isCheckAppointmentByServiceIdError,
  isCheckAppointmentByServiceIdSuccess: state.appointment.isCheckAppointmentByServiceIdSuccess,
});

function mapDispatchToProps(dispatch) {
  return {
    checkAppointmentsByServiceId: (data) => dispatch(checkAppointmentsByServiceIdRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceItem);
