import React from 'react';
import ReactDOM from 'react-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import App from './App';
import './index.css';
import './i18n/i18n';
import * as serviceWorker from './serviceWorker';

function pxToRem(value) {
  return `${value / 16}rem`;
}

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  breakpoints,
  overrides: {
    MuiInputBase: {
      root: {
        '&$disabled': {
          backgroundColor: '#f2f2f2',
        },
      },
    },

    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#979CA0',
      },
    },

    MuiTypography: {
      h1: {
        [breakpoints.down('sm')]: {
          fontSize: pxToRem(66),
        },
      },
      h2: {
        [breakpoints.down('sm')]: {
          fontSize: pxToRem(40),
        },
      },
      h3: {
        [breakpoints.down('sm')]: {
          fontSize: pxToRem(38),
        },
      },
      h4: {
        [breakpoints.down('sm')]: {
          fontSize: pxToRem(24),
        },
      },
      h5: {
        [breakpoints.down('sm')]: {
          fontSize: pxToRem(18),
        },
      },
      h6: {
        [breakpoints.down('sm')]: {
          fontSize: pxToRem(15),
        },
      },
      body1: {
        [breakpoints.down('sm')]: {
          fontSize: pxToRem(12),
        },
      },
      body2: {
        [breakpoints.down('sm')]: {
          fontSize: pxToRem(14),
        },
      },
    },

    MuiTableContainer: {
      root: {
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 1px 2px 0px',
      },
    },

    MuiSelect: {
      selectMenu: {
        minHeight: 0,
      },
      select: {
        '&:focus': {
          background: 'transparent !important',
        },
      },
    },

    MuiDialog: {
      paperWidthSm: {
        maxWidth: '500px',
      },
    },

    MuiFormControl: {
      root: {
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            borderColor: 'rgba(0,0,0,.23)',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#0282DA',
          },
        },
      },
    },

    MuiButton: {
      root: {
        textTransform: 'capitalize',
      },
    },

    MuiMenuItem: {
      root: {
        color: '#000000',
      },
    },

    MuiTab: {
      root: {
        textTransform: 'capitalize',
        fontFamily: '\'Nunito Sans\', sans-serif',
        fontWeight: 600,
      },
    },
  },

  palette: {
    primary: {
      main: '#0282DA', // This is a global primary color
    },
    secondary: {
      main: '#A4A4A4',
    },
    secondaryRed: {
      red: '#dc004e',
    },
  },

  typography: {
    h6: {
      fontWeight: 600,
      fontFamily: '\'Nunito Sans\', sans-serif',
    },
    h5: {
      '&:focus': {
        outline: 'none',
      },
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 16,
    },
  },
});

ReactDOM.render(<ThemeProvider theme={theme}><App /></ThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
