import React, { useState, useEffect } from 'react';
import {
  InputLabel,
  Select,
  TextField,
  Box,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  FormHelperText,
  Typography,
  IconButton,
  OutlinedInput,
  InputAdornment,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import { useDropzone } from 'react-dropzone';
import ErrorIcon from '@material-ui/icons/Error';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import TextMaskCustom from '../../../../../../Modules/TextMaskCustom';
import countryCodeOptions from '../../../../../../Modules/countryCodeOptions';
import {
  getCitiesRequest,
  getStatesRequest,
} from '../../../../../../redux/country/actions';
import {
  changePasswordRequest,
  updateAccountAvatarRequest,
  updateUserAccountRequest,
  getAvatarPreSignUrlRequest, uploadAvatarToAmazonRequest,
} from '../../../../../../redux/account/actions';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../../../../Modules/SnackbarToast';

const Profile = (props) => {
  const history = useHistory();

  // Translation
  const { t } = useTranslation();

  const {
    submitPerformed,
    sendSubmitFinished,
    userAccount,
    getStates,
    getCities,
    updateUserAccount,
    isUpdateUserAccountSuccess,
    isUpdateUserAccountError,
    isUpdateAccountAvatarSuccess,
    isUpdateAccountAvatarError,
    accountAvatar,
    updateAccountAvatarErrors,
    handleChangedProfileInfo,
    allCountries,
    user,
    changePassword,
    isChangePasswordSuccess,
    isChangePasswordError,
    changePasswordErrorMessage,
    getAvatarPreSignUrl,
    isGetAvatarPreSignedUrlSuccess,
    avatarPreSignUrl,
    uploadAvatarToAmazon,
  } = props;

  // Get some previous values
  const prevIsChangePasswordSuccess = usePrevious(isChangePasswordSuccess);
  const prevIsChangePasswordError = usePrevious(isChangePasswordError);
  const prevIsUpdateUserAccountSuccess = usePrevious(isUpdateUserAccountSuccess);
  const prevIsUpdateUserAccountError = usePrevious(isUpdateUserAccountError);
  const prevIsGetAvatarPreSignedUrlSuccess = usePrevious(isGetAvatarPreSignedUrlSuccess);

  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '165px',
  };

  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    height: 150,
    padding: 4,
    boxSizing: 'border-box',
  };

  const thumbInner = {
    position: 'relative',
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  };

  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const [account, setAccount] = useState({
    first_name: '',
    last_name: '',
    country: '',
    state: '',
    city: '',
    birthday: '',
    gender: '',
    phone_code: '',
    phone_number: '',
  });
  const [codeOptions, setCodeOptions] = useState([]);
  const [files, setFiles] = useState(null);
  const [updateUserAccountErrors, setUpdateUserAccountErrors] = useState({});
  const [avatarName, setAvatarName] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [email, setEmail] = useState(null);
  const [errors, setErrors] = useState({
    last_name: false,
    first_name: false,
    phone_number: false,
    city: false,
    country: false,
    state: false,
    phone_code: false,
    birthday: false,
    gender: false,
  });
  const [updateErrors, setUpdateErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [data, setData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  // Change password request
  useEffect(() => {
    if (prevIsChangePasswordSuccess === false && isChangePasswordSuccess) {
      snackBarAlert(true, 'Password changed', 'success');
      history.push('/profile');
    } else if (prevIsChangePasswordError === false && isChangePasswordError) {
      snackBarAlert(true, changePasswordErrorMessage, 'error');
      history.push('/login');
    }
  }, [isChangePasswordSuccess, isChangePasswordError]);

  // Avatar Pre-Sign Url Success
  useEffect(() => {
    if (prevIsGetAvatarPreSignedUrlSuccess === false && isGetAvatarPreSignedUrlSuccess) {
      uploadAvatarToAmazon({ preSignUrl: avatarPreSignUrl, logo: avatar });
    }
  }, [isGetAvatarPreSignedUrlSuccess]);

  // Handle when the get countries
  useEffect(() => {
    const codeOptions = countryCodeOptions.getCodeOptions(allCountries);
    setCodeOptions(codeOptions.length ? codeOptions : [{
      key: 'AM',
      label: { flagImageSrc: 'https://flagcdn.com/w20/am.png' },
      value: '+374',
    }]);
    setAccount({
      first_name: userAccount.first_name,
      last_name: userAccount.last_name,
      birthday: userAccount.birthday,
      gender: userAccount.gender,
      phone_code: userAccount.phone_code,
      phone_number: userAccount.phone_number,
    });
    setAvatar(accountAvatar || userAccount.avatar);
    setEmail(userAccount.email);
    if (userAccount.country && userAccount.state) {
      getStates(userAccount.country.id);
      getCities(userAccount.state.id);
      userAccount.country && (
        setAccount((prevState) => ({
          ...prevState,
          country: userAccount.country.id,
          state: userAccount.state.id,
          city: userAccount.city.id,
        }))
      );
    }
    const timeZones = moment.tz.names();
    const timeZoneNamesList = [];
    timeZones.forEach((item, i) => timeZoneNamesList.push(` (GMT${moment.tz(timeZones[i]).format('Z')})${timeZones[i]}`));
    if (userAccount.timezone) {
      setAccount((prevState) => ({
        ...prevState,
        timezone: userAccount.timezone,
      }));
    }
  }, []);

  // Perform when user account update success
  useEffect(() => {
    if (prevIsUpdateUserAccountSuccess === false && isUpdateUserAccountSuccess) {
      if (!avatar || files) {
        getAvatarPreSignUrl({ logo: avatarName });
      }
    }
  }, [isUpdateUserAccountSuccess]);

  // Perform when user account update error
  useEffect(() => {
    if (prevIsUpdateUserAccountError === false && isUpdateUserAccountError) {
      const errors = {};
      const keys = Object.keys(updateUserAccountErrors);
      keys.map((key) => {
        updateUserAccountErrors[key][0] = errors[key];
        return true;
      });
      setUpdateUserAccountErrors(errors);
      if (!keys.length) {
        snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
      }
    }
  }, [isUpdateUserAccountError]);

  // Check if update avatar success
  useEffect(() => {
    if (isUpdateAccountAvatarSuccess) {
      setAvatar(accountAvatar);
      setFiles(null);
      setUpdateErrors({});
    }
  }, [isUpdateAccountAvatarSuccess]);

  // Check if update avatar error
  useEffect(() => {
    if (isUpdateAccountAvatarError) {
      setUpdateErrors(updateAccountAvatarErrors);
    }
  }, [isUpdateAccountAvatarError]);

  useEffect(() => {
    if (submitPerformed) {
      const errors = validateForm();
      const keys = Object.keys(errors);
      const errorExists = keys.find((key) => errors[key]);
      if (!errorExists) {
        onSubmit();
      }
      sendSubmitFinished();
    }
  }, [submitPerformed]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccount((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    handleChangedProfileInfo({
      [name]: value,
    });
    setData({
      ...data,
      [name]: value,
    });
    setErrors((prevState) => ({
      ...prevState,
      [name]: false,
    }));
  };

  const handlePhoneCodeChange = (selectedCodeOption) => {
    // setSelectedCodeOption(selectedCodeOption);
    handleChangedProfileInfo({
      phone_code: selectedCodeOption,
    });
    handleChange(selectedCodeOption.target.value, 'phone_code');
  };

  const handleChange = (value, key) => {
    setAccount((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleBirthDayChange = (newDate) => {
    const birthday = newDate.target.value;
    setAccount((prevState) => ({
      ...prevState,
      birthday: birthday || null,
    }));
    handleChangedProfileInfo((prevState) => ({
      ...prevState,
      birthday: birthday || null,
    }));
    setErrors((prevState) => ({
      ...prevState,
      birthday: false,
    }));
  };

  const handleGenderChange = (e) => {
    const { value: gender } = e.target;
    setAccount((prevState) => ({
      ...prevState,
      gender,
    }));
    handleChangedProfileInfo(e, true);
  };

  const validateForm = () => {
    const errorsCopy = { ...errors };
    const keys = Object.keys(account);
    keys.map((key) => {
      switch (key) {
        case 'last_name': {
          errorsCopy.last_name = account.last_name && account.last_name.length === 0;
          break;
        }
        case 'first_name': {
          errorsCopy.first_name = account.first_name && account.first_name.length === 0;
          break;
        }
        case 'phone_code': {
          errorsCopy.phone_code = account.phone_code && account.phone_code.length === 0;
          break;
        }
        case 'phone_number': {
          errorsCopy.phone_number = account.phone_number && account.phone_number.length > 0 && account.phone_number.length < 12 ? t('PhoneNumber') + t('IsNotValid') : null;
          break;
        }
        default: {
          break;
        }
      }
    });
    setErrors(() => ({ ...errorsCopy }));
    return errorsCopy;
  };

  const onSubmit = () => {
    setErrors([]);
    setUpdateUserAccountErrors({});
    const logo = avatar ? moment().format('DDMMYYYYHHmmss') + avatar.name : null;
    setAvatarName(logo);
    const AccountCopy = {
      ...account,
      aws_avatar: logo,
      avatar: logo,
    };
    setAccount(AccountCopy);
    updateUserAccount(AccountCopy);
  };

  const removeUserAvatar = () => {
    setFiles(null);
    setAvatar(null);
    handleChangedProfileInfo({
      avatar: null,
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(URL.createObjectURL(acceptedFiles[0]));
      setAvatar(acceptedFiles[0]);
      handleChangedProfileInfo({
        avatar: acceptedFiles[0],
      });
    },
  });

  const thumbs = (
    <>
      {(files || avatar) && (
        <div className="thumb-style">
          <div style={thumb}>
            <div className="thumbInner" style={thumbInner}>
              <img
                src={files || (account ? avatar : null)}
                style={img}
                alt="avatar"
              />
              <div className="remove-industry-avatar">
                <DeleteIcon className="remove-icon" onClick={removeUserAvatar} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      changePassword({
        password: data.newPassword,
        password_confirmation: data.confirmPassword,
        old_password: user.old_password,
      });
    }
  };

  return (
    <Box p="30px" id="customer-info-page">
      <Grid container direction="row" spacing={4}>
        <Grid item xs={12} md={5}>
          <Box className="info-box">
            <Box>
              <Typography variant="subtitle1" className="info-box-title">
                {i18n.t('PersonalInformation')}
              </Typography>
            </Box>
            <Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={4}>
                  <Box className="add-logo-content" id="dropzone">
                    <div {...getRootProps({ className: files || avatar ? 'without-background' : 'dropzone' })}>
                      <input {...getInputProps()} />
                    </div>
                    <aside style={thumbsContainer}>
                      {thumbs}
                    </aside>
                  </Box>
                  { updateErrors.avatar && (
                    <>
                      {updateErrors.avatar.map((error) => (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText error>
                            { error }
                          </FormHelperText>
                        </div>
                      ))}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                      >
                        <TextField
                          fullWidth
                          size="small"
                          value={account.first_name}
                          onChange={(e) => handleInputChange(e)}
                          label={t('FirstName')}
                          variant="outlined"
                          data-cy="first-name"
                          id="first-name"
                          name="first_name"
                          inputProps={{ maxLength: 20 }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                      >
                        <TextField
                          fullWidth
                          size="small"
                          name="last_name"
                          value={account.last_name}
                          onChange={(e) => handleInputChange(e)}
                          label={t('LastName')}
                          variant="outlined"
                          data-cy="last-name"
                          id="last-name"
                          inputProps={{ maxLength: 20 }}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        size="small"
                        value={email}
                        label={email ? '' : email}
                        variant="outlined"
                        data-cy="email"
                        id="email"
                        name="email"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        error={(!!errors.phone_code)}
                      >
                        <InputLabel>{t('Code')}</InputLabel>
                        <Select
                          name="phone_code"
                          label={t('Code')}
                          onChange={handlePhoneCodeChange}
                          value={account.phone_code}
                        >
                          <MenuItem disabled value=""><em>{t('PhoneCode')}</em></MenuItem>
                          <Divider />
                          {codeOptions && codeOptions.map((option, index) => (
                            <MenuItem
                              key={`${option.id}-${index}`}
                              value={option.value}
                              selected={option.value}
                            >
                              <img
                                src={option.label.flagImageSrc}
                                alt="flag"
                                className="country-flag"
                              />
                              {option.key}
                              {option.value}
                            </MenuItem>
                          ))}
                        </Select>

                        {errors.phone_code && (
                          <div className="error-message-content">
                            <ErrorIcon fontSize="small" color="error" />
                            <FormHelperText>
                              {t('PhoneCode') + t('IsRequired')}
                            </FormHelperText>
                          </div>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        error={(!!errors.phone_number)}
                      >
                        <TextField
                          error={(!!errors.phone_number)}
                          fullWidth
                          size="small"
                          id="phone-detail"
                          type="text"
                          value={account.phone_number}
                          onChange={handleInputChange}
                          name="phone_number"
                          label={t('PhoneNumber')}
                          variant="outlined"
                          InputProps={{
                            inputComponent: TextMaskCustom,
                          }}
                        />

                        {errors.phone_number && (
                          <div className="error-message-content">
                            <ErrorIcon fontSize="small" color="error" />
                            <FormHelperText>
                              {errors.phone_number}
                            </FormHelperText>
                          </div>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                      >
                        <InputLabel>{t('Gender')}</InputLabel>
                        <Select
                          onChange={handleGenderChange}
                          label={t('Gender')}
                          id="select-gender"
                          name="gender"
                          value={account.gender}
                        >
                          <MenuItem value="" />
                          <MenuItem value="male">{t('Male')}</MenuItem>
                          <MenuItem value="female">{t('Female')}</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                      >
                        <TextField
                          label={t('Date')}
                          type="date"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          size="small"
                          id="birth_day"
                          name="birth_day"
                          value={account.birthday}
                          onChange={(date) => handleBirthDayChange(date)}
                          variant="outlined"
                          inputProps={{ max: format(new Date(), 'yyyy-MM-dd') }}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box className="info-box">
            <Box>
              <Typography variant="subtitle1" className="info-box-title">
                {t('PasswordSettings')}
              </Typography>
            </Box>
            <Box>
              <form onSubmit={(e) => handleSubmit(e)}>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="current-password">{t('CurrentPassword')}</InputLabel>
                      <OutlinedInput
                        type={showCurrentPassword ? 'text' : 'password'}
                        id="current-password"
                        name="oldPassword"
                        label={t('CurrentPassword')}
                        value={data.oldPassword}
                        onChange={handleInputChange}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="toggle password visibility"
                              onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                              edge="end"
                            >
                              {showCurrentPassword ? <Visibility color="primary" fontSize="small" /> : <VisibilityOff color="secondary" fontSize="small" />}
                            </IconButton>
                          </InputAdornment>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="new-password">{t('NewPassword')}</InputLabel>
                      <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        id="new-password"
                        name="newPassword"
                        label={t('NewPassword')}
                        value={data.newPassword}
                        onChange={handleInputChange}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              size="small"
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <Visibility color="primary" fontSize="small" /> : <VisibilityOff color="secondary" fontSize="small" />}
                            </IconButton>
                          </InputAdornment>
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth variant="outlined" margin="dense">
                      <InputLabel htmlFor="confirm-password">{t('ConfirmPassword')}</InputLabel>
                      <OutlinedInput
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="confirm-password"
                        name="confirmPassword"
                        label={t('ConfirmPassword')}
                        value={data.confirmPassword}
                        onChange={handleInputChange}
                        endAdornment={(
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                              edge="end"
                            >
                              {showConfirmPassword ? <Visibility color="primary" fontSize="small" /> : <VisibilityOff color="secondary" fontSize="small" />}
                            </IconButton>
                          </InputAdornment>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </Box>
  );
};

Profile.propTypes = {
  submitPerformed: PropTypes.bool.isRequired,
  sendSubmitFinished: PropTypes.func.isRequired,
  // Get User Account
  userAccount: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  // Get countries
  allCountries: PropTypes.array.isRequired,
  // Get states
  isGetStatesSuccess: PropTypes.bool.isRequired,
  states: PropTypes.array.isRequired,
  getStates: PropTypes.func.isRequired,
  // Get cities
  isGetCitiesSuccess: PropTypes.bool.isRequired,
  cities: PropTypes.array.isRequired,
  getCities: PropTypes.func.isRequired,
  // Update User Account
  updateUserAccount: PropTypes.func.isRequired,
  isUpdateUserAccountSuccess: PropTypes.bool.isRequired,
  isUpdateUserAccountError: PropTypes.bool.isRequired,
  updateUserAccountErrors: PropTypes.object.isRequired,
  updateAccountAvatar: PropTypes.func.isRequired,
  isUpdateAccountAvatarSuccess: PropTypes.bool.isRequired,
  isUpdateAccountAvatarError: PropTypes.bool.isRequired,
  accountAvatar: PropTypes.string.isRequired,
  updateAccountAvatarErrors: PropTypes.object.isRequired,
  // Changed Profile Info
  handleChangedProfileInfo: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  isChangePasswordSuccess: PropTypes.bool.isRequired,
  isChangePasswordError: PropTypes.bool.isRequired,
  changePasswordErrorMessage: PropTypes.string.isRequired,
  // Amazon Aws S3
  getAvatarPreSignUrl: PropTypes.func.isRequired,
  isGetAvatarPreSignedUrlSuccess: PropTypes.bool.isRequired,
  isGetAvatarPreSignedUrlError: PropTypes.bool.isRequired,
  avatarPreSignUrl: PropTypes.string.isRequired,
  uploadAvatarToAmazon: PropTypes.func.isRequired,
  isUploadAvatarToAmazonSuccess: PropTypes.bool.isRequired,
  isUploadImageToAmazonError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  // User Account
  userAccount: state.account.userAccount,
  // Get countries
  allCountries: state.country.allCountries,
  // Get states
  isGetStatesSuccess: state.country.isGetStatesSuccess,
  states: state.country.states,
  // Get cities
  isGetCitiesSuccess: state.country.isGetCitiesSuccess,
  cities: state.country.cities,
  // Updated Account
  isUpdateUserAccountSuccess: state.account.isUpdateUserAccountSuccess,
  isUpdateUserAccountError: state.account.isUpdateUserAccountError,
  updateUserAccountErrors: state.account.updateUserAccountErrors,
  isUpdateAccountAvatarSuccess: state.account.isUpdateAccountAvatarSuccess,
  isUpdateAccountAvatarError: state.account.isUpdateAccountAvatarError,
  accountAvatar: state.account.accountAvatar,
  updateAccountAvatarErrors: state.account.updateAccountAvatarErrors,
  isChangePasswordSuccess: state.account.isChangePasswordSuccess,
  isChangePasswordError: state.account.isChangePasswordError,
  changePasswordErrorMessage: state.account.changePasswordErrorMessage,
  // Amazon Aws Cloud
  getAvatarPreSignUrl: state.account.getAvatarPreSignUrl,
  isGetAvatarPreSignedUrlSuccess: state.account.isGetAvatarPreSignedUrlSuccess,
  isGetAvatarPreSignedUrlError: state.account.isGetAvatarPreSignedUrlError,
  avatarPreSignUrl: state.account.avatarPreSignUrl,
  uploadAvatarToAmazon: state.account.uploadAvatarToAmazon,
  isUploadAvatarToAmazonSuccess: state.account.isUploadAvatarToAmazonSuccess,
  isUploadImageToAmazonError: state.account.isUploadAvatarToAmazonSuccess,
});

function mapDispatchToProps(dispatch) {
  return {
    getStates: (data) => dispatch(getStatesRequest(data)),
    getCities: (data) => dispatch(getCitiesRequest(data)),
    updateUserAccount: (data) => dispatch(updateUserAccountRequest(data)),
    updateAccountAvatar: (data) => dispatch(updateAccountAvatarRequest(data)),
    changePassword: (data) => dispatch(changePasswordRequest(data)),
    getAvatarPreSignUrl: (data) => dispatch(getAvatarPreSignUrlRequest(data)),
    uploadAvatarToAmazon: (data) => dispatch(uploadAvatarToAmazonRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
