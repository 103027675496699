import { put, select, takeLatest } from 'redux-saga/effects';
import axiosInstance from '../../Config/ServerConfig';
import {
  addCustomServiceError,
  addCustomServiceRequest,
  addCustomServiceSuccess,
  addServiceError,
  addServiceRequest,
  addServiceSuccess,
  deleteCompanyServiceError,
  deleteCompanyServiceRequest,
  deleteCompanyServiceSuccess,
  deleteIndividualUserServiceError,
  deleteIndividualUserServiceRequest,
  deleteIndividualUserServiceSuccess,
  deleteServiceByIdError,
  deleteServiceByIdRequest,
  deleteServiceByIdSuccess,
  deleteServiceError,
  deleteServiceRequest,
  deleteServiceSuccess,
  detachStaffServiceError,
  detachStaffServiceRequest,
  detachStaffServiceSuccess,
  getByIndustryIdError,
  getByIndustryIdRequest,
  getByIndustryIdSuccess,
  getCompanyServicesWithSubServicesError,
  getCompanyServicesWithSubServicesRequest,
  getCompanyServicesWithSubServicesSuccess,
  getIndividualServicesWithSubServicesError,
  getIndividualServicesWithSubServicesRequest,
  getIndividualServicesWithSubServicesSuccess,
  getIndustryServicesError,
  getIndustryServicesRequest,
  getIndustryServicesSuccess,
  getServiceByIdError,
  getServiceByIdRequest,
  getServiceByIdSuccess,
  getServicesByCompanyError,
  getServicesByCompanyRequest,
  getServicesByCompanySuccess,
  getServicesByIndividualUserIdError,
  getServicesByIndividualUserIdRequest,
  getServicesByIndividualUserIdSuccess,
  getServiceWithStaffError,
  getServiceWithStaffRequest,
  getServiceWithStaffSuccess,
  updateCompanyServiceError,
  updateCompanyServiceRequest,
  updateCompanyServiceSuccess,
  updateIndividualUserServiceError,
  updateIndividualUserServiceRequest,
  updateIndividualUserServiceSuccess,
  updateServiceError,
  updateServiceRequest,
  updateServiceSuccess,
} from './actions';

const servicesByCompany = (state) => state.service.servicesByCompany;
const allServices = (state) => state.service.services;

function* getIndustryServices() {
  try {
    const response = yield axiosInstance.get('services');
    if (response && response.status === 200) {
      yield put(getIndustryServicesSuccess(response.data));
    }
  } catch (e) {
    yield put(getIndustryServicesError());
  }
}

function* addService(action) {
  const { payload } = action;
  let url = 'company/m/staff/service';
  if (payload.individual_user_industry_id) {
    url = 'services';
  }
  try {
    const response = yield axiosInstance.post(url, payload);
    if (response && (response.status === 202 || response.status === 200)) {
      const companyServices = yield select(servicesByCompany);
      const updatedData = response.data.data;
      if (Object.keys(updatedData).length) {
        const updatedCompanyService = companyServices.find((category) => category.id === updatedData.id);
        if (updatedCompanyService) {
          updatedCompanyService.subServices = updatedData.subServices;
        } else {
          companyServices.push(updatedData);
        }
      }

      yield put(addServiceSuccess(response.data));
    } else {
      yield put(addServiceError(response.response.data));
    }
  } catch (e) {
    yield put(addServiceError(e.response.data));
  }
}

function* deleteService(action) {
  const { id } = action.payload;
  try {
    const response = yield axiosInstance.delete(`services/${id}`);
    if (response && response.status === 202) {
      yield put(deleteServiceSuccess());
    } else {
      yield put(deleteServiceError(response.response.data));
    }
  } catch (e) {
    yield put(deleteServiceError(e.response.data));
  }
}

function* deleteServiceById(action) {
  const id = action.payload;
  try {
    const response = yield axiosInstance.delete(`company/service/${id}`);
    if (response && response.status === 202) {
      yield put(deleteServiceByIdSuccess());
    } else {
      yield put(deleteServiceByIdError());
    }
  } catch (e) {
    yield put(deleteServiceByIdError(e.response));
  }
}

function* detachStaffService(action) {
  const { payload } = action;
  const url = 'detach-service';
  try {
    const response = yield axiosInstance.post(url, payload);
    if (response && (response.status === 202 || response.status === 200)) {
      yield put(detachStaffServiceSuccess());
    } else if (response.response && response.status === 422) {
      yield put(detachStaffServiceError(response.response.data.message));
    } else {
      yield put(detachStaffServiceError(response.response.data));
    }
  } catch (e) {
    yield put(detachStaffServiceError(e.response.data));
  }
}

function* updateService(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.put(`services/${payload.id}`, payload);
    if (response && response.status === 202) {
      yield put(updateServiceSuccess(payload));
    }
  } catch (e) {
    yield put(updateServiceError(e.response.data));
  }
}

function* getByIndustryId(action) {
  try {
    const { id } = action.payload;
    const response = yield axiosInstance.get(`industry-services/${id}`);
    if (response && response.status === 200) {
      yield put(getByIndustryIdSuccess(response.data));
    }
  } catch (e) {
    yield put(getByIndustryIdError(e.response.data));
  }
}

function* getById(action) {
  try {
    const { id } = action.payload;
    const response = yield axiosInstance.post(`general/services/${id}`);
    if (response && response.status === 200) {
      yield put(getServiceByIdSuccess(response.data));
    }
  } catch (e) {
    yield put(getServiceByIdError(e.response.data));
  }
}

function* getByCompany() {
  try {
    const response = yield axiosInstance.get('company/staff/service');
    if (response && response.status === 200) {
      yield put(getServicesByCompanySuccess(response.data));
    }
  } catch (e) {
    yield put(getServicesByCompanyError(e.response.data));
  }
}

function* deleteCompanyService(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('company/service/delete', payload);
    if (response && response.status === 202) {
      const services = yield select(servicesByCompany);
      const foundCategory = services.find((item) => item.id === payload.parent_id);
      const foundCategoryIndex = services.findIndex((item) => item.id === payload.parent_id);
      const categorySubServices = typeof foundCategory.subServices !== 'string' ? foundCategory.subServices : JSON.parse(foundCategory.subServices);
      let filteredServicesByCompany;
      if (categorySubServices.length === 1) {
        filteredServicesByCompany = services.filter((item) => item.id !== payload.parent_id);
      } else {
        const updatedCategoryServices = categorySubServices.filter((item) => item.company_service_id === payload.ids[0]);
        services[foundCategoryIndex].subServices = categorySubServices.filter((item) => item.company_service_id !== updatedCategoryServices[0].company_service_id);
        filteredServicesByCompany = services;
      }
      yield put(deleteCompanyServiceSuccess(filteredServicesByCompany));
    } else {
      yield put(deleteCompanyServiceError(response.response.data));
    }
  } catch (e) {
    yield put(deleteCompanyServiceError(e.response.data));
  }
}

function* updateCompanyService(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.put(`company/staff/service/${payload.company_service_id}`, payload);
    if (response && response.status === 200) {
      const updatedData = response.data.data;
      const services = yield select(servicesByCompany);
      const servicesAll = yield select(allServices);

      if (Object.keys(updatedData).length) {
        const updatedCategory = services.find((category) => category.id === updatedData.parent_id);
        const addedCategory = servicesAll.find((category) => category.id === updatedData.parent_id);

        if (Object.keys(updatedCategory).length) {
          const updatedService = typeof updatedCategory.subServices === 'string' ? updatedCategory.subServices = JSON.parse(updatedCategory.subServices) : updatedCategory.subServices;
          const updatedServiceIndex = updatedService.findIndex((item) => item.company_service_id === updatedData.company_service_id);

          if (updatedServiceIndex !== -1) {
            updatedService[updatedServiceIndex] = updatedData;
            updatedCategory.subServices = JSON.stringify(updatedService);
          }
        }

        if (addedCategory !== undefined && Object.keys(addedCategory).length) {
          if (addedCategory.subServices.length) {
            const categorySubService = addedCategory.subServices.find((service) => service.id === updatedData.id);
            if (!categorySubService) {
              addedCategory.subServices.push(updatedData);
            }
          }
        }
      }

      yield put(updateCompanyServiceSuccess(response.data));
    }
  } catch (e) {
    yield put(updateCompanyServiceError(e.response.data));
  }
}

function* getServicesWithSubServices(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.get(`company/staff/services?staff_id=${payload.staff_id}`);
    if (response && response.status === 200) {
      yield put(getCompanyServicesWithSubServicesSuccess(response.data));
    }
  } catch (e) {
    yield put(getCompanyServicesWithSubServicesError(e.response.data));
  }
}

function* getByIndividualUserId(action) {
  try {
    const { individualUserIndustryId, industryId } = action.payload;
    const response = yield axiosInstance.get(`service?individual_user_industry_id=${individualUserIndustryId}&industry_id=${industryId}`);
    if (response && response.status === 200) {
      yield put(getServicesByIndividualUserIdSuccess(response.data));
    }
  } catch (e) {
    yield put(getServicesByIndividualUserIdError(e.response.data));
  }
}

function* getServicesByIndividualUserId(action) {
  try {
    const { individualUserIndustryId, industryId } = action.payload;
    const response = yield axiosInstance.get(`individual-service?individual_user_industry_id=${individualUserIndustryId}&industry_id=${industryId}`);
    if (response && response.status === 200) {
      yield put(getIndividualServicesWithSubServicesSuccess(response.data));
    }
  } catch (e) {
    yield put(getIndividualServicesWithSubServicesError(e.response.data));
  }
}

function* updateIndividualUserService(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.put(`services/${payload.individual_user_industry_service_id}`, payload);
    if (response && response.status === 200) {
      yield put(updateIndividualUserServiceSuccess(response.data));
    }
  } catch (e) {
    yield put(updateIndividualUserServiceError(e.response.data));
  }
}

function* deleteIndividualUserService(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('services/delete', payload);
    if (response && response.status === 202) {
      yield put(deleteIndividualUserServiceSuccess());
    } else {
      yield put(deleteIndividualUserServiceError(response.response.data));
    }
  } catch (e) {
    yield put(deleteIndividualUserServiceError(e.response.data));
  }
}

function* getServicesWithStaff(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.get(`company/service/staff/${payload}`);
    if (response && response.status === 200) {
      yield put(getServiceWithStaffSuccess(response.data));
    }
  } catch (e) {
    yield put(getServiceWithStaffError(e.response.data));
  }
}

function* addCustomService(action) {
  const response = yield axiosInstance.post('/services', action.payload);
  try {
    if (response && response.status === 200) {
      yield put(addCustomServiceSuccess(response.data));
    } else {
      yield put(addCustomServiceError(response.response.data));
    }
  } catch (e) {
    yield put(addCustomServiceError(e.response.data));
  }
}

export default function* () {
  yield takeLatest(getIndustryServicesRequest, getIndustryServices);
  yield takeLatest(addServiceRequest, addService);
  yield takeLatest(detachStaffServiceRequest, detachStaffService);
  yield takeLatest(updateServiceRequest, updateService);
  yield takeLatest(deleteServiceRequest, deleteService);
  yield takeLatest(deleteServiceByIdRequest, deleteServiceById);
  yield takeLatest(getByIndustryIdRequest, getByIndustryId);
  yield takeLatest(getServiceByIdRequest, getById);
  yield takeLatest(getServicesByCompanyRequest, getByCompany);
  yield takeLatest(deleteCompanyServiceRequest, deleteCompanyService);
  yield takeLatest(updateCompanyServiceRequest, updateCompanyService);
  yield takeLatest(getCompanyServicesWithSubServicesRequest, getServicesWithSubServices);
  yield takeLatest(getServicesByIndividualUserIdRequest, getByIndividualUserId);
  yield takeLatest(updateIndividualUserServiceRequest, updateIndividualUserService);
  yield takeLatest(deleteIndividualUserServiceRequest, deleteIndividualUserService);
  yield takeLatest(getIndividualServicesWithSubServicesRequest, getServicesByIndividualUserId);
  yield takeLatest(getServiceWithStaffRequest, getServicesWithStaff);
  yield takeLatest(addCustomServiceRequest, addCustomService);
}
