import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import DeleteButton from '../../../MaterialComponents/deleteButton';
import {
  deleteIndividualCloseDayRequest,
} from '../../../redux/individualUser/actions';
import usePrevious from '../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../Modules/SnackbarToast';

function DeleteCloseDayModal(props) {
  const dispatch = useDispatch();

  const {
    isDeleteIndividualCloseDaySuccess,
    isDeleteIndividualCloseDayError,
    deleteIndividualCloseDayErrorMessage,
  } = useSelector((state) => state.individualUser);

  const {
    open,
    onClose,
    closeDayId,
    allCloseDays,
    setAllCloseDays,
    setIndividualUserIndustryItem,
  } = props;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous values
  const prevIsDeleteIndividualCloseDaySuccess = usePrevious(isDeleteIndividualCloseDaySuccess);
  const prevIsDeleteIndividualCloseDayError = usePrevious(isDeleteIndividualCloseDayError);

  // Handle delete Individual day off success
  useEffect(() => {
    if (prevIsDeleteIndividualCloseDaySuccess === false && isDeleteIndividualCloseDaySuccess) {
      const allCloseDaysCopy = [...allCloseDays];
      const closeDayIndex = allCloseDaysCopy.findIndex((item) => item.id === closeDayId);
      allCloseDaysCopy.splice(closeDayIndex, 1);
      setIndividualUserIndustryItem((prevState) => ({
        ...prevState,
        dayOffs: allCloseDaysCopy,
      }));
      setAllCloseDays(allCloseDaysCopy);
      onClose();
      snackBarAlert(true, i18n.t('ClosedDayDeleted'), 'success');
    }
  }, [isDeleteIndividualCloseDaySuccess]);

  // Handle delete Individual day off error
  useEffect(() => {
    if (prevIsDeleteIndividualCloseDayError === false && isDeleteIndividualCloseDayError) {
      snackBarAlert(true, deleteIndividualCloseDayErrorMessage, 'error');
    }
  }, [isDeleteIndividualCloseDayError]);

  const handleConfirmDeleteDayOff = () => {
    dispatch(deleteIndividualCloseDayRequest({ id: closeDayId }));
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  return (
    <div>
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span className="alert-title">
            <span>{i18n.t('ConfirmDeletion')}</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {i18n.t('AreYouSureDelete')} close day?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onClose}
          >
            {i18n.t('Cancel')}
          </Button>
          <DeleteButton
            size="small"
            variant="contained"
            color="primary"
            onClick={handleConfirmDeleteDayOff}
          >
            {i18n.t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  );
}

DeleteCloseDayModal.propTypes = {
  // Parent Props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  closeDayId: PropTypes.number.isRequired,
  allCloseDays: PropTypes.object.isRequired,
  setAllCloseDays: PropTypes.func.isRequired,
  setIndividualUserIndustryItem: PropTypes.func.isRequired,
};

export default DeleteCloseDayModal;
