const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const filterStyle = {
  MenuProps: {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  },
};

export default filterStyle;
