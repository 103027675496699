import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import i18n from 'i18next';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import { getIndustriesRequest } from '../../../../../../../redux/industry/actions';
import countryCodeOptions from '../../../../../../../Modules/countryCodeOptions';
import { updateIndividualUserIndustryRequest } from '../../../../../../../redux/individualUser/actions';
import Loading from '../../../../../../../Components/Loading/loading';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';

function Industry(props) {
  const {
    individualUserIndustryItem,
    industries,
    getIndustries,
    isGetIndustriesSuccess,
    isGetIndustriesError,
    allCountries,
    isUpdateIndividualUserIndustrySubmitDone,
    updateIndividualUserIndustry,
    updatedIndividualUserIndustry,
    isUpdateIndividualUserIndustrySuccess,
    isUpdateIndividualUserIndustryError,
    sendIndividualUserIndustryUpdated,
    updateIndividualUserIndustryErrorMessage,
  } = props;

  const prevIsGetIndustriesSuccess = usePrevious(isGetIndustriesSuccess);
  const prevIsGetIndustriesError = usePrevious(isGetIndustriesError);
  const prevIsUpdateIndividualUserIndustrySuccess = usePrevious(isUpdateIndividualUserIndustrySuccess);
  const prevIsUpdateIndividualUserIndustryError = usePrevious(isUpdateIndividualUserIndustryError);

  const [loading, setLoading] = useState(true);
  const [individualUserIndustry, setIndividualUserIndustry] = useState([]);
  const [industryOptions, setIndustryOptions] = useState([]);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState({});
  const [selectedCodeOption, setSelectedCodeOption] = useState({});
  const [codeOptions, setCodeOptions] = useState([]);
  const [errors, setErrors] = useState({
    industry: false,
    phone_code: false,
    phone_number: false,
  });
  const [updateErrors, setUpdateErrors] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Perform when function gets mounted
  useEffect(() => {
    const codeOptions = countryCodeOptions.getCodeOptions(allCountries);
    setCodeOptions(codeOptions);
    if (individualUserIndustryItem) {
      setIndividualUserIndustry(individualUserIndustryItem);
      const selectedCodeOption = codeOptions.find((item) => item.value === individualUserIndustryItem.phone_code);
      setSelectedCodeOption(selectedCodeOption);
      getIndustries();
    }
  }, []);

  useEffect(() => {
    if (prevIsGetIndustriesSuccess === false && isGetIndustriesSuccess) {
      const industryOptions = industries.map((industry) => ({
        value: industry.id,
        label: industry.name,
      }));
      const selectedIndustryOption = industryOptions.find((item) => item.value === parseInt(individualUserIndustry.industry_id));
      setIndustryOptions(industryOptions);
      setSelectedIndustryOption(selectedIndustryOption);
      setLoading(false);
    }
  }, [isGetIndustriesSuccess]);

  useEffect(() => {
    if (prevIsGetIndustriesError === false && isGetIndustriesError) {
      snackBarAlert(true, i18n.t('IndustriesNotFound'), 'error');
    }
  }, [isGetIndustriesError]);

  useEffect(() => {
    if (isUpdateIndividualUserIndustrySubmitDone) {
      const errors = validateForm();
      const keys = Object.keys(errors);
      const errorExists = keys.find((key) => errors[key]);
      if (!errorExists) {
        updateIndividualUserIndustry(individualUserIndustry);
      } else {
        sendIndividualUserIndustryUpdated(null);
      }
    }
  }, [isUpdateIndividualUserIndustrySubmitDone]);

  useEffect(() => {
    if (prevIsUpdateIndividualUserIndustrySuccess === false && isUpdateIndividualUserIndustrySuccess && updatedIndividualUserIndustry.id) {
      sendIndividualUserIndustryUpdated(updatedIndividualUserIndustry, true);
      setUpdateErrors([]);
      snackBarAlert(true, i18n.t('IndustryUpdated'), 'success');
    }
  }, [isUpdateIndividualUserIndustrySuccess]);

  useEffect(() => {
    if (prevIsUpdateIndividualUserIndustryError === false && isUpdateIndividualUserIndustryError) {
      snackBarAlert(true, updateIndividualUserIndustryErrorMessage, 'error');
    }
  }, [isUpdateIndividualUserIndustryError]);

  const validateForm = () => {
    const errorsCopy = { ...errors };
    const keys = Object.keys(individualUserIndustry);
    keys.map((key) => {
      switch (key) {
        case 'phone_number': {
          errorsCopy.phone_number = individualUserIndustry.phone_number.length < 6;
          break;
        }
        case 'phone_code': {
          errorsCopy.phone_code = individualUserIndustry.phone_code.length === 0;
          break;
        }
        case 'industry_id': {
          errorsCopy.industry = !(individualUserIndustry.industry_id);
          break;
        }
        default: {
          break;
        }
      }
    });
    setErrors(() => ({ ...errorsCopy }));
    return errorsCopy;
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleIndustrySelectChange = (selectedIndustryOption) => {
    setSelectedIndustryOption(selectedIndustryOption);
    setIndividualUserIndustry({
      ...individualUserIndustry,
      industry_id: selectedIndustryOption.value,
    });
  };

  const handlePhoneCodeSelectChange = (selectedPhoneCodeOption) => {
    setSelectedCodeOption(selectedPhoneCodeOption);
    setIndividualUserIndustry({
      ...individualUserIndustry,
      phone_code: selectedPhoneCodeOption.value,
    });
  };

  const checkIndividualUserIndustryServices = () => {
    if (Object.keys(individualUserIndustryItem)) {
      return (individualUserIndustryItem.services.length);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIndividualUserIndustry({
      ...individualUserIndustry,
      [name]: value,
    });
  };

  return (
    <div className="tab-item col-6">
      { !loading ? (
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <div className="business-details">
              <div className="main-form-content text-center">
                <div
                  className={checkIndividualUserIndustryServices() ? 'disable-industries form-group row mt-4' : 'form-group row mt-4'}
                >
                  <label htmlFor="businessIndustry" className="col-sm-3 col-form-label">Industry</label>
                  <div data-cy="select-industry" className="col-sm-9">
                    <Select
                      name="industry_id"
                      className="form-item-select industries"
                      value={selectedIndustryOption && selectedIndustryOption.value ? selectedIndustryOption : ''}
                      onChange={(e) => handleIndustrySelectChange(e)}
                      isDisabled={checkIndividualUserIndustryServices()}
                      options={checkIndividualUserIndustryServices() ? [] : industryOptions}
                    />
                  </div>
                </div>
                <div className="form-group row mt-4 justify-content-end">
                  <label htmlFor="address" className="col-3 col-form-label">Phone</label>
                  <div data-cy="phone-code" className="col-3 d-flex align-items-center">
                    <Select
                      value={selectedCodeOption && selectedCodeOption.value ? selectedCodeOption : ''}
                      className="form-item-select w-100 company-phone-code"
                      isSearchable={false}
                      onChange={(e) => handlePhoneCodeSelectChange(e)}
                      options={codeOptions}
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      data-cy="phone"
                      value={individualUserIndustry.phone_number || ''}
                      type="number"
                      placeholder="Phone Number *"
                      name="phone_number"
                      className={`form-item form-control mb-0${errors.phone_number ? ' is-invalid' : ''}`}
                      id="phone"
                      onChange={(e) => handleInputChange(e)}
                    />
                    {errors.phone_number && <p className="error-message">Phone number is too short</p> }
                    {updateErrors.phone_number && <p className="error-message">{ updateErrors.phone_number }</p> }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading />
      ) }
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  );
}

Industry.propTypes = {
  // Get Props from parent
  isUpdateIndividualUserIndustrySubmitDone: PropTypes.bool.isRequired,
  individualUserIndustryItem: PropTypes.object.isRequired,
  sendIndividualUserIndustryUpdated: PropTypes.func.isRequired,
  // Get Industries
  industries: PropTypes.array.isRequired,
  getIndustries: PropTypes.func.isRequired,
  isGetIndustriesSuccess: PropTypes.bool.isRequired,
  isGetIndustriesError: PropTypes.bool.isRequired,
  // Get Countries
  allCountries: PropTypes.array.isRequired,
  // Get update Individual User Industry Props
  updateIndividualUserIndustry: PropTypes.func.isRequired,
  isUpdateIndividualUserIndustrySuccess: PropTypes.bool.isRequired,
  isUpdateIndividualUserIndustryError: PropTypes.bool.isRequired,
  updatedIndividualUserIndustry: PropTypes.object.isRequired,
  updateIndividualUserIndustryErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // Get Industries
  industries: state.industry.industries,
  isGetIndustriesSuccess: state.industry.isGetIndustriesSuccess,
  isGetIndustriesError: state.industry.isGetIndustriesError,
  // Get Countries
  allCountries: state.country.allCountries,
  // Get update Individual User Industry Props
  isUpdateIndividualUserIndustrySuccess: state.individualUser.isUpdateIndividualUserIndustrySuccess,
  isUpdateIndividualUserIndustryError: state.individualUser.isUpdateIndividualUserIndustryError,
  updatedIndividualUserIndustry: state.individualUser.updatedIndividualUserIndustry,
  updateIndividualUserIndustryErrorMessage: state.individualUser.updateIndividualUserIndustryErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndustries: (data) => dispatch(getIndustriesRequest(data)),
    updateIndividualUserIndustry: (data) => dispatch(updateIndividualUserIndustryRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Industry);
