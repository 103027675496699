import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import {
  Avatar,
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCalendarCompanyStaffRequest } from 'redux/staff/actions';
import { filterAllAppointmentsRequest, getCalendarAppointmentsRequest } from 'redux/appointment/actions';
import { resourceTimeGridOptions } from 'Modules/constants';
import getServicePrice from 'Modules/getServicePrice';
import serviceDuration from 'Modules/serviceDuration';
import getInitials from 'Modules/getInitials';
import getColorsByAppointmentStatus from 'Modules/getColorsByAppointmentStatus';
import AddIcon from '@material-ui/icons/Add';
import FilterIcon from 'assets/icons/filter.svg';
import DefaultProfileImage from 'assets/img/default-profile.png';
import AppDatePicker from 'Modules/AppDatePicker';
import capitalize from 'Modules/capitalize';
import filterItems from 'Modules/AppointmentsFilterItems/personalAppointments';
import SnackbarToast from 'Modules/SnackbarToast';
import usePrevious from 'CustomHooks/usePrevious';
import { getUserSettingsRequest } from 'redux/account/actions';

import AppointmentsDrawer from '../MyAppointments/Drawer';
import AppointmentDetailsDrawer from '../MyAppointments/Drawer/appointmentDetails';
import AppointmentFilterDrawer from '../MyAppointments/FilterDrawer';
import CalendarContext from './calendarContext';
import './styles.scss';

// const businessHours = {
//   daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
//   startTime: '8:00:00',
//   endTime: '18:00:00',
// };

const CustomTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#393939',
    borderRadius: '4px',
    border: '1px solid #E9E9E9',
    padding: '1px',
    minWidth: 350,
    maxWidth: 350,
    width: '100%',
  },
  arrow: {
    fontSize: 20,
    '&::before': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #E9E9E9',
    },
  },
}))(Tooltip);

export default function NewCalendar() {
  const { t } = useTranslation();
  const calendarRef = useRef(null);
  const dispatch = useDispatch();

  const {
    calendarCompanyStaff,
  } = useSelector((state) => state.staff);

  const {
    companyGot,
  } = useSelector((state) => state.company);

  const {
    isGetCalendarAppointmentsSuccess,
    isGetCalendarAppointmentsError,
    getCalendarAppointmentsErrorMessage,
    calendarAppointments,
    filteredAllAppointments,
    isFilterAllAppointmentsSuccess,
    newAppointment,
    updatedAppointment,
    isStoreAppointmentSuccess,
    isUpdateAppointmentSuccess,
  } = useSelector((state) => state.appointment);

  const { userSettings } = useSelector((state) => state.account);

  const [selectedTimeGrid, setSelectedTimeGrid] = useState('');
  const [selectedDate, setSelectedDate] = useState(localStorage.getItem('calendarDate') || moment(new Date()).format('Y-MM-DD'));
  const [selectedHour, setSelectedHour] = useState('');
  const [staffId, setStaffId] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [appointment, setAppointment] = useState({});
  const [isShowAppointmentDrawer, setIsShowAppointmentDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(moment(selectedDate).format('YYYY-MM-DD'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment(selectedDate).add(6, 'months').format('YYYY-MM-DD'));
  const [width, setWidth] = useState(moment(localStorage.getItem('calendarDate') || new Date()).format('MMMM d, yyyy').length + 5);
  const [checkedItems, setCheckedItems] = useState({
    status: [1, 2, 4],
    payment: ['paid', 'unpaid'],
    date: {
      from: selectedStartDate,
      to: selectedEndDate,
    },
  });
  const [isShowAppointmentDetailsDrawer, setIsShowAppointmentDetailsDrawer] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [, setSelectedAppointmentId] = useState(null);

  // Get prev values
  const prevIsStoreAppointmentSuccess = usePrevious(isStoreAppointmentSuccess);
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);
  const prevIsFilterAllAppointmentsSuccess = usePrevious(isFilterAllAppointmentsSuccess);
  const prevIsGetCalendarAppointmentsSuccess = usePrevious(isGetCalendarAppointmentsSuccess);
  const prevIsGetCalendarAppointmentsError = usePrevious(isGetCalendarAppointmentsError);

  const todayTitle = useMemo(() => {
    switch (selectedTimeGrid) {
      case 'resourceTimeGridWeek':
        return 'ThisWeek';
      case 'resourceDayGridMonth':
        return 'ThisMonth';
      default:
        return 'Today';
    }
  }, [selectedTimeGrid]);

  const isTodayDisabled = useMemo(() => {
    const date = moment(selectedDate);

    switch (selectedTimeGrid) {
      case 'resourceTimeGridWeek': {
        const startOfWeek = moment().startOf('week');
        const endOfWeek = moment().endOf('week');
        return date.isBetween(startOfWeek, endOfWeek, 'day', '[]');
      }
      case 'resourceDayGridMonth': {
        const startOfMonth = moment().startOf('month');
        const endOfMonth = moment().endOf('month');
        return date.isBetween(startOfMonth, endOfMonth, 'day', '[]');
      }
      default: {
        const today = moment();
        return date.isSame(today, 'day');
      }
    }
  }, [selectedDate]);

  useEffect(() => {
    dispatch(getCalendarCompanyStaffRequest());
    const mode = window.localStorage.getItem('currentCalendarMode');
    const checkedStatuses = localStorage.getItem('calendarCheckedStatuses');
    // const calendarDate = localStorage.getItem('calendarDate');
    checkedStatuses ? setCheckedItems({ ...checkedItems, status: JSON.parse(checkedStatuses) }) : localStorage.setItem('calendarCheckedStatuses', JSON.stringify(checkedItems.status));
    // if (calendarDate) {
      // setSelectedDate(calendarDate);
    // } else {
      // localStorage.setItem('calendarDate', moment().format('Y-MM-DD'));
    // }

    const calendarApi = calendarRef.current.getApi();
    // calendarApi.gotoDate(calendarDate || selectedDate);
    if (!mode) {
      localStorage.setItem('currentCalendarMode', 'resourceTimeGridDay');
      calendarRef.current.getApi().changeView('resourceTimeGridDay');
      setSelectedTimeGrid('resourceTimeGridDay');
    } else {
      calendarRef.current.getApi().changeView(mode);
      setSelectedTimeGrid(mode);
    }
    calendarApi.gotoDate(localStorage.getItem('calendarDate') || moment(new Date()).format('Y-MM-DD'));
    // const datesPeriodObj = getDatesPeriod(mode, selectedDate);
    // const datesPeriodObj = getDatesPeriod(mode, calendarDate || selectedDate);
    // getAppointments(datesPeriodObj);

    if (Object.keys(userSettings).length === 0) {
      dispatch(getUserSettingsRequest());
    }
  }, []);

  useEffect(() => {
    if (prevIsGetCalendarAppointmentsSuccess === false && isGetCalendarAppointmentsSuccess) {
      setLoading(false);
      setAppointments(calendarAppointments);
    } else if (prevIsGetCalendarAppointmentsError === false && isGetCalendarAppointmentsError) {
      console.log('getCalendarAppointmentsErrorMessage: ', getCalendarAppointmentsErrorMessage);
    }
  }, [isGetCalendarAppointmentsSuccess]);

  useEffect(() => {
    if (prevIsFilterAllAppointmentsSuccess === false && isFilterAllAppointmentsSuccess) {
      getFilteredAppointments();
    }
  }, [isFilterAllAppointmentsSuccess]);

  useEffect(() => {
    if (calendarRef && calendarRef.current) {
      // const calendarApi = calendarRef.current.getApi();

      // window.dateOnClick = calendarApi.select;
    }
  }, [calendarRef]);

  useEffect(() => {
    if (selectedTimeGrid) {
      console.log('newCalendar-selectedTimeGrid', selectedTimeGrid);
      handleChangeCalendarDate();
    }
  }, [selectedTimeGrid]);

  useEffect(() => {
    if (!Boolean(anchorEl)) {
      const checkedStatuses = localStorage.getItem('calendarCheckedStatuses');
      checkedStatuses
        ? setCheckedItems({
            ...checkedItems,
            status: JSON.parse(checkedStatuses),
          })
        : localStorage.setItem(
            'calendarCheckedStatuses',
            JSON.stringify(checkedItems.status)
          );
    }
  }, [anchorEl]);

  useEffect(() => {
    if (prevIsStoreAppointmentSuccess === false && isStoreAppointmentSuccess) {
      if (checkedItems.status.includes(1)) {
        const appointmentsCopy = [...appointments];
        appointmentsCopy.push(newAppointment);
        setAppointments(appointmentsCopy);
      }
    }
  }, [isStoreAppointmentSuccess]);

  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      if (checkedItems.status.includes(1)) {
        const appointmentsCopy = [...appointments];
        const index = appointmentsCopy.findIndex((i) => i.id === updatedAppointment.id);
        appointmentsCopy[index] = updatedAppointment;
        setAppointments(appointmentsCopy);
      }
    }
  }, [isUpdateAppointmentSuccess]);

  useEffect(() => {
    if (prevIsGetCalendarAppointmentsSuccess === false && isGetCalendarAppointmentsSuccess) {
      setAppointments(calendarAppointments);
    }
  }, [isGetCalendarAppointmentsSuccess]);

  const handleDateClick = (arg) => {
    const { date, resource } = arg;
    const d = moment(date).format('Y-MM-DD');
    const t = moment(date).format('HH:mm');
    setAppointment({});
    const calendarDate = localStorage.getItem('calendarDate');
    if (userSettings && userSettings.appointment && userSettings.appointment.creating_order === 'Staff-Service') {
      setStaffId(parseInt(resource.id));
    }
    setSelectedDate(d || calendarDate);
    setSelectedHour(t);
    setIsShowAppointmentDrawer(true);
  };

  const handleEventClick = (arg) => {
    const { extendedProps, start, id } = arg.event;
    const {
      price,
      appointment_date,
      service_id,
      customer_id,
      appointment_duration,
      staff_id,
      status_name,
      company_service_id,
      description,
      from_widget,
    } = extendedProps;

    const now = moment();
    const appointmentTime = moment(`${appointment_date} ${moment(start).format('HH:mm')}:00`);
    if (now.isAfter(appointmentTime) || status_name === 'cancelled' || status_name === 'pending') {
      setAppointment({
        ...extendedProps,
        id: parseInt(id),
        start_time: `${moment(start).format('HH:mm')}:00`,
        duration: appointment_duration,
      });
      setIsShowAppointmentDetailsDrawer(true);
    } else {
      setAppointment({
        id: parseInt(id),
        start_time: moment(start).format('HH:mm'),
        date: appointment_date,
        service_id,
        customer_id,
        price,
        staff_id,
        status_name,
        company_service_id,
        duration: parseInt(appointment_duration),
        note_from_creator: description,
        from_widget,
      });
      setIsShowAppointmentDrawer(true);
    }
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  // const handleResizeStart = (arg) => {
  //   const resources = arg.event.getResources();
  //   const eventEndTime = moment(arg.event.endStr).minute();
  //
  //   if (calendarRef.current) {
  //     if (+eventEndTime === 10) {
  //       calendarRef.current.getApi().setOption('snapDuration', { minute: 5 });
  //     }
  //
  //     eventConstraints.forEach((item) => {
  //       if (+resources[0].id === +item.resourceId) {
  //         calendarRef.current.getApi().addEvent(item);
  //       }
  //     });
  //   }
  // };

  // const handleResizeStop = () => {
  //   if (calendarRef.current) {
  //     calendarRef.current.getApi().setOption('snapDuration', { minute: 15 });
  //
  //     eventConstraints.forEach((item) => {
  //       const event = calendarRef.current.getApi().getEventById(item.id);
  //
  //       event.remove();
  //     });
  //   }
  // };

  const handleChangeDate = (date) => {
    const calendarApi = calendarRef.current.getApi();
    setWidth(moment(date).format('MMMM d, yyyy').length + 5);
    calendarApi.gotoDate(moment(date).format('Y-MM-DD'));
    const grid = window.localStorage.getItem('currentCalendarMode');
    const datesPeriodObj = getDatesPeriod(grid, date);
    getAppointments(datesPeriodObj);
    localStorage.setItem('calendarDate', moment(date).format('Y-MM-DD'));
    setSelectedDate(moment(date).format('YYYY-MM-DD'));
  };

  const renderEventDetail = (arg) => (
    <CustomTooltip
      title={(
        <div className="tooltip-content">
          <div className="tooltip-header" style={{ backgroundColor: getColorsByAppointmentStatus(arg.event.extendedProps.status_name, false) }}>
            <div>
              <DateRangeOutlinedIcon className="header-icon" />
            </div>
            <div className="header-text">
              <span style={{ marginRight: '8px' }}>
                {moment(arg.event.extendedProps.appointment_date).format('LL')}
              </span>
              <span>
                ({arg.timeText})
              </span>
            </div>
          </div>
          <div className="tooltip-customer-box">
            <div className="customer-header">
              <div className="header-title">
                <span className="title">
                  {t('Customer')}
                </span>
              </div>
              <div className="header-status">
                <span>
                  {arg.event.extendedProps.status_name === 'accepted' ? t('Upcoming') : t(capitalize(arg.event.extendedProps.status_name))}
                </span>
              </div>
            </div>
            <div className="customer-detail">
              <p className="customer-name">
                { arg.event.extendedProps.customer_name || t('WalkIn') }
              </p>
              <p className="customer-phone">
                {arg.event.extendedProps.customer_phone_code} {arg.event.extendedProps.customer_phone_number}
              </p>
              <p className="customer-email">
                {arg.event.extendedProps.customer_email}
              </p>
            </div>
          </div>
          <div className="tooltip-service-box">
            <div className="service-header">
              <span className="title">
                {t('Service')}
              </span>
            </div>
            <div className="service-detail">
              <div>
                <p className="service-name">
                  {arg.event.extendedProps.service_name}
                </p>
                <p className="service-duration">
                  {serviceDuration(arg.event.extendedProps.appointment_duration, t('H'), t('Min'))}
                </p>
              </div>
              <div>
                <span className="service-price">
                  {arg.event.extendedProps.price && getServicePrice(arg.event.extendedProps.price.type, arg.event.extendedProps.price.price, companyGot) }
                </span>
              </div>
            </div>
          </div>
          {arg.event.extendedProps.description && (
          <div className="tooltip-notes-box">
            <div className="notes-header">
              <span className="title">
                {t('Notes')}
              </span>
            </div>
            <div className="notes-box">
              <p className="notes-text">
                {arg.event.extendedProps.description}
              </p>
            </div>
          </div>
          )}
        </div>
            )}
      arrow
      placement="right-start"
    >
      <div className="event-content">
        <div
          style={{
            backgroundColor: getColorsByAppointmentStatus(arg.event.extendedProps.status_name, false),
            height: arg.event.extendedProps.appointment_duration >= 90 ? '22px' : '100%',
            borderRadius: arg.event.extendedProps.appointment_duration >= 90 ? '4px 4px 0 0' : '4px',
          }}
          className="event-box-header"
        >
          <>
            <div>
              <p className="header-text">
                {arg.timeText}
              </p>
            </div>
            <div>
              <p className="header-text">
                {arg.event.extendedProps.status_name === 'accepted' ? t('Upcoming') : t(capitalize(arg.event.extendedProps.status_name))}
              </p>
            </div>
          </>
        </div>
        {arg.event.extendedProps.appointment_duration >= 90 && (
        <div className="content-container" style={{ backgroundColor: getColorsByAppointmentStatus(arg.event.extendedProps.status_name, true) }}>
          <p className="content-text">
            {arg.event.extendedProps.service_name}
          </p>
          <p className="content-text">
            { arg.event.extendedProps.customer_name || t('WalkIn') }
          </p>
          {arg.event.extendedProps.from_widget && (
          <div className="from-widget-box">
            <p>
              {`${t('Source')}: ${t('BookingLink')}`}
            </p>
          </div>
          )}
        </div>
        )}
      </div>
    </CustomTooltip>
  );

  const renderAvatar = (arg) => {
    const { extendedProps: { avatar }, title } = arg;

    if (avatar) {
      return (
        <Avatar alt="Remy Sharp" src={avatar} style={{ width: 50, height: 50 }} />
      );
    } else {
      return (
        <div className="staff-avatar">
          {title ? (
            /^[a-zA-Z]*$/.test(title[0]) ? (
              getInitials(title)
            ) : (
              <img src={DefaultProfileImage} alt="default" width={50} height={50} />
            )
          ) : (
            <img src={DefaultProfileImage} alt="default" width={50} height={50} />
          )}
        </div>
      );
    }
  };

  const resourcesDetail = (arg) => (
    <div className="resources-content">
      {renderAvatar(arg.resource)}
      <div className="staff-detail">
        <p className="staff-name">
          {arg.resource.title}
        </p>
        {/*<p className="staff-profession">*/}
        {/*  {arg.resource.extendedProps.profession_name}*/}
        {/*</p>*/}
      </div>
    </div>
  );

  const handleChangeGrid = ({ target: { value: grid } }) => {
    window.localStorage.setItem('currentCalendarMode', grid);
    // const datesPeriodObj = getDatesPeriod(grid, selectedDate);
    // getAppointments(datesPeriodObj);
    calendarRef.current.getApi().changeView(grid);
    setSelectedTimeGrid(grid);
  };

  const getAppointments = (datesPeriodObj) => {
    const checkedStatuses = localStorage.getItem('calendarCheckedStatuses');
    setLoading(true);
    dispatch(getCalendarAppointmentsRequest({
      ...datesPeriodObj,
      status_ids: JSON.parse(checkedStatuses),
    }));
  };

  const handleOpenAppointmentDrawer = () => {
    setAppointment({});
    setStaffId(null);
    // setSelectedDate('');
    setSelectedHour('');
    setAppointment({});
    setIsShowAppointmentDrawer(true);
  };

  const calendarProps = {
    isShowAppointmentDrawer,
    setIsShowAppointmentDrawer,
  };

  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const handleChangeStartDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setSelectedStartDate(formattedDate);
  };

  const handleChangeEndDate = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setSelectedEndDate(formattedDate);
  };

  const handleChangeCheckboxes = (event) => {
    const { name, value } = event.target;
    const checkedFilterItems = checkedItems[name].includes(parseInt(value))
      ? { ...checkedItems, [name]: checkedItems[name].filter((item) => item !== parseInt(value)) }
      : { ...checkedItems, [name]: [...checkedItems[name], parseInt(value)] };

    setCheckedItems({ ...checkedFilterItems });
  };

  const getFilteredAppointments = () => {
    const filteredAllAppointmentsCopy = [...filteredAllAppointments];
    setAppointments(filteredAllAppointmentsCopy);
    setAnchorEl(null);
  };

  const submitFilter = () => {
    const grid = window.localStorage.getItem('currentCalendarMode');
    const datesPeriodObj = getDatesPeriod(grid);
    const data = {
      status_ids: checkedItems.status,
      service_ids: checkedItems.services,
      start_date: datesPeriodObj.start_date,
      end_date: datesPeriodObj.end_date,
      searchValue: '',
      for_calendar: true,
    };

    dispatch(filterAllAppointmentsRequest(data));
    localStorage.setItem('calendarCheckedStatuses', JSON.stringify(checkedItems.status));
  };

  const getDatesPeriod = (calendarMode, date = null) => {
    const currentDate = date ? moment(date) : moment();

    switch (calendarMode) {
      case 'resourceTimeGridDay': {
        return {
          start_date: currentDate.format('YYYY-MM-DD'),
          end_date: currentDate.format('YYYY-MM-DD'),
        };
      }
      case 'resourceTimeGridWeek': {
        return {
          start_date: currentDate.startOf('week').format('YYYY-MM-DD'),
          end_date: currentDate.endOf('week').format('YYYY-MM-DD'),
        };
      }
      case 'resourceDayGridMonth': {
        return {
          start_date: currentDate.startOf('month').format('YYYY-MM-DD'),
          end_date: currentDate.endOf('month').format('YYYY-MM-DD'),
        };
      }
      default: {
        return {
          start_date: currentDate.format('YYYY-MM-DD'),
          end_date: currentDate.format('YYYY-MM-DD'),
        };
      }
    }
  };

  const handleChangeCalendarDate = () => {
    const date = calendarRef.current.getApi().getDate();
    localStorage.setItem('calendarDate', moment(date).format('Y-MM-DD'));
    const datesPeriodObj = getDatesPeriod(selectedTimeGrid, date);
    getAppointments(datesPeriodObj);
    setSelectedDate(datesPeriodObj.start_date);
    setWidth(moment(datesPeriodObj.start_date).format('MMMM d, yyyy').length + 5);
  }

  return (
    <CalendarContext.Provider value={calendarProps}>
      <div id="full-calendar">
        <div className="calendar-header-buttons">
          <Button
            variant="text"
            color="primary"
            disabled={isTodayDisabled}
            onClick={() => {
              calendarRef.current.getApi().today();
              handleChangeCalendarDate();
            }}
          >
            {t(todayTitle)}
          </Button>
          <FormControl fullWidth variant="outlined" size="small" className="calendar-mode-dropdown">
            <Select
              value={selectedTimeGrid || 'resourceTimeGridDay'}
              onChange={(e) => handleChangeGrid(e, calendarRef)}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              {resourceTimeGridOptions.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option.value}
                  selected={selectedTimeGrid}
                >
                  {t(option.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            aria-label="filter"
            onClick={handleOpenFilter}
            className="filterIcon"
          >
            <img src={FilterIcon} alt="filter" />
            <span
              style={{
                marginLeft: '6px',
                color: '#393939',
                font: 'normal 600 14px/19px Nunito Sans, sans-serif',
              }}
            >
              {t('Filter')}
            </span>
          </Button>
          <Button
            variant="contained"
            className="new-appointment-btn"
            onClick={handleOpenAppointmentDrawer}
            color="primary"
            size="small"
            startIcon={<AddIcon />}
          >
            {t('NewAppointment')}
          </Button>
        </div>
        <div className="calendar-date-picker">
          <AppDatePicker
            inputVariant="standard"
            format="MMMM d, yyyy"
            keyboardIcon={undefined}
            InputAdornmentProps={{ style: { marginLeft: -10 }}}
            style={{ width: `${width}ch`, cursor: 'pointer' }}
            selectedDate={selectedDate}
            onChange={handleChangeDate}
          />
        </div>
        <div className="loading">
          {loading && <CircularProgress size={60} />}
        </div>
        <FullCalendar
          ref={calendarRef}
          plugins={[
            interactionPlugin,
            momentTimezonePlugin,
            scrollGridPlugin,
            resourceTimeGridPlugin,
            resourceDayGridPlugin,
          ]}
          headerToolbar={{
            start: 'prev,next',
            end: '',
          }}
          loading={(isLoading) => {
            setLoading(isLoading);
          }}
          editable={false}
          customButtons={{
            prev: {
              click: () => {
                calendarRef.current.getApi().prev();
                handleChangeCalendarDate();
              },
            },
            next: {
              click: () => {
                calendarRef.current.getApi().next();
                handleChangeCalendarDate();
              },
            },
          }}
          firstDay={1}
          // startEditable={false}
          // durationEditable={false}
          eventResizableFromStart={false}
          eventResourceEditable={false}
          // eventResizeStart={handleResizeStart}
          // eventResizeStop={handleResizeStop}
          eventClick={handleEventClick}
          eventContent={renderEventDetail}
          events={appointments}
            // eventConstraint={businessHours}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
          }}
          dateClick={handleDateClick}
          resources={calendarCompanyStaff}
          resourceLabelContent={resourcesDetail}
          slotDuration="00:30:00"
          slotLabelInterval={{
            hour: 1,
          }}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: false,
          }}
          dayHeaderFormat={
            (args) =>
              t(moment(args.date).locale('en').format('ddd')) +
              (selectedTimeGrid === 'resourceDayGridMonth'
                ? ''
                : moment(args.date).locale('en').format(' DD/MM'))
          }
            // slotMinTime="09:00:00"
            // slotMaxTime="24:00:00"
            // scrollTime="01:00:00"
          slotEventOverlap={false}
          initialView="resourceTimeGridDay"
            // businessHours={businessHours}
            // eventDurationEditable={false}
            // eventResizableFromStart={false}
          nowIndicator={false}
          allDaySlot={false}
          dayMinWidth={250}
          height="auto"
          expandRows
          selectable
          eventDurationEditable={false}
          eventStartEditable={false}
          locale="en-GB"
          contentHeight="auto"
          _resize={true}
        />
        {isShowAppointmentDrawer && (
          <AppointmentsDrawer
            setSelectedAppointmentId={setSelectedAppointmentId}
            isOpen={isShowAppointmentDrawer}
            onClose={() => setIsShowAppointmentDrawer(false)}
            date={selectedDate}
            startTime={selectedHour}
            staffId={staffId}
            appointment={appointment}
            snackBarAlert={snackBarAlert}
            setAppointments={setAppointments}
            appointments={appointments}
            fromCalendar
          />
        )}

        {isShowAppointmentDetailsDrawer && (
          <AppointmentDetailsDrawer
            isOpen={isShowAppointmentDetailsDrawer}
            onClose={() => setIsShowAppointmentDetailsDrawer(false)}
            appointment={appointment}
            snackBarAlert={snackBarAlert}
            setAppointments={setAppointments}
            appointments={appointments}
            checkedItems={checkedItems}
          />
        )}

        <SnackbarToast
          message={snackbarMessage}
          type={snackbarType}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
        />
        <AppointmentFilterDrawer
          handleCloseFilter={handleCloseFilter}
          anchorEl={anchorEl}
          selectedDateFrom={selectedStartDate}
          selectedDateTo={selectedEndDate}
          handleChangeStartDate={handleChangeStartDate}
          handleChangeEndDate={handleChangeEndDate}
          handleChangeCheckboxes={handleChangeCheckboxes}
          checkedItems={checkedItems}
          filterData={filterItems}
          submitFilter={submitFilter}
          fromCalendar
        />
      </div>
    </CalendarContext.Provider>
  );
}
