import { handleActions } from 'redux-actions';
import {
  getIndustryServicesRequest,
  getIndustryServicesSuccess,
  getIndustryServicesError,
  addServiceRequest,
  addServiceSuccess,
  addServiceError,
  deleteServiceRequest,
  deleteServiceSuccess,
  deleteServiceError,
  updateServiceRequest,
  updateServiceSuccess,
  updateServiceError,
  detachStaffServiceRequest,
  detachStaffServiceSuccess,
  detachStaffServiceError,
  getByIndustryIdRequest,
  getByIndustryIdSuccess,
  getByIndustryIdError,
  getServiceByIdRequest,
  getServiceByIdSuccess,
  getServiceByIdError,
  getServicesByCompanyRequest,
  getServicesByCompanySuccess,
  getServicesByCompanyError,
  deleteCompanyServiceRequest,
  deleteCompanyServiceSuccess,
  deleteCompanyServiceError,
  updateCompanyServiceRequest,
  updateCompanyServiceSuccess,
  updateCompanyServiceError,
  getCompanyServicesWithSubServicesRequest,
  getCompanyServicesWithSubServicesSuccess,
  getCompanyServicesWithSubServicesError,
  getServicesByIndividualUserIdRequest,
  getServicesByIndividualUserIdSuccess,
  getServicesByIndividualUserIdError,
  deleteIndividualUserServiceRequest,
  deleteIndividualUserServiceSuccess,
  deleteIndividualUserServiceError,
  updateIndividualUserServiceRequest,
  updateIndividualUserServiceSuccess,
  updateIndividualUserServiceError,
  getIndividualServicesWithSubServicesRequest,
  getIndividualServicesWithSubServicesSuccess,
  getIndividualServicesWithSubServicesError,
  getServiceWithStaffRequest,
  getServiceWithStaffSuccess,
  getServiceWithStaffError,
  deleteServiceByIdRequest,
  deleteServiceByIdSuccess,
  deleteServiceByIdError,
  addCustomServiceRequest,
  addCustomServiceSuccess,
  addCustomServiceError,
} from './actions';

const initialState = {
  isDeleteServiceByIdSuccess: false,
  isDeleteServiceByIdError: false,
  isGetIndustryServicesSuccess: false,
  isGetIndustryServicesError: false,
  services: [],
  isAddServiceSuccess: false,
  isAddServiceError: false,
  addServiceErrorMessage: '',
  addedService: {},
  isDeleteServiceSuccess: false,
  isDeleteServiceError: false,
  deleteServiceErrorMessage: '',
  isUpdateServiceSuccess: false,
  isUpdateServiceError: false,
  updatedService: {},
  updateServiceErrorMessage: '',
  isDetachStaffServiceSuccess: false,
  isDetachStaffServiceError: false,
  detachStaffServiceErrorMessage: '',
  isGetByIndustryIdSuccess: false,
  isGetByIndustryIdError: false,
  getByIndustryIdErrorMessage: '',
  servicesIds: [],
  isGetServiceByIdSuccess: false,
  isGetServiceByIdError: false,
  serviceById: [],
  getServiceByIdErrorMessage: '',
  isGetServicesByCompanySuccess: false,
  isGetServicesByCompanyError: false,
  servicesByCompany: [],
  getServicesByCompanyErrorMessage: '',
  isDeleteCompanyServiceSuccess: false,
  isDeleteCompanyServiceError: false,
  deleteCompanyServiceErrorMessage: '',
  isUpdateCompanyServiceSuccess: false,
  isUpdateCompanyServiceError: false,
  updatedCompanyService: {},
  updateServiceCompanyErrorMessage: '',
  isGetCompanyServicesWithSubServicesSuccess: false,
  isGetCompanyServicesWithSubServicesError: false,
  servicesWithSubServicesByCompanyId: {},
  isDeleteIndividualUserServiceSuccess: false,
  isDeleteIndividualUserServiceError: false,
  deleteIndividualUserServiceErrorMessage: '',
  isGetIndividualServicesWithSubServicesSuccess: false,
  isGetIndividualServicesWithSubServicesError: false,
  servicesWithSubServicesByIndividualId: {},
  getServicesByIndividualUserIdErrorMessage: '',
  isGetServiceWithStaffSuccess: false,
  isGetServiceWithStaffError: false,
  companyServiceWithStaff: [],
  isAddCustomServiceSuccess: false,
  isAddCustomServiceError: false,
  addCustomServiceErrorMessage: '',
  customAddedCategory: {},
};

const reducer = handleActions({
  [getIndustryServicesRequest]: (state) => ({
    ...state,
    isGetIndustryServicesSuccess: false,
    isGEtIndustryServicesError: false,
    services: [],
  }),
  [getIndustryServicesSuccess]: (state, { payload }) => ({
    ...state,
    isGetIndustryServicesSuccess: true,
    isGetIndustryServicesError: false,
    services: payload.data,
  }),
  [getIndustryServicesError]: (state) => ({
    ...state,
    isGetIndustryServicesSuccess: false,
    isGetIndustryServicesError: false,
    services: [],
  }),
  [addServiceRequest]: (state) => ({
    ...state,
    isAddServiceSuccess: false,
    isAddServiceError: false,
    addedService: {},
    addServiceErrors: {},
    addServiceErrorMessage: '',
  }),
  [addServiceSuccess]: (state, { payload: { data } }) => ({
    ...state,
    isAddServiceSuccess: true,
    isAddServiceError: false,
    addedService: data,
    addServiceErrors: {},
    addServiceErrorMessage: '',
  }),
  [addServiceError]: (state, { payload }) => ({
    ...state,
    isAddServiceSuccess: false,
    isAddServiceError: true,
    addedService: {},
    addServiceErrors: payload.errors || {},
    addServiceErrorMessage: payload.data || '',
  }),
  [deleteServiceRequest]: (state) => ({
    ...state,
    isDeleteServiceSuccess: false,
    isDeleteServiceError: false,
    deleteServiceErrorMessage: '',
  }),
  [deleteServiceSuccess]: (state) => ({
    ...state,
    isDeleteServiceSuccess: true,
    isDeleteServiceError: false,
    deleteServiceErrorMessage: '',
  }),
  [deleteServiceError]: (state, { payload }) => ({
    ...state,
    isDeleteServiceSuccess: false,
    isDeleteServiceError: true,
    deleteServiceErrorMessage: payload.message,
  }),
  [updateServiceRequest]: (state) => ({
    ...state,
    isUpdateServiceSuccess: false,
    isUpdateServiceError: false,
    updatedService: {},
    updateServiceErrorMessage: '',
  }),
  [updateServiceSuccess]: (state, { payload }) => ({
    ...state,
    isUpdateServiceSuccess: true,
    isUpdateServiceError: false,
    updatedService: payload,
    updateServiceErrorMessage: '',
  }),
  [updateServiceError]: (state, { payload: { message } }) => ({
    ...state,
    isUpdateServiceSuccess: false,
    isUpdateServiceError: true,
    updatedService: {},
    updateServiceErrorMessage: message,
  }),
  [detachStaffServiceRequest]: (state) => ({
    ...state,
    isDetachStaffServiceSuccess: false,
    isDetachStaffServiceError: false,
    detachStaffServiceErrorMessage: '',
  }),
  [detachStaffServiceSuccess]: (state) => ({
    ...state,
    isDetachStaffServiceSuccess: true,
    isDetachStaffServiceError: false,
    detachStaffServiceErrorMessage: '',
  }),
  [detachStaffServiceError]: (state, { payload }) => ({
    ...state,
    isDetachStaffServiceSuccess: false,
    isDetachStaffServiceError: true,
    detachStaffServiceErrorMessage: payload.message,
  }),
  [getByIndustryIdRequest]: (state) => ({
    ...state,
    isGetByIndustryIdSuccess: false,
    isGetByIndustryIdError: false,
    getByIndustryIdErrorMessage: '',
  }),
  [getByIndustryIdSuccess]: (state, { payload }) => ({
    ...state,
    isGetByIndustryIdSuccess: true,
    isGetByIndustryIdError: false,
    getByIndustryIdErrorMessage: '',
    servicesIds: payload,
  }),
  [getByIndustryIdError]: (state, { payload }) => ({
    ...state,
    isGetByIndustryIdSuccess: true,
    isGetByIndustryIdError: false,
    getByIndustryIdErrorMessage: payload.message,
  }),
  [getServiceByIdRequest]: (state) => ({
    ...state,
    isGetServiceByIdSuccess: false,
    isGetServiceByIdError: false,
    serviceById: [],
    getServiceByIdErrorMessage: '',
  }),
  [getServiceByIdSuccess]: (state, { payload }) => ({
    ...state,
    isGetServiceByIdSuccess: true,
    isGetServiceByIdError: false,
    serviceById: payload.data,
    getServiceByIdErrorMessage: '',
  }),
  [getServiceByIdError]: (state, { payload }) => ({
    ...state,
    isGetServiceByIdSuccess: false,
    isGetServiceByIdError: true,
    serviceById: [],
    getServiceByIdErrorMessage: payload.message,
  }),
  [getServicesByCompanyRequest]: (state) => ({
    ...state,
    isGetServicesByCompanySuccess: false,
    isGetServicesByCompanyError: false,
    servicesByCompany: [],
    getServicesByCompanyErrorMessage: '',
  }),
  [getServicesByCompanySuccess]: (state, { payload }) => ({
    ...state,
    isGetServicesByCompanySuccess: true,
    isGetServicesByCompanyError: false,
    servicesByCompany: payload.data,
    getServicesByCompanyErrorMessage: '',
  }),
  [getServicesByCompanyError]: (state, { payload }) => ({
    ...state,
    isGetServicesByCompanySuccess: false,
    isGetServicesByCompanyError: true,
    servicesByCompany: [],
    getServicesByCompanyErrorMessage: payload.message,
  }),
  [deleteCompanyServiceRequest]: (state) => ({
    ...state,
    isDeleteCompanyServiceSuccess: false,
    isDeleteCompanyServiceError: false,
    deleteCompanyServiceErrorMessage: '',
  }),
  [deleteCompanyServiceSuccess]: (state, { payload }) => ({
    ...state,
    isDeleteCompanyServiceSuccess: true,
    isDeleteCompanyServiceError: false,
    deleteCompanyServiceErrorMessage: '',
    servicesByCompany: payload,
  }),
  [deleteCompanyServiceError]: (state, { payload }) => ({
    ...state,
    isDeleteCompanyServiceSuccess: false,
    isDeleteCompanyServiceError: true,
    deleteCompanyServiceErrorMessage: payload.message,
  }),
  [updateCompanyServiceRequest]: (state) => ({
    ...state,
    isUpdateCompanyServiceSuccess: false,
    isUpdateCompanyServiceError: false,
    updatedCompanyService: {},
    updateServiceCompanyErrorMessage: '',
  }),
  [updateCompanyServiceSuccess]: (state, { payload }) => ({
    ...state,
    isUpdateCompanyServiceSuccess: true,
    isUpdateCompanyServiceError: false,
    updatedCompanyService: payload.data,
    updateServiceCompanyErrorMessage: '',
  }),
  [updateCompanyServiceError]: (state, { payload }) => ({
    ...state,
    isUpdateCompanyServiceSuccess: false,
    isUpdateCompanyServiceError: true,
    updatedCompanyService: {},
    updateServiceCompanyErrorMessage: payload.message,
  }),
  [getCompanyServicesWithSubServicesRequest]: (state) => ({
    ...state,
    isGetCompanyServicesWithSubServicesSuccess: false,
    isGetCompanyServicesWithSubServicesError: false,
    servicesWithSubServicesByCompanyId: {},
  }),
  [getCompanyServicesWithSubServicesSuccess]: (state, { payload }) => ({
    ...state,
    isGetCompanyServicesWithSubServicesSuccess: true,
    isGetCompanyServicesWithSubServicesError: false,
    servicesWithSubServicesByCompanyId: payload.data,
  }),
  [getCompanyServicesWithSubServicesError]: (state) => ({
    ...state,
    isGetCompanyServicesWithSubServicesSuccess: false,
    isGetCompanyServicesWithSubServicesError: false,
    servicesWithSubServicesByCompanyId: {},
  }),
  [getServicesByIndividualUserIdRequest]: (state) => ({
    ...state,
    isGetServicesByIndividualUserIdSuccess: false,
    isGetServicesByIndividualUserIdError: false,
    servicesByIndividualUserId: [],
    getServicesByIndividualUserIdErrorMessage: '',
  }),
  [getServicesByIndividualUserIdSuccess]: (state, { payload }) => ({
    ...state,
    isGetServicesByIndividualUserIdSuccess: true,
    isGetServicesByIndividualUserIdError: false,
    servicesByIndividualUserId: payload.data,
    getServicesByIndividualUserIdErrorMessage: '',
  }),
  [getServicesByIndividualUserIdError]: (state, { payload }) => ({
    ...state,
    isGetServicesByIndividualUserIdSuccess: false,
    isGetServicesByIndividualUserIdError: true,
    servicesByIndividualUserId: [],
    getServicesByIndividualUserIdErrorMessage: payload.message,
  }),
  [updateIndividualUserServiceRequest]: (state) => ({
    ...state,
    isUpdateIndividualUserServiceSuccess: false,
    isUpdateIndividualUserServiceError: false,
    updatedIndividualUserService: {},
    updateServiceIndividualUserErrorMessage: '',
  }),
  [updateIndividualUserServiceSuccess]: (state, { payload }) => ({
    ...state,
    isUpdateIndividualUserServiceSuccess: true,
    isUpdateIndividualUserServiceError: false,
    updatedIndividualUserService: payload.data,
    updateServiceIndividualUserErrorMessage: '',
  }),
  [updateIndividualUserServiceError]: (state, { payload }) => ({
    ...state,
    isUpdateIndividualUserServiceSuccess: false,
    isUpdateIndividualUserServiceError: true,
    updatedIndividualUserService: {},
    updateServiceIndividualUserErrorMessage: payload.message,
  }),
  [deleteIndividualUserServiceRequest]: (state) => ({
    ...state,
    isDeleteIndividualUserServiceSuccess: false,
    isDeleteIndividualUserServiceError: false,
    deleteIndividualUserServiceErrorMessage: '',
  }),
  [deleteIndividualUserServiceSuccess]: (state) => ({
    ...state,
    isDeleteIndividualUserServiceSuccess: true,
    isDeleteIndividualUserServiceError: false,
    deleteIndividualUserServiceErrorMessage: '',
  }),
  [deleteIndividualUserServiceError]: (state, { payload }) => ({
    ...state,
    isDeleteIndividualUserServiceSuccess: false,
    isDeleteIndividualUserServiceError: true,
    deleteIndividualUserServiceErrorMessage: payload.message,
  }),
  [getIndividualServicesWithSubServicesRequest]: (state) => ({
    ...state,
    isGetIndividualServicesWithSubServicesSuccess: false,
    isGetIndividualServicesWithSubServicesError: false,
    servicesWithSubServicesByIndividualId: {},
    getServicesByIndividualUserIdErrorMessage: '',
  }),
  [getIndividualServicesWithSubServicesSuccess]: (state, { payload }) => ({
    ...state,
    isGetIndividualServicesWithSubServicesSuccess: true,
    isGetIndividualServicesWithSubServicesError: false,
    servicesWithSubServicesByIndividualId: payload.data,
    getServicesByIndividualUserIdErrorMessage: '',
  }),
  [getIndividualServicesWithSubServicesError]: (state, { payload }) => ({
    ...state,
    isGetIndividualServicesWithSubServicesSuccess: false,
    isGetIndividualServicesWithSubServicesError: false,
    servicesWithSubServicesByIndividualId: {},
    getServicesByIndividualUserIdErrorMessage: payload.message,
  }),
  [getServiceWithStaffRequest]: (state) => ({
    ...state,
    isGetServiceWithStaffSuccess: false,
    isGetServiceWithStaffError: false,
    companyServiceWithStaff: [],
  }),
  [getServiceWithStaffSuccess]: (state, { payload }) => ({
    ...state,
    isGetServiceWithStaffSuccess: true,
    isGetServiceWithStaffError: false,
    companyServiceWithStaff: payload.data,
  }),
  [getServiceWithStaffError()]: (state) => ({
    ...state,
    isGetServiceWithStaffSuccess: false,
    isGetServiceWithStaffError: true,
    companyServiceWithStaff: [],
  }),
  [deleteServiceByIdRequest]: (state) => ({
    ...state,
    isDeleteServiceByIdSuccess: false,
    isDeleteServiceByIdError: false,
  }),
  [deleteServiceByIdSuccess]: (state) => ({
    ...state,
    isDeleteServiceByIdSuccess: true,
    isDeleteServiceByIdError: false,
  }),
  [deleteServiceByIdError]: (state) => ({
    ...state,
    isDeleteServiceByIdSuccess: false,
    isDeleteServiceByIdError: true,
  }),
  [addCustomServiceRequest]: (state) => ({
    ...state,
    isAddCustomServiceSuccess: false,
    isAddCustomServiceError: false,
    addCustomServiceErrorMessage: '',
  }),
  [addCustomServiceSuccess]: (state, { payload }) => ({
    ...state,
    isAddCustomServiceSuccess: true,
    isAddCustomServiceError: false,
    addCustomServiceErrorMessage: '',
    customAddedCategory: payload.data,
  }),
  [addCustomServiceError]: (state) => ({
    ...state,
    isAddCustomServiceSuccess: false,
    isAddCustomServiceError: true,
    addCustomServiceErrorMessage: '',
  }),
}, initialState);

export default reducer;
