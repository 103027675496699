import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloseIcon from '@material-ui/icons/Close';
import deleteIcon from 'assets/icons/modal-delete.svg';
import styles from '../styles.module.scss';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minWidth: '356px',
    maxWidth: '356px',
  },
  dialogActionsRoot: {
    padding: '16px 24px 24px',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    color: '#A4A4A4',
    font: 'normal 600 14px/19px Nunito Sans, sans-serif',
    border: '1px solid #A4A4A4',
    borderRadius: '4px',
    padding: '7px 50px',
    maxWidth: '150px',
    width: '100%',
    height: 33,
  },
  deleteBtn: {
    backgroundColor: '#F12B2C',
    color: '#FFFFFF',
    font: 'normal 600 14px/19px Nunito Sans, sans-serif',
    borderRadius: '4px',
    padding: '7px 50px',
    maxWidth: '150px',
    width: '100%',
  },
}));

function CheckAppointmentServiceModal({
  open, onClose, handleUpdate,
}) {
  const {
    appointments: checkedApp,
  } = useSelector((state) => state.appointment);
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title-check"
      className={styles.serviceModal}
      classes={{ paper: classes.dialogPaper }}
    >
      <DialogContent className={styles.content}>
        <div className={styles.closeIcon}>
          <CloseIcon onClick={onClose} />
        </div>
        <Box className={styles.imgBox}>
          <img src={deleteIcon} alt="delete" />
        </Box>
        <DialogContentText className={styles.contentText}>
          <div className={styles.serviceText}>
            {t('ThisServiceHas')} { checkedApp.length } {t('UpcomingAppointmentServiceModalText')}
          </div>
          <div>
            {t('AllAppointmentsCancelText')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActionsRoot }}>
        <Button
          onClick={onClose}
          color="primary"
          className={classes.cancelBtn}
          autoFocus
        >
          {t('No')}
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={handleUpdate}
          style={{
            color: 'white', backgroundColor: '#F12B2C', width: '100%', height: 33,
          }}
        >
          {t('Cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CheckAppointmentServiceModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

export default CheckAppointmentServiceModal;
