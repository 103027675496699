import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  IconButton,
  Button,
  FormControl,
  // MenuItem,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';

function NewCategoryModal(props) {
  const {
    individualUserIndustryItem,
    serviceOptions,
    individualUserServices,
    onClose,
  } = props;

  const history = useHistory();

  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [error, setError] = useState(false);
  const [filteredServicesOptions, setFilteredServicesOptions] = useState([]);

  useEffect(() => {
    if (serviceOptions.length > 0) {
      const serviceOptionsCopy = [...serviceOptions];
      individualUserServices.map((option) => {
        const service = serviceOptionsCopy.find((item) => item.value === option.id);

        if (service) {
          const index = serviceOptionsCopy.findIndex((item) => item.value === option.id);
          serviceOptionsCopy.splice(index, 1);
        }
      });

      setFilteredServicesOptions(serviceOptionsCopy);
    }
  }, []);

  const handleCategorySelectChange = (e, selectedCategory) => {
    if (selectedCategory && selectedCategory.value) {
      setSelectedCategoryId(selectedCategory.value);
    } else {
      setSelectedCategoryId('');
    }
  };

  const saveCategory = () => {
    if (selectedCategoryId !== '') {
      setError(false);
      history.push(`/individual-user/${individualUserIndustryItem.id}/service/${selectedCategoryId}`);
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open
      id="appointment-preview"
      onClose={onClose}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <span className="alert-title">
          <span> New Category</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <div className="modal-content" />
        <Grid item xs={12}>
          <FormControl
            fullWidth
            variant="outlined"
            margin="dense"
            error={error}
          >
            <Autocomplete
              size="small"
              fullWidth
              autoHighlight
              options={filteredServicesOptions}
              onChange={handleCategorySelectChange}
              getOptionLabel={(option) => option.label}
              renderOption={(option) => (
                <div
                  className="input-menu"
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </div>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'new-password',
                  }}
                  label="Service Category"
                  variant="outlined"
                  error={error}
                />
              )}
            />

            { error && (
              <div className="error-message-content">
                <ErrorIcon fontSize="small" color="error" />
                <FormHelperText>
                  Category is required.
                </FormHelperText>
              </div>
            )}
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={saveCategory}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NewCategoryModal.propTypes = {
  // Parent props
  individualUserIndustryItem: PropTypes.object.isRequired,
  serviceOptions: PropTypes.object.isRequired,
  individualUserServices: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NewCategoryModal;
