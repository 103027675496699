import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import {
  FormControl,
  FormHelperText,
  Grid,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';

import AppDatePicker from 'Modules/AppDatePicker';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import { staffSpecialGraphicRequest } from '../../../../../../../redux/company/actions';

function DateAndTime({
  errors, handleChange, appointmentItem, selectedAppointment,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { storeAppointmentErrors } = useSelector((state) => state.appointment);
  const {
    specialHoursForStaff,
    isSpecialGraphicSuccess,
  } = useSelector((state) => state.company);

  const prevIsSpecialGraphicSuccess = usePrevious(isSpecialGraphicSuccess);

  const [selectedDate, setSelectedDate] = useState(appointmentItem.date || moment().format('YYYY-MM-DD'));
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState(appointmentItem.start_time || '');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(appointmentItem).length > 0 && !!appointmentItem.id) {
      dispatch(staffSpecialGraphicRequest({
        staff_id: appointmentItem.staff_id,
        company_service_id: appointmentItem.company_service_id,
        date: appointmentItem.date,
      }));
    }
  }, []);

  useEffect(() => {
    if (prevIsSpecialGraphicSuccess === false && isSpecialGraphicSuccess) {
      if (appointmentItem.id) {
        const arrAvailableHours = [...specialHoursForStaff];
        if (
          selectedDate === selectedAppointment.date
          && selectedHour && appointmentItem.staff_id === selectedAppointment.staff_id && appointmentItem.service_id === selectedAppointment.service_id
        ) {
          arrAvailableHours.push(selectedHour);
          arrAvailableHours.sort((time1, time2) => time1.localeCompare(time2));
          setOptions(arrAvailableHours);
        } else {
          if (!specialHoursForStaff.includes(appointmentItem.start_time)) {
            setSelectedHour('');
            handleChange('start_time', '');
          }
          setOptions(specialHoursForStaff);
        }
      } else {
        if (!specialHoursForStaff.includes(appointmentItem.start_time)) {
          setSelectedHour('');
          handleChange('start_time', '');
        }
        setOptions(specialHoursForStaff);
      }
      setLoading(false);
    }
  }, [isSpecialGraphicSuccess]);

  const renderDatetime = (label, value, onChange, img, name) => (
    <FormControl
      fullWidth
      size="small"
      variant="outlined"
      error={!!errors[name] || !!storeAppointmentErrors[name]}
    >
      <AppDatePicker
        inputVariant="outlined"
        selectedDate={value || ''}
        onChange={(date) => onChange(name, moment(date).format('YYYY-MM-DD'))}
        error={!!errors[name] || storeAppointmentErrors[name]}
      />

      { errors[name] && (
        <div className="error-message-content">
          <ErrorIcon fontSize="small" color="error" />
          <FormHelperText>
            {label + t('Errors.IsRequired') }
          </FormHelperText>
        </div>
      )}

      { storeAppointmentErrors[name] && (
        <div className="error-message-content">
          <ErrorIcon fontSize="small" color="error" />
          <FormHelperText>
            { storeAppointmentErrors[name] }
          </FormHelperText>
        </div>
      )}
    </FormControl>
  );

  const handleChangeHours = (e, value) => {
    setSelectedHour(value);
    handleChange('start_time', value);
  };

  const handleDateChane = (name, value) => {
    setSelectedDate(value);
    handleChange(name, value);
    const { staff_id, service_id } = appointmentItem;

    if (staff_id && service_id) {
      dispatch(staffSpecialGraphicRequest({
        staff_id: appointmentItem.staff_id,
        company_service_id: appointmentItem.service_id,
        date: value,
      }));
    }
  };

  return (
    <div className="input-content">
      <div className="input-title-box">
        <DateRangeOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
        <span className="input-title">
          {t('DateAndTime')}
        </span>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {renderDatetime('Date', selectedDate, handleDateChane, '', 'date')}
        </Grid>
        <Grid item xs={6}>
          <FormControl
            fullWidth
            error={!!storeAppointmentErrors.start_time || errors.start_time}
            size="small"
            variant="outlined"
          >
            <Autocomplete
              size="small"
              disabled={!appointmentItem.staff_id || !appointmentItem.service_id}
              fullWidth
              autoHighlight
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              selectOnFocus
              clearOnBlur
              options={options}
              value={selectedHour}
              onChange={handleChangeHours}
              loading={loading}
              loadingText={options.length ? 'Loading' : 'No customers'}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                  label={`${t('AvailableHours')} *`}
                  variant="outlined"
                  error={errors.start_time || !!storeAppointmentErrors.start_time}
                />
              )}
            />
            { errors.start_time && (
              <div className="error-message-content">
                <ErrorIcon fontSize="small" color="error" />
                <FormHelperText error>
                  {t('Time') + t('IsRequired')}
                </FormHelperText>
              </div>
            )}

            { storeAppointmentErrors.start_time && (
              <div className="error-message-content">
                <ErrorIcon fontSize="small" color="error" />
                <FormHelperText error>
                  { storeAppointmentErrors.start_time }
                </FormHelperText>
              </div>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
}

DateAndTime.propTypes = {
  errors: PropTypes.object.isRequired,
  selectedAppointment: PropTypes.object.isRequired,
  appointmentItem: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  date: PropTypes.string,
  startTime: PropTypes.string,
};

DateAndTime.defaultProps = {
  date: '',
  startTime: '',
};

export default DateAndTime;
