import React from 'react';
import {
  Box, Card, CardContent, CardMedia, List, ListItem, ListItemText,
} from '@material-ui/core';
import ReactPlaceholder from 'react-placeholder';
import { RoundShape } from 'react-placeholder/lib/placeholders';

function StaffLoading() {
  return (
    <>
      <Box item="true" className="staff-boxes-loading" m="25px">
        <Card className="card-box-root card-box-root-loading box-cursor staff-tab-loading">
          <Box>
            <CardMedia className="img-header">
              <RoundShape color="rgb(224, 224, 224)" style={{ width: 140, height: 140, margin: '0 auto' }} />
            </CardMedia>
            <CardContent className="content-card-box">
              <List disablePadding>
                <ListItem disableGutters dense>
                  <ListItemText className="icon-card-box">
                    <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                  </ListItemText>
                  <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                </ListItem>
                <ListItem disableGutters dense>
                  <ListItemText className="icon-card-box">
                    <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                  </ListItemText>
                  <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                </ListItem>
                <ListItem disableGutters dense>
                  <ListItemText className="icon-card-box">
                    <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                  </ListItemText>
                  <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                </ListItem>
              </List>
            </CardContent>
          </Box>
        </Card>
        <Card className="card-box-root card-box-root-loading box-cursor staff-tab-loading">
          <Box>
            <CardMedia className="img-header">
              <RoundShape color="rgb(224, 224, 224)" style={{ width: 140, height: 140, margin: '0 auto' }} />
            </CardMedia>
            <CardContent className="content-card-box">
              <List disablePadding>
                <ListItem disableGutters dense>
                  <ListItemText className="icon-card-box">
                    <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                  </ListItemText>
                  <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                </ListItem>
                <ListItem disableGutters dense>
                  <ListItemText className="icon-card-box">
                    <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                  </ListItemText>
                  <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                </ListItem>
                <ListItem disableGutters dense>
                  <ListItemText className="icon-card-box">
                    <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                  </ListItemText>
                  <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                </ListItem>
              </List>
            </CardContent>
          </Box>
        </Card>
        <Card className="card-box-root card-box-root-loading box-cursor staff-tab-loading">
          <Box>
            <CardMedia className="img-header">
              <RoundShape color="rgb(224, 224, 224)" style={{ width: 140, height: 140, margin: '0 auto' }} />
            </CardMedia>
            <CardContent className="content-card-box">
              <List disablePadding>
                <ListItem disableGutters dense>
                  <ListItemText className="icon-card-box">
                    <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                  </ListItemText>
                  <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                </ListItem>
                <ListItem disableGutters dense>
                  <ListItemText className="icon-card-box">
                    <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                  </ListItemText>
                  <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                </ListItem>
                <ListItem disableGutters dense>
                  <ListItemText className="icon-card-box">
                    <RoundShape color="rgb(224, 224, 224)" style={{ width: 20, height: 20 }} />
                  </ListItemText>
                  <ListItemText primary={<ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" />} />
                </ListItem>
              </List>
            </CardContent>
          </Box>
        </Card>
      </Box>
    </>
  );
}
export default StaffLoading;
