import React, { useEffect, useState } from 'react';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import {
  Box,
  FormControl, FormHelperText, TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorIcon from '@material-ui/icons/Error';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getCompanyServicesWithSubServicesRequest } from '../../../../../../../redux/service/actions';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import { getCompanyStaffHaveServiceRequest, getStaffCurrentServicesRequest } from '../../../../../../../redux/staff/actions';
import { staffSpecialGraphicRequest } from '../../../../../../../redux/company/actions';
import getInitials from '../../../../../../../Modules/getInitials';
import DefaultProfileImage from '../../../../../../../assets/img/default-profile.png';

function Staff({
  errors, appointmentItem, handleChange, isFirst,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    isGetCompanyStaffHaveServiceSuccess,
    isGetStaffCurrentServicesSuccess,
    companyStaffHaveService,
    staffWithCurrentServices,
  } = useSelector((state) => state.staff);

  const prevIsGetCompanyStaffHaveServiceSuccess = usePrevious(isGetCompanyStaffHaveServiceSuccess);
  const prevIsGetStaffCurrentServicesSuccess = usePrevious(isGetStaffCurrentServicesSuccess);

  const [selectedOption, setSelectedOption] = useState({});
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleChangeOption = (e, value) => {
    if (!value) {
      handleChange('staff_id', null);
      setSelectedOption({});
    } else {
      setSelectedOption(value);
      handleChange('staff_id', value.id);
      if (!isFirst) {
        dispatch(staffSpecialGraphicRequest({
          staff_id: value.id,
          company_service_id: appointmentItem.service_id,
          date: appointmentItem.date || moment().format('YYYY-MM-DD'),
        }));
      } else if (appointmentItem.id) {
        dispatch(staffSpecialGraphicRequest({
          staff_id: value.id,
          company_service_id: appointmentItem.service_id,
          date: appointmentItem.date || moment().format('YYYY-MM-DD'),
        }));
      } else {
        handleChange('service_id', null);
        handleChange('price', null);
        handleChange('duration', null);
        dispatch(getCompanyServicesWithSubServicesRequest({ staff_id: value.id }));
      }
    }
  };

  useEffect(() => {
    if (isFirst) {
      setLoading(true);
      // if (companyStaff.length) {
      //   getStaff();
      // } else {
      //   dispatch(getCompanyStaffRequest({ forService: null }));
      // }
      if (appointmentItem.id) {
        dispatch(getStaffCurrentServicesRequest({ company_service_id: appointmentItem.service_id }));
      } else {
        dispatch(getCompanyStaffHaveServiceRequest({ forService: null }));
      }
    } else if (appointmentItem.service_id) {
      setLoading(true);
      dispatch(getStaffCurrentServicesRequest({ company_service_id: appointmentItem.service_id }));
    }
  }, []);

  useEffect(() => {
    if ((prevIsGetCompanyStaffHaveServiceSuccess === false && isGetCompanyStaffHaveServiceSuccess) || (prevIsGetStaffCurrentServicesSuccess === false && isGetStaffCurrentServicesSuccess)) {
      setSelectedOption({});
      getStaff();
    }
  }, [isGetCompanyStaffHaveServiceSuccess, isGetStaffCurrentServicesSuccess]);

  const getStaff = () => {
    const staff = (isFirst && !appointmentItem.id) ? companyStaffHaveService : staffWithCurrentServices;
    const staffOptions = staff.map(({ id, full_name, avatar }) => ({
      id,
      value: id,
      label: full_name,
      logo: avatar,
    }));
    setOptions(staffOptions);
    setLoading(false);
    if (appointmentItem.staff_id) {
      const founded = staffOptions.find((i) => i.id === appointmentItem.staff_id);
      if (founded !== undefined) {
        setSelectedOption(founded);
        dispatch(getCompanyServicesWithSubServicesRequest({ staff_id: founded.id }));
      }
    }
  };

  return (
    <div className="input-content">
      <div className="input-title-box">
        <AssignmentIndOutlinedIcon style={{ fill: '#0282DA', fontSize: '19px' }} />
        <span className="input-title">
          {t('Staff')}
        </span>
      </div>
      <FormControl
        fullWidth
        error={!!errors.staff}
        size="small"
        variant="outlined"
      >
        <Autocomplete
          size="small"
          disabled={!appointmentItem.service_id && !isFirst}
          fullWidth
          autoHighlight
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          selectOnFocus
          clearOnBlur
          onChange={(e, value) => handleChangeOption(e, value)}
          value={selectedOption}
          options={options}
          loading={loading}
          loadingText={options.length ? t('Loading') : t('NoOptions')}
          getOptionLabel={(option) => option.label || ''}
          renderOption={(option) => (
            <div
              className="input-menu"
              key={option.value}
              value={option.value}
            >
              <Box display="flex" alignItems="center">
                {option.logo ? (
                  <img className="avatar-circle" src={option.logo} alt="default" width={32} height={32} />
                ) : (
                  <div className="default-circle">
                    {option.label ? (
                      /^[a-zA-Z]*$/.test(option.label[0]) ? (
                        getInitials(option.label[0])
                      ) : (
                        <img src={DefaultProfileImage} alt="default" width={32} height={32} />
                      )
                    ) : (
                      <img src={DefaultProfileImage} alt="default" width={32} height={32} />
                    )}
                  </div>
                )}
                {option.label}
              </Box>
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              label={`${t('Staff')} *`}
              placeholder={t('ChooseStaff')}
              variant="outlined"
              error={errors.staff}
            />
          )}
        />
        { errors.staff && (
          <div className="error-message-content">
            <ErrorIcon fontSize="small" color="error" />
            <FormHelperText>
              {t('Staff') + t('IsRequired')}
            </FormHelperText>
          </div>
        )}
      </FormControl>
    </div>
  );
}

Staff.propTypes = {
  errors: PropTypes.object.isRequired,
  appointmentItem: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  isFirst: PropTypes.bool,
};

Staff.defaultProps = {
  isFirst: false,
};

export default Staff;
