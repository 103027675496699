import React from 'react';
import {
  AppBar, Box, Toolbar, Typography,
} from '@material-ui/core';
import ReactPlaceholder from 'react-placeholder';

const secondNavbarLoading = () => (
  <>
    <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
      <Toolbar>
        <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
          <Box display="flex" className="tabs-navbar">
            <Box alignSelf="center" pr="24px" mr="24px" width="150px">
              <Typography>
                <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
              </Typography>
            </Box>
            <Box alignSelf="center" ml="-18px" mr="6px" width="90px">
              <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
            </Box>
            <Box alignSelf="center" className="tab-companies" width="80px">
              <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
            </Box>
          </Box>
          <Box display="flex" align="right" className="tabs-navbar">
            <Box alignSelf="center" pr="10px" mr="6px" width="250px">
              <Typography>
                <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
              </Typography>
            </Box>
            <Box alignSelf="center" mr="30px" width="100px">
              <Typography>
                <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
              </Typography>
            </Box>
            <Box alignSelf="center" mr="20px" width="50px">
              <Typography>
                <ReactPlaceholder showLoadingAnimation rows={1} color="rgb(224, 224, 224)" />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  </>
);

export default secondNavbarLoading;
