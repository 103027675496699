import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { TableBody, TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AppointmentHeadCells } from 'Modules/constants';
import { useDispatch, useSelector } from 'react-redux';
import Header from './header';
import Body from './body';
import noAppointments from '../../../../../../assets/noDataImages/no-appointments.svg';
import TableLoading from '../../../../../../Components/General/TableLoading.';
import { filterAllAppointmentsRequest } from '../../../../../../redux/appointment/actions';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    '& .MuiTableCell-body': {
      padding: '7px 16px',
    },
  },
  paginationBox: {
    borderTop: '1px solid #E3E5E6',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableContainer: {
    height: 'calc(100vh - 181px)',
    overflow: 'auto',
    borderTop: '1px solid #E3E5E6',
    boxShadow: 'unset',
    borderRadius: 'unset',
  },
});

function AppointmentTable({
  appointments,
  loading,
  setLoading,
  checkStartTimeAppointment,
  handleCancelAppointmentSelect,
  handleFinishAppointmentSelect,
  handleChangeAppointmentStatus,
  handleAcceptAppointmentSelect,
  showConfirmDelete,
  handleOpenUpdateModal,
  checkedItems,
  selectedStartDate,
  selectedEndDate,
  count,
  setPage,
  page,
  rowsPerPage,
  setRowsPerPage,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();
  const { companyGot } = useSelector((state) => state.company);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    const data = {
      status_ids: checkedItems.status,
      service_ids: checkedItems.services,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      searchValue: '',
      page: newPage + 1,
      limit: rowsPerPage,
    };
    setLoading(false);
    dispatch(filterAllAppointmentsRequest(data));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    const data = {
      status_ids: checkedItems.status,
      service_ids: checkedItems.services,
      start_date: selectedStartDate,
      end_date: selectedEndDate,
      searchValue: '',
      page: 1,
      limit: parseInt(event.target.value, 10),
    };
    setLoading(false);
    dispatch(filterAllAppointmentsRequest(data));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="simple table" size="medium">
          <Header
            headCells={AppointmentHeadCells.filter(
              (cell) => cell.id !== 'staff_first_name' || companyGot.is_staff_based
            )}
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={appointments.length}
          />
          {loading ? (
            appointments.length ? (
              <Body
                appointments={appointments}
                rowsPerPage={rowsPerPage}
                page={page}
                order={order}
                orderBy={orderBy}
                loading={loading}
                checkStartTimeAppointment={checkStartTimeAppointment}
                handleCancelAppointmentSelect={handleCancelAppointmentSelect}
                handleFinishAppointmentSelect={handleFinishAppointmentSelect}
                handleChangeAppointmentStatus={handleChangeAppointmentStatus}
                handleAcceptAppointmentSelect={handleAcceptAppointmentSelect}
                showConfirmDelete={showConfirmDelete}
                handleOpenUpdateModal={handleOpenUpdateModal}
              />
            ) : null
          ) : (
            <TableBody>
              <TableLoading count={10} />
            </TableBody>
          )}
        </Table>

        {loading && appointments.length === 0 ? (
          <div className="no-data" style={{ marginTop: 30, marginBottom: 30, height: 'unset' }}>
            <img src={noAppointments} alt="no-appointments" />
          </div>
        ) : null}
      </TableContainer>
      {count > 5 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={count}
          labelRowsPerPage={t('RowsPerPage')}
          labelDisplayedRows={({ count, from, to }) => `${from}-${to}/${count}`}
          rowsPerPage={rowsPerPage}
          page={page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          onPageChange={handleChangePage}
          className={classes.paginationBox}
        />
      )}
    </>
  );
}

AppointmentTable.propTypes = {
  appointments: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  checkStartTimeAppointment: PropTypes.func.isRequired,
  handleCancelAppointmentSelect: PropTypes.func.isRequired,
  handleFinishAppointmentSelect: PropTypes.func.isRequired,
  handleChangeAppointmentStatus: PropTypes.func.isRequired,
  handleAcceptAppointmentSelect: PropTypes.func.isRequired,
  showConfirmDelete: PropTypes.func.isRequired,
  handleOpenUpdateModal: PropTypes.func.isRequired,
  checkedItems: PropTypes.object.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  setRowsPerPage: PropTypes.func.isRequired,
};

export default AppointmentTable;
