import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import i18n from 'i18next';
import Loading from '../../../../Components/Loading/loading';
import countryCodeOptionsModule from '../../../../Modules/countryCodeOptions';
import { getIndustriesRequest } from '../../../../redux/industry/actions';
import TextMaskCustom from '../../../../Modules/TextMaskCustom';

function Industry(props) {
  const {
    userAccount: {
      id,
    },
    allCountries,
    getIndustries,
    isGetIndustriesSuccess,
    industries,
    individualIndustries,
    setIndividualUserFunction,
    formErrors,
    individualUserData,
  } = props;

  const [individualUserIndustry, setIndividualUserIndustry] = useState({
    id: null,
    user_id: id,
    industry_id: '',
    phone_code: '',
    phone_number: '',
  });
  const [industryOptions, setIndustryOptions] = useState([]);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState({ id: null });
  const [codeOptions, setCodeOptions] = useState([]);
  const [selectedCodeOption, setSelectedCodeOption] = useState({ value: null });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const individualUserDataCopy = { ...individualUserData };
    setIndividualUserIndustry(individualUserDataCopy);
    setSelectedIndustryOption({ id: individualUserDataCopy.industry_id });
    setSelectedCodeOption({ value: individualUserDataCopy.phone_code });
  }, []);

  useEffect(() => {
    getIndustries();
  }, []);

  useEffect(() => {
    if (isGetIndustriesSuccess) {
      const codeOptions = countryCodeOptionsModule.getCodeOptions(allCountries);
      setCodeOptions(codeOptions);
      const getIndustryOptions = countryCodeOptionsModule.getIndustryOptions(industries);
      setIndustryOptions(getIndustryOptions);
      const newIndustries = industries.filter((item) => !individualIndustries.includes(parseInt(item.id)));
      const industryOptions = countryCodeOptionsModule.getIndustryOptions(newIndustries);
      setIndustryOptions(industryOptions);
      if (industryOptions.length > 0 && individualUserIndustry.industry_id === '') {
        setSelectedIndustryOption({ id: industryOptions[0].id });
        setIndividualUserFunction({
          industry_id: industryOptions[0].id,
        });
      }
      setLoading(false);
    }
  }, [isGetIndustriesSuccess]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIndividualUserFunction({
      [name]: value,
    });
    setIndividualUserIndustry({
      phone_number: value,
    });
  };

  const handleIndustryOptionChange = (event) => {
    setSelectedIndustryOption({ id: event.target.value });
    setIndividualUserFunction({
      industry_id: event.target.value,
    });
  };

  const handlePhoneCodeOptionChange = (event) => {
    setSelectedCodeOption({ value: event.target.value });
    setIndividualUserFunction({
      phone_code: event.target.value,
    });
  };

  return !loading ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormControl
          fullWidth
          size="small"
          variant="outlined"
          error={!!formErrors.industry_id}
        >
          <InputLabel>Industry *</InputLabel>
          <Select
            value={selectedIndustryOption.id ? selectedIndustryOption.id : ''}
            onChange={(event) => handleIndustryOptionChange(event)}
            label="Industry *"
          >
            { industryOptions.length > 0 && industryOptions.map((item) => (
              <MenuItem
                key={Math.random()}
                value={item.id}
              >
                { item.label }
              </MenuItem>
            )) }
          </Select>

          { formErrors.industry_id && (
            <div className="error-message-content">
              <ErrorIcon fontSize="small" color="error" />
              <FormHelperText>
                Industry is required.
              </FormHelperText>
            </div>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl
          fullWidth
          margin="dense"
          variant="outlined"
          error={!!formErrors.phone_code}
        >
          <InputLabel>Code *</InputLabel>
          <Select
            value={selectedCodeOption.value ? selectedCodeOption.value : ''}
            onChange={(event) => handlePhoneCodeOptionChange(event)}
            label="Code *"
          >
            { codeOptions.length > 0 && codeOptions.map((item) => (
              <MenuItem
                key={Math.random()}
                value={item.value}
              >
                <img
                  src={item.label.flagImageSrc}
                  alt="flag"
                  className="country-flag"
                />
                {item.key}
                {item.value}
              </MenuItem>
            )) }
          </Select>

          { formErrors.phone_code && (
            <div className="error-message-content">
              <ErrorIcon fontSize="small" color="error" />
              <FormHelperText>
                {i18n.t('PhoneCode')} is required.
              </FormHelperText>
            </div>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <FormControl
          fullWidth
          margin="dense"
          variant="outlined"
          error={!!formErrors.phone_number}
        >
          <TextField
            error={!!formErrors.phone_number}
            fullWidth
            size="small"
            value={individualUserIndustry.phone_number}
            label="Phone Number *"
            name="phone_number"
            variant="outlined"
            type="text"
            onChange={(event) => handleInputChange(event)}
            InputProps={{
              inputComponent: TextMaskCustom,
            }}
          />

          { formErrors.phone_number && (
            <div className="error-message-content">
              <ErrorIcon fontSize="small" color="error" />
              <FormHelperText>
                Phone number is required.
              </FormHelperText>
            </div>
          )}
        </FormControl>
      </Grid>
    </Grid>
  ) : <Loading />;
}

Industry.propTypes = {
  // Get Parent Props
  individualIndustries: PropTypes.array.isRequired,
  // Get User Account
  userAccount: PropTypes.object.isRequired,
  // Get Countries Props
  allCountries: PropTypes.array.isRequired,
  // send data to parent
  setIndividualUserFunction: PropTypes.func.isRequired,
  // Get Industries
  getIndustries: PropTypes.func.isRequired,
  isGetIndustriesSuccess: PropTypes.bool.isRequired,
  industries: PropTypes.array.isRequired,
  formErrors: PropTypes.object.isRequired,
  // Industry
  individualUserData: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  userAccount: state.account.userAccount,
  allCountries: state.country.allCountries,
  // Get Industries
  isGetIndustriesSuccess: state.industry.isGetIndustriesSuccess,
  industries: state.industry.industries,
});

const mapDispatchToProps = (dispatch) => ({
  getIndustries: (data) => dispatch(getIndustriesRequest(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Industry);
