import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import i18n from 'i18next';
import DeleteButton from '../../../MaterialComponents/deleteButton';
import {
  deleteSpecialDayRequest,
} from '../../../redux/company/actions';
import usePrevious from '../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../Modules/SnackbarToast';

function DeleteSpecialDayModal(props) {
  const {
    open,
    onClose,
    specialDayId,
    buttonLoading,
    setButtonLoading,
    allSpecialDays,
    setAllSpecialDays,
    deleteSpecialDay,
    isDeleteSpecialDaySuccess,
    isDeleteSpecialDayError,
    deleteSpecialDayErrorMessage,
    handleDeleteCompanyState,
  } = props;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous values
  const prevIsDeleteSpecialDaySuccess = usePrevious(isDeleteSpecialDaySuccess);
  const prevIsDeleteSpecialDayError = usePrevious(isDeleteSpecialDayError);

  // Handle delete day off success
  useEffect(() => {
    if (prevIsDeleteSpecialDaySuccess === false && isDeleteSpecialDaySuccess) {
      const allSpecialDaysCopy = [...allSpecialDays];
      const dayOffIndex = allSpecialDaysCopy.findIndex((item) => item.id === specialDayId);
      allSpecialDaysCopy.splice(dayOffIndex, 1);
      setAllSpecialDays(allSpecialDaysCopy);
      handleDeleteCompanyState('specialDays', allSpecialDaysCopy);
      setButtonLoading(true);
      onClose();
      snackBarAlert(true, i18n.t('SpecificWorkDayDeleted'), 'success');
    }
  }, [isDeleteSpecialDaySuccess]);

  // Handle delete day off error
  useEffect(() => {
    if (prevIsDeleteSpecialDayError === false && isDeleteSpecialDayError) {
      snackBarAlert(true, deleteSpecialDayErrorMessage, 'error');
    }
  }, [isDeleteSpecialDayError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleConfirmDeleteSpecialDay = () => {
    deleteSpecialDay({ id: specialDayId });
    setButtonLoading(false);
  };

  return (
    <div>
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span className="alert-title">
            <span>Confirm Specific WorkDay(s)</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {i18n.t('AreYouSureDelete')} entry? The general schedule will be applied to the specified date(s)
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onClose}
          >
            {i18n.t('Cancel')}
          </Button>
          <DeleteButton
            size="small"
            variant="contained"
            color="primary"
            onClick={handleConfirmDeleteSpecialDay}
            loading={buttonLoading}
          >
            {!buttonLoading && <CircularProgress color="white" size={20} />}
            {buttonLoading && i18n.t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  );
}

DeleteSpecialDayModal.propTypes = {
  // Parent Props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  specialDayId: PropTypes.number.isRequired,
  allSpecialDays: PropTypes.object.isRequired,
  setAllSpecialDays: PropTypes.func.isRequired,
  handleDeleteCompanyState: PropTypes.func.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  setButtonLoading: PropTypes.func.isRequired,

  // Delete special day
  deleteSpecialDay: PropTypes.func.isRequired,
  isDeleteSpecialDaySuccess: PropTypes.bool.isRequired,
  isDeleteSpecialDayError: PropTypes.bool.isRequired,
  deleteSpecialDayErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // Delete special day
  isDeleteSpecialDaySuccess: state.company.isDeleteSpecialDaySuccess,
  isDeleteSpecialDayError: state.company.isDeleteSpecialDayError,
  deleteSpecialDayErrorMessage: state.company.deleteSpecialDayErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    deleteSpecialDay: (data) => dispatch(deleteSpecialDayRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteSpecialDayModal);
