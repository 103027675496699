import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import usePrevious from '../../../../CustomHooks/usePrevious';
import PermissionsModule from '../../../../Modules/Permission';
import IndividualAppointmentDetails from '../../Roles/Individual/Appointments/details';
import CustomerAppointmentDetails from '../../Roles/Customer/Appointments/details';
import StaffAppointmentDetails from '../../Roles/Staff/Appointments/details';
import { getUserRoleInAppointmentRequest } from '../../../../redux/appointment/actions';
import SnackbarToast from '../../../../Modules/SnackbarToast';

function AppointmentDetails(props) {
  const {
    account: { testPermissions },
    match,
    history,
    isGetUserRoleInAppointmentSuccess,
    isGetUserRoleInAppointmentError,
    userRoleInAppointment,
    getUserRoleInAppointment,
  } = props;
  const prevIsGetUserRoleInAppointmentSuccess = usePrevious(isGetUserRoleInAppointmentSuccess);
  const prevIsGetUserRoleInAppointmentError = usePrevious(isGetUserRoleInAppointmentError);

  const [selectedRole, setSelectedRole] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const [first] = PermissionsModule.getRoles(testPermissions);
    localStorage.setItem('prefix', first.toLowerCase());
    getUserRoleInAppointment({ id: match.params.appointmentId });
  }, []);

  useEffect(() => {
    if (prevIsGetUserRoleInAppointmentSuccess === false && isGetUserRoleInAppointmentSuccess) {
      localStorage.setItem('prefix', userRoleInAppointment);
      setSelectedRole(userRoleInAppointment);
    }
  }, [isGetUserRoleInAppointmentSuccess]);
  useEffect(() => {
    if (prevIsGetUserRoleInAppointmentError === false && isGetUserRoleInAppointmentError) {
      setOpenSnackbar(true);
      setSnackbarMessage(i18n.t('AppointmentNotFound'));
      setSnackbarType('error');
    }
  }, [isGetUserRoleInAppointmentError]);

  const getSelectedRoleComponent = () => {
    const props = { history, match };
    // eslint-disable-next-line default-case
    switch (selectedRole.toLowerCase()) {
      case 'individual': {
        return <IndividualAppointmentDetails {...props} />;
      }
      case 'staff': {
        return <StaffAppointmentDetails {...props} />;
      }
      case 'customer': {
        return <CustomerAppointmentDetails {...props} />;
      }
    }
  };

  return (
    <>
      {getSelectedRoleComponent()}
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </ >
  );
}

AppointmentDetails.propTypes = {
  account: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  getUserRoleInAppointment: PropTypes.func.isRequired,
  isGetUserRoleInAppointmentSuccess: PropTypes.bool.isRequired,
  isGetUserRoleInAppointmentError: PropTypes.bool.isRequired,
  userRoleInAppointment: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account.userAccount,
  isGetUserRoleInAppointmentSuccess: state.appointment.isGetUserRoleInAppointmentSuccess,
  isGetUserRoleInAppointmentError: state.appointment.isGetUserRoleInAppointmentError,
  userRoleInAppointment: state.appointment.userRoleInAppointment,
});

function mapDispatchToProps(dispatch) {
  return {
    getUserRoleInAppointment: (data) => dispatch(getUserRoleInAppointmentRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentDetails);
