import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import '../../index.scss';
import {
  InputLabel,
  TextField,
  Select,
  Grid,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import { updateAppointmentRequest } from '../../../../../../../redux/appointment/actions';
import { getStaffAvailableHoursRequest } from '../../../../../../../redux/customer/actions';
import capitalize from '../../../../../../../Modules/capitalize';
import calendar from '../../../../../../../assets/icons/calendar.svg';
import InfoLoading from '../../../../../../../Components/Loading/infoLoading';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';

function Info(props) {
  const dispatch = useDispatch();

  const {
    appointment,
    setIsButtonDisabled,
    isAppointmentCancelled,
    allStatuses,
    updateAppointment,
    isUpdateAppointmentSuccess,
    isUpdateAppointmentError,
    updatedAppointment,
    updateAppointmentErrors,
    isAppointmentUpdate,
    setIsAppointmentUpdate,
  } = props;

  const {
    isGetStaffAvailableHoursSuccess,
    isGetStaffAvailableHoursError,
    availableHours,
  } = useSelector((state) => state.customers);

  // Get some props previous values
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);
  const prevIsUpdateAppointmentError = usePrevious(isUpdateAppointmentError);
  const prevIsGetStaffAvailableHoursSuccess = usePrevious(isGetStaffAvailableHoursSuccess);
  const prevIsGetStaffAvailableHoursError = usePrevious(isGetStaffAvailableHoursError);

  const [loading, setLoading] = useState(true);
  const [appointmentItem, setAppointmentItem] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatusOption, setSelectedStatusOption] = useState({});
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [staffAvailableHours, setStaffAvailableHours] = useState([]);

  // Perform once, when component gets mounted
  useEffect(() => {
    if (appointment && appointment.id) {
      const start_time = moment(appointment.start_time, 'HH:mm').format('HH:mm');
      setAppointmentItem({
        ...appointment,
        day: new Date(appointment.date),
        start_time,
      });
      const availableHoursData = {
        staff_id: appointment.staff_id,
        service_id: appointment.service_id,
        date: appointment.date,
      };
      dispatch(getStaffAvailableHoursRequest(availableHoursData));
      const selectedOption = getSelectedStatusOption(statusOptions, appointment.status_id);
      setSelectedStatusOption(selectedOption);
      setLoading(false);
    }
  }, [appointment]);
  // Handle all statuses change
  useEffect(() => {
    if (allStatuses.length) {
      const options = allStatuses.map((item) => ({
        label: capitalize(item.name),
        value: item.id,
      }));
      setStatusOptions(options);
      if (appointment && appointment.status_id) {
        const selectedOption = getSelectedStatusOption(options, appointment.status_id);
        setSelectedStatusOption(selectedOption);
        setLoading(false);
      }
    }
  }, [allStatuses]);
  // Perform, when appointment updated
  useEffect(() => {
    if (isAppointmentUpdate) {
      handleSaveAppointment();
      setIsAppointmentUpdate(false);
    }
  }, [isAppointmentUpdate]);
  // Perform, when update appointment success
  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      const time = moment(updatedAppointment.start_time, 'HH:mm:ss');
      setAppointmentItem({
        ...updatedAppointment,
        day: new Date(updatedAppointment.date),
        time,
      });
      const option = statusOptions.find((item) => item.value === parseInt(updatedAppointment.status_id));
      setSelectedStatusOption(option);
      setLoading(false);
      setErrors({});
      setOpenSnackbar(true);
      setSnackbarMessage('Appointment updated');
      setSnackbarType('success');
    }
  }, [isUpdateAppointmentSuccess]);
  // Perform, when update appointment error
  useEffect(() => {
    if (prevIsUpdateAppointmentError === false && isUpdateAppointmentError) {
      setLoading(false);
      setIsButtonDisabled(false);
      setErrors(updateAppointmentErrors);
    }
  }, [isUpdateAppointmentError]);
  // Perform, when available hours is success
  useEffect(() => {
    if (prevIsGetStaffAvailableHoursSuccess === false && isGetStaffAvailableHoursSuccess) {
      setStaffAvailableHours(availableHours);
    }
  }, [isGetStaffAvailableHoursSuccess]);
  // Perform, when available hours is error
  useEffect(() => {
    if (prevIsGetStaffAvailableHoursError === false && isGetStaffAvailableHoursError) {
      setOpenSnackbar(true);
      setSnackbarMessage('Something is wrong');
      setSnackbarType('error');
    }
  }, [isGetStaffAvailableHoursError]);

  const getSelectedStatusOption = (options, statusId) => options.find((item) => item.value === parseInt(statusId));

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAppointmentItem({
      ...appointmentItem,
      [name]: value,
    });
  };

  const handleSaveAppointment = () => {
    setLoading(true);
    updateAppointment({
      ...appointmentItem,
    });
  };

  const updateAppointmentItem = (name, value) => {
    let updateValue = value;
    if (name === 'start_time') {
      updateValue = `${updateValue}:00`;
    }
    setAppointmentItem({
      ...appointmentItem,
      [name]: updateValue,
    });
  };

  const renderDatetime = (label, value, onChange, img, name) => (
    <div className="datetime-content">
      <TextField
        fullWidth
        error={!!errors[name]}
        size="small"
        variant="outlined"
        type={label.toLowerCase()}
        label={label}
        style={{ position: 'relative' }}
        onChange={(e) => onChange(name, e.target.value)}
        disabled={isAppointmentCancelled}
        placeholder={label}
        value={value || ''}
        inputProps={{
          autoComplete: 'new-password',
        }}
      />

      {errors[name] && (
        <div className="error-message-content">
          <ErrorIcon fontSize="small" color="error" />
          <FormHelperText error>
            {errors[name]}
          </FormHelperText>
        </div>
      )}
    </div>
  );

  const handleChangeHours = (event) => {
    setAppointmentItem({
      ...appointmentItem,
      start_time: `${event.target.textContent}:00`,
    });
  };

  return (
    <>
      <Grid id="info-tab" className="tab-item">
        { !loading ? (
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Title"
                name="title"
                placeholder="Title"
                value={appointmentItem.title || ''}
                onChange={(event) => handleInputChange(event)}
                disabled={isAppointmentCancelled}
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel>Status</InputLabel>
                <Select
                  native
                  label="Status"
                  value={selectedStatusOption.value}
                  disabled
                >
                  {statusOptions && statusOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Company"
                name="company"
                placeholder="Company"
                value={appointmentItem.company ? appointmentItem.company.name : ''}
                disabled
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Staff"
                name="staff"
                placeholder="Staff"
                value={appointmentItem.staff ? `${appointmentItem.staff.first_name} ${appointmentItem.staff.last_name}` : ''}
                disabled
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Service"
                name="service"
                placeholder="Service"
                value={appointmentItem.service ? appointment.service.name : ''}
                disabled
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                className="width-100"
                variant="outlined"
                style={{ position: 'relative' }}
                label="Permalink"
                name="permalink"
                placeholder="Permalink"
                value={appointmentItem.permalink || ''}
                disabled
                inputProps={{
                  autoComplete: 'new-password',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {renderDatetime('Date', appointmentItem.date, updateAppointmentItem, calendar, 'date')}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!staffAvailableHours.length}
                size="small"
                variant="outlined"
              >
                <Autocomplete
                  size="small"
                  fullWidth
                  autoHighlight
                  name="start_time"
                  options={staffAvailableHours}
                  value={appointmentItem.start_time}
                  onChange={handleChangeHours}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                      label="Available hours"
                      variant="outlined"
                      error={errors.start_time}
                    />
                  )}
                />
                { errors.start_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      Time is Required
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
              {/* {renderDatetime('Time', appointmentItem.start_time, updateAppointmentItem, clock, 'start_time')} */}
            </Grid>
          </Grid>
        ) : <InfoLoading />}
      </Grid>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

Info.propTypes = {
  appointment: PropTypes.object.isRequired,
  setIsButtonDisabled: PropTypes.func.isRequired,
  isAppointmentCancelled: PropTypes.bool.isRequired,
  allStatuses: PropTypes.array.isRequired,
  updateAppointment: PropTypes.func.isRequired,
  isUpdateAppointmentSuccess: PropTypes.bool.isRequired,
  isUpdateAppointmentError: PropTypes.bool.isRequired,
  updatedAppointment: PropTypes.object.isRequired,
  updateAppointmentErrors: PropTypes.object.isRequired,
  isAppointmentUpdate: PropTypes.bool.isRequired,
  setIsAppointmentUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isUpdateAppointmentSuccess: state.appointment.isUpdateAppointmentSuccess,
  isUpdateAppointmentError: state.appointment.isUpdateAppointmentError,
  updatedAppointment: state.appointment.updatedAppointment,
  updateAppointmentErrors: state.appointment.updateAppointmentErrors,
});

function mapDispatchToProps(dispatch) {
  return {
    updateAppointment: (data) => dispatch(updateAppointmentRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);
