import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import i18n from 'i18next';
import usePrevious from '../../../../CustomHooks/usePrevious';
import { acceptInvitationRequest } from '../../../../redux/customer/actions';
import Loading from '../../../../Components/Loading/loading';
import SetNewPassword from '../../Onboarding/SetNewPassword';
import SnackbarToast from '../../../../Modules/SnackbarToast';

function AcceptCustomerInvitation(props) {
  const {
    history,
    match,
    handleLogin,
    acceptInvitation,
    isAcceptInvitationSuccess,
    isAcceptInvitationError,
    invitedUser,
    acceptInvitationErrorMessage,
    isJustInvitationAccepted,
  } = props;
  const prevIsAcceptInvitationSuccess = usePrevious(isAcceptInvitationSuccess);
  const prevIsAcceptInvitationError = usePrevious(isAcceptInvitationError);

  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Perform, when function gets mounted
  useEffect(() => {
    if (!isJustInvitationAccepted) {
      if (localStorage.getItem('token')) {
        history.push('/calendar');
      } else {
        const { token } = match.params;
        if (token) {
          acceptInvitation({ token });
        } else {
          snackBarAlert(true, 'Token is not identified', 'error');
          history.push('/login');
        }
      }
    } else {
      setLoading(false);
    }
  }, []);
  // Perform when accept invitation success
  useEffect(() => {
    if (prevIsAcceptInvitationSuccess === false && isAcceptInvitationSuccess) {
      snackBarAlert(true, i18n.t('InvitationAccepted'), 'success');
      if (invitedUser && invitedUser.token) {
        handleLogin(invitedUser.token);
      } else {
        history.push('/calendar');
      }
    }
  }, [isAcceptInvitationSuccess]);
  // Perform when accept invitation error
  useEffect(() => {
    if (prevIsAcceptInvitationError === false && isAcceptInvitationError) {
      snackBarAlert(true, acceptInvitationErrorMessage, 'error');
      history.push('/login');
    }
  }, [isAcceptInvitationError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  return loading ? <Loading />
    : (
      <>
        <SetNewPassword user={invitedUser} />;
        <SnackbarToast
          message={snackbarMessage}
          type={snackbarType}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
        />
      </>
    );
}

AcceptCustomerInvitation.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  handleLogin: PropTypes.func.isRequired,
  isJustInvitationAccepted: PropTypes.bool.isRequired,
  // Accept invitation
  acceptInvitation: PropTypes.func.isRequired,
  isAcceptInvitationSuccess: PropTypes.bool.isRequired,
  isAcceptInvitationError: PropTypes.bool.isRequired,
  invitedUser: PropTypes.object.isRequired,
  acceptInvitationErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
  isAcceptInvitationSuccess: state.customers.isAcceptInvitationSuccess,
  isAcceptInvitationError: state.customers.isAcceptInvitationError,
  invitedUser: state.customers.invitedUser,
  acceptInvitationErrorMessage: state.customers.acceptInvitationErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    acceptInvitation: (data) => dispatch(acceptInvitationRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AcceptCustomerInvitation));
