import React, { Component } from 'react';
import NotPrivateRoutes from './NotPrivateRoutes';
import PrivateRoutes from './PrivateRoutes';
import axiosInstance from '../Config/ServerConfig';
import Loading from '../Components/Loading/loading';

class Routing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: !!localStorage.getItem('token'),
      isPersonalDetailsOnBoarded: localStorage.getItem('isPersonalDetailsOnBoarded'),
      individualUserOnBoarded: localStorage.getItem('isIndividualOnBoardDone'),
      isCompanyOnBoarded: localStorage.getItem('isCompanyOnBoarded'),
      isEmailVerified: localStorage.getItem('isEmailVerified'),
      isPhoneVerified: localStorage.getItem('isPhoneVerified'),
      isJustInvitationAccepted: false,
      loading: false,
    };
  }

  async componentDidMount() {
    const { isAuthenticated } = this.state;
    if (!isAuthenticated) {
      localStorage.removeItem('prefix');
    }
  }

  // Handle User Logged In
  handleLogin = (token) => {
    localStorage.setItem('token', token);
    this.setState({
      isAuthenticated: true,
      isJustInvitationAccepted: true,
    });
    return true;
  };

  // Handle User Logged Out
  handleLogOut = () => {
    const lang = localStorage.getItem('i18nextLng');
    localStorage.clear();
    localStorage.setItem('i18nextLng', lang);
    this.setState({ isAuthenticated: false });
    axiosInstance.defaults.baseURL = process.env.REACT_APP_API_URL;
    return true;
  };

  // Handle Personal Details On Board Done
  handlePersonalDetailsOnBoarded = async (personalDetailsIsOnBoarded) => {
    const isPersonalDetailsOnBoarded = personalDetailsIsOnBoarded !== null;
    localStorage.setItem('isPersonalDetailsOnBoarded', isPersonalDetailsOnBoarded);
    await this.setState({ isPersonalDetailsOnBoarded });
    return true;
  };

  // Handle Phone Verification Done
  handlePhoneVerified = async (phoneVerified) => {
    const isPhoneVerified = phoneVerified !== null;
    localStorage.setItem('isPhoneVerified', isPhoneVerified);
    await this.setState({ isPhoneVerified });
    return true;
  };

  // Handle Company On Board Done
  handleCompanyOnBoarded = async (companyIsOnBoarded) => {
    const isCompanyOnBoarded = companyIsOnBoarded !== null;
    localStorage.setItem('isCompanyOnBoarded', isCompanyOnBoarded);
    await this.setState({ isCompanyOnBoarded });
    return true;
  };

  // Handle Individual User On Board Done
  handleIndividualUserOnBoardDone = async (individualUserOnBoarded) => {
    const individualUserOnBoardDone = (individualUserOnBoarded !== null);
    localStorage.setItem('isIndividualOnBoardDone', individualUserOnBoardDone);
    await this.setState({ individualUserOnBoarded: individualUserOnBoardDone });
    return true;
  };

  // Handle Email Verification Done
  handleEmailVerified = (emailVerified) => {
    const isEmailVerified = emailVerified !== null;
    localStorage.setItem('isEmailVerified', isEmailVerified);
    this.setState({ isEmailVerified });
    return true;
  };

  render() {
    const {
      isAuthenticated, isCompanyOnBoarded, isEmailVerified,
      isPersonalDetailsOnBoarded, isPhoneVerified,
      loading, individualUserOnBoarded, isJustInvitationAccepted,
    } = this.state;

    return !loading ? (
      <div className="App">
        {isAuthenticated ? (
          <PrivateRoutes
            handleLogout={this.handleLogOut}
            isAuthenticated={isAuthenticated}
            handlePersonalDetailsOnBoarded={this.handlePersonalDetailsOnBoarded}
            isPersonalDetailsOnBoarded={isPersonalDetailsOnBoarded}
            handleIndividualUserOnBoardDone={this.handleIndividualUserOnBoardDone}
            individualUserOnBoarded={individualUserOnBoarded}
            handleCompanyOnBoarded={this.handleCompanyOnBoarded}
            isCompanyOnBoarded={isCompanyOnBoarded}
            handleEmailVerified={this.handleEmailVerified}
            isEmailVerified={isEmailVerified}
            handlePhoneVerified={this.handlePhoneVerified}
            isPhoneVerified={isPhoneVerified}
            isJustInvitationAccepted={isJustInvitationAccepted}
          />
        ) : (
          <NotPrivateRoutes
            isAuthenticated={isAuthenticated}
            handleLogin={(token) => this.handleLogin(token)}
            handlePersonalDetailsOnBoarded={this.handlePersonalDetailsOnBoarded}
            handleCompanyOnBoarded={this.handleCompanyOnBoarded}
            handleIndividualUserOnBoardDone={this.handleIndividualUserOnBoardDone}
            handleEmailVerified={this.handleEmailVerified}
            handlePhoneVerified={this.handlePhoneVerified}
          />
        )}
      </div>
    ) : <Loading />;
  }
}

export default Routing;
