import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import usePrevious from '../../../../CustomHooks/usePrevious';
import Loading from '../../../../Components/Loading/loading';
import {
  authenticateGoogleAccountRequest,
  updateUserAccountInGlobalScope,
} from '../../../../redux/account/actions';
import SnackbarToast from '../../../../Modules/SnackbarToast';

const GoogleOAuth = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const {
    isAuthenticateGoogleAccountSuccess,
    isAuthenticateGoogleAccountError,
  } = useSelector((state) => state.account);
  const prevIsAuthenticateGoogleAccountSuccess = usePrevious(isAuthenticateGoogleAccountSuccess);
  const prevIsAuthenticateGoogleAccountError = usePrevious(isAuthenticateGoogleAccountError);

  const [snackbarData, setSnackbarData] = useState({
    message: '',
    type: '',
    open: false,
  });

  useEffect(() => {
    const code = new URLSearchParams(location.search).get('code');
    dispatch(authenticateGoogleAccountRequest({ code }));
  }, []);

  useEffect(() => {
    if (!prevIsAuthenticateGoogleAccountSuccess && isAuthenticateGoogleAccountSuccess) {
      dispatch(updateUserAccountInGlobalScope({ is_synced_with_google: true }));
      setSnackbarData({
        message: 'Google Account connected',
        type: 'success',
        open: true,
      });
      setTimeout(() => {
        history.push('/calendar');
      }, 1000);
    } else if (!prevIsAuthenticateGoogleAccountError && isAuthenticateGoogleAccountError) {
      setSnackbarData({
        message: 'Error authenticating Google Account',
        type: 'error',
        open: true,
      });
    }
  }, [isAuthenticateGoogleAccountSuccess, isAuthenticateGoogleAccountError]);

  return (
    <>
      <Loading />
      <SnackbarToast
        open={snackbarData.open}
        message={snackbarData.message}
        type={snackbarData.type}
        onClose={() => setSnackbarData((prev) => ({ ...prev, open: false }))}
      />
    </>
  );
};

export default GoogleOAuth;
