const staffProfessions = (companyStaff) => {
  const companyStaffWithProfessions = companyStaff.filter((item) => item.staff_company_professions && item.staff_company_professions.length);
  const staffProfessions = companyStaffWithProfessions.map((item) => item.staff_company_professions[0]);
  const uniqueStaffProfessions = [...new Map(staffProfessions.map((item) => [item['id'], item])).values()];
  return uniqueStaffProfessions.map((item) => (
    {
      id: item.id,
      label: item.name,
    }
  ));
};

export default staffProfessions;
