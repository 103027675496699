import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';

function CustomerDropdownMenu(props) {
  const {
    customer,
    openEditInviteCustomerModal,
    showConfirmDeleteAlertContent,
    isUpdateIndividualCustomerSuccess,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (isUpdateIndividualCustomerSuccess) {
      setAnchorEl(null);
    }
  }, [isUpdateIndividualCustomerSuccess]);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        elevation={1}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        <Link
          className="card-box-item"
          to="/"
          onClick={(e) => {
            openEditInviteCustomerModal(e, customer.id);
            setAnchorEl(null);
          }}
        >
          <MenuItem>
            Edit
          </MenuItem>
        </Link>
        <Link
          className="card-box-item"
          to="/"
          onClick={(e) => {
            showConfirmDeleteAlertContent(e, customer, true);
            setAnchorEl(null);
          }}
        >
          <MenuItem>
            Delete
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

CustomerDropdownMenu.propTypes = {
  customer: PropTypes.object.isRequired,
  openEditInviteCustomerModal: PropTypes.func.isRequired,
  showConfirmDeleteAlertContent: PropTypes.func.isRequired,
  isUpdateIndividualCustomerSuccess: PropTypes.bool.isRequired,
};

export default CustomerDropdownMenu;
