import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import '../../../../../../style/dropzone.scss';
import '../companies.scss';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  AppBar,
  Tooltip,
  TextField,
  Box,
  Button,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';
import TuneRoundedIcon from '@material-ui/icons/Tune';
import SearchIcon from '@material-ui/icons/Search';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';
import {
  deleteCompanyRequest,
  updateCompanyRequest,
  getCompanyRequest,
} from '../../../../../../redux/company/actions';
import { getIndustriesRequest } from '../../../../../../redux/industry/actions';
import InfoLoading from '../../../../../../Components/Loading/infoLoading';
import {
  Info,
} from './tabs';
import {
  getIndustryServicesRequest,
} from '../../../../../../redux/service/actions';
import {
  getCitiesRequest,
  getStatesRequest,
} from '../../../../../../redux/country/actions';
import FilterStaffOptions from '../../../../../../Modals/Staff/FilterStaffOptions';
import FilterCustomerOptions from '../../Customers/FilterCustomer/filterCustomer';
import SaveCompanyChanges from '../../../../../../Modals/Owner/SaveCompanyInfo';
import SnackbarToast from '../../../../../../Modules/SnackbarToast';
import { filterStaffOptionsRequest } from '../../../../../../redux/staff/actions';
import { filterCustomerByStatusRequest } from '../../../../../../redux/customer/actions';

class CompanyDetails extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    // Get Company By Id Props
    companyGot: PropTypes.object.isRequired,
    // Delete Company Props
    professions: PropTypes.array.isRequired,
    // Update Company Props
    updatedCompany: PropTypes.object.isRequired,
    updateCompany: PropTypes.func.isRequired,
    isUpdateCompanySuccess: PropTypes.bool.isRequired,
    // Filter appointment success
    isFilterCustomerByStatusSuccess: PropTypes.bool.isRequired,
    isFilterStaffSuccess: PropTypes.bool.isRequired,
    filterStaffOptions: PropTypes.func.isRequired,
    filterAllCustomerByStatus: PropTypes.func.isRequired,
    // Get Company
    getCompany: PropTypes.func.isRequired,
    isGetCompanySuccess: PropTypes.bool.isRequired,
    company: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: false,
      loading: false,
      company: {},
      activeTabIndex: 0,
      errors: {
        name: false,
        phone_number: false,
        address: false,
        country: false,
        city: false,
      },
      alert: null,
      staffFilterOptions: [
        {
          value: 'staff',
          label: 'Staff',
        },
        {
          value: 'invitations',
          label: 'Invitations',
        },
      ],
      selectedStaffFilter: {},
      isUpdateCompanySubmitDone: false,
      customerFilterOptions: [
        {
          value: 'index',
          label: 'Customers',
        },
        {
          value: 'invitations',
          label: 'Invitations',
        },
      ],
      selectedCustomerFilter: {},
      checkedStatus: {},
      IsAppointmentModalShown: false,
      companyDraft: {},
      openSnackbar: false,
      snackbarType: '',
      snackbarMessage: '',
    };
  }

  componentDidMount() {
    const {
      getCompany,
    } = this.props;
    this.setState({
      loading: true,
    });
    getCompany();
  }

  componentDidUpdate(prevProps) {
    const {
      isGetCompanySuccess,
      company,
      updatedCompany,
      isUpdateCompanySuccess,
      isFilterCustomerByStatusSuccess,
      isFilterStaffSuccess,
    } = this.props;

    // Handle Get Company By Id Success
    if (!prevProps.isGetCompanySuccess && isGetCompanySuccess && company.id) {
      this.setState({
        company,
        loading: false,
        checkedStatus: {
          active: true,
          inActive: true,
          positions: [1],
          professions: [],
        },
      });
    }
    // Handle Update company success
    if (!prevProps.isUpdateCompanySuccess && isUpdateCompanySuccess) {
      this.setState({
        company: updatedCompany,
        companyDraft: {},
      });
      this.setState({
        openSnackbar: true,
        snackbarMessage: 'Company updated successfully',
        snackbarType: 'success',
      });
    }
    // Handle filter appointment success
    if (!prevProps.isFilterCustomerByStatusSuccess && isFilterCustomerByStatusSuccess) {
      this.setState({ anchorEl: false });
    }

    if (!prevProps.isFilterStaffSuccess && isFilterStaffSuccess) {
      this.setState({ anchorEl: false });
    }
  }

  handleDeleteCompanyState = (name, value) => {
    this.setState((prevState) => ({
      company: {
        ...prevState.company,
        [name]: value,
      },
    }));
  };

  handleSaveCompany = (e) => {
    e.preventDefault();
    const { isUpdateCompanySubmitDone } = this.state;
    this.setState({
      isUpdateCompanySubmitDone: !isUpdateCompanySubmitDone,
    });
  };

  handleCancelConfirmation = () => {
    this.setState({ alert: null });
  };

  handleDeleteCompanyClick = (e) => {
    e.preventDefault();
    const getAlert = () => (
      <SweetAlert
        custom
        showCancel
        showCloseButton
        confirmBtnText="Confirm"
        cancelBtnText="Cancel"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="Confirm Company Delete"
        onConfirm={() => this.handleConfirmCompanyDelete()}
        onCancel={() => this.handleCancelConfirmation()}
      >
        <Box mt="24px">
          <Typography variant="subtitle1" color="dark">Are You sure You want to</Typography>
          <Typography variant="subtitle1" color="dark">Delete the company</Typography>
          <Typography variant="subtitle1" color="dark">And lose all data related to this company ?</Typography>
        </Box>
      </SweetAlert>
    );
    this.setState({ alert: getAlert() });
  };

  getSelectedTab = (tab) => {
    let selectedTab;
    let selectedTabName = tab;
    switch (tab) {
      case 'info': {
        selectedTab = 0;
        break;
      }
      case 'gallery': {
        selectedTab = 1;
        break;
      }
      case 'staff': {
        selectedTab = 2;
        break;
      }
      case 'services': {
        selectedTab = 3;
        break;
      }
      case 'working-hours': {
        selectedTab = 4;
        break;
      }
      case 'customers': {
        selectedTab = 5;
        break;
      }
      default: {
        selectedTab = 0;
        selectedTabName = 'info';
      }
    }
    return {
      selectedTab,
      selectedTabName,
    };
  };

  handleChangeTab = (tab, bool, closeModal) => {
    const { staffFilterOptions, customerFilterOptions, companyDraft } = this.state;
    if (Object.keys(companyDraft).length && !bool) {
      this.setState({
        IsAppointmentModalShown: true,
        tab,
      });
    } else if (!closeModal) {
      const { history, match } = this.props;
      const { id, filter } = match.params;
      let url = `/companies/${id}/${tab}`;
      const { selectedTab, selectedTabName } = this.getSelectedTab(tab);
      if (tab === 'staff') {
        let { selectedStaffFilter } = this.state;
        let shouldBeSelected = (selectedStaffFilter.value || filter || 'staff');
        if (shouldBeSelected !== 'staff' && shouldBeSelected !== 'invitations') {
          shouldBeSelected = 'staff';
        }
        url = `${url}/${shouldBeSelected}`;
        selectedStaffFilter = staffFilterOptions.find((item) => item.value === shouldBeSelected);
        this.setState({
          selectedStaffFilter,
        });
      } else if (tab === 'customers') {
        let { selectedCustomerFilter } = this.state;
        let shouldBeSelected = (selectedCustomerFilter.value || filter || 'index');
        if (shouldBeSelected !== 'index' && shouldBeSelected !== 'invitations') {
          shouldBeSelected = 'index';
        }
        url = `${url}/${shouldBeSelected}`;
        selectedCustomerFilter = customerFilterOptions.find((item) => item.value === (shouldBeSelected));
        if (!selectedCustomerFilter) {
          selectedCustomerFilter = customerFilterOptions.find((item) => item.value === 'index');
        }
        this.setState({
          selectedCustomerFilter,
        });
      } else {
        url = `/companies/${id}/${selectedTabName}`;
      }
      this.setState({
        activeTabIndex: selectedTab,
      });
      history.push(url);
    }
  };

  handleFilterStaffTab = ({ target }) => {
    const { selectedStaffFilter } = this.state;
    const { history, match } = this.props;
    const { id, tab } = match.params;
    const selectedStaffFilterOption = target ? JSON.parse(target.value) : selectedStaffFilter;
    history.push(`/companies/${id}/${tab}/${selectedStaffFilterOption.value}`);

    this.setState({ selectedStaffFilter: selectedStaffFilterOption });
  };

  handleCustomersFilterChange = ({ target }) => {
    const { selectedCustomerFilter } = this.state;
    const { history, match } = this.props;
    const { id, tab } = match.params;
    const selectedCustomerFilterOption = target ? JSON.parse(target.value) : selectedCustomerFilter;

    history.push(`/companies/${id}/${tab}/${selectedCustomerFilterOption.value}`);

    this.setState({ selectedCustomerFilter: selectedCustomerFilterOption });
  };

  handleCompanyUpdated = (updatedCompany, success = false) => {
    this.setState({
      isUpdateCompanySubmitDone: false,
    });
    if (success) {
      this.setState({
        ...updatedCompany,
      });
    }
  };

  a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: false });
  };

  handleOpenFilter = () => {
    this.setState({ anchorEl: true });
  }

  handleCloseFilter = () => {
    this.setState({ anchorEl: false });
  };

  handleChangeStatus = (event) => {
    this.setState({
      checkedStatus: event,
    });
  };

  updateCompanyInfo = (event) => {
    const { companyGot } = this.props;
    if (event.input) {
      const [value] = Object.values(event);
      const [name] = Object.keys(event);
      this.setState({
        companyDraft: {
          ...companyGot,
          [name]: value,
        },
      });
    } else {
      this.setState({
        companyDraft: {
          ...companyGot,
          city_id: event.city_id || companyGot.city_id,
          country_id: event.country_id || companyGot.country_id,
          phone_code: event.phone_code || companyGot.phone_code,
          state_id: event.state_id || companyGot.state_id,
          timezone: event.timezone || companyGot.timezone,
        },
      });
    }
  };

  handleCloseInfoModal = () => {
    const { tab } = this.state;
    this.setState({
      companyDraft: {},
      IsAppointmentModalShown: false,
    });
    this.handleChangeTab(tab, true, true);
  };

  handleCancelCompanyChange = () => {
    const { tab } = this.state;
    const { history, match } = this.props;
    const { selectedTab } = this.getSelectedTab(tab);
    const { id } = match.params;
    const url = `/companies/${id}/${tab}`;
    history.push(url);
    this.handleCloseInfoModal();
    this.setState({
      companyDraft: {},
      activeTabIndex: selectedTab,
    });
  };

  handleSaveCompanyChange = () => {
    const { tab, companyDraft } = this.state;
    const { updateCompany, history, match } = this.props;
    const { selectedTab } = this.getSelectedTab(tab);
    const { id } = match.params;
    const url = `/companies/${id}/${tab}`;
    history.push(url);
    updateCompany(companyDraft);
    this.handleCloseInfoModal();
    this.setState({
      activeTabIndex: selectedTab,
    });
  };

  handleSearchItems = (event, name) => {
    const { checkedStatus } = this.state;
    const { filterStaffOptions, filterAllCustomerByStatus } = this.props;
    const { value } = event.target;
    const searchInputValue = {
      active: checkedStatus.active,
      inActive: checkedStatus.inActive,
      position_ids: checkedStatus.positions,
      profession_ids: checkedStatus.professions,
      searchValue: value,
    };
    if (name === 'staff') {
      filterStaffOptions(searchInputValue);
    } else if (name === 'customer') {
      filterAllCustomerByStatus(searchInputValue);
    }
  };

  handleUpdateCheckedStatus = (updatedCheckedStatus) => {
    this.setState({
      checkedStatus: updatedCheckedStatus,
    });
  };

  handleFilterAllCustomer = (data) => {
    const { filterAllCustomerByStatus } = this.props;
    filterAllCustomerByStatus(data);
  };

  // Handle change status checkbox
  handleChangeStatusCheckbox = (event) => {
    const { checkedStatus } = this.state;
    const { name, value } = event.target;
    const checkedFilterItems = checkedStatus[name] === !!value
      ? { ...checkedStatus, [name]: !value }
      : { ...checkedStatus, [name]: !!value };
    this.setState({
      checkedStatus: { ...checkedFilterItems },
    });
  };

  render() {
    const {
      alert,
      errors,
      company,
      loading,
      anchorEl,
      openSnackbar,
      snackbarType,
      checkedStatus,
      activeTabIndex,
      snackbarMessage,
      IsAppointmentModalShown,
      isUpdateCompanySubmitDone,
    } = this.state;
    const { professions } = this.props;
    const open = Boolean(anchorEl);
    return (
      <>
        <AppBar color="inherit" id="second-navbar" elevation={0}>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
              <Box display="flex" className="tabs-navbar">
                <Box alignSelf="center">
                  <Typography variant="h6" noWrap>
                    {i18n.t('MyCompany')}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex">
                <Box alignSelf="center" data-cy="filter" />
                <Box alignSelf="center" className="actions-tab">
                  {activeTabIndex === 0 && (
                    <div>
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(e) => this.handleSaveCompany(e)}
                      >
                        {i18n.t('Update')}
                      </Button>
                    </div>
                  )}
                  {activeTabIndex === 2 && (
                    <Box display="flex" alignItems="center" ml="auto">
                      <Box ml={2} mr={2} className="staff-fields">
                        <TextField
                          type="search"
                          size="small"
                          color="secondary"
                          className="search-staff"
                          placeholder="Search staff"
                          InputProps={{
                            startAdornment: <SearchIcon position="start" />,
                          }}
                          variant="outlined"
                          onChange={(e) => this.handleSearchItems(e, 'staff')}
                        />
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={(e) => this.handleOpenAddStaffModal(e)}
                        >
                          {i18n.t('AddStaff')}
                        </Button>
                        <Tooltip title="Filter">
                          <IconButton
                            aria-label="filter"
                            onClick={this.handleOpenFilter}
                          >
                            <TuneRoundedIcon color="secondary" />
                          </IconButton>
                        </Tooltip>
                        <FilterStaffOptions
                          open={open}
                          anchorEl={anchorEl}
                          company={company}
                          allProfessions={professions}
                          checkedStatus={checkedStatus}
                          handleChangeStatus={this.handleChangeStatus}
                          handleCloseFilter={this.handleCloseFilter}
                          handleUpdateCheckedStatus={this.handleUpdateCheckedStatus}
                          filterStaffOptions={this.handleFilterStaff}
                        />
                      </Box>
                    </Box>
                  )}
                  {activeTabIndex === 3 && (
                    <Box display="flex" alignItems="center">
                      <Box>
                        <Button
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={(e) => this.handleOpenNewCategoryModal(e)}
                        >
                          Add Category
                        </Button>
                      </Box>
                    </Box>
                  )}
                  {activeTabIndex === 5 && (
                    <Box ml={2} className="customer-fields">
                      <TextField
                        type="search"
                        size="small"
                        color="secondary"
                        className="search-customer"
                        placeholder="Search for customers"
                        InputProps={{
                          startAdornment: <SearchIcon position="start" />,
                        }}
                        variant="outlined"
                        onChange={(e) => this.handleSearchItems(e, 'customer')}
                      />
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={(e) => this.openInviteCustomerModal(e)}
                      >
                        Add Customer 1
                      </Button>
                      <Tooltip title="Filter">
                        <IconButton
                          aria-label="filter"
                          onClick={this.handleOpenFilter}
                        >
                          <TuneRoundedIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                      <FilterCustomerOptions
                        isOpenCustomerFilter={open}
                        checkedStatus={checkedStatus}
                        // company={company}
                        handleChangeStatus={this.handleChangeStatus}
                        handleCloseFilter={this.handleCloseFilter}
                        handleChangeStatusCheckbox={this.handleChangeStatusCheckbox}
                        handleFilterAllCustomer={this.handleFilterAllCustomer}
                        anchorEl={anchorEl}
                        open
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {!loading ? (
          <div>
            <Box>
              <Box className="tab-list companies-tab-list">
                <Info
                  errors={errors}
                  companyItem={company}
                  isUpdateCompanySubmitDone={isUpdateCompanySubmitDone}
                  sendCompanyUpdated={this.handleCompanyUpdated}
                  updateCompanyInfo={this.updateCompanyInfo}
                />
              </Box>
            </Box>
            {/* Save in draft modal */}
            <SaveCompanyChanges
              open={IsAppointmentModalShown}
              handleCloseInfoModal={this.handleCloseInfoModal}
              handleSaveCompanyChange={this.handleSaveCompanyChange}
              handleCancelCompanyChange={this.handleCancelCompanyChange}
            />
            <SnackbarToast
              message={snackbarMessage}
              type={snackbarType}
              open={openSnackbar}
              onClose={() => this.setState({ openSnackbar: false })}
            />
            {alert}
          </div>
        ) : <InfoLoading />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account.userAccount,
  // Get Company By Id
  isGetCompanyByIdSuccess: state.company.isGetCompanyByIdSuccess,
  isGetCompanyByIdError: state.company.isGetCompanyByIdError,
  companyGot: state.company.companyGot,
  getCompanyByIdErrorMessage: state.company.getCompanyByIdErrorMessage,
  // Get Industries
  industries: state.industry.industries,
  // Delete Staff
  isDeleteStaffSuccess: state.staff.isDeleteStaffSuccess,
  isDeleteStaffError: state.staff.isDeleteStaffError,
  deleteStaffErrorMessage: state.staff.deleteStaffErrorMessage,
  // Get Staff Positions
  isGetStaffPositionsSuccess: state.staff.isGetStaffPositionsSuccess,
  isGetStaffPositionsError: state.staff.isGetStaffPositionsError,
  positions: state.staff.positions,
  // Add Staff
  isAddStaffToCompanySuccess: state.staff.isAddStaffToCompanySuccess,
  isAddStaffToCompanyError: state.staff.isAddStaffToCompanyError,
  addedStaff: state.staff.addedStaff,
  addStaffErrorMessage: state.staff.addStaffErrorMessage,
  // Invite Staff
  isInviteStaffToCompanySuccess: state.staff.isInviteStaffToCompanySuccess,
  isInviteStaffToCompanyError: state.staff.isInviteStaffToCompanyError,
  storedInvitation: state.staff.storedInvitation,
  inviteStaffErrorMessage: state.staff.inviteStaffErrorMessage,
  isInvitedExistingStaffErrorMessage: state.staff.isInvitedExistingStaffErrorMessage,
  isInvitedExistingStaffError: state.staff.isInvitedExistingStaffError,
  isInvitedExistingStaffSuccess: state.staff.isInvitedExistingStaffSuccess,
  staffInvitation: state.staff.staffInvitation,
  // Get Industry Services
  isGetIndustryServicesSuccess: state.service.isGetIndustryServicesSuccess,
  isGetIndustryServicesError: state.service.isGetIndustryServicesError,
  services: state.service.services,
  // Update Staff
  isUpdateStaffSuccess: state.staff.isUpdateStaffSuccess,
  isUpdateStaffError: state.staff.isUpdateStaffError,
  updatedStaff: state.staff.updatedStaff,
  updateStaffErrorMessage: state.staff.updateStaffErrorMessage,
  // Delete Company
  isDeleteCompanySuccess: state.company.isDeleteCompanySuccess,
  isDeleteCompanyError: state.company.isDeleteCompanyError,
  deleteCompanyErrorMessage: state.company.deleteCompanyErrorMessage,
  // Update Invitation
  isUpdateInvitationSuccess: state.staff.isUpdateInvitationSuccess,
  isUpdateInvitationError: state.staff.isUpdateInvitationError,
  updatedInvitation: state.staff.updatedInvitation,
  updateInvitationErrorMessage: state.staff.updateInvitationErrorMessage,
  // Delete Invitation
  isDeleteInvitationSuccess: state.staff.isDeleteInvitationSuccess,
  isDeleteInvitationError: state.staff.isDeleteInvitationError,
  deleteInvitationErrorMessage: state.staff.deleteInvitationErrorMessage,
  // Update Staff Permissions
  isUpdateStaffPermissionsSuccess: state.staff.isUpdateStaffPermissionsSuccess,
  isUpdateStaffPermissionsError: state.staff.isUpdateStaffPermissionsError,
  updateStaffPermissionsErrorMessage: state.staff.updateStaffPermissionsErrorMessage,
  updatedPagesAccess: state.staff.updatedPagesAccess,
  // Get states
  isGetStatesSuccess: state.country.isGetStatesSuccess,
  isGetStatesError: state.country.isGetStatesError,
  states: state.country.states,
  // Get states
  isGetCitiesSuccess: state.country.isGetCitiesSuccess,
  isGetCitiesError: state.country.isGetCitiesError,
  cities: state.country.cities,
  professions: state.country.professions,
  // Update Company
  isUpdateCompanySuccess: state.company.isUpdateCompanySuccess,
  isUpdateCompanyError: state.company.isUpdateCompanyError,
  updatedCompany: state.company.updatedCompany,
  updateCompanyErrors: state.company.updateCompanyErrors,
  // Filter appointment
  isFilterCustomerByStatusSuccess: state.customers.isFilterCustomerByStatusSuccess,
  isFilterStaffSuccess: state.staff.isFilterStaffSuccess,
  isFilterStaffError: state.staff.isFilterStaffError,
  filterStaffOptions: state.staff.filterStaffOptions,
  // Filter Customer
  filterAllCustomerByStatus: state.customers.filterAllCustomerByStatus,
  // Get Company
  isGetCompanySuccess: state.company.isGetCompanySuccess,
  company: state.company.company,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndustries: (data) => dispatch(getIndustriesRequest(data)),
    getIndustryServices: (data) => dispatch(getIndustryServicesRequest(data)),
    deleteCompany: (data) => dispatch(deleteCompanyRequest(data)),
    getStates: (data) => dispatch(getStatesRequest(data)),
    getCities: (data) => dispatch(getCitiesRequest(data)),
    updateCompany: (data) => dispatch(updateCompanyRequest(data)),
    filterStaffOptions: (data) => dispatch(filterStaffOptionsRequest(data)),
    filterAllCustomerByStatus: (data) => dispatch(filterCustomerByStatusRequest(data)),
    getCompany: () => dispatch(getCompanyRequest()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CompanyDetails));
