import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import {
  Dialog, DialogContent, DialogTitle, Grid, IconButton, TextField,
} from '@material-ui/core';
import avatar from '../../../assets/img/avatar.svg';

function CustomerProfileModal(props) {
  const {
    customer,
    closeCustomerProfileModal,
  } = props;
  return (
    <Dialog
      open
      onClose={() => closeCustomerProfileModal()}
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>Customer Profile</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={() => closeCustomerProfileModal()}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            First Name
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              type="text"
              margin="dense"
              variant="outlined"
              defaultValue={customer.first_name}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            Last Name
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              type="text"
              margin="dense"
              variant="outlined"
              defaultValue={customer.last_name}
              disabled
            />
          </Grid>
          <Grid item xs={3}>
            Phone Number
          </Grid>
          <Grid item xs={9}>
            <TextField
              fullWidth
              type="text"
              margin="dense"
              variant="outlined"
              defaultValue={customer.phone_number}
              disabled
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item xs={12}>
            <img src={avatar || customer.avatar} alt="" />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

CustomerProfileModal.propTypes = {
  customer: PropTypes.object.isRequired,
  closeCustomerProfileModal: PropTypes.func.isRequired,
};

export default CustomerProfileModal;
