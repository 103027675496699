import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Accordion,
  AccordionDetails,
  Box,
  IconButton,
  AccordionSummary,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  getIndustryServicesRequest,
  getServicesByCompanyRequest,
} from '../../../../../../../../redux/service/actions';
import NewCategoryModal from '../../../../../../../../Modals/Services/newCategory';
import usePrevious from '../../../../../../../../CustomHooks/usePrevious';
import ServicesIsolatedDropdown from './servicesIsolatedDropdown';
import DeleteServiceModal from '../../../../../../../../Modals/Services/deleteService';
import ServiceItem from './serviceItem';
import noService from '../../../../../../../../assets/img/no-service.svg';
import servicesLogo from '../../../../../../../../assets/servicesLogo';
import AccordionLoading from '../../../../../../../../Components/Loading/accordionLoading';

import { checkAppointmentsByServiceIdRequest } from '../../../../../../../../redux/appointment/actions';
import SnackbarToast from '../../../../../../../../Modules/SnackbarToast';
import { getCompanyByIdRequest } from '../../../../../../../../redux/company/actions';

function Services(props) {
  const {
    company,
    services: allServices,
    appointments,
    serviceOptions,
    getByCompanyId,
    getCompanyById,
    servicesByCompany,
    getIndustryServices,
    openNewCategoryModal,
    closeNewCategoryModal,
    checkAppointmentsByServiceId,
    isGetServicesByCompanyError,
    isGetServicesByCompanySuccess,
    getServicesByCompanyErrorMessage,
    isCheckAppointmentByServiceIdSuccess,
  } = props;

  // Get some props previous value
  const prevIsGetServicesCompanyIdSuccess = usePrevious(isGetServicesByCompanySuccess);
  const prevIsGetServicesCompanyIdError = usePrevious(isGetServicesByCompanyError);
  const prevIsCheckAppointmentByServiceIdSuccess = usePrevious(isCheckAppointmentByServiceIdSuccess);

  const [serviceData, setServiceData] = useState({
    service_id: '',
    duration: 0,
    price: '',
    staff_ids: [],
    staffs: [],
    name: '',
  });
  const [companyServices, setCompanyServices] = useState([]);
  const [selectedCompanyServiceId, setSelectedCompanyServiceId] = useState(0);
  const [openDeleteServiceModal, setOpenDeleteServiceModal] = useState(false);
  const [serviceIds, setServiceIds] = useState([]);
  const [selectedServiceParentId, setSelectedServiceParentId] = useState(0);
  const [staffOptions, setStaffOptions] = useState([]);
  const [services, setServices] = useState(allServices);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState('');

  useEffect(() => {
    getIndustryServices({ industryId: company.industry_id });
    getByCompanyId({
      companyId: company.id,
      industryId: company.industry_id,
    });
    getCompanyById(company.id);
    const staffOptions = [];
    company.staff.map((staff) => {
      staffOptions.push({
        id: staff.id,
        avatar: staff.avatar,
        label: staff.full_name,
        value: staff.id,
        professions: staff.staff_company_professions[0].name,
      });
    });

    setServiceData((prevValue) => ({
      ...prevValue,
    }));

    setStaffOptions(staffOptions);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (allServices.length > 0) {
      setServices(allServices);
    }
  }, [allServices]);

  // Check Appointments Success
  useEffect(() => {
    if (prevIsCheckAppointmentByServiceIdSuccess === false && isCheckAppointmentByServiceIdSuccess) {
      const filteredAppointments = appointments.filter((appointment) => appointment.status.id === 1 || appointment.status.id === 2);
      setFilteredAppointments(filteredAppointments);
    }
  }, [isCheckAppointmentByServiceIdSuccess]);

  // Handle Get Services By Company Id Success
  useEffect(() => {
    if (prevIsGetServicesCompanyIdSuccess === false && isGetServicesByCompanySuccess) {
      setCompanyServices(servicesByCompany);
      setLoading(true);
    }
  }, [isGetServicesByCompanySuccess]);

  // Handle Get Services By Company Id Error
  useEffect(() => {
    if (prevIsGetServicesCompanyIdError === false && isGetServicesByCompanyError) {
      setOpenSnackbar(true);
      setSnackbarMessage(getServicesByCompanyErrorMessage);
      setSnackbarType('error');
    }
  }, [isGetServicesByCompanyError]);

  const handleDeleteCategory = (e) => {
    e.stopPropagation();
    setExpanded(false);
    const companyService = companyServices.find((service) => service.id === selectedServiceId);
    const serviceIds = [];
    JSON.parse(companyService.subServices).map((subService) => {
      if (subService.company_service_id) {
        serviceIds.push(subService.company_service_id);
      }
    });

    setServiceIds(serviceIds);
    checkAppointmentsByServiceId({
      service_ids: serviceIds,
    });
    setAnchorEl(false);
    setSelectedCompanyServiceId(0);
    setSelectedServiceParentId(selectedServiceId);
    setOpenDeleteServiceModal(true);
  };

  const handleAddServiceToStaff = (e, parentServiceId) => {
    e.stopPropagation();
    setExpanded(!expanded ? parentServiceId : expanded);
    const companyServicesCopy = [...companyServices];
    const companyService = companyServices.find((service) => service.id === parentServiceId);
    const companyServiceIndex = companyServices.findIndex((service) => service.id === parentServiceId);
    const subServices = JSON.parse(companyService.subServices);
    let createdSubServiceForm = true;
    subServices.map((item) => {
      if (!item.company_service_id) {
        createdSubServiceForm = false;
      }
    });
    if (createdSubServiceForm) {
      subServices.unshift(serviceData);
      companyService.subServices = JSON.stringify(subServices);
      if (!companyService.accordion) {
        companyService.accordion = !(companyService.accordion);
      }
      companyServicesCopy[companyServiceIndex] = companyService;
      setCompanyServices(companyServicesCopy);
    }
  };

  const toggleAccordion = (parentServiceId) => {
    const companyServicesCopy = [...companyServices];
    const companyService = companyServices.find((service) => service.id === parentServiceId);
    companyService.accordion = !(companyService.accordion);
    setCompanyServices(companyServicesCopy);
  };

  const useStyles = makeStyles(() => ({
    expanded: {
      margin: '0 0 !important',
    },
  }));

  const classes = useStyles();

  const handleChangeAccordion = (parentServiceId) => {
    if (parentServiceId === expanded) {
      setExpanded(false);
    } else {
      setExpanded(parentServiceId);
    }
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteServiceModal(false);
    setAnchorEl(null);
  };

  return (
    <>
      {loading ? (
        <div id="tab-main-parent" className="tab-customers">
          <Box className="box-general">
            <div className="main-content">
              {companyServices.length ? companyServices.map((service, index) => (
                <Box key={`${service.name}-${index}`} mt={4} className="accordion-box">
                  <Accordion elevation={0} expanded={expanded === service.id} onChange={() => handleChangeAccordion(service.id)}>
                    <AccordionSummary
                      classes={{
                        expanded: classes.expanded,
                      }}
                      expandIcon={<ExpandMoreIcon onClick={() => toggleAccordion(service.id)} />}
                      className="card-header-service accordion-category"
                    >
                      <Box display="flex" alignItems="center" width="100%">
                        <Box mr="10px">
                          <img src={servicesLogo[service.name]} alt="serviceLogo" width="40px" />
                        </Box>
                        <Box width="inherit">
                          <Typography variant="h5">
                            {service.name}
                          </Typography>
                        </Box>

                        <Box display="flex" width="100%" justifyContent="flex-end">
                          <Box align="left">
                            <IconButton color="primary" className="addIcon-Service" onClick={(e) => handleAddServiceToStaff(e, service.id)}>
                              <AddIcon />
                            </IconButton>
                          </Box>
                          <Box>
                            <ServicesIsolatedDropdown
                              anchorEl={anchorEl}
                              setAnchorEl={setAnchorEl}
                              parentServiceId={service.id}
                              selectedServiceId={selectedServiceId}
                              setSelectedServiceId={setSelectedServiceId}
                              handleDeleteCategory={(e) => handleDeleteCategory(e)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box className="card-box-root-service" width="100%">
                        <Box height="auto">
                          {JSON.parse(service.subServices).map((subService, index) => (
                            <Box className="service" key={`${subService.name}-${index}`}>
                              <ServiceItem
                                companyId={company.id}
                                subService={subService}
                                serviceId={service.id}
                                setServiceIds={setServiceIds}
                                setSelectedCompanyServiceId={setSelectedCompanyServiceId}
                                setSelectedServiceParentId={setSelectedServiceParentId}
                                setOpenDeleteServiceModal={setOpenDeleteServiceModal}
                                companyServices={companyServices}
                                setCompanyServices={setCompanyServices}
                                services={services}
                                setServices={setServices}
                                staffOptions={staffOptions}
                                setFilteredAppointments={setFilteredAppointments}
                                industryId={company.industry_id}
                              />
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )) : (
                <Box className="service-page" mx="auto">
                  <Box>
                    <Box>
                      <img src={noService} alt="noCustomer" />
                    </Box>
                    <Typography component="div">
                      <Box fontWeight="500" align="center">
                        <Typography variant="h6" color="secondary">
                          No services yet
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              )}
            </div>
          </Box>

          {openNewCategoryModal && (
            <NewCategoryModal
              company={company}
              serviceOptions={serviceOptions}
              companyServices={companyServices}
              onClose={closeNewCategoryModal}
            />
          )}

          <DeleteServiceModal
            open={openDeleteServiceModal}
            onClose={handleCloseDeleteModal}
            companyServices={companyServices}
            setCompanyServices={setCompanyServices}
            companyServiceId={selectedCompanyServiceId}
            setCompanyServiceId={setSelectedCompanyServiceId}
            serviceIds={serviceIds}
            setServiceIds={setServiceIds}
            selectedCompanyParentId={selectedServiceParentId}
            setSelectedCompanyParentId={setSelectedServiceParentId}
            // servicesByCompanyId={servicesByCompanyId}
            // companyId={company.id}
            filteredAppointments={filteredAppointments}
          />

          <SnackbarToast
            message={snackbarMessage}
            type={snackbarType}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
          />
        </div>
      ) : <AccordionLoading />}
    </>
  );
}

Services.propTypes = {
  getCompanyById: PropTypes.func.isRequired,
  // Get Props From Parent
  company: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired,
  openNewCategoryModal: PropTypes.bool.isRequired,
  serviceOptions: PropTypes.array.isRequired,
  closeNewCategoryModal: PropTypes.func.isRequired,
  getIndustryServices: PropTypes.func.isRequired,
  // Get Services By Company Id
  getByCompanyId: PropTypes.func.isRequired,
  isGetServicesByCompanySuccess: PropTypes.bool.isRequired,
  isGetServicesByCompanyError: PropTypes.bool.isRequired,
  servicesByCompany: PropTypes.array.isRequired,
  getServicesByCompanyErrorMessage: PropTypes.string.isRequired,
  // Check Appointments
  checkAppointmentsByServiceId: PropTypes.func.isRequired,
  isCheckAppointmentByServiceIdSuccess: PropTypes.bool.isRequired,
  appointments: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // Get Industry Service
  isGetIndustryServicesSuccess: state.service.isGetIndustryServicesSuccess,
  services: state.service.services,
  // Update service
  isUpdateServiceSuccess: state.service.isUpdateServiceSuccess,
  isUpdateServiceError: state.service.isUpdateServiceError,
  updatedService: state.service.updatedService,
  updateServiceErrorMessage: state.service.updateServiceErrorMessage,
  // // Add service
  isAddServiceSuccess: state.service.isAddServiceSuccess,
  addedService: state.service.addedService,
  addServiceErrorMessage: state.service.addServiceErrorMessage,
  // Get Services By Company Id
  isGetServicesByCompanySuccess: state.service.isGetServicesByCompanySuccess,
  isGetServicesByCompanyError: state.service.isGetServicesByCompanyError,
  servicesByCompany: state.service.servicesByCompany,
  getServicesByCompanyErrorMessage: state.service.getServicesByCompanyErrorMessage,
  // Check Appointments
  isCheckAppointmentByServiceIdSuccess: state.appointment.isCheckAppointmentByServiceIdSuccess,
  isCheckAppointmentByServiceIdError: state.appointment.isCheckAppointmentByServiceIdError,
  appointments: state.appointment.appointments,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndustryServices: (data) => dispatch(getIndustryServicesRequest(data)),
    getByCompanyId: (data) => dispatch(getServicesByCompanyRequest(data)),
    getCompanyById: (data) => dispatch(getCompanyByIdRequest(data)),
    checkAppointmentsByServiceId: (data) => dispatch(checkAppointmentsByServiceIdRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
