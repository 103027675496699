import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Dialog,
  DialogTitle,
  CardActions,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Avatar,
  Divider,
  Button,
  IconButton,
  Typography,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import BlockIcon from '@material-ui/icons/Block';
import moment from 'moment';
import SettingsIcon from '@material-ui/icons/Settings';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LightTooltip from '../../MaterialComponents/lightTooltip';

function AppointmentActions(props) {
  const {
    open,
    edit,
    accept,
    cancel,
    onClose,
    appointment,
    handleDelete,
  } = props;

  const isAcceptAllowed = appointment && appointment.is_accept_allowed;
  const statusId = appointment && parseInt(appointment.status_id);

  return (
    <Dialog
      className="appointment-details"
      aria-labelledby="customized-dialog-title"
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
          <span>Appointment Details</span>
          <span>
            {((statusId !== 4 && statusId !== 3
              && moment(`${appointment.date} ${appointment.start_time}`).isAfter(moment()))) && (
              <LightTooltip title="Edit" placement="top">
                <IconButton onClick={() => edit(appointment.id)}>
                  <EditIcon />
                </IconButton>
              </LightTooltip>
            )}
            {/* If appointment accept is allowed */}
            {isAcceptAllowed && (
              <LightTooltip title="Accept" placement="top">
                <IconButton
                  onClick={() => accept(appointment.id)}
                >
                  <CheckCircleOutlineIcon />
                </IconButton>
              </LightTooltip>
            )}
            {/* If appointment is not cancelled and finished */}
            {(statusId !== 4 && statusId !== 3) && (
              <LightTooltip title="Cancel" placement="top">
                <IconButton
                  onClick={() => cancel(appointment.id)}
                >
                  <BlockIcon />
                </IconButton>
              </LightTooltip>
            )}
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            <Card className={`appointment-card ${appointment.status ? appointment.status.name : ''}`}>
              <CardContent className="appointment-card-body">
                <Typography>
                  <div className="appointment-start-date">
                    <span className="month">{moment(appointment.date).format('MMM')}</span>
                    <span className="day">{moment(appointment.date).format('D')}</span>
                    <span className="time">{moment(appointment.start_time, 'HH:mm').format('HH:mm')}</span>
                  </div>
                </Typography>
              </CardContent>
              <CardActions className="appointment-card-actions">
                <Typography align="center" size="small">
                  {appointment.status && (appointment.status.name).toUpperCase()}
                </Typography>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={7}>
            <Card className="appointment-card-info">
              <CardHeader
                avatar={(
                  <Avatar variant="circle">
                    {appointment.company && appointment.company.name.charAt(0).toUpperCase()}
                  </Avatar>
                )}
                title={appointment.company ? appointment.company.name : ''}
                subheader={`${appointment.company ? appointment.company.phone_code : ''} ${appointment.company ? appointment.company.phone_number : ''}`}
              />
              <Divider />
              <CardContent className="appointment-info-body">
                <Box display="flex" minWidth="100px">
                  <Box>
                    <SettingsIcon />
                  </Box>
                  <Box alignSelf="center" className="info-text">
                    {appointment.service && appointment.service.name} ({appointment.duration} min)
                  </Box>
                </Box>
                <Box display="flex" minWidth="100px">
                  <Box>
                    <AccountCircleIcon />
                  </Box>
                  <Box alignSelf="center" className="info-text">
                    {appointment.staff && appointment.staff.full_name}
                  </Box>
                </Box>
                <Box display="flex" minWidth="100px">
                  <Box>
                    <LocalAtmIcon />
                  </Box>
                  <Box alignSelf="center" className="info-text">
                    AMD {appointment.price}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions divider className="appointment-preview-btn">
        <Button
          className="delete-appointment-btn"
          onClick={(e) => handleDelete(e, appointment.id)}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AppointmentActions.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  appointment: PropTypes.object.isRequired,
  accept: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default AppointmentActions;
