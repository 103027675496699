import moment from 'moment';

function getNotificationTimeDifference(date, tz) {
  const end = moment().tz(tz);
  const start = moment.tz(date, tz);
  const duration = moment.duration(end.diff(start));
  let humanized = duration.humanize();
  humanized = humanized.replace(' an', 1);
  humanized = humanized.replace(' a', 1);
  return `${humanized} ago`;
}

export default getNotificationTimeDifference;
