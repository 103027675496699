import React, { useEffect, useState } from 'react';
import {
  Select,
  TextField,
  InputLabel,
  Box,
  Grid,
  Button,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ErrorIcon from '@material-ui/icons/Error';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import hours from '../../../../../../../../Modules/hour';
import minutes from '../../../../../../../../Modules/minutes';
import EditServiceModal from '../../../../../../../../Modals/Services/editService';
import {
  addServiceRequest,
  updateIndividualUserServiceRequest,
} from '../../../../../../../../redux/service/actions';
import usePrevious from '../../../../../../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../../../../../../Modules/SnackbarToast';
import { checkAppointmentsByServiceIdRequest } from '../../../../../../../../redux/appointment/actions';

function EditServiceItem(props) {
  const dispatch = useDispatch();
  const {
    // Add service
    isAddServiceSuccess,
    isAddServiceError,
    addedService,
    addServiceErrorMessage,
    // Update Service
    isUpdateIndividualUserServiceSuccess,
    isUpdateIndividualUserServiceError,
    updatedIndividualUserService,
    updateServiceIndividualUserErrorMessage,
  } = useSelector((state) => state.service);

  const {
    isCheckAppointmentByServiceIdSuccess,
    appointments,
  } = useSelector((state) => state.appointment);

  const {
    individualUserId,
    serviceId,
    industryId,
    subService,
    setEditAble,
    serviceOptions,
    individualUserServices,
    setIndividualUserServices,
  } = props;

  // Get some props previous value
  const prevIsUpdateIndividualUserServiceSuccess = usePrevious(isUpdateIndividualUserServiceSuccess);
  const prevIsUpdateIndividualUserServiceError = usePrevious(isUpdateIndividualUserServiceError);
  const prevIsAddServiceSuccess = usePrevious(isAddServiceSuccess);
  const prevIsAddServiceError = usePrevious(isAddServiceError);
  const prevIsCheckAppointmentByServiceIdSuccess = usePrevious(isCheckAppointmentByServiceIdSuccess);

  const [serviceData, setServiceData] = useState(subService);
  const [selectedServiceOption, setSelectedServiceOption] = useState({});
  const [openEditServiceModal, setOpenEditServiceModal] = useState(false);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [subServiceData, setSubServiceData] = useState({});
  const [errors, setErrors] = useState({
    service_id: false,
    duration: false,
    price: false,
    staffs: false,
  });

  const useStyles = makeStyles(() => ({
    menuPaper: {
      maxHeight: 300,
    },
  }));

  useEffect(() => {
    if (subService.individual_user_industry_service_id) {
      const serviceDataCopy = { ...serviceData };
      const serviceOptionsCopy = [...serviceOptions];
      serviceOptionsCopy.push({
        id: subService.service_id,
        logo: null,
        label: subService.name,
        value: subService.service_id,
      });

      const selectedValue = serviceOptionsCopy.find((service) => service.id === serviceData.service_id);
      serviceDataCopy.previous_service_id = subService.service_id;
      serviceDataCopy.editType = 'update';
      setServiceData(serviceDataCopy);
      if (Object.keys(subServiceData).length === 0) {
        setSubServiceData(serviceDataCopy);
      }
      if (selectedValue) {
        setSelectedServiceOption(selectedValue);
      }
    }
  }, [subService]);

  // Check Appointments Success
  useEffect(() => {
    if (prevIsCheckAppointmentByServiceIdSuccess === false && isCheckAppointmentByServiceIdSuccess) {
      const filteredAppointments = appointments.filter((appointment) => appointment.status.id === 1 || appointment.status.id === 2);
      if (!validateForm(serviceData)) {
        if (filteredAppointments.length > 0) {
          setOpenEditServiceModal(true);
        } else {
          handleSaveChangedService();
        }
      }
      setFilteredAppointments(filteredAppointments);
    }
  }, [isCheckAppointmentByServiceIdSuccess]);

  // Handle Update Company Service Success
  useEffect(() => {
    if (prevIsUpdateIndividualUserServiceSuccess === false && isUpdateIndividualUserServiceSuccess && subService.individual_user_industry_service_id === updatedIndividualUserService.individual_user_industry_service_id) {
      const individualUserServicesCopy = [...individualUserServices];
      const individualUserService = individualUserServices.find((service) => service.id === serviceId);
      const individualUserServiceIndex = individualUserServices.findIndex((service) => service.id === serviceId);
      const subServices = JSON.parse(individualUserService.subServices);
      const subServiceIndex = subServices.findIndex((subService) => subService.individual_user_industry_service_id === updatedIndividualUserService.individual_user_industry_service_id);
      const selectedValue = serviceOptions.find((service) => service.id === updatedIndividualUserService.id);

      setSelectedServiceOption(selectedValue);
      subServices[subServiceIndex] = updatedIndividualUserService;
      individualUserService.subServices = JSON.stringify(subServices);
      individualUserServicesCopy[individualUserServiceIndex] = individualUserService;
      setEditAble(false);
      setIndividualUserServices(individualUserServicesCopy);
    }
  }, [isUpdateIndividualUserServiceSuccess]);

  // Handle Update Company Service Error
  useEffect(() => {
    if (prevIsUpdateIndividualUserServiceError === false && isUpdateIndividualUserServiceError) {
      snackBarAlert(true, updateServiceIndividualUserErrorMessage, 'error');
    }
  }, [isUpdateIndividualUserServiceError]);

  // Handle Add Service Success
  useEffect(() => {
    if (prevIsAddServiceSuccess === false && isAddServiceSuccess) {
      const individualUserServicesCopy = [...individualUserServices];
      const individualUserService = individualUserServices.find((service) => service.id === serviceId);
      const individualUserServiceIndex = individualUserServices.findIndex((service) => service.id === serviceId);
      const subServices = JSON.parse(individualUserService.subServices);
      subServices.splice(0, 1);
      subServices.unshift(addedService);
      individualUserService.subServices = JSON.stringify(subServices);
      individualUserServicesCopy[individualUserServiceIndex] = individualUserService;
      setIndividualUserServices(individualUserServicesCopy);
    }
  }, [isAddServiceSuccess]);

  // Handle Add Service Error
  useEffect(() => {
    if (prevIsAddServiceError === false && isAddServiceError) {
      snackBarAlert(true, addServiceErrorMessage, 'error');
    }
  }, [isAddServiceError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleSaveService = () => {
    if (!validateForm(serviceData)) {
      if (JSON.stringify(serviceData) !== JSON.stringify(subServiceData)) {
        dispatch(checkAppointmentsByServiceIdRequest({
          individual_user_industry_id: individualUserId,
          service_id: subService.individual_user_industry_service_id,
        }));
      } else {
        setEditAble(false);
      }
    }
  };

  const handleSaveChangedService = () => {
    const serviceDataCopy = { ...serviceData };
    serviceDataCopy.parent_id = serviceId;
    serviceDataCopy.industry_id = industryId;
    serviceDataCopy.individual_user_industry_id = individualUserId;
    serviceDataCopy.editType = filteredAppointments ? serviceDataCopy.editType : null;
    dispatch(updateIndividualUserServiceRequest(serviceDataCopy));
  };

  const validateForm = (subService) => {
    const errorsCopy = { ...errors };
    errorsCopy.service_id = (subService.service_id === '' && subService.name === '');
    errorsCopy.price = subService.price === '';
    errorsCopy.duration = subService.duration === 0;
    setErrors(() => ({ ...errorsCopy }));

    return (errorsCopy.service_id || errorsCopy.price || errorsCopy.duration);
  };

  const handleAddService = () => {
    const serviceDataCopy = { ...serviceData };
    serviceDataCopy.single = true;
    serviceDataCopy.parent_id = serviceId;
    serviceDataCopy.industry_id = industryId;

    dispatch(addServiceRequest(serviceDataCopy));
  };

  const handleServiceSelectChange = (e, selectedService) => {
    const serviceDataCopy = { ...serviceData };
    if (selectedService) {
      if (typeof selectedService === 'string') {
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          setSelectedServiceOption(selectedService);
        });
      } else if (selectedService && selectedService.inputValue) {
        serviceDataCopy.name = selectedService.inputValue;
        serviceDataCopy.service_id = null;
        setSelectedServiceOption(selectedService.inputValue);
      } else {
        setSelectedServiceOption(selectedService);
        serviceDataCopy.service_id = selectedService.id;
      }
    } else {
      setSelectedServiceOption('');
      serviceDataCopy.service_id = '';
      serviceDataCopy.name = '';
    }
    setServiceData(serviceDataCopy);
  };

  const handleHourOrMinChange = (e) => {
    const { name, value } = e.target;
    const serviceDataCopy = { ...serviceData };

    if (name === 'hour') {
      const min = serviceDataCopy.duration % 60;
      serviceDataCopy.duration = (parseInt(value) * 60) + min;
    } else {
      const hour = Math.floor(serviceDataCopy.duration / 60);
      serviceDataCopy.duration = (hour * 60) + parseInt(value);
    }
    setServiceData(serviceDataCopy);
  };

  const handlePriceChange = (e) => {
    const { value } = e.target;
    const serviceDataCopy = { ...serviceData };
    serviceDataCopy.price = parseInt(value);
    setServiceData(serviceDataCopy);
  };

  const handleDeleteService = (index) => {
    const individualUserServicesCopy = [...individualUserServices];
    const individualUserService = individualUserServices.find((service) => service.id === serviceId);
    const subServices = JSON.parse(individualUserService.subServices);
    const individualUserServiceIndex = individualUserServices.findIndex((service) => service.id === serviceId);
    subServices.splice(index, 1);
    individualUserService.subServices = JSON.stringify(subServices);
    individualUserServicesCopy[individualUserServiceIndex] = individualUserService;
    setIndividualUserServices(individualUserServicesCopy);
  };

  const classes = useStyles();
  const filter = createFilterOptions();

  return (
    <>
      <Grid container direction="row" alignItems="center" spacing={3}>
        <Grid item xs={3}>
          <FormControl
            fullWidth
            size="small"
            variant="outlined"
            error={!!errors.service_id}
          >
            <Autocomplete
              size="small"
              fullWidth
              value={selectedServiceOption}
              onChange={handleServiceSelectChange}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              options={serviceOptions}
              getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.label;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(option) => option.label}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label="Service *" variant="outlined" error={!!errors.service_id} />
              )}
            />

            { errors.service_id && (
              <div className="error-message-content">
                <ErrorIcon fontSize="small" color="error" />
                <FormHelperText>
                  Service is required
                </FormHelperText>
              </div>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Box
            display="flex"
          >
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={!!errors.duration}
              className="duration"
            >
              <InputLabel error={!!errors.duration}>Hour *</InputLabel>
              <Select
                variant="outlined"
                data-cy="breaking-start-time"
                onChange={(e) => handleHourOrMinChange(e, subService.individual_user_industry_service_id)}
                value={Math.floor(serviceData.duration / 60)}
                name="hour"
                label="Hour *"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                { hours.map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={!!errors.duration}
            >
              <InputLabel error={!!errors.duration}>Min *</InputLabel>
              <Select
                variant="outlined"
                data-cy="breaking-start-time"
                value={(serviceData.duration % 60)}
                onChange={(e) => handleHourOrMinChange(e, subService.individual_user_industry_service_id)}
                name="min"
                label="Min *"
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                { minutes.map((min) => <MenuItem key={Math.random()} value={min}>{min}</MenuItem>) }
              </Select>
            </FormControl>
          </Box>

          { errors.duration && (
            <div className="error-message-content">
              <ErrorIcon fontSize="small" color="error" />
              <FormHelperText error>
                Duration is required
              </FormHelperText>
            </div>
          )}
        </Grid>
        <Grid item xs>
          <FormControl
            fullWidth
            size="small"
            variant="outlined"
            error={!!errors.price}
          >
            <TextField
              data-cy="price"
              size="small"
              value={serviceData.price}
              onChange={(e) => handlePriceChange(e, subService.individual_user_industry_service_id)}
              type="number"
              name="price"
              label="Price(AMD) *"
              placeholder="1000 ֏"
              variant="outlined"
              error={!!errors.price}
              onKeyDown={(e) => e.key === '+' || e.key === '-' || e.key === 'e' ? e.preventDefault() : true}
            />

            { errors.price && (
              <div className="error-message-content">
                <ErrorIcon fontSize="small" color="error" />
                <FormHelperText>
                  Price is required
                </FormHelperText>
              </div>
            )}
          </FormControl>
        </Grid>
        <Grid item xs>
          <Box display="flex" justifyContent="center">
            <Box mr={2}>
              <Button
                size="small"
                variant="contained"
                onClick={subService.individual_user_industry_service_id ? () => handleSaveService(subService.individual_user_industry_service_id, serviceId) : handleAddService}
                color="primary"
                className=""
              >
                Save
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                onClick={subService.individual_user_industry_service_id ? () => setEditAble(false) : handleDeleteService}
                color="default"
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <EditServiceModal
        open={openEditServiceModal}
        filteredAppointments={filteredAppointments}
        onClose={() => setOpenEditServiceModal(false)}
        serviceData={serviceData}
        setServiceData={setServiceData}
        handleSaveChangedService={handleSaveChangedService}
      />
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

EditServiceItem.propTypes = {
  individualUserId: PropTypes.number.isRequired,
  individualUserServices: PropTypes.array.isRequired,
  subService: PropTypes.object.isRequired,
  serviceOptions: PropTypes.array.isRequired,
  serviceId: PropTypes.number.isRequired,
  setEditAble: PropTypes.func.isRequired,
  setIndividualUserServices: PropTypes.func.isRequired,
  industryId: PropTypes.number.isRequired,
};

export default EditServiceItem;
