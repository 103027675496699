import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import i18n from 'i18next';
import usePrevious from '../../../../../CustomHooks/usePrevious';
import { resetNotificationsRequest } from '../../../../../redux/account/actions';
import {
  makeReadRequest,
  acceptAppointmentRequest,
  acceptCustomerInvitationRequest,
  cancelAppointmentRequest,
  declineCustomerInvitationRequest,
  acceptStaffInvitationFromNotificationRequest,
  declineStaffInvitationFromNotificationRequest,
} from '../../../../../redux/notification/actions';
import '../../../../../style/notifications.scss';
import TopBarContent from '../../../../../Components/Notifications/TopBarContent/topBarContent';
import NotificationItemContent from '../../../../../Components/Notifications/NotificationItemContent/notificationItemContent';
import SnackbarToast from '../../../../../Modules/SnackbarToast';

function Notifications(props) {
  const {
    history,
    makeRead,
    isMakeReadSuccess,
    isMakeReadError,
    makeReadErrorMessage,
    userNotifications,
    acceptAppointment,
    isAcceptAppointmentSuccess,
    isAcceptAppointmentError,
    acceptAppointmentErrorMessage,
    cancelAppointment,
    isCancelAppointmentSuccess,
    isCancelAppointmentError,
    cancelAppointmentErrorMessage,
    acceptCustomerInvitation,
    isAcceptCustomerInvitationSuccess,
    isAcceptCustomerInvitationError,
    acceptCustomerInvitationErrorMessage,
    declineCustomerInvitation,
    isDeclineCustomerInvitationSuccess,
    isDeclineCustomerInvitationError,
    declineCustomerInvitationErrorMessage,
    acceptStaffInvitation,
    isAcceptStaffInvitationSuccess,
    isAcceptStaffInvitationError,
    acceptStaffInvitationErrorMessage,
    declineStaffInvitation,
    isDeclineStaffInvitationSuccess,
    isDeclineStaffInvitationError,
    declineStaffInvitationErrorMessage,
    resetNotifications,
  } = props;

  const [lastClickedNotificationId, setLastClickedNotificationId] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [notificationToDeleteId, setNotificationToDeleteId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous values
  const prevIsMakeReadSuccess = usePrevious(isMakeReadSuccess);
  const prevIsMakeReadError = usePrevious(isMakeReadError);
  const prevIsAcceptAppointmentSuccess = usePrevious(isAcceptAppointmentSuccess);
  const prevIsAcceptAppointmentError = usePrevious(isAcceptAppointmentError);
  const prevIsCancelAppointmentSuccess = usePrevious(isCancelAppointmentSuccess);
  const prevIsCancelAppointmentError = usePrevious(isCancelAppointmentError);
  const prevIsAcceptCustomerInvitationSuccess = usePrevious(isAcceptCustomerInvitationSuccess);
  const prevIsAcceptCustomerInvitationError = usePrevious(isAcceptCustomerInvitationError);
  const prevIsDeclineCustomerInvitationSuccess = usePrevious(isDeclineCustomerInvitationSuccess);
  const prevIsDeclineCustomerInvitationError = usePrevious(isDeclineCustomerInvitationError);
  const prevIsAcceptStaffInvitationSuccess = usePrevious(isAcceptStaffInvitationSuccess);
  const prevIsAcceptStaffInvitationError = usePrevious(isAcceptStaffInvitationError);
  const prevIsDeclineStaffInvitationSuccess = usePrevious(isDeclineStaffInvitationSuccess);
  const prevIsDeclineStaffInvitationError = usePrevious(isDeclineStaffInvitationError);

  // Component mounted
  useEffect(() => {
    setNotifications([...userNotifications]);
  }, []);
  // Perform, when accept appointment success
  useEffect(() => {
    if (prevIsAcceptAppointmentSuccess === false && isAcceptAppointmentSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, i18n.t('AppointmentAccepted'), 'success');
    }
  }, [isAcceptAppointmentSuccess]);
  // Perform, when accept appointment error
  useEffect(() => {
    if (prevIsAcceptAppointmentError === false && isAcceptAppointmentError) {
      snackBarAlert(true, acceptAppointmentErrorMessage, 'error');
    }
  }, [isAcceptAppointmentError]);
  // Perform, when cancel appointment success
  useEffect(() => {
    if (prevIsCancelAppointmentSuccess === false && isCancelAppointmentSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, i18n.t('AppointmentCanceled'), 'success');
    }
  }, [isCancelAppointmentSuccess]);
  // Perform, when accept appointment error
  useEffect(() => {
    if (prevIsCancelAppointmentError === false && isCancelAppointmentError) {
      snackBarAlert(true, cancelAppointmentErrorMessage, 'error');
    }
  }, [isCancelAppointmentError]);
  // Perform, when accept customer invitation success
  useEffect(() => {
    if (prevIsAcceptCustomerInvitationSuccess === false && isAcceptCustomerInvitationSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, i18n.t('InvitationAccepted'), 'success');
    }
  }, [isAcceptCustomerInvitationSuccess]);
  // Perform, when accept customer invitation error
  useEffect(() => {
    if (prevIsAcceptCustomerInvitationError === false && isAcceptCustomerInvitationError) {
      snackBarAlert(true, acceptCustomerInvitationErrorMessage, 'error');
    }
  }, [isAcceptCustomerInvitationError]);
  // Perform, when decline customer invitation success
  useEffect(() => {
    if (prevIsDeclineCustomerInvitationSuccess === false && isDeclineCustomerInvitationSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, i18n.t('InvitationDeclined'), 'success');
    }
  }, [isDeclineCustomerInvitationSuccess]);
  // Perform, when decline customer invitation error
  useEffect(() => {
    if (prevIsDeclineCustomerInvitationError === false && isDeclineCustomerInvitationError) {
      snackBarAlert(true, declineCustomerInvitationErrorMessage, 'error');
    }
  }, [isDeclineCustomerInvitationError]);
  // Perform, when accept staff invitation success
  useEffect(() => {
    if (prevIsAcceptStaffInvitationSuccess === false && isAcceptStaffInvitationSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, i18n.t('InvitationAccepted'), 'success');
    }
  }, [isAcceptStaffInvitationSuccess]);
  // Perform, when accept staff invitation error
  useEffect(() => {
    if (prevIsAcceptStaffInvitationError === false && isAcceptStaffInvitationError) {
      snackBarAlert(true, acceptStaffInvitationErrorMessage, 'error');
    }
  }, [isAcceptStaffInvitationError]);
  // Perform, when accept staff invitation success
  useEffect(() => {
    if (prevIsDeclineStaffInvitationSuccess === false && isDeclineStaffInvitationSuccess) {
      removeDeletedNotification(notifications);
      snackBarAlert(true, i18n.t('InvitationDeclined'), 'success');
    }
  }, [isDeclineStaffInvitationSuccess]);
  // Perform, when accept staff invitation error
  useEffect(() => {
    if (prevIsDeclineStaffInvitationError === false && isDeclineStaffInvitationError) {
      snackBarAlert(true, declineStaffInvitationErrorMessage, 'error');
    }
  }, [isDeclineStaffInvitationError]);
  // Perform, when make notification read success
  useEffect(() => {
    if (prevIsMakeReadSuccess === false && isMakeReadSuccess && lastClickedNotificationId) {
      const notificationsCopy = [...notifications];
      const notificationIndex = notificationsCopy.findIndex((item) => item.id === lastClickedNotificationId);
      notificationsCopy[notificationIndex].read_at = moment();
      setNotifications(notificationsCopy);
      resetNotifications(notificationsCopy);
      const { link } = notificationsCopy[notificationIndex];
      if (link) {
        history.push(link);
      }
    }
  }, [isMakeReadSuccess]);
  // Perform, when make notification read error
  useEffect(() => {
    if (prevIsMakeReadError === false && isMakeReadError) {
      const notificationsCopy = [...notifications];
      const notificationIndex = notificationsCopy.findIndex((item) => item.id === lastClickedNotificationId);
      history.push(notificationsCopy[notificationIndex].link);
      snackBarAlert(true, makeReadErrorMessage, 'error');
    }
  }, [isMakeReadError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const removeDeletedNotification = (notifications) => {
    const notificationsCopy = [...notifications];
    const notificationIndex = notificationsCopy.findIndex((item) => item.id === notificationToDeleteId);
    notificationsCopy.splice(notificationIndex, 1);
    setLastClickedNotificationId(null);
    setNotifications(notificationsCopy);
    resetNotifications(notificationsCopy);
  };

  const redirectNotification = (event, notification) => {
    const { classList } = event.target;
    const { id, link } = notification;
    if (classList.contains('btn')) {
      return false;
    }

    if (!notification.read_at) {
      setLastClickedNotificationId(id);
      makeRead({ id });
    } else if (link) {
      history.push(link);
    }
  };

  const handleAcceptAppointment = (id) => {
    setNotificationToDeleteId(id);
    acceptAppointment({ id });
  };

  const handleCancelAppointment = (id) => {
    setNotificationToDeleteId(id);
    cancelAppointment({ id });
  };

  const handleAcceptCustomerInvitation = (id) => {
    setNotificationToDeleteId(id);
    acceptCustomerInvitation({ id });
  };

  const handleDeclineCustomerInvitation = (id) => {
    setNotificationToDeleteId(id);
    declineCustomerInvitation({ id });
  };

  const handleAcceptStaffInvitation = (id) => {
    setNotificationToDeleteId(id);
    acceptStaffInvitation({ id });
  };

  const handleDeclineStaffInvitation = (id) => {
    setNotificationToDeleteId(id);
    declineStaffInvitation({ id });
  };

  const getNotificationStatusClassName = (status) => {
    if (status === 'Accepted') {
      return 'accepted';
    } else if (status === 'Created') {
      return 'created';
    } else {
      return 'cancelled';
    }
  };

  return (
    <>
      <div className="menu-item notifications">
        <div className="general-content">
          <TopBarContent />
          <div className="panel">
            <div className="main-content">
              <div className="notifications-list">
                <NotificationItemContent
                  notifications={notifications}
                  redirectNotification={redirectNotification}
                  getNotificationStatusClassName={getNotificationStatusClassName}
                  handleAcceptAppointment={handleAcceptAppointment}
                  handleCancelAppointment={handleCancelAppointment}
                  handleAcceptCustomerInvitation={handleAcceptCustomerInvitation}
                  handleDeclineCustomerInvitation={handleDeclineCustomerInvitation}
                  handleAcceptStaffInvitation={handleAcceptStaffInvitation}
                  handleDeclineStaffInvitation={handleDeclineStaffInvitation}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

Notifications.propTypes = {
  history: PropTypes.object.isRequired,
  userNotifications: PropTypes.array.isRequired,
  // Make notification read
  makeRead: PropTypes.func.isRequired,
  isMakeReadSuccess: PropTypes.bool.isRequired,
  isMakeReadError: PropTypes.bool.isRequired,
  makeReadErrorMessage: PropTypes.string.isRequired,
  // Accept appointment
  acceptAppointment: PropTypes.func.isRequired,
  isAcceptAppointmentSuccess: PropTypes.bool.isRequired,
  isAcceptAppointmentError: PropTypes.bool.isRequired,
  acceptAppointmentErrorMessage: PropTypes.string.isRequired,
  // Cancel appointment
  cancelAppointment: PropTypes.func.isRequired,
  isCancelAppointmentSuccess: PropTypes.bool.isRequired,
  isCancelAppointmentError: PropTypes.bool.isRequired,
  cancelAppointmentErrorMessage: PropTypes.string.isRequired,
  // Accept customer invitation
  acceptCustomerInvitation: PropTypes.func.isRequired,
  isAcceptCustomerInvitationSuccess: PropTypes.bool.isRequired,
  isAcceptCustomerInvitationError: PropTypes.bool.isRequired,
  acceptCustomerInvitationErrorMessage: PropTypes.string.isRequired,
  // Decline customer invitation
  declineCustomerInvitation: PropTypes.func.isRequired,
  isDeclineCustomerInvitationSuccess: PropTypes.bool.isRequired,
  isDeclineCustomerInvitationError: PropTypes.bool.isRequired,
  declineCustomerInvitationErrorMessage: PropTypes.string.isRequired,
  // Accept staff invitation
  acceptStaffInvitation: PropTypes.func.isRequired,
  isAcceptStaffInvitationSuccess: PropTypes.bool.isRequired,
  isAcceptStaffInvitationError: PropTypes.bool.isRequired,
  acceptStaffInvitationErrorMessage: PropTypes.string.isRequired,
  // Decline staff invitation
  declineStaffInvitation: PropTypes.func.isRequired,
  isDeclineStaffInvitationSuccess: PropTypes.bool.isRequired,
  isDeclineStaffInvitationError: PropTypes.bool.isRequired,
  declineStaffInvitationErrorMessage: PropTypes.string.isRequired,
  // Reset Notifications
  resetNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isMakeReadSuccess: state.notification.isMakeReadSuccess,
  isMakeReadError: state.notification.isMakeReadError,
  makeReadErrorMessage: state.notification.makeReadErrorMessage,
  userNotifications: state.account.userAccount.notifications,
  isAcceptAppointmentSuccess: state.notification.isAcceptAppointmentSuccess,
  isAcceptAppointmentError: state.notification.isAcceptAppointmentError,
  acceptAppointmentErrorMessage: state.notification.acceptAppointmentErrorMessage,
  isCancelAppointmentSuccess: state.notification.isCancelAppointmentSuccess,
  isCancelAppointmentError: state.notification.isCancelAppointmentError,
  cancelAppointmentErrorMessage: state.notification.cancelAppointmentErrorMessage,
  isAcceptCustomerInvitationSuccess: state.notification.isAcceptCustomerInvitationSuccess,
  isAcceptCustomerInvitationError: state.notification.isAcceptCustomerInvitationError,
  acceptCustomerInvitationErrorMessage: state.notification.acceptCustomerInvitationErrorMessage,
  isDeclineCustomerInvitationSuccess: state.notification.isDeclineCustomerInvitationSuccess,
  isDeclineCustomerInvitationError: state.notification.isDeclineCustomerInvitationError,
  declineCustomerInvitationErrorMessage: state.notification.declineCustomerInvitationErrorMessage,
  isAcceptStaffInvitationSuccess: state.notification.isAcceptStaffInvitationSuccess,
  isAcceptStaffInvitationError: state.notification.isAcceptStaffInvitationError,
  acceptStaffInvitationErrorMessage: state.notification.acceptStaffInvitationErrorMessage,
  isDeclineStaffInvitationSuccess: state.notification.isDeclineStaffInvitationSuccess,
  isDeclineStaffInvitationError: state.notification.isDeclineStaffInvitationError,
  declineStaffInvitationErrorMessage: state.notification.declineStaffInvitationErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    makeRead: (data) => dispatch(makeReadRequest(data)),
    acceptAppointment: (data) => dispatch(acceptAppointmentRequest(data)),
    cancelAppointment: (data) => dispatch(cancelAppointmentRequest(data)),
    resetNotifications: (data) => dispatch(resetNotificationsRequest(data)),
    acceptCustomerInvitation: (data) => dispatch(acceptCustomerInvitationRequest(data)),
    declineCustomerInvitation: (data) => dispatch(declineCustomerInvitationRequest(data)),
    acceptStaffInvitation: (data) => dispatch(acceptStaffInvitationFromNotificationRequest(data)),
    declineStaffInvitation: (data) => dispatch(declineStaffInvitationFromNotificationRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
