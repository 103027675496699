import React from 'react';
import PropTypes from 'prop-types';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { Box, MenuItem } from '@material-ui/core';

function SwitchToAccount(props) {
  const { handleSwitchToAccountProps, switchRoleName } = props;
  return (
    <MenuItem>
      <Box
        display="flex"
        onClick={() => handleSwitchToAccountProps({ showSwitchToAccountModal: true, switchRoleName })}
      >
        <Box mr="10px">
          <SyncAltIcon className="settings-icon" fontSize="small" />
        </Box>
        <Box alignSelf="center">
            Switch To {switchRoleName} Account
        </Box>
      </Box>
    </MenuItem>

  );
}

SwitchToAccount.propTypes = {
  handleSwitchToAccountProps: PropTypes.func.isRequired,
  switchRoleName: PropTypes.string.isRequired,
};

export default SwitchToAccount;
