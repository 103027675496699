import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { TablePagination } from '@material-ui/core';
import moment from 'moment';

import {
  getStaffAppointmentsHistoryRequest,
  getStaffUpcomingAppointmentsRequest,
} from '../../../../../../redux/staff/actions';
import getColorsByAppointmentStatus from 'Modules/getColorsByAppointmentStatus';
import capitalize from 'Modules/capitalize';
import noUpcomingAppointmentsImage from 'assets/noDataImages/no-upcoming-appointments.svg';
import noAppointmentsHistoryImage from 'assets/noDataImages/no-history.svg';
import Loading from 'Components/Loading/loading';
import './style.scss';

const AppointmentBox = (props) => {
  const {
    id,
    title,
    data,
    count,
    loading,
    setLoading,
    selectedIndex,
  } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    setPage(0);
    getAppointments(0);
  }, [selectedIndex]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setLoading(true);
    getAppointments(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAppointments = (newPage) => {
    if (selectedIndex === 0) {
      dispatch(getStaffUpcomingAppointmentsRequest({ id, page: newPage + 1 }));
    } else {
      dispatch(getStaffAppointmentsHistoryRequest({ id, page: newPage + 1 }));
    }
  };

  return (
    <>
      <p className="editCustomerText">
        {title}
      </p>
      <div className="appointments-section-customers">
        <div className="appointments-pagination-customer">
          {!loading ? (
            Object.keys(data).length ? (
              <div className="app-detail-box">
                {data.map((item) => (
                  <div className="appointment-box" style={{ backgroundColor: getColorsByAppointmentStatus(item.status_name, true) }}>
                    <div className="box-content">
                      <div className="time-box" style={{ backgroundColor: getColorsByAppointmentStatus(item.status_name, false) }}>
                        <div>
                          <span style={{ textTransform: 'capitalize' }}>
                            {moment(item.date, 'YYYY-MM-DD')
                              .locale(`${i18n.language}${i18n.language === 'hy' ? '-am' : ''}`)
                              .format('ddd, D MMM YYYY')}{' '}
                            {moment(item.start_time, 'HH:mm:ss')
                              .locale(`${i18n.language}${i18n.language === 'hy' ? '-am' : ''}`)
                              .format('HH:mm')}
                          </span>{' '}
                          - <span>{moment(item.end_time, 'HH:mm:ss').format('HH:mm')}</span>
                        </div>
                        <div className="status-name-customer">
                          <span>{item.status_name === 'accepted' ? t('Upcoming') : t(capitalize(item.status_name))}</span>
                        </div>
                      </div>
                      <div className="detail-box">
                        <p className="service-name">{item.service_name}</p>
                        <p className="customer-name">{item.customer_name || t('WalkIn')}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              selectedIndex === 0 ? (
                <div className="no-data">
                  <img src={noUpcomingAppointmentsImage} alt="no-upcoming-appointments" />
                </div>
              ) : (
                <div className="no-data">
                  <img src={noAppointmentsHistoryImage} alt="no-history" />
                </div>
              )
            )
          ) : (
            <Loading />
          )}
        </div>
        <div style={{ borderTop: '1px solid #E3E5E6' }}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={count}
            page={page}
            onPageChange={handleChangePage}
            labelRowsPerPage={t('RowsPerPage')}
            labelDisplayedRows={({ count, from, to }) => `${from}-${to}/${count}`}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
    </>
  );
};

AppointmentBox.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
};

export default AppointmentBox;
