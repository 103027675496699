import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import '../../../../../../style/dropzone.scss';
import './details.scss';
import '../../../../../../style/general.scss';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {
  AppBar,
  Box,
  Tab,
  Tabs,
  Toolbar,
  IconButton, TextField, Tooltip,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import SearchIcon from '@material-ui/icons/Search';
import TuneRoundedIcon from '@material-ui/icons/Tune';
import i18n from 'i18next';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import {
  getIndividualUserIndustriesRequest,
  getIndividualUserIndustryByIdRequest,
  deleteIndividualUserIndustryRequest,
  filterIndividualCustomerByStatusRequest,
} from '../../../../../../redux/individualUser/actions';
import {
  Services,
  Gallery,
  WorkingSchedule,
  Customers,
  Info,
} from './tabs';
import countryCodeOptions from '../../../../../../Modules/countryCodeOptions';
import AddNewServiceModal from '../../../../../../Modals/IndividualUserIndustry/AddNewServiceModal';
import {
  getGeneralAppointmentsRequest,
} from '../../../../../../redux/appointment/actions';
import InfoLoading from '../../../../../../Components/Loading/infoLoading';
import SnackbarToast from '../../../../../../Modules/SnackbarToast';
import FilterCustomerOptions from '../../../../../../Modals/Customers/FilterCustomerOptions';

function IndividualUserIndustryDetails(props) {
  const dispatch = useDispatch();

  const {
    match,
    history,
    userAccount,
    allCountries,
    getIndividualUserIndustryById,
    isGetIndividualUserIndustryByIdSuccess,
    isGetIndividualUserIndustryByIdError,
    IndividualUserIndustryGot,
    getIndividualUserIndustryErrorMessage,
    isDeleteIndividualUserIndustrySuccess,
    isDeleteIndividualUserIndustryError,
    deleteIndividualUserIndustryErrorMessage,
  } = props;

  const {
    isGetIndividualUserIndustriesSuccess,
    isGetIndividualUserIndustriesError,
    allIndividualUserIndustries,
  } = useSelector((state) => state.individualUser);

  // Get some props values
  const prevIsGetIndividualUserIndustryByIdSuccess = usePrevious(isGetIndividualUserIndustryByIdSuccess);
  const prevIsGetIndividualUserIndustryByIdError = usePrevious(isGetIndividualUserIndustryByIdError);
  const prevIsDeleteIndividualUserIndustrySuccess = usePrevious(isDeleteIndividualUserIndustrySuccess);
  const prevIsDeleteIndividualUserIndustryError = usePrevious(isDeleteIndividualUserIndustryError);
  const prevIsGetIndividualUserIndustriesSuccess = usePrevious(isGetIndividualUserIndustriesSuccess);
  const prevIsGetIndividualUserIndustriesError = usePrevious(isGetIndividualUserIndustriesError);

  const customerFilterOptions = [
    {
      value: 'index',
      label: 'Customers',
    },
    {
      value: 'invitations',
      label: 'Invitations',
    },
  ];

  const [loading, setLoading] = useState(false);
  const [individualUserIndustry, setIndividualUserIndustry] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [alert, setAlert] = useState(null);
  const [selectedCustomerFilter, setSelectedCustomerFilter] = useState({});
  const [openInviteCustomerModalPerformed, setOpenInviteCustomerModalPerformed] = useState(false);
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [addNewServiceModal, setAddNewServiceModal] = useState(false);
  const [openNewCategoryModal, setOpenNewCategoryModal] = useState(false);
  const [isUpdateIndustrySubmitDone, setIsUpdateIndustrySubmitDone] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const individualIndustry = {};
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
  const [isOpenCustomerFilter, setIsOpenCustomerFilter] = useState(null);
  const [currentIndustry, setCurrentIndustry] = useState({});
  const [checkedStatus, setCheckedStatus] = useState({
    active: true,
    inActive: true,
    individual_user_industry_ids: [],
    searchValue: '',
  });
  const [allIndustries, setAllIndustries] = useState({});
  const customerFilter = {
    active: true,
    inActive: true,
  };

  useEffect(() => {
    const { id } = match.params;
    if (id) {
      setLoading(true);
      getIndividualUserIndustryById(id);
      const phoneCodeOptions = countryCodeOptions.getCodeOptions(allCountries);
      setPhoneCodeOptions(phoneCodeOptions);
    }
    dispatch(getIndividualUserIndustriesRequest());
  }, []);

  useEffect(() => {
    if (prevIsGetIndividualUserIndustryByIdSuccess === false && isGetIndividualUserIndustryByIdSuccess) {
      const { id, tab } = match.params;
      let { filter } = match.params;
      const { selectedTab, selectedTabName } = getSelectedTab(tab);
      let url = `/industries/${id}/${selectedTabName}`;
      if (tab === 'customers') {
        if (!filter && filter !== 'index' && filter !== 'invitations') {
          filter = 'index';
        }
        url = `${url}/${filter}`;
        const selectedCustomerFilterCopy = customerFilterOptions.find((item) => item.value === filter);
        setSelectedCustomerFilter(selectedCustomerFilterCopy || customerFilterOptions[0]);
      }
      setIndividualUserIndustry(IndividualUserIndustryGot);
      setCurrentIndustry(IndividualUserIndustryGot);
      setLoading(false);
      setActiveTabIndex(selectedTab);
      history.push(url);
    }
  }, [isGetIndividualUserIndustryByIdSuccess]);

  useEffect(() => {
    if (prevIsGetIndividualUserIndustryByIdError === false && isGetIndividualUserIndustryByIdError) {
      snackBarAlert(true, getIndividualUserIndustryErrorMessage, 'error');
      history.push('/industries');
    }
  }, [isGetIndividualUserIndustryByIdError]);

  useEffect(() => {
    if (prevIsDeleteIndividualUserIndustrySuccess === false && isDeleteIndividualUserIndustrySuccess) {
      history.push('/industries');
      setLoading(false);
      setAlert(null);
      snackBarAlert(true, i18n.t('IndustryDeleted'), 'success');
    }
  }, [isDeleteIndividualUserIndustrySuccess]);

  useEffect(() => {
    if (prevIsDeleteIndividualUserIndustryError === false && isDeleteIndividualUserIndustryError) {
      setLoading(false);
      setAlert(null);
      snackBarAlert(true, deleteIndividualUserIndustryErrorMessage, 'error');
    }
  }, [isDeleteIndividualUserIndustryError]);

  // Handle get All Industry success
  useEffect(() => {
    if (prevIsGetIndividualUserIndustriesSuccess === false && isGetIndividualUserIndustriesSuccess) {
      setAllIndustries(allIndividualUserIndustries);
      const industryIds = allIndividualUserIndustries.map((item) => item.id);
      setCheckedStatus({
        ...checkedStatus,
        individual_user_industry_ids: industryIds,
      });
    }
  }, [isGetIndividualUserIndustriesSuccess]);

  // Handle get All Industry error
  useEffect(() => {
    if (prevIsGetIndividualUserIndustriesError === false && isGetIndividualUserIndustriesError) {
      snackBarAlert(true, i18n.t('InvalidData'), 'error');
      setLoading(false);
    }
  }, [isGetIndividualUserIndustriesError]);

  const getSelectedTab = (tab) => {
    let selectedTab;
    let selectedTabName = tab;
    switch (tab) {
      case 'info': {
        selectedTab = 0;
        break;
      }
      case 'gallery': {
        selectedTab = 1;
        break;
      }
      case 'working-schedule': {
        selectedTab = 2;
        break;
      }
      case 'services': {
        selectedTab = 3;
        break;
      }
      case 'customers': {
        selectedTab = 4;
        break;
      }
      default: {
        selectedTab = 0;
        selectedTabName = 'info';
      }
    }
    return {
      selectedTab,
      selectedTabName,
    };
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleChangeTab = (tab) => {
    const { id } = match.params;
    const { selectedTab, selectedTabName } = getSelectedTab(tab);
    let url = `/industries/${id}/${selectedTabName}`;
    if (tab === 'customers') {
      let shouldBeSelected = (selectedCustomerFilter.value || 'index');
      if (shouldBeSelected !== 'index' && shouldBeSelected !== 'invitations') {
        shouldBeSelected = 'index';
      }
      url = `${url}/${shouldBeSelected}`;
      let selectedCustomerFilterCopy = customerFilterOptions.find((item) => item.value === (shouldBeSelected));
      if (!selectedCustomerFilterCopy) {
        selectedCustomerFilterCopy = customerFilterOptions.find((item) => item.value === 'index');
      }
      setSelectedCustomerFilter(selectedCustomerFilterCopy || customerFilterOptions[0]);
    }
    setActiveTabIndex(selectedTab);
    history.push(url);
  };

  const getIndividualUserIndustry = (individualUserIndustry) => {
    setIndividualUserIndustry(individualUserIndustry);
  };

  const handleIndividualUserIndustryUpdated = (individualUserIndustry, success = false) => {
    if (success) {
      setIndividualUserIndustry(individualUserIndustry);
    }
  };

  const handleCustomersFilterChange = ({ target }) => {
    const { value } = JSON.parse(target.value);
    const { id, tab } = match.params;
    history.push(`/industries/${id}/${tab}/${value}`);
    const selected = customerFilterOptions.find((item) => item.value === value);
    setSelectedCustomerFilter(selected);
  };

  const handleCloseInviteCustomerModal = () => {
    setOpenInviteCustomerModalPerformed(false);
  };

  const handleOpenNewCategoryModal = () => setOpenNewCategoryModal(true);
  const handleCloseNewCategoryModal = () => setOpenNewCategoryModal(false);

  const onNewInvitationCreated = (invitation) => {
    setIndividualUserIndustry((prevValue) => ({
      ...prevValue,
      customerInvitations: [...prevValue.customerInvitations, invitation],
    }));
  };
  const handleSaveIndustry = (e) => {
    e.preventDefault();
    setIsUpdateIndustrySubmitDone(!isUpdateIndustrySubmitDone);
  };

  const handleIndustryUpdated = (industry, success = false) => {
    setIsUpdateIndustrySubmitDone(false);
    if (success) {
      setIndividualUserIndustry(industry);
    }
  };

  const handleSearchCustomer = (event) => {
    const data = {
      active: checkedStatus.active,
      inActive: checkedStatus.inActive,
      individual_user_industry_id: currentIndustry.id,
      searchValue: event.target ? event.target.value : '',
    };
    dispatch(filterIndividualCustomerByStatusRequest(data));
  };

  const handleCloseFilter = () => {
    setIsOpenCustomerFilter(false);
  };

  // Handle change industry checkbox
  const handleChangeIndustry = (event) => {
    const { name, value } = event.target;
    const checkedCheckBoxItems = checkedStatus[name].includes(parseInt(value))
      ? { ...checkedStatus, [name]: checkedStatus[name].filter((item) => item !== parseInt(value)) }
      : { ...checkedStatus, [name]: [...checkedStatus[name], parseInt(value)] };

    setCheckedStatus({ ...checkedCheckBoxItems });
  };

  // Handle change status checkbox
  const handleChangeStatusCheckbox = (event) => {
    const { name, value } = event.target;
    const checkedFilterItems = checkedStatus[name] === !!value
      ? { ...checkedStatus, [name]: !value }
      : { ...checkedStatus, [name]: !!value };
    setCheckedStatus({ ...checkedFilterItems });
  };

  return (
    <>
      <div>
        <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
              <Box display="flex" className="tabs-navbar">
                <Box alignSelf="center" className="divider-title">
                  <Typography variant="h6" noWrap>
                    { individualUserIndustry.industry_name }
                  </Typography>
                </Box>
                <Box alignSelf="center" ml="-18px" mr="6px">
                  <Link to="/industries">
                    <IconButton size="small">
                      <KeyboardArrowLeftIcon />
                    </IconButton>
                  </Link>
                </Box>
                <Box alignSelf="center" className="tab-companies">
                  <AppBar position="static" color="inherit" elevation={0}>
                    <Tabs
                      value={activeTabIndex}
                      aria-label="simple tabs example"
                      variant="scrollable"
                      indicatorColor="primary"
                      textColor="secondary"
                    >
                      <Tab
                        label="Info"
                        className={`item-nav${activeTabIndex === 0 ? ' active' : ''}`}
                        onClick={() => handleChangeTab('info')}
                      />
                      <Tab
                        label="Gallery"
                        className={`item-nav${activeTabIndex === 1 ? ' active' : ''}`}
                        onClick={() => handleChangeTab('gallery')}
                      />
                      <Tab
                        label="Working Schedule"
                        className={`item-nav${activeTabIndex === 2 ? ' active' : ''}`}
                        onClick={() => handleChangeTab('working-schedule')}
                      />
                      <Tab
                        label="Services"
                        className={`item-nav${activeTabIndex === 3 ? ' active' : ''}`}
                        onClick={() => handleChangeTab('services')}
                      />
                      <Tab
                        label="Customers"
                        className={`item-nav${activeTabIndex === 4 ? ' active' : ''}`}
                        onClick={() => handleChangeTab('customers')}
                      />
                    </Tabs>
                  </AppBar>
                </Box>
              </Box>
              <Box display="flex">
                <Box alignSelf="center" className="actions-tab">
                  { activeTabIndex === 0 ? (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={(event) => handleSaveIndustry(event)}
                    >
                      Update
                    </Button>
                  ) : null }
                  { activeTabIndex === 3 ? (
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      onClick={handleOpenNewCategoryModal}
                    >
                      Add Category
                    </Button>
                  ) : null }
                  { activeTabIndex === 4 ? (
                    <Box display="flex" alignItems="center">
                      <TextField
                        type="search"
                        size="small"
                        color="secondary"
                        className="search-staff"
                        placeholder="Search for customers"
                        InputProps={{
                          startAdornment: <SearchIcon position="start" />,
                        }}
                        variant="outlined"
                        onChange={handleSearchCustomer}
                      />
                      <Button
                        color="primary"
                        size="small"
                        variant="contained"
                        onClick={() => setIsAddCustomerModalOpen(true)}
                      >
                        Add Customer
                      </Button>
                      <Tooltip title="Filter">
                        <IconButton
                          aria-label="filter"
                          onClick={() => setIsOpenCustomerFilter(true)}
                        >
                          <TuneRoundedIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                      <FilterCustomerOptions
                        company={currentIndustry}
                        checkedItems={checkedStatus}
                        customerFilter={customerFilter}
                        role={userAccount.activeRole.name}
                        handleCloseFilter={handleCloseFilter}
                        isOpenCustomerFilter={isOpenCustomerFilter}
                        allIIndustries={allIndustries}
                        handleChangeIndustry={handleChangeIndustry}
                        handleChangeStatus={handleChangeStatusCheckbox}
                        handleSubmitCustomerOption={handleSearchCustomer}
                      />
                    </Box>
                  ) : null }
                </Box>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        {!loading ? (
          <Box className="tab-list companies-tab-list">
            { activeTabIndex === 0 ? (
              <Info
                individualIndustry={individualIndustry}
                individualUserIndustryItem={individualUserIndustry}
                isUpdateIndustrySubmitDone={isUpdateIndustrySubmitDone}
                sendIndustryUpdated={handleIndustryUpdated}
              />
            ) : null }
            { activeTabIndex === 1 ? (
              <Gallery
                match={match}
              />
            ) : null }
            { activeTabIndex === 2 ? (
              <WorkingSchedule
                individualIndustry={individualIndustry}
                individualUserIndustryItem={individualUserIndustry}
                setIndividualUserIndustryItem={setIndividualUserIndustry}
              />
            ) : null }
            { activeTabIndex === 3 ? (
              <Services
                match={props}
                history={history}
                openNewCategoryModal={openNewCategoryModal}
                handleCloseNewCategoryModal={handleCloseNewCategoryModal}
                individualUserIndustryItem={individualUserIndustry}
                sendIndividualUserIndustry={(individualUserIndustry) => getIndividualUserIndustry(individualUserIndustry)}
              />
            ) : null }
            { activeTabIndex === 4 ? (
              <Customers
                match={match}
                phoneCodeOptions={phoneCodeOptions}
                addCustomerModalOpen={isAddCustomerModalOpen}
                customerFilterOptions={customerFilterOptions}
                selectedCustomerFilter={selectedCustomerFilter}
                individualUserIndustry={individualUserIndustry}
                onNewInvitationCreated={onNewInvitationCreated}
                sendSelectedCustomerFilter={handleCustomersFilterChange}
                closeInviteCustomerModal={handleCloseInviteCustomerModal}
                openInviteCustomerModalPerformed={openInviteCustomerModalPerformed}
                setAddCustomerModalOpen={() => setIsAddCustomerModalOpen(false)}
                sendIndividualUserIndustryUpdated={handleIndividualUserIndustryUpdated}
              />
            ) : null}
          </Box>
        ) : <InfoLoading /> }
        {alert}
      </div>
      {activeTabIndex === 3 ? (
        <AddNewServiceModal
          individualUserIndustryItem={individualUserIndustry}
          open={addNewServiceModal}
          onClose={() => setAddNewServiceModal(false)}
        />
      ) : null}
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

IndividualUserIndustryDetails.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  allCountries: PropTypes.array.isRequired,
  userAccount: PropTypes.object.isRequired,
  // Get Individual User Industry By Id Props
  getIndividualUserIndustryById: PropTypes.func.isRequired,
  isGetIndividualUserIndustryByIdSuccess: PropTypes.bool.isRequired,
  isGetIndividualUserIndustryByIdError: PropTypes.bool.isRequired,
  IndividualUserIndustryGot: PropTypes.object.isRequired,
  getIndividualUserIndustryErrorMessage: PropTypes.string.isRequired,
  // Delete Individual User Industry
  isDeleteIndividualUserIndustrySuccess: PropTypes.bool.isRequired,
  isDeleteIndividualUserIndustryError: PropTypes.bool.isRequired,
  deleteIndividualUserIndustryErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // User Account
  userAccount: state.account.userAccount,
  // Get Individual User Industry By Id
  isGetIndividualUserIndustryByIdSuccess: state.individualUser.isGetIndividualUserIndustryByIdSuccess,
  isGetIndividualUserIndustryByIdError: state.individualUser.isGetIndividualUserIndustryByIdError,
  IndividualUserIndustryGot: state.individualUser.IndividualUserIndustryGot,
  getIndividualUserIndustryErrorMessage: state.individualUser.getIndividualUserIndustryErrorMessage,
  // Get Industries
  industries: state.industry.industries,
  // Get Industry Services
  isGetIndustryServicesSuccess: state.service.isGetIndustryServicesSuccess,
  isGetIndustryServicesError: state.service.isGetIndustryServicesError,
  services: state.service.services,
  // Delete Individual User Industry
  isDeleteIndividualUserIndustrySuccess: state.individualUser.isDeleteIndividualUserIndustrySuccess,
  isDeleteIndividualUserIndustryError: state.individualUser.isDeleteIndividualUserIndustryError,
  deleteIndividualUserIndustryErrorMessage: state.individualUser.deleteIndividualUserIndustryErrorMessage,
  // Get all countries
  allCountries: state.country.allCountries,
  // Update Individual User Industry
  isUpdateIndividualUserIndustrySuccess: state.individualUser.isUpdateIndividualUserIndustrySuccess,
  isUpdateIndividualUserIndustryError: state.individualUser.isUpdateIndividualUserIndustryError,
  updatedIndividualUserIndustry: state.individualUser.updatedIndividualUserIndustry,
  updateIndividualUserIndustryErrorMessage: state.individualUser.updateIndividualUserIndustryErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    getPersonalAppointments: (data) => dispatch(getGeneralAppointmentsRequest(data)),
    getIndividualUserIndustryById: (data) => dispatch(getIndividualUserIndustryByIdRequest(data)),
    deleteIndividualUserIndustry: (data) => dispatch(deleteIndividualUserIndustryRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IndividualUserIndustryDetails);
