import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AppBar,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  AccordionSummary,
  Box,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import io from 'socket.io-client';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import {
  Pagination,
  LeftContent,
  ChatContent,
  SubmitMessage,
  TopContent,
} from '../../../../../Components/Messages';
import messagesSocketModule from '../../../../../Modules/messagesSocket';
import {
  getMessagesRequest,
  makeMessagesReadRequest,
  storeMessageRequest,
  updateMessageRequest,
  deleteMessageRequest,
} from '../../../../../redux/message/actions';
import '../../../../../style/messages.scss';
import MessagesLoading from '../../../../../Components/Loading/messagesLoading';
import Index from '../../../../../Components/Messages/MissingMessages';
import RightContent from '../../../../../Components/Messages/RightContent/rightContent';
import DeleteButton from '../../../../../MaterialComponents/deleteButton';
import SnackbarToast from '../../../../../Modules/SnackbarToast';
import i18n from '../../../../../i18n/i18n';

class Messages extends Component {
  lastDeleteMessageId = null;

  static propTypes = {
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    // User Account
    userAccount: PropTypes.object.isRequired,
    // Get Index
    getMessages: PropTypes.func.isRequired,
    isGetMessagesSuccess: PropTypes.bool.isRequired,
    isGetMessagesError: PropTypes.bool.isRequired,
    appointments: PropTypes.array.isRequired,
    allCount: PropTypes.number.isRequired,
    getMessagesErrorMessage: PropTypes.string.isRequired,
    // Store Message
    storeMessage: PropTypes.func.isRequired,
    isStoreMessageSuccess: PropTypes.bool.isRequired,
    isStoreMessageError: PropTypes.bool.isRequired,
    storedMessage: PropTypes.object.isRequired,
    storeMessageErrorMessage: PropTypes.string.isRequired,
    // Make Index Read
    makeMessagesRead: PropTypes.func.isRequired,
    isMakeMessagesReadSuccess: PropTypes.bool.isRequired,
    isMakeMessagesReadError: PropTypes.bool.isRequired,
    messagesReadAppointmentId: PropTypes.number.isRequired,
    // Update Message Props
    updateMessage: PropTypes.func.isRequired,
    isUpdateMessageSuccess: PropTypes.bool.isRequired,
    isUpdateMessageError: PropTypes.bool.isRequired,
    updatedMessage: PropTypes.object.isRequired,
    updateMessageErrorMessage: PropTypes.string.isRequired,
    // Delete Message Props
    deleteMessage: PropTypes.func.isRequired,
    isDeleteMessageSuccess: PropTypes.bool.isRequired,
    isDeleteMessageError: PropTypes.bool.isRequired,
    deleteMessageErrorMessage: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      openSnackbar: false,
      snackbarType: '',
      snackbarMessage: '',
      loading: false,
      appointments: [],
      limit: 10,
      page: 0,
      pages: 0,
      selectedAppointment: {
        service: {},
        company: {
          industry: {},
        },
        staff: {},
        messages: [],
      },
      newMessage: {
        from_id: '',
        to_id: '',
        text: '',
        appointment_id: '',
      },
      alert: null,
      expanded: false,
      emptySendMessage: true,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    const { limit, page } = this.state;
    const { getMessages } = this.props;
    getMessages({
      limit,
      page,
    });
    this.socket = io(process.env.REACT_APP_NODEJS_API_URL);
    // Listen For New Message Sent
    this.socket.on('receiveMessage', (message) => {
      const { appointments, selectedAppointment } = this.state;
      const data = messagesSocketModule.receiveMessage({
        appointments,
        selectedAppointment,
        message,
      });
      this.setState({
        selectedAppointment: data.selectedAppointment,
        appointments: data.appointments,
      });
      this.scrollChatContent();
    });
    // Listen For Message Update
    this.socket.on('messageUpdated', (message) => {
      const { selectedAppointment, appointments } = this.state;
      const data = messagesSocketModule.updateMessage({
        appointments,
        selectedAppointment,
        message,
      });
      this.setState({
        appointments: data.appointments,
        selectedAppointment: data.selectedAppointment,
      });
    });
    // Listen For Message Delete
    this.socket.on('messageDeleted', ({ messageId, appointmentId }) => {
      const { appointments, selectedAppointment } = this.state;
      const data = messagesSocketModule.deleteMessage({
        appointments,
        selectedAppointment,
        messageId,
        appointmentId,
      });
      this.setState({
        appointments: data.appointments,
        selectedAppointment: data.selectedAppointment,
      });
    });
  }

  componentDidUpdate(prevProps) {
    const {
      isGetMessagesSuccess,
      isGetMessagesError,
      appointments,
      allCount, getMessagesErrorMessage,
      isStoreMessageSuccess,
      isStoreMessageError,
      storedMessage,
      storeMessageErrorMessage,
      isMakeMessagesReadSuccess,
      isMakeMessagesReadError,
      messagesReadAppointmentId,
      isUpdateMessageSuccess,
      isUpdateMessageError,
      updatedMessage,
      updateMessageErrorMessage,
      isDeleteMessageSuccess,
      isDeleteMessageError,
      deleteMessageErrorMessage,
    } = this.props;
    // Handle Get Appointment Index Success
    if (!prevProps.isGetMessagesSuccess && isGetMessagesSuccess) {
      const { limit } = this.state;
      const { history, location } = this.props;
      const pages = Math.ceil(allCount / limit);
      const selectedAppointment = appointments[0];
      this.setState({
        loading: false,
        appointments,
        pages,
        selectedAppointment,
      });
      appointments.map((item) => {
        this.socket.emit('join', {
          permalink: item.permalink,
        });
      });
      this.scrollChatContent();
      if (location && selectedAppointment) {
        const getAppointmentUrlId = new URLSearchParams(location.search).get('appointment-room');
        const filteredAppointment = appointments.filter((item) => item.id === parseInt(getAppointmentUrlId));
        this.setState({
          selectedAppointment: filteredAppointment[0] || appointments[0],
        });
        history.push(`/messages/?appointment-room=${getAppointmentUrlId !== null ? getAppointmentUrlId : selectedAppointment.id}`);
      }
    }
    // Handle Get Appointment Index Error
    if (!prevProps.isGetMessagesError && isGetMessagesError) {
      this.setState({
        loading: false,
        openSnackbar: true,
        snackbarMessage: getMessagesErrorMessage,
        snackbarType: 'error',
      });
    }
    // Handle Store Message Success
    if (!prevProps.isStoreMessageSuccess && isStoreMessageSuccess && storedMessage.id) {
      const { appointments } = this.state;
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === storedMessage.appointment_id);
      appointmentsCopy[appointmentIndex].messages = [
        ...appointmentsCopy[appointmentIndex].messages,
        storedMessage,
      ];
      this.setState({
        appointments: appointmentsCopy,
        selectedAppointment: appointmentsCopy[appointmentIndex],
        newMessage: {
          from_id: '',
          to_id: '',
          text: '',
          appointment_id: '',
        },
        emptySendMessage: true,
      });
      this.scrollChatContent();
      this.socket.emit('sendMessage', {
        message: storedMessage,
        permalink: appointmentsCopy[appointmentIndex].permalink,
      });
    }
    // Handle Store Message Error
    if (!prevProps.isStoreMessageError && isStoreMessageError) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: storeMessageErrorMessage,
        snackbarType: 'error',
      });
    }
    // Handle Make Index Read Success
    if (!prevProps.isMakeMessagesReadSuccess && isMakeMessagesReadSuccess && !isMakeMessagesReadError) {
      const { appointments } = this.state;
      const { userAccount } = this.props;
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === parseInt(messagesReadAppointmentId));
      if (appointmentIndex !== -1) {
        appointmentsCopy[appointmentIndex].messages = appointmentsCopy[appointmentIndex].messages.map((item) => {
          if (item.to_id === userAccount.id || item.to_id === appointmentsCopy[appointmentIndex].staff.id) {
            return {
              ...item,
              seen_at: {
                ...item.seen_at,
                owner: moment().format('Y-MM-DD HH:mm'),
              },
            };
          } else {
            return item;
          }
        });
        this.setState({
          appointments: appointmentsCopy,
        });
      }
    }
    // Handle Update Message Success
    if (!prevProps.isUpdateMessageSuccess && isUpdateMessageSuccess && updatedMessage.id) {
      const { appointments } = this.state;
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === updatedMessage.appointment_id);
      const messageIndex = appointmentsCopy[appointmentIndex].messages.findIndex((item) => item.id === updatedMessage.id);
      if (messageIndex !== -1) {
        appointmentsCopy[appointmentIndex].messages[messageIndex] = updatedMessage;
      }
      this.setState({
        appointments: appointmentsCopy,
        newMessage: {
          from_id: '',
          to_id: '',
          text: '',
          appointment_id: '',
        },
        selectedAppointment: appointmentsCopy[appointmentIndex],
      });
      this.socket.emit('updateMessage', {
        message: updatedMessage,
        permalink: appointmentsCopy[appointmentIndex].permalink,
      });
    }
    // Handle Update Message Error
    if (!prevProps.isUpdateMessageError && isUpdateMessageError) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: updateMessageErrorMessage,
        snackbarType: 'error',
      });
    }
    // Handle Delete Message Success
    if (!prevProps.isDeleteMessageSuccess && isDeleteMessageSuccess) {
      const {
        appointments,
        selectedAppointment,
        newMessage,
      } = this.state;
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === selectedAppointment.id);
      const messageIndex = appointmentsCopy[appointmentIndex].messages.findIndex((item) => item.id === this.lastDeleteMessageId);
      if (messageIndex !== -1) {
        appointmentsCopy[appointmentIndex].messages.splice(messageIndex, 1);
      }
      const defaultMessageObject = {
        from_id: '',
        to_id: '',
        text: '',
        appointment_id: '',
      };
      const messageItem = newMessage.id && newMessage.id === parseInt(this.lastDeleteMessageId) ? { ...defaultMessageObject } : { ...newMessage };
      this.setState({
        appointments: appointmentsCopy,
        selectedAppointment: appointmentsCopy[appointmentIndex],
        newMessage: messageItem,
      });
      this.socket.emit('deleteMessage', {
        permalink: appointmentsCopy[appointmentIndex].permalink,
        messageId: this.lastDeleteMessageId,
        appointmentId: appointmentsCopy[appointmentIndex].id,
      });
      this.lastDeleteMessageId = null;
    }
    // Handle Delete Message Error
    if (!prevProps.isDeleteMessageError && isDeleteMessageError) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: deleteMessageErrorMessage,
        snackbarType: 'error',
      });
      this.lastDeleteMessageId = null;
    }
  }

  componentWillUnmount() {
    this.socket.emit('leaveConnection');
    this.socket.off();
  }

  handlePageChanged = (page) => {
    this.setState({
      page,
      loading: true,
    });
    const { getMessages } = this.props;
    const { limit } = this.state;
    getMessages({
      limit,
      page,
    });
  };

  handleChooseAppointmentMessage = (id) => {
    const {
      appointments,
      selectedAppointment: { id: previousAppointmentId },
      newMessage,
    } = this.state;
    const { history } = this.props;
    const appointmentItem = appointments.find((item) => item.id === id);
    const appointmentsCopy = [...appointments];
    const prevAppointmentIndex = appointmentsCopy.findIndex((item) => item.id === parseInt(previousAppointmentId));
    appointmentsCopy[prevAppointmentIndex].newMessage = newMessage;
    const currentMessage = appointmentItem.newMessage || {
      from_id: '',
      to_id: '',
      text: '',
      appointment_id: '',
    };
    this.setState({
      appointments: appointmentsCopy,
      selectedAppointment: appointmentItem,
      newMessage: currentMessage,
    }, () => {
      this.scrollChatContent();
      this.makeAppointmentMessagesRead();
    });
    history.push(`/messages/?appointment-room=${appointmentItem.id}`);
  };

  handleMessageTextChange = (event) => {
    const { value } = event.target;
    this.setState((prevState) => ({
      newMessage: {
        ...prevState.newMessage,
        text: value,
      },
    }));

    if (value === '') {
      this.setState({
        emptySendMessage: true,
      });
    } else {
      this.setState({
        emptySendMessage: false,
      });
    }
  };

  handleSendMessage = () => {
    const {
      newMessage,
      selectedAppointment: { id, customer_id },
    } = this.state;
    const { userAccount, storeMessage } = this.props;
    if (newMessage.text && (newMessage.text.trim() !== '')) {
      storeMessage({
        ...newMessage,
        from_id: userAccount.id,
        to_id: customer_id,
        appointment_id: id,
      });
    } else {
      this.setState({
        emptySendMessage: true,
      });
    }
  };

  scrollChatContent = () => {
    setTimeout(() => {
      const chantContentObj = document.getElementById('messages-content');
      if (chantContentObj) {
        chantContentObj.scrollTop = chantContentObj.scrollHeight;
      }
    }, 10);
  };

  makeAppointmentMessagesRead = () => {
    const { selectedAppointment } = this.state;
    const { userAccount } = this.props;
    const { messages } = selectedAppointment;
    const roleName = userAccount.activeRole.name.toLowerCase();
    const atLeastOneUnread = messages.find((item) => item.seen_at[roleName] === 'null'
                                           && (item.to_id === userAccount.id
                                           || (item.to_id === selectedAppointment.staff.id && roleName === 'owner')));
    if (atLeastOneUnread && atLeastOneUnread.id) {
      const { makeMessagesRead } = this.props;
      makeMessagesRead({ id: selectedAppointment.id });
    }
  };

  handleEditMessage = (event, id) => {
    event.preventDefault();
    const { selectedAppointment } = this.state;
    const messageItem = selectedAppointment.messages.find((item) => item.id === id);
    this.setState({
      newMessage: messageItem,
    });
  };

  handleUpdateMessage = () => {
    const { newMessage } = this.state;
    const { updateMessage } = this.props;
    updateMessage(newMessage);
  };

  showDeleteMessagePopup = (event, id) => {
    const { t } = useTranslation();
    event.preventDefault();
    const alert = (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={() => ({})}
        onCancel={() => this.setState({ alert: null })}
      >
        <DialogTitle
          id="customized-dialog-title"
          onCancel={() => this.setState({ alert: null })}
        >
          <span className="alert-title">
            <span>{t('ConfirmDelete')}</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => this.setState({ alert: null })}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {t('ConfirmActionMassages.Cancel')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({ alert: null })}
            size="small"
          >
            {t('Cancel')}
          </Button>
          <DeleteButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => this.handleDeleteMessage(id)}
          >
            {t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
    );
    this.setState({ alert });
  };

  handleDeleteMessage = (id) => {
    this.lastDeleteMessageId = id;
    const { deleteMessage } = this.props;
    deleteMessage({ id });
    this.setState({ alert: null });
  };

  handleChange = (panel) => (event, isExpanded) => {
    isExpanded ? this.setState({ expanded: panel }) : this.setState({ expanded: false });
  };

  render() {
    const {
      loading,
      appointments,
      selectedAppointment,
      page,
      pages,
      newMessage,
      alert,
      expanded,
      emptySendMessage,
      openSnackbar,
      snackbarType,
      snackbarMessage,
    } = this.state;
    // const { t } = useTranslation();
    const { userAccount } = this.props;
    return (
      <>
        <div className="menu-item messages">
          <div className="general-content">
            <div className="panel">
              <div className="main-content">
                <div>
                  <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
                    <Toolbar>
                      <Typography variant="h6">
                        <Box display="flex" alignItems="center" height="100%">
                          Messages
                        </Box>
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  {!loading ? (
                    appointments.length > 0 && selectedAppointment && selectedAppointment.id ? (
                      <Box className="main-chat-content accordion">
                        <Accordion expanded={expanded === 'panel1'} onChange={this.handleChange('panel1')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                          >
                            <Typography>Users</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="left-content">
                              <LeftContent
                                appointments={appointments}
                                selectedAppointment={selectedAppointment}
                                handleChooseAppointmentMessage={this.handleChooseAppointmentMessage}
                                userAccount={userAccount}
                              />
                              {appointments.length > 10 && (
                                <Pagination page={page} pages={pages} handlePageChanged={this.handlePageChanged} />
                              )}
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={this.handleChange('panel2')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                          >
                            <Typography>Chats</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="content-chat">
                              <TopContent
                                selectedAppointment={selectedAppointment}
                                userRole="owner"
                              />
                              <div className="chat" onClick={() => this.makeAppointmentMessagesRead()}>
                                <ChatContent
                                  selectedAppointment={selectedAppointment}
                                  userAccount={userAccount}
                                  handleEditMessage={(event, id) => this.handleEditMessage(event, id)}
                                  showDeleteMessagePopup={(event, id) => this.showDeleteMessagePopup(event, id)}
                                />
                                <SubmitMessage
                                  newMessage={newMessage}
                                  handleSendMessage={this.handleSendMessage}
                                  handleUpdateMessage={this.handleUpdateMessage}
                                  handleMessageTextChange={this.handleMessageTextChange}
                                  onClick={() => this.makeAppointmentMessagesRead()}
                                  emptySendMessage={emptySendMessage}
                                />
                              </div>
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} className="accordion-last-item" onChange={this.handleChange('panel3')}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3bh-content"
                          >
                            <Typography>{i18n.t('Details')}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <RightContent selectedAppointment={selectedAppointment} />
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                    ) : (
                      <Index />
                    )
                  ) : (
                    <MessagesLoading />
                  )}
                </div>
              </div>
            </div>
          </div>
          { alert }
        </div>
        <SnackbarToast
          message={snackbarMessage}
          type={snackbarType}
          open={openSnackbar}
          onClose={() => this.setState({ openSnackbar: false })}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  // User Account
  userAccount: state.account.userAccount,
  // Get Index
  isGetMessagesSuccess: state.message.isGetMessagesSuccess,
  isGetMessagesError: state.message.isGetMessagesError,
  appointments: state.message.appointments,
  allCount: state.message.allCount,
  getMessagesErrorMessage: state.message.getMessagesErrorMessage,
  // Store Message
  isStoreMessageSuccess: state.message.isStoreMessageSuccess,
  isStoreMessageError: state.message.isStoreMessageError,
  storedMessage: state.message.storedMessage,
  storeMessageErrorMessage: state.message.storeMessageErrorMessage,
  // Make Index Read
  isMakeMessagesReadSuccess: state.message.isMakeMessagesReadSuccess,
  isMakeMessagesReadError: state.message.isMakeMessagesReadError,
  messagesReadAppointmentId: state.message.messagesReadAppointmentId,
  // Update Message
  isUpdateMessageSuccess: state.message.isUpdateMessageSuccess,
  isUpdateMessageError: state.message.isUpdateMessageError,
  updatedMessage: state.message.updatedMessage,
  updateMessageErrorMessage: state.message.updateMessageErrorMessage,
  // Delete Message
  isDeleteMessageSuccess: state.message.isDeleteMessageSuccess,
  isDeleteMessageError: state.message.isDeleteMessageError,
  deleteMessageErrorMessage: state.message.deleteMessageErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    getMessages: (data) => dispatch(getMessagesRequest(data)),
    storeMessage: (data) => dispatch(storeMessageRequest(data)),
    makeMessagesRead: (data) => dispatch(makeMessagesReadRequest(data)),
    updateMessage: (data) => dispatch(updateMessageRequest(data)),
    deleteMessage: (data) => dispatch(deleteMessageRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
