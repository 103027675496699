import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  CircularProgress,
  Checkbox,
  Box,
  Button,
  Typography,
  IconButton,
  FormControl,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { useDispatch } from 'react-redux';
import {
  cancelCompanyDayAppointmentsRequest,
  cancelIndividualUserIndustryDayAppointmentsRequest,
} from '../../../redux/appointment/actions';

function HaveAppointmentsModal(props) {
  const dispatch = useDispatch();

  const {
    open,
    onClose,
    weekDay,
    from,
    cancelData,
    buttonLoading,
    setButtonLoading,
  } = props;

  const [isCancel, setIsCancel] = useState(false);

  const handleChangeValue = (e) => {
    const { checked } = e.target;
    setIsCancel(checked);
  };

  const handleCancelAppointments = () => {
    if (isCancel) {
      if (from === 'company') {
        dispatch(cancelCompanyDayAppointmentsRequest(cancelData));
      } else if (from === 'individual') {
        dispatch(cancelIndividualUserIndustryDayAppointmentsRequest(cancelData));
      }
      setButtonLoading(false);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title" maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span className="alert-title">
            <span>Change time</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Box>
              <Box mb={3} display="flex" alignItems="center" justifyContent="center">
                <Box width={75}>
                  <ReportProblemOutlinedIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography>
                      You have upcoming appointments on {weekDay}s : Reschedule the appointments or Create Specific days to keep the appointments, and close {weekDay}s.
                  </Typography>
                </Box>
              </Box>
              <Box align="center">
                <FormControl component="fieldset">
                  <FormControlLabel
                    fullWidth
                    size="small"
                    control={(
                      <Checkbox
                        checked={isCancel}
                        onChange={handleChangeValue}
                        name="isCancel"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        className="checkbox"
                        color="primary"
                      />
                    )}
                    label={`Cancel all upcoming appointments on ${weekDay}s`}
                  />
                </FormControl>
              </Box>
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="primary"
            onClick={onClose}
          >
            Got It
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleCancelAppointments}
            disabled={!isCancel}
            loading={buttonLoading}
          >
            {!buttonLoading && <CircularProgress color="white" size={20} />}
            {buttonLoading && 'Cancel Appointments'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

HaveAppointmentsModal.propTypes = {
  // Parent Props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  weekDay: PropTypes.string.isRequired,
  from: PropTypes.string.isRequired,
  cancelData: PropTypes.object.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  setButtonLoading: PropTypes.func.isRequired,
};

export default HaveAppointmentsModal;
