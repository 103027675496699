const liveChat = () => {
  window.$crisp = [];
  window.CRISP_WEBSITE_ID = '0aa35924-d851-43b5-8afb-e3d87617089a';

  (function crisp() {
    const d = document;
    const s = d.createElement('script');

    s.src = 'https://client.crisp.chat/l.js';
    s.async = 1;
    d.getElementsByTagName('head')[0].appendChild(s);
  }());
};

export default liveChat;
