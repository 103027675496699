import React, { useEffect, useState } from 'react';
import './notVerifiedEmail.scss';
import { Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AppBar,
  Typography,
  Grid,
  Container,
  Box,
  Toolbar,
} from '@material-ui/core';
import Logo from 'assets/img/logo.svg';
import CheckEmail from 'assets/img/check-email.svg';
import { sendVerificationLinkRequest } from 'redux/account/actions';
import SnackbarToast from 'Modules/SnackbarToast';

function notVerifiedEmail(props) {
  const {
    isSendVerificationLinkSuccess,
    isSendVerificationLinkError,
    sendVerificationLinkErrorMessage,
    verificationLinkSentMessage,
    sendVerificationLink,
    history,
  } = props;

  const location = useLocation();

  const [email, setEmail] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (location.state && location.state.email) {
      setEmail(location.state.email);
    } else {
      history.push('/login');
    }
  }, []);

  // Check if resend verification link success
  useEffect(() => {
    if (isSendVerificationLinkSuccess) {
      const location = {
        pathname: '/email/not-verified',
        state: { email },
      };
      history.push(location);
      snackBarAlert(true, verificationLinkSentMessage, 'success');
    }
  }, [isSendVerificationLinkSuccess]);

  // Check if resend verification link error
  useEffect(() => {
    if (isSendVerificationLinkError) {
      snackBarAlert(true, sendVerificationLinkErrorMessage, 'error');
    }
  }, [isSendVerificationLinkError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const resendVerificationLink = () => (
    sendVerificationLink({ email })
  );

  return (
    <>
      <div className="auth">
        <Container>
          <AppBar color="transparent" position="static" elevation={0} className="login-header">
            <Toolbar>
              <Box mx="auto" className="auth-logo">
                <Link to="/login">
                  <img src={Logo} alt="Logo" />
                </Link>
              </Box>
            </Toolbar>
          </AppBar>
          <Grid container justify="center">
            <Grid item direction="row" justify="center" sm={10}>
              <div>
                <Box mb="30px">
                  <Typography align="center" variant="h4">
                    Thank you !
                  </Typography>
                </Box>
                <Typography component="div" color="secondary">
                  <Box align="center" className="activate-account" fontSize="20px">
                    Please verify
                    <span className="verify-email"> { `${email} ` }</span>
                    to activate your account
                  </Box>
                </Typography>
                <Grid item xs={6} md={8} className="email-check">
                  <img src={CheckEmail} alt="check-email" />
                </Grid>
                <Typography component="div" color="secondary">
                  <Box align="center" color="secondary" className="resend-email" fontSize="20px">
                    If you haven&apos;t received it yet click to
                    <Link
                      to="#"
                      data-cy="resend-link"
                      onClick={resendVerificationLink}
                    >
                      Resend
                    </Link>
                    confirmation email
                  </Box>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

notVerifiedEmail.propTypes = {
  history: PropTypes.object.isRequired,
  sendVerificationLink: PropTypes.func.isRequired,
  isSendVerificationLinkSuccess: PropTypes.bool.isRequired,
  isSendVerificationLinkError: PropTypes.bool.isRequired,
  verificationLinkSentMessage: PropTypes.string.isRequired,
  sendVerificationLinkErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userEmail: state.account.userAccount.email,
  isSendVerificationLinkSuccess: state.account.isSendVerificationLinkSuccess,
  isSendVerificationLinkError: state.account.isSendVerificationLinkError,
  verificationLinkSentMessage: state.account.verificationLinkSentMessage,
  sendVerificationLinkErrorMessage: state.account.sendVerificationLinkErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    sendVerificationLink: (data) => dispatch(sendVerificationLinkRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(notVerifiedEmail);
