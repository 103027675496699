import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Box,
  Grid,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import i18n from 'i18next';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import {
  getCompanyByIdRequest,
  storeGalleryCompanyRequest,
  deleteGalleryCompanyRequest,
  getPreSignUrlRequest, uploadImageToAmazonRequest,
} from '../../../../../../../redux/company/actions';
import GalleryLoading from '../../../../../../../Components/Loading/galleryLoading';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';
import DeleteGalleryImage from '../../../../../../../Modals/Owner/DeleteGalleryImage';

function Gallery(props) {
  const {
    companyGot,
    companyItem,
    getCompanyById,
    getPreSignUrl,
    storeGalleryCompany,
    deleteGalleryCompany,
    storedGalleryCompany,
    isGetCompanyByIdSuccess,
    isStoreGalleryCompanyError,
    isDeleteGalleryCompanyError,
    isStoreGalleryCompanySuccess,
    isDeleteGalleryCompanySuccess,
    isGetPreSignedUrlSuccess,
    // isGetPreSignedUrlError,
    preSignUrl,
    isUploadImageToAmazonSuccess,
    // isUploadImageToAmazonError,
    uploadImageToAmazon,
  } = props;

  const [images, setImages] = useState([]);
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteGalleryLoading, setDeleteGalleryLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openDeleteImageModal, setOpenDeleteImageModal] = React.useState(false);
  const [selectedImageId, setSelectedImageId] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [logoName, setLogoName] = useState('');

  const prevIsGetCompanyByIdSuccess = usePrevious(isGetCompanyByIdSuccess);
  const prevIsStoreGalleryCompanySuccess = usePrevious(isStoreGalleryCompanySuccess);
  const prevIsStoreGalleryCompanyError = usePrevious(isStoreGalleryCompanyError);
  const prevIsDeleteGalleryCompanySuccess = usePrevious(isDeleteGalleryCompanySuccess);
  const prevIsDeleteGalleryCompanyError = usePrevious(isDeleteGalleryCompanyError);
  const prevIsGetPreSignedUrlSuccess = usePrevious(isGetPreSignedUrlSuccess);
  // const prevIsGetPreSignedUrlError = usePrevious(isGetPreSignedUrlError);
  const prevIsUploadImageToAmazonSuccess = usePrevious(isUploadImageToAmazonSuccess);
  // const prevIsUploadImageToAmazonError = usePrevious(isUploadImageToAmazonError);

  useEffect(() => {
    getCompanyById(companyItem.id);
  }, []);

  // Handle Get Company By Id Success
  useEffect(() => {
    if (prevIsGetCompanyByIdSuccess === false && isGetCompanyByIdSuccess) {
      setLoading(true);
      setImages(companyGot.images);
    }
  }, [isGetCompanyByIdSuccess]);
  // Get PreSigned Url
  useEffect(() => {
    if (prevIsGetPreSignedUrlSuccess === false && isGetPreSignedUrlSuccess) {
      uploadImageToAmazon({ preSignUrl, logo: selectedImage[0] });
    }
  }, [isGetPreSignedUrlSuccess]);

  useEffect(() => {
    if (prevIsUploadImageToAmazonSuccess === false && isUploadImageToAmazonSuccess) {
      const imagesCopy = (selectedImage.map((file) => Object.assign(file, {
        image: URL.createObjectURL(file),
      })));
      const filteredImage = imagesCopy.filter((img) => !img.id);

      if (filteredImage.length) {
        storeGalleryCompany({
          images: logoName,
        });
      }
    }
  }, [isUploadImageToAmazonSuccess]);

  // Perform when gallery images is added
  useEffect(() => {
    if (prevIsStoreGalleryCompanySuccess === false && isStoreGalleryCompanySuccess) {
      setCompany({
        ...company,
        images: images.concat(storedGalleryCompany),
      });
      setImages(images.concat(storedGalleryCompany));
      snackBarAlert(true, storedGalleryCompany.length > 1 ? 'Images are added' : 'Image successfully uploaded', 'success');
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isStoreGalleryCompanySuccess]);

  // Perform when images is not added
  useEffect(() => {
    if (prevIsStoreGalleryCompanyError === false && isStoreGalleryCompanyError) {
      snackBarAlert(true, 'Image size is too large', 'error');
      setLoading(true);
    }
  }, [isStoreGalleryCompanyError]);

  // Perform when images is deleted
  useEffect(() => {
    if (prevIsDeleteGalleryCompanySuccess === false && isDeleteGalleryCompanySuccess) {
      snackBarAlert(true, i18n.t('ImageDeleted'), 'success');
    }
  }, [isDeleteGalleryCompanySuccess]);

  // Perform when images is not added
  useEffect(() => {
    if (prevIsDeleteGalleryCompanyError === false && isDeleteGalleryCompanyError) {
      snackBarAlert(true, 'Images is not added', 'error');
    }
  }, [isDeleteGalleryCompanyError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const removeCompanyImage = () => {
    const filteredImages = images.filter((file) => file.id !== selectedImageId);
    setImages(filteredImages);
    setCompany({
      ...company,
      images: filteredImages,
    });
    setDeleteGalleryLoading(true);
    setDeleteGalleryLoading(true);
    setOpenDeleteImageModal(false);
    deleteGalleryCompany({
      id: selectedImageId,
    });
  };

  const handleClickOpen = (e, id) => {
    setSelectedImageId(id);
    setDeleteGalleryLoading(false);
    setOpenDeleteImageModal(true);
  };
  const handleClose = () => {
    setOpenDeleteImageModal(false);
  };

  const { getRootProps: getRootPropsCompany, getInputProps: getInputPropsCompany } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setSelectedImage(acceptedFiles);
      const logo = moment().format('DDMMYYYYHHmmss') + acceptedFiles[0].name;
      setLogoName(logo);
      const { id } = companyGot;
      getPreSignUrl({ id, logo, folder: 'companyImage' });
    },
  });

  const thumbsCompany = images.map((file, index) => (
    <div className="thumbGallery" key={`${index}-${file.size}`}>
      <div className="thumbInner">
        <img
          name={file.size}
          src={file.image || file.path}
          className="img"
          alt=""
        />
        <div className="remove-industry-avatar">
          <DeleteIcon
            className="remove-icon"
            onClick={(e) => handleClickOpen(e, file.image || file.id)}
          />
        </div>
      </div>
    </div>
  ));

  return (
    <>
      {loading ? (
        <>
          <Grid className="company-gallery">
            <Grid item sm={12}>
              <Box className="add-gallery-content" id="dropzone">
                <section className="gallery">
                  <div className="aside">
                    <div {...getRootPropsCompany({ className: 'dropzone' })}>
                      <input {...getInputPropsCompany()} />
                    </div>
                    {thumbsCompany}
                  </div>
                </section>
              </Box>
            </Grid>
          </Grid>
          <SnackbarToast
            message={snackbarMessage}
            type={snackbarType}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
          />
          <DeleteGalleryImage
            handleClose={handleClose}
            openDeleteImageModal={openDeleteImageModal}
            removeCompanyImage={removeCompanyImage}
            deleteGalleryLoading={deleteGalleryLoading}
          />
        </>
      ) : <GalleryLoading />}
    </>
  );
}

Gallery.propTypes = {
  companyItem: PropTypes.object.isRequired,
  // Get Company By Id Props
  getCompanyById: PropTypes.func.isRequired,
  isGetCompanyByIdSuccess: PropTypes.bool.isRequired,
  isGetCompanyByIdError: PropTypes.bool.isRequired,
  companyGot: PropTypes.object.isRequired,
  getCompanyByIdErrorMessage: PropTypes.string.isRequired,
  // Gallery props
  storeGalleryCompany: PropTypes.func.isRequired,
  storedGalleryCompany: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isStoreGalleryCompanySuccess: PropTypes.bool.isRequired,
  isStoreGalleryCompanyError: PropTypes.bool.isRequired,
  // Delete gallery image props
  deleteGalleryCompany: PropTypes.func.isRequired,
  deletedGalleryCompany: PropTypes.object.isRequired,
  isDeleteGalleryCompanySuccess: PropTypes.bool.isRequired,
  isDeleteGalleryCompanyError: PropTypes.bool.isRequired,
  // Amazon AWS Get Pre_Sign Url
  getPreSignUrl: PropTypes.func.isRequired,
  // isGetPreSignedUrlError: PropTypes.bool.isRequired,
  isGetPreSignedUrlSuccess: PropTypes.bool.isRequired,
  preSignUrl: PropTypes.string.isRequired,
  // Upload Image to Amazon
  uploadImageToAmazon: PropTypes.func.isRequired,
  isUploadImageToAmazonSuccess: PropTypes.bool.isRequired,
  // isUploadImageToAmazonError: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  // Get Company By Id
  isGetCompanyByIdSuccess: state.company.isGetCompanyByIdSuccess,
  isGetCompanyByIdError: state.company.isGetCompanyByIdError,
  companyGot: state.company.companyGot,
  getCompanyByIdErrorMessage: state.company.getCompanyByIdErrorMessage,
  // Get Gallery
  storeGalleryCompany: state.company.storeGalleryCompany,
  storedGalleryCompany: state.company.storedGalleryCompany,
  isStoreGalleryCompanySuccess: state.company.isStoreGalleryCompanySuccess,
  isStoreGalleryCompanyError: state.company.isStoreGalleryCompanyError,
  // Delete gallery image
  deleteGalleryCompany: state.company.deleteGalleryCompany,
  deletedGalleryCompany: state.company.deletedGalleryCompany,
  isDeleteGalleryCompanySuccess: state.company.isDeleteGalleryCompanySuccess,
  isDeleteGalleryCompanyError: state.company.isDeleteGalleryCompanyError,
  // Amazon AWs
  // getPreSignUrl: state.company.getPreSignUrl,
  isGetPreSignedUrlSuccess: state.company.isGetPreSignedUrlSuccess,
  // isGetPreSignedUrlError: state.company.isGetPreSignedUrlError,
  preSignUrl: state.company.preSignUrl,
  uploadImageToAmazon: state.company.uploadImageToAmazon,
  isUploadImageToAmazonSuccess: state.company.isUploadImageToAmazonSuccess,
  // isUploadImageToAmazonError: state.company.isUploadImageToAmazonError,
});

function mapDispatchToProps(dispatch) {
  return {
    getCompanyById: (data) => dispatch(getCompanyByIdRequest(data)),
    storeGalleryCompany: (data) => dispatch(storeGalleryCompanyRequest(data)),
    deleteGalleryCompany: (data) => dispatch(deleteGalleryCompanyRequest(data)),
    getPreSignUrl: (data) => dispatch(getPreSignUrlRequest(data)),
    uploadImageToAmazon: (data) => dispatch(uploadImageToAmazonRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
