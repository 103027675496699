import React, { useEffect, useRef, useState } from 'react';
import {
  AppBar,
  InputLabel,
  Select,
  TextField,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Toolbar, Typography,
} from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ErrorIcon from '@material-ui/icons/Error';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  addServiceRequest,
  getServiceByIdRequest,
} from '../../../../../redux/service/actions';
import usePrevious from '../../../../../CustomHooks/usePrevious';
import InfoLoading from '../../../../../Components/Loading/infoLoading';
import hours from '../../../../../Modules/hour';
import minutes from '../../../../../Modules/minutes';
import SnackbarToast from '../../../../../Modules/SnackbarToast';
import servicesLogo from '../../../../../assets/servicesLogo';
import AutocompleteSelectStaff from '../../../../../Modules/AutocompleteSelectStaff';
import { getCompanyStaffRequest } from '../../../../../redux/staff/actions';

function AddServices(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { match } = props;
  const {
    getServiceByIdErrorMessage,
    isGetServiceByIdSuccess,
    addServiceErrorMessage,
    isGetServiceByIdError,
    isAddServiceSuccess,
    isAddServiceError,
    serviceById,
  } = useSelector((state) => state.service);
  const {
    isGetCompanyStaffSuccess,
    companyStaff,
  } = useSelector((state) => state.staff);

  // Get some props previous value
  const prevIsGetCompanyStaffSuccess = usePrevious(isGetCompanyStaffSuccess);
  const prevIsGetServiceByIdSuccess = usePrevious(isGetServiceByIdSuccess);
  const prevIsGetServiceByIdError = usePrevious(isGetServiceByIdError);
  const prevIsAddServiceSuccess = usePrevious(isAddServiceSuccess);
  const prevIsAddServiceError = usePrevious(isAddServiceError);

  const [serviceData, setServiceData] = useState({
    service_id: '',
    serviceOption: {},
    duration: 0,
    price: '',
    staffs: [],
    selectedStaffOptions: [],
    industry_id: '',
    parent_id: '',
    name: '',
  });

  const history = useHistory();
  const [services, setServices] = useState([]);
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [service, setService] = useState({});
  const [staffsOptions, setStaffsOptions] = useState([]);
  const [subServicesOptions, setSubServicesOptions] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const useStyles = makeStyles(() => ({
    menuPaper: {
      maxHeight: 300,
    },
  }));

  useEffect(() => {
    dispatch(getCompanyStaffRequest({ forService: true }));
  }, []);

  // Handle Get Company Staff
  useEffect(() => {
    if (prevIsGetCompanyStaffSuccess === false && isGetCompanyStaffSuccess) {
      const { id } = match.params;
      const staffOptions = [];
      companyStaff.map((staff) => {
        staffOptions.push({
          id: staff.id,
          avatar: staff.avatar,
          label: staff.full_name,
          value: staff.id,
          professions: staff.staff_company_professions.length && staff.staff_company_professions[0].name,
        });
      });

      setStaffsOptions(staffOptions);

      if (id) {
        dispatch(getServiceByIdRequest({ id }));
      }
    }
  }, [isGetCompanyStaffSuccess]);

  // Handle Get Service By Id Success
  useEffect(() => {
    if (prevIsGetServiceByIdSuccess === false && isGetServiceByIdSuccess) {
      setLoading(false);
      setService(serviceById);
      const serviceOptions = [];
      const serviceDataCopy = { ...serviceData };
      serviceDataCopy.parent_id = serviceById.id;
      setServices([serviceDataCopy]);

      serviceById.subServices.map((service) => {
        serviceOptions.push({
          id: service.id,
          logo: service.logo,
          value: service.id,
          label: service.name,
        });
      });
      setSubServicesOptions(serviceOptions);
    }
  }, [isGetServiceByIdSuccess]);

  // Handle Get Service By Id Error
  useEffect(() => {
    if (prevIsGetServiceByIdError === false && isGetServiceByIdError) {
      snackBarAlert(true, getServiceByIdErrorMessage, 'error');
    }
  }, [isGetServiceByIdError]);

  // Handle Add Service Success
  useEffect(() => {
    if (prevIsAddServiceSuccess === false && isAddServiceSuccess) {
      history.push('/service');
    }
  }, [isAddServiceSuccess]);

  // Handle Add Service Error
  useEffect(() => {
    if (prevIsAddServiceError === false && isAddServiceError) {
      snackBarAlert(true, addServiceErrorMessage, 'error');
    }
  }, [isAddServiceError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleServiceSelectChange = (e, selectedService, index) => {
    const servicesCopy = [...services];
    if (selectedService) {
      if (typeof selectedService === 'string') {
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          servicesCopy[index]['service_id'] = selectedService;
        });
      } else if (selectedService && selectedService.inputValue) {
        servicesCopy[index]['name'] = selectedService.inputValue;
        servicesCopy[index]['service_id'] = null;
        servicesCopy[index]['serviceOption'] = selectedService.inputValue;
      } else {
        servicesCopy[index]['service_id'] = selectedService.id;
        servicesCopy[index]['serviceOption'] = selectedService;
      }
    } else {
      servicesCopy[index]['service_id'] = '';
      servicesCopy[index]['name'] = '';
      servicesCopy[index]['serviceOption'] = [];
    }
    setServices(servicesCopy);
  };

  const getStaffIDs = (selectedOptions) => {
    const staffIds = [];
    selectedOptions.map((option) => {
      staffIds.push(option.id);
    });

    return staffIds;
  };

  const handleToggleOption = (selectedOptions, index) => {
    const servicesCopy = [...services];
    servicesCopy[index]['selectedStaffOptions'] = selectedOptions;
    servicesCopy[index]['staffs'] = getStaffIDs(selectedOptions);
    setServices(servicesCopy);
  };

  const handleClearOptions = (index) => {
    const servicesCopy = [...services];
    servicesCopy[index]['selectedStaffOptions'] = [];
    servicesCopy[index]['staffs'] = [];
    setServices(servicesCopy);
  };

  const handleSelectAll = (isSelected, index) => {
    const servicesCopy = [...services];
    if (isSelected) {
      servicesCopy[index]['selectedStaffOptions'] = staffsOptions;
      servicesCopy[index]['staffs'] = getStaffIDs(staffsOptions);
    } else {
      handleClearOptions(index);
    }
    setServices(servicesCopy);
  };

  const getOptionLabel = (option) => `${option.label}`;

  const handlePriceChange = (e, index) => {
    const { name, value } = e.target;
    const maxLength = 10;
    const newValue = value < maxLength ? value : parseInt(value.toString().substring(0, maxLength));
    const servicesCopy = [...services];
    servicesCopy[index][name] = newValue;
    setServices(servicesCopy);
  };

  const handleHourOrMinChange = (e, index) => {
    const { name, value } = e.target;
    const servicesCopy = [...services];
    if (name === 'hour') {
      const min = servicesCopy[index]['duration'] % 60;
      servicesCopy[index]['duration'] = (parseInt(value) * 60) + min;
    } else {
      const hour = Math.floor(servicesCopy[index]['duration'] / 60);
      servicesCopy[index]['duration'] = (hour * 60) + parseInt(value);
    }
    setServices(servicesCopy);
  };

  const removeService = (index) => {
    const servicesCopy = [...services];
    const errorsCopy = [...errors];
    errorsCopy.splice(index, 1);
    servicesCopy.splice(index, 1);
    setServices(servicesCopy);
    setErrors(errorsCopy);
  };

  const validateForm = () => {
    const staffServicesCopy = [...services];
    const errorsServices = [];
    staffServicesCopy.map((service, index) => {
      const error = [];
      error['service_id'] = service.service_id === '' && service.name === '';
      error['price'] = service.price === '';
      error['duration'] = service.duration === 0;
      errorsServices[index] = error;
    });

    setErrors(errorsServices);
    const error = staffServicesCopy.filter((i) => (i.service_id === '' || i.price === '' || i.duration === 0));

    return error.length === 0;
  };

  const ref = useRef();

  const scrollToBottomOfList = React.useCallback(() => {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  }, [ref]);

  const handleAddServiceToStaff = () => {
    scrollToBottomOfList();
    const staffServicesCopy = [...services];
    staffServicesCopy.push({
      service_id: serviceData.service_id,
      price: serviceData.price,
      duration: serviceData.duration,
      staffs: serviceData.staffs,
      selectedStaffOptions: serviceData.selectedStaffOptions,
      serviceOption: serviceData.serviceOption,
      parent_id: service.id,
      name: '',
    });
    setServices(staffServicesCopy);
    setServiceData({
      service_id: '',
      serviceOption: {},
      price: '',
      duration: 0,
      staffs: [],
      selectedStaffOptions: [],
      parent_id: service.id,
      name: '',
    });
  };

  const saveService = () => {
    if (validateForm()) {
      dispatch(addServiceRequest(services));
    }
  };

  const classes = useStyles();
  const filter = createFilterOptions();

  return (
    <>
      <AppBar color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
            <Box display="flex" className="tabs-navbar">
              <Box alignSelf="center" ml="-18px" mr="6px">
                <Link to="/service">
                  <IconButton size="small">
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Link>
              </Box>
            </Box>
            <Box display="flex">
              <Box alignSelf="center" data-cy="filter">
                <div>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={saveService}
                  >
                    Save
                  </Button>
                </div>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {!loading ? (
        <div>
          <Box>
            <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
              <Toolbar>
                <Box display="flex" width="100%" alignItems="center">
                  <Box mr="10px">
                    <img src={servicesLogo[service.name]} alt="" width="50px" />
                  </Box>
                  <Typography variant="h6">
                    <Box display="flex" alignItems="center" height="100%">
                      { service.name }
                    </Box>
                  </Typography>
                </Box>
              </Toolbar>
            </AppBar>
            <Box>
              <Grid container direction="row" id="fixed-box-info" spacing={3} className="category-rows">
                {services.map((item, index) => (
                  <>
                    <Grid item xs={12} sm={3}>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        error={(errors[index] && errors[index]['service_id'])}
                      >
                        <Autocomplete
                          size="small"
                          fullWidth
                          value={item.serviceOption}
                          onChange={(e, value) => handleServiceSelectChange(e, value, index)}
                          filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            if (params.inputValue !== '') {
                              filtered.push({
                                inputValue: params.inputValue,
                                label: `Add "${params.inputValue}"`,
                              });
                            }

                            return filtered;
                          }}
                          options={subServicesOptions}
                          getOptionLabel={(option) => {
                            // e.g value selected with enter, right from the input
                            if (typeof option === 'string') {
                              return option;
                            }
                            if (option.inputValue) {
                              return option.inputValue;
                            }
                            return option.label;
                          }}
                          selectOnFocus
                          clearOnBlur
                          handleHomeEndKeys
                          renderOption={(option) => option.label}
                          freeSolo
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                                maxLength: 50,
                              }}

                              label={`${t('Service')} *`}
                              variant="outlined"
                              error={(errors[index] && errors[index]['service_id'])}
                            />
                          )}
                        />

                        { errors[index] && errors[index]['service_id'] && (
                          <div className="error-message-content">
                            <ErrorIcon fontSize="small" color="error" />
                            <FormHelperText>
                              {t('Service') + t('IsRequired')}
                            </FormHelperText>
                          </div>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <Box
                        display="flex"
                      >
                        <FormControl
                          fullWidth
                          size="small"
                          variant="outlined"
                          error={errors[index] && errors[index]['duration']}
                          className="duration"
                        >
                          <InputLabel error={!!errors.duration}>Hour *</InputLabel>
                          <Select
                            variant="outlined"
                            disableUnderline
                            data-cy="breaking-start-time"
                            onChange={(e) => handleHourOrMinChange(e, index)}
                            value={Math.floor(item.duration / 60)}
                            name="hour"
                            label={`${t('Hour')} *`}
                            MenuProps={{ classes: { paper: classes.menuPaper } }}
                          >
                            { hours.map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
                          </Select>
                        </FormControl>
                        <FormControl
                          fullWidth
                          size="small"
                          variant="outlined"
                          error={errors[index] && errors[index]['duration']}
                        >
                          <InputLabel error={!!errors.duration}>Min *</InputLabel>
                          <Select
                            variant="outlined"
                            disableUnderline
                            data-cy="breaking-start-time"
                            value={(item.duration % 60)}
                            onChange={(e) => handleHourOrMinChange(e, index)}
                            name="min"
                            label={`${t('min')} *`}
                            MenuProps={{ classes: { paper: classes.menuPaper } }}
                          >
                            { minutes.map((min) => <MenuItem key={Math.random()} value={min}>{min}</MenuItem>) }
                          </Select>
                        </FormControl>
                      </Box>

                      { errors[index] && errors[index]['duration'] && (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText error>
                            {t('Duration') + t('IsRequired')}
                          </FormHelperText>
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                        error={errors[index] && errors[index]['price']}
                      >
                        <TextField
                          data-cy="price"
                          size="small"
                          value={item.price}
                          onChange={(event) => handlePriceChange(event, index)}
                          type="number"
                          name="price"
                          label={`${t('Price')} *`}
                          error={errors[index] && errors[index]['price']}
                          placeholder={`${t('Price')} *`}
                          variant="outlined"
                          onKeyDown={(e) => e.key === '+' || e.key === '-' || e.key === 'e' ? e.preventDefault() : true}
                          inputProps={{
                            autoComplete: 'new-password',
                          }}
                        />

                        { errors[index] && errors[index]['price'] && (
                          <div className="error-message-content">
                            <ErrorIcon fontSize="small" color="error" />
                            <FormHelperText>
                              {t('Price') + t('IsRequired')}
                            </FormHelperText>
                          </div>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl
                        fullWidth
                        size="small"
                        variant="outlined"
                      >
                        <AutocompleteSelectStaff
                          items={staffsOptions}
                          getOptionLabel={getOptionLabel}
                          selectedValues={item.selectedStaffOptions}
                          label={`${t('Staff')} *`}
                          placeholder={`${t('Staff')} *`}
                          limitTags={2}
                          onToggleOption={(selectedOptions) => handleToggleOption(selectedOptions, index)}
                          onClearOptions={() => handleClearOptions(index)}
                          onSelectAll={(isSelected) => handleSelectAll(isSelected, index)}
                        />
                      </FormControl>
                    </Grid>
                    { services.length !== 1 && (
                    <Grid item xs={12} sm={1}>
                      <Box display="flex" alignItems="center" height="100%">
                        <FormControl
                          // fullWidth
                          size="small"
                          variant="outlined"
                          error={errors[index] && errors[index]}
                        >
                          <IconButton
                            aria-label="delete"
                            onClick={() => removeService(index)}
                            size="small"
                          >
                            <CloseIcon fontSize="small" color="secondary" />
                          </IconButton>
                          { errors[index] && errors[index] && (
                            <div className="error-message-content" />
                          )}
                        </FormControl>
                      </Box>
                    </Grid>
                    )}
                  </>
                ))}
                <div id="bottom-of-list" ref={ref} />
              </Grid>
              <Box px="17px">
                <IconButton color="primary" onClick={handleAddServiceToStaff}>
                  <AddIcon />
                </IconButton>
              </Box>
            </Box>

          </Box>
          <SnackbarToast
            message={snackbarMessage}
            type={snackbarType}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
          />
          {alert}
        </div>
      ) : <InfoLoading />}
    </>
  );
}

AddServices.propTypes = {
  // Parent props
  match: PropTypes.object.isRequired,
};

export default AddServices;
