import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  TextField,
  InputLabel,
  IconButton,
  MenuItem,
  FormControl,
  FormHelperText,
  Grid,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ErrorIcon from '@material-ui/icons/Error';
import i18n from 'i18next';
import countryCodeOptions from '../../../../../../Modules/countryCodeOptions';
import {
  getAllCompaniesRequest,
  inviteCustomerToCompanyRequest,
} from '../../../../../../redux/company/actions';
import Loading from '../../../../../../Components/Loading/loading';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import { updateInvitationRequest } from '../../../../../../redux/customer/actions';
import TextMaskCustom from '../../../../../../Modules/TextMaskCustom';
import SnackbarToast from '../../../../../../Modules/SnackbarToast';

function InviteCustomer(props) {
  const {
    setAnchorEl,
    isInviteCustomerModalShown,
    setIsInviteCustomerModalShown,
    companiesCustomerInvitations,
    setCompaniesCustomerInvitations,
    customerToInviteTest,
    getAllCompanies,
    isGetAllCompaniesSuccess,
    isGetAllCompaniesError,
    allCompanies,
    getAllCompaniesErrorMessage,
    allCountries,
    inviteCustomerToCompany,
    isInviteCustomerToCompanySuccess,
    isInviteCustomerToCompanyError,
    inviteCustomerToCompanySucceed,
    inviteCustomerToCompanyErrors,
    newInvitation,
    userAccount,
    isUpdateInvitationSuccess,
    isUpdateInvitationError,
    updateInvitation,
    updateInvitationErrorMessage,
    updateInvitationSucceed,
    updatedInvitation,
  } = props;

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [validationErrors, setValidationError] = useState({});
  const [companyOptions, setCompanyOptions] = useState([]);
  const [codeOptions, setCodeOptions] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [alert, setAlert] = useState(null);
  const [customerToInvite, setCustomerToInvite] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company: { value: null },
    phone_code: { value: null },
    phone_number: null,
    invitationEmailIsTheSame: false,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous values
  const prevIsInviteCustomerToCompanySuccess = usePrevious(isInviteCustomerToCompanySuccess);
  const prevIsInviteCustomerToCompanyError = usePrevious(isInviteCustomerToCompanyError);
  const prevIsUpdateInvitationError = usePrevious(isUpdateInvitationError);
  const prevIsUpdateInvitationSuccess = usePrevious(isUpdateInvitationSuccess);

  useEffect(() => {
    getAllCompanies();
    setCodeOptions(countryCodeOptions.getCodeOptions(allCountries));
  }, []);

  useEffect(() => {
    const keys = Object.keys(customerToInviteTest);
    const valueExists = keys.find((key) => customerToInviteTest[key]);
    if (valueExists) {
      setCustomerToInvite(customerToInviteTest);
    }
  }, [customerToInviteTest]);

  // Handle Get All Companies Success
  useEffect(() => {
    if (isGetAllCompaniesSuccess) {
      const { companies } = allCompanies;
      const companyOptions = [];
      companies.map((company) => companyOptions.push({
        value: company.id,
        label: company.name,
      }));
      setCompanyOptions(companyOptions);
      setCompanies([...companies]);
      setLoading(false);
    }
  }, [isGetAllCompaniesSuccess]);

  // Handle Get All Companies Error
  useEffect(() => {
    if (isGetAllCompaniesError) {
      setLoading(false);
      snackBarAlert(true, getAllCompaniesErrorMessage, 'error');
    }
  }, [isGetAllCompaniesError]);

  // Handle invite customer to company success
  useEffect(() => {
    if (prevIsInviteCustomerToCompanySuccess === false && isInviteCustomerToCompanySuccess) {
      const companyInvitationsCopy = [...companiesCustomerInvitations];
      if (inviteCustomerToCompanySucceed.message && inviteCustomerToCompanySucceed.message === 'existing_customer') {
        // User with invitation email already exists, ask user to confirm
        setIsInviteCustomerModalShown(false);
        setLoading(false);
        const { existingCustomer } = inviteCustomerToCompanySucceed;
        const alert = getExistingCustomerAlert(existingCustomer);
        setAlert(alert);
      }
      setCustomerToInvite(
        {
          first_name: '',
          last_name: '',
          email: null,
          company: { value: null },
          phone_code: { value: null },
          phone_number: null,
        },
      );
      snackBarAlert(true, inviteCustomerToCompanySucceed.message, 'success');
      setLoading(false);
      setIsInviteCustomerModalShown(false);
      if (Object.keys(newInvitation).length) {
        companyInvitationsCopy.push(newInvitation);
        setCompaniesCustomerInvitations(companyInvitationsCopy);
        setAlert(null);
      }
      setAnchorEl(null);
    }
  }, [isInviteCustomerToCompanySuccess]);

  // Handle invite customer to company error
  useEffect(() => {
    if (prevIsInviteCustomerToCompanyError === false && isInviteCustomerToCompanyError) {
      setErrors(inviteCustomerToCompanyErrors.errors);
      setLoading(false);
      snackBarAlert(true, inviteCustomerToCompanyErrors.message, 'error');
    }
  }, [isInviteCustomerToCompanyError]);

  const getExistingCustomerAlert = (existingCustomer, isGettingUpdated = false) => (
    <Dialog
      onClose={() => setIsInviteCustomerModalShown(false)}
      aria-labelledby="customized-dialog-title"
      open
    >
      <DialogTitle onClick={() => setAlert(null)}>
        <span className="alert-title">
          <span>Existing User</span>
          <span>
            <IconButton aria-label="close" className="close-btn" onClick={() => setAlert(null)}>
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>Please make sure this is the customer you want to invite:</Typography>
        <Typography gutterBottom>Name: <Link to="#" onClick={(e) => e.preventDefault()}>{existingCustomer.first_name} {existingCustomer.last_name}</Link></Typography>
        <Typography gutterBottom>Email: <Link to="#" onClick={(e) => e.preventDefault()}>{existingCustomer.email}</Link></Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => setAlert(null)}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={() => isGettingUpdated ? handleUpdateCustomerInvitation(true)
            : handleCustomerInvite(true)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Handle update customer invitation success
  useEffect(() => {
    if (prevIsUpdateInvitationSuccess === false && isUpdateInvitationSuccess) {
      if (
        updateInvitationSucceed
        && updateInvitationSucceed.message
        && updateInvitationSucceed.message === 'existing_customer'
      ) {
        setLoading(false);
        const { existingCustomer } = updateInvitationSucceed;
        const alert = getExistingCustomerAlert(existingCustomer, true);
        setAlert(alert);
      } else {
        const companyInvitationsCopy = [...companiesCustomerInvitations];
        const invitationIndex = companyInvitationsCopy.findIndex(
          (item) => item.id === updatedInvitation.id,
        );
        if (invitationIndex !== -1) {
          companyInvitationsCopy[invitationIndex] = updatedInvitation;
        } else {
          const oldInvitationIndex = companyInvitationsCopy.findIndex(
            (item) => item.id === updatedInvitation.id,
          );
          companyInvitationsCopy.splice(oldInvitationIndex, 1);
          companyInvitationsCopy.push(updatedInvitation);
        }
        setCompaniesCustomerInvitations(companyInvitationsCopy);
        setCustomerToInvite({
          first_name: '',
          last_name: '',
          email: '',
          company: { value: null },
          phone_code: { value: null },
          phone_number: null,
        });
        setIsInviteCustomerModalShown(false);
        setLoading(false);
        setAnchorEl(null);
        setAlert(null);
        snackBarAlert(true, i18n.t('InvitationUpdated'), 'success');
      }
    }
  }, [isUpdateInvitationSuccess]);

  // Handle update invitation error
  useEffect(() => {
    if (prevIsUpdateInvitationError === false && isUpdateInvitationError) {
      setLoading(false);
      snackBarAlert(true, updateInvitationErrorMessage, 'error');
    }
  }, [isUpdateInvitationError]);

  const handleInputChangeForCustomer = (event) => {
    const { value, name } = event.target;
    setCustomerToInvite({
      ...customerToInvite,
      [name]: value,
    });
  };

  const handleUpdateCustomerInvitation = (existingCustomer = false) => {
    const data = { ...customerToInvite };
    if (userAccount.email === data.email) {
      snackBarAlert(true, i18n.t('CantInviteYourSelf'), 'error');
      return false;
    }
    if (existingCustomer === true) {
      data.is_confirmed = true;
    }
    data.phone_number = customerToInvite.phone_number === '' ? null : customerToInvite.phone_number;
    data.phone_code = customerToInvite.phone_code.value;
    data.email = customerToInvite.email === '' ? null : customerToInvite.email;
    data.staff_id = userAccount.id;
    delete data.company;
    setErrors({});
    setValidationError({});
    if (validateInvitation(data)) {
      setLoading(true);
      updateInvitation(data);
    }
  };

  const handleCustomerInvite = (existingCustomer = false) => {
    const data = { ...customerToInvite };
    if (existingCustomer === true) {
      data.is_confirmed = true;
    }
    data.staff_id = userAccount.id;
    data.email = customerToInvite.email === '' ? null : customerToInvite.email;
    data.phone_code = customerToInvite.phone_code.value;
    data.phone_number = customerToInvite.phone_number === '' ? null : customerToInvite.phone_number;
    delete data.company;
    setErrors({});
    setValidationError({});
    if (validateInvitation(data)) {
      setLoading(true);
      inviteCustomerToCompany(data);
    }
  };

  const validateInvitation = (data) => {
    const {
      first_name,
      last_name,
      email,
      phone_code,
      phone_number,
    } = data;
    const errors = {
      firstName: first_name.length === 0 ? 'First name is required' : null,
      lastName: last_name.length === 0 ? 'Last name is required' : null,
      email: email === null ? 'Email is required' : null,
      phoneNumber: phone_number === null ? 'Phone number is required' : null,
      phoneCode: phone_code === null ? 'Phone code is required' : null,
    };
    setValidationError(errors);
    return Object.values(errors).filter((error) => (error !== null)).length === 0;
  };

  const handleSelectChangeForCustomer = (event) => {
    if (companies && companies.length > 0) {
      const companyId = parseInt(event.target.value);
      const company = companies.find((item) => item.id === companyId);
      setCustomerToInvite({
        ...customerToInvite,
        company: {
          label: company.name,
          value: companyId,
        },
      });
    }
  };

  const handleSelectChangeForCode = (event) => {
    const codeId = parseInt(event.target.value);
    const phone = codeOptions.find((item) => parseInt(item.value) === codeId);
    setCustomerToInvite({
      ...customerToInvite,
      phone_code: {
        key: phone.key,
        label: phone.label,
        value: phone.value,
      },
    });
  };

  const handleCloseModal = () => {
    setIsInviteCustomerModalShown(false);
    setCustomerToInvite(
      {
        first_name: '',
        last_name: '',
        email: null,
        company: { value: null },
        phone_code: { value: null },
        phone_number: null,
      },
    );
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  return (
    <>
      <div>
        <Dialog
          open={isInviteCustomerModalShown}
          onClose={() => setIsInviteCustomerModalShown(false)}
          aria-labelledby="customized-dialog-title"
        >
          <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <span>{customerToInvite.id ? 'Edit invitation' : 'Invite to Register'}</span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            { !loading ? (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={!!validationErrors.firstName}
                  >
                    <TextField
                      fullWidth
                      error={!!validationErrors.firstName}
                      size="small"
                      label="First name *"
                      data-cy="firstName"
                      data-customer="first_name"
                      variant="outlined"
                      type="text"
                      name="first_name"
                      value={customerToInvite.first_name}
                      onChange={handleInputChangeForCustomer}
                      inputProps={{
                        autoComplete: 'new-password',
                      }}
                    />

                    { validationErrors.firstName && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.firstName}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={!!validationErrors.lastName}
                  >
                    <TextField
                      fullWidth
                      error={!!validationErrors.lastName}
                      size="small"
                      label="Last name *"
                      data-cy="lastName"
                      data-customer="last_name"
                      variant="outlined"
                      type="text"
                      name="last_name"
                      value={customerToInvite.last_name}
                      onChange={handleInputChangeForCustomer}
                      inputProps={{
                        autoComplete: 'new-password',
                      }}
                    />

                    { validationErrors.lastName && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.lastName}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={!!validationErrors.lastName}
                  >
                    <TextField
                      fullWidth
                      error={!!validationErrors.lastName}
                      size="small"
                      data-cy="email"
                      label="Email address *"
                      variant="outlined"
                      type="text"
                      name="email"
                      value={customerToInvite.email || ''}
                      onChange={handleInputChangeForCustomer}
                      inputProps={{
                        autoComplete: 'new-password',
                      }}
                    />

                    { validationErrors.email && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.email}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} data-cy="company">
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={!!validationErrors.company}
                  >
                    <InputLabel>Company *</InputLabel>
                    <Select
                      name="company"
                      id="customerToCompany"
                      onChange={(e) => handleSelectChangeForCustomer(e, 'company')}
                      value={customerToInvite.company.value}
                      label="Company *"
                    >
                      <MenuItem aria-label="None" value="" />
                      {companyOptions && companyOptions.map((option, index) => (
                        <MenuItem
                          key={`${option.id}-${index}`}
                          value={option.value}
                          selected={customerToInvite.company.value === option.value}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>

                    { validationErrors.company && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.company}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={!!validationErrors.phoneCode}
                  >
                    <InputLabel>Code *</InputLabel>
                    <Select
                      name="phone_code"
                      label="Code *"
                      onChange={(e) => handleSelectChangeForCode(e, 'phone_code')}
                      value={customerToInvite.phone_code.value}
                    >
                      <MenuItem disabled value=""><em>{i18n.t('PhoneCode')}</em></MenuItem>
                      {codeOptions && codeOptions.map((option, index) => (
                        <MenuItem
                          key={`${option.id}-${index}`}
                          value={option.value}
                          selected={customerToInvite.phone_code.value === option.value}
                        >
                          <img
                            src={option.label.flagImageSrc}
                            alt="flag"
                            className="country-flag"
                          />
                          {option.key}
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>

                    { validationErrors.phoneCode && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.phoneCode}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={!!validationErrors.phoneNumber}
                  >
                    <TextField
                      fullWidth
                      error={!!validationErrors.phoneNumber}
                      size="small"
                      data-cy="phone"
                      id="phone-detail"
                      data-customer="phone_number"
                      type="text"
                      name="phone_number"
                      value={customerToInvite.phone_number || ''}
                      onChange={(e) => setCustomerToInvite({
                        ...customerToInvite,
                        phone_number: e.target.value,
                      })}
                      onFocus={() => {
                        setValidationError({ ...validationErrors, phone_number: '', phone: '' });
                        setErrors({ ...errors, phone_number: '' });
                      }}
                      label="Phone number *"
                      variant="outlined"
                      InputProps={{
                        inputComponent: TextMaskCustom,
                        autoComplete: 'new-password',
                      }}
                    />

                    { validationErrors.phoneNumber && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {validationErrors.phoneNumber}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <Loading />
            ) }
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              data-cy="cancel"
              type="button"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Button
              size="small"
              data-cy="send"
              type="button"
              onClick={customerToInvite.id ? handleUpdateCustomerInvitation : handleCustomerInvite}
              variant="contained"
              color="primary"
            >
              {customerToInvite.id ? 'Save' : 'Send'}
            </Button>
          </DialogActions>
        </Dialog>
        {alert}
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

InviteCustomer.propTypes = {
  setAnchorEl: PropTypes.bool,
  isInviteCustomerModalShown: PropTypes.bool.isRequired,
  setIsInviteCustomerModalShown: PropTypes.func.isRequired,
  customerToInviteTest: PropTypes.object.isRequired,
  companiesCustomerInvitations: PropTypes.array.isRequired,
  setCompaniesCustomerInvitations: PropTypes.func.isRequired,
  // Get countries
  allCountries: PropTypes.array.isRequired,
  // Get All Companies Props
  getAllCompanies: PropTypes.func.isRequired,
  isGetAllCompaniesSuccess: PropTypes.bool.isRequired,
  isGetAllCompaniesError: PropTypes.bool.isRequired,
  allCompanies: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  getAllCompaniesErrorMessage: PropTypes.string.isRequired,
  // Company customer invitation
  inviteCustomerToCompany: PropTypes.func.isRequired,
  isInviteCustomerToCompanySuccess: PropTypes.bool.isRequired,
  isInviteCustomerToCompanyError: PropTypes.bool.isRequired,
  inviteCustomerToCompanySucceed: PropTypes.object.isRequired,
  inviteCustomerToCompanyErrors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  newInvitation: PropTypes.object.isRequired,
  userAccount: PropTypes.object.isRequired,
  isUpdateInvitationSuccess: PropTypes.bool.isRequired,
  isUpdateInvitationError: PropTypes.bool.isRequired,
  updateInvitation: PropTypes.func.isRequired,
  updateInvitationErrorMessage: PropTypes.string.isRequired,
  updateInvitationSucceed: PropTypes.object.isRequired,
  updatedInvitation: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // User account
  userAccount: state.account.userAccount,
  // Get all countries
  allCountries: state.country.allCountries,
  // Get All Companies
  isGetAllCompaniesSuccess: state.company.isGetAllCompaniesSuccess,
  isGetAllCompaniesError: state.company.isGetAllCompaniesError,
  getAllCompaniesErrorMessage: state.company.getAllCompaniesErrorMessage,
  allCompanies: state.company.allCompanies,
  // Invite customer to company
  isInviteCustomerToCompanySuccess: state.company.isInviteCustomerToCompanySuccess,
  isInviteCustomerToCompanyError: state.company.isInviteCustomerToCompanyError,
  inviteCustomerToCompanySucceed: state.company.inviteCustomerToCompanySucceed,
  inviteCustomerToCompanyErrors: state.company.inviteCustomerToCompanyErrors,
  newInvitation: state.company.newInvitation,
  // Update customer invitation
  isUpdateInvitationSuccess: state.customers.isUpdateInvitationSuccess,
  isUpdateInvitationError: state.customers.isUpdateInvitationError,
  updatedInvitation: state.customers.updatedInvitation,
  updateInvitationErrorMessage: state.customers.updateInvitationErrorMessage,
  updateInvitationSucceed: state.customers.updateInvitationSucceed,
});

function mapDispatchToProps(dispatch) {
  return {
    getAllCompanies: () => dispatch(getAllCompaniesRequest()),
    inviteCustomerToCompany: (data) => dispatch(inviteCustomerToCompanyRequest(data)),
    updateInvitation: (data) => dispatch(updateInvitationRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InviteCustomer);
