import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Switch,
  FormControlLabel,
  Box,
  Grid,
  Button,
  IconButton,
  FormControl,
  Typography,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import ErrorIcon from '@material-ui/icons/Error';
import i18n from 'i18next';
import usePrevious from '../../../CustomHooks/usePrevious';
import Loading from '../../../Components/Loading/loading';
import { getServicesByIndividualUserIdRequest } from '../../../redux/service/actions';
import {
  getIndividualAvailableHoursRequest,
  getIndividualUserIndustryByIdRequest,
} from '../../../redux/individualUser/actions';
import { getIndividualCustomersRequest } from '../../../redux/customer/actions';
import calendar from '../../../assets/icons/calendar.svg';
import SnackbarToast from '../../../Modules/SnackbarToast';
import servicesLogo from '../../../assets/servicesLogo';
import SaveInDraft from './SaveInDraft';

function CreateOrUpdateAppointmentModal(props) {
  const dispatch = useDispatch();

  const {
    account,
    industries,
    industryOptions,
    calendarAppointmentItem,
    handleCreateAppointment,
    handleOpenCreateCustomerModal,
    isCreateOrUpdateAppointmentPerformed,
    closeCreateOrUpdateAppointmentModal,
  } = props;

  const {
    servicesByIndividualUserId,
    isGetServicesByIndividualUserIdSuccess,
  } = useSelector((state) => state.service);

  const {
    newCustomerForIndustry,
    isStoreCustomerToIndustrySuccess,
    isIndividualAvailableHoursSuccess,
    isIndividualAvailableHoursError,
    individualAvailableHours,
    isGetIndividualUserIndustryByIdSuccess,
    IndividualUserIndustryGot,
  } = useSelector((state) => state.individualUser);

  const prevIsIndividualAvailableHoursError = usePrevious(isIndividualAvailableHoursError);
  const prevIsStoreCustomerToIndustrySuccess = usePrevious(isStoreCustomerToIndustrySuccess);
  const prevIsIndividualAvailableHoursSuccess = usePrevious(isIndividualAvailableHoursSuccess);
  const prevIsGetIndividualUserIndustryByIdSuccess = usePrevious(isGetIndividualUserIndustryByIdSuccess);
  const prevIsGetServicesByIndividualUserIdSuccess = usePrevious(isGetServicesByIndividualUserIdSuccess);

  const [appointmentItem, setAppointmentItem] = useState({});
  const [appointmentLoading, setAppointmentLoading] = useState(false);
  const [selectedCustomerOption, setSelectedCustomerOption] = useState({});
  const [serviceOptions, setServiceOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedIndustryOption, setSelectedIndustryOption] = useState({});
  const [customerOptions, setCustomerOptions] = useState([]);
  const [industry, setIndustry] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedAllServiceOption, setSelectedAllServiceOption] = useState([]);
  const [pendingValue, setPendingValue] = useState('');
  const [availableTime, setAvailableTime] = useState([]);
  const [selectedHour, setSelectedHour] = useState('');
  const [showReminder, setShowReminder] = useState(false);
  const [check, setCheck] = useState(false);
  const [appointmentDraft, setAppointmentDraft] = useState({
    industry: '',
    customer: '',
    service: '',
  });
  const [loadDraft, setLoadDraft] = useState({});
  const [isSaveDraftModalOpen, setIsSaveDraftModalOpen] = useState(false);

  useEffect(() => () => {
    localStorage.removeItem('appointmentItems');
  }, []);

  // Show create appointment modal
  useEffect(() => {
    if (isCreateOrUpdateAppointmentPerformed) {
      const reminderCustomer = account.settings.reminder.customer;
      setShowReminder(reminderCustomer);
      if (reminderCustomer) {
        setCheck(!!reminderCustomer);
      }
      if (calendarAppointmentItem) {
        setAppointmentItem({
          ...calendarAppointmentItem,
          reminder: !!reminderCustomer,
        });
      } else {
        setAppointmentItem({
          ...appointmentItem,
          date: moment().format('YYYY-MM-DD'),
          reminder: !!reminderCustomer,
        });
      }
      // Retrieve the object from storage
      const retrievedDraftAppointmentObject = localStorage.getItem('appointmentItems');
      const getDraftAppointment = JSON.parse(retrievedDraftAppointmentObject);
      if (getDraftAppointment) {
        setLoadDraft(getDraftAppointment);
      }
    }
  }, [isCreateOrUpdateAppointmentPerformed]);

  // New Customer added
  useEffect(() => {
    if (prevIsStoreCustomerToIndustrySuccess === false && isStoreCustomerToIndustrySuccess) {
      const currentCustomer = { ...newCustomerForIndustry };
      const newCustomerOptions = {
        value: currentCustomer.id,
        label: `${currentCustomer.first_name} ${currentCustomer.last_name}`,
        phone: currentCustomer.full_phone_number,
      };
      setSelectedCustomerOption({
        ...selectedCustomerOption,
        value: currentCustomer.id,
        label: `${currentCustomer.first_name} ${currentCustomer.last_name}`,
        phone: currentCustomer.full_phone_number,
      });
      customerOptions.push(newCustomerOptions);
      snackBarAlert(true, i18n.t('CustomerAdded'), 'success');
    }
  }, [isStoreCustomerToIndustrySuccess]);

  // Handle servicesWithSubServices {
  useEffect(() => {
    if (prevIsGetServicesByIndividualUserIdSuccess === false && isGetServicesByIndividualUserIdSuccess) {
      const servicesByIndividualUserIdCopy = [...servicesByIndividualUserId];
      servicesByIndividualUserId.forEach((item) => {
        typeof item.subServices === 'string' ? item.subServices = JSON.parse(item.subServices) : item;
      });
      setSelectedAllServiceOption(servicesByIndividualUserIdCopy);
    }
  }, [servicesByIndividualUserId]);

  // Handle get available hours
  useEffect(() => {
    if (prevIsIndividualAvailableHoursSuccess === false && isIndividualAvailableHoursSuccess) {
      const individualAvailableHoursCopy = [...individualAvailableHours];
      setAvailableTime(individualAvailableHoursCopy);
    }
  }, [isIndividualAvailableHoursSuccess]);

  // Handle get available hours error
  useEffect(() => {
    if (prevIsIndividualAvailableHoursError === false && isIndividualAvailableHoursError) {
      snackBarAlert('true', 'Available hours not defined', 'error');
    }
  }, [isIndividualAvailableHoursError]);

  // Handle get Industry by ID success
  useEffect(() => {
    if (prevIsGetIndividualUserIndustryByIdSuccess === false && isGetIndividualUserIndustryByIdSuccess) {
      const industryCopy = { ...IndividualUserIndustryGot };
      const industryCustomers = industryCopy.customers;
      const customerOptions = [];
      industryCustomers.map((customer) => customerOptions.push({
        value: customer.id,
        label: `${customer.first_name} ${customer.last_name}`,
        phone: customer.full_phone_number,
      }));
      setCustomerOptions(customerOptions);
    }
  }, [isGetIndividualUserIndustryByIdSuccess]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const closeModal = () => {
    appointmentDraft.industry !== ''
    || appointmentDraft.customer !== ''
    || appointmentDraft.service !== '' ? setIsSaveDraftModalOpen(true)
      : handleRemoveSelectedItem();
  };

  const handleIndustryChange = (industry) => {
    if (industry) {
      const { industryId } = industry;
      const industryItem = industries.find((item) => item.industry_id === industryId);
      const industryOption = industryOptions.find((item) => item.industryId === industryId);
      setSelectedIndustryOption(industryOption);
      const serviceOptions = [];
      industryItem.services.map((item) => serviceOptions.push({
        id: item.id,
        value: item.service.id,
        name: item.service.name,
        duration: item.duration,
        price: item.price,
        type: item.industry_name,
        individual_user_industry_id: item.individual_user_industry_id,
      }));
      setServiceOptions(serviceOptions);
      setSelectedCustomerOption('');
      setIndustry(industryItem);
      setAppointmentDraft({
        ...appointmentDraft,
        industry: industryOption,
      });
      dispatch(getIndividualUserIndustryByIdRequest(industryItem.id));
      dispatch(getServicesByIndividualUserIdRequest({
        individualUserIndustryId: serviceOptions[0].individual_user_industry_id,
        industryId: industryItem.industry_id,
      }));
    } else {
      setSelectedIndustryOption({});
      setAppointmentDraft({
        ...appointmentDraft,
        industry: '',
      });
    }
  };

  const handleCustomerOptionChange = (customer) => {
    if (customer) {
      const customerId = customer.value;
      const currentCustomer = customerOptions.find((item) => item.value === customerId);
      setSelectedCustomerOption(currentCustomer);
      setAppointmentItem({
        ...appointmentItem,
        customer_id: customerId,
      });
      setAppointmentDraft({
        ...appointmentDraft,
        customer: currentCustomer,
      });
    } else {
      setAppointmentItem({});
      setAppointmentDraft({
        ...appointmentDraft,
        customer: '',
      });
    }
  };

  const handleServiceOptionChange = (service) => {
    if (service) {
      const serviceId = service.id;
      const serviceOption = serviceOptions.find((item) => item.value === serviceId);
      setAppointmentItem({
        ...appointmentItem,
        price: service.price,
        duration: service.duration,
        service_id: serviceOption.id,
      });
      const data = {
        individual_user_industry_id: service.individual_user_industry_id,
        individual_user_industry_service_id: serviceOption.id,
        date: appointmentItem.date,
      };
      setAppointmentDraft({
        ...appointmentDraft,
        service,
      });
      setPendingValue(service);
      dispatch(getIndividualAvailableHoursRequest(data));
      dispatch(getIndividualCustomersRequest(data));
    } else {
      setPendingValue('');
      setAppointmentItem({
        ...appointmentItem,
        price: '',
        duration: '',
        service_id: '',
      });
      setAppointmentDraft({
        ...appointmentDraft,
        service: '',
      });
    }
  };

  const handleDateChange = (name, value) => {
    setAppointmentItem({
      ...appointmentItem,
      [name]: value,
    });
    const data = {
      individual_user_industry_id: selectedIndustryOption.value,
      individual_user_industry_service_id: appointmentItem.service_id,
      date: value,
    };
    dispatch(getIndividualAvailableHoursRequest(data));
  };

  const validateForm = (name, all = false) => {
    const errorsCopy = { ...errors };
    if (all) {
      errorsCopy.industry = !selectedIndustryOption.value;
      errorsCopy.service = !pendingValue;
      errorsCopy.customer = !selectedCustomerOption.value;
      errorsCopy.startTime = !selectedHour;
      setErrors(errorsCopy);
      return (
        Object.keys(selectedIndustryOption).length !== 0
        && Object.keys(pendingValue) !== 0
        && Object.keys(selectedCustomerOption).length !== 0
        && selectedHour.length !== 0
      );
    } else {
      const value = appointmentItem[name];
      errorsCopy[name] = value.length === 0;
      setErrors(errorsCopy);
    }
  };

  const renderDatetime = (label, value, onChange, img, name) => (
    <FormControl
      fullWidth
      size="small"
      variant="outlined"
    >
      <TextField
        fullWidth
        size="small"
        type={label.toLowerCase()}
        onChange={(e) => onChange(name, e.target.value)}
        variant="outlined"
        label={label}
        style={{ position: 'relative' }}
        placeholder={label}
        value={value || ''}
      />

      { errors[name] && (
        <div className="error-message-content">
          <ErrorIcon fontSize="small" color="error" />
          <FormHelperText>
            {`${label} is required.`}
          </FormHelperText>
        </div>
      )}
    </FormControl>
  );

  const handleChangeHours = (event) => {
    const value = event && (typeof event === 'string' ? event : event.target.textContent);
    setAppointmentItem({
      ...appointmentItem,
      start_time: value,
    });
    setAppointmentDraft({
      ...appointmentDraft,
      startTime: value,
    });
    setSelectedHour(value);
  };

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setAppointmentItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }, [appointmentItem]);

  const options = selectedAllServiceOption.reduce((akku, row) => {
    const service = [];
    row.subServices.map((item) => {
      service.push({
        type: !service.length ? row.name : null,
        name: item.name,
        duration: item.duration,
        individual_user_industry_id: item.individual_user_industry_id,
        price: item.price,
        id: item.id,
      });
    });
    akku = akku.concat(service);
    return akku;
  }, []);

  const handleReminderDetailsChange = (event) => {
    const { checked } = event.target;
    setCheck(checked);

    setAppointmentItem({
      ...appointmentItem,
      reminder: checked,
    });
  };

  const filterCustomerOptions = createFilterOptions({
    stringify: (option) => option.label + option.phone,
  });

  const handleSaveToDraft = () => {
    const saveAppointment = {
      customer: appointmentDraft.customer || selectedCustomerOption,
      industry: appointmentDraft.industry || selectedIndustryOption,
      service: appointmentDraft.service,
      startTime: appointmentDraft.startTime,
    };
    handleRemoveSelectedItem();
    // Put an appointment to storage
    localStorage.setItem('appointmentItems', JSON.stringify(saveAppointment));
    snackBarAlert(true, i18n.t('AppointmentSavedDraft'), 'success');
  };

  const handleRemoveSelectedItem = () => {
    setIsSaveDraftModalOpen(false);
    setAppointmentDraft({
      industry: '',
      customer: '',
      service: '',
    });
    closeCreateOrUpdateAppointmentModal();
    setAppointmentItem({
      ...appointmentItem,
      price: '',
      duration: '',
      service_id: '',
    });
    setSelectedIndustryOption({});
    setSelectedCustomerOption({});
    setPendingValue('');
    setSelectedHour('');
  };

  const handleGetDraft = () => {
    loadDraft.industry && handleIndustryChange(loadDraft.industry);
    loadDraft.customer && handleCustomerOptionChange(loadDraft.customer);
    loadDraft.startTime && handleChangeHours(loadDraft.startTime);
    loadDraft.service && handleServiceOptionChange(loadDraft.service);
    setLoadDraft({});
    localStorage.setItem('appointmentItems', JSON.stringify({}));
  };

  const handleStoreAppointment = () => {
    if (validateForm('', true)) {
      handleCreateAppointment(industry, pendingValue, appointmentItem, selectedCustomerOption);
      setAppointmentLoading(false);
      handleRemoveSelectedItem();
    }
  };

  return (
    <>
      <Dialog
        open={isCreateOrUpdateAppointmentPerformed}
        aria-labelledby="example-modal-sizes-title-sm"
        onClose={closeModal}
      >
        <DialogTitle id="customized-dialog-title" onClose={closeModal}>
          <span className="alert-title">
            <span>Appointment</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => closeModal()}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          { !appointmentLoading ? (
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" size="small">
                  <Autocomplete
                    size="small"
                    fullWidth
                    autoHighlight
                    value={selectedIndustryOption}
                    onChange={(event, newValue) => {
                      setSelectedIndustryOption(newValue);
                      handleIndustryChange(newValue);
                    }}
                    options={industryOptions}
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => (
                      <div
                        className="input-menu"
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </div>
                    )}
                    filterOptions={filterCustomerOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                        label="Industry *"
                        variant="outlined"
                        error={(!!errors.industry)}
                      />
                    )}
                  />
                </FormControl>
                { errors.industry && <p className="error-message">Industry is required.</p> }
              </Grid>
              <Grid item xs={8}>
                <FormControl fullWidth variant="outlined" size="small">
                  <Autocomplete
                    size="small"
                    fullWidth
                    autoHighlight
                    value={selectedCustomerOption}
                    onChange={(event, newValue) => {
                      setSelectedCustomerOption(newValue);
                      handleCustomerOptionChange(newValue);
                    }}
                    options={customerOptions}
                    disabled={!Object.keys(selectedIndustryOption).length}
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => (
                      <div
                        className="input-menu"
                        key={option.value}
                        value={option.value}
                      >
                        {option.label} <span className="customer-option-phone"> + {option.phone} </span>
                      </div>
                    )}
                    filterOptions={filterCustomerOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                        label="Customer *"
                        variant="outlined"
                        error={(!!errors.customer)}
                      />
                    )}
                  />
                  { errors.customer && <p className="error-message mt-1">Customer is required.</p> }
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  <Button onClick={handleOpenCreateCustomerModal}>
                    Add New Customer
                  </Button>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth size="small" variant="outlined">
                  <Autocomplete
                    size="small"
                    fullWidth
                    autoHighlight
                    disabled={!Object.keys(selectedIndustryOption).length}
                    options={options}
                    groupBy={(option) => (
                      <span className="group-title">{option.type
                        ? <img src={servicesLogo[option.type]} className="service-img" alt="service-logo" width="20px" /> : null}
                        {option.type}
                      </span>
                    )}
                    getOptionLabel={(option) => option.name}
                    value={pendingValue}
                    onChange={(event, newValue) => {
                      setPendingValue(newValue);
                      handleServiceOptionChange(newValue);
                    }}
                    renderOption={(option) => (
                      <div
                        className="input-menu"
                        key={option.id}
                        value={option.id}
                      >
                        <Grid container direction="row">
                          <Grid item xs={8}>{option.name}</Grid>
                          <Grid item xs={2}><span className="service-duration">{option.duration} min</span></Grid>
                          <Grid item xs={2}><span className="service-price">{option.price} ֏</span></Grid>
                        </Grid>
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                        label="Service"
                        variant="outlined"
                        error={errors.service}
                      />
                    )}
                  />
                  { errors.service && <p className="error-message mt-1">Service is required.</p> }
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                {renderDatetime('Date', appointmentItem.date, handleDateChange, calendar, 'date')}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  size="small"
                  variant="outlined"
                >
                  <Autocomplete
                    size="small"
                    fullWidth
                    autoHighlight
                    options={availableTime}
                    disabled={!Object.keys(pendingValue).length}
                    value={selectedHour}
                    onChange={handleChangeHours}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'new-password',
                        }}
                        label="Available hours"
                        variant="outlined"
                        error={(!!errors.startTime)}
                      />
                    )}
                  />
                  { errors.startTime && (
                    <div className="error-message-content">
                      <ErrorIcon fontSize="small" color="error" />
                      <FormHelperText error>
                        Time is required.
                      </FormHelperText>
                    </div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Box className="w-100">
                  <TextField
                    fullWidth
                    size="small"
                    label="Notes"
                    variant="outlined"
                    aria-label="Notes"
                    name="note_from_creator"
                    className="notes-from-appointment"
                    placeholder={`Appointment ${i18n.t('Notes')}`}
                    onChange={handleInputChange}
                  />
                </Box>
              </Grid>
              {showReminder && (
                <Grid item xs={12} sm={12} justify="flex-end">
                  <FormControl component="fieldset">
                    <FormControlLabel
                      value={check}
                      control={(
                        <Switch
                          color="primary"
                          checked={check}
                        />
                            )}
                      onChange={handleReminderDetailsChange}
                      color="primary"
                      name="checked"
                      size="small"
                      label="Remind customer"
                      labelPlacement="start"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>
          ) : <Loading /> }
        </DialogContent>
        { !appointmentLoading ? (
          <DialogActions className="appointment-bottom-btn">
            <div className="bottom-btn">
              <div>
                <Button
                  size="small"
                  data-cy="save"
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="load-btn"
                  disabled={!Object.keys(loadDraft).length}
                  onClick={handleGetDraft}
                >
                  Load Draft
                </Button>
              </div>
              <div>
                <Button
                  onClick={closeModal}
                  data-cy="cancel"
                  type="cancel"
                  size="small"
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => handleStoreAppointment()}
                  color="primary"
                >
                  Save
                </Button>
              </div>
            </div>
          </DialogActions>
        )
          : null }
      </Dialog>
      <SaveInDraft
        open={isSaveDraftModalOpen}
        appointmentLoading={appointmentLoading}
        handleSaveToDraft={handleSaveToDraft}
        handleCancelSaveDraft={handleRemoveSelectedItem}
        handleCloseModal={() => setIsSaveDraftModalOpen(false)}
      />
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

CreateOrUpdateAppointmentModal.propTypes = {
  account: PropTypes.object.isRequired,
  industryOptions: PropTypes.array.isRequired,
  isCreateOrUpdateAppointmentPerformed: PropTypes.bool.isRequired,
  closeCreateOrUpdateAppointmentModal: PropTypes.func.isRequired,
  industries: PropTypes.array.isRequired,
  calendarAppointmentItem: PropTypes.object.isRequired,
  updateIndustries: PropTypes.func.isRequired,
  customerOption: PropTypes.array.isRequired,
  handleOpenCreateCustomerModal: PropTypes.func.isRequired,
  handleCreateAppointment: PropTypes.func.isRequired,
};

export default CreateOrUpdateAppointmentModal;
