import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function BottomCreatePart({ onClose, appointmentLoading, handleStoreAppointment }) {
  const { t } = useTranslation();
  return (
    <div className="buttons-box">
      <div className="btn">
        <Button
          onClick={onClose}
          data-cy="cancel"
          type="cancel"
          size="small"
          style={{
            borderRadius: 4,
            border: '1px solid #A4A4A4',
            backgroundColor: '#FFFFFF',
            padding: '6px 28px',
            font: 'normal 400 14px/19px Nunito Sans, sans-serif',
            color: '#A4A4A4',
            width: '100%',
            height: '100%',
          }}
        >
          {t('Cancel')}
        </Button>
      </div>
      <div className="create-btn">
        <Button
          size="small"
          onClick={handleStoreAppointment}
          data-cy="save"
          type="submit"
          variant="contained"
          color="primary"
          loading={appointmentLoading.toString()}
          style={{
            borderRadius: 4,
            backgroundColor: '#0282DA',
            padding: '8px 16px',
            font: 'normal 400 14px/19px Nunito Sans, sans-serif',
            color: '#FFFFFF',
            width: '100%',
            height: '100%',
          }}
        >
          {appointmentLoading && <CircularProgress color="white" size={20} />}
          {t('CreateAppointment')}
        </Button>
      </div>
    </div>
  );
}

BottomCreatePart.propTypes = {
  onClose: PropTypes.func.isRequired,
  appointmentLoading: PropTypes.bool.isRequired,
  handleStoreAppointment: PropTypes.func.isRequired,
};

export default BottomCreatePart;
