import React, { useEffect, useState } from 'react';
import '../../companies.scss';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select,
  Checkbox,
  TextField,
  InputLabel,
  Box,
  Grid,
  MenuItem,
  Typography,
  FormControl,
  FormHelperText,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ErrorIcon from '@material-ui/icons/Error';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import moment from 'moment';
import 'moment-timezone';
import DeleteIcon from '@material-ui/icons/Delete';
import withStyles from '@material-ui/core/styles/withStyles';
import i18n from 'i18next';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import {
  getCitiesRequest,
  getStatesRequest,
} from '../../../../../../../redux/country/actions';
import countryCodeOptions from '../../../../../../../Modules/countryCodeOptions';
import {
  updateCompanyRequest,
  uploadImageToAmazonRequest,
  getPreSignUrlRequest,
} from '../../../../../../../redux/company/actions';
import imgStyle from '../../../../../../../Modules/uploadImgStyle';
import InfoLoading from '../../../../../../../Components/Loading/infoLoading';
import TextMaskCustom from '../../../../../../../Modules/TextMaskCustom';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';
import validateEmail from '../../../../../../../Modules/validateEmail';

function Info(props) {
  const dispatch = useDispatch();
  const StaffCheckbox = withStyles(() => ({
    root: {
      color: '#62B1E8',
      '&$checked': {
        color: '#62B1E8',
      },
    },
  }))((props) => <Checkbox color="default" {...props} />);

  const ServiceCheckbox = withStyles(() => ({
    root: {
      color: '#62B1E8',
      '&$checked': {
        color: '#62B1E8',
      },
    },
  }))((props) => <Checkbox color="default" {...props} />);

  const useStyles = makeStyles(() => ({
    overviewBox: {
      display: 'flex',
    },
    photoTextarea: {
      flex: '1 1 auto',
    },
    overView: {
      marginLeft: 16,
    },
  }));
  const {
    companyItem,
    updateCompanyInfo,
    sendCompanyUpdated,
    isUpdateCompanySubmitDone,
  } = props;

  const {
    isUpdateCompanySuccess,
    isUpdateCompanyError,
    updatedCompany,
    updateCompanyErrors,
    isGetPreSignedUrlSuccess,
    // isUploadImageToAmazonSuccess,
    // isUploadImageToAmazonError,
    preSignUrl,
  } = useSelector((state) => state.company);

  const {
    states,
    isGetStatesSuccess,
    isGetStatesError,
    cities,
    isGetCitiesSuccess,
    isGetCitiesError,
    allCountries,
  } = useSelector((state) => state.country);

  // Get some props values
  const prevIsGetCitiesSuccess = usePrevious(isGetCitiesSuccess);
  const prevIsGetCitiesError = usePrevious(isGetCitiesError);
  const prevIsUpdateCompanySuccess = usePrevious(isUpdateCompanySuccess);
  const prevIsUpdateCompanyError = usePrevious(isUpdateCompanyError);
  const prevIsGetPreSignedUrlSuccess = usePrevious(isGetPreSignedUrlSuccess);
  // const prevIsGetPreSignedUrlError = usePrevious(isGetPreSignedUrlSuccess);
  // const prevIsUploadImageToAmazonSuccess = usePrevious(isUploadImageToAmazonSuccess);
  // const prevIsUploadImageToAmazonError = usePrevious(isUploadImageToAmazonError);

  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState({});
  const [selectedStateOption, setSelectedStateOption] = useState({});
  const [selectedCityOption, setSelectedCityOption] = useState({});
  const [selectedTimeZoneOption, setSelectedTimeZoneOption] = useState('');
  const [codeOptions, setCodeOptions] = useState([]);
  const [files, setFiles] = useState(null);
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [isChangeLogo, setIsChangeLogo] = useState(false);
  const [errors, setErrors] = useState({
    name: false,
    nameLong: false,
    email: false,
    industry: false,
    country: false,
    state: false,
    city: false,
    address: false,
    phone_code: false,
    phone_number: false,
  });
  const [userCompanyCountry, setUserCompanyCountry] = useState({});
  const [updateErrors, setUpdateErrors] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [logoName, setLogoName] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const CHARACTER_LIMIT = 10;

  // Handle update company submit
  useEffect(() => {
    if (isUpdateCompanySubmitDone) {
      const errors = validateForm();
      const keys = Object.keys(errors);
      const errorExists = keys.find((key) => errors[key]);
      if (!errorExists) {
        if (selectedLogo) {
          const logo = moment().format('DDMMYYYYHHmmss') + selectedLogo.name;
          setLogoName(logo);
          const companyCopy = {
            ...company,
            aws_logo: logo,
          };
          setCompany(companyCopy);
          dispatch(updateCompanyRequest({ company: companyCopy, isChangeLogo }));
        } else {
          dispatch(updateCompanyRequest({ company, isChangeLogo }));
        }
      } else {
        sendCompanyUpdated(null);
      }
    }
  }, [isUpdateCompanySubmitDone]);

  // Perform when function gets mounted
  useEffect(() => {
    if (Object.keys(companyItem).length) {
      setCompany(companyItem);
      if (companyItem.country_id) {
        dispatch(getStatesRequest(companyItem.country_id));
      }
    }
    const countryOptions = countryCodeOptions.getCountryOptions(allCountries);
    setCountryOptions(countryOptions);
    const codeOptions = countryCodeOptions.getCodeOptions(allCountries);
    setCodeOptions(codeOptions);

    const userCompanyCountry = countryOptions.find((item) => item.id === companyItem.country_id);
    setUserCompanyCountry(userCompanyCountry);
    const selectedCountryOption = countryOptions.find((item) => item.id === companyItem.country_id);
    setSelectedCountryOption(selectedCountryOption);
    const timeZones = moment.tz.names();
    const timeZoneNamesList = [];
    timeZones.forEach((item, i) => timeZoneNamesList.push(` (GMT${moment.tz(timeZones[i]).format('Z')})${timeZones[i]}`));
    setTimeZoneOptions(timeZoneNamesList);
  }, []);

  // Check If Get States Is Success
  useEffect(() => {
    if (isGetStatesSuccess) {
      const stateOptions = countryCodeOptions.getStateOptions(states);
      setStateOptions(stateOptions);
      if (selectedCountryOption.id === userCompanyCountry.id) {
        if (company.state_id) {
          const selectedStateOption = stateOptions.find((item) => item.id === company.state_id);
          if (selectedStateOption && company.state_id) {
            setSelectedStateOption(selectedStateOption);
            dispatch(getCitiesRequest(company.state_id));
          } else {
            setLoading(false);
          }
        }
      }
    }
  }, [isGetStatesSuccess]);

  // Is Get Pre-Signed Url
  useEffect(() => {
    if (prevIsGetPreSignedUrlSuccess === false && isGetPreSignedUrlSuccess) {
      dispatch(uploadImageToAmazonRequest({ preSignUrl, logo: selectedLogo }));
    }
  }, [isGetPreSignedUrlSuccess]);

  // Check If you Get States Is Error
  useEffect(() => {
    if (isGetStatesError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetStatesError]);

  // Check If you Get Cities Is Success
  useEffect(() => {
    if (prevIsGetCitiesSuccess === false && isGetCitiesSuccess) {
      const cityOptions = countryCodeOptions.getCitiesOptions(cities);
      setCityOptions(cityOptions);
      if (selectedCountryOption.id === userCompanyCountry.id) {
        const selectedCityOption = cityOptions.find((item) => item.id === company.city_id);
        if (selectedCityOption) {
          setSelectedCityOption(selectedCityOption);
        }
        setLoading(false);
      }
    }
  }, [isGetCitiesSuccess]);

  // Check If Get Cities Is Error
  useEffect(() => {
    if (prevIsGetCitiesError === false && isGetCitiesError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
      setLoading(false);
    }
  }, [isGetCitiesError]);

  // Check if update company success
  useEffect(() => {
    if (!prevIsUpdateCompanySuccess && isUpdateCompanySuccess && updatedCompany.id) {
      const preSignUrlOption = {
        id: company.id,
        logo: logoName,
        folder: null,
      };
      if (selectedLogo) {
        dispatch(getPreSignUrlRequest(preSignUrlOption));
      }
      sendCompanyUpdated(updatedCompany, true);
      setUpdateErrors([]);
      setCompany(updatedCompany);
    }
  }, [isUpdateCompanySuccess]);

  // Check if update company error
  useEffect(() => {
    if (prevIsUpdateCompanyError === false && isUpdateCompanyError) {
      sendCompanyUpdated(null);
      setUpdateErrors(updateCompanyErrors);
    }
  }, [isUpdateCompanyError]);

  const validateForm = () => {
    const errorsCopy = { ...errors };
    const keys = Object.keys(company);
    keys.map((key) => {
      switch (key) {
        case 'name': {
          errorsCopy.name = company.name.length === 0;
          errorsCopy.nameLong = company.name.length > 191;
          break;
        }
        case 'email': {
          errorsCopy.email = company.email && company.email !== 'null' && validateEmail(company.email);
          break;
        }
        case 'address': {
          errorsCopy.address = company.address.length === 0;
          break;
        }
        case 'phone_number': {
          errorsCopy.phone_number = !company.phone_number ? i18n.t('PhoneNumber') + i18n.t('IsRequired')
            : company.phone_number.length < 12 ? i18n.t('PhoneNumber') + i18n.t('IsNotValid') : null;
          break;
        }
        case 'phone_code': {
          errorsCopy.phone_code = company.phone_code.length === 0;
          break;
        }
        case 'industry_id': {
          errorsCopy.industry = !(company.industry_id);
          break;
        }
        case 'country_id': {
          errorsCopy.country = !(company.country_id);
          break;
        }
        case 'state_id': {
          errorsCopy.state = !(company.state_id);
          break;
        }
        case 'city_id': {
          errorsCopy.city = !(company.city_id);
          break;
        }
        default: {
          break;
        }
      }
    });
    setErrors(() => ({ ...errorsCopy }));
    return errorsCopy;
  };
  const handleChangeStaffBased = (event) => {
    setCompany({
      ...company,
      is_staff_based: event.target.checked,
    });
  };
  const handleChangeServiceBased = (event) => {
    setCompany({
      ...company,
      is_service_based: event.target.checked,
    });
  };
  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompany({
      ...company,
      [name]: value,
    });
    updateCompanyInfo({
      [name]: value,
      input: true,
    });
  };

  const handleCountrySelectChange = (event, selectedCountry) => {
    if (selectedCountry) {
      const codeOption = codeOptions.find((item) => item.key === selectedCountry.key);
      const countryId = selectedCountry.id;
      dispatch(getStatesRequest(countryId));
      setSelectedCountryOption(countryId ? selectedCountry : '');
      setSelectedStateOption('');
      setSelectedCityOption('');
      setCityOptions([]);
      setCompany((prevState) => ({
        ...prevState,
        country_id: countryId,
        phone_code: codeOption.value,
        state_id: '',
        city_id: '',
        address: '',
      }));
      updateCompanyInfo({
        country_id: countryId,
        phone_code: codeOption.value,
        state_id: '',
        city_id: '',
        input: false,
      });
    } else {
      setStateOptions([]);
      setSelectedStateOption('');
      setSelectedCityOption('');
      setSelectedCountryOption('');
      setCityOptions([]);
      setCompany((prevState) => ({
        ...prevState,
        country_id: '',
        state_id: '',
        city_id: '',
        address: '',
      }));
    }
  };

  const handleStateSelectChange = (e, selectedState) => {
    if (selectedState && selectedState.id) {
      const stateId = selectedState.id;
      dispatch(getCitiesRequest(stateId));
      setSelectedStateOption(stateId ? selectedState : '');
      setSelectedCityOption('');
      setCityOptions([]);
      setCompany({
        ...company,
        state_id: stateId,
        address: '',
      });
      updateCompanyInfo({
        state_id: stateId,
        input: false,
      });
    } else {
      setSelectedCityOption('');
      setSelectedStateOption('');
      setCityOptions([]);
      setCompany({
        ...company,
        state_id: '',
        address: '',
      });
    }
  };

  const handleCitySelectChange = (e, selectedCity) => {
    if (selectedCity && selectedCity.id) {
      const cityId = selectedCity.id;
      setSelectedCityOption(cityId ? selectedCity : '');
      setCompany({
        ...company,
        city_id: cityId,
        address: '',
      });
      updateCompanyInfo({
        city_id: cityId,
        input: false,
      });
    } else {
      setSelectedCityOption('');
      setCompany({
        ...company,
        city_id: '',
        address: '',
      });
    }
  };

  const handleTimeZoneSelectChange = (e, selectedTimeZone) => {
    if (selectedTimeZone) {
      setSelectedTimeZoneOption(selectedTimeZone || '');
      setCompany((prevState) => ({
        ...prevState,
        timezone: selectedTimeZone,
      }));
      updateCompanyInfo({
        timezone: selectedTimeZone,
        input: false,
      });
    }
  };

  const handlePhoneCodeSelectChange = (selectedCodeOption) => {
    const phoneCode = selectedCodeOption.target.value;
    setCompany({
      ...company,
      phone_code: phoneCode,
    });
  };

  const removeIndustryAvatar = () => {
    setFiles(null);
    setCompany({
      ...company,
      aws_logo: null,
      logo: null,
    });
    setSelectedLogo(null);
    setIsChangeLogo(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(URL.createObjectURL(acceptedFiles[0]));
      setSelectedLogo(acceptedFiles[0]);
      setIsChangeLogo(true);
    },
  });

  const thumbs = (
    <>
      {(files || selectedLogo || company.logo) && (
        <div className="thumb-style">
          <div style={imgStyle.thumb}>
            <div className="thumbInner" style={imgStyle.thumbInner}>
              <img
                src={files || selectedLogo || company.logo}
                style={imgStyle.img}
                alt="logo"
              />
              <div className="remove-industry-avatar">
                <DeleteIcon className="remove-icon" onClick={() => removeIndustryAvatar()} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );

  const classes = useStyles();

  return (
    <>
      {!loading ? (
        <Grid container direction="row" id="fixed-box-info" spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="info-box">
              <Box className="info-box-title">
                <Typography align="left" className="info-title-text">{i18n.t('InfoGeneral')}</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.name)}
                  >
                    <TextField
                      fullWidth
                      error={(!!errors.name)}
                      size="small"
                      name="name"
                      value={company.name || ''}
                      onChange={(e) => handleInputChange(e)}
                      variant="outlined"
                      label={`${i18n.t('CompanyName')} *`}
                      inputProps={{
                        autoComplete: 'new-password',
                        maxLength: 60,
                      }}
                    />

                    { errors.name && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('name') + i18n.t('IsRequired')}
                        </FormHelperText>
                      </div>
                    )}

                    { errors.nameLong && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('PhoneNumber') + i18n.t('IsTooLong')}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <FormControl fullWidth size="small" variant="outlined" disabled>
                    <TextField
                      fullWidth
                      size="small"
                      name="name"
                      value={company.industry.name}
                      variant="outlined"
                      label={`${i18n.t('Industry')} *`}
                      disabled
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} container>
                  <Grid item xs={10} className="filter-menu">
                    <Box alignSelf="center" ml={2} mt={1} className="filter-text">
                      <Typography component="div" noWrap>
                        {i18n.t('StaffBased')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <StaffCheckbox
                      checked={company.is_staff_based ? 'checked' : ''}
                      onChange={(e) => handleChangeStaffBased(e)}
                      name="staff_based"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} sm={6} container>
                  <Grid item xs={10} className="filter-menu">
                    <Box alignSelf="center" ml={2} mt={1} className="filter-text">
                      <Typography component="div" noWrap>
                        {i18n.t('ServiceBased')}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <ServiceCheckbox
                        // value={company.is_staff_based}
                      checked={company.is_service_based ? 'checked' : ''}
                      onChange={(e) => handleChangeServiceBased(e)}
                      name="service_based"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={(!!errors.email)}
                  >
                    <TextField
                      error={(!!errors.email)}
                      type="email"
                      label={i18n.t('Email')}
                      name="email"
                      placeholder={i18n.t('CompanyEmail')}
                      size="small"
                      className="company-email"
                      value={company.email !== 'null' ? company.email : ''}
                      onChange={handleInputChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><MailOutlineIcon /></InputAdornment>,
                        autoComplete: 'new-password',
                      }}
                      variant="outlined"
                    />

                    { errors.email && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('Errors.EmailValid')}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.phone_code)}
                    disabled
                  >
                    <InputLabel>{`${i18n.t('PhoneCode')} *`}</InputLabel>
                    <Select
                      name="phone_code"
                      label={`${i18n.t('PhoneCode')} *`}
                      value={company.phone_code}
                      onChange={(event) => handlePhoneCodeSelectChange(event)}
                    >
                      {codeOptions && codeOptions.map((option) => (
                        <MenuItem
                          key={Math.random()}
                          value={option.value}
                        >
                          <img
                            src={option.label.flagImageSrc}
                            alt="flag"
                            className="country-flag"
                          />
                          {option.key}
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>

                    { errors.phone_code && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('PhoneCode') + i18n.t('IsRequired')}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.phone_number || !!updateErrors.phone_number)}
                  >
                    <TextField
                      error={(!!errors.phone_number || !!updateErrors.phone_number)}
                      fullWidth
                      size="small"
                      id="phone-detail"
                      type="text"
                      value={company.phone_number || ''}
                      onChange={(e) => handleInputChange(e)}
                      name="phone_number"
                      variant="outlined"
                      data-cy="phone"
                      label={`${i18n.t('PhoneNumber')} *`}
                      InputProps={{
                        inputComponent: TextMaskCustom,
                        autoComplete: 'new-password',
                      }}
                    />
                    { (errors.phone_number || updateErrors.length !== 0) && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {updateErrors.phone_number ? updateErrors.phone_number : errors.phone_number}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box className={classes.overviewBox}>
                    <Box className={classes.photoTextarea} maxWidth="150px">
                      <Box className="add-logo-content" id="dropzone" width="100%">
                        <div {...getRootProps({ className: files || selectedLogo || company.logo ? 'without-background' : 'dropzone' })}>
                          <input {...getInputProps()} />
                        </div>
                        <aside style={imgStyle.thumbsContainer}>
                          {thumbs}
                        </aside>
                      </Box>
                      { updateErrors.logo && (
                        <>
                          {updateErrors.logo.map((error) => (
                            <div className="error-message-content">
                              <ErrorIcon fontSize="small" color="error" />
                              <FormHelperText error>
                                { error }
                              </FormHelperText>
                            </div>
                          ))}
                        </>
                      )}
                    </Box>
                    <Box className={`${classes.photoTextarea} ${classes.overView}`}>
                      <TextField
                        fullWidth
                        label={i18n.t('CompanyOverview')}
                        multiline
                        rows={8}
                        placeholder={i18n.t('CompanyOverview')}
                        value={company.overview !== 'null' ? company.overview : i18n.t('CompanyOverview')}
                        defaultValue={i18n.t('CompanyOverview')}
                        variant="outlined"
                        onChange={handleInputChange}
                        className="overview"
                        name="overview"
                        inputProps={{
                          autoComplete: 'new-password',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="info-box">
              <Box className="info-box-title">
                <Typography align="left" className="info-title-text">{i18n.t('InfoLocation')}</Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.country)}
                  >
                    <Autocomplete
                      size="small"
                      fullWidth
                      onChange={handleCountrySelectChange}
                      value={selectedCountryOption}
                      options={countryOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          label={`${i18n.t('Country')} *`}
                          variant="outlined"
                          error={(!!errors.country)}
                        />
                      )}
                    />

                    { errors.country && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('Country') + i18n.t('IsRequired')}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.state)}
                  >
                    <Autocomplete
                      size="small"
                      fullWidth
                      disabled={!selectedCountryOption.id}
                      onChange={handleStateSelectChange}
                      value={selectedStateOption}
                      options={stateOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          label={`${i18n.t('State')} *`}
                          variant="outlined"
                          error={(!!errors.state)}
                        />
                      )}
                    />

                    { errors.state && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('State') + i18n.t('IsRequired')}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.city)}
                  >
                    <Autocomplete
                      size="small"
                      fullWidth
                      disabled={!selectedStateOption.id}
                      onChange={handleCitySelectChange}
                      value={selectedCityOption}
                      options={cityOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          label={`${i18n.t('City')} *`}
                          variant="outlined"
                          error={(!!errors.city)}
                        />
                      )}
                    />

                    { errors.city && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('City') + i18n.t('IsRequired')}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.address)}
                  >
                    <TextField
                      fullWidth
                      error={(!!errors.address)}
                      size="small"
                      variant="outlined"
                      name="address"
                      label={`${i18n.t('Address')} *`}
                      value={company.address || ''}
                      inputProps={{
                        autoComplete: 'new-password',
                      }}
                      onChange={(e) => handleInputChange(e)}
                    />

                    { errors.address && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('Address') + i18n.t('IsRequired')}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={!!errors.zip}
                  >
                    <TextField
                      fullWidth
                      error={!!errors.zip}
                      id="zip"
                      label={i18n.t('ZipCode')}
                      variant="outlined"
                      size="small"
                      name="zip_code"
                      value={company.zip_code !== 'null' ? company.zip_code : ''}
                      onChange={handleInputChange}
                      inputProps={{ maxLength: CHARACTER_LIMIT, autoComplete: 'new-password' }}
                    />
                    { errors.zip && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('ZipCode') + i18n.t('IsRequired')}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    <Autocomplete
                      size="small"
                      fullWidth
                      onChange={handleTimeZoneSelectChange}
                      value={selectedTimeZoneOption || company.timezone !== 'null' ? selectedTimeZoneOption || company.timezone : ''}
                      options={timeZoneOptions}
                      getOptionLabel={(option) => option || ''}
                      renderOption={(option) => (
                        <>
                          <span>{`(${moment.tz(option).zoneAbbr()})`} </span>
                          {option}
                        </>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          label={`${i18n.t('TimeZone')} *`}
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Box className="info-box">
              <Box className="info-box-title">
                <Typography align="left" className="info-title-text">{i18n.t('InfoLinks')}</Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item sm={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    <TextField
                      label={i18n.t('WebSite')}
                      name="website_url"
                      placeholder={i18n.t('WebSite')}
                      size="small"
                      className="company-link"
                      value={company.website_url !== 'null' ? company.website_url : ''}
                      onChange={(e) => handleInputChange(e)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><LanguageIcon /></InputAdornment>,
                        autoComplete: 'new-password',
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    <TextField
                      label={i18n.t('Facebook')}
                      name="facebook_url"
                      placeholder={i18n.t('Facebook')}
                      size="small"
                      className="company-link"
                      value={company.facebook_url !== 'null' ? company.facebook_url : ''}
                      onChange={(e) => handleInputChange(e)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><FacebookIcon /></InputAdornment>,
                        autoComplete: 'new-password',
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    <TextField
                      label={i18n.t('Instagram')}
                      name="instagram_url"
                      placeholder={i18n.t('Instagram')}
                      size="small"
                      className="company-link"
                      value={company.instagram_url !== 'null' ? company.instagram_url : ''}
                      onChange={(e) => handleInputChange(e)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><InstagramIcon /></InputAdornment>,
                        shrink: true,
                        autoComplete: 'new-password',
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    <TextField
                      label={i18n.t('BookingUrl')}
                      name="booking_url"
                      size="small"
                      className="company-link"
                      value={company.booking_url !== 'null' ? company.booking_url : ''}
                      onChange={(e) => handleInputChange(e)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><DateRangeIcon /></InputAdornment>,
                        autoComplete: 'new-password',
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : <InfoLoading />}
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

Info.propTypes = {
  companyItem: PropTypes.object.isRequired,
  updateCompanyInfo: PropTypes.func.isRequired,
  sendCompanyUpdated: PropTypes.func.isRequired,
  getPreSignUrl: PropTypes.func.isRequired,
  isUpdateCompanySubmitDone: PropTypes.bool.isRequired,
};

export default Info;
