import React, { useState, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import usePrevious from '../../../../CustomHooks/usePrevious';
import { syncGoogleCalendarRequest } from '../../../../redux/account/actions';
import SnackbarToast from '../../../../Modules/SnackbarToast';

const SyncButton = () => {
  const dispatch = useDispatch();
  const {
    isSyncGoogleCalendarSuccess,
    isSyncGoogleCalendarError,
    oauthUrl,
  } = useSelector((state) => state.account);
  const prevIsSyncGoogleCalendarSuccess = usePrevious(isSyncGoogleCalendarSuccess);
  const prevIsSyncGoogleCalendarError = usePrevious(isSyncGoogleCalendarError);

  const [buttonLoading, setButtonLoading] = useState(false);
  const [isSnackbarError, setIsSnackbarError] = useState(false);

  useEffect(() => {
    if (!prevIsSyncGoogleCalendarSuccess && isSyncGoogleCalendarSuccess) {
      setButtonLoading(false);
      window.location = oauthUrl;
    } else if (!prevIsSyncGoogleCalendarError && isSyncGoogleCalendarError) {
      setIsSnackbarError(true);
      setButtonLoading(false);
    }
  }, [isSyncGoogleCalendarSuccess, isSyncGoogleCalendarError]);

  const onButtonClick = () => {
    setButtonLoading(true);
    dispatch(syncGoogleCalendarRequest());
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={onButtonClick}
        size="small"
        disabled={buttonLoading}
      >
        {buttonLoading && <CircularProgress color="white" size={20} />}Sync with google
      </Button>
      <SnackbarToast
        message="Something went wrong"
        type="error"
        open={isSnackbarError}
        onClose={() => setIsSnackbarError(false)}
      />
    </>
  );
};

export default SyncButton;
