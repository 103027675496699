import React from 'react';
import {
  Dialog,
  Select,
  InputLabel,
  TextField,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Button,
  MenuItem,
  IconButton,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';
import i18n from 'i18next';
import TextMaskCustom from '../../../Modules/TextMaskCustom';

const EditCustomerModal = (props) => {
  const {
    open,
    onClose,
    customerItem,
    selectedPhoneCode,
    phoneCodeOptions,
    handleUpdateCustomer,
    invitationFormErrors,
    handleEditCustomerItemChange,
    handleSelectChangeForCode,
  } = props;

  return (
    <Dialog
      size="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <span className="alert-title">
          <span>{i18n.t('EditCustomer')}</span>
          <span>
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={(!!invitationFormErrors.firstName)}
            >
              <TextField
                fullWidth
                error={(!!invitationFormErrors.firstName)}
                size="small"
                label="First name"
                data-cy="firstName"
                data-customer="first_name"
                variant="outlined"
                type="text"
                name="first_name"
                value={customerItem.first_name}
                onChange={handleEditCustomerItemChange}
                inputProps={{ maxLength: 20 }}
              />

              {invitationFormErrors.firstName && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {invitationFormErrors.firstName}
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={(!!invitationFormErrors.lastName)}
            >
              <TextField
                fullWidth
                error={(!!invitationFormErrors.lastName)}
                size="small"
                label="Last name"
                data-cy="lastName"
                data-customer="last_name"
                id="outlined-size-normal"
                variant="outlined"
                type="text"
                name="last_name"
                value={customerItem.last_name}
                onChange={handleEditCustomerItemChange}
                inputProps={{ maxLength: 20 }}
              />

              {invitationFormErrors.lastName && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {invitationFormErrors.lastName}
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={(!!invitationFormErrors.phone_code)}
            >
              <InputLabel>Code *</InputLabel>
              <Select
                name="phone_code"
                label="Code *"
                onChange={handleSelectChangeForCode}
                value={selectedPhoneCode.value || customerItem.phone_code}
              >
                <MenuItem disabled value=""><em>{i18n.t('PhoneCode')}</em></MenuItem>
                {phoneCodeOptions && phoneCodeOptions.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.value}
                    selected={selectedPhoneCode.value === option.value}
                  >
                    <img
                      src={option.label.flagImageSrc}
                      alt="flag"
                      className="country-flag"
                    />
                    {option.key}
                    {option.value}
                  </MenuItem>
                ))}
              </Select>

              {invitationFormErrors.phone_code && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {invitationFormErrors.phone_code}
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={(!!invitationFormErrors.phone_number)}
            >
              <TextField
                fullWidth
                error={(!!invitationFormErrors.phone_number)}
                size="small"
                data-cy="phone"
                id="phone-detail"
                data-customer="phone_number"
                type="text"
                name="phone_number"
                value={customerItem.phone_number}
                onChange={handleEditCustomerItemChange}
                label="Phone number *"
                variant="outlined"
                InputProps={{
                  inputComponent: TextMaskCustom,
                }}
              />

              {invitationFormErrors.phone_number && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    {invitationFormErrors.phone_number}
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          data-cy="cancel"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="contained"
          data-cy="update"
          onClick={handleUpdateCustomer}
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditCustomerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customerItem: PropTypes.object.isRequired,
  phoneCodeOptions: PropTypes.array.isRequired,
  selectedPhoneCode: PropTypes.object.isRequired,
  handleUpdateCustomer: PropTypes.func.isRequired,
  invitationFormErrors: PropTypes.object.isRequired,
  handleSelectChangeForCode: PropTypes.func.isRequired,
  handleEditCustomerItemChange: PropTypes.func.isRequired,
};

export default EditCustomerModal;
