import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import '../../../../../../../style/general.scss';
import {
  Card,
  Menu,
  Select,
  Dialog,
  Tooltip,
  TextField,
  InputLabel,
  DialogTitle,
  ListItemText,
  DialogActions,
  DialogContent,
  LinearProgress,
  Box,
  Grid,
  Badge,
  Button,
  MenuItem,
  CardMedia,
  CardHeader,
  Typography,
  IconButton,
  CardContent,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ErrorIcon from '@material-ui/icons/Error';
import Avatar from '@material-ui/core/Avatar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import withStyles from '@material-ui/core/styles/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import moment from 'moment';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import {
  getIndividualCustomersRequest,
  deleteIndividualUserInvitationRequest,
  updateIndividualUserInvitationRequest,
  getAllIndividualCustomerInvitationsRequest,
} from '../../../../../../../redux/customer/actions';
import {
  resendCustomerEmailRequest,
  storeCustomerToIndustryRequest,
  updateIndividualCustomerRequest,
  inviteCustomerToIndustryRequest,
  deleteCustomerFromIndustryRequest,
  getIndividualUserIndustriesRequest,
  getIndividualUserIndustryByIdRequest,
} from '../../../../../../../redux/individualUser/actions';
import countryCodeOptions from '../../../../../../../Modules/countryCodeOptions';
import noCustomers from '../../../../../../../assets/img/no-customer.svg';
import TextMaskCustom from '../../../../../../../Modules/TextMaskCustom';
import BoxLoading from '../../../../../../../Components/Loading/BoxLoading';
import DeleteButton from '../../../../../../../MaterialComponents/deleteButton';
import LightTooltip from '../../../../../../../MaterialComponents/lightTooltip';
import firstLetter from '../../../../../../../Modules/getFirstLetter';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';
import AddCustomerModal from '../../../../../../../Modals/IndividualUser/Customer/addCustomerModal';
import CustomerDropdownMenu from '../../../../../../../Modals/IndividualUser/Customer/customerDropdownMenu';
import CustomerIsolatedDropdown from '../../../../../../../Modals/IndividualUser/Customer/customerIsolatedDropdown';
import PreviewCustomerModal from '../../../../../../../Modals/Customers/Preview';

function Customers(props) {
  const dispatch = useDispatch();

  // Parent Props
  const {
    match,
    addCustomerModalOpen,
    setAddCustomerModalOpen,
  } = props;

  const { userAccount } = useSelector((state) => state.account);

  const {
    allCountries,
  } = useSelector((state) => state.country);

  const {
    isGetAllCustomersError,
    allIndividualCustomers,
    isGetAllCustomersSuccess,
    isDeleteIndividualCustomerError,
    updatedIndividualUserInvitation,
    allIndustriesCustomerInvitations,
    isGetAllCustomerInvitationsError,
    isDeleteIndividualCustomerSuccess,
    isGetAllIndividualInvitationsError,
    isGetAllIndividualInvitationsSuccess,
    deleteIndividualCustomerErrorMessage,
    updateIndividualUserInvitationSucceed,
    isDeleteIndividualUserInvitationError,
    isUpdateIndividualUserInvitationError,
    isUpdateIndividualUserInvitationSuccess,
    isDeleteIndividualUserInvitationSuccess,
    updateIndividualUserInvitationErrorMessage,
    deleteIndividualUserInvitationErrorMessage,
  } = useSelector((state) => state.customers);

  const {
    newInvitation,
    newCustomerForIndustry,
    updatedIndividualCustomer,
    IndividualUserIndustryGot,
    allIndividualUserIndustries,
    isResendCustomerEmailSuccess,
    inviteCustomerToIndustryErrors,
    isInviteCustomerToIndustryError,
    isUpdateIndividualCustomerError,
    inviteCustomerToIndustrySucceed,
    isStoreCustomerToIndustrySuccess,
    isInviteCustomerToIndustrySuccess,
    isUpdateIndividualCustomerSuccess,
    isGetIndividualUserIndustriesError,
    filteredIndividualCustomerByStatus,
    isDeleteCustomerFromIndustrySuccess,
    updateIndividualCustomerErrorMessage,
    isGetIndividualUserIndustriesSuccess,
    isGetIndividualUserIndustryByIdError,
    getIndividualUserIndustryErrorMessage,
    isGetIndividualUserIndustryByIdSuccess,
    isFilterIndividualCustomerByStatusError,
    isFilterIndividualCustomerByStatusSuccess,
  } = useSelector((state) => state.individualUser);

  // Get some props previous values
  const prevIsGetAllCustomersError = usePrevious(isGetAllCustomersError);
  const prevIsGetAllCustomersSuccess = usePrevious(isGetAllCustomersSuccess);
  const prevIsResendCustomerEmailSuccess = usePrevious(isResendCustomerEmailSuccess);
  const prevIsDeleteIndividualCustomerError = usePrevious(isDeleteIndividualCustomerError);
  const prevIsInviteCustomerToIndustryError = usePrevious(isInviteCustomerToIndustryError);
  const prevIsUpdateIndividualCustomerError = usePrevious(isUpdateIndividualCustomerError);
  const prevIsStoreCustomerToIndustrySuccess = usePrevious(isStoreCustomerToIndustrySuccess);
  const prevIsDeleteIndividualCustomerSuccess = usePrevious(isDeleteIndividualCustomerSuccess);
  const prevIsInviteCustomerToIndustrySuccess = usePrevious(isInviteCustomerToIndustrySuccess);
  const prevIsUpdateIndividualCustomerSuccess = usePrevious(isUpdateIndividualCustomerSuccess);
  const prevIsGetAllIndividualInvitationsError = usePrevious(isGetAllIndividualInvitationsError);
  const prevIsGetIndividualUserIndustriesError = usePrevious(isGetIndividualUserIndustriesError);
  const prevIsDeleteCustomerFromIndustrySuccess = usePrevious(isDeleteCustomerFromIndustrySuccess);
  const prevIsGetIndividualUserIndustriesSuccess = usePrevious(isGetIndividualUserIndustriesSuccess);
  const prevIsGetAllIndividualInvitationsSuccess = usePrevious(isGetAllIndividualInvitationsSuccess);
  const prevIsGetIndividualUserIndustryByIdError = usePrevious(isGetIndividualUserIndustryByIdError);
  const prevIsDeleteIndividualUserInvitationError = usePrevious(isDeleteIndividualUserInvitationError);
  const prevIsUpdateIndividualUserInvitationError = usePrevious(isUpdateIndividualUserInvitationError);
  const prevIsGetIndividualUserIndustryByIdSuccess = usePrevious(isGetIndividualUserIndustryByIdSuccess);
  const prevIsUpdateIndividualUserInvitationSuccess = usePrevious(isUpdateIndividualUserInvitationSuccess);
  const prevIsDeleteIndividualUserInvitationSuccess = usePrevious(isDeleteIndividualUserInvitationSuccess);
  const prevIsFilterIndividualCustomerByStatusError = usePrevious(isFilterIndividualCustomerByStatusError);
  const prevIsFilterIndividualCustomerByStatusSuccess = usePrevious(isFilterIndividualCustomerByStatusSuccess);

  const theme = useTheme();
  const [alert, setAlert] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const tableAppHeader = ['Date', 'Name', 'Price', 'Status'];
  const [customerItem, setCustomerItem] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone_code: '',
    phone_number: '',
    individual_user_industry_id: '',
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEm, setAnchorEm] = React.useState(null);
  const [snackbarType, setSnackbarType] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [lineProgress, setLineProgress] = useState(false);
  const [checkedLogin, setCheckedLogin] = useState(false);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState({
    value: null,
  });
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [industryOptions, setIndustryOptions] = useState([]);
  const [validationErrors, setValidationError] = useState({});
  const [phoneCodeOptions, setPhoneCodeOptions] = useState([]);
  const [invitationFormErrors, setInvitationFormErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });
  const [customerIdToDelete, setCustomerIdToDelete] = useState([]);
  const [invitationItem] = useState({
    first_name: '',
    last_name: '',
    company_id: '',
    phone_code: '',
    phone_number: '',
    email: '',
  });
  const [isOpenResendModal, setIsOpenResendModal] = useState(false);
  const [individualCustomers, setIndividualCustomers] = useState([]);
  const [invitationToDelete, setInvitationToDelete] = useState({
    id: null,
    industryId: null,
  });
  const [previewCustomerItem, setPreviewCustomerItem] = useState({});
  const [customerToDeleteId, setCustomerToDeleteId] = useState(null);
  const [customerIndustry, setCustomerIndustry] = useState({
    first_name: '',
    last_name: '',
    email: '',
    industry: { value: null },
    phone_code: { value: null },
    phone_number: null,
  });
  const [allIndustryCustomers, setAllIndustryCustomers] = useState([]);
  const [resendInvitationItem, setResendInvitationItem] = useState({});
  const [currentIndustry, setCurrentIndustry] = useState(IndividualUserIndustryGot);
  const [isAddCustomerModalOpen, setIsAddCustomerModalOpen] = useState(false);
  const [invitationOldIndustryId] = useState(null);
  const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] = useState(false);
  const [industryCustomerInvitations, setIndustryCustomerInvitations] = useState([]);
  const [isPreviewCustomerModalInfo, setIsPreviewCustomerModalInfo] = useState(false);
  const [selectedIndustriesToDeleteCustomer, setSelectedIndustriesToDeleteCustomer] = useState([]);
  const open = Boolean(anchorEl);

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);

  // Perform when function gets mounted
  useEffect(() => {
    setLoading(false);
    dispatch(getIndividualCustomersRequest());
    dispatch(getIndividualUserIndustriesRequest());
    const phoneCodeOptionsCopy = countryCodeOptions.getCodeOptions(allCountries);
    setPhoneCodeOptions(phoneCodeOptionsCopy);
  }, []);

  // Handle get All Industry success
  useEffect(() => {
    if (prevIsGetIndividualUserIndustriesSuccess === false && isGetIndividualUserIndustriesSuccess) {
      const industryID = match.params.id;
      const industryOptions = [];
      allIndividualUserIndustries.map((industry) => industryOptions.push({
        value: industry.id,
        label: industry.industry_name,
      }));
      setIndustryOptions(industryOptions);
      setCurrentIndustry(parseInt(industryID));
      dispatch(getIndividualUserIndustryByIdRequest(parseInt(industryID)));
    }
  }, [isGetIndividualUserIndustriesSuccess]);

  // Handle get All Industry error
  useEffect(() => {
    if (prevIsGetIndividualUserIndustriesError === false && isGetIndividualUserIndustriesError) {
      snackBarAlert(true, i18n.t('InvalidData'), 'error');
      setLoading(false);
    }
  }, [isGetIndividualUserIndustriesError]);

  // Handle Get individual industry by ID success
  useEffect(() => {
    if (prevIsGetIndividualUserIndustryByIdSuccess === false && isGetIndividualUserIndustryByIdSuccess) {
      const IndividualUserIndustryGotCopy = { ...IndividualUserIndustryGot };
      setCurrentIndustry(IndividualUserIndustryGotCopy);
    }
  }, [isGetIndividualUserIndustryByIdSuccess]);

  // When a current Industry is not empty
  useEffect(() => {
    if (currentIndustry.id) {
      const currentIndustryCopy = { ...currentIndustry };
      const allCustomers = currentIndustryCopy.customers;
      const allInvitationCustomers = currentIndustryCopy.customerInvitations;
      const mergedCustomer = [...allCustomers, ...allInvitationCustomers];
      const sortedCustomer = mergedCustomer.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllIndustryCustomers(sortedCustomer);
      setLoading(false);
    }
  }, [currentIndustry]);

  // Handle Get All Industry by ID error
  useEffect(() => {
    if (prevIsGetIndividualUserIndustryByIdError && isGetIndividualUserIndustryByIdError) {
      snackBarAlert(true, getIndividualUserIndustryErrorMessage.message, 'error');
    }
  }, [isGetIndividualUserIndustryByIdError]);

  // Handle create customer to Industry success
  useEffect(() => {
    if (prevIsStoreCustomerToIndustrySuccess === false && isStoreCustomerToIndustrySuccess) {
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      setAllIndustryCustomers(allIndustryCustomersCopy);
      const individualUserIndustryId = currentIndustry.id || customerItem.individual_user_industry_id;
      const [chosenIndustry] = allIndividualUserIndustries.filter((item) => item.id === individualUserIndustryId);
      allIndustryCustomersCopy.push({
        ...newCustomerForIndustry,
        individual_user_customer_invitation: [],
        pivot: {
          individual_user_industry_id: chosenIndustry.id,
        },
      });
      allIndustryCustomersCopy.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllIndustryCustomers(allIndustryCustomersCopy);
      chosenIndustry.customers.push({
        ...newCustomerForIndustry,
        individual_user_customer_invitation: [],
        pivot: {
          individual_user_industry_id: chosenIndustry.id,
        },
      });
      setLineProgress(false);
      setCustomerItem({
        email: '',
        first_name: '',
        last_name: '',
        company_id: '',
        phone_code: '',
        phone_number: '',
        individual_user_industry_id: '',
      });
      setCustomerIndustry({
        ...customerIndustry,
        industry: { value: null },
      });
      handleCloseAddCustomerModal();
      snackBarAlert(true, i18n.t('CustomerAdded'), 'success');
    }
  }, [isStoreCustomerToIndustrySuccess]);

  // Handle customer deleted
  useEffect(() => {
    if (prevIsDeleteCustomerFromIndustrySuccess === false && isDeleteCustomerFromIndustrySuccess) {
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      const customerIndex = allIndustryCustomersCopy.findIndex((item) => item.id === customerToDeleteId);
      allIndustryCustomersCopy.splice(customerIndex, 1);
      setAllIndustryCustomers(allIndustryCustomersCopy);
      setCustomerToDeleteId(null);
      setAlert(null);
      setAnchorEl(null);
      setLoading(false);
      setLineProgress(false);
      snackBarAlert(true, i18n.t('CustomerDeleted'), 'success');
    }
  }, [isDeleteCustomerFromIndustrySuccess]);

  useEffect(() => {
    if (prevIsGetAllCustomersSuccess === false && isGetAllCustomersSuccess) {
      setIndividualCustomers(allIndividualCustomers);
      dispatch(getAllIndividualCustomerInvitationsRequest());
      setLoading(false);
    }
  }, [isGetAllCustomersSuccess]);

  useEffect(() => {
    if (prevIsGetAllCustomersError === false && isGetAllCustomersError) {
      snackBarAlert(true, i18n.t('InvalidData'), 'error');
      setLoading(false);
    }
  }, [isGetAllCustomersError]);

  // Handle resend invitation success
  useEffect(() => {
    if (prevIsResendCustomerEmailSuccess === false && isResendCustomerEmailSuccess) {
      setIsOpenResendModal(false);
      setLineProgress(false);
      snackBarAlert(true, i18n.t('EmailResent'), 'success');
    }
  }, [isResendCustomerEmailSuccess]);

  useEffect(() => {
    if (prevIsGetAllIndividualInvitationsSuccess === false && isGetAllIndividualInvitationsSuccess) {
      setIndustryCustomerInvitations(allIndustriesCustomerInvitations);
      setLoading(false);
    }
  }, [isGetAllIndividualInvitationsSuccess]);

  useEffect(() => {
    if (prevIsGetAllIndividualInvitationsError === false && isGetAllIndividualInvitationsError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetAllIndividualInvitationsError]);

  // Handle get companies customer invitations error
  useEffect(() => {
    if (isGetAllCustomerInvitationsError) {
      setLoading(false);
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetAllCustomerInvitationsError]);

  useEffect(() => {
    if (prevIsInviteCustomerToIndustrySuccess === false && isInviteCustomerToIndustrySuccess) {
      if (inviteCustomerToIndustrySucceed.message && inviteCustomerToIndustrySucceed.message === 'existing_customer') {
        // User with invitation email already exists, ask user to confirm
        const { existingCustomer } = inviteCustomerToIndustrySucceed;
        const alert = getExistingCustomerAlert(existingCustomer);
        setAlert(alert);
      } else {
        const allIndustryCustomersCopy = [...allIndustryCustomers];
        const customerIndex = allIndustryCustomersCopy.findIndex((item) => item.id === newInvitation.customer_id);
        if (customerIndex !== -1) {
          allIndustryCustomersCopy[customerIndex].individual_user_customer_invitation.push(newInvitation);
        } else {
          allIndustryCustomersCopy.push({
            ...newInvitation,
            pivot: {
              individual_user_industry_id: currentIndustry.id,
            },
          });
        }
        allIndustryCustomersCopy.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
        setAllIndustryCustomers(allIndustryCustomersCopy);
        setLineProgress(false);
        setCustomerIndustry({
          ...customerIndustry,
          industry: { value: null },
        });
        handleCloseAddCustomerModal();
        setCustomerItem({
          email: '',
          first_name: '',
          last_name: '',
          company_id: '',
          phone_code: '',
          phone_number: '',
          individual_user_industry_id: '',
        });
        setCheckedLogin(false);
        setLineProgress(false);
        snackBarAlert(true, inviteCustomerToIndustrySucceed.message, 'success');
      }
    }
  }, [isInviteCustomerToIndustrySuccess]);

  useEffect(() => {
    if (prevIsInviteCustomerToIndustryError === false && isInviteCustomerToIndustryError) {
      snackBarAlert(true, inviteCustomerToIndustryErrors.message, 'error');
    }
  }, [isInviteCustomerToIndustryError]);

  useEffect(() => {
    if (prevIsDeleteIndividualUserInvitationSuccess === false && isDeleteIndividualUserInvitationSuccess) {
      const customerInvitationsCopy = [...industryCustomerInvitations];
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      const invitationIndustryIndex = allIndustryCustomersCopy.findIndex((item) => item.id === invitationToDelete.id);
      const customerIndex = allIndustryCustomers.findIndex((item) => item.id === invitationToDelete.customerId);
      if (customerIndex !== -1) {
        allIndustryCustomers[customerIndex].individual_user_customer_invitation.pop();
      } else {
        allIndustryCustomersCopy.splice(invitationIndustryIndex, 1);
      }
      setIndustryCustomerInvitations(customerInvitationsCopy);
      setAllIndustryCustomers(allIndustryCustomersCopy);
      setAlert(null);
      setInvitationToDelete({
        id: null,
        industryId: null,
      });
      snackBarAlert(true, i18n.t('InvitationDeleted', 'success'));
      setLoading(false);
      setAnchorEm(false);
    }
  }, [isDeleteIndividualUserInvitationSuccess]);

  useEffect(() => {
    if (prevIsDeleteIndividualUserInvitationError === false && isDeleteIndividualUserInvitationError) {
      setInvitationToDelete({
        id: null,
        industryId: null,
      });
      setLoading(false);
      snackBarAlert(true, deleteIndividualUserInvitationErrorMessage, 'error');
    }
  }, [isDeleteIndividualUserInvitationError]);

  useEffect(() => {
    if (prevIsUpdateIndividualUserInvitationSuccess === false && isUpdateIndividualUserInvitationSuccess) {
      if (
        updateIndividualUserInvitationSucceed
        && updateIndividualUserInvitationSucceed.message
        && updateIndividualUserInvitationSucceed.message === 'existing_customer'
      ) {
        const { existingCustomer } = updateIndividualUserInvitationSucceed;
        const alert = getExistingCustomerAlert(existingCustomer, true);
        setAlert(alert);
      } else {
        const industryInvitationsCopy = [...industryCustomerInvitations];
        const industryIndex = industryInvitationsCopy.findIndex(
          (item) => item.id === updatedIndividualUserInvitation.industry_id,
        );
        const invitationIndex = industryInvitationsCopy[industryIndex].customerInvitations.findIndex(
          (item) => item.id === updatedIndividualUserInvitation.id,
        );
        if (invitationIndex !== -1) {
          industryInvitationsCopy[industryIndex].customerInvitations[invitationIndex] = updatedIndividualUserInvitation;
        } else {
          const oldIndustryIndex = industryInvitationsCopy.findIndex(
            (item) => item.id === invitationOldIndustryId,
          );
          const oldInvitationIndex = industryInvitationsCopy[oldIndustryIndex].customerInvitations.findIndex(
            (item) => item.id === updatedIndividualUserInvitation.id,
          );
          industryInvitationsCopy[oldIndustryIndex].customerInvitations.splice(oldInvitationIndex, 1);
          industryInvitationsCopy[industryIndex].customerInvitations.push(updatedIndividualUserInvitation);
        }
        setIndustryCustomerInvitations(industryInvitationsCopy);
        setCustomerIndustry({
          first_name: '',
          last_name: '',
          email: '',
          industry: { value: null },
          phone_code: { value: null },
          phone_number: null,
        });
        snackBarAlert(true, i18n.t('InvitationUpdated'), 'success');
      }
    }
  }, [isUpdateIndividualUserInvitationSuccess]);

  useEffect(() => {
    if (prevIsUpdateIndividualUserInvitationError === false && isUpdateIndividualUserInvitationError) {
      snackBarAlert(true, updateIndividualUserInvitationErrorMessage, 'error');
    }
  }, [isUpdateIndividualUserInvitationError]);

  useEffect(() => {
    if (prevIsDeleteIndividualCustomerSuccess === false && isDeleteIndividualCustomerSuccess) {
      const individualCustomersCopy = [...individualCustomers];
      const customerIndex = individualCustomersCopy.findIndex((item) => item.id === customerIdToDelete);
      let industries = JSON.parse(individualCustomers[customerIndex].industries);
      const industryIds = industries.map((item) => item.id);
      if (JSON.stringify(industryIds) === JSON.stringify(selectedIndustriesToDeleteCustomer)) {
        individualCustomersCopy.splice(customerIndex, 1);
        setIndividualCustomers(individualCustomersCopy);
      } else {
        industries = industries.filter((item) => !selectedIndustriesToDeleteCustomer.includes(item.id));
        individualCustomersCopy[customerIndex].industries = JSON.stringify(industries);
        setIndividualCustomers(individualCustomersCopy);
      }
      setSelectedIndustriesToDeleteCustomer([]);
      setCustomerIdToDelete(null);
      setLoading(false);
      setAnchorEl(null);
      snackBarAlert(true, i18n.t('CustomerDeleted'), 'success');
    }
  }, [isDeleteIndividualCustomerSuccess]);

  useEffect(() => {
    if (prevIsDeleteIndividualCustomerError === false && isDeleteIndividualCustomerError) {
      setLoading(false);
      snackBarAlert(true, deleteIndividualCustomerErrorMessage, 'error');
    }
  }, [isDeleteIndividualCustomerError]);

  // Handle update customer success
  useEffect(() => {
    if (prevIsUpdateIndividualCustomerSuccess === false && isUpdateIndividualCustomerSuccess) {
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      const updateCustomerSucceedCopy = { ...updatedIndividualCustomer };
      const customerIndex = allIndustryCustomersCopy.findIndex((item) => item.id === updateCustomerSucceedCopy.id);
      const updatedCustomerInfo = {
        ...updateCustomerSucceedCopy,
        individual_user_customer_invitation: [],
        pivot: {
          individual_user_industry_id: allIndustryCustomersCopy[customerIndex].pivot.individual_user_industry_id,
        },
        email: null,
      };
      allIndustryCustomersCopy.splice(customerIndex, 1, updatedCustomerInfo);
      allIndustryCustomersCopy.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllIndustryCustomers(allIndustryCustomersCopy);
      setSelectedPhoneCode({
        value: '',
      });
      setCustomerItem({});
      setIsEditCustomerModalOpen(false);
      setAnchorEl(null);
      setAnchorEm(null);
      snackBarAlert(true, i18n.t('CustomerUpdated'), 'success');
    }
  }, [isUpdateIndividualCustomerSuccess]);

  // Handle update customer error
  useEffect(() => {
    if (prevIsUpdateIndividualCustomerError === false && isUpdateIndividualCustomerError) {
      setIsEditCustomerModalOpen(false);
      snackBarAlert(true, updateIndividualCustomerErrorMessage.message, 'error');
    }
  }, [isUpdateIndividualCustomerError]);

  // Handle customer filter success
  useEffect(() => {
    if (prevIsFilterIndividualCustomerByStatusSuccess === false && isFilterIndividualCustomerByStatusSuccess) {
      const allIndividualUserIndustriesCopy = [...allIndividualUserIndustries];
      const customerInvitationsCopy = allIndividualUserIndustriesCopy[0].customerInvitations;
      const customersCopy = allIndividualUserIndustriesCopy[0].customers;
      const allCustomers = [...customerInvitationsCopy, ...customersCopy];
      const filteredIndividualCustomerByStatusCopy = [...filteredIndividualCustomerByStatus.customers];
      const activeIds = filteredIndividualCustomerByStatusCopy.map((item) => item.customer_id || item.id);
      const allCustomersAfterFilter = allCustomers.filter((item) => activeIds.indexOf(item.id) !== -1);
      allCustomersAfterFilter.sort((left, right) => moment.utc(right.created_at).diff(moment.utc(left.created_at)));
      setAllIndustryCustomers(allCustomersAfterFilter);
    }
  }, [isFilterIndividualCustomerByStatusSuccess]);

  // Handle customer filter error
  useEffect(() => {
    if (prevIsFilterIndividualCustomerByStatusError === false && isFilterIndividualCustomerByStatusError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isFilterIndividualCustomerByStatusError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const getExistingCustomerAlert = (existingCustomer, isGettingUpdated = false) => (
    <Dialog
      open={() => ({})}
      onClose={() => setAlert(null)}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => setAlert(null)}>
        <span className="alert-title">
          <span>Existing user</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={() => setAlert(null)}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom> Please make sure this is the customer you want to invite: </Typography>
        <Typography gutterBottom> Name: <Link to="#" onClick={(e) => e.preventDefault()}>{existingCustomer.first_name} {existingCustomer.last_name}</Link></Typography>
        <Typography gutterBottom> Email: <Link to="#" onClick={(e) => e.preventDefault()}>{existingCustomer.email}</Link></Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          data-cy="cancel"
          type="button"
          onClick={() => setAlert(null)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          size="small"
          color="primary"
          data-cy="delete"
          type="button"
          onClick={() => isGettingUpdated ? handleUpdateCustomerInvitation(true) : handleCustomerInvite(true)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleSelectIndustryForCustomer = (event) => {
    const industryId = parseInt(event.target.value);
    if (industryId) {
      const industry = allIndividualUserIndustries.find((item) => item.id === industryId);
      setCustomerIndustry({
        ...customerIndustry,
        industry: {
          label: industry.industry_name,
          value: industry.id,
        },
      });
      setCustomerItem({
        ...customerItem,
        individual_user_industry_id: industry.id,
      });
    } else {
      setCustomerIndustry({
        ...customerIndustry,
        industry: {
          label: '',
          value: '',
        },
      });
    }
  };

  const handleCustomerInvite = (existingCustomer = false) => {
    const data = { ...customerItem };
    if (existingCustomer === true) {
      setAlert(null);
      data.is_confirmed = true;
    }
    data.individual_user_industry_id = customerItem.individual_user_industry_id !== '' ? customerItem.individual_user_industry_id : currentIndustry.id;
    data.phone_code = customerItem.phone_code;
    data.phone_number = customerItem.phone_number === null ? '' : customerItem.phone_number;
    data.email = customerItem.email === '' ? '' : customerItem.email;
    delete data.industry;
    setValidationError({});
    if (validateInvitation(data)) {
      dispatch(inviteCustomerToIndustryRequest(data));
    }
  };

  const handleUpdateCustomerInvitation = (existingCustomer = false) => {
    const data = { ...customerIndustry };
    if (userAccount.email === data.email) {
      snackBarAlert(true, i18n.t('CantInviteYourSelf'), 'error');
      return false;
    }
    if (existingCustomer === true) {
      setAlert(null);
      data.is_confirmed = true;
    }
    data.industry_id = customerIndustry.industry.value;
    data.phone_code = customerIndustry.phone_code.value;
    data.phone_number = customerIndustry.phone_number === null ? '' : customerIndustry.phone_number;
    data.email = customerIndustry.email === '' ? '' : customerIndustry.email;
    delete data.industry;
    setValidationError({});
    if (validateInvitation(data)) {
      dispatch(updateIndividualUserInvitationRequest(data));
    } else if (existingCustomer === true) {
      setAlert(null);
    }
  };

  const validateInvitation = (data) => {
    const {
      first_name,
      last_name,
      email,
      industry_id,
      phone_code,
      phone_number,
    } = data;
    const errors = {
      firstName: first_name.length === 0 ? 'First name is required' : null,
      lastName: last_name.length === 0 ? 'Last name is required' : null,
      email: email === '' ? 'Email is required' : null,
      industry: industry_id === '' ? 'Industry is required' : null,
      phone_code: phone_code === null ? 'Phone Code is required' : null,
      phone_number: phone_number === '' ? 'Phone number is required' : null,
    };
    setValidationError(errors);
    return Object.values(errors).filter((error) => (error !== null)).length === 0;
  };

  const handleConfirmDeleteInvitation = (customer) => {
    if (customer.individual_user_customer_invitation && customer.individual_user_customer_invitation.length) {
      dispatch(deleteIndividualUserInvitationRequest({ id: customer.individual_user_customer_invitation[0].id }));
      setInvitationToDelete({
        id: customer.individual_user_customer_invitation[0].id,
        industryId: customer.individual_user_customer_invitation[0].industry_id,
        customerId: customer.individual_user_customer_invitation[0].customer_id,
      });
    } else {
      dispatch(deleteIndividualUserInvitationRequest({ id: customer.id }));
      setInvitationToDelete({
        id: customer.id,
        industryId: customer.industry_id,
      });
    }
    setLoading(true);
  };

  const handleSelectChangeForCode = (event) => {
    const codeId = parseInt(event.target.value);
    const phone = phoneCodeOptions.find((item) => parseInt(item.value) === codeId);
    setSelectedPhoneCode({
      value: phone.value,
    });
    setCustomerItem({
      ...customerItem,
      phone_code: phone.value,
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateCustomer = () => {
    if (validateCreateForm()) {
      setInvitationFormErrors({});
      const allIndustryCustomersCopy = [...allIndustryCustomers];
      const currentCustomer = allIndustryCustomersCopy.find((item) => item.id === customerItem.id);
      const customerUpdateInfo = {
        individual_user_industry_id: currentCustomer.pivot.individual_user_industry_id,
        id: customerItem.id,
        first_name: customerItem.first_name,
        last_name: customerItem.last_name,
        phone_number: customerItem.phone_number,
        phone_code: customerItem.phone_code,
        full_phone_number: (`${customerItem.phone_code} ${customerItem.phone_number}`).replace(/\D/g, ''),
      };
      dispatch(updateIndividualCustomerRequest(customerUpdateInfo));
    }
  };

  const handleCloseAddCustomerModal = useCallback(() => {
    setCustomerItem({
      email: '',
      first_name: '',
      last_name: '',
      company_id: '',
      phone_code: '',
      phone_number: '',
      individual_user_industry_id: '',
    });
    setValidationError({});
    setCheckedLogin(false);
    setIsAddCustomerModalOpen(false);
    setSelectedPhoneCode({ value: null });
    setAddCustomerModalOpen();
    setCustomerIndustry({
      ...customerIndustry,
      industry: { value: null },
    });
  }, []);

  const handleInputChange = useCallback((event) => {
    const { name, value } = event.target;
    setCustomerItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }, [invitationItem]);

  const validateCreateForm = (e) => {
    const errors = {
      first_name: e ? null : !customerItem.first_name.length ? 'First name is required' : null,
      last_name: e ? null : !customerItem.last_name.length ? 'Last name is required' : null,
      individual_user_industry_id: e ? null : currentIndustry.id ? null : !customerItem.individual_user_industry_id ? 'Industry is required' : null,
      email: e ? null : checkedLogin ? !customerItem.email.length ? 'Email is required' : null : null,
      phone_number: e ? null : (customerItem.phone_number === '' && !customerItem.phone_number.length)
        ? 'Phone number is required' : customerItem.phone_number.length < 12 ? 'Phone number is not valid' : null,
      phone_code: e ? null : (selectedPhoneCode && selectedPhoneCode.value === null && customerItem.phone_code === ''
        && !customerItem.phone_code.length) ? 'Code is required' : null,
    };
    setValidationError(errors);
    setInvitationFormErrors(errors);
    return Object.values(errors)
      .filter((error) => (error !== null)).length === 0;
  };

  const handleInviteCustomerFormSubmit = (checkedLogin, existingCustomer = false) => {
    if (validateCreateForm()) {
      const data = { ...customerItem };
      if (userAccount.email === data.email) {
        return false;
      }
      if (existingCustomer === true) {
        setAlert(null);
        data.is_confirmed = true;
      }
      data.id = null;
      data.individual_user_industry_id = customerItem.individual_user_industry_id !== '' ? customerItem.individual_user_industry_id : currentIndustry.id;
      data.phone_code = customerItem.phone_code;
      data.phone_number = customerItem.phone_number === '' ? null : customerItem.phone_number;
      data.email = customerItem.email === '' ? null : customerItem.email;
      data.customer_id = customerItem.id || null;
      setInvitationFormErrors({
        firstName: false,
        lastName: false,
        email: false,
      });
      setLineProgress(true);
      dispatch(inviteCustomerToIndustryRequest(data));
    } else if (existingCustomer === true) {
      setAlert(null);
    }
  };

  const handleCreateCustomerFormSubmit = (checkedLogin) => {
    if (validateCreateForm(checkedLogin)) {
      const data = { ...customerItem };
      data.individual_user_industry_id = currentIndustry.id || customerItem.individual_user_industry_id;
      data.phone_code = customerItem.phone_code;
      data.phone_number = customerItem.phone_number === '' ? null : customerItem.phone_number;
      data.email = null;
      data.full_phone_number = (`${data.phone_code} ${data.phone_number}`).replace(/\D/g, '');
      dispatch(storeCustomerToIndustryRequest(data));
    }
  };

  const handleChangeCustomerLogin = (event) => {
    setCheckedLogin(event.target.checked);
  };

  const handleConfirmDeleteCustomer = (customer) => {
    setLoading(true);
    setCustomerToDeleteId(customer.id);
    dispatch(deleteCustomerFromIndustryRequest({
      customer_id: customer.id,
      individual_user_industry_id: customer.pivot.individual_user_industry_id,
    }));
  };

  const showConfirmDeleteAlertContent = (e, customer, isCustomer) => {
    e.preventDefault();
    setAnchorEm(false);
    setAnchorEl(false);
    const alert = (
      <Dialog
        open={() => ({})}
        onClose={() => setAlert(null)}
      >
        <DialogTitle id="customized-dialog-title" onClose={() => setAlert(null)}>
          <span className="alert-title">
            <span>{i18n.t('ConfirmDeletion')}</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => setAlert(null)}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography>{i18n.t('AreYouSureDelete')} {isCustomer ? 'customer' : 'invitation'} ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => setAlert(null)}
          >
            {i18n.t('Cancel')}
          </Button>
          <DeleteButton
            size="small"
            variant="contained"
            color="primary"
            onClick={() => isCustomer ? handleConfirmDeleteCustomer(customer) : handleConfirmDeleteInvitation(customer)}
          >
            {i18n.t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
    );
    setAlert(alert);
  };

  const handleClickMenu = (event, customer, created) => {
    setAnchorEm(event.currentTarget);
    const resendInvitationItemCopy = {
      id: created ? customer.individual_user_customer_invitation[0].id : customer.id,
      email: created ? customer.individual_user_customer_invitation[0].email : customer.email,
    };
    setResendInvitationItem(resendInvitationItemCopy);
  };

  const handleCloseMenu = () => {
    setAnchorEm(null);
  };

  const handleOpenResendModal = () => {
    setAnchorEm(false);
    setIsOpenResendModal(true);
  };

  const handleCloseResendInvitationModal = () => {
    setIsOpenResendModal(false);
  };

  const handleResendCustomerInvitation = (resendInvitationItem) => {
    dispatch(resendCustomerEmailRequest(resendInvitationItem));
    setAnchorEm(null);
    setLineProgress(true);
  };

  const handleInviteCustomerModal = (event, id) => {
    event.preventDefault();
    const [customerItem] = currentIndustry.customers.filter((item) => item.id === id);
    setCustomerItem({
      id: customerItem.id,
      first_name: customerItem.first_name,
      last_name: customerItem.last_name,
      phone_code: customerItem.phone_code,
      phone_number: customerItem.phone_number,
      email: customerItem.email || '',
      individual_user_industry_id: currentIndustry.id,
    });
    setIsAddCustomerModalOpen(true);
    setCheckedLogin(true);
    setAnchorEl(null);
  };

  // Customer Preview
  const handlePreviewCustomerInfo = (id) => {
    const allIndustryCustomersCopy = [...allIndustryCustomers];
    const customerItem = allIndustryCustomersCopy.find((item) => item.id === id);
    const allStaffAppointments = [];
    const appointments = allStaffAppointments.filter((item) => item.customer_id === id);
    setPreviewCustomerItem({
      ...customerItem,
      email: customerItem.email || (customerItem.individual_user_customer_invitation.length ? customerItem.individual_user_customer_invitation[0].email : null),
      appointments,
    });
    setIsPreviewCustomerModalInfo(true);
    setTabValue(0);
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setTabValue(index);
  };

  const handleOpenEditCustomerModal = (event, customerID) => {
    event.preventDefault();
    setAnchorEl(null);
    const allIndustryCustomersCopy = [...allIndustryCustomers];
    const customerItem = allIndustryCustomersCopy.find((item) => item.id === customerID);
    setCustomerItem({
      id: customerItem.id,
      first_name: customerItem.first_name,
      last_name: customerItem.last_name,
      phone_code: customerItem.phone_code,
      phone_number: customerItem.phone_number,
    });
    setSelectedPhoneCode({
      value: customerItem.phone_code,
    });
    setInvitationFormErrors({});
    setIsEditCustomerModalOpen(true);
    setCheckedLogin(false);
  };

  const handleCloseEditModal = () => {
    setIsEditCustomerModalOpen(false);
  };

  const handleEditCustomerItemChange = useCallback((event) => {
    const { name, value } = event.target;
    setCustomerItem((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  }, [customerItem]);

  return !loading ? (
    <div className="menu-item" id="customers">
      <div className="general-content">
        <Box display="flex" flexWrap="wrap" justifyContent="flex-start" width="100%">
          <Tooltip title="New Customer">
            <Card className="card-plus-box" onClick={() => setIsAddCustomerModalOpen(true)}>
              <CardMedia className="img-header" />
            </Card>
          </Tooltip>
          {allIndustryCustomers.length ? allIndustryCustomers.map((item, index) => (
            // Invited Customer
            item.customer_id === null
              ? (
                <span key={item.id}>
                  <Box item m="15px" maxWidth="220px">
                    <Card className="card-box-root" key={index}>
                      <CardHeader
                        className="card-header-service"
                        action={(
                          <CustomerIsolatedDropdown
                            customerInvitation={item}
                            showConfirmDeleteAlertContent={showConfirmDeleteAlertContent}
                          />
                          )}
                        title={(
                          <LightTooltip
                            title={`${item.first_name} ${item.last_name}`}
                            placement="top-start"
                          >
                            <span>
                              {`${item.first_name} ${item.last_name}`}
                            </span>
                          </LightTooltip>
                          )}
                      />
                      <CardMedia className="img-header" onClick={() => handlePreviewCustomerInfo(item.id)}>
                        {item && item.avatar ? (
                          <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                        ) : (
                          <Avatar variant="circle" className="card-box-img card-box-letters">
                            {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                          </Avatar>
                        )}
                      </CardMedia>
                      <CardContent className="content-card-box">
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="invited-menu-btn"
                          onClick={(e) => handleClickMenu(e, item)}
                          endIcon={<ExpandMoreIcon />}
                        >
                              Invited
                        </Button>
                        <StyledMenu
                          id="customized-menu"
                          anchorEl={anchorEm}
                          keepMounted
                          open={Boolean(anchorEm)}
                          onClose={handleCloseMenu}
                        >
                          <StyledMenuItem onClick={(e) => showConfirmDeleteAlertContent(e, item, false)}>
                            <ListItemText primary="Cancel" />
                          </StyledMenuItem>
                          <StyledMenuItem onClick={(e) => handleOpenResendModal(e, item)}>
                            <ListItemText primary="Resend" />
                          </StyledMenuItem>
                        </StyledMenu>
                      </CardContent>
                    </Card>
                  </Box>
                </span>
              )
              // Registered Customer
              : item.email_verified_at
                ? (
                  <span key={item.id}>
                    <Box
                      item
                      m="15px"
                      maxWidth="220px"
                      key={`${item.id}-${index}`}
                      className="customer-box"
                    >
                      <Card className="card-box-root">
                        <CardHeader
                          className="card-header-service"
                          action={(
                            <div>
                              <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id="long-menu"
                                elevation={1}
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                                PaperProps={{
                                  style: {
                                    width: '20ch',
                                  },
                                }}
                              >
                                <Link
                                  data-cy="delete-customer"
                                  to="/"
                                  className="card-box-item"
                                  onClick={(e) => showConfirmDeleteAlertContent(e, item.id, true)}
                                >
                                  <MenuItem>
                                      Delete
                                  </MenuItem>
                                </Link>
                              </Menu>
                            </div>
                            )}
                          title={(
                            <LightTooltip title={`${item.first_name} ${item.last_name}`} placement="top-start">
                              <span>
                                {`${item.first_name} ${item.last_name}`}
                              </span>
                            </LightTooltip>
                            )}
                        />
                        <Box>
                          <CardMedia className="img-header" onClick={() => handlePreviewCustomerInfo(item.id)}>
                            {item && item.avatar ? (
                              <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                            ) : (
                              <Badge
                                color="secondary"
                                overlap="circle"
                                className="avatar-badge"
                                badgeContent={item.email_verified_at ? <CheckCircleOutlineIcon /> : ''}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                              >
                                <Avatar variant="circle" className="card-box-img card-box-letters">
                                  {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                </Avatar>
                              </Badge>
                            )}
                          </CardMedia>
                          <CardContent className="content-card-box" />
                        </Box>
                      </Card>
                    </Box>
                  </span>
                )
              // Added Customer
                : !item.individual_user_customer_invitation.length
                  ? (
                    <span>
                      <Box
                        item
                        m="15px"
                        maxWidth="220px"
                        key={`${item.id}-${index}`}
                        className="customer-box"
                      >
                        <Card className="card-box-root">
                          <CardHeader
                            className="card-header-service"
                            action={(
                              <CustomerDropdownMenu
                                customer={item}
                                openEditInviteCustomerModal={handleOpenEditCustomerModal}
                                showConfirmDeleteAlertContent={showConfirmDeleteAlertContent}
                                isUpdateIndividualCustomerSuccess={isUpdateIndividualCustomerSuccess}
                              />
                              )}
                            title={(
                              <LightTooltip title={`${item.first_name} ${item.last_name}`} placement="top-start">
                                <span>
                                  {`${item.first_name} ${item.last_name}`}
                                </span>
                              </LightTooltip>
                              )}
                          />
                          <Box>
                            <CardMedia className="img-header" onClick={() => handlePreviewCustomerInfo(item.id)}>
                              {item && item.avatar ? (
                                <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                              ) : (
                                <Avatar variant="circle" className="card-box-img card-box-letters">
                                  {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                </Avatar>
                              )}
                            </CardMedia>
                            <CardContent className="content-card-box">
                              {!item.email
                                ? (
                                  <Button
                                    color="primary"
                                    size="small"
                                    className="invite-btn"
                                    onClick={(e) => handleInviteCustomerModal(e, item.id)}
                                  >
                                      Invite
                                  </Button>
                                )
                                : (
                                  <Typography className="registered-text">
                                      Registered
                                  </Typography>
                                )}
                            </CardContent>
                          </Box>
                        </Card>
                      </Box>
                    </span>
                  )
                // Customer with invitations
                  : item.individual_user_customer_invitation.length
                    ? (
                      <span>
                        <Box item m="15px" maxWidth="220px">
                          <Card className="card-box-root" key={index}>
                            <CardHeader
                              className="card-header-service"
                              action={(
                                <CustomerIsolatedDropdown
                                  anchorEl={anchorEl}
                                  customerInvitation={item}
                                  showConfirmDeleteAlertContent={showConfirmDeleteAlertContent}
                                />
                                )}
                              title={(
                                <LightTooltip
                                  title={`${item.first_name} ${item.last_name}`}
                                  placement="top-start"
                                >
                                  <span>
                                    {`${item.first_name} ${item.last_name}`}
                                  </span>
                                </LightTooltip>
                                )}
                            />
                            <CardMedia className="img-header" onClick={() => handlePreviewCustomerInfo(item.id)}>
                              {item && item.avatar ? (
                                <Avatar className="card-box-avatar" alt="avatar" src={item.avatar} />
                              ) : (
                                <Avatar variant="circle" className="card-box-img card-box-letters">
                                  {firstLetter(item.first_name ? item.first_name : '', item.last_name ? item.last_name : '')}
                                </Avatar>
                              )}
                            </CardMedia>
                            <CardContent className="content-card-box">
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className="invited-menu-btn"
                                onClick={(e) => handleClickMenu(e, item, 'created')}
                                endIcon={<ExpandMoreIcon />}
                              >
                                  Invited
                              </Button>
                              <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEm}
                                keepMounted
                                open={Boolean(anchorEm)}
                                onClose={handleCloseMenu}
                              >
                                <StyledMenuItem onClick={(e) => showConfirmDeleteAlertContent(e, item, false)}>
                                  <ListItemText primary="Cancel" />
                                </StyledMenuItem>
                                <StyledMenuItem onClick={(e) => handleOpenResendModal(e, item)}>
                                  <ListItemText primary="Resend" />
                                </StyledMenuItem>
                              </StyledMenu>
                            </CardContent>
                          </Card>
                        </Box>
                      </span>
                    )
                    : null
          ))
            : (
              <Box className="customers-page" mx="auto">
                <Box>
                  <Box>
                    <img src={noCustomers} alt="noCustomer" />
                  </Box>
                  <Typography component="div">
                    <Box fontWeight="500" align="center">
                      <Typography variant="h6" color="secondary">
                          You don&apos;t have any customers yet
                      </Typography>
                    </Box>
                  </Typography>
                </Box>
              </Box>
            )}
        </Box>
      </div>
      {/* Resend Invitation */}
      <Dialog
        size="sm"
        open={isOpenResendModal}
        onClose={handleCloseResendInvitationModal}
      >
        <DialogTitle>
          <span className="alert-title">
            <span>Resend Invite</span>
            <span>
              <IconButton aria-label="close" className="close-btn" onClick={handleCloseResendInvitationModal}>
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          {!lineProgress
            ? (
              <Grid
                container
                spacing={2}
                direction="row"
                alignItems="center"
              >
                <Grid item xs={12}>
                  <span className="invite-staff-text">
                      Resend Invite to
                    <span className="invite-staff-text-name"> {resendInvitationItem.email} </span> ?
                  </span>
                </Grid>
              </Grid>
            ) : <LinearProgress />}
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={handleCloseResendInvitationModal}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={(() => handleResendCustomerInvitation(resendInvitationItem))}
          >
            Resend
          </Button>
        </DialogActions>
      </Dialog>
      {/* Edit Customer */}
      <Dialog
        size="sm"
        open={isEditCustomerModalOpen}
        onClose={handleCloseEditModal}
      >
        <DialogTitle>
          <span className="alert-title">
            <span>{i18n.t('EditCustomer')}</span>
            <span>
              <IconButton aria-label="close" className="close-btn" onClick={handleCloseEditModal}>
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!invitationFormErrors.firstName)}
              >
                <TextField
                  fullWidth
                  error={(!!invitationFormErrors.firstName)}
                  size="small"
                  label="First name"
                  data-cy="firstName"
                  data-customer="first_name"
                  variant="outlined"
                  type="text"
                  name="first_name"
                  value={customerItem.first_name}
                  onChange={handleEditCustomerItemChange}
                  inputProps={{ maxLength: 20 }}
                />

                {invitationFormErrors.firstName && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {invitationFormErrors.firstName}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!invitationFormErrors.lastName)}
              >
                <TextField
                  fullWidth
                  error={(!!invitationFormErrors.lastName)}
                  size="small"
                  label="Last name"
                  data-cy="lastName"
                  data-customer="last_name"
                  id="outlined-size-normal"
                  variant="outlined"
                  type="text"
                  name="last_name"
                  value={customerItem.last_name}
                  onChange={handleEditCustomerItemChange}
                  inputProps={{ maxLength: 20 }}
                />

                {invitationFormErrors.lastName && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {invitationFormErrors.lastName}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!invitationFormErrors.phone_code)}
              >
                <InputLabel>Code *</InputLabel>
                <Select
                  name="phone_code"
                  label="Code *"
                  onChange={handleSelectChangeForCode}
                  value={selectedPhoneCode.value || customerItem.phone_code}
                >
                  <MenuItem disabled value=""><em>{i18n.t('PhoneCode')}</em></MenuItem>
                  {phoneCodeOptions && phoneCodeOptions.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.value}
                      selected={selectedPhoneCode.value === option.value}
                    >
                      <img
                        src={option.label.flagImageSrc}
                        alt="flag"
                        className="country-flag"
                      />
                      {option.key}
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>

                {invitationFormErrors.phone_code && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {invitationFormErrors.phone_code}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!invitationFormErrors.phone_number)}
              >
                <TextField
                  fullWidth
                  error={(!!invitationFormErrors.phone_number)}
                  size="small"
                  data-cy="phone"
                  id="phone-detail"
                  data-customer="phone_number"
                  type="text"
                  name="phone_number"
                  value={customerItem.phone_number}
                  onChange={handleEditCustomerItemChange}
                  label="Phone number *"
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />

                {invitationFormErrors.phone_number && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {invitationFormErrors.phone_number}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            data-cy="cancel"
            onClick={handleCloseEditModal}
          >
            Cancel
          </Button>
          <Button
            size="small"
            color="primary"
            variant="contained"
            data-cy="update"
            onClick={handleUpdateCustomer}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
      <AddCustomerModal
        customerItem={customerItem}
        checkedLogin={checkedLogin}
        lineProgress={lineProgress}
        invitationItem={invitationItem}
        industryOptions={industryOptions}
        currentIndustryId={currentIndustry.id}
        phoneCodeOptions={phoneCodeOptions}
        validationErrors={validationErrors}
        customerIndustry={customerIndustry}
        selectedPhoneCode={selectedPhoneCode}
        handleInputChange={handleInputChange}
        handleUpdateCustomer={handleUpdateCustomer}
        handleChangeCustomerLogin={handleChangeCustomerLogin}
        handleSelectChangeForCode={handleSelectChangeForCode}
        handleCloseAddCustomerModal={handleCloseAddCustomerModal}
        handleInviteCustomerFormSubmit={handleInviteCustomerFormSubmit}
        handleCreateCustomerFormSubmit={handleCreateCustomerFormSubmit}
        handleSelectIndustryForCustomer={handleSelectIndustryForCustomer}
        isAddCustomerModalOpen={isAddCustomerModalOpen || addCustomerModalOpen}
      />
      <PreviewCustomerModal
        theme={theme}
        tabValue={tabValue}
        a11yProps={a11yProps}
        tableAppHeader={tableAppHeader}
        handleChangeTab={handleChangeTab}
        handleChangeIndex={handleChangeIndex}
        previewCustomerItem={previewCustomerItem}
        isPreviewCustomerModalInfo={isPreviewCustomerModalInfo}
        setIsPreviewCustomerModalInfo={setIsPreviewCustomerModalInfo}
      />
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
      {alert}
    </div>
  ) : <BoxLoading />;
}

Customers.propTypes = {
  match: PropTypes.object.isRequired,
  addCustomerModalOpen: PropTypes.bool.isRequired,
  setAddCustomerModalOpen: PropTypes.func.isRequired,
};

export default Customers;
