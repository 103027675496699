import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import details from '../../../assets/img/details.svg';
import UserDetails from './details/userDetails';

function TopContent(props) {
  const {
    selectedAppointment,
    userRole,
  } = props;

  return selectedAppointment ? (
    <div>
      <div className="users-detail">
        { (userRole === 'customer' || userRole === 'individual') && selectedAppointment.company ? (
          <UserDetails
            firstName={selectedAppointment.staff.first_name}
            lastName={selectedAppointment.staff.last_name}
            moreDetail={selectedAppointment.company.name}
          />
        ) : (userRole === 'owner' || userRole === 'individual') && selectedAppointment.customer ? (
          <UserDetails
            firstName={selectedAppointment.customer.first_name}
            lastName={selectedAppointment.customer.last_name}
            moreDetail="customer"
          />
        ) : (userRole === 'owner' || userRole === 'individual') && selectedAppointment.individual_user_industry && !selectedAppointment.company ? (
          <UserDetails
            firstName={selectedAppointment.individual_user_industry.first_name}
            lastName={selectedAppointment.individual_user_industry.last_name}
            moreDetail="individual"
          />
        ) : (userRole === 'customer' || userRole === 'individual') && selectedAppointment.individual_user_industry ? (
          <UserDetails
            firstName={selectedAppointment.individual_user_industry.first_name}
            lastName={selectedAppointment.individual_user_industry.last_name}
            moreDetail="individual"
          />
        ) : null}
        <div className="details-icon">
          <Link to="">
            <img src={details} alt="details" />
          </Link>
        </div>
      </div>
    </div>
  ) : null;
}

TopContent.propTypes = {
  selectedAppointment: PropTypes.object.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default TopContent;
