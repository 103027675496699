import React from 'react';
import { Typography } from '@material-ui/core';

const PrivacyAndCookies = () => (
  <div>
    <Typography>
        Privacy page
    </Typography>
  </div>
);

export default PrivacyAndCookies;
