import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
// import FullCalendar from '@fullcalendar/react';
// import interactionPlugin from '@fullcalendar/interaction';
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from '@material-ui/core';
import TuneRoundedIcon from '@material-ui/icons/TuneRounded';
import CloseIcon from '@material-ui/icons/Close';
// import EditIcon from '@material-ui/icons/Edit';
// import BlockIcon from '@material-ui/icons/Block';
// import DeleteIcon from '@material-ui/icons/Delete';
import i18n from 'i18next';
import {
  cancelRequest,
  updateAppointmentRequest,
  deleteAppointmentRequest,
  getGeneralAppointmentsRequest,
  getUserRoleInAppointmentRequest,
} from '../../../../redux/appointment/actions';
import './index.scss';
import CreateNewAppointmentModal from '../../../../Modals/NewAppointment';
import AppointmentPreview from '../../../../Modals/AppointmentPreview';
import usePrevious from '../../../../CustomHooks/usePrevious';
import { resetNotificationsRequest } from '../../../../redux/account/actions';
import FilterGeneralCalendar from '../FilterGeneralCalendar';
import PermissionsModule from '../../../../Modules/Permission';
import CalendarLoading from '../../../../Components/Loading/calendarLoading';
// import LightTooltip from '../../../../MaterialComponents/lightTooltip';
import SnackbarToast from '../../../../Modules/SnackbarToast';

function Appointments(props) {
  const {
    cancel,
    userAccount,
    isCancelError,
    isCancelSuccess,
    deleteAppointment,
    resetNotifications,
    cancelErrorMessage,
    generalAppointments,
    updatedAppointment,
    userRoleInAppointment,
    getGeneralAppointments,
    isDeleteAppointmentError,
    getPersonalAppointments,
    // getUserRoleInAppointment,
    isUpdateAppointmentSuccess,
    isDeleteAppointmentSuccess,
    isGetGeneralAppointmentsSuccess,
    isGetUserRoleInAppointmentSuccess,
    deleteAppointmentErrorMessage,
  } = props;
  // Get some props previous values
  const prevIsGetGeneralAppointmentsSuccess = usePrevious(isGetGeneralAppointmentsSuccess);
  const prevIsDeleteAppointmentSuccess = usePrevious(isDeleteAppointmentSuccess);
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);
  const prevIsGetUserRoleInAppointmentSuccess = usePrevious(isGetUserRoleInAppointmentSuccess);

  const [allAppointments, setAllAppointments] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [isNewAppointmentModal, setIsNewAppointmentModal] = useState(false);
  const [isPreviewShown, setIsPreviewShown] = useState(false);
  const [appointmentItem, setAppointmentItem] = useState({});
  const [saveAppointment, setSaveAppointment] = useState(() => {});
  const [alert, setAlert] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastDraggedAppointment, setLastDraggedAppointment] = useState({
    id: null,
    eventToRemove: null,
  });
  const [checkedItems, setCheckedItems] = useState({});
  const [, setSelectedRole] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // get all appointments by checked items when component mounted
  useEffect(() => {
    setNotifications([...userAccount.notifications]);
    setLoading(true);
    getGeneralAppointments();
    const userRoles = PermissionsModule.getRolesWithId(userAccount.testPermissions);
    const roleIds = [];
    userRoles.map((item) => {
      if (!roleIds.includes(item.role_id.toString())) {
        roleIds.push(item.role_id.toString());
      }
    });
    setCheckedItems({ ...checkedItems, roles: roleIds });
  }, []);

  // Perform, when get general appointments success
  useEffect(() => {
    if (prevIsGetGeneralAppointmentsSuccess === false && isGetGeneralAppointmentsSuccess) {
      setAllAppointments(generalAppointments);
      const events = generalAppointments.map((item) => getEvent(item));
      setCalendarEvents(events);
      setLoading(false);
      scrollCalendarToCurrentTime();
    }
  }, [isGetGeneralAppointmentsSuccess]);

  // Handle Update Appointment Success
  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      const allAppointmentsCopy = [...allAppointments];
      const appointmentIndex = allAppointmentsCopy.findIndex((item) => item.id === updatedAppointment.id);
      allAppointmentsCopy.splice(appointmentIndex, 1);
      allAppointmentsCopy.push(updatedAppointment);
      setAllAppointments(allAppointmentsCopy);
      snackBarAlert(true, i18n.t('AppointmentRescheduled'), 'success');
      setLastDraggedAppointment(() => ({
        id: null,
        eventToRemove: null,
      }));
      setAlert(null);
    }
  }, [isUpdateAppointmentSuccess]);

  // Handle Delete Appointment Success
  useEffect(() => {
    if (prevIsDeleteAppointmentSuccess === false && isDeleteAppointmentSuccess) {
      const newAppointments = [...allAppointments];
      const appointmentIndex = newAppointments.findIndex((item) => item.id === parseInt(lastDraggedAppointment.id));
      newAppointments.splice(appointmentIndex, 1);
      const events = newAppointments.map((item) => getEvent(item));
      setCalendarEvents(events);
      setAllAppointments(newAppointments);
      setAlert(null);
      setLoading(null);
      snackBarAlert(true, i18n.t('AppointmentDeleted'), 'success');
    }
  }, [isDeleteAppointmentSuccess]);

  // Handle Delete Appointment Error
  useEffect(() => {
    if (isDeleteAppointmentError) {
      setAlert(null);
      setLoading(false);
      snackBarAlert(true, deleteAppointmentErrorMessage, 'error');
    }
  }, [isDeleteAppointmentError]);

  // Handle Cancel Appointment Success
  useEffect(() => {
    if (isCancelSuccess) {
      const allAppointmentsCopy = [...allAppointments];
      const index = allAppointmentsCopy.findIndex((item) => item.id === lastDraggedAppointment.id);
      allAppointmentsCopy[index].status_id = 3;
      setAllAppointments(allAppointmentsCopy);
      setLoading(false);
      setAlert(null);
      snackBarAlert(true, i18n.t('AppointmentCanceled'), 'success');
    }
  }, [isCancelSuccess]);

  // Handle Cancel Appointment Error
  useEffect(() => {
    if (isCancelError) {
      setLoading(false);
      setAlert(null);
      snackBarAlert(true, cancelErrorMessage, 'error');
    }
  }, [isCancelError]);

  // Perform, when get user role appointments success
  useEffect(() => {
    if (prevIsGetUserRoleInAppointmentSuccess === false && isGetUserRoleInAppointmentSuccess) {
      localStorage.setItem('prefix', userRoleInAppointment);
      setSelectedRole(userRoleInAppointment.toLowerCase());
    }
  }, [isGetUserRoleInAppointmentSuccess]);

  const getEvent = (appointment) => {
    const startTime = moment(appointment.start_time, 'LT').format('LT');
    const endTime = moment(appointment.end_time, 'LT').format('LT');
    const day = moment(appointment.date).format('Y-MM-DD');
    const startDate = new Date(`${day} ${startTime}`);
    const endDate = new Date(`${day} ${endTime}`);
    const status = appointment.status.name;

    return {
      id: appointment.id,
      title: appointment.title || appointment.note_from_customer || '',
      start: startDate,
      end: endDate,
      status,
      color: '#0282DA',
      textColor: '#000000',
      border: '0.5px solid #0078FF',
      backgroundColor: '#EBF4FF',
    };
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const selectAppointment = () => {
    setIsNewAppointmentModal(true);
  };

  const closeNewAppointmentModal = () => {
    setIsNewAppointmentModal(false);
  };

  // const renderEventTitle = (start, end, title, status) => (`
  //   <div class="d-flex align-items-center">
  //     <span class="status-color${status}" />
  //     <span class="role-color" />
  //     <span class="duration">${start} - ${end}</span>
  //     <span class="event-title">${title && (` - ${title}`)}</span>
  //   </div>
  // `);

  const scrollCalendarToCurrentTime = () => {
    const now = moment();
    const isPm = now.format('a') === 'pm';
    const hours = parseInt(now.format('hh')) + (isPm ? 12 : 0);
    const minutes = parseInt(now.format('mm'));
    // each hour block height - 72
    const height = (hours * 72) + (minutes / 3);
    const viewContainer = document.getElementsByClassName('fc-view-container');
    setTimeout(() => {
      viewContainer[0].scrollTop = height;
    }, 500);
  };

  // const eventRender = (event) => {
  //   const {
  //     event: eventApi,
  //     el: element,
  //   } = event;
  //   const start = moment(eventApi.start).format('hh:mm a');
  //   const end = moment(eventApi.end).format('hh:mm a');
  //   const prevTitle = eventApi.title;
  //   const { status } = eventApi.extendedProps;
  //   element.innerHTML = renderEventTitle(start, end, prevTitle, status);
  // };

  const onAppointmentCreated = (appointment) => {
    const event = getEvent(appointment);
    setCalendarEvents([
      ...calendarEvents,
      event,
    ]);
    setAllAppointments((prevValue) => ([...prevValue, appointment]));
  };

  const togglePreview = (show) => {
    setIsPreviewShown(show);
  };

  const getAppointmentItem = (appointmentItem, showPreview = false) => {
    setAppointmentItem(appointmentItem);
    togglePreview(showPreview);
  };

  const getSaveAppointment = (func) => {
    setSaveAppointment(() => func);
  };

  const handleSaveAppointment = () => {
    setIsPreviewShown(false);
    saveAppointment();
  };

  // Handle Appointment Drop To Same Appointments
  // const eventDrop = (newEvent) => {
  //   const { event } = newEvent;
  //   const id = parseInt(event.id);
  //   const startDay = moment(event.start).format('Y-MM-DD');
  //   const startTime = moment(event.start).format('HH:mm');
  //   const droppedAppointment = {
  //     id,
  //     eventToRemove: newEvent,
  //   };
  //   setLastDraggedAppointment(() => ({
  //     ...droppedAppointment,
  //   }));
  //   const appointment = allAppointments.find((item) => item.id === id);
  //   setAlert(handleShowRescheduleAppointmentAlert(appointment, startDay, startTime, newEvent, id));
  //   return false;
  // };

  // Handle Delete Appointment
  const handleDeleteAppointment = () => {
    const { id } = lastDraggedAppointment;
    deleteAppointment({ id });
  };

  // Handle Cancel Appointment
  const handleCancelAppointment = () => {
    resetNotificationsOnAction(lastDraggedAppointment.id);
    cancel(lastDraggedAppointment.id);
  };

  // Reset Notifications
  const resetNotificationsOnAction = () => {
    const notificationsCopy = [...notifications].filter((item) => (parseInt(item.appointment_id) !== parseInt(lastDraggedAppointment.id)));
    resetNotifications(notificationsCopy);
  };

  // handle change and filter checkboxes
  const handleChangeCheckboxes = (event) => {
    const { name, value } = event.target;
    const checkedFilterItems = checkedItems[name].includes(value)
      ? { ...checkedItems, [name]: checkedItems[name].filter((item) => item !== value) }
      : { ...checkedItems, [name]: [...checkedItems[name], value] };

    setCheckedItems({ ...checkedFilterItems });
  };

  // Handle calendar selection start
  // const selectAllow = (args) => {
  //   if (Date.parse(args.start) < Date.parse(new Date())) {
  //     return false;
  //   } else {
  //     const startDay = moment(args.startStr).format('Y-MM-DD');
  //     const startTime = moment(args.startStr).format('HH:mm');
  //     const endTime = moment(args.endStr).format('HH:mm');
  //     const hoursDiff = moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'hours');
  //     const minutesDiff = moment.utc(moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'))).format('mm');
  //     if (hoursDiff > 0 || minutesDiff > 0) {
  //       const appointmentItem = {
  //         day_time: args.startStr,
  //         date: startDay,
  //         start_time: startTime,
  //       };
  //       setAppointmentItem(appointmentItem);
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }
  // };

  // submit data for filtered appointments
  const submitFilter = () => {
    getPersonalAppointments(checkedItems);
    setAnchorEl(null);
    setLoading(true);
  };

  // open filter menu
  const handleOpenFilter = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // close filter menu
  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  // const handleShowRescheduleAppointmentAlert = () => (
  //   <Dialog
  //     open={() => ({})}
  //     onClose={() => setAlert(null)}
  //   />
  // );

  const getDeleteAppointmentAlert = () => (
    <Dialog
      open={() => ({})}
      onClose={() => setAlert(null)}
    >
      <DialogTitle id="customized-dialog-title" onClose={() => setAlert(null)}>
        <span className="alert-title">
          <span>{i18n.t('ConfirmDeletion')}</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={() => setAlert(null)}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>{i18n.t('AppointmentDeleteQuestion')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={() => setAlert(null)}
        >
          {i18n.t('Cancel')}
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => handleDeleteAppointment()}
        >
          {i18n.t('Confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );

  // Handle Calendar Event Click
  // const handleEventClick = (e) => {
  //   const { event } = e;
  //   const { history } = props;
  //   const appointment = allAppointments.find((item) => item.id === parseInt(event.id));
  //   const statusId = parseInt(appointment.status_id);
  //   lastDraggedAppointment.id = appointment.id;
  //   const [first] = PermissionsModule.getRoles(userAccount.testPermissions);
  //   localStorage.setItem('prefix', first.toLowerCase());
  //   getUserRoleInAppointment({ id: appointment.id });
  //   const getAlert = () => (
  //     <Dialog
  //       aria-labelledby="customized-dialog-title"
  //       open={() => ({})}
  //       onClose={() => setAlert(null)}
  //       onConfirm={() => ({})}
  //     >
  //       <DialogTitle id="customized-dialog-title" onClose={() => setAlert(null)}>
  //         <Box display="flex" justifyContent="flex-end">
  //           <span>
  //             {/* If appointment is not canceled and finished */}
  //             {(statusId !== 4 && statusId !== 3) && (
  //             <LightTooltip title="Edit" placement="top">
  //               <IconButton onClick={() => history.push(`/my-appointments/${lastDraggedAppointment.id}`)}>
  //                 <EditIcon />
  //               </IconButton>
  //             </LightTooltip>
  //             )}
  //             {/* If appointment is not canceled and finished */}
  //             {(statusId !== 4 && statusId !== 3) && (
  //             <LightTooltip title="Decline" placement="top">
  //               <IconButton onClick={() => handleCancelAppointment()}>
  //                 <BlockIcon />
  //               </IconButton>
  //             </LightTooltip>
  //             )}
  //             <LightTooltip title="Delete" placement="top">
  //               <IconButton onClick={() => setAlert(getDeleteAppointmentAlert())}>
  //                 <DeleteIcon />
  //               </IconButton>
  //             </LightTooltip>
  //             <IconButton
  //               aria-label="close"
  //               className="close-btn"
  //               onClick={() => setAlert(null)}
  //             >
  //               <CloseIcon />
  //             </IconButton>
  //           </span>
  //         </Box>
  //       </DialogTitle>
  //       <DialogContent dividers>
  //         <Typography component="div">
  //           <Box display="flex">
  //             <Box fontWeight="fontWeightBold" mr={1} minWidth="110px">
  //               {appointment.company !== null ? 'Company name:' : 'Individual User:'}
  //             </Box>
  //             <Box alignSelf="center">
  //               {appointment.company !== null ? appointment.company.name : appointment.individualUser.full_name}
  //             </Box>
  //           </Box>
  //           <Box display="flex">
  //             <Box fontWeight="fontWeightBold" mr={1} minWidth="110px">
  //               Customer name:
  //             </Box>
  //             <Box alignSelf="center">
  //               {appointment.customer.full_name}
  //             </Box>
  //           </Box>
  //           <Box display="flex">
  //             <Box fontWeight="fontWeightBold" mr={1} minWidth="110px">
  //               Customer phone:
  //             </Box>
  //             <Box alignSelf="center">
  //               {`${appointment.customer.phone_code} ${appointment.customer.phone_number}`}
  //             </Box>
  //           </Box>
  //           <Box display="flex">
  //             <Box fontWeight="fontWeightBold" mr={1} minWidth="110px">
  //               Date:
  //             </Box>
  //             <Box alignSelf="center">
  //               {appointment.date}
  //             </Box>
  //           </Box>
  //           <Box display="flex">
  //             <Box fontWeight="fontWeightBold" mr={1} minWidth="110px">
  //               Start time:
  //             </Box>
  //             <Box alignSelf="center">
  //               {appointment.start_time}
  //             </Box>
  //           </Box>
  //           <Box display="flex">
  //             <Box fontWeight="fontWeightBold" mr={1} minWidth="110px">
  //               Duration(min):
  //             </Box>
  //             <Box alignSelf="center">
  //               {appointment.duration}
  //             </Box>
  //           </Box>
  //           <Box display="flex">
  //             <Box fontWeight="fontWeightBold" mr={1} minWidth="110px">
  //               Price($):
  //             </Box>
  //             <Box alignSelf="center">
  //               {appointment.price}
  //             </Box>
  //           </Box>
  //         </Typography>
  //       </DialogContent>
  //     </Dialog>
  //   );
  //   setAlert(getAlert());
  // };

  return !loading ? (
    <>
      <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%" alignItems="center" className="second-navbar">
            <Typography variant="h6">
              <Box display="flex" alignItems="center" height="100%">
                Calendar
              </Box>
            </Typography>
            <Box display="flex" alignItems="center">
              <Box mr="10px">
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  onClick={selectAppointment}
                >
                  Add appointment
                </Button>
              </Box>
              <IconButton
                aria-label="filter"
                onClick={handleOpenFilter}
              >
                <TuneRoundedIcon color="secondary" />
              </IconButton>
              <FilterGeneralCalendar
                anchorEl={anchorEl}
                checkedItems={checkedItems}
                handleCloseFilter={handleCloseFilter}
                submitFilter={submitFilter}
                handleChangeCheckboxes={handleChangeCheckboxes}
              />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <div className="menu-item general">
        <div className="panel">
          <div className="main-content">
            <div className="box-general">
              <div className="full-calendar">
                {/*<FullCalendar*/}
                {/*  className="full-calendar"*/}
                {/*  events={calendarEvents}*/}
                {/*  defaultView="timeGridDay"*/}
                {/*  slotDuration="00:20:00"*/}
                {/*  slotLabelInterval={{ hours: 1 }}*/}
                {/*  titleFormat={{*/}
                {/*    year: 'numeric',*/}
                {/*    month: 'short',*/}
                {/*    day: 'numeric',*/}
                {/*  }}*/}
                {/*  buttonText={{*/}
                {/*    today: 'Today',*/}
                {/*  }}*/}
                {/*  header={{*/}
                {/*    left: 'prev title next',*/}
                {/*    right: '',*/}
                {/*  }}*/}
                {/*  slotLabelFormat={{*/}
                {/*    hour: 'numeric',*/}
                {/*    minute: '2-digit',*/}
                {/*    hour12: false,*/}
                {/*  }}*/}
                {/*  eventTimeFormat={{*/}
                {/*    hour: 'numeric',*/}
                {/*    minute: '2-digit',*/}
                {/*    hour12: false,*/}
                {/*  }}*/}
                {/*  locale="en-GB"*/}
                {/*  scrollTime="00:00"*/}
                {/*  allDaySlot={false}*/}
                {/*  contentHeight="auto"*/}
                {/*  plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}*/}
                {/*  editable*/}
                {/*  selectable*/}
                {/*  eventRender={eventRender}*/}
                {/*  select={selectAppointment}*/}
                {/*  eventClick={(e) => handleEventClick(e)}*/}
                {/*  eventDrop={(event) => eventDrop(event)}*/}
                {/*  selectAllow={(args) => selectAllow(args)}*/}
                {/*/>*/}
              </div>
            </div>
          </div>
        </div>
        { alert }
        <CreateNewAppointmentModal
          open={isNewAppointmentModal && !isPreviewShown}
          close={closeNewAppointmentModal}
          onAppointmentCreated={onAppointmentCreated}
          sendAppointmentItem={getAppointmentItem}
          sendSaveAppointment={getSaveAppointment}
          calendarAppointmentItem={appointmentItem}
          openInviteCustomerModal={() => {}}
          customerOptions={[]}
        />
        <AppointmentPreview
          open={isPreviewShown}
          onModalClose={() => togglePreview(false)}
          appointmentItem={appointmentItem}
          saveAppointment={handleSaveAppointment}
        />
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  ) : <CalendarLoading />;
}

Appointments.propTypes = {
  getGeneralAppointments: PropTypes.func.isRequired,
  isGetGeneralAppointmentsSuccess: PropTypes.bool.isRequired,
  generalAppointments: PropTypes.array.isRequired,
  // Delete Appointment Props
  deleteAppointment: PropTypes.func.isRequired,
  isDeleteAppointmentSuccess: PropTypes.bool.isRequired,
  isDeleteAppointmentError: PropTypes.bool.isRequired,
  deleteAppointmentErrorMessage: PropTypes.string.isRequired,
  // Cancel Appointment Props
  cancel: PropTypes.func.isRequired,
  isCancelSuccess: PropTypes.bool.isRequired,
  isCancelError: PropTypes.bool.isRequired,
  cancelErrorMessage: PropTypes.string.isRequired,
  // Reset Notifications Props
  resetNotifications: PropTypes.func.isRequired,
  updatedAppointment: PropTypes.object.isRequired,
  isUpdateAppointmentSuccess: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  userAccount: PropTypes.object.isRequired,
  getPersonalAppointments: PropTypes.func.isRequired,
  isGetUserRoleInAppointmentSuccess: PropTypes.bool.isRequired,
  // getUserRoleInAppointment: PropTypes.func.isRequired,
  userRoleInAppointment: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  userAccount: state.account.userAccount,
  isGetGeneralAppointmentsSuccess: state.appointment.isGetGeneralAppointmentsSuccess,
  isGetGeneralAppointmentsError: state.appointment.isGetGeneralAppointmentsError,
  generalAppointments: state.appointment.generalAppointments,
  // Delete Appointment
  isDeleteAppointmentSuccess: state.appointment.isDeleteAppointmentSuccess,
  isDeleteAppointmentError: state.appointment.isDeleteAppointmentError,
  deleteAppointmentErrorMessage: state.appointment.deleteAppointmentErrorMessage,
  isGetAppointmentsSuccess: state.appointment.isGetAppointmentsSuccess,
  isGetAppointmentsError: state.appointment.isGetAppointmentsError,
  allAppointments: state.appointment.allAppointments,
  // Cancel Appointment
  isCancelSuccess: state.appointment.isCancelSuccess,
  isCancelError: state.appointment.isCancelError,
  cancelErrorMessage: state.appointment.cancelErrorMessage,
  // Update Appointment
  isUpdateAppointmentSuccess: state.appointment.isUpdateAppointmentSuccess,
  isUpdateAppointmentError: state.appointment.isUpdateAppointmentError,
  updatedAppointment: state.appointment.updatedAppointment,
  updateAppointmentErrorMessage: state.appointment.updateAppointmentErrorMessage,
  account: state.account.userAccount,
  isGetUserRoleInAppointmentSuccess: state.appointment.isGetUserRoleInAppointmentSuccess,
  userRoleInAppointment: state.appointment.userRoleInAppointment,
});

function mapDispatchToProps(dispatch) {
  return {
    getGeneralAppointments: () => dispatch(getGeneralAppointmentsRequest()),
    getPersonalAppointments: (data) => dispatch(getGeneralAppointmentsRequest(data)),
    updateAppointment: (data) => dispatch(updateAppointmentRequest(data)),
    deleteAppointment: (data) => dispatch(deleteAppointmentRequest(data)),
    cancel: (data) => dispatch(cancelRequest(data)),
    resetNotifications: (data) => dispatch(resetNotificationsRequest(data)),
    // getUserRoleInAppointment: (data) => dispatch(getUserRoleInAppointmentRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
