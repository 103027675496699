import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Toolbar,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import SnackbarToast from '../../../../../Modules/SnackbarToast';
import Profile from './Tabs/profile';
import SaveCustomersChanges from '../../../../../Modals/Customers/SaveCustomersInfo';
import { getCitiesRequest, getStatesRequest } from '../../../../../redux/country/actions';
import { updateAccountAvatarRequest, updateUserAccountRequest } from '../../../../../redux/account/actions';

class Settings extends Component {
  static propTypes = {
    t: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    updateUserAccount: PropTypes.func.isRequired,
    isUpdateUserAccountSuccess: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      submitPerformed: false,
      IsAppointmentModalShown: false,
      activeTabIndex: 0,
      changedProfileInfo: {},
      openSnackbar: false,
      snackbarMessage: '',
      snackbarType: '',
    };
  }

  componentDidUpdate(prevProps) {
    const { isUpdateUserAccountSuccess } = this.props;

    // Handle Update Breaking Hours Error
    if (!prevProps.isUpdateUserAccountSuccess && isUpdateUserAccountSuccess) {
      this.setState({
        openSnackbar: true,
        snackbarMessage: 'Account updated successfully !!!',
        snackbarType: 'success',
        changedProfileInfo: {},
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ submitPerformed: true });
  };

  submitFinished = () => this.setState({ submitPerformed: false });

  a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  getSelectedTab = (tab) => {
    let selectedTab;
    let selectedTabName = tab;
    switch (tab) {
      case 'profile': {
        selectedTab = 0;
        break;
      }
      case 'account-type': {
        selectedTab = 1;
        break;
      }
      default: {
        selectedTab = 0;
        selectedTabName = 'profile';
      }
    }
    return {
      selectedTab,
      selectedTabName,
    };
  };

  handleTabChange = (tab) => {
    const { changedProfileInfo } = this.state;
    if (Object.keys(changedProfileInfo).length) {
      this.setState({
        IsAppointmentModalShown: true,
        tab,
      });
    } else {
      const { selectedTab } = this.getSelectedTab(tab);
      this.setState({
        activeTabIndex: selectedTab,
      });
    }
  };

  handleCloseInfoModal = () => {
    this.setState({
      IsAppointmentModalShown: false,
    });
  };

  handleCancelProfileChange = () => {
    const { tab } = this.state;
    const { selectedTab } = this.getSelectedTab(tab);
    this.setState({
      changedProfileInfo: {},
    });
    this.handleCloseInfoModal();
    this.setState({
      activeTabIndex: selectedTab,
    });
  };

  handleSaveProfileChange = () => {
    const { updateUserAccount } = this.props;
    const { tab, changedProfileInfo } = this.state;
    const { selectedTab } = this.getSelectedTab(tab);
    this.setState({
      changedProfileInfo: {
        changed: false,
      },
    });
    updateUserAccount(changedProfileInfo);
    this.handleCloseInfoModal();
    this.setState({
      activeTabIndex: selectedTab,
    });
  };

  handleChangedProfileInfo = (event, select) => {
    const { account } = this.props;
    if (select) {
      const { name } = event.target;
      const { value } = event.target;
      this.setState((prevState) => ({
        changedProfileInfo: {
          ...prevState.changedProfileInfo,
          [name]: value,
          changed: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        changedProfileInfo: {
          ...prevState.changedProfileInfo,
          avatar: event.avatar,
          first_name: event.first_name || account.first_name,
          last_name: event.last_name || account.last_name,
          birthday: event.birthday || account.birthday,
          gender: event.gender || account.gender,
          phone_number: event.phone_number || account.phone_number,
          city_id: event.city_id || account.city_id,
          country_id: event.country_id || account.country,
          phone_code: event.phone_code || account.phone_code,
          state_id: event.state_id || account.state_id,
          timezone: event.timezone || account.timezone,
        },
      }));
    }
  };

  render() {
    const { t, account } = this.props;
    const {
      submitPerformed,
      activeTabIndex,
      snackbarMessage,
      snackbarType,
      openSnackbar,
      IsAppointmentModalShown,
    } = this.state;

    const createAccountPermissions = {
      customer: false,
      owner: false,
      individual: false,
    };

    if (account && account.permissions && account.permissions.length) {
      const permissions = JSON.parse(account.permissions[0].permissions).common_access;
      // Permission to create Customer account
      if (permissions.create_customer_account && permissions.create_customer_account.status === 1) {
        createAccountPermissions.customer = !!permissions.create_customer_account.status;
      }
      // Permission to create owner account
      if (permissions.create_owner_account && permissions.create_owner_account.status === 1) {
        createAccountPermissions.owner = !!permissions.create_owner_account.status;
      }
      // Permission to create individual account
      if (permissions.create_individual_account && permissions.create_individual_account.status === 1) {
        createAccountPermissions.individual = !!permissions.create_individual_account.status;
      }
    }

    return (
      <div className="main-content">
        <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
          <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
              <Box display="flex" className="tabs-navbar">
                <Box alignSelf="center" className="divider-title">
                  <Typography variant="h6" noWrap>
                    <Box display="flex" alignItems="center" height="100%">
                      {t('MyProfile')}
                    </Box>
                  </Typography>
                </Box>
                <Box alignSelf="center" className="tab-companies">
                  <AppBar position="static" color="inherit" elevation={0}>
                    <Tabs
                      value={activeTabIndex}
                      aria-label="simple tabs example"
                      variant="scrollable"
                      indicatorColor="primary"
                      textColor="secondary"
                    >
                      <Tab
                        label={t('MyProfile')}
                        onClick={() => this.handleTabChange('profile')}
                        {...this.a11yProps(0)}
                      />
                    </Tabs>
                  </AppBar>
                </Box>
              </Box>
              { activeTabIndex === 0 && (
                <Box display="flex" alignItems="center">
                  <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    onClick={(e) => this.handleSubmit(e)}
                  >
                    {t('Update')}
                  </Button>
                </Box>
              ) }
            </Box>
          </Toolbar>
        </AppBar>
        {activeTabIndex === 0 && (
          <Profile
            submitPerformed={submitPerformed}
            sendSubmitFinished={this.submitFinished}
            handleChangedProfileInfo={this.handleChangedProfileInfo}
          />
        )}
        {activeTabIndex === 1 && (
          {/* <AccountType
            createCustomerAccount={createAccountPermissions.customer}
            createOwnerAccount={createAccountPermissions.owner}
            createIndividualAccount={createAccountPermissions.individual}
            handleChangeCreateAccount={handleChangeCreateAccount}
            handleChangeAccountStatus={handleChangeAccountStatus}
          /> */}
        )}
        <SaveCustomersChanges
          open={IsAppointmentModalShown}
          handleCloseInfoModal={this.handleCloseInfoModal}
          handleSaveProfileChange={this.handleSaveProfileChange}
          handleCancelProfileChange={this.handleCancelProfileChange}
        />
        <SnackbarToast
          message={snackbarMessage}
          type={snackbarType}
          open={openSnackbar}
          onClose={() => this.setState({ openSnackbar: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.account.userAccount,
  // Updated Account
  isUpdateUserAccountSuccess: state.account.isUpdateUserAccountSuccess,
  isUpdateUserAccountError: state.account.isUpdateUserAccountError,
  updateUserAccountErrors: state.account.updateUserAccountErrors,
  isUpdateAccountAvatarSuccess: state.account.isUpdateAccountAvatarSuccess,
  isUpdateAccountAvatarError: state.account.isUpdateAccountAvatarError,
  updateAccountAvatarErrors: state.account.updateAccountAvatarErrors,
});

function mapDispatchToProps(dispatch) {
  return {
    getStates: (data) => dispatch(getStatesRequest(data)),
    getCities: (data) => dispatch(getCitiesRequest(data)),
    updateUserAccount: (data) => dispatch(updateUserAccountRequest(data)),
    updateAccountAvatar: (data) => dispatch(updateAccountAvatarRequest(data)),
  };
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Settings));
