import { takeLatest, put } from 'redux-saga/effects';

import {
  changeLanguageRequest,
  changeLanguageSuccess,
  changeLanguageError,
} from './action';
import axiosInstance from '../../Config/ServerConfig';

function* changeLanguage(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('account/language', payload);
    if (response && response.status === 200) {
      yield put(changeLanguageSuccess(response.data));
    } else {
      yield put(changeLanguageError());
    }
  } catch (e) {
    yield put(changeLanguageError());
  }
}

export default function* () {
  yield takeLatest(changeLanguageRequest, changeLanguage);
}
