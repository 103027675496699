import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GetSelectedRoleMessages from './getSelectedRoleMessages';
import PermissionsModule from '../../../../Modules/Permission';
import IndividualMessages from '../../Roles/Individual/Messages/messages';
import CustomerMessages from '../../Roles/Customer/Messages/messages';
import StaffMessages from '../../Roles/Staff/Messages/messages';

function Messages(props) {
  const {
    account: { testPermissions },
    match,
    history,
  } = props;

  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');

  useEffect(() => {
    const roles = PermissionsModule.getRoles(testPermissions);
    setRoles(roles);

    const roleToSelect = PermissionsModule.getRoleToSelect(roles);
    setSelectedRole(roleToSelect);
  }, []);

  useEffect(() => {
    localStorage.setItem('prefix', selectedRole);
  }, [selectedRole]);

  const getSelectedRoleComponent = (selectedRoleMessages) => {
    const props = { history, match };
    // eslint-disable-next-line default-case
    switch (selectedRole.toLowerCase()) {
      case 'individual': {
        return (
          <IndividualMessages {...props}>
            {selectedRoleMessages}
          </IndividualMessages>
        );
      }
      case 'staff': {
        return (
          <StaffMessages {...props}>
            {selectedRoleMessages}
          </StaffMessages>
        );
      }
      case 'customer': {
        return (
          <CustomerMessages {...props}>
            {selectedRoleMessages}
          </CustomerMessages>
        );
      }
    }
  };

  return (
    <>
      {getSelectedRoleComponent(
        <GetSelectedRoleMessages
          roles={roles}
          selectedRole={selectedRole}
          selectRole={(role) => setSelectedRole(role)}
        />,
      )}
    </ >
  );
}

Messages.propTypes = {
  account: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account.userAccount,
});

export default connect(mapStateToProps)(Messages);
