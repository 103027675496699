import withStyles from '@material-ui/core/styles/withStyles';
import {
  blue, green, grey, orange,
} from '@material-ui/core/colors';
import { Checkbox } from '@material-ui/core';
import React from 'react';

const Checkboxes = {
  Accept: withStyles(() => ({
    root: {
      color: green[300],
      '&$checked': {
        color: green[300],
      },
    },
    checked: {},
  }))((props) => <Checkbox color="default" {...props} />),
  Pending: withStyles(() => ({
    root: {
      color: orange[300],
      '&$checked': {
        color: orange[300],
      },
    },
    checked: {},
  }))((props) => <Checkbox color="default" {...props} />),
  Cancel: withStyles(() => ({
    root: {
      color: grey[400],
      '&$checked': {
        color: grey[600],
      },
    },
    checked: {},
  }))((props) => <Checkbox color="default" {...props} />),
  Finish: withStyles(() => ({
    root: {
      color: blue[400],
      '&$checked': {
        color: blue[400],
      },
    },
    checked: {},
  }))((props) => <Checkbox color="default" {...props} />),
};

export default Checkboxes;
