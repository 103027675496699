import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import DeleteButton from '../../../MaterialComponents/deleteButton';
import usePrevious from '../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../Modules/SnackbarToast';
import { deleteIndividualSpecialDayRequest } from '../../../redux/individualUser/actions';

function DeleteSpecialDayModal(props) {
  const dispatch = useDispatch();

  const {
    isDeleteIndividualSpecialDayError,
    isDeleteIndividualSpecialDaySuccess,
    deleteIndividualSpecialDayErrorMessage,
  } = useSelector((state) => state.individualUser);

  const {
    open,
    onClose,
    specialDayId,
    allSpecialDays,
    setAllSpecialDays,
    setIndividualUserIndustryItem,
  } = props;

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous values
  const prevIsDeleteIndividualSpecialDaySuccess = usePrevious(isDeleteIndividualSpecialDaySuccess);
  const prevIsDeleteIndividualSpecialDayError = usePrevious(isDeleteIndividualSpecialDayError);

  // Handle delete day off success
  useEffect(() => {
    if (prevIsDeleteIndividualSpecialDaySuccess === false && isDeleteIndividualSpecialDaySuccess) {
      const allSpecialDaysCopy = [...allSpecialDays];
      const dayOffIndex = allSpecialDaysCopy.findIndex((item) => item.id === specialDayId);
      allSpecialDaysCopy.splice(dayOffIndex, 1);
      setIndividualUserIndustryItem((prevState) => ({
        ...prevState,
        specialDays: allSpecialDaysCopy,
      }));
      setAllSpecialDays(allSpecialDaysCopy);
      onClose();
      snackBarAlert(true, i18n.t('SpecificWorkDayDeleted'), 'success');
    }
  }, [isDeleteIndividualSpecialDaySuccess]);

  // Handle delete day off error
  useEffect(() => {
    if (prevIsDeleteIndividualSpecialDayError === false && isDeleteIndividualSpecialDayError) {
      snackBarAlert(true, deleteIndividualSpecialDayErrorMessage, 'error');
    }
  }, [isDeleteIndividualSpecialDayError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleConfirmDeleteSpecialDay = () => {
    dispatch(deleteIndividualSpecialDayRequest({ id: specialDayId }));
  };

  return (
    <div>
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span className="alert-title">
            <span>Confirm Specific WorkDay(s)</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {i18n.t('AreYouSureDelete')} entry? The general schedule will be applied to the specified date(s)
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onClose}
          >
            {i18n.t('Cancel')}
          </Button>
          <DeleteButton
            size="small"
            variant="contained"
            color="primary"
            onClick={handleConfirmDeleteSpecialDay}
          >
            {i18n.t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  );
}

DeleteSpecialDayModal.propTypes = {
  // Parent Props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  specialDayId: PropTypes.number.isRequired,
  allSpecialDays: PropTypes.object.isRequired,
  setAllSpecialDays: PropTypes.func.isRequired,
  setIndividualUserIndustryItem: PropTypes.func.isRequired,
};

export default DeleteSpecialDayModal;
