import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Radio,
  Dialog,
  RadioGroup,
  DialogTitle,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Box,
  Button,
  Typography,
  IconButton,
  FormControl,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { useTranslation } from 'react-i18next';

function EditServiceModal(props) {
  const { t } = useTranslation();
  const {
    open,
    onClose,
    filteredAppointments,
    handleSaveChangedService,
    serviceData,
    setServiceData,
  } = props;

  const [value, setValue] = useState('update');

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
    const serviceDataCopy = { ...serviceData };
    serviceDataCopy.editType = value;
    setServiceData(serviceDataCopy);
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title" maxWidth="lg">
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span className="alert-title">
            <span>Edit Service Details</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            <Box>
              <Box mb={3} display="flex" alignItems="center" justifyContent="center">
                <Box>
                  <ReportProblemOutlinedIcon fontSize="large" />
                </Box>
                <Box>
                  <Typography variant="h6" component="h6"><b>This service has { filteredAppointments && filteredAppointments.length } upcoming appointments</b></Typography>
                </Box>
              </Box>
              <Box>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="type" name="edit-type" value={value} onChange={handleChange}>
                    <FormControlLabel value="update" control={<Radio color="primary" />} label="Update all the upcoming appointments for this service and ask customers to either accept or reject the changes." />
                    <FormControlLabel value="keep" control={<Radio color="primary" />} label="Keep the old service details for the upcoming appointments." />
                    <FormControlLabel value="cancel" control={<Radio color="primary" />} label="Cancel all the upcoming appointments for this service." />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleSaveChangedService}
          >
            {t('Apply')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditServiceModal.propTypes = {
  // Parent Props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  filteredAppointments: PropTypes.array.isRequired,
  handleSaveChangedService: PropTypes.func.isRequired,
  serviceData: PropTypes.object.isRequired,
  setServiceData: PropTypes.func.isRequired,
};

export default EditServiceModal;
