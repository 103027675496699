import React from 'react';
import {
  Menu,
  Checkbox,
  Box,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import industriesLogo from '../../../assets/industriesLogo';

function FilterCustomerOptions(props) {
  const {
    checkedItems,
    customerFilter,
    allIIndustries,
    handleCloseFilter,
    handleChangeStatus,
    handleChangeIndustry,
    isOpenCustomerFilter,
    handleSubmitCustomerOption,
  } = props;

  return (
    <Menu
      className="filter-staff-menu"
      anchorEl={isOpenCustomerFilter}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpenCustomerFilter}
      onClose={handleCloseFilter}
    >
      <Typography variant="h5">Industry</Typography>
      <Divider />
      {allIIndustries.length && allIIndustries.map((industry) => (
        <li key={industry.id} className="industries-list">
          <Box alignSelf="center" className="industry-info">
            <Box display="flex">
              <img src={industriesLogo[industry.industry_name]} className="industry-logo" alt="industryLogo" width="20px" /> {industry.industry_name}
            </Box>
          </Box>
          <Checkbox
            color="primary"
            value={industry.id}
            checked={Object.keys(checkedItems).length ? checkedItems.individual_user_industry_ids.includes(industry.id) : ''}
            onChange={(e) => handleChangeIndustry(e, industry.id)}
            name="individual_user_industry_ids"
          />
        </li>
      ))}
      <Divider />
      <Typography variant="h5">Status</Typography>
      <Divider />
      <li key={customerFilter.active}>
        <Box alignSelf="center" mr="5px">
          <Typography variant="h5">Registered</Typography>
        </Box>
        <Checkbox
          size="small"
          color="primary"
          value={customerFilter.active}
          name="active"
          checked={Object.keys(checkedItems).length ? checkedItems.active : ''}
          onChange={(e) => handleChangeStatus(e, customerFilter.active)}
        />
      </li>
      <li key={customerFilter.inActive}>
        <Box alignSelf="center" mr="5px">
          <Typography variant="h5">Not Registered</Typography>
        </Box>
        <Checkbox
          size="small"
          color="primary"
          value={customerFilter.inActive}
          name="inActive"
          checked={Object.keys(checkedItems).length ? checkedItems.inActive : ''}
          onChange={(e) => handleChangeStatus(e, customerFilter.inActive)}
        />
      </li>
      <Divider />
      <Box display="flex" justifyContent="flex-end" mt="16px">
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => handleSubmitCustomerOption()}
        >
          Apply
        </Button>
      </Box>
    </Menu>
  );
}

FilterCustomerOptions.propTypes = {
  checkedItems: PropTypes.object.isRequired,
  customerFilter: PropTypes.object.isRequired,
  allIIndustries: PropTypes.array.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  isOpenCustomerFilter: PropTypes.bool.isRequired,
  handleChangeStatus: PropTypes.func.isRequired,
  handleChangeIndustry: PropTypes.func.isRequired,
  handleSubmitCustomerOption: PropTypes.func.isRequired,
};

export default FilterCustomerOptions;
