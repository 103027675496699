import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { staffHeadCells } from 'Modules/constants';
import { getCompanyStaffRequest } from 'redux/staff/actions';
import usePrevious from 'CustomHooks/usePrevious';
import SnackbarToast from 'Modules/SnackbarToast';
import EnhancedTable from './Table';
import Header from './Header';
import StaffContext from './staffContext';
import AddStaffDrawer from './drawer';
import UpdateStaffDrawer from './newDrawer';

function NewStaff() {
  const dispatch = useDispatch();

  const {
    isGetCompanyStaffSuccess,
    companyStaff,
    isAddStaffToCompanySuccess,
    isUpdateStaffSuccess,
    updatedStaff,
    isDeleteStaffSuccess,
    companyStaffCount,
    addedStaff,
  } = useSelector((state) => state.staff);

  // Prev values
  const prevIsGetCompanyStaffSuccess = usePrevious(isGetCompanyStaffSuccess);
  const prevIsAddStaffToCompanySuccess = usePrevious(isAddStaffToCompanySuccess);
  const prevIsUpdateStaffSuccess = usePrevious(isUpdateStaffSuccess);
  const prevIsDeleteStaffSuccess = usePrevious(isDeleteStaffSuccess);

  const [staff, setStaff] = useState([]);
  const [open, setOpen] = useState(false);
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [selected, setSelected] = useState([]);
  const [deletedStaffId, setDeletedStaffId] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [professionOptions, setProfessionOptions] = useState([]);
  const [staffCount, setStaffCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [snackbarData, setSnackbarData] = useState({
    message: '',
    type: '',
    open: false,
  });

  useEffect(() => {
    // if (!companyStaff.length) {
    //
    // } else {
    //   setStaffCount(companyStaffCount);
    //   getCompanyStaff();
    // }
    dispatch(getCompanyStaffRequest({ forService: true, page: 1, limit: 10 }));
  }, []);

  useEffect(() => {
    if (prevIsGetCompanyStaffSuccess === false && isGetCompanyStaffSuccess) {
      setStaffCount(companyStaffCount);
      getCompanyStaff();
      setLoading(false);
    }
  }, [isGetCompanyStaffSuccess]);

  useEffect(() => {
    if (prevIsAddStaffToCompanySuccess === false && isAddStaffToCompanySuccess) {
      setStaffCount(staffCount + 1);
      setOpen(false);
      if (staff.length < rowsPerPage) {
        const staffCopy = [...staff];
        staffCopy.push(addedStaff);
        setStaff(staffCopy);
      }
    }
  }, [isAddStaffToCompanySuccess]);

  useEffect(() => {
    if (prevIsUpdateStaffSuccess === false && isUpdateStaffSuccess) {
      const staffCopy = [...staff];
      const foundedIndex = staffCopy.findIndex((item) => item.id === updatedStaff.id);
      staffCopy[foundedIndex] = updatedStaff;
      setStaff(staffCopy);
      setOpenEditDrawer(false);
    }
  }, [isUpdateStaffSuccess]);

  useEffect(() => {
    if (prevIsDeleteStaffSuccess === false && isDeleteStaffSuccess) {
      const staffCopy = [...staff];
      let data;
      if (selected.length) {
        data = staffCopy.filter((item) => !selected.includes(item.id));
        setStaffCount(staffCount - selected.length);
      } else {
        data = staffCopy.filter((item) => !deletedStaffId.includes(item.id));
        setStaffCount(staffCount - 1);
      }
      setStaff(data);
      setOpenEditDrawer(false);
      setSelected([]);
      setDeletedStaffId([]);
    }
  }, [isDeleteStaffSuccess]);

  const getCompanyStaff = () => {
    setStaff(companyStaff);
  };

  const deleteStaff = (e, id) => {
    e.stopPropagation();
    setDeletedStaffId([id]);
    setSelected([]);
    setIsModalOpen(true);
  };

  return (
    <StaffContext.Provider value={{
      open,
      setOpen,
      openEditDrawer,
      setOpenEditDrawer,
      isUpdate,
      setIsUpdate,
      selected,
      selectedIndex,
      setSelectedIndex,
      setSelected,
      isModalOpen,
      setIsModalOpen,
      staff,
      selectedStaff,
      setSelectedStaff,
      deletedStaffId,
      setDeletedStaffId,
      deleteStaff,
      professionOptions,
      setProfessionOptions,
      snackbarData,
      setSnackbarData,
      staffCount,
    }}
    >
      <Header />
      <div style={{ height: 'calc(100vh - 128px)' }}>
        <EnhancedTable
          rows={staff}
          headCells={staffHeadCells}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          loading={loading}
        />
        <AddStaffDrawer selectedStaff={isUpdate ? selectedStaff : {}} isUpdate />
        <UpdateStaffDrawer selectedStaff={selectedStaff} />
      </div>
      <SnackbarToast
        open={snackbarData.open}
        message={snackbarData.message}
        type={snackbarData.type}
        onClose={() => setSnackbarData((prev) => ({ ...prev, open: false }))}
      />
    </StaffContext.Provider>
  );
}

export default NewStaff;
