import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../details.scss';
import '../../../../../../../style/general.scss';
import '../../../../../../../style/dropzone.scss';
import {
  Select,
  Box,
  Link,
  MenuItem,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import hours from '../../../../../../../Modules/hours';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import { updateWorkingHoursRequest } from '../../../../../../../redux/businnessHours/actions';
import {
  checkIndividualUserIndustryDayAppointmentsRequest,
} from '../../../../../../../redux/appointment/actions';
import weekDayNumber from '../../../../../../../Modules/weekDayNumber';

function WorkingHours(props) {
  const dispatch = useDispatch();

  const {
    // Check Day Appointments
    isCheckIndividualUserIndustryDayAppointmentsSuccess,
    isCheckIndividualUserIndustryDayAppointmentsError,
    individualUserIndustryDayAppointments,
    // Cancel Day Appointments
    isCancelIndividualUserIndustryDayAppointmentsSuccess,
    isCancelIndividualUserIndustryDayAppointmentsError,
    cancelIndividualUserIndustryDayAppointmentsErrorMessage,
  } = useSelector((state) => state.appointment);

  const {
    setWeekDay,
    cancelData,
    setLoading,
    setCancelData,
    individualUserIndustryItem,
    changeIndividualIndustryHours,
    setHaveAppointmentsModalShown,
  } = props;

  // Get some props previous values
  const prevIsCheckIndividualUserIndustryDayAppointmentsSuccess = usePrevious(isCheckIndividualUserIndustryDayAppointmentsSuccess);
  const prevIsCheckIndividualUserIndustryDayAppointmentsError = usePrevious(isCheckIndividualUserIndustryDayAppointmentsError);
  const prevIsCancelIndividualUserIndustryDayAppointmentsSuccess = usePrevious(isCancelIndividualUserIndustryDayAppointmentsSuccess);
  const prevIsCancelIndividualUserIndustryDayAppointmentsError = usePrevious(isCancelIndividualUserIndustryDayAppointmentsError);

  const [workingHours, setWorkingHours] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState(false);
  const [dayId, setDayId] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [checkWorkingHours, setCheckWorkingHours] = useState(false);
  const [applyWorkingHours, setApplyWorkingHours] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  useEffect(() => {
    setWorkingHours(individualUserIndustryItem.workingHours);
  }, [individualUserIndustryItem]);

  // Handle Get Company Day Appointments Success
  useEffect(() => {
    if (prevIsCheckIndividualUserIndustryDayAppointmentsSuccess === false && isCheckIndividualUserIndustryDayAppointmentsSuccess && checkWorkingHours) {
      if (individualUserIndustryDayAppointments.length > 0) {
        if (dayId) {
          const weekDay = workingHours.find((hour) => hour.week_day_id === dayId).week_day;
          setWeekDay(weekDay);
        }
        const appointmentsIds = [];
        individualUserIndustryDayAppointments.map((item) => {
          appointmentsIds.push(item.id);
        });
        setCancelData({
          appointment_ids: appointmentsIds,
          name: 'work',
        });
        setHaveAppointmentsModalShown(true);
      } else if (dayId) {
        handleWorkingHoursDetailsChange(name, value, checked, dayId);
      } else {
        setWorkingHours(applyWorkingHours);
        dispatch(updateWorkingHoursRequest({
          individualUserIndustryId: individualUserIndustryItem.id,
          businessDays: applyWorkingHours,
        }));
      }
      setCheckWorkingHours(false);
    }
  }, [isCheckIndividualUserIndustryDayAppointmentsSuccess]);

  // Handle Get Company Day Appointments Error
  useEffect(() => {
    if (prevIsCheckIndividualUserIndustryDayAppointmentsError === false && isCheckIndividualUserIndustryDayAppointmentsError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isCheckIndividualUserIndustryDayAppointmentsError]);

  // Handle cancel appointments success
  useEffect(() => {
    if (prevIsCancelIndividualUserIndustryDayAppointmentsSuccess === false && isCancelIndividualUserIndustryDayAppointmentsSuccess && cancelData.name === 'work') {
      setHaveAppointmentsModalShown(false);
      if (dayId) {
        handleWorkingHoursDetailsChange(name, value, checked, dayId);
      } else {
        setWorkingHours(applyWorkingHours);
        dispatch(updateWorkingHoursRequest({
          individual_user_industry_id: individualUserIndustryItem.id,
          businessDays: applyWorkingHours,
        }));
      }
    }
  }, [isCancelIndividualUserIndustryDayAppointmentsSuccess]);

  // Handle cancel appointments success
  useEffect(() => {
    if (prevIsCancelIndividualUserIndustryDayAppointmentsError === false && isCancelIndividualUserIndustryDayAppointmentsError) {
      setOpenSnackbar(true);
      setSnackbarType('error');
      setSnackbarMessage(cancelIndividualUserIndustryDayAppointmentsErrorMessage);
    }
  }, [isCancelIndividualUserIndustryDayAppointmentsError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const applyToAll = (firstWorkHour) => {
    const workingHoursCopy = [...workingHours];

    workingHoursCopy.map((workHour) => {
      workHour.start_time = firstWorkHour.start_time;
      workHour.end_time = firstWorkHour.end_time;
    });

    setCheckWorkingHours(true);
    setApplyWorkingHours(workingHoursCopy);
    dispatch(checkIndividualUserIndustryDayAppointmentsRequest({
      individual_user_industry_id: individualUserIndustryItem.id,
      working_days: workingHoursCopy,
    }));
  };

  const getSelectedLowestWeekDayId = () => {
    let lowestWeekDayId = Number.POSITIVE_INFINITY;
    workingHours.map((workHour) => {
      const weekDayId = parseInt(workHour.week_day_id);
      if (weekDayId < lowestWeekDayId && workHour.is_working) lowestWeekDayId = weekDayId;
    });

    return lowestWeekDayId;
  };

  const handleCheckAppointments = (event, id) => {
    const { name, value, checked } = event.target;
    const workingHoursCopy = [...individualUserIndustryItem.workingHours];
    const workingHour = workingHoursCopy.find((hour) => hour.week_day_id === id);
    setName(name);
    setValue(value);
    setChecked(checked);
    setDayId(id);
    setCheckWorkingHours(true);
    const startTime = (name === 'start_time' ? value : workingHour.start_time);
    const endTime = (name === 'end_time' ? value : workingHour.end_time);
    dispatch(checkIndividualUserIndustryDayAppointmentsRequest({
      individual_user_industry_id: individualUserIndustryItem.id,
      day: weekDayNumber(id),
      start_time: startTime,
      end_time: endTime,
    }));
  };

  const handleWorkingHoursDetailsChange = (name, value, checked, id) => {
    const val = checked !== undefined ? checked : value;
    const workingHoursCopy = [...individualUserIndustryItem.workingHours];
    const workingHourIndex = workingHoursCopy.findIndex((hour) => hour.week_day_id === id);
    changeIndividualIndustryHours(workingHoursCopy);
    if (name === 'is_working') {
      workingHoursCopy[workingHourIndex][name] = val;
      setWorkingHours(workingHoursCopy);
    } else if (name === 'start_time') {
      const startTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(workingHoursCopy[workingHourIndex].end_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        workingHoursCopy[workingHourIndex][name] = val;
        setWorkingHours(workingHoursCopy);
      } else {
        snackBarAlert(true, i18n.t('StartTimeEarlier'), 'error');

        return false;
      }
    } else if (name === 'end_time') {
      const startTime = moment(workingHoursCopy[workingHourIndex].start_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        workingHoursCopy[workingHourIndex][name] = val;
        setWorkingHours(workingHoursCopy);
      } else {
        snackBarAlert(true, i18n.t('EndTimeAfter'), 'error');

        return false;
      }
    }

    dispatch(updateWorkingHoursRequest({
      individualUserIndustryId: individualUserIndustryItem.id,
      businessDays: workingHoursCopy,
    }));
    setLoading(false);
  };

  return (
    <Box py="25px" px="30px" className="tab-item" id="tab-main-parent">
      <Box display="flex" m="0">
        <Box className="business-hours">
          <div className="hours-content">
            { workingHours.map((workHour) => (
              <Box display="flex" key={Math.random()}>
                <Box minWidth="173px" className="day-content">
                  {workHour.is_working ? (
                    <>
                      <Box>
                        <div className="hours-select">
                          <Select
                            variant="outlined"
                            id="start_time"
                            disableUnderline
                            disabled={workHour ? !workHour['is_working'] : !workHour.is_working && true}
                            data-cy="working-start-time"
                            value={workHour.start_time}
                            name="start_time"
                            MenuProps={MenuProps}
                            onChange={(event) => handleCheckAppointments(event, workHour.week_day_id)}
                          >
                            { hours.map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
                          </Select>
                          <span>-</span>
                          <Select
                            variant="outlined"
                            id="end_time"
                            disableUnderline
                            disabled={workHour ? !workHour['is_working'] : !workHour.is_working && true}
                            data-cy="working-end-time"
                            value={workHour.end_time}
                            name="end_time"
                            MenuProps={MenuProps}
                            onChange={(event) => handleCheckAppointments(event, workHour.week_day_id)}
                          >
                            { hours.map((hour) => <MenuItem key={Math.random()} value={hour} disabled={hour < workHour.start_time}>{hour}</MenuItem>) }
                          </Select>
                        </div>
                      </Box>
                      {getSelectedLowestWeekDayId(workHour.week_day_id) === parseInt(workHour.week_day_id) && workHour.is_working && (
                      <Box ml="10px">
                        <Link href="#" onClick={() => applyToAll(workHour)} color="inherit">
                          {i18n.t('ApplyToAll')}
                        </Link>
                      </Box>
                      )}
                    </>
                  ) : (
                    <Box className="selects-border" />
                  )}
                </Box>
              </Box>
            )) }
          </div>
        </Box>
      </Box>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </Box>
  );
}

WorkingHours.propTypes = {
  // Parent props
  setWeekDay: PropTypes.func.isRequired,
  cancelData: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setCancelData: PropTypes.func.isRequired,
  individualUserIndustryItem: PropTypes.object.isRequired,
  changeIndividualIndustryHours: PropTypes.func.isRequired,
  setHaveAppointmentsModalShown: PropTypes.bool.isRequired,
};
export default WorkingHours;
