import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import DeleteButton from '../../../MaterialComponents/deleteButton';

const DeleteCustomerModal = (props) => {
  const {
    open,
    onClose,
    customer,
    isCustomer,
    handleConfirmDeleteCustomer,
    handleConfirmDeleteInvitation,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="customized-dialog-title" onClose={onClose}>
        <span className="alert-title">
          <span>{i18n.t('ConfirmDeletion')}</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{i18n.t('AreYouSureDelete')} {isCustomer ? 'customer' : 'invitation'} ?</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={onClose}
        >
          {i18n.t('Cancel')}
        </Button>
        <DeleteButton
          size="small"
          variant="contained"
          color="primary"
          onClick={() => isCustomer ? handleConfirmDeleteCustomer(customer) : handleConfirmDeleteInvitation(customer)}
        >
          {i18n.t('Delete')}
        </DeleteButton>
      </DialogActions>
    </Dialog>
  );
};

DeleteCustomerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  isCustomer: PropTypes.bool.isRequired,
  handleConfirmDeleteCustomer: PropTypes.func.isRequired,
  handleConfirmDeleteInvitation: PropTypes.func.isRequired,
};

export default DeleteCustomerModal;
