import React from 'react';
import 'react-placeholder/lib/reactPlaceholder.css';
import { Grid } from '@material-ui/core';
import { TextRow, RectShape } from 'react-placeholder/lib/placeholders';

function infoLoading() {
  return (
    <Grid container direction="row" id="fixed-box" spacing={2}>
      <Grid item xs={12} sm={4}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextRow showLoadingAnimation color="rgb(224, 224, 224)" style={{ height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextRow showLoadingAnimation color="rgb(224, 224, 224)" style={{ height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 160, height: 160 }} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 550, height: 160 }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item xs={12} sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextRow showLoadingAnimation color="rgb(224, 224, 224)" style={{ height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <TextRow showLoadingAnimation color="rgb(224, 224, 224)" style={{ height: 38, borderRadius: 5 }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid container direction="row" spacing={2}>
          <Grid item sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
          <Grid item sm={12}>
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ width: 540, height: 38, borderRadius: 5 }} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default infoLoading;
