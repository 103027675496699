import React from 'react';
import 'react-placeholder/lib/reactPlaceholder.css';
import { Box, Grid } from '@material-ui/core';
import { RectShape, TextRow } from 'react-placeholder/lib/placeholders';
import ReactPlaceholder from 'react-placeholder';

function workingScheduleLoading() {
  return (
    <>
      <Grid container xs={12} spacing={2} id="working-schedule-content">
        <Grid item xs={6} className="working-schedule">
          <div className="time-zone">
            <ReactPlaceholder rows={1} showLoadingAnimation delay={15} color="rgb(224, 224, 224)" style={{ width: 100, margin: '0 auto' }} />
          </div>
          <Box py="25px" px="30px" className="tab-item" id="tab-main-parent">
            <RectShape showLoadingAnimation color="rgb(224, 224, 224)" style={{ height: 190 }} />
          </Box>
        </Grid>
      </Grid>
      <Grid container xs={12} spacing={2} id="working-schedule-content">
        <Grid item xs={6} className="working-schedule">
          <Box py="25px" px="30px">
            <TextRow color="rgb(224, 224, 224)" style={{ height: 30 }} />
            <TextRow color="rgb(224, 224, 224)" style={{ height: 30 }} />
            <TextRow color="rgb(224, 224, 224)" style={{ height: 30 }} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default workingScheduleLoading;
