import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import './index.scss';

const AppointmentPreview = (props) => {
  const {
    open,
    onModalClose,
    appointmentItem,
    saveAppointment,
  } = props;

  const renderInfoBlock = (title, value) => (
    <Box mr="15px" className="info-block">
      <div className="title">{title}</div>
      <div className="value">{value}</div>
    </Box>
  );

  const renderDateFields = () => {
    const month = moment(appointmentItem.date).format('MMM');
    const weekDay = moment(appointmentItem.date).format('ddd');
    const day = moment(appointmentItem.date).format('D');

    return (
      <>
        <div className="month">{month}</div>
        <div className="day">{day}</div>
        <div className="week">{weekDay.toUpperCase()}</div>
      </>
    );
  };

  return (
    <Dialog
      id="appointment-preview"
      open={open}
      onClose={onModalClose}
    >
      <DialogTitle id="customized-dialog-title" onClose={onModalClose}>
        <span className="alert-title">
          <span> New appointment</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onModalClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <div className="modal-content">
          <div className="left">
            {renderDateFields()}
          </div>
          <div className="right">
            <div className="top">
              {renderInfoBlock('Company', appointmentItem.companyName)}
              {renderInfoBlock('Staff', appointmentItem.staffName)}
            </div>
            <div className="top">
              {renderInfoBlock('Service', appointmentItem.serviceName)}
              {renderInfoBlock('Time', appointmentItem.time)}
            </div>
            <div className="bottom">
              {renderInfoBlock('Price', appointmentItem.price)}
              {renderInfoBlock('Duration', appointmentItem.duration)}
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="contained"
          onClick={onModalClose}
        >
          Edit
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={saveAppointment}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AppointmentPreview.propTypes = {
  open: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  saveAppointment: PropTypes.func.isRequired,
  appointmentItem: PropTypes.object.isRequired,
};

export default AppointmentPreview;
