import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { deleteCompanyServiceRequest, deleteServiceRequest } from 'redux/service/actions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import deleteIcon from 'assets/icons/modal-delete.svg';
import { useTranslation } from 'react-i18next';
import ServiceContext from '../serviceContext';
import styles from '../styles.module.scss';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minWidth: '356px',
    maxWidth: '356px',
  },
  dialogActionsRoot: {
    padding: '16px 24px 24px',
    justifyContent: 'space-between',
  },
  cancelBtn: {
    color: '#A4A4A4',
    font: 'normal 600 14px/19px Nunito Sans, sans-serif',
    border: '1px solid #A4A4A4',
    borderRadius: '4px',
    padding: '7px 50px',
    maxWidth: '150px',
    width: '100%',
    height: 33,
  },
  deleteBtn: {
    backgroundColor: '#F12B2C',
    color: '#FFFFFF',
    font: 'normal 600 14px/19px Nunito Sans, sans-serif',
    borderRadius: '4px',
    padding: '7px 50px',
    maxWidth: '150px',
    width: '100%',
  },
}));

const DeleteServiceModal = () => {
  const {
    openDeleteModal,
    setOpenDeleteModal,
    selectedServiceId,
    selectedCategoryId,
    setOpenServiceDrawer,
    selectedCategory,
    setSelectedCategory,
  } = useContext(ServiceContext);

  const dispatch = useDispatch();

  const { t } = useTranslation();

  const classes = useStyles();

  const handleClose = () => {
    setOpenDeleteModal(false);
    setSelectedCategory(null);
  };

  const handleDeleteService = () => {
    if (selectedCategory) {
      dispatch(deleteServiceRequest({ id: selectedCategory.id }));
    } else {
      const data = {
        checked: true,
        ids: selectedServiceId,
        isService: true,
        parent_id: selectedCategoryId,
      };
      setOpenServiceDrawer(false);
      dispatch(deleteCompanyServiceRequest(data));
    }
  };

  return (
    <div>
      <Dialog
        open={openDeleteModal}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        className={styles.serviceModal}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent className={styles.content}>
          <Box className={styles.imgBox}>
            <img src={deleteIcon} alt="delete" />
          </Box>
          <DialogContentText className={styles.contentText}>
            {selectedCategory ? t('DeleteCategoryText') : t('ServiceDeletionText')}
          </DialogContentText>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActionsRoot }}>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.cancelBtn}
            autoFocus
          >
            {t('Cancel')}
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={handleDeleteService}
            style={{
              color: 'white', backgroundColor: '#F12B2C', width: '100%', height: 33,
            }}
          >
            {t('Delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteServiceModal;
