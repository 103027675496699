import capitalize from '../capitalize';

const getPagesAccess = (permissions) => {
  const rolesPagesAccess = {};
  permissions.map((item) => {
    const roleName = item.role.name;

    const pagesAccess = JSON.parse(item.permissions).pages_access[0];
    const values = Object.entries(pagesAccess);

    const filteredValues = values.filter((item) => item[1].status);
    rolesPagesAccess[roleName] = filteredValues.map((item) => item[0]);
  });

  return rolesPagesAccess;
};

const getGeneralRouteLinks = (firstRolePages, roles, rolesPagesAccess) => {
  let generalLinks = [];
  firstRolePages.forEach((link) => {
    roles.forEach((role) => {
      if (rolesPagesAccess[role].includes(link)) {
        generalLinks = [
          ...generalLinks,
          link,
        ];
      }
    });
  });

  return generalLinks;
};

const removeDuplicateLinks = (roles, rolesPagesAccess, general) => {
  roles.forEach((role) => {
    rolesPagesAccess[role] = rolesPagesAccess[role].filter((item) => !general.includes(item));
  });

  return rolesPagesAccess;
};

const getRoles = (permissions) => {
  const pagesAccess = getPagesAccess(permissions);

  return Object.keys(pagesAccess);
};

const getRolesWithId = (permissions) => {
  const userRoles = [];
  permissions.map((roles) => {
    roles && userRoles.push({
      role_id: roles.role.id,
      name: roles.role.name,
    });
  });
  return userRoles;
};

const getRoleToSelect = (roles) => {
  const prefix = localStorage.getItem('prefix');

  if (prefix && roles.includes(capitalize(prefix))) {
    return capitalize(prefix);
  } else {
    const [first] = roles;
    return first;
  }
};

export default {
  getPagesAccess,
  getGeneralRouteLinks,
  removeDuplicateLinks,
  getRoles,
  getRoleToSelect,
  getRolesWithId,
};
