import StepConnector from '@material-ui/core/StepConnector';
import { withStyles, makeStyles } from '@material-ui/core/styles';

export const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#0282DA',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#0282DA',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  dialogContent: {
    width: '900px',
  },
  dialog: {
    maxWidth: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

export const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#0282DA',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#0282DA',
    zIndex: 1,
    fontSize: 18,
  },
});
