import React, { useEffect, useState } from 'react';
import {
  Select,
  TextField,
  InputLabel,
  Box,
  Grid,
  Button,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import hours from 'Modules/hour';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import minutes from 'Modules/minutes';
import EditServiceModal from 'Modals/Services/editService';
import usePrevious from 'CustomHooks/usePrevious';
import {
  addServiceRequest,
  deleteServiceByIdRequest,
  updateCompanyServiceRequest,
} from 'redux/service/actions';
import { checkAppointmentsByServiceIdRequest } from 'redux/appointment/actions';
import SnackbarToast from 'Modules/SnackbarToast';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import AutocompleteSelectStaff from '../../../../../../../../Modules/AutocompleteSelectStaff';

function EditServiceItem(props) {
  const { t } = useTranslation();
  const {
    user,
    services,
    setServices,
    // companyId,
    serviceId,
    industryId,
    addService,
    subService,
    setEditAble,
    staffOptions,
    addedService,
    serviceOptions,
    companyServices,
    isAddServiceError,
    setCompanyServices,
    isAddServiceSuccess,
    updateCompanyService,
    updatedCompanyService,
    addServiceErrorMessage,
    isUpdateCompanyServiceError,
    isUpdateCompanyServiceSuccess,
    updateServiceCompanyErrorMessage,
    checkAppointmentsByServiceId,
    isCheckAppointmentByServiceIdSuccess,
    deleteServiceById,
    appointments,
    isDeleteServiceByIdSuccess,
    // isDeleteServiceByIdError,
  } = props;

  // Get some props previous value
  const prevIsUpdateCompanyServiceSuccess = usePrevious(isUpdateCompanyServiceSuccess);
  const prevIsUpdateCompanyServiceError = usePrevious(isUpdateCompanyServiceError);
  const prevIsAddServiceSuccess = usePrevious(isAddServiceSuccess);
  const prevIsAddServiceError = usePrevious(isAddServiceError);
  const prevIsCheckAppointmentByServiceIdSuccess = usePrevious(isCheckAppointmentByServiceIdSuccess);
  const prevIsDeleteServiceByIdSuccess = usePrevious(isDeleteServiceByIdSuccess);

  const [serviceData, setServiceData] = useState(subService);
  const [serviceOptionsList, setServiceOptionsList] = useState(serviceOptions);
  const [deleteServiceId, setDeleteServiceId] = useState(null);
  const [selectedServiceOption, setSelectedServiceOption] = useState({});
  const [selectedStaffOptions, setSelectedStaffOptions] = useState([]);
  const [openEditServiceModal, setOpenEditServiceModal] = useState(false);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [subServiceData, setSubServiceData] = useState({});
  const [errors, setErrors] = useState({
    service_id: false,
    duration: false,
    price: false,
    staffs: false,
  });

  const useStyles = makeStyles(() => ({
    menuPaper: {
      maxHeight: 300,
    },
  }));

  useEffect(() => {
    // TODO some changes with staffs select and service select
    if (subService.company_service_id) {
      const serviceDataCopy = { ...serviceData };
      const options = [];
      if (!serviceData.staff_ids) {
        if (serviceData.staffs) {
          serviceData.staffs.map((staff) => {
            options.push({
              id: staff.id,
              value: staff.id,
              label: `${staff.first_name} ${staff.last_name}`,
            });
          });
        }
      } else {
        staffOptions.map((staff) => {
          if (serviceData.staff_ids.includes(staff.id)) {
            options.push({
              id: staff.id,
              value: staff.id,
              label: staff.label,
            });
          }
        });
      }
      const serviceOptionsCopy = [...serviceOptions];
      serviceOptionsCopy.push({
        id: subService.service_id,
        logo: null,
        label: subService.name,
        value: subService.service_id,
        user: subService.user_id,
      });

      const selectedValue = serviceOptionsCopy.find((service) => service.id === serviceData.service_id);
      serviceDataCopy.staff_ids = getStaffIDs(options);
      serviceDataCopy.previous_service_id = subService.service_id;
      serviceDataCopy.editType = 'update';
      setServiceData(serviceDataCopy);
      if (Object.keys(subServiceData).length === 0) {
        setSubServiceData(serviceDataCopy);
      }
      if (selectedValue) {
        setSelectedServiceOption(selectedValue);
      }
      setSelectedStaffOptions(options);
    }
  }, [subService]);
  // Delete Service By Id Success
  useEffect(() => {
    if (prevIsDeleteServiceByIdSuccess === false && isDeleteServiceByIdSuccess) {
      const serviceOptionsListCopy = serviceOptionsList.filter((service) => service.id !== deleteServiceId);
      setServiceOptionsList(serviceOptionsListCopy);
      setSelectedServiceOption({});
    }
  }, [isDeleteServiceByIdSuccess]);

  // Check Appointments Success
  useEffect(() => {
    if (prevIsCheckAppointmentByServiceIdSuccess === false && isCheckAppointmentByServiceIdSuccess) {
      const filteredAppointments = appointments.filter((appointment) => appointment.status.id === 1 || appointment.status.id === 2);
      if (!validateForm(serviceData)) {
        if (filteredAppointments.length > 0) {
          setOpenEditServiceModal(true);
        } else {
          handleSaveChangedService();
        }
      }
      setFilteredAppointments(filteredAppointments);
    }
  }, [isCheckAppointmentByServiceIdSuccess]);

  // Handle Update Company Service Success
  useEffect(() => {
    if (prevIsUpdateCompanyServiceSuccess === false && isUpdateCompanyServiceSuccess && subService.company_service_id === updatedCompanyService.company_service_id) {
      const companyServicesCopy = [...companyServices];
      const companyService = companyServices.find((service) => service.id === serviceId);
      const companyServiceIndex = companyServices.findIndex((service) => service.id === serviceId);
      const subServices = JSON.parse(companyService.subServices);
      const subServiceIndex = subServices.findIndex((subService) => subService.company_service_id === updatedCompanyService.company_service_id);
      const selectedValue = serviceOptions.find((service) => service.id === updatedCompanyService.id);
      const staffOptions = [];
      if (updatedCompanyService.staffs) {
        updatedCompanyService.staffs.map((staff) => {
          staffOptions.push({
            id: staff.id,
            value: staff.id,
            label: `${staff.first_name} ${staff.last_name}`,
          });
        });
      }

      setSelectedServiceOption(selectedValue);
      setSelectedStaffOptions(staffOptions);
      updatedCompanyService.staff_ids = getStaffIDs(staffOptions);
      subServices[subServiceIndex] = updatedCompanyService;
      companyService.subServices = JSON.stringify(subServices);
      companyServicesCopy[companyServiceIndex] = companyService;
      setEditAble(false);
      setCompanyServices(companyServicesCopy);
    }
  }, [isUpdateCompanyServiceSuccess]);

  // Handle Update Company Service Error
  useEffect(() => {
    if (prevIsUpdateCompanyServiceError === false && isUpdateCompanyServiceError) {
      snackBarAlert(true, updateServiceCompanyErrorMessage, 'error');
    }
  }, [isUpdateCompanyServiceError]);

  // Handle Add Service Success
  useEffect(() => {
    if (prevIsAddServiceSuccess === false && isAddServiceSuccess) {
      const companyServicesCopy = [...companyServices];
      const companyService = companyServices.find((service) => service.id === serviceId);
      const companyServiceIndex = companyServices.findIndex((service) => service.id === serviceId);
      const subServices = JSON.parse(companyService.subServices);
      subServices.splice(0, 1);
      subServices.unshift(addedService);
      companyService.subServices = JSON.stringify(subServices);
      companyServicesCopy[companyServiceIndex] = companyService;
      // Add service
      const newServiceOption = {
        id: addedService.service_id,
        name: addedService.name,
        user_id: user.id,
      };
      const servicesCopy = [...services];
      const serviceItem = servicesCopy.find((service) => service.id === serviceId);
      const serviceItemIndex = servicesCopy.findIndex((service) => service.id === serviceId);
      serviceItem.subServices.push(newServiceOption);
      servicesCopy[serviceItemIndex] = serviceItem;
      setServices(servicesCopy);
      setCompanyServices(companyServicesCopy);
    }
  }, [isAddServiceSuccess]);
  // Handle Add Service Error
  useEffect(() => {
    if (prevIsAddServiceError === false && isAddServiceError) {
      snackBarAlert(true, addServiceErrorMessage, 'error');
    }
  }, [isAddServiceError]);

  const handleSaveService = () => {
    if (JSON.stringify(serviceData) !== JSON.stringify(subServiceData)) {
      checkAppointmentsByServiceId({
        service_id: subService.company_service_id,
      });
    } else {
      setEditAble(false);
    }
  };

  const handleSaveChangedService = () => {
    const serviceDataCopy = { ...serviceData };
    serviceDataCopy.parent_id = serviceId;
    serviceDataCopy.industry_id = industryId;
    serviceDataCopy.editType = filteredAppointments ? serviceDataCopy.editType : null;
    updateCompanyService(serviceDataCopy);
  };

  const validateForm = (subService) => {
    const errorsCopy = { ...errors };
    errorsCopy.service_id = (subService.service_id === '' && subService.name === '');
    errorsCopy.price = subService.price === '';
    errorsCopy.duration = subService.duration === 0;
    setErrors(() => ({ ...errorsCopy }));

    return (errorsCopy.service_id || errorsCopy.price || errorsCopy.duration);
  };

  const handleAddService = () => {
    if (!validateForm(serviceData)) {
      const serviceDataCopy = { ...serviceData };
      serviceDataCopy.single = true;
      serviceDataCopy.parent_id = serviceId;
      serviceDataCopy.industry_id = industryId;
      addService(serviceDataCopy);
    }
  };

  const getStaffIDs = (selectedOptions) => {
    const staffIds = [];
    selectedOptions.map((option) => {
      staffIds.push(option.id);
    });

    return staffIds;
  };

  const getOptionLabel = (option) => `${option.label}`;

  const handleToggleOption = (selectedOptions) => {
    const serviceDataCopy = { ...serviceData };
    serviceDataCopy.staff_ids = getStaffIDs(selectedOptions);
    setSelectedStaffOptions(selectedOptions);
    setServiceData(serviceDataCopy);
  };

  const handleClearOptions = () => {
    const serviceDataCopy = { ...serviceData };
    serviceDataCopy.staff_ids = [];
    setSelectedStaffOptions([]);
    setServiceData(serviceDataCopy);
  };

  const handleSelectAll = (isSelected) => {
    const serviceDataCopy = { ...serviceData };
    if (isSelected) {
      setSelectedStaffOptions(staffOptions);
      serviceDataCopy.staff_ids = getStaffIDs(staffOptions);
      setServiceData(serviceDataCopy);
    } else {
      handleClearOptions();
    }
  };

  const handleServiceSelectChange = (e, selectedService) => {
    const serviceDataCopy = { ...serviceData };
    if (selectedService) {
      if (typeof selectedService === 'string') {
        // timeout to avoid instant validation of the dialog's form.
        setTimeout(() => {
          setSelectedServiceOption(selectedService);
        });
      } else if (selectedService && selectedService.inputValue) {
        serviceDataCopy.name = selectedService.inputValue;
        serviceDataCopy.service_id = null;
        setSelectedServiceOption(selectedService.inputValue);
      } else {
        setSelectedServiceOption(selectedService);
        serviceDataCopy.service_id = selectedService.id;
      }
    } else {
      setSelectedServiceOption('');
      serviceDataCopy.service_id = '';
      serviceDataCopy.name = '';
    }
    setServiceData(serviceDataCopy);
  };

  const handleHourOrMinChange = (e) => {
    const { name, value } = e.target;
    const serviceDataCopy = { ...serviceData };

    if (name === 'hour') {
      const min = serviceDataCopy.duration % 60;
      serviceDataCopy.duration = (parseInt(value) * 60) + min;
    } else {
      const hour = Math.floor(serviceDataCopy.duration / 60);
      serviceDataCopy.duration = (hour * 60) + parseInt(value);
    }
    setServiceData(serviceDataCopy);
  };

  const handlePriceChange = (e) => {
    const { value } = e.target;
    const maxLength = 10; // <===
    const newValue = value < maxLength ? value : parseInt(value.toString().substring(0, maxLength));
    const serviceDataCopy = { ...serviceData };
    serviceDataCopy.price = parseInt(newValue);
    setServiceData(serviceDataCopy);
  };

  const handleDeleteService = (index) => {
    const companyServicesCopy = [...companyServices];
    const companyService = companyServices.find((service) => service.id === serviceId);
    const subServices = JSON.parse(companyService.subServices);
    const companyServiceIndex = companyServices.findIndex((service) => service.id === serviceId);
    subServices.splice(index, 1);
    companyService.subServices = JSON.stringify(subServices);
    companyServicesCopy[companyServiceIndex] = companyService;
    setCompanyServices(companyServicesCopy);
  };
  const handleDeleteItemFromService = (id) => {
    setDeleteServiceId(id);
    deleteServiceById(id);
  };
  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const classes = useStyles();
  const filter = createFilterOptions();
  return (
    <>
      <Grid
        container
        spacing={3}
        direction="row"
      >
        <Grid item xs={3}>
          <FormControl
            fullWidth
            size="small"
            variant="outlined"
            error={!!errors.service_id}
          >
            <Autocomplete
              size="small"
              value={selectedServiceOption}
              onChange={handleServiceSelectChange}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                if (params.inputValue !== '') {
                  filtered.push({
                    inputValue: params.inputValue,
                    label: `Add "${params.inputValue}"`,
                  });
                }

                return filtered;
              }}
              options={serviceOptionsList}
              getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === 'string') {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.label;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              renderOption={(option) => {
                if (option.user) {
                  return (
                    <>
                      {option.label}
                      <DeleteIcon onClick={() => handleDeleteItemFromService(option.id)} style={{ marginLeft: 'auto', color: 'red' }} />
                    </>
                  );
                } else {
                  return (
                    <>
                      {option.label}
                    </>
                  );
                }
              }}
              freeSolo
              renderInput={(params) => (
                <TextField {...params} inputProps={{ ...params.inputProps, maxLength: 50 }} label={`${t('Service')} *`} variant="outlined" error={!!errors.service_id} />
              )}
            />

            { errors.service_id && (
              <div className="error-message-content">
                <ErrorIcon fontSize="small" color="error" />
                <FormHelperText>
                  {t('Service') + t('IsRequired')}
                </FormHelperText>
              </div>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Box
            display="flex"
          >
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={!!errors.duration}
              className="duration"
            >
              <InputLabel error={!!errors.duration}>Hour *</InputLabel>
              <Select
                variant="outlined"
                data-cy="breaking-start-time"
                onChange={(e) => handleHourOrMinChange(e, subService.company_service_id)}
                value={Math.floor(serviceData.duration / 60)}
                name="hour"
                label={`${t('Hour')} *`}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                { hours.map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={!!errors.duration}
            >
              <InputLabel error={!!errors.duration}>Min *</InputLabel>
              <Select
                variant="outlined"
                data-cy="breaking-start-time"
                value={(serviceData.duration % 60)}
                onChange={(e) => handleHourOrMinChange(e, subService.company_service_id)}
                name="min"
                label={`${t('Min')} *`}
                MenuProps={{ classes: { paper: classes.menuPaper } }}
              >
                { minutes.map((min) => <MenuItem key={Math.random()} value={min}>{min}</MenuItem>) }
              </Select>
            </FormControl>
          </Box>

          { errors.duration && (
            <div className="error-message-content">
              <ErrorIcon fontSize="small" color="error" />
              <FormHelperText error>
                {t('Duration') + t('IsRequired')}
              </FormHelperText>
            </div>
          )}
        </Grid>
        <Grid item xs>
          <FormControl
            fullWidth
            size="small"
            variant="outlined"
            error={!!errors.price}
          >
            <TextField
              data-cy="price"
              size="small"
              value={serviceData.price}
              onChange={(e) => handlePriceChange(e, subService.company_service_id)}
              type="number"
              name="price"
              label={t('Price')}
              placeholder={t('Price')}
              variant="outlined"
              error={!!errors.price}
              maxLength="12"
              onKeyDown={(e) => e.key === '+' || e.key === '-' || e.key === 'e' ? e.preventDefault() : true}
            />

            { errors.price && (
              <div className="error-message-content">
                <ErrorIcon fontSize="small" color="error" />
                <FormHelperText>
                  {t('Price') + t('IsRequired')}
                </FormHelperText>
              </div>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={3}>
          <FormControl
            fullWidth
            size="small"
            variant="outlined"
          >
            <AutocompleteSelectStaff
              items={staffOptions}
              getOptionLabel={getOptionLabel}
              selectedValues={selectedStaffOptions}
              label={`${t('Staff')} *`}
              placeholder={t('Staff')}
              limitTags={2}
              onToggleOption={(selectedOptions) => handleToggleOption(selectedOptions, subService.company_service_id)}
              onClearOptions={() => handleClearOptions(subService.company_service_id)}
              onSelectAll={(isSelected) => handleSelectAll(isSelected, subService.company_service_id)}
            />
          </FormControl>
        </Grid>
        <Grid item xs>
          <Box display="flex" justifyContent="center">
            <Box mr={2}>
              <Button
                size="small"
                variant="contained"
                onClick={subService.company_service_id ? () => handleSaveService(subService.company_service_id, serviceId) : handleAddService}
                color="primary"
                className=""
              >
                {t('Save')}
              </Button>
            </Box>
            <Box>
              <Button
                size="small"
                variant="contained"
                onClick={subService.company_service_id ? () => setEditAble(false) : handleDeleteService}
                color="default"
              >
                {t('Cancel')}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <EditServiceModal
        open={openEditServiceModal}
        filteredAppointments={filteredAppointments}
        onClose={() => setOpenEditServiceModal(false)}
        serviceData={serviceData}
        setServiceData={setServiceData}
        handleSaveChangedService={handleSaveChangedService}
      />
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

EditServiceItem.propTypes = {
  services: PropTypes.object.isRequired,
  setServices: PropTypes.func.isRequired,
  companyServices: PropTypes.array.isRequired,
  subService: PropTypes.object.isRequired,
  staffOptions: PropTypes.array.isRequired,
  serviceOptions: PropTypes.array.isRequired,
  setServiceOptions: PropTypes.func.isRequired,
  serviceId: PropTypes.number.isRequired,
  setEditAble: PropTypes.func.isRequired,
  setCompanyServices: PropTypes.func.isRequired,
  industryId: PropTypes.number.isRequired,
  // companyId: PropTypes.number.isRequired,
  // Update Company Service
  updateCompanyService: PropTypes.func.isRequired,
  isUpdateCompanyServiceSuccess: PropTypes.bool.isRequired,
  isUpdateCompanyServiceError: PropTypes.bool.isRequired,
  updatedCompanyService: PropTypes.object.isRequired,
  updateServiceCompanyErrorMessage: PropTypes.string.isRequired,
  // Add Service
  addService: PropTypes.func.isRequired,
  isAddServiceSuccess: PropTypes.bool.isRequired,
  isAddServiceError: PropTypes.bool.isRequired,
  addedService: PropTypes.object.isRequired,
  addServiceErrorMessage: PropTypes.string.isRequired,
  setOpenEditServiceModal: PropTypes.func.isRequired,
  // Check Appointments
  checkAppointmentsByServiceId: PropTypes.func.isRequired,
  isCheckAppointmentByServiceIdSuccess: PropTypes.bool.isRequired,
  appointments: PropTypes.object.isRequired,
  // Delete Service By Id
  deleteServiceById: PropTypes.func.isRequired,
  isDeleteServiceByIdSuccess: PropTypes.bool.isRequired,
  // isDeleteServiceByIdError: PropTypes.bool.isRequired,
  // User
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.account.userAccount,
  // Update Company Service
  isUpdateCompanyServiceSuccess: state.service.isUpdateCompanyServiceSuccess,
  isUpdateCompanyServiceError: state.service.isUpdateCompanyServiceError,
  updatedCompanyService: state.service.updatedCompanyService,
  updateServiceCompanyErrorMessage: state.service.updateServiceCompanyErrorMessage,
  // Add Service
  isAddServiceSuccess: state.service.isAddServiceSuccess,
  isAddServiceError: state.service.isAddServiceError,
  addedService: state.service.addedService,
  addServiceErrorMessage: state.service.addServiceErrorMessage,
  // Check Appointments
  isCheckAppointmentByServiceIdSuccess: state.appointment.isCheckAppointmentByServiceIdSuccess,
  appointments: state.appointment.appointments,
  // Delete Service By Id
  isDeleteServiceByIdSuccess: state.service.isDeleteServiceByIdSuccess,
  // isDeleteServiceByIdError: state.service.isDeleteServiceByIdError,
});

function mapDispatchToProps(dispatch) {
  return {
    updateCompanyService: (data) => dispatch(updateCompanyServiceRequest(data)),
    addService: (data) => dispatch(addServiceRequest(data)),
    checkAppointmentsByServiceId: (data) => dispatch(checkAppointmentsByServiceIdRequest(data)),
    deleteServiceById: (data) => dispatch(deleteServiceByIdRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditServiceItem);
