import React from 'react';
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  LinearProgress,
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const ResendCustomer = (props) => {
  const {
    open,
    onClose,
    lineProgress,
    resendInvitationItem,
    handleResendCustomerInvitation,
  } = props;

  return (
    <Dialog
      size="sm"
      open={open}
      onClose={onClose}
    >
      <DialogTitle>
        <span className="alert-title">
          <span>Resend Invite</span>
          <span>
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        {!lineProgress
          ? (
            <Grid
              container
              spacing={2}
              direction="row"
              alignItems="center"
            >
              <Grid item xs={12}>
                <span className="invite-staff-text">
                    Resend Invite to
                  <span className="invite-staff-text-name"> {resendInvitationItem.email} </span> ?
                </span>
              </Grid>
            </Grid>
          ) : <LinearProgress />}
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={(() => handleResendCustomerInvitation(resendInvitationItem))}
        >
          Resend
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ResendCustomer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lineProgress: PropTypes.bool.isRequired,
  resendInvitationItem: PropTypes.object.isRequired,
  handleResendCustomerInvitation: PropTypes.func.isRequired,
};

export default ResendCustomer;
