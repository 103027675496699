import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Button } from '@material-ui/core';
import Logo from 'assets/img/logo.svg';
import { checkUnsubscribeTokenExistRequest, unsubscribeRequest } from '../../../redux/account/actions';
import usePrevious from '../../../CustomHooks/usePrevious';
import './style.scss';

function Unsubscribe() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const {
    isCheckUnsubscribeTokenExistSuccess,
    isCheckUnsubscribeTokenExistError,
    unsubscribeErrors,
    isUnsubscribeSuccess,
  } = useSelector((state) => state.account);

  // Get prev values
  const prevIsCheckUnsubscribeTokenExistSuccess = usePrevious(isCheckUnsubscribeTokenExistSuccess);
  const prevIsCheckUnsubscribeTokenExistError = usePrevious(isCheckUnsubscribeTokenExistError);
  const prevIsUnsubscribeSuccess = usePrevious(isUnsubscribeSuccess);

  const [isAlreadyUnsubscribed, setIsAlreadyUnsubscribed] = useState(false);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const [isInvalidToken, setIsInvalidToken] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(checkUnsubscribeTokenExistRequest({ token }));
  }, []);

  // Check unsubscribe token success
  useEffect(() => {
    if (prevIsCheckUnsubscribeTokenExistSuccess === false && isCheckUnsubscribeTokenExistSuccess) {
      setLoading(false);
    }
  }, [isCheckUnsubscribeTokenExistSuccess]);

  // Check unsubscribe token error
  useEffect(() => {
    if (prevIsCheckUnsubscribeTokenExistError === false && isCheckUnsubscribeTokenExistError) {
      if (unsubscribeErrors.token) {
        setIsInvalidToken(true);
      } else if (unsubscribeErrors.unsubscribed) {
        setIsAlreadyUnsubscribed(true);
      }

      setLoading(false);
    }
  }, [isCheckUnsubscribeTokenExistError]);

  useEffect(() => {
    if (prevIsUnsubscribeSuccess === false && isUnsubscribeSuccess) {
      setIsUnsubscribed(true);
      setLoading(false);
    }
  }, [isUnsubscribeSuccess]);

  const unsubscribe = () => {
    dispatch(unsubscribeRequest({ token }));
    setLoading(true);
  };

  const cancel = () => {
    window.location.href = 'https://www.settime.io/';
  };

  const renderAlert = () => (
    <>
      {isAlreadyUnsubscribed ? (
        <div className="unsubscribe-success">
          <p>
              You have already unsubscribed!
          </p>
        </div>
      ) : (
        <p className="unsubscribe-success">
          The page not found
        </p>
      )}
    </>
  );

  return !loading ? (
    <div className="unsubscribe">
      {!isInvalidToken && (
        <div className="logo">
          <img src={Logo} alt="Logo" />
        </div>
      )}
      {isUnsubscribed ? (
        <div className="unsubscribe-success">
          <p>
            Unsubscribed successfully! You will no longer receive periodic emails from us.
          </p>
        </div>
      ) : (
        <>
          {isAlreadyUnsubscribed || isInvalidToken ? (
            <>{renderAlert()}</>
          ) : (
            <div className="content">
              <p>
                Do you want to unsubscribe?
              </p>
              <p>
                You will no longer receive periodic emails from SetTime.
              </p>

              <hr className="line" />

              <div className="buttons-group">
                <Button variant="outlined" className="yes" onClick={unsubscribe}>
                  Yes, unsubscribe
                </Button>

                <Button variant="outlined" onClick={cancel}>
                  No, cancel
                </Button>
              </div>
            </div>
          )}
        </>
      )}

    </div>
  ) : (
    <div className="unsubscribe-loader">
      <CircularProgress size={70} />
    </div>
  );
}

export default Unsubscribe;
