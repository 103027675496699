import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Switch,
  Grid,
  Link,
  Box,
  MenuItem,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import hours from '../../../../../../../Modules/hours';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';
import { updateBreakingHoursRequest } from '../../../../../../../redux/businnessHours/actions';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import {
  checkIndividualUserIndustryDayAppointmentsRequest,
} from '../../../../../../../redux/appointment/actions';
import weekDayNumber from '../../../../../../../Modules/weekDayNumber';

function BreakingHours(props) {
  const dispatch = useDispatch();

  const {
    // Check Day Appointments
    isCheckIndividualUserIndustryDayAppointmentsSuccess,
    isCheckIndividualUserIndustryDayAppointmentsError,
    individualUserIndustryDayAppointments,
    // Cancel Day Appointments
    isCancelIndividualUserIndustryDayAppointmentsSuccess,
    isCancelIndividualUserIndustryDayAppointmentsError,
    cancelIndividualUserIndustryDayAppointmentsErrorMessage,
  } = useSelector((state) => state.appointment);

  const {
    setWeekDay,
    cancelData,
    setLoading,
    setCancelData,
    workingClosedHours,
    individualUserIndustryItem,
    changeIndividualIndustryHours,
    setHaveAppointmentsModalShown,
  } = props;

  // Get some props previous values
  const prevIsCheckIndividualUserIndustryDayAppointmentsSuccess = usePrevious(isCheckIndividualUserIndustryDayAppointmentsSuccess);
  const prevIsCheckIndividualUserIndustryDayAppointmentsError = usePrevious(isCheckIndividualUserIndustryDayAppointmentsError);
  const prevIsCancelIndividualUserIndustryDayAppointmentsSuccess = usePrevious(isCancelIndividualUserIndustryDayAppointmentsSuccess);
  const prevIsCancelIndividualUserIndustryDayAppointmentsError = usePrevious(isCancelIndividualUserIndustryDayAppointmentsError);

  const [breakingHours, setBreakingHours] = useState(individualUserIndustryItem.breakingHours);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState(false);
  const [dayId, setDayId] = useState('');
  const [breakToggle, setBreakToggle] = useState(false);
  const [applyBreakingHours, setApplyBreakingHours] = useState([]);
  const [checkBreakingHours, setCheckBreakingHours] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const { workingHours } = individualUserIndustryItem;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // Handle Get Individual User Industry Day Appointments Success
  useEffect(() => {
    if (prevIsCheckIndividualUserIndustryDayAppointmentsSuccess === false && isCheckIndividualUserIndustryDayAppointmentsSuccess && checkBreakingHours) {
      if (individualUserIndustryDayAppointments.length > 0) {
        if (dayId) {
          const weekDay = workingHours.find((hour) => hour.week_day_id === dayId).week_day;
          setWeekDay(weekDay);
        }
        const appointmentsIds = [];
        individualUserIndustryDayAppointments.map((item) => {
          appointmentsIds.push(item.id);
        });
        setCancelData({
          appointment_ids: appointmentsIds,
          name: 'break',
        });
        setHaveAppointmentsModalShown(true);
      } else if (breakToggle) {
        handleWorkingHoursDetailsChange(name, value, checked, dayId);
      } else if (dayId) {
        handleBreakingHoursDetailsChange(name, value, checked, dayId);
      } else {
        setBreakingHours(applyBreakingHours);
        dispatch(updateBreakingHoursRequest({
          individualUserIndustryId: individualUserIndustryItem.id,
          breakingHours: applyBreakingHours,
        }));
      }

      setCheckBreakingHours(false);
    }
  }, [isCheckIndividualUserIndustryDayAppointmentsSuccess]);

  // Handle Get Individual User Industry Day Appointments Error
  useEffect(() => {
    if (prevIsCheckIndividualUserIndustryDayAppointmentsError === false && isCheckIndividualUserIndustryDayAppointmentsError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isCheckIndividualUserIndustryDayAppointmentsError]);

  // Handle cancel appointments success
  useEffect(() => {
    if (prevIsCancelIndividualUserIndustryDayAppointmentsSuccess === false && isCancelIndividualUserIndustryDayAppointmentsSuccess && cancelData.name === 'break') {
      setHaveAppointmentsModalShown(false);
      if (breakToggle) {
        handleWorkingHoursDetailsChange(name, value, checked, dayId);
      } else {
        handleBreakingHoursDetailsChange(name, value, checked, dayId);
      }
    }
  }, [isCancelIndividualUserIndustryDayAppointmentsSuccess]);

  // Handle cancel appointments success
  useEffect(() => {
    if (prevIsCancelIndividualUserIndustryDayAppointmentsError === false && cancelIndividualUserIndustryDayAppointmentsErrorMessage) {
      setOpenSnackbar(true);
      setSnackbarType('error');
      setSnackbarMessage(cancelIndividualUserIndustryDayAppointmentsErrorMessage);
    }
  }, [cancelIndividualUserIndustryDayAppointmentsErrorMessage]);

  const handleBreakingHoursDetailsChange = (name, value, checked, id) => {
    const val = checked !== undefined ? checked : value;
    const breakingHoursCopy = [...individualUserIndustryItem.breakingHours];
    const breakingHourIndex = breakingHoursCopy.findIndex((hour) => hour.week_day_id === id);
    if (name === 'is_breaking') {
      breakingHoursCopy[breakingHourIndex][name] = val;
      setBreakingHours(breakingHoursCopy);
    } else if (name === 'start_time') {
      const startTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(breakingHoursCopy[breakingHourIndex].end_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        breakingHoursCopy[breakingHourIndex][name] = val;
        setBreakingHours(breakingHoursCopy);
      } else {
        snackBarAlert(true, i18n.t('StartTimeEarlier'), 'error');

        return false;
      }
    } else if (name === 'end_time') {
      const startTime = moment(breakingHoursCopy[breakingHourIndex].start_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        breakingHoursCopy[breakingHourIndex][name] = val;
        setBreakingHours(breakingHoursCopy);
      } else {
        snackBarAlert(true, i18n.t('EndTimeAfter'), 'error');

        return false;
      }
    }

    dispatch(updateBreakingHoursRequest({
      individualUserIndustryId: individualUserIndustryItem.id,
      breakingHours: breakingHoursCopy,
    }));
    setLoading(false);
  };

  const getAvailableBreakingHours = (hours, workingHours, weekDayId, breakingStart, breakingEnd) => {
    const startTime = moment(workingHours[weekDayId - 1].start_time, 'HH::mm').format('HH:mm');
    let endTime = moment(workingHours[weekDayId - 1].end_time, 'HH::mm').format('HH:mm');
    endTime = endTime !== '00:00' ? endTime : '24:00';
    if (breakingStart && !hours.includes(breakingStart)) {
      hours.push(breakingStart);
      hours.sort();
    } else if (breakingEnd && !hours.includes(breakingEnd)) {
      hours.push(breakingEnd);
      hours.sort();
    }
    return hours.filter((hour) => (hour >= startTime && hour <= endTime));
  };

  const handleWorkingHoursDetailsChange = (name, value, checked, id) => {
    const val = checked !== undefined ? checked : value;
    const breakingHoursCopy = [...individualUserIndustryItem.breakingHours];
    const workingHoursCopy = [...individualUserIndustryItem.workingHours];
    const breakingHourIndex = breakingHoursCopy.findIndex((hour) => hour.week_day_id === id);
    const workingHourIndex = workingHoursCopy.findIndex((hour) => hour.week_day_id === id);
    breakingHoursCopy[breakingHourIndex]['is_breaking'] = val;
    setBreakingHours(breakingHoursCopy);

    if (checked) {
      workingHoursCopy[workingHourIndex][name] = val;
      changeIndividualIndustryHours(workingHoursCopy);
    }

    dispatch(updateBreakingHoursRequest({
      individualUserIndustryId: individualUserIndustryItem.id,
      breakingHours: breakingHoursCopy,
    }));

    setLoading(false);
  };

  const handleCheckAppointments = (event, id) => {
    const { name, value, checked } = event.target;
    const breakingHoursCopy = [...individualUserIndustryItem.breakingHours];
    const breakingHour = breakingHoursCopy.find((hour) => hour.week_day_id === id);
    setName(name);
    setValue(value);
    setChecked(checked);
    setDayId(id);
    setCheckBreakingHours(true);
    const breakStartTime = (name === 'start_time' ? value : breakingHour.start_time);
    const breakEndTime = (name === 'end_time' ? value : breakingHour.end_time);
    dispatch(checkIndividualUserIndustryDayAppointmentsRequest({
      individual_user_industry_id: individualUserIndustryItem.id,
      day: weekDayNumber(id),
      break_start_time: breakStartTime,
      break_end_time: breakEndTime,
    }));
  };

  const handleToggleCheckAppointments = (event, id) => {
    const { name, value, checked } = event.target;
    const breakingHoursCopy = [...individualUserIndustryItem.breakingHours];
    const breakingHour = breakingHoursCopy.find((hour) => hour.week_day_id === id);
    if (checked) {
      handleWorkingHoursDetailsChange(name, value, checked, id);
    } else {
      setName(name);
      setValue(value);
      setChecked(checked);
      setDayId(id);
      setCheckBreakingHours(true);
      setBreakToggle(true);
      dispatch(checkIndividualUserIndustryDayAppointmentsRequest({
        individual_user_industry_id: individualUserIndustryItem.id,
        day: weekDayNumber(id),
        break_start_time: breakingHour.start_time,
        break_end_time: breakingHour.end_time,
      }));
    }
  };

  const applyToAll = (firstBreakHour) => {
    const breakingHoursCopy = [...individualUserIndustryItem.breakingHours];

    breakingHoursCopy.map((breakHour) => {
      breakHour.start_time = firstBreakHour.start_time;
      breakHour.end_time = firstBreakHour.end_time;
    });

    setApplyBreakingHours(breakingHoursCopy);
    setCheckBreakingHours(true);
    dispatch(checkIndividualUserIndustryDayAppointmentsRequest({
      individual_user_industry_id: individualUserIndustryItem.id,
      breaking_days: breakingHoursCopy,
    }));
  };

  const getSelectedLowestWeekDayId = () => {
    let lowestWeekDayId = Number.POSITIVE_INFINITY;
    breakingHours.map((breakHour) => {
      const weekDayId = parseInt(breakHour.week_day_id);
      if (weekDayId < lowestWeekDayId && breakHour.is_breaking) lowestWeekDayId = weekDayId;
    });

    return lowestWeekDayId;
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  return (
    <>
      <Box py="25px" px="30px" className="tab-item" id="tab-main-parent">
        <Box display="flex" m="0">
          <div className="business-hours">
            <div className="hours-content">
              { breakingHours.map((breakHour, index) => (
                <Box display="flex" key={`${breakHour.week_day_id}-${index}`}>
                  <Box minWidth="173px" className="day-content" key={Math.random()}>
                    <Box minWidth="120">
                      <Typography component="div">
                        <Grid component="label" container alignItems="center" spacing={1}>
                          <Grid item>
                            <Switch
                              checked={breakHour.is_breaking}
                              onChange={(event) => handleToggleCheckAppointments(event, breakHour.week_day_id)}
                              name="is_working"
                              color="primary"
                              size="small"
                            />
                          </Grid>
                          {breakHour.is_breaking ? (
                            <Grid item> <Typography color="dark" variant="body1"> On </Typography> </Grid>
                          ) : <Typography color="dark" variant="body1"> Off </Typography>}
                        </Grid>
                      </Typography>
                    </Box>
                  </Box>
                  <Box display="flex" minWidth="173px" className="day-content" key={Math.random()}>
                    {breakHour.is_breaking ? (
                      <>
                        <Box display="flex">
                          <div className="hours-select">
                            <Select
                              variant="outlined"
                              disabled={workingClosedHours ? !workingClosedHours[index]['is_working'] : !breakHour.is_breaking && true}
                              disableUnderline
                              data-cy="breaking-start-time"
                              value={breakHour.start_time}
                              name="start_time"
                              MenuProps={MenuProps}
                              onChange={(event) => handleCheckAppointments(event, breakHour.week_day_id)}
                            >
                              { getAvailableBreakingHours(hours, workingHours, breakHour.week_day_id, breakHour.start_time, null).map((hour) => <MenuItem key={Math.random()} disabled={hour === breakHour.end_time} value={hour}>{hour}</MenuItem>) }
                            </Select>
                            <span>-</span>
                            <Select
                              variant="outlined"
                              disabled={workingClosedHours ? !workingClosedHours[index]['is_working'] : !breakHour.is_breaking && true}
                              data-cy="breaking-end-time"
                              disableUnderline
                              value={breakHour.end_time}
                              name="end_time"
                              MenuProps={MenuProps}
                              onChange={(event) => handleCheckAppointments(event, breakHour.week_day_id)}
                            >
                              { getAvailableBreakingHours(hours, workingHours, breakHour.week_day_id, null, breakHour.end_time).map((hour) => <MenuItem key={Math.random()} value={hour} disabled={hour <= breakHour.start_time}>{hour}</MenuItem>) }
                            </Select>
                          </div>
                        </Box>
                        {getSelectedLowestWeekDayId(breakHour.week_day_id) === parseInt(breakHour.week_day_id) && breakHour.is_breaking && (
                          <Box ml="10px">
                            <Link href="#" onClick={() => applyToAll(breakHour)} color="inherit">
                              {i18n.t('ApplyToAll')}
                            </Link>
                          </Box>
                        )}
                      </>
                    ) : (
                      <Box className="selects-border" />
                    )}
                  </Box>
                </Box>
              )) }
            </div>
          </div>
        </Box>
      </Box>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

BreakingHours.propTypes = {
  // Update Breaking Hours Props
  setWeekDay: PropTypes.func.isRequired,
  cancelData: PropTypes.object.isRequired,
  setLoading: PropTypes.func.isRequired,
  setCancelData: PropTypes.func.isRequired,
  workingClosedHours: PropTypes.array.isRequired,
  individualUserIndustryItem: PropTypes.object.isRequired,
  changeIndividualIndustryHours: PropTypes.func.isRequired,
  setHaveAppointmentsModalShown: PropTypes.bool.isRequired,
};

export default BreakingHours;
