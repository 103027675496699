import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Switch,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import i18n from 'i18next';
import BreakingHours from '../breakingHours';
import WorkingHours from '../workingHours';
import WorkingScheduleLoading from '../../../../../../Components/Loading/workingScheduleLoading';
import SnackbarToast from '../../../../../../Modules/SnackbarToast';
import { checkCompanyDayAppointmentsRequest } from '../../../../../../redux/appointment/actions';
import usePrevious from '../../../../../../CustomHooks/usePrevious';
import HaveAppointmentsModal from '../../../../../../Modals/IndividualUserIndustry/HaveAppointments';
import { updateWorkingHoursRequest } from '../../../../../../redux/businnessHours/actions';
import weekDayNumber from '../../../../../../Modules/weekDayNumber';

function WorkingSchedule(props) {
  const dispatch = useDispatch();
  const {
    // Check Day Appointments
    isCheckCompanyDayAppointmentsSuccess,
    isCheckCompanyDayAppointmentsError,
    companyDayAppointments,
    // Cancel Day Appointments
    isCancelCompanyDayAppointmentsSuccess,
    isCancelCompanyDayAppointmentsError,
    cancelCompanyDayAppointmentsErrorMessage,
  } = useSelector((state) => state.appointment);

  const {
    // Update Working Hours
    isUpdateWorkingHoursSuccess,
    isUpdateWorkingHoursError,
    updatedWorkingHours,
    // Update Breaking Hours
    isUpdateBreakingHoursSuccess,
    isUpdateBreakingHoursError,
    updatedBreakingHours,
  } = useSelector((state) => state.businessHours);

  const {
    schedule,
  } = props;

  // Get some props previous values
  const prevIsCheckCompanyDayAppointmentsSuccess = usePrevious(isCheckCompanyDayAppointmentsSuccess);
  const prevIsCheckCompanyDayAppointmentsError = usePrevious(isCheckCompanyDayAppointmentsError);
  const prevIsCancelCompanyDayAppointmentsSuccess = usePrevious(isCancelCompanyDayAppointmentsSuccess);
  const prevIsCancelCompanyDayAppointmentsError = usePrevious(isCancelCompanyDayAppointmentsError);
  const prevIsUpdateWorkingHoursSuccess = usePrevious(isUpdateWorkingHoursSuccess);
  const prevIsUpdateWorkingHoursError = usePrevious(isUpdateWorkingHoursError);
  const prevIsUpdateBreakingHoursSuccess = usePrevious(isUpdateBreakingHoursSuccess);
  const prevIsUpdateBreakingHoursError = usePrevious(isUpdateBreakingHoursError);

  const [workingClosedHours, setWorkingClosedHours] = useState(null);
  const [workingHours, setWorkingHours] = useState([]);
  const [breakingHours, setBreakingHours] = useState([]);
  const [haveAppointmentsModalShown, setHaveAppointmentsModalShown] = useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState('');
  const [checked, setChecked] = useState('');
  const [dayId, setDayId] = useState('');
  const [weekDay, setWeekDay] = useState('');
  const [checkDay, setCheckDay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [cancelData, setCancelData] = useState({});
  const [workingSchedule, setWorkingSchedule] = useState({});

  // Working Schedule
  useEffect(() => {
    if (Object.keys(schedule).length > 0) {
      setLoading(true);
      setButtonLoading(true);
      setWorkingHours(schedule.working_hours);
      setBreakingHours(schedule.breaking_hours);
      setWorkingSchedule(schedule);
    }
  }, [schedule]);

  // Handle Check Company Day Appointments Success
  useEffect(() => {
    if (prevIsCheckCompanyDayAppointmentsSuccess === false && isCheckCompanyDayAppointmentsSuccess && checkDay) {
      if (companyDayAppointments.length > 0) {
        const weekDay = workingHours.find((hour) => hour.week_day_id === dayId).week_day;
        setWeekDay(weekDay);
        const appointmentsIds = [];
        companyDayAppointments.map((item) => {
          appointmentsIds.push(item.id);
        });
        setCancelData({
          appointment_ids: appointmentsIds,
          name: 'toggle',
        });
        setHaveAppointmentsModalShown(true);
      } else {
        handleWorkingHoursToggleDetailsChange(name, value, checked, dayId);
      }

      setCheckDay(false);
    }
  }, [isCheckCompanyDayAppointmentsSuccess]);

  // Handle Check Company Day Appointments Error
  useEffect(() => {
    if (prevIsCheckCompanyDayAppointmentsError === false && isCheckCompanyDayAppointmentsError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isCheckCompanyDayAppointmentsError]);

  // Handle Update Working Hours Success
  useEffect(() => {
    if (prevIsUpdateWorkingHoursSuccess === false && isUpdateWorkingHoursSuccess) {
      setLoading(true);
      setButtonLoading(true);
      setWorkingSchedule((prevState) => ({
        ...prevState,
        workingHours: updatedWorkingHours,
      }));
      setWorkingHours(updatedWorkingHours);
      snackBarAlert(true, 'Working days updated successfully', 'success');
    }
  }, [isUpdateWorkingHoursSuccess]);

  // Handle Update Working Hours Error
  useEffect(() => {
    if (prevIsUpdateWorkingHoursError === false && isUpdateWorkingHoursError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isUpdateWorkingHoursError]);

  // Handle Update Breaking Hours Success
  useEffect(() => {
    if (prevIsUpdateBreakingHoursSuccess === false && isUpdateBreakingHoursSuccess) {
      setLoading(true);
      setButtonLoading(true);
      setWorkingSchedule((prevState) => ({
        ...prevState,
        breakingHours: updatedBreakingHours,
      }));
      snackBarAlert(true, 'Break time updated successfully', 'success');
    }
  }, [isUpdateBreakingHoursSuccess]);

  // Handle Update Breaking Hours Error
  useEffect(() => {
    if (prevIsUpdateBreakingHoursError === false && isUpdateBreakingHoursError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isUpdateBreakingHoursError]);

  // Handle cancel appointments success
  useEffect(() => {
    if (prevIsCancelCompanyDayAppointmentsSuccess === false && isCancelCompanyDayAppointmentsSuccess && cancelData.name === 'toggle') {
      setHaveAppointmentsModalShown(false);
      handleWorkingHoursToggleDetailsChange(name, value, checked, dayId);
    }
  }, [isCancelCompanyDayAppointmentsSuccess]);

  // Handle cancel appointments success
  useEffect(() => {
    if (prevIsCancelCompanyDayAppointmentsError === false && isCancelCompanyDayAppointmentsError) {
      setOpenSnackbar(true);
      setSnackbarType('error');
      setSnackbarMessage(cancelCompanyDayAppointmentsErrorMessage);
    }
  }, [isCancelCompanyDayAppointmentsError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const changeWorkingHours = (value) => {
    setWorkingClosedHours(value);
  };

  const handleCheckAppointments = (event, id) => {
    const { name, value, checked } = event.target;
    if (checked) {
      handleWorkingHoursToggleDetailsChange(name, value, checked, id);
    } else {
      setCheckDay(true);
      dispatch(checkCompanyDayAppointmentsRequest({
        day: weekDayNumber(id),
      }));
      setName(name);
      setValue(value);
      setChecked(checked);
      setDayId(id);
    }
  };

  const handleWorkingHoursToggleDetailsChange = (name, value, checked, id) => {
    const val = checked !== undefined ? checked : value;
    const workingHoursCopy = [...workingHours];
    const breakingHoursCopy = [...breakingHours];
    const workingHourIndex = workingHoursCopy.findIndex((hour) => hour.week_day_id === id);
    const breakingHourIndex = breakingHoursCopy.findIndex((hour) => hour.week_day_id === id);
    changeWorkingHours(workingHoursCopy);
    if (name === 'is_working') {
      workingHoursCopy[workingHourIndex][name] = val;
      breakingHoursCopy[breakingHourIndex]['is_breaking'] = val;
      setWorkingHours(workingHoursCopy);
    } else if (name === 'start_time') {
      const startTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(workingHoursCopy[workingHourIndex].end_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        workingHoursCopy[workingHourIndex][name] = val;
        breakingHoursCopy[breakingHourIndex]['is_breaking'] = val;
        setWorkingHours(workingHoursCopy);
      } else {
        snackBarAlert(true, i18n.t('StartTimeEarlier'), 'error');

        return false;
      }
    } else if (name === 'end_time') {
      const startTime = moment(workingHoursCopy[workingHourIndex].start_time, 'HH::mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(val, 'HH::mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        workingHoursCopy[workingHourIndex][name] = val;
        setWorkingHours(workingHoursCopy);
      } else {
        snackBarAlert(true, i18n.t('EndTimeAfter'), 'error');

        return false;
      }
    }

    dispatch(updateWorkingHoursRequest({
      businessDays: workingHoursCopy,
    }));
  };

  return (
    <>
      {loading ? (
        <>
          <Grid container xs={12} spacing={2} id="working-schedule-content">
            <Grid container className="working-schedule-box">
              <Grid xs={12} className="working-schedule-title">
                <Typography variant="h6" id="tableTitle" component="div">
                  {i18n.t('GeneralSchedule.WeekDays')}
                </Typography>
              </Grid>
              <Grid container className="general-schedule">
                <Grid item xs={3} className="tab-item week-day-switch working-schedule" id="tab-main-parent">
                  <Box className="time-zone">
                    <span>{i18n.t('GeneralSchedule.WeekDays')}</span>
                  </Box>
                  <Box py="25px" px="17px">
                    { workingHours.map((workHour) => (
                      <div className="hours-content" key={`${workHour.week_day}`}>
                        <Box display="flex" minWidth="173px" className="day-content" key={Math.random()}>
                          <Box minWidth="91px">
                            <div className="day-name">
                              <span data-cy="week-day-name">{ i18n.t(`${workHour.week_day}`) }</span>
                            </div>
                          </Box>
                          <Box minWidth="91px">
                            <Typography component="div">
                              <Grid component="label" container alignItems="center" spacing={1}>
                                <Grid item>
                                  <Switch
                                    checked={workHour.is_working}
                                    onChange={(event) => handleCheckAppointments(event, workHour.week_day_id)}
                                    name="is_working"
                                    color="primary"
                                    size="small"
                                  />
                                </Grid>
                                {workHour.is_working ? (
                                  <Grid item> <Typography color="initial" variant="body1"> {i18n.t('Open')} </Typography> </Grid>
                                ) : <Typography color="initial" variant="body1"> {i18n.t('Close')} </Typography>}
                              </Grid>
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    ))}
                  </Box>
                </Grid>
                <Grid item className="working-schedule">
                  <div className="time-zone working">
                    <span>{i18n.t('GeneralSchedule.WorkingHours')}</span>
                  </div>
                  <WorkingHours
                    workingTimes={workingSchedule.working_hours}
                    breakingTimes={workingSchedule.breaking_hours}
                    setWeekDay={setWeekDay}
                    cancelData={cancelData}
                    setLoading={setLoading}
                    setCancelData={setCancelData}
                    changeCompanyHours={changeWorkingHours}
                    setHaveAppointmentsModalShown={setHaveAppointmentsModalShown}
                    checkedCopy={checked}
                  />
                </Grid>
                <Grid className="breaking-schedule">
                  <div className="time-zone">
                    <span>{i18n.t('GeneralSchedule.BreakHours')}</span>
                  </div>
                  <BreakingHours
                    workingTimes={workingSchedule.working_hours}
                    breakingTimes={workingSchedule.breaking_hours}
                    setCancelData={setCancelData}
                    cancelData={cancelData}
                    setLoading={setLoading}
                    setWeekDay={setWeekDay}
                    workingClosedHours={workingClosedHours}
                    changeCompanyHours={changeWorkingHours}
                    setHaveAppointmentsModalShown={setHaveAppointmentsModalShown}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SnackbarToast
            message={snackbarMessage}
            type={snackbarType}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
          />

          <HaveAppointmentsModal
            open={haveAppointmentsModalShown}
            onClose={() => setHaveAppointmentsModalShown(false)}
            weekDay={weekDay}
            from="company"
            cancelData={cancelData}
            buttonLoading={buttonLoading}
            setButtonLoading={setButtonLoading}
          />
        </>
      ) : <WorkingScheduleLoading />}
    </>
  );
}

WorkingSchedule.propTypes = {
  schedule: PropTypes.object.isRequired,
};

export default WorkingSchedule;
