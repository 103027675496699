import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
  InputLabel,
  Select,
  TextField,
  FormControl,
  MenuItem,
  Box,
  FormHelperText, Typography, InputAdornment,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ErrorIcon from '@material-ui/icons/Error';
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import DateRangeIcon from '@material-ui/icons/DateRange';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import moment from 'moment';
import i18n from 'i18next';

import { getIndustriesRequest } from '../../../../../../../redux/industry/actions';
import countryCodeOptions from '../../../../../../../Modules/countryCodeOptions';
import {
  getCitiesRequest,
  getStatesRequest,
} from '../../../../../../../redux/country/actions';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import {
  updateIndividualUserIndustryRequest,
} from '../../../../../../../redux/individualUser/actions';
import TextMaskCustom from '../../../../../../../Modules/TextMaskCustom';
import InfoLoading from '../../../../../../../Components/Loading/infoLoading';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 150,
  height: 150,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner = {
  position: 'relative',
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

function Info(props) {
  const {
    sendIndustryUpdated,
    allCountries,
    getIndustries,
    industries,
    isGetIndustriesSuccess,
    states,
    getStates,
    isGetStatesSuccess,
    isGetStatesError,
    cities,
    getCities,
    isGetCitiesSuccess,
    isGetCitiesError,
    isUpdateIndustrySubmitDone,
    individualUserIndustryItem,
    isUpdateIndividualUserIndustrySuccess,
    isUpdateIndividualUserIndustryError,
    updateIndividualUserIndustryErrors,
    updateIndividualUserIndustry,
    updatedIndividualUserIndustry,
  } = props;

  // Get some props values
  const prevIsGetCitiesSuccess = usePrevious(isGetCitiesSuccess);
  const prevIsGetCitiesError = usePrevious(isGetCitiesError);
  const prevIsUpdateIndividualUserIndustrySuccess = usePrevious(isUpdateIndividualUserIndustrySuccess);
  const prevIsUpdateIndividualUserIndustryError = usePrevious(isUpdateIndividualUserIndustryError);

  const [loading, setLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState([]);
  const [codeOptions, setCodeOptions] = useState([]);
  const [selectedCountryOption, setSelectedCountryOption] = useState({});
  const [stateOptions, setStateOptions] = useState([]);
  const [selectedStateOption, setSelectedStateOption] = useState({});
  const [, setUserIndustryCountry] = useState({});
  const [cityOptions, setCityOptions] = useState([]);
  const [selectedCityOption, setSelectedCityOption] = useState({});
  const [updateErrors, setUpdateErrors] = useState({});
  // Industry*
  const [selectedIndustryOption, setSelectedIndustryOption] = useState({});
  const [selectedIndustryPhoneCode, setSelectedIndustryPhoneCode] = useState('');
  const [files, setFiles] = useState(null);
  const [industry, setIndustry] = useState({});
  const [errors, setErrors] = useState({
    name: false,
    industry: false,
    email: false,
    country: false,
    state: false,
    city: false,
    address: false,
    phone_code: false,
    phone_number: false,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [selectedTimeZoneOption, setSelectedTimeZoneOption] = useState('');
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);

  const CHARACTER_LIMIT = 10;

  useEffect(() => {
    if (Object.keys(individualUserIndustryItem).length) {
      setSelectedIndustryPhoneCode(individualUserIndustryItem.phone_code);
      setIndustry(individualUserIndustryItem);
      setSelectedTimeZoneOption(individualUserIndustryItem.timezone);
      getIndustries();
    }
    const countryOptions = countryCodeOptions.getCountryOptions(allCountries);
    setCountryOptions(countryOptions);
    const codeOptions = countryCodeOptions.getCodeOptions(allCountries);
    setCodeOptions(codeOptions);

    if (individualUserIndustryItem.country_id) {
      const selectedCountryOption = countryOptions.find((item) => item.id === individualUserIndustryItem.country_id);
      setSelectedCountryOption(selectedCountryOption);
      const userIndustryCountry = countryOptions.find((item) => item.id === individualUserIndustryItem.country_id);
      setUserIndustryCountry(userIndustryCountry);
    }
    const timeZones = moment.tz.names();
    const timeZoneNamesList = [];
    timeZones.forEach((item, i) => timeZoneNamesList.push(` (GMT${moment.tz(timeZones[i]).format('Z')})${timeZones[i]}`));
    setTimeZoneOptions(timeZoneNamesList);
  }, []);

  // Check if update industry success
  useEffect(() => {
    if (prevIsUpdateIndividualUserIndustrySuccess === false && isUpdateIndividualUserIndustrySuccess) {
      sendIndustryUpdated(updatedIndividualUserIndustry, true);
      snackBarAlert(true, i18n.t('IndustryUpdated'), 'success');
      setUpdateErrors({});
    }
  }, [isUpdateIndividualUserIndustrySuccess]);

  // Check if update industry error
  useEffect(() => {
    if (prevIsUpdateIndividualUserIndustryError === false && isUpdateIndividualUserIndustryError) {
      setUpdateErrors(updateIndividualUserIndustryErrors);
      sendIndustryUpdated(null);
    }
  }, [isUpdateIndividualUserIndustryError]);
  useEffect(() => {
    if (isGetIndustriesSuccess) {
      const industryOptions = industries.map((industry) => ({
        value: industry.id,
        label: industry.name,
      }));
      const selectedIndustryOption = industryOptions.find((item) => item.value === parseInt(individualUserIndustryItem.industry_id));
      setSelectedIndustryOption(selectedIndustryOption);
      if (industry.country_id) {
        getStates(industry.country_id);
      }
    }
  }, [isGetIndustriesSuccess]);

  // Check If Get States Is Success
  useEffect(() => {
    if (isGetStatesSuccess) {
      const stateOptions = countryCodeOptions.getStateOptions(states);
      setStateOptions(stateOptions);
      if (selectedCountryOption.id === industry.country_id) {
        if (industry.state_id) {
          const selectedStateOption = stateOptions.find((item) => item.id === industry.state_id);
          if (selectedStateOption && industry.state_id) {
            setSelectedStateOption(selectedStateOption);
            getCities(industry.state_id);
          } else {
            setLoading(false);
          }
        }
      }
    }
  }, [isGetStatesSuccess]);

  // Check If Get States Is Error
  useEffect(() => {
    if (isGetStatesError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetStatesError]);

  // Check If Get Cities Is Success
  useEffect(() => {
    if (prevIsGetCitiesSuccess === false && isGetCitiesSuccess) {
      const cityOptions = countryCodeOptions.getCitiesOptions(cities);
      setCityOptions(cityOptions);
      if (selectedCountryOption.id === industry.country_id) {
        const selectedCityOption = cityOptions.find((item) => item.id === industry.city_id);
        if (selectedCityOption) {
          setSelectedCityOption(selectedCityOption);
        }
        setLoading(false);
      }
    }
  }, [isGetCitiesSuccess]);

  // Check If Get Cities Is Error
  useEffect(() => {
    if (prevIsGetCitiesError === false && isGetCitiesError) {
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
      setLoading(false);
    }
  }, [isGetCitiesError]);

  // Handle update industry submit
  useEffect(() => {
    if (isUpdateIndustrySubmitDone) {
      const errors = validateForm();
      const keys = Object.keys(errors);
      const errorExists = keys.find((key) => errors[key]);
      if (!errorExists) {
        updateIndividualUserIndustry(industry);
      } else {
        sendIndustryUpdated(null);
      }
    }
  }, [isUpdateIndustrySubmitDone]);

  const validateForm = () => {
    const errorsCopy = { ...errors };
    const keys = Object.keys(industry);
    keys.map((key) => {
      switch (key) {
        case 'first_name': {
          errorsCopy.name = industry.first_name === null || industry.first_name.length === 0;
          break;
        }
        case 'address': {
          errorsCopy.address = industry.address === null || industry.address.length === 0;
          break;
        }
        case 'phone_number': {
          errorsCopy.phone_number = !industry.phone_number ? 'Phone number is required'
            : industry.phone_number.length < 12 ? 'Phone number is not valid' : null;
          break;
        }
        case 'phone_code': {
          errorsCopy.phone_code = industry.phone_code.length === 0;
          break;
        }
        case 'industry_id': {
          errorsCopy.industry = !(industry.industry_id);
          break;
        }
        case 'email': {
          errorsCopy.email = industry.email ? !industry.email.includes('@') : false;
          break;
        }
        case 'country_id': {
          errorsCopy.country = !(industry.country_id);
          break;
        }
        case 'state_id': {
          errorsCopy.state = !(industry.state_id);
          break;
        }
        case 'city_id': {
          errorsCopy.city = !(industry.city_id);
          break;
        }
        default: {
          break;
        }
      }
    });
    setErrors(() => ({ ...errorsCopy }));
    return errorsCopy;
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleCountrySelectChange = (e, selectedCountry) => {
    if (selectedCountry) {
      const { getStates } = props;
      const countryId = selectedCountry.id;
      const codeOption = codeOptions.find((item) => item.key === selectedCountry.key);
      getStates(countryId);
      setSelectedCountryOption(selectedCountry);
      setSelectedStateOption('');
      setSelectedCityOption('');
      setCityOptions([]);
      setIndustry((prevState) => ({
        ...prevState,
        country_id: countryId,
        phone_code: codeOption.value,
        state_id: null,
        city_id: null,
      }));
      setSelectedIndustryPhoneCode(codeOption.value);
    } else {
      setStateOptions([]);
      setSelectedStateOption('');
      setSelectedCityOption('');
      setSelectedCountryOption('');
      setCityOptions([]);
      setIndustry((prevState) => ({
        ...prevState,
        country_id: null,
        state_id: null,
        city_id: null,
      }));
    }
  };

  const handleStateSelectChange = (e, selectedState) => {
    if (selectedState && selectedState.id) {
      const { getCities } = props;
      const stateId = selectedState.id;
      getCities(stateId);
      setSelectedStateOption(stateId ? selectedState : '');
      setSelectedCityOption('');
      setCityOptions([]);
      setIndustry({
        ...industry,
        state_id: stateId,
      });
    } else {
      setSelectedCityOption('');
      setSelectedStateOption('');
      setCityOptions([]);
      setIndustry({
        ...industry,
        state_id: null,
      });
    }
  };

  const handleCitySelectChange = (e, selectedCity) => {
    if (selectedCity && selectedCity.id) {
      const cityId = selectedCity.id;
      setSelectedCityOption(cityId ? selectedCity : '');
      setIndustry({
        ...industry,
        city_id: cityId,
      });
    } else {
      setSelectedCityOption('');
      setIndustry({
        ...industry,
        city_id: null,
      });
    }
  };

  const handlePhoneCodeSelectChange = (selectedCodeOption) => {
    const phoneCode = selectedCodeOption.target.value;
    setIndustry({
      ...industry,
      phone_code: phoneCode,
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setIndustry({
      ...industry,
      [name]: value,
    });
  };

  const removeIndustryAvatar = () => {
    setFiles(null);
    setIndustry({
      ...industry,
      avatar: null,
    });
  };

  const handleTimeZoneSelectChange = (e, selectedTimeZone) => {
    if (selectedTimeZone) {
      setSelectedTimeZoneOption(selectedTimeZone || '');
      setIndustry((prevState) => ({
        ...prevState,
        timezone: selectedTimeZone,
      }));
    }
  };

  const {
    getRootProps,
    getInputProps,
  } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(URL.createObjectURL(acceptedFiles[0]));
      setIndustry({
        ...industry,
        avatar: acceptedFiles[0],
      });
    },
  });

  const thumbs = (
    <>
      {(files || industry.avatar) && (
        <div style={thumb}>
          <div style={thumbInner}>
            <img
              src={files || (industry ? industry.avatar : null)}
              style={img}
              alt=""
            />
            <div className="remove-industry-avatar">
              <HighlightOffRoundedIcon color="error" onClick={() => removeIndustryAvatar()} />
            </div>
          </div>
        </div>
      )}
    </>
  );

  return (
    <>
      {!loading ? (
        <Grid container direction="row" id="fixed-box-info" spacing={4}>
          <Grid item xs={12} md={4}>
            <Box className="info-box">
              <Box className="info-box-title">
                <Typography align="left" className="info-title-text">General</Typography>
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.name)}
                  >
                    <TextField
                      fullWidth
                      error={(!!errors.name)}
                      size="small"
                      name="first_name"
                      value={industry.first_name || ''}
                      onChange={(e) => handleInputChange(e)}
                      variant="outlined"
                      label="Name *"
                      inputProps={{
                        autoComplete: 'new-password',
                        maxLength: 115,
                      }}
                    />

                    {errors.name && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          Name is required
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small" variant="outlined" disabled>
                    <InputLabel>Industry *</InputLabel>
                    <Select
                      value={selectedIndustryOption.value ? selectedIndustryOption.value : ''}
                      label="Industry *"
                      selected={selectedIndustryOption}
                    >
                      <MenuItem value={selectedIndustryOption.value}>{selectedIndustryOption.label}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={(!!errors.email)}
                  >
                    <TextField
                      error={(!!errors.email)}
                      type="email"
                      label="Email"
                      name="email"
                      placeholder="Enter your email address"
                      size="small"
                      className="company-email"
                      value={industry.email !== 'null' ? industry.email : ''}
                      onChange={handleInputChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><MailOutlineIcon /></InputAdornment>,
                        autoComplete: 'new-password',
                      }}
                      variant="outlined"
                    />

                    { errors.email && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          Please enter valid email
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.phone_code)}
                  >
                    <InputLabel>{i18n.t('PhoneCode')} *</InputLabel>
                    <Select
                      name="phone_code"
                      label={i18n.t('PhoneCode')}
                      value={selectedIndustryPhoneCode}
                      onChange={(event) => handlePhoneCodeSelectChange(event)}
                    >
                      {codeOptions && codeOptions.map((option) => (
                        <MenuItem
                          key={Math.random()}
                          value={option.value}
                        >
                          <img
                            src={option.label.flagImageSrc}
                            alt="flag"
                            className="country-flag"
                          />
                          {option.value}
                        </MenuItem>
                      ))}
                    </Select>

                    {errors.phone_code && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('PhoneCode')} is required
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.phone_number)}
                  >
                    <TextField
                      fullWidth
                      error={(!!errors.phone_number)}
                      size="small"
                      id="phone-detail"
                      type="text"
                      value={industry.phone_number || ''}
                      onChange={(e) => handleInputChange(e)}
                      name="phone_number"
                      variant="outlined"
                      data-cy="phone"
                      label="Phone number *"
                      InputProps={{
                        inputComponent: TextMaskCustom,
                        autoComplete: 'new-password',
                      }}
                    />

                    {errors.phone_number && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {errors.phone_number}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Box className="add-logo-content" id="dropzone">
                    <div {...getRootProps({ className: files || industry ? industry.avatar ? 'without-background' : 'dropzone' : 'dropzone' })}>
                      <input {...getInputProps()} />
                    </div>
                    <aside style={thumbsContainer}>
                      {thumbs}
                    </aside>
                  </Box>
                  {updateErrors.avatar && (
                    <>
                      {updateErrors.avatar.map((error) => (
                        <div className="error-message-content">
                          <ErrorIcon fontSize="small" color="error" />
                          <FormHelperText error>
                            {error}
                          </FormHelperText>
                        </div>
                      ))}
                    </>
                  )}
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    id="outlined-textarea"
                    label="Description"
                    name="description"
                    multiline
                    fullWidth
                    variant="outlined"
                    rowsMax={4}
                    inputProps={{
                      autoComplete: 'new-password',
                      maxLength: 200,
                    }}
                    value={industry.description ? industry.description : ''}
                    onChange={(e) => handleInputChange(e)}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="info-box">
              <Box className="info-box-title">
                <Typography align="left" className="info-title-text">Location</Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.country)}
                  >
                    <Autocomplete
                      size="small"
                      fullWidth
                      autoHighlight
                      onChange={handleCountrySelectChange}
                      value={selectedCountryOption}
                      options={countryOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          label="Country *"
                          variant="outlined"
                          error={(!!errors.country)}
                        />
                      )}
                    />

                    {errors.country && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          Country is required
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.state)}
                  >
                    <Autocomplete
                      size="small"
                      fullWidth
                      autoHighlight
                      onChange={handleStateSelectChange}
                      value={selectedStateOption}
                      options={stateOptions}
                      disabled={!selectedCountryOption.id}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          label="State *"
                          variant="outlined"
                          error={(!!errors.state)}
                        />
                      )}
                    />

                    {errors.state && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          State is required
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.city)}
                  >
                    <Autocomplete
                      size="small"
                      fullWidth
                      autoHighlight
                      disabled={!selectedStateOption.id}
                      onChange={handleCitySelectChange}
                      value={selectedCityOption}
                      options={cityOptions}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          label="City *"
                          variant="outlined"
                          error={(!!errors.city)}
                        />
                      )}
                    />

                    {errors.city && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          City is required
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    size="small"
                    variant="outlined"
                    error={(!!errors.address)}
                  >
                    <TextField
                      fullWidth
                      error={(!!errors.address)}
                      size="small"
                      variant="outlined"
                      name="address"
                      label="Address *"
                      value={industry.address || ''}
                      inputProps={{
                        autoComplete: 'new-password',
                      }}
                      onChange={(e) => handleInputChange(e)}
                    />

                    {errors.address && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          Address is required
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={!!errors.zip}
                  >
                    <TextField
                      fullWidth
                      error={!!errors.zip}
                      id="zip"
                      label="Zip code"
                      variant="outlined"
                      size="small"
                      name="zip_code"
                      value={industry.zip_code || ''}
                      onChange={handleInputChange}
                      inputProps={{ maxlength: CHARACTER_LIMIT, autoComplete: 'new-password' }}
                    />
                    { errors.zip && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          Zip code is required
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={8}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    <Autocomplete
                      size="small"
                      fullWidth
                      onChange={handleTimeZoneSelectChange}
                      value={selectedTimeZoneOption}
                      options={timeZoneOptions}
                      getOptionLabel={(option) => option}
                      renderOption={(option) => (option)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password',
                          }}
                          label="Time zone *"
                          variant="outlined"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="info-box">
              <Box className="info-box-title">
                <Typography align="left" className="info-title-text">Links</Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                <Grid item sm={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    <TextField
                      label="Website"
                      name="website_url"
                      placeholder="Enter company website URL"
                      size="small"
                      className="company-link"
                      value={industry.website_url !== 'null' ? industry.website_url : ''}
                      onChange={(e) => handleInputChange(e)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><LanguageIcon /></InputAdornment>,
                        autoComplete: 'new-password',
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    <TextField
                      label="Facebook"
                      name="facebook_url"
                      placeholder="Enter Facebook account URL"
                      size="small"
                      className="company-link"
                      value={industry.facebook_url !== 'null' ? industry.facebook_url : ''}
                      onChange={(e) => handleInputChange(e)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><FacebookIcon /></InputAdornment>,
                        autoComplete: 'new-password',
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    <TextField
                      label="Instagram"
                      name="instagram_url"
                      placeholder="Enter Instagram account handle"
                      size="small"
                      className="company-link"
                      value={industry.instagram_url !== 'null' ? industry.instagram_url : ''}
                      onChange={(e) => handleInputChange(e)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><InstagramIcon /></InputAdornment>,
                        shrink: true,
                        autoComplete: 'new-password',
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
                <Grid item sm={12}>
                  <FormControl
                    fullWidth
                    variant="outlined"
                    margin="dense"
                  >
                    <TextField
                      label="Booking URL"
                      name="booking_url"
                      size="small"
                      className="company-link"
                      // value={industry.booking_url !== 'null' ? industry.booking_url : ''}
                      onChange={(e) => handleInputChange(e)}
                      InputProps={{
                        startAdornment: <InputAdornment position="start"><DateRangeIcon /></InputAdornment>,
                        autoComplete: 'new-password',
                      }}
                      variant="outlined"
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      ) : <InfoLoading />}
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

Info.propTypes = {
  // Get Parent Props
  individualUserIndustryItem: PropTypes.object.isRequired,
  individualIndustry: PropTypes.object.isRequired,
  isUpdateIndustrySubmitDone: PropTypes.bool.isRequired,
  sendIndustryUpdated: PropTypes.func.isRequired,
  // Get Industries
  getIndustries: PropTypes.func.isRequired,
  isGetIndustriesSuccess: PropTypes.bool.isRequired,
  industries: PropTypes.array.isRequired,
  // Get Countries
  allCountries: PropTypes.array.isRequired,
  // Get states
  states: PropTypes.array.isRequired,
  getStates: PropTypes.func.isRequired,
  isGetStatesSuccess: PropTypes.bool.isRequired,
  isGetStatesError: PropTypes.bool.isRequired,
  // Get Cities
  cities: PropTypes.array.isRequired,
  getCities: PropTypes.func.isRequired,
  isGetCitiesSuccess: PropTypes.bool.isRequired,
  isGetCitiesError: PropTypes.bool.isRequired,
  // Update Individual User Industry
  updateIndividualUserIndustry: PropTypes.func.isRequired,
  isUpdateIndividualUserIndustrySuccess: PropTypes.bool.isRequired,
  isUpdateIndividualUserIndustryError: PropTypes.bool.isRequired,
  updatedIndividualUserIndustry: PropTypes.object.isRequired,
  updateIndividualUserIndustryErrors: PropTypes.object.isRequired,
  updateIndividualUserIndustryErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // Get Industries
  isGetIndustriesSuccess: state.industry.isGetIndustriesSuccess,
  industries: state.industry.industries,
  // Get Countries
  allCountries: state.country.allCountries,
  // Get States
  states: state.country.states,
  isGetStatesSuccess: state.country.isGetStatesSuccess,
  isGetStatesError: state.country.isGetStatesError,
  // Get Cities
  cities: state.country.cities,
  isGetCitiesSuccess: state.country.isGetCitiesSuccess,
  isGetCitiesError: state.country.isGetCitiesError,
  // Update Industry
  isUpdateIndividualUserIndustrySuccess: state.individualUser.isUpdateIndividualUserIndustrySuccess,
  isUpdateIndividualUserIndustryError: state.individualUser.isUpdateIndividualUserIndustryError,
  updatedIndividualUserIndustry: state.individualUser.updatedIndividualUserIndustry,
  updateIndividualUserIndustryErrors: state.individualUser.updateIndividualUserIndustryErrors,
  updateIndividualUserIndustryErrorMessage: state.individualUser.updateIndividualUserIndustryErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndustries: (data) => dispatch(getIndustriesRequest(data)),
    getStates: (data) => dispatch(getStatesRequest(data)),
    getCities: (data) => dispatch(getCitiesRequest(data)),
    updateIndividualUserIndustry: (data) => dispatch(updateIndividualUserIndustryRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);
