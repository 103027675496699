import { put, takeLatest, select } from 'redux-saga/effects';
import {
  storeAppointmentRequest,
  storeAppointmentSuccess,
  storeAppointmentError,
  updateAppointmentRequest,
  updateAppointmentSuccess,
  updateAppointmentError,
  deleteAppointmentRequest,
  deleteAppointmentSuccess,
  deleteAppointmentError,
  getAppointmentsRequest,
  getAppointmentsSuccess,
  getAppointmentsError,
  getAppointmentByIdRequest,
  getAppointmentByIdSuccess,
  getAppointmentByIdError,
  acceptRequest,
  acceptSuccess,
  acceptError,
  cancelRequest,
  cancelSuccess,
  cancelError,
  finishRequest,
  finishSuccess,
  finishError,
  getAllStatusesRequest,
  getAllStatusesSuccess,
  getAllStatusesError,
  getGeneralAppointmentsRequest,
  getGeneralAppointmentsSuccess,
  getGeneralAppointmentsError,
  validAppointmentRequest,
  validAppointmentSuccess,
  validAppointmentError,
  getUserRoleInAppointmentRequest,
  getUserRoleInAppointmentSuccess,
  getUserRoleInAppointmentError,
  filterStaffAppointmentsRequest,
  filterStaffAppointmentsSuccess,
  filterStaffAppointmentsError,
  filterAllAppointmentsRequest,
  filterAllAppointmentsSuccess,
  filterAllAppointmentsError,
  checkAppointmentsByServiceIdRequest,
  checkAppointmentsByServiceIdSuccess,
  checkAppointmentsByServiceIdError,
  checkCompanyDayAppointmentsRequest,
  checkCompanyDayAppointmentsSuccess,
  checkCompanyDayAppointmentsError,
  cancelCompanyDayAppointmentsSuccess,
  cancelCompanyDayAppointmentsError,
  cancelCompanyDayAppointmentsRequest,
  updateAppointmentsReminderRequest,
  updateAppointmentsReminderSuccess,
  updateAppointmentsReminderError,
  updateAppointmentsThankYouMessageRequest,
  updateAppointmentsThankYouMessageSuccess,
  updateAppointmentsThankYouMessageError,
  filterAllIndividualAppointmentsRequest,
  filterAllIndividualAppointmentsSuccess,
  filterAllIndividualAppointmentsError,
  checkIndividualUserIndustryDayAppointmentsRequest,
  checkIndividualUserIndustryDayAppointmentsSuccess,
  checkIndividualUserIndustryDayAppointmentsError,
  cancelIndividualUserIndustryDayAppointmentsRequest,
  cancelIndividualUserIndustryDayAppointmentsSuccess,
  cancelIndividualUserIndustryDayAppointmentsError,
  getIndividualAppointmentsStatisticsRequest,
  getIndividualAppointmentsStatisticsSuccess,
  getIndividualAppointmentsStatisticsError,
  getAppointmentsStatisticsRequest,
  getAppointmentsStatisticsSuccess,
  getAppointmentsStatisticsError,
  getCalendarAppointmentsSuccess,
  getCalendarAppointmentsError,
  getCalendarAppointmentsRequest, filterAllMyAppointmentsRequest, filterAllMyAppointmentsSuccess,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

const appointments = (state) => state.appointment.allAppointments;
const filteredAllAppointments = (state) => state.appointment.filteredAllAppointments;

function* storeAppointment(action) {
  const { payload } = action;
  const url = payload.creator === 'individual' ? 'appointments' : payload.individual_user_industry_id ? 'appointments/individual-user-industry' : 'appointments';
  try {
    const response = yield axiosInstance.post(url, payload);
    if (response && response.status === 200) {
      yield put(storeAppointmentSuccess(response.data));
    } else {
      yield put(storeAppointmentError(response.response.data));
    }
  } catch (e) {
    yield put(storeAppointmentError(e.response.data));
  }
}

function* validAppointment(action) {
  const { payload } = action;
  const url = payload.creator === 'customer' ? 'appointments' : 'appointment/check-validation';
  try {
    const response = yield axiosInstance.post(url, payload);
    if (response && response.status === 200) {
      yield put(validAppointmentSuccess(response.data));
    } else {
      yield put(validAppointmentError(response.response.data));
    }
  } catch (e) {
    yield put(validAppointmentError(e.response.data));
  }
}

function* updateAppointment(action) {
  const appointmentData = { ...action.payload };
  const url = appointmentData.individual_user_industry_id ? `appointments/${appointmentData.id}/individual-user-industry` : `appointments/${appointmentData.id}`;
  try {
    // Remove unnecessary properties from data
    delete appointmentData.company;
    delete appointmentData.customer;
    delete appointmentData.service;
    delete appointmentData.staff;
    delete appointmentData.status;

    const response = yield axiosInstance.put(url, appointmentData);
    if (response && response.status === 200) {
      yield put(updateAppointmentSuccess(response.data));
    } else {
      yield put(updateAppointmentError(response.response.data));
    }
  } catch (e) {
    yield put(updateAppointmentError(e.response.data));
  }
}

function* deleteAppointment(action) {
  const { id } = action.payload;
  try {
    const response = yield axiosInstance.delete(`appointments/${id}`);
    if (response && response.status === 202) {
      const allAppointments = yield select(appointments);
      const allAppointmentsFiltered = allAppointments.filter((item) => item.id !== id);

      let allFilteredAppointments = yield select(filteredAllAppointments);
      const allFilteredAppointmentsCopy = [...allFilteredAppointments];
      allFilteredAppointments = allFilteredAppointmentsCopy.filter((item) => item.id !== id);

      yield put(deleteAppointmentSuccess({ allAppointmentsFiltered, allFilteredAppointments }));
    } else {
      yield put(deleteAppointmentError(response.response.data));
    }
  } catch (e) {
    yield put(deleteAppointmentError(e.response.data));
  }
}

function* getAppointments(action) {
  try {
    let url = 'appointments';
    if (action.payload) {
      const { payload } = action;
      Object.keys(payload).forEach((param, index) => {
        const value = payload[param];
        url += `${(index === 0 ? '?' : '&')}${param}=${value}`;
      });
    }

    const response = yield axiosInstance.get(url);
    if (response && response.status === 200) {
      yield put(getAppointmentsSuccess(response.data));
    } else {
      yield put(getAppointmentsError());
    }
  } catch (e) {
    yield put(getAppointmentsError());
  }
}

function* getAppointmentById(action) {
  const id = action.payload;
  try {
    const response = yield axiosInstance.get(`appointments/${id}`);
    if (response && response.status === 200) {
      yield put(getAppointmentByIdSuccess(response.data));
    } else {
      yield put(getAppointmentByIdError(response.response.data));
    }
  } catch (e) {
    yield put(getAppointmentByIdError(e.response.data));
  }
}

function* accept(action) {
  const id = action.payload;
  try {
    const response = yield axiosInstance.put(`appointment/${id}/accept`);
    if (response && response.status === 202) {
      yield put(acceptSuccess());
    } else {
      yield put(acceptError(response.response.data));
    }
  } catch (e) {
    yield put(acceptError(e.response.data));
  }
}

function* cancel(action) {
  const { id } = action.payload;
  try {
    const response = yield axiosInstance.put(`appointment/${id}/cancel`);
    if (response && response.status === 202) {
      const allAppointments = yield select(filteredAllAppointments);
      const updatedAppointment = allAppointments.find((appointment) => appointment.id === id);
      if (updatedAppointment) {
        updatedAppointment.status_name = 'cancelled';
      }
      yield put(cancelSuccess());
    } else {
      yield put(cancelError(response.response.data));
    }
  } catch (e) {
    yield put(cancelError(e.response.data));
  }
}

function* finish(action) {
  const { id, price } = action.payload;
  try {
    const response = yield axiosInstance.put(`appointment/${id}/finish`, { price });
    if (response && response.status === 200) {
      yield put(finishSuccess());
    } else {
      yield put(finishError(response.response.data));
    }
  } catch (e) {
    yield put(finishError(e.response.data));
  }
}

function* getAllStatuses() {
  try {
    const response = yield axiosInstance.get('appointment/statuses');
    if (response && response.status === 200) {
      yield put(getAllStatusesSuccess(response.data));
    } else {
      yield put(getAllStatusesError());
    }
  } catch (e) {
    yield put(getAllStatusesError());
  }
}

function* getGeneralAppointments(action) {
  try {
    const data = action.payload;
    const response = yield axiosInstance.post('personal/appointments', data);
    if (response && response.data) {
      yield put(getGeneralAppointmentsSuccess(response.data));
    } else {
      yield put(getGeneralAppointmentsError());
    }
  } catch (e) {
    yield put(getGeneralAppointmentsError());
  }
}

function* getUSerRoleInAppointment(action) {
  try {
    const { id } = action.payload;
    const response = yield axiosInstance.get(`appointment/role/${id}`);
    if (response && response.status === 200) {
      yield put(getUserRoleInAppointmentSuccess(response.data));
    } else {
      yield put(getUserRoleInAppointmentError());
    }
  } catch (e) {
    yield put(getUserRoleInAppointmentError());
  }
}

function* filterStaffAppointments(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('appointments/calendar/filter', payload);
    if (response && response.status === 200) {
      yield put(filterStaffAppointmentsSuccess(response.data));
    } else {
      yield put(filterStaffAppointmentsError());
    }
  } catch (e) {
    yield put(filterStaffAppointmentsError());
  }
}

function* filterAllAppointments(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post(`appointments/filter?q=${payload.searchValue}`, payload);
    if (response && response.status === 200) {
      if (payload.for_calendar) {
        yield put(filterAllAppointmentsSuccess(response.data));
      } else {
        yield put(filterAllMyAppointmentsSuccess(response.data));
      }
    } else {
      yield put(filterAllAppointmentsError());
    }
  } catch (e) {
    yield put(filterAllAppointmentsError());
  }
}

function* checkAppointmentsByServiceId(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('check/appointments', payload);
    if (response && response.status === 200) {
      yield put(checkAppointmentsByServiceIdSuccess(response.data));
    } else {
      yield put(checkAppointmentsByServiceIdError());
    }
  } catch (e) {
    yield put(checkAppointmentsByServiceIdError());
  }
}

function* checkCompanyDayAppointments(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('check/day/appointments', payload);
    if (response && response.status === 200) {
      yield put(checkCompanyDayAppointmentsSuccess(response.data));
    } else {
      yield put(checkCompanyDayAppointmentsError());
    }
  } catch (e) {
    yield put(checkCompanyDayAppointmentsError());
  }
}

function* cancelCompanyDayAppointments(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('cancel/day/appointments', payload);
    if (response && response.status === 202) {
      yield put(cancelCompanyDayAppointmentsSuccess(response.data));
    } else {
      yield put(cancelCompanyDayAppointmentsError());
    }
  } catch (e) {
    yield put(cancelCompanyDayAppointmentsError());
  }
}

function* updateAppointmentReminder() {
  try {
    const response = yield axiosInstance.post('reminder/appointments');
    if (response && response.status === 202) {
      yield put(updateAppointmentsReminderSuccess(response.data));
    } else {
      yield put(updateAppointmentsReminderError());
    }
  } catch (e) {
    yield put(updateAppointmentsReminderError());
  }
}

function* updateAppointmentsThankYouMessage() {
  try {
    const response = yield axiosInstance.post('thank-you/appointments');
    if (response && response.status === 202) {
      yield put(updateAppointmentsThankYouMessageSuccess(response.data));
    } else {
      yield put(updateAppointmentsThankYouMessageError());
    }
  } catch (e) {
    yield put(updateAppointmentsThankYouMessageError());
  }
}

function* filterIndividualAppointments(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('appointments/filter', payload);
    if (response && response.status === 200) {
      yield put(filterAllIndividualAppointmentsSuccess(response.data));
    } else {
      yield put(filterAllIndividualAppointmentsError());
    }
  } catch (e) {
    yield put(filterAllIndividualAppointmentsError());
  }
}

function* checkIndividualUserIndustryDayAppointments(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('check/day/appointments', payload);
    if (response && response.status === 200) {
      yield put(checkIndividualUserIndustryDayAppointmentsSuccess(response.data));
    } else {
      yield put(checkIndividualUserIndustryDayAppointmentsError());
    }
  } catch (e) {
    yield put(checkIndividualUserIndustryDayAppointmentsError());
  }
}

function* cancelIndividualUserIndustryDayAppointments(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('cancel/day/appointments', payload);
    if (response && response.status === 202) {
      yield put(cancelIndividualUserIndustryDayAppointmentsSuccess());
    } else {
      yield put(cancelIndividualUserIndustryDayAppointmentsError());
    }
  } catch (e) {
    yield put(cancelIndividualUserIndustryDayAppointmentsError());
  }
}

function* getIndividualAppStatistics(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('statistics/appointments', payload);
    if (response && response.status === 200) {
      yield put(getIndividualAppointmentsStatisticsSuccess(response.data));
    } else {
      yield put(getIndividualAppointmentsStatisticsError());
    }
  } catch (e) {
    yield put(getIndividualAppointmentsStatisticsError());
  }
}

function* getAppointmentStatistics(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('statistics/appointments', payload);
    if (response && response.status === 200) {
      yield put(getAppointmentsStatisticsSuccess(response.data));
    } else {
      yield put(getAppointmentsStatisticsError());
    }
  } catch (e) {
    yield put(getAppointmentsStatisticsError());
  }
}

function* getCalendarAppointments(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.post('web/appointments/list', payload);

    if (response && response.status === 200) {
      yield put(getCalendarAppointmentsSuccess(response.data));
    } else {
      yield put(getCalendarAppointmentsError());
    }
  } catch (e) {
    yield put(getCalendarAppointmentsError());
  }
}

export default function* () {
  yield takeLatest(storeAppointmentRequest, storeAppointment);
  yield takeLatest(updateAppointmentRequest, updateAppointment);
  yield takeLatest(deleteAppointmentRequest, deleteAppointment);
  yield takeLatest(getAppointmentsRequest, getAppointments);
  yield takeLatest(getAppointmentByIdRequest, getAppointmentById);
  yield takeLatest(acceptRequest, accept);
  yield takeLatest(cancelRequest, cancel);
  yield takeLatest(finishRequest, finish);
  yield takeLatest(getAllStatusesRequest, getAllStatuses);
  yield takeLatest(getGeneralAppointmentsRequest, getGeneralAppointments);
  yield takeLatest(validAppointmentRequest, validAppointment);
  yield takeLatest(getUserRoleInAppointmentRequest, getUSerRoleInAppointment);
  yield takeLatest(filterStaffAppointmentsRequest, filterStaffAppointments);
  yield takeLatest(filterAllAppointmentsRequest, filterAllAppointments);
  yield takeLatest(filterAllMyAppointmentsRequest, filterAllAppointments);
  yield takeLatest(checkAppointmentsByServiceIdRequest, checkAppointmentsByServiceId);
  yield takeLatest(checkCompanyDayAppointmentsRequest, checkCompanyDayAppointments);
  yield takeLatest(cancelCompanyDayAppointmentsRequest, cancelCompanyDayAppointments);
  yield takeLatest(updateAppointmentsReminderRequest, updateAppointmentReminder);
  yield takeLatest(updateAppointmentsThankYouMessageRequest, updateAppointmentsThankYouMessage);
  yield takeLatest(filterAllIndividualAppointmentsRequest, filterIndividualAppointments);
  yield takeLatest(checkIndividualUserIndustryDayAppointmentsRequest, checkIndividualUserIndustryDayAppointments);
  yield takeLatest(cancelIndividualUserIndustryDayAppointmentsRequest, cancelIndividualUserIndustryDayAppointments);
  yield takeLatest(getIndividualAppointmentsStatisticsRequest, getIndividualAppStatistics);
  yield takeLatest(getAppointmentsStatisticsRequest, getAppointmentStatistics);
  yield takeLatest(getCalendarAppointmentsRequest, getCalendarAppointments);
}
