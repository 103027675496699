import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppBar,
  Box,
  Grid,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import i18n from 'i18next';
import usePrevious from '../../../../../CustomHooks/usePrevious';
import {
  storeGalleryCompanyRequest,
  deleteGalleryCompanyRequest,
  getPreSignUrlRequest,
  uploadImageToAmazonRequest,
  getCompanyImagesRequest,
} from '../../../../../redux/company/actions';
import GalleryLoading from '../../../../../Components/Loading/galleryLoading';
import SnackbarToast from '../../../../../Modules/SnackbarToast';
import DeleteGalleryImage from '../../../../../Modals/Owner/DeleteGalleryImage';

function Gallery() {
  const dispatch = useDispatch();

  const {
    companyImages,
    isGetCompanyImagesSuccess,
    preSignUrl,
    isGetPreSignedUrlSuccess,
    storedGalleryCompany,
    isStoreGalleryCompanyError,
    isDeleteGalleryCompanyError,
    isStoreGalleryCompanySuccess,
    isDeleteGalleryCompanySuccess,
    isUploadImageToAmazonSuccess,
  } = useSelector((state) => state.company);

  const [images, setImages] = useState([]);
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteGalleryLoading, setDeleteGalleryLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openDeleteImageModal, setOpenDeleteImageModal] = React.useState(false);
  const [selectedImageId, setSelectedImageId] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);
  const [logoName, setLogoName] = useState('');

  const prevIsStoreGalleryCompanySuccess = usePrevious(isStoreGalleryCompanySuccess);
  const prevIsStoreGalleryCompanyError = usePrevious(isStoreGalleryCompanyError);
  const prevIsDeleteGalleryCompanySuccess = usePrevious(isDeleteGalleryCompanySuccess);
  const prevIsDeleteGalleryCompanyError = usePrevious(isDeleteGalleryCompanyError);
  const prevIsGetPreSignedUrlSuccess = usePrevious(isGetPreSignedUrlSuccess);
  const prevIsUploadImageToAmazonSuccess = usePrevious(isUploadImageToAmazonSuccess);
  const prevIsGetCompanyImagesSuccess = usePrevious(isGetCompanyImagesSuccess);

  useEffect(() => {
    dispatch(getCompanyImagesRequest());
  }, []);

  useEffect(() => {
    if (prevIsGetCompanyImagesSuccess === false && isGetCompanyImagesSuccess) {
      setLoading(true);
      setImages(companyImages);
    }
  }, [isGetCompanyImagesSuccess]);

  // Get PreSigned Url
  useEffect(() => {
    if (prevIsGetPreSignedUrlSuccess === false && isGetPreSignedUrlSuccess) {
      dispatch(uploadImageToAmazonRequest({ preSignUrl, logo: selectedImage[0] }));
    }
  }, [isGetPreSignedUrlSuccess]);

  useEffect(() => {
    if (prevIsUploadImageToAmazonSuccess === false && isUploadImageToAmazonSuccess) {
      const imagesCopy = (selectedImage.map((file) => Object.assign(file, {
        image: URL.createObjectURL(file),
      })));
      const filteredImage = imagesCopy.filter((img) => !img.id);

      if (filteredImage.length) {
        dispatch(storeGalleryCompanyRequest({
          images: logoName,
        }));
      }
    }
  }, [isUploadImageToAmazonSuccess]);

  // Perform when gallery images is added
  useEffect(() => {
    if (prevIsStoreGalleryCompanySuccess === false && isStoreGalleryCompanySuccess) {
      setCompany({
        ...company,
        images: images.concat(storedGalleryCompany),
      });
      setImages(images.concat(storedGalleryCompany));
      snackBarAlert(true, storedGalleryCompany.length > 1 ? 'Images are added' : 'Image successfully uploaded', 'success');
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isStoreGalleryCompanySuccess]);

  // Perform when images is not added
  useEffect(() => {
    if (prevIsStoreGalleryCompanyError === false && isStoreGalleryCompanyError) {
      snackBarAlert(true, 'Image size is too large', 'error');
      setLoading(true);
    }
  }, [isStoreGalleryCompanyError]);

  // Perform when images is deleted
  useEffect(() => {
    if (prevIsDeleteGalleryCompanySuccess === false && isDeleteGalleryCompanySuccess) {
      snackBarAlert(true, i18n.t('ImageDeleted'), 'success');
    }
  }, [isDeleteGalleryCompanySuccess]);

  // Perform when images is not added
  useEffect(() => {
    if (prevIsDeleteGalleryCompanyError === false && isDeleteGalleryCompanyError) {
      snackBarAlert(true, 'Images is not added', 'error');
    }
  }, [isDeleteGalleryCompanyError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const removeCompanyImage = () => {
    const filteredImages = images.filter((file) => file.id !== selectedImageId);
    setImages(filteredImages);
    setCompany({
      ...company,
      images: filteredImages,
    });
    setDeleteGalleryLoading(true);
    setDeleteGalleryLoading(true);
    setOpenDeleteImageModal(false);
    dispatch(deleteGalleryCompanyRequest({
      id: selectedImageId,
    }));
  };

  const handleClickOpen = (e, id) => {
    setSelectedImageId(id);
    setDeleteGalleryLoading(false);
    setOpenDeleteImageModal(true);
  };
  const handleClose = () => {
    setOpenDeleteImageModal(false);
  };

  const { getRootProps: getRootPropsCompany, getInputProps: getInputPropsCompany } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setSelectedImage(acceptedFiles);
      const logo = moment().format('DDMMYYYYHHmmss') + acceptedFiles[0].name;
      setLogoName(logo);
      dispatch(getPreSignUrlRequest({ logo, folder: 'companyImage' }));
    },
  });

  const thumbsCompany = images.map((file, index) => (
    <div className="thumbGallery" key={`${index}-${file.size}`}>
      <div className="thumbInner">
        <img
          name={file.size}
          src={file.image || file.path}
          className="img"
          alt=""
        />
        <div className="remove-industry-avatar">
          <DeleteIcon
            className="remove-icon"
            onClick={(e) => handleClickOpen(e, file.image || file.id)}
          />
        </div>
      </div>
    </div>
  ));

  return (
    <>
      <AppBar color="inherit" id="second-navbar" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" flexWrap="wrap" width="100%" alignItems="center">
            <Box display="flex" className="tabs-navbar">
              <Box alignSelf="center" className="divider-title">
                <Typography variant="h6" noWrap>
                  <Box
                    alignItems="center"
                    height="100%"
                    minWidth="auto"
                    maxWidth="220px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="noWrap"
                  >
                    {i18n.t('Gallery')}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {loading ? (
        <>
          <Grid className="company-gallery">
            <Grid item sm={12}>
              <Box className="add-gallery-content" id="dropzone">
                <section className="gallery">
                  <div className="aside">
                    <div {...getRootPropsCompany({ className: 'dropzone' })}>
                      <input {...getInputPropsCompany()} />
                    </div>
                    {thumbsCompany}
                  </div>
                </section>
              </Box>
            </Grid>
          </Grid>
          <SnackbarToast
            message={snackbarMessage}
            type={snackbarType}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
          />
          <DeleteGalleryImage
            handleClose={handleClose}
            openDeleteImageModal={openDeleteImageModal}
            removeCompanyImage={removeCompanyImage}
            deleteGalleryLoading={deleteGalleryLoading}
          />
        </>
      ) : <GalleryLoading />}
    </>
  );
}

export default Gallery;
