import React from 'react';
import {
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

function SaveCompanyChanges(props) {
  const {
    open,
    handleCloseInfoModal,
    handleSaveCompanyChange,
    handleCancelCompanyChange,
  } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle id="customized-dialog-title" onClose={handleCloseInfoModal}>
        <span className="alert-title">
          <span>Save changes</span>
          <IconButton
            aria-label="close"
            className="close-btn"
            onClick={handleCloseInfoModal}
          >
            <CloseIcon />
          </IconButton>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        Do you want to save changes?
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleCancelCompanyChange}
          data-cy="cancel"
          type="cancel"
          size="small"
        >
          No
        </Button>
        <Button
          size="small"
          onClick={handleSaveCompanyChange}
          data-cy="save"
          type="submit"
          variant="contained"
          color="primary"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SaveCompanyChanges.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseInfoModal: PropTypes.func.isRequired,
  handleCancelCompanyChange: PropTypes.func.isRequired,
  handleSaveCompanyChange: PropTypes.func.isRequired,
};

export default SaveCompanyChanges;
