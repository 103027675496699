import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';

function SnackbarToast(props) {
  const {
    open,
    onClose,
    message,
    type,
  } = props;

  return (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      key="top,right"
      onClose={onClose}
      style={{ zIndex: '9999' }}
    >
      <Alert severity={type} onClose={onClose}>
        { message }
      </Alert>
    </Snackbar>
  );
}

SnackbarToast.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default SnackbarToast;
