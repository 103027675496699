import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { Link } from 'react-router-dom';
import {
  Grid,
  Button,
  Box,
  Typography,
  Container,
  makeStyles,
  AppBar,
  Toolbar,
} from '@material-ui/core';
import Logo from 'assets/img/logo.svg';

const useStyles = makeStyles(() => ({
  root: {
    paddingBottom: 8,
  },
  textOr: {
    textAlign: 'center',
  },
}));

function AccountType({ userAccount, handleSubmit }) {
  const [selectedAccountType, setSelectedAccountType] = useState('');

  useEffect(() => {
    if (userAccount && userAccount.activeRole) {
      const { name } = userAccount.activeRole;
      let accountType = 'personal';
      if (name === 'Owner') {
        accountType = 'business';
      }
      setSelectedAccountType(accountType);
    }
  }, []);

  const onSubmit = (type) => {
    handleSubmit(type);
  };

  const classes = useStyles();

  return (
    <>
      <div className="auth auth-bg">
        <Container>
          <AppBar color="transparent" position="static" elevation={0} className="login-header">
            <Toolbar>
              <Box mx="auto" className="auth-logo">
                <img src={Logo} alt="Logo" />
              </Box>
            </Toolbar>
          </AppBar>
          <Box mb="30px">
            <Typography align="center" variant="h4">
                Choose your account type
            </Typography>
          </Box>
          <Grid container spacing={3} direction="row" justifyContent="center" alignItems="center" className={classes.root}>
            <Grid item lg={3} md={3} sm={4} xs={8}>
              <Box
                width="100%"
                maxWidth="250px"
                className={`${selectedAccountType === 'personal' && 'active'} card-account-type`}
              >
                <Box className="img-box">
                  <Box className="img-box-personal" />
                  <Box align="center">
                    <Typography color="secondary" variant="h5" noWrap className="card-title">
                      Personal
                    </Typography>
                    <Link
                      to="#"
                      className="read-more"
                    >
                      <Typography color="primary">
                        Become a Customer or Staff, create and get appointments
                      </Typography>
                    </Link>
                  </Box>
                  <Box align="center" mt="15px">
                    <Button
                      size="small"
                      variant="contained"
                      className="primary-btn"
                      color="primary"
                      onClick={() => onSubmit('personal')}
                    >
                      Choose
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={8}>
              <Box
                width="100%"
                maxWidth="250px"
                mx="auto"
                my="0"
              >
                <Typography variant="h6" color="secondary" className={classes.textOr}>
                  OR
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={4} xs={8}>
              <Box
                width="100%"
                maxWidth="250px"
                className={`${selectedAccountType === 'business' && 'active'} card-account-type`}
              >
                <Box className="img-box">
                  <Box className="img-box-business" />
                  <Box align="center">
                    <Typography color="secondary" variant="h5" noWrap className="card-title">
                      I have a Business
                    </Typography>
                    <Link
                      to="#"
                      className="read-more"
                    >
                      <Typography color="primary">
                        Manage all appointments of your company, send reminders
                      </Typography>
                    </Link>
                  </Box>
                  <Box align="center" mt="15px">
                    <Button
                      size="small"
                      variant="contained"
                      className="primary-btn"
                      color="primary"
                      onClick={() => onSubmit('business')}
                    >
                      Choose
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

AccountType.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  userAccount: PropTypes.object.isRequired,
};

export default AccountType;
