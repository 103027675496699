import moment from 'moment';
import React, { useState } from 'react';
import i18n from 'i18next';
import SnackbarToast from './SnackbarToast';

const validateAppointmentTimeForIndividual = (individual, appointment) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const individualCopy = { ...individual };
  const appointmentCopy = { ...appointment };
  const appointmentWeekDay = moment(appointmentCopy.day_time).day();
  const isWorkingDay = individualCopy.workingHours.find((individual) => appointmentWeekDay === parseInt(individual.daysOfWeek[0])).isWorking;
  // Check if appointment starts in past
  const isCreationDateInPast = moment(appointmentCopy.day_time).isBefore(moment());
  if (isCreationDateInPast) {
    snackBarAlert(true, 'Appointment cannot start in past', 'error');
    return false;
  }
  // Check if appointment date is not working day
  if (!isWorkingDay) {
    snackBarAlert(true, i18n.t('SelectedDayNotWork'), 'error');
    return false;
  }
  const startDay = moment(appointmentCopy.date).format('Y-MM-DD');
  const startDayName = moment(startDay).format('dddd');
  const startDayNumber = moment(startDay).day();
  const startTime = moment(appointmentCopy.day_time).format();
  const endTime = moment(appointmentCopy.day_time)
    .add(parseInt(appointmentCopy.duration), 'minutes').format();
    // Check for working hours start and end
  const workingHoursDay = individualCopy.workingHours.find((day) => parseInt(startDayNumber) === parseInt(day.daysOfWeek[0]));
  const workingHoursStart = moment(`${appointmentCopy.date} ${workingHoursDay.startTime}`).format();
  const workingHoursEnd = moment(`${appointmentCopy.date} ${workingHoursDay.endTime}`).format();
  const isAppointmentOutOfWorkingHours = moment(startTime).isBefore(workingHoursStart)
            || moment(startTime).isSameOrAfter(workingHoursEnd)
            || moment(workingHoursEnd).isBetween(startTime, endTime, undefined, '(]');
    // Check if appointment time is not on breaking hours
  const breakingHoursDay = individualCopy.breakingHours.find((day) => startDayName === day.week_day);
  const breakingHoursStart = moment(`${appointmentCopy.date} ${breakingHoursDay.start_time}`).format();
  const breakingHoursEnd = moment(`${appointmentCopy.date} ${breakingHoursDay.end_time}`).format();
  const isAppointmentIncludesBreakingHours = breakingHoursDay.is_breaking
            && (moment(breakingHoursStart).isBetween(startTime, endTime, undefined, '[]')
            || moment(breakingHoursEnd).isBetween(startTime, endTime, undefined, '(]')
            || moment(startTime).isBetween(breakingHoursStart, breakingHoursEnd, undefined, '()'));
  isAppointmentOutOfWorkingHours && snackBarAlert(true, i18n.t('UserNotWork'), 'error');
  isAppointmentIncludesBreakingHours && snackBarAlert(true, i18n.t('UserNotBreak'), 'error');
  return (
    <>
      !isAppointmentOutOfWorkingHours && !isAppointmentIncludesBreakingHours
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};
export default validateAppointmentTimeForIndividual;
