import PropTypes from 'prop-types';
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import Box from '@material-ui/core/Box';

function ListLoading({ count }) {
  const rows = [];
  for (let i = 0; i < count; i += 1) {
    rows.push(
      <Box sx={{ width: '100%' }} key={i}>
        <Skeleton animation="wave" />
      </Box>,
    );
  }
  return <>{rows}</>;
}

export default ListLoading;

ListLoading.propTypes = {
  count: PropTypes.number.isRequired,
};
