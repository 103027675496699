const filterItems = {
  services: [],
  status: [
    {
      title: 'Upcoming',
      name: 'upcoming',
      status_id: '1',
    },
    {
      title: 'Pending',
      name: 'pending',
      status_id: '2',
    },
    {
      title: 'Canceled',
      name: 'cancelled',
      status_id: '3',
    },
    {
      title: 'Finished',
      name: 'finished',
      status_id: '4',
    },
  ],
};

export default filterItems;
