import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import usePrevious from '../../../CustomHooks/usePrevious';
import PersonalDetails from './PersonalDetails';
import AccountType from './AccountType';
import { addAccountTypeRequest } from '../../../redux/account/actions';
import BusinessDetails from './BusinessDetails';
import { getAllCountriesRequest } from '../../../redux/country/actions';
import SnackbarToast from '../../../Modules/SnackbarToast';

function Onboarding(props) {
  const {
    userAccount,
    addAccountType,
    isAddAccountTypeSuccess,
    isAddAccountTypeError,
    addAccountTypeErrorMessage,
    getAllCountries,
    isGetAllCountriesSuccess,
    allCountries,
  } = props;
  const prevIsAddAccountTypeSuccess = usePrevious(isAddAccountTypeSuccess);
  const prevIsAddAccountTypeError = usePrevious(isAddAccountTypeError);
  const prevIsGetAllCountriesSuccess = usePrevious(isGetAllCountriesSuccess);

  const { type } = useParams();
  const history = useHistory();

  const [isBackToAccountType, setIsBackToAccountType] = useState(true);
  const [selectedAccountType, setSelectedAccountType] = useState('');
  const [user, setUser] = useState({});
  const [countries, setCountries] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    if (userAccount.activeRole) {
      const userAccountCopy = { ...userAccount };
      const accountType = getAccountType();
      setUser(userAccountCopy);
      if (userAccount.invited || (type && type === accountType)) {
        setIsBackToAccountType(false);
        setSelectedAccountType(accountType);
        history.push(`/onboarding/${accountType}`);
      } else if (type && type !== accountType) {
        history.push('/onboarding');
      }
    } else {
      history.push('/onboarding');
    }
  }, [userAccount]);

  useEffect(() => {
    if (prevIsAddAccountTypeSuccess === false && isAddAccountTypeSuccess) {
      setIsBackToAccountType(false);
      history.push(`/onboarding/${selectedAccountType}`);
    } else if (prevIsAddAccountTypeError === false && isAddAccountTypeError) {
      setOpenSnackbar(true);
      setSnackbarMessage(addAccountTypeErrorMessage);
      setSnackbarType('error');
    }
  }, [isAddAccountTypeSuccess, isAddAccountTypeError]);

  useEffect(() => {
    if (prevIsGetAllCountriesSuccess === false && isGetAllCountriesSuccess) {
      setCountries(allCountries);
    }
  }, [isGetAllCountriesSuccess]);

  const getAccountType = () => {
    const { activeRole: { name } } = userAccount;

    return name === 'Owner' ? 'business' : 'personal';
  };

  const backToAccountType = (isBack = true, selectedType = '') => {
    setIsBackToAccountType(isBack);
    setSelectedAccountType(selectedType);
    history.push('/onboarding');
  };

  const handleAccountTypeSubmit = (type) => {
    const isUpdate = (user.activeRole && user.activeRole.name);
    setSelectedAccountType(type);
    addAccountType({ type, isUpdate });
  };

  const detailsProps = {
    backToAccountType,
    invited: userAccount.invited,
    countries,
  };

  return isBackToAccountType && !userAccount.invited ? (
    <>
      <AccountType
        userAccount={user}
        handleSubmit={handleAccountTypeSubmit}
        backToAccountType={backToAccountType}
      />
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  ) : (selectedAccountType === 'business'
    ? (<BusinessDetails {...detailsProps} />)
    : (<PersonalDetails {...detailsProps} />)
  );
}

Onboarding.propTypes = {
  userAccount: PropTypes.object.isRequired,
  addAccountType: PropTypes.func.isRequired,
  isAddAccountTypeSuccess: PropTypes.bool.isRequired,
  isAddAccountTypeError: PropTypes.bool.isRequired,
  addAccountTypeErrorMessage: PropTypes.string.isRequired,
  // Get countries
  getAllCountries: PropTypes.func.isRequired,
  isGetAllCountriesSuccess: PropTypes.bool.isRequired,
  allCountries: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  userAccount: state.account.userAccount,
  isAddAccountTypeSuccess: state.account.isAddAccountTypeSuccess,
  isAddAccountTypeError: state.account.isAddAccountTypeError,
  addAccountTypeErrorMessage: state.account.addAccountTypeErrorMessage,
  // Get countries
  isGetAllCountriesSuccess: state.country.isGetAllCountriesSuccess,
  isGetAllCountriesError: state.country.isGetAllCountriesError,
  allCountries: state.country.allCountries,
});

function mapDispatchToProps(dispatch) {
  return {
    addAccountType: (data) => dispatch(addAccountTypeRequest(data)),
    getAllCountries: (data) => dispatch(getAllCountriesRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
