import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab } from '@material-ui/core';

function NavTabs(props) {
  const {
    activeTabIndex,
    handleChangeTab,
  } = props;
  return (
    <AppBar position="static" color="inherit" elevation={0}>
      <Tabs
        value={activeTabIndex}
        aria-label="simple tabs example"
        variant="scrollable"
        indicatorColor="primary"
        textColor="secondary"
      >
        <Tab
          label="Info"
          className={`nav-item${activeTabIndex === 0 ? ' active' : ''}`}
          onClick={() => handleChangeTab('info')}
        />
        <Tab
          label="Working Schedule"
          className={`nav-item${activeTabIndex === 1 ? ' active' : ''}`}
          onClick={() => handleChangeTab('working-schedule')}
        />
        <Tab
          label="Services"
          className={`nav-item${activeTabIndex === 2 ? ' active' : ''}`}
          onClick={() => handleChangeTab('services')}
        />
      </Tabs>
    </AppBar>
  );
}

NavTabs.propTypes = {
  activeTabIndex: PropTypes.number.isRequired,
  handleChangeTab: PropTypes.func.isRequired,
};

export default NavTabs;
