const resources = {
  en: {
    translation: {
      Accept: 'Accept',
      Accepted: 'Accepted',
      AccountCreated: 'Account created.',
      Actions: 'Options',
      Add: 'Add',
      AddAppointment: 'Add Appointment',
      AddClosedDays: 'Add Closed Days',
      AddCompany: 'Add Company',
      AddCustomer: 'Add Customer',
      AddDayOff: 'Add Days Off',
      AddNewCustomer: 'Add New Customer',
      Address: 'Street address',
      AddService: 'Add Service',
      AddShortDescOptional: 'Add a short description (optional)',
      AddSpecificWorkDays: 'Add Specific Workdays',
      AddStaff: 'Add Staff',
      All: 'All',
      AllAppointmentsCancelText: 'All upcoming appointments will be cancelled.',
      AMD: 'AMD',
      Apply: 'Apply',
      ApplyToAll: 'Apply to All',
      'Appointment Count': 'Appointment Count',
      AppointmentAccepted: 'Appointment accepted successfully',
      AppointmentCanceled: 'Appointment canceled successfully',
      AppointmentCancelModalText: 'Are you sure you want to cancel your appointment?',
      AppointmentCreated: 'Appointment created successfully',
      AppointmentDeleted: 'Appointment deleted successfully',
      AppointmentDeleteModalText: 'Appointment will disappear from calendar and won’t be calculated in Statistics.',
      AppointmentDeleteQuestion: 'Are you sure you want to delete the appointment ?',
      AppointmentDetails: 'Appointment Details',
      AppointmentHistory: 'Appointment History',
      AppointmentNotes: 'Not visible to customers',
      AppointmentNotFiltered: 'Appointment is not filtered',
      AppointmentNotFound: 'Appointment not found.',
      AppointmentRescheduled: 'Appointment rescheduled successfully',
      Appointments: 'Appointments',
      AppointmentSaveDraft: 'Appointment saved in draft',
      AppointmentsHistory: 'Appointments History',
      AreYouSureCancel: 'Are you sure you want to cancel the',
      AreYouSureDelete: 'Are you sure you want to delete the',
      'Assigned Services': 'Assigned Services',
      AtLeastOne: 'At least one ',
      AutoAccept: 'Automatically accept appointments',
      AutoAcceptLabel: 'Auto Accept',
      AutoAcceptTooltipText: 'Appointments received from online booking will be accepted automatically.',
      AutoFinish: 'Automatically mark confirmed appointments as finished once their end time has passed',
      AvailableHours: 'Available time slots',
      BookingLink: 'Booking link',
      BookingUrl: 'Booking URL',
      BreakEndTimeAfter: 'Breaking end time should be after breaking start time.',
      BreakEndTimeRequired: 'Break end time is required.',
      Breaking: 'Breaking',
      BreakStartTimeRequired: 'Break start time is required.',
      BufferTime: 'Buffer Time',
      BufferTimeTooltipText: 'Adding extra time after the service, which is not shown to user.',
      BusinessNotSaved: 'Business details not saved.',
      BusinessSaved: 'Business details saved.',
      ByDate: 'By Date',
      ByStatus: 'By Status',
      Calendar: 'Calendar',
      Category: 'Category',
      CategoryIsRequired: 'Category is required',
      CalendarTimeSlot: 'Time range',
      Cancel: 'Cancel',
      CancelAllUpcomingAppointments: 'Cancel all upcoming appointments',
      CancelAppointment: 'Cancel Appointment',
      CancelInvitation: 'Cancel Invitation',
      Cancelled: 'Cancelled',
      CantInviteYourSelf: "You can't invite yourself as customer",
      Categories: 'Categories',
      Characters: 'characters',
      ChooseCustomer: 'Select a customer',
      ChooseHour: 'Choose Hour',
      ChooseMinutes: 'Choose Minutes',
      ChooseOrCreateService: 'Choose or Create Service',
      CreateService: 'Create Service',
      ChooseOrCreateServiceCategory: 'Choose or Create Service Category',
      ChooseServiceCategory: 'Choose Service Category',
      ChooseService: 'Select a service',
      ChooseServiceProvider: 'Choose Service Provider',
      ChooseStaff: 'Select a staff member',
      City: 'City',
      Close: 'Closed',
      ClosedDayAdded: 'Closed day(s) added successfully.',
      ClosedDays: 'Closed Days',
      ClosedDayUpdated: 'Closed day(s) updated successfully.',
      Code: 'Country code',
      Companies: 'Companies',
      Company: 'Company',
      CompanyDeleted: 'Company deleted successfully',
      CompanyEmail: 'Enter company email address',
      CompanyName: 'Company name',
      CompanyOverview: 'Write a brief overview of your company',
      Confirm: 'Confirm',
      ConfirmActionMassagesDelete: 'Are you sure you want to remove this company?',
      ConfirmActionMassagesDeleteError: 'You cannot remove this company yet because it has upcoming appointments.',
      ConfirmActionMassagesLeave: 'Are you sure that you want to leave the company ?',
      ConfirmCancel: 'Confirm Cancel',
      ConfirmDelete: 'Confirm Deletion',
      ConfirmDeletion: 'Confirm Deletion',
      ConfirmPassword: 'Confirm password',
      continueDeleting: 'Continue deleting?',
      continueWithFacebook: 'Continue With Facebook',
      Country: 'Country',
      Create: 'Create',
      CreateAppointment: 'Create Appointment',
      CurrentPassword: 'Current password',
      Customer: 'Customer',
      'Customer Name': 'Customer Name',
      CustomerAdded: 'Customer added',
      CustomerDeleted: 'Customer deleted successfully',
      CustomerDeleteText: 'If there is any appointment with these customers it will be canceled.',
      CustomerDetails: 'Customer Details',
      CustomerPreview: 'Customer Preview',
      Customers: 'Customers',
      CustomerUpdated: 'Customer updated successfully',
      Daily: 'Daily',
      Date: 'Date',
      DateAndTime: 'Date & Time',
      DatePicker: 'Date Picker',
      DateTime: 'Date/Time',
      DayAdded: 'Day(s) off added successfully',
      DayOfBirth: 'Date of birth',
      DayOffDeleted: 'Day(s) off deleted successfully.',
      DaysOff: 'Days Off',
      DayUpdated: 'Day(s) off updated successfully.',
      Decline: 'Decline',
      DefaultView: 'Default calendar view',
      Delete: 'Delete',
      DeleteCategoryText: 'If you delete this service category, all the services under it will also be deleted.',
      deleteMultipleStaffText: 'If there is any appointment with these staff it will be canceled.',
      Description: 'Description',
      Details: 'Details',
      Digit: 'digit',
      DontGetCode: 'Don’t get a code?',
      DontHaveAnAccount: "Don't have an account?",
      DownloadAndSignUp: 'Download the app and Sign up now!',
      Duration: 'Duration',
      Edit: 'Edit',
      EditAppointment: 'Edit Appointment',
      EditCategory: 'Edit Category',
      EditCustomer: 'Edit Customer',
      EditService: 'Edit Service',
      EditSpecificWorkDays: 'Edit Specific Workdays',
      EditStaff: 'Edit Staff',
      Email: 'Email',
      EmailOptional: 'Email (optional)',
      EmailRequired: 'Email is required.',
      EmailResent: 'Email is successfully resent.',
      EmailValid: 'Please enter a valid email address.',
      EndDate: 'End date',
      EndDateRequired: 'End date is required.',
      EndTime: 'End time',
      EndTimeAfter: 'End time should be after start time.',
      EnterCodeText: 'Enter 6-digit code sent to your',
      EnterCustomerEmail: 'Enter customer email',
      EnterCustomerFirstName: 'Enter customer first name',
      EnterCustomerLastName: 'Enter customer last name',
      EnterCustomerPhoneNumber: 'Enter customer phone number',
      EnterSignInDetailsText: 'Please enter your details to get sign In.',
      EnterStaffFirstName: 'Enter staff first name',
      EnterStaffLastName: 'Enter staff last name',
      EnterStaffPhoneNumber: 'Enter staff phone number',
      EnterStaffProfession: 'Enter staff profession',
      Facebook: 'Facebook page URL',
      FasterAndMoreOrganizedText: 'Let’s make the process of booking faster and more organized.',
      Female: 'Female',
      FileNotSupported: 'The file is not supported',
      Filter: 'Filter',
      FinalPriceText: 'Please define final price for this service.',
      Finish: 'Finish',
      FinishAppointment: 'Finish Appointment',
      Finished: 'Finished',
      'First Visit': 'First Visit',
      FirstName: 'First Name',
      FirstNameOrLastNameRequired: 'First name or last name is required',
      Fixed: 'Fixed',
      FollowUp: 'Follow-up',
      ForgotPassword1: 'Forgot password?',
      ForgotPassword2: 'Forgot password',
      Free: 'Free',
      Fri: 'Fri',
      Friday: 'Friday',
      From: 'From',
      Gallery: 'Gallery',
      Gender: 'Gender',
      General: 'General Information',
      GeneralSchedule: {
        BreakHours: 'Break time',
        Title: 'General Schedule',
        WeekDays: 'Working days',
        WorkingHours: 'Working hours',
      },
      H: 'h',
      haveOneStaff: 'Having at least 1 staff is required.',
      Hour: 'Hour',
      ImageDeleted: 'Image successfully deleted',
      IndustriesNotSaved: 'Industries details not saved.',
      Industry: 'Industry',
      IndustryDeleted: 'Industry deleted successfully.',
      IndustryNotFound: 'Industry is not found.',
      IndustryUpdated: 'Industry updated successfully.',
      Info: 'Info',
      InfoGeneral: 'General Information',
      InfoLinks: 'Links',
      InfoLocation: 'Location',
      InfoTitle: 'Info',
      Instagram: 'Instagram account handle',
      InvalidData: 'Invalid data.',
      InvalidLoginOrPass: 'Invalid Login or Password.',
      Invitation: 'Invitation',
      InvitationAccepted: 'Invitation accepted successfully',
      InvitationDeclined: 'Invitation declined.',
      InvitationDeleted: 'Invitation deleted successfully',
      InvitationSent: 'Invitation sent.',
      InvitationUpdated: 'Invitation updated successfully',
      Invite: 'Invite',
      InviteCustomer: 'Invite to Register',
      InviteCustomerDescription: 'Invite <0>{{name}}</0> to register on SetTime.',
      Invited: 'Invited',
      InviteToRegister: 'Invite to register',
      IsNotValid: ' is not valid',
      IsRequired: ' is required',
      IsTooLong: ' is too long',
      Language: 'Language',
      'Last Visit': 'Last Visit',
      LastName: 'Last Name',
      LastNameOptional: 'Last Name (optional)',
      Leave: 'Leave',
      LeaveCompany: 'Leave company.',
      LoadDraft: 'Load Draft',
      Loading: 'Loading',
      Location: 'Location',
      Logout: 'Log Out',
      Lowercase: 'lowercase',
      lowercasePhoneNumber: 'Phone number',
      MaintenanceTime: 'Maintenance Time',
      Male: 'Male',
      Manager: 'Manager',
      Min: 'min',
      Mon: 'Mon',
      Monday: 'Monday',
      Monthly: 'Monthly',
      MyAppointments: 'Appointments',
      MyCompany: 'My Company',
      MyProfile: 'My Profile',
      Name: 'Name',
      New: 'New',
      NewAppointment: 'New Appointment',
      NewCategory: 'New Category',
      NewCompany: 'New Company',
      NewCustomer: 'New Customer',
      NewPassMustBeDifferentText: 'Your new password must be different from previous used password',
      NewPassword: 'New password',
      NewService: 'New Service',
      NewStaff: 'New Staff',
      Next: 'Next',
      No: 'No',
      NoAppointments: 'No appointments yet',
      NoNotifications: 'No notifications yet',
      NoOptions: 'No Options',
      Note: 'Note',
      Notes: 'Appointment notes',
      Notification: 'Notification',
      NotificationCanceled: 'Notify customers when their appointment is canceled',
      NotificationConfirmed: 'Notify customers when their appointment is confirmed',
      NotificationRescheduled: 'Notify customers when their appointment is rescheduled',
      NotRegistered: 'Not registered',
      Off: 'Off',
      On: 'On',
      Open: 'Open',
      Order: 'Scheduling Order',
      Password: 'Password',
      PasswordRequired: 'Password is required.',
      PasswordSettings: 'Change Password',
      PastDate: 'Allow to create past-date appointments',
      Pending: 'Pending',
      Permalink: 'Permalink',
      PersonalInformation: 'Personal Information',
      Phone: 'Phone',
      PhoneCode: 'Country code',
      PhoneNumber: 'Phone Number',
      PhoneNumberOptional: 'Phone Number (optional)',
      Position: 'Position',
      Price: 'Price',
      PriceType: 'Price type',
      Pricing: 'Pricing',
      Profession: 'Profession',
      ProfessionOptional: 'Profession (optional)',
      Range: 'Range',
      ReadAll: 'Read All',
      Registered: 'Registered',
      RemindCustomer: 'Remind the customer',
      Reminder: 'Reminders',
      Resend: 'Resend',
      ResetPassword: 'Reset Password',
      RowsPerPage: 'Rows per page',
      Sat: 'Sat',
      Saturday: 'Saturday',
      Save: 'Save',
      SaveDraft: 'Save Draft',
      SaveDraftText: 'Would you like to save this appointment as a draft?',
      SearchByCustomerOrStaff: 'Search by customer or staff',
      SearchCustomer: 'Search by customer name',
      SearchService: 'Search by service name',
      SearchStaff: 'Search by staff name',
      SelectedDayNotWork: 'Selected day is not working day',
      Send: 'Send',
      Service: 'Service',
      'Service Name': 'Service Name',
      ServiceBased: 'Service-based',
      ServiceCategory: 'Service category',
      ServiceDeleted: 'Service deleted successfully.',
      ServiceDeletionText: 'This service will be unavailable for booking and if there is any appointment it will be canceled.',
      ServiceDuration: 'Service duration',
      ServiceNotFound: 'Service not found.',
      ServiceProvider: 'service provider',
      Services: 'Services',
      Settings: 'Settings',
      ShortPassword: 'Password is too short.',
      ShowLess: 'Show Less',
      ShowMore: 'Show More',
      ShowOnWidget: 'Show on Widget',
      ShowOnWidgetTooltipText: 'Service will be available for online booking.',
      SignIn: 'Sign In',
      SlotDuration: 'Calendar slot duration',
      SomethingWrong: 'Something went wrong.',
      Source: 'Source',
      SpecificDayAdded: 'Specific workday(s) added successfully.',
      SpecificDayDeleted: 'Specific workday(s) deleted successfully.',
      SpecificDaysTitle: 'Specific Workdays',
      SpecificDayUpdated: 'Specific workday(s) updated successfully.',
      SpecificWorkDayDeleted: 'Specific workday(s) deleted successfully.',
      Staff: 'Staff',
      'Staff Name': 'Staff Name',
      StaffBased: 'Staff-based',
      StaffDetails: 'Staff Details',
      StaffNotFind: 'Staff is not find !',
      StaffPreview: 'Staff Preview',
      Staffs: 'Staffs',
      StartDate: 'Start date',
      StartTime: 'Start time',
      StartTimeEarlier: 'The start time should be earlier than the end time.',
      StartTimeRequired: 'Start time is required.',
      State: 'State',
      Statistics: 'Statistics',
      Status: 'Status',
      Sun: 'Sun',
      Sunday: 'Sunday',
      TableHeader: {
        Actions: 'Options',
        BreakTime: 'Break',
        Date: 'Dates',
        Description: 'Description',
        Staff: 'Staff',
        Time: 'Working hours',
      },
      ThankYou: 'Send a thank-you message to the customer',
      ThisMonth: 'This month',
      ThisServiceHas: 'This service has ',
      ThisWeek: 'This week',
      Thu: 'Thu',
      Thursday: 'Thursday',
      Time: 'Time',
      TimeZone: 'Time zone',
      Tin: 'TIN',
      Title: 'Title',
      To: 'To',
      Today: 'Today',
      Tomorrow: 'Tomorrow',
      Total: 'Total',
      TotalPayments: 'Revenue',
      Tue: 'Tue',
      Tuesday: 'Tuesday',
      Upcoming: 'Upcoming',
      UpcomingAppointments: 'Upcoming Appointments',
      UpcomingAppointmentServiceModalText: ' upcoming appointment(s).',
      upcomingAppointmentWith: 'The upcoming appointments with',
      Update: 'Update',
      UpdatedSuccess: 'Updated Successfully.',
      Uppercase: 'uppercase',
      UserNotBreak: 'User is on brake at selected hours',
      UserNotWork: 'User does not work at selected hours',
      ValidAppointment: 'Valid Appointment fields',
      VerificationCode: 'Verification Code',
      Visits: 'Visits',
      WalkIn: 'Walk In',
      WalkInDesc: 'Choose walk in if you want to skip customer.',
      WebSite: 'Company website URL',
      Wed: 'Wed',
      Wednesday: 'Wednesday',
      Weekly: 'Weekly',
      WeWillSendVerificationCode: 'We will send you the 6 digit verification code.',
      willBeCancelled: ' will be cancelled.',
      WithDrawInvitation: 'Cancel Invitation',
      'Work Schedule': 'Work schedule',
      WorkDateEndTimeRequired: 'Workday end time is required.',
      WorkDateStartTimeRequired: 'Workday start time is required.',
      WorkdayEnd: 'Workday end',
      WorkdayStart: 'Workday Start',
      WorkingScheduleTab: {
        Company: 'Company',
        General: 'General',
        Staff: 'Staff',
      },
      WorkSchedule: 'Work Schedule',
      Yes: 'Yes',
      ZipCode: 'Zip code',
    },
  },
};

export default resources;
