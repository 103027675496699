import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  InputLabel,
  Select,
  Paper,
  TableCell,
  MenuItem,
  IconButton,
  Grid,
  Button,
  FormControl,
  FormHelperText,
  Box,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import TableContainer from '@material-ui/core/TableContainer';
import ErrorIcon from '@material-ui/icons/Error';
import i18n from 'i18next';

import { storeIndividualUserIndustryRequest } from '../../../redux/individualUser/actions';
import countryCodeOptionsModule from '../../../Modules/countryCodeOptions';
import { addServiceRequest, getIndustryServicesRequest } from '../../../redux/service/actions';
import Loading from '../../../Components/Loading/loading';
import { getIndustriesRequest } from '../../../redux/industry/actions';
import usePrevious from '../../../CustomHooks/usePrevious';
import TextMaskCustom from '../../../Modules/TextMaskCustom';
import SnackbarToast from '../../../Modules/SnackbarToast';

function NewIndustryModal(props) {
  const {
    open,
    onClose,
    services,
    addService,
    industries,
    allCountries,
    userAccount,
    getIndustries,
    getIndustryServices,
    isGetIndustriesSuccess,
    individualUserIndustries,
    setIndividualUserIndustries,
    isGetIndustryServicesSuccess,
    storeIndividualUserIndustry,
    storedIndividualUserIndustry,
    isGetIndustryServicesError,
    isStoreIndividualUserIndustrySuccess,
    isStoreIndividualUserIndustryError,
    storeIndividualUserIndustryErrors,
  } = props;

  // Get some props for previous service* values
  const prevIsStoreIndividualUserIndustrySuccess = usePrevious(isStoreIndividualUserIndustrySuccess);
  const prevIsStoreIndividualUserIndustryError = usePrevious(isStoreIndividualUserIndustryError);
  const prevIsGetIndustryServicesSuccess = usePrevious(isGetIndustryServicesSuccess);
  const prevIsGetIndustryServicesError = usePrevious(isGetIndustryServicesError);
  const prevIsGetIndustriesSuccess = usePrevious(isGetIndustriesSuccess);

  // Industry*
  const [industryOptions, setIndustryOptions] = useState([]);
  const [selectedIndustryOption, setSelectedIndustryOption] = useState({ id: null });
  const [selectedCodeOption, setSelectedCodeOption] = useState({ value: null });
  const [individualUserData, setIndividualUserData] = useState({
    industry_id: '',
    phone_code: '',
    phone_number: '',
  });
  const [individualUserIndustry, setIndividualUserIndustry] = useState({ });
  const [codeOptions, setCodeOptions] = useState([]);
  // Services*
  const [selectedServices, setSelectedServices] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [errors, setErrors] = useState({
    industry_id: false,
    phone_code: false,
    phone_number: false,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const individualUserDataCopy = { ...individualUserData };
    setIndividualUserIndustry(individualUserDataCopy);
    setSelectedIndustryOption({ id: individualUserDataCopy.industry_id });
    setSelectedCodeOption({ value: individualUserDataCopy.phone_code });
    getIndustries();
    const codeOptions = countryCodeOptionsModule.getCodeOptions(allCountries);
    setCodeOptions(codeOptions);
    const getIndustryOptions = countryCodeOptionsModule.getIndustryOptions(industries);
    setIndustryOptions(getIndustryOptions);
    const newIndustries = industries.filter((item) => !individualUserIndustries.includes(parseInt(item.id)));
    const industryOptions = countryCodeOptionsModule.getIndustryOptions(newIndustries);
    setIndustryOptions(industryOptions);
    if (industryOptions.length > 0 && individualUserIndustry.industry_id === '') {
      setSelectedIndustryOption({ id: industryOptions[0].id });
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (open) {
      setAllServices([]);
      setValidationErrors({});
      setSelectedCodeOption('');
      setIndividualUserData({
        industry_id: '',
        phone_code: '',
        phone_number: '',
      });
      setSelectedIndustryOption({
        id: null,
      });
    }
  }, [open]);

  useEffect(() => {
    if (prevIsGetIndustriesSuccess === false && isGetIndustriesSuccess) {
      const industriesCopy = [...industries];
      setIndustryOptions(industriesCopy);
    }
  }, [isGetIndustriesSuccess]);

  useEffect(() => {
    if (selectedIndustryOption.value) {
      const getUserIndustriesId = individualUserData.industry_id;
      getIndustryServices({
        industryId: getUserIndustriesId,
      });
    }
  }, [selectedIndustryOption]);

  useEffect(() => {
    if (prevIsStoreIndividualUserIndustrySuccess === false && isStoreIndividualUserIndustrySuccess) {
      if (storedIndividualUserIndustry.industry_id) {
        const individualUserIndustriesCopy = [...individualUserIndustries];
        individualUserIndustriesCopy.push(storedIndividualUserIndustry);
        setIndividualUserIndustries(individualUserIndustriesCopy);
        addService({
          services: selectedServices,
          individual_user_industry_id: storedIndividualUserIndustry.id,
        });
        setIndividualUserData({
          industry_id: '',
          duration: '',
          price: '',
          service_id: '',
          service_name: '',
          phone_code: userAccount.phone_code,
          phone_number: userAccount.phone_number,
        });
        setAllServices([]);
        setSelectedIndustryOption({
          id: null,
        });
        setValidationErrors({});
        setSelectedCodeOption('');
        onClose();
        setLoading(false);
      }
    }
  }, [isStoreIndividualUserIndustrySuccess]);

  useEffect(() => {
    if (prevIsStoreIndividualUserIndustryError === false && isStoreIndividualUserIndustryError) {
      setValidationErrors(storeIndividualUserIndustryErrors);
      setAllServices([]);
      setSelectedIndustryOption({
        id: null,
      });
    }
  }, [isStoreIndividualUserIndustryError]);

  useEffect(() => {
    if (prevIsGetIndustryServicesSuccess === false && isGetIndustryServicesSuccess) {
      const serviceOptions = services.map((service) => ({
        label: service.name,
        value: service.id,
      }));
      setServiceOptions(serviceOptions);
      setLoading(false);
    }
  }, [isGetIndustryServicesSuccess]);

  useEffect(() => {
    if (prevIsGetIndustryServicesError === false && isGetIndustryServicesError) {
      setLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage('Something went wrong, please refresh the page');
      setSnackbarType('error');
    }
  }, [isGetIndustryServicesError]);

  useEffect(() => {
    serviceOptions.sort(compareServices);
  }, [serviceOptions]);

  const setIndividualUserFunction = (dataOptions) => {
    setIndividualUserData((prevState) => ({
      ...prevState,
      ...dataOptions,
    }));
  };

  // Change Price/Duration value
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIndividualUserFunction({
      [name]: value,
    });
    setIndividualUserIndustry({
      phone_number: industryOptions[0],
    });
  };
  // Change Industry*
  const handleNewIndustryOptionChange = (event) => {
    const { value } = event.target;
    const findSelectedIndustry = industryOptions.find((item) => item.id === value);
    if (findSelectedIndustry) {
      setSelectedIndustryOption(findSelectedIndustry);
      setIndividualUserFunction({
        industry_id: findSelectedIndustry.id,
      });
      setAllServices([]);
    }
    if (errors.industry_id || errors.service_id) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        industry_id: false,
        service_id: false,
      }));
    }
  };

  const handlePhoneCodeOptionChange = (event) => {
    setSelectedCodeOption({ value: event.target.value });
    setIndividualUserFunction({
      phone_code: event.target.value,
    });
  };

  const validateForm = () => {
    const errorsCopy = { ...errors };
    errorsCopy.industry_id = individualUserData.industry_id === '';
    errorsCopy.phone_code = individualUserData.phone_code === '';
    errorsCopy.phone_number = individualUserData.phone_number === '';
    setErrors(() => ({ ...errorsCopy }));
    return errorsCopy;
  };

  const compareServices = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };

  const handleAddIndustry = () => {
    const errorExists = validateForm();
    if (errorExists) {
      storeIndividualUserIndustry(individualUserData);
    }
  };

  const handleDeleteService = (service, key) => {
    const allServicesCopy = [...allServices];
    if (allServicesCopy.length > 0) {
      allServicesCopy.splice(key, 1);
      setSelectedServices(allServicesCopy);
      setAllServices(allServicesCopy);
      const serviceOptionsCopy = [...serviceOptions];
      serviceOptionsCopy.push({
        value: service.service_id,
        label: service.service_name,
      });
      setServiceOptions(serviceOptionsCopy);
    }
  };

  return !loading ? (
    <div>
      <Dialog onClose={onClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span className="alert-title">
            <span>New Industry</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Grid
            container
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={4}>
              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                error={(!!errors.phone_code)}
              >
                <InputLabel>Code *</InputLabel>
                <Select
                  value={selectedCodeOption.value || ''}
                  onChange={(event) => handlePhoneCodeOptionChange(event)}
                  label="Code *"
                >
                  { codeOptions.length > 0 && codeOptions.map((item) => (
                    <MenuItem
                      key={Math.random()}
                      value={item.value}
                    >
                      <img
                        src={item.label.flagImageSrc}
                        alt="flag"
                        className="country-flag"
                      />
                      {item.key}
                      {item.value}
                    </MenuItem>
                  )) }
                </Select>

                { errors.phone_code && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {i18n.t('PhoneCode')} is required.
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl
                fullWidth
                margin="dense"
                variant="outlined"
                error={(!!errors.phone_number)}
              >
                <TextField
                  fullWidth
                  error={(!!errors.phone_number)}
                  size="small"
                  value={individualUserData.phone_number}
                  label="Phone Number *"
                  name="phone_number"
                  variant="outlined"
                  type="text"
                  onChange={(event) => handleInputChange(event)}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />

                { errors.phone_number && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      Phone number is required.
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                error={(!!errors.industry_id || !!validationErrors.industry_id)}
              >
                <InputLabel>Industry *</InputLabel>
                <Select
                  name="industry_id"
                  value={selectedIndustryOption.id}
                  onChange={(event) => handleNewIndustryOptionChange(event)}
                  label="Industry *"
                  id="industry"
                >
                  <MenuItem value="" disabled><em>Select Industry</em></MenuItem>
                  { industryOptions.length > 0 && industryOptions.map((item) => (
                    <MenuItem
                      key={Math.random()}
                      value={item.id}
                    >
                      { item.name }
                    </MenuItem>
                  )) }
                </Select>

                { validationErrors.industry_id && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {validationErrors.industry_id}
                    </FormHelperText>
                  </div>
                )}

                { errors.industry_id && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      Industry is required.
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
          </Grid>
          { allServices && allServices.length
            ? (
              <Box mt="10px">
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Service Name</TableCell>
                        <TableCell align="left">Time (min)</TableCell>
                        <TableCell align="left">Price ($)</TableCell>
                        <TableCell align="left" />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { allServices.map((service, key) => (
                        <TableRow key={service.id}>
                          <TableCell component="th" scope="row">
                            {service.service_name}
                          </TableCell>
                          <TableCell align="left">
                            { service.duration }
                          </TableCell>
                          <TableCell align="left">
                            { service.price }
                          </TableCell>
                          <TableCell align="left">
                            <IconButton
                              size="small"
                              aria-label="delete"
                              onClick={() => handleDeleteService(service, key)}
                            >
                              <DeleteOutlineIcon fontSize="small" color="secondary" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )
            : null }
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => handleAddIndustry()}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  ) : <Loading />;
}

NewIndustryModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // Get Parent Props
  individualUserIndustries: PropTypes.array.isRequired,
  setIndividualUserIndustries: PropTypes.func.isRequired,
  // Get User Account
  userAccount: PropTypes.object.isRequired,
  // Get Countries
  allCountries: PropTypes.array.isRequired,
  // Get Industries
  getIndustries: PropTypes.func.isRequired,
  industries: PropTypes.array.isRequired,
  isGetIndustriesSuccess: PropTypes.bool.isRequired,
  // Industry
  isStoreIndividualUserIndustrySuccess: PropTypes.bool.isRequired,
  isStoreIndividualUserIndustryError: PropTypes.bool.isRequired,
  storeIndividualUserIndustryErrors: PropTypes.object.isRequired,
  storeIndividualUserIndustry: PropTypes.func.isRequired,
  storedIndividualUserIndustry: PropTypes.object.isRequired,
  getIndustryServices: PropTypes.func.isRequired,
  isGetIndustryServicesSuccess: PropTypes.bool.isRequired,
  isGetIndustryServicesError: PropTypes.bool.isRequired,
  services: PropTypes.array.isRequired,
  // Add service
  addService: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  // Store Individual User Industry
  isStoreIndividualUserIndustrySuccess: state.individualUser.isStoreIndividualUserIndustrySuccess,
  isStoreIndividualUserIndustryError: state.individualUser.isStoreIndividualUserIndustryError,
  storedIndividualUserIndustry: state.individualUser.storedIndividualUserIndustry,
  storeIndividualUserIndustryErrors: state.individualUser.storeIndividualUserIndustryErrors,
  userAccount: state.account.userAccount,
  // Get Industries
  industries: state.industry.industries,
  isGetIndustriesSuccess: state.industry.isGetIndustriesSuccess,
  // Get industry Services
  isGetIndustryServicesSuccess: state.service.isGetIndustryServicesSuccess,
  isGetIndustryServicesError: state.service.isGetIndustryServicesError,
  services: state.service.services,
  // Add service
  isAddServiceSuccess: state.service.isAddServiceSuccess,
  isAddServiceError: state.service.isAddServiceError,
  addServiceErrorMessage: state.service.addServiceErrorMessage,
  // Get Countries
  allCountries: state.country.allCountries,
});

function mapDispatchToProps(dispatch) {
  return {
    storeIndividualUserIndustry: (data) => dispatch(storeIndividualUserIndustryRequest(data)),
    getIndustries: (data) => dispatch(getIndustriesRequest(data)),
    getIndustryServices: (data) => dispatch(getIndustryServicesRequest(data)),
    addService: (data) => dispatch(addServiceRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewIndustryModal);
