import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  IconButton,
  Box,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { connect } from 'react-redux';
import WarningIcon from '@material-ui/icons/Warning';
import i18n from 'i18next';
import { deleteCompanyServiceRequest } from '../../redux/service/actions';
import usePrevious from '../../CustomHooks/usePrevious';
import SnackbarToast from '../../Modules/SnackbarToast';

function DeleteServiceModal(props) {
  const {
    open,
    onClose,
    // companyId,
    companyServiceId,
    setCompanyServiceId,
    serviceIds,
    setServiceIds,
    selectedCompanyParentId,
    setSelectedCompanyParentId,
    companyServices,
    setCompanyServices,
    deleteCompanyService,
    isDeleteCompanyServiceSuccess,
    isDeleteCompanyServiceError,
    deleteCompanyServiceErrorMessage,
    filteredAppointments,
  } = props;

  // Get some props previous values
  const prevIsDeleteCompanyServiceSuccess = usePrevious(isDeleteCompanyServiceSuccess);
  const prevIsDeleteCompanyServiceError = usePrevious(isDeleteCompanyServiceError);

  const [checked, setChecked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Handle Delete Company Service Success
  useEffect(() => {
    if (prevIsDeleteCompanyServiceSuccess === false && isDeleteCompanyServiceSuccess) {
      const companyServicesCopy = [...companyServices];
      const companyService = companyServices.find((service) => service.id === selectedCompanyParentId);
      const companyServiceIndex = companyServices.findIndex((service) => service.id === selectedCompanyParentId);
      if (serviceIds.length === 0) {
        const subServices = JSON.parse(companyService.subServices);
        const subServiceIndex = subServices.findIndex((service) => service.company_service_id === companyServiceId);
        subServices.splice(subServiceIndex, 1);
        companyService.subServices = JSON.stringify(subServices);
        if (JSON.parse(companyService.subServices).length === 0) {
          companyServicesCopy.splice(companyServiceIndex, 1);
        } else {
          companyServicesCopy[companyServiceIndex] = companyService;
        }
      } else {
        companyServicesCopy.splice(companyServiceIndex, 1);
      }
      setCompanyServices(companyServicesCopy);
      setCompanyServiceId('');
      setSelectedCompanyParentId('');
      setServiceIds([]);
      onClose();
      snackBarAlert(true, i18n.t('ServiceDeleted'), 'success');
    }
  }, [isDeleteCompanyServiceSuccess]);

  // Handle Delete Company Service Error
  useEffect(() => {
    if (prevIsDeleteCompanyServiceError === false && isDeleteCompanyServiceError) {
      snackBarAlert(true, deleteCompanyServiceErrorMessage, 'error');
    }
  }, [isDeleteCompanyServiceError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleDeleteService = () => {
    if (serviceIds.length > 0) {
      deleteCompanyService({
        ids: serviceIds,
        checked,
      });
    } else {
      deleteCompanyService({
        id: companyServiceId,
        checked,
      });
    }
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="customized-dialog-title">
        <DialogTitle id="customized-dialog-title" onClose={onClose}>
          <span className="alert-title">
            <span>Delete Service</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {filteredAppointments.length > 0
              ? (
                <>
                  <Box>
                    <Box mb={3} display="flex" alignItems="center" justifyContent="center">
                      <Box>
                        <WarningIcon />
                      </Box>
                      <Box>
                        <b>This service has { filteredAppointments.length } upcoming {filteredAppointments.length >= 2 ? 'appointments' : 'appointment'}</b>
                      </Box>
                    </Box>
                    <Box>
                      <FormControlLabel
                        control={<Checkbox color="primary" onChange={handleCheckboxChange} checked={checked} />}
                        label="Cancel all upcoming appointments with this service"
                      />
                    </Box>
                  </Box>
                </>
              ) : (
                <>
                  {serviceIds.length > 0 ? (
                    <Box>
                      <Typography gutterBottom>
                        Are you sure?
                      </Typography>
                      <Typography gutterBottom>
                        If you delete this category, all services included in it will be deleted too.
                      </Typography>
                    </Box>
                  ) : (
                    <Box>
                      <Typography gutterBottom>
                        Are you sure?
                      </Typography>
                      <Typography gutterBottom>
                        You and your customers will not be able to create appointments with this service anymore
                      </Typography>
                    </Box>
                  )}
                </>
              )}

          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={handleDeleteService}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  );
}

DeleteServiceModal.propTypes = {
  // Parent Props
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // companyId: PropTypes.number.isRequired,
  companyServiceId: PropTypes.number.isRequired,
  setCompanyServiceId: PropTypes.func.isRequired,
  serviceIds: PropTypes.array.isRequired,
  setServiceIds: PropTypes.func.isRequired,
  selectedCompanyParentId: PropTypes.number.isRequired,
  setSelectedCompanyParentId: PropTypes.func.isRequired,
  companyServices: PropTypes.array.isRequired,
  setCompanyServices: PropTypes.func.isRequired,
  filteredAppointments: PropTypes.array.isRequired,
  // Delete Company Service
  deleteCompanyService: PropTypes.func.isRequired,
  isDeleteCompanyServiceSuccess: PropTypes.bool.isRequired,
  isDeleteCompanyServiceError: PropTypes.bool.isRequired,
  deleteCompanyServiceErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // Delete Company Service
  isDeleteCompanyServiceSuccess: state.service.isDeleteCompanyServiceSuccess,
  isDeleteCompanyServiceError: state.service.isDeleteCompanyServiceError,
  deleteCompanyServiceErrorMessage: state.service.deleteCompanyServiceErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    deleteCompanyService: (data) => dispatch(deleteCompanyServiceRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteServiceModal);
