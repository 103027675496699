import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import i18n from 'i18next';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import {
  storeIndividualGalleryCompanyRequest,
  deleteIndividualGalleryCompanyRequest,
  getIndividualUserIndustryByIdRequest,
} from '../../../../../../../redux/individualUser/actions';
import GalleryLoading from '../../../../../../../Components/Loading/galleryLoading';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';

function Gallery(props) {
  const {
    match,
    IndividualUserIndustryGot,
    getIndividualUserIndustryById,
    storeIndividualGalleryCompany,
    storedIndividualGalleryCompany,
    deleteIndividualGalleryCompany,
    isStoreIndividualGalleryCompanyError,
    isGetIndividualUserIndustryByIdSuccess,
    isStoreIndividualGalleryCompanySuccess,
    isDeleteIndividualGalleryCompanySuccess,
  } = props;

  const [images, setImages] = useState([]);
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const prevIsGetIndividualUserIndustryByIdSuccess = usePrevious(isGetIndividualUserIndustryByIdSuccess);
  const prevIsStoreIndividualGalleryCompanySuccess = usePrevious(isStoreIndividualGalleryCompanySuccess);
  const prevIsStoreIndividualGalleryCompanyError = usePrevious(isStoreIndividualGalleryCompanyError);
  const prevIsDeleteIndividualGalleryCompanySuccess = usePrevious(isDeleteIndividualGalleryCompanySuccess);

  useEffect(() => {
    const { id } = match.params;
    getIndividualUserIndustryById(id);
  }, []);

  // Handle Get Industry By Id Success
  useEffect(() => {
    if (prevIsGetIndividualUserIndustryByIdSuccess === false && isGetIndividualUserIndustryByIdSuccess) {
      setImages(IndividualUserIndustryGot.images);
    }
  }, [isGetIndividualUserIndustryByIdSuccess]);

  // Perform when gallery images is added
  useEffect(() => {
    if (prevIsStoreIndividualGalleryCompanySuccess === false && isStoreIndividualGalleryCompanySuccess) {
      const allImages = images.concat(storedIndividualGalleryCompany);
      setImages(allImages);
      snackBarAlert(true, storedIndividualGalleryCompany.length > 1 ? 'Images are added' : 'Image successfully uploaded', 'success');
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [isStoreIndividualGalleryCompanySuccess]);

  // Perform when images is not added
  useEffect(() => {
    if (prevIsStoreIndividualGalleryCompanyError === false && isStoreIndividualGalleryCompanyError) {
      snackBarAlert(true, 'Image size is too large', 'error');
    }
  }, [isStoreIndividualGalleryCompanyError]);

  // Perform when images is deleted
  useEffect(() => {
    if (prevIsDeleteIndividualGalleryCompanySuccess === false && isDeleteIndividualGalleryCompanySuccess) {
      snackBarAlert(true, i18n.t('ImageDeleted'), 'success');
    }
  }, [isDeleteIndividualGalleryCompanySuccess]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const removeCompanyImage = (e, i) => {
    const filteredImages = images.filter((file) => file.id !== i);
    const removedImage = images.find((file) => file.id === i);
    setImages(filteredImages);
    setCompany({
      ...company,
      images: filteredImages,
    });
    deleteIndividualGalleryCompany(removedImage);
  };

  const { getRootProps: getRootPropsCompany, getInputProps: getInputPropsCompany } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      const imagesCopy = (acceptedFiles.map((file) => Object.assign(file, {
        image: URL.createObjectURL(file),
      })));
      const filteredImage = imagesCopy.filter((img) => !img.id);
      if (filteredImage.length) {
        storeIndividualGalleryCompany({
          images: filteredImage,
          individual_user_industry_id: IndividualUserIndustryGot.id,
        });
      }
    },
  });

  const thumbsCompany = images.map((file, index) => (
    <div className="thumbGallery" key={`${index}-${file.size}`}>
      <div className="thumbInner">
        <img
          name={file.size}
          src={file.image || file.path}
          className="img"
          alt=""
        />
        <div className="remove-industry-avatar">
          <DeleteIcon className="remove-icon" onClick={(e) => removeCompanyImage(e, file.image || file.id)} />
        </div>
      </div>
    </div>
  ));

  return (
    <>
      {!loading ? (
        <>
          <Grid className="company-gallery">
            <Grid item sm={12}>
              <Box className="add-gallery-content" id="dropzone">
                <section className="gallery">
                  <div className="aside">
                    <div {...getRootPropsCompany({ className: 'dropzone' })}>
                      <input {...getInputPropsCompany()} />
                    </div>
                    {thumbsCompany}
                  </div>
                </section>
              </Box>
            </Grid>
          </Grid>
          <SnackbarToast
            message={snackbarMessage}
            type={snackbarType}
            open={openSnackbar}
            onClose={() => setOpenSnackbar(false)}
          />
        </>
      ) : <GalleryLoading />}
    </>
  );
}

Gallery.propTypes = {
  match: PropTypes.object.isRequired,
  // Get Individual User Industry By Id Props
  getIndividualUserIndustryById: PropTypes.func.isRequired,
  isGetIndividualUserIndustryByIdSuccess: PropTypes.bool.isRequired,
  isGetIndividualUserIndustryByIdError: PropTypes.bool.isRequired,
  IndividualUserIndustryGot: PropTypes.object.isRequired,
  getIndividualUserIndustryErrorMessage: PropTypes.string.isRequired,
  // Gallery props
  storeIndividualGalleryCompany: PropTypes.func.isRequired,
  storedIndividualGalleryCompany: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isStoreIndividualGalleryCompanySuccess: PropTypes.bool.isRequired,
  isStoreIndividualGalleryCompanyError: PropTypes.bool.isRequired,
  // Delete Gallery
  deleteIndividualGalleryCompany: PropTypes.func.isRequired,
  isDeleteIndividualGalleryCompanySuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  // Get Individual User Industry By Id
  isGetIndividualUserIndustryByIdSuccess: state.individualUser.isGetIndividualUserIndustryByIdSuccess,
  isGetIndividualUserIndustryByIdError: state.individualUser.isGetIndividualUserIndustryByIdError,
  IndividualUserIndustryGot: state.individualUser.IndividualUserIndustryGot,
  getIndividualUserIndustryErrorMessage: state.individualUser.getIndividualUserIndustryErrorMessage,
  // Get Gallery
  storedIndividualGalleryCompany: state.individualUser.storedIndividualGalleryCompany,
  isStoreIndividualGalleryCompanySuccess: state.individualUser.isStoreIndividualGalleryCompanySuccess,
  isStoreIndividualGalleryCompanyError: state.individualUser.isStoreIndividualGalleryCompanyError,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndividualUserIndustryById: (data) => dispatch(getIndividualUserIndustryByIdRequest(data)),
    storeIndividualGalleryCompany: (data) => dispatch(storeIndividualGalleryCompanyRequest(data)),
    deleteIndividualGalleryCompany: (data) => dispatch(deleteIndividualGalleryCompanyRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
