import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFilter,
  faSortAmountUp,
} from '@fortawesome/free-solid-svg-icons';
import { Box, TextField, Typography } from '@material-ui/core';

function topBarContent() {
  return (
    <>
      <div className="page-top-bar">
        <div className="left-content">
          <Box>
            <Typography variant="h3">
              Notifications
            </Typography>
          </Box>
        </div>
        <div className="right-content">
          <div>
            <TextField
              fullWidth
              label="Search..."
              type="search"
              size="small"
              color="secondary"
            />
            <span className="filter-icon">
              <span>
                <FontAwesomeIcon icon={faSortAmountUp} />
              </span>
              |
              <span>
                <FontAwesomeIcon icon={faFilter} />
              </span>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default topBarContent;
