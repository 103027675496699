import PropTypes from 'prop-types';
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { TableCell, TableRow } from '@material-ui/core';

function TableLoading({ count }) {
  const tableRows = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < count; i++) {
    tableRows.push(
      <TableRow
        hover
        role="checkbox"
        tabIndex={-1}
        className="row"
        key={`row-${i + 1}`}
      >
        <TableCell padding="checkbox" className="row-item selectCheckbox">
          <Skeleton sx={{ my: 5, mx: 1 }} height={30} key={i} />
        </TableCell>
        <TableCell component="th" padding="none" className="row-item">
          <Skeleton sx={{ my: 4, mx: 1 }} height={30} key={i} />
        </TableCell>
        <TableCell align="left" className="row-item email">
          <Skeleton sx={{ my: 4, mx: 1 }} height={30} key={i} />
        </TableCell>
        <TableCell align="left" className="row-item">
          <Skeleton sx={{ my: 4, mx: 1 }} height={30} key={i} />
        </TableCell>
        <TableCell align="center" className="row-item" style={{ paddingRight: '34px' }}>
          <Skeleton sx={{ my: 4, mx: 1 }} height={30} key={i} />
        </TableCell>
        <TableCell align="left" className="row-item">
          <Skeleton sx={{ my: 4, mx: 1 }} height={30} key={i} />
        </TableCell>
        <TableCell align="left" className="row-item">
          <Skeleton sx={{ my: 4, mx: 1 }} height={30} key={i} />
        </TableCell>
        <TableCell align="left" className="row-item">
          <Skeleton sx={{ my: 4, mx: 1 }} height={30} key={i} />
        </TableCell>
        <TableCell align="center" className="row-item action">
          <Skeleton sx={{ my: 4, mx: 1 }} height={30} key={i} />
        </TableCell>
      </TableRow>,
    );
  }
  return <>{tableRows}</>;
}

export default TableLoading;

TableLoading.propTypes = {
  count: PropTypes.number.isRequired,
};
