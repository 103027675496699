import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { acceptIndividualCustomerInvitationRequest } from '../../../../redux/customer/actions';
import Loading from '../../../../Components/Loading/loading';
import usePrevious from '../../../../CustomHooks/usePrevious';
import SetNewPassword from '../../Onboarding/SetNewPassword';
import SnackbarToast from '../../../../Modules/SnackbarToast';

function AcceptIndividualCustomerInvitation(props) {
  const {
    history,
    match,
    handleLogin,
    isJustInvitationAccepted,
    acceptInvitation,
    isAcceptIndividualCustomerInvitationSuccess,
    isAcceptIndividualCustomerInvitationError,
    acceptIndividualCustomerInvitationErrorMessage,
    invitedIndividualUser,
  } = props;

  const { t } = useTranslation();

  const prevIsAcceptIndividualCustomerInvitationSuccess = usePrevious(isAcceptIndividualCustomerInvitationSuccess);
  const prevIsAcceptIndividualCustomerInvitationError = usePrevious(isAcceptIndividualCustomerInvitationError);

  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Perform, when function gets mounted
  useEffect(() => {
    if (!isJustInvitationAccepted) {
      if (localStorage.getItem('token')) {
        history.push('/calendar');
      } else {
        const { token } = match.params;
        if (token) {
          acceptInvitation({ token });
        } else {
          snackBarAlert(true, 'Token is not identified', 'error');
          history.push('/login');
        }
      }
    } else {
      setLoading(false);
    }
  }, []);
  // Perform when accept invitation success
  useEffect(() => {
    if (prevIsAcceptIndividualCustomerInvitationSuccess === false && isAcceptIndividualCustomerInvitationSuccess) {
      snackBarAlert(true, t('InvitationAccepted'), 'success');
      if (invitedIndividualUser && invitedIndividualUser.token) {
        handleLogin(invitedIndividualUser.token);
      } else {
        history.push('/calendar');
      }
    }
  }, [isAcceptIndividualCustomerInvitationSuccess]);
  // Perform when accept invitation error
  useEffect(() => {
    if (prevIsAcceptIndividualCustomerInvitationError === false && isAcceptIndividualCustomerInvitationError) {
      snackBarAlert(true, acceptIndividualCustomerInvitationErrorMessage, 'error');
      history.push('/login');
    }
  }, [isAcceptIndividualCustomerInvitationError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  return loading ? <Loading /> : (
    <>
      <SetNewPassword user={invitedIndividualUser} />
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

AcceptIndividualCustomerInvitation.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  handleLogin: PropTypes.func.isRequired,
  isJustInvitationAccepted: PropTypes.bool.isRequired,
  // Accept invitation
  acceptInvitation: PropTypes.func.isRequired,
  isAcceptIndividualCustomerInvitationSuccess: PropTypes.bool.isRequired,
  isAcceptIndividualCustomerInvitationError: PropTypes.bool.isRequired,
  acceptIndividualCustomerInvitationErrorMessage: PropTypes.string.isRequired,
  invitedIndividualUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  ...state,
  isAcceptIndividualCustomerInvitationSuccess: state.customers.isAcceptIndividualCustomerInvitationSuccess,
  isAcceptIndividualCustomerInvitationError: state.customers.isAcceptIndividualCustomerInvitationError,
  acceptIndividualCustomerInvitationErrorMessage: state.customers.acceptIndividualCustomerInvitationErrorMessage,
  invitedIndividualUser: state.customers.invitedIndividualUser,
});

function mapDispatchToProps(dispatch) {
  return {
    acceptInvitation: (data) => dispatch(acceptIndividualCustomerInvitationRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(AcceptIndividualCustomerInvitation),
);
