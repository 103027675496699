import React, { useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, Drawer, FormHelperText, InputAdornment, Button, Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './style.scss';
import { useTranslation } from 'react-i18next';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { uploadImageToAmazonRequest } from 'redux/company/actions';
import { phoneNumberRegex } from 'Modules/regexValidations';
import usePrevious from 'CustomHooks/usePrevious';
import trimObject from 'Modules/objectTrim';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { getProfessionsRequest } from 'redux/country/actions';
import { getAvatarPreSignUrlRequest } from 'redux/account/actions';
import { updateStaffRequest } from 'redux/staff/actions';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import DefaultProfileImage from 'assets/img/default-profile.png';
import getInitials from 'Modules/getInitials';
import StaffContext from '../staffContext';
import LeftSide from './leftSide';
import AppointmentBox from './appointmentBox';
import { addProfessionRequest } from '../../../../../../redux/staff/actions';

function StaffDrawer2(props) {
  const { selectedStaff } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    openEditDrawer,
    setOpenEditDrawer,
    isUpdate,
    setIsUpdate,
    selectedIndex,
    setSelectedIndex,
    deleteStaff,
    professionOptions,
    setProfessionOptions,
    setSnackbarData,
  } = useContext(StaffContext);

  const {
    updateCompanyStaffErrors,
    isUpdateStaffError,
    isUpdateStaffSuccess,
    updatedStaff,
    staffUpcomingAppointments,
    staffUpcomingAppointmentsCount,
    isGetStaffUpcomingAppointmentsSuccess,
    isGetStaffUpcomingAppointmentsError,
    getStaffUpcomingAppointmentsErrorMessage,
    staffAppointmentsHistory,
    staffAppointmentsHistoryCount,
    isGetStaffAppointmentsHistorySuccess,
    isGetStaffAppointmentsHistoryError,
    getStaffAppointmentsHistoryErrorMessage,
    isAddProfessionSuccess,
    isAddProfessionError,
  } = useSelector((state) => state.staff);

  const { isGetAvatarPreSignedUrlSuccess, avatarPreSignUrl, userAccount } = useSelector((state) => state.account);

  const { professions, isGetProfessionsSuccess } = useSelector((state) => state.country);

  const prevIsUpdateStaffError = usePrevious(isUpdateStaffError);
  const prevIsGetProfessionsSuccess = usePrevious(isGetProfessionsSuccess);
  const prevIsAddProfessionSuccess = usePrevious(isAddProfessionSuccess);
  const prevIsGetAvatarPreSignedUrlSuccess = usePrevious(isGetAvatarPreSignedUrlSuccess);
  const prevIsUpdateStaffSuccess = usePrevious(isUpdateStaffSuccess);
  const prevIsGetStaffUpcomingAppointmentsSuccess = usePrevious(isGetStaffUpcomingAppointmentsSuccess);
  const prevIsGetStaffUpcomingAppointmentsError = usePrevious(isGetStaffUpcomingAppointmentsError);
  const prevIsGetStaffAppointmentsHistorySuccess = usePrevious(isGetStaffAppointmentsHistorySuccess);
  const prevIsGetStaffAppointmentsHistoryError = usePrevious(isGetStaffAppointmentsHistoryError);

  const staff = {
    is_web: true,
    first_name: '',
    last_name: '',
    profession_id: null,
    profession_name: '',
    phone_number: '',
    position_id: 2,
    aws_avatar: null,
  };
  const defaultStaffErrors = {
    name: { first_name: false, last_name: false },
    // profession: { isRequired: false },
    phone_number: { isRequired: false, isValid: false },
  };

  const [data, setData] = useState(staff);
  const [errors, setErrors] = useState(defaultStaffErrors);
  const [actionError, setActionErrors] = useState({});
  const [selectedProfession, setSelectedProfession] = useState({});
  const [files, setFiles] = useState(null);
  const [avatarName, setAvatarName] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [isSelectedPhoneNumber, setIsSelectedPhoneNumber] = useState(false);
  const [upcomingAppointments, setUpcomingAppointments] = useState({});
  const [appointmentsHistory, setAppointmentsHistory] = useState({});
  const [upcomingAppointmentsCount, setUpcomingAppointmentsCount] = useState(0);
  const [appointmentsHistoryCount, setAppointmentsHistoryCount] = useState(0);
  const [loading, setLoading] = useState(true);

  const addProfessionLoading = useMemo(() => !isAddProfessionError && !isAddProfessionSuccess, [
    isAddProfessionSuccess,
    isAddProfessionError,
  ]);

  useEffect(() => {
    if (!professions.length) {
      dispatch(getProfessionsRequest());
    } else {
      getProfessions();
    }
  }, [userAccount]);

  useEffect(() => {
    if (openEditDrawer) {
      if (Object.keys(selectedStaff).length === 0) {
        setData(staff);
        setFiles(null);
        setAvatar(null);
        setSelectedProfession({});
      }
      setErrors(defaultStaffErrors);
      setActionErrors({});

      if (Object.keys(selectedStaff).length > 0) {
        setData({
          is_web: true,
          id: selectedStaff.id,
          first_name: selectedStaff.first_name,
          last_name: selectedStaff.last_name,
          profession_id: selectedStaff.staff_company_professions[0]?.id || null,
          phone_number: selectedStaff.phone_number ? selectedStaff.phone_number : '',
          position_id: 2,
          profession_name: selectedStaff.profession_name,
          avatar: selectedStaff.avatar,
        });
        const foundedProfession = professionOptions.find(
          (item) => item.id === selectedStaff.staff_company_professions[0]?.id
        );
        setSelectedProfession(foundedProfession);
        setFiles(null);
        setAvatar(selectedStaff.avatar);
      }
    }
  }, [openEditDrawer]);

  useEffect(() => {
    if (prevIsUpdateStaffError === false && isUpdateStaffError) {
      setActionErrors(updateCompanyStaffErrors);
    }
  }, [isUpdateStaffError]);

  useEffect(() => {
    if (prevIsGetProfessionsSuccess === false && isGetProfessionsSuccess) {
      getProfessions();
    }
  }, [isGetProfessionsSuccess]);

  useEffect(() => {
    if (prevIsAddProfessionSuccess === false && isAddProfessionSuccess) {
      setData((prev) => ({ ...prev, profession_id: professions[0].id }));
    }
  }, [isAddProfessionSuccess]);

  useEffect(() => {
    if (prevIsUpdateStaffSuccess === false && isUpdateStaffSuccess) {
      addCustomProfession(updatedStaff);
      if (files) {
        dispatch(getAvatarPreSignUrlRequest({ logo: avatarName }));
      }
    }
  }, [isUpdateStaffSuccess]);

  useEffect(() => {
    if (prevIsGetAvatarPreSignedUrlSuccess === false && isGetAvatarPreSignedUrlSuccess && isUpdate) {
      const uploadImageOption = {
        preSignUrl: avatarPreSignUrl,
        logo: avatar,
      };
      dispatch(uploadImageToAmazonRequest(uploadImageOption));
    }
  }, [isGetAvatarPreSignedUrlSuccess]);

  useEffect(() => {
    if (prevIsGetStaffUpcomingAppointmentsSuccess === false && isGetStaffUpcomingAppointmentsSuccess) {
      // const sortedAppointments = sortByDate(staffUpcomingAppointments);
      setUpcomingAppointmentsCount(staffUpcomingAppointmentsCount);
      setUpcomingAppointments(staffUpcomingAppointments);
      setLoading(false);
    } else if (prevIsGetStaffUpcomingAppointmentsError === false && isGetStaffUpcomingAppointmentsError) {
      setLoading(false);
      setSnackbarData({
        message: getStaffUpcomingAppointmentsErrorMessage,
        type: 'error',
        open: true,
      });
    }
  }, [isGetStaffUpcomingAppointmentsSuccess, isGetStaffUpcomingAppointmentsError]);

  useEffect(() => {
    if (prevIsGetStaffAppointmentsHistorySuccess === false && isGetStaffAppointmentsHistorySuccess) {
      // const sortedAppointments = sortByDate(staffAppointmentsHistory);

      setAppointmentsHistoryCount(staffAppointmentsHistoryCount);
      setAppointmentsHistory(staffAppointmentsHistory);
      setLoading(false);
    } else if (prevIsGetStaffAppointmentsHistoryError === false && isGetStaffAppointmentsHistoryError) {
      setLoading(false);
      setSnackbarData({
        message: getStaffAppointmentsHistoryErrorMessage,
        type: 'error',
        open: true,
      });
    }
  }, [isGetStaffAppointmentsHistorySuccess, isGetStaffAppointmentsHistoryError]);

  const getProfessions = () => {
    const professionOptions = [];
    professions.map((prof) => {
      professionOptions.push({
        id: prof.id,
        value: prof.id,
        label: prof.name,
      });
      return true;
    });
    setProfessionOptions(professionOptions);
  };

  const handleChange = (name, value) => {
    setData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const errorsCopy = { ...errors };
    const {
      first_name,
      last_name,
      phone_number,
      // profession_id,
      // profession_name,
    } = data;
    errorsCopy.name.first_name = first_name.length === 0;
    errorsCopy.name.last_name = last_name.length === 0;
    // errorsCopy.profession.isRequired = (profession_id === null && profession_name.length === 0);
    errorsCopy.phone_number.isValid = phone_number && phone_number.length > 21;
    setErrors(errorsCopy);

    return (
      Object.values(errors.phone_number).some((error) => error === true) ||
      Object.values(errors.name).every((error) => error === true)
      // || Object.values(errors.profession).some((error) => error === true)
    );
  };

  const save = () => {
    if (!validateForm()) {
      trimObject(data);
      const dataCopy = { ...data };
      delete dataCopy.avatar;
      if (Object.keys(selectedStaff).length > 0) {
        dispatch(updateStaffRequest(dataCopy));
      }
    }
  };

  const filter = createFilterOptions();

  const handleProfessionSelectChange = (e, profession) => {
    const dataCopy = { ...data };
    if (profession) {
      if (profession && profession.inputValue) {
        dispatch(addProfessionRequest({ name: profession.inputValue }));
      } else {
        dataCopy.profession_id = profession.id;
      }
    } else {
      dataCopy.profession_name = '';
      dataCopy.profession_id = null;
    }

    setSelectedProfession(profession);
    setData(dataCopy);
  };

  const { getRootProps: getRootPropsCompany, getInputProps: getInputPropsCompany } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      setFiles(URL.createObjectURL(acceptedFiles[0]));
      setAvatar(acceptedFiles[0]);
      const name = moment().format('DDMMYYYYHHmmss') + acceptedFiles[0].name;
      handleChange('aws_avatar', name);
      setAvatarName(name);
    },
  });

  const handleDeletePic = (e) => {
    e.stopPropagation();
    setFiles(null);
    setAvatar(null);
    handleChange('aws_avatar', null);
  };

  const addCustomProfession = (staff) => {
    if (data.profession_name.length > 0) {
      const professionOptionsCopy = [...professionOptions];
      professionOptionsCopy.push({
        id: staff.staff_company_professions[0].id,
        value: staff.staff_company_professions[0].id,
        label: staff.staff_company_professions[0].name,
      });
      setProfessionOptions(professionOptionsCopy);
    }
  };

  const handleChangePhoneNumber = (value) => {
    if (value === '' || phoneNumberRegex.test(value)) {
      handleChange('phone_number', value);
    }
  };

  const iconAdornment = isSelectedPhoneNumber
    ? {
        inputMode: 'string',
        pattern: phoneNumberRegex,
        startAdornment: (
          <InputAdornment position="start">
            <PhoneOutlinedIcon fontSize="small" color={errors.phone_number.isValid ? 'error' : 'secondary'} />
          </InputAdornment>
        ),
      }
    : {};

  const renderAvatar = () => {
    const { first_name, last_name } = selectedStaff;

    if (files || avatar) {
      return (
        <Avatar
          alt="Remy Sharp"
          src={files || avatar ? files || (Object.keys(selectedStaff).length > 0 ? avatar : null) : DefaultProfileImage}
          style={{ width: 100, height: 100 }}
        />
      );
    } else {
      return (
        <div className="no-avatar-circle">
          {first_name ? (
            /^[a-zA-Z]*$/.test(first_name[0]) ? (
              getInitials(first_name)
            ) : last_name ? (
              /^[a-zA-Z]*$/.test(last_name[0]) ? (
                getInitials(last_name)
              ) : (
                <img src={DefaultProfileImage} alt="default" width={100} height={100} />
              )
            ) : (
              <img src={DefaultProfileImage} alt="default" width={100} height={100} />
            )
          ) : (
            <img src={DefaultProfileImage} alt="default" width={100} height={100} />
          )}
        </div>
      );
    }
  };

  const onClose = () => {
    setOpenEditDrawer(false);
    setAppointmentsHistory({});
    setUpcomingAppointments({});
    setLoading(true);
  };

  return (
    <Drawer
      anchor="right"
      open={openEditDrawer}
      onClose={onClose}
      className="drawer2"
      // disableBackdropClick
      PaperProps={{
        style: {
          height: 'calc(100% - 64px)',
          top: 64,
          padding: '20px 0 0 0',
        },
      }}
    >
      <div className="title-content">
        <h3 className="title">{t('StaffDetails')}</h3>
        <CloseIcon className="close-icon" onClick={onClose} />
      </div>
      <div className="content">
        <div className="left-content">
          <LeftSide
            deleteStaff={deleteStaff}
            selectedStaff={selectedStaff}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            setLoading={setLoading}
          />
        </div>
        <div className="rightContent">
          {isUpdate && (
            <>
              <div className="drawer-content">
                <p className="editStaffTitle">{t('EditStaff')}</p>
                <div {...getRootPropsCompany({ className: 'dropzone' })}>
                  <div>
                    {renderAvatar()}
                    <input {...getInputPropsCompany()} />
                    <div className="action" onClick={(files || avatar) && handleDeletePic}>
                      {files || avatar ? (
                        <DeleteOutlineIcon width={14} height={14} />
                      ) : (
                        <AddIcon width={14} height={14} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="input-content">
                  <TextField
                    type="text"
                    size="small"
                    color="secondary"
                    className="input"
                    placeholder={t('FirstName')}
                    label={t('FirstName')}
                    variant="outlined"
                    error={errors.name.first_name && errors.name.last_name}
                    value={data.first_name}
                    onChange={(e) => handleChange('first_name', e.target.value)}
                  />
                  {errors.name.first_name && errors.name.last_name && (
                    <FormHelperText error>{t('FirstNameOrLastNameRequired')}</FormHelperText>
                  )}
                </div>
                <div className="input-content">
                  <TextField
                    type="text"
                    size="small"
                    color="secondary"
                    className="input"
                    placeholder={t('LastNameOptional')}
                    label={t('LastNameOptional')}
                    variant="outlined"
                    error={errors.name.first_name && errors.name.last_name}
                    value={data.last_name}
                    onChange={(e) => handleChange('last_name', e.target.value)}
                  />
                </div>
                <div className="input-content">
                  <Autocomplete
                    size="small"
                    fullWidth
                    disabled={addProfessionLoading}
                    value={selectedProfession}
                    onChange={(e, value) => handleProfessionSelectChange(e, value)}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          label: `Add "${params.inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    options={professionOptions}
                    getOptionLabel={(option) => {
                      // e.g value selected with enter, right from the input
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.label || '';
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    renderOption={(option) => option.label}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          style: { height: '100%', maxHeight: 48, padding: '9.5px 4px' },
                          autoComplete: 'profession',
                          // maxLength: 50,
                        }}
                        placeholder={t('ProfessionOptional')}
                        label={t('ProfessionOptional')}
                        variant="outlined"
                        // error={errors.profession.isRequired}
                      />
                    )}
                  />
                  {/*{errors.profession.isRequired ? (*/}
                  {/*  <FormHelperText error>*/}
                  {/*    {t('Profession') + t('IsRequired')}*/}
                  {/*  </FormHelperText>*/}
                  {/*) : null}*/}
                </div>
                <div className="input-content">
                  <TextField
                    size="small"
                    color="secondary"
                    className="input"
                    placeholder={t('PhoneNumberOptional')}
                    label={t('PhoneNumber')}
                    variant="outlined"
                    error={errors.phone_number.isValid || actionError.phone_number}
                    value={data.phone_number}
                    onChange={(e) => handleChangePhoneNumber(e.target.value)}
                    InputProps={iconAdornment}
                    onFocus={() => setIsSelectedPhoneNumber(true)}
                    onBlur={() => setIsSelectedPhoneNumber(false)}
                  />
                  {errors.phone_number.isValid ? (
                    <FormHelperText error>{t('lowercasePhoneNumber') + t('IsNotValid')}</FormHelperText>
                  ) : actionError.phone_number ? (
                    <FormHelperText error>{actionError.phone_number}</FormHelperText>
                  ) : null}
                </div>
              </div>
              <div className="save-btn">
                <div>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: 4,
                      border: '1px solid #A4A4A4',
                      backgroundColor: '#FFFFFF',
                      font: 'normal 400 14px/19px Nunito Sans, sans-serif',
                      color: '#A4A4A4',
                      width: 100,
                      height: '36px',
                    }}
                    onClick={onClose}
                  >
                    {t('Cancel')}
                  </Button>
                </div>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={save}
                    disabled={addProfessionLoading}
                    style={{ width: '100px', height: '36px' }}
                  >
                    {t('Save')}
                  </Button>
                </div>
              </div>
            </>
          )}
          {(selectedIndex === 0 || selectedIndex === 1) && !isUpdate && (
            <AppointmentBox
              id={selectedStaff.id}
              selectedIndex={selectedIndex}
              title={t(selectedIndex === 0 ? 'UpcomingAppointments' : 'AppointmentsHistory')}
              loading={loading}
              setLoading={setLoading}
              data={selectedIndex === 0 ? upcomingAppointments : appointmentsHistory}
              count={selectedIndex === 0 ? upcomingAppointmentsCount : appointmentsHistoryCount}
            />
          )}
        </div>
      </div>
    </Drawer>
  );
}

export default StaffDrawer2;

StaffDrawer2.propTypes = {
  selectedStaff: PropTypes.object,
};

StaffDrawer2.defaultProps = {
  selectedStaff: {},
};
