import withStyles from '@material-ui/core/styles/withStyles';
import { Button } from '@material-ui/core';

const deleteButton = withStyles(() => ({
  root: {
    backgroundColor: 'transparent',
    borderWidth: 1,
    borderColor: '#F12B2C',
    color: '#F12B2C',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
}))(Button);

export default deleteButton;
