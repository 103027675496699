import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  TextField,
  Select,
  InputLabel,
  Paper,
  TableCell,
  Typography,
  Box,
  MenuItem,
  FormControl,
  IconButton,
  FormHelperText,
  TableContainer,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';
import Loading from '../../../../Components/Loading/loading';
import {
  getIndustryServicesRequest,
} from '../../../../redux/service/actions';
import usePrevious from '../../../../CustomHooks/usePrevious';
import SnackbarToast from '../../../../Modules/SnackbarToast';

function Services(props) {
  const {
    individualUserIndustry,
    getIndustryServices,
    isGetIndustryServicesSuccess,
    isGetIndustryServicesError,
    services,
    setSelectedServices,
  } = props;

  const prevIsGetIndustryServicesSuccess = usePrevious(isGetIndustryServicesSuccess);
  const prevIsGetIndustryServicesError = usePrevious(isGetIndustryServicesError);

  const [serviceItem, setServiceItem] = useState({
    duration: '',
    price: '',
    service_id: '',
    service_name: '',
  });
  const [errors, setErrors] = useState({
    price: false,
    duration: false,
  });
  const [allServices, setAllServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [selectedServiceOption, setSelectedServiceOption] = useState({ value: null });
  const [previousIndustryId, setPreviousIndustryId] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (individualUserIndustry.industry_id !== previousIndustryId) {
      getIndustryServices({
        industryId: individualUserIndustry.industry_id,
      });
      setSelectedServiceOption('');
      setPreviousIndustryId(individualUserIndustry.industry_id);
    }
  }, []);

  useEffect(() => {
    if (prevIsGetIndustryServicesSuccess === false && isGetIndustryServicesSuccess) {
      const serviceOptions = services.map((service) => ({
        label: service.name,
        value: service.id,
      }));
      setServiceOptions(serviceOptions);
      setLoading(false);
    }
  }, [isGetIndustryServicesSuccess]);

  useEffect(() => {
    if (prevIsGetIndustryServicesError === false && isGetIndustryServicesError) {
      setLoading(false);
      setOpenSnackbar(true);
      setSnackbarMessage('Something went wrong, please refresh the page');
      setSnackbarType('error');
    }
  }, [isGetIndustryServicesError]);

  useEffect(() => {
    serviceOptions.sort(compareServices);
  }, [serviceOptions]);

  const handleServiceOptionChange = (event) => {
    const { value } = event.target;
    const findSelectedService = serviceOptions.find((item) => item.value === value);
    if (findSelectedService) {
      setSelectedServiceOption(findSelectedService);
      setServiceItem((prevServiceItem) => ({
        ...prevServiceItem,
        service_id: findSelectedService.value,
        service_name: findSelectedService.label,
      }));
      if (errors.service_id) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          service_id: false,
        }));
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const serviceItemCopy = { ...serviceItem };
    serviceItemCopy[name] = value;
    setServiceItem((prevServiceItem) => ({
      ...prevServiceItem,
      ...serviceItemCopy,
    }));
    validateForm(serviceItemCopy, name);
  };

  const handleAddOrUpdateService = () => {
    const formErrors = validateForm(serviceItem, null, true);
    const keys = Object.keys(formErrors);
    const errorExists = keys.find((key) => formErrors[key]);
    if (!errorExists) {
      addService();
      const filteredServiceOptions = serviceOptions.filter((option) => option.value !== serviceItem.service_id);
      setServiceOptions(filteredServiceOptions);
    }
  };

  const addService = () => {
    setAllServices((prevAllServices) => ([
      ...prevAllServices,
      serviceItem,
    ]));
    const allServicesCopy = [...allServices];
    allServicesCopy.push(serviceItem);
    setSelectedServices(allServicesCopy);
    setServiceItem({
      duration: '',
      price: '',
      service_id: '',
      service_name: '',
    });
    setSelectedServiceOption({
      value: null,
    });
  };

  const validateForm = (service, name, all = false) => {
    const errorsCopy = { ...errors };
    if (all) {
      const keys = Object.keys(service);
      keys.map((key) => {
        errorsCopy[key] = !(service[key] && service[key].toString().length);
      });
    } else {
      errorsCopy[name] = !(service[name] && service[name].toString().length);
    }
    setErrors(errorsCopy);
    return errorsCopy;
  };

  const handleDeleteService = (service, key) => {
    const allServicesCopy = [...allServices];
    if (allServicesCopy.length > 0) {
      allServicesCopy.splice(key, 1);
      setSelectedServices(allServicesCopy);
      setAllServices(allServicesCopy);
      const serviceOptionsCopy = [...serviceOptions];
      serviceOptionsCopy.push({
        value: service.service_id,
        label: service.service_name,
      });
      setServiceOptions(serviceOptionsCopy);
    }
  };

  const compareServices = (a, b) => {
    // Use toUpperCase() to ignore character casing
    const nameA = a.label.toUpperCase();
    const nameB = b.label.toUpperCase();

    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  };

  return !loading ? (
    <div>
      <Box align="center" mb="24px">
        <Typography>
          Don&apos;t worry - you&apos;ll be able to edit these later
        </Typography>
      </Box>
      <Box mb="10px">
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={!!errors.service_id}
            >
              <InputLabel>Service *</InputLabel>
              <Select
                value={selectedServiceOption.value ? selectedServiceOption.value : ''}
                onChange={(event) => handleServiceOptionChange(event)}
                label="Service *"
                id="service"
              >
                { serviceOptions.length > 0 && serviceOptions.map((item) => (
                  <MenuItem
                    key={Math.random()}
                    value={item.value}
                  >
                    { item.label }
                  </MenuItem>
                )) }
              </Select>

              { errors.service_id && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    Service is required.
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={!!errors.duration}
            >
              <TextField
                fullWidth
                error={!!errors.duration}
                size="small"
                value={serviceItem.duration}
                onChange={(event) => handleInputChange(event)}
                type="number"
                variant="outlined"
                name="duration"
                label="Duration(min) *"
              />

              { errors.duration && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    Duration is required.
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl
              fullWidth
              size="small"
              variant="outlined"
              error={!!errors.price}
            >
              <TextField
                error={!!errors.price}
                fullWidth
                size="small"
                value={serviceItem.price}
                onChange={(event) => handleInputChange(event)}
                type="number"
                variant="outlined"
                name="price"
                label="Price($) *"
              />

              { errors.price && (
                <div className="error-message-content">
                  <ErrorIcon fontSize="small" color="error" />
                  <FormHelperText>
                    Price is required.
                  </FormHelperText>
                </div>
              )}
            </FormControl>
          </Grid>
          <Grid container xs={1} alignItems="center">
            <IconButton
              color="primary"
              onClick={handleAddOrUpdateService}
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
        { allServices && allServices.length
          ? (
            <Box mt="10px">
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Services</TableCell>
                      <TableCell align="left">Duration</TableCell>
                      <TableCell align="left">Price ($)</TableCell>
                      <TableCell align="left">Delete</TableCell>
                      <TableCell align="left" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { allServices.map((service, key) => (
                      <TableRow key={service.service_id}>
                        <TableCell component="th" scope="row">
                          {service.service_name}
                        </TableCell>
                        <TableCell align="left">
                          { service.duration }
                        </TableCell>
                        <TableCell align="left">
                          { service.price }
                        </TableCell>
                        <TableCell align="left">
                          <IconButton size="small" onClick={() => handleDeleteService(service, key)}>
                            <DeleteOutlineIcon fontSize="small" color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )
          : null }
      </Box>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </div>
  ) : <Loading />;
}

Services.propTypes = {
  individualUserIndustry: PropTypes.object.isRequired,
  getIndustryServices: PropTypes.func.isRequired,
  isGetIndustryServicesSuccess: PropTypes.bool.isRequired,
  isGetIndustryServicesError: PropTypes.bool.isRequired,
  services: PropTypes.array.isRequired,
  setSelectedServices: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  // Get industry Services
  isGetIndustryServicesSuccess: state.service.isGetIndustryServicesSuccess,
  isGetIndustryServicesError: state.service.isGetIndustryServicesError,
  services: state.service.services,
});

function mapDispatchToProps(dispatch) {
  return {
    getIndustryServices: (data) => dispatch(getIndustryServicesRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
