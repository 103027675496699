import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const ExistingModal = (props) => {
  const {
    open,
    onClose,
    existingUser,
    handleConfirmExistingUser,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>Existing User</span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>Please make sure this is the staff you want to invite:</Typography>
        <Typography gutterBottom>
          Name:
          <Link
            to="#"
            onClick={(e) => e.preventDefault()}
          >
            {existingUser.first_name}
            {' '}
            {existingUser.last_name}
          </Link>
        </Typography>
        <Typography gutterBottom>
          Email:
          <Link
            to="#"
            onClick={(e) => e.preventDefault()}
          >
            {existingUser.email}
          </Link>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={handleConfirmExistingUser}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExistingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  existingUser: PropTypes.string.isRequired,
  handleConfirmExistingUser: PropTypes.func.isRequired,
};

export default ExistingModal;
