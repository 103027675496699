import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import '../../index.scss';
import {
  InputLabel,
  TextField,
  TextareaAutosize,
  Select as MaterialSelect,
  FormControl,
  Grid,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from 'react-i18next';
import usePrevious from '../../../../../../../CustomHooks/usePrevious';
import { updateAppointmentRequest } from '../../../../../../../redux/appointment/actions';
import capitalize from '../../../../../../../Modules/capitalize';
import InfoLoading from '../../../../../../../Components/Loading/infoLoading';
import calendar from '../../../../../../../assets/icons/calendar.svg';
import clock from '../../../../../../../assets/icons/clock.svg';
import customerAndStaffDefaultLogo from '../../../../../../../assets/img/avatar.svg';
import SnackbarToast from '../../../../../../../Modules/SnackbarToast';

function Info(props) {
  const { t } = useTranslation();
  const {
    appointment,
    staffsCompanyByService,
    isAppointmentCancelled,
    allStatuses,
    updateAppointment,
    isUpdateAppointmentSuccess,
    isUpdateAppointmentError,
    updatedAppointment,
    updateAppointmentErrors,
    isAppointmentUpdate,
    setIsAppointmentUpdate,
    setIsButtonDisabled,
  } = props;

  // Get some props previous values
  const prevIsUpdateAppointmentSuccess = usePrevious(isUpdateAppointmentSuccess);
  const prevIsUpdateAppointmentError = usePrevious(isUpdateAppointmentError);

  const [loading, setLoading] = useState(true);
  const [appointmentItem, setAppointmentItem] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedStatusOption, setSelectedStatusOption] = useState({});
  const [selectedStaffOption, setSelectedStaffOption] = useState('');
  const [errors, setErrors] = useState({});
  const [staffId, setStaffId] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (isAppointmentUpdate) {
      handleSaveAppointment();
      setIsAppointmentUpdate(false);
    }
  }, [isAppointmentUpdate]);

  // Perform once, when component gets mounted
  useEffect(() => {
    if (appointment && appointment.id) {
      const time = moment(appointment.start_time, 'HH:mm:ss');
      setAppointmentItem({
        ...appointment,
        day: new Date(appointment.date),
        time,
      });
      setStaffId(appointment.staff.id);
      setSelectedStaffOption({
        id: appointment.staff.id,
        value: appointment.staff.full_name,
        label: appointment.staff.full_name,
        logo: appointment.staff.logo || customerAndStaffDefaultLogo,
      });
      const selectedOption = getSelectedStatusOption(statusOptions, appointment.status_id);
      setSelectedStatusOption(selectedOption);
      setLoading(false);
    }
  }, [appointment]);
  // Handle all statuses change
  useEffect(() => {
    if (allStatuses.length) {
      const options = allStatuses.map((item) => ({
        label: capitalize(item.name),
        value: item.id,
      }));
      setStatusOptions(options);
      if (appointment && appointment.status_id) {
        const selectedOption = getSelectedStatusOption(options, appointment.status_id);
        setSelectedStatusOption(selectedOption);
        setLoading(false);
      }
    }
  }, [allStatuses]);
  // Perform, when update appointment success
  useEffect(() => {
    if (prevIsUpdateAppointmentSuccess === false && isUpdateAppointmentSuccess) {
      const time = moment(updatedAppointment.start_time, 'HH:mm:ss');
      setAppointmentItem({
        ...updatedAppointment,
        day: new Date(updatedAppointment.date),
        time,
      });
      const option = statusOptions.find((item) => item.value === parseInt(updatedAppointment.status_id));
      setSelectedStatusOption(option);
      setLoading(false);
      setErrors({});
      setOpenSnackbar(true);
      setSnackbarMessage('Appointment updated successfully');
      setSnackbarType('success');
    }
  }, [isUpdateAppointmentSuccess]);
  // Perform, when update appointment error
  useEffect(() => {
    if (prevIsUpdateAppointmentError === false && isUpdateAppointmentError) {
      setLoading(false);
      setIsButtonDisabled(false);
      setErrors(updateAppointmentErrors);
    }
  }, [isUpdateAppointmentError]);

  const getSelectedStatusOption = (options, statusId) => options.find((item) => item.value === parseInt(statusId));

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAppointmentItem({
      ...appointmentItem,
      [name]: value,
    });
  };

  const handleSaveAppointment = () => {
    setLoading(true);
    let oldStaffId = null;
    if (staffId !== appointmentItem.staff_id) {
      oldStaffId = staffId;
    }
    updateAppointment({
      ...appointmentItem,
      oldStaffId,
    });
  };

  const updateAppointmentItem = (name, value) => {
    let updateValue = value;
    if (name === 'start_time') {
      updateValue = `${updateValue}:00`;
    }
    setAppointmentItem({
      ...appointmentItem,
      [name]: updateValue,
    });
  };

  const chooseStaff = (event, selectedStaff) => {
    if (selectedStaff) {
      setSelectedStaffOption(selectedStaff);
      updateAppointmentItem('staff_id', selectedStaff.id);
    } else {
      setSelectedStaffOption('');
    }
  };

  const renderDatetime = (label, value, onChange, img, name) => (
    <div className="datetime-content">
      <TextField
        fullWidth
        error={!!errors[name]}
        size="small"
        type={label.toLowerCase()}
        onChange={(e) => onChange(name, e.target.value)}
        disabled={isAppointmentCancelled}
        variant="outlined"
        label={label}
        style={{ position: 'relative' }}
        placeholder={label}
        value={value || ''}
      />

      {errors[name] && (
        <div className="error-message-content">
          <ErrorIcon fontSize="small" color="error" />
          <FormHelperText error>
            {errors[name]}
          </FormHelperText>
        </div>
      )}
    </div>
  );

  return !loading ? (
    <>
      <Grid id="info-tab" className="tab-item">
        <Grid
          container
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              label={t('Title')}
              name="title"
              value={appointmentItem.title || ''}
              onChange={(event) => handleInputChange(event)}
              disabled={isAppointmentCancelled}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="outlined" size="small">
              <InputLabel>Status</InputLabel>
              <MaterialSelect
                label={t('Status')}
                id="demo-simple-select-helper"
                value={selectedStatusOption.value}
                disabled
              >
                {statusOptions && statusOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </MaterialSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              style={{ position: 'relative' }}
              label={t('Company')}
              name="company"
              placeholder={t('Company')}
              value={appointmentItem.company ? appointmentItem.company.name : ''}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              style={{ position: 'relative' }}
              label={t('Service')}
              name="service"
              placeholder={t('Service')}
              value={appointmentItem.service ? appointment.service.name : ''}
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              style={{ position: 'relative' }}
              label={t('Customer')}
              name="customer"
              placeholder={t('Customer')}
              value={
                appointmentItem.customer
                  ? `${appointmentItem.customer.first_name} ${appointmentItem.customer.last_name}`
                  : ''
              }
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={!!errors.staff_id}
                variant="outlined"
                margin="dense"
              >
                <Autocomplete
                  fullWidth
                  size="small"
                  autoHighlight
                  value={selectedStaffOption}
                  options={staffsCompanyByService}
                  getOptionLabel={(option) => option.label}
                  onChange={chooseStaff}
                  renderOption={(option) => (
                    <div
                      className="input-menu"
                      key={option.id}
                      value={option.value}
                    >
                      <span className="option-logo">
                        <img src={option.logo || customerAndStaffDefaultLogo} alt="industryLogo" />
                      </span>
                      {option.label}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password',
                      }}
                      label={t('Staff')}
                      error={!!errors.staff_id}
                      variant="outlined"
                    />
                  )}
                />

                {errors.staff_id && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText error>
                      {errors.staff_id}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderDatetime('Date', appointmentItem.date, updateAppointmentItem, calendar, 'date')}
          </Grid>
          <Grid item xs={12} sm={6}>
            {renderDatetime('Time', appointmentItem.start_time, updateAppointmentItem, clock, 'start_time')}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              label={t('Permalink')}
              name="permalink"
              placeholder="Permalink"
              value={appointmentItem.permalink || ''}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextareaAutosize
              rowsMin={3}
              className="notes-from-creator"
              name="note_from_creator"
              aria-label="Notes"
              placeholder="Notes"
              value={appointmentItem.note_from_creator || ''}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </Grid>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  ) : <InfoLoading />;
}

Info.propTypes = {
  appointment: PropTypes.object.isRequired,
  staffsCompanyByService: PropTypes.array.isRequired,
  isAppointmentCancelled: PropTypes.bool.isRequired,
  allStatuses: PropTypes.array.isRequired,
  setIsButtonDisabled: PropTypes.func.isRequired,
  updateAppointment: PropTypes.func.isRequired,
  isUpdateAppointmentSuccess: PropTypes.bool.isRequired,
  isUpdateAppointmentError: PropTypes.bool.isRequired,
  updatedAppointment: PropTypes.object.isRequired,
  updateAppointmentErrors: PropTypes.object.isRequired,
  isAppointmentUpdate: PropTypes.bool.isRequired,
  setIsAppointmentUpdate: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isUpdateAppointmentSuccess: state.appointment.isUpdateAppointmentSuccess,
  isUpdateAppointmentError: state.appointment.isUpdateAppointmentError,
  updatedAppointment: state.appointment.updatedAppointment,
  updateAppointmentErrors: state.appointment.updateAppointmentErrors,
});

function mapDispatchToProps(dispatch) {
  return {
    updateAppointment: (data) => dispatch(updateAppointmentRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Info);
