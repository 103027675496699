import React from 'react';
import {
  Menu,
  Checkbox,
  Box,
  Button,
  Divider,
  MenuItem,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import DateRangeIcon from '@material-ui/icons/DateRange';
import industriesLogo from '../../../../../../assets/industriesLogo';
import Checkboxes from '../../../../../../Modules/checkboxes';

function FilterContent(props) {
  const {
    open,
    anchorEl,
    checkedItems,
    selectedEndDate,
    handleChangeDate,
    selectedStartDate,
    handleCloseFilter,
    submitFilterItems,
    appointmentStatus,
    appointmentIndustry,
    handleChangeCheckboxes,
  } = props;

  return (
    <Menu
      className="filter-menu"
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={open}
      onClose={handleCloseFilter}
    >
      {appointmentIndustry.length
        ? (
          <>
            <Typography variant="h5">Industry</Typography>
            <Divider />
          </>
        )
        : null}
      {appointmentIndustry.map((industry) => (
        <li key={industry.id} className="industries-list">
          <Box alignSelf="center" className="industry-info">
            <Box display="flex">
              <img src={industriesLogo[industry.name]} className="industry-logo" alt="industryLogo" width="20px" /> {industry.name}
            </Box>
          </Box>
          <Checkbox
            color="primary"
            value={industry.id}
            name="industry_ids"
            checked={Object.keys(checkedItems).length ? checkedItems.industry_ids.includes(industry.id) : ''}
            onChange={(e) => handleChangeCheckboxes(e, industry.id)}
          />
        </li>
      ))}
      <Divider />
      <Typography variant="h5">Status</Typography>
      {appointmentStatus.map((status) => (
        <li key={status.name}>
          <Box alignSelf="center" mr="5px" className="filter-text">
            <Typography component="div" noWrap>
              {status.title}
            </Typography>
          </Box>
          { parseInt(status.status_id) === 1
            ? (
              <Checkboxes.Accept
                value={status.status_id}
                checked={Object.keys(checkedItems).length ? checkedItems.status_ids.includes(parseInt(status.status_id)) : ''}
                onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                name="status_ids"
              />
            )
            : parseInt(status.status_id) === 2
              ? (
                <Checkboxes.Pending
                  value={status.status_id}
                  checked={Object.keys(checkedItems).length ? checkedItems.status_ids.includes(parseInt(status.status_id)) : ''}
                  onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                  name="status_ids"
                />
              )
              : parseInt(status.status_id) === 3
                ? (
                  <Checkboxes.Cancel
                    value={status.status_id}
                    checked={Object.keys(checkedItems).length ? checkedItems.status_ids.includes(parseInt(status.status_id)) : ''}
                    onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                    name="status_ids"
                  />
                ) : (
                  <Checkboxes.Finish
                    value={status.status_id}
                    checked={Object.keys(checkedItems).length ? checkedItems.status_ids.includes(parseInt(status.status_id)) : ''}
                    onChange={(e) => handleChangeCheckboxes(e, parseInt(status.status_id))}
                    name="status_ids"
                  />
                )}
        </li>
      ))}
      <Divider />
      <Divider />
      <Typography variant="h5">Date</Typography>
      <Box display="flex">
        <div className="date-from">
          <Typography>From</Typography>
          <MenuItem className="date-picker">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                name="start_date"
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                value={selectedStartDate === '' ? ParsableDate : selectedStartDate}
                onChange={(e) => handleChangeDate(e, 'start_date')}
                inputProps={{ readOnly: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<DateRangeIcon fontSize="small" />}
              />
            </MuiPickersUtilsProvider>
          </MenuItem>
        </div>
        <div className="date-to">
          <Typography>To</Typography>
          <MenuItem className="date-picker">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                name="end_date"
                variant="inline"
                format="yyyy-MM-dd"
                margin="normal"
                value={selectedEndDate === '' ? (moment().add(6, 'months')) : selectedEndDate}
                onChange={(e) => handleChangeDate(e, 'end_date')}
                inputProps={{ readOnly: true }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<DateRangeIcon fontSize="small" />}
                disabled={selectedEndDate < selectedStartDate}
                minDate={selectedStartDate}
              />
            </MuiPickersUtilsProvider>
          </MenuItem>
        </div>
      </Box>
      <Divider />
      <Box display="flex" justifyContent="flex-end" mt="16px">
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => submitFilterItems(checkedItems)}
        >
          Apply
        </Button>
      </Box>
    </Menu>
  );
}

FilterContent.propTypes = {
  anchorEl: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  checkedItems: PropTypes.object.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  submitFilterItems: PropTypes.func.isRequired,
  appointmentStatus: PropTypes.array.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  appointmentIndustry: PropTypes.array.isRequired,
  handleChangeCheckboxes: PropTypes.func.isRequired,
};

FilterContent.defaultProps = {
  anchorEl: null,
};

export default FilterContent;
