import React from 'react';
import {
  Menu,
  Checkbox,
  Box,
  Button,
  Divider,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function FilterCustomerOptions(props) {
  const { t } = useTranslation();
  const {
    role,
    // company,
    checkedStatus,
    handleCloseFilter,
    isOpenCustomerFilter,
    handleFilterAllCustomer,
    handleChangeStatusCheckbox,
  } = props;

  const customerFilter = {
    active: true,
    inActive: true,
  };

  const handleFilterCustomerOption = () => {
    const filteredData = {
      active: checkedStatus.active,
      inActive: checkedStatus.inActive,
      searchValue: '',
    };
    handleFilterAllCustomer(filteredData);
    handleCloseFilter();
  };

  return (
    <Menu
      className="filter-staff-menu"
      anchorEl={isOpenCustomerFilter}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isOpenCustomerFilter}
      onClose={handleCloseFilter}
    >
      <Typography variant="h5">{t('Status')}</Typography>
      <Divider />
      <li key={customerFilter.active}>
        <Box alignSelf="center" mr="5px">
          <Typography variant="h5">{t('Registered')}</Typography>
        </Box>
        <Checkbox
          size="small"
          color="primary"
          value={customerFilter.active}
          name="active"
          checked={Object.keys(checkedStatus).length ? checkedStatus.active : ''}
          onChange={(e) => handleChangeStatusCheckbox(e, customerFilter.active)}
        />
      </li>
      <li key={customerFilter.inActive}>
        <Box alignSelf="center" mr="5px">
          <Typography variant="h5">{t('NotRegistered')}</Typography>
        </Box>
        <Checkbox
          size="small"
          color="primary"
          value={customerFilter.inActive}
          name="inActive"
          checked={Object.keys(checkedStatus).length ? checkedStatus.inActive : ''}
          onChange={(e) => handleChangeStatusCheckbox(e, customerFilter.inActive)}
        />
      </li>
      <Divider />
      <Box display="flex" justifyContent="flex-end" mt="16px">
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => handleFilterCustomerOption(role)}
        >
          {t('Apply')}
        </Button>
      </Box>
    </Menu>
  );
}

FilterCustomerOptions.propTypes = {
  role: PropTypes.string.isRequired,
  // company: PropTypes.object.isRequired,
  checkedStatus: PropTypes.object.isRequired,
  handleCloseFilter: PropTypes.func.isRequired,
  isOpenCustomerFilter: PropTypes.bool.isRequired,
  handleFilterAllCustomer: PropTypes.func.isRequired,
  handleChangeStatusCheckbox: PropTypes.func.isRequired,
};

export default FilterCustomerOptions;
