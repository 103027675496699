import React from 'react';
import PropTypes from 'prop-types';
import {
  Menu,
  IconButton,
  MenuItem,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ServicesIsolatedDropdown(props) {
  const { t } = useTranslation();
  const {
    anchorEl,
    setAnchorEl,
    parentServiceId,
    setSelectedServiceId,
    handleDeleteCategory,
  } = props;

  const handleOpenMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedServiceId(parentServiceId);
  };

  const handleCloseMenu = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(e) => handleOpenMenu(e)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        elevation={1}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={(e) => handleCloseMenu(e)}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        <Link
          to="#"
          onClick={(e, selectedServiceId) => handleDeleteCategory(e, selectedServiceId)}
          className="card-box-item"
        >
          <MenuItem>
            {t('Delete')}
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

ServicesIsolatedDropdown.propTypes = {
  anchorEl: PropTypes.bool.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  parentServiceId: PropTypes.number.isRequired,
  setSelectedServiceId: PropTypes.func.isRequired,
  handleDeleteCategory: PropTypes.func.isRequired,
};

export default ServicesIsolatedDropdown;
