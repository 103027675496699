const receiveMessage = ({ appointments, message, selectedAppointment }) => {
  const index = appointments.findIndex((item) => item.id === message.appointment_id);
  appointments[index] = {
    ...appointments[index],
    messages: [
      ...appointments[index].messages,
      message,
    ],
  };
  if (selectedAppointment.id === message.appointment_id) {
    selectedAppointment.messages = [
      ...selectedAppointment.messages,
      message,
    ];
  }
  return {
    selectedAppointment,
    appointments,
  };
};
const updateMessage = (data) => {
  const { message, appointments } = data;
  let { selectedAppointment } = data;
  const appointmentIndex = appointments.findIndex((item) => item.id === message.appointment_id);
  const messageIndex = appointments[appointmentIndex].messages.findIndex((item) => item.id === message.id);
  appointments[appointmentIndex].messages[messageIndex] = message;
  if (selectedAppointment.id === message.appointment_id) {
    selectedAppointment = appointments[appointmentIndex];
  }
  return {
    appointments,
    selectedAppointment,
  };
};
const deleteMessage = (data) => {
  const {
    appointments, messageId,
    appointmentId,
  } = data;
  let { selectedAppointment } = data;
  const appointmentIndex = appointments.findIndex((item) => item.id === appointmentId);
  const messageIndex = appointments[appointmentIndex].messages.findIndex((item) => item.id === messageId);
  appointments[appointmentIndex].messages.splice(messageIndex, 1);
  if (appointments[appointmentIndex].id === selectedAppointment.id) {
    selectedAppointment = appointments[appointmentIndex];
  }
  return {
    appointments,
    selectedAppointment,
  };
};

export default {
  receiveMessage,
  updateMessage,
  deleteMessage,
};
