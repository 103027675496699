import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Switch,
  FormControlLabel,
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import i18n from 'i18next';
import {
  specialDayRequest,
  updateSpecialDayRequest,
} from '../../../redux/company/actions';
import usePrevious from '../../../CustomHooks/usePrevious';
import splitSpaces from '../../../Modules/splitSpaces';
import SnackbarToast from '../../../Modules/SnackbarToast';

function CompanyWorkDayModal(props) {
  const {
    open,
    onClose,
    // companyGot,
    buttonLoading,
    setButtonLoading,
    handleUpdateCompanyState,
    specialDay,
    allSpecialDays,
    setAllSpecialDays,
    addSpecialDay,
    isSpecialDaySuccess,
    isSpecialDayError,
    storeSpecialDayErrors,
    storedSpecialDay,
    updateSpecialDay,
    isUpdateSpecialDaySuccess,
    isUpdateSpecialDayError,
    updateSpecialDayErrors,
    updatedSpecialDay,
  } = props;

  const date = new Date();
  const [isBreaking, setIsBreaking] = useState(false);
  const [updateErrors, setUpdateErrors] = useState({});
  const [errorBreakingTime, setErrorBreakingTime] = useState({});
  const [errorWorkingTime, setErrorWorkingTime] = useState({});
  const [errorDate, setErrorDate] = useState('');
  const [errors, setErrors] = useState({
    start_date: false,
    end_date: false,
    start_time: false,
    end_time: false,
    break_start_time: false,
    break_end_time: false,
  });
  const [storeErrors, setStoreErrors] = useState({});
  const [workDayData, setWorkDayData] = useState({
    start_date: '',
    end_date: '',
    start_time: '',
    end_time: '',
    description: '',
    break_start_time: null,
    break_end_time: null,
    is_working: true,
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Get some props previous value
  const prevIsSpecialDaySuccess = usePrevious(isSpecialDaySuccess);
  const prevIsSpecialDayError = usePrevious(isSpecialDayError);
  const prevIsUpdateSpecialDaySuccess = usePrevious(isUpdateSpecialDaySuccess);
  const prevIsUpdateSpecialDayError = usePrevious(isUpdateSpecialDayError);

  // Get Special Day data
  useEffect(() => {
    const keys = Object.keys(specialDay);
    const valueExists = keys.find((key) => specialDay[key]);
    if (valueExists) {
      setWorkDayData(specialDay);

      if (specialDay.break_start_time) {
        setIsBreaking(true);
      }
    } else {
      setWorkDayData({
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
        break_start_time: null,
        break_end_time: null,
        description: '',
      });
      setIsBreaking(false);
      setErrors({});
      setUpdateErrors({});
      setStoreErrors({});
      setErrorBreakingTime({});
      setErrorWorkingTime({});
      setErrorDate('');
    }
  }, [specialDay]);

  // Handle Store Company Special Day success
  useEffect(() => {
    if (prevIsSpecialDaySuccess === false && isSpecialDaySuccess) {
      snackBarAlert(true, i18n.t('SpecificDayAdded'), 'success');
      const companySpecialDaysCopy = [...allSpecialDays];
      companySpecialDaysCopy.push(storedSpecialDay);
      setAllSpecialDays(companySpecialDaysCopy);
      handleUpdateCompanyState('specialDays', companySpecialDaysCopy);
      setWorkDayData({
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
        break_start_time: null,
        break_end_time: null,
        description: '',
      });
      setStoreErrors({});
      onClose();
    }
  }, [isSpecialDaySuccess]);

  // Handle Store Special Day error
  useEffect(() => {
    if (prevIsSpecialDayError === false && isSpecialDayError) {
      setStoreErrors(storeSpecialDayErrors);
    }
  }, [isSpecialDayError]);

  // Handle Update Special Day success
  useEffect(() => {
    if (prevIsUpdateSpecialDaySuccess === false && isUpdateSpecialDaySuccess) {
      const specialDaysCopy = [...allSpecialDays];
      const specialDayIndex = specialDaysCopy.findIndex((item) => item.id === updatedSpecialDay.id);
      specialDaysCopy.splice(specialDayIndex, 1);
      specialDaysCopy.push(updatedSpecialDay);
      setAllSpecialDays(specialDaysCopy);
      handleUpdateCompanyState('specialDays', specialDaysCopy);
      setUpdateErrors({});
      setButtonLoading(true);
      onClose();
      snackBarAlert(true, i18n.t('SpecificDayUpdated'), 'success');
    }
  }, [isUpdateSpecialDaySuccess]);

  useEffect(() => {
    if (prevIsUpdateSpecialDayError === false && isUpdateSpecialDayError) {
      setUpdateErrors(updateSpecialDayErrors);
    }
  }, [isUpdateSpecialDayError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const useStyles = makeStyles(() => ({
    error: {
      borderColor: '#f44336',
    },
  }));

  const updateWorkDayData = (name, value) => {
    setWorkDayData({
      ...workDayData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const errorsCopy = { ...errors };
    errorsCopy.start_date = workDayData.start_date === '';
    errorsCopy.end_date = workDayData.end_date === '';
    errorsCopy.start_time = workDayData.start_time === '';
    errorsCopy.end_time = workDayData.end_time === '';

    if (isBreaking) {
      errorsCopy.break_start_time = workDayData.break_start_time === null;
      errorsCopy.break_end_time = workDayData.break_end_time === null;
    } else {
      errorsCopy.break_start_time = false;
      errorsCopy.break_end_time = false;
    }
    setErrors(() => ({ ...errorsCopy }));
    return (errorsCopy.start_date || errorsCopy.end_date || errorsCopy.start_time || errorsCopy.end_time || errorsCopy.break_start_time || errorsCopy.break_end_time);
  };

  const handleAddWorkDay = () => {
    if (!validateForm()) {
      const workDayDataCopy = { ...workDayData };
      workDayDataCopy.description = splitSpaces(workDayDataCopy.description);
      addSpecialDay(workDayDataCopy);
      setButtonLoading(false);
    }
  };

  const handleUpdateWorkDay = () => {
    if (!validateForm()) {
      const workDayDataCopy = { ...workDayData };
      if (!isBreaking) {
        workDayDataCopy.break_start_time = null;
        workDayDataCopy.break_end_time = null;
      }
      workDayDataCopy.description = splitSpaces(workDayDataCopy.description);
      setButtonLoading(false);
      updateSpecialDay(workDayDataCopy);
    }
  };

  const handleChangeDays = (e) => {
    const { name, value } = e.target;
    setErrorDate('');
    if (name === 'start_date') {
      setWorkDayData({
        ...workDayData,
        start_date: value,
        end_date: value,
      });
    } else if (name === 'end_date') {
      const startDate = moment(workDayData.start_date, 'Y-MM-DD').format('Y-MM-DD HH:mm a');
      const endDate = moment(value, 'Y-MM-DD').format('Y-MM-DD HH:mm a');
      if (startDate < endDate) {
        updateWorkDayData('end_date', value);
      } else {
        setErrorDate('End date should be after start date');
      }
    }
  };

  const handleChangeTimes = (e) => {
    const { name, value } = e.target;
    setErrorWorkingTime({});
    if (name === 'start_time') {
      const startTime = moment(value, 'HH:mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(workDayData.end_time, 'HH:mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        updateWorkDayData('start_time', value);
      } else {
        setErrorWorkingTime({
          start_time: i18n.t('StartTimeEarlier'),
        });
      }
    } else if (name === 'end_time') {
      const startTime = moment(workDayData.start_time, 'HH:mm').format('Y-MM-DD HH:mm a');
      const endTime = moment(value, 'HH:mm').format('Y-MM-DD HH:mm a');
      if (startTime < endTime) {
        updateWorkDayData('end_time', value);
      } else {
        setErrorWorkingTime({
          end_time: i18n.t('EndTimeAfter'),
        });
      }
    }
  };

  const handleChangeBreaking = (e) => {
    setIsBreaking(e.target.checked);
  };

  const handleChangeBreakingTimes = (e) => {
    const { name, value } = e.target;
    setErrorBreakingTime({});
    const breakingTime = moment(value, 'HH:mm').format('Y-MM-DD HH:mm a');
    const startTime = moment(workDayData.start_time, 'HH:mm').format('Y-MM-DD HH:mm a');
    const endTime = moment(workDayData.end_time, 'HH:mm').format('Y-MM-DD HH:mm a');
    if (breakingTime >= startTime && breakingTime <= endTime) {
      if (name === 'break_start_time') {
        const breakingStartTime = moment(value, 'HH:mm').format('Y-MM-DD HH:mm a');
        const breakingEndTime = moment(workDayData.break_end_time, 'HH:mm').format('Y-MM-DD HH:mm a');
        if (breakingStartTime < breakingEndTime) {
          updateWorkDayData('break_start_time', value);
        } else {
          setErrorBreakingTime({
            break_start_time: 'Breaking start time should be before breaking end time',
          });
        }
      } else if (name === 'break_end_time') {
        const breakingStartTime = moment(workDayData.break_start_time, 'HH:mm').format('Y-MM-DD HH:mm a');
        const breakingEndTime = moment(value, 'HH:mm').format('Y-MM-DD HH:mm a');
        if (breakingStartTime < breakingEndTime) {
          updateWorkDayData('break_end_time', value);
        } else {
          setErrorBreakingTime({
            break_end_time: i18n.t('BreakingTimeAfter'),
          });
        }
      }
    } else {
      setErrorBreakingTime({
        break_start_time: 'Choose an hour between the start time and end time',
      });
    }
  };

  const classes = useStyles();

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">
              <span>{workDayData.id ? i18n.t('EditSpecificWorkDays') : i18n.t('AddSpecificWorkDays')}</span>
            </Typography>
            <IconButton aria-label="close" className="close-btn" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.start_date || !!storeErrors.start_date || !!updateErrors.start_date}
                size="small"
                variant="outlined"
              >
                <TextField
                  label={i18n.t('StartDate')}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.start_date || !!storeErrors.start_date || !!updateErrors.start_date}
                  size="small"
                  id="start_date"
                  name="start_date"
                  value={workDayData.start_date || ''}
                  onChange={(e) => handleChangeDays(e)}
                  variant="outlined"
                  inputProps={{ min: format(date, 'yyyy-MM-dd'), max: '9999-12-31' }}
                />

                { errors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {i18n.t('StartDateRequired')}
                    </FormHelperText>
                  </div>
                )}

                { storeErrors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.start_date}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.start_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.start_date}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.end_date || !!storeErrors.end_date || !!updateErrors.end_date || !!errorDate}
                size="small"
                variant="outlined"
              >
                <TextField
                  label={i18n.t('EndDate')}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.end_date || !!storeErrors.end_date || !!updateErrors.end_date || !!errorDate}
                  size="small"
                  name="end_date"
                  onChange={(e) => handleChangeDays(e)}
                  variant="outlined"
                  value={workDayData.end_date || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd'), max: '9999-12-31' }}
                />

                { errors.end_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {i18n.t('EndDateRequired')}
                    </FormHelperText>
                  </div>
                )}

                { storeErrors.end_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.end_date}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.end_date && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.end_date}
                    </FormHelperText>
                  </div>
                )}

                { errorDate && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {errorDate}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.start_time || !!storeErrors.start_time || !!updateErrors.start_time || !!errorWorkingTime.start_time}
                size="small"
                variant="outlined"
              >
                <TextField
                  label={i18n.t('WorkdayStart')}
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.start_time || !!storeErrors.start_time || !!updateErrors.start_time || !!errorWorkingTime.start_time}
                  size="small"
                  name="start_time"
                  onChange={(e) => handleChangeTimes(e)}
                  variant="outlined"
                  value={workDayData.start_time || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd') }}
                />

                { errors.start_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {i18n.t('WorkDateStartTimeRequired')}
                    </FormHelperText>
                  </div>
                )}

                { storeErrors.start_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.start_time}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.start_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.start_time}
                    </FormHelperText>
                  </div>
                )}

                { errorWorkingTime.start_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {errorWorkingTime.start_time}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                error={!!errors.end_time || !!storeErrors.end_time || !!updateErrors.end_time || !!errorWorkingTime.end_time}
                size="small"
                variant="outlined"
              >
                <TextField
                  label={i18n.t('WorkdayEnd')}
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  error={!!errors.end_time || !!storeErrors.end_time || !!updateErrors.end_time || !!errorWorkingTime.end_time}
                  size="small"
                  name="end_time"
                  onChange={(e) => handleChangeTimes(e)}
                  variant="outlined"
                  value={workDayData.end_time || ''}
                  inputProps={{ min: format(date, 'yyyy-MM-dd') }}
                />

                { errors.end_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {i18n.t('WorkDateEndTimeRequired')}
                    </FormHelperText>
                  </div>
                )}

                { storeErrors.end_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.end_time}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.end_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.end_time}
                    </FormHelperText>
                  </div>
                )}

                { errorWorkingTime.end_time && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {errorWorkingTime.end_time}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Grid component="label" item xs="auto">
                <FormControlLabel
                  value="breaking"
                  control={(
                    <Switch
                      checked={isBreaking}
                      onChange={handleChangeBreaking}
                      name="is_breaking"
                      color="primary"
                      size="small"
                    />
                  )}
                  label="Break"
                  labelPlacement="top"
                />
              </Grid>
            </Grid>
            {isBreaking && (
              <>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.break_start_time || !!errorBreakingTime.break_start_time}
                    size="small"
                    variant="outlined"
                  >
                    <TextField
                      fullWidth
                      error={!!errors.break_start_time || !!errorBreakingTime.break_start_time}
                      label="Break start"
                      size="small"
                      type="time"
                      name="break_start_time"
                      onChange={(e) => handleChangeBreakingTimes(e)}
                      variant="outlined"
                      value={workDayData.break_start_time || ''}
                      inputProps={{ min: format(date, 'yyyy-MM-dd') }}
                      inputlabelprops={{
                        shrink: true,
                      }}
                    />

                    { errors.break_start_time && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('BreakStartTimeRequired')}
                        </FormHelperText>
                      </div>
                    )}

                    { errorBreakingTime.break_start_time && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {errorBreakingTime.break_start_time}
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.break_end_time || !!errorBreakingTime.break_end_time}
                    size="small"
                    variant="outlined"
                  >
                    <TextField
                      fullWidth
                      error={!!errors.break_end_time || !!errorBreakingTime.break_end_time}
                      label="Break end"
                      size="small"
                      type="time"
                      name="break_end_time"
                      onChange={(e) => handleChangeBreakingTimes(e)}
                      variant="outlined"
                      value={workDayData.break_end_time || ''}
                      inputProps={{ min: format(date, 'yyyy-MM-dd') }}
                      inputlabelprops={{
                        shrink: true,
                      }}
                    />

                    { errors.break_end_time && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          {i18n.t('BreakEndTimeRequired')}
                        </FormHelperText>
                      </div>
                    )}

                    { errorBreakingTime.break_end_time && (
                      <div className="error-message-content">
                        <ErrorIcon fontSize="small" color="error" />
                        <FormHelperText>
                          { errorBreakingTime.break_end_time }
                        </FormHelperText>
                      </div>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={!!storeErrors.description || !!updateErrors.description}
                size="small"
                variant="outlined"
              >
                <TextField
                  value={workDayData.description || ''}
                  onChange={(e) => updateWorkDayData('description', e.target.value)}
                  className={(!!storeErrors.description || !!updateErrors.description) && classes.error}
                  multiline
                  fullWidth
                  rowsMin={2}
                  inputProps={{ maxLength: 500 }}
                  variant="outlined"
                  placeholder={i18n.t('Notes')}
                  label={i18n.t('Description')}
                  name="note_from_creator"
                />

                { storeErrors.description && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {storeErrors.description}
                    </FormHelperText>
                  </div>
                )}

                { updateErrors.description && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {updateErrors.description}
                    </FormHelperText>
                  </div>
                )}

              </FormControl>
            </Grid>
            {workDayData.start_date && workDayData.start_time && workDayData.end_time && (
              <Grid item xs={12}>
                <Box className="info-working-schedule-section">
                  {i18n.t('From')} { moment(workDayData.start_date).format('ll') } { moment(workDayData.start_time, 'HH:mm').format('LT') } {i18n.t('To')} { moment(workDayData.end_date).format('ll') } { moment(workDayData.end_time, 'HH:mm').format('LT') }
                </Box>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            data-cy="cancel"
            type="button"
            onClick={onClose}
          >
            {i18n.t('Cancel')}
          </Button>
          <Button
            size="small"
            data-cy="send"
            type="button"
            onClick={workDayData.id ? handleUpdateWorkDay : handleAddWorkDay}
            variant="contained"
            color="primary"
            loading={buttonLoading}
          >
            {!buttonLoading && <CircularProgress color="white" size={20} />}
            {buttonLoading && workDayData.id ? i18n.t('Save') : buttonLoading && !workDayData.id ? i18n.t('Add') : ''}
          </Button>
        </DialogActions>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

CompanyWorkDayModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  // companyGot: PropTypes.object.isRequired,
  handleUpdateCompanyState: PropTypes.object.isRequired,
  specialDay: PropTypes.object.isRequired,
  allSpecialDays: PropTypes.object.isRequired,
  setAllSpecialDays: PropTypes.func.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  setButtonLoading: PropTypes.func.isRequired,

  // Add Special Day
  addSpecialDay: PropTypes.func.isRequired,
  isSpecialDaySuccess: PropTypes.bool.isRequired,
  isSpecialDayError: PropTypes.bool.isRequired,
  storeSpecialDayErrors: PropTypes.object.isRequired,
  storedSpecialDay: PropTypes.object.isRequired,

  // Update Special Day
  updateSpecialDay: PropTypes.func.isRequired,
  isUpdateSpecialDaySuccess: PropTypes.bool.isRequired,
  isUpdateSpecialDayError: PropTypes.bool.isRequired,
  updateSpecialDayErrors: PropTypes.object.isRequired,
  updatedSpecialDay: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // companyGot: state.company.companyGot,

  // Add Company Special Off
  isSpecialDaySuccess: state.company.isSpecialDaySuccess,
  isSpecialDayError: state.company.isSpecialDayError,
  storeSpecialDayErrors: state.company.storeSpecialDayErrors,
  storedSpecialDay: state.company.storedSpecialDay,

  // Update Special Day
  isUpdateSpecialDaySuccess: state.company.isUpdateSpecialDaySuccess,
  isUpdateSpecialDayError: state.company.isUpdateSpecialDayError,
  updateSpecialDayErrors: state.company.updateSpecialDayErrors,
  updatedSpecialDay: state.company.updatedSpecialDay,
});

function mapDispatchToProps(dispatch) {
  return {
    addSpecialDay: (data) => dispatch(specialDayRequest(data)),
    updateSpecialDay: (data) => dispatch(updateSpecialDayRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyWorkDayModal);
