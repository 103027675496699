import {
  Box, Button, Dialog, DialogActions, DialogContent, IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import warningSharp from '../../../../../../assets/img/warningSharp.svg';

function DeleteModal({ open, onClose, deleteCustomer }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="delete-modal"
    >
      <DialogContent className="modal-content">
        <Box className="close">
          <IconButton
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box className="warning-icon">
          <img src={warningSharp} alt="warning" style={{ width: 24, height: 20 }} />
        </Box>
        <Box className="title">
          {t('CustomerDeleteText')}
        </Box>
      </DialogContent>
      <DialogActions className="buttons">
        <Button
          size="small"
          variant="outlined"
          onClick={onClose}
          style={{ color: '#A4A4A4', width: '100%', height: 33 }}
        >
          {t('Cancel')}
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={deleteCustomer}
          style={{
            color: 'white', backgroundColor: '#F12B2C', width: '100%', height: 33,
          }}
        >
          {t('Delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteModal;

DeleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  deleteCustomer: PropTypes.func.isRequired,
};
