import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function StaffIsolatedDropdown(props) {
  const { t } = useTranslation();
  const {
    anchorEl,
    setAnchorEl,
    staffItem,
    handleEditStaff,
    handleDeleteStaff,
    isUpdateStaffSuccess,
  } = props;

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (isUpdateStaffSuccess) {
      setAnchorEl(null);
    }
  }, [isUpdateStaffSuccess]);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        elevation={1}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: '20ch',
          },
        }}
      >
        {!staffItem.email_verified_at
          ? (
            <span>
              <Link
                to="#"
                onClick={() => handleEditStaff(staffItem.id)}
                className="card-box-item"
              >
                <MenuItem>
                Edit
                </MenuItem>
              </Link>
            </span>
          )
          : null}
        <Link
          to="#"
          onClick={() => handleDeleteStaff(staffItem.id)}
          className="card-box-item"
        >
          <MenuItem>
            {t('Delete')}
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}

StaffIsolatedDropdown.propTypes = {
  anchorEl: PropTypes.bool.isRequired,
  setAnchorEl: PropTypes.func.isRequired,
  staffItem: PropTypes.object.isRequired,
  handleEditStaff: PropTypes.func.isRequired,
  handleDeleteStaff: PropTypes.func.isRequired,
  isUpdateStaffSuccess: PropTypes.bool.isRequired,
};

export default StaffIsolatedDropdown;
