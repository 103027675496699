import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, Typography,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import usePrevious from '../../CustomHooks/usePrevious';
import { switchToAccountRequest } from '../../redux/account/actions';
import SnackbarToast from '../../Modules/SnackbarToast';

const SwitchToAccountModal = (props) => {
  const {
    handleSwitchToAccountProps,
    switchRoleName,
    switchToAccount,
    isSwitchToAccountSuccess,
    isSwitchToAccountError,
    switchToAccountErrorMessage,
  } = props;

  const prevIsSwitchToAccountSuccess = usePrevious(isSwitchToAccountSuccess);
  const prevIsSwitchToAccountError = usePrevious(isSwitchToAccountError);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (isSwitchToAccountSuccess && !prevIsSwitchToAccountSuccess) {
      localStorage.setItem('prefix', switchRoleName);
      snackBarAlert(true, `Switched to ${switchRoleName} account`, 'success');
      handleSwitchToAccountProps({
        showSwitchToAccountModal: false,
        switchRoleName: '',
      });
      window.location.reload(false);
    }
  }, [isSwitchToAccountSuccess]);

  useEffect(() => {
    if (isSwitchToAccountError && !prevIsSwitchToAccountError) {
      snackBarAlert(true, switchToAccountErrorMessage, 'error');
      handleSwitchToAccountProps({
        showSwitchToAccountModal: false,
        switchRoleName: '',
      });
    }
  }, [isSwitchToAccountError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const confirmSwitchToAccount = () => {
    switchToAccount({ name: switchRoleName });
  };

  const close = () => {
    handleSwitchToAccountProps({
      showSwitchToAccountModal: false,
    });
  };

  return (
    <>
      <Dialog
        open
        onClose={close}
      >
        <DialogTitle id="customized-dialog-title">
          <span className="alert-title">
            <span>Switch To Account</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={close}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
          <DialogContent dividers>
            <Typography gutterBottom>Are you sure you want to switch to your
              <span className="role-name"> { switchRoleName } </span> account ?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              size="small"
              onClick={() => handleSwitchToAccountProps({
                showSwitchToAccountModal: false,
                switchRoleName: '',
              })}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => confirmSwitchToAccount()}
            >
              Switch
            </Button>
          </DialogActions>
        </DialogTitle>
      </Dialog>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
};

SwitchToAccountModal.propTypes = {
  handleSwitchToAccountProps: PropTypes.func.isRequired,
  switchRoleName: PropTypes.string.isRequired,
  switchToAccount: PropTypes.func.isRequired,
  isSwitchToAccountSuccess: PropTypes.bool.isRequired,
  isSwitchToAccountError: PropTypes.bool.isRequired,
  switchToAccountErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  isSwitchToAccountSuccess: state.account.isSwitchToAccountSuccess,
  isSwitchToAccountError: state.account.isSwitchToAccountError,
  switchToAccountErrorMessage: state.account.switchToAccountErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    switchToAccount: (data) => dispatch(switchToAccountRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchToAccountModal);
