import { put, takeLatest } from 'redux-saga/effects';
import {
  storeIndividualUserIndustryRequest,
  storeIndividualUserIndustrySuccess,
  storeIndividualUserIndustryError,
  getIndividualUserIndustryByIdRequest,
  getIndividualUserIndustryByIdSuccess,
  getIndividualUserIndustryByIdError,
  updateIndividualUserIndustryRequest,
  updateIndividualUserIndustrySuccess,
  updateIndividualUserIndustryError,
  deleteIndividualUserIndustryRequest,
  deleteIndividualUserIndustrySuccess,
  deleteIndividualUserIndustryError,
  getIndividualUserIndustriesRequest,
  getIndividualUserIndustriesSuccess,
  getIndividualUserIndustriesError,
  getFilteredIndividualUserIndustriesRequest,
  getFilteredIndividualUserIndustriesSuccess,
  getFilteredIndividualUserIndustriesError,
  getIndividualUserAvailableTimesError,
  getIndividualUserAvailableTimesRequest,
  getIndividualUserAvailableTimesSuccess,
  inviteCustomerToIndustryRequest,
  inviteCustomerToIndustrySuccess,
  inviteCustomerToIndustryError,
  getPersonalIndividualUserIndustriesRequest,
  getPersonalIndividualUserIndustriesSuccess,
  getPersonalIndividualUserIndustriesError,
  getUserIndividualIndustriesRequest,
  getUserIndividualIndustriesSuccess,
  getUserIndividualIndustriesError,
  storeIndividualGalleryCompanyRequest,
  storeIndividualGalleryCompanySuccess,
  storeIndividualGalleryCompanyError,
  deleteIndividualGalleryCompanyRequest,
  deleteIndividualGalleryCompanySuccess,
  deleteIndividualGalleryCompanyError,
  individualCloseDayRequest,
  individualCloseDaySuccess,
  individualCloseDayError,
  updateIndividualCloseDayRequest,
  updateIndividualCloseDaySuccess,
  updateIndividualCloseDayError,
  deleteIndividualCloseDayRequest,
  deleteIndividualCloseDaySuccess,
  deleteIndividualCloseDayError,
  specialIndividualDayRequest,
  specialIndividualDaySuccess,
  specialIndividualDayError,
  deleteIndividualSpecialDayRequest,
  deleteIndividualSpecialDaySuccess,
  deleteIndividualSpecialDayError,
  updateIndividualSpecialDayRequest,
  updateIndividualSpecialDaySuccess,
  updateIndividualSpecialDayError,
  storeCustomerToIndustryRequest,
  storeCustomerToIndustrySuccess,
  storeCustomerToIndustryError,
  deleteCustomerFromIndustryRequest,
  deleteCustomerFromIndustrySuccess,
  deleteCustomerFromIndustryError,
  resendCustomerEmailRequest,
  resendCustomerEmailSuccess,
  resendCustomerEmailError,
  updateIndividualCustomerRequest,
  updateIndividualCustomerSuccess,
  updateIndividualCustomerError,
  filterIndividualCustomerByStatusRequest,
  filterIndividualCustomerByStatusSuccess,
  filterIndividualCustomerByStatusError,
  getIndividualUserSettingsRequest,
  getIndividualUserSettingsSuccess,
  getIndividualUserSettingsError,
  updateIndividualUserSettingsRequest,
  updateIndividualUserSettingsSuccess,
  updateIndividualUserSettingsError,
  getIndividualAvailableHoursRequest,
  getIndividualAvailableHoursSuccess,
  getIndividualAvailableHoursError,
  getIndustryAvailableDatesRequest,
  getIndustryAvailableDatesSuccess,
  getIndustryAvailableDatesError,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

function* getIndividualUserIndustries() {
  try {
    const response = yield axiosInstance.get('individual-user-industries');
    if (response && response.status === 200) {
      yield put(getIndividualUserIndustriesSuccess(response.data));
    } else {
      yield put(getIndividualUserIndustriesError(response.response.data));
    }
  } catch (e) {
    yield put(getIndividualUserIndustriesError(e.response.data));
  }
}

function* store({ payload }) {
  try {
    const { avatar } = payload;
    let data = payload;
    let config = axiosInstance.defaults.headers;
    if (avatar) {
      config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const {
        phone_code,
        phone_number, industry_id,
      } = data;
      const formData = new FormData();
      formData.append('phone_code', phone_code);
      formData.append('phone_number', phone_number);
      formData.append('industry_id', industry_id);
      formData.append('avatar', avatar);
      // formData.append('avatar', avatar);
      data = formData;
    } else {
      data.avatar = undefined;
    }
    const response = yield axiosInstance.post('individual-user-industries', data, config);
    if (response && response.status === 200) {
      yield put(storeIndividualUserIndustrySuccess(response.data));
    } else {
      yield put(storeIndividualUserIndustryError(response.response.data));
    }
  } catch (e) {
    yield put(storeIndividualUserIndustryError(e.response.data));
  }
}

function* update({ payload }) {
  const { avatar } = payload;
  const { id } = payload;
  let data = payload;
  let config = axiosInstance.defaults.headers;
  // TODO add call for avatar
  if (avatar) {
    config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const {
      first_name,
      country_id,
      state_id,
      city_id,
      phone_code,
      phone_number,
      industry_id,
      address,
      description,
      zip_code,
      timezone,
      facebook_url,
      instagram_url,
      website_url,
      email,
    } = data;
    const formData = new FormData();
    formData.append('id', id);
    formData.append('first_name', first_name);
    formData.append('country_id', country_id);
    formData.append('state_id', state_id);
    formData.append('city_id', city_id);
    formData.append('address', address);
    formData.append('description', description);
    formData.append('phone_code', phone_code);
    formData.append('phone_number', phone_number);
    formData.append('industry_id', industry_id);
    formData.append('zip_code', zip_code);
    formData.append('timezone', timezone);
    formData.append('facebook_url', facebook_url);
    formData.append('instagram_url', instagram_url);
    formData.append('website_url', website_url);
    formData.append('email', email);
    typeof avatar !== 'string' ? formData.append('avatar', avatar) : null;
    formData.append('_method', 'PUT');
    data = formData;
  } else {
    data['_method'] = 'PUT';
  }
  try {
    const response = yield axiosInstance.post(`individual-user-industries/${id}`, data, config);
    if (response && response.status === 200) {
      yield put(updateIndividualUserIndustrySuccess(response.data));
    } else {
      yield put(updateIndividualUserIndustryError(response.response.data));
    }
  } catch (e) {
    yield put(updateIndividualUserIndustryError(e.response.data));
  }
}

function* getFilteredIndividualUsers(action) {
  try {
    const response = yield axiosInstance.post('individual-user-industries', action.payload);
    if (response && response.status === 200) {
      yield put(getFilteredIndividualUserIndustriesSuccess(response.data));
    } else {
      yield put(getFilteredIndividualUserIndustriesError(response.response.data));
    }
  } catch (e) {
    yield put(getFilteredIndividualUserIndustriesError(e.response.data));
  }
}

function* getIndividualUserAvailableTimes(action) {
  try {
    const response = yield axiosInstance.post('individual-user-industries/available-times', action.payload);
    if (response && response.status === 200) {
      yield put(getIndividualUserAvailableTimesSuccess(response.data));
    } else {
      yield put(getIndividualUserAvailableTimesError(response.response.data));
    }
  } catch (e) {
    yield put(getIndividualUserAvailableTimesError(e.response.data));
  }
}

function* getIndividualUserIndustryById(action) {
  const id = action.payload;
  try {
    const response = yield axiosInstance.get(`individual-user-industries/${id}`);
    if (response && response.status === 200) {
      yield put(getIndividualUserIndustryByIdSuccess(response.data));
    } else {
      yield put(getIndividualUserIndustryByIdError(response.response.data));
    }
  } catch (e) {
    yield put(getIndividualUserIndustryByIdError(e.response.data));
  }
}

function* deleteIndividualUserIndustry(action) {
  const { id } = action.payload;
  try {
    const response = yield axiosInstance.delete(`individual-user-industries/${id}`);
    if (response && response.status === 202) {
      yield put(deleteIndividualUserIndustrySuccess());
    } else {
      yield put(deleteIndividualUserIndustryError(response.response.data));
    }
  } catch (e) {
    yield put(deleteIndividualUserIndustryError(e.response.data));
  }
}

function* inviteCustomerToIndustry(data) {
  const response = yield axiosInstance.post('customer/invite', data.payload);
  try {
    if (response && response.status === 200) {
      yield put(inviteCustomerToIndustrySuccess(response.data));
    } else {
      yield put(inviteCustomerToIndustryError(response.response.data));
    }
  } catch (e) {
    yield put(inviteCustomerToIndustryError(e.response.data));
  }
}

function* getPersonalIndustries(action) {
  try {
    const { industryId } = action.payload;
    const response = yield axiosInstance.get(`personal/industries?industry_id=${industryId}`);
    if (response && response.status === 200) {
      yield put(getPersonalIndividualUserIndustriesSuccess(response.data));
    } else {
      yield put(getPersonalIndividualUserIndustriesError(response.data));
    }
  } catch (e) {
    yield put(getPersonalIndividualUserIndustriesError(e.response.data));
  }
}

function* getUserIndividualIndustries() {
  try {
    const response = yield axiosInstance.get('personal/user-industries');
    if (response && response.status === 200) {
      yield put(getUserIndividualIndustriesSuccess(response.data));
    } else {
      yield put(getUserIndividualIndustriesError(response.data));
    }
  } catch (e) {
    yield put(getUserIndividualIndustriesError(e.response.data));
  }
}

function* storeIndividualGalleryCompany(action) {
  const { payload } = action;
  try {
    const { images } = payload;
    let data = payload;
    let config = axiosInstance.defaults.headers;
    if (images.length) {
      config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const { individual_user_industry_id } = data;
      const formData = new FormData();
      formData.append('individual_user_industry_id', individual_user_industry_id);
      for (let i = 0; i < images.length; i += 1) {
        formData.append('images[]', images[i]);
      }
      data = formData;
    }
    const response = yield axiosInstance.post('images', data, config);
    if (response && response.status && response.status === 200) {
      yield put(storeIndividualGalleryCompanySuccess(response.data));
    } else {
      yield put(storeIndividualGalleryCompanyError(response.response.data));
    }
  } catch (e) {
    if (e && e.response && e.response.data) {
      yield put(storeIndividualGalleryCompanyError(e.response.data));
    }
  }
}

function* deleteIndividualGalleryCompany(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.delete(`images/${id}`);
  try {
    if (response && response.status === 200) {
      yield put(deleteIndividualGalleryCompanySuccess(response.data));
    } else {
      yield put(deleteIndividualGalleryCompanyError(response.response.data));
    }
  } catch (e) {
    yield put(deleteIndividualGalleryCompanyError(e.response.data));
  }
}

function* addIndividualCloseDay(action) {
  const response = yield axiosInstance.post('day-off', action.payload);
  try {
    if (response && response.status === 200) {
      yield put(individualCloseDaySuccess(response.data));
    } else {
      yield put(individualCloseDayError(response.response.data));
    }
  } catch (e) {
    yield put(individualCloseDayError(e.response.data));
  }
}

function* updateIndividualCloseDay(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.put(`day-off/${id}`, action.payload);
  try {
    if (response && response.status === 200) {
      yield put(updateIndividualCloseDaySuccess(response.data));
    } else {
      yield put(updateIndividualCloseDayError(response.response.data));
    }
  } catch (e) {
    yield put(updateIndividualCloseDayError(e.response.data));
  }
}

function* deleteIndividualCloseDay(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.delete(`day-off/${id}`);
  try {
    if (response && response.status === 200) {
      yield put(deleteIndividualCloseDaySuccess(response.data));
    } else {
      yield put(deleteIndividualCloseDayError(response.response.data));
    }
  } catch (e) {
    yield put(deleteIndividualCloseDayError(e.response.data));
  }
}

function* addIndividualSpecialDay(action) {
  const response = yield axiosInstance.post('special-day', action.payload);
  try {
    if (response && response.status === 200) {
      yield put(specialIndividualDaySuccess(response.data));
    } else {
      yield put(specialIndividualDayError(response.response.data));
    }
  } catch (e) {
    yield put(specialIndividualDayError(e.response.data));
  }
}

function* updateIndividualSpecialDay(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.put(`special-day/${id}`, action.payload);
  try {
    if (response && response.status === 200) {
      yield put(updateIndividualSpecialDaySuccess(response.data));
    } else {
      yield put(updateIndividualSpecialDayError(response.response.data));
    }
  } catch (e) {
    yield put(updateIndividualSpecialDayError(e.response.data));
  }
}

function* deleteIndividualSpecialDay(action) {
  const { id } = action.payload;
  const response = yield axiosInstance.delete(`special-day/${id}`);
  try {
    if (response && response.status === 200) {
      yield put(deleteIndividualSpecialDaySuccess(response.data));
    } else {
      yield put(deleteIndividualSpecialDayError(response.response.data));
    }
  } catch (e) {
    yield put(deleteIndividualSpecialDayError(e.response.data));
  }
}

function* storeCustomerToIndustry(data) {
  const response = yield axiosInstance.post('customers', data.payload);
  try {
    if (response && response.status === 200) {
      yield put(storeCustomerToIndustrySuccess(response.data));
    } else {
      yield put(storeCustomerToIndustryError(response.response.data));
    }
  } catch (e) {
    yield put(storeCustomerToIndustryError(e.response.data));
  }
}

function* deleteCustomerFromIndustry(action) {
  const { customer_id, individual_user_industry_id, industryIds } = action.payload;
  const data = { customer_id };
  if (industryIds) {
    data.industry_ids = industryIds;
  } else {
    data.individual_user_industry_id = individual_user_industry_id;
  }
  try {
    const response = yield axiosInstance.post('customer/delete', data);
    if (response && response.status === 202) {
      yield put(deleteCustomerFromIndustrySuccess());
    } else {
      yield put(deleteCustomerFromIndustryError(response.response.data));
    }
  } catch (e) {
    yield put(deleteCustomerFromIndustryError(e.response.data));
  }
}

function* resendCustomerEmail(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('customer/resend', payload);
    if (response && response.status === 202) {
      yield put(resendCustomerEmailSuccess(response.data));
    } else {
      yield put(resendCustomerEmailError(response.response.data));
    }
  } catch (e) {
    yield put(resendCustomerEmailError(e.response.data));
  }
}

function* updateIndividualCustomer(action) {
  const { payload } = action;
  const { id } = payload;
  try {
    const response = yield axiosInstance.put(`customers/${id}`, payload);
    if (response && response.status === 200) {
      yield put(updateIndividualCustomerSuccess(response.data));
    } else {
      yield put(updateIndividualCustomerError(response.response.data));
    }
  } catch (e) {
    yield put(updateIndividualCustomerError(e.response.data));
  }
}

function* filterAllIndividualCustomerByStatus(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post(`filtered-customers?q=${payload.searchValue}`, payload);
    if (response && response.status === 200) {
      yield put(filterIndividualCustomerByStatusSuccess(response.data));
    } else {
      yield put(filterIndividualCustomerByStatusError(response.response.data));
    }
  } catch (e) {
    yield put(filterIndividualCustomerByStatusError(e.response.data));
  }
}

function* getIndividualSettings() {
  try {
    const response = yield axiosInstance.get('user/settings');
    if (response && response.status === 200) {
      yield put(getIndividualUserSettingsSuccess(response.data));
    }
  } catch (e) {
    yield put(getIndividualUserSettingsError());
  }
}

function* updateIndividualUserSettings(action) {
  try {
    const { payload } = action;
    const response = yield axiosInstance.put('user/settings', payload);
    if (response && response.status === 202) {
      yield put(updateIndividualUserSettingsSuccess());
    } else {
      yield put(updateIndividualUserSettingsError(response.response.data));
    }
  } catch (e) {
    yield put(updateIndividualUserSettingsError(e.response.data));
  }
}

function* availableHours(action) {
  const response = yield axiosInstance.post('individual/available-hours', action.payload);
  try {
    if (response && response.status === 200) {
      yield put(getIndividualAvailableHoursSuccess(response.data));
    } else {
      yield put(getIndividualAvailableHoursError(response.response.data.errors));
    }
  } catch (e) {
    yield put(getIndividualAvailableHoursError(e.response.data));
  }
}

function* getAvailableDates(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('available-date', payload);
    if (response && response.status === 200) {
      yield put(getIndustryAvailableDatesSuccess(response.data));
    } else {
      yield put(getIndustryAvailableDatesError(response.data));
    }
  } catch (e) {
    yield put(getIndustryAvailableDatesError(e.response.data));
  }
}

export default function* () {
  yield takeLatest(getIndividualUserIndustriesRequest, getIndividualUserIndustries);
  yield takeLatest(getIndividualUserIndustryByIdRequest, getIndividualUserIndustryById);
  yield takeLatest(storeIndividualUserIndustryRequest, store);
  yield takeLatest(updateIndividualUserIndustryRequest, update);
  yield takeLatest(deleteIndividualUserIndustryRequest, deleteIndividualUserIndustry);
  yield takeLatest(getFilteredIndividualUserIndustriesRequest, getFilteredIndividualUsers);
  yield takeLatest(getIndividualUserAvailableTimesRequest, getIndividualUserAvailableTimes);
  yield takeLatest(inviteCustomerToIndustryRequest, inviteCustomerToIndustry);
  yield takeLatest(getPersonalIndividualUserIndustriesRequest, getPersonalIndustries);
  yield takeLatest(getUserIndividualIndustriesRequest, getUserIndividualIndustries);
  yield takeLatest(storeIndividualGalleryCompanyRequest, storeIndividualGalleryCompany);
  yield takeLatest(deleteIndividualGalleryCompanyRequest, deleteIndividualGalleryCompany);
  yield takeLatest(individualCloseDayRequest, addIndividualCloseDay);
  yield takeLatest(updateIndividualCloseDayRequest, updateIndividualCloseDay);
  yield takeLatest(deleteIndividualCloseDayRequest, deleteIndividualCloseDay);
  yield takeLatest(specialIndividualDayRequest, addIndividualSpecialDay);
  yield takeLatest(updateIndividualSpecialDayRequest, updateIndividualSpecialDay);
  yield takeLatest(deleteIndividualSpecialDayRequest, deleteIndividualSpecialDay);
  yield takeLatest(storeCustomerToIndustryRequest, storeCustomerToIndustry);
  yield takeLatest(deleteCustomerFromIndustryRequest, deleteCustomerFromIndustry);
  yield takeLatest(resendCustomerEmailRequest, resendCustomerEmail);
  yield takeLatest(updateIndividualCustomerRequest, updateIndividualCustomer);
  yield takeLatest(filterIndividualCustomerByStatusRequest, filterAllIndividualCustomerByStatus);
  yield takeLatest(getIndividualUserSettingsRequest, getIndividualSettings);
  yield takeLatest(updateIndividualUserSettingsRequest, updateIndividualUserSettings);
  yield takeLatest(getIndividualAvailableHoursRequest, availableHours);
  yield takeLatest(getIndustryAvailableDatesRequest, getAvailableDates);
}
