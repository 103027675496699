import { takeLatest, put, select } from 'redux-saga/effects';
import {
  getAllCompanyCustomersRequest,
  getAllCompanyCustomersSuccess,
  getAllCompanyCustomersError,
  getAllCompanyWithIndustriesRequest,
  getAllCompanyWithIndustriesSuccess,
  getAllCompanyWithIndustriesError,
  getCompanyCustomersRequest,
  getCompanyCustomersSuccess,
  getCompanyCustomersError,
  getAllCustomerInvitationsRequest,
  getAllCustomerInvitationsSuccess,
  getAllCustomerInvitationsError,
  getIndividualCustomersRequest,
  getIndividualCustomersSuccess,
  getIndividualCustomersError,
  acceptInvitationRequest,
  acceptInvitationSuccess,
  acceptInvitationError,
  deleteCustomerRequest,
  deleteCustomerSuccess,
  deleteCustomerError,
  deleteIndividualCustomerRequest,
  deleteIndividualCustomerSuccess,
  deleteIndividualCustomerError,
  deleteInvitationRequest,
  deleteInvitationSuccess,
  deleteInvitationError,
  deleteIndividualUserInvitationRequest,
  deleteIndividualUserInvitationSuccess,
  deleteIndividualUserInvitationError,
  updateCustomerRequest,
  updateCustomerSuccess,
  updateCustomerError,
  updateInvitationRequest,
  updateInvitationSuccess,
  updateInvitationError,
  updateIndividualUserInvitationRequest,
  updateIndividualUserInvitationSuccess,
  updateIndividualUserInvitationError,
  getAllIndividualCustomerInvitationsRequest,
  getAllIndividualCustomerInvitationsSuccess,
  getAllIndividualCustomerInvitationsError,
  acceptIndividualCustomerInvitationRequest,
  acceptIndividualCustomerInvitationSuccess,
  acceptIndividualCustomerInvitationError,
  resendCustomerInvitationRequest,
  resendCustomerInvitationSuccess,
  resendCustomerInvitationError,
  filterCustomerByStatusRequest,
  filterCustomerByStatusSuccess,
  filterCustomerByStatusError,
  getStaffAvailableHoursSuccess,
  getStaffAvailableHoursError,
  getStaffAvailableHoursRequest,
  filterCustomerAppointmentsRequest,
  filterCustomerAppointmentsSuccess,
  filterCustomerAppointmentsError,
  filterCustomerAppointmentsByDateRequest,
  filterCustomerAppointmentsByDateSuccess,
  filterCustomerAppointmentsByDateError,
  getCustomerUpcomingAppointmentsSuccess,
  getCustomerUpcomingAppointmentsError,
  getCustomerUpcomingAppointmentsRequest,
  getCustomerAppointmentsHistoryRequest,
  getCustomerAppointmentsHistorySuccess,
  getCustomerAppointmentsHistoryError,
  getAllCompanyCustomersSelectRequest,
  getAllCompanyCustomersSelectError,
  getAllCompanyCustomersSelectSuccess,
} from './actions';
import axiosInstance from '../../Config/ServerConfig';

const allCompanyCustomers = (state) => state.customers.allCompanyCustomers;

function* getAllCompanyCustomers({ payload }) {
  try {
    const { limit, page } = payload;
    const response = yield axiosInstance.get(`company/p/customers/v2?page=${page}&limit=${limit}`);

    // const items = yield select(allCompanyCustomers);

    // let itemsLength = items.length;
    // if (refresh) {
    //   itemsLength = 0;
    // }
    // const isMoreData = itemsLength + response.data.data.length < response.data.total;

    if (response && response.status === 200) {
      yield put(getAllCompanyCustomersSuccess({
        data: response.data.data,
        total: response.data.total,
      }));
    } else {
      yield put(getAllCompanyCustomersError());
    }
  } catch (e) {
    yield put(getAllCompanyCustomersError());
  }
}

function* getAllCompanyCustomersForSelect() {
  try {
    const response = yield axiosInstance.get('company/w/customers/select');
    if (response && response.status === 200) {
      yield put(getAllCompanyCustomersSelectSuccess({
        data: response.data,
      }));
    } else {
      yield put(getAllCompanyCustomersSelectError());
    }
  } catch (e) {
    yield put(getAllCompanyCustomersSelectError());
  }
}

function* getAllCompanyWithIndustries({ payload }) {
  try {
    const { industry_id } = payload;
    const response = yield axiosInstance.post('all-companies-and-individuals-by-industry', { industry_id });
    if (response && response.status === 200) {
      yield put(getAllCompanyWithIndustriesSuccess(response.data));
    } else {
      yield put(getAllCompanyCustomersError());
    }
  } catch (e) {
    yield put(getAllCompanyWithIndustriesError());
  }
}

function* getCompanyCustomers({ payload }) {
  try {
    const { id } = payload;
    const response = yield axiosInstance.get(`company/${id}/customers`);
    if (response && response.status === 200) {
      yield put(getCompanyCustomersSuccess(response.data));
    } else {
      yield put(getCompanyCustomersError());
    }
  } catch (e) {
    yield put(getCompanyCustomersError());
  }
}

function* getAllCompanyCustomerInvitations() {
  try {
    const response = yield axiosInstance.get('company/customer-invitations');
    if (response && response.status === 200) {
      yield put(getAllCustomerInvitationsSuccess(response.data));
    } else {
      yield put(getAllCustomerInvitationsError());
    }
  } catch (e) {
    yield put(getAllCustomerInvitationsError());
  }
}

function* getAllIndividualCustomers() {
  try {
    const response = yield axiosInstance.get('customers');
    if (response && response.status === 200) {
      yield put(getIndividualCustomersSuccess(response.data));
    } else {
      yield put(getIndividualCustomersError());
    }
  } catch (e) {
    yield put(getIndividualCustomersError());
  }
}

function* getAllIndividualCustomerInvitations() {
  try {
    const response = yield axiosInstance.get('customer-invitations');
    if (response && response.status === 200) {
      yield put(getAllIndividualCustomerInvitationsSuccess(response.data));
    } else {
      yield put(getAllIndividualCustomerInvitationsError());
    }
  } catch (e) {
    yield put(getAllIndividualCustomerInvitationsError());
  }
}

function* acceptCustomerInvitation(action) {
  const { token } = action.payload;
  try {
    const response = yield axiosInstance.post('company/customer/invite/accept', { token });
    if (response && response.status === 200) {
      yield put(acceptInvitationSuccess(response.data));
    } else {
      yield put(acceptInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(acceptInvitationError(e.response.data));
  }
}

function* acceptIndividualCustomerInvitation(action) {
  const { token } = action.payload;
  try {
    const response = yield axiosInstance.post('industry/customer/invite/accept', { token });
    if (response && response.status === 200) {
      yield put(acceptIndividualCustomerInvitationSuccess(response.data));
    } else {
      yield put(acceptIndividualCustomerInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(acceptIndividualCustomerInvitationError(e.response.data));
  }
}

function* deleteCustomer(action) {
  const { ids } = action.payload;
  const data = { ids, is_cancel: true };
  try {
    const response = yield axiosInstance.post('company/customer/delete', data);
    if (response && response.status === 202) {
      let allCustomers = yield select(allCompanyCustomers);
      allCustomers = allCustomers.filter((item) => !data.ids.includes(item.id));

      yield put(deleteCustomerSuccess(allCustomers));
    } else {
      yield put(deleteCustomerError(response.response.data));
    }
  } catch (e) {
    yield put(deleteCustomerError(e.response.data));
  }
}

function* deleteIndividualCustomer(action) {
  const { customerId, industryIds, industryId } = action.payload;
  const data = {};
  data.customer_id = customerId;
  if (industryIds) {
    data.industry_ids = industryIds;
  } else {
    data.industry_id = industryId;
  }
  try {
    const response = yield axiosInstance.post('customer/delete', data);
    if (response && response.status === 202) {
      yield put(deleteIndividualCustomerSuccess());
    } else {
      yield put(deleteIndividualCustomerError(response.response.data));
    }
  } catch (e) {
    yield put(deleteIndividualCustomerError(e.response.data));
  }
}

function* deleteInvitation(action) {
  const { id } = action.payload;
  try {
    const response = yield axiosInstance.delete(`company/customer/invite/${id}`);
    if (response && response.status === 202) {
      yield put(deleteInvitationSuccess());
    } else {
      yield put(deleteInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(deleteInvitationError(e.response.data));
  }
}

function* deleteIndividualUserInvitation(action) {
  const { id } = action.payload;
  try {
    const response = yield axiosInstance.delete(`customer/invite/${id}`);
    if (response && response.status === 202) {
      yield put(deleteIndividualUserInvitationSuccess());
    } else {
      yield put(deleteIndividualUserInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(deleteIndividualUserInvitationError(e.response.data));
  }
}

function* updateCustomer(action) {
  const { payload } = action;
  const { id } = payload;
  try {
    const response = yield axiosInstance.put(`company/customers/${id}`, payload);
    if (response && response.status === 200) {
      const customers = yield select(allCompanyCustomers);
      const updatedCustomer = response.data.data;

      const updatedCustomerIndex = customers.findIndex((customer) => customer.id === updatedCustomer.id);
      if (updatedCustomerIndex !== -1) {
        customers[updatedCustomerIndex] = updatedCustomer;
      }
      yield put(updateCustomerSuccess(response.data));
    } else {
      yield put(updateCustomerError(response.response.data));
    }
  } catch (e) {
    yield put(updateCustomerError(e.response.data));
  }
}

function* updateInvitation(action) {
  const { payload } = action;
  const { id } = payload;
  try {
    const response = yield axiosInstance.put(`company/customer/invite/${id}`, payload);
    if (response && response.status === 200) {
      yield put(updateInvitationSuccess(response.data));
    } else {
      yield put(updateInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(updateInvitationError(e.response.data));
  }
}

function* updateIndividualUserInvitation(action) {
  const { payload } = action;
  const { id } = payload;
  try {
    const response = yield axiosInstance.put(`customer/invite/${id}`, payload);
    if (response && response.status === 200) {
      yield put(updateIndividualUserInvitationSuccess(response.data));
    } else {
      yield put(updateIndividualUserInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(updateIndividualUserInvitationError(e.response.data));
  }
}

function* resendCustomerInvitation(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('company/customer/resend', payload);
    if (response && response.status === 202) {
      yield put(resendCustomerInvitationSuccess(response.data));
    } else {
      yield put(resendCustomerInvitationError(response.response.data));
    }
  } catch (e) {
    yield put(resendCustomerInvitationError(e.response.data));
  }
}

function* filterAllCustomerByStatus(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post(`company/filtered-customers?q=${payload.searchValue}`, payload);
    if (response && response.status === 200) {
      yield put(filterCustomerByStatusSuccess(response.data));
    } else {
      yield put(filterCustomerByStatusError(response.response.data));
    }
  } catch (e) {
    yield put(filterCustomerByStatusError(e.response.data));
  }
}

function* filterCustomerAppointments(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post('appointments/calendar/filter', payload);
    if (response && response.status === 200) {
      yield put(filterCustomerAppointmentsSuccess(response.data));
    } else {
      yield put(filterCustomerAppointmentsError(response.response.data));
    }
  } catch (e) {
    yield put(filterCustomerAppointmentsError(e.response.data));
  }
}

function* filterCustomerAppointmentsByDate(action) {
  const { payload } = action;
  try {
    const response = yield axiosInstance.post(`appointments/filter?q=${payload.searchValue}`, payload);
    if (response && response.status === 200) {
      yield put(filterCustomerAppointmentsByDateSuccess(response.data));
    } else {
      yield put(filterCustomerAppointmentsByDateError(response.response.data));
    }
  } catch (e) {
    yield put(filterCustomerAppointmentsByDateError(e.response.data));
  }
}

function* getStaffAvailableHours(action) {
  const response = yield axiosInstance.post('staff/available-hours', action.payload);
  try {
    if (response && response.status === 200) {
      yield put(getStaffAvailableHoursSuccess(response.data));
    } else {
      yield put(getStaffAvailableHoursError(response.response.data.errors));
    }
  } catch (e) {
    yield put(getStaffAvailableHoursError(e.response.data));
  }
}

function* getCustomerUpcomingAppointments(action) {
  const { id, page } = action.payload;
  const response = yield axiosInstance.get(`p/appointments/customer/upcoming/${id}?page=${page}`);
  try {
    if (response && response.status === 200) {
      yield put(getCustomerUpcomingAppointmentsSuccess(response.data));
    } else {
      yield put(getCustomerUpcomingAppointmentsError(response.response.data.errors));
    }
  } catch (e) {
    yield put(getCustomerUpcomingAppointmentsError(e.response.data));
  }
}

function* getCustomerAppointmentsHistory(action) {
  const { id, page } = action.payload;
  const response = yield axiosInstance.get(`p/appointments/customer/history/${id}?page=${page}`);
  try {
    if (response && response.status === 200) {
      yield put(getCustomerAppointmentsHistorySuccess(response.data));
    } else {
      yield put(getCustomerAppointmentsHistoryError(response.response.data.errors));
    }
  } catch (e) {
    yield put(getCustomerAppointmentsHistoryError(e.response.data));
  }
}

export default function* () {
  yield takeLatest(getAllCompanyCustomersRequest, getAllCompanyCustomers);
  yield takeLatest(getAllCompanyCustomersSelectRequest, getAllCompanyCustomersForSelect);
  yield takeLatest(getAllCompanyWithIndustriesRequest, getAllCompanyWithIndustries);
  yield takeLatest(getCompanyCustomersRequest, getCompanyCustomers);
  yield takeLatest(getIndividualCustomersRequest, getAllIndividualCustomers);
  yield takeLatest(acceptInvitationRequest, acceptCustomerInvitation);
  yield takeLatest(acceptIndividualCustomerInvitationRequest, acceptIndividualCustomerInvitation);
  yield takeLatest(getAllCustomerInvitationsRequest, getAllCompanyCustomerInvitations);
  yield takeLatest(getAllIndividualCustomerInvitationsRequest, getAllIndividualCustomerInvitations);
  yield takeLatest(deleteCustomerRequest, deleteCustomer);
  yield takeLatest(deleteIndividualCustomerRequest, deleteIndividualCustomer);
  yield takeLatest(deleteInvitationRequest, deleteInvitation);
  yield takeLatest(deleteIndividualUserInvitationRequest, deleteIndividualUserInvitation);
  yield takeLatest(updateCustomerRequest, updateCustomer);
  yield takeLatest(updateInvitationRequest, updateInvitation);
  yield takeLatest(updateIndividualUserInvitationRequest, updateIndividualUserInvitation);
  yield takeLatest(resendCustomerInvitationRequest, resendCustomerInvitation);
  yield takeLatest(filterCustomerByStatusRequest, filterAllCustomerByStatus);
  yield takeLatest(getStaffAvailableHoursRequest, getStaffAvailableHours);
  yield takeLatest(filterCustomerAppointmentsRequest, filterCustomerAppointments);
  yield takeLatest(filterCustomerAppointmentsByDateRequest, filterCustomerAppointmentsByDate);
  yield takeLatest(getCustomerUpcomingAppointmentsRequest, getCustomerUpcomingAppointments);
  yield takeLatest(getCustomerAppointmentsHistoryRequest, getCustomerAppointmentsHistory);
}
