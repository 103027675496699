import React from 'react';
import {
  Select,
  TextField,
  InputLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Grid,
  Button,
  Divider,
  MenuItem,
  IconButton,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import DeleteIcon from '@material-ui/icons/Delete';
import i18n from 'i18next';
import TextMaskCustom from '../../../Modules/TextMaskCustom';
import imgStyle from '../../../Modules/uploadImgStyle';

const AddStaff = (props) => {
  const {
    open,
    onClose,
    staffItem,
    codeOptions,
    staffErrors,
    handleAddStaff,
    addStaffErrors,
    buttonLoading,
    positionOptions,
    professionOptions,
    handleUpdateStaff,
    selectedCodeValue,
    inviteStaffErrors,
    handleChangePosition,
    selectedPositionOption,
    handleStaffInputChange,
    handleChangeProfession,
    handlePhoneNumberChange,
    selectedProfessionOption,
    handleCountryCodeSelectChange,
    handleUpdateAvatar,
    removeUserAvatar,
    files,
    avatar,
  } = props;
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    height: '165px',
  };
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    height: 150,
    padding: 4,
    boxSizing: 'border-box',
  };
  const thumbInner = {
    position: 'relative',
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
  };
  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: (acceptedFiles) => {
      handleUpdateAvatar(acceptedFiles);
    },
  });
  const thumbs = (
    <>
      {(files || avatar) && (
      <div className="thumb-style">
        <div style={thumb}>
          <div className="thumbInner" style={thumbInner}>
            <img
              src={files || (staffItem.avatar ? staffItem.avatar : '')}
              style={imgStyle.img}
              alt="img"
            />
            <div className="remove-industry-avatar">
              <DeleteIcon className="remove-icon" onClick={removeUserAvatar} />
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>
            {staffItem.id ? i18n.t('EditStaff') : i18n.t('AddStaff')}
          </span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers className="add-staff-modal">
        <Grid
          container
          spacing={2}
        >
          <Grid xs={4} item className="add-staff-modal-upload">
            <Grid item>
              <Box className="add-logo-content" id="dropzone">
                <div {...getRootProps({ className: files || avatar ? 'without-background' : 'dropzone' })}>
                  <input {...getInputProps()} />
                </div>
                <aside style={thumbsContainer}>
                  {thumbs}
                </aside>
              </Box>
            </Grid>
          </Grid>
          <Grid xs={8} item container spacing={2} className="add-staff-form">
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!staffErrors.first_name || !!inviteStaffErrors.firstName || !!addStaffErrors.firstName)}
              >
                <TextField
                  fullWidth
                  required
                  pattern="\S+"
                  error={(!!staffErrors.first_name || !!inviteStaffErrors.firstName || !!addStaffErrors.firstName)}
                  size="small"
                  label={i18n.t('FirstName')}
                  variant="outlined"
                  name="first_name"
                  value={staffItem.first_name}
                  onChange={(event) => handleStaffInputChange(event)}
                  inputProps={{ maxLength: 20 }}
                />

                {(!!inviteStaffErrors.firstName || addStaffErrors.firstName || !!staffErrors.first_name) && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {addStaffErrors.firstName || 'First name is required.'}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!staffErrors.last_name || !!inviteStaffErrors.lastName || !!addStaffErrors.lastName)}
              >
                <TextField
                  fullWidth
                  error={(!!staffErrors.last_name || !!inviteStaffErrors.lastName || !!addStaffErrors.lastName)}
                  size="small"
                  label={`${i18n.t('LastName')} *`}
                  variant="outlined"
                  name="last_name"
                  value={staffItem.last_name}
                  onChange={(event) => handleStaffInputChange(event)}
                  inputProps={{ maxLength: 20 }}
                />

                {(!!inviteStaffErrors.lastName || addStaffErrors.lastName || !!staffErrors.last_name) && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {addStaffErrors.lastName || 'Last name is required.'}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel>{i18n.t('Position')}</InputLabel>
                <Select
                  value={selectedPositionOption}
                  name="position"
                  label="Position"
                  onChange={(e) => handleChangePosition(e)}
                >
                  {positionOptions.map((option, index) => (
                    <MenuItem key={`${option.id}-${index}`} value={option.id}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!inviteStaffErrors.profession_id || !!addStaffErrors.profession_id)}
              >
                <InputLabel>{i18n.t('Profession')}</InputLabel>
                <Select
                  onChange={(e) => handleChangeProfession(e)}
                  value={selectedProfessionOption}
                  label={i18n.t('Profession')}
                  name="profession"
                  disabled={selectedPositionOption === 1}
                >
                  {professionOptions.map((option, index) => (
                    <MenuItem key={`${option.id}-${index}`} value={option.id}>{option.name}</MenuItem>
                  ))}
                </Select>

                {(addStaffErrors.profession_id || inviteStaffErrors.profession_id) && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {addStaffErrors.profession_id || 'Profession is required.'}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={5}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!inviteStaffErrors.phone_code || !!addStaffErrors.phone_code)}
              >
                <InputLabel>{i18n.t('Code')}</InputLabel>
                <Select
                  name="phone_code"
                  label={i18n.t('Code')}
                  onChange={handleCountryCodeSelectChange}
                  value={staffItem.phone_code}
                >
                  <MenuItem disabled value=""><em>{i18n.t('PhoneCode')}</em></MenuItem>
                  <Divider />
                  {codeOptions && codeOptions.map((option, index) => (
                    <MenuItem
                      key={`${option.id}-${index}`}
                      value={option.value}
                      selected={selectedCodeValue === option.value}
                    >
                      <img
                        src={option.label.flagImageSrc}
                        alt="flag"
                        className="country-flag"
                      />
                      {option.key}
                      {' '}
                      {option.value}
                    </MenuItem>
                  ))}
                </Select>

                {(inviteStaffErrors.phone_code || addStaffErrors.phone_code) && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {addStaffErrors.phone_code || `${i18n.t('PhoneCode')}is required.`}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={7}>
              <FormControl
                fullWidth
                size="small"
                variant="outlined"
                error={(!!staffErrors.phone_number || !!inviteStaffErrors.phone_number || !!addStaffErrors.phone_number)}
              >
                <TextField
                  fullWidth
                  error={(!!staffErrors.phone_number || !!inviteStaffErrors.phone_number || !!addStaffErrors.phone_number)}
                  size="small"
                  id="phone-detail"
                  type="text"
                  value={staffItem.phone_number}
                  onChange={handlePhoneNumberChange}
                  name="phone_number"
                  label={i18n.t('PhoneNumber')}
                  variant="outlined"
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                />

                {(staffErrors.phone_number || inviteStaffErrors.phone_number || addStaffErrors.phone_number) && (
                  <div className="error-message-content">
                    <ErrorIcon fontSize="small" color="error" />
                    <FormHelperText>
                      {addStaffErrors.phone_number || staffErrors.phone_number || 'Phone code is required.'}
                    </FormHelperText>
                  </div>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={onClose}
        >
          {i18n.t('Cancel')}
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={staffItem.id ? handleUpdateStaff : handleAddStaff}
          loading={!buttonLoading}
        >
          {!buttonLoading && <CircularProgress color="white" size={20} />}
          {buttonLoading && staffItem.id ? i18n.t('Update') : i18n.t('Add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddStaff.propTypes = {
  removeUserAvatar: PropTypes.func.isRequired,
  handleUpdateAvatar: PropTypes.func.isRequired,
  files: PropTypes.array.isRequired,
  avatar: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  staffItem: PropTypes.object.isRequired,
  codeOptions: PropTypes.array.isRequired,
  staffErrors: PropTypes.object.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  handleAddStaff: PropTypes.func.isRequired,
  addStaffErrors: PropTypes.object.isRequired,
  positionOptions: PropTypes.array.isRequired,
  handleUpdateStaff: PropTypes.func.isRequired,
  professionOptions: PropTypes.array.isRequired,
  selectedCodeValue: PropTypes.string.isRequired,
  inviteStaffErrors: PropTypes.object.isRequired,
  handleChangePosition: PropTypes.func.isRequired,
  inviteYourselfError: PropTypes.bool.isRequired,
  handleStaffInputChange: PropTypes.func.isRequired,
  handleChangeProfession: PropTypes.func.isRequired,
  handlePhoneNumberChange: PropTypes.func.isRequired,
  handleInviteCurrentStaff: PropTypes.func.isRequired,
  selectedPositionOption: PropTypes.number.isRequired,
  invitationEmailStaffText: PropTypes.string.isRequired,
  selectedProfessionOption: PropTypes.string.isRequired,
  handleCountryCodeSelectChange: PropTypes.func.isRequired,
};

export default AddStaff;
