import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import firstLetter from '../../../Modules/getFirstLetter';

function LeftContent(props) {
  const {
    appointments, selectedAppointment,
    handleChooseAppointmentMessage,
    userAccount, selectedRole,
    user,
  } = props;

  const [activeRoleName, setActiveRoleName] = useState('');

  useEffect(() => {
    if (selectedRole) {
      const activeRoleNameCopy = selectedRole.toLowerCase();
      setActiveRoleName(activeRoleNameCopy);
    } else {
      const { activeRole: { name } } = userAccount;
      const activeRoleNameCopy = name.toLowerCase();
      setActiveRoleName(activeRoleNameCopy);
    }
  }, []);

  function checkIssetUnreadMessage(id) {
    const appointment = appointments.find((item) => item.id === id);
    const { appointment: { messages } } = { appointment };
    const roleName = activeRoleName.toLowerCase();
    if (messages !== undefined) {
      return Boolean(messages.find((item) => item.seen_at[roleName] === 'null'
          && (roleName === 'owner' ? item.to_id === appointment.staff_id : item.to_id === userAccount.id)));
    }
  }

  const getUserName = (appointment) => {
    let name = '';

    if (appointment.customer_id && user.id === appointment.customer_id) {
      if (appointment.company && appointment.staff) {
        name = appointment.staff.full_name;
      } else if (!appointment.company && appointment.individual_user_industry_id) {
        name = appointment.individual_user_industry.full_name;
      }
    } else if (appointment.staff_id && user.id === appointment.staff_id) {
      name = appointment.customer.full_name;
    } else if (appointment.individual_user_id && user.id === appointment.individual_user_id) {
      name = appointment.customer.full_name;
    } else if (appointment.company && appointment.company.owner && user.id === appointment.company.owner.id) {
      name = appointment.customer.full_name;
    } else if (appointment.individual_user_industry && user.id === appointment.individual_user_industry.user_id) {
      name = appointment.customer.full_name;
    }

    return name;
  };

  const getLogo = (appointment) => {
    let logo = '';

    if (appointment.customer_id && user.id === appointment.customer_id) {
      if (appointment.company && appointment.staff) {
        appointment.staff.avatar ? (
          logo = <Avatar className="user-avatar" alt="avatar" src={appointment.staff.avatar} />
        ) : (
          logo = (
            <Avatar className="user-avatar">
              { firstLetter(appointment.staff.first_name ? appointment.staff.first_name : '',
                appointment.staff.last_name ? appointment.staff.last_name : '')}
            </Avatar>
          ));
      } else if (!appointment.company && appointment.individual_user_industry_id) {
        appointment.individual_user_industry.avatar ? (
          logo = <Avatar className="user-avatar" alt="avatar" src={appointment.individual_user_industry.avatar} />
        ) : (
          logo = (
            <Avatar className="user-avatar">
              { firstLetter(appointment.individual_user_industry.first_name ? appointment.individual_user_industry.first_name : '',
                appointment.individual_user_industry.last_name ? appointment.individual_user_industry.last_name : '')}
            </Avatar>
          )
        );
      }
    } else if (appointment.staff_id && user.id === appointment.staff_id) {
      appointment.customer.avatar ? (
        logo = <Avatar className="user-avatar" alt="avatar" src={appointment.customer.avatar} />
      ) : (
        logo = (
          <Avatar className="user-avatar">
            { firstLetter(appointment.customer.first_name ? appointment.customer.first_name : '',
              appointment.customer.last_name ? appointment.customer.last_name : '')}
          </Avatar>
        )
      );
    } else if (appointment.individual_user_id && user.id === appointment.individual_user_id) {
      appointment.customer.avatar ? (
        logo = <Avatar className="user-avatar" alt="avatar" src={appointment.individual_user_industry.avatar} />
      ) : (
        logo = (
          <Avatar className="user-avatar">
            { firstLetter(appointment.customer.first_name ? appointment.customer.first_name : '',
              appointment.customer.last_name ? appointment.customer.last_name : '')}
          </Avatar>
        )
      );
    } else if (appointment.company && appointment.company.owner && user.id === appointment.company.owner.id) {
      appointment.customer.avatar ? (
        logo = <Avatar className="user-avatar" alt="avatar" src={appointment.customer.avatar} />
      ) : (
        logo = (
          <Avatar className="user-avatar">
            { firstLetter(appointment.customer.first_name ? appointment.customer.first_name : '',
              appointment.customer.last_name ? appointment.customer.last_name : '')}
          </Avatar>
        )
      );
    } else if (appointment.individual_user_industry && user.id === appointment.individual_user_industry.user_id) {
      appointment.customer.avatar ? (
        logo = <Avatar className="user-avatar" alt="avatar" src={appointment.customer.avatar} />
      ) : (
        logo = (
          <Avatar className="user-avatar">
            { firstLetter(appointment.customer.first_name ? appointment.customer.first_name : '',
              appointment.customer.last_name ? appointment.customer.last_name : '')}
          </Avatar>
        )
      );
    }

    return logo;
  };

  return (
    <Box className="messages-main-content">
      { appointments.map((item) => (
        <Box
          key={item.id}
          className={`message-item ${item.id === selectedAppointment.id ? 'active' : ''} ${checkIssetUnreadMessage(item.id) ? 'unread' : ''}`}
          onClick={() => handleChooseAppointmentMessage(item.id)}
        >
          { checkIssetUnreadMessage(item.id) && (
            <Box display="block" className="unread-dot" />
          )}
          <Box className="image-content">
            {getLogo(item)}
          </Box>
          <Box className="info-content">
            <Typography noWrap variant="subtitle1" color="dark">{getUserName(item)}</Typography>
            <span>{moment(item.date, 'Y-MM-DD').format('DD MMM')} { moment(item.start_time, 'HH:mm:s').format('HH:mm')}-{ moment(item.end_time, 'HH:mm:s').format('HH:mm')}</span>
          </Box>
        </Box>
      )) }
    </Box>
  );
}

const mapStateToProps = (state) => ({
  user: state.account.userAccount,
});

LeftContent.propTypes = {
  appointments: PropTypes.array.isRequired,
  selectedAppointment: PropTypes.object.isRequired,
  handleChooseAppointmentMessage: PropTypes.func.isRequired,
  userAccount: PropTypes.object.isRequired,
  selectedRole: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(LeftContent);
