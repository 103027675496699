import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCompanyStaffForSelectRequest } from 'redux/staff/actions';
import { checkAppointmentsByServiceIdRequest } from 'redux/appointment/actions';
import { changeLanguageRequest } from 'redux/translate/action';
import {
  getIndustryServicesRequest,
  getServicesByCompanyRequest,
} from 'redux/service/actions';
import PropTypes from 'prop-types';
import {
  Box, Button, Table, TableBody,
} from '@material-ui/core';
import SnackbarToast from 'Modules/SnackbarToast';
import usePrevious from 'CustomHooks/usePrevious';
import noServicesImage from 'assets/noDataImages/no-services.svg';
import AddIcon from '@material-ui/icons/Add';
import TableLoading from '../../../../../Components/General/TableLoading.';
import DeleteServiceModal from './Modal/deleteServiceModal';
import Categories from './categories';
import Header from './header';
import ServicesTable from './Table';
import ServiceDrawer from './Drawer';
import ServiceContext from './serviceContext';
import styles from './styles.module.scss';
import CreateOrUpdateCategoryModal from './Modal/createOrUpdateCategoryModal';

function Services(props) {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const { isGetCompanyStaffSelectSuccess, companyStaffForSelect } = useSelector((state) => state.staff);
  const { currentLanguage, isGetTranslateLanguageSuccess, isGetTranslateLanguageError } = useSelector((state) => state.translate);
  const {
    servicesByCompany,
    isGetServicesByCompanyError,
    isGetServicesByCompanySuccess,
    getServicesByCompanyErrorMessage,
    isGetIndustryServicesSuccess,
    services: allServices,
    isDeleteCompanyServiceSuccess,
    isDeleteCompanyServiceError,
    deleteCompanyServiceErrorMessage,
    addedService,
    isAddServiceSuccess,
    isAddServiceError,
    addServiceErrorMessage,
    isDeleteServiceSuccess,
    isDeleteServiceError,
    isUpdateServiceSuccess,
    isUpdateServiceError,
    updatedService,
  } = useSelector((state) => state.service);

  const {
    appointments,
    isCheckAppointmentByServiceIdSuccess,
  } = props;

  // Get some props previous value
  const prevIsGetServicesByCompanySuccess = usePrevious(isGetServicesByCompanySuccess);
  const prevIsGetServicesByCompanyError = usePrevious(isGetServicesByCompanyError);
  const prevIsCheckAppointmentByServiceIdSuccess = usePrevious(isCheckAppointmentByServiceIdSuccess);
  const prevIsGetIndustryServicesSuccess = usePrevious(isGetIndustryServicesSuccess);
  const prevIsGetCompanyStaffSelectSuccess = usePrevious(isGetCompanyStaffSelectSuccess);
  const prevIsDeleteServiceSuccess = usePrevious(isDeleteServiceSuccess);
  const prevIsDeleteServiceError = usePrevious(isDeleteServiceError);
  const prevIsUpdateServiceSuccess = usePrevious(isUpdateServiceSuccess);
  const prevIsUpdateServiceError = usePrevious(isUpdateServiceError);
  const prevIsAddServiceSuccess = usePrevious(isAddServiceSuccess);
  const prevIsAddServiceError = usePrevious(isAddServiceError);
  // const prevIsGetTranslateLanguageSuccess = usePrevious(isGetTranslateLanguageSuccess);
  const prevIsDeleteCompanyServiceSuccess = usePrevious(isDeleteCompanyServiceSuccess);
  const prevIsDeleteCompanyServiceError = usePrevious(isDeleteCompanyServiceError);
  const [, setStaffOptions] = useState([]);
  const [services, setServices] = useState(allServices);
  const [, setFilteredAppointments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const [selectedCategoryServices, setSelectedCategoryServices] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [currentCategory, setCurrentCategory] = useState({ name: 'All' });
  const [openServiceDrawer, setOpenServiceDrawer] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openCategoryModal, setOpenCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [allCategoryServices, setAllCategoryServices] = useState([]);
  const [price, setPrice] = useState({ type: 'fixed', price: { from: '0', to: '0' } });
  const [page, setPage] = useState(0);
  const [errors, setErrors] = useState({
    service_id: false,
    staff_ids: false,
    duration: false,
    category: false,
    price: false,
    priceType: false,
    maxToValue: false,
  });

  useEffect(() => {
    // if (!companyStaffForSelect.length) {
    //
    // } else {
    //   getCompanyStaff();
    // }
    if (!isGetTranslateLanguageError && !isGetTranslateLanguageSuccess && !currentLanguage) {
      dispatch(changeLanguageRequest({ lang: i18n.language }));
    }
    dispatch(getCompanyStaffForSelectRequest());
  }, []);

  useEffect(() => {
    if (prevIsGetCompanyStaffSelectSuccess === false && isGetCompanyStaffSelectSuccess) {
      getCompanyStaff();
    }
  }, [isGetCompanyStaffSelectSuccess]);

  useEffect(() => {
    if (servicesByCompany && servicesByCompany.length > 0) {
      setServices(servicesByCompany);
    }
  }, [servicesByCompany]);

  // Check Appointments Success
  useEffect(() => {
    if (prevIsCheckAppointmentByServiceIdSuccess === false && isCheckAppointmentByServiceIdSuccess) {
      const filteredAppointments = appointments.filter((appointment) => appointment.status.id === 1 || appointment.status.id === 2);
      setFilteredAppointments(filteredAppointments);
    }
  }, [isCheckAppointmentByServiceIdSuccess]);

  // Handle Get Services By Company Id Success
  useEffect(() => {
    if (prevIsGetServicesByCompanySuccess === false && isGetServicesByCompanySuccess) {
      getServiceByCompany();
    }
  }, [isGetServicesByCompanySuccess]);

  // Handle Get Industry Services
  useEffect(() => {
    if (prevIsGetIndustryServicesSuccess === false && isGetIndustryServicesSuccess) {
      getAllServices();
    }
  }, [isGetIndustryServicesSuccess]);

  // Handle Get Services By Company Id Error
  useEffect(() => {
    if (prevIsGetServicesByCompanyError === false && isGetServicesByCompanyError) {
      snackBarAlert(true, getServicesByCompanyErrorMessage, 'error');
    }
  }, [isGetServicesByCompanyError]);

  // useEffect(() => {
  // if (prevIsGetTranslateLanguageSuccess === false && isGetTranslateLanguageSuccess) {
  //   setLoading(true);
  //   if (!servicesByCompany.length) {
  //     dispatch(getServicesByCompanyRequest());
  //   } else {
  //     getServiceByCompany();
  //   }
  // }
  // }, [isGetTranslateLanguageSuccess]);

  // Delete company service
  useEffect(() => {
    if (prevIsDeleteCompanyServiceSuccess === false && isDeleteCompanyServiceSuccess) {
      const filtered = selectedCategoryServices.filter((service) => !selectedServiceId.includes(service.company_service_id));
      setSelectedCategoryServices(filtered);
      const categoryServices = filtered.find((item) => selectedCategoryId === item.parent_id);
      const filteredServices = services.map((service) => {
        if (service.id === selectedCategoryId) {
          return { ...service, subService: filtered || [], subServices: filtered || [] };
        }
        return service;
      });
      setServices(filteredServices);
      if (!filtered.length || !categoryServices) {
        // const filteredCategories = categories.filter((category) => category.id !== selectedCategoryId);
        // setCategories(filteredCategories);

        if (currentCategory.id) {
          const indexOfDeletedCategory = categories.findIndex((item) => item.id === selectedCategoryId);
          if (indexOfDeletedCategory !== -1) {
            // getAllSubServices();
            handleSelectCategory('', 'All', 0, filteredServices);
          }
        }
      }
      setOpenDeleteModal(false);
      snackBarAlert(true, 'Service deleted successfully', 'success');
    } else if (prevIsDeleteCompanyServiceError === false && isDeleteCompanyServiceError) {
      snackBarAlert(true, deleteCompanyServiceErrorMessage, 'error');
    }
  }, [isDeleteCompanyServiceSuccess, isDeleteCompanyServiceError]);

  useEffect(() => {
    if (prevIsDeleteServiceSuccess === false && isDeleteServiceSuccess) {
      const categoriesCopy = [...categories];
      const index = categoriesCopy.findIndex((item) => item.id === selectedCategory.id);
      categoriesCopy.splice(index, 1);
      setCategories(categoriesCopy);
      setOpenDeleteModal(false);
      setSelectedCategory(null);
      const servicesCopy = [...services];
      const serviceIndex = servicesCopy.findIndex((item) => item.id === selectedCategory.id);
      servicesCopy.splice(serviceIndex, 1);
      setSelectedCategoryServices([]);
      setServices(servicesCopy);
    } else if (prevIsDeleteServiceError === false && isDeleteServiceError) {
      snackBarAlert(true, 'Something went wrong', 'error');
    }
  }, [isDeleteServiceSuccess, isDeleteServiceError]);

  useEffect(() => {
    if (prevIsUpdateServiceSuccess === false && isUpdateServiceSuccess) {
      const categoriesCopy = [...categories];
      const index = categoriesCopy.findIndex((item) => item.id === updatedService.id);
      categoriesCopy[index].name = updatedService.name;
      setCategories(categoriesCopy);
      setSelectedCategory(null);
      setOpenCategoryModal(false);
    } else if (prevIsUpdateServiceError === false && isUpdateServiceError) {
      snackBarAlert(true, 'Something went wrong', 'error');
    }
  }, [isUpdateServiceSuccess, isUpdateServiceError]);

  useEffect(() => {
    const all = [];
    const servicesByCompanyCopy = [...services];
    servicesByCompanyCopy.map((service) => {
      service.subService = typeof service.subServices === 'string' ? service.subServices = JSON.parse(service.subServices) : service.subService;
      return service.subServices && service.subServices.map((sub) => all.push(sub));
    });
    setAllCategoryServices(all);
  }, [services]);

  useEffect(() => {
    if (prevIsAddServiceSuccess === false && isAddServiceSuccess) {
      const subService = typeof addedService.subServices === 'string' ? addedService.subServices = JSON.parse(addedService.subServices) : addedService.subService;
      let servicesCopy = [...services];
      const isServiceExist = servicesCopy.find((service) => service.id === addedService.id);
      if (isServiceExist) {
        servicesCopy = servicesCopy.map((service) => {
          if (service.id !== addedService.id) {
            return service;
          }
          return { ...addedService, subService };
        });
      } else {
        servicesCopy.push(addedService);
      }
      setServices(servicesCopy);
    } else if (prevIsAddServiceError === false && isAddServiceError) {
      snackBarAlert(true, addServiceErrorMessage, 'error');
    }
  }, [isAddServiceSuccess]);

  const getServiceByCompany = () => {
    const data = [{ id: '', name: t('All'), user_id: null }];
    servicesByCompany.map((service) => data.push({ id: service.id, name: service.name || service.name_en, user_id: service.user_id }));
    setCategories(data);
    setServices(servicesByCompany);
    if (!allServices.length) {
      dispatch(getIndustryServicesRequest());
    } else {
      getAllServices();
    }
    getCurrentCategory(servicesByCompany);
  };

  useEffect(() => {
    if (currentLanguage && !loading) {
      setLoading(true);
      dispatch(getServicesByCompanyRequest());
    }
  }, [currentLanguage]);

  const getAllServices = () => {
    const data = [{ id: '', name: 'All', user_id: null }];
    allServices.map((service) => data.push({ id: service.id, name: service.name || service.name_en, user_id: service.user_id }));
    setAllCategories(data);

    setLoading(false);
  };

  const getCompanyStaff = () => {
    if (!servicesByCompany.length) {
      dispatch(getServicesByCompanyRequest());
    } else {
      getServiceByCompany();
    }
    const staffOptions = [];
    companyStaffForSelect.map((staff) => {
      staffOptions.push({
        id: staff.id,
        avatar: staff.avatar,
        label: staff.full_name,
        value: staff.id,
        professions: staff.staff_company_professions.length && staff.staff_company_professions[0].name,
      });
    });
    setStaffOptions(staffOptions);
  };

  const getCurrentCategory = (data) => {
    const currentCategory = JSON.parse(window.localStorage.getItem('currentCategory'));
    if (currentCategory) {
      handleSelectCategory(currentCategory.id, currentCategory.name, currentCategory.index, data);
    } else {
      handleSelectCategory('', 'All', 0, data);
    }
  };

  const getAllSubServices = (data = services) => {
    const all = [];
    data.map((service) => {
      service.subService = typeof service.subServices === 'string' ? service.subServices = JSON.parse(service.subServices) : service.subService;
      return service.subServices && service.subServices.map((sub) => all.push(sub));
    });
    setSelectedCategoryServices(all);
  };

  const getCurrentCategorySubServices = (categoryId, data) => {
    const selectedCategory = data.find((service) => service.id === categoryId);
    if (selectedCategory) {
      const subServices = typeof selectedCategory.subServices === 'string' ? selectedCategory.subServices = JSON.parse(selectedCategory.subServices) : selectedCategory.subServices;
      setSelectedCategoryServices(subServices);
    } else if (addedService && addedService.subServices) {
      const subServices = typeof addedService.subServices === 'string' ? addedService.subServices = JSON.parse(addedService.subServices) : addedService.subServices;
      setSelectedCategoryServices(subServices);
    }
  };

  const handleSelectCategory = (categoryId, categoryName, index, data = services) => {
    window.localStorage.setItem('currentCategory', JSON.stringify({ id: categoryId, name: categoryName, index }));

    setPage(0);
    setCurrentCategory({ id: categoryId, name: categoryName });
    setSelectedServiceId([]);
    if (services) {
      if (categoryName !== 'All') {
        getCurrentCategorySubServices(categoryId, data);
        setSelectedCategoryIndex(index);
      } else {
        getAllSubServices(data);
        setSelectedCategoryIndex(index);
      }
    }
  };

  const handleSearchService = (event) => {
    const { value } = event.target;
    const filtered = value && selectedCategoryServices.filter((sub) => (sub.name.toLowerCase().includes(value.toLowerCase())));

    if (currentCategory.name !== 'All') {
      if (value) {
        setSelectedCategoryServices(filtered);
      } else {
        getCurrentCategorySubServices(currentCategory.id, services);
      }
    } else if (currentCategory.name === 'All') {
      if (value) {
        setSelectedCategoryServices(filtered);
      } else {
        getAllSubServices();
      }
    }
  };

  const handleDeleteCategory = (category) => {
    setOpenDeleteModal(true);
    setSelectedCategory(category);
  };

  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setOpenCategoryModal(true);
  };

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleOpenDeleteModal = (e, serviceId, parentId) => {
    e.stopPropagation();
    setOpenDeleteModal(true);
    const data = [];
    data.push(serviceId);
    setSelectedServiceId(data);
    setSelectedCategoryId(parentId);
  };

  const serviceProps = {
    allServices,
    currentCategory,
    servicesByCompany,
    allCategoryServices,
    selectedCategoryServices,
    setSelectedCategoryServices,
    selectedCategoryId,
    setSelectedCategoryId,
    selectedServiceId,
    setSelectedServiceId,
    openDeleteModal,
    setOpenDeleteModal,
    allCategories,
    setAllCategories,
    openServiceDrawer,
    setOpenServiceDrawer,
    services,
    setServices,
    categories,
    setCategories,
    errors,
    setErrors,
    price,
    setPrice,
    selectedCategoryIndex,
    setSelectedCategoryIndex,
    snackBarAlert,
    handleOpenDeleteModal,
    handleSelectCategory,
    setOpenCategoryModal,
    openCategoryModal,
    handleEditCategory,
    handleDeleteCategory,
    selectedCategory,
    setSelectedCategory,
  };

  return (
    <>
      <ServiceContext.Provider value={serviceProps}>
        <Box id={styles.servicesContainer}>
          <Box height="100%">
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} height="100%">
              <Box gridColumn="span 2" height="100%" className={styles.container}>
                <Box className={styles.categoriesContainer}>
                  <Box className={styles.titleBox}>
                    <p>
                      {t('Categories')}
                    </p>
                    <Button
                      size="small"
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={() => setOpenCategoryModal(true)}
                    >
                      {t('new')}
                    </Button>
                  </Box>
                  <Box className={styles.categories}>
                    <Categories />
                  </Box>
                </Box>
              </Box>
              <Box gridColumn="span 10">
                <Box>
                  <Header handleSearchService={handleSearchService} />
                </Box>
                <Box className={styles.tableBox}>
                  {!loading ? (
                    selectedCategoryServices.length > 0 ? (
                      <ServicesTable
                        selectedCategoryServices={selectedCategoryServices}
                        loading={loading}
                        page={page}
                        setPage={setPage}
                      />
                    ) : (
                      <div className="no-data">
                        <img src={noServicesImage} alt="no-services" />
                      </div>
                    )
                  ) : (
                    <Table>
                      <TableBody>
                        <TableLoading count={10} />
                      </TableBody>
                    </Table>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          <ServiceDrawer />
          <DeleteServiceModal />
          {openCategoryModal ? <CreateOrUpdateCategoryModal /> : null}
        </Box>
      </ServiceContext.Provider>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
    </>
  );
}

Services.propTypes = {
  // Check Appointments
  isCheckAppointmentByServiceIdSuccess: PropTypes.bool.isRequired,
  appointments: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  // Update service
  updatedService: state.service.updatedService,
  updateServiceErrorMessage: state.service.updateServiceErrorMessage,
  // // Add service
  isAddServiceSuccess: state.service.isAddServiceSuccess,
  addedService: state.service.addedService,
  addServiceErrorMessage: state.service.addServiceErrorMessage,
  // Check Appointments
  isCheckAppointmentByServiceIdSuccess: state.appointment.isCheckAppointmentByServiceIdSuccess,
  isCheckAppointmentByServiceIdError: state.appointment.isCheckAppointmentByServiceIdError,
  appointments: state.appointment.appointments,
  // Account
  account: state.account.userAccount,
});

function mapDispatchToProps(dispatch) {
  return {
    checkAppointmentsByServiceId: (data) => dispatch(checkAppointmentsByServiceIdRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Services);
