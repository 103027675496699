import { handleActions } from 'redux-actions';
import {
  changeLanguageRequest,
  changeLanguageSuccess,
  changeLanguageError,
} from './action';

const initialState = {
  isGetTranslateLanguageSuccess: false,
  isGetTranslateLanguageError: false,
  currentLanguage: '',
};

const reducer = handleActions(
  {
    [changeLanguageRequest]: (state) => ({
      ...state,
      isGetTranslateLanguageSuccess: false,
      isGetTranslateLanguageError: false,
      currentLanguage: '',
    }),
    [changeLanguageSuccess]: (state, { payload }) => ({
      ...state,
      isGetTranslateLanguageSuccess: true,
      isGetTranslateLanguageError: false,
      currentLanguage: payload,
    }),
    [changeLanguageError]: (state) => ({
      ...state,
      isGetTranslateLanguageSuccess: false,
      isGetTranslateLanguageError: true,
      currentLanguage: '',
    }),
  },
  initialState,
);

export default reducer;
