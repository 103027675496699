import React, {
  useState,
  useEffect,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PermissionsModule from '../../../../Modules/Permission';
import IndividualCustomers from '../../Roles/Individual/Customers/customers';
import StaffCustomers from '../../Roles/Staff/Customers/customers';
import GetSelectedRoleMessages from '../Messages/getSelectedRoleMessages';

function Customers(props) {
  const {
    account: { testPermissions },
    match,
    history,
  } = props;

  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');

  useEffect(() => {
    const roles = PermissionsModule.getRoles(testPermissions).filter((role) => role !== 'Customer');
    setRoles(roles);

    const roleToSelect = PermissionsModule.getRoleToSelect(roles);
    setSelectedRole(roleToSelect);
  }, []);
  useEffect(() => {
    localStorage.setItem('prefix', selectedRole);
  }, [selectedRole]);

  const getSelectedRoleComponent = () => {
    const props = { history, match, roles };
    // eslint-disable-next-line default-case
    switch (selectedRole.toLowerCase()) {
      case 'individual': {
        return <IndividualCustomers {...props} />;
      }
      case 'staff': {
        return <StaffCustomers {...props} />;
      }
    }
  };

  return (
    <>
      {getSelectedRoleComponent(
        <GetSelectedRoleMessages
          roles={roles}
          selectedRole={selectedRole}
          selectRole={(role) => setSelectedRole(role)}
        />,
      )}
    </ >
  );
}

Customers.propTypes = {
  account: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  account: state.account.userAccount,
});

export default connect(mapStateToProps)(Customers);
