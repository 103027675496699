import React from 'react';
import getCurrencyIcon from './getCurrencyIcon';

const getServicePrice = (priceType, price, companyGot) => {
  let subServicePrice;
  switch (priceType) {
    case 'range': {
      subServicePrice = price.to ? `${price.from} - ${price.to} ${companyGot.currency && getCurrencyIcon(companyGot.currency)}` : (
        <>
          <span styles={{
            color: '#A4A4A4',
            font: 'normal 500 14px/19px Nunito Sans, sans-serif',
          }}
          >From
          </span> {price.from} {companyGot.currency && getCurrencyIcon(companyGot.currency)}
        </>
      );
      break;
    }
    case 'fixed': {
      subServicePrice = `${price.to} ${companyGot.currency && getCurrencyIcon(companyGot.currency)}`;
      break;
    }
    case 'free': {
      subServicePrice = 'Free';
      break;
    }
    default: {
      break;
    }
  }
  return subServicePrice;
};

export default getServicePrice;
