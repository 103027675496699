const resources = {
  fr: {
    translation: {
      Accept: 'Accepter',
      Accepted: 'Accepté',
      AccountCreated: 'Compte créé.',
      Actions: 'Options',
      Add: 'Ajouter',
      AddAppointment: 'Ajouter un rendez-vous',
      AddClosedDays: 'Ajouter des jours de fermeture',
      AddCompany: 'Ajouter une entreprise',
      AddCustomer: 'Ajouter un client',
      AddDayOff: 'Ajouter des jours de congé',
      AddNewCustomer: 'Ajouter un nouveau client',
      Address: 'Adresse',
      AddService: 'Ajouter un service',
      AddShortDescOptional: 'Ajouter une brève description (facultatif)',
      AddSpecificWorkDays: 'Ajouter des jours de travail spécifiques',
      AddStaff: 'Ajouter un personnel',
      All: 'Tout',
      AllAppointmentsCancelText: 'Tous les rendez-vous à venir seront annulés.',
      AMD: 'AMD',
      Apply: 'Appliquer',
      ApplyToAll: 'Appliquer à tous',
      'Appointment Count': 'Nombre de rendez-vous',
      AppointmentAccepted: 'Rendez-vous accepté avec succès',
      AppointmentCanceled: 'Rendez-vous annulé avec succès',
      AppointmentCancelModalText: 'Êtes-vous sûr de vouloir annuler votre rendez-vous?',
      AppointmentCreated: 'Rendez-vous créé avec succès',
      AppointmentDeleted: 'Rendez-vous supprimé avec succès',
      AppointmentDeleteModalText: 'Le rendez-vous disparaîtra du calendrier et ne sera pas pris en compte dans les statistiques.',
      AppointmentDeleteQuestion: 'Êtes-vous sûr de vouloir supprimer le rendez-vous?',
      AppointmentDetails: 'Détails du rendez-vous',
      AppointmentHistory: 'Historique des rendez-vous',
      AppointmentNotes: 'Non visible par les clients',
      AppointmentNotFiltered: 'Le rendez-vous n\'est pas filtré',
      AppointmentNotFound: 'Rendez-vous non trouvé.',
      AppointmentRescheduled: 'Rendez-vous reporté avec succès',
      Appointments: 'Rendez-Vous',
      AppointmentSaveDraft: 'Rendez-vous sauvegardé en tant que brouillon',
      AppointmentsHistory: 'Historique des rendez-vous',
      AreYouSureCancel: 'Êtes-vous sûr de vouloir annuler le',
      AreYouSureDelete: 'Êtes-vous sûr de vouloir supprimer le',
      'Assigned Services': 'Services attribués',
      AtLeastOne: 'Au moins un(e) ',
      AutoAccept: 'Accepter automatiquement les rendez-vous',
      AutoAcceptLabel: 'Auto Accepter',
      AutoAcceptTooltipText: 'Les rendez-vous reçus via la réservation en ligne seront automatiquement acceptés.',
      AutoFinish: 'Marquer automatiquement les rendez-vous confirmés comme terminés une fois que leur heure de fin est passée',
      AvailableHours: 'Créneaux horaires disponibles',
      BookingLink: 'Lien de réservation',
      BookingUrl: 'URL de réservation',
      BreakEndTimeAfter: 'L\'heure de fin de la pause doit être postérieure à l\'heure de début de la pause.',
      BreakEndTimeRequired: 'L\'heure de fin de la pause est obligatoire.',
      Breaking: 'Pause',
      BreakStartTimeRequired: 'L\'heure de début de la pause est obligatoire.',
      BufferTime: 'Temps tampon',
      BufferTimeTooltipText: 'Ajouter du temps supplémentaire après le service, qui n\'est pas affiché aux utilisateurs.',
      BusinessNotSaved: 'Détails de l\'entreprise non sauvegardés.',
      BusinessSaved: 'Détails de l\'entreprise sauvegardés.',
      ByDate: 'Par date',
      ByStatus: 'Par statut',
      Calendar: 'Calendrier',
      CalendarTimeSlot: 'Plage horaire',
      Cancel: 'Annuler',
      CancelAllUpcomingAppointments: 'Annuler tous les rendez-vous à venir',
      CancelAppointment: 'Annuler le rendez-vous',
      CancelInvitation: 'Annuler l\'invitation',
      Cancelled: 'Annulé',
      CantInviteYourSelf: 'Vous ne pouvez pas vous inviter vous-même en tant que client',
      Categories: 'Catégories',
      Characters: 'caractères',
      ChooseCustomer: 'Sélectionnez un client',
      ChooseHour: 'Choisir une heure',
      ChooseMinutes: 'Choisir des minutes',
      ChooseOrCreateService: 'Choisir ou créer un service',
      CreateService: 'Créer un service',
      ChooseOrCreateServiceCategory: 'Choisir ou créer une catégorie de service',
      ChooseServiceCategory: 'Choisissez la catégorie de service',
      ChooseService: 'Sélectionnez un service',
      ChooseServiceProvider: 'Choisissez le fournisseur de service',
      ChooseStaff: 'Sélectionnez un personnel',
      City: 'Ville',
      Close: 'Fermé',
      ClosedDayAdded: 'Jour(s) de fermeture ajouté(s) avec succès.',
      ClosedDays: 'Jours de fermeture',
      ClosedDayUpdated: 'Jour(s) de fermeture mis à jour avec succès.',
      Code: 'Code pays',
      Companies: 'Entreprises',
      Company: 'Entreprise',
      CompanyDeleted: 'Entreprise supprimée avec succès',
      CompanyEmail: 'Entrez l\'adresse e-mail de l\'entreprise',
      CompanyName: 'Nom de l\'entreprise',
      CompanyOverview: 'Rédigez un bref aperçu de votre entreprise',
      Confirm: 'Confirmer',
      ConfirmActionMassagesDelete: 'Êtes-vous sûr de vouloir supprimer cette entreprise?',
      ConfirmActionMassagesDeleteError: 'Vous ne pouvez pas supprimer cette entreprise car elle a des rendez-vous à venir.',
      ConfirmActionMassagesLeave: 'Êtes-vous sûr de vouloir quitter l\'entreprise?',
      ConfirmCancel: 'Annuler la confirmation',
      ConfirmDelete: 'Confirmer la suppression',
      ConfirmDeletion: 'Confirmer la suppression',
      ConfirmPassword: 'Confirmez le mot de passe',
      continueDeleting: 'Continuer la suppression?',
      continueWithFacebook: 'Continuer avec Facebook',
      Country: 'Pays',
      Create: 'Créer',
      CreateAppointment: 'Créer un rendez-vous',
      CurrentPassword: 'Mot de passe actuel',
      Customer: 'Client',
      'Customer Name': 'Nom du client',
      CustomerAdded: 'Client ajouté',
      CustomerDeleted: 'Client supprimé avec succès',
      CustomerDeleteText: 'S\'il y a un rendez-vous avec ces clients, il sera annulé.',
      CustomerDetails: 'Détails du client',
      CustomerPreview: 'Aperçu du client',
      Customers: 'Clients',
      CustomerUpdated: 'Client mis à jour avec succès',
      Daily: 'Quotidien',
      Date: 'Date',
      DateAndTime: 'Date et heure',
      DatePicker: 'Sélecteur de date',
      DateTime: 'Date/Heure',
      DayAdded: 'Jour(s) de congé ajouté(s) avec succès',
      DayOfBirth: 'Date de naissance',
      DayOffDeleted: 'Jour(s) de congé supprimé(s) avec succès.',
      DaysOff: 'Jours de congé',
      DayUpdated: 'Jour(s) de congé mis à jour avec succès.',
      Decline: 'Décliner',
      DefaultView: 'Vue de calendrier par défaut',
      Delete: 'Supprimer',
      DeleteCategoryText: 'Si vous supprimez cette catégorie de services, tous les services qui en font partie seront également supprimés.',
      deleteMultipleStaffText: 'S\'il y a un rendez-vous avec ces membres du personnel, il sera annulé.',
      Description: 'Description',
      Details: 'Détails',
      Digit: 'chiffre',
      DontGetCode: 'Vous n\'avez pas reçu de code?',
      DontHaveAnAccount: "Vous n'avez pas de compte?",
      DownloadAndSignUp: 'Téléchargez l\'application et inscrivez-vous maintenant!',
      Duration: 'Durée',
      Edit: 'Modifier',
      EditAppointment: 'Modifier le rendez-vous',
      EditCategory: 'Modifier la catégorie',
      EditCustomer: 'Modifier le client',
      EditService: 'Modifier le service',
      EditSpecificWorkDays: 'Modifier les jours de travail spécifiques',
      EditStaff: 'Modifier le personnel',
      Email: 'E-mail',
      EmailOptional: 'E-mail (facultatif)',
      EmailRequired: 'L\'adresse e-mail est obligatoire.',
      EmailResent: 'E-mail renvoyé avec succès.',
      EmailValid: 'Veuillez entrer une adresse e-mail valide.',
      EndDate: 'Date de fin',
      EndDateRequired: 'La date de fin est obligatoire.',
      EndTime: 'Heure de fin',
      EndTimeAfter: 'L\'heure de fin doit être après l\'heure de début.',
      EnterCodeText: 'Saisissez le code à 6 chiffres envoyé à votre',
      EnterCustomerEmail: 'Saisissez l\'adresse e-mail du client',
      EnterCustomerFirstName: 'Saisissez le prénom du client',
      EnterCustomerLastName: 'Saisissez le nom de famille du client',
      EnterCustomerPhoneNumber: 'Saisissez le numéro de téléphone du client',
      EnterSignInDetailsText: 'Veuillez saisir vos détails pour vous connecter.',
      EnterStaffFirstName: 'Saisissez le prénom du personnel',
      EnterStaffLastName: 'Saisissez le nom de famille du personnel',
      EnterStaffPhoneNumber: 'Saisissez le numéro de téléphone du personnel',
      EnterStaffProfession: 'Saisissez la profession du personnel',
      Facebook: 'URL de la page Facebook',
      FasterAndMoreOrganizedText: 'Rendons le processus de réservation plus rapide et plus organisé.',
      Female: 'Femme',
      FileNotSupported: 'Le fichier n\'est pas pris en charge',
      Filter: 'Filtre',
      FinalPriceText: 'Veuillez définir le prix final pour ce service.',
      Finish: 'Terminer',
      FinishAppointment: 'Terminer le rendez-vous',
      Finished: 'Terminé',
      'First Visit': 'Première visite',
      FirstName: 'Prénom',
      FirstNameOrLastNameRequired: 'Le prénom ou le nom de famille est requis',
      Fixed: 'Fixe',
      FollowUp: 'Suivi',
      ForgotPassword1: 'Mot de passe oublié?',
      ForgotPassword2: 'Mot de passe oublié',
      Free: 'Gratuit',
      Fri: 'Ven',
      Friday: 'Vendredi',
      From: 'De',
      Gallery: 'Galerie',
      Gender: 'Genre',
      General: 'Informations générales',
      GeneralSchedule: {
        BreakHours: 'Temps de pause',
        Title: 'Calendrier général',
        WeekDays: 'Jours de travail',
        WorkingHours: 'Heures de travail',
      },
      H: 'h',
      haveOneStaff: 'Il est obligatoire d\'avoir au moins 1 personnel.',
      Hour: 'Heure',
      ImageDeleted: 'Image supprimée avec succès',
      IndustriesNotSaved: 'Détails de l\'industrie non sauvegardés.',
      Industry: 'Industrie',
      IndustryDeleted: 'Industrie supprimée avec succès.',
      IndustryNotFound: 'L\'industrie n\'est pas trouvée.',
      IndustryUpdated: 'Industrie mise à jour avec succès.',
      Info: 'Info',
      InfoGeneral: 'Informations générales',
      InfoLinks: 'Liens',
      InfoLocation: 'Emplacement',
      InfoTitle: 'Info',
      Instagram: 'Nom d\'utilisateur du compte Instagram',
      InvalidData: 'Données non valides.',
      InvalidLoginOrPass: 'Nom d\'utilisateur ou mot de passe incorrect.',
      Invitation: 'Invitation',
      InvitationAccepted: 'Invitation acceptée avec succès',
      InvitationDeclined: 'Invitation déclinée.',
      InvitationDeleted: 'Invitation supprimée avec succès',
      InvitationSent: 'Invitation envoyée.',
      InvitationUpdated: 'Invitation mise à jour avec succès',
      Invite: 'Inviter',
      InviteCustomer: 'Inviter à s\'inscrire',
      InviteCustomerDescription: 'Invitez <0>{{name}}</0> à s\'inscrire sur SetTime.',
      Invited: 'Invité',
      InviteToRegister: 'Inviter à s\'inscrire',
      IsNotValid: ' n\'est pas valide',
      IsRequired: ' est requis',
      IsTooLong: ' est trop long',
      Language: 'Langue',
      'Last Visit': 'Dernière visite',
      LastName: 'Nom de famille',
      LastNameOptional: 'Nom de famille (facultatif)',
      Leave: 'Quitter',
      LeaveCompany: 'Quitter l\'entreprise.',
      LoadDraft: 'Charger le brouillon',
      Loading: 'Chargement',
      Location: 'Emplacement',
      Logout: 'Déconnexion',
      Lowercase: 'minuscule',
      lowercasePhoneNumber: 'Numéro de téléphone',
      MaintenanceTime: 'Temps de maintenance',
      Male: 'Homme',
      Manager: 'Gestionnaire',
      Min: 'min',
      Mon: 'Lun',
      Monday: 'Lundi',
      Monthly: 'Mensuel',
      MyAppointments: 'Rendez-Vous',
      MyCompany: 'Mon entreprise',
      MyProfile: 'Mon profil',
      Name: 'Nom',
      New: 'Nouveau',
      NewAppointment: 'Nouveau rendez-vous',
      NewCategory: 'Nouvelle catégorie',
      NewCompany: 'Nouvelle entreprise',
      NewCustomer: 'Nouveau client',
      NewPassMustBeDifferentText: 'Votre nouveau mot de passe doit être différent du précédent.',
      NewPassword: 'Nouveau mot de passe',
      NewService: 'Nouveau service',
      NewStaff: 'Nouveau personnel',
      Next: 'Suivant',
      No: 'Non',
      NoAppointments: 'Pas encore de rendez-vous',
      NoNotifications: 'Pas encore de notifications',
      NoOptions: 'Pas d\'options',
      Note: 'Note',
      Notes: 'Notes de rendez-vous',
      Notification: 'Notification',
      NotificationCanceled: 'Notifier les clients lorsque leur rendez-vous est annulé',
      NotificationConfirmed: 'Notifier les clients lorsque leur rendez-vous est confirmé',
      NotificationRescheduled: 'Notifier les clients lorsque leur rendez-vous est reporté',
      NotRegistered: 'Non inscrit',
      Off: 'Éteint',
      On: 'Allumé',
      Open: 'Ouvert',
      Order: 'Ordre de planification',
      Password: 'Mot de passe',
      PasswordRequired: 'Le mot de passe est obligatoire.',
      PasswordSettings: 'Changer le mot de passe',
      PastDate: 'Autoriser la création de rendez-vous passés',
      Pending: 'En attente',
      Permalink: 'Lien permanent',
      PersonalInformation: 'Informations personnelles',
      Phone: 'Téléphone',
      PhoneCode: 'Code pays',
      PhoneNumber: 'Numéro de téléphone',
      PhoneNumberOptional: 'Numéro de téléphone (facultatif)',
      Position: 'Poste',
      Price: 'Prix',
      PriceType: 'Type de prix',
      Pricing: 'Tarification',
      Profession: 'Profession',
      ProfessionOptional: 'Profession (facultatif)',
      Range: 'Plage',
      ReadAll: 'Tout lire',
      Registered: 'Inscrit',
      RemindCustomer: 'Rappeler au client',
      Reminder: 'Rappels',
      Resend: 'Renvoyer',
      ResetPassword: 'Réinitialiser le mot de passe',
      RowsPerPage: 'Lignes par page',
      Sat: 'Sam',
      Saturday: 'Samedi',
      Save: 'Enregistrer',
      SaveDraft: 'Sauvegarder le brouillon',
      SaveDraftText: 'Souhaitez-vous enregistrer ce rendez-vous en tant que brouillon?',
      SearchByCustomerOrStaff: 'Recherche par client ou personnel',
      SearchCustomer: 'Recherche par nom de client',
      SearchService: 'Recherche par nom de service',
      SearchStaff: 'Recherche par nom de personnel',
      SelectedDayNotWork: 'Le jour sélectionné n\'est pas un jour de travail',
      Send: 'Envoyer',
      Service: 'Service',
      'Service Name': 'Nom du service',
      ServiceBased: 'Basé sur le service',
      ServiceCategory: 'Catégorie de service',
      ServiceDeleted: 'Service supprimé avec succès.',
      ServiceDeletionText: 'Ce service ne sera pas disponible à la réservation, et s\'il y a des rendez-vous, ils seront annulés.',
      ServiceDuration: 'Durée du service',
      ServiceNotFound: 'Service non trouvé.',
      ServiceProvider: 'Fournisseur de service',
      Services: 'Services',
      Settings: 'Paramètres',
      ShortPassword: 'Le mot de passe est trop court.',
      ShowLess: 'Montrer moins',
      ShowMore: 'Montrer plus',
      ShowOnWidget: 'Afficher sur le widget',
      ShowOnWidgetTooltipText: 'Le service sera disponible pour la réservation en ligne.',
      SignIn: 'Se connecter',
      SlotDuration: 'Durée de la plage horaire du calendrier',
      SomethingWrong: 'Quelque chose s\'est mal passé.',
      Source: 'Source',
      SpecificDayAdded: 'Jour(s) de travail spécifique ajouté(s) avec succès.',
      SpecificDayDeleted: 'Jour(s) de travail spécifique supprimé(s) avec succès.',
      SpecificDaysTitle: 'Jours de travail spécifiques',
      SpecificDayUpdated: 'Jour(s) de travail spécifique mis à jour avec succès.',
      SpecificWorkDayDeleted: 'Jour(s) de travail spécifique supprimé(s) avec succès.',
      Staff: 'Personnel',
      'Staff Name': 'Nom du personnel',
      StaffBased: 'Basé sur le personnel',
      StaffDetails: 'Détails du personnel',
      StaffNotFind: 'Personnel introuvable !',
      StaffPreview: 'Aperçu du personnel',
      Staffs: 'Personnels',
      StartDate: 'Date de début',
      StartTime: 'Heure de début',
      StartTimeEarlier: 'L\'heure de début doit être antérieure à l\'heure de fin.',
      StartTimeRequired: 'L\'heure de début est obligatoire.',
      State: 'État',
      Statistics: 'Statistiques',
      Status: 'Statut',
      Sun: 'Dim',
      Sunday: 'Dimanche',
      TableHeader: {
        Actions: 'Options',
        BreakTime: 'Pause',
        Date: 'Dates',
        Description: 'Description',
        Staff: 'Personnel',
        Time: 'Heures de travail',
      },
      ThankYou: 'Envoyez un message de remerciement au client',
      ThisMonth: 'Ce mois-ci',
      ThisServiceHas: 'Ce service a ',
      ThisWeek: 'Cette semaine',
      Thu: 'Jeu',
      Thursday: 'Jeudi',
      Time: 'Temps',
      TimeZone: 'Fuseau horaire',
      Tin: 'NIF',
      Title: 'Titre',
      To: 'À',
      Today: 'Aujourd\'hui',
      Tomorrow: 'Demain',
      Total: 'Total',
      TotalPayments: 'Revenu',
      Tue: 'Mar',
      Tuesday: 'Mardi',
      Upcoming: 'À venir',
      UpcomingAppointments: 'Rendez-vous à venir',
      UpcomingAppointmentServiceModalText: ' rendez-vous(s) à venir.',
      upcomingAppointmentWith: 'Les rendez-vous à venir avec',
      Update: 'Mettre à jour',
      UpdatedSuccess: 'Mise à jour réussie.',
      Uppercase: 'majuscule',
      UserNotBreak: 'L\'utilisateur est en pause aux heures sélectionnées',
      UserNotWork: 'L\'utilisateur ne travaille pas aux heures sélectionnées',
      ValidAppointment: 'Champs de rendez-vous valides',
      VerificationCode: 'Code de vérification',
      Visits: 'Visites',
      WalkIn: 'Marche In',
      WalkInDesc: 'Choisissez "Marche In" si vous voulez sauter le client.',
      WebSite: 'URL du site Web de l\'entreprise',
      Wed: 'Mer',
      Wednesday: 'Mercredi',
      Weekly: 'Hebdomadaire',
      WeWillSendVerificationCode: 'Nous vous enverrons le code de vérification à 6 chiffres.',
      willBeCancelled: ' sera annulé.',
      WithDrawInvitation: 'Annuler l\'invitation',
      'Work Schedule': 'Horaire de travail',
      WorkDateEndTimeRequired: 'L\'heure de fin de la journée de travail est requise.',
      WorkDateStartTimeRequired: 'L\'heure de début de la journée de travail est requise.',
      WorkdayEnd: 'Fin de la journée de travail',
      WorkdayStart: 'Début de la journée de travail',
      WorkingScheduleTab: {
        Company: 'Entreprise',
        General: 'Général',
        Staff: 'Personnel',
      },
      WorkSchedule: 'Horaire de travail',
      Yes: 'Oui',
      ZipCode: 'Code postal',
    },
  },
};

export default resources;
