import { handleActions } from 'redux-actions';
import {
  getHistoryRequest, getHistorySuccess,
  getHistoryError,
} from './actions';

const initialState = {
  isGetHistorySuccess: false,
  isGetHistoryError: false,
  allHistory: [],
};

const reducer = handleActions({
  [getHistoryRequest]: (state) => ({
    ...state,
    isGetHistorySuccess: false,
    isGetHistoryError: false,
    allHistory: [],
  }),
  [getHistorySuccess]: (state, { payload }) => ({
    ...state,
    isGetHistorySuccess: true,
    allHistory: payload.data,
  }),
  [getHistoryError]: (state) => ({
    ...state,
    isGetHistoryError: true,
    isGetHistorySuccess: false,
    allHistory: [],
  }),
}, initialState);

export default reducer;
