import React, {
  useState,
  useEffect,
  useRef,
} from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import moment from 'moment';
import i18n from 'i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  AppBar,
  Dialog,
  DialogContent,
  DialogTitle,
  Accordion,
  AccordionDetails,
  DialogActions,
  Box,
  Toolbar,
  AccordionSummary,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import messagesSocketModule from 'Modules/messagesSocket';
import {
  getMessagesRequest,
  makeMessagesReadRequest,
  storeMessageRequest,
  updateMessageRequest,
  deleteMessageRequest,
} from 'redux/message/actions';
import '../../../../../style/messages.scss';
import '../../../../../style/general.scss';
import SnackbarToast from 'Modules/SnackbarToast';
import {
  LeftContent,
  ChatContent,
  SubmitMessage,
  TopContent,
  RightContent,
} from '../../../../../Components/Messages';
import Index from '../../../../../Components/Messages/MissingMessages';
import DeleteButton from '../../../../../MaterialComponents/deleteButton';
import MessagesLoading from '../../../../../Components/Loading/messagesLoading';
import usePrevious from '../../../../../CustomHooks/usePrevious';

function Messages(props) {
  const {
    isGetMessagesSuccess,
    isGetMessagesError,
    getMessagesErrorMessage,
    getMessages,
    isStoreMessageSuccess,
    storeMessageErrorMessage,
    isStoreMessageError,
    storedMessage,
    isMakeMessagesReadSuccess,
    isMakeMessagesReadError,
    messagesReadAppointmentId,
    isUpdateMessageSuccess,
    isUpdateMessageError,
    updatedMessage,
    updateMessageErrorMessage,
    isDeleteMessageSuccess,
    isDeleteMessageError,
    deleteMessageErrorMessage,
    userAccount,
    children,
  } = props;

  const [socket, setSocket] = useState(null);
  const [lastDeleteMessageId, setLastDeleteMessageId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [alert, setAlert] = useState(null);
  const [emptySendMessage, setEmptySendMessage] = useState(true);
  const [selectedAppointment, setSelectedAppointment] = useState({
    service: {},
    company: {
      industry: {},
    },
    staff: {},
    messages: [],
    individual: {},
  });
  const [newMessage, setNewMessage] = useState({
    from_id: '',
    to_id: '',
    text: '',
    appointment_id: '',
  });
  // const [date, setDate] = useState(new Date());
  const [expanded, setExpanded] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const limit = 10;

  // Get some props previous values
  const prevIsGetMessagesSuccess = usePrevious(isGetMessagesSuccess);
  const prevIsGetMessagesError = usePrevious(isGetMessagesError);
  const prevIsStoreMessageSuccess = usePrevious(isStoreMessageSuccess);
  const prevIsStoreMessageError = usePrevious(isStoreMessageError);
  const prevIsMakeMessagesReadSuccess = usePrevious(isMakeMessagesReadSuccess);
  const prevIsUpdateMessageSuccess = usePrevious(isUpdateMessageSuccess);
  const prevIsUpdateMessageError = usePrevious(isUpdateMessageError);
  const prevIsDeleteMessageSuccess = usePrevious(isDeleteMessageSuccess);
  const prevIsDeleteMessageError = usePrevious(isDeleteMessageError);

  // Refs
  const appointmentsRef = useRef(appointments);
  const selectedAppointmentRef = useRef(selectedAppointment);

  // Change current ref after state change
  useEffect(() => {
    appointmentsRef.current = appointments;
  }, [appointments]);
  useEffect(() => {
    selectedAppointmentRef.current = selectedAppointment;
  }, [selectedAppointment]);

  // handle get Appointment Index on mount
  useEffect(() => {
    setLoading(true);
    const socket = io(process.env.REACT_APP_NODEJS_API_URL);
    const page = 0;
    getMessages({
      limit,
      page,
    });
    setSocket(socket);
    // Message Receive
    socket.on('receiveMessage', (message) => handleMessageReceive(message, appointmentsRef.current, selectedAppointmentRef.current));
    // Message Update
    socket.on('messageUpdated', (message) => handleMessageUpdate(message, appointmentsRef.current, selectedAppointmentRef.current));
    // Message Delete
    socket.on('messageDeleted', ({ messageId, appointmentId }) => handleMessageDeleted(messageId, appointmentId, appointmentsRef.current, selectedAppointmentRef.current));
    // Socket leaveConnection
    return () => {
      socket.emit('leaveConnection');
      socket.off();
    };
  }, []);
  // Handle Get Appointment Index Success
  useEffect(() => {
    if (prevIsGetMessagesSuccess === false && isGetMessagesSuccess) {
      const { appointments } = props;
      const selectedAppointment = appointments[0];
      setAppointments([...appointments]);
      setSelectedAppointment({ ...selectedAppointment });
      // Join socket for each appointment
      const socketJoin = () => {
        appointments.map((item) => {
          socket.emit('join', {
            permalink: item.permalink,
          });
        });
      };
      setLoading(false);
      socketJoin();
      scrollChatContent();
    }
  }, [isGetMessagesSuccess]);
  // Handle Get Appointment Index Error
  useEffect(() => {
    if (prevIsGetMessagesError === false && isGetMessagesError) {
      setLoading(false);
      snackBarAlert(true, getMessagesErrorMessage, 'error');
    }
  }, [isGetMessagesError]);
  // Handle Store Message Success
  useEffect(() => {
    if (prevIsStoreMessageSuccess === false && isStoreMessageSuccess && storedMessage.id) {
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === storedMessage.appointment_id);
      appointmentsCopy[appointmentIndex].messages = [
        ...appointmentsCopy[appointmentIndex].messages,
        storedMessage,
      ];
      setAppointments(appointmentsCopy);
      setNewMessage({
        from_id: '',
        to_id: '',
        text: '',
        appointment_id: '',
      });
      setSelectedAppointment(appointmentsCopy[appointmentIndex]);
      socket.emit('sendMessage', {
        message: storedMessage,
        permalink: appointmentsCopy[appointmentIndex].permalink,
      });
      setEmptySendMessage(true);
      scrollChatContent();
    }
  }, [isStoreMessageSuccess]);
  // Handle Store Message Error
  useEffect(() => {
    if (prevIsStoreMessageError === false && isStoreMessageError) {
      snackBarAlert(true, storeMessageErrorMessage, 'error');
    }
  }, [isStoreMessageError]);
  // Handle Make Index Read Success
  useEffect(() => {
    if (prevIsMakeMessagesReadSuccess === false && isMakeMessagesReadSuccess && !isMakeMessagesReadError) {
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === parseInt(messagesReadAppointmentId));
      if (appointmentIndex !== -1) {
        appointmentsCopy[appointmentIndex].messages = appointmentsCopy[appointmentIndex].messages.map((item) => {
          if (appointmentsCopy[appointmentIndex].company_id ? (item.to_id === userAccount.id || item.to_id === appointmentsCopy[appointmentIndex].staff.id)
            : (item.to_id === appointmentsCopy[appointmentIndex].individual_user_industry.user_id)) {
            return {
              ...item,
              seen_at: {
                ...item.seen_at,
                individual: moment().format('Y-MM-DD HH:mm'),
              },
            };
          } else {
            return item;
          }
        });
        setAppointments([...appointmentsCopy]);
      }
    }
  }, [isMakeMessagesReadSuccess]);
  // Handle Update Message Success
  useEffect(() => {
    if (prevIsUpdateMessageSuccess === false && isUpdateMessageSuccess && updatedMessage.id) {
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === updatedMessage.appointment_id);
      const messageIndex = appointmentsCopy[appointmentIndex].messages.findIndex((item) => item.id === updatedMessage.id);
      if (messageIndex !== -1) {
        appointmentsCopy[appointmentIndex].messages[messageIndex] = updatedMessage;
      }
      setAppointments([...appointmentsCopy]);
      setSelectedAppointment({ ...selectedAppointment, selectedAppointment: appointmentsCopy[appointmentIndex] });
      setNewMessage({
        from_id: '',
        to_id: '',
        text: '',
        appointment_id: '',
      });
      socket.emit('updateMessage', {
        message: updatedMessage,
        permalink: appointmentsCopy[appointmentIndex].permalink,
      });
    }
  }, [isUpdateMessageSuccess]);
  // Handle Update Message Error
  useEffect(() => {
    if (prevIsUpdateMessageError === false && isUpdateMessageError) {
      snackBarAlert(true, updateMessageErrorMessage, 'error');
    }
  }, [isUpdateMessageError]);
  // Handle Delete Message Success
  useEffect(() => {
    if (prevIsDeleteMessageSuccess === false && isDeleteMessageSuccess) {
      const appointmentsCopy = [...appointments];
      const appointmentIndex = appointmentsCopy.findIndex((item) => item.id === selectedAppointment.id);
      const messageIndex = appointmentsCopy[appointmentIndex].messages.findIndex((item) => item.id === lastDeleteMessageId);
      if (messageIndex !== -1) {
        appointmentsCopy[appointmentIndex].messages.splice(messageIndex, 1);
      }
      const defaultMessageObject = {
        from_id: '',
        to_id: '',
        text: '',
        appointment_id: '',
      };
      const messageItem = newMessage.id && newMessage.id === lastDeleteMessageId ? { ...defaultMessageObject } : { ...newMessage };
      setAppointments([...appointmentsCopy]);
      setSelectedAppointment(appointmentsCopy[appointmentIndex]);
      setNewMessage(messageItem);
      socket.emit('deleteMessage', {
        permalink: appointmentsCopy[appointmentIndex].permalink,
        messageId: lastDeleteMessageId,
        appointmentId: appointmentsCopy[appointmentIndex].id,
      });
      setLastDeleteMessageId(null);
    }
  }, [isDeleteMessageSuccess]);
  // Handle Delete Message Error
  useEffect(() => {
    if (prevIsDeleteMessageError === false && isDeleteMessageError) {
      snackBarAlert(true, deleteMessageErrorMessage, 'error');
      setLastDeleteMessageId(null);
    }
  }, [isDeleteMessageError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  // Socket messages handlers
  const handleMessageReceive = (message, appointments, selectedAppointment) => {
    const data = messagesSocketModule.receiveMessage({
      appointments,
      selectedAppointment,
      message,
    });
    setSelectedAppointment({ ...data.selectedAppointment });
    setAppointments([...data.appointments]);
    scrollChatContent();
  };
  const handleMessageUpdate = (message, appointments, selectedAppointment) => {
    const data = messagesSocketModule.updateMessage({
      appointments,
      selectedAppointment,
      message,
    });
    setSelectedAppointment({ ...data.selectedAppointment });
    setAppointments([...data.appointments]);
  };
  const handleMessageDeleted = (messageId, appointmentId, appointments, selectedAppointment) => {
    const data = messagesSocketModule.deleteMessage({
      appointments,
      selectedAppointment,
      messageId,
      appointmentId,
    });
    setSelectedAppointment({ ...data.selectedAppointment });
    setAppointments([...data.appointments]);
  };

  const handleChooseAppointmentMessage = (id) => {
    const previousAppointmentId = selectedAppointment.id;
    const appointmentItem = appointments.find((item) => item.id === id);
    const appointmentsCopy = [...appointments];
    const prevAppointmentIndex = appointmentsCopy.findIndex((item) => item.id === parseInt(previousAppointmentId));
    appointmentsCopy[prevAppointmentIndex].newMessage = newMessage;
    const currentMessage = appointmentItem.newMessage || {
      from_id: '',
      to_id: '',
      text: '',
      appointment_id: '',
    };
    setSelectedAppointment(appointmentItem);
    setAppointments(appointmentsCopy);
    setNewMessage(currentMessage);
    scrollChatContent();
    makeAppointmentMessagesRead();
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleMessageTextChange = (event) => {
    const { value } = event.target;
    setNewMessage({
      ...newMessage,
      text: value,
    });

    if (value === '') {
      setEmptySendMessage(true);
    } else {
      setEmptySendMessage(false);
    }
  };

  const handleSendMessage = () => {
    const { userAccount, storeMessage } = props;
    if (newMessage.text && (newMessage.text.trim() !== '')) {
      storeMessage({
        ...newMessage,
        from_id: userAccount.id,
        to_id: selectedAppointment.customer_id,
        appointment_id: selectedAppointment.id,
      });
    } else {
      setEmptySendMessage(true);
    }
  };

  const scrollChatContent = () => {
    setTimeout(() => {
      const chantContentObj = document.getElementById('messages-content');
      if (chantContentObj) {
        chantContentObj.scrollTop = chantContentObj.scrollHeight;
      }
    }, 10);
  };

  const makeAppointmentMessagesRead = () => {
    const { userAccount } = props;
    const roleName = userAccount.activeRole.name.toLowerCase();

    const atLeastOneUnread = selectedAppointment.messages.find((item) => item.seen_at[roleName] === 'null'
        && (item.to_id === userAccount.id));

    if (atLeastOneUnread && atLeastOneUnread.id) {
      const { makeMessagesRead } = props;
      makeMessagesRead({ id: selectedAppointment.id });
    }
  };

  const handleEditMessage = (event, id) => {
    event.preventDefault();
    const messageItem = selectedAppointment.messages.find((item) => item.id === id);
    setNewMessage(messageItem);
  };

  const handleUpdateMessage = () => {
    const { updateMessage } = props;
    updateMessage(newMessage);
  };

  const showDeleteMessagePopup = (event, id) => {
    event.preventDefault();
    const alert = (
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={() => ({})}
        onCancel={() => setAlert(null)}
      >
        <DialogTitle
          id="customized-dialog-title"
          onCancel={() => setAlert(null)}
        >
          <span className="alert-title">
            <span>{i18n.t('ConfirmDeletion')}</span>
            <span>
              <IconButton
                aria-label="close"
                className="close-btn"
                onClick={() => setAlert(null)}
              >
                <CloseIcon />
              </IconButton>
            </span>
          </span>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {i18n.t('AreYouSureDelete')} message ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAlert(null)}
            size="small"
          >
            {i18n.t('Cancel')}
          </Button>
          <DeleteButton
            variant="contained"
            size="small"
            color="primary"
            onClick={() => handleDeleteMessage(id)}
          >
            {i18n.t('Delete')}
          </DeleteButton>
        </DialogActions>
      </Dialog>
    );
    setAlert(alert);
  };

  const handleDeleteMessage = (id) => {
    const { deleteMessage } = props;
    setLastDeleteMessageId(id);
    deleteMessage({ id });
    setAlert(null);
  };

  return !loading ? (
    <div className="menu-item messages">
      <div className="general-content">
        <div className="panel">
          <div className="main-content messages">
            <Box>
              <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
                <Toolbar>
                  <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
                    <Typography variant="h6">
                      <Box display="flex" alignItems="center" height="100%">
                        Messages
                      </Box>
                    </Typography>
                    <Box display="flex">
                      <div>
                        {children}
                      </div>
                    </Box>
                  </Box>
                </Toolbar>
              </AppBar>
              { appointments.length > 0 ? (
                <Box className="main-chat-content accordion">
                  <Accordion expanded={expanded === 'panel1'} className="accordion-first-item" onChange={handleChange('panel1')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                    >
                      <Typography>Users</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <div className="left-content">
                          <LeftContent
                            appointments={appointments}
                            selectedAppointment={selectedAppointment}
                            handleChooseAppointmentMessage={handleChooseAppointmentMessage}
                            userAccount={userAccount}
                          />
                        </div>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} className="accordion-mid-item" onChange={handleChange('panel2')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2bh-content"
                    >
                      <Typography>Chats</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="content-chat">
                        <TopContent selectedAppointment={selectedAppointment} userRole="individual" />
                        <div className="chat" onClick={() => makeAppointmentMessagesRead()}>
                          <ChatContent
                            selectedAppointment={selectedAppointment}
                            userAccount={userAccount}
                            handleEditMessage={(event, id) => handleEditMessage(event, id)}
                            showDeleteMessagePopup={(event, id) => showDeleteMessagePopup(event, id)}
                          />
                          <SubmitMessage
                            newMessage={newMessage}
                            handleSendMessage={handleSendMessage}
                            handleUpdateMessage={handleUpdateMessage}
                            handleMessageTextChange={handleMessageTextChange}
                            onClick={() => makeAppointmentMessagesRead()}
                            emptySendMessage={emptySendMessage}
                          />
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel3'} className="accordion-last-item" onChange={handleChange('panel3')}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel3bh-content"
                    >
                      <Typography>{i18n.t('Details')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <RightContent selectedAppointment={selectedAppointment} />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              ) : (
                <Index />
              )}
            </Box>
          </div>
        </div>
      </div>
      <SnackbarToast
        message={snackbarMessage}
        type={snackbarType}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
      />
      { alert }
    </div>
  ) : <MessagesLoading />;
}

Messages.propTypes = {
  // Account
  userAccount: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  // Get Index Props
  getMessages: PropTypes.func.isRequired,
  isGetMessagesSuccess: PropTypes.bool.isRequired,
  isGetMessagesError: PropTypes.bool.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  appointments: PropTypes.array.isRequired,
  getMessagesErrorMessage: PropTypes.string.isRequired,
  // Store Message
  storeMessage: PropTypes.func.isRequired,
  isStoreMessageSuccess: PropTypes.bool.isRequired,
  storeMessageErrorMessage: PropTypes.string.isRequired,
  isStoreMessageError: PropTypes.bool.isRequired,
  storedMessage: PropTypes.object.isRequired,
  // Make Index Read
  makeMessagesRead: PropTypes.func.isRequired,
  isMakeMessagesReadSuccess: PropTypes.bool.isRequired,
  isMakeMessagesReadError: PropTypes.bool.isRequired,
  messagesReadAppointmentId: PropTypes.number.isRequired,
  // Update Message Props
  updateMessage: PropTypes.func.isRequired,
  isUpdateMessageSuccess: PropTypes.bool.isRequired,
  isUpdateMessageError: PropTypes.bool.isRequired,
  updatedMessage: PropTypes.object.isRequired,
  updateMessageErrorMessage: PropTypes.string.isRequired,
  // Delete Message Props
  deleteMessage: PropTypes.func.isRequired,
  isDeleteMessageSuccess: PropTypes.bool.isRequired,
  isDeleteMessageError: PropTypes.bool.isRequired,
  deleteMessageErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // User Account
  userAccount: state.account.userAccount,
  // Get Index
  isGetMessagesSuccess: state.message.isGetMessagesSuccess,
  isGetMessagesError: state.message.isGetMessagesError,
  appointments: state.message.appointments,
  allCount: state.message.allCount,
  getMessagesErrorMessage: state.message.getMessagesErrorMessage,
  // Store Message
  isStoreMessageSuccess: state.message.isStoreMessageSuccess,
  isStoreMessageError: state.message.isStoreMessageError,
  storedMessage: state.message.storedMessage,
  storeMessageErrorMessage: state.message.storeMessageErrorMessage,
  // Make Index Read
  isMakeMessagesReadSuccess: state.message.isMakeMessagesReadSuccess,
  isMakeMessagesReadError: state.message.isMakeMessagesReadError,
  messagesReadAppointmentId: state.message.messagesReadAppointmentId,
  // Update Message
  isUpdateMessageSuccess: state.message.isUpdateMessageSuccess,
  isUpdateMessageError: state.message.isUpdateMessageError,
  updatedMessage: state.message.updatedMessage,
  updateMessageErrorMessage: state.message.updateMessageErrorMessage,
  // Delete Message
  isDeleteMessageSuccess: state.message.isDeleteMessageSuccess,
  isDeleteMessageError: state.message.isDeleteMessageError,
  deleteMessageErrorMessage: state.message.deleteMessageErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    getMessages: (data) => dispatch(getMessagesRequest(data)),
    storeMessage: (data) => dispatch(storeMessageRequest(data)),
    makeMessagesRead: (data) => dispatch(makeMessagesReadRequest(data)),
    updateMessage: (data) => dispatch(updateMessageRequest(data)),
    deleteMessage: (data) => dispatch(deleteMessageRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
