import PropTypes from 'prop-types';
import React from 'react';
import { Check } from '@material-ui/icons';
import clsx from 'clsx';
import { useQontoStepIconStyles } from '../stepStyles';

export default function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  /**
     * Whether this step is active.
     */
  active: PropTypes.bool.isRequired,
  /**
     * Mark the step as completed. Is passed to child components.
     */
  completed: PropTypes.bool.isRequired,
};
