import { handleActions } from 'redux-actions';
import {
  getIndustriesRequest,
  getIndustriesSuccess,
  getIndustriesError,
} from './actions';

const initialState = {
  isGetIndustriesSuccess: false,
  isGetIndustriesError: false,
  industries: [],
};

const reducer = handleActions({
  [getIndustriesRequest]: (state) => ({
    ...state,
    isGetIndustriesSuccess: false,
    industries: [],
  }),
  [getIndustriesSuccess]: (state, { payload }) => ({
    ...state,
    isGetIndustriesSuccess: true,
    industries: payload.data.industries,
  }),
  [getIndustriesError]: (state) => ({
    ...state,
    isGetIndustriesError: true,
  }),
}, initialState);

export default reducer;
