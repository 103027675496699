import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  AppBar,
  Card,
  ListItemText,
  Menu,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Box,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  Button,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import BusinessIcon from '@material-ui/icons/Business';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import Avatar from '@material-ui/core/Avatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import i18n from 'i18next';
import usePrevious from '../../../../../CustomHooks/usePrevious';
import {
  deleteCustomerRequest,
  deleteInvitationRequest,
  getAllCompanyCustomersRequest,
  getAllCustomerInvitationsRequest,
} from '../../../../../redux/customer/actions';
import CustomerProfileModal from '../../../../../Modals/Staff/Customers/customerProfileModal';
import InviteCustomer from './details/inviteCustomer';
import countryCodeOptions from '../../../../../Modules/countryCodeOptions';
import noCustomers from '../../../../../assets/img/no-customer.svg';
import BoxLoading from '../../../../../Components/Loading/BoxLoading';
import DeleteButton from '../../../../../MaterialComponents/deleteButton';
import ConfirmDeleteInvitationModal from '../../../../../Modals/Customers/ConfirmDeleteInvitation';
import LightTooltip from '../../../../../MaterialComponents/lightTooltip';
import firstLetter from '../../../../../Modules/getFirstLetter';
import SnackbarToast from '../../../../../Modules/SnackbarToast';

function Customers(props) {
  const {
    allCountries,
    getAllCompanyCustomers,
    isGetAllCustomersSuccess,
    isGetAllCustomersError,
    allCompanyCustomers,
    getAllCustomerInvitations,
    isGetAllCustomerInvitationsSuccess,
    isGetAllCustomerInvitationsError,
    allCompaniesCustomerInvitations,
    deleteCustomer,
    isDeleteCustomerSuccess,
    isDeleteCustomerError,
    deleteCustomerErrorMessage,
    deleteInvitation,
    isDeleteInvitationSuccess,
    isDeleteInvitationError,
    deleteInvitationErrorMessage,
  } = props;

  // State
  const prevIsDeleteCustomerSuccess = usePrevious(isDeleteCustomerSuccess);
  const prevIsDeleteCustomerError = usePrevious(isDeleteCustomerError);
  const prevIsDeleteInvitationSuccess = usePrevious(isDeleteInvitationSuccess);
  const prevIsDeleteInvitationError = usePrevious(isDeleteInvitationError);

  const [loading, setLoading] = useState(true);
  const [isInviteCustomerModalShown, setIsInviteCustomerModalShown] = useState(false);
  const [isSelectCompaniesModalShown, setIsSelectCompaniesModalShown] = useState(false);
  const [modal, setModal] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState({});
  const [customers, setCustomers] = useState([]);
  const [codeOptions, setCodeOptions] = useState([]);
  const [companiesCustomerInvitations, setCompaniesCustomerInvitations] = useState([]);
  const [deletedCustomerId, setDeletedCustomerId] = useState(null);
  const [invitationId, setInvitationId] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [companiesToSelect, setCompaniesToSelect] = useState([]);
  const [selectedCompaniesToDeleteCustomer, setSelectedCompaniesToDeleteCustomer] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDeleteInvitationModal, setIsDeleteInvitationModal] = useState(false);
  const [, setAlert] = useState(null);
  const open = Boolean(anchorEl);

  // const [invitationOldCompanyId, setInvitationOldCompanyId] = useState(null);
  const [customerToInvite, setCustomerToInvite] = useState({
    first_name: '',
    last_name: '',
    email: '',
    company: { value: null },
    phone_code: { value: null },
    phone_number: null,
    invitationEmailIsTheSame: false,
  });
  const [invitationToDelete, setInvitationToDelete] = useState({
    id: null,
    companyId: null,
  });
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarType, setSnackbarType] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');

  // Perform, when function gets mounted
  useEffect(() => {
    setCodeOptions(countryCodeOptions.getCodeOptions(allCountries));
    getAllCompanyCustomers();
    getAllCustomerInvitations();
  }, []);
  // Handle get customers success
  useEffect(() => {
    if (isGetAllCustomersSuccess) {
      setCustomers(allCompanyCustomers);
      setLoading(false);
    }
  }, [isGetAllCustomersSuccess]);
  // Handle get customers error
  useEffect(() => {
    if (isGetAllCustomersError) {
      snackBarAlert(true, i18n.t('InvalidData'), 'error');
      setLoading(false);
    }
  }, [isGetAllCustomersError]);
  // Handle get companies customer invitations success
  useEffect(() => {
    if (isGetAllCustomerInvitationsSuccess) {
      setCompaniesCustomerInvitations(allCompaniesCustomerInvitations);
      setLoading(false);
    }
  }, [isGetAllCustomerInvitationsSuccess]);

  // Handle get companies customer invitations error
  useEffect(() => {
    if (isGetAllCustomerInvitationsError) {
      setLoading(false);
      snackBarAlert(true, i18n.t('SomethingWrong'), 'error');
    }
  }, [isGetAllCustomerInvitationsError]);
  // Handle delete customer success
  useEffect(() => {
    if (prevIsDeleteCustomerSuccess === false && isDeleteCustomerSuccess) {
      const companyCustomersCopy = [...customers];
      const customerIndex = companyCustomersCopy.findIndex((item) => item.id === deletedCustomerId);
      let companies = JSON.parse(customers[customerIndex].companies);
      if (companiesToSelect.length === 1) {
        companyCustomersCopy.splice(customerIndex, 1);
        setCustomers(companyCustomersCopy);
      } else {
        companies = companies.filter((item) => !selectedCompaniesToDeleteCustomer.includes(item.id));
        companyCustomersCopy[customerIndex].companies = JSON.stringify(companies);
        setCustomers(companyCustomersCopy);
      }
      setCustomers(companyCustomersCopy);
      setSelectedCompaniesToDeleteCustomer([]);
      setDeletedCustomerId(null);
      setCompaniesToSelect([]);
      setIsSelectCompaniesModalShown(false);
      setLoading(false);
      setAnchorEl(null);
      snackBarAlert(true, i18n.t('CustomerDeleted'), 'success');
    }
  }, [isDeleteCustomerSuccess]);
  // Handle delete customer error
  useEffect(() => {
    if (prevIsDeleteCustomerError === false && isDeleteCustomerError) {
      setDeletedCustomerId(null);
      setLoading(true);
      snackBarAlert(true, deleteCustomerErrorMessage, 'error');
    }
  }, [isDeleteCustomerError]);
  // Handle delete customer invitation success
  useEffect(() => {
    if (prevIsDeleteInvitationSuccess === false && isDeleteInvitationSuccess) {
      const customerInvitationsCopy = [...companiesCustomerInvitations];
      const invitationIndex = customerInvitationsCopy.findIndex(
        (invitation) => invitation.id === invitationToDelete.id,
      );
      customerInvitationsCopy.splice(invitationIndex, 1);
      setCompaniesCustomerInvitations(customerInvitationsCopy);
      setIsDeleteInvitationModal(false);
      setInvitationToDelete({
        id: null,
        companyId: null,
      });
      snackBarAlert(true, i18n.t('InvitationDeleted', 'success'));
      setAnchorEl(null);
      setLoading(false);
    }
  }, [isDeleteInvitationSuccess]);

  // Handle delete customer invitation error
  useEffect(() => {
    if (prevIsDeleteInvitationError === false && isDeleteInvitationError) {
      setInvitationToDelete({
        id: null,
        companyId: null,
      });
      setLoading(false);
      setAnchorEl(null);
      snackBarAlert(true, deleteInvitationErrorMessage, 'error');
    }
  }, [isDeleteInvitationError]);

  const snackBarAlert = (snackOpen, SnackMessage, SnackType) => {
    setOpenSnackbar(snackOpen);
    setSnackbarMessage(SnackMessage);
    setSnackbarType(SnackType);
  };

  const handleOpenCustomerProfileModal = (e, customerId) => {
    e.preventDefault();
    const customer = customers.find((item) => parseInt(item.id) === parseInt((customerId)));
    setSelectedCustomer(customer);
    setModal(getCustomerProfileModal);
  };

  const getCustomerProfileModal = () => (
    <CustomerProfileModal
      closeCustomerProfileModal={closeCustomerProfileModal}
      customer={selectedCustomer}
    />
  );

  const closeCustomerProfileModal = () => {
    setModal(false);
  };

  const handleShowDeleteCustomerAlert = (e) => {
    const selectedCustomer = customers.find((item) => item.id === customerId);
    setCompaniesToSelect(JSON.parse(selectedCustomer.companies));
    e.preventDefault();
    setDeletedCustomerId(customerId);
    setIsSelectCompaniesModalShown(true);
  };

  const handleClickInvitation = (event, invitationId, companyId) => {
    setAnchorEl(event.currentTarget);
    setInvitationId(invitationId);
    setCompanyId(companyId);
  };

  const handleClickCustomer = (event, customerId) => {
    setAnchorEl(event.currentTarget);
    setCustomerId(customerId);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEditInvitation = (e, invitationId, companyId) => {
    e.preventDefault();
    const companyOptions = [];
    allCompanyCustomers.map((company) => companyOptions.push({
      value: company.id,
      label: company.name,
    }));
    const invitation = companiesCustomerInvitations.find((item) => item.id === invitationId);
    const selectedPhoneCode = codeOptions.find((item) => item.value === invitation.phone_code);
    const companyOption = companyOptions.find((item) => item.value === companyId);
    setCustomerToInvite({
      ...invitation,
      company: companyOption,
      phone_code: selectedPhoneCode,
    });
    setIsInviteCustomerModalShown(true);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChangeTab = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleShowDeleteInvitationModal = (e) => {
    e.preventDefault();
    setIsDeleteInvitationModal(true);
  };

  const handleConfirmDeleteInvitation = (e, invitationId, companyId) => {
    setLoading(true);
    setInvitationToDelete({
      id: invitationId,
      companyId,
    });
    deleteInvitation({
      id: invitationId,
    });
  };

  const handleSelectedCompany = (companyId) => {
    const selectedCompaniesCopy = [...selectedCompaniesToDeleteCustomer];
    if (selectedCompaniesCopy.includes(companyId)) {
      const index = selectedCompaniesCopy.findIndex((item) => item === companyId);
      selectedCompaniesCopy.splice(index, 1);
    } else {
      selectedCompaniesCopy.push(companyId);
    }
    setSelectedCompaniesToDeleteCustomer(selectedCompaniesCopy);
  };

  const handleConfirmCustomersDelete = () => {
    if (selectedCompaniesToDeleteCustomer.length === 0) {
      snackBarAlert(true, 'Please select company', 'error');
      return false;
    }
    setLoading(true);
    deleteCustomer({
      id: deletedCustomerId,
      companyIds: selectedCompaniesToDeleteCustomer,
    });
  };

  const handleCustomersDelete = () => {
    setLoading(true);
    deleteCustomer({
      id: deletedCustomerId,
      companyIds: [companiesToSelect[0].id],
    });
  };

  const closeSelectCompaniesModal = () => {
    setIsSelectCompaniesModalShown(false);
    setSelectedCompaniesToDeleteCustomer([]);
    setDeletedCustomerId(null);
    setCompaniesToSelect([]);
  };

  return !loading ? (
    <div className="menu-item" id="customers">
      <div className="general-content">
        <AppBar position="fixed" color="inherit" id="second-navbar" elevation={0}>
          <Toolbar>
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" className="tabs-navbar">
                <Box alignSelf="center" className="divider-title">
                  <Typography variant="h6">
                    <Box display="flex" alignItems="center" height="100%">
                        Customers
                    </Box>
                  </Typography>
                </Box>
                <Box alignSelf="center" className="tab-companies">
                  <AppBar position="static" color="inherit" elevation={0}>
                    <Tabs
                      value={activeTabIndex}
                      onChange={handleChangeTab}
                      aria-label="simple tabs example"
                      indicatorColor="primary"
                      textColor="secondary"
                    >
                      <Tab
                        label="Customers"
                        onClick={() => handleChangeTab(0)}
                        {...a11yProps(0)}
                      />
                      <Tab
                        label="Invitations"
                        onClick={() => handleChangeTab(1)}
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </AppBar>
                </Box>
              </Box>
            </Box>
            <Box display="flex">
              <Button
                size="small"
                variant="contained"
                onClick={() => setIsInviteCustomerModalShown(true)}
                color="primary"
              >
                  Invite
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box>
          <Box className="tab-customers">
            <div className="box-general page-main-content">
              { activeTabIndex === 0 && (
                <div className="main-content">
                  <Box display="flex" mt="0" className="customers-customers-line">
                    { customers.length > 0 ? customers && customers.map((customer, index) => (
                      <Box m="15px" maxWidth="220px" key={`${customer.id}-${index}`}>
                        <Card className="card-box-root">
                          <CardHeader
                            className="card-header-service"
                            action={(
                              <div>
                                <IconButton
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={(e) => handleClickCustomer(e, customer.id)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  id="long-menu"
                                  elevation={1}
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      width: '20ch',
                                    },
                                  }}
                                >
                                  <Link
                                    className="card-box-item"
                                    to="/"
                                    onClick={(e) => handleOpenCustomerProfileModal(e, customerId)}
                                  >
                                    <MenuItem>
                                      Profile
                                    </MenuItem>
                                  </Link>
                                  <Link
                                    to="/"
                                    className="card-box-item"
                                  >
                                    <MenuItem>
                                      Message
                                    </MenuItem>
                                  </Link>
                                  <Link
                                    className="card-box-item"
                                    data-cy="delete-customer"
                                    to="/"
                                    onClick={(e) => handleShowDeleteCustomerAlert(e, customerId)}
                                  >
                                    <MenuItem>
                                    Delete
                                    </MenuItem>
                                  </Link>
                                </Menu>
                              </div>
                                  )}
                            title={(
                              <LightTooltip title={`${customer.first_name} ${customer.last_name}`} placement="top-start">
                                <span>
                                  {`${customer.first_name} ${customer.last_name}`}
                                </span>
                              </LightTooltip>
                          )}
                          />
                          <Box>
                            <CardMedia className="img-header">
                              { customer && customer.avatar ? (
                                <Avatar className="card-box-avatar" alt="avatar" src={customer.avatar} />
                              ) : (
                                <Avatar variant="circle" className="card-box-img card-box-letters">
                                  { firstLetter(customer.first_name ? customer.first_name : '', customer.last_name ? customer.last_name : '')}
                                </Avatar>
                              )}
                            </CardMedia>
                            <CardContent className="content-card-box">
                              <List disablePadding>
                                <ListItem disableGutters dense>
                                  <ListItemText className="icon-card-box">
                                    <BusinessIcon fontSize="small" />
                                  </ListItemText>
                                  <ListItemText primary="Company:" className="details-card-box detail-name" />
                                  <ListItemText secondary={JSON.parse(customer.companies) ? JSON.parse(customer.companies).length : 0} className="details-card-box" />
                                </ListItem>
                              </List>
                            </CardContent>
                          </Box>
                        </Card>
                      </Box>
                    )) : (
                      <Box className="customers-page" mx="auto">
                        <Box>
                          <Box>
                            <img src={noCustomers} alt="noCustomer" />
                          </Box>
                          <Typography component="div">
                            <Box fontWeight="500" align="center">
                              <Typography variant="h6" color="secondary">
                              You don&apos;t have any customers yet
                              </Typography>
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </div>
              )}
              { activeTabIndex === 1 && (
                <div className="main-content">
                  <Box display="flex" mt="0" className="customers-customers-line">
                    { companiesCustomerInvitations.length > 0 ? companiesCustomerInvitations && companiesCustomerInvitations.map((invitation, index) => (
                      <Box m="15px" maxWidth="220px" key={`${invitation.id}-${index}`}>
                        <Card className="card-box-root">
                          <CardHeader
                            className="card-header-service"
                            action={(
                              <div>
                                <IconButton
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={(e) => handleClickInvitation(e, invitation.id, invitation.company_id)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                                <Menu
                                  id="long-menu"
                                  elevation={1}
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      width: '20ch',
                                    },
                                  }}
                                >
                                  <Link
                                    to="/"
                                    className="card-box-item"
                                    onClick={(e) => handleEditInvitation(e, invitationId, companyId)}
                                  >
                                    <MenuItem>
                                    Edit
                                    </MenuItem>
                                  </Link>
                                  <Link
                                    className="card-box-item"
                                    data-cy="delete-customer"
                                    to="/"
                                    onClick={(e) => handleShowDeleteInvitationModal(e, invitationId, companyId)}
                                  >
                                    <MenuItem>
                                    Delete
                                    </MenuItem>
                                  </Link>
                                </Menu>
                              </div>
                          )}
                            title={(
                              <LightTooltip title={`${invitation.first_name} ${invitation.last_name}`} placement="top-start">
                                <span>
                                  {`${invitation.first_name} ${invitation.last_name}`}
                                </span>
                              </LightTooltip>
                          )}
                          />
                          <Box>
                            <CardMedia className="img-header">
                              { invitation && invitation.avatar ? (
                                <Avatar className="card-box-avatar" alt="avatar" src={invitation.avatar} />
                              ) : (
                                <Avatar variant="circle" className="card-box-img card-box-letters">
                                  { firstLetter(invitation.first_name ? invitation.first_name : '', invitation.last_name ? invitation.last_name : '')}
                                </Avatar>
                              )}
                            </CardMedia>
                            <CardContent className="content-card-box">
                              <List disablePadding>
                                <ListItem disableGutters dense>
                                  <ListItemText className="icon-card-box">
                                    <BusinessIcon fontSize="small" />
                                  </ListItemText>
                                  <ListItemText primary="Company:" className="details-card-box detail-name" />
                                  {/* <ListItemText secondary={invitation.company.name} className="details-card-box" /> */}
                                </ListItem>
                                <ListItem disableGutters dense>
                                  <ListItemText className="icon-card-box">
                                    <MailOutlineIcon fontSize="small" />
                                  </ListItemText>
                                  <ListItemText primary="Email:" className="details-card-box detail-name" />
                                  <ListItemText secondary={invitation.email} className="details-card-box" />
                                </ListItem>
                              </List>
                            </CardContent>
                          </Box>
                        </Card>
                      </Box>
                    )) : (
                      <Box className="customers-page" mx="auto">
                        <Box>
                          <Box>
                            <img src={noCustomers} alt="noCustomer" />
                          </Box>
                          <Typography component="div">
                            <Box fontWeight="500" align="center">
                              <Typography variant="h6" color="secondary">
                              You don&apos;t have a invitations yet
                              </Typography>
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </div>
              )}
            </div>
          </Box>
        </Box>
        <InviteCustomer
          isInviteCustomerModalShown={isInviteCustomerModalShown}
          setIsInviteCustomerModalShown={setIsInviteCustomerModalShown}
          customerToInviteTest={customerToInvite}
          invitationOldCompanyId={null}
          // invitationOldCompanyId={invitationOldCompanyId}
          companiesCustomerInvitations={companiesCustomerInvitations}
          setCompaniesCustomerInvitations={setCompaniesCustomerInvitations}
          setAnchorEl={setAnchorEl}
        />
        <ConfirmDeleteInvitationModal
          open={isDeleteInvitationModal}
          onClose={() => setIsDeleteInvitationModal(true)}
          handleConfirmDeleteInvitation={(e) => handleConfirmDeleteInvitation(e, invitationId, companyId)}
          invitationId={invitationId}
          companyId={companyId}
        />
        <Dialog
          open={isSelectCompaniesModalShown}
          onClose={closeSelectCompaniesModal}
        >
          { companiesToSelect.length > 1 ? (
            <>
              <DialogTitle onClick={() => setAlert(null)}>
                <span className="alert-title">
                  <span>{i18n.t('ConfirmDeletion')}</span>
                  <span>
                    <IconButton
                      aria-label="close"
                      className="close-btn"
                      onClick={closeSelectCompaniesModal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </span>
                </span>
              </DialogTitle>
              <DialogContent dividers>
                <>
                  <Typography variant="body1" gutterBottom component="div">
                    <Box fontWeight="600">
                      Select the companies from which you want to delete the customer
                    </Box>
                  </Typography>
                  <List dense>
                    {companiesToSelect.map((company) => (
                      <ListItem key={`company${company.id}`} button>
                        <ListItemText>
                          {company.name}
                        </ListItemText>
                        <ListItemSecondaryAction>
                          <Checkbox
                            color="primary"
                            checked={selectedCompaniesToDeleteCustomer.includes(company.id)}
                            onChange={() => handleSelectedCompany(company.id)}
                            name="test"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            className="checkbox"
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </>
              </DialogContent>
            </>
          ) : (
            <>
              <DialogTitle id="customized-dialog-title" onClose={closeSelectCompaniesModal}>
                <span className="alert-title">
                  <span>{i18n.t('ConfirmDeletion')}</span>
                  <span>
                    <IconButton
                      aria-label="close"
                      className="close-btn"
                      onClick={closeSelectCompaniesModal}
                    >
                      <CloseIcon />
                    </IconButton>
                  </span>
                </span>
              </DialogTitle>
              <DialogContent dividers>
                <Typography gutterBottom>
                  {i18n.t('AreYouSureDelete')} customer ?
                </Typography>
              </DialogContent>
            </>
          ) }
          <DialogActions>
            <Button
              size="small"
              data-cy="cancel"
              type="button"
              onClick={closeSelectCompaniesModal}
            >
              {i18n.t('Cancel')}
            </Button>
            <DeleteButton
              size="small"
              variant="contained"
              data-cy="delete"
              type="button"
              onClick={companiesToSelect.length > 1 ? handleConfirmCustomersDelete : handleCustomersDelete}
              color="primary"
            >
              {i18n.t('Delete')}
            </DeleteButton>
          </DialogActions>
        </Dialog>
        { modal }
        <SnackbarToast
          message={snackbarMessage}
          type={snackbarType}
          open={openSnackbar}
          onClose={() => setOpenSnackbar(false)}
        />
      </div>
    </div>
  ) : <BoxLoading />;
}

Customers.propTypes = {
  // Get all customers
  getAllCompanyCustomers: PropTypes.func.isRequired,
  isGetAllCustomersSuccess: PropTypes.bool.isRequired,
  isGetAllCustomersError: PropTypes.bool.isRequired,
  allCompanyCustomers: PropTypes.array.isRequired,
  // Get all customer invitations
  getAllCustomerInvitations: PropTypes.func.isRequired,
  isGetAllCustomerInvitationsSuccess: PropTypes.bool.isRequired,
  isGetAllCustomerInvitationsError: PropTypes.bool.isRequired,
  allCompaniesCustomerInvitations: PropTypes.array.isRequired,
  // Get Countries
  allCountries: PropTypes.array.isRequired,
  // Delete Customer
  deleteCustomer: PropTypes.func.isRequired,
  isDeleteCustomerSuccess: PropTypes.bool.isRequired,
  isDeleteCustomerError: PropTypes.bool.isRequired,
  deleteCustomerErrorMessage: PropTypes.string.isRequired,
  // Delete invitation
  deleteInvitation: PropTypes.func.isRequired,
  isDeleteInvitationSuccess: PropTypes.bool.isRequired,
  isDeleteInvitationError: PropTypes.bool.isRequired,
  deleteInvitationErrorMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  // Get customers
  isGetAllCustomersSuccess: state.customers.isGetAllCustomersSuccess,
  isGetAllCustomersError: state.customers.isGetAllCustomersError,
  allCompanyCustomers: state.customers.allCompanyCustomers,
  // Get all customer invitations
  isGetAllCustomerInvitationsSuccess: state.customers.isGetAllCustomerInvitationsSuccess,
  isGetAllCustomerInvitationsError: state.customers.isGetAllCustomerInvitationsError,
  allCompaniesCustomerInvitations: state.customers.allCompaniesCustomerInvitations,
  // Get Countries
  allCountries: state.country.allCountries,
  // Delete customer
  isDeleteCustomerSuccess: state.customers.isDeleteCustomerSuccess,
  isDeleteCustomerError: state.customers.isDeleteCustomerError,
  deleteCustomerErrorMessage: state.customers.deleteCustomerErrorMessage,
  // Delete invitation
  isDeleteInvitationSuccess: state.customers.isDeleteInvitationSuccess,
  isDeleteInvitationError: state.customers.isDeleteInvitationError,
  deleteInvitationErrorMessage: state.customers.deleteInvitationErrorMessage,
});

function mapDispatchToProps(dispatch) {
  return {
    getAllCompanyCustomers: (data) => dispatch(getAllCompanyCustomersRequest(data)),
    deleteCustomer: (data) => dispatch(deleteCustomerRequest(data)),
    getAllCustomerInvitations: (data) => dispatch(getAllCustomerInvitationsRequest(data)),
    deleteInvitation: (data) => dispatch(deleteInvitationRequest(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Customers);
