import React from 'react';
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';

const ResendInvitation = (props) => {
  const {
    open,
    onClose,
    staffItem,
    lineProgress,
    handleResendInvitation,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="customized-dialog-title">
        <span className="alert-title">
          <span>
              Resend Invite
          </span>
          <span>
            <IconButton
              aria-label="close"
              className="close-btn"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </span>
        </span>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          spacing={2}
          direction="row"
          alignItems="center"
        >
          <Grid item xs={12}>
            <span className="invite-staff-text">
            Resend Invite to <span className="invite-staff-text-name">{staffItem.email}</span> ?
            </span>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          size="small"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={((e) => handleResendInvitation(e, staffItem))}
          loading={lineProgress}
        >
          {lineProgress && <CircularProgress color="white" size={20} />}
          {!lineProgress && 'Resend'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ResendInvitation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  staffItem: PropTypes.object.isRequired,
  lineProgress: PropTypes.bool.isRequired,
  handleResendInvitation: PropTypes.func.isRequired,
};

export default ResendInvitation;
