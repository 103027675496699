import React from 'react';
import {
  TextField, FormControl, FormHelperText,
} from '@material-ui/core';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import ErrorIcon from '@material-ui/icons/Error';

function AutocompleteSelectStaff(props) {
  const {
    items,
    label,
    error,
    limitTags,
    onSelectAll,
    onToggleOption,
    onClearOptions,
    getOptionLabel,
    selectedValues,
  } = props;

  const allSelected = items.length === selectedValues.length;
  const handleToggleSelectAll = () => {
    onSelectAll && onSelectAll(!allSelected);
  };

  const handleChange = (event, selectedOptions, reason) => {
    if (reason === 'select-option' || reason === 'remove-option') {
      if (selectedOptions.find((option) => option.value === 'select-all')) {
        handleToggleSelectAll();
      } else {
        onToggleOption && onToggleOption(selectedOptions);
      }
    } else if (reason === 'clear') {
      onClearOptions && onClearOptions();
    }
  };

  const inputRenderer = (params) => (
    <FormControl
      fullWidth
      size="small"
      variant="outlined"
      error={!!error}
    >
      <TextField {...params} error={!!error} variant="outlined" label={label} placeholder={!items.length ? 'No staff' : ''} />
      { error && (
      <div className="error-message-content">
        <ErrorIcon fontSize="small" color="error" />
        <FormHelperText>
          Staff is required.
        </FormHelperText>
      </div>
      )}
    </FormControl>
  );

  const getOptionSelected = (option, anotherOption) => option.value === anotherOption.value;
  const filter = createFilterOptions();

  return (
    <Autocomplete
      multiple
      size="small"
      limitTags={limitTags}
      options={items}
      value={selectedValues}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return filtered.length > 0 && [{ label: 'All', value: 'select-all' }, ...filtered];
      }}
      onChange={handleChange}
      renderInput={inputRenderer}
      renderOption={(option) => (
        <div
          className="input-menu"
          key={option.value}
          value={option.value}
        >
          {option.label} <span className="profession-label">{option.professions}</span>
        </div>
      )}
    />
  );
}

AutocompleteSelectStaff.propTypes = {
  items: PropTypes.array.isRequired,
  error: PropTypes.object.isRequired,
  selectedValues: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  limitTags: PropTypes.number.isRequired,
  onToggleOption: PropTypes.func.isRequired,
  onClearOptions: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
};

export default AutocompleteSelectStaff;
