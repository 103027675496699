import { createAction } from 'redux-actions';

export const updateWorkingHoursRequest = createAction('UPDATE_WORKING_HOURS_REQUEST');
export const updateWorkingHoursSuccess = createAction('UPDATE_WORKING_HOURS_SUCCESS');
export const updateWorkingHoursError = createAction('UPDATE_WORKING_HOURS_ERROR');
export const updateBreakingHoursRequest = createAction('UPDATE_BREAKING_HOURS_REQUEST');
export const updateBreakingHoursSuccess = createAction('UPDATE_BREAKING_HOURS_SUCCESS');
export const updateBreakingHoursError = createAction('UPDATE_BREAKING_HOURS_ERROR');
export const getDefaultWorkingHoursRequest = createAction('GET_DEFAULT_WORKING_HOURS_REQUEST');
export const getDefaultWorkingHoursSuccess = createAction('GET_DEFAULT_WORKING_HOURS_SUCCESS');
export const getDefaultWorkingHoursError = createAction('GET_DEFAULT_WORKING_HOURS_ERROR');
export const getDefaultBreakingHoursRequest = createAction('GET_DEFAULT_BREAKING_HOURS_REQUEST');
export const getDefaultBreakingHoursSuccess = createAction('GET_DEFAULT_BREAKING_HOURS_SUCCESS');
export const getDefaultBreakingHoursError = createAction('GET_DEFAULT_BREAKING_HOURS_ERROR');
