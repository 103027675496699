import React from 'react';
import PropTypes from 'prop-types';
import {
  MenuItem,
  Select,
  Box,
} from '@material-ui/core';
import hours from '../../../../../../../Modules/hours';

function WorkingHours(props) {
  const {
    workingHours,
  } = props;

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Box py="25px" px="30px" className="tab-item" id="tab-main-parent">
      <Box display="flex" m="0">
        <Box className="business-hours">
          <Box className="hours-content">
            { workingHours.map((workHour) => (
              <Box display="flex" minWidth="173px" className="day-content" key={Math.random()}>
                {workHour.is_working ? (
                  <Box>
                    <div className="hours-select">
                      <Select
                        variant="outlined"
                        height="30px"
                        id="start_time"
                        disableUnderline
                        disabled
                        data-cy="working-start-time"
                        value={workHour.start_time}
                        name="start_time"
                        MenuProps={MenuProps}
                      >
                        { hours.map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
                      </Select>
                      <span>-</span>
                      <Select
                        variant="outlined"
                        id="end_time"
                        disableUnderline
                        disabled
                        data-cy="working-end-time"
                        value={workHour.end_time}
                        name="end_time"
                        MenuProps={MenuProps}
                      >
                        { hours.map((hour) => <MenuItem key={Math.random()} value={hour}>{hour}</MenuItem>) }
                      </Select>
                    </div>
                  </Box>
                ) : (
                  <Box className="selects-border" />
                )}
              </Box>
            )) }
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

WorkingHours.propTypes = {
  // Get Company
  workingHours: PropTypes.array.isRequired,
};

export default WorkingHours;
