import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

const VerificationCodeInput = ({ setVerificationCode }) => {
  const [codeValues, setCodeValues] = useState({
    digit1: '',
    digit2: '',
    digit3: '',
    digit4: '',
    digit5: '',
    digit6: '',
  });

  const handleCodeChange = (value, name, maxLength, fieldIndex) => {
    const inputsCount = 6;
    const codeValuesCopy = { ...codeValues };

    if (value.length >= maxLength) {
      if (parseInt(fieldIndex, 10) < inputsCount) {
        const nextSibling = document.querySelector(
          `input[name=digit${parseInt(fieldIndex, 10) + 1}]`,
        );

        if (nextSibling !== null) {
          nextSibling.focus();
        }
      }
    }

    codeValuesCopy[name] = value;
    setCodeValues(codeValuesCopy);
    setVerificationCode(Object.values(codeValuesCopy).join(''));
  };

  return (
    <>
      {Object.keys(codeValues).map((key, index) => (
        <TextField
          fullWidth
          className="verification-code-input"
          key={index}
          type="number"
          pattern="[0-9]*"
          name={key}
          value={codeValues[key]}
          onChange={(e) => handleCodeChange(Math.max(0, parseInt(e.target.value)).toString().slice(0, 1), e.target.name, e.target.maxLength, index + 1)}
          inputProps={{ maxLength: 1 }}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 1);
          }}
        />
      ))}
    </>
  );
};

VerificationCodeInput.propTypes = {
  setVerificationCode: PropTypes.func.isRequired,
};

export default VerificationCodeInput;
